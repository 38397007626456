import { apiRequest, handleCall } from 'syft-acp-core/api/call'
import { ApiResponse } from 'syft-acp-core/api/types'

import {
  ListAppealsInput,
  FetchAppealInput,
  CreateAppealInput,
  UpdateAppealInput,
  Assignee,
  AppealEntity,
  Reason,
} from '../types'

/**
 * Retrieves a list of appeals. Implements pagination.
 */
export const listAppeals = ({ payload }: ListAppealsInput) =>
  new Promise<ApiResponse<AppealEntity[]>>((resolve, reject) => {
    const req = apiRequest({ path: '/admin/appeals', reqArgs: payload.options })
    handleCall(req, resolve, reject, true, payload.options)
  })

export const fetchAppeal = ({ payload }: FetchAppealInput) =>
  new Promise<ApiResponse<AppealEntity>>((resolve, reject) => {
    const req = apiRequest({ path: `/admin/appeals/${payload.appealID}` })
    handleCall(req, resolve, reject, true)
  })

/**
 * Assigns admin actor with 5 appeals.
 */
export const assignAppeals = () =>
  new Promise<ApiResponse<number[]>>((resolve, reject) => {
    const req = apiRequest({ path: '/admin/appeals/assign', method: 'POST' })
    handleCall(req, resolve, reject, true)
  })

/**
 * Gets users that were assigned an appeal in the past 3 months.
 */
export const fetchAssignees = () =>
  new Promise<ApiResponse<Assignee[]>>((resolve, reject) => {
    const req = apiRequest({ path: '/admin/appeals/assignees' })
    handleCall(req, resolve, reject, true)
  })

/**
 * Creates an appeal.
 */
export const createAppeal = ({ payload }: CreateAppealInput) =>
  new Promise<ApiResponse<AppealEntity>>((resolve, reject) => {
    const req = apiRequest({
      path: `/admin/appeals`,
      method: 'POST',
      reqArgs: payload,
    })
    handleCall(req, resolve, reject, true)
  })

/**
 * Updates an appeal.
 */
export const updateAppeal = ({ payload }: UpdateAppealInput) =>
  new Promise<ApiResponse<AppealEntity>>((resolve, reject) => {
    const req = apiRequest({
      path: `/admin/appeals/${payload.id}`,
      method: 'PATCH',
      reqArgs: payload.options,
    })
    handleCall(req, resolve, reject, true)
  })

export const fetchAppealReasons = () =>
  new Promise<ApiResponse<Reason[]>>((resolve, reject) => {
    const req = apiRequest({ path: '/workers/appeal_reasons' })
    handleCall(req, resolve, reject)
  })
