import React from 'react'
import PropTypes from 'prop-types'
import { TrackingProvider } from '@indeed/flex-tracking-context'
import { useFlexFlagIsOn } from '@indeed/flex-feature-flags'

import AgencyViewBody from 'syft-acp-core/entities2/AgencyView'
import PageTitle from 'syft-acp-core/components/PageTitle'
import { AgencySubNavigation } from './AgencySubNavigation'

export const AgencyView = ({ match: { params }, ...props }) => {
  const isAgencyRateCardEnabled = useFlexFlagIsOn('pte_16679_agency_rate_cards')

  return (
    <TrackingProvider
      value={{
        context: {
          agency_id: Number(params.id),
        },
      }}
    >
      <PageTitle title="Agencies list">
        {isAgencyRateCardEnabled && <AgencySubNavigation agencyID={Number(params.id)} />}
        <AgencyViewBody params={params} {...props} />
      </PageTitle>
    </TrackingProvider>
  )
}

AgencyView.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({ id: PropTypes.string }).isRequired,
  }),
  isAgencyRateCardEnabled: PropTypes.bool.isRequired,
}
