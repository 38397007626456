// Syft ACP - UI Library <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import React from 'react'
import PropTypes from 'prop-types'

import './RecordHeader.css'

class RecordHeader extends React.PureComponent {
  static propTypes = {
    children: PropTypes.node.isRequired,
    labels: PropTypes.node,
    border: PropTypes.bool,
    main: PropTypes.bool,
    sub: PropTypes.bool,
  }

  static defaultProps = {
    labels: null,
    border: false,
    main: true,
    sub: false,
  }

  render() {
    const { border, main, sub, labels, children } = this.props

    return (
      <div className={`acp-item-header ${border ? 'border' : ''}`}>
        <span className={`header-text type-${main ? 'main' : sub ? 'sub' : 'subsub'}`}>{children}</span>
        {labels}
      </div>
    )
  }
}

export default RecordHeader
