import * as React from 'react'

import { ChangedNumberPropertiesProps } from './ChangedNumberProperties.types'

const ChangedNumberProperties = ({ count, text, divClassName }: ChangedNumberPropertiesProps) => {
  if (!count) return null
  return (
    <div className={divClassName}>
      <strong>
        Number of {text}s impacted: {count} {text}
        {count > 1 ? 's' : ''}
      </strong>
    </div>
  )
}

export default ChangedNumberProperties
