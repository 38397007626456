import createEntityReducer from 'syft-acp-core/reducers/generators/entityReducer'

import types from './action-types'
import * as actions from './actions'
import {
  WorkerMarketingPreferencesEntity,
  WorkerMarketingPreferencesState,
  WorkerMarketingPreferencesReducerType,
} from './types'

const setIsLoading = (isLoading: boolean) => (state: WorkerMarketingPreferencesEntity) => ({
  ...state,
  isLoading,
})

export const setFetchData = (
  state: WorkerMarketingPreferencesState,
  action: actions.FetchWorkerMarketingPreferencesSucceededAction,
) => ({
  ...state,
  [action.request.workerID]: {
    ...state[action.request.workerID],
    ...action.payload,
  },
  isLoading: false,
})

const setIsUpdating = (isUpdating: boolean) => (state: WorkerMarketingPreferencesEntity) => ({
  ...state,
  isUpdating,
})

export const updateData = (
  state: WorkerMarketingPreferencesState,
  action: actions.UpdateWorkerMarketingPreferencesSucceededAction,
) => ({
  ...state,
  [action.request.workerID]: {
    ...state[action.request.workerID],
    ...action.payload,
  },
  pendingConsent: null,
  isUpdating: false,
})

export const setPendingConsent = (
  state: WorkerMarketingPreferencesState,
  action: actions.SetPendingConsentWorkerMarketingPreferencesAction,
) => ({
  ...state,
  pendingConsent: action.payload,
})

export const clearPendingConsent = (state: WorkerMarketingPreferencesState) => ({
  ...state,
  pendingConsent: null,
})

const initialState = {
  isLoading: false,
  isUpdating: false,
  pendingConsent: null,
}

const entityReducer = createEntityReducer(
  'workerMarketingPreferences',
  {
    [types.ENTITY_FETCH_BEGIN]: setIsLoading(true),
    [types.ENTITY_FETCH_SUCCEEDED]: setFetchData,
    [types.ENTITY_FETCH_FAILED]: setIsLoading(false),
    [types.SET_PENDING_WORKER_MARKETING_PREFERENCES_BEGIN]: setPendingConsent,
    [types.CLEAR_PENDING_WORKER_MARKETING_PREFERENCES_BEGIN]: clearPendingConsent,
    [types.ENTITY_UPDATE_BEGIN]: setIsUpdating(true),
    [types.ENTITY_UPDATE_SUCCEEDED]: updateData,
    [types.ENTITY_UPDATE_FAILED]: setIsUpdating(false),
  },
  { initialState },
)

export default entityReducer as WorkerMarketingPreferencesReducerType
