export const trackingEvents = {
  SAVE: {
    CLICKED: 'employer_salesforce_promotional_details.save.clicked',
  },
  NEW_PROMOTION_PLAN: {
    CLICKED: 'employer_salesforce_promotional_details.new_promotion_plan.clicked',
  },
  ADD_PROMOTION_PERIOD: {
    CLICKED: 'employer_salesforce_promotional_details.add_promotion_period.clicked',
  },
}
