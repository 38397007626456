import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { formatFullName } from 'syft-acp-util/formatting'
import { isDraft } from 'syft-acp-core/sagas/calls/bookingCalls'
import { updateBooking, createBookingChanges, replaceBookingChanges } from 'syft-acp-core/actions/bookings'
import EntityListInputWrapper from 'syft-acp-core/components/EntityList/EntityListInputWrapper'
import FilterAutocompleteWorkers from 'syft-acp-util/components/FilterForm/FilterAutocompleteWorkers'
import UserAvatar from 'syft-acp-core/components/UserAvatar'

import './ListingWorkersAutocomplete.css'

class ListingWorkersAutocomplete extends PureComponent {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    attr: PropTypes.string.isRequired,
    shiftBooking: PropTypes.object.isRequired,
    placeholder: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
  }

  constructor(props) {
    super(props)
    const worker = this.getWorkerInfo(
      props.shiftBooking
        ? { ...props.shiftBooking.worker, name: formatFullName(props.shiftBooking.worker) }
        : {},
    )
    this.state = { worker }
  }

  getWorkerInfo = worker => ({
    id: worker && worker.id,
    name: worker && worker.name,
    avatar: worker && worker.profile_picture && worker.profile_picture.uuid,
  })

  onChange = (value, query) => {
    const { dispatch, shiftBooking, attr } = this.props
    const { id } = shiftBooking
    const val = Number(value)
    const action = isDraft(id) ? createBookingChanges : replaceBookingChanges

    // TODO: query the user's profile picture and update when fetched.
    const workerInfo = this.getWorkerInfo({
      id: val,
      name: query,
      profile_picture: { uuid: '' },
    })

    this.setState({
      worker: workerInfo,
    })

    dispatch(updateBooking({ id, attr, val }))
    dispatch(action({ id, attr, val }))
  }

  render() {
    const { placeholder, disabled } = this.props
    const { avatar, name } = this.state.worker
    return (
      <EntityListInputWrapper className="AutocompleteWorkers">
        {<UserAvatar avatar={avatar} name={name} size="xsmall" shape="rounded-square" />}
        <FilterAutocompleteWorkers
          className="FilterInput"
          callback={this.onChange}
          forceValue={name}
          placeholder={placeholder}
          disabled={disabled}
        />
      </EntityListInputWrapper>
    )
  }
}

export default connect()(ListingWorkersAutocomplete)
