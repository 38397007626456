import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { useFormatDateTime } from 'syft-acp-core/lib/hooks'

const FormattedDateTime = ({ value, format, children, Component, ...options }) => {
  const [formattedDateTime, formatDateTime] = useFormatDateTime(value, { ...options, format })

  if (typeof children === 'function') {
    return children(formattedDateTime, formatDateTime)
  }

  return <Component>{formattedDateTime}</Component>
}

FormattedDateTime.defaultProps = {
  format: undefined,
  children: undefined,
  Component: Fragment,
  toParts: false,
}

FormattedDateTime.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.instanceOf(Date)]).isRequired,
  format: PropTypes.object,
  children: PropTypes.func,
  Component: PropTypes.elementType,
  toParts: PropTypes.bool,
}

export { fullLocalDateTimeFormat } from './formats'

export default React.memo(FormattedDateTime)
