import React, { useMemo, useCallback } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { noop } from 'lodash-es'

import { downloadAsFileFromApi } from 'syft-acp-core/api/file'
import { MenuItem } from 'syft-acp-atoms/Button'

const shouldAllowDownload = completedShifts =>
  completedShifts &&
  completedShifts.total &&
  completedShifts.ids.some(id => {
    const shift = completedShifts.entityMap[id]
    return shift.approved && !shift.no_show
  })

const WorkerReferenceDownloadLink = ({ workerId, completedShifts, onClick }) => {
  const downloadAllowed = useMemo(
    () => shouldAllowDownload(completedShifts[`workerID=${workerId}`]),
    [completedShifts, workerId]
  )

  const downloadReferenceAction = useCallback(() => {
    downloadAsFileFromApi(`/admin/workers/${workerId}/reference`, {}, 'worker-reference.pdf')
    onClick()
  }, [workerId, onClick])

  return (
    <>
      {downloadAllowed ? (
        <MenuItem key={workerId} onClick={downloadReferenceAction}>
          Download reference
        </MenuItem>
      ) : (
        <MenuItem disabled key={workerId}>
          (Reference unavailable)
        </MenuItem>
      )}
    </>
  )
}

WorkerReferenceDownloadLink.propTypes = {
  workerId: PropTypes.number.isRequired,
  completedShifts: PropTypes.object,
  onClick: PropTypes.func,
}

WorkerReferenceDownloadLink.defaultProps = {
  completedShifts: {},
  onClick: noop,
}

export default connect(state => ({
  completedShifts: state.workerCompletedShifts.entitySets,
}))(WorkerReferenceDownloadLink)
