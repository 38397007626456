import React, { useCallback } from 'react'
import { connect } from 'react-redux'
import { difference } from 'lodash-es'
import { bindActionCreators } from 'redux'
import { FormGroup, Checkbox } from 'react-bootstrap'
import * as filtersActions from 'syft-acp-core/store/filters/actions'
import { FilterSelectManagerRolesProps, ConnectedFilterSelectManagerRolesProps } from './FilterSelectManagerRoles.types'

import './FilterSelectManagerRoles.css'

export const roles = [
  { id: 'account_director', label: 'Account director' },
  { id: 'account_manager', label: 'Account manager' },
  { id: 'sales_manager', label: 'Sales manager' },
]

export const getValuesFromState = (state: any, { value }: ConnectedFilterSelectManagerRolesProps) => {
  const queryManagerRole = state?.routing?.locationBeforeTransitions?.query?.manager_role
  return { value: value || (Array.isArray(queryManagerRole) || !queryManagerRole ? queryManagerRole : [queryManagerRole]) }
}

export const FilterSelectManagerRoles = ({ actions, value = [], disabled = false, name }: FilterSelectManagerRolesProps) => {
  const onChange = useCallback(val => {
    if (value.indexOf(val) > -1) {
      actions.setFilter(difference(value, [val]))
    } else {
      actions.setFilter([...value, val])
    }
  }, [actions, value])

  return (
    <FormGroup className="DataManagerRoles">
      {roles.map((role, n) => (
        <Checkbox
          value={role.id}
          name={name}
          checked={value.indexOf(role.id) > -1}
          onChange={() => onChange(role.id)}
          disabled={disabled}
          key={n}
        >
          {role.label}
        </Checkbox>
      ))}
    </FormGroup>
  )
}

const conector = connect(
  getValuesFromState,
  (dispatch, { actions }) => {
    const boundActions = bindActionCreators(
      {
        ...filtersActions,
      },
      dispatch
    )

    return {
      actions: {
        ...boundActions,
        ...actions,
      },
    }
  }
)

export default conector(FilterSelectManagerRoles)
