// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import classnames from 'classnames'
import ReactTooltip from 'react-tooltip'

import './EntityListCellWrapper.css'

/**
 * Table cell component for an EntityList table. Normally, all content is encased
 * in a <Link> component. In some cases we want only the child content.
 *
 * @param {Object} props Properties
 * @returns {XML} Pure EntityListCell component
 */
export default class EntityListCellWrapper extends PureComponent {
  static propTypes = {
    children: PropTypes.node.isRequired,
    className: PropTypes.string.isRequired,
    enableLink: PropTypes.bool.isRequired,
    hasLink: PropTypes.bool.isRequired,
    hasPadding: PropTypes.bool,
    isSubRow: PropTypes.bool.isRequired,
    linkAttributes: PropTypes.arrayOf(PropTypes.string).isRequired,
    rowURL: PropTypes.string.isRequired,
    tooltipVal: PropTypes.object.isRequired,
    selectCallback: PropTypes.func.isRequired,
    selectFullRow: PropTypes.bool.isRequired,
    subRows: PropTypes.number.isRequired,
    type: PropTypes.string.isRequired,
  }

  static defaultProps = {
    hasPadding: false,
  }

  render() {
    const {
      className,
      hasLink,
      rowURL,
      tooltipVal,
      isSubRow,
      subRows = null,
      linkAttributes,
      enableLink = true,
      children,
    } = this.props
    const rowSpan = subRows ? (isSubRow ? 1 : subRows) : null
    const linkClick = {}
    if (this.props.selectFullRow) {
      linkClick.onClick = e => {
        e.preventDefault()
        this.props.selectCallback(e)
      }
    } else {
      linkClick.to = enableLink ? rowURL : null
    }
    const dataTip = tooltipVal.value
    const dataTipFor = tooltipVal ? `entity-list-tooltip-${tooltipVal.id}` : null
    return (
      <td
        className={className}
        rowSpan={rowSpan}
        {...(tooltipVal ? { 'data-tip': dataTip, 'data-for': dataTipFor } : {})}
        data-testid="entity-list-row-cell"
      >
        {dataTip ? <ReactTooltip effect="solid" id={dataTipFor} /> : null}
        {hasLink ? (
          <Link {...linkClick} className={classnames(linkAttributes, { 'disabled-link': !enableLink })}>
            {children}
          </Link>
        ) : (
          children
        )}
      </td>
    )
  }
}
