import { apiRequest, handleCall } from 'syft-acp-core/api/call'
import { omit } from 'lodash-es'

export const fetchAgencyAccounts = ({ payload }) =>
  new Promise((resolve, reject) => {
    const req = apiRequest({ path: `/admin/agencies/${payload.agencyId}/accounts`, reqArgs: payload })
    handleCall(req, resolve, reject, true, payload)
  })

export const fetchAgencyAccountsPermissions = ({ payload }) =>
  new Promise((resolve, reject) => {
    const req = apiRequest({
      path: `/admin/agencies/${payload.agencyId}/accounts/${payload.accountId}/permissions`,
      reqArgs: payload,
    })
    handleCall(req, resolve, reject, true, payload)
  })

export const saveAgencyAccount = ({ id, data }) =>
  new Promise((resolve, reject) => {
    const newData = omit(data, 'role_ids')
    const req = apiRequest({
      path: `/admin/agencies/${id}/accounts`,
      reqArgs: { account_id: data.id, ...omit(newData, 'agencyId') },
      method: 'PATCH',
    })
    handleCall(req, resolve, reject, true, { agencyId: id })
  })
