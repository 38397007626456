import { ShiftFulfilmentModalState as State } from './ShiftFulfilmentModal.types'
import { ShiftFulfilmentModalAction } from './ShiftFulfilmentModal.actions'
import * as actionTypes from './ShiftFulfilmentModal.action-types'

export const reducer = (state: State, action: ShiftFulfilmentModalAction): State => {
  switch (action.type) {
    case actionTypes.SET_SHIFT:
      return action.payload
    case actionTypes.UPDATE_OFFER_RATE:
      return {
        ...state,
        offer_rate: action.payload,
      }
    case actionTypes.UPDATE_CLIENT_RATE:
      return {
        ...state,
        job: {
          ...state?.job,
          pay_rate: action.payload,
        },
      }
    case actionTypes.UPDATE_BOOKABLE_INDIVIDUALLY:
      return {
        ...state,
        job: {
          ...state?.job,
          bookable_individually: action.payload,
        },
      }
    case actionTypes.UPDATE_HARD_SKILLS:
      return {
        ...state,
        job: {
          ...state?.job,
          skill_ids: action.payload,
        },
      }
    case actionTypes.UPDATE_STOP_OFFERS:
      return {
        ...state,
        stop_offers_for_worker_type: action.payload,
      }
    case actionTypes.UPDATE_START_TIME:
      return {
        ...state,
        start_time: action.payload,
      }
    case actionTypes.UPDATE_FULFILMENT_REVIEWED:
      return {
        ...state,
        fulfilment_reviewed: action.payload,
      }
    case actionTypes.UPDATE_END_TIME:
      return {
        ...state,
        end_time: action.payload,
      }
    case actionTypes.UPDATE_OFFER_TO_FLEX:
      return {
        ...state,
        offer_to_flex: action.payload,
      }

    default:
      return state
  }
}
