import React, { useEffect } from 'react'
import { Grid, Col, Row } from 'react-bootstrap'
import { map, fromPairs, get } from 'lodash-es'

import Acp from 'syft-acp-uikit'
import { createEntityListConnector } from 'syft-acp-util/entityList'
import NewManagerButton from 'syft-acp-core/components/Buttons/NewManagerButton'
import * as managersActions from 'syft-acp-core/actions/managers'
import { roles } from 'syft-acp-util/components/FilterForm/FilterSelectManagerRoles'
import { ManagerEntity } from 'syft-acp-core/store/types'

import { ManagersListProps as Props } from './ManagersList.types'

export const entityConnector = createEntityListConnector<ManagerEntity>({
  entityActions: { fetchEntities: managersActions.fetchAdminManagers },
  entityStore: 'managers',
})

// Transform { id: 'account_manager', label: 'Account manager' } etc.
// into { account_manager: 'Account manager '}. For easy k/v lookup.
const managerRolesKV = fromPairs(map(roles, r => [r.id, r.label]))

const getRoles = (row: ManagerEntity) => {
  if (row.manager_roles?.length === 0) return '–'
  return row.manager_roles?.map((role: string) => get(managerRolesKV, role, role)).join(', ')
}

const ManagersList = ({ actions, data, isLoadingData }: Props) => {
  useEffect(() => {
    actions.fetchEntities && actions.fetchEntities({ options: {} })
  }, [])

  return (
    <>
      <Grid>
        <Row>
          <Col md={12}>
            <NewManagerButton label="New manager" />
          </Col>
        </Row>
      </Grid>
      <Acp.EntityList data={data} isLoading={isLoadingData} idKeyValue="id" inContainer>
        <Acp.Table rowLink={(row: ManagerEntity) => `/admin/managers/view/${row.id}`}>
          <Acp.Col.Text value="id" header="ID" align="left" isMinimal isNumeric />
          <Acp.Col.Text value="name" header="Name" align="left" />
          <Acp.Col.Text value="email" header="Email" align="left" />
          <Acp.Col.Text value={getRoles} header="Roles" align="left" />
        </Acp.Table>
      </Acp.EntityList>
    </>
  )
}

export default entityConnector(ManagersList)
