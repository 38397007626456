import * as types from './action-types'

export const bulkEditAdminTimesheets = payload => ({
  type: types.ADMIN_TIMESHEETS_BULK_EDIT_BEGIN,
  payload,
})

export const editAllAdminTimesheets = payload => ({
  type: types.ADMIN_TIMESHEETS_EDIT_ALL_BEGIN,
  payload,
})
