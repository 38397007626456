import React, { memo, useCallback } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { useTrackingTrigger } from '@indeed/flex-tracking-context'

import { showConfirmModal } from 'syft-acp-core/store/modals/actions'
import { Button } from 'syft-acp-atoms/Button'
import { deleteAgencyPlatform } from 'syft-acp-core/actions/agency-platform'
import { fetchAgencyProfile } from 'syft-acp-core/actions/agency-profile'
import { trackingEvents } from './AgencyView.tracking'

const RemoveButton = memo(({ data: { agency_platforms, id: agencyId }, dispatch, row: { platform_id } }) => {
  const triggerEvent = useTrackingTrigger()
  const { id } = agency_platforms.find(agencyPlatform => agencyPlatform.platform_id === platform_id) || {}
  const onConfirm = useCallback(() => {
    const reloadAgency = () => dispatch(fetchAgencyProfile({ agencyId }))
    dispatch(deleteAgencyPlatform({ id }, reloadAgency))
    triggerEvent(trackingEvents.DELETE_EMPLOYER_BUTTON.CLICKED, {
      agency_platform_id: id,
    })
  }, [agencyId, dispatch, id, triggerEvent])

  const showConfirm = useCallback(() => {
    dispatch(
      showConfirmModal({
        question: 'Are you sure you want to delete this Employer from the agency?',
        onConfirm,
      })
    )
  }, [dispatch, onConfirm])

  if (!id) return null

  return (
    <Button kind="danger" onClick={showConfirm}>
      Delete
    </Button>
  )
})

export const AgencyEmployerRemoveButton = connect()(RemoveButton)

RemoveButton.propTypes = {
  data: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  row: PropTypes.object.isRequired,
}
