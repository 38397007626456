import { connect } from 'react-redux'
import { get } from 'lodash-es'
import { createSelector } from 'reselect'
import { mergePaginationArgs } from './resource'

/**
 * Adds an acpData() method to a component. This returns data from connected resources.
 * The data returned is { isLoading, isStale, isOK, data, meta }, all from the last request.
 *
 * If multiple resources are connected to a component, this function will return
 * an object containing resource names as the keys.
 */
const decorateAcpData = (component, resources) => {
  // eslint-disable-next-line no-param-reassign
  component.prototype._acpData = function acpData() {
    const resourceList = Object.keys(resources(this.props))
    const items = {}
    for (const item of resourceList) {
      const resource = this.props[item]
      items[item] = resource
    }
    // If only one resource is connected to this component,
    // return only its information.
    if (resourceList.length === 1) {
      return items[resourceList[0]]
    } else {
      return items
    }
  }
}

/**
 * Connects a component to one or more API resources.
 *
 * Use like this:
 *
 *    const WorkersList = ({ activeResource, activeQuery }) => (
 *      <Acp.EntityList hasResultCount hasPagination idKeyValue="id" resource={ activeResource } activeQuery={ activeQuery }>
 *        <Acp.Actions>
 *          <Acp.Button onClick={ (a, b) => console.log(a, b) } kind="success">Save</Acp.Button>
 *        </Acp.Actions>
 *        <Acp.Table rowLink={ row => `/entity/users/view/${row.id}` }>
 *          <Acp.Col.Text value="id" header="ID" isMinimal isNumeric />
 *          <Acp.ColEditable.Text value="account_number" header="Acc. number" isMain />
 *        </Acp.Table>
 *      </Acp.EntityList>
 *    );
 *
 *    export default connectAcpResource({ workers })(WorkersList);
 *
 * This connects the 'workers' API resource.
 *
 * TODO: currently only a single API resource is supported.
 */
export const connectAcpResource =
  (resources, { addAcpData = true } = {}) =>
  Component => {
    const getResources = typeof resources === 'function' ? resources : () => resources

    // Adds an acpData() method that returns the connected resources' data.
    if (addAcpData) {
      decorateAcpData(Component, getResources)
    }

    // Add a getter for 'acpData' that binds its 'this' value to the component.
    // This has to happen after the object is instantiated.
    Object.defineProperty(Component.prototype, 'AcpResource', {
      get() {
        return this._acpData.bind(this)
      },
    })

    /** Returns a connected resource state for the component. */
    const reduceState = () =>
      createSelector(
        [state => get(state, 'routing.locationBeforeTransitions.query'), (_, props) => getResources(props)],
        (queryFromRouter, currentResources) => {
          const resourceList = Object.keys(currentResources)
          return resourceList.reduce((resProps, res) => {
            const resource = currentResources[res]
            const isPaginated = resource.info.paginated
            const query = isPaginated ? mergePaginationArgs(queryFromRouter, isPaginated) : queryFromRouter

            const getCSV = (onSuccess, onFail) => resource.getCSV(query, { onSuccess, onFail })
            /**
             * Fetches resource and converts to CSV and downloads it
             *
             * @param args
             * @param options
             * @returns {Promise<void>}
             */
            const downloadCSV = (args, options) =>
              resource.downloadCSV({ ...this.getQuery(), ...args }, { ...options, forceFetch: true })
            /**
             * Re-fetch resource; by default, unless a call is already in progress
             *
             * @param args
             * @param options
             * @returns {*}
             */
            const refetch = (args, options = {}) => resource.getRemote({ ...query, ...args }, options)
            /**
             * Retrieves the current latest information from the resource, for the current query.
             *
             * @param args
             * @param options
             * @returns {*}
             */
            const queryView = (args, options = {}) => resource.queryView({ ...query, ...args }, options)
            /**
             * Makes another call to the API resource; unless a call is already in progress.
             *
             * @param args
             * @returns {Promise<*>}
             */
            const queryUpdate = args => resource.getRemote({ ...query, ...args }, { forceFetch: false })

            /**
             * Get req result
             *
             * @param args
             * @returns {{data: [], meta: {}}|{data: [], meta: {}}|{data: [], meta: {}}}
             */
            const getRequestResult = args => queryView(args).setLens.get().reqResult

            return {
              ...resProps,
              query,
              activeResourceName: res,
              activeResource: resource,
              [res]: {
                get: resource.get,
                post: resource.post,
                put: resource.put,
                patch: resource.patch,
                delete: resource.delete,
                // FIXME
                getCSV,
                downloadCSV,
                // Re-fetch resource; by default, unless a call is already in progress
                refetch,
                // Retrieves the current latest information from the resource, for the current query.
                queryView,
                // Makes another call to the API resource; unless a call is already in progress.
                queryUpdate,
                getRequestResult,
              },
            }
          }, {})
        }
      )

    return connect(reduceState)(Component)
  }
