import { generateEntityCrudActionTypes } from 'syft-acp-util/store/action-types'

const actionTypes = generateEntityCrudActionTypes('workerMartketingPreferences')

const CONSENT_WORKER_MARKETING_PREFERENCES_BEGIN =
  'syftacp/CONSENT_WORKER_MARKETING_PREFERENCES_BEGIN' as const

const SET_PENDING_WORKER_MARKETING_PREFERENCES_BEGIN =
  'syftacp/SET_PENDING_WORKER_MARKETING_PREFERENCES_BEGIN' as const
const CLEAR_PENDING_WORKER_MARKETING_PREFERENCES_BEGIN =
  'syftacp/CLEAR_PENDING_WORKER_MARKETING_PREFERENCES_BEGIN' as const

export default {
  ...actionTypes,
  CONSENT_WORKER_MARKETING_PREFERENCES_BEGIN,
  SET_PENDING_WORKER_MARKETING_PREFERENCES_BEGIN,
  CLEAR_PENDING_WORKER_MARKETING_PREFERENCES_BEGIN,
}
