import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import { zeroPad } from 'syft-acp-util/formatting'

import './TimeInput.css'

// Input limits for hours/minutes.
const hourLimits = {
  0: 2,
  1: 3,
}
const minuteLimits = {
  0: 5,
  1: 9,
}

/**
 * A time input component that mimics the standard <input type="time">.
 * It's designed to display time as a 24 hour clock.
 */
class TimeInput extends PureComponent {
  static propTypes = {
    onChange: PropTypes.func.isRequired,
    value: PropTypes.string,
    disabled: PropTypes.bool,
    // If 'duration' is true, we will send back a number of seconds instead, counting from 00:00.
    duration: PropTypes.bool,
    // Whether the time input is limited to 24 hours.
    limitHours: PropTypes.bool,
    // Whether there's an 'x' button that clears the input back to null.
    canBeCleared: PropTypes.bool,
  }

  static defaultProps = {
    value: null,
    duration: false,
    disabled: false,
    limitHours: true,
    canBeCleared: false,
  }

  // Value that's displayed if the hours/minutes is null.
  static emptyValue = '--'

  static getDerivedStateFromProps(props, state) {
    return { ...state, textValue: props.value }
  }

  constructor(props) {
    super()
    this.inputRef = null
    this.state = {
      activeHours: false,
      activeMinutes: false,
      activeNumber: 0,
      textValue: props.value,
      focus: false,
    }
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.onClickOutside)
    document.addEventListener('keydown', this.onKeyDown)
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.onClickOutside)
    document.removeEventListener('keydown', this.onKeyDown)
  }

  callback = value => {
    this.props.onChange(value, this.getDurationInMs(value))
  }

  // Increment or decrement the value in response to user input.
  changeValue = (diff, unit = 'minutes') => {
    const { limitHours } = this.props
    const { textValue } = this.state
    let { hours, minutes } = this.splitValue(textValue)
    if (unit === 'minutes') minutes += diff
    if (unit === 'hours') hours += diff
    if (minutes > 59) {
      minutes = 0
      hours += 1
    }
    if (minutes < 0) {
      minutes = 59
      hours -= 1
    }
    if (limitHours) {
      if (hours > 23) {
        hours = 0
      }
      if (hours < 0) {
        hours = 23
      }
    } else if (hours < 0) {
      hours = 0
    }
    const newValue = this.joinValue(hours, minutes)
    this.callback(newValue)
  }

  // Update the value directly in case a number is input.
  setValue = (number, activeNumber, unit = 'minutes') => {
    const { limitHours } = this.props
    const { textValue, activeHours } = this.state
    let { hours, minutes } = this.splitValue(textValue)

    if (hours == null) hours = 0
    if (minutes == null) minutes = 0

    if (unit === 'minutes') {
      const minStr = zeroPad(String(minutes)).split('')
      minStr[activeNumber] = Math.min(number, minuteLimits[activeNumber])
      minutes = Number(minStr.join(''))
    }
    if (unit === 'hours') {
      let hrStr = zeroPad(String(hours)).split('')
      hrStr[activeNumber] =
        limitHours && activeNumber === 1 && hrStr[0] === hourLimits[0]
          ? Math.min(number, hourLimits[activeNumber])
          : number
      if (Number(hrStr.join('')) >= 23 && limitHours) hrStr = '23'.split('')
      hours = Number(hrStr.join(''))
    }
    const newValue = this.joinValue(hours, minutes)
    this.callback(newValue)

    // If we're on number 0, switch to number 1.
    if (activeNumber === 0) {
      this.setState({ ...this.state, activeNumber: 1 })
    }
    // If we're on number 1 and on hours, switch to minutes.
    if (activeNumber === 1 && activeHours) {
      this.setState({ ...this.state, activeHours: false, activeMinutes: true, activeNumber: 0 })
    }
  }

  // When clicking outside, remove focus from the component.
  onClickOutside = ev => {
    if (!ev || (this.inputRef && !this.inputRef.contains(ev.target))) {
      this.setState({
        ...this.state,
        focus: false,
        activeHours: false,
        activeMinutes: false,
        activeNumber: 0,
      })
    }
  }

  // When clicking on either the body or the hours/minutes values, focus the component.
  onClickBody = () => {
    if (this.props.disabled) return
    // Use a setTimeout() to ensure lower priority than onClickHours() and onClickMinutes().
    setTimeout(() => this.setState({ ...this.state, focus: true, activeNumber: 0 }), 0)
  }

  onClickHours = () => {
    if (this.props.disabled) return
    this.setState({ ...this.state, focus: true, activeMinutes: false, activeHours: true, activeNumber: 0 })
  }

  onClickMinutes = () => {
    if (this.props.disabled) return
    this.setState({ ...this.state, focus: true, activeHours: false, activeMinutes: true, activeNumber: 0 })
  }

  // Responds to user arrow key input.
  // TODO: add code to respond to tabbing.
  onKeyDown = ev => {
    if (this.props.disabled) return
    const { activeNumber, activeMinutes, activeHours, focus } = this.state

    const isLeft = ev.keyCode === 37
    const isUp = ev.keyCode === 38
    const isRight = ev.keyCode === 39
    const isDown = ev.keyCode === 40

    // Check if this is a number.
    const isNumber = ev.keyCode >= 48 && ev.keyCode <= 57
    const number = ev.keyCode - 48

    if (!focus) {
      return
    }

    ev.preventDefault()

    // When pressing left/right, switch between editing the hours/minutes.
    if (isLeft && (activeMinutes || (!activeMinutes && !activeHours))) {
      return this.onClickHours()
    }
    if (isRight && (activeHours || (!activeMinutes && !activeHours))) {
      return this.onClickMinutes()
    }

    // Don't allow changing the value unless one of the two units is active.
    if (!activeMinutes && !activeHours) {
      return
    }

    // When pressing up/down, add or remove one from the active unit.
    if (isUp) {
      return this.changeValue(1, activeMinutes ? 'minutes' : 'hours')
    }
    if (isDown) {
      return this.changeValue(-1, activeMinutes ? 'minutes' : 'hours')
    }

    // When typing a number, set the value directly.
    if (isNumber) {
      return this.setValue(number, activeNumber, activeMinutes ? 'minutes' : 'hours')
    }
  }

  // Joins the hours/minutes values together into a string.
  joinValue = (hours, minutes) => {
    const hasAnyNumber = hours != null || minutes != null
    const nHours = hours || (hasAnyNumber ? 0 : hours)
    const nMinutes = minutes || (hasAnyNumber ? 0 : minutes)
    return `${zeroPad(nHours)}:${zeroPad(nMinutes)}`
  }

  // Splits the hours/minutes value up into separate numbers.
  splitValue = value => {
    if (!value) return { hours: null, minutes: null }
    const [hours, minutes] = value.split(':')
    return { hours: Number(hours), minutes: Number(minutes) }
  }

  // Turns a text time value into a number of ms. Used for durations.
  getDurationInMs = value => {
    const vals = this.splitValue(value)
    return (vals.hours * 3600 + vals.minutes * 60) * 1000
  }

  // Prepares the hours/minutes for display in the DOM.
  renderValue = (hours, minutes, disabled = false) => {
    if (hours == null && minutes == null && disabled) {
      return { displayHour: TimeInput.emptyValue, displayMinute: TimeInput.emptyValue }
    }
    const displayHour = hours != null ? zeroPad(String(hours)) : TimeInput.emptyValue
    const displayMinute = minutes != null ? zeroPad(String(minutes)) : TimeInput.emptyValue

    return { displayHour, displayMinute }
  }

  onFocusComponent = () => {
    this.onClickHours()
  }

  /** Clears the component and sets its value to null. */
  onClickClear = () => {
    this.callback(null)
    this.setState({ ...this.state, focus: false, activeHours: false, activeMinutes: false, activeNumber: 0 })
  }

  // Saves a reference to the component's DOM node, which is used to detect focus.
  saveRef = node => {
    this.inputRef = node
  }

  render() {
    const { disabled, canBeCleared } = this.props
    const { focus, activeHours, activeMinutes, textValue } = this.state

    const { hours, minutes } = this.splitValue(textValue)
    const { displayHour, displayMinute } = this.renderValue(hours, minutes, disabled)

    // Whether the component is displaying the --:-- empty value.
    const hasEmptyValue = displayHour === TimeInput.emptyValue
    const hasZeroValue = displayHour === '00' && displayMinute === '00'

    return (
      <div
        className={classnames('TimeInput', { disabled })}
        ref={this.saveRef}
        onClick={this.onClickBody}
        tabIndex="0"
        onFocus={this.onFocusComponent}
      >
        <div className="values">
          <div className={classnames('value', 'left', 'hours')} onClick={this.onClickHours}>
            <span className={classnames('value-wrapper', { active: activeHours })}>{displayHour}</span>
          </div>
          <div className="value separator">:</div>
          <div className={classnames('value', 'right', 'minutes')} onClick={this.onClickMinutes}>
            <span className={classnames('value-wrapper', { active: activeMinutes })}>{displayMinute}</span>
          </div>
        </div>
        {canBeCleared && !hasEmptyValue && !hasZeroValue && (
          <div className="clear" onClick={this.onClickClear} />
        )}
        <div className={classnames('field', { focus, disabled })} />
      </div>
    )
  }
}

export default TimeInput
