import React, { useCallback, useMemo } from 'react'
import { keyBy, noop } from 'lodash-es'
import { useFlexFlagIsOn } from '@indeed/flex-feature-flags'
import { history } from 'syft-acp-core/history'
import { Button, ButtonGroup } from 'syft-acp-atoms/Button'
import NotifyBar from 'syft-acp-atoms/NotifyBar'
import { TYPE_CUSTOM } from 'syft-acp-core/components/EditableTable'
import LoadingIndicator from 'syft-acp-util/components/LoadingIndicator'
import RecordSection from 'syft-acp-core/components/RecordSection'
import RecordHeaderForm from 'syft-acp-core/components/RecordSection/RecordHeaderForm'

import PreferenceDetail from '../PreferenceDetail'
import PreferencesListWrapper, { OwnProps, RenderProps } from '../PreferencesListWrapper'
import { EmployerPreferenceEntity } from '../../../store/employerPreferences/types'

const useSectionStructure = (fields: { name: string; header: string }[]) =>
  useMemo(
    () =>
      fields.map(({ name, header }) => [
        name,
        {
          header,
          type: TYPE_CUSTOM,
          getData: (
            preferences: Record<string, EmployerPreferenceEntity>,
            { onChange }: { onChange: (value: any) => void },
          ) => (
            <div style={{ marginTop: 10 }}>
              <PreferenceDetail
                hideSectionTitle
                root
                onEntityUpdate={onChange}
                id={preferences?.[name]?.id}
              />
            </div>
          ),
        },
      ]),
    [fields],
  )

const PreferencesListSection = ({
  onEntityUpdate,
  title,
  data,
  isBusy,
  isPristine,
  onSave,
  lastMessage,
  jobID,
  workLocationID,
  callbackOnSave = noop,
  callbackOnVenuePreferences,
  employerID,
}: RenderProps) => {
  const enableOverbookPreferences = useFlexFlagIsOn('overbooked_preferences')
  const additionalPreferences = enableOverbookPreferences
    ? [{ name: 'overbooking', header: 'Overbooking' }]
    : []

  const sectionData = useMemo(() => keyBy(data, 'group'), [data])
  const structure = useSectionStructure([
    ...additionalPreferences,
    { name: 'rates', header: 'Rates' },
    { name: 'booking', header: 'Booking' },
    { name: 'worker_pool', header: 'Worker pool' },
  ])
  const onUpdate = useCallback(
    (field, entity) => {
      onEntityUpdate(entity)
    },
    [onEntityUpdate],
  )

  const handleOnSave = () => {
    callbackOnSave()
    onSave()
  }

  const handleVenuePreferences = useCallback(() => {
    callbackOnVenuePreferences && callbackOnVenuePreferences()
    history.push(`/entity/employers/view/${employerID}/venues/view/${workLocationID}`)
  }, [callbackOnVenuePreferences, employerID, workLocationID])

  const venuePreferences = useMemo(
    () => <Button onClick={handleVenuePreferences}>Venue preferences</Button>,
    [handleVenuePreferences],
  )

  const notificationsNode = useMemo(
    () =>
      lastMessage ? (
        <NotifyBar severity="error">{lastMessage}</NotifyBar>
      ) : (
        <NotifyBar severity="warning">No preferences found</NotifyBar>
      ),
    [lastMessage],
  )

  return !lastMessage && Object.keys(sectionData).length ? (
    <RecordSection
      header={
        <RecordHeaderForm>
          <ButtonGroup>
            <LoadingIndicator loadingState={isBusy} />
            {jobID ? venuePreferences : null}

            <Button kind="success" onClick={handleOnSave} disabled={isBusy || isPristine}>
              Save
            </Button>
          </ButtonGroup>
        </RecordHeaderForm>
      }
      title={title}
      data={sectionData}
      structure={structure}
      update={onUpdate}
      noRowBorders={false}
    />
  ) : (
    <RecordSection
      title={title}
      header={jobID ? <RecordHeaderForm>{venuePreferences}</RecordHeaderForm> : null}
      notificationsNode={notificationsNode}
    />
  )
}

export default (props: Omit<OwnProps, 'render'>) => {
  const render = useCallback((renderProps: RenderProps) => <PreferencesListSection {...renderProps} />, [])
  return <PreferencesListWrapper {...props} render={render} />
}
