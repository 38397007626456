export const ADHOC_SHIFT_APPROVE_BEGIN = 'syftacp/ADHOC_SHIFT_APPROVE_BEGIN'
export const ADHOC_SHIFT_REJECT_BEGIN = 'syftacp/ADHOC_SHIFT_REJECT_BEGIN'
export const ADHOC_SHIFT_FETCH_BEGIN = 'syftacp/ADHOC_SHIFT_FETCH_BEGIN'

export const ADHOC_SHIFT_APPROVE_SUCCEEDED = 'syftacp/ADHOC_SHIFT_APPROVE_SUCCEEDED'
export const ADHOC_SHIFT_APPROVE_FAILED = 'syftacp/ADHOC_SHIFT_APPROVE_FAILED'
export const ADHOC_SHIFT_REJECT_SUCCEEDED = 'syftacp/ADHOC_SHIFT_REJECT_SUCCEEDED'
export const ADHOC_SHIFT_REJECT_FAILED = 'syftacp/ADHOC_SHIFT_REJECT_FAILED'
export const ADHOC_SHIFT_FETCH_SUCCEEDED = 'syftacp/ADHOC_SHIFT_FETCH_SUCCEEDED'
export const ADHOC_SHIFT_FETCH_FAILED = 'syftacp/ADHOC_SHIFT_FETCH_FAILED'
