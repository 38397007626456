import { countries } from 'country-data'
import { sortBy } from 'lodash-es'

import { filterSelect } from './FilterSelect'

// Display GB at the top of the list.
const priorityCountries = ['GB']
// Add Kosovo the list manually.
const extraCountries = ['XK']

// Returns list of countries to use in the select item. This function expects its only argument
// to be the list of countries, as imported from the 'country-data' NPM package.
// Note: returned value must be string, ISO, 2-letter, uppercase codes: "GB" | "FR" | etc.
// See <https://syftapp.atlassian.net/wiki/spaces/SV/pages/31002741/Admin+Edit+Worker+s+Profile+API>
// Note: we're adding Kosovo to the list manually because it's not assigned yet. See <CMS2-580>.
const getCountryItems = ({ all }, priority = priorityCountries, additions = extraCountries) => {
  // Limit countries to assigned types.
  const allAssigned = all.filter(c => c.status === 'assigned')
  const highPriority = allAssigned.filter(c => priority.indexOf(c.alpha2) > -1)
  const lowPriority = allAssigned.filter(c => priority.indexOf(c.alpha2) === -1)
  const extraCodes = all.filter(c => additions.indexOf(c.alpha2) > -1)

  // Sort the low priority countries and additions by label and alpha2 code.
  const list = [...highPriority, ...sortBy([...lowPriority, ...extraCodes], ['name'])]

  // Return only the alpha2 code and the name for the label.
  return list.map(c => ({ id: c.alpha2, label: c.name }))
}

export default filterSelect(getCountryItems(countries), 'Select a country', 'id', false)
