// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import * as types from './action-types'
import entityReducer from 'syft-acp-core/reducers/generators/entityReducer'
import * as entityTypes from 'syft-acp-core/reducers/generators/entities'

const stopLoading = resolved => (state, action) => {
  // Modify entityMap once per ID.
  // eslint-disable-next-line
  action.request.ids.map(id => (state.entityMap = { ...state.entityMap, [id]: { ...state.entityMap[id], resolved } }))
  state.isSavingData = false
  state.isLoadingData = false
}

const startResolving = state => {
  state.isSavingData = true
  state.isLoadingData = true
}

export default entityReducer('expiringDocuments', {
  [entityTypes.ENTITIES_FETCH_BEGIN]: types.EXPIRING_DOCUMENTS_FETCH_BEGIN,
  [entityTypes.ENTITIES_FETCH_SUCCEEDED]: types.EXPIRING_DOCUMENTS_FETCH_SUCCEEDED,
  [entityTypes.ENTITIES_FETCH_FAILED]: types.EXPIRING_DOCUMENTS_FETCH_FAILED,
  [types.EXPIRING_DOCUMENTS_RESOLVE_BEGIN]: startResolving,
  [types.EXPIRING_DOCUMENTS_RESOLVE_SUCCEEDED]: stopLoading(true),
  [types.EXPIRING_DOCUMENTS_RESOLVE_FAILED]: stopLoading(false),
})
