import React from 'react'
import PropTypes from 'prop-types'
import { workerStatuses } from 'syft-acp-core/data/workerStatus'
import { mapValues } from 'lodash-es'
import TypeLabels from './TypeLabels'

const WorkerLabels = ({ value, full }) => {
  const statuses = mapValues(workerStatuses, status => ({
    ...status,
    name: full ? `Worker ${status.name}` : status.name,
  }))
  return <TypeLabels value={value} typesMap={statuses} />
}

WorkerLabels.propTypes = {
  value: PropTypes.object,
  full: PropTypes.bool,
}

WorkerLabels.defaultProps = {
  value: {},
  full: false,
}

export default WorkerLabels
