import * as types from './action-types'

export const fetchCsatSkipped = (dates, type) => ({
  type: types.CSAT_SKIPPED_FETCH_BEGIN,
  payload: {
    dates,
    type,
  },
})

export const fetchCsatScore = (dates, type) => ({
  type: types.CSAT_SCORE_FETCH_BEGIN,
  payload: {
    type,
    dates,
  },
})

export const fetchCsatResults = (dates, type) => ({
  type: types.CSAT_RESULTS_FETCH_BEGIN,
  payload: {
    type,
    dates,
  },
})

export const fetchCsatCsvData = (date, type) => ({
  type: types.CSAT_FETCH_CSV_BEGIN,
  payload: { date, type },
})
