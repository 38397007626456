import { get } from 'lodash-es'
import entityReducer from './generators/entityReducer'
import {
  AGENCIES_LIST_CREATE_BEGIN,
  AGENCIES_LIST_CREATE_FAILED,
  AGENCIES_LIST_CREATE_SUCCEEDED,
  AGENCIES_LIST_FETCH_BEGIN,
  AGENCIES_LIST_FETCH_FAILED,
  AGENCIES_LIST_FETCH_SUCCEEDED,
} from '../actions/action-types'
import * as entityTypes from './generators/entities'

const createNewAgencySucceededState = (state, action) => {
  const id = get(action, 'payload.id')

  return {
    ...state,
    lastMessage: '',
    lastStatus: null,
    lastBody: null,
    entityMap: {
      [id]: action.payload,
      ...state.entityMap,
    },
    isLoadingData: false,
    isSavingData: false,
  }
}

export default entityReducer(
  'agenciesList',
  {
    [entityTypes.ENTITIES_FETCH_BEGIN]: AGENCIES_LIST_FETCH_BEGIN,
    [entityTypes.ENTITIES_FETCH_SUCCEEDED]: AGENCIES_LIST_FETCH_SUCCEEDED,
    [entityTypes.ENTITIES_FETCH_FAILED]: AGENCIES_LIST_FETCH_FAILED,
    [entityTypes.ENTITY_SAVE_BEGIN]: AGENCIES_LIST_CREATE_BEGIN,
    [AGENCIES_LIST_CREATE_SUCCEEDED]: createNewAgencySucceededState,
    [entityTypes.ENTITY_SAVE_FAILED]: AGENCIES_LIST_CREATE_FAILED,
  },
  {
    idKey: 'id',
    localEntityMap: true,
  }
)
