import React from 'react'
import { RouteComponentProps } from 'react-router-dom'

import SubNavigation from 'syft-acp-core/components/Navigation/SubNavigation'
import { PreferencesListPage } from 'syft-acp-core/components/ClientPreferences'
import SiteComponent from 'syft-acp-core/components/SiteComponent'

type RouteParams = {
  id?: string
}

const EmployerPreferencesView = (props: RouteComponentProps<RouteParams>) => (
  <div>
    <SubNavigation subPage="employers" data={{ employerID: Number(props.match.params?.id) }} />
    <SiteComponent>
      <PreferencesListPage
        title="Fulfilment preferences — head office"
        employerID={Number(props.match.params?.id)}
      />
    </SiteComponent>
  </div>
)

export default EmployerPreferencesView
