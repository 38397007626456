export const trackingEvents = {
  ACTIONS_BAN_WORKER: {
    CLICKED: 'worker_controls.actions_ban_worker.clicked',
    CONFIRMED: 'worker_controls.actions_ban_worker.confirmed',
  },
  ACTIONS_UNBAN_WORKER: {
    CLICKED: 'worker_controls.actions_unban_worker.clicked',
  },
  ACTIONS_DOWNLOAD_REFERENCE: {
    CLICKED: 'worker_controls.actions_download_reference.clicked',
  },
  FORM_SAVE: {
    CLICKED: 'worker_controls.form_save.clicked',
  },
  WORKER_USER_DELETE: {
    CLICKED: 'worker_controls.worker_user_delete.clicked',
  },
  WORKER_TYPE: {
    UPDATED: 'worker_controls.worker_type.updated',
  },
}
