import { apiRequest, handleCall } from 'syft-acp-core/api/call'

import { ApiResponse } from 'syft-acp-core/api/types'

import { ConductReviewEntity, ListConductReviewsInput, UpdateConductReviewInput } from './types'

/**
 * Retrieves a list of conduct reviews. Implements pagination.
 *
 * @param params {Object}
 * @param params.options {Object} Other query options
 */
export const listConductReviews = ({ payload }: ListConductReviewsInput) =>
  new Promise((resolve, reject) => {
    const req = apiRequest({ path: '/admin/conduct_reviews', reqArgs: payload.options })
    handleCall(req, resolve, reject, true, payload.options)
  })

export const fetchConductReview = ({ payload }: { payload: { id: number } }) =>
  new Promise<ApiResponse<ConductReviewEntity>>((resolve, reject) => {
    const req = apiRequest({ path: `/admin/conduct_reviews/${payload.id}` })
    handleCall(req, resolve, reject)
  })

/**
 * Assigns admin actor with 5 conduct reviews.
 */
export const assignConductReviews = () =>
  new Promise((resolve, reject) => {
    const req = apiRequest({ path: '/admin/conduct_reviews/assign', method: 'POST' })
    handleCall(req, resolve, reject, true)
  })

/**
 * Gets users that were assigned a conduct review in the past 3 months.
 */
export const fetchAssignees = () =>
  new Promise((resolve, reject) => {
    const req = apiRequest({ path: '/admin/conduct_reviews/assignees' })
    handleCall(req, resolve, reject, true)
  })

/**
 * Updates a conduct review.
 */
export const updateConductReview = ({ payload }: UpdateConductReviewInput) =>
  new Promise((resolve, reject) => {
    const req = apiRequest({
      path: `/admin/conduct_reviews/${payload.id}`,
      method: 'PATCH',
      reqArgs: payload.options,
    })
    handleCall(req, resolve, reject, true)
  })
