import { takeEvery, takeLatest, select, put } from 'redux-saga/effects'
import { takeLatestApiCall } from 'syft-acp-core/sagas/helpers'
import { notificationCall } from 'syft-acp-core/sagas/calls'
import { fetchShifts } from 'syft-acp-core/store/shifts/actions'
import { fetchShiftEvents, ShiftEventsAction } from 'syft-acp-core/store/shiftEvents/actions'

import { fetchFulfilmentShift, patchFulfilmentShift } from './api'
import * as types from './action-types'

const refetchShifts = function* (response: ShiftEventsAction) {
  // @ts-ignore
  const query = yield select(state => state?.routing?.locationBeforeTransitions?.query)
  yield put(fetchShifts({ options: query }))
  yield put(fetchShiftEvents({ id: response?.payload?.id }))
}

export default function* saga() {
  yield takeLatestApiCall(types.FULFILMENT_SHIFT_FETCH_BEGIN, fetchFulfilmentShift)
  yield takeLatestApiCall(types.FULFILMENT_SHIFT_EDIT_BEGIN, patchFulfilmentShift)
  yield takeLatest(types.FULFILMENT_SHIFT_EDIT_SUCCEEDED, refetchShifts)
  yield takeEvery([types.FULFILMENT_SHIFT_EDIT_FAILED, types.FULFILMENT_SHIFT_EDIT_SUCCEEDED], notificationCall)
}
