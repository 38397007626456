// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import './SiteComponent.css'

const SiteComponent = ({ className = '', noMargin = false, children }) => (
  <div className={classnames('site-component', className, { 'no-margin': noMargin })}>{children}</div>
)
SiteComponent.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  noMargin: PropTypes.bool,
}

export default SiteComponent
