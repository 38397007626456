import * as types from './action-types'

export const approveAdhocShift = (shiftBookingId: number) => ({
  type: types.ADHOC_SHIFT_APPROVE_BEGIN,
  payload: { adhoc_shift_booking_id: shiftBookingId },
})

export const rejectAdhocShift = (shiftBookingId: number) => ({
  type: types.ADHOC_SHIFT_REJECT_BEGIN,
  payload: { adhoc_shift_booking_id: shiftBookingId },
})

export const fetchAdhocShiftStatus = (shiftBookingId: number) => ({
  type: types.ADHOC_SHIFT_FETCH_BEGIN,
  payload: { adhoc_shift_booking_id: shiftBookingId },
})
