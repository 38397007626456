export const SHIFTS_FETCH_BEGIN = 'syftacp/SHIFTS_FETCH_BEGIN' as const
export const SHIFTS_FETCH_FAILED = 'syftacp/SHIFTS_FETCH_FAILED' as const
export const SHIFTS_FETCH_SUCCEEDED = 'syftacp/SHIFTS_FETCH_SUCCEEDED' as const

export const REMOVE_ADMIN_SHIFTS_SPOT_BEGIN = 'syftacp/REMOVE_ADMIN_SHIFTS_SPOT_BEGIN' as const
export const REMOVE_ADMIN_SHIFTS_SPOT_FAILED = 'syftacp/REMOVE_ADMIN_SHIFTS_SPOT_FAILED' as const
export const REMOVE_ADMIN_SHIFTS_SPOT_SUCCEEDED = 'syftacp/REMOVE_ADMIN_SHIFTS_SPOT_SUCCEEDED' as const

export const SHIFT_DELETE_BEGIN = 'syftacp/SHIFT_DELETE_BEGIN' as const
export const SHIFT_DELETE_FAILED = 'syftacp/SHIFT_DELETE_FAILED' as const
export const SHIFT_DELETE_SUCCEEDED = 'syftacp/SHIFT_DELETE_SUCCEEDED' as const
export const SHIFT_EDIT_FAILED = 'syftacp/SHIFT_EDIT_FAILED' as const
export const SHIFT_EDIT_SUCCEEDED = 'syftacp/SHIFT_EDIT_SUCCEEDED' as const
export const SHIFT_STOP_NEW_WORKERS_BEGIN = 'syftacp/SHIFT_STOP_NEW_WORKERS_BEGIN' as const
export const SHIFT_STOP_NEW_WORKERS_FAILED = 'syftacp/SHIFT_STOP_NEW_WORKERS_FAILED' as const
export const SHIFT_STOP_NEW_WORKERS_SUCCEEDED = 'syftacp/SHIFT_STOP_NEW_WORKERS_SUCCEEDED' as const

export const ADMIN_SHIFT_FETCH_BEGIN = 'syftacp/ADMIN_SHIFT_FETCH_BEGIN' as const
export const ADMIN_SHIFT_FETCH_FAILED = 'syftacp/ADMIN_SHIFT_FETCH_FAILED' as const
export const ADMIN_SHIFT_FETCH_SUCCEEDED = 'syftacp/ADMIN_SHIFT_FETCH_SUCCEEDED' as const

export const ADMIN_SHIFT_EDIT_BEGIN = 'syftacp/ADMIN_SHIFT_EDIT_BEGIN' as const
export const ADMIN_SHIFT_EDIT_FAILED = 'syftacp/ADMIN_SHIFT_EDIT_FAILED' as const
export const ADMIN_SHIFT_EDIT_SUCCEEDED = 'syftacp/ADMIN_SHIFT_EDIT_SUCCEEDED' as const
