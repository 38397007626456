import * as R from 'kefir.ramda'
import { noop, isFunction, get, merge } from 'lodash-es'

/** Quick check to see if something is lens-like or not. */
const isLens = obj => obj && obj.get && isFunction(obj.get)

/**
 * Checks whether any items are selected, and whether an item should be disabled
 * for lack of selection.
 */
export const checkSelection = (stateSelection, stateSubSelection, requiresSelection, requiresSubSelection) => {
  // Whether any selected items are present.
  const hasSelection = R.gt(R.length(stateSelection), 0)
  const hasSubSelection = R.gt(R.length(stateSubSelection), 0)
  // Whether this item is disabled, either for lack of a selection when it requires one,
  // or for lack of a subselection when it requires one.
  const disabledForSelection = R.and(requiresSelection, R.not(hasSelection))
  const disabledForSubSelection = R.and(requiresSubSelection, R.not(hasSubSelection))

  return {
    hasSelection,
    hasSubSelection,
    disabledForSelection,
    disabledForSubSelection,
  }
}

/**
 * Return the actual data for a specific subrow value.
 *
 * The return value is an object with one key (the requested item).
 * If the requested value refers to a nested object (e.g. "profile.picture.url"),
 * a nested object will be returned.
 */
const getValueDataLevel = (allValues, subData, valueItem) => {
  const valueSegments = valueItem.split('.')

  // For plain values, return early.
  if (valueSegments.length === 1) {
    return {
      [valueItem]: get(subData, valueItem, null),
    }
  }

  // If we do have multiple segments, generate an object.
  const nextItem = valueSegments.shift()
  const existingData = get(allValues, nextItem, {})
  const nextSubData = get(subData, nextItem, null)

  // Stop recursing if we don't have data for this section.
  if (!nextSubData) {
    return {
      [nextItem]: null,
    }
  }

  // Make a nested object containing data for each segment in the value.
  const nestedData = getValueDataLevel(existingData, nextSubData, valueSegments.join('.'))
  return {
    [nextItem]: {
      ...existingData,
      ...nestedData,
    },
  }
}

/**
 * Returns the correct subrow for taking data from.
 */
const selectSubRow = (subDataArr, sub, idKeySub = '#') => {
  // By default, we don't select subrows based on a data value,
  // but on their position in the array (unlike main row selections).
  if (idKeySub === '#') {
    return subDataArr[sub]
  }
  return subDataArr.find(n => n[idKeySub] === sub)
}

/**
 * Extracts the relevant data from a subrow.
 */
const getSubRowData = (rowData, sub, spec, idKeySub = '#') => {
  const { value } = spec

  // TODO: function values are not supported yet.
  if (isFunction(value)) {
    return null
  }

  // Grab the subdata array from the row and check if it's there.
  const subDataArr = rowData[spec._subRowValue]
  if (!Array.isArray(subDataArr)) {
    return null
  }

  const subData = selectSubRow(subDataArr, sub, idKeySub)
  if (!subData) {
    return null
  }

  // Ensure references to nested data are returned as nested objects.
  const valueArr = Array.isArray(value) ? value : [value]
  return valueArr.reduce(
    (allValues, valueItem) => ({
      ...allValues,
      ...getValueDataLevel(allValues, subData, valueItem),
    }),
    {}
  )
}

/**
 * Returns data from the table per selected subrows.
 *
 * This function takes the table's data and returns a subset of it based
 * on which subrows the user has selected. It uses the table spec to
 * determine which rows to return to the caller.
 *
 * This is used to determine the data given to callback functions for
 * table "action" components, such as a <Acp.Button /> in the table actions header.
 */
const getSubSelectionData = (tableData, selection, subDataSpec, idKeyMain = 'id', idKeySub = '#') =>
  selection.map(row => {
    const [main, sub] = row.split('.').map(n => Number(n))
    const rowData = tableData.find(n => n[idKeyMain] === main)
    return subDataSpec.reduce((data, specItem) => merge({}, data, getSubRowData(rowData, sub, specItem, idKeySub)), {})
  })

/**
 * Returns the current table data, either from a lens or directly if it's a plain object.
 */
const getCurrentTableData = tableDataLens => {
  return isLens(tableDataLens) ? tableDataLens.get() : tableDataLens
}

/**
 * Returns the current selection state, either from a lens or as an empty object
 * (if the table does not support selection).
 */
const getCurrentSelectionData = ({ stateSelectionLens, stateSubSelectionLens }) => {
  const rowSelection = isLens(stateSelectionLens) ? stateSelectionLens.get() : []
  const rowSubSelection = isLens(stateSubSelectionLens) ? stateSubSelectionLens.get() : []
  return {
    rowSelection,
    rowSubSelection,
  }
}

/**
 * Adds callback arguments and selection details to an onClick handler.
 */
export const decorateOnClick = (
  handler,
  el,
  callbackArgs,
  subDataSpec,
  tableDataLens,
  stateSelectionLens,
  stateSubSelectionLens
) => {
  // Take the onClick handler either directly from an element's props,
  // or from a function that was passed.
  const elOnClick = get(el, 'props.onClick', noop)
  const onClick = handler || elOnClick

  // When running the handler, retrieve the latest data.
  const onClickFn = () => {
    // Collect data to send to the handler.
    const tableData = getCurrentTableData(tableDataLens)
    const { rowSelection, rowSubSelection } = getCurrentSelectionData({ stateSelectionLens, stateSubSelectionLens })
    const rowSubSelectionData = getSubSelectionData(tableData, rowSubSelection, subDataSpec)

    return onClick({
      ...callbackArgs,
      tableData,
      rowSelection,
      rowSubSelection,
      rowSubSelectionData,
    })
  }
  return onClickFn
}

/**
 * Checks whether an action element should be disabled for lack of selection.
 */
export const checkDisabled = (disabled, disabledForSelection, disabledForSubSelection) =>
  R.or(R.or(disabledForSelection, disabledForSubSelection), disabled)
