import React from 'react'
import classnames from 'classnames'
import S from './DataTabs.module.scss'
import { Tab, Props } from './DataTabs.types'

export const DataTabs = ({ value, withoutTableMargin, children, attribute, tabId, callback }: Props) => {
  return (
    <>
      {value?.map((tab: Tab) => {
        return (
          <button
            key={tab.id}
            aria-label={`Select ${tab.name} ${attribute}`}
            onClick={() => callback(tab.id)}
            className={classnames(
              S['data_tab_container'],
              tab.id === tabId && S['data_tab_container_active'],
            )}
          >
            <div className={classnames(!withoutTableMargin && S['data_tab_container_counter'])}>
              {tab.workers_count === 1 ? 'Worker:' : 'Workers:'} {tab.workers_count}
            </div>
            <div className={classnames(tab.id === tabId && S['data_tab_container_bold'])}>
              {tab.name} ({tab.id})
            </div>
          </button>
        )
      })}
      {children}
    </>
  )
}

export default DataTabs
