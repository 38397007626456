/* eslint-disable react/prop-types */
// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { Grid, Row, Col } from 'react-bootstrap'
import { history } from 'syft-acp-core/history'
import { bindActionCreators } from 'redux'
import { get } from 'lodash-es'
import PropTypes from 'prop-types'
import { fetchEmployer } from 'syft-acp-core/store/employers/actions'

import { Button, ButtonGroup } from 'syft-acp-atoms/Button'
import { defaultWrapperPropTypes } from 'syft-acp-util/entityList'
import RecordSection from 'syft-acp-core/components/RecordSection'
import RecordControls from 'syft-acp-core/components/RecordSection/RecordControls'
import SiteComponent from 'syft-acp-core/components/SiteComponent'
import { EmployerDetailControlsLeftConnected } from './EmployerDetailControls'

const EmployerDetailWrapperOuter = ({ children, fullWidth }) =>
  fullWidth ? (
    <div>{children}</div>
  ) : (
    <Grid>
      <Row>{children}</Row>
    </Grid>
  )

EmployerDetailWrapperOuter.propTypes = {
  children: PropTypes.node.isRequired,
  fullWidth: PropTypes.bool,
}

EmployerDetailWrapperOuter.defaultProps = {
  fullWidth: false,
}

export class EmployerDetailWrapperBase extends PureComponent {
  componentDidMount() {
    if (!this.props.isLoadingData && !this.props.employerData) {
      this.props.actions.fetchEmployer(this.props.employerID)
    }
  }

  backAction = () => {
    history.push('/entity/employers/list')
  }

  getBio = data =>
    [data.contact_name, data.contact_mobile && `(Tel.: ${data.contact_mobile})`]
      .filter(n => n)
      .join(' ')
      .trim()

  render() {
    const {
      className,
      useFilterForm,
      filterForm,
      fullWidth,
      controlsLeft,
      employerData,
      controlsRight,
      hideHeader,
      wrappedContent,
      beforeWrappedContent,
    } = this.props
    const rightControls = useFilterForm ? filterForm : controlsRight
    return (
      <SiteComponent className={className}>
        <EmployerDetailWrapperOuter fullWidth={fullWidth}>
          <Col md={12}>
            <RecordControls>
              <div>
                <ButtonGroup>
                  <Button onClick={this.backAction}>Back to list</Button>
                </ButtonGroup>
                {/* To add extra buttons (e.g. save, delete), pass them as a component called */}
                {/* the 'controlsLeft' and 'controlsRight' props. */}
                {/* Inject our props into the controls React element. */}
                {controlsLeft && React.cloneElement(controlsLeft, { ...this.props })}
              </div>
              {/* Same for the controls on the right side. If using a generated filter form, */}
              {/* display that instead. */}
              {rightControls && React.cloneElement(rightControls, { ...this.props })}
            </RecordControls>
            {!hideHeader && (
              <RecordSection
                data={employerData}
                structure={[]}
                nameValue={get(employerData, 'company_name')}
                nameBio={employerData && this.getBio(employerData)}
                nameAvatar={get(employerData, 'picture') ? get(employerData, 'picture.uuid') : null}
                showName
              />
            )}
            {filterForm && !useFilterForm && React.cloneElement(filterForm, { ...this.props })}
          </Col>
          {beforeWrappedContent && React.cloneElement(beforeWrappedContent, { ...this.props })}
          {wrappedContent}
        </EmployerDetailWrapperOuter>
      </SiteComponent>
    )
  }
}
export const EmployerDetailWrapper = connect(
  (state, ownProps) => ({
    employerData: state.employers.entityDetail[ownProps.employerID],
    isLoadingData: state.employers.isLoadingData,
  }),
  dispatch => ({
    actions: bindActionCreators({ fetchEmployer }, dispatch),
  }),
)(EmployerDetailWrapperBase)

const EmployerDetailListWrapper = props => {
  return (
    <EmployerDetailWrapper
      {...{
        ...props,
        controlsLeft: <EmployerDetailControlsLeftConnected employerID={props.employerID} />,
        wrappedContent: (
          <span>
            {props.entityListResults}
            {props.entityList}
            {props.entityListPagination}
          </span>
        ),
      }}
    />
  )
}
EmployerDetailListWrapper.propTypes = {
  ...defaultWrapperPropTypes,
  controlsRight: PropTypes.element,
}

export default EmployerDetailListWrapper
