import * as types from './action-types'
import { CreateWorkerStrikesSucceededPayload, FetchWorkerStrikeReasonsSucceededPayload } from './types'

export const fetchWorkerStrikes = (workerID: number, options: Record<string, any>) => ({
  type: types.WORKER_STRIKES_FETCH_BEGIN,
  workerID,
  options,
})

export const fetchWorkerStrike = (workerID: number, strikeID: number, options: Record<string, any>) => ({
  type: types.WORKER_STRIKE_FETCH_BEGIN,
  workerID,
  strikeID,
  options,
})

export const updateWorkerStrike = (workerID: number, strikeID: number, data: Record<string, any>) => ({
  type: types.WORKER_STRIKE_UPDATE_BEGIN,
  workerID,
  strikeID,
  data,
})

export const createWorkerStrike = (workerID: number, data: Record<string, any>) => ({
  type: types.WORKER_STRIKE_CREATE_BEGIN,
  workerID,
  data,
})
export type CreateWorkerStrikeAction = ReturnType<typeof createWorkerStrike>

export const createWorkerStrikeSucceeded = (
  payload: CreateWorkerStrikesSucceededPayload,
  request: CreateWorkerStrikeAction
) => ({
  type: types.WORKER_STRIKE_CREATE_SUCCEEDED,
  request,
  payload,
})
export type CreateWorkerStrikeSucceededAction = ReturnType<typeof createWorkerStrikeSucceeded>

export const deleteWorkerStrike = (workerID: number, strikeID: number) => ({
  type: types.WORKER_STRIKE_DELETE_BEGIN,
  workerID,
  strikeID,
})
export type DeleteWorkerStrikeAction = ReturnType<typeof deleteWorkerStrike>

export const deleteWorkerStrikeSucceeded = (payload: Record<string, any>, request: DeleteWorkerStrikeAction) => ({
  type: types.WORKER_STRIKE_DELETE_BEGIN,
  request,
  payload,
})
export type DeleteWorkerStrikeSucceededAction = ReturnType<typeof deleteWorkerStrikeSucceeded>

export const fetchWorkerStrikeReasons = () => ({ type: types.WORKER_STRIKE_REASONS_FETCH_BEGIN })
export type FetchWorkerStrikeReasonsAction = ReturnType<typeof fetchWorkerStrikeReasons>

export const fetchWorkerStrikeReasonsSucceeded = (
  payload: FetchWorkerStrikeReasonsSucceededPayload,
  request: FetchWorkerStrikeReasonsAction
) => ({
  type: types.WORKER_STRIKE_REASONS_FETCH_SUCCEEDED,
  payload,
  request,
})
export type FetchWorkerStrikeReasonsSucceededAction = ReturnType<typeof fetchWorkerStrikeReasonsSucceeded>
