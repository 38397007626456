import { apiRequest, handleCall } from 'syft-acp-core/api/call'

export const fetchCities = () =>
  new Promise((resolve, reject) => {
    const req = apiRequest({
      path: '/cities',
      reqArgs: { only_exposed: false },
      utilisePagination: false,
    })
    handleCall(req, resolve, reject)
  })
