import * as types from './action-types'

export const fetchAdminManagers = ({ options }) => ({
  type: types.ADMIN_MANAGERS_FETCH_BEGIN,
  options,
})

export const fetchAdminManager = id => ({
  type: types.ADMIN_MANAGER_FETCH_BEGIN,
  id,
})

export const createAdminManager = payload => ({
  type: types.ADMIN_MANAGER_CREATE_BEGIN,
  payload,
})

export const updateAdminManager = (id, payload) => ({
  type: types.ADMIN_MANAGER_UPDATE_BEGIN,
  id,
  payload,
})

export const deleteAdminManager = (id, payload) => ({
  type: types.ADMIN_MANAGER_DELETE_BEGIN,
  id,
  payload,
})
