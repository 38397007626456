import { put, select } from 'redux-saga/effects'
import {
  ConsentChangeWorkerMarketingPreferencesAction,
  setPendingConsentWorkerMarketingPreferences,
  updateWorkerMarketingPreferences,
} from './actions'
import { showModal } from '../modals/actions'
import { selectWorkerMarketingPreferencesToUpdatePayload } from './selectors'
import { UpdateWorkerMarketingPreferencesPayload } from './types'

export function* handleConsentChange(action: ConsentChangeWorkerMarketingPreferencesAction) {
  const optin = action.payload.value

  yield put(setPendingConsentWorkerMarketingPreferences(action.payload))

  // Consent to new preference = show modal before update
  if (optin) {
    yield put(showModal('marketingPreferencesModal'))
    return
  }

  const updatePayload: UpdateWorkerMarketingPreferencesPayload = yield select(
    selectWorkerMarketingPreferencesToUpdatePayload,
    action.payload.worker_id,
  )
  yield put(updateWorkerMarketingPreferences(action.payload.worker_id, updatePayload))
}
