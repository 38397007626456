import React, { useCallback, Fragment } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { useTrackingTrigger, TrackingTrigger } from '@indeed/flex-tracking-context'

import { ButtonDropdown, MenuItem } from 'syft-acp-atoms/Button'
import AddNewAgencyModal from 'syft-acp-core/components/Modal/AddNewAgencyModal'
import * as modalActions from 'syft-acp-core/store/modals/actions'
import * as filterTypes from 'syft-acp-util/components/FilterForm/FilterForm'

import entityList from 'syft-acp-util/entityList'
import * as tableType from 'syft-acp-core/components/EntityList/'
import { FilterGroup, FilterText } from 'syft-acp-util/components/FilterForm'
import * as agenciesList from 'syft-acp-core/actions/agencies-list'
import EntityListComponent from 'syft-acp-core/components/EntityList/EntityListComponent'
import { useFlexFlagIsOn } from '@indeed/flex-feature-flags'

import { trackingEvents } from './AgenciesList.tracking'

import './AgenciesList.css'

const tableFormat = [
  { type: tableType.TXT, val: 'id', header: 'ID', numeric: true },
  {
    type: tableType.TXT,
    classes: ['nowrap'],
    val: 'name',
    header: 'Name',
  },
  {
    type: tableType.TXT,
    classes: ['nowrap'],
    val: 'email',
    header: 'Email',
  },
  {
    type: tableType.TXT,
    classes: ['nowrap'],
    val: 'telephone_number',
    header: 'Telephone number',
  },
  {
    type: tableType.TXT,
    classes: ['nowrap'],
    val: 'user_id',
    header: 'User ID',
  },
]

const filters = (
  <div>
    <FilterGroup title="Name/ID:">
      <FilterText name="query" placeholder="Name/ID" />
    </FilterGroup>
    <FilterGroup title="Email:">
      <FilterText name="email" placeholder="Email" />
    </FilterGroup>
    <FilterGroup title="Telephone number:">
      <FilterText name="telephone_number" placeholder="Telephone number" />
    </FilterGroup>
  </div>
)

const AgencyListing = ({ actions }) => {
  const trackingTrigger = useTrackingTrigger()
  const roleCompliancePage = useFlexFlagIsOn('agencies_role_compliance_page')

  const openModal = useCallback(() => {
    trackingTrigger(trackingEvents.ADD_AGENCY.CLICKED)
    actions.showModal('addNewAgencyModal')
  }, [actions, trackingTrigger])

  const handleConfirm = () => {
    trackingTrigger(trackingEvents.ADD_AGENCY_INVITE.CLICKED)
  }

  const actionFormat = [
    {
      type: filterTypes.TYPE_CUSTOM_BUTTON,
      customButton: () => (
        <ButtonDropdown title="Actions" pullRight>
          <MenuItem onSelect={openModal}>Add agency</MenuItem>
          <MenuItem>
            <Link to="/agencies/coverage-imports">Bulk coverage upload</Link>
          </MenuItem>
          {roleCompliancePage && (
            <MenuItem>
              <Link to="/agencies/role-compliance">Bulk Role & Compliance management</Link>
            </MenuItem>
          )}
        </ButtonDropdown>
      ),
    },
  ]

  const AgencyEntityList = entityList(
    EntityListComponent,
    tableFormat,
    filters,
    actionFormat,
    'agenciesList',
    {
      fetchEntities: actions.fetchAgenciesList,
    },
    {
      urlGenerator: rowData => `/agencies/view/${rowData.id}`,
      showResultCount: true,
    },
  )

  return (
    <Fragment>
      <AgencyEntityList />
      <AddNewAgencyModal onConfirm={handleConfirm} />
      <TrackingTrigger
        onLoad={{
          event: trackingEvents.PAGE.VIEW,
        }}
      />
    </Fragment>
  )
}

AgencyListing.propTypes = {
  actions: PropTypes.object.isRequired,
}

export default connect(
  state => ({
    isLoadingData: state.images.isLoadingData,
    errorMessage: state.images.message,
    lastUploadedImage: state.images.lastUploadedImage,
  }),
  dispatch => ({
    actions: bindActionCreators({ ...agenciesList, ...modalActions }, dispatch),
  }),
)(AgencyListing)
