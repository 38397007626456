import { createReducer } from '@reduxjs/toolkit'

import * as types from './action-types'

const initialState = {
  commitConfirmationHash: '',
  errors: [],
  isLoading: false,
  isConfirmation: false,
  changes: {
    venues: 0,
    added: 0,
    deleted: 0,
    unchanged: 0,
  },
}

const reducer = createReducer(initialState, builder =>
  builder.addDefaultCase((state, action) => {
    switch (action.type) {
      case types.AGENCY_COVERAGE_BULK_UPLOAD_CLEAR:
        return {
          ...state,
          errors: [],
          commitConfirmationHash: '',
          isLoading: false,
          isConfirmation: false,
          changes: {
            venues: 0,
            added: 0,
            deleted: 0,
            unchanged: 0,
          },
        }
      case types.AGENCY_COVERAGE_BULK_UPLOAD_SAVE_BEGIN:
        return {
          ...state,
          errors: [],
          commitConfirmationHash: action.commitConfirmationHash,
          isLoading: true,
          isConfirmation: action.isConfirmation,
        }
      case types.AGENCY_COVERAGE_BULK_UPLOAD_SAVE_SUCCEEDED:
        return {
          ...state,
          errors: [],
          commitConfirmationHash: action.payload.commit_confirmation_hash,
          changes: action.payload.changes,
          isLoading: false,
        }
      case types.AGENCY_COVERAGE_BULK_UPLOAD_SAVE_FAILED:
        return {
          ...state,
          errors: action.payload?.body?.errors || [],
          commitConfirmationHash: '',
          isLoading: false,
        }
      default:
        return state
    }
  })
)

export default reducer
