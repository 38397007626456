import React, { useEffect } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Grid, Col, Row } from 'react-bootstrap'
import { useTrackingTrigger } from '@indeed/flex-tracking-context'
import { useFlexFlagIsOn } from '@indeed/flex-feature-flags'

import entityDetail from 'syft-acp-util/entityDetail'
import SetFilter from 'syft-acp-core/components/SetFilter'
import SiteComponent from 'syft-acp-core/components/SiteComponent'
import RecordSection from 'syft-acp-core/components/RecordSection'
import EntityAlert from 'syft-acp-core/components/Alerts/EntityAlert'
import * as conductReviewsActions from 'syft-acp-core/store/conductReviews/actions'

import WorkerStrikesList from '../WorkerStrikes/WorkerStrikesList'
import ConductReviewDetailsControls from './ConductReviewDetailControls'
import { ConductReviewDetailProps as Props } from './ConductReviewDetail.types'
import { getDataStructure } from './ConductReviewDetail.constants'
import { trackingEvents } from './ConductReviewDetail.tracking'

export const storeConnector = connect(
  (state: any) => ({
    countryCode: state.auth?.userData?.country_code,
  }),
  dispatch => ({
    actions: bindActionCreators({ ...conductReviewsActions }, dispatch),
  }),
)

export const ConductReviewDetail = ({
  actions,
  actionUpdate,
  data,
  id,
  lastBody,
  isPristine,
  countryCode,
}: Props) => {
  const triggerEvent = useTrackingTrigger()
  const hideStrikesAddBans = useFlexFlagIsOn('pte_32272_hide_strikes_add_bans')

  const isClosed = isPristine && !!data.outcome

  useEffect(() => {
    actions.fetchConductReview(id)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    triggerEvent(trackingEvents.PAGE.VIEWED, { conduct_review_id: id })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const country = countryCode === 'GB' ? 'UK' : 'US'

  return (
    <>
      <SetFilter queryKey="exclude_never_activated" />
      <SetFilter queryKey="paginated" />
      <SiteComponent>
        <Grid>
          <Row>
            <Col md={12} sm={12}>
              <ConductReviewDetailsControls id={id} data={data} isPristine={isPristine} />
              <EntityAlert lastBody={lastBody} />
              {getDataStructure(isClosed, country, hideStrikesAddBans).map(({ title, structure }) => (
                <RecordSection
                  key={title}
                  title={title}
                  data={data}
                  structure={structure}
                  update={actionUpdate}
                />
              ))}
              {!hideStrikesAddBans && <WorkerStrikesList workerID={data.worker?.id} isConductReviewsView />}
            </Col>
          </Row>
        </Grid>
      </SiteComponent>
    </>
  )
}

export default storeConnector(entityDetail('conductReviews', ConductReviewDetail))
