import { createSelector } from 'reselect'
import { get, keyBy } from 'lodash-es'

const getAppCountryCode = state => get(state, 'app.countryCode')
const getCountriesData = state => keyBy(get(state, 'countries.entityMap'), 'country_code')

export const getAppCountryData = createSelector(
  [getAppCountryCode, getCountriesData],
  (appCountryCode, countriesData) => countriesData[appCountryCode]
)
