import React, { useEffect } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { history } from 'syft-acp-core/history'
import { flowRight } from 'lodash-es'
import deepEqual from 'react-fast-compare'
import { useTrackingTrigger } from '@indeed/flex-tracking-context'

import Acp from 'syft-acp-uikit'
import { createEntityListConnector } from 'syft-acp-util/entityList'
import { fetchCommissionsSales } from 'syft-acp-core/store/commissionsSales/actions'
import { getCities, getManagers } from 'syft-acp-core/selectors'
import usePrevValue from 'syft-acp-util/hooks/usePrevValue'

import { CommissionsSalesEntity } from 'syft-acp-core/store/commissionsSales/types'
import { COMMISSIONS_LIST_FILTER_CHANGED } from './CommissionsList.tracking'

type CommissionsListProps = ConnectedProps<typeof entityConnector> & ConnectedProps<typeof storeConnector>

export const entityConnector = createEntityListConnector<Partial<CommissionsSalesEntity>>({
  entityActions: { fetchEntities: fetchCommissionsSales },
  entityStore: 'commissionsSales',
})

export const storeConnector = connect(state => ({
  managers: getManagers(state.managers),
  cities: getCities(state.cities),
}))

const handleOnClick = ({ rowData }: { rowData: CommissionsSalesEntity }) => {
  const pathSuffix = rowData.work_location_id ? `work_location/${rowData.work_location_id}` : 'employer'
  const commissionPath = `/entity/employers/view/${rowData.employer_id}/commissions/view/${rowData.id}/${pathSuffix}`

  history.push(commissionPath)
}

const isRed = ({ has_commissions }: { has_commissions: boolean }) => has_commissions || 'red'

const CommissionsList = ({ actions, cities, managers, data, query, isLoadingData }: CommissionsListProps) => {
  const triggerEvent = useTrackingTrigger()
  const prevQuery = usePrevValue(query)

  const handleChangeFilter = (name: string, value: string | number) => {
    if (typeof value === 'string') {
      triggerEvent(COMMISSIONS_LIST_FILTER_CHANGED, { name, value })
    }
  }
  useEffect(() => {
    if (!deepEqual(prevQuery, query)) {
      actions.fetchEntities?.({ options: query })
    }
  }, [query, actions, prevQuery])

  return (
    <Acp.EntityList
      isLoading={isLoadingData}
      hasResultCount
      hasPagination
      idKeyValue="id"
      data={data}
      resArguments={query}
    >
      <Acp.Actions>
        <Acp.FilterGroup title="City">
          <Acp.Filter.Dropdown name="city_id" placeholder="Any" onChange={handleChangeFilter}>
            {cities.map(({ id, name }: { id: number; name: string }) => (
              <Acp.Option slug={id} key={`${name}-${id}`}>
                {name}
              </Acp.Option>
            ))}
          </Acp.Filter.Dropdown>
        </Acp.FilterGroup>
        <Acp.FilterGroup title="Venue">
          <Acp.Filter.Text name="work_location_name" placeholder="Venue name" onChange={handleChangeFilter} />
        </Acp.FilterGroup>
        <Acp.FilterGroup title="Manager">
          <Acp.Filter.Dropdown name="syft_manager_id" placeholder="Any" onChange={handleChangeFilter}>
            {managers.map(({ id, name }: { id: number; name: string }) => (
              <Acp.Option slug={id} key={`${name}-${id}`}>
                {name}
              </Acp.Option>
            ))}
          </Acp.Filter.Dropdown>
        </Acp.FilterGroup>
      </Acp.Actions>
      <Acp.Table rowColor={isRed}>
        <Acp.Col.Text value="employer_id" header="Client ID" headerTooltip="Client ID" />
        <Acp.Col.Text value="employer_name" header="Client" headerTooltip="Client" />
        <Acp.Col.Text value="venue_name" header="Venue" headerTooltip="Venue name" />
        <Acp.Col.Text value="area_name" header="Area" headerTooltip="Area name" />
        <Acp.Col.Text value="work_location_id" header="Location ID" headerTooltip="Location ID" />
        <Acp.Col.Text value="industry" header="Industry/Subindustry" headerTooltip="Industry/Subindustry" />
        <Acp.Col.Text value="manager_position" header="Manager Position" headerTooltip="Manager Position" />
        <Acp.Col.Text value="manager_name" header="Manager Name" headerTooltip="Manager Name" />
        <Acp.Col.Text value="sales_force_id" header="Salesforce ID" headerTooltip="Salesforce ID" />
        <Acp.Col.Button header="Action">
          <Acp.Button kind="regular" onClick={handleOnClick}>
            Edit
          </Acp.Button>
        </Acp.Col.Button>
      </Acp.Table>
    </Acp.EntityList>
  )
}

export default flowRight(entityConnector, storeConnector)(CommissionsList)
