import * as React from 'karet'
import * as L from 'partial.lenses'
import { get } from 'lodash-es'
import InputText from 'syft-acp-atoms/InputText'

import { cellPropTypes, cellDefaultProps, PropTypesAtom } from '../dataProps'
import { colEditablePlaceholderFactory } from '../../../placeholders/factory'

export const AcpEditableColText = colEditablePlaceholderFactory('AcpEditableCellText')

const AcpEditableCellText = ({ value, options }) => {
  const textPrefix = get(options, 'prefix', false)
  const textSuffix = get(options, 'suffix', false)
  const isNonWrapped = Boolean(get(options, 'isNonWrapped', false))
  const isMonospaced = Boolean(get(options, 'isMonospaced', false))
  return (
    <InputText
      isMonospaced={isMonospaced}
      isNonWrapped={isNonWrapped}
      textPrefix={textPrefix}
      textSuffix={textSuffix}
      isFullWidth
      value={value.view(L.valueOr(''))}
    />
  )
}

AcpEditableCellText.propTypes = {
  ...cellPropTypes,
  value: PropTypesAtom,
}

AcpEditableCellText.defaultProps = {
  ...cellDefaultProps,
}

AcpEditableCellText.cellProperties = ['form']

export default AcpEditableCellText
