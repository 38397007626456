import { apiRequest, handleCall } from 'syft-acp-core/api/call'

import { FetchCsatWorkersAction } from './actions'

export const fetchCsatWorkers = ({ options }: FetchCsatWorkersAction) =>
  new Promise((resolve, reject) => {
    const request = apiRequest({
      path: `/admin/surveys/workers/csats`,
      reqArgs: options,
    })
    handleCall(request, resolve, reject, true, options)
  })
