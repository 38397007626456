// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { InputGroup, FormControl } from 'react-bootstrap'

import { Button } from 'syft-acp-atoms/Button'

import DataArrayItem from './DataArrayItem'
import './DataArray.css'

class DataArray extends PureComponent {
  static propTypes = {
    value: PropTypes.arrayOf(PropTypes.string),
    header: PropTypes.string,
    editable: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    parameters: PropTypes.object,
  }

  static defaultProps = {
    value: [],
    header: '',
    editable: false,
    disabled: false,
    parameters: {},
  }

  constructor() {
    super()
    this.state = {
      currValue: '',
    }
  }

  addTag = () => {
    // Don't add a tag if it's an empty string.
    if (this.state.currValue === '') {
      return
    }
    this.props.onChange([...this.props.value, this.state.currValue])
    this.setState({ currValue: '' })
  }

  removeTag = n => {
    this.props.onChange(this.props.value.filter((_, idx) => idx !== n))
  }

  onKeyPress = ev => {
    // When pressing enter, add the currently typed value to the array. Except if it's an empty string.
    if (ev.key === 'Enter') {
      this.addTag()
    }
  }

  onLocalChange = ev => {
    let val = ev.target.value

    // Reject non-numeric input if this is a numeric field.
    if (this.props.parameters.numeric) {
      val = val.replace(/\D/g, '')
    }
    this.setState({ currValue: val })
  }

  render() {
    const { value, header, editable, disabled } = this.props
    const { currValue } = this.state

    // A list of the current values.
    const values = (
      <div className="existing-items">
        {(value || []).map((item, n) => (
          <DataArrayItem key={n} index={n} onDelete={this.removeTag}>
            {item}
          </DataArrayItem>
        ))}
      </div>
    )

    if (editable) {
      return (
        <div className="DataArray">
          <InputGroup>
            <FormControl
              className="boxed-input"
              type="text"
              onChange={this.onLocalChange}
              onKeyPress={this.onKeyPress}
              value={currValue}
              placeholder={header}
              disabled={disabled}
            />
            <InputGroup.Button>
              <Button onClick={this.addTag}>Add</Button>
            </InputGroup.Button>
          </InputGroup>
          {values}
        </div>
      )
    } else {
      return values
    }
  }
}

export default DataArray
