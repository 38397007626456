import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as filtersActions from 'syft-acp-core/store/filters/actions'
import { filterSelect } from './FilterSelect'
import { useTrackingTrigger } from '@indeed/flex-tracking-context'
import { trackingEvents } from '../../../core/entities/EmployerDetail/EmployerDetail.tracking'

export const PRIORITIES = [
  { id: '0', label: 'Tier 1' },
  { id: '1', label: 'Tier 2' },
  { id: '2', label: 'Tier 3' },
  { id: '3', label: 'Tier 4' },
  { id: '4', label: 'Tier 4 (CC only)' },
]

export const getPriorityLabel = tier => {
  const priority = PRIORITIES.find(p => p.id === tier?.toString())
  return priority ? priority.label : '–'
}

const FilterTiers = ({
  name,
  actions,
  disabled,
  small,
  tiny,
  value,
  allowAny,
  className,
  onChange,
  allowNameLabel,
}) => {
  const triggerEvent = useTrackingTrigger()

  const onFilterChange = val => {
    !!onChange && onChange(name, val)
    actions.setFilter(name, val, val)
    triggerEvent(trackingEvents.TIER_DROPDOWN.CLICKED, { tier: val })
  }
  const defaultOption = allowAny ? 'Any' : 'Select a tier'
  const FilterSelect = filterSelect(PRIORITIES, defaultOption, 'id', false)

  const additionalProps = allowNameLabel
    ? {
        ariaLabel: name,
      }
    : {}
  return (
    <FilterSelect
      small={small}
      tiny={tiny}
      name={name}
      onChange={onFilterChange}
      value={value}
      allowAny={allowAny}
      disabled={disabled}
      className={className}
      {...additionalProps}
    />
  )
}

FilterTiers.propTypes = {
  name: PropTypes.string.isRequired,
  actions: PropTypes.objectOf(PropTypes.func).isRequired,
  disabled: PropTypes.bool,
  small: PropTypes.bool,
  tiny: PropTypes.bool,
  className: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  allowAny: PropTypes.bool,
  onChange: PropTypes.func,
}

FilterTiers.defaultProps = {
  disabled: false,
  small: false,
  tiny: false,
  value: null,
  className: '',
  allowAny: false,
  allowNameLabel: false,
}

export default connect(
  (state, { value }) => ({
    value: value?.toString() || state.routing.locationBeforeTransitions.query.tier,
  }),
  (dispatch, { actions }) => {
    const boundActions = bindActionCreators(
      {
        ...filtersActions,
      },
      dispatch,
    )

    return {
      actions: {
        ...boundActions,
        ...actions,
      },
    }
  },
)(FilterTiers)
