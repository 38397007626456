export const trackingEvents = {
  AGENCY_SHIFTS: {
    PAGE: {
      LOADED: 'agency_shifts.page.loaded',
    },
    SHIFT: {
      SELECTED: 'agency_shifts.shift.selected',
    },
    FILTERS: {
      CHANGED: 'agency_shifts.filters.changed',
    },
    ACTION: {
      CLICKED: 'agency_shifts.action.clicked',
    },
  },
}
