import { useFlexFlagIsOn } from '@indeed/flex-feature-flags'
import React from 'react'

export const withFlexFlagValueHoc = (Component, flagName) => {
  return props => {
    const flagIsOn = useFlexFlagIsOn(flagName)

    return (
      <Component
        {...{
          [flagName]: flagIsOn,
        }}
        {...props}
      />
    )
  }
}
