// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import { formatDatetime } from 'syft-acp-util/formatting'
import * as tableType from 'syft-acp-core/components/EntityList/'
import * as workersActions from 'syft-acp-core/store/workers/actions'
import entityDetailList from 'syft-acp-core/components/EntityDetail/entityDetailList'
import entityList from 'syft-acp-util/entityList'

const tableFormat = [
  {
    type: tableType.TXT,
    header: 'Shift ID',
    val: 'shift_id',
    classes: ['nowrap'],
    numeric: true,
  },
  {
    type: tableType.TXT,
    header: 'Job ID',
    val: 'job_id',
    classes: ['nowrap'],
    numeric: true,
  },
  {
    type: tableType.USER_LINE,
    header: 'Employer',
    classes: ['main'],
  },
  {
    type: tableType.TXT,
    header: 'Job role',
    classes: ['nowrap'],
    val: e => e.role_name,
  },
  {
    type: tableType.TXT,
    header: 'Completed at',
    classes: ['nowrap'],
    val: e => e.completed_at && formatDatetime(e.completed_at, e?.venue?.timezone),
  },
  {
    type: tableType.BOOL,
    header: 'A',
    expl: 'Approved',
    abbr: true,
    classes: ['nowrap'],
    val: 'approved',
  },
  {
    type: tableType.BOOL,
    header: 'N',
    expl: 'No show',
    abbr: true,
    classes: ['nowrap'],
    val: 'no_show',
  },
  {
    type: tableType.RATING,
    header: 'Rating',
    classes: ['nowrap'],
    val: 'rating.stars',
  },
  {
    type: tableType.BOOL,
    header: 'C',
    expl: 'Rating comment',
    abbr: true,
    classes: ['nowrap'],
    val: 'rating.comments',
    valTooltip: e => ({ value: e.rating.comments, id: `i${e.shift_id}` }),
  },
]

const CompletedShiftsWrapper = entityDetailList({ title: 'Completed shifts', withPagination: true })

export default entityList(
  CompletedShiftsWrapper,
  tableFormat,
  null,
  null,
  'workerCompletedShifts',
  {
    fetchEntities: ({ options }, { workerID }) => workersActions.fetchCompletedShifts(workerID, options),
  },
  {
    recordList: true,
    disableAutoScroll: true,
    paginationKey: 'shiftsPage',
  },
)
