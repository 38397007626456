import { createSelector } from 'reselect'
import { Store } from 'syft-acp-core/store'
import { GenericState } from 'syft-acp-core/reducers/generators/entityReducer'
import { entitySetName } from 'syft-acp-core/reducers/generators/utils'

import { WorkerReferralClaimsEntity, RoleInClaim } from './types'

const selectWorkerReferralClaims = (state: Store) => state.workerReferralClaims
const selectWorkerId = (state: Store, workerId: number) => workerId
const selectRole = (role: RoleInClaim) =>
  ({
    claimant: 'Claimant',
    referrer: 'Referrer',
  }[role])

const createLabel = (claim: WorkerReferralClaimsEntity) => {
  const lastName = claim.role === 'referrer' ? `${claim.claimant_last_name} | ` : ''
  return `${selectRole(claim.role)} | ${lastName}${claim.code}`
}

export const selectWorkerReferralClaimOptions = createSelector(
  [selectWorkerReferralClaims, selectWorkerId],
  (workerReferralClaims: GenericState<WorkerReferralClaimsEntity>, workerId: number) => {
    const setKey = entitySetName({ workerId })
    const entitySet = workerReferralClaims?.entitySets[setKey]
    return entitySet
      ? entitySet.ids.map(id => ({
          id: entitySet.entityMap[id].id,
          label: createLabel(entitySet.entityMap[id]),
        }))
      : []
  },
)
