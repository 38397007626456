// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import React from 'react'
import { Tabs } from 'react-bootstrap'
import classnames from 'classnames'

import { tabsProps } from './structure'
import './EntitySubTabs.css'

const EntityTabs = props => {
  const { className, noBorder, ...attrs } = props
  return (
    <Tabs
      {...attrs}
      className={classnames('entity-list-subtabs', className, {
        'no-border': noBorder,
      })}
    >
      {props.children}
    </Tabs>
  )
}

EntityTabs.propTypes = tabsProps

export default EntityTabs
