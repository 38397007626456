// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import { takeLatest } from 'redux-saga/effects'
import * as types from 'syft-acp-core/actions/action-types'
import { notificationCall } from './calls'

export default function* root() {
  yield takeLatest([types.JOB_RATE_WORKER_SUCCEEDED, types.JOB_RATE_WORKER_FAILED], notificationCall)
}
