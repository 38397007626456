// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import React, { useCallback } from 'react'
import { Checkbox, Form, FormGroup, Radio } from 'react-bootstrap'
import { Props } from './DataMultiAssociativeEnum.types'

import '../DataEnum.scss'
import './DataMultiAssociativeEnum.scss'

const DataMultiAssociativeEnum = ({ value, options, onChange, editable, disabled }: Props) => {
  const onOptionChange = useCallback(
    (key: string, parent?: string) => {
      const newSelection = value?.key !== (parent || key)
      const stateKey = parent ? parent : key
      const childStateValue = newSelection || !value?.value?.includes(key) ? [key] : []
      const stateValue = !parent ? [] : childStateValue

      onChange({
        key: stateKey,
        value: stateValue,
      })
    },
    [value, onChange]
  )

  return (
    <FormGroup className="DataEnum DataMultiAssociativeEnum">
      {options.map(({ value: optionValue, label, child }, idx) => {
        const parentSelected = value?.key === optionValue
        return (
          <React.Fragment key={optionValue}>
            <Form componentClass="fieldset" disabled={!editable || disabled} className="DataMultiAssociativeEnum__row">
              <div className="DataMultiAssociativeEnum__column">
                <Radio
                  checked={parentSelected}
                  onChange={() => {
                    onOptionChange(optionValue)
                  }}
                >
                  {label || optionValue}
                </Radio>
              </div>
              {child && child.length ? (
                <Form componentClass="fieldset" disabled={!parentSelected} className="DataMultiAssociativeEnum__column">
                  {child.map(({ value: childValue, label: childLabel }) => {
                    const Cmp = child.length > 1 ? Radio : Checkbox
                    return (
                      <Cmp
                        disabled={!editable || disabled}
                        checked={value?.value?.includes(childValue)}
                        key={childValue}
                        onChange={() => onOptionChange(childValue, optionValue)}
                      >
                        {childLabel || childValue}
                      </Cmp>
                    )
                  })}
                </Form>
              ) : null}
            </Form>
            {idx < options.length - 1 ? <hr className="DataMultiAssociativeEnum__divider" /> : null}
          </React.Fragment>
        )
      })}
    </FormGroup>
  )
}

export default DataMultiAssociativeEnum
