// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import { reducer as notifications } from 'react-notification-system-redux'
import { connectRouter } from 'connected-react-router'
import { history } from '../history'
import accessRequestData from './access-request-data'
import agencies from './agencies'
import agenciesList from './agencies-list'
import agencyAccounts from './agency-accounts'
import agencyAccountPermissions from './agency-account-permissions'
import agencyPlatform from './agency-platform'
import agencyProfile from './agency-profile'
import app from './app'
import auth from './auth'
import bankHolidayRegions from './bankHolidayRegions'
import cities from './cities'
import clientApplications from './client-applications'
import commissions from './commissions'
import conversationMessages from './conversation-messages'
import conversations from './conversations'
import countries from './countries'
import csat from './csat'
import deletedRTWDocuments from './worker-deleted-rtw-documents'
import employerAccounts from './employer-accounts'
import employerBlocklists from './employer-blocklists'
import employerHistory from './employer-history'
import employerNetworks from './employer-networks'
import employerRatings from './employer-ratings'
import employerTemplates from './employer-templates'
import importLogEvents from './import-log-events'
import employerSalesForce from './employer-salesforce'
import employerToken from './employer-token'
import employerVenueSalesForce from './employer-venue-salesforce'
import employerVenuesFiltered from './employer-venues-filtered'
import employerVenueNames from './employer-venue-names'
import errors from './errors'
import globalShiftBookings from './global-shift-bookings'
import healthQuestionnaires from './health-questionnaires'
import images from './images'
import industries from './industries'
import listingAgencyShiftBookings from './listing-agency-shift-bookings'
import listingAppliedWorkers from './listing-applied-workers'
import listingOfferedWorkers from './listing-offered-workers'
import listingRatings from './listing-ratings'
import listingShiftBookings from './listing-shift-bookings'
import localstorage from './localstorage'
import managers from './managers'
import noShowReasons from './no-show-reasons'
import oauthData from './oauthData'
import overpayments from './overpayments'
import payments from './payments'
import referralClaims from './referral-claims'
import referralCodes from './referral-codes'
import settings from './settings'
import stats from './stats'
import strikes from './strikes'
import timeDependentRates from './timeDependentRates'
import userSettings from './user-settings'
import workerCompletedShifts from './worker-completed-shifts'
import workerDocuments from './worker-documents'
import workerDocumentTypes from './worker-document-types'
import workerFeedback from './worker-feedback'
import workerNetworks from './worker-networks'
import workersTransfers from './worker-transfers'
import workerUpcomingJobs from './worker-upcoming-jobs'
import workerCsvImport from './worker-csv-import'
import workerLeaveRequests from './worker-leave-requests'

// ducks
// TODO move this index file to /store when all reducers moved to /store folder
import jobs from '../store/jobs/reducer'
import checkboxes from '../store/checkboxes/reducer'
import shifts from '../store/shifts/reducer'
import spotlightSearch from '../store/spotlight-search/reducer'
import shiftEvents from '../store/shiftEvents/reducer'
import shiftBookings from '../store/shift-bookings/reducer'
import employers from '../store/employers/reducer'
import employerVenues from '../store/employer-venues/reducer'
import newEmployerRateCards from '../store/employerRateCards/reducer'
import skills from '../store/skills/reducer'
import roles from '../store/roles/reducer'
import expiringDocuments from '../store/expiringDocuments/reducer'
import externalEmployers from '../store/externalEmployers/reducer'
import externalWorkLocations from '../store/externalWorkLocations/reducer'
import agencyBulkUpload from '../store/agency-bulk-upload/reducer'
import agencyShifts from '../store/agency-shifts/reducer'
import agencyShiftHistory from '../store/agency-shift-history/reducer'
import agencyVenues from '../store/agency-venues/reducer'
import agencyUnfilledAllocations from '../store/agency-unfilled-allocations/reducer'
import dataTabs from '../store/data-tabs/reducer'
import modals from '../store/modals/reducer'
import workerHolidayPay from '../store/worker-holiday-pay/reducer'
import workerMissingPay from '../store/worker-missing-pay/reducer'
import workerReferralBonuses from '../store/workerReferralBonuses/reducer'
import workers from '../store/workers/reducer'
import workerShifts from '../store/workerShifts/reducer'
import workerStrikes from '../store/workerStrikes/reducer'
import workerExpenses from '../store/workerExpenses/reducer'
import compliancePolicies from '../store/compliancePolicies/reducer'
import externalPlatforms from '../store/external-platforms/reducer'
import listings from '../store/listings/reducer'
import listingShifts from '../store/listing-shifts/reducer'
import employerPreferences from '../store/employerPreferences/reducer'
import alerts from '../store/alerts/reducer'
import csatAgencies from '../store/csatAgencies/reducer'
import csatEmployers from '../store/csatEmployers/reducer'
import csatWorkers from '../store/csatWorkers/reducer'
import users from '../store/users/reducer'
import autoOfferRates from '../store/autoOfferRates/reducer'
import managerCommissions from '../store/managerCommissions/reducer'
import matchingExplainability from '../store/matching-explainability/reducer'
import venueCategories from '../store/venueCategories/reducer'
import watchlistCompliance from '../store/watchlistCompliance/reducer'
import commissionsSales from '../store/commissionsSales/reducer'
import importFile from '../store/import-file/reducer'
import fulfilmentShifts from '../store/fulfilmentShifts/reducer'
import conductReviews from '../store/conductReviews/reducer'
import appeals from '../store/appeals/reducer'
import employerShiftRateTypes from '../store/employerShiftRateTypes/reducer'
import timeOffRequests from '../store/timeOffRequests/reducer'
import workerReferralClaims from '../store/workerReferralClaims/reducer'
import workerMarketingPreferences from '../store/workerMarketingPreferences/reducer'
import workerDuplicateAccountsCount from '../store/worker-duplicate-accounts-count/reducer'
import workerTier from '../store/workerTier/reducer'
import dismissibleAlerts from '../store/dismissible-alerts/reducer'
import adhocShiftsApproval from '../store/adhocShiftBookings/reducer'
import clientDeliveryTeams from '../store/delivery-teams/reducer'
// TODO probably needs updating TS, it false positive since we use GenericState below
// eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
import type { GenericState } from './generators/entityReducer'
import { DefaultRootState } from 'react-redux'
import { combineReducers } from 'redux'
import { createRoutingReducer } from 'syft-acp-core/reducers/routing'

const router = connectRouter(history)

const appReducers = combineReducers({
  routing: createRoutingReducer(router),
  router,
  accessRequestData,
  adhocShiftsApproval,
  agencies,
  agenciesList,
  agencyAccounts,
  agencyAccountPermissions,
  agencyBulkUpload,
  agencyShiftHistory,
  agencyShifts,
  agencyPlatform,
  agencyProfile,
  agencyVenues,
  agencyUnfilledAllocations,
  alerts,
  app,
  auth,
  autoOfferRates,
  bankHolidayRegions,
  checkboxes,
  cities,
  clientApplications,
  clientDeliveryTeams,
  commissions,
  commissionsSales,
  conversationMessages,
  conversations,
  countries,
  csat,
  csatWorkers,
  csatAgencies,
  csatEmployers,
  dataTabs,
  deletedRTWDocuments,
  employerAccounts,
  employerBlocklists,
  employerHistory,
  employerNetworks,
  employerRatings,
  employers,
  employerSalesForce,
  employerToken,
  employerVenues,
  newEmployerRateCards,
  employerShiftRateTypes,
  employerVenueSalesForce,
  employerVenuesFiltered,
  employerVenueNames,
  employerTemplates,
  employerPreferences,
  errors,
  expiringDocuments,
  externalPlatforms,
  externalEmployers,
  externalWorkLocations,
  fulfilmentShifts,
  globalShiftBookings,
  healthQuestionnaires,
  images,
  industries,
  jobs,
  listingAgencyShiftBookings,
  listingAppliedWorkers,
  listingOfferedWorkers,
  listingRatings,
  listings,
  listingShiftBookings,
  listingShifts,
  localstorage,
  managers,
  managerCommissions,
  matchingExplainability,
  modals,
  noShowReasons,
  notifications,
  importFile,
  importLogEvents,
  oauthData,
  overpayments,
  payments,
  referralClaims,
  referralCodes,
  roles,
  settings,
  shiftBookings,
  shifts,
  shiftEvents,
  skills,
  spotlightSearch,
  stats,
  strikes,
  timeDependentRates,
  users,
  userSettings,
  venueCategories,
  workerCompletedShifts,
  workerHolidayPay,
  workerMissingPay,
  workerReferralBonuses,
  workerDocuments,
  workerDocumentTypes,
  workerDuplicateAccountsCount,
  workerExpenses,
  workerFeedback,
  workerNetworks,
  workers,
  workerLeaveRequests,
  workerTier,
  workersTransfers,
  workerShifts,
  workerStrikes,
  workerUpcomingJobs,
  workerCsvImport,
  workerReferralClaims,
  workerMarketingPreferences,
  watchlistCompliance,
  compliancePolicies,
  conductReviews,
  appeals,
  timeOffRequests,
  dismissibleAlerts,
})

type CombinedReducers = ReturnType<typeof appReducers>

export type RootState = {
  [Property in keyof CombinedReducers as CombinedReducers[Property] extends never
    ? never
    : Property]: CombinedReducers[Property]
}

export type EntitiesState = {
  [Key in keyof DefaultRootState as DefaultRootState[Key] extends GenericState
    ? Key
    : never]: DefaultRootState[Key]
}

export default appReducers
