import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { isArray, get } from 'lodash-es'

import CellTag from '../CellTag'
import { cellPropTypes, cellDefaultProps } from '../dataProps'
import { colPlaceholderFactory } from '../../../placeholders/factory'

import './AcpCellEnum.css'

// Column placeholder component.
export const AcpColEnum = colPlaceholderFactory('AcpCellEnum', ['AcpEnum'])

/** Single enum item. */
const AcpCellEnumTag = ({ value, slug, color, unknown = false }) => (
  <CellTag value={value} slug={slug} color={color} unknown={unknown} />
)

/** Single enum item. */
const AcpCellEnumText = ({ value, slug, unknown = false }) => (
  <span className={classNames('AcpCellEnumItem', 'type-text', `slug-${slug}`, { unknown })}>{value || '–'}</span>
)

/**
 * Enum type that displays one or multiple of a set of predefined values.
 * Can be passed a string or an array of strings as its value.
 *
 *    <Acp.Col type={ tableType.ENUM } value="logged_by" header="Logged by">
 *      <Acp.Enum slug="admin">Admin</Acp.Enum>
 *      <Acp.Enum slug="client">Client</Acp.Enum>
 *    </Acp.Col>
 */
export default class AcpCellEnum extends React.PureComponent {
  static propTypes = {
    ...cellPropTypes,
    // The value is expected to be a string or number, or an array thereof.
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
    ]),
    // The enum options are passed as an array of objects:
    // [{ slug: 'admin', children: 'Admin', color: '#00ff00' }]
    spec: PropTypes.shape({
      _nested: PropTypes.arrayOf(
        PropTypes.shape({
          slug: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
          children: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,
          color: PropTypes.string,
        })
      ).isRequired,
    }).isRequired,
    options: PropTypes.shape({
      style: PropTypes.oneOf(['tag', 'text']),
      displayUnknownValue: PropTypes.bool,
    }).isRequired,
  }

  static defaultProps = {
    ...cellDefaultProps,
  }

  getEnumComponent = options => {
    switch (options.style) {
      case 'tag':
        return AcpCellEnumTag
      case 'text':
        return AcpCellEnumText
      default:
        return AcpCellEnumTag
    }
  }

  render() {
    const { value, spec, options } = this.props

    // Index the enums by their slug value.
    const enumOptions = spec._nested.reduce((acc, e) => ({ ...acc, [e.slug]: e }), {})
    // Filter out all values that don't exist as options, then replace the values with those options.
    const values = (isArray(value) ? value : [value]).filter(v => enumOptions[v]).map(v => enumOptions[v])
    // If we are left with nothing, and the "display unknown value" setting isn't false, display the plain value.
    const displayPlain = values.length === 0 && get(options, 'displayUnknownValue', true) === true

    // Determine the type of enum component to display.
    const EnumComponent = this.getEnumComponent(options)
    const isNullValue = value == null

    return (
      <>
        {isNullValue && '–'}
        {!isNullValue && displayPlain && <EnumComponent value={value} slug={value} unknown />}
        {!isNullValue &&
          !displayPlain &&
          values.map(v => (
            <EnumComponent key={v.slug} slug={v.slug} value={v.value ? v.value : v.children} color={v.color} />
          ))}
      </>
    )
  }
}
