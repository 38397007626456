import { apiRequest, handleCall } from 'syft-acp-core/api/call'
import { downloadAsFileFromApi } from 'syft-acp-core/api/file'

import { AutoOfferRateOptionType } from './actions'

const getReqArgs = (options: AutoOfferRateOptionType['options']) => ({
  offset: 0,
  page: 1,
  per_page: 25,
  ...options,
})

export const fetchAutoOfferRates = ({ options }: AutoOfferRateOptionType) =>
  new Promise((resolve, reject) => {
    const request = apiRequest({
      path: '/admin/automatic_offer_rates',
      reqArgs: getReqArgs(options),
    })
    handleCall(request, resolve, reject, true, options)
  })

export const fetchAutoOfferRateCSV = ({ options }: AutoOfferRateOptionType) => {
  const reqArgs = getReqArgs(options)
  return downloadAsFileFromApi('/admin/automatic_offer_rates.csv', reqArgs, 'fetchAutoOfferRateCSV')
}
