// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited
import { get } from 'lodash-es'
import entityReducer from './generators/entityReducer'
import { entitySaveFailed } from 'syft-acp-core/reducers/generators/common-reducers/entity-save-failed'
import * as types from '../actions/action-types'
import * as entityTypes from './generators/entities'

export const inviteFailed = (state, action) => {
  return {
    ...entitySaveFailed(state, action),
    skipLocalDataUpdating: true,
  }
}

export const saveFailed = (state, action) => {
  const updatedData = get(action, 'request.data', {})
  const entityDetail = updatedData.id
    ? { ...state.entityDetail, [updatedData.id]: updatedData }
    : { ...state.entityDetail }

  return {
    ...entitySaveFailed(state, action),
    entityDetail,
    skipLocalDataUpdating: false,
  }
}

export const loginSuccess = (state, action) => {
  const { payload = {} } = action
  return {
    ...state,
    isLoadingData: false,
    impersonationRedirectUrl: payload.url,
  }
}

export const loginBegin = state => {
  return {
    ...state,
    isLoadingData: true,
    impersonationRedirectUrl: null,
  }
}

export default entityReducer(
  'employerAccounts',
  {
    [entityTypes.ENTITIES_FETCH_BEGIN]: types.EMPLOYER_ACCOUNTS_FETCH_BEGIN,
    [entityTypes.ENTITIES_FETCH_SUCCEEDED]: types.EMPLOYER_ACCOUNTS_FETCH_SUCCEEDED,
    [entityTypes.ENTITIES_FETCH_FAILED]: types.EMPLOYER_ACCOUNTS_FETCH_FAILED,
    [entityTypes.ENTITY_FETCH_BEGIN]: types.EMPLOYER_ACCOUNT_FETCH_BEGIN,
    [entityTypes.ENTITY_FETCH_SUCCEEDED]: types.EMPLOYER_ACCOUNT_FETCH_SUCCEEDED,
    [entityTypes.ENTITY_FETCH_FAILED]: types.EMPLOYER_ACCOUNT_FETCH_FAILED,
    [entityTypes.ENTITY_SAVE_BEGIN]: [
      types.EMPLOYER_ACCOUNT_SAVE_BEGIN,
      types.EMPLOYER_ACCOUNT_DESTROY_PERMISSIONS_BEGIN,
      types.EMPLOYER_ACCOUNT_INVITE_BEGIN,
    ],
    [entityTypes.ENTITY_SAVE_SUCCEEDED]: [
      types.EMPLOYER_ACCOUNT_SAVE_SUCCEEDED,
      types.EMPLOYER_ACCOUNT_DESTROY_PERMISSIONS_SUCCEEDED,
      types.EMPLOYER_ACCOUNT_INVITE_SUCCEEDED,
    ],
    [entityTypes.ENTITY_SAVE_FAILED]: [types.EMPLOYER_ACCOUNT_DESTROY_PERMISSIONS_FAILED],
    [types.EMPLOYER_ACCOUNT_SAVE_FAILED]: saveFailed,
    [types.EMPLOYER_ACCOUNT_INVITE_FAILED]: inviteFailed,
    [types.EMPLOYER_ACCOUNT_LOGIN_SUCCEEDED]: loginSuccess,
    [types.EMPLOYER_ACCOUNT_LOGIN_BEGIN]: loginBegin,
  },
  {
    idKey: 'id',
    localEntityMap: false,
  },
)
