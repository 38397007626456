import { get, isNumber } from 'lodash-es'
import octicons from 'octicons'
import PropTypes from 'prop-types'
import React, { useMemo, useState } from 'react'
import { connect } from 'react-redux'
import ReactTooltip from 'react-tooltip'

import { ButtonIcon } from 'syft-acp-atoms/Button'
import { EntityAlertConnected } from 'syft-acp-core/components/Alerts/EntityAlert'
import entityDetailList from 'syft-acp-core/components/EntityDetail/entityDetailList'
import * as tableType from 'syft-acp-core/components/EntityList/'
import AddFlexCompReasonModal from 'syft-acp-core/components/Modal/AddFlexCompReasonModal'
import AddNoShowReasonModal from 'syft-acp-core/components/Modal/AddNoShowReasonModal'
import EndJobAssignmentModal from 'syft-acp-core/components/Modal/EndJobAssignmentModal'
import ListingShiftBreakDetailsModal from 'syft-acp-core/components/Modal/ListingShiftBreakDetailsModal'
import { entityList$ } from 'syft-acp-core/reducers/generators/entities'
import { isDraft } from 'syft-acp-core/sagas/calls/bookingCalls'
import { makePropertyCheck } from 'syft-acp-core/store/filters/helpers'
import { fetchAdminBookings } from 'syft-acp-core/store/listings/actions'
import * as filterTypes from 'syft-acp-util/components/FilterForm/FilterForm'
import entityList from 'syft-acp-util/entityList'

import { apiAuthorizedURL } from '../../../api/call'
import {
  ListingEditShiftsButton,
  ListingRemoveSpotButton,
  ListingSaveShiftsButton,
  ListingShiftCancelButton,
} from './ListingButtons'
import {
  ListingCheckbox,
  ListingPayrateInput,
  ListingShiftsBreaksDetails,
  ListingShiftsFlexCompCheckbox,
  ListingShiftsNoShowCheckbox,
  ListingShiftTimeInput,
  ListingWorkersAutocomplete,
} from './ListingInputs'
import ShiftBookingGeoLocation from './ShiftBookingGeoLocation'

import RetroactiveEditModal from 'syft-acp-core/components/Modal/RetroactiveEditModal'
import './ListingShiftBookings.css'

const openUrl = (url, params) => {
  window.location = apiAuthorizedURL(url, params)
}

const isDisabled = ({ id, no_show } = {}) => isDraft(id) || no_show
const shiftBreaksClockInOutModalName = 'shiftBreakClockInOutModal'

const isWorkerInternal = ({ worker_platform }) => worker_platform === 'own'

const punchSection = hasPunchInOut =>
  !!hasPunchInOut
    ? [
        {
          type: tableType.CUSTOM_COMPONENT,
          val: v => (
            <div className="in-out-input-wrapper">
              <ListingShiftTimeInput
                attr="time_punches.0.punch_in"
                isClockIn
                shiftBooking={v}
                disabled={isDisabled(v) || !v.time_punches}
                timeZone={get(v, 'timezone')}
              />
              /
              <ListingShiftTimeInput
                attr="time_punches.0.punch_out"
                shiftBooking={v}
                disabled={isDisabled(v) || !v.time_punches}
                timeZone={get(v, 'timezone')}
              />
            </div>
          ),
          classes: ['in'],
          valHints: ['time_punches'],
          header: 'Punch In/Out 1',
          hasLink: false,
        },
        {
          type: tableType.CUSTOM_COMPONENT,
          val: v => (
            <div className="in-out-input-wrapper">
              <ListingShiftTimeInput
                attr="time_punches.1.punch_in"
                shiftBooking={v}
                disabled={isDisabled(v) || !v.time_punches}
                timeZone={get(v, 'timezone')}
              />
              /
              <ListingShiftTimeInput
                attr="time_punches.1.punch_out"
                shiftBooking={v}
                disabled={isDisabled(v) || !v.time_punches}
                timeZone={get(v, 'timezone')}
              />
            </div>
          ),
          classes: ['in'],
          valHints: ['time_punches', 'retroactive_reason'],
          header: 'Punch 2 In/Out',
          hasLink: false,
        },
        {
          type: tableType.CUSTOM_COMPONENT,
          val: v => (
            <div className="in-out-input-wrapper">
              <ListingShiftTimeInput
                attr="time_punches.2.punch_in"
                shiftBooking={v}
                disabled={isDisabled(v) || !v.time_punches}
                timeZone={get(v, 'timezone')}
              />
              /
              <ListingShiftTimeInput
                attr="time_punches.2.punch_out"
                shiftBooking={v}
                disabled={isDisabled(v) || !v.time_punches}
                timeZone={get(v, 'timezone')}
              />
            </div>
          ),
          classes: ['in'],
          valHints: ['time_punches', 'retroactive_reason'],
          header: 'Punch In/Out 3',
          hasLink: false,
        },
      ]
    : []

const timePunchSubjectMap = {
  worker_time: 'worker',
  client_time: 'client',
}

const createTableFormat = ({
  hasPunchInOut,
  hasUuid,
  hasComplianceDocs,
  listingID,
  enableListingDetailRoleChanges,
  roles,
  hasPayBillSplit,
  timePunchView,
}) => [
  {
    type: tableType.TXT,
    val: v => (!isDraft(v.id) ? v.id : null),
    header: 'ID',
    numeric: true,
  },
  {
    type: tableType.START_END,
    vals: ['startTime', 'endTime'],
    options: data => ({ timeZone: get(data, 'timezone') }),
    noBold: true,
    classes: ['time'],
    header: 'Time',
  },
  {
    type: tableType.CUSTOM_COMPONENT,
    val: v => <ShiftBookingGeoLocation small geoLocation={v.geo_location} />,
    headerIcon: octicons.location,
    headerIconAlt: 'Location',
    classes: ['geolocation'],
    expl: 'Geolocation',
    hasLink: false,
  },
  {
    type: tableType.CUSTOM_COMPONENT,
    val: v => {
      return (
        <div className="in-out-input-wrapper">
          <ListingShiftTimeInput
            attr={hasPayBillSplit ? `${timePunchView}.clock_in` : 'clock_in.time'}
            isClockIn
            shiftBooking={v}
            disabled={isDisabled(v) || v.time_punches}
            timeZone={get(v, 'timezone')}
          />
          /
          <ListingShiftTimeInput
            attr={hasPayBillSplit ? `${timePunchView}.clock_out` : 'clock_out.time'}
            isClockOut
            shiftBooking={v}
            disabled={isDisabled(v) || v.time_punches}
            timeZone={get(v, 'timezone')}
          />
        </div>
      )
    },
    classes: ['in'],
    valHints: ['syft_unpaid_hours_compensation', 'retroactive_reason'],
    header: 'In/Out',
    hasLink: false,
  },
  /*

  Note: this has been turned off in favor of the 'missing time' section below this item.
  Keeping this here just in case. It shows the TOTAL amount of missing time given to a worker,
  so an accumulation of all items, but it messes up when editing.

  {
    type: tableType.CUSTOM_COMPONENT,
    // Note: this is a little complicated. See app/sagas/calls/params/bulkEditParams.js.
    val: v => {
      const enforcedTime = v.syft_unpaid_hours_compensation != null ? v.syft_unpaid_hours_compensation === true : null;
      return (
        <ListingDurationInput
          attr="unpaid_times_n"
          attrSource="unpaid_times"
          isUnpaidTime
          enforcedTime={ enforcedTime }
          shiftBooking={ v }
          duration
          disabled={ enforcedTime || isDisabled(v) }
        />
      );
    },
    header: 'Missing time',
    classes: ['missing-time'],
    valHints: ['unpaid_times', 'unpaid_times_n', 'syft_unpaid_hours_compensation'],
    hasLink: false
  },
  */

  {
    type: tableType.CUSTOM_COMPONENT,
    val: v => (
      <ListingShiftsBreaksDetails
        shiftBooking={v}
        attr={hasPayBillSplit ? `${timePunchView}.break_duration` : 'break.duration'}
        isClockOut
        disabled={isDisabled(v) || v.time_punches}
        modalName={shiftBreaksClockInOutModalName}
        timeZone={get(v, 'timezone')}
      />
    ),
    hasLink: false,
    classes: ['minimal', 'booking-breaks'],
    header: 'Breaks',
  },
  ...punchSection(hasPunchInOut),
  {
    type: tableType.CUSTOM_COMPONENT,
    val: v => (
      <ListingWorkersAutocomplete
        key={`booking_worker_${v.id}`}
        attr="worker.id"
        shiftBooking={v}
        placeholder="Select Worker"
        disabled={v.no_show}
      />
    ),
    valHints: ['worker.id'],
    classes: ['booking-worker'],
    header: 'Worker',
    hasLink: false,
  },
  {
    type: tableType.TXT,
    val: 'venue.name',
    header: 'Venue',
    classes: ['minimal'],
  },
  !!enableListingDetailRoleChanges && {
    type: tableType.TXT,
    val: v => v?.role_id && roles?.[v.role_id]?.title,
    header: 'Role',
    classes: ['minimal'],
  },
  {
    type: tableType.TXT,
    val: 'area.name',
    header: 'Area',
    classes: ['minimal'],
  },
  hasUuid && {
    // Note: external_booking_uuid is an object containing 'id' (number) and 'uuid' (string).
    // The ID is not important for ops.
    type: tableType.MONO,
    val: 'external_booking_uuid.uuid',
    // classes: ['main'],
    header: 'UUID value',
  },
  hasComplianceDocs && {
    type: tableType.TXT,
    val: data => {
      const docs = get(data, 'compliance_docs', [])
      if (!docs || docs.length === 0) {
        return (
          <ButtonIcon icon="file-pdf" iconSize={12} disabled target="_blank" onClick={() => openUrl('#')}>
            View
          </ButtonIcon>
        )
      }
      return docs.map(d => {
        const id = get(d, 'files.0.file_id')
        return (
          <span>
            <ButtonIcon
              icon="file-pdf"
              iconSize={12}
              target="_blank"
              onClick={() => openUrl(`/compliance/workers/${data.worker.id}/documents/${id}`)}
            >
              View
            </ButtonIcon>
            &nbsp;
          </span>
        )
      })
    },
    classes: ['minimal', 'button-wrapper'],
    header: 'C. letter',
    expl: 'Compliance letter',
  },
  hasComplianceDocs && {
    type: tableType.TXT,
    val: data => (get(data, 'compliance_docs', []).length > 0 ? 'Yes' : 'No'),
    classes: ['minimal'],
    header: 'Letter sent',
  },
  {
    type: tableType.CUSTOM_COMPONENT,
    val: v => (
      <div className='internal-worker-pay-rate'>
        <ListingPayrateInput
          attr="worker_pay_rate.amount"
          id={v?.id}
          value={v?.worker_pay_rate?.amount}
          disabled={isWorkerInternal(v.worker) || isDisabled(v)}
        />
        {isWorkerInternal(v.worker) && (
          <>
            <a data-tip="Internal worker" data-for="internal-worker-tooltip" />
            <ReactTooltip id="internal-worker-tooltip" effect="solid" />
          </>
        )}
      </div>
    ),
    valHints: ['worker_pay_rate.amount'],
    classes: ['rate'],
    header: 'Pay rate',
    hasLink: false,
  },
  {
    type: tableType.MONEY,
    val: 'client_pay_rate.amount',
    header: 'Client rate',
  },
  {
    type: tableType.CUSTOM_COMPONENT,
    val: v => <ListingCheckbox attr="provisional" shiftBooking={v} disabled={isNumber(v.id)} />,
    expl: 'Provisional booking - marks the booking as provisional',
    header: 'PB',
    classes: ['minimal'],
    valHints: ['provisional'],
    hasLink: false,
  },
  {
    type: tableType.CUSTOM_COMPONENT,
    val: v => <ListingCheckbox attr="syft_unpaid_hours_compensation" shiftBooking={v} disabled={false} />,
    expl: 'Compensation hours - enforces a shift duration of 4/6 hours',
    header: 'CH',
    classes: ['minimal'],
    valHints: ['syft_unpaid_hours_compensation'],
    hasLink: false,
  },
  {
    type: tableType.CUSTOM_COMPONENT,
    val: v => <ListingShiftsNoShowCheckbox shiftBooking={v} disabled={isDraft(v.id)} />,
    header: 'NS',
    expl: 'No show',
    classes: ['minimal'],
    valHints: ['no_show', 'no_show_reason'],
    hasLink: false,
  },
  {
    type: tableType.CUSTOM_COMPONENT,
    val: v => <ListingShiftsFlexCompCheckbox shiftBooking={v} disabled={isDraft(v.id)} />,
    header: 'FC',
    expl: 'Flex compensation - if checked, Flex will pay this booking instead of the client',
    classes: ['minimal'],
    valHints: ['syft_compensation', 'syft_compensation_reason', 'syft_compensation_approver'],
    hasLink: false,
  },
  {
    type: tableType.CUSTOM_COMPONENT,
    val: v => <ListingCheckbox attr="fee_not_charged" shiftBooking={v} disabled={isDisabled(v)} />,
    expl: 'No fee - if checked, no fee will be charged for this shift',
    header: 'NF',
    classes: ['minimal'],
    valHints: ['fee_not_charged'],
    hasLink: false,
  },
  {
    type: tableType.TXT,
    val: v =>
      isDraft(v.id) ? (
        <ListingRemoveSpotButton shiftBooking={v} shiftID={v.shiftID} />
      ) : (
        <ListingShiftCancelButton listingID={listingID} shiftBooking={v} shiftID={v.shiftID} />
      ),
    classes: ['visible-items'],
    header: 'Actions',
    hasLink: false,
  },
]

const filters = null

export const entityStore = 'listingShiftBookings'

const StoreAlerts = EntityAlertConnected(entityStore)
const ListingShiftBookingsListWrapper = ({
  hasPayBillSplit,
  timePunchView,
  hasChanges,
  onTimePunchViewChange,
}) =>
  entityDetailList({
    title: 'Bookings',
    className: 'listing-shift-workers',
    notificationsNode: <StoreAlerts />,
    subHeaderNode: hasPayBillSplit ? (
      <div className="sub-header">
        <span>Time punch view</span>
        <label>
          <input
            type="radio"
            value="worker_time"
            onChange={e => onTimePunchViewChange(e.target.value)}
            checked={timePunchView === 'worker_time'}
            disabled={hasChanges}
          />{' '}
          <span>Worker</span>
        </label>
        <label>
          <input
            type="radio"
            value="client_time"
            onChange={e => onTimePunchViewChange(e.target.value)}
            checked={timePunchView === 'client_time'}
            disabled={hasChanges}
          />{' '}
          <span>Client</span>
        </label>
      </div>
    ) : null,
  })

const ListingShiftBookings = props => {
  const {
    shiftID,
    venue,
    entityListData,
    entityMapChanges,
    listingID,
    roles,
    enableListingDetailRoleChanges,
  } = props
  const [timePunchView, setTimePunchView] = useState('worker_time')
  const timezone = get(venue, 'timezone')
  const data = entityListData.map(listData => ({ ...listData, timezone }))
  const hasUuid = data.some(booking => booking?.external_booking_uuid?.uuid)
  const hasPunchInOut = data.some(booking => booking?.time_punches)
  const hasComplianceDocs = data.some(booking => booking?.compliance_docs?.length)
  const hasPayBillSplit = data.some(booking => booking?.pay_bill_split)

  const actionFormat = [
    {
      label: 'Edit all',
      type: filterTypes.TYPE_CUSTOM_BUTTON,
      passProps: ['shiftID', 'breakDuration', 'startTime', 'endTime', 'timeZone'],
      global: true,
      customButton: buttonProps => (
        <ListingEditShiftsButton
          hasPayBillSplit={hasPayBillSplit}
          subject={timePunchSubjectMap[timePunchView]}
          {...buttonProps}
        />
      ),
    },
    {
      label: 'Save',
      type: filterTypes.TYPE_CUSTOM_BUTTON,
      passProps: ['job'],
      customButton: ListingSaveShiftsButton,
    },
  ]

  const hasChanges = Object.keys(entityMapChanges ?? {}).length > 0

  const ListingShiftBookingsList = useMemo(
    () =>
      entityList(
        ListingShiftBookingsListWrapper({
          hasPayBillSplit,
          timePunchView,
          onTimePunchViewChange: setTimePunchView,
          hasChanges,
        }),
        createTableFormat({
          hasUuid,
          hasPunchInOut,
          hasComplianceDocs,
          listingID,
          roles,
          enableListingDetailRoleChanges,
          hasPayBillSplit,
          timePunchView,
        }),
        filters,
        actionFormat,
        entityStore,
        ({ onNextPage }) => ({ onNextPage }),
        {
          debugString: 'ListingShiftBookingsList',
          disableAutoScroll: true,
          idFunction: makePropertyCheck(
            [
              'id',
              'worker_pay_rate',
              'fee_not_charged',
              'syft_unpaid_hours_compensation',
              'syft_compensation',
              'no_show',
              'break_details',
              'jobID',
              'shiftID',
              'clock_in.time',
              'clock_out.time',
              'client_time.clock_in',
              'client_time.clock_out',
              'worker_time.clock_in',
              'worker_time.clock_out',
              'time_punches',
              'retroactive_reason',
              'startTime',
              'endTime',
              'worker.id',
              'provisional',
            ],
            true,
            true,
          ),
          recordList: true,
          showResultCount: true,
          noOverflow: true,
        },
      ),
    [
      hasComplianceDocs,
      hasPunchInOut,
      hasUuid,
      listingID,
      roles,
      enableListingDetailRoleChanges,
      timePunchView,
      hasPayBillSplit,
      hasChanges,
    ],
  )
  return (
    <div className="ListingShiftBookingsList">
      <ListingShiftBreakDetailsModal
        hasPayBillSplit={hasPayBillSplit}
        subject={hasPayBillSplit ? timePunchSubjectMap[timePunchView] : null}
        modalName={shiftBreaksClockInOutModalName}
      />
      <AddNoShowReasonModal />
      <RetroactiveEditModal />
      <AddFlexCompReasonModal />
      <EndJobAssignmentModal shiftID={shiftID} listingID={listingID} />
      <ListingShiftBookingsList
        hasLinks={false}
        {...props}
        query={{ shiftID }}
        entityList={data}
        timeZone={timezone}
        test
      />
    </div>
  )
}

ListingShiftBookings.propTypes = {
  title: PropTypes.string.isRequired,
  shiftID: PropTypes.number.isRequired,
  roles: PropTypes.array.isRequired,
  enableListingDetailRoleChanges: PropTypes.bool.isRequired,
  job: PropTypes.object.isRequired,
  onNextPage: PropTypes.func.isRequired,
  venue: PropTypes.object.isRequired,
  entityListData: PropTypes.array.isRequired,
  entityMapChanges: PropTypes.object.isRequired,
}

export default connect(
  (state, { shiftID }) => ({
    entityListData: entityList$(state[entityStore], { shiftID }),
    entityMapChanges: state[entityStore].entityMapChanges,
    roles: state.roles.entityMap,
  }),
  (dispatch, props) => ({
    onNextPage: data => {
      dispatch(fetchAdminBookings(props.shiftID, props.job.id, { page: data.page, per_page: 8 }))
    },
  }),
)(ListingShiftBookings)
