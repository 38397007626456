// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import React from 'react'
import { Form } from 'react-bootstrap'
import { Box, FormField, Input, Button, Text } from '@indeed/ifl-components'
import flexLogoUrl from './logos/indeed-flex-logo-login.svg'

import { LoginFormPureProps as Props } from './LoginSSOFormPure.types'

import './LoginSSOFormPure.css'

const LoginSSOFormPure = ({
  isDisabled,
  valueEmail,
  isAwaitingRehydration,
  feedbackMessage,
  onTypeEmail,
  onSubmit,
  onLoginTrouble,
  emailValidationMessage,
}: Props) => {
  const isDisabledForm = isDisabled || isAwaitingRehydration
  return (
    <div className="login-container">
      <Form
        onSubmit={onSubmit}
        className={`login ${isAwaitingRehydration ? 'awaiting-rehydration' : ''}`}
        data-testid="form-login"
      >
        <Box as="img" src={flexLogoUrl} className="login-main-logo" />

        <div>
          <Text variant="contrast" sx={{ fontWeight: 'bold' }} level={3}>
            Log in with your single sign-on (SSO) credentials
          </Text>
          <FormField className="login-field" htmlFor="email" label="Email">
            <Input
              type="email"
              aria-label="Email"
              onChange={onTypeEmail}
              value={valueEmail}
              disabled={isDisabledForm}
              id="email"
            />
          </FormField>
          <Box
            sx={{
              marginTop: '25px',
              marginBottom: '40px',
            }}
          >
            <Button
              variant="primary"
              size="md"
              type="submit"
              loading={isDisabledForm}
              disabled={isDisabledForm}
              onClick={onSubmit}
            >
              Log in with SSO
            </Button>
          </Box>
          {(feedbackMessage || emailValidationMessage) && (
            <p className="feedback-message">
              {feedbackMessage
                ? `${feedbackMessage} please raise an IT support ticket on `
                : emailValidationMessage}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://syftapp.slack.com/archives/C01G7U6HWBU"
              >
                slack.
              </a>
            </p>
          )}
        </div>
        <div className="login-trouble-section">
          <div>
            <Button variant="destructiveText" onClick={onLoginTrouble}>
              Having trouble logging in?
            </Button>
          </div>
        </div>
      </Form>
    </div>
  )
}

export default LoginSSOFormPure
