import React from 'react'

import { Modal } from '@indeed/ipl-modal'
import { Text } from '@indeed/ifl-components'
import { Form } from 'react-bootstrap'
import { LoginTroubleModalProps as Props } from './LoginResetPasswordModal.types'
import { Input } from '@indeed/ifl-components'

const LoginResetPasswordModal = ({ isOpen, handleOnExit, onTypeEmail, handleResetPassword }: Props) => {
  return (
    <Modal
      isOpen={isOpen}
      onExit={handleOnExit}
      title="Confirmation"
      footerButtons={[
        {
          label: 'Close',
          props: {
            onClick: () => handleOnExit(),
          },
        },
        {
          label: 'Reset password',
          props: {
            variant: 'secondary',
            onClick: () => handleResetPassword(),
          },
        },
      ]}
      data-testid="login-password-modal"
    >
      <Text as="p" data-testid="fill-your-address" level={3}>
        Fill out your email address here and click "reset password".
      </Text>
      <Form data-testid="form-reset-password" className={`login`} onSubmit={handleResetPassword}>
        <Input aria-label="Email" type="text" onChange={onTypeEmail} id="email" />
      </Form>
    </Modal>
  )
}

export default LoginResetPasswordModal
