// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import React from 'react'

import ManagersList from 'syft-acp-core/entities/ManagersList'
import PageView from 'syft-acp-core/components/PageView'
import SubNavigationAdmin from './SubNavigation'

export const AdminManagersList = () => (
  <>
    <SubNavigationAdmin />
    <PageView title={['Managers', 'Overview']} entityPage>
      <ManagersList />
    </PageView>
  </>
)
