import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { sortBy } from 'lodash-es'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as filtersActions from 'syft-acp-core/store/filters/actions'
import * as venueCategoriesActions from 'syft-acp-core/store/venueCategories/actions'
import { filterSelect } from './FilterSelect'
import { selectVenueCategoryOptionsByEmployerId } from 'syft-acp-core/store/venueCategories/selectors'

class FilterSelectVenueCategories extends PureComponent {
  static propTypes = {
    name: PropTypes.string.isRequired,
    venueCategories: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        label: PropTypes.string.isRequired,
      }),
    ).isRequired,
    actions: PropTypes.objectOf(PropTypes.func).isRequired,
    disabled: PropTypes.bool,
    value: PropTypes.string,
    allowAny: PropTypes.bool,
    hasVenueCategoriesData: PropTypes.bool,
    type: PropTypes.string,
    queryAttribute: PropTypes.string,
    employerID: PropTypes.number,
    filterOptions: PropTypes.object,
    onChange: PropTypes.func,
  }

  static defaultProps = {
    disabled: false,
    value: null,
    allowAny: false,
    hasVenueCategoriesData: false,
    type: null,
    filterOptions: {},
    onChange: () => {},
  }

  componentDidMount() {
    const { hasVenueCategoriesData, actions, employerID } = this.props
    if (!hasVenueCategoriesData) {
      actions.fetchVenueCategories(employerID)
    }
  }

  onChange = val => {
    const { actions, name, filterOptions, onChange } = this.props
    onChange(val)
    actions.setFilter(name, val, null, filterOptions)
  }

  render() {
    const { venueCategories, name, value, disabled, type } = this.props
    const FilterSelect = filterSelect(venueCategories, this.props.allowAny ? 'Any' : 'Venue Category', type)

    return (
      <FilterSelect
        name={name}
        className="SelectVenueCategory"
        onChange={this.onChange}
        value={value}
        allowAny={this.props.allowAny}
        disabled={disabled}
        ariaLabel="Venue Category"
      />
    )
  }
}

export default connect(
  (state, { value, queryAttribute = 'venue_category_id', employerID }) => ({
    venueCategories: sortBy(selectVenueCategoryOptionsByEmployerId(state, employerID), ['name']),
    hasVenueCategoriesData: state.venueCategories.hasData,
    value: value || state.routing.locationBeforeTransitions.query[queryAttribute],
  }),
  (dispatch, { actions }) => {
    const boundActions = bindActionCreators(
      {
        ...filtersActions,
        ...venueCategoriesActions,
      },
      dispatch,
    )
    return {
      actions: {
        ...boundActions,
        ...actions,
      },
    }
  },
)(FilterSelectVenueCategories)
