import React, { useEffect } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import RecordSection from 'syft-acp-core/components/RecordSection'
import { dataStructure } from 'syft-acp-core/entities/WorkerDetail/structure'
import { fetchWorkerHolidayPayAccruals } from 'syft-acp-core/store/worker-holiday-pay/actions'
import { selectWorkerHolidayPayAccruals } from 'syft-acp-core/store/worker-holiday-pay/selectors'

import {
  RecordWorkerHolidayPayProps as Props,
  RecordWorkerHolidayPayOwnProps as OwnProps,
} from './RecordWorkerHolidayPay.types'

export const storeConnector = connect(
  (state, ownProps: OwnProps) => ({
    workerHolidayPayAccruals: selectWorkerHolidayPayAccruals(state)(ownProps.workerID),
  }),
  dispatch => ({
    actions: bindActionCreators({ fetchWorkerHolidayPayAccruals }, dispatch),
  })
)

const RecordWorkerHolidayPay = ({ actions, workerID, workerHolidayPayAccruals }: Props) => {
  useEffect(() => {
    actions.fetchWorkerHolidayPayAccruals(workerID)
  }, [actions, workerID])

  return <RecordSection title="Holiday pay" data={workerHolidayPayAccruals} structure={dataStructure.holidayPay} />
}

export default storeConnector(RecordWorkerHolidayPay)
