import React, { useEffect } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Grid, Col, Row } from 'react-bootstrap'
import { get } from 'lodash-es'
import { useTrackingTrigger } from '@indeed/flex-tracking-context'

import entityDetail from 'syft-acp-util/entityDetail'

import SiteComponent from 'syft-acp-core/components/SiteComponent'
import RecordSection from 'syft-acp-core/components/RecordSection'
import { EntityAlertConnected } from 'syft-acp-core/components/Alerts/EntityAlert'
import * as workersNetworkActions from 'syft-acp-core/actions/worker-networks'

import WorkerNetworkDetailControls from './WorkerNetworkDetailControls'
import { trackingEvents } from './WorkerNetworkDetail.tracking'
import { WorkerNetworkDetailProps as Props } from './WorkerNetworkDetail.types'
import { structure } from './structure'

const NetworkAlert = EntityAlertConnected('workerNetworks')
export const WorkerNetworkDetail = ({
  id = 0,
  workerID,
  data,
  actionUpdate,
  isPristine,
  actions,
  lastBody = null,
}: Props) => {
  const triggerEvent = useTrackingTrigger()
  useEffect(() => {
    if (workerID && id) {
      actions.fetchWorkerNetwork(workerID, id)
    }
  }, [workerID, id, actions])

  const errorKey = () => (get(lastBody, 'error_details.fields[0]') ? 'error_details.fields[0]' : 'debug.message')

  const onSave = (ownerId: number) => {
    triggerEvent(trackingEvents.NEW.SAVE_BUTTON.CLICKED, {
      owner_id: ownerId,
    })
  }

  const onDelete = () => {
    triggerEvent(trackingEvents.DETAIL.DELETE_BUTTON.CLICKED)
  }

  return (
    <SiteComponent>
      <Grid>
        <Row>
          <Col md={12} sm={12}>
            <WorkerNetworkDetailControls
              data={data}
              isPristine={isPristine}
              workerID={workerID}
              onSave={onSave}
              onDelete={onDelete}
            />
            <NetworkAlert lastBodyKey={errorKey()} />
            <RecordSection
              title="Profile information"
              data={data}
              structure={structure}
              isSaved={id !== 0}
              update={actionUpdate}
            />
          </Col>
        </Row>
      </Grid>
    </SiteComponent>
  )
}

export const storeConnector = connect(null, dispatch => ({
  actions: bindActionCreators(workersNetworkActions, dispatch),
}))

export default storeConnector(entityDetail('workerNetworks', WorkerNetworkDetail))
