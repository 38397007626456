// Syft ACP - Lib <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import React from 'react'
import PropTypes from 'prop-types'
import { Button } from 'syft-acp-atoms/Button'

const ActionButton = ({ label, callback, disabled, buttonType, isBright, isActive }) => (
  <Button kind={buttonType} onClick={callback} disabled={disabled} isBright={isBright} isActive={isActive}>
    {label}
  </Button>
)

ActionButton.propTypes = {
  label: PropTypes.string.isRequired,
  callback: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  isBright: PropTypes.bool,
  isActive: PropTypes.bool,
  buttonType: PropTypes.string,
}

ActionButton.defaultProps = {
  buttonType: 'primary',
  isBright: false,
  isActive: false,
  disabled: false,
}

export default ActionButton
