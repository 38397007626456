import { apiRequest, handleCall } from 'syft-acp-core/api/call'
import { ApiResponse } from 'syft-acp-core/api/types'

import {
  ListTimeOffRequestsOptions,
  FetchTimeOffRequestInput,
  UpdateTimeOffRequestInput,
  ApproveTimeOffRequestInput,
  RejectTimeOffRequestInput,
  TimeOffRequestEntity,
} from './types'
import { API_URL } from './helpers'

/**
 * Retrieves a list of time off requests. Implements pagination.
 */
export const listTimeOffRequests = ({ options }: ListTimeOffRequestsOptions) =>
  new Promise<ApiResponse<TimeOffRequestEntity[]>>((resolve, reject) => {
    const req = apiRequest({ path: API_URL.LIST, reqArgs: options })
    handleCall(req, resolve, reject, true, options)
  })

export const fetchTimeOffRequest = ({ payload }: FetchTimeOffRequestInput) =>
  new Promise<ApiResponse<TimeOffRequestEntity>>((resolve, reject) => {
    const req = apiRequest({ path: API_URL.FETCH(payload.requestID) })
    handleCall(req, resolve, reject, true)
  })

/**
 * Updates a time off request.
 */
export const updateTimeOffRequest = ({ payload }: UpdateTimeOffRequestInput) =>
  new Promise<ApiResponse<TimeOffRequestEntity>>((resolve, reject) => {
    const req = apiRequest({
      path: API_URL.UPDATE(payload.id),
      method: 'PATCH',
      reqArgs: payload.options,
    })
    handleCall(req, resolve, reject, true)
  })

/**
 * Approves a time off request.
 */
export const approveTimeOffRequest = ({ payload }: ApproveTimeOffRequestInput) =>
  new Promise<ApiResponse<TimeOffRequestEntity>>((resolve, reject) => {
    const req = apiRequest({
      path: API_URL.APPROVE(payload.id),
      method: 'POST',
    })
    handleCall(req, resolve, reject, true)
  })

/**
 * Rejects a time off request.
 */
export const rejectTimeOffRequest = ({ payload }: RejectTimeOffRequestInput) =>
  new Promise<ApiResponse<TimeOffRequestEntity>>((resolve, reject) => {
    const req = apiRequest({
      path: API_URL.REJECT(payload.id),
      method: 'POST',
    })
    handleCall(req, resolve, reject, true)
  })

/**
 * Feth a list of employers who have time off requests
 * associated with them (either past or present).
 * @param employer {String} Substring to filter employers by
 */
export const fetchTimeOffRequestEmployers = (employer: string) =>
  new Promise<ApiResponse<TimeOffRequestEntity['employer'][]>>((resolve, reject) => {
    const req = apiRequest({
      path: API_URL.EMPLOYERS(employer),
    })
    handleCall(req, resolve, reject, true)
  })

/**
 * Feth a list of venues that have time off requests
 * associated with them (either past or present).
 * @param venue {String} Substring to filter venues by
 */
export const fetchTimeOffRequestVenues = (venue: string) =>
  new Promise<ApiResponse<TimeOffRequestEntity['venue'][]>>((resolve, reject) => {
    const req = apiRequest({
      path: API_URL.VENUES(venue),
    })
    handleCall(req, resolve, reject, true)
  })
