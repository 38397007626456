// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import entityReducer from './generators/entityReducer'
import * as types from '../actions/action-types'
import * as entityTypes from './generators/entities'

export default entityReducer(
  'employerHistory',
  {
    [entityTypes.ENTITIES_FETCH_BEGIN]: types.EMPLOYER_EVENTS_FETCH_BEGIN,
    [entityTypes.ENTITIES_FETCH_SUCCEEDED]: types.EMPLOYER_EVENTS_FETCH_SUCCEEDED,
    [entityTypes.ENTITIES_FETCH_FAILED]: types.EMPLOYER_EVENTS_FETCH_FAILED,
  },
  {
    localEntityMap: true,
  }
)
