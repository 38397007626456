export const trackingEvents = {
  CREATE_BUTTON: {
    CLICKED: 'managers.create_button.clicked',
  },
  UPDATE_BUTTON: {
    CLICKED: 'managers.update_button.clicked',
  },
  DELETE_BUTTON: {
    CLICKED: 'managers.delete_button.clicked',
  },
}
