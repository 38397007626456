import moment from 'moment'
import type { TimeDependentRate } from 'syft-acp-core/store/employerRateCards/types'
import * as structureTypes from 'syft-acp-core/components/EditableTable'
import type { Option } from './types'

export const createFormFields = (
  bankHolidayOptions: Option[],
  hasShiftRateType: boolean,
  hideMarginField = false,
  hideInvoiceField = false
) => {
  const rateField = {
    type: structureTypes.TYPE_RATE,
    editable: true,
    allowEmpty: true,
  }

  const marginField = hideMarginField
    ? []
    : [
        [
          'margins_percent',
          {
            header: 'Margin',
            type: structureTypes.TYPE_NUMERIC,
            editable: true,
            suffix: '%',
            prefix: '%',
            parameters: { ariaLabel: 'Margin' },
          },
        ],
      ]

  const invoiceField = hideInvoiceField
    ? []
    : [
        [
          'invoice_rate',
          {
            ...rateField,
            header: 'Invoice rate',
            parameters: {
              ariaLabel: 'Invoice rate',
            },
          },
        ],
      ]

  return [
    [
      'label',
      {
        header: 'Label',
        editable: true,
        type: structureTypes.TYPE_TXT,
      },
    ],
    ['', { type: structureTypes.TYPE_SEPARATOR, colspan: 4 }],
    [
      'default_payable_rate',
      {
        ...rateField,
        header: 'Default rate',
        parameters: {
          ariaLabel: 'Default rate',
        },
      },
    ],
    [
      'min_payable_rate',
      {
        ...rateField,
        header: 'Minimum rate',
        parameters: {
          ariaLabel: 'Minimum rate',
        },
      },
    ],
    [
      'max_payable_rate',
      {
        ...rateField,
        header: 'Maximum rate',
        parameters: {
          ariaLabel: 'Maximum rate',
        },
      },
    ],
    ...invoiceField,
    ...marginField,
    ['', { type: structureTypes.TYPE_SEPARATOR, colspan: 4 }],
    [
      'start',
      {
        header: 'Start time',
        editable: !hasShiftRateType,
        type: structureTypes.TYPE_TIME,
      },
    ],
    [
      'end',
      {
        header: 'End time',
        editable: !hasShiftRateType,
        type: structureTypes.TYPE_TIME,
      },
    ],
    ['', { type: structureTypes.TYPE_SEPARATOR, colspan: 4 }],
    [
      'bank_holiday_region_id',
      {
        header: 'Bank holidays',
        editable: true,
        type: structureTypes.TYPE_DROPDOWN,
        options: bankHolidayOptions,
        parameters: {
          ariaLabel: 'Bank holidays',
          allowAny: true,
          anyOption: 'Select bank holiday',
        },
      },
    ],
    [
      'week_days',
      {
        header: 'Day of week',
        editable: true,
        type: structureTypes.TYPE_CHECKBOXES,
        parameters: {
          display: 'inline-center',
        },
        options: [
          ['Monday', 'Mon'],
          ['Tuesday', 'Tue'],
          ['Wednesday', 'Wed'],
          ['Thursday', 'Thu'],
          ['Friday', 'Fri'],
          ['Saturday', 'Sat'],
          ['Sunday', 'Sun'],
        ],
      },
    ],
  ]
}

export const validateForm = (data: TimeDependentRate) => {
  const errors: string[] = []

  if (!data.label) {
    errors.push('Label is required')
  }

  if (!data.default_payable_rate) {
    errors.push('Default payable rate is required')
  }

  if (!data.max_payable_rate) {
    errors.push('Maximum payable rate is required')
  }

  if (!data.min_payable_rate) {
    errors.push('Minimum payable rate is required')
  }

  if (!data.start && !data.end && !data.bank_holiday_region_id && !data.week_days) {
    errors.push('Tier is required e.g. start and end time and/or bank holiday or day of week')
  }

  if (data.bank_holiday_region_id && data.week_days?.length > 0) {
    errors.push('Select bank holiday OR days of the week')
  }

  return errors
}

const formatTimeTo24hr = (time: string | null = '') => {
  return time && time.search(/(am|pm)/i) >= 0 ? moment(time, 'hh:mm A').format('HH:mm') : time
}

export const formatRequest = (data: TimeDependentRate) => ({
  ...data,
  start: data.start ? formatTimeTo24hr(data.start) : undefined,
  end: data.end ? formatTimeTo24hr(data.end) : undefined,
})
