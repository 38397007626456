// Syft ACP - UI Library <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { get } from 'lodash-es'

import RecordSegment from '../componentSegments/RecordSegment'
import RecordFooter from '../componentSegments/RecordFooter'
import RecordHeaderForm from '../componentSegments/RecordHeaderForm'
import AcpWrapper from './AcpWrapper'
import './AcpGenericWrapper.css'

class AcpGenericWrapper extends React.PureComponent {
  static propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    componentData: PropTypes.shape({ children: PropTypes.node }).isRequired,
    noMargin: PropTypes.bool.isRequired,
    noPadding: PropTypes.bool.isRequired,
    isFullWidth: PropTypes.bool.isRequired,
    isLoading: PropTypes.bool,
    isOK: PropTypes.bool,
    withPagination: PropTypes.bool,
  }

  static defaultProps = {
    children: null,
    isLoading: false,
    isFullWidth: false,
    isOK: true,
    className: '',
    withPagination: false,
  }

  render() {
    const { children, className, componentData, noMargin, noPadding, isFullWidth, isLoading } = this.props
    const componentClass = classnames('acp-generic-wrapper', className, {
      'full-width': isFullWidth,
    })
    const hasTitle = componentData.AcpHeader || componentData.AcpActions
    const hasFooter = componentData.AcpFooter
    return (
      <AcpWrapper
        isIntegrated
        className={componentClass}
        componentData={componentData}
        noMargin={noMargin}
        noRounding={false}
      >
        {hasTitle && (
          <RecordSegment noPadding>
            <RecordHeaderForm isHeader isLoading={isLoading}>
              {get(componentData, 'AcpActions.children', children)}
            </RecordHeaderForm>
          </RecordSegment>
        )}
        <RecordSegment withText noPadding={noPadding}>
          {children}
        </RecordSegment>
        {hasFooter && <RecordFooter hasTopBorder={false}>{componentData.AcpFooter.children}</RecordFooter>}
      </AcpWrapper>
    )
  }
}

export default AcpGenericWrapper
