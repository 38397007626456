import { entityReducer } from 'syft-acp-core/reducers/generators/entityReducer'
import * as entityTypes from 'syft-acp-core/reducers/generators/entities'

import * as types from './action-types'
import { EmployerShiftRateTypeEntity } from './types'

export default entityReducer<EmployerShiftRateTypeEntity>(
  'employerShiftRateTypes',
  {
    [entityTypes.ENTITIES_FETCH_BEGIN]: types.EMPLOYER_SHIFT_RATE_TYPES_FETCH_BEGIN,
    [entityTypes.ENTITIES_FETCH_SUCCEEDED]: types.EMPLOYER_SHIFT_RATE_TYPES_FETCH_SUCCEEDED,
    [entityTypes.ENTITIES_FETCH_FAILED]: types.EMPLOYER_SHIFT_RATE_TYPES_FETCH_FAILED,
  },
  {
    idKey: 'id',
    localEntityMap: true,
  }
)
