// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import * as types from '../actions/action-types'

const initialState = {
  hasData: false,
  isLoadingData: false,
  message: '',
  lastUploadedImage: {},
}

const rehydrateReset = {
  isLoadingData: false,
  message: '',
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.AUTH_DELETE:
      return initialState
    case types.REHYDRATION_COMPLETE:
      return {
        ...state,
        ...rehydrateReset,
      }
    case types.UPLOAD_IMAGE_BEGIN:
      return {
        ...state,
        message: '',
        isLoadingData: true,
        lastUploadedImage: {},
      }
    case types.UPLOAD_IMAGE_SUCCEEDED:
      return {
        ...state,
        lastUploadedImage: action.payload,
        isLoadingData: false,
        hasData: true,
        message: '',
      }
    case types.UPLOAD_IMAGE_FAILED:
      return {
        ...state,
        isLoadingData: false,
        message: action.message || action.payload.message,
        lastUploadedImage: {},
      }
    default:
      return state
  }
}

export default reducer
