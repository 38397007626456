import { generateEntityCrudReducer } from 'syft-acp-util/store/reducers'
import externalEmployerTypes from './action-types'
import type { ExternalEmployerEntity } from './types'

export default generateEntityCrudReducer<ExternalEmployerEntity>({
  name: 'externalEmployers',
  actions: externalEmployerTypes,
  options: {
    idKey: 'id',
    payloadProcess: ({ external_employer }: { external_employer: ExternalEmployerEntity }) => external_employer,
  },
})
