// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import React from 'react'
import PropTypes from 'prop-types'

import './RecordSegment.scss'

const RecordSegment = props => {
  const cls = ['segment']

  if (props.withTable) {
    cls.push('with-table')
  }
  if (props.noPadding) {
    cls.push('no-padding')
  }
  if (props.footer) {
    cls.push('footer')
  }
  if (props.noBorder) {
    cls.push('no-border')
  }
  if (props.className) {
    cls.push(props.className)
  }

  return <div className={cls.join(' ')}>{props.children}</div>
}
RecordSegment.propTypes = {
  children: PropTypes.node,
  withTable: PropTypes.bool,
  className: PropTypes.string,
  noPadding: PropTypes.bool,
  noBorder: PropTypes.bool,
  footer: PropTypes.bool,
}
RecordSegment.defaultProps = {
  children: null,
  withTable: null,
  className: '',
  noPadding: null,
}

export default RecordSegment
