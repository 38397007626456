import React, { useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Col, ControlLabel, FormGroup, Row } from 'react-bootstrap'

import { getModal$ } from 'syft-acp-core/store/modals/selectors'
import { Modal } from 'syft-acp-core/components/Modal'
import DataText from 'syft-acp-core/components/EditableTable/DataText'
import PlatformsAutocomplete from 'syft-acp-core/components/EditableTable/PlatformsAutocomplete'
import { hideModal } from 'syft-acp-core/store/modals/actions'
import * as actionsList from 'syft-acp-core/actions/agencies-list'
import { ScreenReaderOnlyText } from 'syft-acp-atoms/ScreenReaderOnlyText'

export const AddNewAgencyModalPure = ({ item, dispatch, actions, onConfirm = () => {} }) => {
  const labelWidth = 3
  const fieldWidth = 9
  const [platformIds, setPlaftormIds] = useState(null)
  const [email, setEmail] = useState(null)
  const [telephoneNumber, setTelephoneNumber] = useState(null)
  const [name, setName] = useState(null)
  const [errorText, setErrorText] = useState(null)

  const validate = useCallback(() => {
    if (!name) {
      setErrorText('The name is required!')
      return false
    }
    return true
  }, [name, setErrorText])

  const closeModal = useCallback(() => {
    dispatch(hideModal(item.modalName))
    setPlaftormIds(null)
    setEmail(null)
    setTelephoneNumber(null)
    setName(null)
    setErrorText(null)
  }, [dispatch, setEmail, setTelephoneNumber, setName, setErrorText, item])

  const onConfirmHandler = useCallback(() => {
    const isValid = validate()

    if (isValid) {
      const data = {
        name,
        telephone_number: telephoneNumber,
        email,
        platform_ids: platformIds,
      }
      actions.createAgency(data)
      onConfirm()
      closeModal()
    }
  }, [actions, closeModal, email, name, telephoneNumber, platformIds, validate, onConfirm])

  const onChangeValue = useCallback(
    func => value => {
      func(value)
    },
    []
  )

  return (
    <Modal
      header="Add New Agency"
      isShown={item.isShown}
      onClose={closeModal}
      onConfirm={onConfirmHandler}
      errorText={errorText || null}
      confirmationText="Invite"
    >
      <div className="missing-time-modal">
        {errorText ? (
          <ScreenReaderOnlyText alert="error" aria-live="assertive">
            {errorText}
          </ScreenReaderOnlyText>
        ) : null}
        <FormGroup className="form-item">
          <Row>
            <Col md={labelWidth}>
              <ControlLabel htmlFor="name">Name (required)</ControlLabel>
            </Col>
            <Col md={fieldWidth}>
              <DataText id="name" value={name} onChange={onChangeValue(setName)} editable enabled />
            </Col>
          </Row>
          <Row>
            <Col md={labelWidth}>
              <ControlLabel htmlFor="email">Email</ControlLabel>
            </Col>
            <Col md={fieldWidth}>
              <DataText id="email" value={email} onChange={onChangeValue(setEmail)} editable enabled />
            </Col>
          </Row>
          <Row>
            <Col md={labelWidth}>
              <ControlLabel htmlFor="telephoneNumber">Telephone number</ControlLabel>
            </Col>
            <Col md={fieldWidth}>
              <DataText
                id="telephoneNumber"
                value={telephoneNumber}
                onChange={onChangeValue(setTelephoneNumber)}
                editable
                enabled
              />
            </Col>
          </Row>
          <Row>
            <Col md={labelWidth}>
              <ControlLabel htmlFor="platform">Platform</ControlLabel>
            </Col>
            <Col md={fieldWidth}>
              <PlatformsAutocomplete id="platform" onChange={onChangeValue(setPlaftormIds)} />
            </Col>
          </Row>
        </FormGroup>
      </div>
    </Modal>
  )
}

AddNewAgencyModalPure.propTypes = {
  item: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  actions: PropTypes.object.isRequired,
  onConfirm: PropTypes.func,
}

export default connect(
  state => ({
    item: getModal$(state.modals)('addNewAgencyModal'),
  }),
  dispatch => ({
    dispatch,
    actions: bindActionCreators({ ...actionsList }, dispatch),
  })
)(AddNewAgencyModalPure)
