// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import { apiRequest, handleCall } from '../../call'

/**
 * Retrieves a list of worker networks.
 *
 * @param params {Object}
 * @param params.workerID {Number} The worker ID
 */
export const listWorkerNetworks = ({ workerID, page, toCSV = false }) =>
  new Promise((resolve, reject) => {
    const req = apiRequest({
      path: `/workers/${workerID}/trusted_network_employers`,
      returnAll: toCSV,
      reqArgs: { page },
    })
    handleCall(req, resolve, reject, true, { page }, null, toCSV, `worker-${workerID}-network`)
  })

/**
 * Retrieves a worker's trusted network.
 *
 * @param params {Object}
 * @param params.workerID {Number} The worker ID
 * @param params.networkID {Number} The network ID
 */
export const fetchWorkerNetwork = ({ workerID, networkID, options }) =>
  new Promise((resolve, reject) => {
    const req = apiRequest({ path: `/workers/${workerID}/trusted_network_employers/${networkID}` })
    handleCall(req, resolve, reject, true, options)
  })

/**
 * Removes a worker's trusted network.
 *
 * @param params {Object}
 * @param params.workerID {Number} The worker ID
 * @param params.objectIDs.locationID {Number} The location ID
 * @param params.objectIDs.employerID {Number} The employer ID
 */
export const deleteWorkerNetwork = ({ workerID, objectIDs }) =>
  new Promise((resolve, reject) => {
    const { employerID, locationID } = objectIDs
    const req = apiRequest({
      path: employerID
        ? `/employers/${employerID}/trusted_network_workers/${workerID}`
        : `/work_locations/${locationID}/trusted_network_workers/${workerID}`,
      reqArgs: null,
      method: 'DELETE',
    })
    handleCall(req, resolve, reject, true)
  })

/**
 * Links a worker to an employer or location
 *
 * @param params {Object}
 * @param params.workerID {Number} The worker ID
 * @param params.objectIDs.locationID {Number} The location ID
 * @param params.objectIDs.employerID {Number} The employer ID
 */
export const createWorkerNetwork = ({ workerID, objectIDs }) =>
  new Promise((resolve, reject) => {
    const { employerID, locationID } = objectIDs
    const req = apiRequest({
      path: employerID
        ? `/employers/${employerID}/trusted_network_workers/${workerID}`
        : `/work_locations/${locationID}/trusted_network_workers/${workerID}`,
      reqArgs: null,
      method: 'PUT',
    })
    handleCall(req, resolve, reject)
  })
