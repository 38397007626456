import React from 'react'
import PropTypes from 'prop-types'

import RatingStars from 'syft-acp-atoms/RatingStars'
import { cellPropTypes, cellDefaultProps } from '../dataProps'
import { colPlaceholderFactory } from '../../../placeholders/factory'

// Column placeholder component.
export const AcpColRating = colPlaceholderFactory('AcpCellRating')

/**
 * Column type for worker or employer ratings.
 *
 *    <Acp.Col.Rating value="rating" header="Avg. rating" />
 */
export default class AcpCellRating extends React.PureComponent {
  static propTypes = {
    ...cellPropTypes,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }

  static defaultProps = {
    ...cellDefaultProps,
  }

  // Displays the exact rating for the tooltip.
  static getTooltip = value => {
    if (value == null) return `Rating not set`
    return `Rating: ${Number(value).toFixed(1)}`
  }

  render() {
    const { value } = this.props
    return <RatingStars amount={value} />
  }
}
