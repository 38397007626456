// Syft ACP - Atoms <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import React from 'react'
import PropTypes from 'prop-types'
import { Nav, Navbar, NavItem } from 'react-bootstrap'
import { LinkContainer } from 'react-router-bootstrap'
import classnames from 'classnames'

import { SubNavigationBadge } from './SubNavigationBadge'
import './SubNavigation.css'

/** Single link towards a subpage. Used to provide the links for SubNavigation. */
export const SubLink = ({ children, to, withCounter, counterLength, highlighted, exact }) => (
  <LinkContainer to={to} exact={exact}>
    <NavItem className={classnames({ 'acpSubNav-highlighted': highlighted })}>
      {children}
      {withCounter && (
        <span>
          &nbsp;<SubNavigationBadge>{counterLength != null ? counterLength : '…'}</SubNavigationBadge>
        </span>
      )}
    </NavItem>
  </LinkContainer>
)

SubLink.propTypes = {
  withCounter: PropTypes.bool,
  highlighted: PropTypes.bool,
  children: PropTypes.node.isRequired,
  to: PropTypes.string,
  counterLength: PropTypes.number,
  exact: PropTypes.bool,
}

SubLink.defaultProps = {
  highlighted: false,
  withCounter: false,
  to: '',
  counterLength: 0,
}

/** Subnavigation component. */
const SubNavigation = ({ children }) => {
  return (
    <Navbar staticTop componentClass="header" className="acpSubNav" role="banner">
      <Navbar.Header>
        <Navbar.Toggle />
      </Navbar.Header>
      <Navbar.Collapse className="bs-navbar-collapse" role="navigation">
        <Nav role="navigation">{children}</Nav>
      </Navbar.Collapse>
    </Navbar>
  )
}

SubNavigation.propTypes = {
  children: PropTypes.node.isRequired,
}

export default SubNavigation
