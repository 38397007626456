import entityReducer from 'syft-acp-core/reducers/generators/entityReducer'
import * as entityTypes from 'syft-acp-core/reducers/generators/entities'

import * as types from './action-types'
import type { AppealEntity, AppealsReducerType, AppealsState, Reason } from '../types'
import { assigneeInitialState, setTsLastAssigned, setAssignees } from '../constants'

export default entityReducer<AppealEntity, AppealsState>(
  'appeals',
  {
    [entityTypes.ENTITIES_FETCH_BEGIN]: types.APPEALS_FETCH_BEGIN,
    [entityTypes.ENTITIES_FETCH_SUCCEEDED]: types.APPEALS_FETCH_SUCCEEDED,
    [entityTypes.ENTITIES_FETCH_FAILED]: types.APPEALS_FETCH_FAILED,
    [entityTypes.ENTITY_FETCH_BEGIN]: types.APPEAL_FETCH_BEGIN,
    [entityTypes.ENTITY_FETCH_SUCCEEDED]: types.APPEAL_FETCH_SUCCEEDED,
    [entityTypes.ENTITY_FETCH_FAILED]: types.APPEAL_FETCH_FAILED,
    [entityTypes.ENTITY_UPDATE_BEGIN]: types.APPEAL_UPDATE_BEGIN,
    [entityTypes.ENTITY_UPDATE_SUCCEEDED]: types.APPEAL_UPDATE_SUCCEEDED,
    [entityTypes.ENTITY_UPDATE_FAILED]: types.APPEAL_UPDATE_FAILED,
    [types.APPEALS_ASSIGN_SUCCEEDED]: setTsLastAssigned,
    [types.APPEALS_ASSIGNEES_FETCH_SUCCEEDED]: setAssignees,
    [types.APPEAL_REASONS_FETCH_SUCCEEDED]: (state: AppealsState, action: { payload: Reason[] }) => ({
      ...state,
      appealReasons: action.payload,
    }),
  },
  {
    initialState: { ...assigneeInitialState, appealReasons: [] },
  }
) as AppealsReducerType
