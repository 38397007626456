import entityReducer, { BaseEntity, UserOptions } from 'syft-acp-core/reducers/generators/entityReducer'
import * as entities from 'syft-acp-core/reducers/generators/entities'

import { GeneratedCrudActions } from './action-types'

export const generateEntityCrudReducer = <T extends BaseEntity>({
  name,
  actions,
  extraActions,
  options = { idKey: 'id' },
}: {
  name: string
  actions: GeneratedCrudActions
  extraActions?: Record<string | symbol, string | Function>
  options?: UserOptions<T>
}) =>
  entityReducer<T>(
    name,
    {
      ...Object.entries(actions).reduce((reducerActions, [actionKey, value]) => {
        reducerActions[(entities as any)[actionKey]] = value
        return reducerActions
      }, {} as Record<string, string>),
      ...extraActions,
    },
    options
  )
