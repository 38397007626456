export const trackingEvents = {
  PAGE: { VIEWED: 'appeals.page.viewed' },
  ASSIGN: { CLICKED: 'appeals.assign.clicked' },
  FILTERS: {
    OUTCOME: {
      CHANGED: 'appeals.filter_outcome.changed',
    },
    REASON: {
      CHANGED: 'appeals.filter_reason.changed',
    },
    ASSIGNEE: {
      CHANGED: 'appeals.filter_assignee.changed',
    },
  },
}
