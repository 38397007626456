import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import FilterSelectStates from 'syft-acp-util/components/FilterForm/FilterSelectStates'
import { getFederatedState } from 'syft-acp-util/states'

const DataStateDropdown = ({ value, editable, onChange, showDisabled }) => {
  const actions = {
    setFilter: useCallback((name, id, val) => onChange(val), [onChange]),
  }
  const stateName = getFederatedState(value)

  return editable || showDisabled ? (
    <FilterSelectStates name="state" value={value} actions={actions} disabled={!editable} />
  ) : (
    <div className="plain-text">{stateName}</div>
  )
}

DataStateDropdown.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  editable: PropTypes.bool,
  showDisabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
}

DataStateDropdown.defaultProps = {
  value: '',
  editable: false,
  showDisabled: false,
}

export default connect((state, { onChange }) => ({
  actions: {
    setFilter: (name, id, value) => onChange(value),
  },
}))(DataStateDropdown)
