// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import { get } from 'lodash-es'
import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import moment from 'moment'

import NotifyBar from 'syft-acp-atoms/NotifyBar'
import RecordSection from 'syft-acp-core/components/RecordSection'
import WorkerHeader from 'syft-acp-core/entities/WorkerDetail/WorkerHeader'
import BanWorkerModal from 'syft-acp-core/components/Modal/BanWorkerModal'
import { SUPPORTED_COUNTRY_CODES } from 'syft-acp-core/lib/i18n'
import * as workersActions from 'syft-acp-core/store/workers/actions'
import entityDetail from 'syft-acp-util/entityDetail'

import CountrySpecific from 'syft-acp-core/components/CountrySpecific'
import { WorkerStrikeDetailsProps as Props } from './WorkerOnboardingDetail.types'
import WorkerDetailControls from 'syft-acp-core/entities/WorkerDetail/WorkerDetailControls'

import { useInterviewStructure } from './WorkerOnboardingDetail.hooks'
import EntityDetailItem from 'syft-acp-core/components/EntityDetail/EntityDetailItem'
import RecordSegment from 'syft-acp-core/components/RecordSection/RecordSegment'
import RecordHeader from 'syft-acp-core/components/RecordSection/RecordHeader'
import { Box, Text } from '@indeed/ifl-components'
import { Link } from 'react-router-dom'

const WorkerOnboardingDetail = ({
  id,
  data,
  userEmail,
  actionUpdate,
  isPristine,
  actions,
  lastMessage = '',
}: Props) => {
  const [emptyInitialTrainingDetails] = useState(data?.training_details === null)

  useEffect(() => {
    const needsUpdate = !data || !data.id || !data.bio || !data.us_i9_form
    const workerID = get(data, 'id', id)
    if (needsUpdate) {
      actions.fetchWorker(workerID)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const [interviewTitle, interviewStructure] = useInterviewStructure()

  return (
    <>
      <WorkerDetailControls data={data} isPristine={isPristine} />

      <NotifyBar conditional noMargin visible={!!data.banned}>
        This user is currently banned from the system and cannot be assigned to do any jobs.
      </NotifyBar>

      <NotifyBar conditional noMargin visible={!!lastMessage}>
        {lastMessage}
      </NotifyBar>

      <WorkerHeader data={data} />
      <CountrySpecific countries={[SUPPORTED_COUNTRY_CODES.GB]}>
        <EntityDetailItem>
          <RecordSegment noPadding>
            <RecordHeader main>Driving licence</RecordHeader>
          </RecordSegment>
          <RecordSegment noPadding>
            <Box sx={{ padding: 4 }}>
              <Text level={2}>
                This functionality has been moved to the{' '}
                <Link to={`/entity/workers/view/${data?.id ?? id}/compliance/list`}>Compliance page</Link>
              </Text>
            </Box>
          </RecordSegment>
        </EntityDetailItem>
      </CountrySpecific>
      <RecordSection
        title={interviewTitle}
        data={data}
        structure={interviewStructure}
        update={actionUpdate}
        defaultData={
          data.training_details &&
          emptyInitialTrainingDetails && {
            interviewed_on: moment().format('YYYY-MM-DD'),
            interviewer: { email: userEmail },
          }
        }
      />
      <WorkerDetailControls data={data} isPristine={isPristine} />
      <BanWorkerModal userID={data.user_id} workerID={data.id} />
    </>
  )
}

export const storeConnector = connect(
  ({ auth }) => ({
    userEmail: `${get(auth, 'userData.email')}`,
  }),
  dispatch => ({
    actions: bindActionCreators(workersActions, dispatch),
  }),
)

export default storeConnector(entityDetail('workers', WorkerOnboardingDetail))
