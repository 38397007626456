// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import React from 'react'
import PropTypes from 'prop-types'

import { Button } from 'syft-acp-atoms/Button'

import './EntityListFeedback.css'

/**
 * Table body used for displaying error messages.
 *
 * @param {Object} props Properties
 * @constructor {XML} Pure EntityListFeedback component
 */
const EntityListFeedback = props => (
  <tbody className="entity-list-feedback" data-testid="entity-list-feedback">
    <tr className="notification">
      <td colSpan={props.cols} className="placeholder">
        <div className="content-outer">
          <div className="content-inner">
            <div className="status">{props.status}</div>
            <div className="feedback">{props.feedback}</div>
            {props.feedback !== '' && props.refetchData && !props.isLoadingData ? (
              <Button kind="primary" onClick={props.refetchData} className="refetch">
                Reload data
              </Button>
            ) : (
              ''
            )}
          </div>
        </div>
      </td>
    </tr>
  </tbody>
)
EntityListFeedback.propTypes = {
  cols: PropTypes.number.isRequired,
  status: PropTypes.string.isRequired,
  isLoadingData: PropTypes.bool.isRequired,
  feedback: PropTypes.string.isRequired,
  refetchData: PropTypes.func,
}

export default EntityListFeedback
