import * as React from 'react'
import { Col, Grid, Row } from 'react-bootstrap'

import PageView from 'syft-acp-core/components/PageView'
import TimeOffRequestDetail from 'syft-acp-core/entities2/TimeOffRequestDetail'

import SubNavigationAdmin from './SubNavigation'
import { RouteComponentProps } from 'react-router-dom'

export const AdminTimeOffRequestView = ({ match: { params } }: RouteComponentProps<{ id: string }>) => (
  <>
    <SubNavigationAdmin />
    <PageView title={['LTA', `Viewing time off request #${params.id}`]}>
      <Grid fluid>
        <Row>
          <Col md={12}>
            <TimeOffRequestDetail id={Number(params.id)} />
          </Col>
        </Row>
      </Grid>
    </PageView>
  </>
)
