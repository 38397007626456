/* eslint-disable react/no-danger */
// Syft ACP - Atoms <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import React from 'react'
import octicons from 'octicons'
import classnames from 'classnames'

// eslint-disable-next-line react/prop-types
const iconFactory =
  (icon, label = 'Icon') =>
  ({ iconSize = 12, className = '', fill = '', height = 0 }) => {
    const customHeight = height !== 0 ? { height } : {}
    return (
      <div
        className={classnames('icon', className)}
        dangerouslySetInnerHTML={{
          __html: octicons[icon].toSVG({
            'aria-hidden': label === 'hidden',
            'aria-label': label === 'hidden' ? undefined : label,
            width: iconSize,
            ...customHeight,
            class: 'img',
            fill,
          }),
        }}
      />
    )
  }

export default iconFactory
