import types from './action-types'
import { WorkerReferralBonusEntity } from './types'

export const fetchWorkerReferralBonuses = ({ options }: { options: Record<string, any> }) => ({
  type: types.ENTITIES_FETCH_BEGIN,
  options,
})

export const saveWorkerReferralBonus = (workerId: number, data: WorkerReferralBonusEntity) => ({
  type: types.ENTITY_CREATE_BEGIN,
  payload: {
    workerId,
    data,
  },
})

export const deleteWorkerReferralBonuses = (workerId: number, ids: number[]) => ({
  type: types.ENTITIES_DELETE_BEGIN,
  payload: {
    workerId,
    ids,
  },
})
