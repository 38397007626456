import { apiRequest, handleCall } from 'syft-acp-core/api/call'

const basePath = '/admin/syft_managers'

export const fetchAdminManagers = ({ payload: reqArgs }) =>
  new Promise((resolve, reject) => {
    const req = apiRequest({ path: `${basePath}/index`, reqArgs })
    handleCall(req, resolve, reject, true)
  })

export const fetchAdminManager = ({ id }) =>
  new Promise((resolve, reject) => {
    const req = apiRequest({ path: `${basePath}/${id}` })
    handleCall(req, resolve, reject, true)
  })

export const createAdminManager = ({ payload }) =>
  new Promise((resolve, reject) => {
    // FIXME: backend requires `manager_role` to contain the new data.
    // eslint-disable-next-line no-param-reassign
    payload.manager_role = payload.manager_roles
    const req = apiRequest({ path: `${basePath}/create`, reqArgs: payload, method: 'POST' })
    handleCall(req, resolve, reject, true)
  })

export const updateAdminManager = ({ id, payload }) =>
  new Promise((resolve, reject) => {
    // FIXME: backend requires `manager_role` to contain the new data.
    // eslint-disable-next-line no-param-reassign
    payload.manager_role = payload.manager_roles
    const req = apiRequest({ path: `${basePath}/${id}`, reqArgs: payload, method: 'PATCH' })
    handleCall(req, resolve, reject, true)
  })

export const deleteAdminManager = ({ id, payload: reqArgs }) =>
  new Promise((resolve, reject) => {
    const req = apiRequest({ path: `${basePath}/${id}`, reqArgs, method: 'DELETE' })
    handleCall(req, resolve, reject, true)
  })
