// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { get, pickBy, identity, isFunction, isEqual } from 'lodash-es'
import classnames from 'classnames'

import { arePropertiesEqual, getVisibleTraits } from 'syft-acp-core/store/filters/helpers'
import * as types from './cellTypes'
import cellFactories from './cellFactories'
import EntityListCellWrapper from './EntityListCellWrapper'
import tableFormatPropTypes from './tableFormatPropTypes'

/** Value displayed when a cell is invalid; e.g. when its data type does not match. */
export const emptyCellPlaceholder = '–'
const invalidCell = <span className="invalid-cell">{emptyCellPlaceholder}</span>

/** Reducer that replaces all null or undefined values of 'a' with those of 'b'. */
const defaultsNull = (a, b) => (acc, k) => ({ ...acc, [k]: a[k] == null ? b[k] : a[k] })

/**
 * Returns the number of subrows in a row. Used on the shifts listing page.
 */
const getSubrowCount = (tableFormat, data) => {
  let rowDataValue
  for (const rowData of tableFormat) {
    if (types.subRowTypes.indexOf(rowData.type) > -1 && isFunction(rowData.val)) {
      // Run the code to get the value.
      rowDataValue = rowData.val(data, rowData)
      // If we received an array, it means we have subrows.
      if (rowDataValue.length) {
        return rowDataValue.length
      }
    }
  }
  // If there are no subrows, render everything in one single row.
  return 1
}

/**
 * Returns the attributes and content (component) of a cell.
 */
const getCellAttributes = (rowFormat, data, entityType, baseURL, editCallback, rowNumber) => {
  // These are the classes either supplied by the row, or by our defaults.
  const rowClasses = rowFormat.classes ? rowFormat.classes : [types.typeClasses[rowFormat.type]]

  // The content (value) of this cell is 'null' by default, because it does not need
  // to be specified (e.g. in case of the selector type cell, which doesn't need data).
  // If 'vals' is set, send an array of values to the component.
  let cellValue = null
  if (rowFormat.vals) {
    cellValue = isFunction(rowFormat.vals)
      ? rowFormat.vals(data, rowFormat, editCallback, rowNumber)
      : rowFormat.vals.map(val => get(data, val))
  } else if (rowFormat.val) {
    cellValue = isFunction(rowFormat.val)
      ? rowFormat.val(data, rowFormat, editCallback, rowNumber)
      : get(data, rowFormat.val)
  }

  const cellOptions = pickBy(
    isFunction(rowFormat.options) ? rowFormat.options(data, cellValue, entityType) : rowFormat.options || {},
    identity,
  )

  // Produce the current cell's contents.
  const cellContent = cellFactories[rowFormat.type](
    cellValue,
    { ...rowFormat, options: cellOptions },
    data,
    entityType,
  )

  // Merge in our defaults. Anything that is undefined or null in cellData
  // will be replaced with the values listed here.
  const cellDefaults = {
    // If the content is 'null', replace it with an "invalid cell" placeholder.
    content: invalidCell,
    type: rowFormat.type,
    rowURL: baseURL,
    rowClass: null,
    rowAttributes: [],
    linkAttributes: [],
    hasInnerLink: false,
    hasOuterLink: rowFormat.hasLink !== false,
  }

  const cellData = Object.keys(cellDefaults).reduce(defaultsNull(cellContent, cellDefaults), {})

  return {
    ...cellData,
    rowAttributes: [...cellData.rowAttributes, ...rowClasses],
  }
}

/**
 * A single row for the EntityList component.
 */
class EntityListRow extends Component {
  static propTypes = {
    data: PropTypes.object,
    entityType: PropTypes.string.isRequired,
    hasLinks: PropTypes.bool,
    idKey: PropTypes.string.isRequired,
    rowClasses: PropTypes.func,
    editCallback: PropTypes.func,
    rowNumber: PropTypes.number,
    selectCallback: PropTypes.func,
    selectedItems: PropTypes.array,
    selectedRow: PropTypes.shape({ key: PropTypes.string, value: PropTypes.any }),
    selectFullRow: PropTypes.bool,
    tableFormat: tableFormatPropTypes,
    urlBase: PropTypes.string.isRequired,
    urlGenerator: PropTypes.func,
  }

  static defaultProps = {
    tableFormat: [],
    data: {},
    hasLinks: undefined,
    rowClasses: undefined,
    editCallback: undefined,
    rowNumber: undefined,
    selectCallback: undefined,
    selectedItems: [],
    selectedRow: undefined,
    selectFullRow: false,
    urlGenerator: undefined,
  }

  shouldComponentUpdate(nextProps) {
    // List all 'val' items of the table format, minus functions.
    const visibleTraits = getVisibleTraits(nextProps.tableFormat)
    const hasSameData = arePropertiesEqual(this.props.data, nextProps.data, visibleTraits)
    if (!hasSameData) {
      return true
    }

    const hasSameDeepData = isEqual(this.props.data, nextProps.data)
    return !hasSameDeepData
  }

  render() {
    const { urlGenerator, urlBase, idKey, tableFormat, entityType, hasLinks, data } = this.props

    // Standard URL for all rows, unless otherwise specified.
    const baseURL = urlGenerator ? urlGenerator(data) : `${urlBase}${get(data, idKey)}`

    // A row can be selected either if selectedItems contains its index number (normal multiple selection)
    // or if its data matches selectedRow.
    const isSelected =
      this.props.selectedItems.indexOf(get(data, idKey)) > -1 ||
      (this.props.selectedRow ? data[this.props.selectedRow.key] === this.props.selectedRow.value : false)

    // A row might have multiple subrows. This is determined by whether any of our
    // column types is SUBROW. If so, we must figure out how many subrows there are
    // so we can add the appropriate number of rowspan values to our cells.
    const subRowCount = getSubrowCount(tableFormat, data)
    const subRows = []
    // Bind this row's data to the callback function if we have one. Data is the same per row.
    const rowSelectCallback = this.props.selectCallback
      ? this.props.selectCallback.bind(null, data)
      : () => {}
    for (let n = 0; n < subRowCount; ++n) {
      // For every cell in our row, iterate over the table format and return
      // a formatted cell component.
      const cells = tableFormat.map((rowFormat, m) => {
        // Retrieve the content of each cell.
        const cellData = getCellAttributes(
          rowFormat,
          data,
          entityType,
          baseURL,
          this.props.editCallback,
          this.props.rowNumber,
        )

        // Anything that is not a subrow only gets included in the first iteration.
        const isSubRow = types.subRowTypes.indexOf(cellData.type) > -1
        if (n > 0 && !isSubRow) {
          return null
        }

        let cellContent
        if (isSubRow && React.isValidElement(cellData.content) && cellData.content.type !== 'span') {
          // Inject the row number into the child component.
          cellContent = React.cloneElement(cellData.content, { rowNumber: n })
        } else if (cellData.type === types.SELECTOR && isSelected) {
          // If this is a selector, and the row is selected, ensure that the checkbox is checked.
          cellContent = React.cloneElement(cellData.content, { checked: true })
        } else {
          cellContent = cellData.content
        }

        // Rows can have extra classes that are applied to the <td>.
        const combinedClasses = [
          ...cellData.rowAttributes,
          get(cellData, `rowClass.${n}`),
          `col-${m}`,
          `row-${n}`,
        ]

        // If tooltip data is provided, retrieve it now using the data.
        const tooltipInfo = rowFormat.valTooltip ? rowFormat.valTooltip(data) : {}

        // Return one standard cell.
        return (
          <EntityListCellWrapper
            className={combinedClasses.join(' ')}
            enableLink={hasLinks}
            tooltipVal={tooltipInfo}
            hasLink={cellData.hasOuterLink}
            isSubRow={isSubRow}
            key={String(m)}
            linkAttributes={cellData.linkAttributes}
            rowURL={cellData.rowURL}
            selectCallback={rowSelectCallback}
            selectedItems={this.props.selectedItems}
            selectFullRow={this.props.selectFullRow}
            subRows={subRowCount}
            type={cellData.type}
          >
            {cellContent}
          </EntityListCellWrapper>
        )
      })

      subRows.push(cells)
    }
    const rowClassNames = classnames(['clickable', this.props.rowClasses && this.props.rowClasses(data)])

    return (
      <tbody className={isSelected ? 'selected' : null}>
        {subRows.map((row, n) => (
          <tr key={n} className={rowClassNames} data-testid="entity-list-row">
            {row}
          </tr>
        ))}
      </tbody>
    )
  }
}

export default EntityListRow
