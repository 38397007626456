// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import React from 'react'

import { TrackingTrigger, TrackingProvider } from '@indeed/flex-tracking-context'

import PageView from 'syft-acp-core/components/PageView'
import WorkerStrikeDetails from 'syft-acp-core/entities/WorkerStrikes/WorkerStrikeDetails'
import { trackingEvents } from 'syft-acp-core/entities/WorkerStrikes/WorkerStrikeDetails/WorkerStrikeDetails.tracking'
import { SAVE_BUTTON_EVENT } from 'syft-acp-core/entities/WorkerStrikes/WorkerStrikeDetails/WorkerStrikeDetails.helpers'

import { WorkerSubNavigation } from './SubNavigation'
import { RouteComponentProps } from 'react-router-dom'

type Props = RouteComponentProps<{
  id: string
  strikeID: string
}>

export const EntityWorkersStrikesView = ({ match: { params } }: Props) => (
  <TrackingProvider
    value={{
      context: {
        worker_id: params.id,
        strike_id: params.strikeID,
      },
      eventMappings: event => (event === SAVE_BUTTON_EVENT ? trackingEvents.SAVE_BUTTON.CLICKED : event),
    }}
  >
    <TrackingTrigger
      onLoad={{
        event: trackingEvents.PAGE.VIEWED,
      }}
    />
    <WorkerSubNavigation workerID={params.id} />,
    <PageView title={[`Viewing strike ${params.strikeID} for worker ${params.id}`]}>
      <WorkerStrikeDetails workerID={Number(params.id)} id={Number(params.strikeID)} />
    </PageView>
  </TrackingProvider>
)
