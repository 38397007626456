import { createSelector } from 'reselect'
import type { VenueCategory } from './types'

type State = {
  venueCategories: { entityMap: Record<string, any> }
}

const selectVenueCategories = (state: State) => state.venueCategories
const selectEmployerId = (state: State, employerId: number) => employerId

export const selectVenueCategoryOptionsByEmployerId = createSelector(
  [selectVenueCategories, selectEmployerId],
  ({ entityMap = {} }, employerId: number) =>
    Object.values(entityMap).flatMap((venueCategory: VenueCategory) =>
      venueCategory.employer_id === employerId ? { id: venueCategory.id, label: venueCategory.name } : []
    )
)
