import React from 'react'
import PropTypes from 'prop-types'

import UserAvatar from 'syft-acp-core/components/UserAvatar'
import './UserLine.css'

const UserLine = ({ workerPlatform, avatarUUID = '', name, platformDisplayName }) => (
  <span className="user-line">
    {avatarUUID && <UserAvatar avatar={avatarUUID} name={name} size="xsmall" shape="rounded-square" />}
    <span>{name}</span>
    {(workerPlatform === 'own' || (workerPlatform === 'agency' && platformDisplayName)) && (
      <span className="platform-name">{platformDisplayName || 'Flex+'}</span>
    )}
  </span>
)

UserLine.propTypes = {
  workerPlatform: PropTypes.string,
  avatarUUID: PropTypes.string,
  name: PropTypes.string,
  platformDisplayName: PropTypes.string,
}

UserLine.defaultProps = {
  workerPlatform: '',
  avatarUUID: '',
  name: '',
  platformDisplayName: undefined,
}

export default UserLine
