import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Label } from 'react-bootstrap'
import { fetchIndustries } from 'syft-acp-core/actions/industries'
import { keyBy } from 'lodash-es'

import './RoleAndSkills.css'

// Skill item container
const ArrayItem = ({ isSpecial, children }) => (
  <Label className={classnames('role-and-skills-skill', { 'is-special': isSpecial })} bsStyle="default">
    <span className="content">{children}</span>
  </Label>
)

/**
 * Component that displays a role and skills.
 */
class RoleAndSkills extends React.PureComponent {
  static propTypes = {
    skills: PropTypes.array.isRequired,
    roleID: PropTypes.number.isRequired,
    allRoles: PropTypes.object.isRequired,
  }

  componentDidMount() {
    if (Object.keys(this.props.allRoles).length === 0) {
      this.props.actions.fetchIndustries()
    }
  }

  render() {
    const { skills, roleID, allRoles } = this.props
    const currRole = allRoles[roleID]
    return (
      <div className="RoleAndSkills">
        <div className="role">
          {currRole.title}
          {currRole.subindustry ? ` (${currRole.subindustry})` : ''}
        </div>
        <div className="skills">
          {skills.map((skill, n) => (
            <ArrayItem key={n} isSpecial={skill.special}>
              {skill.title}
            </ArrayItem>
          ))}
        </div>
      </div>
    )
  }
}

export default connect(
  state => {
    // Retrieve all roles from the industries endpoint.
    const allRoles = keyBy(
      Object.values(state.industries.entityMap).reduce((roles, ind) => [...roles, ...ind.roles], []),
      'id'
    )

    return {
      allRoles,
    }
  },
  dispatch => ({
    actions: bindActionCreators({ fetchIndustries }, dispatch),
  })
)(RoleAndSkills)
