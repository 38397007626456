import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Grid, Col, Row } from 'react-bootstrap'

import entityDetail from 'syft-acp-util/entityDetail'

import * as structureTypes from 'syft-acp-core/components/EditableTable'
import EntityDetailItem from 'syft-acp-core/components/EntityDetail/EntityDetailItem'
import RecordName from 'syft-acp-core/components/RecordSection/RecordName'
import RecordSection from 'syft-acp-core/components/RecordSection'
import SiteComponent from 'syft-acp-core/components/SiteComponent'

import UserSettingsControls from './UserSettingsControls'

/* TODO: we can update the values directly and omit the save button.

updateCallback = (name, value) => {
  // Directly set the value in the store.
  this.props.actions.setValue(name, value, this.props.id)
}

Maybe update this in the future. For now, it works like all other pages with a save button.
*/

const structure = [
  [
    'language',
    {
      header: 'Language',
      editable: false,
      type: structureTypes.TYPE_LANGUAGE_DROPDOWN,
    },
  ],
  [
    'country',
    {
      header: 'Country',
      editable: false,
      type: structureTypes.TYPE_COUNTRY_DROPDOWN,
    },
  ],
  [
    'timezone',
    {
      header: 'Time zone',
      editable: true,
      expl: 'Selected time zone will automatically update to and from DST when applicable.',
      type: structureTypes.TYPE_TIMEZONE_DROPDOWN,
    },
  ],

  ['', { type: structureTypes.TYPE_SEPARATOR }],

  [
    'darkModeEnabled',
    {
      header: 'Dark mode',
      editable: true,
      label: 'Enable dark mode (experimental and not entirely done yet)',
      type: structureTypes.TYPE_DARK_MODE_TOGGLE,
    },
  ],
]

const UserSettings = props => {
  const user = props.userData
  return (
    <SiteComponent>
      <Grid>
        <Row>
          <Col md={12} sm={12}>
            <UserSettingsControls id={props.id} data={props.data} isPristine={props.isPristine} />
            {user.worker && ( // Note: user ID 1 is the only user that doesn't have a worker.
              <EntityDetailItem>
                <RecordName name={user.worker.name} bio={user.worker.bio} avatar={user.worker.profile_picture.uuid} />
              </EntityDetailItem>
            )}
            <RecordSection title="User settings" data={props.data} structure={structure} update={props.actionUpdate} />
          </Col>
        </Row>
      </Grid>
    </SiteComponent>
  )
}
UserSettings.propTypes = {
  id: PropTypes.number.isRequired,
  userData: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  actionUpdate: PropTypes.func.isRequired,
  isPristine: PropTypes.bool.isRequired,
}

export default connect(state => ({
  userData: state.auth.userData,
}))(entityDetail('userSettings', UserSettings))
