import autoOfferRateTypes from './action-types'
import { AutoOfferRateActionPayload } from './types'

export const fetchAutoOfferRate = ({ options }: { options: Partial<AutoOfferRateActionPayload> }) => ({
  type: autoOfferRateTypes.ENTITIES_FETCH_BEGIN,
  options,
})

export const downloadAutoOfferRateCSV = ({ options }: { options: Partial<AutoOfferRateActionPayload> }) => ({
  type: autoOfferRateTypes.AUTO_OFFER_RATES_CSV_DOWNLOAD_BEGIN,
  options,
})

export type AutoOfferRateOptionType =
  | ReturnType<typeof fetchAutoOfferRate>
  | ReturnType<typeof downloadAutoOfferRateCSV>
