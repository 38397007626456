// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import './NotifyBar.scss'

const alertStyles = {
  // TODO: add more.
  success: 'success',
  error: 'danger',
  warning: 'warning',
}

/** Displays a bar with text inside, notifying the user about a potential issue or error. */
// TODO: change api, change 'noMargin' maybe since it's the more common case.
const NotifyBar = ({ severity, visible, noMargin, conditional, children }) =>
  (conditional && visible) || !conditional ? (
    <div
      data-testid="notify-bar"
      className={classnames('NotifyBar', `type-${alertStyles[severity]}`, { 'no-margin': noMargin })}
    >
      <div className="inner">{children}</div>
    </div>
  ) : null

NotifyBar.propTypes = {
  severity: PropTypes.string,
  children: PropTypes.node,
  visible: PropTypes.bool,
  noMargin: PropTypes.bool,
  conditional: PropTypes.bool,
}

NotifyBar.defaultProps = {
  children: null,
  severity: 'error',
  visible: false,
  noMargin: false,
  conditional: false,
}

export default NotifyBar
