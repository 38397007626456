// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import { takeLatest, call, put, take, fork, select } from 'redux-saga/effects'
import { removeAll } from 'react-notification-system-redux'
import { get } from 'lodash-es'
import { datadogRum } from '@datadog/browser-rum-slim'

import { fetchIndustries } from 'syft-acp-core/actions/industries'
import { fetchCities } from 'syft-acp-core/actions/cities'
import { fetchCountries } from 'syft-acp-core/actions/countries'

import * as types from 'syft-acp-core/actions/action-types'
import * as api from 'syft-acp-core/api'
import {
  loginSucceeded,
  loginFailed,
  loginSSOFailed,
  refreshAuthToken,
  refreshAuthTokenIfOld,
  logOut,
} from 'syft-acp-core/actions/auth'
import wait from 'syft-acp-util/wait'

import { takeLatestApiCall, withRedirectUrlSearchParam } from './helpers'
import { redirectCall } from './calls'
import { listRoles } from '../store/roles/actions'
import { listDeliveryTeams } from '../store/delivery-teams/actions'

// The duration of one week in milliseconds.
const ONE_WEEK = 604800

/**
 * Saga for user login. When the login promise resolves, we redirect the user to the root path.
 * @param {Object} action Action data, containing the user's email and password
 */
function* loginCall(action) {
  try {
    const payload = yield call(api.loginUser, action.email, action.password)
    if (!payload?.body?.error) {
      yield put(loginSucceeded(payload, true))
      yield put(removeAll())
    } else {
      yield put(loginFailed(payload))
    }
  } catch (e) {
    yield put(loginFailed(e))
  }
}

/**
 * Saga for user login via SSO. When the login promise resolves, we redirect the user to the SSO login.
 * @param {Object} action Action data, containing the user's email
 */
export function* loginWithSSOCall(action) {
  try {
    // Get SSO type login addres
    const payload = yield call(api.loginUserWithSSO, action.email)
    const redirectUrl = payload?.payload?.redirect_url
    if (redirectUrl) {
      const location = window.location.href
      const oneLoginUrl = withRedirectUrlSearchParam(redirectUrl, location)
      // Open SSO login address with redirect to current url
      yield call([window.location, window.location.assign], oneLoginUrl)
    }
  } catch (e) {
    yield put(loginSSOFailed(e))
  }
}

/**
 * Saga for user login refeeem via code. When the login promise resolves, we redirect the user to the root path.
 * @param {Object} action Action data, containing the user's code
 */
export function* loginSSORedeemCall(action) {
  try {
    // Call to get token and user info
    const payload = yield call(api.loginUserRedeemSSO, action.code)
    if (!payload?.body?.error) {
      yield put(loginSucceeded(payload.payload, true))
      yield put(removeAll())
    } else {
      yield put(loginFailed(payload.payload))
    }
  } catch (e) {
    yield put(loginFailed(e))
  }
}

export function* logoutCall(action) {
  const token = yield select(state => get(state, 'auth.oauthData.access_token'))
  yield call(api.logoutUser, { token })
  yield put(logOut())
  yield call(redirectCall, () => '/login', action)
}

/**
 * Refreshes the auth tokens if they are nearing expiration (will expire one week from now).
 */
export function* refreshOldAuth() {
  const oauth = yield select(state => state.auth.oauthData)

  // Convert to milliseconds for Javascript.
  const expires = (oauth.created_at + oauth.expires_in) * 1000
  const now = Number(new Date())
  if (expires - now < ONE_WEEK) {
    yield put(refreshAuthToken())
  }
}

/**
 * Loads initial data after logging in or app rehydrated with logged in user.
 */
export function* loadInitData() {
  const auth = yield select(state => state.auth)
  if (!!auth.userData && auth.isLoggedIn) {
    yield put(fetchCountries())
    yield put(fetchIndustries())
    yield put(listRoles())
    yield put(listDeliveryTeams())
  }
}

/**
 * Loads initial data after logging in.
 */
function* loadCountrySpecificData() {
  yield put(fetchCities())
}

/**
 * Check our auth token 10-20 seconds after boot-up,
 * then check every once in a while if the token needs updating.
 */
function* setupAuthRefresh() {
  yield take(types.REHYDRATION_COMPLETE)
  yield call(wait, 5 + Math.round(Math.random() * 1) * 1000)
  yield put(refreshAuthTokenIfOld())
  while (true) {
    // Wait 10 minutes.
    yield call(wait, 600000 - 10000)
    yield put(refreshAuthTokenIfOld())
  }
}

export function* setDataDogUserContext() {
  const { isLoggedIn, userData } = yield select(state => state.auth || {})

  if (isLoggedIn) {
    yield call(datadogRum.setUser, {
      id: userData.id,
      type: userData?.admin_account?.type ?? 'admin',
    })
  }
  return userData
}

export function* respondToSessionChange() {
  let prevUser = yield call(setDataDogUserContext)

  yield takeLatest(
    action =>
      [
        types.REHYDRATION_COMPLETE,
        types.LOGIN_SUCCEEDED,
        types.AUTH_LOG_OUT,
        types.AUTH_REFRESH_TOKEN_SUCCEEDED,
        types.AUTH_REFRESH_OLD_TOKEN_SUCCEEDED,
      ].includes(action.type),
    function* () {
      const { userData } = yield select(state => state.auth || {})

      if (prevUser?.id !== userData?.id) {
        yield call(datadogRum.clearUser)
        yield call(setDataDogUserContext)
      }

      prevUser = userData
    },
  )
}

export default function* root() {
  yield takeLatest(types.REHYDRATION_COMPLETE, refreshOldAuth)
  yield takeLatest(types.LOGIN_BEGIN, loginCall)
  yield takeLatest(types.LOGIN_SSO_BEGIN, loginWithSSOCall)
  yield takeLatest(types.LOGIN_REDEEM_BEGIN, loginSSORedeemCall)
  yield takeLatest(types.AUTH_LOG_OUT_BEGIN, logoutCall)
  yield takeLatest(
    [types.REHYDRATION_COMPLETE, types.LOGIN_SUCCEEDED, types.AUTH_REFRESH_TOKEN_SUCCEEDED],
    loadInitData,
  )
  yield takeLatest([types.COUNTRIES_FETCH_SUCCEEDED, types.COUNTRIES_FETCH_FAILED], loadCountrySpecificData)
  yield takeLatestApiCall(types.AUTH_REFRESH_TOKEN_BEGIN, api.refreshAuth)
  yield takeLatestApiCall(types.AUTH_REFRESH_OLD_TOKEN_BEGIN, refreshOldAuth)
  yield takeLatestApiCall(types.RESET_PASSWORD_BEGIN, api.resetPassword)

  // Setup the token refresh callback.
  yield fork(setupAuthRefresh)
  yield fork(respondToSessionChange)
}
