import { all, call, put, takeLatest } from 'redux-saga/effects'

import { notificationCall } from 'syft-acp-core/sagas/calls'
import { takeEveryApiCall, takeLatestApiCall } from 'syft-acp-core/sagas/helpers'

import actionTypes from './action-types'
import * as actions from './actions'
import { fetchEmployerPreferences, updateEmployerPreferences } from './api'
import { AnyAction } from '@reduxjs/toolkit'
import { EmployerPreferenceEntity } from './types'

export default function* saga() {
  yield takeEveryApiCall(actionTypes.ENTITIES_FETCH_BEGIN, fetchEmployerPreferences)
  yield takeLatest([actionTypes.ENTITIES_FETCH_FAILED, actionTypes.ENTITIES_UPDATE_FAILED], function* (action) {
    yield call(notificationCall, action)
  })
  yield takeLatestApiCall(actionTypes.ENTITIES_UPDATE_BEGIN, updateEmployerPreferences)
  yield takeLatest(
    actionTypes.ENTITIES_UPDATE_BEGIN,
    function* (action: ReturnType<typeof actions.updateEmployerPreferences>) {
      yield all(
        action.payload.preferences.map(preference =>
          put({
            type: actionTypes.ENTITY_UPDATE_BEGIN,
            payload: preference,
            options: action.options,
          })
        )
      )
    }
  )
  yield takeLatest(actionTypes.ENTITIES_UPDATE_SUCCEEDED, function* (action: AnyAction) {
    yield all(
      (action.payload as EmployerPreferenceEntity[]).map(preference =>
        put({
          type: actionTypes.ENTITY_UPDATE_SUCCEEDED,
          payload: preference,
          options: action.options,
        })
      )
    )
  })
}
