import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { get } from 'lodash-es'

import * as filtersActions from 'syft-acp-core/store/filters/actions'
import { filterSelect } from './FilterSelect'

class FilterDropdown extends PureComponent {
  static propTypes = {
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    options: PropTypes.array,
    actions: PropTypes.object,
    allowAny: PropTypes.bool,
    disabled: PropTypes.bool,
    convertIDs: PropTypes.bool,
    categorized: PropTypes.bool,
    name: PropTypes.string.isRequired,
    type: PropTypes.string,
    className: PropTypes.string,
    defaultOption: PropTypes.string,
  }

  static defaultProps = {
    defaultOption: 'Any',
    className: '',
    value: null,
    options: [],
    actions: {},
    allowAny: false,
    disabled: false,
    type: null,
    convertIDs: false,
    categorized: false,
  }

  onChange = val => {
    const { actions, name } = this.props
    actions.setFilter(name, val, val)
  }

  render() {
    const { value, allowAny, disabled, type, name, options, defaultOption, className, convertIDs, categorized } =
      this.props
    const FilterSelect = filterSelect(options, defaultOption, type, convertIDs, categorized)

    return (
      <FilterSelect
        name={name}
        className={className}
        onChange={this.onChange}
        value={value}
        allowAny={allowAny}
        disabled={disabled}
      />
    )
  }
}

export default connect(
  (state, ownProps) => {
    const queryAttribute = get(ownProps, 'queryAttribute', get(ownProps, 'name'))
    return {
      value: ownProps.value || state.routing.locationBeforeTransitions.query[queryAttribute],
    }
  },
  dispatch => ({
    actions: bindActionCreators({ ...filtersActions }, dispatch),
  })
)(FilterDropdown)
