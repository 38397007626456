// Syft ACP - UI Library <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import * as React from 'react'
import { FormControl } from 'react-bootstrap'

import { acpFilterDefaults } from '../../dataProps'
import { filterDecorator } from '../../filterDecorator'
import { AcpFilterNumberProps, AcpFilterNumberState } from './AcpFilterNumber.types'

class AcpFilterNumber extends React.PureComponent<AcpFilterNumberProps, AcpFilterNumberState> {
  static defaultProps = acpFilterDefaults

  static requireDebounce = true

  constructor(props: AcpFilterNumberProps) {
    super(props)
    this.state = { value: props.initialValue || props.value || '' }
  }

  isNumber = (value: any) => typeof value === 'number' && !isNaN(value)

  handleChange = (e: React.FormEvent<FormControl>) => {
    const { name, min, max, setValue, onChange } = this.props
    let { value } = e.target as HTMLInputElement
    const numberValue = parseInt(value, 10)

    // (min | max || 0) is here so that TS knows they are defined (it cannot infer this from isNumber).
    // The values will never actually resolve to 0 because isNumber already checks whether they are defined.
    if (this.isNumber(numberValue) && this.isNumber(min) && numberValue < (min || 0)) value = min + ''
    if (this.isNumber(numberValue) && this.isNumber(max) && numberValue > (max || 0)) value = max + ''

    this.setState({ value })
    setValue(name || '', value)
    !!onChange && onChange(name || '', value)
  }

  render() {
    const { placeholder, isDisabled } = this.props

    return (
      <FormControl
        type="number"
        value={this.state.value || ''}
        placeholder={placeholder}
        onChange={this.handleChange}
        disabled={isDisabled}
      />
    )
  }
}

export default filterDecorator(AcpFilterNumber)
