import React, { useState } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import * as filtersActions from 'syft-acp-core/store/filters/actions'
import { Popover } from '@material-ui/core'
import { Button } from 'syft-acp-atoms/Button'
import { RecordHeaderForm } from '../AcpComponent/componentSegments'
import iconFactory from 'syft-acp-atoms/Icons'
import S from './AcpListPopoverFilters.module.scss'

import { AcpListPopoverFiltersProps } from './types'

const ChevronDownIcon = iconFactory('chevron-down', 'Chevron down')
const ChevronUpIcon = iconFactory('chevron-right', 'Chevron left')

export const AcpListPopoverFilters = ({
  data,
  subDataSpec,
  stateSelection,
  hasNarrowActions,
  stateSubSelection,
  isLoading,
  actionsNode,
  actions,
  initialFilters,
  popoverButtonLabel,
  onOpen,
  onClose,
  legend,
}: AcpListPopoverFiltersProps) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
  const open = Boolean(anchorEl)

  const handleClick = (event: { currentTarget: HTMLButtonElement }) => {
    !open && !!onOpen && onOpen()
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    !!onClose && onClose()
    setAnchorEl(null)
  }
  const id = open ? 'simple-popover' : undefined
  return (
    <div className="container modals">
      <div className={S['acp-list-filter-container']}>
        <div className={S['acp-list-filter-popup']}>
          <Button
            className={S['acp-list-filter-button']}
            aria-describedby={id}
            variant="contained"
            onClick={handleClick}
          >
            <div>
              <div className={S['acp-list-filter-button__label']}>{popoverButtonLabel || 'View Filters'}</div>
              {anchorEl === null ? <ChevronUpIcon iconSize={10} /> : <ChevronDownIcon />}
            </div>
          </Button>
        </div>
        {legend}
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
        >
          <div className={S['acp-list-popover-container']}>
            <RecordHeaderForm
              data={data}
              subDataSpec={subDataSpec}
              stateSelection={stateSelection}
              stateSubSelection={stateSubSelection}
              isNarrow={hasNarrowActions}
              isLoading={isLoading}
              handleReset={() => actions.resetFilters(initialFilters)}
            >
              {/* @ts-expect-error have no clue why PropTypes.node doesnt work */}
              {actionsNode.children}
            </RecordHeaderForm>
          </div>
        </Popover>
      </div>
    </div>
  )
}

export default connect(null, dispatch => ({
  actions: bindActionCreators(filtersActions, dispatch),
}))(AcpListPopoverFilters)
