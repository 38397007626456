import React, { useCallback } from 'react'
import { useForm } from 'react-final-form'
import { Button } from 'react-bootstrap'
import { Props } from './ToggleFields.types'

export const ToggleFields = ({ name, allValues }: Props) => {
  const form = useForm()
  const onClick = useCallback(() => {
    const fields = form.getRegisteredFields()

    if (fields.includes(name)) {
      const fieldState = form.getFieldState(name)

      if (Object.keys(fieldState?.value || {}).length > 0) {
        form.change(name, null)
      } else {
        form.change(name, allValues)
      }
    }
  }, [allValues, form, name])

  return (
    <Button bsStyle="link" onClick={onClick}>
      Select / unselect all
    </Button>
  )
}
