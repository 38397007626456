import entityReducer from './generators/entityReducer'
import * as types from '../actions/action-types'
import * as entityTypes from './generators/entities'

export default entityReducer(
  'workerUpcomingJobs',
  {
    [entityTypes.ENTITIES_FETCH_BEGIN]: types.WORKER_UPCOMING_JOBS_FETCH_BEGIN,
    [entityTypes.ENTITIES_FETCH_SUCCEEDED]: types.WORKER_UPCOMING_JOBS_FETCH_SUCCEEDED,
    [entityTypes.ENTITIES_FETCH_FAILED]: types.WORKER_UPCOMING_JOBS_FETCH_FAILED,
  },
  {
    idKey: 'id',
    localEntityMap: true,
    groupBy: ['workerID'],
  }
)
