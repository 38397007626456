// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { range } from 'lodash-es'
import classnames from 'classnames'

import { Button, ButtonLink, ButtonGroup } from 'syft-acp-atoms/Button'
import { getPageQueryString } from 'syft-acp-core/store/filters/helpers'

import './PaginationButtons.css'

const LABEL_FIRST = '«'
const LABEL_PREV = '‹'
const LABEL_NEXT = '›'
const LABEL_LAST = '»'
const LABEL_ELLIPSIS = '…'

/**
 * Displays page numbers for navigation.
 *
 * e.g.:
 * [«][‹]{1}[2][3][4][5][…][29][›][»]
 *
 * Displays a maximum of (by default) five buttons, except if the prev/next buttons are the first/last.
 * Hides the extra buttons with an ellisis symbol.
 */
export default class PaginationButtons extends PureComponent {
  static propTypes = {
    first: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    hasTopMargin: PropTypes.oneOfType([PropTypes.bool]),
    last: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    ellipsis: PropTypes.oneOfType([PropTypes.bool]),
    totalPages: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    onPageChange: PropTypes.oneOfType([PropTypes.func]).isRequired,
    activePage: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    maxButtons: PropTypes.oneOfType([PropTypes.number]),
    prevNextOnly: PropTypes.bool,
    includeEmpty: PropTypes.bool,
  }

  static defaultProps = {
    first: 0,
    hasTopMargin: false,
    last: 0,
    ellipsis: false,
    maxButtons: 5,
    prevNextOnly: false,
  }

  // Pass on the page number and click event to the page change callback.
  toPage = n => ev => this.props.onPageChange(n, ev)

  // When clicking on disabled buttons, only cancel the default action (since they are links).
  cancel = ev => ev.preventDefault()

  renderButtonRange = (buttonRange, activePage, filterOptions) => {
    return buttonRange.map(btn => {
      const isCurrentPage = activePage - 1 === btn
      return (
        <ButtonLink
          key={btn}
          kind={isCurrentPage ? 'highlighted' : 'regular'}
          to={getPageQueryString(btn + 1, 'page', filterOptions)}
          onClick={isCurrentPage ? this.cancel : this.toPage(btn + 1)}
          disabled={isCurrentPage}
          hideDisabledState
        >
          {String(btn + 1)}
        </ButtonLink>
      )
    })
  }

  render() {
    const { ellipsis, totalPages, activePage, hasTopMargin, maxButtons, prevNextOnly } = this.props
    // We count from 0, but display from 1. The getPageQueryString() function requires 1, too.
    const activePageZero = activePage - 1
    const maxButtonsSide = Math.floor((maxButtons - 1) / 2)
    const first = 1
    const last = prevNextOnly ? activePage + 1 : Math.max(activePage, totalPages)
    const prev = Math.max(activePage - 1, 1)
    const next = Math.min(activePage + 1, last)
    const options = { includeEmpty: this.props.includeEmpty }
    const firstLink = getPageQueryString(first, 'page', options)
    const prevLink = getPageQueryString(prev, 'page', options)
    const nextLink = getPageQueryString(next, 'page', options)
    const lastLink = getPageQueryString(last, 'page', options)

    // Determine the range of pages we'll show. This is zero-indexed.
    const left = activePageZero - maxButtonsSide
    const leftLimited = Math.max(left, 0)
    const right = activePageZero + maxButtonsSide
    const rightLimited = Math.min(right, last - 1)
    const buttonRange = range(
      Math.max(leftLimited + (rightLimited - right), 0),
      Math.min(rightLimited + 1 + (leftLimited - left), last),
    )

    // We display extra first and last buttons if they're not already in view.
    const displayFirstButton = buttonRange.indexOf(0) === -1 && !prevNextOnly
    const displayLastButton = prevNextOnly ? false : buttonRange.indexOf(last - 1) === -1

    // The ellipses are displayed when there are hidden buttons.
    const displayFirstEllipsis = buttonRange[0] > 1
    const displayLastEllipsis = buttonRange[buttonRange.length - 1] < last - 2

    const onFirstPage = activePage <= 1
    const onLastPage = activePage >= last

    return (
      <ButtonGroup className={classnames('PaginationButtons', { 'has-top-margin': hasTopMargin })}>
        {
          <ButtonLink
            to={firstLink}
            onClick={onFirstPage ? this.cancel : this.toPage(first)}
            disabled={onFirstPage}
          >
            {LABEL_FIRST}
          </ButtonLink>
        }
        {
          <ButtonLink
            to={prevLink}
            onClick={onFirstPage ? this.cancel : this.toPage(prev)}
            disabled={onFirstPage}
          >
            {LABEL_PREV}
          </ButtonLink>
        }
        {displayFirstButton && (
          <ButtonLink
            to={firstLink}
            onClick={onFirstPage ? this.cancel : this.toPage(first)}
            disabled={onFirstPage}
          >
            {'1'}
          </ButtonLink>
        )}
        {ellipsis && displayFirstEllipsis && <Button disabled>{LABEL_ELLIPSIS}</Button>}
        {this.renderButtonRange(buttonRange, activePage, options)}
        {ellipsis && displayLastEllipsis && <Button disabled>{LABEL_ELLIPSIS}</Button>}
        {displayLastButton && (
          <ButtonLink
            to={lastLink}
            onClick={onLastPage ? this.cancel : this.toPage(last)}
            disabled={onLastPage}
          >
            {last}
          </ButtonLink>
        )}
        {
          <ButtonLink
            to={nextLink}
            onClick={onLastPage ? this.cancel : this.toPage(next)}
            disabled={onLastPage}
          >
            {LABEL_NEXT}
          </ButtonLink>
        }
        {!prevNextOnly && (
          <ButtonLink
            to={lastLink}
            onClick={onLastPage ? this.cancel : this.toPage(last)}
            disabled={onLastPage}
          >
            {LABEL_LAST}
          </ButtonLink>
        )}
      </ButtonGroup>
    )
  }
}
