import React from 'react'

import { ControlLabel } from 'react-bootstrap'
import DataDate from 'syft-acp-core/components/EditableTable/DataDate'
import { Field }  from 'react-final-form'
import { useIntl } from 'react-intl'

const Timezone = () => {
  const intl = useIntl()
  return intl.timeZone
}

const ControlLabelWithTimezone = ({label}) => {
  return <ControlLabel>{label} (<Timezone />)</ControlLabel>
}

const DateField = ({name, label, disabled, parameters}) => {
  const showTimezone = parameters?.showTime || parameters?.showShortTime;

  return (
    <div className='date-field'>
      <Field name={name}>
        {({ input }) => (
          <>
            {showTimezone ?  <ControlLabelWithTimezone label={label} /> : <ControlLabel>{label}</ControlLabel>}
            <DataDate
              {...input}
              testId={name}
              disabled={disabled}
              parameters={parameters}
              editable
            />
          </>
        )}
      </Field>
    </div>
  )
}

export default DateField;
