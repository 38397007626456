import { takeLatestApiCall } from 'syft-acp-core/sagas/helpers'
import { takeLatest } from 'redux-saga/effects'
import { notificationCall } from 'syft-acp-core/sagas/calls'

import matchingExplainabilityTypes from './action-types'
import * as api from './api'

export default function* saga() {
  yield takeLatestApiCall(matchingExplainabilityTypes.ENTITY_FETCH_BEGIN, api.fetchMatchingExplainability)
  yield takeLatest([matchingExplainabilityTypes.ENTITY_FETCH_FAILED], notificationCall)
}
