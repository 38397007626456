// TODO: use correct options object types

import * as types from '../action-types'

/**
 * Initiates a leave request list fetching process.
 * @param {Object} options Other options
 * @param {Object} [_props] Props (unused here, required for EntityList API)
 * @param {boolean} [toCSV=false] Whether to export as CSV
 * @returns {{options: *, type: string, toCSV: boolean}}
 */
export const fetchLeaveRequests = (
  { options }: { options: Record<string, any> },
  _props: Record<string, any>,
  toCSV = false
) => ({ type: types.WORKER_LEAVE_REQUEST_FETCH_BEGIN, options, toCSV })

/** Same as fetchLeaveRequests(), but offers a CSV download instead. */
export const fetchLeaveRequestsCSV = (options: Record<string, any>, view?: string) => ({
  type: types.WORKER_LEAVE_REQUEST_FETCH_CSV,
  options,
  view,
})

export const changeProcessedStatusLeaveRequests = (ids: number[], status: boolean) => ({
  type: types.WORKER_LEAVE_REQUEST_CHANGE_STATUS_BEGIN,
  ids,
  status,
})
