import PropTypes from 'prop-types'

export const actionPropTypes = {
  children: PropTypes.node,
  componentArgs: PropTypes.object,
  callbackArgs: PropTypes.object,
  requiresSelection: PropTypes.bool,
  requiresSubSelection: PropTypes.bool,
  disabled: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
  id: PropTypes.string,
  kind: PropTypes.oneOf(['primary', 'regular', 'neutral', 'success', 'warning', 'danger', 'highlighted']),
}

export const actionDefaultProps = {
  children: null,
  componentArgs: {},
  callbackArgs: {},
  requiresSelection: false,
  requiresSubSelection: false,
  disabled: false,
  id: undefined,
  kind: 'regular',
}
