// Syft ACP - UI Library <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import * as React from 'karet'
import * as U from 'karet.util'
import { Component } from 'react'
import { get, isArray, isFunction } from 'lodash-es'

import { extractTypeNodeSpec } from '../../lib/childNodes'
import AcpListWrapper from './AcpListWrapper'
import { acpEntityListProps, acpEntityListDefaults } from './props'

/** Checks whether a value is an atom lens or not. */
const isAtom = obj => obj.get != null || obj._alive != null

/** Checks whether an element contains a descendant by a certain name. */
const containsDescendants = (element, names) => {
  const children = get(element, 'children', null)
  const childElements = isArray(children) ? children : [children]

  if (!children) return false
  return childElements.find(c => names.indexOf(get(c, 'type.identityName')) > -1) != null
}

/** Returns lenses for 'data' and 'meta' from an ACP resource set lens. */
const destructureSetData = lens => {
  const { reqResult } = U.destructure(lens)
  const { data, meta } = U.destructure(reqResult)
  return { data, meta }
}

class AcpEntityList extends Component {
  static propTypes = acpEntityListProps

  static defaultProps = acpEntityListDefaults

  // Whether the initial data call (done once when mounting) has been made yet.
  // The first call that this list makes will be canceled if there are mandatory arguments.
  hasMadeInitialCall = false

  // The unique ID is used to determine if we should re-render.
  uniqID = ''

  /** Check to see if the table needs to update. When using a resource, this is determined from the unique ID. */
  shouldComponentUpdate(nextProps) {
    const currUniqID = this.uniqID
    let nextUniqID
    if (!nextProps.resource) {
      nextUniqID = nextProps.uniqID
      this.uniqID = nextUniqID

      // If no uniqID is passed, we can't check for updates efficiently.
      if (nextUniqID == null) return true
      return nextUniqID != null && nextUniqID !== currUniqID
    }
    // Get the unique ID from the resource.
    const nextData = this.getResourceData(nextProps.resource, nextProps.resArguments || {})
    nextUniqID = nextData.reqUniqID
    this.uniqID = nextData.reqUniqID
    return currUniqID !== nextUniqID
  }

  /** Checks whether the settings for this EntityList are sensible. */
  checkErrors = (componentData, { isIntegrated }) => {
    // Check for configuration errors:
    const hasFilters = containsDescendants(componentData.AcpActions, ['AcpFilterGroup'])
    const hasDirectHeader = componentData.AcpHeader != null
    const hasActionsHeader = containsDescendants(componentData.AcpActions, ['AcpHeader'])

    // If there are filter elements inside an AcpActions component,
    // then 1) this list must be non-integrated, and 2) there should be no header.
    if (hasFilters && isIntegrated) {
      console.error('AcpEntityList: list cannot be integrated if there are filters.')
    }

    // A direct header and an actions header are mutually exclusive: there must be one.
    if (hasDirectHeader && hasActionsHeader) {
      console.error(
        'AcpEntityList: list cannot contain an AcpHeader and an AcpActions with AcpHeader; there should be one.',
      )
    }

    // If the list is non-integrated, there cannot be a header.
    if (!isIntegrated && (hasDirectHeader || hasActionsHeader)) {
      console.error('AcpEntityList: list cannot contain a header if it is non-integrated.')
    }
  }

  /** Retries the last call. */
  reloadLastCall = resArguments => async () => {
    // FIXME: only single resources are supported for now, not lists with multiple resources.
    const { resource, reloadLastCall } = this.props
    if (resource) {
      const resourceItem = isFunction(resource) ? resource() : resource
      await resourceItem.queryUpdate(resArguments)
      this.forceUpdate()
    } else if (reloadLastCall) {
      await reloadLastCall(resArguments)
      this.forceUpdate()
    }
  }

  /** Returns the resource's current data. */
  getResourceData = (resource, resArguments) => {
    // Get list data from either an AcpResource or from the props.
    // FIXME: only single resources are supported for now, not lists with multiple resources.
    // FIXME: do we need this isFunction()? I think we don't
    const resourceItem = isFunction(resource) ? resource() : resource

    // Only require mandatory arguments during the initial call.
    // TODO: this needs to be changed to something more robust. See <ADMN-218>.
    const resourceData = resourceItem.queryView(resArguments, {
      requireMandatory: this.hasMadeInitialCall === false,
    })
    this.hasMadeInitialCall = true
    return resourceData
  }

  /** Returns all necessary data to be able to render the table. */
  getTableState = (resource, resArguments, propState) => {
    // Filler values; everything the component expects.
    const defaults = {
      data: null,
      meta: null,
      hasData: null,
      isLoading: null,
      isOK: null,
      isStale: null,
      reqError: null,
      reqTime: 0,
      reqUniqID: '',
    }

    // If there's no resource and no data was passed directly, return the filler values.
    if (!resource && (!propState || !propState.data)) {
      return defaults
    }
    if (!resource && propState != null) {
      // Check if the passed data is a lens or not.
      const destructured = isAtom(propState.data) ? U.destructure(propState.data) : propState.data
      return {
        ...defaults,
        data: destructured.data,
        meta: destructured.meta,
        state: propState.state,
        hasData: propState.hasData,
        isLoading: propState.isLoading,
        isOK: propState.isOK,
        isStale: propState.isStale,
      }
    }

    const resourceData = this.getResourceData(resource, resArguments)

    const {
      // Full query set data (see 'reqResult.data' and 'reqResult.meta')
      setLens,
      // Request meta data
      hasData,
      isLoading,
      isOK,
      isStale,
      reqError,
      reqTime,
      reqUniqID,
    } = resourceData
    const { data, meta } = destructureSetData(setLens)

    return {
      data,
      meta,
      hasData,
      isLoading,
      isOK,
      isStale,
      reqError,
      reqTime,
      reqUniqID,
    }
  }

  /** Saves the unique ID to the state, if it's new. */
  saveUniqID = newID => {
    const oldID = this.state.uniqID
    if (newID !== oldID) return this.setState({ uniqID: newID })
    return null
  }

  render() {
    const {
      children,
      className,
      // Note: stateSelection and stateSubSelection are always initialized as an atom([]).
      stateSelection,
      stateSubSelection,
      rowSelection,
      hasResultCount,
      hasMinimumSize,
      hasPagination,
      idKeyValue,
      inContainer,
      isIntegrated,
      actionsInline,
      resource,
      uniqID,
      hasNoMargin,
      reloadLastCall,
      hasPopoverFilters,
      popoverButtonLabel,
      legend,
      initialFilters,
      resArguments = {},
      pagination,
      onPopoverFilterOpen,
      withInfoColumn,
      onPopoverFilterClose,
    } = this.props
    // It's possible to set the list state directly by passing these props.
    // When using a resource this is unnecessary since they will be automatically determined.
    const propData = this.props.data
    const propHasData = this.props.hasData
    const propIsLoading = this.props.isLoading
    const propIsOK = this.props.isOK
    const propIsStale = this.props.isStale
    // The definitive version of the list state.
    const { hasData, isLoading, isOK, isStale, reqError, reqTime, reqUniqID, data, meta } =
      this.getTableState(resource, resArguments, {
        data: propData,
        hasData: propHasData,
        isLoading: propIsLoading,
        isOK: propIsOK,
        isStale: propIsStale,
        uniqID,
      })
    this.uniqID = reqUniqID

    // Retrieve info about placeholder elements.
    const componentData = extractTypeNodeSpec(children, ['AcpFooter', 'AcpHeader', 'AcpActions'], null, true)
    const tableData = extractTypeNodeSpec(children, ['AcpTable'], null, true, true)

    // Log an error if the configuration is wrong.
    this.checkErrors(componentData, { isIntegrated })

    return (
      <AcpListWrapper
        data={data}
        meta={meta}
        className={className}
        componentData={{ ...componentData, ...tableData }}
        reqError={reqError}
        hasData={hasData}
        hasInnerPagination={isIntegrated}
        hasInnerResultCount={isIntegrated}
        hasPopoverFilters={hasPopoverFilters}
        popoverButtonLabel={popoverButtonLabel}
        hasMinimumSize={hasMinimumSize}
        hasNoMargin={hasNoMargin}
        hasPagination={hasPagination}
        hasResultCount={hasResultCount}
        idKeyValue={idKeyValue}
        inContainer={inContainer}
        isIntegrated={isIntegrated}
        initialFilters={initialFilters}
        isLoading={isLoading}
        isOK={isOK}
        legend={legend}
        isStale={isStale}
        withInfoColumn={withInfoColumn}
        reloadLastCall={resource || reloadLastCall ? this.reloadLastCall(resArguments) : null}
        reqTime={reqTime}
        reqUniqID={reqUniqID}
        rowSelection={rowSelection} /* Deprecated. */
        stateSelection={stateSelection}
        stateSubSelection={stateSubSelection}
        actionsInline={actionsInline}
        pagination={pagination}
        onPopoverFilterOpen={onPopoverFilterOpen}
        onPopoverFilterClose={onPopoverFilterClose}
      />
    )
  }
}

export default AcpEntityList
