import React, { useCallback } from 'react'
import { FormControl } from 'react-bootstrap'

type Props = {
  value?: string
  header?: string
  disabled?: boolean
  onChange: (value: string) => void
}

export const DataTime = ({ value, header, disabled, onChange }: Props) => {
  const handleChange = useCallback(event => onChange(event.currentTarget.value), [onChange])

  return (
    <FormControl
      type="time"
      value={value === null ? '' : value}
      disabled={disabled}
      onChange={handleChange}
      aria-label={header}
    />
  )
}
