// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import React from 'react'
import { TrackingProvider, TrackingTrigger } from '@indeed/flex-tracking-context'
import PageView from 'syft-acp-core/components/PageView'
import WorkerNetworkDetail from 'syft-acp-core/entities/WorkerNetworkDetail/WorkerNetworkDetail'
import { trackingEvents } from 'syft-acp-core/entities/WorkerNetworkDetail/WorkerNetworkDetail.tracking'
import { WorkerSubNavigation } from './SubNavigation'
import { RouteComponentProps } from 'react-router-dom'

type Props = RouteComponentProps<{ id: string }>

export const EntityWorkersNetworksNew = ({ match: { params } }: Props) => (
  <TrackingProvider
    value={{
      context: {
        worker_id: params.id,
      },
    }}
  >
    <TrackingTrigger onLoad={{ event: trackingEvents.NEW.PAGE.VIEWED }} />
    <WorkerSubNavigation workerID={params.id} />
    <PageView title={[`New network for worker ${params.id}`]}>
      <WorkerNetworkDetail workerID={Number(params.id)} id={0} />
    </PageView>
  </TrackingProvider>
)
