export const getDuplicateAccountsAlertMessage = ({
  verified,
  banned,
  unverified,
}: {
  verified: number
  banned: number
  unverified: number
}) => {
  const mapProps = {
    banned: { count: banned, label: 'banned' },
    verified: { count: verified, label: 'verified' },
    unverified: { count: unverified, label: 'unverified' },
  }

  const messageArr = Object.values(mapProps)
    .map(({ count, label }) => {
      if (count) {
        return `${count} ${label}`
      }
      return null
    })
    .filter(Boolean)

  return messageArr?.length ? messageArr.join(', ') : null
}
