// Syft ACP - Lib <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import React from 'react'
import PropTypes from 'prop-types'

import { ButtonGroup } from 'syft-acp-atoms/Button'
import './FilterGroup.css'

const FilterGroup = ({ title, children }) => (
  <div className="filter-group">
    <label className="button-group-label">
      {title && `${title}:`}
      <ButtonGroup className="button-group-inputs">{children}</ButtonGroup>
    </label>
  </div>
)

FilterGroup.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
}

FilterGroup.defaultProps = {
  title: '',
}

export default FilterGroup
