export const COMPLIANCE_POLICIES_FETCH_BEGIN = 'syftacp/COMPLIANCE_POLICIES_FETCH_BEGIN'
export const COMPLIANCE_POLICIES_FETCH_FAILED = 'syftacp/COMPLIANCE_POLICIES_FETCH_FAILED'
export const COMPLIANCE_POLICIES_FETCH_SUCCEEDED = 'syftacp/COMPLIANCE_POLICIES_FETCH_SUCCEEDED'

export const COMPLIANCE_POLICIES_UPDATE_BEGIN = 'syftacp/COMPLIANCE_POLICIES_UPDATE_BEGIN'
export const COMPLIANCE_POLICIES_UPDATE_FAILED = 'syftacp/COMPLIANCE_POLICIES_UPDATE_FAILED'
export const COMPLIANCE_POLICIES_UPDATE_SUCCEEDED = 'syftacp/COMPLIANCE_POLICIES_UPDATE_SUCCEEDED'

export const COMPLIANCE_POLICIES_DELETE_BEGIN = 'syftacp/COMPLIANCE_POLICIES_DELETE_BEGIN'
export const COMPLIANCE_POLICIES_DELETE_FAILED = 'syftacp/COMPLIANCE_POLICIES_DELETE_FAILED'
export const COMPLIANCE_POLICIES_DELETE_SUCCEEDED = 'syftacp/COMPLIANCE_POLICIES_DELETE_SUCCEEDED'

export const COMPLIANCE_POLICIES_CREATE_BEGIN = 'syftacp/COMPLIANCE_POLICIES_CREATE_BEGIN'
export const COMPLIANCE_POLICIES_CREATE_FAILED = 'syftacp/COMPLIANCE_POLICIES_CREATE_FAILED'
export const COMPLIANCE_POLICIES_CREATE_SUCCEEDED = 'syftacp/COMPLIANCE_POLICIES_CREATE_SUCCEEDED'
