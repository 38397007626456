export const entityFetchSucceed = (state, action, idKey, options) => {
  const lastUpdate = Number(new Date())
  // If needed, we can preprocess the payload before saving it.
  const payload = options.payloadProcess ? options.payloadProcess(action.payload, action) : action.payload
  const key = options.optionsKey ? action.options[options.optionsKey] : payload[idKey]
  return {
    ...state,
    lastMessage: '',
    lastStatus: null,
    lastBody: null,
    entityUpdateTimes: {
      ...state.entityUpdateTimes,
      [key]: lastUpdate,
    },
    entityMap: {
      ...state.entityMap,
      [key]: {
        ...payload,
      },
    },
    entityDetail: {
      ...state.entityDetail,
      [key]: {
        ...payload,
      },
    },
    isLoadingData: false,
  }
}
