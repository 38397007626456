// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import * as modalActions from 'syft-acp-core/store/modals/actions'
import * as authActions from 'syft-acp-core/actions/auth'
import * as appActions from 'syft-acp-core/actions/app'
import LoginFormPure from './LoginFormPure'
import LoginTroubleModal from './LoginTroubleModal'
import LoginResetPasswordModal from './LoginResetPasswordModal'

class LoginForm extends PureComponent {
  static propTypes = {
    isCheckingLogin: PropTypes.bool.isRequired,
    feedbackMessage: PropTypes.string.isRequired,
    hasRehydrated: PropTypes.bool.isRequired,
    hasResetPassword: PropTypes.bool.isRequired,
    actions: PropTypes.objectOf(PropTypes.func).isRequired,
  }

  constructor() {
    super()
    this.state = { email: '', password: '', emailFP: '', openTroubleModal: false, openResetPassword: false }
  }

  typePassword = ev => {
    this.setState({ password: ev.target.value })
  }

  typeEmail = ev => {
    this.setState({ email: ev.target.value })
  }

  closeLoginModal = () => {
    this.setState({ openTroubleModal: false })
  }

  closeResetPasswordLoginModal = () => {
    this.setState({ openResetPassword: false })
  }

  typeForgotPasswordEmail = ev => {
    this.setState({ emailFP: ev.target.value })
  }

  loginReq = ev => {
    ev.preventDefault()
    this.props.actions.beginLogin(this.state.email, this.state.password)
  }

  loginTrouble = () => {
    this.setState({ openTroubleModal: true })
  }

  handleResetPassword = () => {
    this.props.actions.resetPassword(this.state.emailFP)
    this.closeResetPasswordLoginModal()
  }

  forgotPassword = () => {
    this.setState({ emailFP: '', openResetPassword: true })
  }

  render() {
    const { feedbackMessage } = this.props
    const message =
      feedbackMessage?.indexOf('Error 401') > -1
        ? `Couldn't login with that email and password combination. Please check that you typed it correctly.`
        : feedbackMessage
    return (
      <>
        <LoginFormPure
          isDisabled={this.props.isCheckingLogin}
          onTypeEmail={this.typeEmail}
          onTypePassword={this.typePassword}
          valueEmail={this.state.email}
          valuePassword={this.state.password}
          onSubmit={this.loginReq}
          onLoginTrouble={this.loginTrouble}
          onForgotPassword={this.forgotPassword}
          hasResetPassword={this.props.hasResetPassword}
          feedbackMessage={message}
          isAwaitingRehydration={!this.props.hasRehydrated}
          data-testid="login-form-pure"
        />
        <LoginTroubleModal
          handleSubmit={this.props.actions.resetAndReload}
          isOpen={this.state.openTroubleModal}
          handleOnExit={this.closeLoginModal}
        />
        <LoginResetPasswordModal
          isOpen={this?.state?.openResetPassword}
          handleOnExit={this.closeResetPasswordLoginModal}
          handleResetPassword={this.handleResetPassword}
          onTypeEmail={this.typeForgotPasswordEmail}
        />
      </>
    )
  }
}

export default connect(
  state => ({
    feedbackMessage: state?.auth?.lastMessage,
    hasRehydrated: state?.localstorage?.hasRehydrated,
    isCheckingLogin: state?.auth?.isCheckingLogin,
    isResettingPassword: state?.auth?.isResettingPassword,
    hasResetPassword: state?.auth?.hasResetPassword,
    oauthData: state?.auth?.oauthData,
    userData: state?.auth?.userData,
  }),
  dispatch => ({
    actions: bindActionCreators(
      {
        ...authActions,
        ...modalActions,
        ...appActions,
      },
      dispatch
    ),
  })
)(LoginForm)
