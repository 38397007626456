// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import React, { useRef, useEffect, useCallback } from 'react'
import PhoneInput from 'react-phone-number-input'
import { useIntl } from 'react-intl'

import { SUPPORTED_COUNTRY_CODES } from 'syft-acp-core/lib/i18n'

import DataText from './DataText'

import 'react-phone-number-input/style.css'

const PhoneNumberInputContainer = ({ onChange, value }) => {
  const intl = useIntl()
  const ref = useRef()
  const handleChange = useCallback(val => onChange(val || ''), [onChange])

  useEffect(() => {
    if (ref && ref.current) {
      ref.current.classList.add('form-control')
    }
  }, [ref])

  return (
    <PhoneInput
      ref={ref}
      defaultCountry={intl.countryCode}
      value={value}
      onChange={handleChange}
      displayInitialValueAsLocal
    />
  )
}

PhoneNumberInputContainer.propTypes = {
  ...DataText.propTypes,
}

const DataPhoneNumber = props => {
  const { countryCode } = useIntl()

  // TODO remove it when UK site is ready for phone validation
  if (countryCode === SUPPORTED_COUNTRY_CODES.GB) {
    return <DataText {...props} />
  }

  return <PhoneNumberInputContainer {...props} />
}

DataPhoneNumber.propTypes = {
  ...DataText.propTypes,
}

export default DataPhoneNumber
