export const categoryLabel = {
  overbook: 'Overbook',
  check_in: 'Check in',
  check_in_overbook: 'CI/OB',
  no_show: 'No show',
  induction: 'Induction',
}

export const OFFER_TO_FLEX_ALL = 'all'
export const OFFER_TO_FLEX_NETWORK = 'network'
export const OFFER_TO_FLEX_INDIVIDUAL = 'individual'
export const OFFER_TO_FLEX_AGENCY = 'agency'
export const SYMBOL_OFFER_TO_FLEX_ALL = 'A'
export const SYMBOL_OFFER_TO_FLEX_NETWORK = 'S'
export const SYMBOL_OFFER_TO_FLEX_INDIVIDUAL = 'I'
export const SYMBOL_OFFER_TO_FLEX_AGENCY = 'AG'
