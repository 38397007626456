import { apiRequest, handleCall } from 'syft-acp-core/api/call'

/**
 * Retrieves a list of commissions sales win associations.
 */
export const commissionsSales = ({ options = {} }): Promise<any> =>
  new Promise((resolve, reject) => {
    const req = apiRequest({
      path: '/admin/sales/manager_commissions_sales_win_associations',
      reqArgs: options,
    })
    handleCall(req, resolve, reject, true, options)
  })
