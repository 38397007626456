import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { FormGroup } from 'react-bootstrap'
import { bindActionCreators } from 'redux'
import { values, includes, without, concat } from 'lodash-es'

import * as countriesActions from 'syft-acp-core/actions/countries'
import LabeledCheckbox from 'syft-acp-core/components/LabeledCheckbox'

import './DataCityMultipleChoice.css'

// Converts cities to an options map.
const options = citiesMap =>
  values(citiesMap).map(city => ({
    id: city.id,
    label: city.name,
  }))

class DataCityMultipleChoice extends React.PureComponent {
  static propTypes = {
    value: PropTypes.arrayOf(PropTypes.number),
    editable: PropTypes.bool,
    cities: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        label: PropTypes.string.isRequired,
      })
    ).isRequired,
    hasCitiesData: PropTypes.bool.isRequired,
    onChange: PropTypes.func.isRequired,
    actions: PropTypes.shape({
      fetchCountries: PropTypes.func.isRequired,
    }).isRequired,
  }

  static defaultProps = {
    value: [],
    editable: false,
  }

  componentDidMount() {
    const { hasCitiesData, actions } = this.props
    if (!hasCitiesData) {
      actions.fetchCountries()
    }
  }

  toggleCity = id => add => {
    const { value, onChange } = this.props
    // Either add to the value or remove from it.
    const newValue = add === true ? concat(value, id) : without(value, id)
    onChange(newValue)
  }

  render() {
    const { value, cities, editable } = this.props
    return (
      <div className="DataCityMultipleChoice">
        <FormGroup>
          {cities.map(c => (
            <LabeledCheckbox
              className="city-multiple-choice"
              key={c.label}
              callback={this.toggleCity(c.id)}
              checked={includes(value, c.id)}
              editable={editable}
              label={c.label}
            />
          ))}
        </FormGroup>
      </div>
    )
  }
}

export default connect(
  (state, { onChange }) => ({
    cities: options(state.cities.entityMap),
    hasCitiesData: state.cities.hasData,
    actions: {
      setFilter: (name, id, value) => onChange(value),
    },
  }),
  (dispatch, { actions }) => ({
    actions: {
      ...bindActionCreators(countriesActions, dispatch),
      ...actions,
    },
  })
)(DataCityMultipleChoice)
