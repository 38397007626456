// Alert types. For more information:
// <https://syftapp.atlassian.net/wiki/spaces/SV/pages/30509764/Notification+Types>

import { AlertResourceTypes } from './resourceTypes'

export type ResourceType = typeof AlertResourceTypes[keyof typeof AlertResourceTypes]

/**
 * Returns URL related to an admin alert.
 * @param {string} type Resource type
 * @param {number} id Resource ID
 * @return {string} URL address
 */

export const alertLink = (type: ResourceType, id: number, additionalResourceId?: number) => {
  switch (type) {
    case AlertResourceTypes.SHIFT:
      return `/entity/shifts/view/${id}`
    case AlertResourceTypes.JOB:
      return `/listings/view/${additionalResourceId}/job/${id}`
    case AlertResourceTypes.LISTING:
    case AlertResourceTypes.AGENCY:
      return `/listings/view/${id}`
    case AlertResourceTypes.WORKER:
      return `/entity/workers/view/${id}`
    default:
      return null
  }
}
