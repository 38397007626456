import React from 'react'
import PropTypes from 'prop-types'
import { get } from 'lodash-es'

import TypeLabel from './TypeLabel'

const StatusLabel = ({ value, typesMap, groupName, defaultStatus, ...rest }) => {
  const status = get(value, 'status')
  const statusProps = status
    ? typesMap[status] || typesMap[value] || { name: status, style: 'info', message: 'Unknown status' }
    : defaultStatus
  const labelProps = {
    ...statusProps,
    ...rest,
  }

  return <TypeLabel {...labelProps} name={groupName ? `${groupName} ${labelProps.name}` : labelProps.name} />
}

StatusLabel.propTypes = {
  value: PropTypes.oneOf([PropTypes.string, PropTypes.object]),
  typesMap: PropTypes.object.isRequired,
  defaultStatus: PropTypes.object.isRequired,
  groupName: PropTypes.string,
}

StatusLabel.defaultProps = {
  value: null,
  groupName: null,
}

export default StatusLabel
