// Syft ACP - Lib <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import React, { useRef, useLayoutEffect } from 'react'
import { connect } from 'react-redux'
import ReactTooltip from 'react-tooltip'

import { GeolocationTooltipProps as Props } from './GeolocationTooltip.types'

export const storeConnector = connect((state: Record<string, any>) => ({
  query: state.routing.locationBeforeTransitions.query,
}))

export const innerText = {
  FILTERING_ON: '☑️ Filtering by location',
  FILTERING_OFF: '⚠️ All geolocation options must be set in order to filter by location',
}

const getText = (definedParamsNumber: number) => {
  if (definedParamsNumber === 4) return innerText.FILTERING_ON
  if (definedParamsNumber > 0) return innerText.FILTERING_OFF
  return ''
}

const GeolocationTooltip = ({ query }: Props) => {
  const ref = useRef<HTMLDivElement>(null)
  const { longitude, latitude, radius, radius_unit } = query
  const definedParams = [longitude, latitude, radius, radius_unit].filter(Boolean)

  useLayoutEffect(() => {
    if (definedParams.length > 0) ref.current && ReactTooltip.show(ref.current)
    else ref.current && ReactTooltip.hide(ref.current)
  }, [definedParams.length])

  return (
    <>
      <div
        ref={ref}
        data-type={definedParams.length === 4 ? 'success' : 'warning'}
        data-text-color="black"
        data-place="right"
        data-tip={getText(definedParams.length)}
        data-for="geo"
        data-offset="{'top': -35}"
      />
      <ReactTooltip id="geo" />
    </>
  )
}

export default storeConnector(GeolocationTooltip)
