import { apiRequest, handleCall } from 'syft-acp-core/api/call'

/**
 * Retrieves a list of roles.
 */
export const listRoles = ({ options = {} }) =>
  new Promise((resolve, reject) => {
    const req = apiRequest({ path: '/roles' })
    handleCall(req, resolve, reject, true, options)
  })
