// Syft ACP - Lib <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import React from 'react'
import PropTypes from 'prop-types'

import { ButtonLink } from 'syft-acp-atoms/Button'

const ActionButton = ({ label, to, disabled, buttonType, isBright, isActive }) => (
  <ButtonLink kind={buttonType} to={to} disabled={disabled} isBright={isBright} isActive={isActive}>
    {label}
  </ButtonLink>
)

ActionButton.propTypes = {
  label: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  buttonType: PropTypes.string,
  isBright: PropTypes.bool,
  isActive: PropTypes.bool,
}

ActionButton.defaultProps = {
  disabled: false,
  isBright: false,
  isActive: false,
  buttonType: 'primary',
}

export default ActionButton
