// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import React from 'react'
import { Tabs, Tab } from 'react-bootstrap'
import { get } from 'lodash-es'
import { TrackingProvider, TrackingTrigger } from '@indeed/flex-tracking-context'

import PageView from 'syft-acp-core/components/PageView'
import CsatResultList from 'syft-acp-core/entities2/CsatResultList'
import { DOWNLOAD_CSAT_RESULT_CSV_BUTTON_CLICKED } from 'syft-acp-core/entities2/CsatResultList/constants'
import {
  AgencyCsat,
  WorkerCsat,
  EmployerCsat,
  DOWNLOAD_CSAT_DATA_CSV_BUTTON_CLICKED,
} from 'syft-acp-core/entities2/CsatDataList'
import { paths, apiCsat, csatPageTitle } from 'syft-acp-util/csat'
import { csatDataType, watchListCsatProps as Props } from './WatchListCsat.types'
import { trackingEvents } from './WatchListCsat.tracking'
import SubNavigationWatchlist from '../SubNavigation'

export const WatchListCsat = ({ location }: Props) => {
  const pathChunks = get(location, 'pathname').split('/')
  const path = pathChunks[pathChunks.length - 1]

  const csatDataList = {
    [paths.workerCsat]: <WorkerCsat path={path} csatDataType={apiCsat[path as keyof csatDataType]} />,
    [paths.employerCsat]: <EmployerCsat path={path} csatDataType={apiCsat[path as keyof csatDataType]} />,
    [paths.agencyCsat]: <AgencyCsat path={path} csatDataType={apiCsat[path as keyof csatDataType]} />,
  }

  const mapEvents = (event: string) => {
    switch (event) {
      case DOWNLOAD_CSAT_RESULT_CSV_BUTTON_CLICKED:
        return trackingEvents[path as keyof csatDataType].DOWNLOAD_RESULT_CSV.CLICKED
      case DOWNLOAD_CSAT_DATA_CSV_BUTTON_CLICKED:
        return trackingEvents[path as keyof csatDataType].DOWNLOAD_DATA_CSV.CLICKED
      default:
        return event
    }
  }

  return (
    <TrackingProvider
      value={{
        eventMappings: mapEvents,
      }}
    >
      <SubNavigationWatchlist />
      <TrackingTrigger onLoad={{ event: trackingEvents[path as keyof csatDataType].VIEWED }} />
      <PageView title={['Watchlist', csatPageTitle[path]]} entityPage>
        <Tabs className="listing-shift-tabs" id="data" defaultActiveKey="data">
          <Tab eventKey="data" title="Data">
            {csatDataList[path] || null}
          </Tab>
          <Tab eventKey="results" title="Results">
            <CsatResultList type={path} />
          </Tab>
        </Tabs>
      </PageView>
    </TrackingProvider>
  )
}
