// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

import LabeledCheckbox from 'syft-acp-core/components/LabeledCheckbox'

class EntityListAsyncBool extends PureComponent {
  static propTypes = {
    disabled: PropTypes.bool,
    oneWay: PropTypes.bool,
    callback: PropTypes.func.isRequired,
    checked: PropTypes.bool,
    isHeader: PropTypes.bool,
  }

  static defaultProps = {
    disabled: false,
    oneWay: false,
    checked: false,
    isHeader: false,
  }

  constructor() {
    super()
    this.state = {
      isLoading: false,
      forceValue: null,
    }
  }

  onChange = () => {
    this.setState({ isLoading: true })
    this.props
      .callback()
      .then(() => this.setState({ isLoading: false, forceValue: true }))
      .catch(() => this.setState({ isLoading: false }))
  }

  render() {
    const isTrue = this.props.checked || this.state.forceValue === true
    let isEditable = true
    const { isHeader, oneWay, disabled } = this.props
    // Turn off editable state if this item is (either/or):
    //   * one-way (cannot go back after it's been made true) and true
    //   * disabled
    //   * loading
    if ((oneWay && isTrue) || disabled || this.state.isLoading) {
      isEditable = false
    }
    return (
      <LabeledCheckbox isHeader={isHeader} editable={isEditable} callback={this.onChange} checked={isTrue} />
    )
  }
}

export default EntityListAsyncBool
