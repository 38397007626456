import * as types from './action-types'

import { ListConductReviewsInput, UpdateConductReviewInput } from './types'

export const fetchConductReviews = ({ payload }: ListConductReviewsInput) => ({
  type: types.CONDUCT_REVIEWS_FETCH_BEGIN,
  payload,
})

export const fetchConductReview = (id: number) => ({
  type: types.CONDUCT_REVIEW_FETCH_BEGIN,
  payload: { id },
})

export const assignConductReviews = () => ({ type: types.CONDUCT_REVIEWS_ASSIGN_BEGIN })

export const fetchAssignees = () => ({ type: types.CONDUCT_REVIEWS_ASSIGNEES_FETCH_BEGIN })

export const updateConductReview = (id: number, options: UpdateConductReviewInput['payload']['options']) => ({
  type: types.CONDUCT_REVIEW_UPDATE_BEGIN,
  payload: {
    id,
    options,
  },
})
