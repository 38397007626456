import React, { useCallback, useMemo, useState } from 'react'
import { connect } from 'react-redux'
import { Form, useForm } from 'react-final-form'
import { FORM_ERROR } from 'final-form'
import { get, identity } from 'lodash-es'
import moment from 'moment'
import { Col, ControlLabel, Form as FormUI, HelpBlock, InputGroup, Row } from 'react-bootstrap'

import { ButtonIcon } from 'syft-acp-atoms/Button'
import { formatSecToMin, parseMinToSec } from 'syft-acp-core/components/Form/utils'
import { getErrors } from 'syft-acp-core/lib/notifications'
import FormNumberInput from 'syft-acp-core/components/Form/FormNumberInput'
import { trackingEvents } from 'syft-acp-core/entities/ListingDetail/tracking'
import FormDateTimePickerInput from 'syft-acp-core/components/Form/FormDateTimePickerInput'
import { hideModal } from 'syft-acp-core/store/modals/actions'
import { getModal$ } from 'syft-acp-core/store/modals/selectors'
import { withTrackingTriggerHoc } from 'syft-acp-util/withTrackingTriggerHoc'
import { addTimezone } from 'syft-acp-util/formatting'
import Modal from './Modal'

const FormRow = ({ title, children, formState, isChanging, toggleFieldVisibility, fieldName }) => {
  const changeVisibleField = useCallback(
    e => {
      e.preventDefault()
      toggleFieldVisibility(fieldName)
    },
    [fieldName, toggleFieldVisibility],
  )
  return (
    <Row data-testid="listing-shift-break-row" className={`${formState.error ? 'has-error' : ''}`}>
      <Col md={3}>
        <ControlLabel htmlFor="edit-all-clock-in">{title}</ControlLabel>
      </Col>
      {isChanging ? (
        <>
          <Col md={7}>{children}</Col>
          <Col md={2}>
            <ButtonIcon icon="x" iconSize={10} iconLabel="Remove" onClick={changeVisibleField} />
          </Col>
        </>
      ) : (
        <Col md={9}>
          <ButtonIcon icon="plus" iconSize={10} iconLabel="Add" onClick={changeVisibleField} />
        </Col>
      )}
    </Row>
  )
}

const FormModal = ({ onClose, isShown, timeZone, fieldsInitialValues }) => {
  const form = useForm()
  const changeField = form.change
  const [visibleFields, setVisibleFields] = useState({
    clock_in_time: false,
    clock_out_time: false,
    break_duration: false,
  })

  const toggleFieldVisibility = useCallback(
    fieldName => {
      setVisibleFields(fields => ({ ...fields, [fieldName]: !fields[fieldName] }))
      changeField(fieldName, fieldsInitialValues?.[fieldName] || undefined)
    },
    [changeField, fieldsInitialValues],
  )

  const formState = form.getState()
  const canSubmit =
    (formState.valid || formState.submitError) &&
    !formState.submitting &&
    !!Object.keys(formState.modified).length

  return (
    <Modal
      header="Edit all bookings"
      confirmationText="Apply to all bookings"
      onClose={onClose}
      onConfirm={form.submit}
      canSubmit={canSubmit}
      errorText={formState.error || formState.submitError}
      isLoading={formState.submitting}
      isShown={isShown}
    >
      <div className="shift-breaks-modal">
        <FormUI horizontal className="form-item">
          <FormRow
            title="Clock In"
            formState={formState}
            isChanging={visibleFields.clock_in_time}
            toggleFieldVisibility={toggleFieldVisibility}
            fieldName="clock_in_time"
          >
            <FormDateTimePickerInput
              initialValue={fieldsInitialValues.clock_in_time}
              name="clock_in_time"
              id="edit-all-clock-in"
              timeZone={timeZone}
              data-testid="shift-edit-all-clock-in"
            />
          </FormRow>

          <FormRow
            title="Clock Out"
            formState={formState}
            isChanging={visibleFields.clock_out_time}
            toggleFieldVisibility={toggleFieldVisibility}
            fieldName="clock_out_time"
          >
            <FormDateTimePickerInput
              initialValue={fieldsInitialValues.clock_out_time}
              name="clock_out_time"
              id="edit-all-clock-out"
              timeZone={timeZone}
              data-testid="shift-edit-all-clock-out"
            />
          </FormRow>
          <FormRow
            title="Break"
            formState={formState}
            isChanging={visibleFields.break_duration}
            toggleFieldVisibility={toggleFieldVisibility}
            fieldName="break_duration"
          >
            <FormNumberInput
              name="break_duration"
              id="edit-all-break-duration"
              min={0}
              format={formatSecToMin}
              parse={parseMinToSec}
              initialValue={fieldsInitialValues['break_duration']}
              rightAddon={<InputGroup.Addon>min.</InputGroup.Addon>}
            />
          </FormRow>
          {formState.submitting && <HelpBlock>Submitting ...</HelpBlock>}
        </FormUI>
      </div>
    </Modal>
  )
}

const ListingShiftBreakDetailsModal = ({ closeModal, modal, triggerEvent }) => {
  const { onConfirm = identity, startTime, endTime, breakDuration, timeZone } = get(modal, 'options', {})
  const isFormValid = useCallback(
    ({ clock_in_time, clock_out_time }) => {
      const valid = [clock_in_time, clock_out_time].every(value =>
        addTimezone(moment(value), timeZone).isValid(),
      )
      return valid ? undefined : { [FORM_ERROR]: 'Please enter valid time' }
    },
    [timeZone],
  )
  const initialValues = useMemo(
    () => ({
      clock_in_time: startTime,
      clock_out_time: endTime,
      break_duration: breakDuration * 60, // make duration in seconds is what BE expects
    }),
    [breakDuration, endTime, startTime],
  )
  const onSubmit = useCallback(
    async value => {
      try {
        triggerEvent(trackingEvents.LISTING_INFO.BOOKINGS.EDITED, value)
        await onConfirm(value)
        closeModal()
      } catch (e) {
        return {
          [FORM_ERROR]: getErrors(e, true)?.message,
        }
      }
    },
    [closeModal, onConfirm, triggerEvent],
  )

  return (
    modal.isShown && (
      <Form
        fieldsInitialValues={initialValues}
        onSubmit={onSubmit}
        validate={isFormValid}
        component={FormModal}
        onClose={closeModal}
        isShown={modal.isShown}
        timeZone={timeZone}
      />
    )
  )
}

export default connect(
  (state, { modalName }) => ({
    modal: getModal$(state.modals)(modalName),
  }),
  (dispatch, { modalName }) => ({ closeModal: () => dispatch(hideModal(modalName)) }),
)(withTrackingTriggerHoc(ListingShiftBreakDetailsModal))
