// Syft ACP - Lib <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import React from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import { ButtonBoolean } from 'syft-acp-atoms/Button'
import { Checkbox } from 'react-bootstrap'
import * as filtersActions from 'syft-acp-core/store/filters/actions'
import './FilterBoolCheckbox.css'
import classNames from 'classnames'

export const FilterBoolCheckboxButton = ({ handleChange, checked, label }) => (
  <ButtonBoolean checked={checked} onClick={handleChange} aria-label={label}>
    {label}
  </ButtonBoolean>
)

FilterBoolCheckboxButton.propTypes = {
  handleChange: PropTypes.func.isRequired,
  checked: PropTypes.bool,
  label: PropTypes.string,
  variant: PropTypes.string,
  className: PropTypes.string,
}

FilterBoolCheckboxButton.defaultProps = {
  label: '',
  className: '',
  checked: false,
}

export const FilterOnlyCheckbox = ({ handleChange, checked, label, className }) => (
  <div className={classNames('simple-checkbox-container', className)}>
    <div className="simple-checkbox">
      <Checkbox id={label} onClick={handleChange} checked={checked} />
    </div>
    <label for={label} className="simple-checkbox">
      {label}
    </label>
  </div>
)

FilterOnlyCheckbox.propTypes = {
  handleChange: PropTypes.func.isRequired,
  checked: PropTypes.bool,
  label: PropTypes.string,
  variant: PropTypes.string,
}

FilterOnlyCheckbox.defaultProps = {
  label: '',
  checked: false,
}

class FilterBoolCheckbox extends React.Component {
  componentDidMount() {
    // Set default value if applicable.
    if (this.props.defaultValue && this.props.query[this.props.name] !== this.props.defaultValue) {
      this.handleChange()
    }
  }

  handleChange = ev => {
    // eslint-disable-next-line no-unused-expressions
    ev && ev.stopPropagation()

    const { value, onChange } = this.props
    // TODO: this is a massive hack. Remove it. This is to make value=false work correctly.
    // Maybe we don't need any of this if we just pass 'false' instead of bool false...
    let queryValue = this.props.query[this.props.name]
    if (this.props.boolValue) {
      queryValue = queryValue === 'false' ? false : null
    }

    const checked = value !== true ? queryValue === value : queryValue

    !!onChange && !!ev && onChange(this.props.name, checked ? 'true' : 'false')
    this.props.actions.setFilter(this.props.name, checked ? null : value)
  }

  render() {
    // TODO: hack. see above.
    let queryValue = this.props.query[this.props.name]
    if (this.props.boolValue && queryValue) {
      queryValue = queryValue === 'false' ? false : null
    }
    const checked = queryValue === this.props.value
    return this.props.variant === 'only_checkbox' ? (
      <FilterOnlyCheckbox
        handleChange={this.handleChange}
        className={this.props.className}
        checked={checked}
        label={this.props.label}
      />
    ) : (
      <FilterBoolCheckboxButton
        handleChange={this.handleChange}
        className={this.props.className}
        checked={checked}
        label={this.props.label}
      />
    )
  }
}

FilterBoolCheckbox.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string.isRequired,
  variant: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  defaultValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.bool]),
  query: PropTypes.object.isRequired,
  boolValue: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.bool]),
  actions: PropTypes.objectOf(PropTypes.func).isRequired,
  onChange: PropTypes.func,
}

FilterBoolCheckbox.defaultProps = {
  className: null,
  value: 'true',
  variant: 'default',
  defaultValue: false,
  boolValue: false,
}

export default connect(
  state => ({
    query: state.routing.locationBeforeTransitions.query,
  }),
  dispatch => ({
    actions: bindActionCreators(filtersActions, dispatch),
  })
)(FilterBoolCheckbox)
