import * as React from 'react'
import { isNil } from 'lodash-es'
import moment from 'moment'
import classnames from 'classnames'
import { Tooltip } from '@material-ui/core'
import { Error as ErrorIcon } from '@indeed/ifl-icons'

import { ShiftBookingEntity } from 'syft-acp-core/store/shift-bookings/types'
import { RoleEntity } from 'syft-acp-core/store/roles/types'
import { SkillEntity } from 'syft-acp-core/store/skills/types'
import EntitySelector from 'syft-acp-core/components/EntityList/EntitySelector'
import UserLine from 'syft-acp-core/components/UserAvatar/UserLine'
import { formatFullName } from 'syft-acp-util/formatting'
import PhoneNumber from 'syft-acp-core/components/PhoneNumber'
import FormattedDateTime, { fullLocalDateTimeFormat } from 'syft-acp-core/components/FormattedDateTime'
import { ShiftWorkerUpdates } from 'syft-acp-core/components/ShiftWorkerUpdates'
import EntityListAsyncBool from 'syft-acp-core/components/EntityList/EntityListAsyncBool'
import { ShiftEntity } from 'syft-acp-core/store/shifts/types'
import { ListingShiftBooking } from 'syft-acp-core/store/listing-shifts/types'
import { dateAddTzSetTime } from 'syft-acp-util/time'
import { formatCurrency } from 'syft-acp-util/formatting'
import { BaseEntity } from 'syft-acp-core/reducers/generators/entityReducer'
import { useFormatTimeRange } from 'syft-acp-core/lib/hooks'

import OfferTypeItem from '../OfferTypeItem'
import { getNewOfferType, manuallyConfirmWorker } from './table-format'
import { RowValue, SubRowValue, ShiftBooking, WorkLocation } from './helpers.types'
import { MonetaryAmount } from 'syft-acp-core/store/types'
import {
  categoryLabel,
  OFFER_TO_FLEX_ALL,
  OFFER_TO_FLEX_NETWORK,
  OFFER_TO_FLEX_INDIVIDUAL,
  OFFER_TO_FLEX_AGENCY,
  SYMBOL_OFFER_TO_FLEX_ALL,
  SYMBOL_OFFER_TO_FLEX_NETWORK,
  SYMBOL_OFFER_TO_FLEX_INDIVIDUAL,
  SYMBOL_OFFER_TO_FLEX_AGENCY,
} from './constants'
import S from './tableHelpers.module.scss'

export const formatRole = (role: RoleEntity, category: string) => {
  if (!role) return '–'
  const prefix = categoryLabel[category as keyof typeof categoryLabel]
  return prefix ? `${prefix} - ${role.title}` : role.title
}

export const getRowLink = (rowData: ShiftEntity) =>
  rowData && `/listings/view/${rowData.listing_id}/job/${rowData.job_id}/shift/${rowData.id}`

export const getSubRowLink = (subRowData: ShiftBookingEntity) =>
  subRowData?.worker &&
  subRowData?.worker?.worker_platform !== OFFER_TO_FLEX_AGENCY &&
  `/entity/workers/view/${subRowData.worker.id}`

export const getRowColor = (rowData: ShiftEntity) => {
  // PTE-10243
  if (rowData.late_cancellation && rowData.workers_required !== rowData.workers_booked) return 'red'
  // Flex+ jobs that got posted to Flex:
  if (rowData.platform_id !== 1) return 'gray'
  // A company's first ever job with Flex:
  if (rowData.venue_trial_period_shift) return 'cyan'
  return ''
}

/** Returns the highlight color of the subrow. */
export const getSubRowColor = (s: SubRowValue) => s?.highlight

export const getBrandCompany = (e: RowValue | Partial<ShiftEntity>) => e.employer?.name

export const getWorkersBookedRequired = (e: RowValue) => `${e.workers_booked}/${e.workers_required}`

export const getWorkersNeeded = (e: RowValue) => e.workers_required - e.booking_stats.booked

export const getWorkersApplied = (e: RowValue) => e.booking_stats.applied + ''

export const getWorkersOffered = (e: RowValue) => e.booking_stats.offered + ''

export const getNewOfferTypeLabel = (
  offerToFlex?: string | null,
  offerToInternal?: string | null,
  offerToAgency?: string | null,
) => {
  if (!!offerToFlex && !offerToInternal && !offerToAgency) {
    return 'FLEX'
  }
  if (!offerToFlex && !!offerToInternal && !offerToAgency) {
    return 'INTERNAL'
  }
  if (!!offerToFlex && !!offerToInternal && !offerToAgency) {
    return 'FLEX / INTERNAL'
  }
  if (!offerToFlex && !offerToInternal && !!offerToAgency) {
    return 'AGENCY'
  }
  if (!!offerToFlex && !offerToInternal && !!offerToAgency) {
    return 'FLEX / AGENCY'
  }
  if (!offerToFlex && !!offerToInternal && !!offerToAgency) {
    return 'INTERNAL / AGENCY'
  }
  if (!!offerToFlex && !!offerToInternal && !!offerToAgency) {
    return 'FLEX / INTERNAL / AGENCY'
  }
}

export const getNewOfferTypeSymbol = (
  offerToFlex?: string | null,
  offerToInternal?: string | null,
  offerToAgency?: string | null,
) => {
  if (offerToFlex === OFFER_TO_FLEX_ALL || (!offerToFlex && offerToInternal === OFFER_TO_FLEX_ALL)) {
    return SYMBOL_OFFER_TO_FLEX_ALL
  }
  if (offerToFlex === OFFER_TO_FLEX_NETWORK || (!offerToFlex && offerToInternal === OFFER_TO_FLEX_NETWORK)) {
    return SYMBOL_OFFER_TO_FLEX_NETWORK
  }
  if (
    offerToFlex === OFFER_TO_FLEX_INDIVIDUAL ||
    (!offerToFlex && offerToInternal === OFFER_TO_FLEX_INDIVIDUAL)
  ) {
    return SYMBOL_OFFER_TO_FLEX_INDIVIDUAL
  }
  if (offerToAgency === OFFER_TO_FLEX_AGENCY) {
    return SYMBOL_OFFER_TO_FLEX_AGENCY
  }
  return '-'
}

export const getNewOfferTypeTooltip = (
  offerToFlex?: string | null,
  offerToInternal?: string | null,
  offerToAgency?: string | null,
) => {
  if (offerToFlex === OFFER_TO_FLEX_ALL && !offerToInternal && !offerToAgency) {
    return 'Offered to All flexers'
  }
  if (offerToFlex === OFFER_TO_FLEX_NETWORK && !offerToInternal && !offerToAgency) {
    return "Offered to Flexers in client's network"
  }
  if (offerToFlex === OFFER_TO_FLEX_INDIVIDUAL && !offerToInternal && !offerToAgency) {
    return 'Individually offered to Flexers'
  }
  if (offerToInternal === OFFER_TO_FLEX_ALL && !offerToFlex && !offerToAgency) {
    return 'Offered to All internal staff'
  }
  if (offerToInternal === OFFER_TO_FLEX_NETWORK && !offerToFlex && !offerToAgency) {
    return "Offered to client's primary venue staff"
  }
  if (offerToInternal === OFFER_TO_FLEX_INDIVIDUAL && !offerToFlex && !offerToAgency) {
    return 'Individually offered to internal staff'
  }
  if (!!offerToInternal && !!offerToFlex && !offerToAgency) {
    return 'This listing is offered to both Flexers and Internals'
  }
  if (!offerToInternal && !!offerToFlex && !!offerToAgency) {
    return 'This listing is offered to both Flexers and Agency'
  }
  if (!!offerToInternal && !!offerToFlex && !!offerToAgency) {
    return 'This listing is offered to Flexers, Internals and Agencies'
  }
}

export const getOfferTypeValue = (e: RowValue) => {
  const offerToFlex = e.offer_to_flex
  const offerToInternal = e.offer_to_internal
  const offerToAgency = e.offer_to_agency

  const offerType = getNewOfferType(offerToFlex, offerToInternal, offerToAgency)
  const label = getNewOfferTypeLabel(offerToFlex, offerToInternal, offerToAgency)

  const typeOfWorkers = <span className={S['type_of_workers']}>{label}</span>

  return (
    <OfferTypeItem>
      <span className={S['cell_block']}>{offerType}</span>
      {label ? typeOfWorkers : null}
    </OfferTypeItem>
  )
}

export const getOfferTypeValueTooltip = (e: RowValue) => {
  const offerToFlex = e.offer_to_flex
  const offerToInternal = e.offer_to_internal
  const offerToAgency = e.offer_to_agency

  return getNewOfferType(offerToFlex, offerToInternal, offerToAgency, true)
}

export const getShowListOfBookingsValue = (e: RowValue) => (
  <EntitySelector value={e.id} scope="ShiftShowBookings" />
)

export const getWorkerBooked = (roles: RoleEntity[]) => (shift: RowValue, s: SubRowValue) => {
  return s?.worker ? (
    <>
      <UserLine
        workerPlatform={s.worker.worker_platform}
        avatarUUID={s.worker.profile_picture.uuid}
        name={formatFullName(s.worker)}
        platformDisplayName={s?.agency_name}
      />
      <ShiftWorkerUpdates shift={shift} shiftBooking={s} roles={roles} />
    </>
  ) : (
    '-'
  )
}

export const getConfirmed = (e: Partial<RowValue>, s: SubRowValue) => {
  const jobID = e?.job_id
  const workerID = s?.worker?.id
  const shiftID = e?.id
  return (
    <EntityListAsyncBool
      callback={manuallyConfirmWorker({ jobID, workerID, shiftID })}
      oneWay
      checked={s?.confirmed}
      disabled={isNil(s?.worker.id) || s?.worker?.agency}
    />
  )
}

export const getConfirmedHeader = (entityList: any) => {
  const unconfirmedBookings = entityList?.filter((item: Partial<RowValue>) => {
    const unconfirmed = item?.shift_bookings?.filter((booking: ShiftBooking) => !booking.confirmed) || []
    return !!unconfirmed?.length
  })
  const payload =
    unconfirmedBookings
      .map((booking: Partial<RowValue>) => {
        const bookingsFiltered =
          booking?.shift_bookings?.filter((item: ShiftBooking) => !item.confirmed) || []
        return bookingsFiltered?.map((item: ShiftBooking) => ({
          job_id: booking.job_id,
          worker_id: item.worker.id,
          shift_ids: [item.shift_id],
        }))
      })
      .flat(1) || []
  return (
    <EntityListAsyncBool
      callback={manuallyConfirmWorker({ payload })}
      oneWay
      isHeader
      checked={!unconfirmedBookings?.length}
      disabled={false}
    />
  )
}

export const getNameWithOverride = (
  e: Partial<RowValue>,
  attr: 'area' | 'venue' | 'role',
  roleChangesEnabled?: boolean,
  customLabel?: string,
) => {
  const cnt = e?.overrides_summary?.[attr]
  const title = attr[0].toUpperCase() + attr.slice(1)
  const nameComponent = () => (
    <span className={S['cell_block']}>{customLabel || (attr !== 'role' && e?.[attr]?.name) || '–'}</span>
  )
  if (!cnt) {
    return nameComponent()
  }
  if (!roleChangesEnabled) {
    return (
      <>
        {nameComponent()}
        <span className={classnames(S['work_location-moved-workers'], S['shift-change'])}>
          {title} updated for {cnt} {cnt === 1 ? 'worker' : 'workers'}
        </span>
      </>
    )
  }

  return (
    <div className={S['cell_inline_flex']}>
      {nameComponent()}
      <Tooltip
        title={
          <div className={S['cell_tooltip']}>
            {title} updated for {cnt} {cnt === 1 ? 'worker' : 'workers'}
          </div>
        }
        placement="bottom"
        arrow
      >
        <div className={S['cell_icon']}>
          <ErrorIcon size="sm" sx={{ alignItems: 'center', marginLeft: '2px' }} color="grey" />
        </div>
      </Tooltip>
    </div>
  )
}

export const getArea = (shiftRoleChanges: boolean) => (e: Partial<RowValue>) =>
  getNameWithOverride(e, 'area', shiftRoleChanges)

export const getVenue = (shiftRoleChanges: boolean) => (e: Partial<RowValue>) =>
  getNameWithOverride(e, 'venue', shiftRoleChanges)

export const getTelephoneNumber = (_: RowValue, s: SubRowValue) =>
  // @ts-expect-error
  s?.worker?.telephone_number ? <PhoneNumber value={s.worker.telephone_number} copyable /> : '-'

export const getPreviouslyCompletedShifts = (_: RowValue, s: SubRowValue) =>
  s?.worker?.completed_shifts_count ?? '-'

export const getWorkerCancellations = (_: RowValue, s: SubRowValue) => s?.cancellations_within_24_hours ?? '-'

export const getClockedIn = (e: RowValue, s: SubRowValue) =>
  s?.clock_in ? (
    // @ts-expect-error
    <FormattedDateTime
      value={s.clock_in.time}
      format={{
        ...fullLocalDateTimeFormat,
        timeZone: e?.venue?.timezone,
      }}
    />
  ) : (
    '–'
  )

export const getClockedOut = (e: RowValue, s: SubRowValue) =>
  s?.clock_out ? (
    // @ts-expect-error
    <FormattedDateTime
      value={s.clock_out.time}
      format={{
        ...fullLocalDateTimeFormat,
        timeZone: e?.venue?.timezone,
      }}
    />
  ) : (
    '–'
  )

export const getShiftBookingSelectValue = (e: RowValue, s: SubRowValue) => [
  {
    shiftBookingID: s?.shift_booking_id,
    workerID: s?.worker.id,
    jobID: e?.job_id,
  },
]

export const getHardSkills = (e: ShiftEntity, roles: RoleEntity[]) => {
  const titles = e?.hard_skill_ids?.map((id: number) => {
    let skillTitle
    Object.values(roles).forEach((item: RoleEntity) => {
      // eslint-disable-next-line array-callback-return
      item?.skills?.map((relation: SkillEntity) => {
        if (relation.id === id) {
          skillTitle = relation.title
        }
      })
    })
    return skillTitle
  })
  return titles?.join(', ')
}

export const getPayRate = (e: ShiftEntity, shiftRoleChanges: boolean) => {
  const payRates = e?.overrides_summary?.client_pay_rate
  if (shiftRoleChanges) {
    return (
      <div className={S['cell_inline_flex']}>
        {formatCurrency(e?.pay_rate?.amount, e?.pay_rate?.currency)}
        {!!payRates && payRates > 0 && (
          <Tooltip
            title={
              <div className={S['cell_tooltip']}>
                Pay rate updated for {payRates} {payRates === 1 ? 'worker' : 'workers'}
              </div>
            }
            placement="bottom"
            arrow
          >
            <ErrorIcon size="sm" sx={{ alignItems: 'center', marginLeft: '2px' }} color="grey" />
          </Tooltip>
        )}
      </div>
    )
  }
  return (
    <>
      {formatCurrency(e?.pay_rate?.amount, e?.pay_rate?.currency)}
      {!!payRates && payRates > 0 && (
        <>
          <div className={classnames(S['work_location-moved-workers'], S['shift-change'])}>
            Pay rate updated
          </div>
          <div className={classnames(S['work_location-moved-workers'], S['shift-change'])}>
            for {payRates} {payRates === 1 ? 'worker' : 'workers'}
          </div>
        </>
      )}
    </>
  )
}

export const StartEndTime = ({
  startTime,
  endTime,
  venue,
}: {
  startTime: string
  endTime: string
  venue: WorkLocation
}) => {
  const timeRangeOptions = React.useMemo(
    () => ({
      separator: '-',
      format: {
        timeZone: venue?.timezone,
        timeZoneName: 'short',
      },
    }),
    [venue],
  )
  const [timeRange] = useFormatTimeRange([startTime, endTime], timeRangeOptions)
  const [start, end] = (timeRange as string).split(timeRangeOptions.separator)

  return (
    <>
      {start}-{end}
    </>
  )
}

export const getWorkerChanges =
  (
    shiftAttributes: {
      area: WorkLocation
      venue: WorkLocation
      pay_rate: MonetaryAmount
      roleId: number
      startTime: string
      endTime: string
    },
    roles: Record<number, BaseEntity>,
  ) =>
  (row: ListingShiftBooking & { shiftBooking: ShiftBooking; client_pay_rate: MonetaryAmount }) => {
    const data = []
    if (
      row?.shiftBooking?.start_time &&
      row?.shiftBooking?.end_time &&
      (row?.shiftBooking?.start_time !== shiftAttributes.startTime ||
        row?.shiftBooking?.end_time !== shiftAttributes.endTime)
    ) {
      data.push({
        name: 'Time',
        value: (
          <StartEndTime
            venue={shiftAttributes.venue}
            startTime={row?.shiftBooking?.start_time}
            endTime={row?.shiftBooking?.end_time}
          />
        ),
      })
    }
    if (row?.shiftBooking?.venue && row?.shiftBooking?.venue?.name !== shiftAttributes?.venue?.name) {
      data.push({ name: 'Venue', value: row?.shiftBooking?.venue?.name })
    }
    if (row?.shiftBooking?.role_id && row?.shiftBooking?.role_id !== shiftAttributes?.roleId) {
      data.push({ name: 'Role', value: roles?.[row?.shiftBooking?.role_id]?.title })
    }
    if (shiftAttributes?.area?.name !== row?.shiftBooking?.area?.name) {
      data.push({ name: 'Area', value: row?.shiftBooking?.area?.name || 'Null' })
    }
    if (
      row?.client_pay_rate &&
      shiftAttributes?.pay_rate?.amount !== row?.client_pay_rate?.amount &&
      !row?.worker?.agency
    ) {
      data.push({
        name: 'Pay rate',
        value: formatCurrency(row?.client_pay_rate?.amount, row?.client_pay_rate?.currency),
      })
    }
    if (data.length) {
      return (
        <div>
          {data.map((item: { name: string; value: string }) => {
            return (
              <div key={item.name}>
                {item.name}: <span className={S['shift-change']}>{item.value}</span>
              </div>
            )
          })}
        </div>
      )
    }
    return <>-</>
  }

export const getShiftBookingSelectHeaderValue = (rowData: ShiftEntity) =>
  rowData.shift_bookings?.length
    ? rowData.shift_bookings?.map(s => ({
        shiftBookingID: s.shift_booking_id,
        workerID: s.worker.id,
        jobID: rowData.job_id,
      }))
    : undefined

export const addDayIfSameDay = (startDate: string | null, endDate: string | null) => {
  if (moment(startDate).isSame(moment(endDate), 'day')) {
    return {
      start_time_gte: startDate,
      start_time_lte: dateAddTzSetTime(moment(moment(endDate)).add(1, 'd')),
    }
  } else {
    return {
      start_time_gte: startDate,
      start_time_lte: endDate,
    }
  }
}

export const impactScoreToPercentage = (rowData: Partial<ShiftEntity>): string => {
  const { fulfilment_review_impact_score = 0.0 } = rowData
  const percentage = +(fulfilment_review_impact_score * 100).toFixed(2)

  return `${percentage}%`
}
