import { useMemo } from 'react'
import { range } from 'lodash-es'

import { formatWeekDay } from '../formatting'

export const getWorkWeekVariants = () =>
  range(0, 7)
    .map(idx => {
      const firstDay = idx + 1 > 6 ? 0 : idx + 1
      return { range: [firstDay, idx], label: `${formatWeekDay(firstDay)} - ${formatWeekDay(idx)}` }
    })
    .sort(a => a.range[1])

export const useWorkWeekVariants = () => useMemo(() => getWorkWeekVariants(), [])
