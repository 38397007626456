// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import React from 'react'

import { DefaultWrapper, defaultWrapperPropTypes } from 'syft-acp-util/entityList'
import SiteComponent from 'syft-acp-core/components/SiteComponent'

const EntityListComponent = props => (
  <SiteComponent className={props.className}>
    <DefaultWrapper {...props} />
  </SiteComponent>
)

EntityListComponent.propTypes = defaultWrapperPropTypes

export default EntityListComponent
