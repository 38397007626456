export const EMPLOYER_FETCH_BEGIN = 'syftacp/EMPLOYER_FETCH_BEGIN'
export const EMPLOYER_FETCH_FAILED = 'syftacp/EMPLOYER_FETCH_FAILED'
export const EMPLOYER_FETCH_SUCCEEDED = 'syftacp/EMPLOYER_FETCH_SUCCEEDED'

export const EMPLOYERS_FETCH_BEGIN = 'syftacp/EMPLOYERS_FETCH_BEGIN'
export const EMPLOYERS_FETCH_FAILED = 'syftacp/EMPLOYERS_FETCH_FAILED'
export const EMPLOYERS_FETCH_SUCCEEDED = 'syftacp/EMPLOYERS_FETCH_SUCCEEDED'

export const EMPLOYER_SAVE_BEGIN = 'syftacp/EMPLOYER_SAVE_BEGIN'
export const EMPLOYER_SAVE_FAILED = 'syftacp/EMPLOYER_SAVE_FAILED'
export const EMPLOYER_SAVE_SUCCEEDED = 'syftacp/EMPLOYER_SAVE_SUCCEEDED'

export const EMPLOYER_APPROVE_BEGIN = 'syftacp/EMPLOYER_APPROVE_BEGIN'
export const EMPLOYER_APPROVE_FAILED = 'syftacp/EMPLOYER_APPROVE_FAILED'
export const EMPLOYER_APPROVE_SUCCEEDED = 'syftacp/EMPLOYER_APPROVE_SUCCEEDED'

export const EMPLOYER_MIGRATE_BEGIN = 'syftacp/EMPLOYER_MIGRATE_BEGIN'
export const EMPLOYER_MIGRATE_FAILED = 'syftacp/EMPLOYER_MIGRATE_FAILED'
export const EMPLOYER_MIGRATE_SUCCEEDED = 'syftacp/EMPLOYER_MIGRATE_SUCCEEDED'

export const EMPLOYER_LOGIN_AS_BEGIN = 'syftacp/__NO_POST__/EMPLOYER_LOGIN_AS_BEGIN' // FIXME: see <syft-acp-core/store.js>.
export const EMPLOYER_LOGIN_AS_FAILED = 'syftacp/__NO_POST__/EMPLOYER_LOGIN_AS_FAILED'
export const EMPLOYER_LOGIN_AS_SUCCEEDED = 'syftacp/__NO_POST__/EMPLOYER_LOGIN_AS_SUCCEEDED'
