import React from 'react'
import PropTypes from 'prop-types'

import { formatCurrency } from 'syft-acp-util/formatting'
import { cellPropTypes, cellDefaultProps } from '../dataProps'
import { colPlaceholderFactory } from '../../../placeholders/factory'

// Column placeholder component.
export const AcpColMoney = colPlaceholderFactory('AcpCellMoney')

/**
 * Column type definition for AcpTable components.
 *
 *    <AcpCol type={ tableType.MONEY } value="rate" header="Worker rate" />
 */
export default class AcpCellMoney extends React.PureComponent {
  static propTypes = {
    ...cellPropTypes,
    // The database always gives us a string float value for money.
    // In some cases we might pass an integer or float after processing the number internally.
    value: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
      PropTypes.shape({
        amount: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        currency: PropTypes.string,
      }),
    ]),
  }

  static defaultProps = {
    ...cellDefaultProps,
  }

  static preferredAlignment = 'right'

  render() {
    const { value } = this.props
    const hasCurrency = value && value.currency
    const hasAmount = value && value.amount

    if (hasCurrency) {
      return <span>{formatCurrency(value.amount, value.currency)}</span>
    }
    if (hasAmount) {
      return <span>{formatCurrency(value.amount)}</span>
    }
    return <span>{formatCurrency(value)}</span>
  }
}
