import * as React from 'karet'
import * as U from 'karet.util'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { get } from 'lodash-es'
import { useTrackingTrigger } from '@indeed/flex-tracking-context'

import { fetchEmployer } from 'syft-acp-core/store/employers/actions'

import { getEmployerName } from 'syft-acp-util/formatting'
import { stringify } from 'syft-acp-core/store/filters/helpers'
import { resSalesWinAssociations } from 'syft-acp-core/api/res/admin/sales'

import SalesForceListPure from './SalesForceListPure'
import { promotionData, saveSalesforceData, loadPricingPlans, loadSalesForceData } from './data'
import { placeholderData } from './helpers'
import { trackingEvents } from './EmployerSalesForceList.tracking'

/**
 * Connected Salesforce component.
 *
 * This component loads data from the API and then populates the Salesforce EntityList component.
 * It only takes an 'employerID' number and always refreshes its data when mounted.
 */
const SalesForceListConnected = ({ employerID, employerName, routingQuery }) => {
  const triggerEvent = useTrackingTrigger()
  // Start loading pricing plans and Salesforce data.
  // The 'promotionData' lens will be updated when these requests finish.
  loadPricingPlans()

  // If we navigated here directly, we won't have any general employer data yet.
  if (!employerName) {
    // FIXME: component doesn't re-render. Make an atom.
    fetchEmployer(employerID)
  }

  const { salesforceData } = U.destructure(promotionData)
  const setName = stringify({ employerID })
  const salesforceSetLens = U.view(setName, salesforceData)

  // Pass on the data and meta to an EntityList in the pure component.
  const salesforceSetResults = U.view('reqResult', salesforceSetLens)
  const salesforceSetIsLoading = U.view('isLoading', salesforceSetLens)
  loadSalesForceData(employerID, false, routingQuery, salesforceSetIsLoading)

  // If this is our first time loading, add a placeholder object until we get data from the server.
  if (salesforceSetLens.get() == null || salesforceSetIsLoading.get() === true) {
    salesforceSetLens.set({ ...placeholderData })
  }
  return (
    <>
      <SalesForceListPure
        dataSalesforce={salesforceSetResults}
        employerID={employerID}
        employerName={employerName}
        entitySave={(...args) => {
          const { rowData } = args[0]
          triggerEvent(trackingEvents.SAVE.CLICKED, {
            id: rowData.id,
            sales_win_id: rowData.sales_win_id,
            target_type: rowData.target_type,
            target_id: rowData.target_id,
          })
          saveSalesforceData(
            ...args,
            employerID,
            salesforceSetResults,
            salesforceSetIsLoading,
            resSalesWinAssociations,
            routingQuery
          )
        }}
        hasData={U.view('hasData', salesforceSetLens)}
        isLoading={salesforceSetIsLoading}
        isOK={U.view('isOK', salesforceSetLens)}
      />
    </>
  )
}

SalesForceListConnected.propTypes = {
  employerID: PropTypes.number.isRequired,
  employerName: PropTypes.string.isRequired,
  routingQuery: PropTypes.object.isRequired,
}

export default connect(
  (state, ownProps) => ({
    routingQuery: get(state, 'routing.locationBeforeTransitions.query', {}),
    employerName: getEmployerName(state.employers.entityMap[ownProps.employerID]),
  }),
  dispatch => bindActionCreators({ fetchEmployer }, dispatch)
)(SalesForceListConnected)
