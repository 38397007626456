import React from 'react'
import PropTypes from 'prop-types'

import { workerStatuses } from 'syft-acp-core/data/workerStatus'
import { cellPropTypes, cellDefaultProps } from '../dataProps'
import { colPlaceholderFactory } from '../../../placeholders/factory'
import AcpCellStatus from './AcpCellStatus'

// Proptypes for worker statuses. Converts the worker statuses
// to a proptype definition, i.e. { failed_induction: PropTypes.bool, ... }.
const workerStatusProps = Object.keys(workerStatuses).reduce(
  (acc, status) => ({ ...acc, [status]: PropTypes.bool }),
  {}
)

// Column placeholder component.
export const AcpColWorkerStatus = colPlaceholderFactory('AcpCellWorkerStatus')

/**
 * Displays a worker's status tags. This uses AcpCellEnum with a number of preset values.
 *
 *    <Acp.Col.WorkerStatus value="worker_status" header="Status" />
 */
const AcpCellWorkerStatus = props => (
  <AcpCellStatus {...props} options={{ ...props.options, statuses: workerStatuses }} />
)

AcpCellWorkerStatus.propTypes = {
  ...cellPropTypes,
  value: PropTypes.shape(workerStatusProps),
  options: PropTypes.shape({
    style: PropTypes.string,
    displayUnknownValue: PropTypes.bool,
  }).isRequired,
}

AcpCellWorkerStatus.defaultProps = {
  ...cellDefaultProps,
}

export default AcpCellWorkerStatus
