import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Grid, Col, Row } from 'react-bootstrap'

import entityDetail from 'syft-acp-util/entityDetail'

import SiteComponent from 'syft-acp-core/components/SiteComponent'
import RecordSection from 'syft-acp-core/components/RecordSection'
import EntityAlert from 'syft-acp-core/components/Alerts/EntityAlert'
import * as managersActions from 'syft-acp-core/actions/managers'

import ManagerDetailControls from './ManagerDetailControls'

import { dataStructure } from './structure'

export class ManagerDetail extends Component {
  componentDidMount() {
    const { actions, id } = this.props

    if (id) {
      actions.fetchAdminManager(id)
    }
  }

  render() {
    return (
      <SiteComponent>
        <Grid>
          <Row>
            <Col md={12} sm={12}>
              <ManagerDetailControls data={this.props.data} isPristine={this.props.isPristine} />
              <EntityAlert lastBody={this.props.lastBody} />
              <RecordSection
                title="Manager information"
                data={this.props.data}
                structure={dataStructure}
                update={this.props.actionUpdate}
              />
            </Col>
          </Row>
        </Grid>
      </SiteComponent>
    )
  }
}

ManagerDetail.propTypes = {
  id: PropTypes.number.isRequired,
  data: PropTypes.object.isRequired,
  actionUpdate: PropTypes.func.isRequired,
  isPristine: PropTypes.bool.isRequired,
  actions: PropTypes.objectOf(PropTypes.func).isRequired,
  lastBody: PropTypes.object,
}

export default connect(null, dispatch => ({
  actions: bindActionCreators(managersActions, dispatch),
}))(entityDetail('managers', ManagerDetail))
