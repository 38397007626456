import React, { PropsWithChildren } from 'react'

import { Chip } from '@indeed/ifl-components'
import { DataArrayItemProps } from './DataArrayItem.types'

const DataArrayItem = ({
  index,
  onDelete,
  children,
  title,
  rawTitle,
  sx,
}: PropsWithChildren<DataArrayItemProps>) => (
  <Chip
    size="sm"
    title={title}
    sx={sx}
    onClick={e => e.preventDefault()}
    onRemove={onDelete ? () => onDelete(index) : undefined}
    removeButtonProps={{ 'aria-label': `Remove ${rawTitle} skill` }}
    type={onDelete ? 'removable' : 'single'}
  >
    {children}
  </Chip>
)

export default DataArrayItem
