import { useIntl } from 'react-intl'
import { includes, isFunction, isEmpty, isArray, compact } from 'lodash-es'
import PropTypes from 'prop-types'
import { useMemo } from 'react'

const isCountrySpecific = (currentCountry, countries, excluded) => {
  const hasMatches = includes(countries, currentCountry)
  return excluded ? !hasMatches : hasMatches
}

export const filterCountrySpecificStructs = (structure, countryCode) => {
  const isCollection = isArray(structure)
  const filteredStructure = Object.keys(structure).reduce(
    (filtered, key) => {
      const struct = structure[key]
      const { showForCountries, hideForCountries } = struct
      const excluded = isEmpty(showForCountries)

      if (
        (isEmpty(showForCountries) && isEmpty(hideForCountries)) ||
        isCountrySpecific(countryCode, excluded ? hideForCountries : showForCountries, excluded)
      ) {
        // eslint-disable-next-line no-param-reassign
        filtered[key] = struct
      }

      return filtered
    },
    !isCollection ? {} : [],
  )
  return isCollection ? compact(filteredStructure) : filteredStructure
}

export const useCountrySpecificStructs = structs => {
  const { countryCode } = useIntl()
  return useMemo(() => filterCountrySpecificStructs(structs, countryCode), [structs, countryCode])
}

const CountrySpecific = ({ children, countries, excluded }) => {
  const { countryCode } = useIntl()

  if (!countries || !countries.length) {
    return children
  }
  const shouldShow = isCountrySpecific(countryCode, countries, excluded)

  if (isFunction(children)) {
    return children(shouldShow, countryCode)
  }

  return (shouldShow && children) || null
}

CountrySpecific.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]).isRequired,
  countries: PropTypes.arrayOf(PropTypes.string).isRequired,
  excluded: PropTypes.bool.isRequired,
}

CountrySpecific.defaultProps = {
  countries: [],
  excluded: false,
}

export default CountrySpecific
