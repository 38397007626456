import PropTypes from 'prop-types'

import placeholderFactory from './factory'

/**
 * Placeholder element for component-wide control actions that show up in the header.
 * This is where, for example, a save button and a loader is placed.
 */
export default placeholderFactory('AcpControlGroup', {
  children: PropTypes.node.isRequired,
})
