import { apiRequest, handleCall } from 'syft-acp-core/api/call'
import { ApiResponse } from 'syft-acp-core/api/types'

import * as types from './types'

export const fetchWorkerStrikes = ({ workerID, options }: types.FetchWorkerStrikesInput) =>
  new Promise<ApiResponse<types.WorkerStrikeEntity[]>>((resolve, reject) => {
    const req = apiRequest({ path: `/admin/workers/${workerID}/strikes`, reqArgs: options })
    handleCall(req, resolve, reject, true, options)
  })

export const fetchWorkerStrike = ({ workerID, strikeID }: types.FetchWorkerStrikeInput) =>
  new Promise<ApiResponse<types.WorkerStrikeEntity>>((resolve, reject) => {
    const req = apiRequest({ path: `/admin/workers/${workerID}/strikes/${strikeID}` })
    handleCall(req, resolve, reject)
  })

export const updateWorkerStrike = ({ workerID, strikeID, data }: types.UpdateWorkerStrikeInput) =>
  new Promise<ApiResponse<types.WorkerStrikeEntity>>((resolve, reject) => {
    const req = apiRequest({ path: `/admin/workers/${workerID}/strikes/${strikeID}`, reqArgs: data, method: 'PATCH' })
    handleCall(req, resolve, reject)
  })

export const createWorkerStrike = ({ workerID, data }: types.CreateWorkerStrikeInput) =>
  new Promise<ApiResponse<types.WorkerStrikeEntity>>((resolve, reject) => {
    const req = apiRequest({ path: `/admin/workers/${workerID}/strikes`, reqArgs: data, method: 'POST' })
    handleCall(req, resolve, reject)
  })

export const deleteWorkerStrike = ({ workerID, strikeID }: types.DeleteWorkerStrikeInput) =>
  new Promise<ApiResponse<types.WorkerStrikeEntity>>((resolve, reject) => {
    const req = apiRequest({ path: `/admin/workers/${workerID}/strikes/${strikeID}`, method: 'DELETE' })
    handleCall(req, resolve, reject)
  })

export const fetchWorkerStrikeReasons = () =>
  new Promise<ApiResponse<Record<string, any>[]>>((resolve, reject) => {
    const req = apiRequest({ path: '/admin/workers/strike_reasons' })
    handleCall(req, resolve, reject)
  })
