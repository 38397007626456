import { connect } from 'react-redux'

import { updateAgencyBookings } from 'syft-acp-core/actions/agency-bookings'

import { ListingBreakInput } from './ListingBreakInput'

const mapDispatchToProps = dispatch => ({
  onChange: payload => dispatch(updateAgencyBookings(payload)),
})

export default connect(null, mapDispatchToProps)(ListingBreakInput)
