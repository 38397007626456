import * as types from '../action-types'

/**
 * Initiates a compliance rules list fetching process.
 * @param {Object} options Other options
 * @returns {{options: *, type: string}}
 */
export const fetchCompliancePolicies = ({ options }: { options: Record<string, any> }) => ({
  type: types.COMPLIANCE_POLICIES_FETCH_BEGIN,
  options,
})

export const updateCompliancePolicy = ({ options }: { options: Record<string, any> }) => ({
  type: types.COMPLIANCE_POLICIES_UPDATE_BEGIN,
  options,
  payload: options,
})

export const deleteCompliancePolicy = ({ options }: { options: Record<string, any> }) => ({
  type: types.COMPLIANCE_POLICIES_DELETE_BEGIN,
  options,
  payload: options,
})

export const createCompliancePolicy = ({ options }: { options: Record<string, any> }) => ({
  type: types.COMPLIANCE_POLICIES_CREATE_BEGIN,
  options,
  payload: options,
})
