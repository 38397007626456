import { apiRequest, handleCall } from 'syft-acp-core/api/call'

export const fetchRatings = ({ options: reqArgs }) =>
  new Promise((resolve, reject) => {
    const req = apiRequest({ path: '/admin/app_ratings', reqArgs })
    handleCall(req, resolve, reject, true, reqArgs)
  })

export const fetchNoShowReasons = ({ options: reqArgs }) =>
  new Promise((resolve, reject) => {
    const req = apiRequest({ path: '/no_show_reasons', reqArgs })
    handleCall(req, resolve, reject, true, reqArgs)
  })
