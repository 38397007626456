// Syft ACP - Atoms <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import * as React from 'karet'
import classNames from 'classnames'
import PropTypes from 'prop-types'

import './Table.css'

/**
 * This is a replacement component for the standard <table> that includes
 * basic styling for the ACP.
 */
const Table = ({
  // Content of the table.
  children,
  // Additional class name.
  className,
  // Whether the table has a margin separating it from sibling elements.
  hasTopMargin,
  hasBottomMargin,
  // Include borders in between rows.
  hasRowBorders,
  // Include borders in between columns.
  hasColBorders,
  // Include an outer border on each side.
  hasTopBorder,
  hasLeftBorder,
  hasRightBorder,
  hasBottomBorder,
  // Rounding settings for each four corners.
  hasTopLeftRounding,
  hasTopRightRounding,
  hasBottomLeftRounding,
  hasBottomRightRounding,
}) => {
  // Determine the table's look based on what settings were passed.
  const tableClass = classNames('simpletable', className, {
    hasTopMargin,
    hasBottomMargin,
    hasRowBorders,
    hasColBorders,
    hasTopBorder,
    hasLeftBorder,
    hasRightBorder,
    hasNoRightBorder: !hasRightBorder,
    hasBottomBorder,
    hasNoBottomBorder: !hasBottomBorder,
    hasTopLeftRounding,
    hasTopRightRounding,
    hasBottomLeftRounding,
    hasBottomRightRounding,
  })
  return <table className={tableClass}>{children}</table>
}

Table.defaultProps = {
  // Content of the table.
  children: null,
  // Additional class name.
  className: null,
  // Whether the table has a margin separating it from sibling elements.
  hasTopMargin: true,
  hasBottomMargin: true,
  // Include borders in between rows.
  hasRowBorders: true,
  // Include borders in between columns.
  hasColBorders: true,
  // Include an outer border on each side.
  hasTopBorder: true,
  hasLeftBorder: true,
  hasRightBorder: true,
  hasBottomBorder: true,
  // Rounding settings for each four corners.
  hasTopLeftRounding: true,
  hasTopRightRounding: true,
  hasBottomLeftRounding: true,
  hasBottomRightRounding: true,
}

Table.propTypes = {
  className: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.array]),
  hasTopMargin: PropTypes.bool,
  hasBottomMargin: PropTypes.bool,
  hasRowBorders: PropTypes.bool,
  hasColBorders: PropTypes.bool,
  hasTopBorder: PropTypes.bool,
  hasLeftBorder: PropTypes.bool,
  hasRightBorder: PropTypes.bool,
  hasBottomBorder: PropTypes.bool,
  hasTopLeftRounding: PropTypes.bool,
  hasTopRightRounding: PropTypes.bool,
  hasBottomLeftRounding: PropTypes.bool,
  hasBottomRightRounding: PropTypes.bool,
}

export default Table
