import React, { useCallback, useEffect, useMemo } from 'react'
import { connect } from 'react-redux'
import { isEmpty } from 'lodash-es'
import { bindActionCreators } from 'redux'
import RecordSection from 'syft-acp-core/components/RecordSection'
import { entityList$ } from 'syft-acp-core/reducers/generators/entities'
import * as externalPlatformsActions from 'syft-acp-core/store/external-platforms/actions'
import { showConfirmModal } from 'syft-acp-core/store/modals/actions'
import RecordHeaderForm from 'syft-acp-core/components/RecordSection/RecordHeaderForm'
import LoadingIndicator from 'syft-acp-util/components/LoadingIndicator'
import { Button } from 'syft-acp-atoms/Button'
import NotifyBar from 'syft-acp-atoms/NotifyBar'

import { createStructure } from './createStructure'

import type { Props, TransferRecordSectionProps } from './TransferRecordSection.types'
import { ExternalPlatformEntity } from '../../store/external-platforms/types'

export const connector = connect(
  (state, props: TransferRecordSectionProps) => ({
    externalPlatforms: entityList$(state.externalPlatforms) as ExternalPlatformEntity[],
    isLoadingData: props.isLoadingData || !!state.externalPlatforms.isLoadingData,
    isSavingData: props.isSavingData || !!state.externalPlatforms.isSavingData,
  }),
  (dispatch, props: TransferRecordSectionProps) => ({
    actions: { ...bindActionCreators({ ...externalPlatformsActions, showConfirmModal }, dispatch), ...props.actions },
  })
)

const TransferRecordSection = ({
  data,
  actions,
  isPristine,
  isSavingData,
  isLoadingData,
  lastMessage,
  externalPlatforms,
  actionUpdate,
  showTransferredOnField,
  showBranchField,
  showTimekeepingField,
}: Props) => {
  const structure = useMemo(
    () => createStructure({ externalPlatforms, showTransferredOnField, showBranchField, showTimekeepingField }),
    [externalPlatforms, showBranchField, showTimekeepingField, showTransferredOnField]
  )

  const saveTransferRecord = useCallback(() => {
    if (isPristine) return null

    if (!data.id) {
      return data.external_platform_id && actions.createTransferRecord(data)
    }

    if (!data.external_platform_id) {
      return actions.showConfirmModal({
        question: 'Are you sure? This transfer record will be permanently deleted.',
        onConfirm: () => actions.deleteTransferRecord(data),
      })
    }

    return actions.updateTransferRecord(data)
  }, [data, actions, isPristine])

  useEffect(() => {
    if (isEmpty(externalPlatforms)) {
      actions.fetchExternalPlatforms()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <RecordSection
      title="Transfer record"
      header={
        <RecordHeaderForm>
          <LoadingIndicator loadingState={isLoadingData} />
          <Button kind="success" disabled={isSavingData || isPristine} onClick={saveTransferRecord}>
            Save
          </Button>
        </RecordHeaderForm>
      }
      notificationsNode={
        <div style={{ padding: 15 }}>
          <NotifyBar conditional noMargin visible={!data.id} severity="warning">
            This entity is not transferred. Select 'External Platform' from the list to create transform record.
          </NotifyBar>

          <NotifyBar conditional noMargin visible={!!data.id} severity="success">
            This entity is transferred. To remove transfer record, select 'Not transferred' External platform option
          </NotifyBar>

          <NotifyBar conditional noMargin visible={!!lastMessage}>
            {lastMessage}
          </NotifyBar>
        </div>
      }
      data={data}
      structure={structure}
      update={actionUpdate}
    />
  )
}

export default connector(TransferRecordSection)
