// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import React from 'react'
import PropTypes from 'prop-types'

const Home = props => <div className="page">{props.children}</div>
Home.propTypes = {
  children: PropTypes.node,
}

export default Home
