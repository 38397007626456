// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import entityReducer from './generators/entityReducer'
import * as types from '../actions/action-types'
import * as entityTypes from './generators/entities'

export default entityReducer(
  'employerNetworks',
  {
    [entityTypes.ENTITIES_FETCH_BEGIN]: types.EMPLOYER_NETWORKS_FETCH_BEGIN,
    [entityTypes.ENTITIES_FETCH_SUCCEEDED]: types.EMPLOYER_NETWORKS_FETCH_SUCCEEDED,
    [entityTypes.ENTITIES_FETCH_FAILED]: types.EMPLOYER_NETWORKS_FETCH_FAILED,
    [entityTypes.ENTITY_FETCH_BEGIN]: types.EMPLOYER_NETWORK_FETCH_BEGIN,
    [entityTypes.ENTITY_FETCH_SUCCEEDED]: types.EMPLOYER_NETWORK_FETCH_SUCCEEDED,
    [entityTypes.ENTITY_FETCH_FAILED]: types.EMPLOYER_NETWORK_FETCH_FAILED,
  },
  {
    idKey: 'id',
    localEntityMap: true,
  }
)
