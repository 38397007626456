import entityReducer from 'syft-acp-core/reducers/generators/entityReducer'
import * as entityTypes from 'syft-acp-core/reducers/generators/entities'

import * as types from './action-types'

export default entityReducer(
  'venueCategories',
  {
    [entityTypes.ENTITIES_FETCH_BEGIN]: types.VENUE_CATEGORIES_FETCH_BEGIN,
    [entityTypes.ENTITIES_FETCH_SUCCEEDED]: types.VENUE_CATEGORIES_FETCH_SUCCEEDED,
    [entityTypes.ENTITIES_FETCH_FAILED]: types.VENUE_CATEGORIES_FETCH_FAILED,
  },
  {
    idKey: 'id',
    localEntityMap: true,
  }
)
