import React from 'react'
import SubNavigation, { SubLink } from 'syft-acp-atoms/SubNavigation'

const WorkersSubNavigation = () => (
  <SubNavigation>
    <SubLink to="/entity/workers/list">All workers</SubLink>
    <SubLink to="/entity/workers/transferred/list">Transferred workers</SubLink>
  </SubNavigation>
)

export default WorkersSubNavigation
