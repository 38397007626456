import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import * as filtersActions from 'syft-acp-core/store/filters/actions'
import { Store } from 'syft-acp-core/store'
import { requestStatusOptions } from '../TimeOffRequestList.helpers'

import { FilterTimeOffRequestStatusProps as Props } from './FilterTimeOffRequestStatus.types'
import { filterSelect } from 'syft-acp-util/components/FilterForm/FilterSelect'

export const storeConnector = connect(
  (state: Store) => ({
    query: state.routing.locationBeforeTransitions.query as Record<string, string | string[]>,
  }),
  dispatch => ({
    actions: {
      ...bindActionCreators(filtersActions, dispatch),
    },
  }),
)

const FilterTimeOffRequestStatus = ({
  defaultOption = 'Any',
  className = '',
  actions,
  allowAny = true,
  type,
  small = false,
  tiny = false,
  name = 'status',
  ariaLabel = 'Request status filter',
  query,
  onChange,
}: Props) => {
  const value = query[name]

  const FilterSelect = filterSelect(requestStatusOptions, defaultOption || 'Any', type, false, false)

  const onFilterChange = (val: string) => {
    !!onChange && onChange(name, val)
    actions.setFilter(name, val)
  }

  return (
    <FilterSelect
      name={name}
      className={`${className}`}
      onChange={onFilterChange}
      value={value}
      small={small}
      tiny={tiny}
      allowAny={allowAny}
      ariaLabel={ariaLabel}
    />
  )
}

export default storeConnector(FilterTimeOffRequestStatus)
