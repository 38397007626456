// Syft ACP - Lib <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import momentTz from 'moment-timezone'
import { DEFAULT_TIMEZONES } from 'syft-acp-core/lib/i18n'

const supportedTimezones = [...DEFAULT_TIMEZONES, 'America/New_York', 'America/Los_Angeles']

// Long names for timezones.
const abbreviations = {
  GMT: 'Greenwich Mean Time',
  BST: 'British Summer Time',
  CST: 'Central Standard Time',
  CDT: 'Central Daylight Time',
  EST: 'Eastern Standard Time',
  EDT: 'Eastern Daylight Time',
  PST: 'Pacific Standard Time',
  PDT: 'Pacific Daylight Time',
}

/**
 * Get the long name for a timezone by its abbreviation.
 */
export const getTimezoneName = abbr => {
  return abbreviations[abbr]
}

export const getSupportedTimezones = () => supportedTimezones

/**
 * Returns timezone information from a timezone identifier, e.g. "Europe/London".
 */
export const getTimezoneInfo = tz => {
  const [offset, abbr] = momentTz.tz(momentTz(), tz).format('Z$zz').split('$')
  const long = getTimezoneName(abbr)
  return {
    timezone: tz,
    abbr,
    long,
    offset,
  }
}
