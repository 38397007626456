import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { Field } from 'react-final-form'
import { ControlLabel, FormControl, FormGroup } from 'react-bootstrap'
import classnames from 'classnames'

const getValidator = isRequired => (isRequired ? value => (value ? undefined : 'Required') : () => {})

const getValidationState = meta => {
  if (!meta.touched) return null
  if (meta.error) return 'error'
  if (meta.valid) return 'success'

  return null
}

const RolesSelect = ({ industries, required, name, label, disabled, initialValues, ...rest }) => {
  if (initialValues && typeof initialValues?.role_id === 'number') {
    const default_industry = Object.values(industries).find(industry =>
      industry.roles.find(role => String(role.id) === String(initialValues.role_id)),
    )
    const default_role = default_industry?.roles.find(
      role => String(role.id) === String(initialValues.role_id),
    )
    initialValues.role_id = `${default_role?.id};${default_role?.title}`
  }

  return (
    <Field name={name} validate={getValidator(required)} {...rest}>
      {({ input, meta }) => (
        <FormGroup validationState={getValidationState(meta)} className={classnames({ required })}>
          <ControlLabel>{label}</ControlLabel>
          <FormControl
            disabled={disabled}
            componentClass="select"
            name={name}
            placeholder="select"
            {...input}
          >
            <option value="select">— Select a role —</option>
            {Object.values(industries).map(industry => (
              <optgroup data-testid="roles-industry-group" key={industry.id} label={industry.title}>
                {industry.roles.map(role => (
                  <option data-testid="role-option" key={role.id} value={`${role.id};${role.title}`}>
                    {role.title}
                  </option>
                ))}
              </optgroup>
            ))}
          </FormControl>
        </FormGroup>
      )}
    </Field>
  )
}

RolesSelect.propTypes = {
  industries: PropTypes.object,
  required: PropTypes.bool,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
}

RolesSelect.defaultProps = {
  industries: {},
  required: false,
  disabled: false,
}

export default connect(state => ({
  industries: state.industries.entityMap,
}))(RolesSelect)
