// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import { get } from 'lodash-es'
import config from 'syft-acp-core/config'
import { store } from 'syft-acp-core/store'

/** Returns the currently set country code, if any (null otherwise). */
export const getEnvCountryCode = () => {
  if (config.region) return config.region
  const auth = get(store.getState(), 'auth')
  return get(auth, 'userData.country_code', null)
}

/**
 * TEMPORARY MEASURE - only general staging supports currency for SalesForce.
 *
 * Should be deleted ASAP.
 */
export const _hasSalesForceCurrencySupport = () => config.region === 'GB' && config.stagingLevel !== 'prod'
