export const trackingEvents = {
  SHIFT_FULFILMENT: {
    PAGE: {
      LOADED: 'shift_fulfilment.page.loaded',
    },
    SHIFT: {
      SELECTED: 'shift_fulfilment.shift.selected',
    },
    SHIFT_LINK: {
      CLICKED: 'shift_fulfilment.shift_link.clicked',
    },
    VIEW_FILTERS: {
      CHANGED: 'shift_fulfilment.view_filters.changed',
      OPENED: 'shift_fulfilment.view_filters.opened',
      CLOSED: 'shift_fulfilment.view_filters.closed',
    },
    VIEW_SORT: {
      CHANGED: 'shift_fulfilment.view_sort.changed',
    },
  },
}
