// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import React from 'react'
import PropTypes from 'prop-types'

import './DataIcon.css'

const DataIcon = props => <span className="data-icon">{props.children}</span>
DataIcon.propTypes = {
  children: PropTypes.node.isRequired,
}

export default DataIcon
