// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import React from 'react'
import Clipboard from 'react-clipboard.js'

import { Button, ButtonIcon, ButtonGroup } from 'syft-acp-atoms/Button'
import { downloadLocalStorage } from 'syft-acp-core/lib/export'
import { showModal } from 'syft-acp-core/store/modals/actions'
import RecordControls from 'syft-acp-core/components/RecordSection/RecordControls'
import ImportApplicationStateModal from 'syft-acp-core/components/Modal/ImportApplicationStateModal'
import { clearState, copyAuthTokenOrNotify } from 'syft-acp-util/localState'
import { store } from 'syft-acp-core/store'

class ErrorControls extends React.PureComponent {
  render() {
    return (
      <RecordControls>
        <ImportApplicationStateModal />
        <div>
          <ButtonGroup key="export">
            {/* Note: there is another button like this in UserDetailControls. It might be removed later. */}
            <ButtonIcon icon="cloud-download" iconSize={16} left onClick={downloadLocalStorage} kind="primary">
              Export application state
            </ButtonIcon>
          </ButtonGroup>{' '}
          <ButtonGroup key="import">
            <ButtonIcon
              icon="cloud-upload"
              iconSize={16}
              left
              onClick={() => store.dispatch(showModal('importApplicationStateModal'))}
            >
              Import application state
            </ButtonIcon>
            <Button onClick={() => clearState()}>Clear state</Button>
          </ButtonGroup>{' '}
          <ButtonGroup key="auth">
            {/* FIXME: this should really use the Button component. */}
            <Clipboard
              className="regular btn Button btn btn-default"
              component="button"
              data-clipboard-text={copyAuthTokenOrNotify()}
            >
              Copy auth token
            </Clipboard>
          </ButtonGroup>
        </div>
      </RecordControls>
    )
  }
}

export default ErrorControls
