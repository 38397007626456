import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { values, intersection } from 'lodash-es'
import { entityList$ } from 'syft-acp-core/reducers/generators/entities'
import * as filtersActions from 'syft-acp-core/store/filters/actions'
import * as managersActions from 'syft-acp-core/actions/managers'
import { queue } from 'syft-acp-util/wait'
import { filterSelect } from './FilterSelect'

class FilterAutocompleteManagers extends React.PureComponent {
  componentDidMount() {
    // FIXME: for some reason, we don't get the most recent information here.
    // Huge hack to make sure we don't request managers 500 times in a row when using salesforce.
    queue(() => {
      if (this.props.managers.length === 0 && !this.props.isLoadingManagers) {
        this.props.actions.fetchAdminManagers({ options: {} })
      }
    })
  }

  onChange = val => {
    const { actions, name, onChange, isDataManager } = this.props
    // Data manager needs an extra param index, on filters don't need an index
    if (isDataManager) {
      actions.setFilter(name, val, val)
    } else {
      actions.setFilter(name, val)
    }
    !!onChange && onChange(name, val)
  }

  getManagersMap = () => values(this.props.managers).map(({ id, name }) => ({ id, label: name }))

  render() {
    const { value, allowAny, disabled, small, type, name, allowNameLabel } = this.props
    const managersMap = this.getManagersMap()
    const defaultOption = allowAny ? 'Any' : 'Manager'
    const FilterSelect = filterSelect(managersMap, defaultOption, type)

    const additionalProps = allowNameLabel
      ? {
          ariaLabel: name,
        }
      : {}

    return (
      <FilterSelect
        name={name}
        small={small}
        onChange={this.onChange}
        value={value}
        allowAny={allowAny}
        disabled={disabled}
        {...additionalProps}
      />
    )
  }
}

FilterAutocompleteManagers.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  managers: PropTypes.array.isRequired,
  actions: PropTypes.object.isRequired,
  isLoadingManagers: PropTypes.bool,
  onChange: PropTypes.func,
  allowAny: PropTypes.bool,
  isDataManager: PropTypes.bool,
  small: PropTypes.bool,
  disabled: PropTypes.bool,
  allowNameLabel: PropTypes.bool,
  name: PropTypes.string,
  type: PropTypes.string,
  queryKey: PropTypes.string,
  parameters: PropTypes.object,
}

FilterAutocompleteManagers.defaultProps = {
  isLoadingManagers: false,
  disabled: false,
  small: false,
  allowAny: false,
  isDataManager: false,
  allowNameLabel: false,
  name: null,
  type: null,
  value: '',
}

export default connect(
  (state, { value, parameters, queryKey = 'syft_manager_id' }) => {
    return {
      isLoadingManagers: state.managers.isLoadingData,
      managers:
        parameters && parameters.managerRoles
          ? entityList$(state.managers, {}).filter(
              manager => intersection(manager.manager_roles, parameters.managerRoles).length > 0,
            )
          : entityList$(state.managers, {}),
      value: value || state.routing.locationBeforeTransitions.query[queryKey],
    }
  },
  (dispatch, { actions }) => {
    const boundActions = bindActionCreators(
      {
        ...filtersActions,
        ...managersActions,
      },
      dispatch,
    )

    return {
      actions: {
        ...boundActions,
        ...actions,
      },
    }
  },
)(FilterAutocompleteManagers)
