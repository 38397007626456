import { apiRequest, handleCall } from 'syft-acp-core/api/call'
import { Action } from '../types'

export const fetchWorkerReferralClaims = ({ payload }: Action<{ workerId: number }>) =>
  new Promise((resolve, reject) => {
    const req = apiRequest({
      path: `/admin/workers/${payload?.workerId}/referral_claims`,
      method: 'GET',
    })
    handleCall(req, resolve, reject, true, { workerId: payload?.workerId })
  })
