// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import React from 'react'

import PageView from 'syft-acp-core/components/PageView'
import ShiftList from 'syft-acp-core/entities/ShiftList'

export const FlexShifts = () => (
  <PageView title={['Shifts', 'Flex shifts']} entityPage>
    <ShiftList query={{ platform: 'syft' }} />
  </PageView>
)
