import React from 'react'
import PropTypes from 'prop-types'
import { Row, Col } from 'react-bootstrap'
import { useFlexFlagIsOn } from '@indeed/flex-feature-flags'

import ListingMainInformation from './ListingMainInformation'
import ListingShiftBookings from './ListingShiftBookings'
import ListingAgencyShiftBookings from './ListingAgencyShiftBookings'
import ListingBelowMinimumHours from './ListingBelowMinimumHours'
import ListingMissingTime from './ListingMissingTime'

import './ListingShiftTabContent.css'

const ListingShiftTabContent = ({ breakDuration, listingID, shift, venue, job }) => {
  const enableListingDetailRoleChanges = useFlexFlagIsOn('pte_23601_listing_detail_role_changes')
  return (
    <div className="ListingShiftGroup">
      <div className="inner">
        <ListingMainInformation
          id={shift.id}
          listingID={listingID}
          initialData={shift}
          venue={venue}
          job={job}
          jobID={job.id}
          enableListingDetailRoleChanges={enableListingDetailRoleChanges}
        />
        <Row className="group-row">
          <Col md={12} sm={12}>
            <ListingShiftBookings
              enableListingDetailRoleChanges={enableListingDetailRoleChanges}
              title="Bookings"
              listingID={listingID}
              shiftID={shift.id}
              breakDuration={breakDuration}
              startTime={shift.start_time}
              endTime={shift.end_time}
              job={job}
              venue={venue}
            />
          </Col>
        </Row>
        <Row className="group-row">
          <Col md={12} sm={12}>
            <ListingAgencyShiftBookings
              enableListingDetailRoleChanges={enableListingDetailRoleChanges}
              listingID={listingID}
              shiftID={shift.id}
              startTime={shift.start_time}
              endTime={shift.end_time}
              venue={venue}
            />
          </Col>
        </Row>
        <Row className="group-row">
          <Col md={12} sm={12}>
            <ListingBelowMinimumHours listingID={listingID} shiftID={shift.id} />
          </Col>
        </Row>
        <Row className="group-row">
          <Col md={12} sm={12}>
            <ListingMissingTime listingID={listingID} shiftID={shift.id} />
          </Col>
        </Row>
      </div>
    </div>
  )
}

ListingShiftTabContent.propTypes = {
  breakDuration: PropTypes.number.isRequired,
  shift: PropTypes.object.isRequired,
  listingID: PropTypes.number,
  venue: PropTypes.object,
  job: PropTypes.object.isRequired,
}

ListingShiftTabContent.defaultProps = {
  listingID: 0,
  venue: {},
}

export default ListingShiftTabContent
