export const BOOKING_ADD = 'syftacp/BOOKING_ADD' as const
export const BOOKING_UPDATE = 'syftacp/BOOKING_UPDATE' as const
export const BOOKINGS_BULK_OFFER_BEGIN = 'syftacp/BOOKINGS_BULK_OFFER_BEGIN' as const
export const BOOKINGS_BULK_OFFER_FAILED = 'syftacp/BOOKINGS_BULK_OFFER_FAILED' as const
export const BOOKINGS_BULK_OFFER_SUCCEEDED = 'syftacp/BOOKINGS_BULK_OFFER_SUCCEEDED' as const
export const BOOKINGS_CANCEL_CHANGES = 'syftacp/BOOKINGS_CANCEL_CHANGES' as const
export const BOOKINGS_CREATE_CHANGES = 'syftacp/BOOKINGS_CREATE_CHANGES' as const
export const END_ASSIGNMENT_BEGIN = 'syftacp/END_ASSIGNMENT_BEGIN' as const
export const END_ASSIGNMENT_FAILED = 'syftacp/END_ASSIGNMENT_FAILED' as const
export const END_ASSIGNMENT_SUCCEEDED = 'syftacp/END_ASSIGNMENT_SUCCEEDED' as const
export const BLOCK_WORKER_CLIENT_BEGIN = 'syftacp/BLOCK_WORKER_CLIENT_BEGIN' as const
export const BLOCK_WORKER_CLIENT_FAILED = 'syftacp/BLOCK_WORKER_CLIENT_FAILED' as const
export const BLOCK_WORKER_CLIENT_SUCCEEDED = 'syftacp/BLOCK_WORKER_CLIENT_SUCCEEDED' as const
export const BOOKINGS_DELETE_BEGIN = 'syftacp/BOOKINGS_DELETE_BEGIN' as const
export const BOOKINGS_DELETE_FAILED = 'syftacp/BOOKINGS_DELETE_FAILED' as const
export const BOOKINGS_DELETE_SUCCEEDED = 'syftacp/BOOKINGS_DELETE_SUCCEEDED' as const
export const BOOKINGS_FETCH_BEGIN = 'syftacp/BOOKINGS_FETCH_BEGIN' as const
export const BOOKINGS_FETCH_FAILED = 'syftacp/BOOKINGS_FETCH_FAILED' as const
export const BOOKINGS_FETCH_SUCCEEDED = 'syftacp/BOOKINGS_FETCH_SUCCEEDED' as const
export const BOOKINGS_OFFER_BEGIN = 'syftacp/BOOKINGS_OFFER_BEGIN' as const
export const BOOKINGS_OFFER_FAILED = 'syftacp/BOOKINGS_OFFER_FAILED' as const
export const BOOKINGS_OFFER_SUCCEEDED = 'syftacp/BOOKINGS_OFFER_SUCCEEDED' as const
export const BOOKINGS_REMOVE_CHANGES = 'syftacp/BOOKINGS_REMOVE_CHANGES' as const
export const BOOKINGS_REPLACE_BEGIN = 'syftacp/BOOKINGS_REPLACE_BEGIN' as const
export const BOOKINGS_REPLACE_CHANGES = 'syftacp/BOOKINGS_REPLACE_CHANGES' as const
export const BOOKINGS_REPLACE_FAILED = 'syftacp/BOOKINGS_REPLACE_FAILED' as const
export const BOOKINGS_REPLACE_SUCCEEDED = 'syftacp/BOOKINGS_REPLACE_SUCCEEDED' as const
export const BOOKINGS_SAVE_BEGIN = 'syftacp/BOOKINGS_SAVE_BEGIN' as const
export const BOOKINGS_SAVE_FAILED = 'syftacp/BOOKINGS_SAVE_FAILED' as const
export const BOOKINGS_SAVE_SUCCEEDED = 'syftacp/BOOKINGS_SAVE_SUCCEEDED' as const
export const BOOKINGS_UPDATE_CHANGES = 'syftacp/BOOKINGS_UPDATE_CHANGES' as const
