import React, { useMemo, useEffect } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { flowRight } from 'lodash-es'
import { history } from 'syft-acp-core/history'
import { useTrackingTrigger } from '@indeed/flex-tracking-context'

import * as modalActions from 'syft-acp-core/store/modals/actions'
import Acp from 'syft-acp-uikit'
import { createEntityListConnector } from 'syft-acp-util/entityList'
import ManagerCommissionModal from 'syft-acp-core/components/Modal/ManagerCommissionModal'
import StopManagerCommissionModal from 'syft-acp-core/components/Modal/StopManagerCommissionModal'
import { getCity } from 'syft-acp-util/cities'
import { fetchManagerCommissions } from 'syft-acp-core/store/managerCommissions/actions'
import { ManagerCommissionEntity } from 'syft-acp-core/store/managerCommissions/types'
import {
  ManagerCommissionsListConnectedProps,
  ManagerCommissionsListProps,
  NewManagerCommissionPayload,
} from './types'
import { trackingEvents } from './ManagerCommissionsList.tracking'

const formatCityWithStateCode = (data: Partial<ManagerCommissionEntity>) =>
  [getCity(data.city?.id), data.city?.federated_state?.code].filter(Boolean).join(', ')

export const entityConnector = createEntityListConnector<Partial<ManagerCommissionEntity>>({
  entityActions: { fetchEntities: fetchManagerCommissions },
  entityStore: 'managerCommissions',
})

export const storeConnector = connect(null, (dispatch, props: ManagerCommissionsListConnectedProps) => ({
  dispatch,
  actions: { ...bindActionCreators({ ...modalActions }, dispatch), ...props.actions },
}))

const ManagerCommissionsList = ({ data, actions, params, query }: ManagerCommissionsListProps) => {
  const triggerEvent = useTrackingTrigger()
  const { work_location_id, employer_id } = params
  const resourceParams = useMemo(() => {
    const resource = {
      target_id: work_location_id || employer_id,
      target_type: work_location_id ? 'WorkLocation' : 'Employer',
      ...query,
    }
    return resource
  }, [employer_id, query, work_location_id])
  useEffect(() => {
    actions.fetchEntities && actions.fetchEntities({ options: resourceParams })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const confirmCallback = () => {
    actions.fetchEntities && actions.fetchEntities({ options: resourceParams })
  }

  const selectRowLink = () => {
    const { showModal } = actions
    const { sales_win_association_id } = params

    showModal('managerCommissionModal', {
      id: sales_win_association_id,
      targetType: resourceParams.target_type,
      onConfirm: (newCommissionData: NewManagerCommissionPayload) => {
        triggerEvent(trackingEvents.NEW.CLICKED, {
          industry_id: newCommissionData.industry_id,
          manager_ids: newCommissionData.syft_manager_ids,
          subindustry: newCommissionData.subindustry,
          effective_time_from: newCommissionData.effective_time_from,
        })
        confirmCallback()
      },
    })
  }

  const selectStopLink = (row: { rowData: Partial<ManagerCommissionEntity> }) => {
    const { showModal, showConfirmModal } = actions
    const { sales_win_association_id } = params

    if (!row.rowData.effective_time_to) {
      const id = row.rowData.id
      showModal('stopManagerCommissionModal', {
        dataId: sales_win_association_id,
        id,
        onConfirm: () => {
          triggerEvent(trackingEvents.STOP.CLICKED, {
            commission_id: id,
          })
          confirmCallback()
        },
      })
    } else {
      showConfirmModal({
        modalHeader: 'Stop commission',
        question: (
          <div>
            <p>The commission is already stopped!</p>
          </div>
        ),
      })
    }
  }

  const itemIndustryTitle = (item: Partial<ManagerCommissionEntity>) =>
    item.subindustry ? `${item?.industry?.title}, ${item.subindustry}` : item?.industry?.title
  const handleLinkBack = () => history.push(`/entity/employers/view/${employer_id}/commissions/list`)

  const selectHighlight = (item: Partial<ManagerCommissionEntity>) => (item.effective_time_to ? 'red' : '')

  return (
    <>
      <Acp.EntityList
        hasResultCount
        hasPagination
        inContainer
        idKeyValue="id"
        data={data}
        resArguments={resourceParams}
      >
        <Acp.Actions>
          <Acp.Header>
            Commissions for {resourceParams.target_type} #{resourceParams.target_id}
          </Acp.Header>
          <Acp.Button onClick={handleLinkBack}>Back to Commissions</Acp.Button>
          <Acp.Button kind="primary" onClick={selectRowLink}>
            Add new commission
          </Acp.Button>
        </Acp.Actions>
        <Acp.Table rowColor={selectHighlight}>
          <Acp.Col.Text value="syft_manager.name" header="Manager name" colNoLink />
          <Acp.Col.Text value={itemIndustryTitle} header="Industry / Subindustry" colNoLink />
          <Acp.Col.Text value={formatCityWithStateCode} header="City" colNoLink />
          <Acp.Col.Text value="syft_manager_position" header="Syft manager position" colNoLink />
          <Acp.Col.Timestamp
            value="effective_time_from"
            header="Effective date from"
            options={{ useFormat: 'date' }}
            isMinimal
          />
          <Acp.Col.Timestamp
            value="effective_time_to"
            header="Effective date to"
            options={{ useFormat: 'date' }}
            isMinimal
          />
          <Acp.Col.Button header="Action" isMinimal>
            <Acp.Button onClick={selectStopLink}>Stop</Acp.Button>
          </Acp.Col.Button>
        </Acp.Table>
      </Acp.EntityList>
      <ManagerCommissionModal employerId={employer_id} />
      <StopManagerCommissionModal employerId={employer_id} />
    </>
  )
}

export default flowRight(entityConnector, storeConnector)(ManagerCommissionsList)
