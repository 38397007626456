import React from 'react'
import { Field } from 'react-final-form'
import { Checkbox } from 'react-bootstrap'
import { FieldProps, Props } from './FormCheckbox.types'

export const FormCheckboxUI: React.FC<Props> = ({ input: { value, ...input }, meta, ...rest }) => (
  <Checkbox {...rest} {...input} />
)

export const FormCheckbox = (props: FieldProps) => (
  <Field<string> {...props} type="checkbox" component={FormCheckboxUI} />
)
