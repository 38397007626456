// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import entityReducer from 'syft-acp-core/reducers/generators/entityReducer'
import * as entityTypes from 'syft-acp-core/reducers/generators/entities'

import * as types from './action-types'
import { WorkerMissingPayEntity } from './types'

export default entityReducer<WorkerMissingPayEntity>(
  'workerMissingPay',
  {
    [entityTypes.ENTITIES_FETCH_BEGIN]: types.WORKER_MISSING_PAY_FETCH_BEGIN,
    [entityTypes.ENTITIES_FETCH_SUCCEEDED]: types.WORKER_MISSING_PAY_FETCH_SUCCEEDED,
    [entityTypes.ENTITIES_FETCH_FAILED]: types.WORKER_MISSING_PAY_FETCH_FAILED,
    [entityTypes.ENTITY_DELETE_BEGIN]: types.WORKER_MISSING_PAY_DELETE_BEGIN,
    [entityTypes.ENTITY_DELETE_SUCCEEDED]: types.WORKER_MISSING_PAY_DELETE_SUCCEEDED,
    [entityTypes.ENTITY_DELETE_FAILED]: types.WORKER_MISSING_PAY_DELETE_FAILED,
  },
  {
    localEntityMap: true,
  },
)
