import entityReducer from 'syft-acp-core/reducers/generators/entityReducer'
import * as entityTypes from 'syft-acp-core/reducers/generators/entities'
import * as types from './action-types'
import { ExternalPlatformEntity } from './types'

export default entityReducer<ExternalPlatformEntity>('externalPlatforms', {
  [entityTypes.ENTITIES_FETCH_BEGIN]: types.EXTERNAL_PLATFORMS_FETCH_BEGIN,
  [entityTypes.ENTITIES_FETCH_SUCCEEDED]: types.EXTERNAL_PLATFORMS_FETCH_SUCCEEDED,
  [entityTypes.ENTITIES_FETCH_FAILED]: types.EXTERNAL_PLATFORMS_FETCH_FAILED,
})
