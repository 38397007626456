// Syft ACP - UI Library <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import * as React from 'karet'
import * as U from 'karet.util'
import * as R from 'kefir.ramda'
import { cloneElement, useMemo } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { get, isArray, pick } from 'lodash-es'

import PageContainer from 'syft-acp-atoms/PageContainer'
import AcpPagination from '../AcpTable/paginationComponents/AcpPagination'
import AcpResultCount from '../AcpTable/AcpResultCount'
import AcpWrapper from '../AcpComponent/wrappers/AcpWrapper'
import { getTableSpec, getSubRowSpec } from '../AcpTable/childNodes'
import { RecordFooter, RecordHeader, RecordHeaderForm, RecordSegment } from '../AcpComponent/componentSegments'
import AcpListPopoverFilters from './AcpListPopoverFilters'

const orAtom = t => PropTypes.oneOfType([PropTypes.shape({ get: PropTypes.func }), t])

/** Returns true if the given element has an AcpHeader inside of it. */
const hasHeader = element => {
  const children = get(element, 'children', null)
  const childElements = isArray(children) ? children : [children]

  if (!children) return false
  return childElements.find(c => get(c, 'type.identityName') === 'AcpHeader')
}

/** Lifted function to render the pagination. Used until AcpPagination is modified to work natively with lenses. */
const renderPagination = U.lift(
  ({ meta, insideWrapper = false, isRegularText = false, pagination = {}, withInfoColumn }) => (
    <AcpPagination
      data={meta}
      insideWrapper={insideWrapper}
      isRegularText={isRegularText}
      withInfoColumn={withInfoColumn}
      {...pagination}
    />
  )
)

/** Lifted function to render the result count. */
const renderResultCount = U.lift(({ isIntegrated, isLoading, meta, isOK, withInfoColumn }) => (
  <AcpResultCount
    withInfoColumn={withInfoColumn}
    isIntegrated={isIntegrated}
    meta={meta}
    isOK={isOK}
    isLoading={isLoading}
  />
))

const AcpListWrapper = ({
  className,
  componentData,
  data,
  hasInnerPagination,
  hasInnerResultCount,
  hasMinimumSize,
  hasNoMargin,
  hasPagination,
  hasResultCount,
  idKeyValue,
  inContainer,
  isIntegrated,
  withInfoColumn,
  isLoading,
  isOK,
  isStale,
  meta,
  reloadLastCall,
  reqError,
  rowSelection,
  stateSelection,
  stateSubSelection,
  actionsInline = false,
  hasPopoverFilters,
  popoverButtonLabel,
  legend,
  initialFilters,
  pagination,
  onPopoverFilterOpen,
  onPopoverFilterClose,
}) => {
  const componentClass = classnames('acp-list-wrapper', className)
  // All valid components that may be inside.
  const { actionsNode, headerNode, dataDefinition, footerNode, subDataSpec, tableNode } = useMemo(() => {
    const { AcpActions, AcpHeader, AcpFooter, AcpTable } = pick(componentData, [
      'AcpActions',
      'AcpHeader',
      'AcpFooter',
      'AcpTable',
    ])
    const dataDef = getTableSpec(AcpTable.props.children)
    const subDataSpecification = getSubRowSpec(dataDef)

    return {
      actionsNode: AcpActions,
      headerNode: AcpHeader,
      footerNode: AcpFooter,
      tableNode: AcpTable,
      // Retrieves all props from child elements that are of <AcpCol /> type.
      dataDefinition: dataDef,
      // A filtered list of the above for only subrows.
      subDataSpec: subDataSpecification,
    }
  }, [componentData])

  // Whether the actions filters should be set in a narrow (responsive) container.
  // NOTE: this is currently untested with integrated lists.
  const hasNarrowActions = get(actionsNode, 'narrowContainer', false)

  // Whether the actions node contains a header.
  const actionsNodeHasHeader = hasHeader(actionsNode)

  // Whether this component has a top or bottom section; used to set the borders.
  const hasTopSection = Boolean(
    headerNode || (actionsNode && actionsNodeHasHeader) || (hasResultCount && hasInnerResultCount)
  )
  const hasBottomSection = Boolean(footerNode || (hasPagination && hasInnerPagination))

  // Wrap the component in a page container if the 'inContainer' attribute is there.
  // Otherwise this gets wrapped in an inert div.
  const ComponentWrapper = inContainer ? PageContainer : 'div'

  const hasActionsHeader = actionsNode && !actionsNodeHasHeader

  // TODO: this requires cleanup, to be done as part of a general refactoring.
  return (
    <ComponentWrapper>
      {hasPopoverFilters && hasActionsHeader && (
        <AcpListPopoverFilters
          data={data}
          subDataSpec={subDataSpec}
          stateSelection={stateSelection}
          stateSubSelection={stateSubSelection}
          isNarrow={hasNarrowActions}
          isLoading={isLoading}
          actionsNode={actionsNode}
          initialFilters={initialFilters}
          popoverButtonLabel={popoverButtonLabel}
          onOpen={onPopoverFilterOpen}
          onClose={onPopoverFilterClose}
          legend={legend}
        />
      )}
      {!hasPopoverFilters && hasActionsHeader && (
        <RecordHeaderForm
          data={data}
          subDataSpec={subDataSpec}
          stateSelection={stateSelection}
          stateSubSelection={stateSubSelection}
          isNarrow={hasNarrowActions}
          isLoading={isLoading}
        >
          {actionsNode.children}
        </RecordHeaderForm>
      )}
      {hasResultCount && !hasInnerResultCount && (
        <>
          {U.when(R.not(R.isEmpty(meta)), renderResultCount({ isLoading, isOK, isIntegrated, meta, withInfoColumn }))}
        </>
      )}
      <AcpWrapper
        hasNoMargin={hasNoMargin}
        isIntegrated={isIntegrated}
        className={componentClass}
        noBottomBorder={!hasBottomSection}
        componentData={componentData}
      >
        {((actionsNode && actionsNodeHasHeader) || headerNode) && (
          <RecordSegment noPadding>
            {actionsNode && actionsNodeHasHeader ? (
              <RecordHeaderForm
                data={data}
                subDataSpec={subDataSpec}
                stateSelection={stateSelection}
                stateSubSelection={stateSubSelection}
                isNarrow={hasNarrowActions}
                isHeader
                isLoading={isLoading}
                actionsInline={actionsInline}
              >
                {actionsNode.children}
              </RecordHeaderForm>
            ) : null}
            {headerNode && <RecordHeader main>{headerNode.children}</RecordHeader>}
          </RecordSegment>
        )}
        {hasResultCount && hasInnerResultCount && (
          <>
            {U.when(
              R.not(R.isEmpty(meta)),
              <RecordSegment withText>
                {<>{renderResultCount({ isLoading, isOK, isIntegrated: true, meta, withInfoColumn })}</>}
              </RecordSegment>
            )}
          </>
        )}
        {cloneElement(tableNode, {
          reloadLastCall,
          dataDefinition,
          stateSelection,
          stateSubSelection,
          rowSelection,
          isIntegrated,
          isStale,
          hasTopSection,
          hasMinimumSize,
          hasBottomSection,
          reqError,
          data,
          isLoading,
          isOK,
          idKeyValue,
        })}
        {footerNode && <RecordFooter hasBackground={!isIntegrated}>{footerNode.children}</RecordFooter>}
        {hasPagination && hasInnerPagination && (
          <>
            {U.when(
              R.not(R.isEmpty(meta)),
              renderPagination({ meta, insideWrapper: true, pagination, withInfoColumn })
            )}
          </>
        )}
      </AcpWrapper>
      {hasPagination && !hasInnerPagination && (
        <>
          {U.when(R.not(R.isEmpty(meta)), renderPagination({ meta, insideWrapper: false, pagination, withInfoColumn }))}
        </>
      )}
    </ComponentWrapper>
  )
}

AcpListWrapper.propTypes = {
  className: PropTypes.string,
  componentData: PropTypes.object.isRequired,
  data: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  reqError: PropTypes.object,
  hasInnerPagination: PropTypes.bool,
  hasInnerResultCount: PropTypes.bool,
  hasMinimumSize: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
  hasNoMargin: PropTypes.bool,
  withInfoColumn: PropTypes.bool,
  hasPagination: PropTypes.bool,
  hasResultCount: PropTypes.bool,
  idKeyValue: PropTypes.string,
  inContainer: PropTypes.bool,
  hasPopoverFilters: PropTypes.bool,
  isIntegrated: PropTypes.bool,
  isLoading: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
  isOK: orAtom(PropTypes.bool),
  isStale: PropTypes.bool,
  meta: PropTypes.object,
  reloadLastCall: PropTypes.func,
  rowSelection: PropTypes.object,
  legend: PropTypes.node,
  initialFilters: PropTypes.object,
  stateSelection: PropTypes.object.isRequired,
  stateSubSelection: PropTypes.object.isRequired,
  actionsInline: PropTypes.bool,
  pagination: PropTypes.object,
  onPopoverFilterOpen: PropTypes.func,
  onPopoverFilterClose: PropTypes.func,
}

AcpListWrapper.defaultProps = {
  className: null,
  data: [],
  reqError: null,
  legend: null,
  hasInnerPagination: true,
  hasInnerResultCount: true,
  hasMinimumSize: false,
  hasNoMargin: false,
  hasPagination: true,
  hasResultCount: true,
  idKeyValue: null,
  inContainer: false,
  isIntegrated: true,
  isLoading: false,
  isOK: true,
  isStale: false,
  meta: {},
  reloadLastCall: null,
  rowSelection: null,
  pagination: {},
  onPopoverFilterOpen: null,
  onPopoverFilterClose: null,
}

export default AcpListWrapper
