import React from 'react'

import { Modal } from '@indeed/ipl-modal'
import { Text } from '@indeed/ifl-components'
import { ListingWarningModalProps as Props } from './ListingWarningModal.types'
import { getModal$ } from 'syft-acp-core/store/modals/selectors'
import { showModal } from 'syft-acp-core/lib/modals'
import { hideModal } from 'syft-acp-core/store/modals/actions'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Warning } from './Warning.types'

export const storeConnector = connect(
  state => ({
    modal: getModal$(state.modals)('listingWarningModal'),
  }),
  dispatch => ({
    actions: {
      ...bindActionCreators({ showModal, hideModal }, dispatch),
    },
  }),
)

const ListingWarningModal = ({ actions, modal }: Props) => {
  const handleOnExit = () => {
    actions.hideModal('listingWarningModal')
  }

  return (
    <Modal
      isOpen={modal.isShown}
      onExit={handleOnExit}
      title="Warning"
      footerButtons={[
        {
          label: 'Close',
          props: {
            onClick: () => handleOnExit(),
          },
        },
      ]}
    >
      {modal.options.warningData?.map((warning: Warning) => {
        return (
          <>
            <Text as="p" level={4}>
              {warning.heading}
            </Text>
            {warning?.messages?.map((warningMessage: string) => {
              return (
                <Text as="p" level={3}>
                  {warningMessage}
                </Text>
              )
            })}
          </>
        )
      })}
    </Modal>
  )
}

export default storeConnector(ListingWarningModal)
