import React from 'react'
import { Col, FormGroup, Grid, Row } from 'react-bootstrap'
import { range as createRange } from 'lodash-es'

import FormCheckbox from 'syft-acp-core/components/Form/FormCheckbox'
import FormCondition from 'syft-acp-core/components/Form/FormCondition'
import ToggleFields from 'syft-acp-core/components/Form/ToggleFields'
import WorkWeekVariants, { WorkWeekVariantsComponentProps } from 'syft-acp-core/components/WorkWeekVariants'

import type { Props } from './WorkingWeekInvoicingOptions.types'

import './WorkingWeekInvoicingOptions.scss'

export const defaultFieldNames = {
  workingWeekOptions: 'workingWeekOptions',
  workweekInvoicingData: 'workweekInvoicingData',
  masterInvoicingData: 'masterInvoicingData',
}

const WorkingWeekOptions: React.FC<WorkWeekVariantsComponentProps> = ({ name, range, children }) => (
  <FormCheckbox name={name} value={String(range[1])}>
    {children}
  </FormCheckbox>
)

export const WorkingWeekInvoicingOptions = ({ fieldNames }: Props) => (
  <Grid fluid className="WorkingWeekInvoicingOptions" data-testid="WorkingWeekInvoicingOptions">
    <Row>
      {/* w/a to stretch row to the edge of container */}
      <Row>
        <Col xs={12} md={8} lg={6} className="WorkingWeekInvoicingOptions__column">
          <FormGroup>
            <FormCheckbox
              name={fieldNames.workweekInvoicingData}
              value="true"
              inline
              className="WorkingWeekInvoicingOptions__headline"
            >
              Workweek invoicing data
            </FormCheckbox>
            <FormCondition when={fieldNames.workweekInvoicingData} is={['true']} useTransition>
              <ToggleFields name={fieldNames.workingWeekOptions} allValues={createRange(0, 7).map(String)} />
              <WorkWeekVariants component={WorkingWeekOptions} name={fieldNames.workingWeekOptions} />
            </FormCondition>
          </FormGroup>
        </Col>
        <Col xs={12} md={4} lg={6} className="WorkingWeekInvoicingOptions__column">
          <FormCheckbox
            name={fieldNames.masterInvoicingData}
            value="true"
            inline
            className="WorkingWeekInvoicingOptions__headline"
          >
            Master invoicing data
          </FormCheckbox>
          <p>All worked shifts in a Monday to Sunday period — for payroll reconciliation</p>
        </Col>
      </Row>
    </Row>
  </Grid>
)
