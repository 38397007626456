import entityReducer from './generators/entityReducer'

import * as entityTypes from './generators/entities'
import * as types from '../actions/action-types'

export default entityReducer('employerVenueSalesForce', {
  [entityTypes.ENTITY_FETCH_BEGIN]: types.EMPLOYER_VENUE_SALES_WIN_FETCH_BEGIN,
  [entityTypes.ENTITY_FETCH_SUCCEEDED]: types.EMPLOYER_VENUE_SALES_WIN_FETCH_SUCCEEDED,
  [entityTypes.ENTITY_FETCH_FAILED]: types.EMPLOYER_VENUE_SALES_WIN_FETCH_FAILED,
  [entityTypes.ENTITY_SAVE_BEGIN]: types.EMPLOYER_VENUE_SALES_WIN_SAVE_BEGIN,
  [entityTypes.ENTITY_SAVE_SUCCEEDED]: types.EMPLOYER_VENUE_SALES_WIN_SAVE_SUCCEEDED,
  [entityTypes.ENTITY_SAVE_FAILED]: types.EMPLOYER_VENUE_SALES_WIN_SAVE_FAILED,
})
