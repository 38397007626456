// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import './RoleSkillDisplay.css'

class RoleDisplay extends React.PureComponent {
  static propTypes = {
    id: PropTypes.number,
    industries: PropTypes.object,
  }

  static defaultProps = {
    id: undefined,
    industries: {},
  }

  render() {
    const { id, industries } = this.props
    const roles = Object.values(industries).reduce((all, i) => [...all, ...i.roles], [])
    const role = roles.find(r => r.id === id)
    return <div className="RoleSkillDisplay">{role ? role.title : '–'}</div>
  }
}

export default connect(state => ({
  industries: state.industries.entityMap,
}))(RoleDisplay)
