import * as React from 'karet'
import * as L from 'kefir.partial.lenses'
import * as U from 'karet.util'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { PropTypesAtom } from 'syft-acp-util/observables'

import './InputDropdown.css'

const InputDropdown = ({ value, disabled, selectOptions, className, isNumber, placeholder }) => {
  return (
    <div className={classNames('acp-input-dropdown', className)}>
      <U.Select disabled={disabled} value={isNumber ? U.view(L.rewrite(Number), value) : value}>
        <option value="" disabled className="placeholder">
          {placeholder}
        </option>
        {L.collectAs(
          ({ slug, label }) => (
            <option key={slug} value={slug}>
              {label}
            </option>
          ),
          L.elems,
          selectOptions
        )}
      </U.Select>
    </div>
  )
}

InputDropdown.propTypes = {
  value: PropTypesAtom,
  isNumber: PropTypes.bool,
  disabled: PropTypes.oneOfType([PropTypesAtom, PropTypes.bool]),
  placeholder: PropTypes.string,
  className: PropTypes.string,
  selectOptions: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.shape({
        slug: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
      })
    ).isRequired,
    // FIXME: proptype for lens
    PropTypes.object,
  ]),
}

InputDropdown.defaultProps = {
  className: '',
  selectOptions: null,
  disabled: false,
  isNumber: false,
  placeholder: '–',
  value: null,
}

export default InputDropdown
