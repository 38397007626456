import React, { useState, useCallback } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { bindActionCreators } from 'redux'
import { get, flowRight } from 'lodash-es'
import { subDays } from 'date-fns'

import * as filtersActions from 'syft-acp-core/store/filters/actions'
import { dateAddTz } from 'syft-acp-util/time'
import { filterSelect } from '../FilterSelect'
import { Props } from './types'

export const storeConnector = connect(
  (state: any, ownProps: any) => {
    const queryAttribute = get(ownProps, 'queryAttribute', get(ownProps, 'name'))
    return {
      value: ownProps.value || state.routing.locationBeforeTransitions.query[queryAttribute],
    }
  },
  dispatch => ({
    actions: bindActionCreators({ ...filtersActions }, dispatch),
  })
)

type AllProps = ConnectedProps<typeof storeConnector> & Props

const FilterTimeDropdown = ({
  allowAny,
  disabled,
  name,
  nameAfter,
  nameBefore,
  options,
  defaultOption,
  className,
  actions,
  value,
}: Partial<AllProps>) => {
  const [innerValue, setInnerValue] = useState(value)
  const onChange = useCallback(
    val => {
      const today = new Date()
      const date = new Date()
      const yesterday = subDays(date, 1)

      date.setDate(today.getDate() - val)
      setInnerValue(String(val))
      actions?.setFilter(name, val)
      actions?.setFilter(nameAfter, val === 0 ? dateAddTz(yesterday) : null)
      actions?.setFilter(nameBefore, val > 0 ? dateAddTz(date) : null)
    },
    [actions, name, nameAfter, nameBefore]
  )

  const FilterSelect = filterSelect(options, defaultOption)

  return (
    <FilterSelect
      name={name}
      className={className}
      onChange={onChange}
      value={innerValue}
      allowAny={allowAny}
      disabled={disabled}
    />
  )
}

export default flowRight(storeConnector)(FilterTimeDropdown)
