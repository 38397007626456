import * as React from 'react'
import classnames from 'classnames'

import AcpComponent from '../../../AcpComponent/index'

import { FormActionWrapperProps, FormFilterWrapperProps } from './RecordWrappers.types'
import './RecordWrappers.scss'

/**
 * Wrapper for form elements inside an actions container (e.g. a save button next to the loader).
 */
export const FormActionWrapper = ({ children, betweenSpace }: FormActionWrapperProps) => {
  return (
    <div className="action-component-wrapper">
      {/* A single space character to separate the buttons. */}
      {betweenSpace ? <>&nbsp;</> : null}
      {children}
    </div>
  )
}

FormActionWrapper.defaultProps = {
  children: null,
  betweenSpace: true,
}

/**
 * Wrapper for filter sections inside of a record header form.
 *
 * EntityLists can be either integrated or non-integrated. If they are integrated,
 * they will have a header that can contain buttons and a loader, and not much else.
 * If they are non-integrated, they often have a ton of filters at the top.
 *
 * This wrapper component is used for adding filters to a header.
 */
export const FormFilterWrapper = ({ children, maxWidth, inline }: FormFilterWrapperProps) => {
  return (
    <div
      className={classnames(
        'filter-component-wrapper',
        maxWidth && 'filter-component-wrapper--max-width',
        inline && 'filter-component-wrapper--inline'
      )}
    >
      {children}
    </div>
  )
}

FormFilterWrapper.defaultProps = {
  children: null,
  maxWidth: false,
}

// TODO: type AcpComponent and import props types
export const GenericComponent = (props: Record<string, any>) => (
  <div className="acp-actions-generic-component">
    <AcpComponent {...props} />
  </div>
)
