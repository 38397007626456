export const WORKER_HOLIDAY_PAY_ACCRUALS_FETCH_BEGIN = 'syftacp/WORKER_HOLIDAY_PAY_ACCRUALS_FETCH_BEGIN' as const
export const WORKER_HOLIDAY_PAY_ACCRUALS_FETCH_SUCCEEDED =
  'syftacp/WORKER_HOLIDAY_PAY_ACCRUALS_FETCH_SUCCEEDED' as const
export const WORKER_HOLIDAY_PAY_ACCRUALS_FETCH_FAILED = 'syftacp/WORKER_HOLIDAY_PAY_ACCRUALS_FETCH_FAILED' as const

export const WORKER_HOLIDAY_PAY_HISTORIC_REQUESTS_FETCH_BEGIN =
  'syftacp/WORKER_HOLIDAY_PAY_HISTORIC_REQUESTS_FETCH_BEGIN' as const
export const WORKER_HOLIDAY_PAY_HISTORIC_REQUESTS_FETCH_SUCCEEDED =
  'syftacp/WORKER_HOLIDAY_PAY_HISTORIC_REQUESTS_FETCH_SUCCEEDED' as const
export const WORKER_HOLIDAY_PAY_HISTORIC_REQUESTS_FETCH_FAILED =
  'syftacp/WORKER_HOLIDAY_PAY_HISTORIC_REQUESTS_FETCH_FAILED' as const

export const WORKER_HOLIDAY_PAY_HISTORIC_REQUEST_MAKE_BEGIN =
  'syftacp/WORKER_HOLIDAY_PAY_HISTORIC_REQUEST_MAKE_BEGIN' as const
export const WORKER_HOLIDAY_PAY_HISTORIC_REQUEST_MAKE_SUCCEEDED =
  'syftacp/WORKER_HOLIDAY_PAY_HISTORIC_REQUEST_MAKE_SUCCEEDED' as const
export const WORKER_HOLIDAY_PAY_HISTORIC_REQUEST_MAKE_FAILED =
  'syftacp/WORKER_HOLIDAY_PAY_HISTORIC_REQUEST_MAKE_FAILED' as const

export const WORKER_HOLIDAY_PAY_HISTORIC_REQUEST_DELETE_BEGIN =
  'syftacp/WORKER_HOLIDAY_PAY_HISTORIC_REQUEST_DELETE_BEGIN' as const
export const WORKER_HOLIDAY_PAY_HISTORIC_REQUEST_DELETE_SUCCEEDED =
  'syftacp/WORKER_HOLIDAY_PAY_HISTORIC_REQUEST_DELETE_SUCCEEDED' as const
export const WORKER_HOLIDAY_PAY_HISTORIC_REQUEST_DELETE_FAILED =
  'syftacp/WORKER_HOLIDAY_PAY_HISTORIC_REQUEST_DELETE_FAILED' as const

export const WORKER_HOLIDAY_PAY_HISTORIC_REQUEST_VALIDATE_BEGIN =
  'syftacp/WORKER_HOLIDAY_PAY_HISTORIC_REQUEST_VALIDATE_BEGIN' as const
export const WORKER_HOLIDAY_PAY_HISTORIC_REQUEST_VALIDATE_SUCCEEDED =
  'syftacp/WORKER_HOLIDAY_PAY_HISTORIC_REQUEST_VALIDATE_SUCCEEDED' as const
export const WORKER_HOLIDAY_PAY_HISTORIC_REQUEST_VALIDATE_FAILED =
  'syftacp/WORKER_HOLIDAY_PAY_HISTORIC_REQUEST_VALIDATE_FAILED' as const
export const WORKER_HOLIDAY_PAY_HISTORIC_REQUEST_VALIDATE_RESET =
  'syftacp/WORKER_HOLIDAY_PAY_HISTORIC_REQUEST_VALIDATE_RESET' as const

export const WORKER_HOLIDAY_PAY_WEEKLY_REQUESTS_FETCH_BEGIN =
  'syftacp/WORKER_HOLIDAY_PAY_WEEKLY_REQUESTS_FETCH_BEGIN' as const
export const WORKER_HOLIDAY_PAY_WEEKLY_REQUESTS_FETCH_SUCCEEDED =
  'syftacp/WORKER_HOLIDAY_PAY_WEEKLY_REQUESTS_FETCH_SUCCEEDED' as const
export const WORKER_HOLIDAY_PAY_WEEKLY_REQUESTS_FETCH_FAILED =
  'syftacp/WORKER_HOLIDAY_PAY_WEEKLY_REQUESTS_FETCH_FAILED' as const

export const WORKER_HOLIDAY_PAY_WEEKLY_REQUEST_MAKE_BEGIN =
  'syftacp/WORKER_HOLIDAY_PAY_WEEKLY_REQUEST_MAKE_BEGIN' as const
export const WORKER_HOLIDAY_PAY_WEEKLY_REQUEST_MAKE_SUCCEEDED =
  'syftacp/WORKER_HOLIDAY_PAY_WEEKLY_REQUEST_MAKE_SUCCEEDED' as const
export const WORKER_HOLIDAY_PAY_WEEKLY_REQUEST_MAKE_FAILED =
  'syftacp/WORKER_HOLIDAY_PAY_WEEKLY_REQUEST_MAKE_FAILED' as const
