// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import React from 'react'
import PropTypes from 'prop-types'

import { TrackingProvider } from '@indeed/flex-tracking-context'

import EmployerVenueDetail from 'syft-acp-core/entities/EmployerDetail/EmployerVenueDetail'
import SubNavigation from 'syft-acp-core/components/Navigation/SubNavigation'

const EmployerVenueView = ({ match: { params } }) => (
  <TrackingProvider
    value={{
      context: {
        employer_id: Number(params.id),
        venue_id: Number(params.venueID),
      },
    }}
  >
    <SubNavigation subPage="employers" data={{ employerID: Number(params.id) }} />
    <EmployerVenueDetail id={Number(params.venueID)} employerID={Number(params.id)} />
  </TrackingProvider>
)

EmployerVenueView.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      // Note: ID gets passed as a string directly from the URL.
      id: PropTypes.string.isRequired,
      venueID: PropTypes.string.isRequired,
    }).isRequired,
  }),
}

export default EmployerVenueView
