import PropTypes from 'prop-types'
import placeholderFactory from './factory'

/**
 * Element used to generate buttons for <Acp.Table /> components.
 *
 *    <Acp.ButtonDropdown kind="danger" requiresSelection>
 *      <Acp.Option>Mark as not contacted</Acp.Option>
 *      <Acp.Option divider />
 *      <Acp.Option header>Mark worker</Acp.Option>
 *      <Acp.Option color="yellow">Mark as clocked in</Acp.Option>
 *      <Acp.Option color="gray">Mark as no answer</Acp.Option>
 *      <Acp.Option color="red">Mark as late/missing</Acp.Option>
 *      <Acp.Option color="green">Mark as confirmed</Acp.Option>
 *    </Acp.Button>
 *
 * Has the following properties:
 *
 *    * kind: must be one of 'primary', 'regular', 'neutral', 'success', 'warning', 'danger', 'highlighted'.
 *    * onClick: a function that runs when clicking the button, taking an object containing rowData and tableData among other things.
 *    * requiresSelection: only activates when rows are selected.
 *    * requiresSubSelection: only activates when subrows are selected.
 *    * to: string to send the user to a different page.
 *
 * See also AcpButton.
 */
export default placeholderFactory(
  'AcpButtonDropdown',
  {
    kind: PropTypes.oneOf(['primary', 'regular', 'neutral', 'success', 'warning', 'danger', 'highlighted']),
    onClick: PropTypes.func,
    requiresSelection: PropTypes.bool,
    requiresSubSelection: PropTypes.bool,
    to: PropTypes.string,
  },
  null,
  null,
  ['AcpOption']
)
