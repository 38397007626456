// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import React from 'react'

import PageTitle from 'syft-acp-core/components/PageTitle'
import ConvertedEmployerList from 'syft-acp-core/entities/EmployerList/ConvertedEmployerList'

const Conversion = () => (
  <PageTitle title={['BizDev', 'Conversion']}>
    <div className="page">
      <ConvertedEmployerList />
    </div>
  </PageTitle>
)
Conversion.propTypes = {}

export default Conversion
