// Syft ACP - UI Library <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import './FilterContainer.css'

class FilterContainer extends React.PureComponent {
  static propTypes = {
    isSmall: PropTypes.bool,
    isTiny: PropTypes.bool,
    children: PropTypes.node.isRequired,
  }

  static defaultProps = {
    isSmall: false,
    isTiny: false,
  }

  render() {
    const { isSmall, isTiny, children } = this.props
    return (
      <span className={classnames('acp-filter-container', { 'is-small': isSmall, 'is-tiny': isTiny })}>{children}</span>
    )
  }
}

export default FilterContainer
