// Syft ACP - UI Library <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import * as React from 'react'
import classnames from 'classnames'

import { ButtonGroup } from 'syft-acp-atoms/Button'

import { FilterGroupProps as Props } from './FilterGroup.types'
import './FilterGroup.scss'

class FilterGroup extends React.PureComponent<Props> {
  static defaultProps = {
    title: null,
    maxWidth: false,
    compact: false,
  }

  render() {
    const { title, children, compact, maxWidth, isBreak } = this.props
    if (isBreak) return <br />
    return (
      <span className={classnames('acp-filter-group', maxWidth && 'acp-filter-group--max-width')}>
        <label className={classnames('acp-filter-group__label', compact && 'compact')}>
          <div className={classnames('acp-filter-group__label__text', compact && 'compact')}>{title}</div>
          <ButtonGroup className="acp-filter-group__button-group">{children}</ButtonGroup>
        </label>
      </span>
    )
  }
}

export default FilterGroup
