// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import React from 'react'

import PageView from 'syft-acp-core/components/PageView'
import AgenciesList from 'syft-acp-core/entities2/AgenciesList'

export const Agencies = () => (
  <PageView title="Agencies list" entityPage>
    <AgenciesList />
  </PageView>
)
