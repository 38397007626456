import React from 'react'
import PropTypes from 'prop-types'
import UserAvatarFromID from 'syft-acp-core/components/UserAvatar/UserAvatarFromID'
import EntityListInputWrapper from 'syft-acp-core/components/EntityList/EntityListInputWrapper'
import FilterAutocompleteWorkers from 'syft-acp-util/components/FilterForm/FilterAutocompleteWorkers'

const FilterAutocompleteWorkersAvatar = ({ id, name, callback, disabled = false }) => (
  <EntityListInputWrapper className="AutocompleteWorkers">
    <UserAvatarFromID id={id} name={name} size="xsmall" shape="rounded-square" />
    <FilterAutocompleteWorkers
      className="FilterInput"
      callback={callback}
      forceValue={name}
      placeholder={name}
      disabled={disabled}
    />
  </EntityListInputWrapper>
)

FilterAutocompleteWorkersAvatar.propTypes = {
  callback: PropTypes.func,
  id: PropTypes.number,
  name: PropTypes.string,
  disabled: PropTypes.bool,
}

FilterAutocompleteWorkersAvatar.defaultProps = {
  disabled: false,
  name: null,
  id: null,
  callback: null,
}

export default FilterAutocompleteWorkersAvatar
