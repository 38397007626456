// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import { listEmployerVenueNames } from 'syft-acp-core/store/employer-venues/api'

import { takeLatestApiCall } from './helpers'

export default function* root() {
  yield takeLatestApiCall('EMPLOYER_VENUE_NAMES_FETCH', listEmployerVenueNames)
}
