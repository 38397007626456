import React, { useEffect } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import Acp from 'syft-acp-uikit'
import { history } from 'syft-acp-core/history'
import { useTrackingTrigger, TrackingTrigger } from '@indeed/flex-tracking-context'

import { Button, ButtonGroup, ButtonLink } from 'syft-acp-atoms/Button'
import { fetchAgencyAccounts } from 'syft-acp-core/actions/agency-accounts'
import { entityList$, pagination$ } from 'syft-acp-core/reducers/generators/entities'
import { loginAsAgency } from 'syft-acp-core/store/agencyLogin/actions'

import { trackingEvents } from './AgencyAccountsList.tracking'

export const handleOpenUrl = userId => res =>
  !res?.errorObject && res?.payload?.url && window.open(`${res?.payload?.url}&impersonatorId=${userId}`)

const AgencyAccountsList = ({ agencyId, data, meta, isLoading, actions, userId, query }) => {
  const trackingTrigger = useTrackingTrigger()

  const backAction = () => {
    history.push('/agencies/list')
  }

  const handleNewMember = () => {
    trackingTrigger(trackingEvents.NEW_MEMBER.CLICKED)
  }

  const handleFilterChange = (name, value) => {
    if (name && value) {
      trackingTrigger(trackingEvents.FILTER.CHANGED, {
        filter: name,
      })
    }
  }

  useEffect(() => {
    actions.fetchAgencyAccounts({ agencyId, ...query })
  }, [actions, agencyId, query])

  return (
    <>
      <Acp.EntityList
        inContainer
        hasResultCount
        hasPagination
        idKeyValue="id"
        data={{ data, meta }}
        isLoading={isLoading}
      >
        <Acp.Actions>
          <Acp.ControlGroup>
            <ButtonGroup>
              <Button onClick={backAction}>Back to list</Button>
            </ButtonGroup>
            <ButtonGroup>
              <ButtonLink
                kind="success"
                to={`/agencies/view/${agencyId}/accounts/new`}
                onClick={handleNewMember}
              >
                Invite new member
              </ButtonLink>
            </ButtonGroup>
          </Acp.ControlGroup>
          <Acp.FilterGroup title="Branch:">
            <Acp.Filter.Text name="branch_name" placeholder="Branch" onChange={handleFilterChange} />
          </Acp.FilterGroup>
          <Acp.FilterGroup title="Name/ID:">
            <Acp.Filter.Text name="query" placeholder="Name/ID" onChange={handleFilterChange} />
          </Acp.FilterGroup>
          <Acp.FilterGroup title="Email:">
            <Acp.Filter.Text name="email" placeholder="Email" onChange={handleFilterChange} />
          </Acp.FilterGroup>
          <Acp.FilterGroup title="Telephone number:">
            <Acp.Filter.Text
              name="telephone_number"
              placeholder="Telephone number"
              onChange={handleFilterChange}
            />
          </Acp.FilterGroup>
        </Acp.Actions>
        <Acp.Table rowLink={row => `/agencies/view/${agencyId}/accounts/view/${row.id}`}>
          <Acp.Col.Text value="id" header="ID" isMinimal isNumeric />
          <Acp.Col.Text value="branch_name" header="Branch" isMinimal />
          <Acp.Col.Text value="name" header="Name" isMinimal />
          <Acp.Col.Text value="email" header="Email" isMinimal />
          <Acp.Col.Text value="telephone_number" header="Tel. nr" isMinimal />
          <Acp.Col.Text value="level" header="Level" isMinimal />
          <Acp.Col.Timestamp value="created_at" header="Created at" isMinimal />
          <Acp.Col.Timestamp
            value={row => {
              return row?.invitation_status?.status === 'accepted' ? row?.invitation_status?.date : ''
            }}
            header="Invite accepted at"
            isMinimal
          />
          <Acp.Col.Button isMinimal header="Actions">
            <Acp.Button
              onClick={({ rowData }) => {
                trackingTrigger(trackingEvents.LOGIN.CLICKED, { account_id: rowData.id })
                new Promise(resolve => actions.loginAsAgency(rowData.id, resolve)).then(handleOpenUrl(userId))
              }}
            >
              Log in as user
            </Acp.Button>
          </Acp.Col.Button>
        </Acp.Table>
      </Acp.EntityList>
      <TrackingTrigger onLoad={{ event: trackingEvents.PAGE.VIEWED }} />
    </>
  )
}

export default connect(
  (state, props) => {
    const query = state.routing.locationBeforeTransitions.query
    return {
      data: entityList$(state?.agencyAccounts, { agencyId: props.agencyId, ...query }),
      meta: pagination$(state?.agencyAccounts, { agencyId: props.agencyId, ...query }),
      userId: state?.auth?.userData?.id,
      isLoading: state?.agencyAccounts?.isLoadingData,
      query,
    }
  },
  dispatch => ({
    actions: bindActionCreators({ fetchAgencyAccounts, loginAsAgency }, dispatch),
  }),
)(AgencyAccountsList)
