import * as actionTypes from './action-types'
import * as types from './types'

export const fetchWorkerHolidayPayHistoricRequests = ({
  options: { workerID },
}: {
  options: types.FetchWorkerHolidayPayInput
}) => ({
  type: actionTypes.WORKER_HOLIDAY_PAY_HISTORIC_REQUESTS_FETCH_BEGIN,
  workerID,
})
export type FetchWorkerHolidayPayHistoricRequestsBeginAction = ReturnType<typeof fetchWorkerHolidayPayHistoricRequests>

export const fetchWorkerHolidayPayHistoricRequestsSucceeded = (
  payload: types.FetchWorkerHolidayPayHistoricRequestsSucceededPayload,
  request: FetchWorkerHolidayPayHistoricRequestsBeginAction
) => ({
  type: actionTypes.WORKER_HOLIDAY_PAY_HISTORIC_REQUESTS_FETCH_SUCCEEDED,
  payload,
  request,
})
export type FetchWorkerHolidayPayHistoricRequestsSucceededAction = ReturnType<
  typeof fetchWorkerHolidayPayHistoricRequestsSucceeded
>

export const makeWorkerHolidayPayHistoricRequest = ({
  options: { workerID, amount, startDate },
}: {
  options: types.MakeWorkerHolidayPayHistoricRequestInput
}) => ({
  type: actionTypes.WORKER_HOLIDAY_PAY_HISTORIC_REQUEST_MAKE_BEGIN,
  workerID,
  amount,
  startDate,
})
export type MakeWorkerHolidayPayHistoricRequestBeginAction = ReturnType<typeof makeWorkerHolidayPayHistoricRequest>

export const makeWorkerHolidayPayHistoricRequestSucceeded = (
  payload: types.WorkerHolidayPayHistoricRequestsEntity,
  request: MakeWorkerHolidayPayHistoricRequestBeginAction
) => ({
  type: actionTypes.WORKER_HOLIDAY_PAY_HISTORIC_REQUEST_MAKE_SUCCEEDED,
  payload,
  request,
})
export type MakeWorkerHolidayPayHistoricRequestSucceededAction = ReturnType<
  typeof makeWorkerHolidayPayHistoricRequestSucceeded
>

export const deleteWorkerHolidayPayHistoricRequest = (id: number, workerId: number) => ({
  type: actionTypes.WORKER_HOLIDAY_PAY_HISTORIC_REQUEST_DELETE_BEGIN,
  payload: { id },
  meta: { workerId },
})

export const validateWorkerHolidayPayHistoricRequest = ({
  options: { workerID, amount },
}: {
  options: types.ValidateWorkerHolidayPayHistoricRequestInput
}) => ({
  type: actionTypes.WORKER_HOLIDAY_PAY_HISTORIC_REQUEST_VALIDATE_BEGIN,
  workerID,
  amount,
})
export type ValidateWorkerHolidayPayHistoricRequestBeginAction = ReturnType<
  typeof validateWorkerHolidayPayHistoricRequest
>

export const validateWorkerHolidayPayHistoricRequestSucceeded = (
  payload: types.ValidateWorkerHolidayPayHistoricRequestSucceededPayload,
  request: MakeWorkerHolidayPayHistoricRequestBeginAction
) => ({
  type: actionTypes.WORKER_HOLIDAY_PAY_HISTORIC_REQUEST_VALIDATE_SUCCEEDED,
  payload,
  request,
})
export type ValidateWorkerHolidayPayHistoricRequestSucceededAction = ReturnType<
  typeof validateWorkerHolidayPayHistoricRequestSucceeded
>

export const resetValidation = (workerID: number) => ({
  type: actionTypes.WORKER_HOLIDAY_PAY_HISTORIC_REQUEST_VALIDATE_RESET,
  workerID,
})
export type ResetValidationAction = ReturnType<typeof resetValidation>

export const fetchWorkerHolidayPayWeeklyRequests = ({
  options: { workerID },
}: {
  options: types.FetchWorkerHolidayPayInput
}) => ({
  type: actionTypes.WORKER_HOLIDAY_PAY_WEEKLY_REQUESTS_FETCH_BEGIN,
  workerID,
})
export type FetchWorkerHolidayPayWeeklyRequestsBeginAction = ReturnType<typeof fetchWorkerHolidayPayWeeklyRequests>

export const fetchWorkerHolidayPayWeeklyRequestsSucceeded = (
  payload: types.FetchWorkerHolidayPayWeeklyRequestsSucceededPayload,
  request: FetchWorkerHolidayPayWeeklyRequestsBeginAction
) => ({
  type: actionTypes.WORKER_HOLIDAY_PAY_WEEKLY_REQUESTS_FETCH_SUCCEEDED,
  payload,
  request,
})
export type FetchWorkerHolidayPayWeeklyRequestsSucceededAction = ReturnType<
  typeof fetchWorkerHolidayPayWeeklyRequestsSucceeded
>

export const makeWorkerHolidayPayWeeklyRequest = ({
  options: { workerID },
}: {
  options: types.FetchWorkerHolidayPayInput
}) => ({
  type: actionTypes.WORKER_HOLIDAY_PAY_WEEKLY_REQUEST_MAKE_BEGIN,
  workerID,
})
export type MakeWorkerHolidayPayWeeklyRequestBeginAction = ReturnType<typeof makeWorkerHolidayPayWeeklyRequest>

export const makeWorkerHolidayPayWeeklyRequestSucceeded = (
  payload: types.WorkerHolidayPayWeeklyRequestsEntity,
  request: MakeWorkerHolidayPayWeeklyRequestBeginAction
) => ({
  type: actionTypes.WORKER_HOLIDAY_PAY_WEEKLY_REQUEST_MAKE_SUCCEEDED,
  payload,
  request,
})
export type MakeWorkerHolidayPayWeeklyRequestSucceededAction = ReturnType<
  typeof makeWorkerHolidayPayWeeklyRequestSucceeded
>

export const fetchWorkerHolidayPayAccruals = (workerID: number) => ({
  type: actionTypes.WORKER_HOLIDAY_PAY_ACCRUALS_FETCH_BEGIN,
  workerID,
})
export type FetchWorkerHolidayPayAccrualsBeginAction = ReturnType<typeof fetchWorkerHolidayPayAccruals>

export const fetchWorkerHolidayPayAccrualsSucceeded = (
  payload: types.FetchWorkerHolidayPayAccrualsSucceededPayload,
  request: FetchWorkerHolidayPayAccrualsBeginAction
) => ({
  type: actionTypes.WORKER_HOLIDAY_PAY_ACCRUALS_FETCH_SUCCEEDED,
  payload,
  request,
})
export type FetchWorkerHolidayPayAccrualsSucceededAction = ReturnType<typeof fetchWorkerHolidayPayAccrualsSucceeded>
