import { put, select, takeLatest } from 'redux-saga/effects'
import { getWorker$ } from 'syft-acp-core/store/workers/selectors'
import * as api from 'syft-acp-core/api/resources/admin/workers'
import * as types from 'syft-acp-core/actions/action-types'

import { takeLatestApiCall } from './helpers'
import { notificationCall } from './calls'

export function* updateWorker({ payload, request }) {
  if (payload?.worker_transfer?.worker_id) {
    const worker = yield select(state => getWorker$(state?.workers)(payload?.worker_transfer?.worker_id))
    const workerPayload = { ...worker, ...payload }

    yield put({ type: types.WORKER_MODIFY_SUCCEEDED, payload: workerPayload })
  } else if (request?.type === types.WORKER_TRANSFER_DELETE_BEGIN) {
    const { worker_transfer, ...worker } = yield select(state => getWorker$(state?.workers)(request.payload?.worker_id))
    yield put({ type: types.WORKER_MODIFY_SUCCEEDED, payload: worker })
  }
}

export default function* root() {
  yield takeLatestApiCall('UPLOAD_TRANSFERRED_WORKER_CSV', api.uploadTransferredWorkerCsv)
  yield takeLatestApiCall('WORKER_TRANSFER_RESEND_INVITATION', api.resendWorkerInvitation)
  yield takeLatestApiCall('WORKER_TRANSFER_FETCH', api.fetchWorkerTransfer)
  yield takeLatestApiCall('WORKER_TRANSFER_UPDATE', api.updateWorkerTransfer)
  yield takeLatestApiCall('WORKER_TRANSFER_CREATE', api.createWorkerTransfer)
  yield takeLatestApiCall('WORKER_TRANSFER_DELETE', api.deleteWorkerTransfer)

  yield takeLatest([types.WORKER_TRANSFER_CREATE_SUCCEEDED, types.WORKER_TRANSFER_DELETE_SUCCEEDED], updateWorker)

  yield takeLatest(
    [
      types.WORKER_TRANSFER_CREATE_SUCCEEDED,
      types.WORKER_TRANSFER_DELETE_SUCCEEDED,
      types.WORKER_TRANSFER_UPDATE_SUCCEEDED,
      types.WORKER_TRANSFER_CREATE_FAILED,
      types.WORKER_TRANSFER_DELETE_FAILED,
      types.WORKER_TRANSFER_UPDATE_FAILED,
      types.WORKER_TRANSFER_FETCH_FAILED,
      types.UPLOAD_TRANSFERRED_WORKER_CSV_SUCCEEDED,
      types.UPLOAD_TRANSFERRED_WORKER_CSV_FAILED,
      types.WORKER_TRANSFER_RESEND_INVITATION_SUCCEEDED,
      types.WORKER_TRANSFER_RESEND_INVITATION_FAILED,
    ],
    notificationCall
  )
}
