// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import classnames from 'classnames'
import { get } from 'lodash-es'

import { needsIndustriesUpdate$ } from 'syft-acp-core/reducers/industries'
import { fetchIndustries } from 'syft-acp-core/actions/industries'
import './DataIndustries.css'

class DataIndustries extends PureComponent {
  static propTypes = {
    ids: PropTypes.arrayOf(PropTypes.number),
    isLoadingData: PropTypes.bool.isRequired,
    industries: PropTypes.object.isRequired,
    dispatch: PropTypes.func.isRequired,
  }

  static defaultProps = {
    ids: [],
  }

  componentDidMount() {
    if (!this.props.isLoadingData && needsIndustriesUpdate$()) {
      this.props.dispatch(fetchIndustries())
    }
  }

  render() {
    const { ids, industries } = this.props

    if (ids.length === 0) {
      return <div>–</div>
    }

    return (
      <div className="DataIndustries plain-text">
        {[...ids].sort().map(id => {
          const industryName = get(industries, `${id}.title`, '–')
          const industryCode = get(industries, `${id}.code`, 'unknown')
          const cls = classnames('industry', `type-${industryCode}`)
          return (
            <span className={cls} key={id} data-tip={industryName}>
              {industryName[0]}
            </span>
          )
        })}
      </div>
    )
  }
}

export default connect(({ industries }) => ({
  industries: industries.entityMap,
  isLoadingData: industries.isLoadingData,
}))(DataIndustries)
