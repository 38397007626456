// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import { apiRequest, handleCall } from 'syft-acp-core/api/call'

export const fetchStatsWorkers = ({ payload: reqArgs }) =>
  new Promise((resolve, reject) => {
    const req = apiRequest({ path: '/stats/workers', reqArgs })
    handleCall(req, resolve, reject, true)
  })

/**
 * @param params {Object} Function parameters as object
 * @param params.id {String} any pre-generated UUID string event.
 */
export const fetchStatsEmployer = ({ id }) =>
  new Promise((resolve, reject) => {
    const req = apiRequest({ path: '/stats/employers', reqArgs: { employer_id: id } })
    handleCall(req, resolve, reject)
  })
