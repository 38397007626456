import { apiRequest, handleCall } from 'syft-acp-core/api/call'

/**
 * Retrieves a list of leave requests. Implements pagination.
 *
 * @param params {Object}
 * @param params.options {Object} Other query options
 */
export const fetchLeaveRequests = ({ options = {} }) =>
  new Promise((resolve, reject) => {
    const req = apiRequest({ path: '/admin/leave_requests', reqArgs: options })
    handleCall(req, resolve, reject, true, options)
  })

export const changeProcessedStatusLeaveRequests = ({ ids, status }) => {
  const key = status ? 'processed' : 'unprocessed'
  const parameters = {
    [key]: ids,
  }

  return new Promise((resolve, reject) => {
    const req = apiRequest({ path: '/admin/leave_requests', reqArgs: parameters, method: 'PATCH' })
    handleCall(req, resolve, reject, true)
  })
}
