import React from 'react'
import { TrackingProvider } from '@indeed/flex-tracking-context'

type Props = {
  agencyId: number
  accountId?: number
  children: React.ReactNode
}

export const AgenciesTrackingProvider = ({ agencyId, accountId, children }: Props) => (
  <TrackingProvider value={{ context: { agency_id: agencyId, account_id: accountId } }}>{children}</TrackingProvider>
)
