export const trackingEvents = {
  PAGE: {
    VIEWED: 'agency_accounts.page.viewed',
  },
  NEW_MEMBER: {
    CLICKED: 'agency_accounts.new_member_button.clicked',
  },
  FILTER: {
    CHANGED: 'agency_accounts.filter.changed',
  },
  LOGIN: {
    CLICKED: 'agency_accounts.login_as_user_button.clicked',
  },
}
