/* eslint-disable react/no-string-refs */
import { keyBy, orderBy } from 'lodash-es'
import PropTypes from 'prop-types'
import React, { PureComponent } from 'react'
import { Typeahead } from 'react-bootstrap-typeahead'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { fetchIndustries } from 'syft-acp-core/actions/industries'
import { CLIENT, LEGAL } from 'syft-acp-core/constants'
import { listSkills } from 'syft-acp-core/store/skills/actions'
import DataArrayItem from './DataArrayItem'

import { useFlexFlagIsOn } from '@indeed/flex-feature-flags'
import { ChipGroup } from '@indeed/ifl-components'

const getPlaceholderSkill = skillId => ({
  id: skillId,
  title: `Skill #${skillId}`,
  special: false,
})

export const highlightDisabledSkills = (skills, disabledSkills) =>
  skills.map(skill => ({ ...skill, disabled: disabledSkills.includes(skill.id) }))

export const getSkillsData = ({ skills, essentialSkills, disabledSkills, state }) => {
  let skillData
  if (skills && skills[0] !== null && typeof skills[0] !== 'object') {
    skillData = skills.map(skill => state?.skills?.entityMap?.[skill] || getPlaceholderSkill(skill))
  } else {
    skillData = orderBy(skills, ['id'])
  }

  if (essentialSkills?.length) {
    skillData = skillData.map(skill =>
      essentialSkills.find(essentialSkill => skill?.id === essentialSkill?.id)
        ? { ...skill, essential: true }
        : skill,
    )
  }
  if (disabledSkills.length > 0) {
    // Filter undefined skillData, in case missing disabled skills from roles endpoint
    skillData = highlightDisabledSkills(skillData.filter(Boolean), disabledSkills)
  }

  return skillData
}

class AutocompleteSkills extends PureComponent {
  static propTypes = {
    onChange: PropTypes.func.isRequired,
    value: PropTypes.string,
    options: PropTypes.array.isRequired,
    disabled: PropTypes.bool,
  }

  changeAndClear = item => {
    if (item.length) {
      setTimeout(() => this.refs.typeahead.getInstance().clear(), 0)
      this.props.onChange(item)
    }
  }

  render() {
    const { value, options, disabled } = this.props
    return (
      <Typeahead
        selected={value}
        onChange={this.changeAndClear}
        minLength={1}
        ref="typeahead"
        selectHintOnEnter
        id="title"
        labelKey="title"
        options={options}
        placeholder="Choose a skill..."
        disabled={disabled}
      />
    )
  }
}

const getFilteredSkillsList = (onlyHardOrEssentialSkills, skillList) =>
  onlyHardOrEssentialSkills && skillList
    ? skillList?.filter(skill => skill?.special || skill?.essential)
    : skillList

class DataJobSkills extends PureComponent {
  static propTypes = {
    skillData: PropTypes.array.isRequired,
    skills: PropTypes.object.isRequired,
    allRoles: PropTypes.object.isRequired,
    actions: PropTypes.object.isRequired,
    onlyHardOrEssentialSkills: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
    // TODO: remove otherData. Needed temporarily to fix skills.
    // NOTE: (2023-12-18) See comment on line 173 on why the above todo is not necessarily relevant.
    otherData: PropTypes.object.isRequired,
    editable: PropTypes.bool,
    isReadOnlySkills: PropTypes.bool,
  }

  static defaultProps = {
    editable: true,
  }

  componentDidMount() {
    if (Object.keys(this.props.allRoles).length === 0) {
      this.props.actions.fetchIndustries()
    }
    if (Object.keys(this.props.skills).length === 0) {
      this.props.actions.listSkills()
    }
  }

  addSkill = newSkill => {
    const mergedSkills = [...this.props.skillData, ...newSkill]
    const skillIds = mergedSkills.map(skill => skill.id)
    this.props.onChange(skillIds)
  }

  removeSkill = skillId => {
    const newSkills = this.props.skillData.filter(skill => skill?.id !== skillId)
    const skillIds = newSkills.map(skill => skill.id)
    this.props.onChange(skillIds)
  }

  render() {
    const { skillData, allRoles, otherData, onlyHardOrEssentialSkills, editable, isReadOnlySkills } =
      this.props
    const roleID = otherData.role ? otherData.role.id : otherData.role_id
    const currRole = allRoles[roleID]
    const skillList = currRole ? orderBy(currRole.skills, ['title', 'id']) : []
    const filteredSkillList = getFilteredSkillsList(onlyHardOrEssentialSkills, skillList)

    return (
      <div>
        {!isReadOnlySkills && (
          <AutocompleteSkills onChange={this.addSkill} options={filteredSkillList} disabled={!editable} />
        )}
        <ChipGroup type={isReadOnlySkills ? 'single' : 'removable'} sx={{ mt: 2 }}>
          {skillData
            .filter(s => {
              if (isReadOnlySkills) {
                return onlyHardOrEssentialSkills ? !s?.soft : s
              }
              return onlyHardOrEssentialSkills ? s?.special || s?.essential : s
            })
            .map(({ special, essential, flexible, soft, title, id, disabled, category }, n) => {
              const isLegal = (category === CLIENT || category === LEGAL) && !(isReadOnlySkills && soft)
              const isHardSkill = !isLegal && (isReadOnlySkills ? !soft : special || essential)
              return (
                <DataArrayItem
                  key={`${id}.${n}`}
                  index={id}
                  onDelete={isReadOnlySkills ? null : this.removeSkill}
                  title={`${isHardSkill ? 'Hard' : 'Soft'} Skill`}
                  rawTitle={title}
                  sx={{
                    span: {
                      ...(isLegal ? { backgroundColor: 'alert.300' } : {}),
                      ...(isHardSkill ? { backgroundColor: 'info.400' } : {}),
                    },
                  }}
                >
                  {!isReadOnlySkills && essential && <span>(Essential) </span>}
                  {isReadOnlySkills && flexible && <span>(Flexible) </span>}
                  <span>{title}</span>
                  {disabled && <span> (Disabled)</span>}
                </DataArrayItem>
              )
            })}
        </ChipGroup>
      </div>
    )
  }
}

const DataJobSkillsConnected = connect(
  (state, ownProps) => {
    const essentialSkills = ownProps.otherData.essential_skills
    const disabledSkills = ownProps.otherData.disabledSkills ?? []
    // TODO: remove this hack.
    // Sometimes the API gives us [1, 2, 3] instead of [{ id: 1 }, { id: 2 }, { id: 3 }] plus extended information.
    // In that case we need to upgrade the data to our standard.
    //
    // NOTE: (2023-12-18) The comment above is not entirely correct. The API (backend) does always give us the
    // desired format: an array of objects with extended information. The case in which we get an array of
    // skill IDs happens only in the `ListingJob` component, the structure of which calls this component
    // explicitly with an array of skill IDs as a param.
    //
    // This happens here: src/core/entities/ListingDetail/ListingShiftTabs/ListingJob/structure.js:31
    const skillsMap = state?.skills?.entityMap
    const skillData = getSkillsData({
      disabledSkills,
      essentialSkills,
      skills: ownProps.skills,
      state,
    })

    // Retrieve all roles from the industries endpoint.
    const allRoles = keyBy(Object.values(state.roles.entityMap), 'id')
    return {
      skills: skillsMap,
      allRoles,
      skillData: skillData.map(skill => {
        const jobSkill = ownProps.otherData.skills?.find(({ id }) => skill?.id === id)
        return {
          ...skill,
          flexible: !!jobSkill?.flexible,
          soft: !!jobSkill?.soft,
        }
      }),
    }
  },
  dispatch => ({
    actions: bindActionCreators({ fetchIndustries, listSkills }, dispatch),
  }),
)(DataJobSkills)

const DataJobSkillsWrapped = props => {
  const isReadOnlySkills = useFlexFlagIsOn('job_posting_acp_read_only_skills')
  return <DataJobSkillsConnected {...props} isReadOnlySkills={isReadOnlySkills} />
}

export default DataJobSkillsWrapped
