export const trackingEvents = {
  AGENCY_SHIFT_HISTORY: {
    PAGE: {
      LOADED: 'agency_shift_history.page.loaded',
    },
    FILTERS: {
      CHANGED: 'agency_shift_history.filters.changed',
    },
  },
}
