import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { Button } from 'syft-acp-atoms/Button'
import RecordControls from 'syft-acp-core/components/RecordSection/RecordControls'
import { updateJob } from 'syft-acp-core/store/jobs/actions'
import LoadingIndicator from 'syft-acp-util/components/LoadingIndicator'
import { useFlexFlagIsOn } from '@indeed/flex-feature-flags'
import { withTrackingTriggerHoc } from 'syft-acp-util/withTrackingTriggerHoc'
import { showConfirmModal } from 'syft-acp-core/store/modals/actions'
import { isPast } from 'syft-acp-core/entities2/ShiftAgencyList/helpers'

import { trackingEvents } from '../../tracking'

const ListingJobDetailControls = ({
  dispatch,
  updatedData,
  data,
  listingID,
  shift,
  isPristine,
  isSavingData,
  isLoadingData,
  triggerEvent,
  setUpdatedData,
  stopOfferTypes,
}) => {
  const jobStopOffersEnabled = useFlexFlagIsOn('pte_12238_listings_stop_job_offer')
  const [stopJobOffers, setStopOffers] = useState()
  useEffect(() => {
    setStopOffers(stopOfferTypes?.length !== 1)
  }, [stopOfferTypes])
  const onSuccesCallback = () => {
    triggerEvent(trackingEvents.LISTING_INFO.JOB.SAVED, { jobId: data.id })
    setUpdatedData({})
  }

  const saveAction = () => {
    dispatch(updateJob(data.id, updatedData, onSuccesCallback))
  }
  const stopResumeOffers = () => {
    const stopOffersData = stopJobOffers
      ? { stop_offers_for_worker_type: ['syft'] }
      : { stop_offers_for_worker_type: [] }

    dispatch(
      showConfirmModal({
        question: stopJobOffers ? (
          <>
            <div>You are about to stop job offers for ALL upcoming shifts within this job.</div>
            <div>Are you sure you wish to proceed?</div>
          </>
        ) : (
          <>
            <div>You are about to resume job offers for ALL upcoming shifts within this job.</div>
            <div>Are you sure you wish to proceed?</div>
          </>
        ),
        modalConfirm: 'Yes',
        onConfirm: () => {
          dispatch(updateJob(data.id, stopOffersData, null, listingID, shift))
          triggerEvent(trackingEvents.LISTING_INFO.JOB_STOP_OFFERS.CLICKED, { value: stopOffersData })
          setStopOffers(!stopJobOffers)
        },
      }),
    )
  }
  const shifts = data?.shifts || []
  const lastShift = shifts ? shifts[shifts.length - 1] : true

  return (
    <RecordControls className="overlayed">
      <div className="right">
        <LoadingIndicator loadingState={isSavingData || isLoadingData} />{' '}
        {jobStopOffersEnabled && !isPast(lastShift?.start_time) && (
          <Button kind="primary" onClick={stopResumeOffers}>
            {stopJobOffers ? 'Stop job offers' : 'Resume job offers'}
          </Button>
        )}
        <Button kind="success" onClick={saveAction} disabled={isSavingData || isLoadingData || isPristine}>
          Save
        </Button>
      </div>
    </RecordControls>
  )
}

ListingJobDetailControls.propTypes = {
  isLoadingData: PropTypes.bool,
  updatedData: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  listingID: PropTypes.number.isRequired,
  shift: PropTypes.object,
  triggerEvent: PropTypes.func.isRequired,
  isPristine: PropTypes.bool.isRequired,
  isSavingData: PropTypes.bool,
  setUpdatedData: PropTypes.func.isRequired,
}

export default connect(({ jobs: { isSavingData, isLoadingData, entityMap } }, props) => ({
  stopOfferTypes: entityMap[props.data.id]?.stop_offers_for_worker_type,
  isSavingData,
  isLoadingData,
}))(withTrackingTriggerHoc(ListingJobDetailControls))
