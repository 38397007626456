import PropTypes from 'prop-types'

import placeholderFactory from './factory'

export default placeholderFactory(
  'AcpColGroup',
  {
    children: PropTypes.node.isRequired,
  },
  null,
  null,
  ['AcpCol', 'AcpEditableCol']
)
