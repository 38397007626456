import PropTypes from 'prop-types'

import placeholderFactory from './factory'

/**
 * Link button placeholder used for links that look like buttons.
 */
export default placeholderFactory('AcpButtonLink', {
  kind: PropTypes.oneOf(['primary', 'regular', 'neutral', 'success', 'warning', 'danger', 'highlighted']),
})
