/* eslint-disable react/require-default-props */
// Syft ACP - Lib <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import { get, isEmpty, isEqual, isFunction, map, noop } from 'lodash-es'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { injectIntl } from 'react-intl'
import { compose } from 'redux'

import { filterCountrySpecificStructs } from 'syft-acp-core/components/CountrySpecific'
import EntityList, {
  EntityListPagination,
  EntityListResults,
  paginationProps,
} from 'syft-acp-core/components/EntityList'
import QueryTable from 'syft-acp-core/components/QueryTable'

import FilterForm from '../components/FilterForm'
import { arePropertiesEqual } from 'syft-acp-core/store/filters/helpers'
import { removePagination } from '../entityUtils'

import { createEntityListConnector } from './entityListConnector'

// Default actions.
export const ACTION_DELETE = Symbol('ACTION_DELETE')
export const ACTION_DOWNLOAD_CSV = Symbol('ACTION_DOWNLOAD_CSV')

/**
 * The default wrapper for entity lists.
 */
export const DefaultWrapper = ({ filterForm, entityListResults, entityList, entityListPagination }) => (
  <div data-testid="default-entitylist-wrapper">
    {filterForm}
    {entityListResults}
    {entityList}
    {entityListPagination}
  </div>
)

export const defaultWrapperPropTypes = {
  filterForm: PropTypes.node,
  entityList: PropTypes.node.isRequired,
  entityListResults: PropTypes.node,
  entityListPagination: PropTypes.node,
}

DefaultWrapper.propTypes = defaultWrapperPropTypes

DefaultWrapper.defaultProps = {
  entityListResults: null,
  entityListPagination: null,
  filterForm: null,
}

/**
 *
 * Higher order component for creating entity lists and their associated filter forms.
 * This component generates a fully functional entity list almost entirely by a declarative
 * interface.
 *
 * Table format specification:
 *
 * TODO
 *
 * ----
 *
 * The filter format is an an array of objects that describe how the data can be filtered.
 * These filters usually have a single form field and filter a single property;
 * for example, a text field that filters the names of the entities. They can, however,
 * contain multiple form fields and each field can filter multiple properties.
 *
 * A simple example:
 *
 *    {
 *      title: 'Filter by event name',
 *      placeholder: 'Event name',
 *      filter: 'event_name',
 *      attributes: { small: true },
 *      type: filterTypes.TYPE_TEXT
 *    }
 *
 * This filters entities by event name. It includes a title (shown above the text field)
 * and a placeholder (shown in the text field if nothing is typed in). The "filter" value
 * contains "event_name", which is the value we'll use when filtering the data.
 *
 * The "attributes" object is passed along to the filter component as extra information.
 * "Type" contains one of the valid type symbols that can be imported from FilterForm.
 *
 * Here's an example of a filter that has multiple fields:
 *
 *    {
 *      title: 'Filter by status',
 *      fields: [
 *        {
 *          values: [
 *            [filterTypes.ENUM_ANY],
 *            ['live', 'not_booked', 'booked', 'needs_approval', 'completed']
 *          ],
 *          labels: {
 *            [filterTypes.ENUM_ANY]: 'All',
 *            'live': 'Live',
 *            'not_booked': 'Not booked',
 *            'booked': 'Booked',
 *            'needs_approval': 'Needs approval',
 *            'completed': 'Completed'
 *          },
 *          filter: 'status',
 *          type: filterTypes.TYPE_ENUM
 *        }
 *      ]
 *    }
 *
 * There can be as many or as few fields; having an array of one is the same as
 * putting the contents of the field object in the main object.
 *
 * An item (either a single one, or multiple in a "fields" array) can have either one
 * filter, or it can have multiple filters:
 *
 *    {
 *      title: 'Other filters',
 *      type: filterTypes.TYPE_CHECKBOXES,
 *      filters: ['a', 'b', 'c'],
 *      labels: {
 *        a: 'Test a',
 *        b: 'Test b',
 *        c: 'Test c'
 *      }
 *    }
 *
 * Note that every type of filter (CHECKBOXES, ENUM, TEXT, etc) has a default value.
 * This default value can be set with a "default" field, or if that field is omitted we'll
 * use a standard default value. For example, every TEXT component has an empty string
 * as default value.
 *
 * If there are multiple filters, and we want to provide a default value, we need to
 * give exactly as many defaults as there are filters. So the above CHECKBOXES example
 * would need three defaults if any defaults are desired at all. If there is a discrepancy,
 * the filter format will not pass validation.
 *
 * A filter format also cannot have multiple items with the same "filter" value.
 * Each unique "filter" value may appear only once.
 *
 * ----
 *
 * Action format specification:
 *
 * TODO
 *
 * ----
 *
 * Options specification:
 *
 * The following options can be passed:
 *    - fullWidth {Boolean}        if true, the list takes up the full page width
 * @deprecated Use Acp.EntityList with Acp.Table
 */
const entityListHOC = (
  WrappedComponent,
  tableFormat,
  filters,
  actionFormat,
  entityStore,
  entityActions,
  options = {},
) => {
  class EntityListComponent extends Component {
    static propTypes = {
      intl: PropTypes.object.isRequired,
      actions: PropTypes.shape({
        showConfirmModal: PropTypes.func,
        fetchEntities: PropTypes.func,
        deleteEntities: PropTypes.func,
        onNextPage: PropTypes.func,
      }).isRequired,
      disableRoutingQuery: PropTypes.bool,
      entityList: PropTypes.arrayOf(PropTypes.object).isRequired,
      hasLinks: PropTypes.bool,
      isLoadingData: PropTypes.bool.isRequired,
      isSavingData: PropTypes.bool.isRequired,
      lastMessage: PropTypes.string.isRequired,
      pagination: paginationProps,
      query: PropTypes.object,
      queryString: PropTypes.string,
      pageKey: PropTypes.string,
      editCallback: PropTypes.func,
      selectCallback: PropTypes.func,
      selectedItems: PropTypes.array,
      selectedRow: PropTypes.shape({ key: PropTypes.string, value: PropTypes.any }),
      selectFullRow: PropTypes.bool,
      selectSingleRow: PropTypes.bool,
      hasFooter: PropTypes.bool,
      noFilterContainer: PropTypes.bool,
      urlBase: PropTypes.string,
      urlGenerator: PropTypes.func,
      onNextPage: PropTypes.func,
      actionFormat: PropTypes.array,
    }

    static defaultProps = {
      disableRoutingQuery: false,
      pagination: {},
      editCallback: noop,
      urlBase: null,
      onNextPage: null,
      selectedItems: [],
      query: {},
    }

    state = {
      actionFormat: [],
    }

    constructor(props) {
      super(props)
      // Bind standard callback functions to the action format.
      this.state = {
        actionFormat: this.decorateActions(this.filterCountrySpecificFormats(actionFormat || [])),
      }
      this.tableFormat = this.filterCountrySpecificFormats(tableFormat || [])
    }

    componentDidUpdate(prevProps) {
      if (!isEqual(prevProps.actionFormat, this.props.actionFormat)) {
        this.setState({
          actionFormat: this.decorateActions(
            this.filterCountrySpecificFormats(this.props.actionFormat || []),
          ),
        })
      }
    }

    // Retrieves data at least once when mounting.
    componentDidMount() {
      // Fetch initial data, unless noInitialFetch is true.
      if (!options.noInitialFetch || Object.keys(this.props.query).length > 0) {
        this.fetchData(this.props.query, this.props)
      }

      if (this.props.actionFormat) {
        this.setState({
          actionFormat: this.decorateActions(
            this.filterCountrySpecificFormats(this.props.actionFormat || []),
          ),
        })
      }
    }

    shouldComponentUpdate(newProps) {
      // Check for the following props to see if they have changed.
      // Other props should either never change, or will always be accompanied by a change
      // by any of the props listed here; therefore we don't need to check them.
      const isIdentical = arePropertiesEqual(this.props, newProps, [
        'queryString',
        'lastMessage',
        'isSavingData',
        'isLoadingData',
      ])
      if (!isIdentical) {
        return true
      }

      // Check if any of the IDs in entityList are different. Rerender if so.
      // TODO: check if this is appropriate.
      const oldIDs = map(this.props.entityList, 'id').sort()
      const newIDs = map(newProps.entityList, 'id').sort()
      const oldFirst = this.props.entityList != null && this.props.entityList[0]
      const newFirst = newProps.entityList != null && newProps.entityList[0]
      if (oldIDs.length === 0 && newIDs.length === 0) {
        return false
      }
      if (oldFirst == null && newFirst == null) {
        return false
      }
      if ((oldFirst == null) !== (newFirst == null)) {
        return true
      }
      if (!isEqual(oldIDs, newIDs)) {
        return true
      }

      // Check for data differences if this is a recordList (since they are editable).
      let isIdenticalRL = true
      if (options.recordList) {
        if (options.idFunction) {
          isIdenticalRL = options.idFunction(this.props.entityList, newProps.entityList)
        }
        return !isIdenticalRL
      }
      // If we're still here, nothing seems to be different.
      return false
    }

    actionDownloadCSV = (data, refetchData, resultData, confirmCallback) => {
      const totalItems = get(resultData, 'pagination.total', null)
      const queryParameters = removePagination(resultData.query)
      const hasFilters = !isEmpty(queryParameters)
      this.props.actions.showConfirmModal({
        modalConfirm: 'Download',
        question: (
          <div>
            {hasFilters ? (
              <div>
                <p>
                  Download a CSV file with these search results? The following search filters will be applied:
                </p>
                <QueryTable queryData={queryParameters} />
              </div>
            ) : (
              <p>Download a CSV file with these search results?</p>
            )}
            <p>
              There will be {totalItems} item{totalItems !== 1 ? 's' : ''} in the file.
            </p>
          </div>
        ),
        onConfirm: () => {
          this.refetchDataAsCSV()
          confirmCallback()
        },
      })
    }

    actionDelete = () => {
      const selected = this.props.selectedItems
      if (options.deleteEntities) {
        return options.deleteEntities(selected)
      } else {
        return this.props.actions.deleteEntities(this.props.selectedItems)
      }
    }

    // Set callback functions for actions that have default handlers.
    // For example, this sets the correct callback function to the 'delete' button.
    decorateActions = format =>
      format.map(item => {
        let { action } = item
        let buttonType = item.buttonType ? item.buttonType : 'regular'
        let global = item.global ? item.global : false
        switch (item.action) {
          case ACTION_DELETE:
            action = this.actionDelete
            buttonType = 'danger'
            break
          case ACTION_DOWNLOAD_CSV:
            action = this.actionDownloadCSV
            buttonType = 'primary'
            global = true
            break
          default:
            break
        }
        return { ...item, action, buttonType, global }
      })

    filterCountrySpecificFormats = formats => {
      const { countryCode } = this.props.intl
      return filterCountrySpecificStructs(formats, countryCode)
    }

    fetchData = (opts, props, toCSV = false) => {
      const hasNoValues = !opts || !Object.keys(opts).length
      if (!this.props.actions.fetchEntities || (options.noFetchOnEmptyForm && hasNoValues)) {
        return
      }
      this.props.actions.fetchEntities({ options: opts }, props, toCSV)
    }

    refetchData = () => this.fetchData(this.props.query, this.props)

    refetchDataAsCSV = () => this.fetchData(this.props.query, this.props, true)

    // Called when updating the props, such as after retrieving fresh data from the server.
    UNSAFE_componentWillReceiveProps(newProps) {
      if (!isEqual(this.props.query, newProps.query)) {
        this.fetchData(newProps.query, newProps)
      }
    }

    render() {
      // If no wrapper is passed, use our default one.
      const Wrapper = WrappedComponent || DefaultWrapper
      const entityListType = (options.fullWidth && 'big') || (options.recordList && 'record')
      const {
        noFilterContainer,
        query,
        pagination,
        actions: { onNextPage },
      } = this.props
      const resultData = { query, pagination }
      return (
        <Wrapper
          {...this.props}
          filterForm={
            <FilterForm
              resultData={resultData}
              actionFormat={this.state.actionFormat}
              entityProps={this.props}
              entityStore={entityStore}
              filters={filters}
              isLoadingData={this.props.isLoadingData}
              isSavingData={this.props.isSavingData}
              refetchData={this.refetchData}
              type={entityListType}
              noFilterContainer={noFilterContainer}
              extraButtons={this.props.extraButtons}
            />
          }
          entityList={
            <EntityList
              entityList={this.props.entityList}
              entityType={entityStore}
              header={options.header}
              footer={isFunction(options.footer) ? options.footer(this.props) : options.footer}
              hasLinks={options.hasLinks !== false && this.props.hasLinks}
              idKey={options.idKey}
              rowDataFilter={options.rowDataFilter}
              isLoadingData={this.props.isLoadingData}
              message={this.props.lastMessage}
              hasFooter={this.props.hasFooter}
              refetchData={this.refetchData}
              rowClasses={options.rowClasses}
              editCallback={this.props.editCallback}
              selectCallback={this.props.selectCallback}
              selectedItems={this.props.selectedItems}
              selectedRow={this.props.selectedRow}
              selectFullRow={this.props.selectFullRow}
              selectSingleRow={this.props.selectSingleRow}
              tableFormat={this.tableFormat}
              title={options.title}
              type={entityListType}
              urlBase={this.props.urlBase}
              urlEntity={options.urlEntity}
              urlGenerator={options.urlGenerator || this.props.urlGenerator}
              noOverflow={options.noOverflow}
            />
          }
          entityListResults={
            this.props.pagination && options.showResultCount ? (
              <EntityListResults pagination={this.props.pagination} type={entityListType} />
            ) : null
          }
          entityListPagination={
            this.props.pagination && this.props.pagination.totalPages ? (
              <EntityListPagination
                disableAutoScroll={options.disableAutoScroll}
                pagination={this.props.pagination}
                paginationKey={this.props.pageKey}
                type={entityListType}
                onNextPage={onNextPage}
              />
            ) : null
          }
        />
      )
    }
  }

  return compose(
    createEntityListConnector({
      entityActions,
      entityStore,
      ...options,
    }),
    injectIntl,
  )(EntityListComponent)
}

export default entityListHOC
