import React from 'react'
import classnames from 'classnames'

import FormError from 'syft-acp-core/components/Form/FormError'
import { Button } from 'syft-acp-atoms/Button'
import LoadingIndicator from 'syft-acp-util/components/LoadingIndicator'

import Modal from './ModalShim'
import './Modal.css'

/**
 * Returns a reference to the <div id="modals"> in the HTML.
 * This <div> can be found in public/index.html and is used to contain modals outside
 * of the regular React scope, such as this modal window created by React Bootstrap.
 */
const getModalsRef = () => document.getElementById('modals')

type ModalComponentProps = {
  header?: string | React.ReactNode
  errorText?: string
  footerText?: string
  cancelText?: string
  isShown?: boolean
  isLoading?: boolean
  canSubmit?: boolean
  children: React.ReactNode
  onClose: (e: React.MouseEvent<HTMLElement>) => void
  onDelete?: (e: React.MouseEvent<HTMLElement>) => void
  onConfirm?: (e: React.MouseEvent<HTMLElement>) => void
  confirmationText?: string
  enforceFocus?: boolean
  className?: string | string[] | Record<string, any>
}

const ModalComponent = ({
  header,
  children,
  isShown,
  onClose,
  onConfirm,
  errorText,
  footerText,
  onDelete,
  isLoading,
  canSubmit,
  cancelText,
  confirmationText,
  enforceFocus,
  className,
}: ModalComponentProps) => (
  <Modal
    show={isShown}
    onHide={onClose}
    className={classnames('modal-window', className)}
    enforceFocus={enforceFocus}
    container={getModalsRef()}
    aria-labelledby="modal-header"
  >
    <Modal.Header closeButton>
      <Modal.Title id="modal-header">{header}</Modal.Title>
    </Modal.Header>

    <Modal.Body>{children}</Modal.Body>

    {footerText ? <Modal.Body className="text-section">{footerText}</Modal.Body> : null}

    <Modal.Footer>
      {errorText ? <FormError className="modal-error" type="error" message={errorText} /> : null}

      <div className="buttons">
        {isLoading ? (
          <span>
            <LoadingIndicator loadingState={isLoading} />
            &nbsp;
          </span>
        ) : null}
        <Button onClick={onClose}>{cancelText}</Button>

        {onConfirm && (
          <Button onClick={onConfirm} disabled={!canSubmit} kind="primary">
            {confirmationText}
          </Button>
        )}
        {onDelete && (
          <Button style={{ marginLeft: '350px' }} onClick={onDelete} kind="danger">
            Delete
          </Button>
        )}
      </div>
    </Modal.Footer>
  </Modal>
)

ModalComponent.defaultProps = {
  header: '',
  errorText: '',
  cancelText: 'Cancel',
  isLoading: false,
  isShown: false,
  canSubmit: true,
  onDelete: undefined,
  onConfirm: undefined,
  confirmationText: '',
  footerText: '',
  enforceFocus: true,
  className: '',
}

export default ModalComponent
