import { createSelector } from 'reselect'
import { SkillEntity } from 'syft-acp-core/store/types'

type State = {
  skills: { entityMap: Record<string, any> }
}

const selectSkills = (state: State) => state.skills

export const selectSkillsOptions = createSelector(selectSkills, ({ entityMap = {} }) =>
  Object.values(entityMap)
    .map((skill: SkillEntity) => ({ id: skill.id, label: skill.title }))
    .sort((a, b) => (a.label.toUpperCase() < b.label.toUpperCase() ? -1 : 1))
)
