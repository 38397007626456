import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import * as filtersActions from 'syft-acp-core/store/filters/actions'

import { filterSelect } from '../FilterSelect'
import { FilterOutcomeReachedProps as Props } from './FilterOutcomeReached.types'

const queryKey = 'outcome_reached'

export const storeConnector = connect(
  state => ({
    value: state.routing.locationBeforeTransitions.query[queryKey],
  }),
  dispatch => ({
    actions: bindActionCreators(filtersActions, dispatch),
  })
)

const options = [
  {
    id: 'true',
    label: 'Completed',
  },
  {
    id: 'false',
    label: 'Incomplete',
  },
]

const FilterOutcomeReached = ({ actions, value, onChange }: Props) => {
  const FilterSelect = filterSelect(options, 'Any', 'id', false)

  const onChangeFn = (val: string) => {
    if (onChange) onChange(val)
    actions.setFilter(queryKey, val)
  }

  useEffect(() => {
    if (!value) actions.setFilter(queryKey, 'false')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return <FilterSelect name={queryKey} onChange={onChangeFn} value={value} allowAny />
}

export default storeConnector(FilterOutcomeReached)
