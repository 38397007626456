import * as types from './action-types'

export function fetchEmployers({ options }, props, toCSV = false) {
  return { type: types.EMPLOYERS_FETCH_BEGIN, options, toCSV }
}

/**
 * Calls the API to receive a login token for a specific employer.
 * Once the employer's login URL is received, it's sent to the callback.
 *
 * @param id {Number} ID of the employer
 * @param callback {Function} Callback function to open the URL
 */
export function loginAsEmployer(id, callback) {
  return { type: types.EMPLOYER_LOGIN_AS_BEGIN, id, callback }
}

/**
 * Initiates a saving process of updated data object for an employer
 *
 * @param id {Number} ID of the employer
 * @param data {Object} Employer's new data object
 */
export function saveEmployer(id, data) {
  return { type: types.EMPLOYER_SAVE_BEGIN, id, data }
}

/**
 * Initiates a saving process of updated data object for an employer
 *
 * @param id {Number} ID of the employer
 * @param approved {Boolean} approved state of the employer
 */
export function approveEmployer(id, approved) {
  return { type: types.EMPLOYER_APPROVE_BEGIN, id, approved }
}

/**
 * Initiates an employer fetching process.
 * @param {Number} id ID of the worker
 */
export function fetchEmployer(id) {
  return { type: types.EMPLOYER_FETCH_BEGIN, id }
}

/**
 * Initeates migration of employer to syftForce
 * @param {Number} id ID of the employer
 */

export function migrateToSyftForce(id) {
  return { type: types.EMPLOYER_MIGRATE_BEGIN, id }
}
