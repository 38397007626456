// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { Grid, Row, Col } from 'react-bootstrap'
import classNames from 'classnames'

import './EntityListWrapper.css'

/**
 * Wrapper component for entity lists. If "type" is set to "big", we will give more space
 * to the EntityList inside.
 *
 * @param {Object} props Properties
 * @returns {XML} ListWrapper component
 */
export default class EntityListWrapper extends PureComponent {
  static propTypes = {
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
    type: PropTypes.string,
  }

  render() {
    const { type, children, className = null } = this.props
    const wrapperClass = classNames('entity-list-wrapper-outer', className, `type-${type || 'regular'}`)
    switch (type) {
      case 'big':
        return (
          <Col className={wrapperClass} md={12}>
            {children}
          </Col>
        )
      case 'record':
        return <div className={wrapperClass}>{children}</div>
      default:
        return (
          <Grid className={wrapperClass}>
            <Row>
              <Col md={12}>{children}</Col>
            </Row>
          </Grid>
        )
    }
  }
}
