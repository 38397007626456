import React, { useEffect, useState } from 'react'
import { bindActionCreators } from 'redux'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { history } from 'syft-acp-core/history'
import { get, isEmpty } from 'lodash-es'
import ReactTooltip from 'react-tooltip'
import { TrackingTrigger, useTrackingTrigger } from '@indeed/flex-tracking-context'

import { Button, ButtonGroup } from 'syft-acp-atoms/Button'
import RecordControls from 'syft-acp-core/components/RecordSection/RecordControls'
import RecordSection from 'syft-acp-core/components/RecordSection'
import { entityList$, pagination$ } from 'syft-acp-core/reducers/generators/entities'
import NotifyBar from 'syft-acp-atoms/NotifyBar'
import { showModal } from 'syft-acp-core/store/modals/actions'
import AgencyAccountsIndustriesRolesModal from 'syft-acp-core/components/Modal/AgencyAccountsIndustriesRolesModal'

import {
  fetchAgencyAccounts,
  updateAgencyAccounts,
  fetchAgencyAccountsPermissions,
} from 'syft-acp-core/actions/agency-accounts'
import entityDetail from 'syft-acp-util/entityDetail'
import { accountStructure, permissionStructure } from './structure'
import { trackingEvents } from './AgencyAccountsDetail.tracking'
import './styles.css'

const AgencyAccountsDetail = ({
  actions,
  accountId,
  agencyId,
  actionUpdate,
  data,
  permissions,
  venues,
  meta,
  query,
  isLoading,
}) => {
  const trackingTrigger = useTrackingTrigger()
  const [permissionsState, setPermissionsState] = useState([])
  const [deletedPermissionsState, setDeletedPermissionsState] = useState([])

  // This object is updating local pagination after removing the locations
  const subDeletedMeta = {
    ...meta,
    perPage: meta.perPage - deletedPermissionsState.length,
    total: meta.total - deletedPermissionsState.length,
  }

  // This effect will set up the permissions once the endpoint is loaded
  useEffect(() => {
    if (accountId && !isLoading) {
      setPermissionsState(permissions)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading])

  // This effect is fetching the new pages of permissions
  useEffect(() => {
    if (accountId) {
      actions.fetchAgencyAccountsPermissions({ agencyId, accountId, ...query })
    }
    if (isEmpty(data) && accountId) {
      actions.fetchAgencyAccounts({ agencyId, query: accountId })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountId, query])

  // Save permissions
  const onSave = async () => {
    trackingTrigger(trackingEvents.FORM_SAVE_BUTTON.CLICKED)

    await actions.updateAgencyAccounts(agencyId, {
      ...data,
      permissions: [...permissionsState, ...deletedPermissionsState],
    })
    // here is timeout bcs there is a race condition for async processing data on BE
    await setTimeout(() => {
      history.push(`/agencies/view/${agencyId}/accounts/list`)
    }, 1000)
  }

  const handleNewLocation = () => {
    trackingTrigger(trackingEvents.NEW_LOCATION.CLICKED)
    actions.showModal('agencyAccountsIndustiresRolesModal')
  }

  const invalidPermissions = permissionsState.find(permission => isEmpty(permission.industry_roles))
  const isAdmin = data.level === 'admin'
  const tooltipProps = {
    'data-for': 'setup-new-location-tooltip',
    'data-tip': isAdmin ? 'Admin level accounts have access to all locations' : '',
  }

  return (
    <div className="container">
      <RecordControls>
        <div className="spacer">
          <ButtonGroup>
            <Button onClick={() => history.push(`/agencies/view/${agencyId}/accounts/list`)}>
              Back to accounts
            </Button>
          </ButtonGroup>
        </div>
        <div className="right">
          <ReactTooltip place="bottom" id="setup-new-location-tooltip" />
          <div data-testid="agency-accounts-tooltip" className="agency-setup-new-location" {...tooltipProps}>
            <Button disabled={isAdmin} onClick={handleNewLocation}>
              Setup new location
            </Button>
          </div>{' '}
          <Button disabled={data.permission_processing || invalidPermissions} kind="success" onClick={onSave}>
            Save
          </Button>
        </div>
      </RecordControls>
      <NotifyBar severity="warning" conditional noMargin visible={data.permission_processing}>
        Your request is being processed! This might take a while
      </NotifyBar>
      <NotifyBar severity="error" conditional noMargin visible={data.errors}>
        Not all permissions could be created. Contact tech support to find out why
      </NotifyBar>
      <RecordSection
        title={accountId ? `Edit member ${accountId}` : 'Invite new member'}
        data={{ ...data, permissions: permissionsState }}
        structure={isAdmin ? accountStructure : [...accountStructure, permissionStructure]}
        update={actionUpdate}
        context={{
          setPermissionsState,
          setDeletedPermissionsState,
          deletedPermissionsState,
          meta: subDeletedMeta,
        }}
      />

      <AgencyAccountsIndustriesRolesModal
        employerLocations={venues}
        data={permissionsState}
        onChange={setPermissionsState}
        agencyId={agencyId}
      />

      <TrackingTrigger onLoad={{ event: trackingEvents.PAGE.VIEWED }} />
    </div>
  )
}

AgencyAccountsDetail.propTypes = {
  actions: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
  query: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
  accountId: PropTypes.number,
  agencyId: PropTypes.number,
  actionUpdate: PropTypes.func.isRequired,
  venues: PropTypes.array.isRequired,
  permissions: PropTypes.array.isRequired,
  data: PropTypes.array,
}

AgencyAccountsDetail.defaultProps = {
  accountId: null,
  agencyId: null,
  data: {},
}

export default entityDetail(
  'agencyAccounts',
  connect(
    (state, { accountId, agencyId }) => {
      const query = get(state, 'routing.locationBeforeTransitions.query')
      return {
        permissions: accountId
          ? entityList$(get(state, 'agencyAccountPermissions'), { accountId, agencyId, ...query })
          : [],
        meta: pagination$(get(state, 'agencyAccountPermissions'), { accountId, agencyId, ...query }),
        isLoading: get(state, 'agencyAccountPermissions.isLoadingData'),
        query,
        venues: get(state, 'employerVenuesFiltered.entityMap'),
      }
    },
    dispatch => ({
      actions: bindActionCreators(
        { showModal, updateAgencyAccounts, fetchAgencyAccountsPermissions, fetchAgencyAccounts },
        dispatch,
      ),
    }),
  )(AgencyAccountsDetail),
)
