// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { InputGroup, FormControl } from 'react-bootstrap'
import { get } from 'lodash-es'

import { Button } from 'syft-acp-atoms/Button'

import DataExternalUUIDsItem from './DataExternalUUIDsItem'
import './DataExternalUUIDs.css'

class DataExternalUUIDs extends PureComponent {
  static propTypes = {
    value: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.number, uuid: PropTypes.string })),
    header: PropTypes.string,
    editable: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    parameters: PropTypes.object,
  }

  static defaultProps = {
    value: [],
    header: '',
    editable: false,
    disabled: false,
    parameters: {},
  }

  constructor() {
    super()
    this.state = {
      currValue: '',
      currID: 0,
      currIndex: null,
    }
  }

  addTag = () => {
    const { currValue, currID, currIndex } = this.state
    const { value } = this.props

    // Don't add a tag if it's an empty string.
    if (currValue === '') {
      return
    }

    // If we're editing, edit the correct item instead of adding a new one.
    if (currID) {
      // const currentItem = value.find((i, n) => (currIndex ? n === currIndex : i.id === currID));
      const newValues = value.map((i, n) => {
        if (i.id === currID || (currIndex && n === currIndex)) {
          return { ...i, uuid: currValue }
        }
        return i
      })
      this.props.onChange(newValues)
    } else {
      this.props.onChange([...value, { uuid: currValue }])
    }
    this.setState({ currValue: '', currID: 0, currIndex: null })
  }

  editTag = (id, index) => {
    const { value } = this.props
    const data = id ? value.find(n => n.id === id) : value[index]
    this.setState({ currValue: data.uuid, currID: data.id, currIndex: !id ? index : null })
  }

  newTag = () => {
    this.setState({ currValue: '', currID: 0, currIndex: null })
  }

  removeTag = (id, index) => {
    const newValues = this.props.value.map(item => {
      if (item.id === id) {
        const destroy = get(item, '_destroy', false)
        return { ...item, _destroy: !destroy }
      }
      return item
    })
    if (!this.props.value.find(n => n.id === id)) {
      return this.props.onChange(this.props.value.filter((_, n) => n !== index))
    }

    this.props.onChange(newValues)
  }

  onKeyPress = ev => {
    // When pressing enter, add the currently typed value to the array. Except if it's an empty string.
    if (ev.key === 'Enter') {
      this.addTag()
    }
  }

  onLocalChange = ev => {
    let val = ev.target.value

    // Reject non-numeric input if this is a numeric field.
    if (this.props.parameters.numeric) {
      val = val.replace(/\D/g, '')
    }
    this.setState({ currValue: val })
  }

  render() {
    const { value, header, editable, disabled } = this.props
    const { currValue, currID, currIndex } = this.state

    // A list of the current values.
    const values = (
      <div className="existing-items">
        {(value || []).map((item, n) => {
          const uuid = get(item, 'uuid')
          const id = get(item, 'id', false)
          const toDelete = get(item, '_destroy', false)
          return (
            <DataExternalUUIDsItem
              onEdit={this.editTag}
              key={n}
              index={n}
              id={id}
              isBeingEdited={currIndex != null ? n === currIndex : currID === id}
              onDelete={this.removeTag}
              setToDelete={toDelete}
              isSaved={id !== false}
            >
              {uuid}
            </DataExternalUUIDsItem>
          )
        })}
      </div>
    )

    if (editable) {
      return (
        <div className="DataExternalUUIDs">
          <div className="uuid-contain">
            <InputGroup className={currID ? 'editing' : ''}>
              <FormControl
                className="boxed-input"
                type="text"
                onChange={this.onLocalChange}
                onKeyPress={this.onKeyPress}
                value={currValue}
                placeholder={header}
                disabled={disabled}
              />
              <InputGroup.Button>
                <Button onClick={this.addTag} kind={currID ? 'success' : 'primary'}>
                  {currID ? 'Edit' : 'Add'}
                </Button>
                {currID ? <Button onClick={this.newTag}>Cancel</Button> : ''}
              </InputGroup.Button>
            </InputGroup>
            {values}
          </div>
        </div>
      )
    } else {
      return values
    }
  }
}

export default DataExternalUUIDs
