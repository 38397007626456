import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { ControlLabel, HelpBlock, FormGroup } from 'react-bootstrap'
import { keyPrefix } from 'syft-acp-core/constants'

import { getModal$ } from 'syft-acp-core/store/modals/selectors'
import { Modal } from 'syft-acp-core/components/Modal'
import { showModal, hideModal } from 'syft-acp-core/store/modals/actions'
import DataTextarea from 'syft-acp-core/components/EditableTable/DataTextarea'
import { queue } from 'syft-acp-util/wait'

class ImportApplicationStateModal extends PureComponent {
  static propTypes = {
    item: PropTypes.object.isRequired,
    dispatch: PropTypes.func.isRequired,
  }

  state = {
    valid: true,
    textValue: '',
    sending: false,
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    // If we are becoming visible, while not being visible, reset the form.
    if (this.props.item.isShown !== nextProps.item.isShown && nextProps.item.isShown === true) {
      this.setState({ textValue: '' })
    }
  }

  onConfirmHandler = () => {
    const valid = this.validate()

    if (valid) {
      this.setState({ sending: true })

      const data = JSON.parse(this.state.textValue)
      localStorage.clear()
      for (const [key, value] of Object.entries(data)) {
        const prefixKey = `${keyPrefix}${key}`
        localStorage.setItem(prefixKey, JSON.stringify(value))
      }

      this.setState({ sending: false })
      this.closeModal()
      window.location.reload()
    }
  }

  setValue = val => {
    this.setState({ textValue: val })
    queue(() => this.validate())
  }

  /** Attempt to parse the JSON. If it parses successfully and isn't just whitespace, this validates. */
  isValidJSON = () => {
    const { textValue } = this.state
    if (textValue.trim() === '') {
      return false
    }
    try {
      JSON.parse(textValue)
      return true
    } catch (err) {
      return false
    }
  }

  validate = () => {
    const valid = this.isValidJSON()
    this.setState({ valid })
    return valid
  }

  closeModal = () => this.props.dispatch(hideModal(this.props.item.modalName))

  openModal = () => this.props.dispatch(showModal(this.props.item.modalName))

  render() {
    return (
      <Modal
        header="Import application state"
        isShown={this.props.item.isShown}
        onClose={this.closeModal}
        onConfirm={this.onConfirmHandler}
        confirmationText="Import data"
      >
        <FormGroup validationState={this.state.valid ? null : 'error'}>
          <ControlLabel>JSON data</ControlLabel>
          <DataTextarea onChange={this.setValue} monospace rows={5} value={this.state.textValue} editable />
          <HelpBlock>This will replace the localstorage data with the data entered here.</HelpBlock>
          <HelpBlock>The page will refresh after saving.</HelpBlock>
        </FormGroup>
      </Modal>
    )
  }
}

export default connect(state => ({
  item: getModal$(state.modals)('importApplicationStateModal'),
}))(ImportApplicationStateModal)
