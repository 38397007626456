import { apiRequest, handleCall } from 'syft-acp-core/api/call'

export const fetchAdminConversations = ({ options = {} }) =>
  new Promise((resolve, reject) => {
    const req = apiRequest({ path: '/admin/conversations', reqArgs: options })
    handleCall(req, resolve, reject, true, options)
  })

export const fetchAdminConversation = ({ id }) =>
  new Promise((resolve, reject) => {
    const req = apiRequest({ path: `/admin/conversations/${id}` })
    handleCall(req, resolve, reject, true)
  })

export const fetchAdminConversationMessages = ({ id }) =>
  new Promise((resolve, reject) => {
    const req = apiRequest({ path: `/admin/conversations/${id}/messages` })
    handleCall(req, resolve, reject, true)
  })
