import { emptySet } from 'syft-acp-core/reducers/generators/constants'
import { entitySetName } from 'syft-acp-core/reducers/generators/utils'

export const entitiesFetchBegin = (state, action, options) => {
  const setKey = entitySetName(action.options, options, action)

  return {
    ...state,
    lastMessage: '',
    lastStatus: null,
    lastBody: null,
    isLoadingData: true,
    entitySets: {
      ...state.entitySets,
      [setKey]: {
        ...emptySet,
        ...state.entitySets[setKey],
        isLoadingData: true,
      },
    },
  }
}
