import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { filterSelect } from './FilterSelect'

class FilterSelectWorkers extends PureComponent {
  static propTypes = {
    options: PropTypes.arrayOf(PropTypes.object),
    disabled: PropTypes.bool,
    removeEmptyWorkers: PropTypes.bool,
    name: PropTypes.string,
    onChange: PropTypes.func,
  }

  static defaultProps = {
    options: [],
    name: '',
    removeEmptyWorkers: false,
    onChange: null,
    disabled: false,
  }

  state = {
    selectedWorkerID: null,
  }

  onChange = val => {
    this.setState({ selectedWorkerID: val.id })
    this.props.onChange(val)
  }

  render() {
    const { options, name, disabled, removeEmptyWorkers = false } = this.props
    const { selectedWorkerID } = this.state
    const selectOptions = removeEmptyWorkers ? options.filter(w => !!w.id) : options
    const worker = options.filter(w => w.id === selectedWorkerID)[0]
    const FilterSelect = filterSelect(selectOptions, 'Worker', 'object')

    return <FilterSelect name={name} disabled={disabled} onChange={this.onChange} value={worker} allowAny={false} />
  }
}

export default FilterSelectWorkers
