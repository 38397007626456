// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import entityReducer from './generators/entityReducer'
import * as types from 'syft-acp-core/actions/action-types'
import * as entityTypes from './generators/entities'

export default entityReducer('importLogEvents', {
  [entityTypes.ENTITIES_FETCH_BEGIN]: types.IMPORT_EVENT_LOGS_FETCH_BEGIN,
  [entityTypes.ENTITIES_FETCH_SUCCEEDED]: types.IMPORT_EVENT_LOGS_FETCH_SUCCEEDED,
  [entityTypes.ENTITIES_FETCH_FAILED]: types.IMPORT_EVENT_LOGS_FETCH_FAILED,
})
