import React, { useCallback } from 'react'

import FilterIndustryRolesMultiselect from 'syft-acp-util/components/FilterForm/FilterIndustryRolesMultiselect'

import { colPlaceholderFactory } from '../../../../placeholders/factory'
import { setNewIndustryRole } from './helpers'
import { AcpCellRolesIndustriesMultiselectProp as Prop } from './AcpCellRolesIndustriesMultiselect.types'

// Column placeholder component.
export const AcpColRolesIndustriesMultiselect = colPlaceholderFactory('AcpCellRolesIndustriesMultiselect')

/**
 * Cell component for displaying date time. It supports a number of formatting options via Intl.DateTimeFormat API.
 */
export default ({ rowData, spec }: Prop) => {
  const handleEdit = useCallback(data => {
    const changedData = {
      ...rowData,
      industry_roles: setNewIndustryRole(rowData.industry_roles, data),
    }

    spec.handleEditChange(changedData)
  }, [rowData, spec])

  return (
    <FilterIndustryRolesMultiselect
      key={rowData.id}
      onChange={handleEdit}
      employerId={rowData.employer_id}
      defaultValues={rowData.industry_roles.map(ele => ({
        value: ele.industry_id,
        options: ele.role_ids.map(roleId => ({ value: roleId })),
      }))}
      oneIndustry
    />
  )
}
