import { takeLatest } from 'redux-saga/effects'
import { fetchAgencyBookings, saveAgencyBookings } from 'syft-acp-core/api/resources/agency-bookings'
import { AGENCY_BOOKING_SAVE_FAILED, AGENCY_BOOKING_SAVE_SUCCEEDED } from 'syft-acp-core/actions/action-types'

import { takeEveryApiCall, takeLatestApiCall } from './helpers'
import { notificationCall } from './calls'

export default function* root() {
  yield takeEveryApiCall('AGENCY_BOOKING_FETCH', fetchAgencyBookings)
  yield takeLatestApiCall('AGENCY_BOOKING_SAVE', saveAgencyBookings)
  yield takeLatest([AGENCY_BOOKING_SAVE_FAILED, AGENCY_BOOKING_SAVE_SUCCEEDED], notificationCall)
}
