import {
  AGENCY_UNFILLED_ALLOCATIONS_FETCH_BEGIN,
  AGENCY_UNFILLED_ALLOCATIONS_DELETE_BEGIN,
  AGENCY_BULK_DELETE_ALLOCATIONS_BEGIN,
} from './action-types'
import { UnfilledAllocationsPayload } from './types'

export const fetchAgencyUnfilledAllocations = (
  id: string,
  payload: UnfilledAllocationsPayload,
  cascadeFullJob: boolean,
  refetch: () => void,
) => ({
  type: AGENCY_UNFILLED_ALLOCATIONS_FETCH_BEGIN,
  id,
  payload,
  cascadeFullJob,
  refetch,
})

export const deleteAgencyUnfilledAllocations = (ids: number[], refetch?: () => void) => ({
  type: AGENCY_UNFILLED_ALLOCATIONS_DELETE_BEGIN,
  ids,
  refetch,
})

export const bulkDeleteAgencyUnfilledAllocations = (ids: number[], refetch?: () => void) => ({
  type: AGENCY_BULK_DELETE_ALLOCATIONS_BEGIN,
  ids,
  refetch,
})
