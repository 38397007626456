import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Grid, Col, Row } from 'react-bootstrap'
import { get } from 'lodash-es'
import { Tab } from 'react-bootstrap'
import { history } from 'syft-acp-core/history'

import entityDetail from 'syft-acp-util/entityDetail'
import SiteComponent from 'syft-acp-core/components/SiteComponent'
import { EntityTabs } from 'syft-acp-core/components/EntityTabs'

import ListingRatingsList from './ListingRatingsList'
import ListingDetailControls from '../ListingDetail/ListingDetailControls'

const navigateListing = ({ shiftID, jobID, listingID }) => {
  history.push(`/listings/ratings/${listingID}/job/${jobID}/shift/${shiftID}`)
}
class ListingRatings extends Component {
  static propTypes = {
    id: PropTypes.number.isRequired,
    jobID: PropTypes.number.isRequired,
    data: PropTypes.object.isRequired,
    isPristine: PropTypes.bool,
  }

  static defaultProps = {
    isPristine: false,
  }

  /** Updates the current URL when a new job/shift is selected. */
  updateURL = (reqShiftID, reqJobID) => {
    const { id } = this.props
    navigateListing({ listingID: id, jobID: reqJobID, shiftID: reqShiftID })
  }

  handleJobSelect = jobID => {
    const job = this.props.data.jobs.find(j => j.id === jobID)
    const shiftID = get(job, 'shifts[0].id')
    this.updateURL(shiftID, jobID)
  }

  render() {
    const {
      data: { jobs },
      jobID,
      id,
      isPristine,
      data,
      shiftID,
    } = this.props
    return (
      <SiteComponent>
        <Grid className="listing-detail">
          <Row>
            <Col md={12} sm={12}>
              <ListingDetailControls data={data} isPristine={isPristine} shiftID={shiftID} />
              <EntityTabs
                activeKey={jobID || get(jobs, '[0].id')}
                onSelect={this.handleJobSelect}
                id="job-tab"
              >
                {jobs &&
                  jobs.map((job, i) => (
                    <Tab eventKey={job.id} title={get(job, 'role.title')} key={i}>
                      <ListingRatingsList jobID={job.id} listingID={id} />
                    </Tab>
                  ))}
              </EntityTabs>
            </Col>
          </Row>
        </Grid>
      </SiteComponent>
    )
  }
}

export default entityDetail('listings', ListingRatings)
