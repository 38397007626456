// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited
import { get } from 'lodash-es'
import { put, take, fork, select, takeLatest } from 'redux-saga/effects'

import * as types from 'syft-acp-core/actions/action-types'
import { USER_PATCH_FAILED, USER_PATCH_SUCCEEDED } from 'syft-acp-core/store/users/action-types'
import { ensureDefaultSettings, setValues as setUserSettings } from 'syft-acp-core/actions/user-settings'
import { patchUser } from 'syft-acp-core/store/users/actions'
import { notificationCall } from 'syft-acp-core/sagas/calls'

export function* watchForSettingsChanged() {
  while (true) {
    const prevUserId = yield select(rootState => rootState.auth.userData.id)
    const prevSettings = yield select(rootState => get(rootState, `userSettings.entityDetail.${prevUserId}`) || {})
    const { settings, name, value, userID } = yield take([
      types.USER_SETTINGS_SET_VALUE,
      types.USER_SETTINGS_SET_VALUES,
    ])
    const timeZone = settings ? get(settings, 'timezone') : (name === 'settings' && value) || null

    if ((timeZone && timeZone !== prevSettings.timezone) || (userID && userID !== prevUserId)) {
      yield put(patchUser(userID, timeZone))
    }
  }
}

export function* setInitialUserSettings() {
  const auth = yield select(state => state.auth)

  if (!!auth.userData && auth.isLoggedIn) {
    yield put(
      setUserSettings(
        {
          timezone: get(auth, 'userData.timezone'),
          country: get(auth, 'userData.country_code'),
        },
        get(auth, 'userData.id')
      )
    )
  }

  yield fork(watchForSettingsChanged)
}

function* removeUserSettings() {
  yield put(ensureDefaultSettings())
}

export default function* root() {
  yield takeLatest([types.REHYDRATION_COMPLETE, types.LOGIN_SUCCEEDED], setInitialUserSettings)
  yield takeLatest(types.AUTH_DELETE, removeUserSettings)
  yield takeLatest([USER_PATCH_SUCCEEDED, USER_PATCH_FAILED], notificationCall)
}
