// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import PropTypes from 'prop-types'
import React, { PureComponent } from 'react'
import { Col } from 'react-bootstrap'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import entityDetail from 'syft-acp-util/entityDetail'
import { queue } from 'syft-acp-util/wait'

import { fetchAdminManagers } from 'syft-acp-core/actions/managers'
import EntityDetailItem from 'syft-acp-core/components/EntityDetail/EntityDetailItem'
import RecordSection from 'syft-acp-core/components/RecordSection'
import RecordHeader from 'syft-acp-core/components/RecordSection/RecordHeader'
import RecordSegment from 'syft-acp-core/components/RecordSection/RecordSegment'
import * as employersActions from 'syft-acp-core/store/employers/actions'

import { EmployerDetailControlRight } from './EmployerDetailControlRight'
import { EmployerDetailControlsLeftConnected } from './EmployerDetailControls'
import { EmployerDetailWrapperBase } from './EmployerDetailListWrapper'
import EmployerDetailStats from './EmployerDetailStats'
import EmployerDetailTransferRecord from './EmployerDetailTransferRecord'
import { dataPropTypes, dataStructure } from './structure'
import { Flex, Box } from '@indeed/ifl-components'

class EmployerDetail extends PureComponent {
  static propTypes = {
    id: PropTypes.number.isRequired,
    data: dataPropTypes.isRequired,
    actionUpdate: PropTypes.func.isRequired,
    isPristine: PropTypes.bool.isRequired,
    isLoadingData: PropTypes.bool.isRequired,
    isSavingData: PropTypes.bool.isRequired,
    actions: PropTypes.objectOf(PropTypes.func).isRequired,
    showGranularShiftEditSettings: PropTypes.bool,
    showReasonsCheckbox: PropTypes.bool,
  }

  constructor() {
    super()
    this.didInitialFetch = false
  }

  componentDidMount() {
    const { actions, id } = this.props
    if (!this.didInitialFetch) {
      this.didInitialFetch = true
      // FIXME: potential fix for a race condition. <CMS2-924>
      queue(() => actions.fetchEmployer(id))
    }
    actions.fetchAdminManagers({ options: {} })
  }

  getBio = data =>
    [data.contact_name, data.contact_mobile && `(Tel.: ${data.contact_mobile})`]
      .filter(n => n)
      .join(' ')
      .trim()

  render() {
    const granularShiftEditsList = dataStructure.granularShiftEdits
    const mainDataTogglableSections = {
      allow_reason_for_booking: this.props.showReasonsCheckbox,
      listing_preset_reasons: this.props.showReasonsCheckbox,
    }
    const mainDataStructure = dataStructure.mainData.filter(item => {
      if (item[0] in mainDataTogglableSections) {
        return mainDataTogglableSections[item[0]]
      }

      return true
    })

    return (
      <EmployerDetailWrapperBase
        employerID={this.props.id}
        data={this.props.data}
        actions={this.props.actions}
        isPristine={this.props.isPristine}
        isSavingData={this.props.isSavingData}
        isLoadingData={this.props.isLoadingData}
        hideHeader
        controlsLeft={<EmployerDetailControlsLeftConnected id={this.props.id} data={this.props.data} />}
        controlsRight={<EmployerDetailControlRight />}
        wrappedContent={[
          <Col md={8} sm={8} key="main">
            <RecordSection
              data={this.props.data}
              structure={mainDataStructure}
              update={this.props.actionUpdate}
              nameValue={this.props.data.company_name}
              nameBio={this.getBio(this.props.data)}
              nameAvatar={this.props.data.picture ? this.props.data.picture.uuid : null}
              showName
              nameExtraContent={
                <Flex
                  sx={{ marginTop: '40px', justifyContent: 'end', paddingRight: 5, color: 'neutral.1000' }}
                >
                  <Box sx={{ color: 'alert.800' }}>*</Box>Required fields
                </Flex>
              }
            />
            <RecordSection
              title="Shift requirements"
              data={this.props.data}
              structure={dataStructure.regulationsData}
              update={this.props.actionUpdate}
            />
            <RecordSection
              title="Company address"
              data={this.props.data}
              structure={dataStructure.addressData}
              update={this.props.actionUpdate}
            />
            <RecordSection
              title="Invoicing details"
              data={this.props.data}
              structure={dataStructure.invoicingData.filter(
                item => !(!this.props.showBillableCheckbox && item[0] === 'allow_externally_billable_jobs'),
              )}
              update={this.props.actionUpdate}
            />
            <EmployerDetailTransferRecord employer={this.props.data} />
            <RecordSection
              title="Agency permissions"
              data={this.props.data}
              structure={dataStructure.agencyPermissions}
              update={this.props.actionUpdate}
            />
            {this.props.showGranularShiftEditSettings && (
              <RecordSection
                title="Granular Shift Edits"
                data={this.props.data}
                structure={granularShiftEditsList}
                update={this.props.actionUpdate}
              />
            )}
          </Col>,
          <Col md={4} sm={4} key="stats">
            <EntityDetailItem>
              <RecordSegment noPadding>
                <RecordHeader main>Stats</RecordHeader>
              </RecordSegment>
              <RecordSegment withTable>
                <EmployerDetailStats id={this.props.id} />
              </RecordSegment>
            </EntityDetailItem>
          </Col>,
        ]}
      />
    )
  }
}

export default connect(
  state => ({
    isSavingData: state.employers.isSavingData,
    isLoadingData: state.employers.isLoadingData,
  }),
  dispatch => ({
    actions: bindActionCreators({ ...employersActions, fetchAdminManagers }, dispatch),
  }),
)(entityDetail('employers', EmployerDetail))
