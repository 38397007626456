// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import React, { useState, ChangeEvent, useEffect } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import * as authActions from 'syft-acp-core/actions/auth'
import * as appActions from 'syft-acp-core/actions/app'
import LoginSSOFormPure from './LoginSSOFormPure'
import LoginTroubleModal from './LoginTroubleModal'
import { validateEmailAndDomain } from './helpers'

import { LoginSSOFormComponentProps as Props } from './LoginSSOForm.types'

const LoginSSOFormComponent = ({ errorBody, isCheckingLogin, hasRehydrated, actions, query }: Props) => {
  const [emailValidationMessage, setEmailValidationMessage] = useState<string | null>(null)
  useEffect(() => {
    if (query?.code) {
      actions.consumeIndeedToken(query?.code)
    }
  }, [])
  useEffect(() => {
    setEmailValidationMessage(null)
  }, [isCheckingLogin])
  const [email, setEmail] = useState('')
  const [openTroubleModal, setOpenTroubleModal] = useState(false)

  const typeEmail = (ev: ChangeEvent<HTMLInputElement>) => {
    setEmail(ev.target.value)
  }

  const closeLoginModal = () => {
    setOpenTroubleModal(false)
  }

  const loginReq = (ev: ChangeEvent<HTMLInputElement>) => {
    ev.preventDefault()
    if (validateEmailAndDomain(email)) {
      setEmailValidationMessage(null)
      actions.beginLoginSSO(email)
    }
    setEmailValidationMessage(
      'Invalid email domain. Please login with your Indeed Flex or Syftapp email address or raise an IT support ticket on '
    )
  }

  const loginTrouble = () => {
    setOpenTroubleModal(true)
  }

  const message = errorBody?.error_description
  return (
    <>
      <LoginSSOFormPure
        isDisabled={isCheckingLogin}
        onTypeEmail={typeEmail}
        valueEmail={email}
        onSubmit={loginReq}
        onLoginTrouble={loginTrouble}
        feedbackMessage={message}
        emailValidationMessage={emailValidationMessage}
        isAwaitingRehydration={!hasRehydrated}
      />
      <LoginTroubleModal
        handleSubmit={actions.resetAndReload}
        isOpen={openTroubleModal}
        handleOnExit={closeLoginModal}
      />
    </>
  )
}

const LoginSSOForm = connect(
  state => ({
    feedbackMessage: state.auth?.lastMessage,
    errorBody: state.auth?.errorBody,
    hasRehydrated: state.localstorage?.hasRehydrated,
    isCheckingLogin: state.auth?.isCheckingLogin,
    query: state.routing?.locationBeforeTransitions.query,
  }),
  dispatch => ({
    actions: bindActionCreators(
      {
        ...authActions,
        ...appActions,
      },
      dispatch
    ),
  })
)(LoginSSOFormComponent)

export { LoginSSOForm }
