export const WORKER_STRIKES_FETCH_BEGIN = 'syftacp/WORKER_STRIKES_FETCH_BEGIN'
export const WORKER_STRIKES_FETCH_FAILED = 'syftacp/WORKER_STRIKES_FETCH_FAILED'
export const WORKER_STRIKES_FETCH_SUCCEEDED = 'syftacp/WORKER_STRIKES_FETCH_SUCCEEDED'

export const WORKER_STRIKE_CREATE_BEGIN = 'syftacp/WORKER_STRIKE_CREATE_BEGIN'
export const WORKER_STRIKE_CREATE_FAILED = 'syftacp/WORKER_STRIKE_CREATE_FAILED'
export const WORKER_STRIKE_CREATE_SUCCEEDED = 'syftacp/WORKER_STRIKE_CREATE_SUCCEEDED'

export const WORKER_STRIKE_DELETE_BEGIN = 'syftacp/WORKER_STRIKE_DELETE_BEGIN'
export const WORKER_STRIKE_DELETE_FAILED = 'syftacp/WORKER_STRIKE_DELETE_FAILED'
export const WORKER_STRIKE_DELETE_SUCCEEDED = 'syftacp/WORKER_STRIKE_DELETE_SUCCEEDED'

export const WORKER_STRIKE_FETCH_BEGIN = 'syftacp/WORKER_STRIKE_FETCH_BEGIN'
export const WORKER_STRIKE_FETCH_FAILED = 'syftacp/WORKER_STRIKE_FETCH_FAILED'
export const WORKER_STRIKE_FETCH_SUCCEEDED = 'syftacp/WORKER_STRIKE_FETCH_SUCCEEDED'

export const WORKER_STRIKE_UPDATE_BEGIN = 'syftacp/WORKER_STRIKE_UPDATE_BEGIN'
export const WORKER_STRIKE_UPDATE_FAILED = 'syftacp/WORKER_STRIKE_UPDATE_FAILED'
export const WORKER_STRIKE_UPDATE_SUCCEEDED = 'syftacp/WORKER_STRIKE_UPDATE_SUCCEEDED'

export const WORKER_STRIKE_REASONS_FETCH_BEGIN = 'syftacp/WORKER_STRIKE_REASONS_FETCH_BEGIN'
export const WORKER_STRIKE_REASONS_FETCH_FAILED = 'syftacp/WORKER_STRIKE_REASONS_FETCH_FAILED'
export const WORKER_STRIKE_REASONS_FETCH_SUCCEEDED = 'syftacp/WORKER_STRIKE_REASONS_FETCH_SUCCEEDED'
