import React from 'react'
import { TrackingProvider } from '@indeed/flex-tracking-context'

type Props = {
  children: React.ReactNode
  employerId: number
}

export const EmployerRateCardTrackingProvider = ({ children, employerId }: Props) => (
  <TrackingProvider value={{ context: { employer_id: employerId } }}>{children}</TrackingProvider>
)
