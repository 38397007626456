import { get, memoize } from 'lodash-es'
import { createSelector } from 'reselect'

export const getEmployer$ = createSelector(
  state => state.employers,
  (_, id) => id,
  (employersState, id) => employersState?.entityMap[id]
)

/**
 * Selects all invoicing types (period and type). We get this data when retrieving any employer's data.
 * Returned as an object with 'period' and 'type' keys.
 */
export const selectInvoicingTypes$ = createSelector(
  state => state,
  employers =>
    memoize(() => {
      // Doesn't matter if we get data from entityDetail or entityMap, and we only need one.
      const employerData = Object.values({ ...employers.entityDetail, ...employers.entityMap })[0]
      return (
        employerData && {
          period: get(employerData, 'invoicing_period_options', []),
          type: get(employerData, 'invoicing_type_options', []),
          format: get(employerData, 'invoicing_format_options', []),
        }
      )
    })
)
