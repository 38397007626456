// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import React from 'react'
import { useFlexFlagIsOn } from '@indeed/flex-feature-flags'

import { get } from 'lodash-es'
import { history } from 'syft-acp-core/history'

import entityList from 'syft-acp-util/entityList'
import { getFederatedState } from 'syft-acp-util/states'
import * as tableType from 'syft-acp-core/components/EntityList/'
import * as listingActions from 'syft-acp-core/store/listings/actions'
import {
  FilterGroup,
  FilterText,
  FilterEnum,
  FilterSelectCities,
  FilterDateRange,
  FilterRolesSkills,
  FilterSelectIndustries,
  FilterAutocompleteManagers,
  FilterTiers,
  FilterAutocompleteVenuesCities,
} from 'syft-acp-util/components/FilterForm'
import { store } from 'syft-acp-core/store'
import CountrySpecific from 'syft-acp-core/components/CountrySpecific'
import * as filterTypes from 'syft-acp-util/components/FilterForm/FilterForm'
import { getPriorityLabel } from 'syft-acp-util/components/FilterForm/FilterTiers'
import { showConfirmModal } from 'syft-acp-core/store/modals/actions'
import { TypeLabels } from 'syft-acp-core/components/TypeMaps'
import EntityListComponent from 'syft-acp-core/components/EntityList/EntityListComponent'
import { SUPPORTED_COUNTRY_CODES } from 'syft-acp-core/lib/i18n'
import { checkDateQueryMatchListing, getDateRangeListing } from 'syft-acp-core/lib/listings'
import { useTrackingTrigger, TrackingTrigger } from '@indeed/flex-tracking-context'
import { trackingEvents } from './index.tracking'

const tableFormat = isEmployerNameEnabled => [
  { type: tableType.SELECTOR },
  { type: tableType.TXT, val: 'id', header: 'Listing ID', numeric: true },
  {
    type: tableType.TXT,
    classes: ['one-liner'],
    val: 'event_name',
    header: 'Event name',
  },
  {
    type: tableType.TXT,
    classes: [],
    val: v => {
      const name = isEmployerNameEnabled ? get(v, 'employer.name') : get(v, 'employer.company_name')
      const types = {
        no_admin_input: {
          name: 'No admin input',
          style: 'default',
        },
      }
      const labels = { no_admin_input: get(v, 'employer.no_admin_input') }
      return (
        <span>
          {name} <TypeLabels value={labels} typesMap={types} />
        </span>
      )
    },
    valHints: ['employer.company_name', 'employer.no_admin_input'],
    header: isEmployerNameEnabled ? 'Brand/company' : 'Employer',
  },
  {
    type: tableType.TXT,
    classes: [],
    val: v => get(v, 'venue.address.city'),
    header: 'City',
  },
  {
    type: tableType.TXT,
    classes: [],
    val: v => getFederatedState(get(v, 'venue.address.federated_state_code')),
    header: 'State',
    showForCountries: [SUPPORTED_COUNTRY_CODES.US],
  },
  {
    type: tableType.TXT,
    classes: ['nowrap', 'limit'],
    val: v => get(v, 'venue.name'),
    header: 'Venue',
  },
  {
    type: tableType.DATETIME,
    classes: ['nowrap'],
    val: 'created_at',
    options: data => ({ timeZone: get(data, 'venue.timezone') }),
    header: 'Created',
  },
  {
    type: tableType.DATETIME,
    classes: ['nowrap'],
    val: 'start_time',
    options: data => ({ timeZone: get(data, 'venue.timezone') }),
    header: 'Start time',
  },
  {
    type: tableType.DATETIME,
    classes: ['nowrap'],
    val: 'end_time',
    options: data => ({ timeZone: get(data, 'venue.timezone') }),
    header: 'End time',
  },
  {
    // Note: "stage" was formerly "status".
    type: tableType.ENUM,
    val: data => {
      const stageVal = data.stage
      // If a listing's stage is "upcoming", it is listed either as "booked" or "not booked"
      // depending on whether the number of required workers matches the number of bookings.
      if (stageVal === 'upcoming') {
        return data.booking_status.filled_spots === data.booking_status.total_spots ? 'booked' : 'not_booked'
      }
      return stageVal
    },
    enumStates: {
      booked: 'booked',
      not_booked: 'not_booked',
      current: 'live',
      in_review: 'in_review',
      complete: 'complete',
      deleted: 'deleted',
    },
    enumLabels: {
      booked: 'Booked',
      not_booked: 'Not booked',
      current: 'Live',
      in_review: 'In review',
      complete: 'Complete',
      deleted: 'Deleted',
    },
    classes: ['nowrap'],
    header: 'Stage',
  },
  {
    type: tableType.TXT,
    numeric: true,
    val: 'booking_status.filled_spots',
    header: 'Booked shifts',
  },
  {
    type: tableType.TXT,
    numeric: true,
    val: 'booking_status.total_spots',
    header: 'Total shifts',
  },
  {
    type: tableType.TXT,
    val: e => getPriorityLabel(get(e, 'employer.tier')),
    classes: ['nowrap'],
    header: 'Priority',
  },
  {
    type: tableType.TXT,
    val: 'employer.syft_account_manager.name',
    header: 'Manager',
  },
]

const filters = onFilterChange => (
  <div>
    <FilterGroup title="ID">
      <FilterText onChange={onFilterChange} name="listing_id" placeholder="Listing ID" small />
    </FilterGroup>
    <FilterGroup title="Event name">
      <FilterText onChange={onFilterChange} name="event_name" placeholder="Event name" small />
    </FilterGroup>
    <FilterGroup title="Employer">
      <FilterText onChange={onFilterChange} name="employer_name" placeholder="Employer name" small />
    </FilterGroup>
    <FilterGroup title="Worker">
      <FilterText onChange={onFilterChange} name="worker_name" placeholder="Worker name" small />
    </FilterGroup>
    <FilterGroup title="Venue">
      <FilterText onChange={onFilterChange} name="venue_name" placeholder="Venue name" small />
    </FilterGroup>
    <CountrySpecific countries={[SUPPORTED_COUNTRY_CODES.US]}>
      {isUS =>
        isUS ? (
          <>
            <FilterGroup title="Venue city">
              <FilterAutocompleteVenuesCities onChange={onFilterChange} name="venue_city" />
            </FilterGroup>
            <FilterGroup title="Region">
              <FilterSelectCities
                onChange={onFilterChange}
                name="city_id"
                queryAttribute="city_id"
                allowAny
              />
            </FilterGroup>
          </>
        ) : (
          <FilterGroup title="City">
            <FilterSelectCities onChange={onFilterChange} name="city_id" allowAny />
          </FilterGroup>
        )
      }
    </CountrySpecific>
    <FilterGroup title="Industry">
      <FilterSelectIndustries
        onChange={onFilterChange}
        name="industry_id"
        queryKey="industry_id"
        options={{ idKey: 'id' }}
        allowAny
      />
    </FilterGroup>
    <FilterGroup title="Account manager">
      <FilterAutocompleteManagers
        onChange={onFilterChange}
        name="syft_manager_id"
        parameters={{ managerRoles: ['account_manager'] }}
        allowAny
      />
    </FilterGroup>
    <FilterGroup title="Shift start date">
      <FilterDateRange onChange={onFilterChange} nameFrom="start_date_gte" nameTo="start_date_lte" useDate />
    </FilterGroup>
    <FilterGroup title="Creation date">
      <FilterDateRange
        onChange={onFilterChange}
        nameFrom="created_at_date_gte"
        nameTo="created_at_date_lte"
        useDate
      />
    </FilterGroup>
    <FilterGroup title="Priority">
      <FilterTiers onChange={onFilterChange} name="tier" allowAny />
    </FilterGroup>
    <FilterGroup title="View from">
      <FilterEnum
        onChange={onFilterChange}
        options={[
          [
            {
              filterFunction: () => getDateRangeListing(0, 1),
              activeFunction: checkDateQueryMatchListing(0, 1),
              label: 'Today',
              name: ['start_date_gte', 'start_date_lte'],
              defaultValue: true,
            },
          ],
          [
            {
              filterFunction: () => getDateRangeListing(1, 2),
              activeFunction: checkDateQueryMatchListing(1, 2),
              label: 'Tomorrow',
              name: ['start_date_gte', 'start_date_lte'],
            },
            {
              filterFunction: () => getDateRangeListing(0, 8),
              activeFunction: checkDateQueryMatchListing(0, 8),
              label: 'Next 7 days',
              name: ['start_date_gte', 'start_date_lte'],
            },
          ],
        ]}
      />
    </FilterGroup>
    <FilterGroup title="Number of shifts">
      <FilterText onChange={onFilterChange} name="total_spots_gte" placeholder="≥" small type="number" />
      <FilterText onChange={onFilterChange} name="total_spots_lte" placeholder="≤" small type="number" />
    </FilterGroup>
    <FilterGroup title="Number of workers">
      <FilterText onChange={onFilterChange} name="workers_required_gte" placeholder="≥" small type="number" />
      <FilterText onChange={onFilterChange} name="workers_required_lte" placeholder="≤" small type="number" />
    </FilterGroup>
    <FilterGroup title="Stage">
      <FilterEnum
        name="status"
        options={[
          [{ value: '', label: 'All' }],
          [
            { value: 'live', label: 'Live' },
            { value: 'not_booked', label: 'Not booked' },
            { value: 'booked', label: 'Booked' },
            { value: 'needs_approval', label: 'Needs approval' },
            { value: 'completed', label: 'Completed' },
          ],
        ]}
        onChange={onFilterChange}
      />
    </FilterGroup>
    <FilterGroup title="Roles and skills">
      <FilterRolesSkills
        onChange={onFilterChange}
        role_key="role_id"
        skill_key="skill_id"
        independentSkills
        allowAny
      />
    </FilterGroup>
  </div>
)

const actionFormat = onDelete => [
  {
    label: 'Delete',
    buttonType: 'danger',
    type: filterTypes.TYPE_BUTTON,
    action: (ids, fetchData) => {
      store.dispatch(
        showConfirmModal({
          question: `Are you sure? The listings (IDs ${ids.join(', ')}) will be deleted.`,
          onConfirm: () => {
            onDelete(ids)
            return store.dispatch(listingActions.deleteListings(ids, () => fetchData()))
          },
        }),
      )
    },
  },
]

const ListingList = () => {
  const isEmployerNameEnabled = useFlexFlagIsOn('pte_2156_listings_employer_name')
  const triggerEvent = useTrackingTrigger()
  const onFilterChange = (key, value) => {
    triggerEvent(trackingEvents.LISTINGS_LIST.VIEW_FILTERS.CHANGED, { filter: key, value })
  }
  const onDelete = ids => {
    triggerEvent(trackingEvents.LISTINGS_LIST.DELETE.CLICKED, { ids })
  }

  const handleCallback = data => {
    triggerEvent(trackingEvents.LISTINGS_LIST.LISTING.SELECTED, { id: data.id })
    history.push(`/listings/view/${data.id}`)
  }

  const EntityList = entityList(
    EntityListComponent,
    tableFormat(isEmployerNameEnabled),
    filters(onFilterChange),
    actionFormat(onDelete),
    'listings',
    { fetchEntities: listingActions.fetchListings },
    {
      fullWidth: true,
      urlEntity: false,
      showResultCount: true,
      // Don't fetch data on mount. We will fetch as soon as the "today" filter activates.
      noInitialFetch: true,

      rowClasses: data => {
        if (data.platform_id !== 1) return 'syftforce'
        return ''
      },
    },
  )
  return (
    <>
      <TrackingTrigger
        onLoad={{
          event: trackingEvents.LISTINGS_LIST.PAGE.VIEWED,
        }}
      />
      <EntityList selectCallback={handleCallback} selectFullRow />
    </>
  )
}

export default ListingList
