import React from 'react'
import QueryTable from '../../QueryTable'
import { FormControl } from 'react-bootstrap'
import { A_VERY_LARGE_NUMBER } from 'syft-acp-core/api/call'
import './DownloadCSVModal.scss'

const MAX_SELECT_ITEMS = 10

interface IDownloadCSVModalContent {
  hasFilters: boolean
  query: any
  totalPages: number
  onPageChange: (e: React.FormEvent<FormControl>) => void
  pageToDownload: number
  total: number
  itemsInFile?: number
  labelTable?: any
  labelTableRange?: any
}

const DownloadCSVModalContent = ({
  hasFilters,
  query,
  totalPages,
  onPageChange,
  pageToDownload,
  total,
  itemsInFile,
  labelTable,
  labelTableRange,
}: IDownloadCSVModalContent) => {
  return (
    <>
      {hasFilters && Object.keys(query).length > 0 && (
        <>
          <p>The following search filters will be applied:</p>
          <QueryTable queryData={query} labelTable={labelTable} labelTableRange={labelTableRange} />
        </>
      )}

      {/* if more than 1 page then give user options */}
      {totalPages > 1 && (
        <div className="multipleFiles">
          <p>
            {totalPages < 10 ? 'Select' : 'Enter'} a file number between 1 and {totalPages} to download:
          </p>
          {/* if total items results in more pages than appropriate for a user-friendly Select then utilise a number input instead */}
          {totalPages < 10 ? (
            <FormControl
              componentClass="select"
              disabled={false}
              name="page-number"
              onChange={onPageChange}
              value={pageToDownload}
            >
              {Array.from(Array(Math.min(totalPages, MAX_SELECT_ITEMS)), (e, i) => {
                return (
                  <option value={i + 1} key={`page${i}`}>
                    {i === Math.min(totalPages, MAX_SELECT_ITEMS) - 1
                      ? `Page ${i + 1}: ${A_VERY_LARGE_NUMBER * i} - ${total}`
                      : `Page ${i + 1}: ${A_VERY_LARGE_NUMBER * i} - ${A_VERY_LARGE_NUMBER * (i + 1) - 1}`}
                  </option>
                )
              })}
            </FormControl>
          ) : (
            <FormControl
              disabled={false}
              onChange={onPageChange}
              name="pageNumberInput"
              type="text"
              value={pageToDownload}
            />
          )}
        </div>
      )}
      <p>
        There will be {itemsInFile} item{total !== 1 ? 's' : ''} in the file.
      </p>
    </>
  )
}

export default DownloadCSVModalContent
