import { useEffect } from 'react'

import usePrevValue from 'syft-acp-util/hooks/usePrevValue'

/**
 * Reset default filters when new records are assigned
 * NOTE: This would be a lot cleaner if we had access to redux hooks
 */
const useResetFilterAssignees = (
  setFilter: (key: string, value: string) => void,
  tsLastAssigned: number | null,
  userID: string
) => {
  const prevTsLastAssigned = usePrevValue(tsLastAssigned)
  useEffect(() => {
    if (tsLastAssigned && prevTsLastAssigned !== tsLastAssigned) {
      setFilter('outcome_reached', 'false')
      setFilter('assigned', 'true')
      setFilter('assignee_id', userID)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tsLastAssigned, prevTsLastAssigned])
}

export default useResetFilterAssignees
