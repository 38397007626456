import * as structureTypes from 'syft-acp-core/components/EditableTable'
import {
  WorkerReferralBonusEntity,
  WorkerReferralBonusSources,
} from 'syft-acp-core/store/workerReferralBonuses/types'
import { getReferralRole, getSource } from './WorkerReferralBonus.utils'

type Option = { label: string | number; id: number | string }

export const getFormFields = (
  source: WorkerReferralBonusSources,
  missingPayOptions: Option[],
  referralClaims: Option[],
  readOnly?: boolean,
) => {
  const editable = !readOnly

  const shiftFields =
    source === 'shift'
      ? [
          [
            'source_id',
            {
              header: 'Shift ID *',
              editable,
              type: structureTypes.TYPE_TXT,
              parameters: {
                ariaLabel: 'Shift ID',
              },
            },
          ],
        ]
      : []

  const missingPayFields =
    source === 'missing_pay'
      ? [
          [
            'source_id',
            {
              header: 'Missing Pay ID *',
              editable,
              type: structureTypes.TYPE_DROPDOWN,
              options: missingPayOptions,
              parameters: {
                ariaLabel: 'Missing Pay ID',
              },
            },
          ],
        ]
      : []

  const readyOnlyFields = readOnly
    ? [
        [
          'employer_name',
          {
            header: 'Employer',
            editable: false,
            type: structureTypes.TYPE_TXT,
          },
        ],
        [
          'venue_name',
          {
            header: 'Venue',
            editable: false,
            type: structureTypes.TYPE_TXT,
          },
        ],
        [
          'date_of_work',
          {
            header: 'Date of Work',
            editable: false,
            type: structureTypes.TYPE_DATE,
          },
        ],
      ]
    : []

  const fields = source
    ? [
        ...shiftFields,
        ...missingPayFields,
        ...readyOnlyFields,
        [
          'bonus_amount',
          {
            header: 'Amount *',
            editable,
            type: structureTypes.TYPE_RATE,
            allowEmpty: true,
            parameters: {
              ariaLabel: 'Amount',
            },
          },
        ],
        [
          'referral_claim_id',
          {
            header: 'Referral claim *',
            editable,
            type: structureTypes.TYPE_DROPDOWN,
            options: referralClaims,
            parameters: {
              ariaLabel: 'Referral claim',
            },
          },
        ],
        [
          'notes',
          {
            header: 'Additional notes',
            editable,
            type: structureTypes.TYPE_TEXTAREA,
            parameters: {
              ariaLabel: 'Additional notes',
            },
          },
        ],
      ]
    : []

  return [
    [
      'source',
      {
        header: 'Linked to *',
        editable,
        type: structureTypes.TYPE_DROPDOWN,
        options: [
          { id: 'shift', label: 'Shift' },
          { id: 'missing_pay', label: 'Missing Pay' },
        ],
        parameters: {
          ariaLabel: 'Linked to',
          convertIds: false,
        },
      },
    ],
    ...fields,
  ]
}

export const validate = (data: WorkerReferralBonusEntity) => {
  const errors = []

  if (!data.source) errors.push('Linked to selection is required')
  if (!data.source_id && data.source) {
    if (data.source === 'missing_pay') errors.push('Missing pay ID is required')
    if (data.source === 'shift') errors.push('Shift ID is required')
  }
  if (!data.bonus_amount) errors.push('Amount is required')
  if (!data.referral_claim_id) errors.push('Referral claim is required')

  return errors
}

export const formatData = (data: WorkerReferralBonusEntity, referralClaims: Option[]) => {
  const claim = referralClaims.find(({ id }) => id === data.referral_claim_id)

  return {
    ...data,
    referral_role: getReferralRole(data.referral_role),
    source: getSource(data.source),
    referral_claim_id: claim ? claim.label : data.referral_claim_id,
  }
}
