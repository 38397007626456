import { TrackingProvider } from '@indeed/flex-tracking-context'
import { Alert, AlertLayout, AlertProps, AlertTitle, useResponsiveValue } from '@indeed/ifl-components'
import React, { useEffect, useMemo, useState } from 'react'
import { ButtonLink } from 'syft-acp-atoms/Button'
import { connect } from 'react-redux'

import { useTrackingTrigger, TrackingTrigger } from '@indeed/flex-tracking-context'
import { trackingEvents } from './DuplicateAccountsAlert.tracking'
import { DuplicateAccountsAlertProps } from './DuplicateAccountsAlert.types'
import { bindActionCreators } from 'redux'
import {
  selectCurrentWorkerDuplicateAccountsCountVerified,
  selectCurrentWorkerDuplicateAccountsCountBanned,
  selectCurrentWorkerDuplicateAccountsCountUnverified,
} from 'syft-acp-core/store/worker-duplicate-accounts-count/selectors'
import { dismissAlert } from 'syft-acp-core/store/dismissible-alerts/actions'
import { selectIsAlertDismissed } from 'syft-acp-core/store/dismissible-alerts/selectors'
import { latestDateAsIsoString } from 'syft-acp-util/time'
import { getDuplicateAccountsAlertMessage } from './DuplicateAccountsAlert.utils'

export const alertIdPrefix = `duplicate-accounts`

export const storeConnector = connect(
  (state, { workerId }: { workerId: number }) => ({
    numVerified: selectCurrentWorkerDuplicateAccountsCountVerified(state, workerId),
    numBanned: selectCurrentWorkerDuplicateAccountsCountBanned(state, workerId),
    numUnverified: selectCurrentWorkerDuplicateAccountsCountUnverified(state, workerId),
    hasBeenDismissed: selectIsAlertDismissed(state, `${alertIdPrefix}-${workerId}`),
  }),
  dispatch => ({
    actions: bindActionCreators({ dismissAlert }, dispatch),
  }),
)

const DuplicateAccountsAlert = ({
  workerId,
  actions,
  numVerified,
  numBanned,
  numUnverified,
  hasBeenDismissed,
}: DuplicateAccountsAlertProps) => {
  const trigger = useTrackingTrigger()
  const layout = useResponsiveValue<AlertLayout>(['stack', 'stack', 'stack', 'inline'])
  const [isVisible, setIsVisible] = useState(true)

  const showBanner = numVerified > 0 || numBanned > 0

  useEffect(() => {
    setIsVisible(showBanner)
  }, [showBanner])

  const eventPayload = useMemo(
    () => ({ verified: numVerified, banned: numBanned, unverified: numUnverified }),
    [numBanned, numVerified, numUnverified],
  )

  const dismiss = () => {
    trigger(trackingEvents.DISMISS, eventPayload)
    setIsVisible(false)
    actions.dismissAlert(`${alertIdPrefix}-${workerId}`, latestDateAsIsoString())
  }

  const buttonActions = useMemo<AlertProps['actions']>(() => {
    return (
      <ButtonLink
        kind="primary"
        onClick={() => trigger(trackingEvents.MANAGE_ACCOUNTS, eventPayload)}
        key="manage-duplicates"
        to={`/o/a/entity/workers/view/${workerId}/duplicate-accounts/list`}
      >
        Manage duplicates
      </ButtonLink>
    )
  }, [workerId, trigger, eventPayload])

  if (!isVisible || hasBeenDismissed) {
    return null
  }

  return (
    <TrackingProvider value={{ context: { worker_id: workerId } }}>
      <Alert
        status={numBanned > 1 || numVerified > 1 ? 'critical' : 'warning'}
        actions={buttonActions}
        onDismiss={dismiss}
        layout={layout}
      >
        <AlertTitle>Duplicate accounts found</AlertTitle>
        {getDuplicateAccountsAlertMessage(eventPayload)}
      </Alert>
      <TrackingTrigger onLoad={{ event: trackingEvents.VIEWED, payload: eventPayload }} />
    </TrackingProvider>
  )
}

export default storeConnector(DuplicateAccountsAlert)
