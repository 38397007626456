// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import React from 'react'
import { flowRight, sortBy, values, reduce, isEmpty, isEqual } from 'lodash-es'
import { connect, ConnectedProps } from 'react-redux'
import { createSelector } from 'reselect'
import { bindActionCreators } from 'redux'

import { fetchEmployers } from 'syft-acp-core/store/employers/actions'
import { EmployerEntity } from 'syft-acp-core/store/employers/types'

import { withTrackingTriggerHoc } from 'syft-acp-util/withTrackingTriggerHoc'
import { WithTrackingTriggerHocProps } from 'syft-acp-util/withTrackingTriggerHoc.types'
import * as managersActions from 'syft-acp-core/actions/managers'
import CountrySpecific from 'syft-acp-core/components/CountrySpecific'
import { SUPPORTED_COUNTRY_CODES } from 'syft-acp-core/lib/i18n'
import { entityList$ } from 'syft-acp-core/reducers/generators/entities'
import { showModal } from 'syft-acp-core/store/modals/actions'
import DownloadCSVModal from 'syft-acp-core/components/Modal/DownloadCSVModal'

import BaseIcon from 'syft-acp-atoms/Icons/base/BaseIcon'

import { getVenueCity } from 'syft-acp-util/cities'
import { FilterRating, FilterAutocompleteVenuesCities } from 'syft-acp-util/components/FilterForm'
import { PRIORITIES, getPriorityLabel } from 'syft-acp-util/components/FilterForm/FilterTiers'
import { createEntityListConnector } from 'syft-acp-util/entityList'
import FilterSelectCities from 'syft-acp-util/components/FilterForm/FilterSelectCities'
import Acp from 'syft-acp-uikit'

import { trackingEvents } from './EmployerList.tracking'

const entityConnector = createEntityListConnector<EmployerEntity>({
  entityActions: {
    fetchEntities: fetchEmployers,
  },
  entityStore: 'employers',
})

type EntityConnectProps = ConnectedProps<typeof entityConnector>

type StateToProps = {
  cities: Record<string, any>
  industries: Record<string, any>
  managers: Record<string, any>
}

type DispatchProps = {
  actions: EntityConnectProps['actions'] & typeof managersActions & { showModal: typeof showModal }
}

// TODO global store state
type StoreState = {
  managers: Record<string, any>
  cities: Record<string, any>
  industries: Record<string, any>
}

type SelectedManagerRoles = { [key: string]: string[]; no_roles: string[] }

const storeConnector = connect<StateToProps, DispatchProps, EntityConnectProps, StoreState>(
  () => {
    // recompute only if managers changed to omit useless rerender
    const selectManagers = createSelector<StoreState, StoreState['managers'], SelectedManagerRoles>(
      [state => state.managers],
      managers =>
        reduce(
          entityList$(managers, {}),
          (map, manager) => {
            if (manager?.manager_roles?.length) {
              manager.manager_roles.forEach((role: string) => {
                if (!map[role]) {
                  map[role] = []
                }

                map[role].push(manager)
              })
            } else {
              if (!map.no_roles) {
                map.no_roles = []
              }
              map.no_roles.push(manager)
            }

            return map
          },
          {} as SelectedManagerRoles
        )
    )
    return state => ({
      cities: state.cities.entityMap,
      industries: state.industries.entityMap,
      managers: selectManagers(state),
    })
  },
  (dispatch, { actions }) => ({
    actions: {
      ...actions,
      ...bindActionCreators({ ...managersActions, showModal }, dispatch),
    },
  })
)

type ConnectProps = EntityConnectProps & ConnectedProps<typeof storeConnector> & WithTrackingTriggerHocProps

type State = {
  isCSVLoading: boolean
}

class EmployersList extends React.PureComponent<ConnectProps, State> {
  readonly state = {
    isCSVLoading: false,
  }

  readonly modalName = 'downloadEmployersCSV'

  componentDidMount() {
    this.props.actions.fetchEntities && this.props.actions.fetchEntities({ options: this.props.query })

    if (isEmpty(this.props.managers)) {
      this.props.actions.fetchAdminManagers({ options: {} })
    }
  }

  componentDidUpdate(prevProps: Readonly<ConnectProps>) {
    if (!isEqual(prevProps.query, this.props.query)) {
      this.props.actions.fetchEntities && this.props.actions.fetchEntities({ options: this.props.query })
    }
  }

  getCitiesFilterOptions = () => values(sortBy(this.props.cities, ['order_number', 'name']))
  getIndustriesFilterOptions = () => values(this.props.industries)
  getAccountManagersFilterOptions = () => this.props.managers.account_manager || []
  getSalesManagersFilterOptions = () => this.props.managers.sales_manager || []
  generateRowLink = ({ id }: EmployerEntity) => `/entity/employers/view/${id}`
  formatCity = (e: EmployerEntity) =>
    !e.city_id ? '-' : this.props.cities[e.city_id]?.name || `${e.city_id} (unknown city)`
  formatPriority = (e: EmployerEntity) => getPriorityLabel(e.tier)

  showDownloadCSVModal = () => {
    this.props.actions.showModal(this.modalName, {
      onConfirm: (pageNum: number) => this.downloadCsv(pageNum),
      query: this.props.query,
      total: this.props.pagination.total,
      hasFilters: true,
    })
  }

  downloadCsv = (pageNum = 1) => {
    const { actions, triggerEvent } = this.props
    actions.fetchEntities &&
      actions.fetchEntities(
        {
          options: {
            page: pageNum,
          },
        },
        this.props,
        true
      )
    triggerEvent(trackingEvents.DOWNLOAD_CSV_BUTTON.CLICKED)
  }

  renderDropdownOption = ({ id, name }: { id: number; name: string }) => (
    <Acp.Option slug={id} key={`${name}-${id}`}>
      {name}
    </Acp.Option>
  )

  render() {
    const { data, isLoadingData } = this.props
    return (
      <CountrySpecific countries={[SUPPORTED_COUNTRY_CODES.US]}>
        {(isUS: boolean) => (
          <>
            <Acp.EntityList data={data} isLoading={isLoadingData} inContainer hasResultCount hasPagination>
              <Acp.Actions>
                <Acp.Button
                  disabled={isLoadingData || !this.props.pagination || this.props.pagination.total === 0}
                  kind="primary"
                  onClick={this.showDownloadCSVModal}
                >
                  Download CSV
                </Acp.Button>

                <Acp.FilterGroup title="ID/company/brand name">
                  <Acp.Filter.Text name="query" placeholder="Query" />
                </Acp.FilterGroup>

                {isUS ? (
                  <Acp.FilterGroup title="Venue city">
                    <FilterAutocompleteVenuesCities name="venue_city" />
                  </Acp.FilterGroup>
                ) : (
                  <Acp.FilterGroup title="City">
                    <Acp.Filter.Dropdown name="city_id" placeholder="Any">
                      {this.getCitiesFilterOptions().map(({ id, name }) => (
                        <Acp.Option slug={id} key={`${name}-${id}`}>
                          {name}
                        </Acp.Option>
                      ))}
                    </Acp.Filter.Dropdown>
                  </Acp.FilterGroup>
                )}
                {isUS && (
                  <Acp.FilterGroup title="Region">
                    <FilterSelectCities name="city_id" allowAny />
                  </Acp.FilterGroup>
                )}
                <Acp.FilterGroup title="Last active date">
                  <Acp.Filter.DateRange nameFrom="last_active_gte" nameTo="last_active_lte" />
                </Acp.FilterGroup>

                <Acp.FilterGroup title="Priority">
                  <Acp.Filter.Dropdown placeholder="All" name="tier">
                    {PRIORITIES.map(priority => (
                      <Acp.Option key={priority.id} slug={priority.id}>
                        {priority.label}
                      </Acp.Option>
                    ))}
                  </Acp.Filter.Dropdown>
                </Acp.FilterGroup>

                <Acp.FilterGroup title="Other filters">
                  <Acp.Filter.Switch name="active_jobs_count_gte" value="1">
                    With active jobs
                  </Acp.Filter.Switch>
                  <Acp.Filter.Switch name="verified">Verified</Acp.Filter.Switch>
                  <Acp.Filter.Switch name="requires_preauth">Requires pre-auth</Acp.Filter.Switch>
                  <Acp.Filter.Switch name="converted">Converted</Acp.Filter.Switch>
                </Acp.FilterGroup>

                <Acp.FilterGroup title="Industry">
                  <Acp.Filter.Dropdown name="industry_id" placeholder="Any">
                    {this.getIndustriesFilterOptions().map(({ id, code, title }) => (
                      <Acp.Option slug={id} key={code}>
                        {title}
                      </Acp.Option>
                    ))}
                  </Acp.Filter.Dropdown>
                </Acp.FilterGroup>

                <Acp.FilterGroup title="Account manager">
                  <Acp.Filter.Dropdown name="account_manager_id" placeholder="Any">
                    {this.getAccountManagersFilterOptions().map(this.renderDropdownOption)}
                  </Acp.Filter.Dropdown>
                </Acp.FilterGroup>

                <Acp.FilterGroup title="Sales manager">
                  <Acp.Filter.Dropdown name="sales_manager_id" placeholder="Any">
                    {this.getSalesManagersFilterOptions().map(this.renderDropdownOption)}
                  </Acp.Filter.Dropdown>
                </Acp.FilterGroup>

                <Acp.FilterGroup title="Sign up date">
                  <Acp.Filter.DateRange nameFrom="created_at_gte" nameTo="created_at_lte" />
                </Acp.FilterGroup>

                <Acp.FilterGroup title="Average rating">
                  <FilterRating nameFrom="rating_gte" nameTo="rating_lte" />
                </Acp.FilterGroup>
              </Acp.Actions>

              <Acp.Table rowLink={this.generateRowLink}>
                <Acp.Col.LegacySelector />
                <Acp.Col.Text value="id" header="ID" isNumeric isMinimal />
                <Acp.Col.Image
                  value="picture"
                  header={<BaseIcon iconSlug="person" iconSize={13} iconAlt="Employer logo" />}
                />
                <Acp.Col.Text value="company_name" header="Company Name" />
                <Acp.Col.Text value="brand_name" header="Brand Name" />
                <Acp.Col.Text value={isUS ? getVenueCity : this.formatCity} header="City" isMinimal />
                <Acp.Col.Text value={this.formatPriority} header="Priority" isMinimal />
                <Acp.Col.Text value="active_jobs_count" header="Active jobs" isMinimal />
                <Acp.Col.DateTime value="last_active_at" header="Last active" isMinimal />
                <Acp.Col.Rating value="rating.average" header="Avg. rating" isMinimal />
                <Acp.Col.Text
                  value="rating.stars.weight"
                  header="R"
                  headerTooltip="Number of ratings"
                  isNumeric
                  headerAbbr
                />
                <Acp.Col.Boolean value="approved_at" header="V" headerAbbr headerTooltip="Verified" />
                <Acp.Col.Boolean value="requires_preauth" header="P" headerAbbr headerTooltip="Requires preauth" />
                <Acp.Col.Boolean value="converted" header="C" headerAbbr headerTooltip="Converted" />
              </Acp.Table>
            </Acp.EntityList>
            <DownloadCSVModal name={this.modalName} />
          </>
        )}
      </CountrySpecific>
    )
  }
}

export default flowRight(entityConnector, storeConnector)(withTrackingTriggerHoc(EmployersList))
