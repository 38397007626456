import { apiRequest, handleCall } from 'syft-acp-core/api/call'
import { UpdateWorkerMarketingPreferencesPayload } from './types'

export const fetchWorkerMarketingPreferences = ({ workerID }: { workerID: number }) =>
  new Promise((resolve, reject) => {
    const req = apiRequest({ path: `/workers/${workerID}/marketing_preferences` })
    handleCall(req, resolve, reject, true)
  })

export const updateWorkerMarketingPreferences = ({
  workerID,
  payload: reqArgs,
}: {
  workerID: number
  payload: UpdateWorkerMarketingPreferencesPayload
}) =>
  new Promise((resolve, reject) => {
    const req = apiRequest({
      path: `/workers/${workerID}/marketing_preferences`,
      reqArgs,
      method: 'PUT',
    })
    handleCall(req, resolve, reject)
  })
