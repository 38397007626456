// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import entityReducer from 'syft-acp-core/reducers/generators/entityReducer'
import * as types from 'syft-acp-core/actions/action-types'
import { get } from 'lodash-es'

// Returns the user ID of the currently logged in user.
const getCurrentUserID = state => get(state, 'auth.userData.id', null)

// Reducer will be initialized to these default settings.
const initialState = {
  darkModeEnabled: false,
  country: null,
  timezone: null,
}

/**
 * Returns a setting for the currently logged in user.
 */
export const getSetting = (storeState, name, userID) =>
  get(storeState, `userSettings.entityDetail[${userID || getCurrentUserID(storeState)}][${name}]`, null)

/**
 * Sets a value in the user's settings.
 */
const setValue = (state, action) => {
  const userID = action.userID ? action.userID : getCurrentUserID(state)

  // We either merge in the existing data, or a new defaultSettings defined above.
  const existingData = state.entityDetail[userID] ? state.entityDetail[userID] : initialState

  // Merge in a new value if provided. If nothing is provided and the user's settings do not exist yet,
  // we will just merge in the default values.
  // Multiple settings can be passed through action.settings.
  const newValue = action.settings ? action.settings : action.name ? { [action.name]: action.value } : {}

  return {
    ...state,
    entityDetail: {
      ...state.entityDetail,
      [userID]: {
        ...existingData,
        ...newValue,
      },
    },
  }
}

export default entityReducer(
  'userSettings',
  {
    [types.USER_SETTINGS_SET_VALUE]: setValue,
    [types.USER_SETTINGS_SET_VALUES]: setValue,
    // Same as setting a value, but without a name/value pair in the action object.
    [types.USER_SETTINGS_SET_DEFAULTS]: setValue,
  },
  {
    initialState: {
      [getCurrentUserID]: initialState,
    },
  }
)
