import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { isEmpty, isNull } from 'lodash-es'
import { useIntl } from 'react-intl'
import FieldUploadWrapper from 'syft-acp-core/components/EditableTable/FieldUploadWrapper'
import CountrySpecific from 'syft-acp-core/components/CountrySpecific'
import { TYPE_RATING, TYPE_SEPARATOR } from './types'
import { Text } from '@indeed/ifl-components'

export const HeaderGroup = ({ header, translationKey, isRequired }) => {
  const intl = useIntl()
  return (
    <Fragment>
      <th className="pre-icon">
        {header || header === '' ? header : intl.formatMessage({ id: translationKey })}
        {isRequired && <Text sx={{ color: 'alert.800' }}>*</Text>}
      </th>
      <td className="icon" />
    </Fragment>
  )
}

export const getColSpan = ({ hasUpload, rowOptions, marginRight }) => {
  return hasUpload || marginRight
    ? { ...(rowOptions?.colSpan && { colSpan: rowOptions?.colSpan }) }
    : { colSpan: rowOptions?.colSpan || 2 }
}

export const NodeCell = ({ colSpan, type, node }) => {
  return (
    <td {...colSpan} className={`field ${type === TYPE_RATING ? 'middle' : ''}`}>
      {node}
    </td>
  )
}

const EditableTableRow = ({ row, fieldData }) => {
  const {
    header,
    isRequired,
    node,
    type,
    hideIfEmpty,
    showForCountries,
    hideForCountries,
    hasUpload,
    marginRight,
    translationKey,
    rowOptions,
    hide,
  } = row

  if ((hideIfEmpty && !node.props.value) || (hide && hide(node.props.data))) {
    return null
  }
  if (type === TYPE_SEPARATOR) {
    return (
      <tr className="separator">
        <td colSpan={row.colspan || 3}>
          <div className="line" />
        </td>
      </tr>
    )
  }
  const countries = showForCountries || hideForCountries
  const excluded = !isEmpty(hideForCountries)
  const tableRow = (
    <tr>
      {!isNull(header) && (
        <HeaderGroup header={header} translationKey={translationKey} isRequired={isRequired} />
      )}
      <NodeCell colSpan={getColSpan({ hasUpload, rowOptions, marginRight })} type={type} node={node} />
      {marginRight ? <td className="right-filler" /> : null}
      {hasUpload ? (
        <td className="right-uploader">
          <FieldUploadWrapper fieldData={fieldData} rowData={row} />
        </td>
      ) : null}
    </tr>
  )

  return (
    (!isEmpty(countries) && (
      <CountrySpecific countries={countries} excluded={excluded}>
        {tableRow}
      </CountrySpecific>
    )) ||
    tableRow
  )
}

EditableTableRow.propTypes = {
  row: PropTypes.object.isRequired,
  fieldData: PropTypes.object,
}

EditableTableRow.defaultProps = {
  fieldData: null,
}

export default EditableTableRow
