import React from 'react'
import PropTypes from 'prop-types'
import { get } from 'lodash-es'

import { showModal } from 'syft-acp-core/lib/modals'
import DataBoolean from 'syft-acp-core/components/EditableTable/DataBoolean'
import EntityListInputWrapper from 'syft-acp-core/components/EntityList/EntityListInputWrapper'

export const ListingFlexCompCheckbox = ({ updateCheckbox, shiftBooking, disabled = false }) => {
  const checked = get(shiftBooking, 'syft_compensation')
  const onChange = async val => {
    const { id, shiftID } = shiftBooking
    updateCheckbox({ shiftID, id, attr: 'syft_compensation', val })

    if (!val) return
    const syftCompReasonData = await showModal('addFlexCompReasonModal', { shiftBooking })
    if (!get(syftCompReasonData, 'syftCompReason', false)) {
      updateCheckbox({ shiftID, id, attr: 'syft_compensation', val: false })
    }
    const { syftCompReason, approver, otherReason } = syftCompReasonData
    if (otherReason) {
      updateCheckbox({ shiftID, id, attr: 'syft_compensation_reason', val: 'Other: ' + otherReason })
    } else {
      updateCheckbox({ shiftID, id, attr: 'syft_compensation_reason', val: syftCompReason })
    }
    updateCheckbox({ shiftID, id, attr: 'syft_compensation_approver', val: approver })
  }

  return (
    <EntityListInputWrapper>
      <DataBoolean onChange={onChange} checked={checked} editable={!disabled} />
    </EntityListInputWrapper>
  )
}

ListingFlexCompCheckbox.propTypes = {
  updateCheckbox: PropTypes.func.isRequired,
  shiftBooking: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
}

ListingFlexCompCheckbox.defaultProps = {
  disabled: false,
}

export default ListingFlexCompCheckbox
