// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import { takeLatest } from 'redux-saga/effects'

import * as api from 'syft-acp-core/api/resources/admin/employer-ratecards'
import * as types from 'syft-acp-core/actions/action-types'
import { takeLatestApiCall } from './helpers'
import { notificationCall } from './calls'

export default function* root() {
  yield takeLatestApiCall('EMPLOYER_RATECARDS_FETCH', api.listEmployerRatecards)
  yield takeLatestApiCall('EMPLOYER_RATECARD_SAVE', api.saveRatecard)
  yield takeLatestApiCall('EMPLOYER_RATECARD_UPDATE', api.updateRatecard)
  yield takeLatest(
    [
      types.EMPLOYER_RATECARD_SAVE_SUCCEEDED,
      types.EMPLOYER_RATECARD_SAVE_FAILED,
      types.EMPLOYER_RATECARD_UPDATE_SUCCEEDED,
      types.EMPLOYER_RATECARD_UPDATE_FAILED,
    ],
    notificationCall
  )
}
