import { SetFilterHelperProps } from './types'

export const setFilterValue = ({
  name,
  filterValue,
  type,
  min,
  max,
  minLength,
  setValue,
  debouncedSetFilter,
}: SetFilterHelperProps) => {
  if ((min !== null || max !== null) && type === 'number') {
    // @ts-ignore: Object is possibly 'null'.
    if (Number(filterValue) < min) {
      setValue(min)
      debouncedSetFilter(name, min)
      // @ts-ignore: Object is possibly 'null'.
    } else if (Number(filterValue) > max) {
      setValue(max)
      debouncedSetFilter(name, max)
    } else {
      setValue(filterValue)
      debouncedSetFilter(name, filterValue)
    }
  } else {
    if (
      typeof filterValue === 'string' &&
      minLength &&
      filterValue.trim().length !== 0 &&
      filterValue.trim().length < minLength
    ) {
      setValue(filterValue)
    } else {
      setValue(filterValue)
      debouncedSetFilter(name, filterValue)
    }
  }
}
