import React, { useCallback, useEffect } from 'react'
import deepEqual from 'react-fast-compare'
import { capitalize } from 'lodash-es'

import Acp from 'syft-acp-uikit'
import { navigateListing } from 'syft-acp-core/entities/ListingDetail/ListingShiftTabs/navigate'
import { fetchWorkerStrikes } from 'syft-acp-core/store/workerStrikes/actions'
import { getAppealDetailPath } from 'syft-acp-core/store/appeals/helpers'
import { WorkerStrikeEntity } from 'syft-acp-core/store/types'
import { createEntityListConnector } from 'syft-acp-util/entityList'
import usePrevValue from 'syft-acp-util/hooks/usePrevValue'
import iconFactory from 'syft-acp-util/iconFactory'

import { WorkerStrikesListProps as Props } from './WorkerStrikesList.types'
import './WorkerStrikesList.scss'

export const entityConnector = createEntityListConnector<WorkerStrikeEntity>({
  entityActions: {
    fetchEntities: ({ options }: Record<string, any>, { workerID }: { workerID: number }) =>
      fetchWorkerStrikes(workerID, options),
  },
  entityStore: 'workerStrikes',
})

const navigateToListing = ({ rowData }: { rowData: WorkerStrikeEntity }) => {
  if (rowData?.shift) {
    navigateListing({
      shiftID: rowData.shift.shift_id,
      jobID: rowData.shift.job_id,
      listingID: rowData.shift.listing_id,
    })
  }
}

const isButtonDisabled = ({ rowData }: { rowData: WorkerStrikeEntity }) => !rowData.shift

const HeaderIcon = iconFactory('link-external')

export const selectStrikeStatus = ({ activated_at, deactivated_at }: WorkerStrikeEntity) =>
  activated_at && !deactivated_at ? 'Active' : 'Deactivated'

const selectAppealStatus = ({ appeal }: WorkerStrikeEntity) => {
  if (!appeal) return null
  return appeal.outcome ? capitalize(appeal.outcome) : 'Open'
}

const getDirectBan = (rowData: WorkerStrikeEntity) => (rowData.reason === 'gross_misconduct' ? 'Yes' : 'No')

const WorkerStrikesList = ({
  actions,
  data,
  isLoadingData,
  query,
  workerID,
  highlightID,
  isAppealsView = false,
  isConductReviewsView = false,
}: Props) => {
  const prevQuery = usePrevValue(query)
  const prevWorkerID = usePrevValue(workerID)
  const fetch = useCallback(
    (q: Record<string, any>) =>
      actions.fetchEntities && workerID && actions.fetchEntities({ options: q }, { workerID }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [workerID]
  )

  useEffect(() => {
    if (!deepEqual(prevQuery, query)) fetch(query)
  }, [fetch, prevQuery, query])

  useEffect(() => {
    // This is for an edge case in detail pages where workerID is initially undefined
    if (prevWorkerID !== workerID) fetch(query)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prevWorkerID, workerID])

  const getRowColour = ({ id }: WorkerStrikeEntity) => (id === highlightID ? 'red' : undefined)

  const getRowLink = ({ id }: WorkerStrikeEntity) => `/entity/workers/view/${workerID}/strikes/view/${id}`

  const getAppealLink = ({ id, appeal }: WorkerStrikeEntity) =>
    appeal ? getAppealDetailPath(id) : `/conduct/appeals/new?workerID=${workerID}&strikeID=${id}`

  if (isAppealsView)
    return (
      <Acp.EntityList data={data} isLoading={isLoadingData} idKeyValue="id" isIntegrated={true} hasPagination>
        <Acp.Table rowLink={getRowLink} rowColor={getRowColour}>
          <Acp.Col.DateTime value="activated_at" header="Strike date" align="left" isMinimal />
          <Acp.Col.Text value="strike_reason.display_name" header="Strike reason" align="left" isMinimal />
          <Acp.Col.Text value={selectStrikeStatus} header="Strike status" align="left" isMinimal />
          <Acp.Col.Text
            value={selectAppealStatus}
            header="Appeal status"
            align="left"
            colLink={getAppealLink}
            isMinimal
          />
          <Acp.Col.Text value="activator.email" header="Activated by" align="left" isMinimal />
          <Acp.Col.Text value="appeal.assignee.full_name" header="Reviewed by" align="left" isMinimal />
        </Acp.Table>
      </Acp.EntityList>
    )

  if (isConductReviewsView)
    return (
      <Acp.EntityList data={data} isLoading={isLoadingData} idKeyValue="id" isIntegrated={true} hasPagination>
        <Acp.Table rowLink={getRowLink} rowColor={getRowColour}>
          <Acp.Col.DateTime value="activated_at" header="Strike date" align="left" isMinimal />
          <Acp.Col.Text value="strike_reason.display_name" header="Strike reason" align="left" isMinimal />
          <Acp.Col.Text value={getDirectBan} header="Direct ban" align="left" isMinimal />
          <Acp.Col.Text value={selectStrikeStatus} header="Strike status" align="left" isMinimal />
          <Acp.Col.DateTime
            value="shift.start_time"
            header="Shift date"
            align="left"
            isMinimal
            options={{
              hour: false,
              minute: false,
              second: false,
              timeZoneName: false,
            }}
          />
          <Acp.Col.Text value="activator.email" header="Activated by" align="left" isMinimal />
        </Acp.Table>
      </Acp.EntityList>
    )

  return (
    <Acp.EntityList data={data} isLoading={isLoadingData} idKeyValue="id">
      <Acp.Table rowLink={getRowLink}>
        <Acp.Col.Text value="id" header="ID" align="left" isMinimal isNumeric />
        <Acp.Col.Text value="shift_id" header="Shift ID" align="left" isMinimal isNumeric />
        <Acp.Col.Text value="shift.job_id" header="Job ID" align="left" isMinimal isNumeric />
        <Acp.Col.Text value="shift.listing_id" header="Listing ID" align="left" isMinimal isNumeric />
        <Acp.Col.Text value="shift.role_name" header="Role" align="left" isMinimal />
        <Acp.Col.Text value="shift.employer_name" header="Employer" align="left" isMinimal />
        <Acp.Col.Text value="reason" header="Reason" align="left" isMinimal />
        <Acp.Col.Text value="worker_rejection_reason.code" header="Rejection reason" align="left" isMinimal />
        <Acp.Col.Text value="internal_notes" header="Internal notes" align="left" isMinimal />
        <Acp.Col.DateTime value="email_sent_at" header="Email sent at" align="left" isMinimal />
        <Acp.Col.DateTime value="activated_at" header="Activated at" align="left" isMinimal />
        <Acp.Col.Text value="activator.email" header="Activated by" align="left" isMinimal />
        <Acp.Col.DateTime value="deactivated_at" header="Deactivated at" align="left" isMinimal />
        <Acp.Col.Text value={selectAppealStatus} header="Appeal" align="left" colLink={getAppealLink} isMinimal />
        <Acp.Col.Button header={<HeaderIcon className="worker-strikes-list__header-icon" />} align="center" isMinimal>
          <Acp.Button onClick={navigateToListing} disabled={isButtonDisabled}>
            View listing
          </Acp.Button>
        </Acp.Col.Button>
      </Acp.Table>
    </Acp.EntityList>
  )
}

export default entityConnector(WorkerStrikesList)
