import {
  getDateRangeShift,
  checkDateQueryMatchShift,
  getDateRangeShiftCurrentTime,
} from 'syft-acp-core/lib/shifts'
import { FilterTypes } from './types'

export const filterFunctions = ({ timezone, fromUK, toUK, fromUS, toUS, currentTime }: FilterTypes) => {
  return timezone === 'Europe/London'
    ? {
        filterFunction: () =>
          currentTime ? getDateRangeShiftCurrentTime(fromUK, toUK) : getDateRangeShift(fromUK, toUK),
        activeFunction: checkDateQueryMatchShift(fromUK, toUK),
      }
    : {
        filterFunction: () =>
          currentTime ? getDateRangeShiftCurrentTime(fromUS, toUS) : getDateRangeShift(fromUS, toUS),
        activeFunction: checkDateQueryMatchShift(fromUS, toUS),
      }
}
