import React, { useMemo, useState, useEffect } from 'react'
import { flowRight } from 'lodash-es'
import { bindActionCreators } from 'redux'
import { connect, ConnectedProps } from 'react-redux'
import { useTrackingTrigger, TrackingTrigger } from '@indeed/flex-tracking-context'

import NotifyBar from 'syft-acp-atoms/NotifyBar'
import { entityDetailHOC } from 'syft-acp-util/entityDetail'
import { Modal } from 'syft-acp-core/components/Modal'
import RecordSection from 'syft-acp-core/components/RecordSection'
import { GetEntityDetailHocProps } from 'types/utils'
import { saveWorkerReferralBonus } from 'syft-acp-core/store/workerReferralBonuses/actions'
import { fetchWorkerReferralClaims } from 'syft-acp-core/store/workerReferralClaims/actions'
import { fetchMissingPay } from 'syft-acp-core/store/worker-missing-pay/actions'
import { ActionUpdate } from 'syft-acp-util/entityDetail/entityDetail.types'
import { GenericState } from 'syft-acp-core/reducers/generators/entityReducer'
import { WorkerReferralBonusEntity } from 'syft-acp-core/store/workerReferralBonuses/types'
import { selectWorkerMissingPayOptions } from 'syft-acp-core/store/worker-missing-pay/selectors'
import { selectWorkerReferralClaimOptions } from 'syft-acp-core/store/workerReferralClaims/selectors'
import LoadingSpinner from 'syft-acp-atoms/LoadingSpinner'

import { getFormFields, validate, formatData } from './CreateReferralBonus.utils'
import { trackingEvents } from './WorkerReferralBonuses.tracking'

type StoreProps = ConnectedProps<typeof storeConnector>
type EntityDetailProps = GetEntityDetailHocProps<typeof entityDetailConnector>

type CreateWorkerReferralBonusOwnProps = {
  workerId: number
  formErrors: string[]
  data: WorkerReferralBonusEntity
  readOnly: boolean
  actionUpdate: ActionUpdate<GenericState>
}
type CreateWorkerReferralBonusProps = CreateWorkerReferralBonusOwnProps & StoreProps

const CreateWorkerReferralBonus = ({
  data,
  workerId,
  formErrors = [],
  actions,
  missingPay,
  isLoadingMissingPay,
  referralClaims,
  isLoadingReferralClaims,
  readOnly,
  actionUpdate,
}: CreateWorkerReferralBonusProps) => {
  const formattedData = useMemo(
    () => (readOnly ? formatData(data, referralClaims) : data),
    [data, referralClaims, readOnly],
  )
  const formFields = useMemo(
    () => getFormFields(data.source, missingPay, referralClaims, readOnly),
    [data.source, missingPay, referralClaims, readOnly],
  )
  const isLoading = isLoadingMissingPay || isLoadingReferralClaims

  useEffect(() => {
    actions.fetchWorkerReferralClaims(workerId)
    if (!readOnly) actions.fetchMissingPay(workerId, { perPage: 100 })
  }, [actions, workerId, readOnly])

  return (
    <>
      <NotifyBar severity="error" conditional visible={formErrors.length > 0} noMargin>
        <ul>
          {formErrors.map(error => (
            <li key={error}>{error}</li>
          ))}
        </ul>
      </NotifyBar>
      {isLoading ? (
        <div style={{ display: 'flex', justifyContent: 'center', margin: '24px 0' }}>
          <LoadingSpinner isLoading />
        </div>
      ) : (
        <form>
          <RecordSection data={formattedData} update={actionUpdate} structure={formFields} noBottomMargin />
        </form>
      )}
    </>
  )
}

type Props = {
  isOpen: boolean
  workerId: number
  onClose: () => void
} & EntityDetailProps &
  StoreProps

const CreateReferralBonusModal = ({ id, isOpen, workerId, data, actions, onClose, ...rest }: Props) => {
  const readOnly = !!id
  const track = useTrackingTrigger()
  const [formErrors, setFormErrors] = useState<string[]>([])

  const handleConfirm = () => {
    const errors = validate(data)

    if (errors.length > 0) {
      setFormErrors(errors)
      return
    }

    actions.saveWorkerReferralBonus(workerId, data)
    track(trackingEvents.ADD_MODAL.SAVE_CLICKED, { worker_id: workerId })
  }

  return (
    <Modal
      header={`${id ? `View Adhoc Referral Bonus: ${id}` : 'Add Adhoc Referral Bonus'}`}
      cancelText="Cancel"
      confirmationText="Save"
      isShown={isOpen}
      isLoading={false}
      onClose={onClose}
      onConfirm={id ? undefined : handleConfirm}
    >
      <CreateWorkerReferralBonus
        data={data}
        workerId={workerId}
        actions={actions}
        formErrors={formErrors}
        readOnly={readOnly}
        {...rest}
      />
      <TrackingTrigger
        onLoad={{
          event: trackingEvents.ADD_MODAL.VIEWED,
          payload: { worker_id: workerId },
        }}
      />
    </Modal>
  )
}

const entityDetailConnector = entityDetailHOC('workerReferralBonuses')
const storeConnector = connect(
  (state, ownProps: CreateWorkerReferralBonusOwnProps) => ({
    isLoadingMissingPay: state.workerMissingPay?.isLoadingData,
    missingPay: selectWorkerMissingPayOptions(state, ownProps.workerId),
    isLoadingReferralClaims: state.workerReferralClaims?.isLoadingData,
    referralClaims: selectWorkerReferralClaimOptions(state, ownProps.workerId),
  }),
  dispatch => ({
    actions: bindActionCreators(
      {
        saveWorkerReferralBonus,
        fetchWorkerReferralClaims,
        fetchMissingPay,
      },
      dispatch,
    ),
  }),
)
export default flowRight(storeConnector, entityDetailConnector)(CreateReferralBonusModal)
