import * as structureTypes from 'syft-acp-core/components/EditableTable'
import { TimeOffRequestDetailEntity } from 'syft-acp-core/store/types'
import { formatRelative } from 'syft-acp-util/formatting'
import {
  getShiftLink,
  getWorkerLink,
  getEmployerLink,
  getVenueLink,
} from 'syft-acp-core/entities2/TimeOffRequestList/TimeOffRequestList.helpers'

export const dataStructure = (data?: TimeOffRequestDetailEntity) => [
  {
    title: 'Time off request information',
    structure: [
      [
        'id',
        {
          header: 'ID',
        },
      ],
      [
        'status',
        {
          header: 'Status',
        },
      ],
      [
        'reason_category.description',
        {
          header: 'Reason category',
        },
      ],
      [
        'reason.description',
        {
          header: 'Reason',
        },
      ],
      [
        'created_at',
        {
          header: 'Request submitted',
          type: structureTypes.TYPE_DATE,
        },
      ],
      [
        'note',
        {
          header: 'Note',
          editable: data?.status === 'requested',
        },
      ],
      [
        'reviewer.full_name',
        {
          header: 'Reviewed by',
        },
      ],
      [
        'reviewed_at',
        {
          header: 'Reviewed on',
          type: structureTypes.TYPE_DATE,
        },
      ],
    ],
  },
  {
    title: 'Worker information',
    structure: [
      [
        'worker.id',
        {
          header: 'Worker ID',
          type: structureTypes.TYPE_LINK,
          parameters: {
            to: getWorkerLink,
          },
        },
      ],
      [
        'worker.name',
        {
          header: 'Worker name',
        },
      ],
      [
        'worker.email',
        {
          header: 'Worker email',
        },
      ],
    ],
  },
  {
    title: 'Shift',
    structure: [
      [
        'shift.id',
        {
          header: 'Shift ID',
          type: structureTypes.TYPE_LINK,
          parameters: {
            to: getShiftLink,
          },
        },
      ],
      [
        'shift.start_time',
        {
          header: 'Start time',
          type: structureTypes.TYPE_TIMESTAMP,
        },
      ],
      [
        'shift.end_time',
        {
          header: 'End time',
          type: structureTypes.TYPE_TIMESTAMP,
        },
      ],
    ],
  },
  {
    title: 'Employer',
    structure: [
      [
        'employer.id',
        {
          header: 'Employer ID',
          type: structureTypes.TYPE_LINK,
          parameters: {
            to: getEmployerLink,
          },
        },
      ],
      [
        'employer.brand_name',
        {
          header: 'Employer brand name',
        },
      ],
      [
        'employer.company_name',
        {
          header: 'Employer company name',
        },
      ],
    ],
  },
  {
    title: 'Venue',
    structure: [
      [
        'venue.id',
        {
          header: 'Venue ID',
          type: structureTypes.TYPE_LINK,
          parameters: {
            to: getVenueLink,
          },
        },
      ],
      [
        'venue.name',
        {
          header: 'Venue name',
        },
      ],
    ],
  },
  {
    title: 'Worker stats',
    structure: [
      [
        'worker_stats.shifts_completed_across_all_clients_count',
        {
          header: 'Shifts completed across all clients',
        },
      ],
      [
        'worker_stats.shifts_completed_for_current_client_count',
        {
          header: 'Shifts completed for current client',
        },
      ],
      [
        'worker_stats.current_job_completed_shifts_count',
        {
          header: 'Shifts completed for current assignment',
        },
      ],
      [
        'worker_stats.current_job_first_completed_shift_completed_at',
        {
          header: 'Time on this assignment since first completed shift',
          getData: ({ worker_stats }: Partial<TimeOffRequestDetailEntity>) => {
            const date = worker_stats?.current_job_first_completed_shift_completed_at
            return !!date ? formatRelative(new Date(date)) : 'N/A'
          },
        },
      ],
      [
        'worker_stats.time_off_requests_count',
        {
          header: 'Total time off requests for this assignment',
        },
      ],
      [
        'worker_stats.accepted_time_off_requests_count',
        {
          header: 'Accepted time off requests for this assignment',
        },
      ],
      [
        'worker_stats.rejected_time_off_requests_count',
        {
          header: 'Rejected time off requests for this assignment',
        },
      ],
      [
        'worker_stats.active_strikes_count',
        {
          header: 'Total active strikes',
        },
      ],
    ],
  },
]
