import PropTypes from 'prop-types'

/** Prop types for column components. */
export const colPropTypes = {
  align: PropTypes.oneOf(['left', 'right', 'center', 'justify']),
  header: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  headerAbbr: PropTypes.bool,
  headerTooltip: PropTypes.string,
  headerWrapping: PropTypes.bool,
  isEditable: PropTypes.bool,
  isMain: PropTypes.bool,
  isMinimal: PropTypes.bool,
  isNumeric: PropTypes.bool,
  isTags: PropTypes.bool,
  options: PropTypes.object,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.func, PropTypes.array]),
  valueTooltip: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  withTooltip: PropTypes.bool,
}

/** Default props for column components. */
export const colDefaultProps = {
  align: null,
  header: null,
  headerAbbr: false,
  headerTooltip: null,
  headerWrapping: false,
  isEditable: false,
  isMain: false,
  isMinimal: false,
  isNumeric: false,
  isTags: false,
  options: {},
  value: null,
  valueTooltip: null,
  withTooltip: false,
}

/** Shortcut for atom proptype validator. */
export const PropTypesAtom = PropTypes.shape({
  _source: PropTypes.object.isRequired,
  _lens: PropTypes.any.isRequired,
})

/** Returns whether an object is an atom or not. */
export const isAtom = obj => {
  return obj.constructor.name === 'LensedAtom'
}

/** Prop types for cell components. These get overridden by individual components. */
export const cellPropTypes = {
  // getTableData: a function to get all the data of the table.
  getTableData: PropTypes.func.isRequired,
  // rowData: contains the current row only, but every value in the row.
  rowData: PropTypes.object.isRequired,
  // spec: the layout of the column, i.e. everything specified on <AcpCell />.
  spec: PropTypes.shape(colPropTypes).isRequired,
  // options: additional options to be used by the cell component.
  options: PropTypes.object,
  // link: the URI that the row links to if clicked (or null if it isn't a link).
  link: PropTypes.string,
  // tableID: ID string of the table that can be used to refer to its tooltip component.
  tableID: PropTypes.string,

  // value: this is the primary value that we want to display in the cell.
  //
  // For example, a cell with value="id" and the data { id: 1234 } would
  // get the integer 1234 as its value. If 'id' doesn't exist in the
  // row's data, the passed value will be null.
  //
  // A value is not required. Some cells might display a static value,
  // or might display a button that always has the same label.
  //
  // By default, we allow any value type to be passed, so it's recommended
  // that every cell component overrides this to specify an exact type.
  value: PropTypes.any,
}

/** Default props for cell components. */
export const cellDefaultProps = {
  getTableData: () => [],
  rowData: {},
  spec: {},
  options: {},
  link: null,
  disableFunc: () => null,
  value: () => null,
}
