import { apiRequest } from 'syft-acp-core/api/call'

import { AGENCIES_LIST_CREATE_BEGIN, AGENCIES_LIST_FETCH_BEGIN } from './action-types'

export const fetchAgenciesList = ({ options }) => ({
  type: AGENCIES_LIST_FETCH_BEGIN,
  options,
})

export const createAgency = payload => ({
  type: AGENCIES_LIST_CREATE_BEGIN,
  payload,
})

/**
 * Runs autocomplete search for agency platforms.
 *
 * @param {String} query String to query the database with
 * @returns {Promise} List of workers on promise fulfillment
 */
export const agencyPlatformsAutocomplete = async query =>
  await fetch(apiRequest({ path: '/admin/platforms', reqArgs: { query } })).then(resp => resp.json())
