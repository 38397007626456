import React, { useCallback, useEffect } from 'react'
import { Row, Col, Radio } from 'react-bootstrap'
import { isEmpty } from 'lodash-es'
import classNames from 'classnames'
import { useTrackingTrigger } from '@indeed/flex-tracking-context'
import { useFlexFlagIsOn } from '@indeed/flex-feature-flags'
import { Spinner } from '@indeed/ifl-components'

import * as helpers from 'syft-acp-core/entities/ShiftList/helpers/tableHelpers'
import Button from 'syft-acp-atoms/ButtonNew' // TODO: delete ButtonNew once new designs are finalised
import LabeledCheckbox from 'syft-acp-core/components/LabeledCheckbox'
import FormattedDateTime, { fullLocalDateTimeFormat } from 'syft-acp-core/components/FormattedDateTime'
import DataJobSkills from 'syft-acp-core/components/EditableTable/DataJobSkills'
import { employerOffers } from 'syft-acp-core/entities/ListingDetail/structure'
import { hideAllAreasOrVenues } from 'syft-acp-core/entities/ListingDetail/ListingShiftTabs/helpers'

import IconXCircle from 'syft-acp-core/components/IconXCircle'
import IconCheckCircle from 'syft-acp-core/components/IconCheckCircle'
import IconQuestion from 'syft-acp-core/components/IconQuestion'
import EditableTextbox from 'syft-acp-core/components/EditableTextbox'
import DataAreasTabs from 'syft-acp-core/components/EditableTable/DataAreasTabs'
import DataVenuesTabs from 'syft-acp-core/components/EditableTable/DataVenuesTabs'

import InformationTip from 'syft-acp-util/components/InformationTip'
import DataDatetime from 'syft-acp-core/components/EditableTable/DataDatetime'
import ShiftFulfilmentAuditLogList from 'syft-acp-core/entities2/ShiftFulfilmentAuditLogList'
import LoadingSpinner from 'syft-acp-atoms/LoadingSpinner'
import ShiftFulfilmentModalJobDescription from './ShiftFulfilmentModalJobDescription'

import { trackingEvents } from './ShiftFulfilmentModal.tracking'
import Modal from '../ModalShim'
import Input from './Input'
import ChangedProperties from './ChangedProperties'
import ChangedNumberProperties from './ChangedNumberProperties'
import { convertShiftIdArrayToArrayOfObjects, getOfferType } from './ShiftFulfilmentModal.helpers'
import { OfferTypeOption, Props, OfferType, AuditLogProps } from './ShiftFulfilmentModal.types'
import S from './ShiftFulfilmentModal.module.scss'

const fieldLabelClassName = 'shift-fulfilment-modal__field__label'
const dateFieldLabelClassName = 'shift-fulfilment-modal__datepicker__label'
const dateColClassName = 'shift-fulfilment-modal__datepicker__col'
const keyValueClassName = 'shift-fulfilment-modal__section__key-value'
const sectionClassName = 'shift-fulfilment-modal__section'
const sectionAuditLogClassName = 'shift-fulfilment-modal__section__auditlog'
const rowClassName = 'shift-fulfilment-modal__section__row'
const listConformationClassName = 'shift-fulfilment-modal__confirmation__list'
const countConformationClassName = 'shift-fulfilment-modal__confirmation__count'
const fieldClassName = 'shift-fulfilment-modal__field'
const payRateChangeClassName = 'shift-fulfilment-modal__pay_rate_change__label'
const payTimeClassName = 'shift-fulfilment-modal__time_change__label'

const getSubmitLabel = (isSavingData: boolean) => (isSavingData ? 'Saving' : 'Continue')

export const ShiftFulfilmentAuditLogSection = ({ auditLog, shiftId, userId, email }: AuditLogProps) => {
  const triggerEvent = useTrackingTrigger()
  useEffect(() => {
    triggerEvent(trackingEvents.SHIFT_FULFILMENT.AUDIT_LOG.VIEWED, { user_id: userId, email })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  if (!auditLog || !shiftId) return null
  return (
    <div className={classNames(S[sectionClassName], S[sectionAuditLogClassName])}>
      <div className={S[rowClassName]}>
        <strong>History of changes to shift</strong>
      </div>
      <ShiftFulfilmentAuditLogList shiftID={shiftId} />
    </div>
  )
}

const ShiftFulfilmentModalView = ({
  closeModal,
  shift,
  notes,
  isShown,
  shiftIndex,
  trackingPayload,
  onSaveShiftNotes,
  state,
  client_preferences,
  onChangeOfferRate,
  onChangeClientRate,
  onChangeStartTime,
  onChangeEndTime,
  onChangeBookableIndividually,
  onChangeStopOffers,
  onChangeHardSkills,
  confirm,
  offerTypeOptions,
  changedListingProperties,
  changedJobProperties,
  toggleConfirm,
  onConfirm,
  onSaveJobDescription,
  isLoading,
  isSavingData,
  changedProperties,
  markAsReviewed,
  initialOfferToFlex,
  essentialSkills,
  changedStopOffers,
  shiftsCount,
  jobsCount,
  disabledSkillsIds = [],
  changedShiftProperties,
  userData,
}: Props) => {
  // Boolean on/off flags
  const auditLog = useFlexFlagIsOn('fulfilment_shift_audit_log')
  const enabledIdsLoaderFlag = useFlexFlagIsOn('pte_19818_fulfilment_shift_ids_loader')
  const labelButton = getSubmitLabel(isSavingData)
  const platformId = shift?.platform_id
  const listingID = shift?.listing_id
  const jobID = shift?.job_id
  const defaultTime = new Date().toISOString()
  const venue = shift?.venue || {}
  const payRateChange = shift?.overrides_summary?.client_pay_rate
  const timeChange = shift?.overrides_summary?.start_end_time
  const roleChange = shift?.overrides_summary?.role

  const offerTo: OfferType = initialOfferToFlex
  const showChangedPayrateMessage = !!changedJobProperties.find(item => item === 'pay_rate') && payRateChange
  const showChangedTimeMessage =
    !!changedShiftProperties.find(item => item === 'Start time' || item === 'End time') && timeChange
  const changedStopOffersRefactored = changedStopOffers
    ? !!state.stop_offers_for_worker_type?.length
      ? ['Offers stopped']
      : ['Offers resumed']
    : []
  const triggerEvent = useTrackingTrigger()
  const isReadOnlySkills = useFlexFlagIsOn('job_posting_acp_read_only_skills')

  const renderInformationIcon = (idForDOM: string, preference: Record<string, any> | undefined) => {
    // order of the condition is important to render the right icon.
    if (preference && preference['open_to_discussion'] === true)
      return <IconQuestion tooltipID={idForDOM} testID={idForDOM} />

    if (preference && preference['allow_changes'] === true)
      return <IconCheckCircle tooltipID={idForDOM} testID={idForDOM} />

    if (preference && preference['allow_changes'] === false)
      return <IconXCircle tooltipID={idForDOM} testID={idForDOM} />
    return null
  }

  const hasAllowChange = (preference: Record<string, any> | undefined) => {
    if (preference && preference['allow_changes'] === false) {
      return false
    }
    return true
  }

  const handleLinkClick = useCallback(
    linkType => () => {
      triggerEvent(trackingEvents.SHIFT_FULFILMENT.LINK.CLICKED, {
        ...trackingPayload,
        type: linkType,
      })
    },
    [trackingPayload, triggerEvent],
  )

  return (
    <Modal className={S['shift-fulfilment-modal']} onHide={closeModal} show={isShown}>
      <div className={S['shift-fulfilment-modal__inner']}>
        <div className={S['shift-fulfilment-modal__exit']}>
          <Button small onClick={closeModal}>
            Exit
          </Button>
        </div>
        <div className={S[sectionClassName]}>
          {isLoading && enabledIdsLoaderFlag ? (
            <div className={classNames(S[rowClassName], S['shift-fulfilment-modal__ids_spinner--first'])}>
              <Spinner style={{ height: '100%' }} title="Loading shift information" />
            </div>
          ) : (
            <>
              <div className={S[rowClassName]}>
                <span className={S[keyValueClassName]}>
                  <strong>Shift ID:</strong>{' '}
                  {!isLoading && (
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href={`/listings/view/${listingID}/job/${jobID}/shift/${state.id}`}
                      onClick={handleLinkClick('shift')}
                    >
                      {state.id}
                    </a>
                  )}
                </span>
                <span className={S[keyValueClassName]}>
                  <strong>Created:</strong>
                  {!isLoading && (
                    // @ts-expect-error
                    <FormattedDateTime
                      value={shift?.created_at}
                      format={{
                        ...fullLocalDateTimeFormat,
                        timeZone: venue?.timezone,
                      }}
                    />
                  )}
                </span>
                <span className={S['shift-fulfilment-modal__spinner']}>
                  <LoadingSpinner isLoading={isLoading} />
                </span>
              </div>
              <div className={S[rowClassName]}>
                <span className={S[keyValueClassName]}>
                  <strong>Employer:</strong>{' '}
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={`/entity/employers/view/${shift?.employer?.id}`}
                    onClick={handleLinkClick('employer')}
                  >
                    {helpers.getBrandCompany(shift)}
                  </a>
                </span>
                {hideAllAreasOrVenues('venue')(shift) && !isLoading && (
                  <span className={S[keyValueClassName]}>
                    <strong>Venue:</strong>{' '}
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href={`/entity/employers/view/${shift?.employer?.id}/venues/view/${shift?.venue?.id}`}
                      onClick={handleLinkClick('venue')}
                    >
                      {venue.name}
                    </a>
                  </span>
                )}
              </div>

              {hideAllAreasOrVenues('venue')(shift) && !isLoading && (
                <>
                  <div
                    className={classNames([
                      S[rowClassName],
                      S['shift-fulfilment-modal__section__row--spread'],
                    ])}
                  >
                    <span className={S[keyValueClassName]}>
                      <strong>Contact name:</strong> {venue.work_location_contact?.name || 'n/a'}
                    </span>
                    <span className={S[keyValueClassName]}>
                      <strong>Tel:</strong> {venue.work_location_contact?.mobile || 'n/a'}
                    </span>
                    <span className={S[keyValueClassName]}>
                      <strong>Email:</strong> {venue.work_location_contact?.email || 'n/a'}
                    </span>
                  </div>
                  {venue?.work_location_note?.body && (
                    <div className={S[rowClassName]}>
                      <p>
                        <strong>Venue notes</strong>
                      </p>
                      {venue.work_location_note?.body}
                    </div>
                  )}
                </>
              )}
              {!hideAllAreasOrVenues('venue')(shift) && !isLoading && (
                <>
                  <div className={S[rowClassName]}>
                    <p>
                      <strong>Venue details</strong>
                    </p>
                    <DataVenuesTabs
                      value={shift.venues}
                      data={{
                        venue: venue,
                        employer: { id: shift?.employer?.id },
                      }}
                      withoutTableMargin
                      minimalData
                    />
                  </div>
                </>
              )}
              {!hideAllAreasOrVenues('area')(shift) && !isLoading && (
                <>
                  <div className={S[rowClassName]}>
                    <p>
                      <strong>Area details</strong>
                    </p>
                    <DataAreasTabs
                      value={shift.areas}
                      data={{
                        area: shift?.area,
                        employerID: shift?.employer?.id,
                        venueID: shift?.venue?.id,
                      }}
                      withoutTableMargin
                    />
                  </div>
                </>
              )}
              <div className={S[rowClassName]}>
                <p>
                  <strong>Ops Internal Notes</strong>
                </p>
                <EditableTextbox
                  message="Saving internal notes will automatically mark this shift as reviewed."
                  placeholder="Handover"
                  value={notes}
                  onSave={onSaveShiftNotes}
                />
              </div>
            </>
          )}
        </div>

        <div className={S[sectionClassName]}>
          {isLoading && enabledIdsLoaderFlag ? (
            <div className={classNames(S[rowClassName], S['shift-fulfilment-modal__ids_spinner--second'])}>
              <Spinner style={{ height: '100%' }} title="Loading shift information" />
            </div>
          ) : (
            <div className={S[rowClassName]}>
              <p>
                <strong>Job description</strong>
              </p>
              <ShiftFulfilmentModalJobDescription
                callback={onSaveJobDescription}
                shiftIndex={shiftIndex}
                roleChange={roleChange}
                shift={shift}
              />
            </div>
          )}
        </div>

        <div className={S[sectionClassName]}>
          {isLoading && enabledIdsLoaderFlag ? (
            <div className={classNames(S[rowClassName], S['shift-fulfilment-modal__ids_spinner--third'])}>
              <Spinner style={{ height: '100%' }} title="Loading shift information" />
            </div>
          ) : (
            <>
              <div className={S[rowClassName]}>
                <strong>Adjust shift manually</strong>
                <span className={S['shift-fulfilment-modal__spinner']}>
                  <LoadingSpinner isLoading={isLoading} />
                </span>
              </div>
              <Row className={S['shift-fulfilment-modal__row']}>
                <Col md={6} className={S[rowClassName]}>
                  <Row>
                    <Col className={S[dateColClassName]} md={12}>
                      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                      <label className={S[fieldClassName]}>
                        <strong className={S[dateFieldLabelClassName]}>Start time</strong>
                        <DataDatetime
                          value={state?.start_time || defaultTime}
                          onChange={onChangeStartTime}
                          disabled={isLoading}
                          editable
                          parameters={{ timeZone: shift?.venue?.timezone }}
                          data={undefined}
                        />
                      </label>
                    </Col>
                  </Row>
                  <Row>
                    <Col className={S[dateColClassName]} md={12}>
                      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                      <label className={S[fieldClassName]}>
                        <strong className={S[dateFieldLabelClassName]}>End time</strong>
                        <DataDatetime
                          value={state?.end_time || defaultTime}
                          onChange={onChangeEndTime}
                          disabled={isLoading}
                          editable
                          parameters={{ timeZone: shift?.venue?.timezone }}
                          data={undefined}
                        />
                      </label>
                      {timeChange && (
                        <div className={S[payTimeClassName]}>
                          Time updated for {timeChange} {timeChange === 1 ? 'worker' : 'workers'}
                        </div>
                      )}
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12}>
                      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                      <label className={S[fieldClassName]}>
                        <strong className={S[fieldLabelClassName]}>Employer rate</strong>
                        <Input value={state.job?.pay_rate} onChange={onChangeClientRate} />
                        {/* Help user to show information while hover on the icon */}
                        {renderInformationIcon('payRateInfo', client_preferences?.pay_rate)}
                        <InformationTip Id="payRateInfo" message={client_preferences?.pay_rate?.info_msg} />
                      </label>
                      {payRateChange && (
                        <div className={S[payRateChangeClassName]}>
                          Pay rate updated for {payRateChange} {payRateChange === 1 ? 'worker' : 'workers'}
                        </div>
                      )}
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12}>
                      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                      <label className={S[fieldClassName]}>
                        <strong className={S[fieldLabelClassName]}>Offer rate</strong>
                        <Input
                          value={state.offer_rate}
                          onChange={onChangeOfferRate}
                          disabled={!hasAllowChange(client_preferences?.offer_rate)}
                          currency={state.job?.pay_rate?.currency}
                        />
                        {/* Help user to show information while hover on the icon */}
                        {renderInformationIcon('offerRateInfo', client_preferences?.offer_rate)}
                        <InformationTip
                          Id="offerRateInfo"
                          message={client_preferences?.offer_rate?.info_msg}
                        />
                      </label>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12}>
                      <div className={S[fieldClassName]}>
                        <strong className={S['shift-fulfilment-modal__offer-type']}>Offer type</strong>
                        <div>{getOfferType(employerOffers, offerTo, platformId)}</div>
                        {/* Help user to show information while hover on the icon */}
                        {renderInformationIcon('offerTypeInfo', client_preferences?.offer_type)}
                        <InformationTip
                          Id="offerTypeInfo"
                          message={client_preferences?.offer_type?.info_msg}
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12}>
                      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                      <label className={S['shift-fulfilment-modal__radio-field']}>
                        <strong className={S['shift-fulfilment-modal__field__label-offer-shift']}>
                          Offer shift to
                        </strong>
                        <div className={S['shift-fulfilment-modal__radio-container']}>
                          <div className={S['shift-fulfilment-modal__radio-item']}>
                            {offerTypeOptions.map((option: OfferTypeOption) => {
                              return (
                                <Row key={option.label}>
                                  <Radio
                                    data-testid={option.label}
                                    checked={option.checked}
                                    disabled={!hasAllowChange(client_preferences?.offer_type)}
                                    onChange={option.callback}
                                    className={S['shift-fulfilment-modal__radio']}
                                  >
                                    {option.label}
                                  </Radio>
                                </Row>
                              )
                            })}
                          </div>
                          <div className={S['shift-fulfilment-modal__checkboxes-message']}>
                            Offering this shift to the Flexers selected does not impact agency allocations.
                          </div>
                        </div>
                      </label>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6}>
                      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                      <label className={S[fieldClassName]}>
                        <strong className={S[fieldLabelClassName]}>Stop offers</strong>
                        <LabeledCheckbox
                          callback={onChangeStopOffers}
                          className="data-boolean"
                          checked={!isEmpty(state.stop_offers_for_worker_type)}
                          editable
                          label={''}
                          labelClassName={S['shift-fulfilment-modal__checkboxes-label']}
                          testId={'stop_offers'}
                        />
                      </label>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6}>
                      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                      <label className={S[fieldClassName]}>
                        <strong className={S[fieldLabelClassName]}>Bookable individually</strong>
                        <LabeledCheckbox
                          callback={onChangeBookableIndividually}
                          className="data-boolean"
                          checked={state.job?.bookable_individually}
                          editable={hasAllowChange(client_preferences?.bookable_individually)}
                          label={''}
                          labelClassName={S['shift-fulfilment-modal__checkboxes-label']}
                          testId={'bookable_individually'}
                        />
                        {/* Help user to show information while hover on the icon */}
                        {renderInformationIcon('bookablesInfo', client_preferences?.bookable_individually)}
                        <InformationTip
                          Id="bookablesInfo"
                          message={client_preferences?.bookable_individually?.info_msg}
                        />
                      </label>
                    </Col>
                  </Row>
                </Col>
                <Col md={6} className={S[rowClassName]}>
                  <Row>
                    <Col md={12}>
                      <div
                        className={classNames([
                          S['shift-fulfilment-modal__field'],
                          S['shift-fulfilment-modal__hard-skills-col'],
                        ])}
                      >
                        <strong className={S['shift-fulfilment-modal__hard-skills']}>Hard skills</strong>
                        <DataJobSkills
                          skills={state.job?.skill_ids || []}
                          otherData={{
                            skills: shift?.skills,
                            role_id: shift.job?.role_id,
                            essential_skills: convertShiftIdArrayToArrayOfObjects(essentialSkills),
                            disabledSkills: disabledSkillsIds,
                          }}
                          editable={true}
                          onChange={onChangeHardSkills}
                          enabled
                          onlyHardOrEssentialSkills
                          className={S['shift-fulfilment-modal__hard-skills-component']}
                        />
                        {!isReadOnlySkills && (
                          <>
                            {renderInformationIcon('HardSkillsInfo', client_preferences?.hard_skills)}
                            <InformationTip
                              Id="HardSkillsInfo"
                              message={client_preferences?.hard_skills?.info_msg}
                            />
                          </>
                        )}
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </>
          )}
        </div>

        {confirm ? (
          <div className={classNames([S[sectionClassName], S['shift-fulfilment-modal__confirmation']])}>
            <p className={S['shift-fulfilment-modal__confirmation__heading']}>Are you sure?</p>
            <p className={S['shift-fulfilment-modal__confirmation__sub-heading']}>
              This shift will be marked as reviewed.
            </p>
            <div className={S['shift-fulfilment-modal__confirmation__content']}>
              <ChangedProperties
                changedProperties={[...changedShiftProperties, ...changedStopOffersRefactored]}
                id={state.id}
                text="Shift"
                listClassName={S[listConformationClassName]}
              />
              <ChangedProperties
                changedProperties={changedJobProperties}
                id={jobID}
                text="Job"
                listClassName={S[listConformationClassName]}
              />
              <ChangedProperties
                changedProperties={changedListingProperties}
                id={listingID}
                text="Listing"
                listClassName={S[listConformationClassName]}
              />
              {(!!changedJobProperties.length || !!changedListingProperties.length) && (
                <ChangedNumberProperties
                  divClassName={S[countConformationClassName]}
                  count={shiftsCount}
                  text="shift"
                />
              )}
              {!!changedListingProperties.length && (
                <ChangedNumberProperties
                  divClassName={S[countConformationClassName]}
                  count={jobsCount}
                  text="job"
                />
              )}
              {showChangedPayrateMessage && (
                <p className={S[countConformationClassName]}>
                  This job contains worker specific rates, which won't be affected by job rate edits
                </p>
              )}
              {showChangedTimeMessage && (
                <p className={S[countConformationClassName]}>
                  This shift contains worker specific Times, which won’t be affected by shift Time edits.
                </p>
              )}
            </div>
            <div>
              <Button
                confirm
                className={classNames([
                  S['shift-fulfilment-modal__button'],
                  S['shift-fulfilment-modal__confirmation__continue-button'],
                ])}
                onClick={onConfirm}
                disabled={isLoading}
              >
                {labelButton}
              </Button>
              <Button
                className={classNames(S['shift-fulfilment-modal__confirmation__cancel-button'])}
                onClick={toggleConfirm}
              >
                Cancel
              </Button>
            </div>
          </div>
        ) : (
          <div>
            <Button
              confirm
              className={classNames([
                S['shift-fulfilment-modal__button'],
                S['shift-fulfilment-modal__save-button'],
              ])}
              onClick={toggleConfirm}
              disabled={
                isLoading ||
                !changedProperties.length ||
                (changedProperties.length === 1 && changedProperties[0] === 'fulfilment_reviewed')
              }
            >
              Save changes
            </Button>
            {state.fulfilment_reviewed ? (
              <Button
                className={classNames(S['shift-fulfilment-modal__not-review-button'])}
                onClick={markAsReviewed}
              >
                Mark as not reviewed
              </Button>
            ) : (
              <Button
                confirm
                className={classNames(S['shift-fulfilment-modal__review-button'])}
                onClick={markAsReviewed}
              >
                Mark as reviewed
              </Button>
            )}
          </div>
        )}
        <ShiftFulfilmentAuditLogSection
          shiftId={state.id}
          auditLog={auditLog}
          userId={userData.id}
          email={userData.email}
        />
      </div>
    </Modal>
  )
}

export default ShiftFulfilmentModalView
