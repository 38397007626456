import React, { useState, useCallback, useEffect } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { get, values } from 'lodash-es'
import { AsyncTypeahead } from 'react-bootstrap-typeahead'
import { fetchAgencyProfile } from 'syft-acp-core/actions/agency-profile'

const AgencyEmployersAutocomplete = ({ onChange, actions, agencyId, employers, isLoading }) => {
  const options = employers.map(employer => ({ id: employer.id, label: employer.company_name }))
  const [selected, setSelected] = useState('')
  useEffect(() => {
    actions.fetchEmployers({ agencyId })
  }, [agencyId, actions])

  const saveInput = useCallback(
    value => {
      setSelected(value[0])
      onChange(get(value, '[0].id'))
    },
    [onChange]
  )

  return (
    <AsyncTypeahead
      labelKey="label"
      defaultSelected={[]}
      selected={selected ? [selected] : []}
      emptyLabel="No matches."
      isLoading={isLoading}
      maxResults={7}
      delay={500}
      id="label"
      options={options}
      selectHintOnEnter
      onChange={saveInput}
      minLength={0}
    />
  )
}

AgencyEmployersAutocomplete.propTypes = {
  onChange: PropTypes.func.isRequired,
  fetchEmployers: PropTypes.func.isRequired,
  agencyId: PropTypes.number.isRequired,
  actions: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
  employers: PropTypes.array.isRequired,
}

export default connect(
  (state, { agencyId }) => ({
    employers: values(get(state, `agencyProfile.entityDetail[${agencyId}].employers`, {})),
    isLoading: state.agencyProfile.isLoadingData,
  }),
  dispatch => ({
    actions: bindActionCreators({ fetchEmployers: fetchAgencyProfile }, dispatch),
  })
)(AgencyEmployersAutocomplete)
