import React from 'react'
import { TrackingProvider } from '@indeed/flex-tracking-context'

type Props = {
  children: React.ReactNode
  timeOffRequestId: number
}

export const TimeOffRequestDetailTrackingProvider = ({ children, timeOffRequestId }: Props) => (
  <TrackingProvider value={{ context: { time_off_request_id: timeOffRequestId } }}>{children}</TrackingProvider>
)
