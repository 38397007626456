// Syft ACP - UI Library <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import React from 'react'
import PropTypes from 'prop-types'

import iconFactory from 'syft-acp-atoms/Icons'
import './HeaderIcon.css'

class HeaderIcon extends React.PureComponent {
  static propTypes = {
    iconSlug: PropTypes.string.isRequired,
    iconAlt: PropTypes.string.isRequired,
    iconSize: PropTypes.number,
  }

  static defaultProps = {
    iconSize: 13,
  }

  render() {
    const { iconSlug, iconAlt, iconSize } = this.props
    const IconComponent = iconFactory(iconSlug, iconAlt)
    return <IconComponent className="acp-header-icon" iconSize={iconSize} />
  }
}

// Checkmark icon
export const CheckIcon = () => <HeaderIcon iconSlug="check" iconAlt="Checkmark" />
// Single person icon
export const PersonIcon = () => <HeaderIcon iconSlug="person" iconAlt="Person" iconSize={11} />
// Multiple people icon
export const PeopleIcon = () => <HeaderIcon iconSlug="organization" iconAlt="People" />
// Clock icon
export const ClockIcon = () => <HeaderIcon iconSlug="clock" iconAlt="Clock" />
// Crossed out icon
export const CrossIcon = () => <div className="acp-header-icon">✕</div>

export default HeaderIcon
