// Syft ACP - UI Library <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import classnames from 'classnames'
import { isArray, isEqual, result, isPlainObject } from 'lodash-es'
import PropTypes from 'prop-types'
import React from 'react'
import { ButtonGroup } from 'react-bootstrap'
import { Button } from 'syft-acp-atoms/Button'
import { acpFilterDefaults } from '../dataProps'

import { filterDecorator } from '../filterDecorator'

const anyValue = ''

/**
 * Display option groups inline, only one at a time can be enabled
 * Slug can be either value or function that generates the value on change
 *
 * <Acp.Filter.Enum name="Tier">
 *   <Acp.Option slug={1}>Tier 1</Acp.Option>
 *   <Acp.Option slug={() => return Date.now()}>Tier 1</Acp.Option>
 * </Acp.Filter.Enum>
 */
class AcpFilterEnum extends React.PureComponent {
  static propTypes = {
    children: PropTypes.node.isRequired,
  }

  static defaultProps = {
    ...acpFilterDefaults,
    children: [],
  }

  // disables debounce set by filter decorator
  static requireDebounce = false

  constructor(props) {
    super(props)
    this.state = {
      value: this.props.initialValue || '',
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { name, setValue } = this.props

    // set query parameter on state value change
    if (prevState.value !== this.state.value) {
      setValue(name, this.state.value)
    }
  }

  handleChange = (value, index) => {
    if (value === anyValue) {
      return this.setState({ value: '' })
    }

    this.setState({
      value,
      activeIndex: index,
    })
  }

  renderInnerOptions = options =>
    (options &&
      options.map((option, i) => {
        if (isPlainObject(option)) {
          const { slug, ...restOpts } = option
          const isActive = isEqual(this.state.value, result(option, 'slug')) || i === this.state.activeIndex
          return (
            <Button
              {...restOpts}
              key={String(i)}
              active={isActive}
              onClick={() => this.handleChange(result(option, 'slug'), i)}
            />
          )
        } else if (isArray(option)) {
          return (
            <ButtonGroup className="filter-enum-form-group" key={`${i}-${option[0]}`}>
              {option[0] && option[0]}
              {this.renderInnerOptions(option[1])}
            </ButtonGroup>
          )
        }

        return null
      })) ||
    null

  renderOptions = () => {
    const { childrenOptions, placeholder } = this.props
    const groupOpts = [['', childrenOptions]]
    const hasAnyOption = !!placeholder
    return this.renderInnerOptions(
      hasAnyOption ? [['', [{ slug: anyValue, children: placeholder }]], ...groupOpts] : groupOpts,
    )
  }

  render() {
    const { className } = this.props
    return (
      <div className={classnames('AcpFilterEnum', className)} data-testid="acp-filter-enum">
        {this.renderOptions()}
      </div>
    )
  }
}

export default filterDecorator(AcpFilterEnum)
