import * as React from 'react'
import { Col, Grid, Row } from 'react-bootstrap'

import PageView from 'syft-acp-core/components/PageView'
import AppealsListTable from 'syft-acp-core/entities2/AppealsList'

import ConductSubNav from './ConductSubNav'

export const AppealsList = () => (
  <>
    <ConductSubNav />
    <PageView title={['Appeals', 'Viewing appeals']}>
      <Grid fluid>
        <Row>
          <Col md={12}>
            <AppealsListTable />
          </Col>
        </Row>
      </Grid>
    </PageView>
  </>
)
