import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Row, Col, FormGroup } from 'react-bootstrap'
import { Form } from 'react-final-form'
import { get, pickBy, identity } from 'lodash-es'
import { downloadAsFileFromApi } from 'syft-acp-core/api/file'
import { datadogRum } from '@datadog/browser-rum-slim'

import { getModal$ } from 'syft-acp-core/store/modals/selectors'
import { Modal } from 'syft-acp-core/components/Modal'
import { notify } from 'syft-acp-core/actions/notifications'
import { dateAddTz } from 'syft-acp-util/time'
import { showModal, hideModal } from 'syft-acp-core/store/modals/actions'
import DateField from './DateField'
import { withTrackingTriggerHoc } from 'syft-acp-util/withTrackingTriggerHoc'
import './PayrollCSVModal.css'
import { trackingEvents } from './Modal.tracking'

export const csv_types = {
  common: 'common_payroll',
  workerTime: 'worker_time_payroll',
  missingTimes: 'missing_times_payroll',
  paycomPayrollMinusCa: 'paycom_payroll_minus_ca',
  paycom: 'paycom_common_payroll',
  missingPayInvoice: 'missing_pay_invoice',
  missingPayPayroll: 'missing_pay_payroll',
  agencyShiftWorkers: 'agency_shift_workers',
  uploadPaycomPayrollToGoogleDrive: 'uploadPaycomPayrollToGoogleDrive',
}

const getHeading = type => {
  switch (type) {
    case csv_types.common:
      return 'Download payroll CSV file'
    case csv_types.missingTimes:
      return 'Download missing times payroll CSV file'
    case csv_types.missingPayInvoice:
      return 'Download missing pay invoice CSV'
    case csv_types.missingPayPayroll:
      return 'Download missing pay Payroll CSV'
    case csv_types.agencyShiftWorkers:
      return 'Download Agency hours CSV'
    case csv_types.uploadPaycomPayrollToGoogleDrive:
      return 'Upload Paycom Payroll CSV to Google Drive'
    case csv_types.paycomPayrollMinusCa:
      return 'Upload Paycom Payroll minus CA CSV to Google Drive'
    case csv_types.workerTime:
      return '(BETA) Download worker-time payroll CSV file'
    default:
      return 'Download CSV'
  }
}

class BaseCsvResource {
  constructor(url, csvType, post, excludeFederatedStates = []) {
    this.url = url
    this.csvType = csvType
    this.method = post ? 'POST' : 'GET'
    this.excludeFederatedStates = excludeFederatedStates
  }
}

class PayrollCsvResource extends BaseCsvResource {
  fetch(dateStart, dateEnd) {
    return downloadAsFileFromApi(
      this.url,
      {
        start_date: dateAddTz(dateStart),
        end_date: dateAddTz(dateEnd),
        payroll_type: this.csvType,
        exclude_federated_states: this.excludeFederatedStates,
        email: this.email,
        method: this.method,
      },
      'payroll',
    )
  }
}

const getModalPropsByType = type => {
  switch (type) {
    case csv_types.missingPayInvoice:
      return {
        csvResource: new PayrollCsvResource('/admin/missing_pays/missing_pay_invoice.csv', type),
        title: 'Invoice CSV',
        message: 'Now downloading the Invoice CSV.',
      }
    case csv_types.missingPayPayroll:
      return {
        csvResource: new PayrollCsvResource('/admin/missing_pays/payroll_missing_pay.csv', type),
        title: 'Missing pay payroll CSV',
        message: 'Now downloading the missing payroll CSV.',
      }
    case csv_types.agencyShiftWorkers:
      return {
        csvResource: new PayrollCsvResource('/admin/agency_shift_workers.csv', type),
        title: 'Agency hours CSV file',
        message: 'Now downloading the agency hours CSV.',
      }
    case csv_types.uploadPaycomPayrollToGoogleDrive:
      return {
        csvResource: new PayrollCsvResource(
          '/admin/shift_bookings/upload_paycom_payroll_csv',
          csv_types.paycom,
          true,
        ),
        title: 'Upload Paycom Payroll CSV to Google Drive',
        message: 'Now uploading the Paycom payroll CSV.',
      }
    case csv_types.paycomPayrollMinusCa:
      return {
        csvResource: new PayrollCsvResource(
          '/admin/shift_bookings/upload_paycom_payroll_csv',
          csv_types.paycom,
          true,
          ['CA'],
        ),
        title: 'Upload Paycom Payroll minus CA CSV to Google Drive',
        message: 'Now uploading the Paycom payroll minus CA CSV.',
      }
    case csv_types.workerTime:
      return {
        csvResource: new PayrollCsvResource('/admin/shift_bookings/full_payroll.csv', type),
        title: 'Worker-time Payroll CSV',
        message: 'Now downloading worker-time payroll CSV.',
      }
    default:
      return {
        csvResource: new PayrollCsvResource('/admin/shift_bookings/full_payroll.csv', type),
        title: 'Payroll CSV',
        message: 'Now downloading the payroll CSV.',
      }
  }
}

const trackingEventName = type => {
  return [csv_types.uploadPaycomPayrollToGoogleDrive, csv_types.paycomPayrollMinusCa].includes(type)
    ? trackingEvents.PAYROLL_MODAL.UPLOAD
    : trackingEvents.PAYROLL_MODAL.DOWNLOAD
}

const confirmationTextCaption = type => {
  if ([csv_types.uploadPaycomPayrollToGoogleDrive, csv_types.paycomPayrollMinusCa].includes(type)) {
    return 'Upload'
  } else {
    return 'Download'
  }
}

class PayrollCSVModal extends PureComponent {
  // Note: default to "common_payroll" to make the modal work even if it was opened
  // without the required type argument.
  getType = () => get(this.props, 'item.options.type', 'common_payroll')
  getParameters = () => get(this.props, 'item.options.parameters', {})

  validationCriteria = values => {
    const defaultCriteria = {
      dateStart: values.dateStart ? null : 'Enter a date',
      dateEnd: values.dateEnd ? null : 'Enter a date',
    }

    if (this.getType() === csv_types.emailPaycomPayroll) {
      return {
        ...defaultCriteria,
        email: values.email ? null : 'Enter an email address',
      }
    }

    return defaultCriteria
  }
  validate = values =>
    // Remove falsy values. If everything is correct, an empty object is returned here.
    pickBy(this.validationCriteria(values), identity)

  submit = values =>
    new Promise(async resolve => {
      try {
        const { dateStart, dateEnd } = values
        const type = this.getType()
        this.props.triggerEvent(trackingEventName(type), {
          type,
          dateStart: dateAddTz(dateStart),
          dateEnd: dateAddTz(dateEnd),
        })
        const { csvResource, title, message } = getModalPropsByType(type)
        await csvResource.fetch(dateStart, dateEnd)
        this.props.dispatch(hideModal(this.props.item.modalName))
        this.props.dispatch(notify('success', { title, message }))
        return resolve()
      } catch (err) {
        // Could not successfully POST.
        datadogRum.addError(err, { message: 'POST to CSV resource failed' })
        return resolve({ form: err.body?.debug?.message || 'Could not submit the form. Server may be busy.' })
      }
    })

  closeModal = () => this.props.dispatch(hideModal(this.props.item.modalName))

  openModal = () => this.props.dispatch(showModal(this.props.item.modalName))

  render() {
    return (
      <Form
        onSubmit={this.submit}
        validate={this.validate}
        render={({ handleSubmit, invalid, submitting, submitFailed, submitErrors }) => (
          <Modal
            // The 'type' determines if we're showing the regular payroll modal ("common_payroll")
            // or the missing times payroll ("missing_times_payroll").
            header={getHeading(this.getType())}
            isShown={this.props.item.isShown}
            onClose={this.closeModal}
            onConfirm={handleSubmit}
            canSubmit={(!invalid && !submitting) || submitFailed}
            errorText={submitFailed && !submitting && submitErrors.form ? submitErrors.form : null}
            confirmationText={confirmationTextCaption(this.getType())}
          >
            <form onSubmit={handleSubmit}>
              <FormGroup disabled={submitting} className="payroll-csv-modal">
                <Row>
                  <Col md={6} className="field-wrapper">
                    <DateField
                      name="dateStart"
                      label="Start date"
                      disable={submitting}
                      parameters={this.getParameters()}
                    />
                  </Col>
                  <Col md={6} className="field-wrapper">
                    <DateField
                      name="dateEnd"
                      label="End date"
                      disable={submitting}
                      parameters={this.getParameters()}
                    />
                  </Col>
                </Row>
              </FormGroup>
            </form>
          </Modal>
        )}
      />
    )
  }
}

PayrollCSVModal.propTypes = {
  item: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
}

export default connect(state => ({
  item: getModal$(state.modals)('payrollCsvModal'),
}))(withTrackingTriggerHoc(PayrollCSVModal))
