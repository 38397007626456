import entityReducer from 'syft-acp-core/reducers/generators/entityReducer'
import * as entityTypes from 'syft-acp-core/reducers/generators/entities'

import * as types from './action-types'
import type { ConductReviewEntity, ConductReviewsReducerType, AssigneeState } from '../types'
import { assigneeInitialState, setTsLastAssigned, setAssignees } from '../constants'

export default entityReducer<ConductReviewEntity, AssigneeState>(
  'conductReviews',
  {
    [entityTypes.ENTITIES_FETCH_BEGIN]: types.CONDUCT_REVIEWS_FETCH_BEGIN,
    [entityTypes.ENTITIES_FETCH_SUCCEEDED]: types.CONDUCT_REVIEWS_FETCH_SUCCEEDED,
    [entityTypes.ENTITIES_FETCH_FAILED]: types.CONDUCT_REVIEWS_FETCH_FAILED,
    [entityTypes.ENTITY_FETCH_BEGIN]: types.CONDUCT_REVIEW_FETCH_BEGIN,
    [entityTypes.ENTITY_FETCH_SUCCEEDED]: types.CONDUCT_REVIEW_FETCH_SUCCEEDED,
    [entityTypes.ENTITY_FETCH_FAILED]: types.CONDUCT_REVIEW_FETCH_FAILED,
    [entityTypes.ENTITY_UPDATE_BEGIN]: types.CONDUCT_REVIEW_UPDATE_BEGIN,
    [entityTypes.ENTITY_UPDATE_SUCCEEDED]: types.CONDUCT_REVIEW_UPDATE_SUCCEEDED,
    [entityTypes.ENTITY_UPDATE_FAILED]: types.CONDUCT_REVIEW_UPDATE_FAILED,
    [types.CONDUCT_REVIEWS_ASSIGN_SUCCEEDED]: setTsLastAssigned,
    [types.CONDUCT_REVIEWS_ASSIGNEES_FETCH_SUCCEEDED]: setAssignees,
  },
  {
    initialState: assigneeInitialState,
  }
) as ConductReviewsReducerType
