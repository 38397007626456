// Syft ACP - Lib <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { Row, Col, Grid } from 'react-bootstrap'

const FilterWrapper = ({ className, children, type, hasFilters, noFilterContainer }) => (
  <div
    className={classnames('entity-list-controls', className, {
      record: type === 'record',
      'no-filters': !hasFilters,
    })}
    data-testid="entity-list-controls"
  >
    {/* If we're displaying a 'record' type list, don't wrap in a grid. */}
    {type !== 'record' && noFilterContainer !== true && (
      <Grid>
        <Row>
          <Col md={12}>{children}</Col>
        </Row>
      </Grid>
    )}
    {(type === 'record' || noFilterContainer === true) && children}
  </div>
)

FilterWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  type: PropTypes.string,
  noFilterContainer: PropTypes.bool,
  hasFilters: PropTypes.bool,
}

FilterWrapper.defaultProps = {
  className: '',
  type: '',
  noFilterContainer: false,
  hasFilters: false,
}

export default FilterWrapper
