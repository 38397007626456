import * as types from './action-types'

/**
 * Get a list of skills
 *
 * @returns {{options: *, type: string }}
 */
export const listSkills = () => ({
  type: types.SKILLS_FETCH_BEGIN
})

/**
 * Initiates the process of bulk toggling a skill for a worker
 *
 * @param workerID {Number} - ID of the worker
 * @param skillID {Number} - ID of the skill to bulk toggle
 * @returns {{options: *, type: string, workerID: number, skillID: number, remove: boolean}}
 */
export const bulkToggleSkill = (workerID: number, skillID: number, remove = false) => ({
  type: types.SKILLS_BULK_TOGGLE_BEGIN,
  workerID,
  skillID,
  remove
})
