import PropTypes from 'prop-types'

export default PropTypes.shape({
  activePage: PropTypes.number.isRequired,
  total: PropTypes.number,
  totalPages: PropTypes.number,
  totalWorkers: PropTypes.number,
  totalBookings: PropTypes.number,
  totalShifts: PropTypes.number,
  totalVacancies: PropTypes.number,
  perPage: PropTypes.number,
  nextPage: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  page: PropTypes.number,
})
