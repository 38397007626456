// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import PropTypes from 'prop-types'

/** Prop types for a tableFormat specification. */
const tableFormatPropTypes = PropTypes.arrayOf(
  PropTypes.shape({
    type: PropTypes.string.isRequired,
    enumStates: PropTypes.objectOf(PropTypes.string),
    enumLabels: PropTypes.objectOf(PropTypes.string),
    val: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
    header: PropTypes.string,
    classes: PropTypes.arrayOf(PropTypes.string),
    icon: PropTypes.node,
    format: PropTypes.func,
    check: PropTypes.func,
    showForCountries: PropTypes.arrayOf(PropTypes.string),
    hideForCountries: PropTypes.arrayOf(PropTypes.string),
  })
).isRequired

export default tableFormatPropTypes
