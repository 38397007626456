import { useState, useEffect, useCallback } from 'react'
import { notify } from 'syft-acp-core/actions/notifications'
import { apiRequest, handleCall } from 'syft-acp-core/api/call'

export const fetchDisabledSkills = () =>
  new Promise((resolve, reject) => {
    const req = apiRequest({ path: '/admin/disabled_skills', method: 'GET' })
    handleCall(req, resolve, reject)
  })

export const useFetchDisabledSkills = dispatch => {
  const [disabledSkillIds, setDisabledSkillIds] = useState([])

  const getDisabledSkills = useCallback(async () => {
    try {
      const { payload } = await fetchDisabledSkills()
      if (payload) {
        const disabledSkills = payload.map(skill => skill.id)
        setDisabledSkillIds(disabledSkills)
      }
    } catch (error) {
      dispatch(
        notify('error', {
          title: 'Error',
          message: 'Could not fetch disabled skills',
        })
      )
    }
  }, [dispatch])

  useEffect(() => {
    // We only want to fetch the disabled skills once
    getDisabledSkills()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return { disabledSkillIds }
}
