// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import * as types from '../action-types'
import { FetchOptions } from './types'

export const fetchShifts = (
  { options }: FetchOptions,
  _props?: any,
  isCSV = false,
  sourceComponent?: string,
) => ({
  type: types.SHIFTS_FETCH_BEGIN,
  options,
  isCSV,
  sourceComponent,
})
export type FetchShiftsAction = ReturnType<typeof fetchShifts>

export const deleteShift = ({
  shiftID,
  jobID,
  listingID,
  removeListing,
}: {
  shiftID: number
  jobID: number
  listingID: number
  removeListing: boolean
}) => ({
  type: types.SHIFT_DELETE_BEGIN,
  shiftID,
  jobID,
  listingID,
  removeListing,
})
export type DeleteShiftAction = ReturnType<typeof deleteShift>

export const removeAdminShiftSpot = (payload: Record<string, any>) => ({
  type: types.REMOVE_ADMIN_SHIFTS_SPOT_BEGIN,
  payload,
})
export type RemoveAdminShiftSpotAction = ReturnType<typeof removeAdminShiftSpot>

export const stopNewWorkers = (shiftID: number, resume: boolean, stop_offers_for_worker_type: string[]) => ({
  type: types.SHIFT_STOP_NEW_WORKERS_BEGIN,
  shiftID,
  payload: { resume, stop_offers_for_worker_type },
})
export type StopNewWorkersAction = ReturnType<typeof stopNewWorkers>
