import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import octicons from 'octicons'

import entityList, { ACTION_DOWNLOAD_CSV } from 'syft-acp-util/entityList'
import WorkerNetworkDetailWrapper from 'syft-acp-core/entities/WorkerNetworkDetail/WorkerNetworkDetailWrapper'
import * as filterTypes from 'syft-acp-util/components/FilterForm/FilterForm'
import * as workersActions from 'syft-acp-core/actions/worker-networks'
import * as tableType from 'syft-acp-core/components/EntityList/'

class WorkerNetworksList extends PureComponent {
  static propTypes = {
    workerID: PropTypes.number.isRequired,
  }

  static actionFormat = [
    {
      label: 'Download CSV',
      type: filterTypes.TYPE_BUTTON,
      action: ACTION_DOWNLOAD_CSV,
    },
  ]

  static filterFormat = null

  static tableFormat = [
    {
      type: tableType.TXT,
      val: 'id',
      header: 'ID',
      numeric: true,
    },
    {
      type: tableType.IMAGE,
      headerIcon: octicons.person,
      classes: ['minimal', 'image'],
      headerIconAlt: 'Picture',
      val: 'picture_uuid',
    },
    {
      type: tableType.TXT,
      classes: ['one-liner'],
      val: 'name',
      header: 'Name',
    },
    {
      type: tableType.TXT,
      val: 'owner_id',
      header: 'Owner ID',
      numeric: true,
    },
    {
      type: tableType.TXT,
      val: 'owner_type',
      classes: ['minimal'],
      header: 'Owner type',
    },
    {
      type: tableType.DATETIME,
      val: 'created_at',
      classes: ['minimal'],
      header: 'Created at',
    },
    {
      type: tableType.DATETIME,
      val: 'updated_at',
      classes: ['minimal'],
      header: 'Updated at',
    },
  ]

  render() {
    const EntityList = entityList(
      WorkerNetworkDetailWrapper,
      WorkerNetworksList.tableFormat,
      WorkerNetworksList.filterFormat,
      WorkerNetworksList.actionFormat,
      'workerNetworks',
      {
        fetchEntities: workersActions.listWorkerNetworks,
      },
      {
        idKey: 'id',
        showResultCount: true,
      }
    )
    return (
      <EntityList
        {...this.props}
        useFilterForm
        noFilterContainer
        pagination
        query={{ workerID: this.props.workerID }}
      />
    )
  }
}

export default connect(null, dispatch => ({
  actions: bindActionCreators({ ...workersActions }, dispatch),
}))(WorkerNetworksList)
