import { isEmpty, pick } from 'lodash-es'
import { REHYDRATE } from 'redux-persist/constants'
import { put, takeLatest } from 'redux-saga/effects'
import { beginLogOut, forceRefreshAuthToken, refreshAuthToken } from '../actions/auth'

export default function* () {
  yield takeLatest([REHYDRATE], function* ({ payload }) {
    const { auth = { oauthData: {} }, oauthData = {} } = pick(payload, ['auth', 'oauthData'])
    // logout users who have not oauthData reducer data in storage.
    // these are the old users before oauthData reducers added
    // or if oauthData was removed in OneHost Internal Tools context app on the same domain
    if (!isEmpty(auth.oauthData) && isEmpty(oauthData)) {
      yield put(beginLogOut())
      return
    }

    const oauthDataExpiresAt = new Date((oauthData.created_at + oauthData.expires_in) * 1000)
    const authOauthDataExpiresAt = new Date((auth.oauthData.created_at + auth.oauthData.expires_in) * 1000)

    // getTime returns NaN for invalid dates, NaN !== NaN
    if (!isNaN(oauthDataExpiresAt.getTime()) && oauthDataExpiresAt.getTime() !== authOauthDataExpiresAt.getTime()) {
      // update both auth & oauthData reducer with new oauthData (new tokens)
      yield put(forceRefreshAuthToken({ oauth: oauthData, user: {} }))
      // refresh access token to get user data and to sync reducers
      yield put(refreshAuthToken())
    }
  })
}
