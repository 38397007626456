// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import React from 'react'
import { formatFullName } from 'syft-acp-util/formatting'

import UserLine from 'syft-acp-core/components/UserAvatar/UserLine'
import entityList from 'syft-acp-util/entityList'
import * as tableType from 'syft-acp-core/components/EntityList/'
import { FilterGroup, FilterText } from 'syft-acp-util/components/FilterForm'
import * as healthActions from 'syft-acp-core/actions/health-questionnaires'
import EntityListComponent from 'syft-acp-core/components/EntityList/EntityListComponent'

/*

Example of the data returned from the server:
{
  "id": 259,
  "document_type": "Health Questionnaire",
  "selected_answer": "yes",
  "details": "hello",
  "status": "completed",
  "expires_on": "2020-03-11",
  "created_at": "2019-03-11T13:25:38.691+0000",
  "worker": {
    "id": 9173,
    "first_name": "Alex",
    "last_name": "Filatov",
    "title": "Mr.",
    "date_of_birth": "1978-09-09",
    "bio": "syft is awesome!",
    "role_ids": [
      3
    ],
    "skill_ids": [
      5,
      51,
      56,
      84,
      86,
      1543,
      1544
    ],
    "minimum_rate": {
      "amount": 8.5,
      "currency": "GBP"
    },
    "telephone_number": "07445086069",
    "languages": null,
    "national_insurance_number": "090978M",
    "availability": [
      "sunday_am",
      "sunday_pm",
      "monday_am",
      "monday_pm",
      "tuesday_am",
      "tuesday_pm",
      "wednesday_am",
      "wednesday_pm",
      "thursday_am",
      "thursday_pm",
      "friday_am",
      "friday_pm",
      "saturday_am",
      "saturday_pm"
    ],
    "completed_shifts_count": 4,
    "user": {
      "id": 38422,
      "email": "alex+staging72@indeedflex.co.uk",
      "admin": false,
      "banned": false
    }
  }
}

*/

const workerProfileHref = data => `/entity/workers/view/${data.worker.id}`
const tableFormat = [
  { type: tableType.TXT, val: 'id', header: 'ID', expl: 'Questionnaire ID', numeric: true },
  { type: tableType.TXT, val: 'worker.id', header: 'W. ID', expl: 'Worker ID', numeric: true },
  {
    type: tableType.CUSTOM_COMPONENT,
    defaultValue: '–',
    val: data => <UserLine name={formatFullName(data.worker)} />,
    href: workerProfileHref,
    header: 'Worker',
    classes: ['nowrap'],
  },
  { type: tableType.EMAIL, val: 'worker.user.email', header: 'Email' },
  { type: tableType.PHONE_NUMBER, val: 'worker.telephone_number', header: 'Tel. nr' },
  { type: tableType.TXT, val: 'details', header: 'Health concern' },
]

const actionFormat = []

const filters = (
  <div>
    <FilterGroup title="Worker name">
      <FilterText name="worker_name" placeholder="Name" />
    </FilterGroup>
  </div>
)

// Generates URLs that link to the workers.
const urlGenerator = rowData => `/entity/workers/view/${rowData.worker.id}`

export default entityList(
  EntityListComponent,
  tableFormat,
  filters,
  actionFormat,
  'healthQuestionnaires',
  {
    fetchEntities: healthActions.fetchHealthQuestionnaires,
  },
  {
    showResultCount: true,
    urlGenerator,
  },
)
