// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { get } from 'lodash-es'
import { bindActionCreators } from 'redux'

import * as workerDocumentActions from 'syft-acp-core/actions/worker-documents'
import * as notifyActions from 'syft-acp-core/actions/notifications'
import * as modalActions from 'syft-acp-core/store/modals/actions'
import { saveAttachment } from 'syft-acp-core/api/resources/attachments'
import WorkerCertificateFilesModal from 'syft-acp-core/components/Modal/WorkerCertificateFilesModal'

import FieldUploader from './FieldUploader'

/**
 * Contains a file uploader for editable tables. Used by the worker onboarding page.
 *
 * Note: 'canLoadDocuments' is a hack, since this is a temporary fix until
 * the new EditableTable has been completed.
 */
class FieldUploadWrapper extends React.PureComponent {
  static propTypes = {
    rowData: PropTypes.object.isRequired,
    fieldData: PropTypes.object.isRequired,
    workerDocuments: PropTypes.object.isRequired,
    actions: PropTypes.object.isRequired,
  }

  constructor() {
    super()
    this.state = {
      isUploading: false,
    }
  }

  get fieldData() {
    const { rowData, fieldData, workerDocuments } = this.props
    const { workerID, workerName } = fieldData
    const { uploadType, canLoadDocuments, editable } = rowData
    const workerDocs = Object.values(get(workerDocuments, `entitySets.workerID=${workerID}.entityMap`, {}))
    const workerDoc = workerDocs.find(d => d.type === uploadType)
    const documentID = get(workerDoc, 'id', null)

    return {
      workerName,
      workerID,
      documentData: workerDoc,
      documentType: uploadType,
      documentID,
      canLoadDocuments,
      viewOnly: !editable,
    }
  }

  /** Uploads a new file to the server. */
  // eslint-disable-next-line consistent-return
  uploadFile = async (accepted, rejected) => {
    const { workerID, workerName, documentID, documentData, documentType } = this.fieldData
    const { notify, updateWorkerDocumentAttachments } = this.props.actions

    if (accepted.length !== 1 || rejected.length !== 0) {
      return notify('error', {
        title: 'Could not upload file',
        message: 'Please select one image (.jpg or .png).',
      })
    }

    // Exit here if for some reason we can't get the document ID.
    // This should never happen through normal usage, but might happen if a bug causes data corruption.
    if (!documentID) {
      return notify('error', {
        title: 'Could not upload file',
        message: `${documentType} document (ID: ${documentID}) for this worker (${workerName} - ID: ${workerID}) does not exist. Try again after refreshing the page.`,
        autoDismiss: 0,
        action: {
          label: 'Refresh',
          callback: () => window.location.reload(),
        },
      })
    }

    // Everything is OK, so upload the file and attach it to the document.
    const data = accepted[0]

    this.setState({ isUploading: true })
    await saveAttachment({ documentID, file: data })
    updateWorkerDocumentAttachments({ workerID, id: documentID, amount: documentData.attachments.length + 1 })
    this.updateWorkerDocuments()
    this.setState({ isUploading: false })
  }

  /** Callback for the file viewer. This opens a modal window with all files for a specific certificate. */
  viewFiles = () => {
    const { workerID, documentType, viewOnly } = this.fieldData
    const { showModal } = this.props.actions
    showModal('workerCertificateFilesModal', {}, null, {
      activeField: documentType,
      activeWorker: workerID,
      hideDelete: viewOnly,
    })
  }

  /** Updates the worker's documents globally. */
  updateWorkerDocuments = documentID => {
    const { workerID } = this.fieldData
    const { listWorkerDocuments, fetchWorkerDocument } = this.props.actions

    listWorkerDocuments({ workerID })
    if (!documentID) return
    fetchWorkerDocument({ workerID, documentID })
  }

  render() {
    const { workerID, workerName, documentData, documentType, canLoadDocuments, viewOnly } = this.fieldData
    const { workerDocuments } = this.props
    const { isUploading } = this.state

    return (
      <div className="FileUploadWrapper">
        <WorkerCertificateFilesModal workerID={workerID} />
        <FieldUploader
          performInitialLoad={canLoadDocuments}
          documentUpload={this.uploadFile}
          documentView={this.viewFiles}
          documentLoad={this.updateWorkerDocuments}
          documentType={documentType}
          isGlobalLoading={isUploading || workerDocuments.isLoadingData}
          workerDoc={documentData}
          workerID={workerID}
          workerName={workerName}
          viewOnly={viewOnly}
        />
      </div>
    )
  }
}

export default connect(
  state => ({ workerDocuments: state.workerDocuments }),
  dispatch => ({
    actions: bindActionCreators({ ...workerDocumentActions, ...modalActions, ...notifyActions }, dispatch),
  }),
)(FieldUploadWrapper)
