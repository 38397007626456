// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import React from 'react'
import PropTypes from 'prop-types'

import PageTitle from 'syft-acp-core/components/PageTitle'
import SubNavigation from 'syft-acp-core/components/Navigation/SubNavigation'

const BizDev = ({ children }) => (
  <PageTitle title="Business Development">
    <div>
      <SubNavigation subPage="bizdev" />
      {children}
    </div>
  </PageTitle>
)
BizDev.propTypes = {
  children: PropTypes.node.isRequired,
}

export default BizDev
