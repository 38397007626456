import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { get } from 'lodash-es'
import * as filtersActions from 'syft-acp-core/store/filters/actions'
import { fetchAgencies, clearAgencies } from 'syft-acp-core/actions/agencies'
import { Button } from 'syft-acp-atoms/Button'

import {
  updateAgenciesRanks,
  removeFromAgenciesByRankValue,
  makeAgenciesOptions,
} from './dataAgenciesDropdownHelpers'
import DataAgenciesDropdownItem from './DataAgenciesDropdownItem'

import './DataAgenciesDropdown.css'

class DataAgenciesDropdown extends PureComponent {
  constructor(props) {
    super()
    const { value, agencies, data } = props
    const dataID = data?.id ?? null
    const platformID = data?.platformID ?? null
    this.state = {
      results: value?.length ? value : [{ agency_id: null, rank: 1, work_location_id: dataID }],
      // For platform ID=1 (Flex) there shouldn't be any agencies; only on Flex+ platforms.
      options: platformID !== 1 ? makeAgenciesOptions({ agencies, value }) : [],
    }
  }

  componentDidMount() {
    const { data, actions } = this.props
    const platformID = data?.platformID ?? null
    if (platformID) {
      actions.fetchAgencies(platformID)
    }
  }

  componentDidUpdate(prevProps) {
    const { value, data, agencies, actions } = this.props
    const dataID = data?.id ?? null

    if (data.platformID !== prevProps.data.platformID) {
      actions.fetchAgencies(data.platformID)
    }
    if (this.props.value !== prevProps.value) {
      this.setResults(value, dataID)
    }
    if (this.props.agencies !== prevProps.agencies) {
      this.setOptions(data, agencies, value)
    }
  }

  componentWillUnmount() {
    this.props.actions.clearAgencies()
  }

  setResults = (value, dataID) =>
    this.setState(() => ({
      results: value && value.length !== 0 ? value : [{ agency_id: null, rank: 1, work_location_id: dataID }],
    }))

  setOptions = (data, agencies, value) =>
    this.setState(() => ({
      options: data?.platformID !== 1 ? makeAgenciesOptions({ agencies, value }) : [],
    }))

  handleChangeDropdown = (value, rank, id) => {
    const updatedResults = updateAgenciesRanks({
      data: this.props.data,
      results: this.state.results,
      value,
      rank,
      id,
    })
    this.setState(() => ({
      results: updatedResults,
    }))

    this.props.onChange(updatedResults)
  }

  handleClick = () => {
    const sortResultsByRank = this.state.results.sort(
      (first, second) => get(first, 'rank', 0) - get(second, 'rank', 0),
    )
    const rank = get(sortResultsByRank, `[${this.state.results.length - 1}].rank`, 0) + 1
    const allData = [...this.state.results, { agency_id: null, rank, work_location_id: this.props.data.id }]
    this.setState(() => ({ results: allData }))
  }

  handleDeleteClick = rank => {
    const updatedResults = removeFromAgenciesByRankValue({
      results: this.state.results,
      rank,
    })

    this.setState(() => ({
      results: updatedResults,
    }))

    this.props.onChange(updatedResults)
  }

  render() {
    const { header, editable, disabled, value: values } = this.props

    const deletable = editable && !disabled

    return (
      <div className="DataAgenciesDropdown">
        {this.state.results.map(agency => (
          <DataAgenciesDropdownItem
            agency={agency}
            deletable={deletable}
            disabled={disabled}
            editable={editable}
            header={header}
            key={agency.rank}
            onChange={this.handleChangeDropdown}
            onDeleteClick={this.handleDeleteClick}
            options={this.state.options}
            values={values}
          />
        ))}
        <Button kind="success" onClick={this.handleClick}>
          New agency
        </Button>
      </div>
    )
  }
}

DataAgenciesDropdown.propTypes = {
  value: PropTypes.array.isRequired,
  disabled: PropTypes.bool,
  editable: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  header: PropTypes.string.isRequired,
  agencies: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
}

DataAgenciesDropdown.defaultProps = {
  disabled: false,
}

const mapStateToProps = (state, { onChange }) => ({
  actions: {
    setFilter: (name, results) => onChange(results),
  },
  agencies: state.agencies.entityMap,
  hasAgenciesData: state.agencies.hasData,
})

const mapDispatchToProps = (dispatch, { actions }) => {
  const boundActions = bindActionCreators(
    {
      ...filtersActions,
      fetchAgencies,
      clearAgencies,
    },
    dispatch,
  )
  return {
    actions: {
      ...boundActions,
      ...actions,
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DataAgenciesDropdown)
