export const trackingEvents = {
  PAGE: {
    VIEWED: 'adhoc_shifts_approval.page.viewed',
  },
  APPROVE_BUTTON: {
    CLICKED: 'adhoc_shifts_approval.approve_button.clicked',
  },
  REJECT_BUTTON: {
    CLICKED: 'adhoc_shifts_approval.reject_button.clicked',
  },
}
