import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

import './FormError.css'

class FormError extends PureComponent {
  static propTypes = {
    type: PropTypes.oneOf(['error', 'warning']),
    message: PropTypes.string,
    className: PropTypes.string,
  }

  static defaultProps = {
    type: 'error',
    message: '',
    className: '',
  }

  render() {
    return (
      <span className={`FormError type-${this.props.type} ${this.props.className}`}>{this.props.message}&nbsp;</span>
    )
  }
}

export default FormError
