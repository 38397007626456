export const trackingEvents = {
  LISTINGS_LIST: {
    PAGE: { VIEWED: 'listings_list.page.viewed' },
    VIEW_FILTERS: {
      CHANGED: 'listings_list.view_filters.changed',
    },
    DELETE: {
      CLICKED: 'listings_list.delete.clicked',
    },
    LISTING: {
      SELECTED: 'listings_list.listing.selected',
    },
  },
}
