import React from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import * as filtersActions from 'syft-acp-core/store/filters/actions'

import { filterSelect } from './FilterSelect'

class FilterOfferAgency extends React.Component {
  static propTypes = {
    actions: PropTypes.object,
    allowAny: PropTypes.bool,
    options: PropTypes.array,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func,
    className: PropTypes.string,
    query: PropTypes.object,
    small: PropTypes.bool,
    tiny: PropTypes.bool,
    type: PropTypes.string,
    defaultOption: PropTypes.string,
    disabled: PropTypes.bool,
  }

  static defaultProps = {
    defaultOption: 'Any',
    options: [
      { id: 'agency', _id: 1, label: 'Agency' },
      { id: 'not_agency', _id: 2, label: 'Not agency' },
    ],
    className: '',
    query: {},
    actions: {},
    allowAny: false,
    type: null,
    small: false,
    tiny: false,
    disabled: false,
  }

  onChange = val => {
    const { name, actions, onChange } = this.props
    actions.setFilter(name, val)
    !!onChange && onChange(this.props.name, val)
  }

  render() {
    const { allowAny, query, type, name, defaultOption, className, small, tiny, options, disabled } = this.props

    const value = query[name]
    const FilterSelect = filterSelect(options, defaultOption, type, false, false)
    return (
      <FilterSelect
        name={name}
        className={className}
        onChange={this.onChange}
        value={value}
        small={small}
        tiny={tiny}
        allowAny={allowAny}
        disabled={disabled}
      />
    )
  }
}

export default connect(
  state => ({
    query: state.routing.locationBeforeTransitions.query,
  }),
  dispatch => ({
    actions: bindActionCreators(filtersActions, dispatch),
  })
)(FilterOfferAgency)
