// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { isEqual } from 'lodash-es'

import { arePropertiesEqual } from 'syft-acp-core/store/filters/helpers'
import * as checkboxesActions from 'syft-acp-core/store/checkboxes/actions'
import LabeledCheckbox from 'syft-acp-core/components/LabeledCheckbox'

/**
 * Checkbox for use in the EntityList component.
 * This allows you to select a row.
 *
 * @param {Object} props Properties
 * @returns {XML} Pure EntitySelector component
 */

class EntitySelector extends Component {
  static propTypes = {
    value: PropTypes.oneOfType([PropTypes.object, PropTypes.array, PropTypes.string, PropTypes.number]),
    valueKeys: PropTypes.arrayOf(PropTypes.string),
    scope: PropTypes.string.isRequired,
    actions: PropTypes.objectOf(PropTypes.func).isRequired,
    items: PropTypes.array.isRequired,
    checked: PropTypes.bool,
  }

  static defaultProps = {
    value: undefined,
    valueKeys: undefined,
    checked: false,
  }

  handleCheck = checked => this.props.actions.checkItems([this.props.value], this.props.scope, checked)

  render() {
    // Determine if the checkbox should be checked.
    const isChecked = this.props.checked
      ? true
      : this.props.valueKeys
      ? // Offer a faster check if the 'valueKeys' option is supplied.
        this.props.items.find(i => arePropertiesEqual(i, this.props.value, this.props.valueKeys))
      : this.props.items.find(e => isEqual(e, this.props.value))

    return <LabeledCheckbox callback={this.handleCheck} checked={!!isChecked} />
  }
}

export default connect(
  (state, props) => ({
    items: state.checkboxes.items[props.scope] || [],
  }),
  dispatch => ({
    actions: bindActionCreators(checkboxesActions, dispatch),
  })
)(EntitySelector)
