// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import entityReducer from './generators/entityReducer'
import * as types from '../actions/action-types'
import * as entityTypes from './generators/entities'

export default entityReducer(
  'bankHolidayRegions',
  {
    [entityTypes.ENTITIES_FETCH_BEGIN]: types.BANK_HOLIDAY_REGIONS_FETCH_BEGIN,
    [entityTypes.ENTITIES_FETCH_SUCCEEDED]: types.BANK_HOLIDAY_REGIONS_FETCH_SUCCEEDED,
    [entityTypes.ENTITIES_FETCH_FAILED]: types.BANK_HOLIDAY_REGIONS_FETCH_FAILED,
  },
  {
    idKey: 'id',
    localEntityMap: true,
  }
)
