import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { pick, get } from 'lodash-es'
import { bindActionCreators } from 'redux'

import { Button, ButtonGroup } from 'syft-acp-atoms/Button'
import RecordControls from 'syft-acp-core/components/RecordSection/RecordControls'
import { deleteShift } from 'syft-acp-core/store/shifts/actions'
import { editListingShift } from 'syft-acp-core/store/listing-shifts/actions'
import { updateJob } from 'syft-acp-core/store/jobs/actions'
import * as modalActions from 'syft-acp-core/store/modals/actions'
import LoadingIndicator from 'syft-acp-util/components/LoadingIndicator'
import DuplicateShiftModal from 'syft-acp-core/components/Modal/DuplicateShiftModal'
import { withTrackingTriggerHoc } from 'syft-acp-util/withTrackingTriggerHoc'
import { withFlexFlagValueHoc } from 'syft-acp-util/withFlexFlagValueHoc'
import { entityList$ } from 'syft-acp-core/reducers/generators/entities'

import { listingOfferFactory } from './ListingButtons'
import { trackingEvents } from '../tracking'
import ListingStopNewWorkersButton from './ListingButtons/ListingStopNewWorkersButton'
import { handleConfirmationMessage } from './helpers'

class ListingMainInformationControls extends Component {
  static propTypes = {
    data: PropTypes.object.isRequired,
    actions: PropTypes.object.isRequired,
    isPristine: PropTypes.bool.isRequired,
    isSavingData: PropTypes.bool.isRequired,
    edit_bookings: PropTypes.bool.isRequired,
    isSyftForce: PropTypes.bool.isRequired,
    triggerEvent: PropTypes.func.isRequired,
    updatedData: PropTypes.object.isRequired,
    bookingsListData: PropTypes.object.isRequired,
    isLoadingData: PropTypes.bool.isRequired,
    reloadData: PropTypes.func.isRequired,
    listingID: PropTypes.number.isRequired,
    shiftID: PropTypes.number.isRequired,
  }

  saveAction = () => {
    const { data, listingID, triggerEvent, shiftID, updatedData, actions } = this.props
    // Filter this data
    const shiftData = pick(
      data,
      'id',
      'start_time',
      'end_time',
      'offer_rate',
      'external_booking_uuids',
      'category',
    )
    triggerEvent(trackingEvents.LISTING_INFO.SHIFT.SAVED, {
      shiftID,
      shiftData: { ...shiftData, ...updatedData?.job },
    })
    const overridePayrate = data?.overrides_summary?.client_pay_rate
    const overrideTime = data?.overrides_summary?.start_end_time
    const differentRate = updatedData?.job?.pay_rate
    const differentTime = updatedData?.start_time || updatedData?.end_time
    if (updatedData && Object.keys(updatedData)?.length) {
      const message = handleConfirmationMessage({
        differentTime,
        differentRate,
        overrideTime,
        overridePayrate,
      })
      if (message) {
        actions.showConfirmModal({
          question: (
            <>
              <div>{message}</div>
              <div>Are you sure you wish to proceed?</div>
            </>
          ),
          onConfirm: () => {
            if (updatedData.job) {
              actions.updateJob(data.job.id, updatedData.job)
            }
            actions.editListingShift(listingID, shiftData)
          },
          modalConfirm: 'Yes',
        })
      } else {
        if (updatedData.job) {
          actions.updateJob(data.job.id, updatedData.job)
        }
        actions.editListingShift(listingID, shiftData)
      }
    } else {
      actions.editListingShift(listingID, shiftData)
    }
  }

  duplicateShift = () => {
    const {
      triggerEvent,
      shiftID,
      actions: { showModal },
    } = this.props
    triggerEvent(trackingEvents.LISTING_INFO.DUPLICATE_SHIFT.CLICKED, { shiftID })
    showModal('duplicateShiftModal')
  }

  deleteAction = () => {
    const { data, triggerEvent, actions, numberOfJobs, listingID } = this.props
    const numberOfShifts = data?.job?.shifts?.length
    const removeListing = numberOfJobs === 1 && numberOfShifts === 1
    const jobID = data?.job?.id
    const shiftID = data.id
    const textDeleteWholeJob = numberOfShifts === 1 ? 'job' : 'shift'
    const textDelete = removeListing ? 'listing' : textDeleteWholeJob
    triggerEvent(trackingEvents.LISTING_INFO.DELETE_SHIFT.CLICKED, { shiftID })

    actions.showConfirmModal({
      question: `Are you sure you want to delete this ${textDelete}?`,
      onConfirm: () => {
        actions.deleteShift({ shiftID, jobID, listingID, removeListing })
        triggerEvent(trackingEvents.LISTING_INFO.SHIFT.DELETED, { shiftID })
      },
    })
  }

  handleOfferToAll = () => {
    const { triggerEvent, shiftID } = this.props
    triggerEvent(trackingEvents.LISTING_INFO.OFFER_TO_ALL.CLICKED, { shiftID })
  }

  handleOfferToStaff = () => {
    const { triggerEvent, shiftID } = this.props
    triggerEvent(trackingEvents.LISTING_INFO.OFFER_TO_STAFF.CLICKED, { shiftID })
  }

  handleStopOffers = (shiftID, stopNewWorkers) => {
    const { triggerEvent } = this.props
    triggerEvent(trackingEvents.LISTING_INFO.STOP_OFFERS.CLICKED, { shiftID, stopNewWorkers })
  }

  handleDuplicateShift = data => {
    const { triggerEvent, shiftID } = this.props
    triggerEvent(trackingEvents.LISTING_INFO.SHIFT.DUPLICATED, { shiftID, data })
  }

  render() {
    const {
      shiftID,
      isSavingData,
      isPristine,
      isLoadingData,
      reloadData,
      listingID,
      isSyftForce,
      edit_bookings: editBookings,
      data,
    } = this.props
    const OfferToAllButton = listingOfferFactory('all', this.handleOfferToAll)
    const OfferToStaffButton = listingOfferFactory('staff', this.handleOfferToStaff)
    return (
      <RecordControls className="overlayed">
        <DuplicateShiftModal
          jobID={data?.job_id}
          callback={this.handleDuplicateShift}
          shiftID={shiftID}
          reloadData={reloadData}
        />
        <div className="right">
          <LoadingIndicator loadingState={isSavingData || isLoadingData} />{' '}
          <ButtonGroup>
            <OfferToAllButton
              shiftID={shiftID}
              listingID={listingID}
              label="Offer to all"
              disabled={isSavingData || isLoadingData}
            />{' '}
            <OfferToStaffButton
              shiftID={shiftID}
              listingID={listingID}
              label="Offer to staff"
              disabled={isSavingData || isLoadingData}
            />{' '}
            <ListingStopNewWorkersButton
              callback={this.handleStopOffers}
              isSyftForce={isSyftForce}
              shiftID={shiftID}
            />
          </ButtonGroup>
          {'  '}
          <Button
            kind="primary"
            onClick={this.duplicateShift}
            disabled={isSavingData || isLoadingData || !editBookings}
          >
            Duplicate shift
          </Button>{' '}
          <Button
            kind="success"
            onClick={this.saveAction}
            disabled={isSavingData || isLoadingData || isPristine}
          >
            Save changes
          </Button>{' '}
          <Button kind="danger" onClick={this.deleteAction}>
            Delete shift
          </Button>
        </div>
      </RecordControls>
    )
  }
}

export default connect(
  ({ listingShifts: { isSavingData, isLoadingData }, listings, listingShiftBookings }, props) => ({
    isSyftForce: Number(get(listings, `entityMap[${props.listingID}.platform_id`)) !== 1,
    bookingsListData: entityList$(listingShiftBookings, { shiftID: props.shiftID }),
    numberOfJobs: listings?.entityMap?.[props?.listingID]?.jobs?.length,
    isSavingData,
    isLoadingData,
  }),
  dispatch => ({
    actions: bindActionCreators({ ...modalActions, updateJob, editListingShift, deleteShift }, dispatch),
  }),
)(withTrackingTriggerHoc(withFlexFlagValueHoc(ListingMainInformationControls, 'edit_bookings')))
