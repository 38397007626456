// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import React from 'react'

import PaymentsList from 'syft-acp-core/entities/PaymentsList'
import PageView from 'syft-acp-core/components/PageView'
import SubNavigationFinance from './SubNavigation'

export const FinancePayments = () => (
  <>
    <SubNavigationFinance />
    <PageView title={['Finance', 'Payments']} entityPage>
      <PaymentsList />
    </PageView>
  </>
)
