import React from 'react'
import { TrackingProvider } from '@indeed/flex-tracking-context'

type Props = {
  children: React.ReactNode
  employerId: number
  rateCardId: number
}

export const TimeDependentRatesTrackingProvider = ({ children, employerId, rateCardId }: Props) => (
  <TrackingProvider value={{ context: { employer_id: employerId, rate_card_id: rateCardId } }}>
    {children}
  </TrackingProvider>
)
