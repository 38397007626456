import React, { useEffect, useState, useMemo } from 'react'
import { debounce } from 'lodash-es'
import { connect, ConnectedProps } from 'react-redux'
import { bindActionCreators } from 'redux'
import { entityList$ } from 'syft-acp-core/reducers/generators/entities'
import { fetchAgencyVenues } from 'syft-acp-core/store/agency-venues/actions'
import * as filtersActions from 'syft-acp-core/store/filters/actions'

import FilterHierarchyMultiselect from '../FilterHierarchyMultiselect'
import { Store } from 'syft-acp-core/store'

import { getInitialValues, getVenueOptions } from './helpers'

import { Props, Option } from './types'
import queryString from 'query-string'

type AllProps = ConnectedProps<typeof storeConnector> & Props

export const storeConnector = connect(
  (state: Store) => ({
    venues: entityList$(state.agencyVenues),
    query: state?.routing?.locationBeforeTransitions?.query,
    isLoading: state?.agencyVenues?.isLoadingData,
  }),
  dispatch => ({
    actions: bindActionCreators({ fetchVenues: fetchAgencyVenues, ...filtersActions }, dispatch),
  })
)

const FilterAgencyVenuesMultiselect = ({ venues, actions, name, query, isLoading, queryName, onChange }: AllProps) => {
  const initialValues = getInitialValues(query)
  const [venueName, setVenueName] = useState('')
  const [selected, setSelected] = useState(initialValues)

  const debouncedFilter = useMemo(
    () =>
      debounce(() => {
        const params = queryString.parse(window.location.search)
        const employerId: any = params['employer_id'] == null ? undefined : Number(params['employer_id'])
        actions.fetchVenues({
          venueName,
          employerId: employerId,
        })
      }, 500),
    [actions, venueName]
  )

  const debouncedTextFilter = useMemo(
    () => debounce(() => (queryName ? actions.setFilter(queryName, venueName) : null), 300, { trailing: true }),
    [actions, queryName, venueName]
  )

  useEffect(() => {
    debouncedFilter()
    debouncedTextFilter()
  }, [debouncedFilter, debouncedTextFilter])

  const venueOptions = getVenueOptions(venueName, venues)
  const onChangeHandler = (val: Option[]) => {
    setSelected(val)
    const ids = val.map(item => item.value)
    actions.setFilter(name, ids)
    onChange && onChange(name, ids)
  }
  return (
    <FilterHierarchyMultiselect
      values={selected}
      handleSearch={setVenueName}
      onChange={onChangeHandler}
      refreshValues={false}
      isLoading={isLoading}
      options={venueOptions}
      withSearch
      placeholder="Venue name"
      resetValues
      deselectOption
    />
  )
}

export default storeConnector(FilterAgencyVenuesMultiselect)
