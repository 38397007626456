// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import React from 'react'
import ChangelogMD from '../../../CHANGELOG.md'

import PageView from 'syft-acp-core/components/PageView'
import PageTextChangelog from 'syft-acp-atoms/PageTextChangelog'

// Regexes for linking the issue codes.
const TRACKER_URL = 'https://syftapp.atlassian.net/browse/'
const ISSUE_PREFIXES = ['PTE', 'SPEAR', 'SUP']
const ISSUE_RE = new RegExp(
  `${ISSUE_PREFIXES.reduce((acc, prefix) => [...acc, `(\\*\\*(${prefix}-[0-9]*):\\*\\*)`], []).join('|')}`,
  'g',
)

/**
 * Links issues (e.g. CMS2-123) to the URL with issue information using Markdown.
 * E.g. 'CMS2-123' is changed into '[CMS2-123](https://syftapp.atlassian.net/browse/CMS2-123)'.
 *
 * @param {string} md Markdown content
 * @returns {string} Markdown content with issues linked
 */
const linkIssues = md => {
  // return md
  return md.replace(ISSUE_RE, `[$1](${TRACKER_URL}$2)`)
}

export const Changelog = props => (
  <PageTextChangelog>
    <PageView props={props} container textPage>
      {linkIssues(ChangelogMD)}
    </PageView>
  </PageTextChangelog>
)
