export const trackingEvents = {
  PAGE: {
    VIEWED: 'employer_rate_cards_list.page.viewed',
  },
  NEW: {
    CLICKED: 'employer_rate_cards_list.new.clicked',
  },
  ADVANCED: {
    CLICKED: 'employer_rate_cards_list.advanced.clicked',
  },
  FILTER: {
    CHANGED: 'employer_rate_cards_list.filter.changed',
  },
  BULK_DISABLE: {
    CLICKED: 'employer_rate_cards_list.bulk_disable.clicked',
    MODAL_VIEWED: 'employer_rate_cards_list.bulk_disable_modal.viewed',
    CONFIRM_CLICKED: 'employer_rate_cards_list.bulk_disable_modal_confirm.clicked',
  },
  BULK_ENABLE: {
    CLICKED: 'employer_rate_cards_list.bulk_enable.clicked',
    MODAL_VIEWED: 'employer_rate_cards_list.bulk_enable_modal.viewed',
    CONFIRM_CLICKED: 'employer_rate_cards_list.bulk_enable_modal_confirm.clicked',
  },
}
