// Syft ACP - Lib <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import './FilterUnit.css'

const FilterUnit = ({ small, tiny, children, className, fullWidth }) => (
  <span className={classnames('filter-unit', className, { small, tiny, full: fullWidth })}>{children}</span>
)

FilterUnit.propTypes = {
  small: PropTypes.bool,
  tiny: PropTypes.bool,
  fullWidth: PropTypes.bool,
  children: PropTypes.node,
  className: PropTypes.string,
}

FilterUnit.defaultProps = {
  small: false,
  tiny: false,
  fullWidth: false,
  children: null,
  className: '',
}

export default FilterUnit
