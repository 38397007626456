// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import React from 'react'
import iconFactory from 'syft-acp-atoms/Icons'

import './IconXCircle.css'

const IconXCircle = ({ tooltipID, testID }: { tooltipID: string; testID: string }) => {
  const IconFactory = iconFactory('x', 'Allowed')
  return (
    <a data-for={tooltipID} data-testid={testID} data-tip>
      <IconFactory fill="white" className="xCircleIcon" iconSize={10} />
    </a>
  )
}
export default IconXCircle
