// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import entityReducer from './generators/entityReducer'
import * as types from '../actions/action-types'
import * as entityTypes from './generators/entities'
import { LeaveRequestState } from '../store/leaveRequests/types'

export type LeaveREquestSucceeedAction = {
  type: typeof types.CONFIRM_SHIFT_BOOKING_SUCCEEDED
  request: { ids: number[]; status: boolean }
}

export const setSavingData = (state: LeaveRequestState) => ({
  ...state,
  isSavingData: true,
})

const statusChange = (state: LeaveRequestState, action: LeaveREquestSucceeedAction) => {
  const { entityMap } = state
  const newEntityMap = action.request.ids.reduce(
    (curEntityMap, id: number) => ({
      ...curEntityMap,
      [id]: { ...entityMap[id], processed: action.request.status },
    }),
    { ...entityMap }
  )

  return {
    ...state,
    entityMap: newEntityMap,
    isSavingData: false,
  }
}

export default entityReducer('leaveRequests', {
  [entityTypes.ENTITIES_FETCH_BEGIN]: types.WORKER_LEAVE_REQUEST_FETCH_BEGIN,
  [entityTypes.ENTITIES_FETCH_SUCCEEDED]: types.WORKER_LEAVE_REQUEST_FETCH_SUCCEEDED,
  [entityTypes.ENTITIES_FETCH_FAILED]: types.WORKER_LEAVE_REQUEST_FETCH_FAILED,
  [types.WORKER_LEAVE_REQUEST_CHANGE_STATUS_BEGIN]: setSavingData,
  [types.WORKER_LEAVE_REQUEST_CHANGE_STATUS_FAILED]: setSavingData,
  [types.WORKER_LEAVE_REQUEST_CHANGE_STATUS_SUCCEEDED]: statusChange,
})
