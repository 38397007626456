import { useEffect } from 'react'
import { useTrackingTrigger } from '@indeed/flex-tracking-context'

/**
 * Reduces repetition in appeals and conduct reviews list components.
 */
const useBasicTracking = (trackingEvents: Record<string, any>, reasonQueryKey: string) => {
  const triggerEvent = useTrackingTrigger()

  const onClickAssign = () => {
    triggerEvent(trackingEvents.ASSIGN.CLICKED)
  }

  const onChangeAssignee = (val: string) => {
    if (val === 'unassigned') triggerEvent(trackingEvents.FILTERS.ASSIGNEE.CHANGED, { assigned: 'false' })
    else triggerEvent(trackingEvents.FILTERS.ASSIGNEE.CHANGED, { assignee_id: val, assigned: 'true' })
  }

  const onChangeOutcome = (val: string) => {
    triggerEvent(trackingEvents.FILTERS.OUTCOME.CHANGED, { outcome_reached: val })
  }

  const onChangeReason = (val: string) => {
    triggerEvent(trackingEvents.FILTERS.REASON.CHANGED, { [reasonQueryKey]: val })
  }

  const onClickApprove = () => {
    triggerEvent(trackingEvents.APPROVE.CLICKED)
  }

  useEffect(() => {
    triggerEvent(trackingEvents.PAGE.VIEWED)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return { onClickAssign, onChangeAssignee, onChangeOutcome, onChangeReason, onClickApprove }
}

export default useBasicTracking
