// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import React from 'react'
import PropTypes from 'prop-types'

import CommissionsTableList from 'syft-acp-core/entities2/CommissionsList'
import PageView from 'syft-acp-core/components/PageView'
import SubNavigation from 'syft-acp-core/components/Navigation/SubNavigation'

export const CommissionsList = ({ match: { params } }) => {
  return (
    <>
      <SubNavigation subPage="employers" data={{ employerID: Number(params.id ?? params.employer_id) }} />
      <PageView title={['Commissions', 'Overview']} entityPage>
        <CommissionsTableList query={params} />
      </PageView>
    </>
  )
}

CommissionsList.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.object.isRequired,
  }),
}
