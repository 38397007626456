import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { get } from 'lodash-es'

import { store } from 'syft-acp-core/store'
import { fetchAdminListing } from 'syft-acp-core/store/listings/actions'
import { fetchAdminShiftListing } from 'syft-acp-core/store/listing-shifts/actions'
import RecordSection from 'syft-acp-core/components/RecordSection'
import entityDetailWrapper from 'syft-acp-util/entityDetail'
import EntityAlert, { EntityAlertConnected } from 'syft-acp-core/components/Alerts/EntityAlert'
import { mainInformationStructure } from '../structure'
import ListingMainInformationControls from './ListingMainInformationControls'
import { navigateListing } from './navigate'
import { setObjectProperty } from './helpers'

const JobsAlert = EntityAlertConnected('jobs')
class ListingMainInformation extends PureComponent {
  constructor() {
    super()
    this.state = {
      updatedData: {},
    }
  }

  componentDidMount() {
    this.checkData(this.props)
  }

  checkData = props => {
    const shiftID = props.id
    if (!props.entityDetail[shiftID]) {
      const info = get(store.getState(), `listingShifts.entityMap[${shiftID}]`, {})
      const isLoading = get(store.getState(), `listingShifts.isLoadingData`, false)

      const { listingID } = props
      if (!info.id && !isLoading) {
        this.reloadData(listingID, shiftID)
      }
    }
  }

  resetState = () => {
    this.setState(() => ({
      updatedData: {},
    }))
  }

  reloadData = (
    listingID = this.props.listingID,
    shiftID = this.props.id,
    jobID = null,
    reloadShift = false,
    navigate = false,
  ) => {
    // Note: this kept calling the fetch operation over and over again
    // when called in componentDidUpdate(). <ADMN-291>
    const { actions } = this.props
    actions.fetchAdminShiftListing(listingID, shiftID)
    if (reloadShift) {
      actions.fetchAdminListing(listingID)
    }
    // Navigate the user to the requested shift ID.
    // This is done after duplicating the shift.
    if (navigate) {
      navigateListing({ listingID, jobID, shiftID })
    }
  }

  updateActionHandler = (path, value, ...rest) => {
    const { actionUpdate, job } = this.props
    if (path === 'start_time' || path === 'end_time') {
      this.setState(() => {
        return {
          updatedData: {
            [path]: value,
          },
        }
      })
    }
    if (path.startsWith('job.')) {
      setObjectProperty(this.state.updatedData, path, value)
      if (path.startsWith('job.uniform')) {
        this.setState(() => ({
          updatedData: {
            job: {
              uniform: {
                ...job?.uniform,
                ...this.state.updatedData?.job?.uniform,
              },
            },
          },
        }))
      } else {
        this.setState(() => ({
          updatedData: this.state.updatedData,
        }))
      }
    }

    actionUpdate(path, value, ...rest)
  }

  render() {
    const {
      id,
      data,
      initialData,
      isPristine,
      lastBody,
      listingID,
      entityDetail,
      jobDependentRates,
      enableListingDetailRoleChanges,
      venue,
      roleSelectedID,
      job,
    } = this.props
    const mainData = {
      ...initialData,
      ...entityDetail[id],
      ...data,
      essential_skill_ids: job.essential_skill_ids || [],
      venue,
    }
    const updatedJob = this.state?.updatedData?.job ? { ...job, ...this.state.updatedData.job } : job
    const shiftDependentRates = get(mainData, 'job_time_dependent_rates', [])
    const updatedDependetRates = shiftDependentRates.map(item => {
      const updatedRates = jobDependentRates.find(rate => rate.id === item.id)
      return updatedRates
    })
    const updatedMainData = {
      ...mainData,
      job_time_dependent_rates: updatedDependetRates,
      job: updatedJob,
    }
    return (
      <div>
        <ListingMainInformationControls
          data={updatedMainData}
          job={job}
          isPristine={isPristine}
          reloadData={this.reloadData}
          shiftID={id}
          listingID={listingID}
          updatedData={this.state.updatedData}
          resetState={this.resetState}
        />
        <RecordSection
          title="Shift information"
          data={updatedMainData}
          noRounding
          structure={mainInformationStructure(
            updatedDependetRates,
            enableListingDetailRoleChanges,
            roleSelectedID,
          )}
          update={this.updateActionHandler}
          notificationsNode={
            <div>
              <EntityAlert lastBody={lastBody} />
              <JobsAlert />
            </div>
          }
        />
      </div>
    )
  }
}

ListingMainInformation.propTypes = {
  data: PropTypes.object.isRequired,
  job: PropTypes.object.isRequired,
  isPristine: PropTypes.bool.isRequired,
  enableListingDetailRoleChanges: PropTypes.bool.isRequired,
  initialData: PropTypes.object.isRequired,
  lastBody: PropTypes.object.isRequired,
  actionUpdate: PropTypes.func.isRequired,
  jobDependentRates: PropTypes.array.isRequired,
  listingID: PropTypes.number.isRequired,
  roleSelectedID: PropTypes.number,
  id: PropTypes.number.isRequired,
  actions: PropTypes.object.isRequired,
  entityDetail: PropTypes.array.isRequired,
  venue: PropTypes.object.isRequired,
}

export default connect(
  (state, props) => ({
    lastMessage: state.listingShifts.lastMessage,
    roleSelectedID: state.dataTabs.role_id,
    lastBody: state.listingShifts.lastBody,
    jobDependentRates: get(state, `jobs.entityDetail[${props.jobID}].job_time_dependent_rates`, []),
  }),
  dispatch => ({
    actions: bindActionCreators({ fetchAdminShiftListing, fetchAdminListing }, dispatch),
  }),
)(entityDetailWrapper('listingShifts', ListingMainInformation))
