import React, { memo } from 'react'

import CopyableText from 'syft-acp-atoms/CopyableText'

import { CellCopyProps as Props } from './CellCopy.types'
import './CellCopy.scss'

/** Allows the value of a cell to be copied. This component wraps around a <CellLink>. */
const CellCopy = ({ value, children }: Props) => (
  <span className="acp-table-copyable">
    <CopyableText copyValue={value}>{children}</CopyableText>
  </span>
)

export default memo(CellCopy)
