// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import React from 'react'

import UsersList from 'syft-acp-core/entities2/UsersList'
import PageView from 'syft-acp-core/components/PageView'
import SubNavigationAdmin from '../../Admin/SubNavigation'

export const EntityUsersList = () => (
  <>
    <SubNavigationAdmin />
    <PageView title={['Users', 'Overview']} container>
      <UsersList />
    </PageView>
  </>
)
