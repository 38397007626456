import React, { PureComponent } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { get } from 'lodash-es'
import classNames from 'classnames'

import { formatTimeAndRelative } from 'syft-acp-util/formatting'
import { showConfirmModal } from 'syft-acp-core/store/modals/actions'
import { Button, ButtonLink } from 'syft-acp-atoms/Button'
import Table from 'syft-acp-util/components/Table'

import './DocumentAttachment.css'

class DocumentAttachment extends PureComponent {
  static propTypes = {
    // 'attachment' can be null if it hasn't been created yet,
    // and we're displaying a placeholder.
    attachment: PropTypes.object,
    actions: PropTypes.objectOf(PropTypes.func).isRequired,
    index: PropTypes.number.isRequired,
    busy: PropTypes.bool.isRequired,
    deleteAttachment: PropTypes.func.isRequired,
    documentID: PropTypes.number.isRequired,
    restrictedDoc: PropTypes.bool.isRequired,
    hideDelete: PropTypes.bool,
  }

  static defaultProps = {
    // 'attachment' can be null if it hasn't been created yet,
    // and we're displaying a placeholder.
    attachment: null,
  }

  formatDate = str => {
    if (str == null) return '–'
    return formatTimeAndRelative(str)
  }

  render() {
    const { actions, attachment, index, deleteAttachment, documentID, busy, restrictedDoc, hideDelete } =
      this.props

    const created = get(attachment, 'created_at', false)
    const updated = get(attachment, 'updated_at', false)
    const deleted = get(attachment, 'deleted_at', null)
    const url = get(attachment, 'url', '')
    const uploader = get(attachment, 'uploaded_by', false)

    const deleteAttachmentHandler = () => {
      actions.showConfirmModal({
        modalHeader: 'Confirm deletion?',
        modalConfirm: 'Delete attachment',
        modalCancel: 'Cancel',
        question: 'Are you sure you want to delete this attachment? This action cannot be undone.',
        onConfirm: () => {
          deleteAttachment(documentID, attachment)
        },
      })
    }

    return (
      <div
        className={classNames('DocumentAttachment', { isDeleted: deleted != null })}
        data-testid="attachment-table"
      >
        <Table colBorders bordered topRowBorder className="attachment-table">
          <tbody>
            <tr>
              <td rowSpan={5} className="idx">
                {index + 1}
              </td>
            </tr>
            <tr>
              <th className="top header">Uploaded by</th>
              <td className="top">{uploader || '–'}</td>
              {deleted == null && (
                <td rowSpan={4} className="btns top">
                  {!restrictedDoc && (
                    <ButtonLink
                      className="view"
                      block
                      target="_blank"
                      disabled={!created || busy}
                      external
                      to={url}
                    >
                      View
                    </ButtonLink>
                  )}
                  {restrictedDoc && (
                    <ButtonLink className="view" block disabled>
                      View
                    </ButtonLink>
                  )}
                  {!hideDelete && (
                    <Button block onClick={deleteAttachmentHandler} disabled={!created || busy} kind="danger">
                      Delete
                    </Button>
                  )}
                </td>
              )}
            </tr>
            <tr>
              <th className="header">Created</th>
              <td>{created ? this.formatDate(created) : '–'}</td>
            </tr>
            <tr>
              <th className="header">Updated</th>
              <td>{updated ? this.formatDate(updated) : '–'}</td>
            </tr>
            {deleted != null && (
              <tr>
                <th className="header">Deleted</th>
                <td>{deleted ? this.formatDate(deleted) : '–'}</td>
              </tr>
            )}
          </tbody>
        </Table>
      </div>
    )
  }
}

export default connect(null, dispatch => ({
  actions: bindActionCreators(
    {
      showConfirmModal,
    },
    dispatch,
  ),
}))(DocumentAttachment)
