import React, { useCallback, useEffect } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { useTrackingTrigger } from '@indeed/flex-tracking-context'

import RecordSection from 'syft-acp-core/components/RecordSection'
import {
  consentWorkerMarketingPreferences,
  fetchWorkerMarketingPreferences,
} from 'syft-acp-core/store/workerMarketingPreferences/actions'
import {
  selectWorkerMarketingPreferencesById,
  selectIsWorkerMarketingPreferencesUpdating,
} from 'syft-acp-core/store/workerMarketingPreferences/selectors'

import {
  RecordWorkerMarketingPreferencesProps as Props,
  RecordWorkerMarketingPreferencesOwnProps as OwnProps,
} from './RecordWorkerMarketingPreferences.types'
import LoadingSpinner from 'syft-acp-atoms/LoadingSpinner'
import { useGetFieldsSectionStructure } from './RecordWorkerMarketingPreferences.hooks'
import { trackingEvents } from './RecordWorkerMarketingPreferences.tracking'

export const storeConnector = connect(
  (state, ownProps: OwnProps) => ({
    workerMarketingPreferences: selectWorkerMarketingPreferencesById(state, ownProps.workerID),
    isUpdating: selectIsWorkerMarketingPreferencesUpdating(state),
  }),
  dispatch => ({
    actions: bindActionCreators(
      { fetchWorkerMarketingPreferences, consentWorkerMarketingPreferences },
      dispatch,
    ),
  }),
)

const RecordWorkerMarketingPreferences = ({
  actions,
  workerID,
  workerMarketingPreferences,
  isUpdating,
}: Props) => {
  const triggerEvent = useTrackingTrigger()

  useEffect(() => {
    if (workerID) {
      actions.fetchWorkerMarketingPreferences(workerID)
    }
  }, [actions, workerID])

  const onUpdate = useCallback(
    (preference, value) => {
      triggerEvent(trackingEvents.CONSENT_CHANGE, {
        preference,
        value,
      })

      actions.consentWorkerMarketingPreferences({
        worker_id: workerID,
        preference,
        value,
      })
    },
    [workerID, actions, triggerEvent],
  )

  const structure = useGetFieldsSectionStructure(isUpdating)

  return (
    <RecordSection
      title="Marketing consent"
      titleLabels={<LoadingSpinner isLoading={isUpdating} />}
      data={workerMarketingPreferences}
      structure={structure}
      footerText="Keep up to date with marketing communications, including job offers, newsletters and prize draws"
      update={onUpdate}
    />
  )
}

export default storeConnector(RecordWorkerMarketingPreferences)
