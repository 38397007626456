// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { get, debounce } from 'lodash-es'
import { withTrackingTriggerHoc } from 'syft-acp-util/withTrackingTriggerHoc'

import { showModal } from 'syft-acp-core/store/modals/actions'
import { store } from 'syft-acp-core/store'
import * as employerBlocklistActions from 'syft-acp-core/actions/employer-blocklist'
import * as tableType from 'syft-acp-core/components/EntityList/'
import entityList, { ACTION_DELETE } from 'syft-acp-util/entityList'
import * as filterTypes from 'syft-acp-util/components/FilterForm/FilterForm'

import EmployerBlocklistListWrapper from './EmployerBlocklistListWrapper'
import { trackingEvents } from './EmployerBlocklistList.tracking'
import { Flex, Input } from '@indeed/ifl-components'

const tableFormat = [
  { type: tableType.SELECTOR, val: 'worker.id' },
  { type: tableType.TXT, val: 'worker.id', header: 'ID', numeric: true },
  { type: tableType.USER_LINE, val: 'worker', header: 'Name', classes: ['main'] },
  { type: tableType.RATING, val: 'worker.average_rating', header: 'Avg. Rating' },
]
const filterFormat = null

const urlGenerator = rowData => {
  const id = get(rowData, 'worker.id', null)
  return id ? `/entity/workers/view/${rowData.worker.id}` : null
}

class EmployerBlocklistList extends PureComponent {
  static propTypes = {
    actions: PropTypes.objectOf(PropTypes.func).isRequired,
    employerID: PropTypes.number.isRequired,
    triggerEvent: PropTypes.func.isRequired,
  }

  constructor() {
    super()
    this.state = {
      BlockList: null,
      searchTerm: '',
    }
  }

  actionFormat = [
    {
      label: 'Remove selected',
      type: filterTypes.TYPE_BUTTON,
      action: ACTION_DELETE,
      buttonType: 'danger',
    },
    {
      label: 'Add new',
      type: filterTypes.TYPE_BUTTON,
      global: true,
      action: () => store.dispatch(showModal('addWorkerToBlocklistModal')),
      buttonType: 'success',
    },
  ]

  componentDidMount() {
    // Construct the blocklist component.
    const BlockList = entityList(
      EmployerBlocklistListWrapper,
      tableFormat,
      filterFormat,
      this.actionFormat,
      'employerBlocklists',
      {
        fetchEntities: employerBlocklistActions.fetchEmployerBlocklist,
      },
      {
        deleteEntities: this.unblockWorkers,
        idKey: 'worker.id',
        showResultCount: false,
        fullWidth: true,
      },
    )

    this.setState({ BlockList })
  }

  unblockWorkers = selected => {
    const { actions, employerID, triggerEvent } = this.props
    actions.removeFromEmployerBlocklist(employerID, selected)
    triggerEvent(trackingEvents.REMOVE_SELECTED.CLICKED, {
      worker_ids: selected,
    })
  }

  debouncedSearchTerm = debounce(searchTerm => {
    this.setState({ searchTerm })
  }, 300)

  render() {
    const { BlockList } = this.state
    if (!BlockList) return null

    return (
      <BlockList
        {...this.props}
        urlGenerator={urlGenerator}
        extraButtons={
          <Flex sx={{ justifyContent: 'end', mb: -6, pt: 3 }}>
            <Input
              placeholder="Search by ID / Name"
              size="sm"
              onChange={({ target: { value } }) => this.debouncedSearchTerm(value)}
            />
          </Flex>
        }
        useFilterForm
        noFilterContainer
        pagination
        query={{ employerID: this.props.employerID, workerIdOrName: this.state.searchTerm }}
      />
    )
  }
}

export default connect(
  state => ({
    isLoadingBlocklists: state.employerBlocklists.isLoadingData,
  }),
  dispatch => ({
    actions: bindActionCreators(employerBlocklistActions, dispatch),
  }),
)(withTrackingTriggerHoc(EmployerBlocklistList))
