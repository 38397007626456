export const API_VERSION_V2 = 'api/v2'

// TODO: import V3 from package
export const API_VERSION_V3 = 'api/v3'

/** Pact server for local development. This is used for querying a Pact mock
 *  server for contract testing. */
export const API_BASE_PACT = process.env.REACT_APP_API_PACT || 'http://localhost:4002/'

export const v3APIs = ['/roles', '/industries']
