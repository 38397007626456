import React from 'react'

import PageView from 'syft-acp-core/components/PageView'
import AppealDetail from 'syft-acp-core/entities/AppealDetail'

import ConductSubNav from './ConductSubNav'
import { RouteComponentProps } from 'react-router-dom'

export const AppealsView = ({ match: { params } }: RouteComponentProps<{ id: string }>) => (
  <>
    <ConductSubNav />
    <PageView title={['Appeals', `Viewing appeal #${params.id}`]}>
      <AppealDetail id={Number(params.id)} />
    </PageView>
  </>
)
