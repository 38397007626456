export const trackingEvents = {
  REFERRAL_CSV_MODAL: {
    DOWNLOAD: 'referral_csv_modal.download.clicked',
  },
  PAYROLL_MODAL: {
    DOWNLOAD: 'payroll_modal.download.clicked',
    UPLOAD: 'payroll_modal.upload.clicked',
  },
  GDRIVE_INVOICE_MODAL: {
    UPLOAD: 'gdrive_invoice_modal.upload.clicked',
  },
  CSV_INVOICE_MODAL: {
    DOWNLOAD: 'csv_invoice_modal.download.clicked',
  },
  LISTING_EMAIL_CSV_MODAL: {
    CLOSED: 'listing_email_csv_modal.closed',
    CSV_REQUEST: {
      SUCCEEDED: 'listing_email_csv_modal.csv_request.succeeded',
      FAILED: 'listing_email_csv_modal.csv_request.failed',
    },
  },
  ADD_AGENCY_EMPLOYER: {
    SUBMIT: 'add_agency_employer_modal.submit.clicked',
  },
}
