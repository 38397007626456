// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import React from 'react'

import { TrackingTrigger, TrackingProvider } from '@indeed/flex-tracking-context'

import PageView from 'syft-acp-core/components/PageView'
import WorkerDetail from 'syft-acp-core/entities/WorkerDetail/WorkerDetail'
import { trackingEvents } from 'syft-acp-core/entities/WorkerDetail/WorkerDetail.tracking'
import { WorkerSubNavigation } from './SubNavigation'

export const EntityWorkersView = ({ match: { params } }) => (
  <>
    <TrackingProvider
      value={{
        context: {
          worker_id: params.id,
        },
      }}
    >
      <TrackingTrigger
        onLoad={{
          event: trackingEvents.PAGE.VIEWED,
        }}
      />
      <WorkerSubNavigation workerID={params.id} />
      <PageView title={['Workers', `Viewing worker ${params.id}`]}>
        <WorkerDetail id={Number(params.id)} />
      </PageView>
    </TrackingProvider>
  </>
)
