import * as U from 'karet.util'
import { isFunction, isString, omit, get, pick, noop } from 'lodash-es'
import downloadAsCSV from 'syft-acp-core/api/csv'

import { A_VERY_LARGE_NUMBER, reqDefaults } from 'syft-acp-core/api/call'
import { apiURL } from 'syft-acp-core/api/endpoints'
import { registerError } from 'syft-acp-core/lib/errorLog'
import { store } from 'syft-acp-core/store'
import { filterAcceptedMetadata, metaHeaderConvert, objCamelToSnake } from './meta'
import { jsonBody } from './params'
import { getSetView, dispatchInit, dispatchCallBegin, dispatchCallFinish } from './cache'

// Supported REST methods.
const methods = ['GET', 'POST', 'PUT', 'PATCH', 'DELETE']
// Response codes that are considered succesful.
const okResponses = [200, 201]

// Types of query string options a resource supports.
export const optionTypes = {
  WORKER_NAME: 'WORKER_NAME',
  NUMBER: 'NUMBER',
  TIMESTAMP: 'TIMESTAMP',
  ANY: 'ANY',
}

/** Default arguments for paginated resources. */
const paginationDefaults = {
  page: 1,
  per_page: 25,
  offset: 0,
}

// Contains a list of all defined resources.
const resources = []

// Convert arguments to snake_case, if requested.
const convertArgsCase = (args, doConversion) => {
  if (!doConversion) return args
  return objCamelToSnake(args)
}

/** Returns the user's current location (address bar contents). */
const getUserLocation = () => {
  const loc = get(store.getState(), 'routing.locationBeforeTransitions', {})
  return pick(loc, ['pathname', 'search', 'query'])
}

/** Returns true or false for whether all mandatory arguments are present. */
const hasRequiredOptions = (argsObj, mandatoryList) => {
  // In case no mandatory arguments are defined at all.
  if (!mandatoryList || mandatoryList.length === 0) return true

  // Check if the arguments set contains every item on the list.
  const argsSet = new Set(Object.keys(argsObj))
  const hasAllOpts = mandatoryList.reduce((bool, opt) => bool && argsSet.has(opt), true)
  return hasAllOpts
}

/** Initializes all API resources with default (empty) data; unless there is rehydrated data. */
export const initAcpResources = () => {
  resources.forEach(res => res.initData())
}

/**
 * Examines both an error object and a response object to extract error information.
 * This is called when something goes wrong in processCall().
 * Any of the data returned may be null, depending on availability.
 *
 * 'hasError' explicitly states that a call went wrong; in some cases the error might be immaterial.
 * 'hasBody' indicates that the request has a parseable body with an error from the API inside.
 */
const unpackError = (err, res, req, resource, json, userLoc, hasError = true, hasBody = false) => {
  const reqMethod = get(req, 'method', null)
  const reqEndpoint = get(resource, 'uri.0', null)
  const reqHeaderSrc = get(res, 'headers', null)
  const reqHeaders = reqHeaderSrc
    ? [...reqHeaderSrc].reduce((headers, header) => ({ ...headers, [header[0]]: header[1] }), {})
    : null

  const reqStatusCode = get(res, 'status', null)
  const reqStatusText = get(res, 'statusText', null)
  const reqURL = get(res, 'url', null)

  const errCode = get(err, 'code', null)
  const errStack = get(err, 'stack', null)
  // The error's name will be something like 'Bad Request'.
  // If the request has a parsed body, we'd prefer the API's explanation, e.g. 'bad_request'.
  // Having an error object and having a body is usually mutually exclusive.
  const errName = get(err, 'name', get(json, 'error', null))
  // The message is preferably the 'message' from the error object (e.g. 'Bad Request').
  // Otherwise, the (probably) more extensive explanation from the API.
  const errMessage = get(err, 'message', json ? get(json, '', err && String(err)) : err && String(err))
  // If this is an API error, 'debug.message' will contain a list of input that was incorrect.
  // E.g. "address is missing, address[line_1] is missing, address[city] is missing, address[post_code] is missing"
  const errSuggestion = get(json, 'debug.message', null)

  return {
    userLocation: userLoc,
    hasError,
    errCode,
    errStack,
    errName,
    errMessage,
    errSuggestion,
    reqHasBody: hasBody,
    reqBody: json,
    reqHeaders,
    reqMethod,
    reqEndpoint,
    reqStatusCode,
    reqStatusText,
    reqURL,
  }
}

/** Runs the call and extracts data. */
const processCall = async (req, resourceData, { onSuccess, onFail } = {}) => {
  let error

  let userLoc

  let json = null
  try {
    // For debugging purposes, we save the user's current location.
    // If something goes wrong, the error log will contain a link back here.
    userLoc = getUserLocation()
    const res = await fetch(req)
    const meta = metaHeaderConvert(filterAcceptedMetadata(res))
    const okResponse = ~okResponses.indexOf(res.status)

    // Attempt to get the response body.
    try {
      json = await res.json()
    } catch (err) {
      error = unpackError(err, res, req, resourceData, null, userLoc)
    }
    // If we didn't get an OK response, but we do have JSON data,
    // that means the error was given to us by the server in a normal response.
    if (!okResponse && json != null) {
      error = unpackError(null, res, req, resourceData, json, userLoc, true, true)
    }

    const returnData = { data: json, meta, res, ...(error ? { error } : {}) }

    if (!okResponse && onFail) onFail(returnData)
    if (okResponse && onSuccess) onSuccess(returnData)

    return returnData
  } catch (err) {
    const returnData = { error: unpackError(err, req, resourceData, null, null, userLoc), res: {} }
    if (onFail) onFail(returnData)
    return returnData
  }
}

/** Adds an error to the error log if a request fails. */
const logOnError = result => {
  if (!result.error || !result.error.hasError) {
    return
  }
  registerError(result.error, result.res)
}

/**
 * Merges in pagination arguments, if pagination is requested.
 */
export const mergePaginationArgs = (args, isPaginated = false) => {
  if (!isPaginated) return args
  return { ...paginationDefaults, ...args }
}

/**
 * Merges in static arguments that must always be sent.
 */
export const mergeStaticArgs = (args, staticArgs) => {
  if (!staticArgs) return args
  return { ...staticArgs, ...args }
}

/**
 * Does last modifications to resource arguments before making a request.
 * In some cases, arguments need to be changed just slightly before sending,
 * while they need to remain intact for the internal cache.
 */
const formatResArgs = (resArgs, resData) => {
  const newArgs = {}
  const opt = resData && resData.options

  // If this resource hasn't defined its options, just return the results verbatim.
  if (!opt) return resArgs

  for (const [key, value] of Object.entries(resArgs)) {
    // Worker names contain an ID and a name, and we need to send just the name.
    if (opt[key] === optionTypes.WORKER_NAME) {
      // eslint-disable-next-line prefer-destructuring
      newArgs[key] = value.split('$')[1]
    }
    // In all other cases, save the argument verbatim.
    else {
      newArgs[key] = value
    }
  }

  return newArgs
}

/**
 * Returns a Request() object with the appropriate data inserted.
 */
const makeResRequest = (uri, method, resData, resArgs = {}, auth = true) => {
  // Validate and possibly modify any arguments before sending.
  const args = formatResArgs(resArgs, resData)

  // If using GET, all data must go in the URI itself.
  if (method === 'GET') {
    const headers = reqDefaults(method, 'application/x-www-form-urlencoded', null, auth)
    return new Request(apiURL(uri, args), { ...headers })
  }
  // If not, populate the request's body with the args.body value (if it exists).
  else {
    const headers = reqDefaults(method, 'application/json', null, auth)
    return new Request(apiURL(uri), { ...headers, ...(args.body ? { body: jsonBody(args.body) } : {}) })
  }
}

/**
 * A valid URI value must be an array with either a function or string as element 0,
 * with the rest of the elements (1 or more) being strings that are valid REST methods.
 */
const checkURI = uri => {
  if (!uri) return false
  if (!uri[0]) return false
  if (!isFunction(uri[0]) && !isString(uri[0])) return false

  const resMethods = uri.slice(1)
  if (!resMethods.length > 0) return false
  for (const method of resMethods) {
    if (!method || !isString(method)) return false
    if (methods.indexOf(method) === -1) return false
  }

  return true
}

/**
 * Resource Options Definition
 *
 * @typedef {Object} ResourceOptions
 * @property {String|Function} uri An array containing the URI and one or more methods (GET, POST, PATCH, PUT, DELETE).
 *        the URI can be either a string or a function that returns a string;
 *        if a function is used, it passes on the arguments from resource.read().
 * @property {Boolean} paginated If true, we will include 'page', 'per_page' and 'offset' variables
 *        from the query string.
 * @property {Boolean} auth Whether to use authentication - always true with only a few exceptions.
 * @property {Object} staticArgs Object with query params that never change and always present in request.
 * @property {Array} mandatoryOptions list of request args that are mandatory
 * @property {Array} uriVars list of vars that are passed to args that must be used in `uri` function and not in uri query.
 *
 */

/**
 * Returns a cachable resource for querying the API.
 *
 * Some examples of using this function:
 *
 *    // Simple paginated GET resource for global data.
 *    const BankDetails = createAPIResource({
 *      uri: [`/admin/workers/bank_details`, 'GET'],
 *      paginated: true
 *    });
 *
 *    // Resource for getting a login token for an employer.
 *    const LoginAsEmployer = createAPIResource({
 *      uri: [({ id }) => `/admin/users/login_as_employer/${id}`, 'POST'],
 *      uriVars: ['id']
 *    });
 *
 * After creating the resource, they can be used as follows (using the LoginAsEmployer resource as example):
 *
 *    const tokenData = await LoginAsEmployer.get({ id: employerID }); // 'employerID' passed on to uri function.
 *    const token = tokenData.setLens.get().reqResult.data; // 'tokenData' now contains the JSON response data.
 *
 * @param {String} name Name of resource. Also used as resource cache key
 * @param {ResourceOptions} data Options of resource
 *
 * @returns {{initData: initData, queryView: (function(*=, ...[*]): {isLoading: *, reqUniqID: *, hasData: *, isOK: *, reqError: *, reqTime: *, setLens: *, isStale: boolean}), post: (function(*=, *, {renameToSnakeCase?: *, onFail: *, onSuccess: *}=): Promise<*>), get: (function(*=, ...[*]): Promise<*>), name: *, getCSV: (function(*=): Promise<void>), getRemote: (function(*=, {forceFetch?: *, renameToSnakeCase?: *, requireMandatory?: *, onFail: *, onSuccess: *}=): Promise<undefined|*>), info: *}}
 */
const initAcpResource = (name, data) => {
  const { uri, uriVars, paginated = false, auth = true } = data

  // Throw if 'uri' is not formatted correctly.
  if (!checkURI(uri))
    throw new Error(`createAPIResource: ${JSON.stringify(uri)} is not a valid URI value or doesn't have valid methods`)

  /**
   * FIXME:
   * ADMN-129 (fix CSV support properly)
   * The interface is (args, { onSuccess, onFail }) but the callbacks aren't used yet.
   */
  const getCSV = async args => {
    const resURI = `${isFunction(uri[0]) ? uri[0](args) : uri[0]}.csv`
    const resCall = makeResRequest(resURI, 'GET', data, args, auth)

    const res = await fetch(resCall)
    const text = await res.text()

    const csvText = new Blob([text], { type: 'text/csv' })
    const csvURL = URL.createObjectURL(csvText)

    /** FIXME: */
    const link = document.createElement('a')
    link.setAttribute('href', csvURL)
    link.setAttribute('download', 'Finance - Auto offer rate.csv')
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  /**
   * Retrieves remote data and updates the cache. This is aliased as update() for connected components.
   * If the set is already loading data the call will be ignored, unless 'forceFetch' is true.
   *
   * @param {Object} args Fetch parameters
   * @param {Object?} options Fetch options
   * @param {Boolean?} options.forceFetch
   * @param {Boolean?} options.renameToSnakeCase
   * @param {Boolean?} options.requireMandatory
   * @param {Function?} options.onBeforeRequest
   * @param {Function?} options.onFail
   * @param {Function?} options.onSuccess
   * @returns {Promise<*>}
   */
  const fetch = async (
    args,
    {
      forceFetch = false,
      renameToSnakeCase = true,
      requireMandatory = false,
      onBeforeRequest = noop,
      onFail,
      onSuccess,
    } = {}
  ) => {
    // Cancel the request if a mandatory option isn't passed.
    // TODO: the request should be made to display an error in the future.
    const containsMandatory = hasRequiredOptions(args, data.mandatoryOptions)
    if (!containsMandatory && requireMandatory) return

    // We've got two types of arguments: those sent in the request body,
    // and those passed on to the URL function. E.g. '/admin/workers/1234/data'.
    // However, both argument types need to be used for the cache key.
    const argsBody = mergeStaticArgs(mergePaginationArgs(omit(args, uriVars), paginated), data.staticArgs)
    const argsURL = pick(args, uriVars)
    const argsBodyDef = convertArgsCase(argsBody, renameToSnakeCase)
    const argsAllDef = { ...argsBody, ...argsURL }

    const { setLens, isLoading } = getSetView(name, argsAllDef)
    if (!!isLoading.get() && !forceFetch) {
      // eslint-disable-next-line consistent-return
      return setLens.get()
    }

    // Generate either an authorized URI call or a plain one.
    const resURI = isFunction(uri[0]) ? uri[0](argsAllDef) : uri[0]
    const resCall = makeResRequest(resURI, uri[1], data, argsBodyDef, auth)

    onBeforeRequest(name, argsAllDef)

    // Run the call and retrieve result data and metadata.
    const result = await processCall(resCall, { uri }, { onFail, onSuccess })
    logOnError(result)

    return {
      result,
      allArgs: argsAllDef,
    }
  }

  /**
   * Retrieves remote data and updates the cache. This is aliased as update() for connected components.
   * If the set is already loading data the call will be ignored, unless 'forceFetch' is true.
   *
   * @param {Object} args Fetch parameters
   * @param {Object?} options Fetch options
   * @param {Boolean?} options.forceFetch
   * @param {Boolean?} options.renameToSnakeCase
   * @param {Boolean?} options.requireMandatory
   * @param {Function?} options.onFail
   * @param {Function?} options.onSuccess
   * @returns {Promise<*>}
   */
  const getRemote = async (args, options) => {
    const response = await fetch(args, {
      ...options,
      // Set the resource to 'loading'.
      onBeforeRequest: (resourceName, argsAllDef) => dispatchCallBegin(resourceName, argsAllDef),
    })

    // Prevents destructuring error caused by response being undefined on first load
    if (!response) return getSetView(name, {}).setLens.get()

    const { result, allArgs } = response
    // Update the cache with the new data.
    dispatchCallFinish(name, allArgs, result, !get(result, 'error'))
    // Now that the data is guaranteed to be there, we can return the lens data.
    // It needs to be in retrun otherwise it will cause the bug on SaleForce
    // eslint-disable-next-line consistent-return
    return getSetView(name, allArgs).setLens.get()
  }
  // TODO
  const downloadCSV = async (args, options) => {
    const { onSuccess = noop, onError = noop, fileName } = options
    const { result } = await fetch({
      ...args,
      page: 1,
      per_page: A_VERY_LARGE_NUMBER,
    })

    if (!!result.error) {
      onError(result.error)
    }

    try {
      await downloadAsCSV({ payload: result.data, meta: result.meta }, fileName || name)
      onSuccess()
    } catch (e) {
      onError(e)
    }
  }

  /**
   * Sends new data to the remote API resource. Used by POST, PUT, PATCH and DELETE.
   *
   * @param args
   * @param body
   * @param renameToSnakeCase
   * @param onFail
   * @param onSuccess
   * @returns {Promise<*>}
   */
  const sendRemote = async (args, body, { renameToSnakeCase = true, onFail, onSuccess } = {}) => {
    // Remote data should already be in snake_case format, and not an atom.
    // 'args' is used to construct the URL.
    const argsDef = convertArgsCase(
      mergeStaticArgs(mergePaginationArgs(omit(args, uriVars), paginated), data.staticArgs),
      renameToSnakeCase
    )
    const resURI = isFunction(uri[0]) ? uri[0](args) : uri[0]
    const resCall = makeResRequest(resURI, 'POST', data, { body })

    dispatchCallBegin(name, argsDef)
    const result = await processCall(resCall, { uri }, { onFail, onSuccess })
    logOnError(result)

    dispatchCallFinish(name, argsDef, result, !result.error)

    // Now that the data is guaranteed to be there, we can return the lens data.
    return getSetView(name, argsDef).setLens.get()
  }

  return {
    name,
    info: data,

    /** Sets up the initial data. */
    initData: () => {
      dispatchInit(name)
    },

    // List of API resource methods.
    /** Creates a new item on the server. */
    post: sendRemote,
    /** FIXME */
    getCSV,
    /** downloads resource as CSV **/
    downloadCSV,
    /** Retrieves data from the server. */
    getRemote,
    /** Retrieves data from cache if it exists; makes a remote call otherwise. */
    get: async (args = {}, ...restArgs) => {
      const argsDef = mergeStaticArgs(mergePaginationArgs(args, paginated), data.staticArgs)
      const { setLens, isStale } = getSetView(name, argsDef)
      if (isStale) {
        return await getRemote(argsDef, ...restArgs)
      }
      return setLens.get()
    },
    /** Returns a view for the given query. Used for displaying the latest data. */
    queryView: (args = {}, ...restArgs) => {
      const argsDef = mergeStaticArgs(mergePaginationArgs(args, paginated), data.staticArgs)

      const { setLens, hasData, isLoading, isOK, isStale, reqTime, reqError } = getSetView(name, argsDef)

      // Create a unique status string, which is a combination of all the meta data for a request.
      // The uniqStatus can be checked to determine if a request contains new data. Does not include isStale.
      const reqUniqID = U.stringify({ hasData, isLoading, isOK, reqTime, reqError, reqArgs: argsDef })

      // Queue a new data fetch in case the data is not present.
      if ((isStale && !isLoading.get()) || !hasData) {
        getRemote(argsDef, ...restArgs)
      }

      return {
        // Full query set data (see 'reqResult.data' and 'reqResult.meta')
        setLens,
        // Request meta data
        hasData,
        isLoading,
        isOK,
        isStale,
        reqError,
        reqTime,
        reqUniqID,
      }
    },
  }
}

/**
 * Creates an API resource and returns its read() method. See initAcpResource() for details
 *
 * @param {String} name Name of resource. Also used as resource cache key
 * @param {ResourceOptions} data Options of resource
 *
 * @returns {{initData: initData, queryView: (function(*=, ...[*]): {isLoading: *, reqUniqID: *, hasData: *, isOK: *, reqError: *, reqTime: *, setLens: *, isStale: boolean}), post: (function(*=, *, {renameToSnakeCase?: *, onFail: *, onSuccess: *}=): Promise<*>), get: (function(*=, ...[*]): Promise<*>), name: *, getCSV: (function(*=): Promise<void>), getRemote: (function(*=, {forceFetch?: *, renameToSnakeCase?: *, requireMandatory?: *, onFail: *, onSuccess: *}=): Promise<undefined|*>), info: *}}
 */
export const createAcpResource = (name, data) => {
  const res = initAcpResource(name, data)
  resources.push(res)
  return res
}
