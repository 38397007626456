// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { Col } from 'react-bootstrap'
import { connect } from 'react-redux'

import * as employerActions from 'syft-acp-core/store/employers/actions'
import Alerts from 'syft-acp-core/components/Alerts'
import EmployerDetailListWrapper from './EmployerDetailListWrapper'

class EmployerAlertList extends PureComponent {
  static propTypes = {
    employerID: PropTypes.number.isRequired,
  }

  componentDidMount() {
    this.props.actions.fetchEmployer(this.props.employerID)
  }

  render() {
    const { employerID, isSavingData, isLoadingData } = this.props
    return (
      <EmployerDetailListWrapper
        employerID={employerID}
        isSavingData={isSavingData}
        isLoadingData={isLoadingData}
        entityList={
          <Col md={12}>
            <Alerts employerID={employerID} />
          </Col>
        }
      />
    )
  }
}

EmployerAlertList.propTypes = {
  isLoadingData: PropTypes.bool.isRequired,
  isSavingData: PropTypes.bool.isRequired,
  actions: PropTypes.objectOf(PropTypes.func).isRequired,
}

export default connect(
  state => ({
    isLoadingData: state.employers.isLoadingData,
    isSavingData: state.employers.isSavingData,
  }),
  dispatch => ({
    actions: bindActionCreators(employerActions, dispatch),
  })
)(EmployerAlertList)
