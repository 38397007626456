export const entityFetchingBegin = state => {
  return {
    ...state,
    isSavingData: true,
    lastMessage: '',
    lastStatus: null,
    lastBody: null,
    isLoadingData: true,
  }
}
