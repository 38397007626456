// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import React from 'react'
import PropTypes from 'prop-types'

import SiteComponent from 'syft-acp-core/components/SiteComponent'
import './PageTextContainer.css'

const PageTextContainer = props => (
  <SiteComponent noMargin>
    <div className={`text-page ${!props.noContainer ? 'text-container' : ''}`}>{props.children}</div>
  </SiteComponent>
)

PageTextContainer.propTypes = {
  children: PropTypes.node.isRequired,
  noContainer: PropTypes.bool,
}

PageTextContainer.defaultProps = {
  noContainer: false,
}

export default PageTextContainer
