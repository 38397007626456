import React, { Fragment, PureComponent } from 'react'
import { connect } from 'react-redux'
import { uniqBy, sortBy, isArray } from 'lodash-es'
import { history } from 'syft-acp-core/history'
import qs from 'query-string'

import LoadingSpinner from 'syft-acp-atoms/LoadingSpinner'
import { ButtonIcon } from 'syft-acp-atoms/Button'

import { getValueArray } from '../filterHelpers'
import FilterOptGroups from '../FilterOptGroups'
import { FilterRolesSkillsProps as Props, FilterRolesSkillsState as State } from './FilterRolesSkills.types'
import './FilterRolesSkills.scss'

/** Returns a list of roles for use by FilterOptGroups. */
export const generateOptions = (industryIds: string[]) => (entityMap: Record<string, any>) => {
  const filteredIndustry =
    industryIds?.length === 1 ? { [industryIds[0]]: entityMap[industryIds[0]] } : entityMap
  return Object.keys(filteredIndustry).map(key => {
    const { title, roles } = entityMap[key]
    const rolesOptions = Object.keys(roles || {}).map(roleKey => {
      const role = roles[roleKey]
      return {
        id: String(role.id),
        label: role.title,
      }
    })
    return [title, rolesOptions]
  })
}

/**
 * Returns a list of skills for a specific role for use by FilterOptGroups.
 * TODO: Figure out how this function works so we can type it properly and remove ts-ignore
 */
const generateSkillOptions = (roles: Record<string, any>, roleID?: string[] | string) => () => {
  const roleData = roleID
    ? Object.entries(roles)
        .filter(([id]) => (isArray(roleID) ? roleID.includes(id) : +roleID === +id))
        .map(([, role]) => role)
    : Object.values(roles)
  const skills = roleData.reduce(
    // @ts-ignore
    (acc, roleDataItem) => [
      ...acc,
      // @ts-ignore
      ...Object.values(roleDataItem?.skills || []).map(item => ({ id: String(item.id), label: item.title })),
    ],
    [],
  )
  // @ts-ignore
  const sorted = sortBy(uniqBy(skills, 'id'), ['label'])

  return [['Skills', sorted]]
}

class FilterRolesSkills extends PureComponent<Props, State> {
  static defaultProps: Props = {
    defaultOption: 'Any',
    value: null,
    options: [],
    actions: {},
    allowAny: false,
    small: false,
    tiny: false,
    role_key: null,
    skill_key: null,
    independentSkills: false,
    disabled: false,
    type: null,
    roleIds: [],
    industryIds: [],
    skillIds: [],
    multiRole: false,
    multiSkill: false,
    roles: {},
    fireCallbackBeforeMount: true,
  }

  constructor(props: Props) {
    super(props)
    const { independentSkills, multiRole, roleIds, multiSkill, skillIds } = props
    const roleFiltersCount = roleIds.length < 1 ? 1 : roleIds.length
    const roleFilters = multiRole ? roleFiltersCount : 1
    const skillFiltersCount = skillIds.length < 1 ? 1 : skillIds.length
    const skillFilters = multiSkill ? skillFiltersCount : 1
    this.state = { role_id: null, roleFilters, skillFilters, isRemovingRole: false, isRemovingSkill: false }
    this.skillOptionsGenerator = independentSkills ? generateSkillOptions(props.roles) : null
  }

  skillOptionsGenerator

  addRoleFilter = () => {
    this.setState(({ roleFilters }) => ({ roleFilters: roleFilters + 1 }))
  }

  removeRoleFilter = () => {
    this.setState(
      ({ roleFilters }) => {
        const n = roleFilters - 1
        // Never go below one filter
        return { roleFilters: n < 1 ? roleFilters : n }
      },
      () => {
        const { roleIds } = this.props
        // Do not change route if empty filters are removed
        if (roleIds.length === this.state.roleFilters + 1) {
          this.setState({ isRemovingRole: true })
          const existingQueries = qs.parse(window.location.search)
          setTimeout(() => {
            roleIds.pop()
            const query = qs.stringify({
              ...existingQueries,
              role_id: roleIds,
              roles_filter_type: roleIds.length > 1 ? 'all' : undefined,
            })
            history.push(`${window.location.pathname}?${query}`)
            this.setState({ isRemovingRole: false })
          }, 300)
        }
      },
    )
  }

  removeAllRoleFilters = () => {
    this.setState(
      () => ({ roleFilters: 1 }),
      () => {
        const query = qs.stringify({
          ...qs.parse(window.location.search),
          role_id: undefined,
          roles_filter_type: undefined,
        })
        history.push(`${window.location.pathname}?${query}`)
      },
    )
  }

  addSkillFilter = () => {
    this.setState(({ skillFilters }) => ({ skillFilters: skillFilters + 1 }))
  }

  removeSkillFilter = () => {
    this.setState(
      ({ skillFilters }) => {
        const n = skillFilters - 1
        // Never go below one filter
        return { skillFilters: n < 1 ? skillFilters : n }
      },
      () => {
        const { skillIds } = this.props
        // Do not change route if empty filters are removed
        if (skillIds.length === this.state.skillFilters + 1) {
          this.setState({ isRemovingSkill: true })
          const existingQueries = qs.parse(window.location.search)
          setTimeout(() => {
            skillIds.pop()
            const query = qs.stringify({
              ...existingQueries,
              skill_id: skillIds,
              skills_filter_type: skillIds.length > 1 ? 'all' : undefined,
            })
            history.push(`${window.location.pathname}?${query}`)
            this.setState({ isRemovingSkill: false })
          }, 300)
        }
      },
    )
  }

  callback = (name: string, val: string) => {
    if (name === 'role_id') this.skillOptionsGenerator = generateSkillOptions(this.props.roles, val)
    this.setState({ [name]: val })
  }

  render() {
    const roleFilters = Array(this.state.roleFilters).fill('')
    const skillFilters = Array(this.state.skillFilters).fill('')

    return (
      <div className="filter-roles-skills filter-unit">
        <div className="filter-roles-skills__col">
          {this.props.role_key && (
            <>
              {roleFilters.map((_e, i) => (
                <Fragment key={i}>
                  {/** @ts-ignore */}
                  <FilterOptGroups
                    {...this.props}
                    className="filter-roles-skills__item"
                    name={this.props.role_key!}
                    small={this.props.small}
                    tiny={this.props.tiny}
                    callback={this.callback}
                    onChange={this.props.onChange}
                    generateOptionsFunc={generateOptions(this.props.industryIds)}
                    filterIndex={i}
                    fireCallbackBeforeMount={this.props.fireCallbackBeforeMount}
                    ariaLabel={this.props.multiRole ? `Role ${i + 1}` : 'Role'}
                  />
                </Fragment>
              ))}
            </>
          )}
        </div>
        {this.props.skill_key && (
          <>
            <div className="filter-roles-skills__col">
              {this.props.skill_key && (this.props.multiSkill || this.props.multiRole) && (
                <span className="filter-roles-skills__divider">|</span>
              )}
              {skillFilters.map((_e, i) => (
                // @ts-ignore
                <FilterOptGroups
                  {...this.props}
                  key={i}
                  className="filter-roles-skills__item"
                  disabled={this.state.role_id === null && this.props.independentSkills === false}
                  name={this.props.skill_key || 'skill_id'}
                  small={this.props.small}
                  tiny={this.props.tiny}
                  callback={this.callback}
                  generateOptionsFunc={this.skillOptionsGenerator}
                  filterIndex={i}
                  fireCallbackBeforeMount={this.props.fireCallbackBeforeMount}
                  ariaLabel={this.props.multiSkill ? `Skill ${i + 1}` : 'Skill'}
                  filterOptions={this.props.filterOptions}
                />
              ))}
            </div>
            {this.props.multiSkill && (
              <div className="filter-roles-skills__col">
                <LoadingSpinner isLoading={this.state.isRemovingSkill} />
                {/* @ts-ignore TODO: type ButtonIcon component */}
                <ButtonIcon
                  className="filter-roles-skills__button primary btn Button"
                  onClick={this.addSkillFilter}
                  icon="plus"
                  iconLabel="hidden"
                  iconSize={12}
                  disabled={this.state.isRemovingSkill}
                  left
                >
                  Add skill
                </ButtonIcon>{' '}
                {/* @ts-ignore */}
                <ButtonIcon
                  className="filter-roles-skills__button primary btn Button"
                  onClick={this.removeSkillFilter}
                  icon="dash"
                  iconSize={12}
                  iconLabel="hidden"
                  disabled={this.state.isRemovingSkill || skillFilters.length < 2}
                  left
                >
                  Remove skill
                </ButtonIcon>
              </div>
            )}
            {this.props.multiRole && (
              <div className="filter-roles-skills__col">
                <LoadingSpinner isLoading={this.state.isRemovingRole} />
                {/* @ts-ignore TODO: type ButtonIcon component */}
                <ButtonIcon
                  className="filter-roles-skills__button primary btn Button"
                  onClick={this.addRoleFilter}
                  icon="plus"
                  iconLabel="hidden"
                  iconSize={12}
                  disabled={this.state.isRemovingRole}
                  left
                >
                  Add role
                </ButtonIcon>{' '}
                {/* @ts-ignore */}
                <ButtonIcon
                  className="filter-roles-skills__button primary btn Button"
                  onClick={this.removeRoleFilter}
                  icon="dash"
                  iconSize={12}
                  iconLabel="hidden"
                  disabled={this.state.isRemovingRole || roleFilters.length < 2}
                  left
                >
                  Remove role
                </ButtonIcon>
                {/* @ts-ignore */}
                <ButtonIcon
                  className="filter-roles-skills__button primary btn Button"
                  onClick={this.removeAllRoleFilters}
                  icon="dash"
                  iconSize={12}
                  iconLabel="hidden"
                  disabled={this.state.isRemovingRole || roleFilters.length < 2}
                  left
                >
                  Remove all roles
                </ButtonIcon>
              </div>
            )}
          </>
        )}
      </div>
    )
  }
}

export default connect((state, { role_key, skill_key }: Partial<Props>) => ({
  entityMap: state.industries.entityMap,
  roles: state.roles.entityMap,
  roleIds: getValueArray(state.routing.locationBeforeTransitions.query[role_key || 'role_id']),
  industryIds: getValueArray(state.routing.locationBeforeTransitions.query['industry_id']),
  skillIds: getValueArray(state.routing.locationBeforeTransitions.query[skill_key || 'skill_id']),
}))(FilterRolesSkills)
