import { emptySet } from 'syft-acp-core/reducers/generators/constants'
import { get, omit, without } from 'lodash-es'
import { entitySetName } from 'syft-acp-core/reducers/generators/utils'
import { generateEntityCrudReducer } from 'syft-acp-util/store/reducers'
import { WorkerReferralBonusEntity } from './types'
import types from './action-types'

export const entitiesDelete = (state: any, action: any) => {
  if (action.type === types.ENTITIES_DELETE_SUCCEEDED) {
    const setKey = entitySetName(action.options, { idKey: 'id' }, action)
    const currentSet = state.entitySets[setKey] || {}
    const removeIds = action.request?.payload?.ids || []

    const oldGlobalMap = get(state, 'entityMap', {})
    const oldGlobalDetail = get(state, 'entityDetail', {})
    const oldSetMap = get(currentSet, 'entityMap', {})
    const oldSetIDs = get(currentSet, 'ids', [])

    const newGlobalMap = omit(oldGlobalMap, removeIds)
    const newGlobalDetail = omit(oldGlobalDetail, removeIds)
    const newSetMap = omit(oldSetMap, removeIds)
    const newSetIDs = without(oldSetIDs, ...removeIds)

    if (removeIds) {
      return {
        ...state,
        entitySets: {
          ...state.entitySets,
          [setKey]: {
            ...emptySet,
            ...currentSet,
            ids: newSetIDs,
            entityMap: newSetMap,
          },
        },
        entityMap: newGlobalMap,
        entityDetail: newGlobalDetail,
        isSavingData: false,
        lastMessage: '',
        lastStatus: null,
        lastBody: null,
        isLoadingData: false,
      }
    } else {
      return {
        ...state,
        isSavingData: false,
        lastMessage: '',
        lastStatus: null,
        lastBody: null,
        isLoadingData: false,
      }
    }
  }

  return state
}

export default generateEntityCrudReducer<WorkerReferralBonusEntity>({
  name: 'workerReferralBonuses',
  actions: types,
  extraActions: {
    [types.ENTITIES_DELETE_SUCCEEDED]: entitiesDelete,
  },
})
