import * as React from 'karet'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { get } from 'lodash-es'
import { fetchAdminManagers } from 'syft-acp-core/actions/managers'
import InputDropdown from 'syft-acp-atoms/InputDropdown'

import { cellPropTypes, cellDefaultProps, PropTypesAtom } from '../dataProps'
import { colEditablePlaceholderFactory } from '../../../placeholders/factory'

/** Placeholder names for various manager types. Displayed as the top option in the dropdown. */
const managerPlaceholders = {
  account_manager: 'Account manager',
  sales_manager: 'Sales manager',
}

export const AcpEditableColManager = colEditablePlaceholderFactory('AcpEditableCellManager')

const AcpEditableCellManager = ({ value, options, managers, isLoadingData, actions }) => {
  // Check if we need to load the list of managers from the API.
  const hasManagers = Object.keys(managers).length > 0
  if (!hasManagers && !isLoadingData) actions.fetchAdminManagers({ options: {} })

  // Determine which manager type to display. E.g. 'account_manager' or 'sales_manager'.
  const managerType = get(options, 'managerType', 'all')
  const managerItems = Object.values(managers).filter(
    m => m.manager_roles.indexOf(managerType) > -1 || managerType === 'all'
  )
  const managerOptions = managerItems.map(m => ({ slug: `${m.id}`, label: m.name }))

  // The placeholder (shown at the top of the options list) is either what the user manually passed,
  // or it's a string from 'managerPlaceholders', or it's simply the string 'Manager'.
  const placeholder = get(options, 'placeholder', get(managerPlaceholders, managerType, 'Manager'))

  return <AcpEditableCellManagerPure value={value} selectOptions={managerOptions} placeholder={placeholder} />
}

const AcpEditableCellManagerPure = ({ value, selectOptions, placeholder }) => {
  return <InputDropdown selectOptions={selectOptions} value={value} placeholder={placeholder} />
}

AcpEditableCellManager.propTypes = {
  ...cellPropTypes,
  value: PropTypesAtom,
  options: PropTypes.shape({
    placeholder: PropTypes.string,
  }).isRequired,
}

AcpEditableCellManager.defaultProps = {
  ...cellDefaultProps,
}

AcpEditableCellManager.cellProperties = ['form']

export default connect(
  state => ({ managers: state.managers.entityMap, isLoadingData: state.managers.isLoadingData }),
  dispatch => ({ actions: bindActionCreators({ fetchAdminManagers }, dispatch) })
)(AcpEditableCellManager)
