// Syft ACP - Lib <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import React, { useCallback, useLayoutEffect, useRef, useState, useMemo } from 'react'
import { connect, DefaultRootState } from 'react-redux'
import { cloneDeep, compact, get, isEqual, set } from 'lodash-es'
import { EntitiesState } from 'syft-acp-core/reducers'
import {
  ActionTrigger,
  ActionUpdate,
  InjectedProps,
  Props,
  SendInitialData,
  GetUpdatedEntityData,
} from './entityDetail.types'
import { createSelector } from 'reselect'

/**
 * Returns a component wrapped with locally managed form state
 * resetting the the local forms data/state
 */
export const entityDetailHOC =
  <
    EntityStoreName extends keyof EntitiesState,
    Entity extends EntitiesState[EntityStoreName],
    P extends Props<Entity>,
  >(
    entityStore: EntityStoreName,
  ) =>
  <BaseProps extends P>(WrappedComponent: React.ComponentType<BaseProps>) => {
    const entityDetailSelector = createSelector([(state: DefaultRootState) => state[entityStore]], store => ({
      isSavingData: store.isSavingData,
      isLoadingData: store.isLoadingData,
      skipLocalDataUpdating: store.skipLocalDataUpdating,
      entityDetail: store.entityDetail,
      entityMap: store.entityMap,
      message: store.message,
      lastMessage: store.lastMessage,
      lastBody: store.lastBody,
    }))

    const connector = connect(entityDetailSelector)
    const EntityDetailComponent = ({
      id,
      isSavingData,
      isLoadingData,
      skipLocalDataUpdating,
      entityDetail,
      entityMap,
      message,
      lastMessage,
      lastBody,
      ...rest
    }: Omit<BaseProps, keyof InjectedProps<Entity>>) => {
      const initialData = useMemo(
        () => entityDetail[id] || entityMap[id] || {},
        [entityDetail, id, entityMap],
      )
      const [state, setState] = useState(() => {
        return {
          localEntityData: cloneDeep(initialData),
          isPristine: true,
        }
      })
      const customInitialData = useRef<Record<string, any> | null>(null)
      const sendInitialData: SendInitialData = useCallback(data => {
        customInitialData.current = data
        setState({ localEntityData: cloneDeep(data), isPristine: true })
      }, [])

      const getUpdatedEntityData: GetUpdatedEntityData = useCallback(
        (value, path, entityData, currentState): Record<string, any> => {
          const newLocalEntityData = {
            ...currentState.localEntityData,
            ...entityData,
          }
          const originalValue = (initialData as any)[path]

          if (value === undefined || value === null || value === '') {
            // if entity doesn't exist yet, delete the field
            if (!id) {
              delete newLocalEntityData[path]
            } else {
              // if original field is not set, do not set it to '' when a text input is cleared
              if (originalValue === null) {
                newLocalEntityData[path] = null
              } else {
                newLocalEntityData[path] = value
              }
            }
          } else {
            newLocalEntityData[path] = value
          }
          return newLocalEntityData
        },
        [initialData, id],
      )

      /**
       * Runs update triggers.
       *
       * @param {String} key Path to the key to change
       * @param {Function} value New value for the key
       * @param {Array} triggers Callback triggers to run
       */
      const actionUpdateTriggers = useCallback(
        (key: string, value: any, triggers: ActionTrigger<Entity>[]) => {
          let updates = false
          const data = {} as Record<string, any>
          triggers
            .filter(trigger => trigger.on === key)
            .forEach(trigger => {
              // Copy the value of one item to another.
              if (trigger.type === 'copy') {
                data[trigger.to] = get(state.localEntityData, trigger.from)
              }
              updates = true
            })

          return [updates, data]
        },
        [state.localEntityData],
      )

      const actionUpdate: ActionUpdate<Entity> = useCallback(
        (rawPath, value, triggers, prefix) => {
          const path = compact([prefix, rawPath]).join('.')
          // Run update triggers and merge any updates we've done in with the data.
          const triggerResult = actionUpdateTriggers(path, value, triggers)
          const entityData = { ...state.localEntityData, ...(triggerResult[1] as Record<string, any>) }

          // If the new value is the same as the current value, prevent the form from becoming dirty.
          const oldValue = get(state.localEntityData, path)
          if (isEqual(oldValue, value) && !triggerResult[0]) {
            return
          }

          const pathParts = path.split(/[[.]/)
          // If this is not a nested item, set the new value directly.
          if (pathParts.length === 1) {
            setState(previousState => {
              const newLocalEntityData = getUpdatedEntityData(value, path, entityData, previousState)

              return {
                localEntityData: newLocalEntityData,
                isPristine: isEqual(newLocalEntityData, initialData),
              }
            })
            return
          }

          // If this is a nested item, clone the whole object and set that.
          // TODO: optimize this. But we can't use 'set' directly on the store, since this is impure.
          const currentData = entityData[pathParts[0]]
          // Create an empty object if it's null. E.g. 'bank_account' for workers.
          const data = currentData != null ? cloneDeep(currentData) : {}
          set(data, pathParts.slice(1).join('.'), value)
          setState(previousState => ({
            localEntityData: {
              ...previousState.localEntityData,
              [pathParts[0]]: data,
            },
            isPristine: false,
          }))
        },
        [actionUpdateTriggers, state.localEntityData, getUpdatedEntityData, initialData],
      )

      useLayoutEffect(() => {
        if (
          !isSavingData &&
          !(lastMessage ?? message) &&
          !skipLocalDataUpdating &&
          !customInitialData.current
        ) {
          setState(() => ({
            // Replace our copy of the entity data.
            // TODO: Note the discrepancy with constructor().
            localEntityData: cloneDeep(entityDetail[id] || entityMap[id] || {}),
            isPristine: true,
          }))
        }
      }, [entityDetail, entityMap, id, isSavingData, lastMessage, message, skipLocalDataUpdating])

      return (
        // @ts-expect-error TODO
        <WrappedComponent
          id={id}
          isSavingData={isSavingData}
          isLoadingData={isLoadingData}
          skipLocalDataUpdating={skipLocalDataUpdating}
          entityDetail={entityDetail}
          entityMap={entityMap}
          message={message}
          lastMessage={lastMessage}
          lastBody={lastBody}
          data={state.localEntityData}
          actionUpdate={actionUpdate}
          isPristine={state.isPristine}
          sendInitialData={sendInitialData}
          {...rest}
        />
      )
    }

    // @ts-expect-error FIXME 'props and props doesn't match', can't figure out why for now
    return connector(React.memo(EntityDetailComponent))
  }

export default <
  EntityStoreName extends keyof EntitiesState,
  Entity extends EntitiesState[EntityStoreName],
  BaseProps extends Props<Entity>,
>(
  entityName: EntityStoreName,
  WrappedComponent: React.ComponentType<BaseProps>,
) => entityDetailHOC(entityName)(WrappedComponent)
