// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import React, { FC } from 'react'

import EmployerRateCardForm from 'syft-acp-core/entities/EmployerDetail/EmployerRateCardForm'
import SubNavigation from 'syft-acp-core/components/Navigation/SubNavigation'
import { RouteComponentProps } from 'react-router-dom'

type Props = RouteComponentProps<{ id: string }>

const NewEmployerRateCardNew: FC<Props> = ({ match: { params } }) => (
  <div>
    <SubNavigation subPage="employers" data={{ employerID: Number(params.id) }} />
    <EmployerRateCardForm
      employerId={Number(params.id)}
      urlBase={`/entity/employers/view/${params.id}/ratecards-v2/list`}
    />
  </div>
)

export default NewEmployerRateCardNew
