import * as types from 'syft-acp-core/actions/action-types'

const initialState = {
  desktopNotificationsEnabled: false,
  // Whether dark mode is enabled.
  darkModeEnabled: false,
  notificationsGloballyEnabled: false,
  userIdleTimeout: 0.5,
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.DESKTOP_NOTIFICATIONS_SET:
      return {
        ...state,
        desktopNotificationsEnabled: action.enabled,
      }
    case types.NOTIFICATIONS_GLOBALLY_TOGGLE:
      return {
        ...state,
        notificationsGloballyEnabled: action.enabled,
      }
    case types.NOTIFICATIONS_GLOBALLY_ENABLE_IF_UNSET:
      return {
        ...state,
        notificationsGloballyEnabled:
          state.notificationsGloballyEnabled == null ? true : state.notificationsGloballyEnabled,
      }
    default:
      return state
  }
}

export default reducer
