// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import React from 'react'
import PropTypes from 'prop-types'

import './RecordControls.css'

const RecordControls = props => (
  <div className={`entity-detail-controls ${props.className}`} disabled={props.isDisabled}>
    {props.children}
  </div>
)

RecordControls.propTypes = {
  children: PropTypes.node,
  isDisabled: PropTypes.bool,
  className: PropTypes.string,
}
RecordControls.defaultProps = {
  children: null,
  isDisabled: false,
  className: '',
}

export default RecordControls
