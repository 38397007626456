// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import React from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import PaginationButtons from 'syft-acp-core/components/PaginationButtons'
import * as filtersActions from 'syft-acp-core/store/filters/actions'
import paginationProps from './paginationStructure'
import EntityListWrapper from './EntityListWrapper'

/**
 * Controls for page navigation
 *
 * @param {Object} props Properties
 * @returns {XML} Pure EntityListPaginationControls component
 */
class EntityListPaginationControls extends React.Component {
  static propTypes = {
    pagination: paginationProps,
    type: PropTypes.string,
    hasTopMargin: PropTypes.bool,
    paginationKey: PropTypes.string,
    disableAutoScroll: PropTypes.bool,
    actions: PropTypes.object,
    onNextPage: PropTypes.func,
  }

  static defaultProps = {
    pagination: {},
    type: null,
    paginationKey: 'page',
    hasTopMargin: false,
    disableAutoScroll: false,
    actions: {},
    onNextPage: null,
  }

  onPageChange = (page, ev) => {
    const { paginationKey, disableAutoScroll, onNextPage, actions } = this.props
    ev.preventDefault()
    if (onNextPage) {
      onNextPage({ page, disableAutoScroll, pageKey: paginationKey })
    } else {
      actions.setPage({ page, disableAutoScroll, pageKey: paginationKey })
    }
  }

  render() {
    const { activePage, totalPages } = this.props.pagination
    const { hasTopMargin, type } = this.props
    return (
      <EntityListWrapper type={type}>
        <PaginationButtons
          ellipsis
          hasTopMargin={hasTopMargin}
          totalPages={totalPages}
          activePage={activePage}
          onPageChange={this.onPageChange}
          prevNextOnly={totalPages === -1}
        />
      </EntityListWrapper>
    )
  }
}

export default connect(null, dispatch => ({
  actions: bindActionCreators(filtersActions, dispatch),
}))(EntityListPaginationControls)
