// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import { takeLatest } from 'redux-saga/effects'
import * as types from 'syft-acp-core/actions/action-types'
import * as api from 'syft-acp-core/api'
import { notificationCall, redirectCall } from './calls'
import { takeLatestApiCall } from './helpers'

export default function* root() {
  yield takeLatestApiCall('EMPLOYER_NETWORKS_FETCH', api.listEmployerNetworks)
  yield takeLatestApiCall('EMPLOYER_NETWORK_FETCH', api.fetchEmployerNetwork)
  yield takeLatestApiCall('DELETE_WORKER_FROM_NETWORK', api.deleteWorkerFromNetwork)
  yield takeLatest(
    types.DELETE_WORKER_FROM_NETWORK_SUCCEEDED,
    redirectCall,
    ({ request }) => `/entity/employers/view/${request.employerID}/networks/list`
  )
  yield takeLatest([types.DELETE_WORKER_FROM_NETWORK_SUCCEEDED, types.EMPLOYER_NETWORKS_FETCH_FAILED], notificationCall)
}
