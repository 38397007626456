import { TimeOffRequestEntity, Status } from '../../store/types'
import { capitalize } from 'lodash-es'
export const getTimeOffRequestDetailPath = (id: number) => `/admin/lta/time-off-requests/view/${id}`

enum StatusColor {
  requested = '',
  accepted = 'green',
  rejected = 'red',
}

export const requestStatusOptions = Object.keys(StatusColor).map(key => ({
  label: capitalize(key),
  id: key as Status,
}))

export const getRowColorByStatus = ({ status }: TimeOffRequestEntity) => StatusColor[status] || undefined

export const getWorkerLink = ({ worker }: TimeOffRequestEntity) =>
  worker && `/entity/workers/view/${worker.id}`
export const getShiftLink = ({ shift }: TimeOffRequestEntity) =>
  shift && `/listings/view/${shift.listing_id}/job/${shift.job_id}/shift/${shift.id}`
export const getEmployerLink = ({ employer }: Partial<TimeOffRequestEntity>) =>
  employer && `/entity/employers/view/${employer.id}`
export const getVenueLink = ({ employer, venue }: Partial<TimeOffRequestEntity>) =>
  employer && venue && `/entity/employers/view/${employer.id}/venues/view/${venue.id}`
