import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { get } from 'lodash-es'
import { bindActionCreators } from 'redux'
import { selectTabVenue } from 'syft-acp-core/store/data-tabs/actions'

import DataTabs from '../../DataTabs'
import S from './DataVenuesTabs.module.scss'
import { Venue } from './DataVenuesTabs.types'

export const DataVenuesTabs = ({
  value,
  data,
  withoutTableMargin,
  minimalData,
  actions,
  selectedVenueId,
}: {
  value?: Venue[]
  data: any
  withoutTableMargin?: boolean
  minimalData: boolean
  selectedVenueId?: number
  actions: {
    selectTabVenue: typeof selectTabVenue
  }
}) => {
  const [tabId, setTypeId] = useState<number | undefined>(selectedVenueId || data.venue.id || value?.[0]?.id)
  const handleSelect = (areaID: number) => {
    actions.selectTabVenue(areaID)
    setTypeId(areaID)
  }
  const selectedTab = value?.find(tab => tab.id === tabId)
  return (
    <DataTabs
      value={value}
      withoutTableMargin={withoutTableMargin}
      callback={handleSelect}
      attribute="venue"
      tabId={tabId}
    >
      {selectedTab && (
        <div className={S['data_tap_info']}>
          {!!minimalData && (
            <>
              <div style={{ display: 'flex' }}>
                <span className={S['data_tab_row_minimal']}>
                  <strong>Contact name:</strong> {selectedTab?.work_location_contact?.name || 'n/a'}
                </span>
                <span className={S['data_tab_row_minimal']}>
                  <strong>Tel:</strong> {selectedTab.work_location_contact?.mobile || 'n/a'}
                </span>
                <span className={S['data_tab_row_minimal']}>
                  <strong>Email:</strong> {selectedTab.work_location_contact?.email || 'n/a'}
                </span>
              </div>
              <div>
                <span className={S['data_tab_row_minimal']}>
                  <strong>Venue notes:</strong> {selectedTab?.work_location_note?.body || 'n/a'}
                </span>
              </div>
              <Link to={`/entity/employers/view/${data.employer.id}/venues/view/${selectedTab.id}`}>
                Link to Venue
              </Link>
            </>
          )}
        </div>
      )}
    </DataTabs>
  )
}

export default connect(
  state => ({
    selectedVenueId: get(state, 'dataTabs.venue_id'),
  }),
  dispatch => ({
    actions: bindActionCreators({ selectTabVenue }, dispatch),
  }),
)(DataVenuesTabs)
