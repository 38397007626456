// Syft ACP - Lib <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { range } from 'lodash-es'
import classnames from 'classnames'

import { Button, ButtonGroup } from 'syft-acp-atoms/Button'

import FilterUnit from './FilterUnit'

const R_OR_BELOW = 'RATING_LESS_THAN_OR_EQUAL'
const R_OR_ABOVE = 'RATING_GREATER_THAN_OR_EQUAL'

export default class FilterRatingPure extends PureComponent {
  static propTypes = {
    valFrom: PropTypes.number,
    valTo: PropTypes.number,
    amountFrom: PropTypes.number,
    amountTo: PropTypes.number,
    callback: PropTypes.func.isRequired,
    withoutLessButton: PropTypes.bool,
    withoutGreaterButton: PropTypes.bool,
    singleValue: PropTypes.bool,
  }

  static defaultProps = {
    amountFrom: 1,
    amountTo: 5,
    withoutLessButton: false,
    withoutGreaterButton: false,
    singleValue: false,
    valFrom: null,
    valTo: null,
  }

  isActive = n => {
    const { valFrom, valTo, amountFrom, amountTo, singleValue } = this.props
    const inRange = n >= this.props.valFrom && n <= this.props.valTo
    const equalToFrom = valFrom === amountFrom
    const equalToTo = valTo === amountTo
    return inRange && (singleValue || !(equalToFrom && equalToTo))
  }

  render() {
    const { valFrom, valTo, amountFrom, amountTo, callback, withoutLessButton, withoutGreaterButton } = this.props
    const orBelow =
      valFrom && valFrom === amountFrom && valTo > amountFrom && !(valFrom === amountFrom && valTo === amountTo)
    const orAbove = valTo && valTo === amountTo && valFrom < amountTo && !(valFrom === amountFrom && valTo === amountTo)

    return (
      <FilterUnit>
        {!withoutLessButton && (
          <ButtonGroup className="filter-enum-form-group">
            <Button className={classnames({ active: orBelow })} onClick={callback(R_OR_BELOW)}>
              ≤
            </Button>
          </ButtonGroup>
        )}
        <ButtonGroup className="filter-enum-form-group">
          {range(amountFrom, amountTo + 1).map(n => (
            <Button key={String(n)} className={classnames({ active: this.isActive(n) })} onClick={callback(n)}>
              {n}
            </Button>
          ))}
        </ButtonGroup>
        {!withoutGreaterButton && (
          <ButtonGroup className="filter-enum-form-group">
            <Button className={classnames({ active: orAbove })} onClick={callback(R_OR_ABOVE)}>
              ≥
            </Button>
          </ButtonGroup>
        )}
      </FilterUnit>
    )
  }
}
