import * as api from 'syft-acp-core/api/resources/admin/leave_requests'
import { takeEveryApiCall, takeLatestApiCall } from './helpers'
import * as types from 'syft-acp-core/actions/action-types'
import { put, takeLatest } from 'redux-saga/effects'
import { downloadAsFileFromApi } from '../api/file'

function* clearItemsCall() {
  yield put({ type: types.CHECKBOXES_CLEAR_ITEMS, scope: 'leaveRequests' })
}

const downloadCSV = (open, { options = {}, view }) => {
  const fullUrl = '/admin/leave_requests.csv'
  const paycircleUrl = '/admin/leave_requests/paycircle.csv'
  const path = view === 'paycircle' ? paycircleUrl : fullUrl
  downloadAsFileFromApi(path, options, [view, 'leavers'].filter(Boolean).join('-'))
}

const openURL = url => {
  window.open(url, '_blank')
}

export default function* root() {
  yield takeLatestApiCall('WORKER_LEAVE_REQUEST_FETCH', api.fetchLeaveRequests)
  yield takeEveryApiCall('WORKER_LEAVE_REQUEST_CHANGE_STATUS', api.changeProcessedStatusLeaveRequests)
  yield takeEveryApiCall('WORKER_LEAVE_REQUEST_CHANGE_STATUS', clearItemsCall)
  yield takeLatest(types.WORKER_LEAVE_REQUEST_FETCH_CSV, downloadCSV, openURL)
}
