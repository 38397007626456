// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import { Box, Button, Flex, Input } from '@indeed/ifl-components'
import React, { useState } from 'react'
import { Accordion, AccordionContainer } from '@indeed/ipl-accordion'
import DataReasonsArrayItem from './DataReasonsArrayItem'
import { SystemStyleObject } from '@indeed/ifl-css'

type DataReasonType = {
  reason?: string
  _destroy?: boolean
  id?: number
}
type DataReasonsArrayType = {
  value: DataReasonType[]
  onChange: (reasons: DataReasonType[]) => any
}

const DataReasonsArray = ({ value = [], onChange }: DataReasonsArrayType) => {
  const [currValue, setCurrValue] = useState<string>('')
  const addTag = () => {
    if (currValue === '') {
      return
    }
    onChange([...value, { reason: currValue }])
    setCurrValue('')
  }

  const removeTag = (index: number) => {
    if (global.confirm('Are you sure you wish to remove this?')) {
      onChange(
        value
          .map((arrayItem: DataReasonType, idx: number) =>
            idx === index && arrayItem.id !== undefined ? { ...arrayItem, _destroy: true } : arrayItem,
          )
          .filter((arrayItem: DataReasonType, idx: number) => !(idx === index && arrayItem.id === undefined)),
      )
    }
  }

  const onKeyPress = (ev: any) => {
    if (ev.key === 'Enter') {
      addTag()
    }
  }

  const onLocalChange = (ev: any) => {
    let val = ev.target.value

    setCurrValue(val)
  }

  const reasons = (value || []).filter(reason => !reason._destroy)
  return (
    <>
      <Flex sx={{ mb: 2 }}>
        <Input type="text" onChange={onLocalChange} onKeyPress={onKeyPress} value={currValue} size="sm" />
        <Button onClick={addTag} size="sm" variant="secondary" sx={{ ml: 2 }}>
          Add
        </Button>
      </Flex>
      {reasons.length > 0 && (
        <Flex>
          <Accordion
            allowToggle={true}
            sx={
              {
                'border-color': '#CCC',
                '& div': {
                  'border-color': '#CCC',
                },
              } as SystemStyleObject
            }
          >
            <AccordionContainer
              title={`Reasons (${reasons.length} added)`}
              sx={
                {
                  'border-color': '#CCC',
                  ':focus:not([data-focus-visible-added])': {
                    'border-color': '#CCC',
                  },
                } as SystemStyleObject
              }
            >
              {reasons.map((item, n) => (
                <Box sx={{ display: 'inline-block' }} key={item.reason}>
                  <DataReasonsArrayItem index={n} onDelete={removeTag} title={item.reason} />
                </Box>
              ))}
            </AccordionContainer>
          </Accordion>
        </Flex>
      )}
    </>
  )
}

export default DataReasonsArray
