export const trackingEvents = {
  SAVE: {
    CLICKED: 'employer_accounts_detail.save.clicked',
  },
  CREATE: {
    CLICKED: 'employer_accounts_new.save.clicked',
  },
  REMOVE_PERMISSIONS: {
    CLICKED: 'employer_accounts_detail.remove_permissions.clicked',
  },
  DISABLE_ACCOUNT: {
    CLICKED: 'employer_accounts_detail.disable_account.clicked',
  },
}
