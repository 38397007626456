import { noop } from 'lodash-es'
import { SubmissionErrors } from 'final-form'

import { WorkerMissingPayEntity } from 'syft-acp-core/store/worker-missing-pay/types'

import { FormattedWorkerMissingPay } from './AddWorkerMissingPayModal.types'

export const getValidator = (isRequired: boolean) =>
  isRequired ? (value: any) => (value ? undefined : 'Required') : noop

export const getValidationState = (meta: Record<string, any>) => {
  if (!meta.touched) return null
  if (meta.error) return 'error'
  if (meta.valid) return 'success'
  return null
}

const formatTimeFromNumber = (time: number) => `${Math.floor(time)}:${Math.round((time - Math.floor(time)) * 60)}`

export const formatFromAPI = (data: WorkerMissingPayEntity) => ({
  ...data,
  hasShiftId: (!!data.shift_id).toString(),
  compensated_by_syft: data.compensated_by_syft.toString(),
  /* NOTE: Regarding the strange format of `role_id`, see also the comment at
   * ./AddWorkerMissingPayModal.tsx:83 */
  role_id: `${data.role_id};${data.worker_role}`,
  worker_pay_rate: data.worker_pay_rate.amount,
  client_pay_rate: data.client_pay_rate.amount,
  missing_time: formatTimeFromNumber(data.missing_time_in_hours),
})

export const getModalHeader = (data: FormattedWorkerMissingPay) =>
  data.id ? 'View worker missing pay' : 'Add worker missing pay'

export const getModalOnConfirm = (data: FormattedWorkerMissingPay, handleSubmit: any) => (data.id ? null : handleSubmit)

export const getModalCanSubmit = (invalid: boolean, submitting: boolean, submitFailed: boolean) =>
  (!invalid && !submitting) || submitFailed

export const getModalErrorText = (submitFailed: boolean, submitting: boolean, submitErrors: SubmissionErrors) =>
  (submitFailed && !submitting && submitErrors?.form) || null

export const getModalCancelText = (data: FormattedWorkerMissingPay) => (data.id ? 'Done' : 'Cancel')

export const isInputDisabled = (data: FormattedWorkerMissingPay, submitting: boolean) => submitting || !!data.id
