import React, { useState, useCallback } from 'react'
import { connect } from 'react-redux'
import { FormGroup } from 'react-bootstrap'
import FormattedDateTime from 'syft-acp-core/components/FormattedDateTime'
import { ControlLabel, Row, Col } from 'react-bootstrap'
import { useFlexFlagIsOn } from '@indeed/flex-feature-flags'

import DataBoolean from 'syft-acp-core/components/EditableTable/DataBoolean'
import DataEnum from 'syft-acp-core/components/EditableTable/DataEnum'
import { useTrackingTrigger } from '@indeed/flex-tracking-context'
import { getModal$ } from 'syft-acp-core/store/modals/selectors'
import { Modal } from 'syft-acp-core/components/Modal'
import { trackingEvents } from './EndJobAssignmentModal.tracking'
import { hideModal } from 'syft-acp-core/store/modals/actions'
import DataDropdown from 'syft-acp-core/components/EditableTable/DataDropdown'
import EditableTextbox from 'syft-acp-core/components/EditableTextbox'
import { endAssignmentWorker } from 'syft-acp-core/actions/bookings'

import { DataState, EndJobAssignmentModalProps } from './types'
import { endJobAssignmentReasons, eligibleReturnToWork } from './structure'
import S from './index.module.scss'

const OTHER_ID = { INVOLUNTARY_REASON: 17, VOLUNTARY_REASON: 18 }
const OTHER = { INVOLUNTARY_REASON: 'other_involuntary', VOLUNTARY_REASON: 'other_voluntary' }

const isValidOtherReason = (visibleDropdown: boolean, reason: number | string | null) => {
  if (visibleDropdown) {
    if (reason === OTHER_ID.INVOLUNTARY_REASON || reason === OTHER_ID.VOLUNTARY_REASON) {
      return true
    }
  } else {
    if (reason === OTHER.INVOLUNTARY_REASON || reason === OTHER.VOLUNTARY_REASON) {
      return true
    }
  }
  return false
}

export const EndJobAssignmentModal = ({ dispatch, item, shiftID, listing }: EndJobAssignmentModalProps) => {
  const defaultData = { reason: null, other: undefined, eligible: true, banned: undefined }
  const { longTermAssignment, worker, jobID, shiftIds } = item.options
  const [data, setData] = useState<DataState>(defaultData)
  const triggerEvent = useTrackingTrigger()
  const closeModal = useCallback(() => {
    dispatch(hideModal(item.modalName))
    setData(defaultData)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, item.modalName])
  const onConfirmHandler = useCallback(() => {
    const payload = {
      worker_id: worker.id,
      end_assignment: true,
      job_id: jobID,
      user_provided_reason: isNaN(Number(data?.reason))
        ? data.reason
        : endJobAssignmentReasons.find(reason => reason.id === data.reason)?.label,
      user_provided_custom_reason: data.other || null,
      worker: { first_name: worker.first_name, last_name: worker.last_name },
      shift_id: shiftID,
      shift_ids: shiftIds,
    }
    const employerId = listing?.employer?.id
    const venueId = listing?.venue?.id

    triggerEvent(trackingEvents.END_LONG_TERM_ASSIGNMENT.SAVE.CLICKED, {
      eligible_to_work: data.eligible,
      blocked: data.banned,
      ...payload,
    })
    dispatch(endAssignmentWorker(payload, data.banned, employerId, venueId))
    closeModal()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [closeModal, data, listing])

  const onChangeOther = useCallback(
    (value?: string) => {
      const newData = { ...data, other: value }
      setData(newData)
    },
    [data],
  )

  const onChangeValue = useCallback(
    (value: string) => {
      const newData = { ...data, reason: value }

      setData(newData)
    },
    [data],
  )
  const onChangeBanWorker = useCallback(
    (value?: string) => {
      const newData = { ...data, banned: value }
      setData(newData)
    },
    [data],
  )
  const onChangeeligibleToWork = useCallback(
    (value: boolean) => {
      const newData = { ...data, eligible: value, banned: value ? undefined : data.banned }
      setData(newData)
    },
    [data],
  )
  const visibleDropdown = useFlexFlagIsOn('pte_13454_end_job_assignment_dropdown_reasons')
  const isOtherReason = isValidOtherReason(visibleDropdown, data.reason) && !!data?.other
  const isEligible = !!data.eligible || !!data.banned

  return (
    <Modal
      header="End job assignment"
      isShown={item.isShown}
      canSubmit={
        ((!!data.reason && !isValidOtherReason(visibleDropdown, data.reason)) || isOtherReason) && isEligible
      }
      onClose={closeModal}
      onConfirm={onConfirmHandler}
      confirmationText="Submit"
    >
      <FormGroup className="form-item">
        <Row>
          <Col md={12}>
            <ControlLabel className={S['end-assignment__header']}>
              {worker?.first_name} {worker?.last_name} is booked on{' '}
              {longTermAssignment?.upcoming_shifts_count} upcoming shifts from: {/* @ts-expect-error */}
              <FormattedDateTime
                value={longTermAssignment?.upcoming_shift_start_time}
                format={{
                  year: 'numeric',
                  month: '2-digit',
                  day: '2-digit',
                }}
              />{' '}
              to {/* @ts-expect-error */}
              <FormattedDateTime
                value={longTermAssignment?.end_time}
                format={{
                  year: 'numeric',
                  month: '2-digit',
                  day: '2-digit',
                }}
              />
            </ControlLabel>
          </Col>
        </Row>
        <br />
        <Row>
          <Col md={12}>Please select a reason for ending their assignment:</Col>
        </Row>
        {visibleDropdown ? (
          <Row>
            <Col md={12}>
              <DataDropdown
                name="reasons"
                onChange={onChangeValue}
                value={data.reason}
                editable
                options={endJobAssignmentReasons}
                showDisabled
              />
            </Col>
          </Row>
        ) : (
          <Row>
            <Col md={12}>
              <DataEnum
                editable
                vertical
                options={endJobAssignmentReasons}
                componentValue={data.reason}
                onChange={onChangeValue}
              />
            </Col>
          </Row>
        )}
        <br />
        {isValidOtherReason(visibleDropdown, data.reason) && (
          <Row>
            <Col md={12}>
              <EditableTextbox
                value={data?.other}
                placeholder="Please enter reason here"
                hasControls={false}
                onSave={onChangeOther}
              />
            </Col>
          </Row>
        )}
        <Row>
          <Col md={5}>
            <div style={{ display: 'flex' }}>
              <div>
                <DataBoolean onChange={onChangeeligibleToWork} checked={data.eligible} editable />
              </div>
              <div className={S['end-assignment__eligible']}>Eligible to return to work</div>
            </div>
          </Col>
        </Row>
        <Row>
          {!data.eligible && (
            <Col md={7}>
              <div className={S['end-assignment__ban']}>
                <DataEnum
                  editable
                  options={eligibleReturnToWork}
                  componentValue={data.banned}
                  onChange={onChangeBanWorker}
                  vertical
                />
              </div>
            </Col>
          )}
        </Row>
        {!data.eligible && (
          <Row>
            <Col md={12}>
              <div className={S['end-assignment__message']}>
                Selecting one of the above options will result in blocking the worker on the client portal.
              </div>
            </Col>
          </Row>
        )}
      </FormGroup>
    </Modal>
  )
}

export default connect((state, props: EndJobAssignmentModalProps) => ({
  item: getModal$(state.modals)('EndJobAssignmentModal'),
  listing: state?.listings?.entityDetail?.[props.listingID],
  // @ts-expect-error invalid types
}))(EndJobAssignmentModal)
