import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { get } from 'lodash-es'

import { cellPropTypes, cellDefaultProps } from '../dataProps'
import { emptyTextFallback } from './fallback'
import { colPlaceholderFactory } from '../../../placeholders/factory'

import './AcpCellText.css'

export const AcpColText = colPlaceholderFactory('AcpCellText')

/**
 * Generic column for displaying text values.
 *
 *    <Acp.Col.Text value="id" header="ID" isNumeric />
 */
export default class AcpCellText extends React.PureComponent {
  static propTypes = {
    ...cellPropTypes,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.node]),
  }

  static defaultProps = {
    ...cellDefaultProps,
  }

  render() {
    const { value, options } = this.props

    // Check if value is 'null' - in that case we display
    // a fallback string to indicate the cell is empty.
    const valueStr = value == null || value === '' ? emptyTextFallback : value

    const isNonWrapped = Boolean(get(options, 'isNonWrapped', false))
    const isMonospaced = Boolean(get(options, 'isMonospaced', false))

    return (
      <span className={classNames('acp-cell-text', { 'monospace-text': isMonospaced, 'is-non-wrapped': isNonWrapped })}>
        {valueStr}
      </span>
    )
  }
}
