// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import download from 'downloadjs'
import { get } from 'lodash-es'

import { store } from 'syft-acp-core/store'

export const downloadLocalStorage = () => {
  const state = store.getState()
  const serialized = JSON.stringify(state)
  const blob = new Blob([serialized])

  const user = get(state, ['auth', 'userData', 'id'], 'x')
  const timestamp = +new Date()

  download(blob, `state_uid${user}_${timestamp}.json`, 'application/json')
}
