export const payStatuses = {
  verified: {
    name: 'Verified',
    message: 'Pay verified',
    canAuthorize: true,
    style: 'success',
  },
  unverified: {
    name: 'Unverified',
    message: 'Pay unverified',
    canAuthorize: false,
    color: '#5A6986',
    style: 'default',
  },
}

export const paycomStatusKeys = Object.keys(payStatuses).reduce((keys, key) => ({ ...keys, [key]: key }), {})
