import entityReducer from 'syft-acp-core/reducers/generators/entityReducer'
import * as entityTypes from 'syft-acp-core/reducers/generators/entities'
import * as types from 'syft-acp-core/actions/action-types'
import { formatFullName } from 'syft-acp-util/formatting'

const sortShiftBookings = shiftBookingsGroups =>
  shiftBookingsGroups.map(shiftGroup => ({
    ...shiftGroup,
    bookings: shiftGroup.bookings.sort((bookingA, bookingB) => {
      const nameA = formatFullName(bookingA.worker).toLowerCase()
      const nameB = formatFullName(bookingB.worker).toLowerCase()

      return nameA < nameB ? -1 : nameA > nameB ? 1 : 0
    }),
  }))

export const convertShiftBookingsPayload = payload =>
  sortShiftBookings(
    payload.reduce((accum, booking) => {
      const shiftBookingsGroup = accum.find(shiftsGroup => shiftsGroup.shift_id === booking.shift_id)

      if (!shiftBookingsGroup) {
        accum.push({ shift_id: booking.shift_id, bookings: [booking] })
      } else {
        shiftBookingsGroup.bookings.push(booking)
      }
      return accum
    }, [])
  )

const userOptions = {
  idKey: 'shift_id',
  payloadProcess: convertShiftBookingsPayload,
}

export default entityReducer(
  'shiftBookings',
  {
    [entityTypes.ENTITIES_FETCH_BEGIN]: types.SHIFT_BOOKINGS_FETCH_BEGIN,
    [entityTypes.ENTITIES_FETCH_SUCCEEDED]: types.SHIFT_BOOKINGS_FETCH_SUCCEEDED,
    [entityTypes.ENTITIES_FETCH_FAILED]: types.SHIFT_BOOKINGS_FETCH_FAILED,
  },
  userOptions
)
