import React from 'react'
import classNames from 'classnames'

import { colPlaceholderFactory } from '../../../../placeholders/factory'
import './AcpCellInfo.scss'
import { AcpCellInfoProps } from './AcpCellInfo.type'

/** Column placeholder component. */
export const AcpColInfo = colPlaceholderFactory('AcpCellInfo')

/**
 * Column type for displaying info.
 *
 * ```
 * <Acp.Col.Info value="12/15" header="Required" />
 * ```
 */
const AcpCellInfo = ({ value, spec, rowData }: AcpCellInfoProps) => {
  const disable = spec?.disableFunc(rowData)
  if (disable) {
    return null
  }
  return (
    <div className="acp-cell-info-container">
      <div className={classNames('acp-cell-info')}>{value}</div>
    </div>
  )
}

AcpCellInfo.cellProperties = ['popover', 'pure']

export default AcpCellInfo
