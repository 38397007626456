import { put, takeLatest } from 'redux-saga/effects'
import * as api from 'syft-acp-core/api/resources/admin/commissions'
import {
  STOP_COMMISSION_SUCCEEDED,
  STOP_COMMISSION_FAILED,
  COMMISSION_CREATE_FAILED,
  COMMISSION_CREATE_SUCCEEDED,
} from 'syft-acp-core/actions/action-types'

import { fetchCommissions } from 'syft-acp-core/actions/commissions'
import { takeLatestApiCall } from './helpers'
import { notificationCall } from './calls'

export default function* root() {
  yield takeLatestApiCall('COMMISSIONS_FETCH', api.fetchCommissions)
  yield takeLatestApiCall('STOP_COMMISSION', api.stopCommission)
  yield takeLatestApiCall('COMMISSION_CREATE', api.createCommission)
  yield takeLatest(
    [STOP_COMMISSION_SUCCEEDED, STOP_COMMISSION_FAILED, COMMISSION_CREATE_FAILED, COMMISSION_CREATE_SUCCEEDED],
    notificationCall
  )
  // force a full re-fetch of the employer-specific data on both create & stop actions
  yield takeLatest([STOP_COMMISSION_SUCCEEDED, COMMISSION_CREATE_SUCCEEDED], function* (action) {
    yield put(fetchCommissions({ options: { employer_id: action.request.options.employer_id } }))
  })
}
