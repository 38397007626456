import { DefaultRootState } from 'react-redux'
import { createSelector } from 'reselect'
import { get, memoize } from 'lodash-es'

import { formatLocalDateAndRelative } from 'syft-acp-util/formatting'
import { ListingShiftEntity } from './types'
import { Store } from 'syft-acp-core/store'

/**
 * Selects a shift by its ID.
 */
export const selectShift$ = createSelector(
  (state: DefaultRootState) => state,
  store =>
    memoize(({ shiftID }: { shiftID: number }) => {
      if (!shiftID) return {} as Partial<ListingShiftEntity>
      return store.listingShifts.entityMap[shiftID] || {}
    }),
)

/**
 * Selects useful information to display about a shift.
 */
export const selectShiftSimple$ = createSelector(
  (store: Store) => store,
  store =>
    memoize((shiftID: number) => {
      if (!shiftID) {
        return {}
      }
      const shift = store?.listingShifts?.entityMap?.[shiftID] || {}
      const job = store.jobs?.entityMap?.[shift?.job_id] || {}
      const simpleData = {
        _shift: shift,
        startTimeHR: formatLocalDateAndRelative(shift.start_time),
        endTimeHR: formatLocalDateAndRelative(shift.end_time),
        inThePast: new Date(shift.start_time) < new Date(),
        shiftID,
        desc: job?.desc,
        jobID: job?.id,
        role: job?.role,
        skills: job?.skills,
        payRate: job?.pay_rate,
      }
      return simpleData
    }),
)

/**
 * Selects all unpaid times from a shift per worker.
 */
export const selectShiftUnpaidTimes$ = createSelector(
  state => state,
  store =>
    memoize((shiftID: number) => {
      if (!shiftID) {
        return []
      }

      const bookings = get(store, `listingShifts.entityMap[${shiftID}].shift_bookings`, [])
      const entries = bookings
        .map((b: Record<string, any>) => ({
          unpaid_times: b.unpaid_times,
          shift_id: shiftID,
          worker_id: get(b, 'worker.id', 0),
        }))
        .filter((b: Record<string, any>) => !!b.worker_id)

      return { entries }
    }),
)
