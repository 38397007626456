import React from 'react'
import { TrackingProvider } from '@indeed/flex-tracking-context'

type Props = {
  children: React.ReactNode
  adhocShiftBookingId: number
}

export const AdhocShiftActionsTrackingProvider = ({ children, adhocShiftBookingId }: Props) => (
  <TrackingProvider value={{ context: { adhoc_shift_booking_id: adhocShiftBookingId } }}>
    {children}
  </TrackingProvider>
)
