// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import * as types from './action-types'

export function fetchAttachments({ documentID }) {
  return { type: types.ATTACHMENTS_FETCH_BEGIN, documentID }
}

export function fetchAttachment({ documentID, secureToken }) {
  return { type: types.ATTACHMENT_FETCH_BEGIN, documentID, secureToken }
}

export function saveAttachment({ documentID, secureToken, file }) {
  return { type: types.ATTACHMENT_SAVE_BEGIN, documentID, secureToken, file }
}

export function deleteAttachment({ documentID, secureToken }) {
  return { type: types.ATTACHMENT_DELETE_BEGIN, documentID, secureToken }
}
