// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import React from 'react'
import { Grid, Row, Col } from 'react-bootstrap'

import './DashboardHeader.css'
import { FormattedMessage } from 'react-intl'

const DashboardHeader = () => (
  <div className="dashboard-wrapper">
    <div className="top-section">
      <Grid>
        <Row>
          <Col md={12}>
            <h2>
              <FormattedMessage id="brand.name" /> Admin Control Panel
            </h2>
          </Col>
        </Row>
      </Grid>
    </div>
  </div>
)

export default DashboardHeader
