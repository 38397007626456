// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import octicons from 'octicons'
import { isString } from 'lodash-es'
import ReactImageFallback from 'react-image-fallback'

import { avatarURL } from 'syft-acp-core/api/endpoints'
import iconFactory from 'syft-acp-atoms/Icons'

import './UserAvatar.css'

// Clock icon in SVG format.
const iconCheck = (
  <span
    className="icon"
    dangerouslySetInnerHTML={{
      __html: octicons.check.toSVG({
        'aria-label': 'Verified',
        width: 9,
        class: 'img',
      }),
    }}
  />
)
const GuestIcon = iconFactory('person')

const UserAvatar = ({ size, verified, avatar, shape, className, inline, name }) => {
  const customSize = !isString(size)
  const sizeStr = customSize ? 'custom' : size
  const style = customSize ? { width: size, height: size } : null

  //  These sizes are based on the equivalent classes in the CSS.
  const guestIconSize = size === 'small' ? 33 : size === 'medium' ? 50 : size === 'xsmall' ? 23 : size

  return (
    <div className={classnames('UserAvatar', `size-${sizeStr}`, `shape-${shape}`, className)} style={style}>
      {verified && <span className="verified">{iconCheck}</span>}
      <div className={classnames('avatar-wrapper', { inline })} style={style}>
        <span className="border" />
        {!avatar || avatar === 'guest' ? (
          <GuestIcon iconSize={guestIconSize} />
        ) : (
          <ReactImageFallback
            src={avatarURL(avatar)}
            fallbackImage={<GuestIcon iconSize={guestIconSize} />}
            alt={name}
          />
        )}
      </div>
    </div>
  )
}

UserAvatar.propTypes = {
  className: PropTypes.string,
  avatar: PropTypes.string,
  name: PropTypes.string,
  inline: PropTypes.bool,
  verified: PropTypes.bool,
  shape: PropTypes.string, // can be 'round' or 'rounded-square'
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]), // can be 'medium', 'small' or 'xsmall'
}

UserAvatar.defaultProps = {
  className: '',
  name: '',
  avatar: 'guest',
  size: 'medium',
  verified: false,
  inline: false,
  shape: 'round',
}

export default UserAvatar
