// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import entityReducer from './generators/entityReducer'
import * as types from '../actions/action-types'
import * as entityTypes from './generators/entities'

export default entityReducer(
  'payments',
  {
    [entityTypes.ENTITIES_FETCH_BEGIN]: types.PAYMENTS_FETCH_BEGIN,
    [entityTypes.ENTITIES_FETCH_SUCCEEDED]: types.PAYMENTS_FETCH_SUCCEEDED,
    [entityTypes.ENTITIES_FETCH_FAILED]: types.PAYMENTS_FETCH_FAILED,
  },
  {
    idKey: 'payment_id',
  }
)
