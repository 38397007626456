import * as React from 'karet'
import * as U from 'karet.util'
import PropTypes from 'prop-types'
import { PropTypesAtom } from 'syft-acp-util/observables'
import DatePicker from 'syft-acp-atoms/DatePicker'
import { datetimeToDate } from 'syft-acp-util/time'

import './InputDatepicker.css'
import { useCallback } from 'react'

const LiftedDatePicker = React.fromClass(DatePicker)

const InputDatepicker = ({ value, disabled, className }) => {
  const onChange = useCallback((name, val) => value.set(datetimeToDate(val)), [value])
  return (
    <LiftedDatePicker
      className={U.cns('input-group', 'acp-input-text-group', 'acp-datepicker-input-container', className)}
      value={value}
      disabled={disabled}
      onChange={onChange}
      name="inputDatePicker"
    />
  )
}

InputDatepicker.propTypes = {
  className: PropTypes.string,

  disabled: PropTypes.oneOfType([PropTypesAtom, PropTypes.bool]),
  value: PropTypesAtom,
}

InputDatepicker.defaultProps = {
  className: '',
  disabled: false,
  value: null,
}

export default InputDatepicker
