export const WORKER_COMPLETED_SHIFTS_FETCH_BEGIN = 'syftacp/WORKER_COMPLETED_SHIFTS_FETCH_BEGIN'
export const WORKER_COMPLETED_SHIFTS_FETCH_FAILED = 'syftacp/WORKER_COMPLETED_SHIFTS_FETCH_FAILED'
export const WORKER_COMPLETED_SHIFTS_FETCH_SUCCEEDED = 'syftacp/WORKER_COMPLETED_SHIFTS_FETCH_SUCCEEDED'
export const WORKER_DOCUMENT_DELETE_BEGIN = 'syftacp/WORKER_DOCUMENT_DELETE_BEGIN'
export const WORKER_DOCUMENT_DELETE_FAILED = 'syftacp/WORKER_DOCUMENT_DELETE_FAILED'
export const WORKER_DOCUMENT_DELETE_SUCCEEDED = 'syftacp/WORKER_DOCUMENT_DELETE_SUCCEEDED'
export const WORKER_DOCUMENT_FETCH_BEGIN = 'syftacp/WORKER_DOCUMENT_FETCH_BEGIN'
export const WORKER_DOCUMENT_FETCH_FAILED = 'syftacp/WORKER_DOCUMENT_FETCH_FAILED'
export const WORKER_DOCUMENT_FETCH_SUCCEEDED = 'syftacp/WORKER_DOCUMENT_FETCH_SUCCEEDED'
export const WORKER_DOCUMENT_SAVE_BEGIN = 'syftacp/WORKER_DOCUMENT_SAVE_BEGIN'
export const WORKER_DOCUMENT_SAVE_FAILED = 'syftacp/WORKER_DOCUMENT_SAVE_FAILED'
export const WORKER_DOCUMENT_SAVE_SUCCEEDED = 'syftacp/WORKER_DOCUMENT_SAVE_SUCCEEDED'
export const WORKER_DOCUMENT_TYPES_FETCH_BEGIN = 'syftacp/WORKER_DOCUMENT_TYPES_FETCH_BEGIN'
export const WORKER_DOCUMENT_TYPES_FETCH_FAILED = 'syftacp/WORKER_DOCUMENT_TYPES_FETCH_FAILED'
export const WORKER_DOCUMENT_TYPES_FETCH_SUCCEEDED = 'syftacp/WORKER_DOCUMENT_TYPES_FETCH_SUCCEEDED'
export const WORKER_DOCUMENT_UPDATE_ATTACHMENTS = 'syftacp/WORKER_DOCUMENT_UPDATE_ATTACHMENTS'
export const WORKER_DOCUMENTS_FETCH_BEGIN = 'syftacp/WORKER_DOCUMENTS_FETCH_BEGIN'
export const WORKER_DOCUMENTS_FETCH_FAILED = 'syftacp/WORKER_DOCUMENTS_FETCH_FAILED'
export const WORKER_DOCUMENTS_FETCH_SUCCEEDED = 'syftacp/WORKER_DOCUMENTS_FETCH_SUCCEEDED'
export const WORKER_FEEDBACK_FETCH_BEGIN = 'syftacp/WORKER_FEEDBACK_FETCH_BEGIN'
export const WORKER_FEEDBACK_FETCH_FAILED = 'syftacp/WORKER_FEEDBACK_FETCH_FAILED'
export const WORKER_FEEDBACK_FETCH_SUCCEEDED = 'syftacp/WORKER_FEEDBACK_FETCH_SUCCEEDED'
export const WORKER_FETCH_BEGIN = 'syftacp/WORKER_FETCH_BEGIN'
export const WORKER_FETCH_FAILED = 'syftacp/WORKER_FETCH_FAILED'
export const WORKER_FETCH_SUCCEEDED = 'syftacp/WORKER_FETCH_SUCCEEDED'
export const WORKER_GENERAL_PREFERENCES_FETCH_BEGIN = 'syftacp/WORKER_GENERAL_PREFERENCES_FETCH_BEGIN'
export const WORKER_GENERAL_PREFERENCES_FETCH_FAILED = 'syftacp/WORKER_GENERAL_PREFERENCES_FETCH_FAILED'
export const WORKER_GENERAL_PREFERENCES_FETCH_SUCCEEDED = 'syftacp/WORKER_GENERAL_PREFERENCES_FETCH_SUCCEEDED'
export const WORKER_IMPORTANT_DOCUMENT_ADD_EXTENDED_INFO =
  'syftacp/WORKER_IMPORTANT_DOCUMENT_ADD_EXTENDED_INFO'
export const WORKER_IMPORTANT_DOCUMENT_SAVE_BEGIN = 'syftacp/WORKER_IMPORTANT_DOCUMENT_SAVE_BEGIN'
export const WORKER_IMPORTANT_DOCUMENT_SAVE_FAILED = 'syftacp/WORKER_IMPORTANT_DOCUMENT_SAVE_FAILED'
export const WORKER_IMPORTANT_DOCUMENT_SAVE_SUCCEEDED = 'syftacp/WORKER_IMPORTANT_DOCUMENT_SAVE_SUCCEEDED'
export const WORKER_IMPORTANT_DOCUMENTS_FETCH_BEGIN = 'syftacp/WORKER_IMPORTANT_DOCUMENTS_FETCH_BEGIN'
export const WORKER_IMPORTANT_DOCUMENTS_FETCH_FAILED = 'syftacp/WORKER_IMPORTANT_DOCUMENTS_FETCH_FAILED'
export const WORKER_IMPORTANT_DOCUMENTS_FETCH_SUCCEEDED = 'syftacp/WORKER_IMPORTANT_DOCUMENTS_FETCH_SUCCEEDED'
export const WORKER_MODIFY_SUCCEEDED = 'syftacp/WORKER_MODIFY_SUCCEEDED'
export const WORKER_NETWORK_CREATE_BEGIN = 'syftacp/WORKER_NETWORK_CREATE_BEGIN'
export const WORKER_NETWORK_CREATE_FAILED = 'syftacp/WORKER_NETWORK_CREATE_FAILED'
export const WORKER_NETWORK_CREATE_SUCCEEDED = 'syftacp/WORKER_NETWORK_CREATE_SUCCEEDED'
export const WORKER_NETWORK_DELETE_BEGIN = 'syftacp/WORKER_NETWORK_DELETE_BEGIN'
export const WORKER_NETWORK_DELETE_FAILED = 'syftacp/WORKER_NETWORK_DELETE_FAILED'
export const WORKER_NETWORK_DELETE_SUCCEEDED = 'syftacp/WORKER_NETWORK_DELETE_SUCCEEDED'
export const WORKER_NETWORK_FETCH_BEGIN = 'syftacp/WORKER_NETWORK_FETCH_BEGIN'
export const WORKER_NETWORK_FETCH_FAILED = 'syftacp/WORKER_NETWORK_FETCH_FAILED'
export const WORKER_NETWORK_FETCH_SUCCEEDED = 'syftacp/WORKER_NETWORK_FETCH_SUCCEEDED'
export const WORKER_NETWORK_UPDATE_BEGIN = 'syftacp/WORKER_NETWORK_UPDATE_BEGIN'
export const WORKER_NETWORK_UPDATE_FAILED = 'syftacp/WORKER_NETWORK_UPDATE_FAILED'
export const WORKER_NETWORK_UPDATE_SUCCEEDED = 'syftacp/WORKER_NETWORK_UPDATE_SUCCEEDED'
export const WORKER_NETWORKS_FETCH_BEGIN = 'syftacp/WORKER_NETWORKS_FETCH_BEGIN'
export const WORKER_NETWORKS_FETCH_FAILED = 'syftacp/WORKER_NETWORKS_FETCH_FAILED'
export const WORKER_NETWORKS_FETCH_SUCCEEDED = 'syftacp/WORKER_NETWORKS_FETCH_SUCCEEDED'
export const WORKER_SAVE_BEGIN = 'syftacp/WORKER_SAVE_BEGIN'
export const WORKER_SAVE_FAILED = 'syftacp/WORKER_SAVE_FAILED'
export const WORKER_SAVE_SUCCEEDED = 'syftacp/WORKER_SAVE_SUCCEEDED'
export const WORKER_TYPE_UPDATE_BEGIN = 'syftacp/WORKER_TYPE_UPDATE_BEGIN'
export const WORKER_TYPE_UPDATE_FAILED = 'syftacp/WORKER_TYPE_UPDATE_FAILED'
export const WORKER_TYPE_UPDATE_SUCCEEDED = 'syftacp/WORKER_TYPE_UPDATE_SUCCEEDED'
export const WORKER_UPCOMING_JOBS_FETCH_BEGIN = 'syftacp/WORKER_UPCOMING_JOBS_FETCH_BEGIN'
export const WORKER_UPCOMING_JOBS_FETCH_FAILED = 'syftacp/WORKER_UPCOMING_JOBS_FETCH_FAILED'
export const WORKER_UPCOMING_JOBS_FETCH_SUCCEEDED = 'syftacp/WORKER_UPCOMING_JOBS_FETCH_SUCCEEDED'
export const WORKER_USER_DELETE_BEGIN = 'syftacp/WORKER_USER_DELETE_BEGIN'
export const WORKER_USER_DELETE_FAILED = 'syftacp/WORKER_USER_DELETE_FAILED'
export const WORKER_USER_DELETE_SUCCEEDED = 'syftacp/WORKER_USER_DELETE_SUCCEEDED'
export const WORKER_TRANSFER_FETCH_BEGIN = 'syftacp/WORKER_TRANSFER_FETCH_BEGIN'
export const WORKER_TRANSFER_FETCH_FAILED = 'syftacp/WORKER_TRANSFER_FETCH_FAILED'
export const WORKER_TRANSFER_FETCH_SUCCEEDED = 'syftacp/WORKER_TRANSFER_FETCH_SUCCEEDED'
export const WORKER_TRANSFER_CREATE_BEGIN = 'syftacp/WORKER_TRANSFER_CREATE_BEGIN'
export const WORKER_TRANSFER_CREATE_FAILED = 'syftacp/WORKER_TRANSFER_CREATE_FAILED'
export const WORKER_TRANSFER_CREATE_SUCCEEDED = 'syftacp/WORKER_TRANSFER_CREATE_SUCCEEDED'
export const WORKER_TRANSFER_UPDATE_BEGIN = 'syftacp/WORKER_TRANSFER_UPDATE_BEGIN'
export const WORKER_TRANSFER_UPDATE_FAILED = 'syftacp/WORKER_TRANSFER_UPDATE_FAILED'
export const WORKER_TRANSFER_UPDATE_SUCCEEDED = 'syftacp/WORKER_TRANSFER_UPDATE_SUCCEEDED'
export const WORKER_TRANSFER_DELETE_BEGIN = 'syftacp/WORKER_TRANSFER_DELETE_BEGIN'
export const WORKER_TRANSFER_DELETE_FAILED = 'syftacp/WORKER_TRANSFER_DELETE_FAILED'
export const WORKER_TRANSFER_DELETE_SUCCEEDED = 'syftacp/WORKER_TRANSFER_DELETE_SUCCEEDED'
export const WORKERS_FETCH_BEGIN = 'syftacp/WORKERS_FETCH_BEGIN'
export const WORKERS_FETCH_CSV = 'syftacp/WORKERS_FETCH_CSV'
export const WORKERS_FETCH_FAILED = 'syftacp/WORKERS_FETCH_FAILED'
export const WORKERS_FETCH_SUCCEEDED = 'syftacp/WORKERS_FETCH_SUCCEEDED'
