import React, { useEffect, useState } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Grid, Col, Row } from 'react-bootstrap'
import { get } from 'lodash-es'

import { useFlexFlagIsOn } from '@indeed/flex-feature-flags'
import { useTrackingTrigger } from '@indeed/flex-tracking-context'

import entityDetail from 'syft-acp-util/entityDetail'
import { EntityAlertConnected } from 'syft-acp-core/components/Alerts/EntityAlert'
import { WorkerShiftsList } from 'syft-acp-core/entities/WorkerShifts'
import { WorkerShiftEntity } from 'syft-acp-core/store/types'
import * as workerStrikesActions from 'syft-acp-core/store/workerStrikes/actions'
import RecordSection from 'syft-acp-core/components/RecordSection'
import SiteComponent from 'syft-acp-core/components/SiteComponent'

import WorkerStrikeDetailsControls from '../WorkerStrikeDetailsControls'
import { getDataStructure, SAVE_BUTTON_EVENT } from './WorkerStrikeDetails.helpers'
import { WorkerStrikeDetailsProps as Props } from './WorkerStrikeDetails.types'

const StrikeAlert = EntityAlertConnected('workerStrikes')

export const storeConnector = connect(null, dispatch => ({
  actions: bindActionCreators(workerStrikesActions, dispatch),
}))

export const WorkerStrikeDetails = ({ data, actions, workerID, id = 0, actionUpdate, lastBody }: Props) => {
  const isNegativeAction = useFlexFlagIsOn('pte_34577_negative_actions')

  const [selectedShiftID, setSelectedShiftID] = useState<number | null>(null)
  const triggerEvent = useTrackingTrigger()

  useEffect(() => {
    if (workerID && id) actions.fetchWorkerStrike(workerID, id, {})
    if (data.shift_id) setSelectedShiftID(data.shift_id)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const errorKey = () =>
    get(lastBody, 'error_details.fields[0]') ? 'error_details.fields[0]' : 'debug.message'

  const selectShift = (row: WorkerShiftEntity) => {
    const newSelectedShiftID = row.shift_id
    setSelectedShiftID(newSelectedShiftID)
    actionUpdate('shift_id', newSelectedShiftID, [])
  }

  const onSave = () => {
    triggerEvent(SAVE_BUTTON_EVENT)
  }

  return (
    <SiteComponent>
      <Grid>
        <Row>
          <Col md={12} sm={12}>
            <WorkerStrikeDetailsControls data={data} workerID={workerID} id={id} onSave={onSave} />
            <StrikeAlert lastBodyKey={errorKey()} />
            <RecordSection
              title={isNegativeAction ? 'Negative action information' : 'Strike information'}
              data={data}
              structure={getDataStructure(!id)}
              update={actionUpdate}
            />
            {/* TODO: handle the selectedRow inside the EntityList itself. */}
            <WorkerShiftsList
              selectCallback={selectShift}
              selectedRow={{ key: 'shift_id', value: selectedShiftID }}
              selectFullRow
              selectSingleRow
              showListingLink
              workerID={workerID}
            />
          </Col>
        </Row>
      </Grid>
    </SiteComponent>
  )
}

export default storeConnector(entityDetail('workerStrikes', WorkerStrikeDetails))
