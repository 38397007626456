import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import * as filtersActions from 'syft-acp-core/store/filters/actions'
import { fetchAssignees as fetchConductReviewAssignees } from 'syft-acp-core/store/conductReviews/actions'
import { fetchAssignees as fetchAppealAssignees } from 'syft-acp-core/store/appeals/actions'

import { filterSelect } from '../FilterSelect'
import { FilterAssigneesProps as Props, FilterAssigneesOwnProps as OwnProps } from './FilterAssignees.types'

const fetchMap: Record<OwnProps['storeKey'], () => { type: string }> = {
  conductReviews: fetchConductReviewAssignees,
  appeals: fetchAppealAssignees,
}

export const storeConnector = connect(
  (state, { storeKey }: OwnProps) => ({
    query: state.routing.locationBeforeTransitions.query,
    assignees: state[storeKey].assignees,
    me: state.auth.userData,
  }),
  (dispatch, { storeKey }) => ({
    actions: bindActionCreators(
      {
        ...filtersActions,
        fetchAssignees: fetchMap[storeKey],
      },
      dispatch
    ),
  })
)

const mapAssignees = (assignees: Props['assignees'] = [], me: Props['me']) => {
  return [
    {
      id: 'unassigned',
      label: 'Unassigned',
    },
    {
      id: me.id,
      label: `${me.email} (me)`,
    },
    ...assignees
      .map(({ id, email }) => ({
        id,
        label: email,
      }))
      .filter(({ id }) => id !== me.id),
  ]
}

const queryKey = 'assignee_id'

const FilterAssignees = ({ actions, assignees, me, query, onChange }: Props) => {
  const value = query.assigned === 'false' ? 'unassigned' : query[queryKey]
  const FilterSelect = filterSelect(mapAssignees(assignees, me), 'Any', 'id', false)

  const onChangeFn = (val: string) => {
    if (onChange) onChange(val)
    resetFilters()
    if (val === 'unassigned') actions.setFilter('assigned', 'false')
    else {
      actions.setFilter('assigned', 'true')
      actions.setFilter(queryKey, val)
    }
  }

  const resetFilters = () => {
    actions.resetFilters({
      assigned: query.assigned as string,
      outcome_reached: query.outcome_reached as string,
      reason: query.reason as string,
    })
  }

  useEffect(() => {
    actions.fetchAssignees()
    // Set current user as default
    if (!query[queryKey] && query.assigned !== 'false') actions.setFilter(queryKey, me.id + '')
    if (!query.assigned) actions.setFilter('assigned', 'true')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return <FilterSelect name={queryKey} onChange={onChangeFn} value={value} allowAny />
}

export default storeConnector(FilterAssignees)
