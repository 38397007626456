import { DISMISSIBLE_ALERTS_DISMISS } from './action-types'

import { DismissedStateDismissed, DismissibleAlertsAction } from './types'
import { dismissAlert } from './actions'

export const initialState = {
  dismissed: [] as DismissedStateDismissed[],
}

export const rehydrateReset = {
  isLoadingData: false,
  message: '',
}

const reducer = (state = initialState, action: DismissibleAlertsAction) => {
  switch (action.type) {
    case DISMISSIBLE_ALERTS_DISMISS:
      let curAction = action as ReturnType<typeof dismissAlert>

      return {
        ...state,
        dismissed: state.dismissed
          .filter(dismissed => dismissed.id !== curAction.id)
          .concat([{ id: curAction.id, dismissed_at: curAction.dismissed_at }]),
      }
    default:
      return state
  }
}

export default reducer
