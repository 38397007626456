import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { get } from 'lodash-es'

import { Button } from 'syft-acp-atoms/Button'
import { stopNewWorkers } from 'syft-acp-core/store/shifts/actions'

const ListingStopNewWorkersButton = ({ shiftID, dispatch, shift, isLoadingData, isSyftForce, callback }) => {
  const onClick = () => {
    callback && callback(shiftID, shift.stop_new_workers)
    dispatch(
      stopNewWorkers(
        shiftID,
        shift.stop_new_workers,
        shift.stop_offers_for_worker_type.length === 0 ? ['syft'] : [],
      ),
    )
  }
  const stopOffersForWorkerType = get(shift, 'stop_offers_for_worker_type[0]')
  const stoppedOffers = (isSyftForce && stopOffersForWorkerType) || (!isSyftForce && shift.stop_new_workers)
  const label = stoppedOffers ? 'Resume shift offers' : 'Stop shift offers'

  return (
    <Button
      onClick={onClick}
      title="Stop shift from being offered out anymore"
      disabled={!shiftID || isLoadingData}
    >
      {label}
    </Button>
  )
}

ListingStopNewWorkersButton.propTypes = {
  shiftID: PropTypes.number.isRequired,
  isLoadingData: PropTypes.bool.isRequired,
  isSyftForce: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
  callback: PropTypes.func,
  shift: PropTypes.object.isRequired,
}

export default connect((state, props) => ({
  isLoadingData: state.listingShifts.isLoadingData,
  shift: state.listingShifts.entityDetail[props.shiftID] || {},
}))(ListingStopNewWorkersButton)
