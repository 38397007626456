import { apiRequest, fileRequest, handleCall } from 'syft-acp-core/api/call'

export const fetchAttachments = ({ documentID }) =>
  new Promise((resolve, reject) => {
    const req = apiRequest({ path: `/documents/${documentID}/attachments` })
    handleCall(req, resolve, reject, true, { documentID })
  })

export const fetchAttachment = ({ documentID, secureToken }) =>
  new Promise((resolve, reject) => {
    const req = apiRequest({ path: `/documents/${documentID}/attachments/${secureToken}` })
    handleCall(req, resolve, reject, true, { documentID })
  })

export const saveAttachment = ({ documentID, secureToken, file }) =>
  new Promise((resolve, reject) => {
    const req = secureToken
      ? fileRequest(`/documents/${documentID}/attachments/${secureToken}`, file, 'PATCH')
      : fileRequest(`/documents/${documentID}/attachments`, file, 'POST')
    handleCall(req, resolve, reject, true, { documentID })
  })

export const deleteAttachment = ({ documentID, secureToken }) =>
  new Promise((resolve, reject) => {
    const req = apiRequest({ path: `/documents/${documentID}/attachments/${secureToken}`, method: 'DELETE' })
    handleCall(req, resolve, reject, true, { documentID })
  })
