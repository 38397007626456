import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { get } from 'lodash-es'

import { Button } from 'syft-acp-atoms/Button'
import { notify } from 'syft-acp-core/actions/notifications'
import { apiRequest, handleCall } from 'syft-acp-core/api/call'
import { fetchAdminShiftListing } from 'syft-acp-core/store/listing-shifts/actions'

// "Offer to all" button, asking anyone suitable for the job if they want to apply.
//
//   * <https://syftapp.atlassian.net/wiki/spaces/SV/pages/163315764/SyftForce+Posting+on+Syft+API>
//   * <https://syftapp.atlassian.net/wiki/spaces/SV/pages/14680254/Publish+Listing+API>
//   * <https://syftapp.atlassian.net/wiki/spaces/SV/pages/228294700/Publish+Listing+on+Syft+API>
//
// Type must be 'all' or 'staff'.

const entityStore = 'listingAppliedWorkers'

export const unprocessableEntityErrorMessages = {
  missing_payment_method: 'You require a payment method setup in order to publish listings.',
  original_flow_invoice_only:
    'You are setup to pay by card, but only invoicing payments are allowed through this channel; please publish listings through the web portal',
  inactive_payment_method:
    'Your payment method is disabled; you require an active payment method to publish listings.',
  incomplete: 'Listing is incomplete (e.g. jobs have no pay rates, or employer is missing some data).',
}

const listingOfferFactory = (type, eventCallback) => {
  return connect(state => ({ entityMap: state[entityStore].entityMap, listings: state.listings }))(
    class ListingOfferButton extends React.PureComponent {
      static propTypes = {
        label: PropTypes.string.isRequired,
        disabled: PropTypes.bool.isRequired,
        listingID: PropTypes.number.isRequired,
        shiftID: PropTypes.number.isRequired,
        dispatch: PropTypes.func.isRequired,
        listings: PropTypes.object,
      }

      static defaultProps = {}

      constructor() {
        super()
        this.state = {
          usedOnce: false,
        }
      }

      handleNotFound = () => {
        const { listingID } = this.props
        this.props.dispatch(
          notify('error', {
            title: `Could not offer listing to ${type}`,
            autoDismiss: 0,
            message: `Could not find listing with ID ${listingID}`,
          }),
        )
      }

      handleUnprocessableEntity = data => {
        this.props.dispatch(
          notify('error', {
            title: `Could not offer listing to ${type}`,
            autoDismiss: 0,
            message:
              unprocessableEntityErrorMessages[data.error] ||
              `Reason: ${data.message || data.error_description}`,
          }),
        )
      }

      handleOtherError = message => {
        this.props.dispatch(
          notify('error', {
            title: `Could not offer listing to ${type}`,
            autoDismiss: 0,
            message: (
              <div>
                <div>
                  <strong>Error:</strong>
                </div>
                <div>{message}</div>
              </div>
            ),
          }),
        )
      }

      typeToOfferTo = offerType => {
        if (offerType === 'all') {
          return 'network,random'
        }
        if (offerType === 'staff') {
          return 'network'
        }

        return offerType
      }

      onClick = async () => {
        const { listingID, shiftID, listings } = this.props
        this.setState({ usedOnce: true })
        eventCallback && eventCallback()
        // Check whether this is a Flex or Flex+ listing.
        const listingData = get(listings, `entityMap[${listingID}]`, {})
        const platformID = get(listingData, 'platform_id', 1)
        const isFlexPlus = Number(platformID) !== 1
        // Use type in error message if something goes wrong.

        try {
          if (type === 'staff' && isFlexPlus) {
            // We don't send out staff offers for Flex+ listings.
            // In this case we probably need to tell the admin to go to the webapp instead.
            return this.props.dispatch(
              notify('error', {
                title: `Could not offer listing to staff`,
                autoDismiss: 0,
                message: `This is a Flex+ listing; to offer to staff, login to the employer's account on the webapp.`,
              }),
            )
          }

          const requestParams = {
            path: `/listings/${listingID}/publish_and_offer`,
            reqArgs: {
              offer_to: this.typeToOfferTo(type),
              syft_posting: isFlexPlus,
            },
            method: 'PUT',
          }
          const flexPlusRequestParams = {
            utilizePagination: false,
            sfPlatform: platformID,
            ...requestParams,
          }

          handleCall(
            apiRequest(isFlexPlus ? flexPlusRequestParams : requestParams),
            () => {
              // Everything went well.
              this.props.dispatch(
                notify('success', {
                  title: 'Success',
                  message: isFlexPlus
                    ? `Flex+ listing has been offered to ${type}.`
                    : `Listing has been offered to ${type}.`,
                }),
              )
              // Display the new applied/offered state.
              this.props.dispatch(fetchAdminShiftListing(listingID, shiftID))
            },
            err => {
              if (err.status === 404) {
                this.handleNotFound()
              } else if (err.status === 422) {
                this.handleUnprocessableEntity(err.body)
              } else if (err.message) {
                this.handleOtherError(err.message)
              }
            },
          )
        } catch (err) {
          this.props.dispatch(
            notify('error', {
              title: `Could not offer listing to ${type}`,
              autoDismiss: 0,
              message: `Error: ${String(err)}`,
            }),
          )
        }
      }

      render() {
        const { usedOnce } = this.state
        const { label, disabled } = this.props
        return (
          <Button onClick={this.onClick} disabled={disabled || usedOnce}>
            {label}
          </Button>
        )
      }
    },
  )
}

export default listingOfferFactory
