import React from 'react'
import PropTypes from 'prop-types'

import FilterCountries from 'syft-acp-util/components/FilterForm/FilterCountries'

const DataCountryDropdown = ({ value, editable, onChange, enabled }) => (
  <FilterCountries value={value} enabled={enabled && editable} onChange={onChange} />
)

DataCountryDropdown.propTypes = {
  value: PropTypes.string.isRequired,
  editable: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  enabled: PropTypes.bool.isRequired,
}

export default DataCountryDropdown
