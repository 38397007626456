import PropTypes from 'prop-types'

import { tableColors } from '../AcpTable'
import placeholderFactory from './factory'

export default placeholderFactory('AcpOption', {
  slug: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  color: PropTypes.oneOf(tableColors),
  placeholder: PropTypes.string,
})
