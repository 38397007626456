// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import PropTypes from 'prop-types'
import React, { useCallback, useState } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Grid, Col, Row } from 'react-bootstrap'
import Table from 'syft-acp-util/components/Table'
import SiteComponent from '../../components/SiteComponent'
import RecordSection from '../../components/RecordSection'
import DataGenericFileUpload from '../../components/EditableTable/DataGenericFileUpload'
import EditableTableRow from '../../components/EditableTable/EditableTableRow'
import { uploadAccessRequestData } from '../../actions/access-request-data'

const ArchiveUpload = ({ action, requestId, reporter, isLoading, error }) => {
  const [invalidError, setInvalidError] = useState(null)
  const handleUpload = useCallback(
    accepted => {
      action(requestId, reporter, accepted[0])
    },
    [requestId, reporter, action]
  )

  const handleInvalidUpload = useCallback(rejected => {
    setInvalidError(`Please select one zip file. Error: ${rejected}`)
  }, [])

  return (
    <DataGenericFileUpload
      mimeTypes={['application/zip', 'application/octet-stream', 'application/x-zip-compressed', 'multipart/x-zip']}
      buttonText="Submit request"
      handleUpload={handleUpload}
      error={invalidError || error}
      isLoading={isLoading}
      handleInvalidUpload={handleInvalidUpload}
    />
  )
}

ArchiveUpload.propTypes = {
  action: PropTypes.func.isRequired,
  error: PropTypes.string.isRequired,
  isLoading: PropTypes.bool.isRequired,
  reporter: PropTypes.string.isRequired,
  requestId: PropTypes.string.isRequired,
}

export const DataRequestFormUI = ({ actions, requestId, reporter, isLoading, error }) => {
  return (
    <>
      <SiteComponent>
        <Grid>
          <Row>
            <Col md={12} sm={12}>
              <RecordSection
                title={`Submit data for ACCESS request ${reporter}:${requestId}`}
                footerText="IMPORTANT: Uploading this form will trigger the ACCESS request submission."
              >
                <Table noRightBorder noRowBorders className="editable-table">
                  <tbody>
                    <EditableTableRow
                      row={{
                        header: 'File upload',
                        node: (
                          <ArchiveUpload
                            action={actions.uploadAccessRequestData}
                            requestId={requestId}
                            reporter={reporter}
                            isLoading={isLoading}
                            error={error}
                          />
                        ),
                      }}
                    />
                  </tbody>
                </Table>
              </RecordSection>
            </Col>
          </Row>
        </Grid>
      </SiteComponent>
    </>
  )
}

DataRequestFormUI.propTypes = {
  actions: PropTypes.shape({
    uploadAccessRequestData: PropTypes.func.isRequired,
  }).isRequired,
  error: PropTypes.string.isRequired,
  isLoading: PropTypes.bool.isRequired,
  reporter: PropTypes.string.isRequired,
  requestId: PropTypes.string.isRequired,
}

export default connect(
  state => ({
    isLoading: state.accessRequestData.isLoading,
    error: state.accessRequestData.error,
  }),
  dispatch => ({
    actions: bindActionCreators({ uploadAccessRequestData }, dispatch),
  })
)(DataRequestFormUI)
