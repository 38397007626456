import * as types from './action-types'

export const selectTabRole = (id: number) => ({
  type: types.SELECT_TAB_ROLE,
  id,
})
export const selectTabVenue = (id: number) => ({
  type: types.SELECT_TAB_VENUE,
  id,
})
export const selectTabArea = (id: number) => ({
  type: types.SELECT_TAB_AREA,
  id,
})
export const resetAllTabs = () => ({
  type: types.RESET_ALL_TABS,
})
