import React from 'react'
import { Label } from 'react-bootstrap'
import classnames from 'classnames'

import './DataExternalUUIDsItem.css'

const ArrayItem = ({
  id,
  index,
  onDelete,
  onEdit,
  children,
  isBeingEdited,
  className,
  title,
  setToDelete,
  isSaved,
}) => (
  <Label
    className={classnames('DataExternalUUIDsItem', 'tag', className, {
      'to-delete': setToDelete,
      'is-saved': isSaved,
      'is-new': !isSaved,
      'is-being-edited': isBeingEdited,
    })}
    bsStyle="default"
    title={title}
    onClick={() => onEdit(id, index)}
  >
    <span className="content">{children}</span>
    <span className="delete" onClick={() => onDelete(id, index)} />
  </Label>
)

export default ArrayItem
