import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { get } from 'lodash-es'

import DataRating from 'syft-acp-core/components/EditableTable/DataRating'
import { ButtonLink } from 'syft-acp-atoms/Button'
import Table from 'syft-acp-util/components/Table'
import * as workersActions from 'syft-acp-core/store/workers/actions'

import './WorkerQuickview.css'

class WorkerQuickview extends React.PureComponent {
  componentDidMount(props = this.props) {
    this.loadWorkerData(props.id)
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.loadWorkerData(nextProps.id)
  }

  getWorkerData = id => {
    return get(this.props.workerDetails, id, get(this.props.workerOverview, id, {}))
  }

  loadWorkerData = id => {
    const workerData = this.getWorkerData(id)
    if (!workerData.id) {
      this.props.actions.fetchWorker(id)
    }
  }

  render() {
    const workerData = this.getWorkerData(this.props.id)
    return (
      <div className="WorkerQuickview">
        <Table bordered className="info-table">
          <tbody>
            <tr>
              <th>ID</th>
              <td>{get(workerData, 'id')}</td>
            </tr>
            <tr>
              <th>Name</th>
              <td>{get(workerData, 'full_name')}</td>
            </tr>
            <tr>
              <th>Email</th>
              <td>{get(workerData, 'email')}</td>
            </tr>
            <tr>
              <th>Avg. rating</th>
              <td>
                <DataRating amount={get(workerData, 'average_rating')} />
              </td>
            </tr>
          </tbody>
        </Table>
        <ButtonLink to={`/entity/workers/view/${this.props.id}`}>View profile</ButtonLink>
      </div>
    )
  }
}

export default connect(
  state => ({
    workerDetails: state.workers.entityDetail,
    workerOverview: state.workers.entityMap,
  }),
  dispatch => ({
    actions: bindActionCreators(workersActions, dispatch),
  }),
)(WorkerQuickview)
