import {
  fetchAgencyAccounts,
  saveAgencyAccount,
  fetchAgencyAccountsPermissions,
} from 'syft-acp-core/api/resources/agency-accounts'
import { AGENCY_ACCOUNTS_UPDATE_SUCCEEDED, AGENCY_ACCOUNTS_UPDATE_FAILED } from 'syft-acp-core/actions/action-types'

import { takeEveryApiCall, takeLatestApiCall } from './helpers'
import { notificationCall } from 'syft-acp-core/sagas/calls'
import { takeLatest } from 'redux-saga/effects'

export default function* root() {
  yield takeEveryApiCall('AGENCY_ACCOUNTS_FETCH', fetchAgencyAccounts)
  yield takeEveryApiCall('AGENCY_ACCOUNTS_PERMISSIONS_FETCH', fetchAgencyAccountsPermissions)
  yield takeLatestApiCall('AGENCY_ACCOUNTS_UPDATE', saveAgencyAccount)
  yield takeLatest([AGENCY_ACCOUNTS_UPDATE_SUCCEEDED, AGENCY_ACCOUNTS_UPDATE_FAILED], notificationCall)
}
