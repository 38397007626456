import { createSelector } from 'reselect'
import { memoize } from 'lodash-es'

import { initialModalState } from './reducer'
import { DefaultRootState } from 'react-redux'

/**
 * Modal selector used to get a reference to a specific modal window.
 * This can be used in a Redux connect() call, for example:
 *
 *    MyComponent = connect(
 *      state => ({
 *      item: getModal$(state.modals)('confirm')
 *    })(MyComponent);
 */
export const getModal$ = createSelector(
  (state: DefaultRootState['modals']) => state.items,
  items =>
    memoize((name: keyof typeof items) => {
      if (!items[name]) {
        return {
          ...initialModalState,
          modalName: name?.toString(),
        }
      }
      return items[name]
    })
)
