import React from 'react'
import PropTypes from 'prop-types'
import ClipboardButton from 'react-clipboard.js'
import classnames from 'classnames'

import iconFactory from 'syft-acp-atoms/Icons'

import './CopyableText.css'

// Copy icon from Octicons.
const ClippyIcon = iconFactory('clippy')

export default class CopyableText extends React.PureComponent {
  static propTypes = {
    children: PropTypes.node,
    copyValue: PropTypes.string,
    onClick: PropTypes.func,
    noHide: PropTypes.bool,
    noFloat: PropTypes.bool,
  }

  static defaultProps = {
    children: null,
    copyValue: null,
    onClick: null,
    noHide: false,
    noFloat: false,
  }

  onClickHandle = value => ev => {
    navigator.clipboard.writeText(value)
    return ev.preventDefault()
  }

  render() {
    const { children, copyValue, noHide, noFloat, onClick } = this.props
    return (
      <div className={noHide ? 'copyable-text' : 'copyable-text hover-hidden'}>
        {children}
        <ClipboardButton
          /* Prevent click-through from activating if inside a link. */
          onClick={onClick || this.onClickHandle(copyValue)}
          className={classnames('Button', 'copy-button', { 'no-float': noFloat })}
          data-clipboard-text={copyValue || children}
        >
          <ClippyIcon iconSize={15} />
        </ClipboardButton>
      </div>
    )
  }
}
