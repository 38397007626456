import React from 'react'
import { compact } from 'lodash-es'

/**
 * Returns whether an item is forced to have a header cell.
 *
 * Only selector columns have a forced header cell right now.
 */
const hasForcedHeader = name => {
  if (name === 'AcpCellSelector') return true
  return false
}

/**
 * Recursively analyzes a node structure and extracts placeholder prop data.
 *
 * This function extracts all needed information for components that are configured
 * through placeholder nodes. Pass on the child nodes of such a component and optionally
 * a 'types' or 'typeGroups' array, and all data needed to render that component will be returned.
 *
 * The 'types' arrays are used to restrict the type of placeholder nodes we'll consider.
 * For example, if you're looking for all footer placeholder components, use ['Footer'] as types.
 * If you're looking for a group of components, such as all column types (ColTxt, ColWorker, etc.),
 * use ['Col'] as typeGroups.
 *
 * As long as this is passed a valid React node, this will return an array.
 */
export const extractNodeSpec = (childNodes, types = null, typeGroups = null) =>
  compact(
    React.Children.map(childNodes, node => {
      if (!node || !node.type) {
        return null
      }
      const { type } = node
      const name = type.identityName
      const group = type.groupName
      const nested = type.nestedTypes
      const nestedGroups = type.nestedTypeGroups

      // Exit if this item is not a proper placeholder element.
      if (!name) {
        return null
      }
      // Exit if the node doesn't match either a requested type or type group.
      const matchesType = types != null && types.indexOf(name) > -1
      const matchesGroup = typeGroups != null && typeGroups.indexOf(group) > -1
      if (!matchesType && !matchesGroup) {
        return null
      }
      const nodeChildren =
        (nested && nested.length) || (nestedGroups && nestedGroups.length)
          ? { _nested: extractNodeSpec(node.props.children, nested, nestedGroups) }
          : {}
      return {
        ...node.props,
        ...nodeChildren,
        _type: name,
        _typeGroup: group,
        _node: node,
        _hasForcedHeader: hasForcedHeader(name),
      }
    })
  )

/**
 * Recursively analyzes a node structure and extracts placeholder prop data.
 *
 * Works just like extractNodeSpec() but returns the results as an object of arrays,
 * one for each type.
 *
 * Other arguments:
 *
 *    getSingle        - returns the first item found as a single item and discards the rest
 *    getComponentOnly - returns the component instead of the props and other data
 */
export const extractTypeNodeSpec = (
  childNodes,
  types = [],
  typeGroups = null,
  getSingle = false,
  getComponentOnly = false
) => {
  if (!types.length) return {}

  const nodeSpec = extractNodeSpec(childNodes, types, typeGroups)
  const typedSpec = types.reduce((acc, type) => ({ ...acc, [type]: [] }), {})

  // Fill each type array with relevant child nodes.
  for (const node of nodeSpec) {
    const type = node._type
    if (!typedSpec[type]) typedSpec[type] = []
    typedSpec[type].push(node)
  }

  // Save only the component and discard the rest of the data if needed.
  if (getComponentOnly) {
    for (const type of Object.keys(typedSpec)) {
      typedSpec[type] = typedSpec[type].map(t => t._node)
    }
  }

  // Restrict each type to a single item if needed.
  if (getSingle) {
    for (const type of Object.keys(typedSpec)) {
      typedSpec[type] = typedSpec[type][0] ? typedSpec[type][0] : null
    }
  }

  return typedSpec
}
