export const formi9Statuses = {
  Incomplete: {
    name: 'Incomplete',
    style: 'info',
    message: 'Form I9 is not complete',
  },
  NewEntry: {
    name: 'New Entry',
    message: 'Section 1 is not complete by the worker.',
    style: 'info',
  },
  Section1LocalSave: {
    name: 'Section 1 Local Save',
    message: 'Section 1 is saved with some errors',
    style: 'info',
  },
  Section1Complete: {
    name: 'Section 1 Complete',
    message: 'Section 1 is complete by the worker',
    style: 'success',
  },
  FormI9Complete: {
    name: 'Complete',
    message: 'Section 1 and Section 2 are complete',
    style: 'success',
  },
  WaitingInvoicing: {
    name: 'Waiting Invoicing',
    message: 'Section 1 and Section 2 are complete',
    style: 'success',
  },
  INVOICED: {
    name: 'Invoiced',
    message: 'Section 1 and Section 2 are complete',
    style: 'success',
  },
}

export const formi9StatusKeys = Object.keys(formi9Statuses).reduce((keys, key) => ({ ...keys, [key]: key }), {})
