// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import React, { PureComponent } from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'

import FileUpload from 'syft-acp-core/components/FileUpload'
import LoadingIndicator from 'syft-acp-util/components/LoadingIndicator'

import './DataGenericFileUpload.css'

/**
 * Displays a file upload input field.
 */
class DataGenericFileUpload extends PureComponent {
  static propTypes = {
    buttonText: PropTypes.string,
    handleUpload: PropTypes.func.isRequired,
    handleInvalidUpload: PropTypes.func.isRequired,
    // eslint-disable-next-line react/no-unused-prop-types
    isLoading: PropTypes.bool.isRequired,
    error: PropTypes.string,
    mimeTypes: PropTypes.arrayOf(PropTypes.string),
    value: PropTypes.shape({
      uuid: PropTypes.string,
    }),
    // eslint-disable-next-line react/no-unused-prop-types
    lastUploadedImage: PropTypes.object,
  }

  static defaultProps = {
    lastUploadedImage: null,
    error: false,
    mimeTypes: ['*'],
    buttonText: 'Choose file',
    value: {
      uuid: null,
    },
  }

  handleUpload = (accepted, rejected) => {
    if (accepted.length !== 1 || rejected.length !== 0) {
      this.props.handleInvalidUpload(rejected)
      return
    }

    this.props.handleUpload(accepted)
  }

  getAcceptedFileTypes = () => {
    const accepted = {}
    this.props.mimeTypes.forEach(type => (accepted[type] = []))
    return accepted
  }

  render() {
    const { buttonText, error, isLoading } = this.props

    return (
      <div className="DataFileUpload">
        {/* Display the file uploader and the current file. */}
        <div className="main">
          <FileUpload accept={this.getAcceptedFileTypes()} buttonText={buttonText} onDrop={this.handleUpload} />
        </div>
        <div className={classnames('feedback')}>
          <div className={classnames('error', { hidden: isLoading || !error })}>{error}</div>
          {/* Loading indicator. */}
          {isLoading && (
            <div>
              <LoadingIndicator loadingState colorDark="#757575" />
              <span> Uploading...</span>
            </div>
          )}
        </div>
      </div>
    )
  }
}

export default DataGenericFileUpload
