import { get } from 'lodash-es'
import React, { useEffect } from 'react'
import { ConnectedProps, connect } from 'react-redux'

import CountrySpecific from 'syft-acp-core/components/CountrySpecific'
import EntityDetailItem from 'syft-acp-core/components/EntityDetail/EntityDetailItem'
import RecordName from 'syft-acp-core/components/RecordSection/RecordName'
import { Formi9Labels, PaycomLabels, WorkerLabels } from 'syft-acp-core/components/TypeMaps'
import { SUPPORTED_COUNTRY_CODES } from 'syft-acp-core/lib/i18n'
import { formatFullName } from 'syft-acp-util/formatting'

import './WorkerHeader.scss'
import DuplicateAccountsAlert from '../DuplicateAccountsAlert'
import { bindActionCreators } from 'redux'
import { fetchWorkerDuplicateAccountsCount } from 'syft-acp-core/store/worker-duplicate-accounts-count/actions'
import { WorkerEntity } from 'syft-acp-core/store/types'

export const storeConnector = connect(null, dispatch => ({
  actions: bindActionCreators({ fetchWorkerDuplicateAccountsCount }, dispatch),
}))

type Props = ConnectedProps<typeof storeConnector> & {
  data: WorkerEntity
  noWrapper?: boolean
}

const WorkerHeader = ({ data, noWrapper, actions }: Props) => {
  const userName = formatFullName(data) || ''
  const userBio = data?.bio ?? ''
  const avatar = data?.profile_picture?.uuid ?? 'guest'
  const isVerified = !!data?.approved_at
  const isTempData = userName === '' && userBio === ''

  const workerLabels = (
    <>
      <WorkerLabels value={get(data, 'status') || {}} />
      <CountrySpecific countries={[SUPPORTED_COUNTRY_CODES.US]}>
        <Formi9Labels value={get(data, 'us_i9_form')} full />
        <PaycomLabels value={get(data, 'us_paycom')} full />
      </CountrySpecific>
    </>
  )

  useEffect(() => {
    if (data.id) {
      actions.fetchWorkerDuplicateAccountsCount(data.id)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.id])

  const userData = (
    <RecordName
      name={userName !== '' ? userName : ' '}
      bio={userBio}
      verified={isVerified}
      avatar={avatar}
      avatarSize={66}
      tempData={isTempData}
      extraContent={workerLabels}
    >
      {!!data?.id && <DuplicateAccountsAlert workerId={data.id} />}
    </RecordName>
  )

  if (noWrapper) {
    return <div className="WorkerHeader">{userData}</div>
  }

  return (
    <div className="WorkerHeader">
      <EntityDetailItem>{userData}</EntityDetailItem>
    </div>
  )
}

export default storeConnector(WorkerHeader)
