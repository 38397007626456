// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { FormControl, InputGroup } from 'react-bootstrap'
import NumericInput from 'react-numeric-input'
import { get } from 'lodash-es'

import { currentIntl } from 'syft-acp-util/intl'
import { getSafeCurrencySymbol } from 'syft-acp-util/formatting'

import './DataRate.css'

const DecimalInput = ({ value, className, onChange, disabled, ariaLabel }) => (
  <NumericInput
    precision={2}
    step={0.25}
    disabled={disabled}
    value={value}
    className={className}
    onChange={onChange}
    aria-label={ariaLabel}
  />
)

DecimalInput.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  className: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  ariaLabel: PropTypes.string,
}

const DataRate = ({
  value,
  onChange,
  editable,
  showZero,
  disableOn,
  allData,
  disabled,
  suffix,
  allowEmpty,
  parameters = {},
}) => {
  const rateValue = value == null ? {} : value
  const { amount = '', currency = currentIntl.currencyCode } = rateValue

  // You can pass a 'disableOn' value - this makes us check some value inside allData,
  // and if that value it 'true' it will disable entry.
  let disableOnValue = false
  if (disableOn) {
    disableOnValue = get(allData, disableOn[0], false) === disableOn[1]
  }
  const onChangeInput = useCallback(
    val => onChange(allowEmpty && val === null ? null : { amount: Number(val), currency }),
    [onChange, currency, allowEmpty],
  )

  return editable ? (
    <div className="date-rate-grid">
      <div className="date-rate-col">
        <InputGroup
          className={classnames(
            'rate',
            'editable-data-rate',
            'boxed-input',
            { 'hide-zero': !showZero },
            { 'above-zero': amount > 0 },
            { 'is-zero': amount <= 0 },
          )}
        >
          <InputGroup.Addon>{getSafeCurrencySymbol(currency)}</InputGroup.Addon>
          <span className="if-zero">Not set</span>
          <FormControl
            type="text"
            disabled={disableOnValue || disabled}
            onChange={onChangeInput}
            value={amount}
            ariaLabel={parameters.ariaLabel}
            componentClass={DecimalInput}
          />
        </InputGroup>
      </div>
      <div>{suffix}</div>
    </div>
  ) : (
    <div className="plain-text">
      <div className="date-rate-grid">
        <div className="date-rate-col">
          {getSafeCurrencySymbol(currency)} {amount}
        </div>
        <div>{suffix}</div>
      </div>
    </div>
  )
}

DataRate.propTypes = {
  value: PropTypes.shape({
    amount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    currency: PropTypes.string,
  }).isRequired,
  onChange: PropTypes.func.isRequired,
  editable: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
  showZero: PropTypes.bool,
  disableOn: PropTypes.array,
  allData: PropTypes.object,
  suffix: PropTypes.string,
  parameters: PropTypes.object,
  allowEmpty: PropTypes.bool,
}

DataRate.defaultProps = {
  disabled: false,
  showZero: false,
  disableOn: null,
  allData: null,
  allowEmpty: false,
  suffix: '',
}

export default DataRate
