import { Reducer } from 'redux'
import { LOCATION_CHANGE, RouterActionType, RouterState } from 'connected-react-router'
import queryString, { ParsedQuery } from 'query-string'
import { Location } from 'history'

type LocationWithQuery = Location & { query: ParsedQuery }

type RoutingState = {
  location: LocationWithQuery
  action: RouterActionType
  previousLocation?: LocationWithQuery
  locationBeforeTransitions: LocationWithQuery
}

export const createRoutingReducer =
  (routerReducer: Reducer<RouterState>): Reducer<RoutingState> =>
  (state, action) => {
    const routerState = routerReducer(state as RouterState, action) as RoutingState

    if (action.type === LOCATION_CHANGE) {
      const newLocation = {
        ...routerState?.location,
        query: queryString.parse(action.payload.location?.search),
      } as LocationWithQuery
      const newState = {
        ...routerState,
        location: newLocation,
        locationBeforeTransitions: newLocation,
      }
      const currentLocation = routerState?.locationBeforeTransitions
      const previousLocation = routerState?.previousLocation

      return {
        ...newState,
        previousLocation:
          currentLocation?.pathname === newLocation?.pathname ? previousLocation : currentLocation,
      }
    }

    return routerState
  }
