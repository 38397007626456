import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import FilterHierarchyMultiselect from 'syft-acp-util/components/FilterForm/FilterHierarchyMultiselect'
import useDebounce from 'syft-acp-core/lib/hooks/useDebounce'
import { Store } from 'syft-acp-core/store'
import * as filtersActions from 'syft-acp-core/store/filters/actions'
import { Props, FilterObjectType } from './FilterAutocompleteMultiSelect.types'

export const storeConnector = connect(
  (state: Store) => ({
    query: state.routing.locationBeforeTransitions.query as Record<string, string | string[]>,
  }),
  dispatch => ({
    actions: bindActionCreators({ ...filtersActions }, dispatch),
  }),
)

const FilterAutocompleteMultiSelect = ({
  query,
  actions,
  name,
  queryFunction,
  defaultOptions = [],
  useDefaultValuesFromQuery = false,
  debounceDelay = 300,
}: Props) => {
  const [options, setOptions] = useState(defaultOptions)
  const [searchString, setSearchString] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const debouncedSearchString = useDebounce(searchString, debounceDelay)

  useEffect(() => {
    if (debouncedSearchString !== '') {
      setIsLoading(true)
      queryFunction(debouncedSearchString).then((data: FilterObjectType[]) => {
        setIsLoading(false)
        setOptions(data)
      })
    }
  }, [debouncedSearchString, queryFunction])

  const onSearch = (value: string) => {
    setSearchString(value)
  }

  const onChange = (newValues: FilterObjectType[]) => {
    actions.setFilter(
      name,
      newValues.map(value => value.value.toString()),
    )
  }

  return (
    <FilterHierarchyMultiselect
      values={useDefaultValuesFromQuery ? query[name] : []}
      options={options}
      withSearch
      handleSearch={onSearch}
      onChange={onChange}
      isLoading={isLoading}
      refreshValues={false}
      scrollable
      showEmptyResults
    />
  )
}

export default storeConnector(FilterAutocompleteMultiSelect)
