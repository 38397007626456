// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import React from 'react'

import PageView from 'syft-acp-core/components/PageView'
import UserDetail from 'syft-acp-core/entities/UserDetail'
import SubNavigationAdmin from '../../Admin/SubNavigation'

export const EntityUsersView = ({ match: { params } }) => (
  <>
    <SubNavigationAdmin />
    <PageView title={['Users', `Viewing user ${params.userID}`]}>
      <UserDetail id={Number(params.userID)} />
    </PageView>
  </>
)
