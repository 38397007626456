// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import React from 'react'
import { Grid, Row, Col } from 'react-bootstrap'

import PageTitle from 'syft-acp-core/components/PageTitle'
import DashboardHeader from 'syft-acp-core/views/subviews/DashboardHeader'

const Dashboard = () => (
  <PageTitle title="Dashboard">
    <div className="page dashboard">
      <DashboardHeader />
      <Grid>
        <Row>
          <Col md={12}></Col>
        </Row>
      </Grid>
    </div>
  </PageTitle>
)

export default Dashboard
