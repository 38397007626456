import React from 'react'
import PageView from 'syft-acp-core/components/PageView'
import AgencyShiftHistoryList from 'syft-acp-core/entities2/AgencyShiftHistoryList'

export const AgencyShiftHistory = () => {
  return (
    <PageView title={['Shifts', 'Agency shift history']} entityPage>
      <AgencyShiftHistoryList />
    </PageView>
  )
}
