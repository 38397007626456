import { apiRequest, handleCall } from 'syft-acp-core/api/call'

export const fetchAgencyProfile = ({ options }) =>
  new Promise((resolve, reject) => {
    const req = apiRequest({ path: `/admin/agencies/${options.agencyId}` })
    handleCall(req, resolve, reject)
  })

export const saveAgencyProfile = ({ payload }) =>
  new Promise((resolve, reject) => {
    const req = apiRequest({ path: `/admin/agencies/${payload.id}`, reqArgs: payload, method: 'PATCH' })
    handleCall(req, resolve, reject)
  })
