// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { get } from 'lodash-es'

class VenueDisplay extends React.PureComponent {
  static propTypes = {
    id: PropTypes.number,
    employerVenues: PropTypes.object,
  }

  static defaultProps = {
    id: null,
    employerVenues: {},
  }

  render() {
    const { id, employerVenues } = this.props
    const venue = get(employerVenues, id)
    return <div className="VenueDisplay">{venue ? venue.name : '–'}</div>
  }
}

export default connect(state => ({
  employerVenues: state.employerVenuesFiltered.entityMap,
}))(VenueDisplay)
