import { createSelector } from 'reselect'
import { Store } from 'syft-acp-core/store'
import { GenericState } from 'syft-acp-core/reducers/generators/entityReducer'
import { entitySetName } from 'syft-acp-core/reducers/generators/utils'

import { WorkerMissingPayEntity } from './types'

const selectWorkerMissingPay = (state: Store) => state.workerMissingPay
const selectWorkerId = (state: Store, workerId: number) => workerId

export const selectWorkerMissingPayOptions = createSelector(
  [selectWorkerMissingPay, selectWorkerId],
  (workerMissingPay: GenericState<WorkerMissingPayEntity>, workerId: number) => {
    const setKey = entitySetName({ workerID: workerId })
    const entitySet = workerMissingPay?.entitySets[setKey]
    return entitySet
      ? entitySet.ids.map(id => ({
          id: entitySet.entityMap[id].id,
          label: entitySet.entityMap[id].id,
        }))
      : []
  },
)
