// Syft ACP - UI Library <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import React from 'react'
import PropTypes from 'prop-types'

import { extractTypeNodeSpec } from '../../lib/childNodes'
import AcpGenericWrapper from './wrappers/AcpGenericWrapper'

/**
 * Wrapper component for generic admin panel info boxes.
 *
 * When writing components that display data, use this as wrapper component
 * to display a standard box around the contents.
 */
class AcpComponent extends React.PureComponent {
  static propTypes = {
    children: PropTypes.node,
    isLoading: PropTypes.bool,
    isOK: PropTypes.bool,
    noPadding: PropTypes.bool,
    noMargin: PropTypes.bool,
    isFullWidth: PropTypes.bool,
  }

  static defaultProps = {
    className: null,
    isLoading: false,
    isOK: true,
    children: null,
    // Whether to omit padding (in case the content isn't text).
    noPadding: false,
    // Add some margin to nicely space out objects.
    noMargin: false,
    // Whether to display the component at full container width plus standard grid padding.
    isFullWidth: false,
  }

  render() {
    const { className, children, noPadding, noMargin, isLoading, isOK, isFullWidth } = this.props
    // Retrieve info about placeholder elements.
    const componentData = extractTypeNodeSpec(children, ['AcpFooter', 'AcpHeader', 'AcpActions'], null, true)

    return (
      <AcpGenericWrapper
        children={children}
        className={className}
        isFullWidth={isFullWidth}
        componentData={componentData}
        isLoading={isLoading}
        isOK={isOK}
        noPadding={noPadding}
        noMargin={noMargin}
      />
    )
  }
}

export default AcpComponent
