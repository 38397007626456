import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Button, ButtonGroup } from 'syft-acp-atoms/Button'
import { Link } from 'react-router-dom'

import { withTrackingTriggerHoc } from 'syft-acp-util/withTrackingTriggerHoc'
import * as managersActions from 'syft-acp-core/actions/managers'
import RecordControls from 'syft-acp-core/components/RecordSection/RecordControls'
import LoadingIndicator from 'syft-acp-util/components/LoadingIndicator'
import ManagerDeleteButton from './ManagerDeleteButton'
import { trackingEvents } from './ManagerDetail.tracking'

class ManagerDetailControls extends Component {
  static propTypes = {
    data: PropTypes.object.isRequired,
    isPristine: PropTypes.bool.isRequired,
    isSavingData: PropTypes.bool.isRequired,
    isLoadingData: PropTypes.bool.isRequired,
    triggerEvent: PropTypes.func.isRequired,
  }

  saveAction = () => {
    const { actions, data, triggerEvent } = this.props
    const { id } = data

    if (id) {
      actions.updateAdminManager(id, data)
      triggerEvent(trackingEvents.UPDATE_BUTTON.CLICKED)
    } else {
      actions.createAdminManager(data)
      triggerEvent(trackingEvents.CREATE_BUTTON.CLICKED)
    }
  }

  render() {
    const { data, isSavingData, isLoadingData, isPristine } = this.props

    return (
      <RecordControls>
        <div className="spacer">
          <ButtonGroup>
            <Link to="/admin/managers">
              <Button>Back to list</Button>
            </Link>
          </ButtonGroup>{' '}
        </div>
        <div className="right">
          <LoadingIndicator loadingState={isSavingData || isLoadingData} />{' '}
          <ButtonGroup>
            <Button kind="success" onClick={this.saveAction} disabled={isSavingData || isPristine}>
              Save
            </Button>
          </ButtonGroup>{' '}
          <ButtonGroup>
            {!!data.id && <ManagerDeleteButton isPristine={isPristine} id={data.id} />}
          </ButtonGroup>
        </div>
      </RecordControls>
    )
  }
}

ManagerDetailControls.propTypes = {
  actions: PropTypes.object.isRequired,
}

export default connect(
  ({ managers: { isSavingData, isLoadingData } }) => ({
    isSavingData,
    isLoadingData,
  }),
  dispatch => ({
    actions: bindActionCreators(managersActions, dispatch),
  }),
)(withTrackingTriggerHoc(ManagerDetailControls))
