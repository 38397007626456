import { useMemo } from 'react'

import { dataStructure } from 'syft-acp-core/entities/WorkerDetail/structure'

/**
 * Returns the data structure for the marketing preference fields, disabling preferences if being updated
 *
 * @param isUpdating
 * @returns Field structure to be used by EditableTable
 */
export const useGetFieldsSectionStructure = (isUpdating: boolean) => {
  return useMemo(() => {
    if (!isUpdating) return dataStructure.marketingPreferences

    return dataStructure.marketingPreferences.map(preferenceStructure => [
      preferenceStructure[0],
      {
        ...preferenceStructure[1],
        editable: false,
      },
    ])
  }, [isUpdating])
}
