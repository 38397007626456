export const trackingEvents = {
  PAGE: {
    VIEWED: 'transferred_workers.page.viewed',
  },
  DOWNLOAD_CSV_BUTTON: {
    CLICKED: 'transferred_workers.download_csv_button.clicked',
  },
  UPLOAD_CSV_BUTTON: {
    CLICKED: 'transferred_workers.upload_csv_button.clicked',
  },
  RESEND_INVITATION_BUTTON: {
    CLICKED: 'transferred_workers.resend_invitation_button.clicked',
  },
}
