import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { get } from 'lodash-es'

const AgencyEmployerDisplay = ({ id: { employerID, agencyID }, agencies }) => {
  const agencyEmployers = get(agencies, [agencyID, 'employers'], [])
  const employer = agencyEmployers.find(agencyEmployer => agencyEmployer.id === employerID) || {}
  const employerName = get(employer, 'company_name', '–')

  return <div className="EmployerDisplay">{employerName}</div>
}

AgencyEmployerDisplay.propTypes = {
  id: PropTypes.shape({
    employerID: PropTypes.number,
    agencyID: PropTypes.number,
  }),
  agencies: PropTypes.object,
}

AgencyEmployerDisplay.defaultProps = {
  id: null,
  agencies: {},
}

export default connect(state => ({
  agencies: get(state, `agencyProfile.entityDetail`),
}))(AgencyEmployerDisplay)
