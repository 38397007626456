import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

import UserLine from 'syft-acp-core/components/UserAvatar/UserLine'

import './DataParticipant.css'

const link = ({ id, role }) =>
  role === 'worker' ? `/entity/workers/view/${id}` : `/entity/employers/view/${id}`

const DataParticipant = ({ participant }) => (
  <div className="plain-text with-participant">
    {participant ? (
      <Link to={link(participant)}>
        <UserLine avatarUUID={participant.picture.uuid} name={participant.name} />
      </Link>
    ) : (
      <span>-</span>
    )}
  </div>
)

DataParticipant.propTypes = {
  participant: PropTypes.object,
}

export default DataParticipant
