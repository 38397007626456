// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import React, { useMemo } from 'react'
import PropTypes from 'prop-types'

import { filterSelect } from 'syft-acp-util/components/FilterForm/FilterSelect'
import { connect } from 'react-redux'
import { entityList$ } from '../../reducers/generators/entities'
import { industryRoles } from '../../reducers/industries'
import { bindActionCreators } from 'redux'
import { fetchIndustries } from '../../actions/industries'

const DataRole = ({ value, onChange, industries, label }) => {
  const rolesOptions = useMemo(() => industryRoles(industries), [industries])

  const FilterSelect = filterSelect(rolesOptions, label)

  return (
    <div className="DataEmployer">
      <FilterSelect onChange={onChange} value={value} />
    </div>
  )
}

DataRole.propTypes = {
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.any,
  industries: PropTypes.array,
  label: PropTypes.string,
}

DataRole.defaultProps = {
  disabled: false,
  value: '',
  industries: [],
  label: 'Select a role',
}

export default connect(
  state => {
    return {
      industries: entityList$(state.industries, { employer_id: null }),
    }
  },
  dispatch => ({
    actions: bindActionCreators({ fetchIndustries }, dispatch),
  })
)(DataRole)
