// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import { apiRequest, handleCall } from 'syft-acp-core/api/call'

/**
 * Fetches a list of events related to this employer for the history page.
 *
 * @param id {Number} ID of the entity
 */
export const listEmployerEvents = ({ options = {}, toCSV = false }) =>
  new Promise((resolve, reject) => {
    const { employerID, ...reqArgs } = options
    const req = apiRequest({
      path: `/admin/employers/${employerID}/events`,
      reqArgs,
      returnAll: toCSV,
    })
    handleCall(req, resolve, reject, true, options, null, toCSV, 'employer-events')
  })
