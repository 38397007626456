import React from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { bindActionCreators } from 'redux'

import { getModal$ } from 'syft-acp-core/store/modals/selectors'
import { history } from 'syft-acp-core/history'
import { Modal } from 'syft-acp-core/components/Modal'
import { hideModal } from 'syft-acp-core/store/modals/actions'
import { ModalNames } from 'syft-acp-core/store/employerRateCards/types'
import { useParams } from 'react-router-dom'

type Props = ConnectedProps<typeof storeConnector>

export const RateCardReplacedModal = ({ actions, item }: Props) => {
  const params = useParams<{ id: string }>()
  const handleClose = () => {
    actions.hideModal(item.modalName)
    history.push(`/entity/employers/view/${params?.id}/ratecards-v2/view/${item.data.newRateCardId}`)
  }

  return (
    <Modal header="Rate card in use" isShown={item.isShown} onClose={handleClose} cancelText="Continue">
      This rate cards is currently assigned to a job. To avoid affecting existing jobs, a copy has been made.
      You will be redirected to the new rate card now.
    </Modal>
  )
}

const storeConnector = connect(
  state => ({
    item: getModal$(state.modals)(ModalNames.RateCardsReplace),
  }),
  dispatch => ({
    actions: bindActionCreators({ hideModal }, dispatch),
  }),
)

const WithStoreRouterRateCardReplacedModal = storeConnector(RateCardReplacedModal)

export default WithStoreRouterRateCardReplacedModal
