// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import React from 'react'
import PropTypes from 'prop-types'

import './EntityListFooter.css'

/**
 * Footer displayed at the end of an entity list.
 */
const EntityListFooter = ({ footerContent, tableSize }) => (
  <tfoot data-testid="entity-list-footer">
    <tr>
      <th className="entity-list-footer" colSpan={tableSize}>
        {footerContent}
      </th>
    </tr>
  </tfoot>
)
EntityListFooter.propTypes = {
  footerContent: PropTypes.node.isRequired,
  tableSize: PropTypes.number.isRequired,
}

export default EntityListFooter
