import externalEmployersType from './action-types'
import {
  CreateExternalEmployerActionPayload,
  DeleteExternalEmployerActionPayload,
  FetchExternalEmployerActionPayload,
  UpdateExternalEmployerActionPayload,
} from './types'

export type ExternalEmployerAction =
  | ReturnType<typeof fetchExternalEmployer>
  | ReturnType<typeof createExternalEmployer>
  | ReturnType<typeof updateExternalEmployer>
  | ReturnType<typeof deleteExternalEmployer>

export const fetchExternalEmployer = (payload: FetchExternalEmployerActionPayload) => ({
  type: externalEmployersType.ENTITY_FETCH_BEGIN,
  payload,
})
export const createExternalEmployer = (payload: CreateExternalEmployerActionPayload) => ({
  type: externalEmployersType.ENTITY_CREATE_BEGIN,
  payload,
})
export const updateExternalEmployer = (payload: UpdateExternalEmployerActionPayload) => ({
  type: externalEmployersType.ENTITY_UPDATE_BEGIN,
  payload,
})
export const deleteExternalEmployer = (payload: DeleteExternalEmployerActionPayload) => ({
  type: externalEmployersType.ENTITY_DELETE_BEGIN,
  payload,
})
