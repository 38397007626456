import { SubmissionErrors } from 'final-form'
import noop from 'lodash-es/noop'

export const getModalCanSubmit = (invalid: boolean, submitting: boolean, submitFailed: boolean) =>
  (!invalid && !submitting) || submitFailed

export const getValidator = (isRequired: boolean) =>
  isRequired ? (value: any) => (value ? undefined : 'Required') : noop

export const getValidationState = (meta: Record<string, any>) => {
  if (!meta.touched) return null
  if (meta.error) return 'error'
  if (meta.valid) return 'success'
  return null
}

export const getModalErrorText = (
  submitFailed: boolean,
  submitting: boolean,
  submitErrors: SubmissionErrors,
) => (submitFailed && !submitting && submitErrors?.form) || null
