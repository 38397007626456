// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import { apiRequest, handleCall } from 'syft-acp-core/api/call'

/**
 * Retrieves a list of employer ratings.
 *
 * @param params {Object}
 * @param params.employerID {Number} the employer ID
 */
export const listEmployerRatings = ({ options = {}, toCSV = false }) =>
  new Promise((resolve, reject) => {
    const { employerID, ...reqArgs } = options
    const req = apiRequest({
      path: `/admin/employers/${employerID}/ratings`,
      reqArgs,
      returnAll: toCSV,
    })
    handleCall(req, resolve, reject, true, options, null, toCSV, 'employer-ratings')
  })
