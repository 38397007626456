import { apiRequest, handleCall } from 'syft-acp-core/api/call'
import { FetchUserAction, PatchUserAction, BanUserAction, FetchUsersAction } from './actions'

/**
 * Retrieves a list of users.
 */
export const users = ({ options = {} }: Omit<FetchUsersAction, 'type'>) =>
  new Promise((resolve, reject) => {
    const req = apiRequest({
      path: '/users',
      reqArgs: options,
    })
    handleCall(req, resolve, reject, true, options)
  })

/**
 * Ban/unban a user.
 *
 * @param params {Object}
 * @param params.id {Number} User id
 * @param params.banned {Boolean} Ban or unban user
 * @param params.reason {String} Reason for ban
 */
export const banUser = ({ id, banned, reasonID }: Omit<BanUserAction, 'type'>) =>
  new Promise((resolve, reject) => {
    const req = apiRequest({ path: `/admin/users/${id}/ban`, reqArgs: { banned, ban_reason_id: reasonID }, method: 'PATCH' })
    handleCall(req, resolve, reject)
  })

/**
 * Patches a user timezone settings.
 *
 * @param params {Object}
 * @param params.id {Number} The user ID
 */
export const patchUser = ({ options: { id, timezone } }: Omit<PatchUserAction, 'type'>) =>
  new Promise((resolve, reject) => {
    const req = apiRequest({
      path: `/users/${id}`,
      reqArgs: { timezone },
      method: 'PATCH',
    })
    handleCall(req, resolve, reject, true)
  })

/**
 * Retrieves a user data from server.
 *
 * @param params {Object}
 * @param params.id {Number} The user ID
 */
export const fetchUser = ({ id }: Omit<FetchUserAction, 'type'>) =>
  new Promise((resolve, reject) => {
    const req = apiRequest({ path: `/users/${id}` })
    handleCall(req, resolve, reject, true)
  })

/**
 * Retrieves user ban reasons from server.
 */
export const fetchUserBanReasons = () =>
  new Promise((resolve, reject) => {
    const req = apiRequest({ path: '/admin/users/ban_reasons' })
    handleCall(req, resolve, reject, true)
  })
