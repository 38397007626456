// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import octicons from 'octicons'

import { withTrackingTriggerHoc } from 'syft-acp-util/withTrackingTriggerHoc'
import entityList, { ACTION_DOWNLOAD_CSV } from 'syft-acp-util/entityList'
import { formatFullName } from 'syft-acp-util/formatting'
import * as filterTypes from 'syft-acp-util/components/FilterForm/FilterForm'
import * as employerNetworksActions from 'syft-acp-core/actions/employer-networks'
import * as tableType from 'syft-acp-core/components/EntityList/'
import EmployerDetailListWrapper from '../EmployerDetailListWrapper'
import { trackingEvents } from './EmployerNetworkList.tracking'

const tableFormat = [
  { type: tableType.SELECTOR },
  { type: tableType.TXT, val: 'id', header: 'W.ID', numeric: true },
  {
    type: tableType.IMAGE,
    headerIcon: octicons.person,
    headerIconAlt: 'Picture',
    val: 'profile_picture',
    expl: 'Picture',
  },
  {
    type: tableType.TXT,
    classes: ['nowrap', 'main'],
    val: e => formatFullName(e),
    header: 'Name',
  },
  {
    type: tableType.TXT,
    val: e => `${e.network_shifts_count}/${e.total_shifts_count}`,
    header: 'Shifts count',
  },
  {
    type: tableType.TXT,
    val: e => `${parseInt(e.network_shifts_hours, 10)}/${parseInt(e.total_shifts_hours, 10)}`,
    header: 'Hours worked',
  },
]

const filterFormat = null

class EmployerNetworkList extends PureComponent {
  static propTypes = {
    actions: PropTypes.objectOf(PropTypes.func).isRequired,
    employerID: PropTypes.number.isRequired,
    triggerEvent: PropTypes.func.isRequired,
  }
  constructor() {
    super()
    this.state = {
      NetworkList: null,
    }
  }

  actionFormat = [
    {
      label: 'Download CSV',
      type: filterTypes.TYPE_BUTTON,
      action: ACTION_DOWNLOAD_CSV,
      confirmCallback: () => {
        this.props.triggerEvent(trackingEvents.DOWNLOAD_CSV_BUTTON.CLICKED)
      },
    },
  ]

  componentDidMount() {
    const { actions } = this.props
    const NetworkList = entityList(
      EmployerDetailListWrapper,
      tableFormat,
      filterFormat,
      this.actionFormat,
      'employerNetworks',
      {
        fetchEntities: actions.fetchEmployerNetworks,
      },
      {
        idKey: 'id',
        showResultCount: false,
        fullWidth: true,
      }
    )

    this.setState({ NetworkList })
  }

  render() {
    const { NetworkList } = this.state
    if (!NetworkList) return null

    return <NetworkList {...this.props} pagination query={{ employerID: this.props.employerID }} />
  }
}

export default connect(null, dispatch => ({
  actions: bindActionCreators(employerNetworksActions, dispatch),
}))(withTrackingTriggerHoc(EmployerNetworkList))
