// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import React from 'react'
import { TrackingProvider } from '@indeed/flex-tracking-context'

import PageView from 'syft-acp-core/components/PageView'
import ManagerDetail, { ManagerDetailEmployers } from 'syft-acp-core/entities/ManagerDetail'
import SubNavigationAdmin from './SubNavigation'

export const AdminManagersView = ({ match: { params } }) => (
  <TrackingProvider
    value={{
      context: {
        manager_id: Number(params.managerID),
      },
    }}
  >
    <SubNavigationAdmin />
    <PageView title={['Managers', `Viewing manager ${params.managerID}`]}>
      <ManagerDetail id={Number(params.managerID)} />
      <ManagerDetailEmployers query={{ manager_id: Number(params.managerID) }} />
    </PageView>
  </TrackingProvider>
)
