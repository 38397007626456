// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import * as types from './action-types'

export function fetchEmployerNetworks({ options }, props, toCSV = false) {
  return { type: types.EMPLOYER_NETWORKS_FETCH_BEGIN, options, toCSV }
}

export function deleteWorkerFromNetwork(employerID, workerID) {
  return { type: types.DELETE_WORKER_FROM_NETWORK_BEGIN, employerID, workerID }
}

export function fetchEmployerNetwork(employerID, workerID) {
  return { type: types.EMPLOYER_NETWORK_FETCH_BEGIN, employerID, workerID }
}
