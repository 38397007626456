import createEntityReducer from 'syft-acp-core/reducers/generators/entityReducer'

import * as types from './action-types'
import * as actions from './actions'
import { WorkerHolidayPayState, WorkerHolidayPayReducerType } from './types'

const setIsLoadingAccruals = (isLoadingAccruals: boolean) => (state: WorkerHolidayPayState) => ({
  ...state,
  isLoadingAccruals,
})

const setIsLoadingHistoricRequests = (isLoadingHistoricRequests: boolean) => (state: WorkerHolidayPayState) => ({
  ...state,
  isLoadingHistoricRequests,
})

const setIsLoadingWeeklyRequests = (isLoadingWeeklyRequests: boolean) => (state: WorkerHolidayPayState) => ({
  ...state,
  isLoadingWeeklyRequests,
})

const setIsValidatingHistoricRequest = (isValidatingHistoricRequest: boolean) => (state: WorkerHolidayPayState) => ({
  ...state,
  isValidatingHistoricRequest,
})

export const setAccruals = (
  state: WorkerHolidayPayState,
  action: actions.FetchWorkerHolidayPayAccrualsSucceededAction
) => ({
  ...state,
  [action.request.workerID]: {
    ...state[action.request.workerID],
    accruals: action.payload,
  },
  isLoadingAccruals: false,
})

export const setHistoricRequests = (
  state: WorkerHolidayPayState,
  action: actions.FetchWorkerHolidayPayHistoricRequestsSucceededAction
) => ({
  ...state,
  [action.request.workerID]: {
    ...state[action.request.workerID],
    historicRequests: action.payload,
  },
  isLoadingHistoricRequests: false,
})

export const addHistoricRequest = (
  state: WorkerHolidayPayState,
  action: actions.MakeWorkerHolidayPayHistoricRequestSucceededAction
) => {
  const historicRequests = (state[action.request.workerID]?.historicRequests || []).concat(action.payload)

  return {
    ...state,
    [action.request.workerID]: {
      ...state[action.request.workerID],
      historicRequests,
    },
    isLoadingHistoricRequests: false,
  }
}

export const setHistoricRequestValidation = (
  state: WorkerHolidayPayState,
  action: actions.ValidateWorkerHolidayPayHistoricRequestSucceededAction
) => ({
  ...state,
  [action.request.workerID]: {
    ...state[action.request.workerID],
    validation: action.payload,
  },
  isValidatingHistoricRequest: false,
})

export const resetValidation = (state: WorkerHolidayPayState, action: actions.ResetValidationAction) => ({
  ...state,
  [action.workerID]: {
    ...state[action.workerID],
    validation: undefined,
  },
  isValidatingHistoricRequest: false,
})

export const setWeeklyRequests = (
  state: WorkerHolidayPayState,
  action: actions.FetchWorkerHolidayPayWeeklyRequestsSucceededAction
) => ({
  ...state,
  [action.request.workerID]: {
    ...state[action.request.workerID],
    weeklyRequests: action.payload,
  },
  isLoadingWeeklyRequests: false,
})

export const addWeeklyRequest = (
  state: WorkerHolidayPayState,
  action: actions.MakeWorkerHolidayPayWeeklyRequestSucceededAction
) => {
  const weeklyRequests = (state[action.request.workerID]?.weeklyRequests || []).concat(action.payload)

  return {
    ...state,
    [action.request.workerID]: {
      ...state[action.request.workerID],
      weeklyRequests,
    },
    isLoadingWeeklyRequests: false,
  }
}

const initialState = {
  isLoadingAccruals: false,
  isLoadingHistoricRequests: false,
  isLoadingWeeklyRequests: false,
  isValidatingHistoricRequest: false,
}

const entityReducer = createEntityReducer(
  'workerHolidayPay',
  {
    // Fetch historic requests
    [types.WORKER_HOLIDAY_PAY_HISTORIC_REQUESTS_FETCH_BEGIN]: setIsLoadingHistoricRequests(true),
    [types.WORKER_HOLIDAY_PAY_HISTORIC_REQUESTS_FETCH_SUCCEEDED]: setHistoricRequests,
    [types.WORKER_HOLIDAY_PAY_HISTORIC_REQUESTS_FETCH_FAILED]: setIsLoadingHistoricRequests(false),
    // Make historic requests
    [types.WORKER_HOLIDAY_PAY_HISTORIC_REQUEST_MAKE_BEGIN]: setIsLoadingHistoricRequests(true),
    [types.WORKER_HOLIDAY_PAY_HISTORIC_REQUEST_MAKE_SUCCEEDED]: addHistoricRequest,
    [types.WORKER_HOLIDAY_PAY_HISTORIC_REQUEST_MAKE_FAILED]: setIsLoadingHistoricRequests(false),
    // Validate historic request
    [types.WORKER_HOLIDAY_PAY_HISTORIC_REQUEST_VALIDATE_BEGIN]: setIsValidatingHistoricRequest(true),
    [types.WORKER_HOLIDAY_PAY_HISTORIC_REQUEST_VALIDATE_SUCCEEDED]: setHistoricRequestValidation,
    [types.WORKER_HOLIDAY_PAY_HISTORIC_REQUEST_VALIDATE_FAILED]: setHistoricRequestValidation,
    [types.WORKER_HOLIDAY_PAY_HISTORIC_REQUEST_VALIDATE_RESET]: resetValidation,
    // Fetch weekly requests
    [types.WORKER_HOLIDAY_PAY_WEEKLY_REQUESTS_FETCH_BEGIN]: setIsLoadingWeeklyRequests(true),
    [types.WORKER_HOLIDAY_PAY_WEEKLY_REQUESTS_FETCH_SUCCEEDED]: setWeeklyRequests,
    [types.WORKER_HOLIDAY_PAY_WEEKLY_REQUESTS_FETCH_FAILED]: setIsLoadingWeeklyRequests(false),
    // Make weekly requests
    [types.WORKER_HOLIDAY_PAY_WEEKLY_REQUEST_MAKE_BEGIN]: setIsLoadingWeeklyRequests(true),
    [types.WORKER_HOLIDAY_PAY_WEEKLY_REQUEST_MAKE_SUCCEEDED]: addWeeklyRequest,
    [types.WORKER_HOLIDAY_PAY_WEEKLY_REQUEST_MAKE_FAILED]: setIsLoadingWeeklyRequests(false),
    // Fetch accruals
    [types.WORKER_HOLIDAY_PAY_ACCRUALS_FETCH_BEGIN]: setIsLoadingAccruals(true),
    [types.WORKER_HOLIDAY_PAY_ACCRUALS_FETCH_SUCCEEDED]: setAccruals,
    [types.WORKER_HOLIDAY_PAY_ACCRUALS_FETCH_FAILED]: setIsLoadingAccruals(false),
  },
  { initialState }
)

// @ts-ignore
export default entityReducer as WorkerHolidayPayReducerType
