import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { get } from 'lodash-es'

import { getModal$ } from 'syft-acp-core/store/modals/selectors'
import { hideModal } from 'syft-acp-core/store/modals/actions'
import Modal from './Modal'

class ConfirmationModal extends PureComponent {
  static propTypes = {
    item: PropTypes.shape({
      modalName: PropTypes.string,
      options: PropTypes.object,
      onClose: PropTypes.func,
      isShown: PropTypes.bool,
    }).isRequired,
    dispatch: PropTypes.func.isRequired,
  }

  constructor(props) {
    super()
    this.setDefaultHandlers(props)
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    this.setDefaultHandlers(newProps)
  }

  modalClose = () => {
    this.props.dispatch(hideModal(this.props.item.modalName))
  }

  setDefaultHandlers = props => {
    // Run callback handlers before closing and before confirming.
    this.closeHandler = props.item.onClose
      ? () => {
          props.item.onClose()
          this.modalClose()
        }
      : this.modalClose

    this.confirmHandler = get(props.item.options, 'onConfirm')
      ? () => {
          props.item.options.onConfirm()
          this.modalClose()
        }
      : this.modalClose
  }

  render() {
    const { options, isShown } = this.props.item
    return (
      <Modal
        header={options.modalHeader}
        cancelText={get(options, 'modalCancel')}
        isShown={isShown}
        onClose={this.closeHandler}
        onConfirm={get(options, 'onConfirm') && this.confirmHandler}
        confirmationText={options.modalConfirm}
      >
        <div>{options.question}</div>
      </Modal>
    )
  }
}

export default connect(state => ({
  item: getModal$(state.modals)('confirm'),
}))(ConfirmationModal)
