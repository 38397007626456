// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
import moment from 'moment'
// © Syft Online Limited
import queryStringLib from 'query-string'

// FIXME: Hack. See below.
const ALLOWED_NULL = Symbol('ALLOWED_NULL')

/**
 * Returns the provided arguments object as a JSON string to be used as request body.
 * Undefined values are filtered out.
 *
 * @param args Key/value pairs containing request data
 * @param allowNulls Moving to allow PATCH/POST with null values
 */
export const jsonBody = (rawArgs, allowNulls = false) => {
  // FIXME: Hack for working_hours_limit until '' is accepted as null for floats. See <CMS2-529>.
  // This turns '' into null, for only working_hours_limit.
  const args = { ...rawArgs }
  if (args.working_hours_limit === '') {
    args.working_hours_limit = ALLOWED_NULL
  }

  const postArgs = Object.keys(args)
    // Temporarily skip null values.
    .filter(key => args[key] !== undefined && (allowNulls || args[key] !== null))
    .reduce((acc, item) => {
      if (moment.isMoment(args[item])) {
        return {
          ...acc,
          [item]: args[item].toString(),
        }
      }
      return { ...acc, [item]: args[item] === ALLOWED_NULL ? null : args[item] }
    }, {})

  return JSON.stringify(postArgs)
}

/**
 * Returns an encoded HTTP query string for making fetch requests.
 * Undefined values are filtered out.
 *
 * @param args Key/value pairs containing request data
 */
export const queryString = args =>
  `?${queryStringLib.stringify(args, { arrayFormat: 'bracket', skipNull: true, strict: false })}`

/**
 * Parses content-disposition response header and extracts filename.
 * See: https://github.com/eligrey/FileSaver.js/wiki/Saving-a-remote-file#using-http-header
 * @param {null|string} disposition
 * @returns {null|string}
 */
export const getContentDispositionFileName = disposition => {
  if (!disposition || disposition.indexOf('inline') > -1) return null

  const utf8FilenameRegex = /filename\*=UTF-8''([\w%\-.]+)(?:; ?|$)/
  const asciiFilenameRegex = /filename=(["']?)(.*?[^\\])\1(?:; ?|$)/

  if (utf8FilenameRegex.test(disposition)) {
    return decodeURIComponent(utf8FilenameRegex.exec(disposition)[1])
  }

  const matches = asciiFilenameRegex.exec(disposition)

  if (matches != null && matches[2]) {
    return matches[2]
  }

  return null
}
