/* eslint-disable react/require-default-props */
// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { FormGroup, Radio } from 'react-bootstrap'

import './DataEnum.scss'

// If one of our enum options is given as a plain string, turn it into a key/value.
const normalizeOptions = options =>
  options.map(option => (typeof option === 'string' ? { value: option } : option))

/* eslint-disable react/no-unused-prop-types */
const enumPropTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  data: PropTypes.object,
  editable: PropTypes.bool,
  disabled: PropTypes.bool,
  vertical: PropTypes.bool,
  val: PropTypes.func,
  options: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.shape({
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        // This will display the item as enabled if it's null.
        acceptNull: PropTypes.bool,
        // If you want to specify a custom label for a given value
        label: PropTypes.string,
      }),
      PropTypes.string,
    ]),
  ),
  onChange: PropTypes.func,
  componentValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}
/* eslint-enable */

class Radios extends PureComponent {
  static propTypes = enumPropTypes

  static defaultProps = {
    options: [],
    value: '',
  }

  componentDidMount() {
    const { value, val, data, options, onChange } = this.props
    // The final value we check against could be calculated by a function.
    const calculatedValue = val ? val(value, data) : value

    options.forEach(({ defaultValue }) => {
      // If the current value is null, and we have a default value, set it.
      if (calculatedValue === null && defaultValue) {
        // I'm not sure if we can avoid this setTimeout() here.
        setTimeout(() => onChange(calculatedValue), 0)
      }
    })
  }

  render() {
    const { value, options, editable, onChange, val, data, disabled } = this.props
    // The final value we check against could be calculated by a function.
    const calculatedValue = val ? val(value, data) : value
    return (
      <FormGroup className="plain-text DataEnum">
        {options.map(({ value: optionValue, label, acceptNull = false }) => {
          // Item is checked if the value matches this option,
          const isChecked =
            calculatedValue === optionValue ||
            // or if it's null and we accept null as a valid value.
            (calculatedValue === null && acceptNull)
          if (this.props.vertical) {
            return (
              <div key={optionValue}>
                <Radio
                  inline
                  disabled={!editable || disabled}
                  checked={isChecked}
                  onChange={() => onChange(optionValue)}
                >
                  {label || optionValue}
                </Radio>
              </div>
            )
          }
          return (
            <Radio
              inline
              disabled={!editable || disabled}
              checked={isChecked}
              key={optionValue}
              onChange={() => onChange(optionValue)}
            >
              {label || optionValue}
            </Radio>
          )
        })}
      </FormGroup>
    )
  }
}

class DataEnum extends PureComponent {
  static propTypes = enumPropTypes

  normalizeProps = props => ({
    ...props,
    ...props.parameters,
    value: props.componentValue,
    options: normalizeOptions(props.options),
    defaultValue: props.defaultValue,
  })

  render() {
    return <Radios {...this.normalizeProps(this.props)} />
  }
}

export default DataEnum
