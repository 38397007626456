// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import { createReducer } from '@reduxjs/toolkit'
import { REHYDRATE } from 'redux-persist/constants'

import * as types from '../actions/action-types'
import usersTypes from 'syft-acp-core/store/users/action-types'

const initialState = {
  isCheckingLogin: false,
  isResettingPassword: false,
  hasResetPassword: false,
  isLoggedIn: false,
  oauthData: {},
  userData: {
    email: '',
    id: 0,
    country_code: undefined,
  },
  lastMessage: '',
  errorBody: {},
  // Platform ID is used for internal resourcing and indicates the unit this session belongs to.
  // This is a string value. '1' is the Syft platform. We default to 'null' so that
  // the login endpoint is queried without platform ID.
  platformID: null,
}

const rehydrateReset = {
  isCheckingLogin: false,
  isResettingPassword: false,
  hasResetPassword: false,
  errorBody: {},
  lastMessage: '',
}

const reducer = createReducer(initialState, builder =>
  builder.addDefaultCase((state = initialState, action) => {
    let authStoreData
    let expiration
    let now

    switch (action.type) {
      case types.REHYDRATION_COMPLETE:
        return {
          ...state,
          ...rehydrateReset,
        }
      case REHYDRATE:
        authStoreData = action.payload.auth
        if (!authStoreData) {
          return state
        }

        // Check for token expiration.
        expiration = authStoreData.oauthData.created_at + authStoreData.oauthData.expires_in
        now = new Date().getTime() / 1000

        if (expiration < now) {
          // If our token has expired, return the initial state to log out the user.
          // TODO: don't log the user out, but refresh the session instead.
          return initialState
        } else {
          return {
            ...state,
            ...authStoreData,
          }
        }
      case types.AUTH_LOG_OUT:
        return {
          ...state,
          platformID: null,
          isCheckingLogin: false,
          isLoggedIn: false,
          hasResetPassword: false,
          oauthData: {},
          lastMessage: '',
          errorBody: {},
          userData: {
            email: '',
          },
        }
      case types.AUTH_REFRESH_TOKEN_FORCE_UPDATE:
      case types.AUTH_REFRESH_TOKEN_SUCCEEDED:
      case types.AUTH_REFRESH_OLD_TOKEN_SUCCEEDED:
        // Refresh our auth token. There's no callback for a failed token refresh.
        // If there's no payload, we did not need to refresh the token.
        if (!action.payload) {
          return state
        }
        return {
          ...state,
          isLoggedIn: true,
          oauthData: action.payload.oauth,
          userData: action.payload.user,
        }
      case types.RESET_PASSWORD_BEGIN:
        return {
          ...state,
          isResettingPassword: true,
          lastMessage: '',
          errorBody: {},
          hasResetPassword: false,
        }
      case types.RESET_PASSWORD_SUCCEEDED:
      case types.RESET_PASSWORD_FAILED:
        return {
          ...state,
          isResettingPassword: false,
          lastMessage: action.payload.message,
          hasResetPassword: true,
        }
      case types.LOGIN_REDEEM_BEGIN:
      case types.LOGIN_SSO_BEGIN:
      case types.AUTH_REFRESH_TOKEN_BEGIN:
      case types.LOGIN_BEGIN:
        return {
          ...state,
          isCheckingLogin: true,
          hasResetPassword: false,
        }
      case types.LOGIN_SUCCEEDED:
        return {
          ...state,
          platformID: action.payload.user.platform.id,
          isCheckingLogin: false,
          isLoggedIn: true,
          oauthData: action.payload.oauth,
          userData: action.payload.user,
          lastMessage: '',
          errorBody: {},
          hasResetPassword: false,
        }
      case types.LOGIN_SSO_FAILED:
      case types.LOGIN_FAILED:
        return {
          ...state,
          platformID: null,
          isCheckingLogin: false,
          isLoggedIn: false,
          oauthData: {},
          userData: {
            email: '',
          },
          errorBody: action.payload.body,
          lastMessage: action.payload.message,
          hasResetPassword: false,
        }
      case usersTypes.USER_PATCH_SUCCEEDED:
        return {
          ...state,
          userData: {
            ...state.userData,
            ...action.payload,
          },
        }
      default:
        return state
    }
  }),
)

export default reducer
