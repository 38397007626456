// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import { takeLatest, call } from 'redux-saga/effects'
import * as types from './action-types'
import * as filters from 'syft-acp-core/store/filters/helpers'

export function* filterSetCall({ name, value, filterIndex, meta = {} }) {
  yield call(filters.updateLocation, { [name]: value, page: 1, filterIndex }, meta.filterOptions)
}
export function* filterResetCall({ filtersToKeep }) {
  yield call(filters.resetQueryfilters, filtersToKeep)
}

export function* pageSetCall({ page, pageKey, disableAutoScroll, meta = {} }) {
  yield call(filters.updateLocation, { [pageKey]: page }, meta.filterOptions)
  if (!disableAutoScroll) {
    window.scrollTo(0, 0)
  }
}

export default function* saga() {
  yield takeLatest(types.FILTER_SET_BEGIN, filterSetCall)
  yield takeLatest(types.FILTER_RESET_BEGIN, filterResetCall)
  yield takeLatest(types.PAGE_SET_BEGIN, pageSetCall)
}
