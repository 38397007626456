import { store } from 'syft-acp-core/store'
import { confirmBooking, confirmAllBookings } from 'syft-acp-core/api/resources/bookings'
import {
  CONFIRM_SHIFT_BOOKING_FAILED,
  CONFIRM_SHIFT_BOOKING_SUCCEEDED,
} from 'syft-acp-core/actions/action-types'
import { showConfirmModal } from 'syft-acp-core/store/modals/actions'
import {
  confirmBulkShiftBookingsBegin,
  confirmBulkShiftBookingsSucceeded,
  confirmBulkShiftBookingsFailed,
} from 'syft-acp-core/store/shift-bookings/actions'

import { ManuallyConfirmWorkerInput } from './helpers.types'
import { getNewOfferTypeSymbol, getNewOfferTypeTooltip } from './tableHelpers'

/**
 * Manually confirm a worker/s for a shift/s. This returns a promise which resolves if
 * the worker is confirmed, and rejects otherwise (including if the user presses 'cancel'
 * when asked if they really want to confirm this worker).
 */
export const manuallyConfirmWorker =
  ({ jobID, workerID, shiftID, payload }: ManuallyConfirmWorkerInput) =>
  () =>
    new Promise((resolve, reject) => {
      const action = () => {
        if (!!payload) {
          store.dispatch(confirmBulkShiftBookingsBegin())
          confirmAllBookings({ payload })
            .then(() => {
              // Call the confirmation notification.
              store.dispatch(confirmBulkShiftBookingsSucceeded(payload))
              resolve(true)
            })
            .catch(() => {
              // Call the error notification.
              store.dispatch(confirmBulkShiftBookingsFailed())
              reject()
            })
        } else {
          // Run the confirm booking API call.
          confirmBooking({ payload: { job_id: jobID, worker_id: workerID, shift_ids: [shiftID] } })
            .then(() => {
              // Call the confirmation notification.
              store.dispatch({ type: CONFIRM_SHIFT_BOOKING_SUCCEEDED })
              resolve(true)
            })
            .catch(() => {
              // Call the error notification.
              store.dispatch({ type: CONFIRM_SHIFT_BOOKING_FAILED })
              reject()
            })
        }
      }

      store.dispatch(
        showConfirmModal(
          {
            question: !!payload
              ? 'Manually confirm these workers for their shifts?'
              : 'Manually confirm this worker for their shift?',
            onConfirm: action,
          },
          reject,
        ),
      )

      return true
    })

export const getNewOfferType = (
  offerToFlex?: string | null,
  offerToInternal?: string | null,
  offerToAgency?: string | null,
  tooltip = false,
) => {
  if (!tooltip) {
    return getNewOfferTypeSymbol(offerToFlex, offerToInternal, offerToAgency)
  }
  return getNewOfferTypeTooltip(offerToFlex, offerToInternal, offerToAgency)
}
