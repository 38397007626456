// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import { difference } from 'lodash-es'
import entityReducer from './generators/entityReducer'
import * as types from '../actions/action-types'
import * as entityTypes from './generators/entities'
import { entitySetName } from './generators/utils'

const setLoading = state => ({
  ...state,
  isLoadingData: true,
})

const setDone = state => ({
  ...state,
  isLoadingData: false,
})

export default entityReducer(
  'employerBlocklists',
  {
    [entityTypes.ENTITIES_FETCH_BEGIN]: types.EMPLOYER_BLOCKLIST_FETCH_BEGIN,
    [entityTypes.ENTITIES_FETCH_SUCCEEDED]: types.EMPLOYER_BLOCKLIST_FETCH_SUCCEEDED,
    [entityTypes.ENTITIES_FETCH_FAILED]: types.EMPLOYER_BLOCKLIST_FETCH_FAILED,
    [types.EMPLOYER_BLOCKLIST_ADD_BEGIN]: setLoading,
    [types.EMPLOYER_BLOCKLIST_REMOVE_BEGIN]: setLoading,
    // Note: when adding a worker succeeded, the saga will re-fetch the full list.
    [types.EMPLOYER_BLOCKLIST_ADD_SUCCEEDED]: setDone,
    [types.EMPLOYER_BLOCKLIST_REMOVE_SUCCEEDED]: (state, action) => {
      const { employerID } = action.request
      const unblocked = action.payload.unblocked_worker_ids
      const setName = entitySetName({ employerID })
      const set = state.entitySets[setName]

      // Remove the unblocked IDs from the blocklist ID array.
      const newIDs = difference(set.ids, unblocked)

      // Save new state with the unblocked workers removed.
      return setDone({
        ...state,
        entitySets: {
          ...state.entitySets,
          [setName]: {
            ...set,
            ids: newIDs,
          },
        },
      })
    },
    [types.EMPLOYER_BLOCKLIST_ADD_FAILED]: setDone,
    [types.EMPLOYER_BLOCKLIST_REMOVE_FAILED]: setDone,
  },
  {
    idKey: 'worker.id',
    localEntityMap: true,
  }
)
