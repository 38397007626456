import React, { useState, useCallback } from 'react'
import CSVReader, { CSVReaderProps } from 'react-csv-reader'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { useTrackingTrigger } from '@indeed/flex-tracking-context'

import { hideModal } from 'syft-acp-core/store/modals/actions'
import { getModal$ } from 'syft-acp-core/store/modals/selectors'
import { Modal } from 'syft-acp-core/components/Modal'
import ModalTable from 'syft-acp-core/components/ModalTable'
import { uploadTransferredWorkerCsv } from '../../actions/transferred-workers'
import { FieldType, Props, CsvType } from './TransferredWorkerCSVModal.types'
import { trackingEvents } from 'syft-acp-core/entities2/TransferredWorkersList/TransferredWorkersList.tracking'

const requiredFields = [
  'email',
  'first_name',
  'last_name',
  'date_of_birth',
  'telephone_number',
  'Erecruit_ID',
  'external_branch',
]

export const storeConnector = connect(
  state => ({
    item: getModal$(state.modals)(modalName),
    workerCsvImport: state.workerCsvImport,
  }),
  dispatch => ({
    actions: bindActionCreators({ uploadTransferredWorkerCsv, hideModal }, dispatch),
  }),
)

export const validateField = (value: string, columnName: string) =>
  requiredFields.includes(columnName) && value.length < 1

export const rowHasError = (arr: FieldType[]) => arr.find(({ error }: FieldType) => error)

const getColumnHeaders = (data: CsvType[]) => Object.keys(data[0])

const modalName = 'transferredWorkerCSVModal'

export const TransferredWorkerCSVModal = ({ item, actions, workerCsvImport }: Props) => {
  const triggerEvent = useTrackingTrigger()
  const [data, setData] = useState<CsvType[]>([])
  const [csvFile, setCsvFile] = useState<File | undefined>()
  const [hasSubmitted, setSubmitted] = useState(false)
  const [errorText, setErrorText] = useState('')

  const closeModal = useCallback(() => {
    actions.hideModal(modalName)
  }, [actions])

  const onConfirmHandler = useCallback(() => {
    triggerEvent(trackingEvents.UPLOAD_CSV_BUTTON.CLICKED)
    actions.uploadTransferredWorkerCsv(csvFile)
    setSubmitted(true)
  }, [actions, triggerEvent, csvFile, setSubmitted])

  const tranformCSV = useCallback(
    (value: string, columnName: string) => {
      const hasError = validateField(value.trim(), columnName.trim())
      if (hasError) {
        setErrorText(`Error: the following fields are required - ${requiredFields.join(', ')}`)
      }
      return {
        value,
        error: hasError,
      }
    },
    [setErrorText],
  )

  const onFileLoaded: CSVReaderProps['onFileLoaded'] = useCallback(
    (rows, fileInfo, originalFile) => {
      const headers = getColumnHeaders(rows)

      if (!requiredFields.every(field => headers.includes(field))) {
        setErrorText(`Error: the following fields are required - ${requiredFields.join(', ')}`)
      }
      setSubmitted(false)
      setData(rows)
      setCsvFile(originalFile)
    },
    [setData, setCsvFile],
  )

  const onError = useCallback(
    error => {
      setErrorText(`Error: an error occured while processing the CSV: ${error?.message}`)
    },
    [setErrorText],
  )

  const onBefore = useCallback(() => {
    setErrorText('')
  }, [setErrorText])

  return (
    <Modal
      header="Upload Transferred Worker CSV"
      isShown={item?.isShown}
      onClose={closeModal}
      onConfirm={onConfirmHandler}
      confirmationText="Upload"
      className="wide"
      errorText={errorText || workerCsvImport.lastMessage}
      canSubmit={data.length > 0 && !!errorText === false}
      isLoading={workerCsvImport.isLoadingData}
      // @ts-expect-error
      footerText={hasSubmitted && workerCsvImport.hasData ? workerCsvImport.message : ''}
    >
      <div className="table-wrapper">
        <p>Expected columns:</p>
        <p>
          Erecruit_ID, first_name, last_name, email, bio, date_of_birth, telephone_number, minimum_rate,
          availability, Line_1, Line_2, State, city, post_code, external_branch
        </p>
        <CSVReader
          label="Select transferred worker CSV"
          onFileLoaded={onFileLoaded}
          onError={onError}
          parserOptions={{
            header: true,
            skipEmptyLines: true,
            transform: tranformCSV,
            beforeFirstChunk: onBefore,
          }}
        />
        {data.length > 0 && (
          <div>
            <ModalTable>
              <tr>
                {getColumnHeaders(data).map((title: string) => {
                  return <th>{title}</th>
                })}
              </tr>
              {data.map((row, rowIndex) => {
                const rowArr = Object.values(row)
                return (
                  <tr className={rowHasError(rowArr) ? 'error' : ''} key={`row-${rowIndex}`}>
                    {rowArr.map((field: FieldType, n: number) => {
                      return <td key={`item-${n}`}>{field.value}</td>
                    })}
                  </tr>
                )
              })}
            </ModalTable>
          </div>
        )}
      </div>
    </Modal>
  )
}

export default storeConnector(TransferredWorkerCSVModal)
