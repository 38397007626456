import React, { useCallback, useMemo } from 'react'
import { Field } from 'react-final-form'
import { HelpBlock, FormControl, InputGroup } from 'react-bootstrap'
import { parseInt as parseIntSafe } from 'lodash-es'
import classnames from 'classnames'
import EntityListInputWrapper from 'syft-acp-core/components/EntityList/EntityListInputWrapper'

import { getErrorFromMeta } from '../utils'
import { FieldProps, Props } from './FormNumberInput.types'

export const NumberInput = ({ input, meta, leftAddon, rightAddon, ...rest }: Props) => {
  const parseNormalize = useCallback(value => {
    if (!value) return 0
    const parsed = parseIntSafe(value)

    if (!isNaN(parsed)) {
      return parsed
    }

    return 0
  }, [])

  const formattedValue = useMemo(() => parseNormalize(input.value), [parseNormalize, input.value])
  const onChange = useCallback(
    e => {
      const value = parseNormalize(e.target.value)

      return input.onChange(value)
    },
    [input, parseNormalize]
  )

  const showError = getErrorFromMeta(meta)

  return (
    <EntityListInputWrapper className={classnames({ 'has-error': showError })}>
      <InputGroup>
        {leftAddon ? leftAddon : null}
        <FormControl {...input} type="number" {...rest} value={formattedValue} onChange={onChange} />
        {rightAddon ? rightAddon : null}
        {showError && <HelpBlock>{meta.error}</HelpBlock>}
      </InputGroup>
    </EntityListInputWrapper>
  )
}

const FormNumberInput = (props: FieldProps) => <Field {...props} component={NumberInput} />

export default FormNumberInput
