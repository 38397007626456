import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as adhocShiftApprovalActions from 'syft-acp-core/store/adhocShiftBookings/actions'
import { AdhocShiftsActionControlsProps as Props } from './AdhocShiftActionControls.types'
import { useTrackingTrigger } from '@indeed/flex-tracking-context'
import {
  Button,
  ButtonLink,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTitle,
  PopoverTrigger,
} from '@indeed/ifl-components'
import React from 'react'
import { trackingEvents } from 'syft-acp-core/entities2/AdhocShifts/AdhocShiftActions.tracking'

export const storeConnector = connect(
  ({ adhocShiftsApproval: { isSavingData, isLoadingData } }) => ({
    isSavingData,
    isLoadingData,
  }),
  dispatch => ({
    actions: bindActionCreators(adhocShiftApprovalActions, dispatch),
  }),
)

const AdhocShiftDisclaimer = ({ shiftBookingId, data }: Partial<Props>) => {
  if (data?.success !== 'no_action') {
    return (
      <div className="acp-page-text" style={{ margin: 30, fontSize: 'medium' }}>
        Shift booking ID: #{shiftBookingId} is either not an adhoc shift or has already been actioned
      </div>
    )
  } else {
    return (
      <div className="acp-page-text" style={{ margin: 30, fontSize: 'medium' }}>
        Do you want to approve or reject Ad-hoc shift booking ID #{shiftBookingId}?
        <br />
        NOTE: This action is irreversible!
      </div>
    )
  }
}

const AdhocShiftActionControls = ({ data, actions, shiftBookingId }: Props) => {
  const triggerEvent = useTrackingTrigger()
  const disabledControls = data.success !== 'no_action'

  const onClickApproveFn = () => {
    triggerEvent(trackingEvents.APPROVE_BUTTON.CLICKED, { adhoc_shift_booking_id: shiftBookingId })
    actions.approveAdhocShift(shiftBookingId)
  }

  const onClickRejectFn = () => {
    triggerEvent(trackingEvents.REJECT_BUTTON.CLICKED, { adhoc_shift_booking_id: shiftBookingId })
    actions.rejectAdhocShift(shiftBookingId)
  }

  return (
    <>
      <AdhocShiftDisclaimer shiftBookingId={shiftBookingId} data={data} />
      <Popover>
        <Button
          as={PopoverTrigger}
          disabled={disabledControls}
          style={{ marginRight: 8 }}
          className={'success btn Button'}
        >
          Approve
        </Button>
        <PopoverContent>
          <PopoverTitle>ATTENTION</PopoverTitle>
          <PopoverBody sx={{ paddingBottom: '5px' }}>
            Are you sure you want to Approve this Ad-hoc shift booking?
          </PopoverBody>
          <Button onClick={onClickApproveFn}>Agree and Continue</Button>
        </PopoverContent>
      </Popover>

      <Popover>
        <Button
          as={PopoverTrigger}
          disabled={disabledControls}
          style={{ marginRight: 8 }}
          className={'danger btn Button'}
        >
          Reject
        </Button>
        <PopoverContent>
          <PopoverTitle>ATTENTION</PopoverTitle>
          <PopoverBody sx={{ paddingBottom: '5px' }}>
            Are you sure you want to Reject this Ad-hoc shift booking?
          </PopoverBody>
          <Button onClick={onClickRejectFn}>Agree and Continue</Button>
        </PopoverContent>
      </Popover>
      <ButtonLink href="/" style={{ background: '#fff', color: '#2a2b2e' }}>
        Cancel
      </ButtonLink>
    </>
  )
}

export default storeConnector(AdhocShiftActionControls)
