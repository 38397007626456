import React, { useEffect, useLayoutEffect } from 'react'
import { datadogRum } from '@datadog/browser-rum-slim'
// eslint-disable-next-line no-restricted-imports
import { Route as DomRoute, RouteProps as DomRouteProps, useLocation, useRouteMatch } from 'react-router-dom'
import config from 'syft-acp-core/config'
import { addGlobalContextTag } from '../tracking/useGlobalTags'
import { useAuthRequired } from 'syft-acp-core/lib/auth'
import { Spinner } from '@indeed/ifl-components'
import { usePreviousValue } from './hooks'

type AccountablePartyIds =
  | 'sts-flexer-expansion'
  | 'sts-wfm-saas'
  | 'sts-wfm-platform'
  | 'sts-fulfilment-support'
  | 'sts-time'
  | 'sts-pay'
  | 'indeed-flex' // default, use for fallback if accountable domain is not known

export type RouteMetadata = {
  accountablePartyId: AccountablePartyIds
}

type RoutePersistentProps = DomRouteProps & {
  authRequired?: boolean
}

export type RouteProps =
  | ({
      children: DomRouteProps['children']
      metadata?: null
    } & RoutePersistentProps)
  | ({
      children?: undefined
      metadata: RouteMetadata
    } & RoutePersistentProps)
  | ({
      children?: undefined
      metadata?: null
    } & RoutePersistentProps)

export const Route = ({ metadata, authRequired, children, ...props }: RouteProps) => {
  // if accountablePartyId is not specified on the route props, default to 'indeed-flex'
  const accountablePartyId = metadata?.accountablePartyId || 'indeed-flex'
  const location = useLocation()
  const matches = useRouteMatch(props)
  const previousLocationPathname = usePreviousValue(location.pathname)
  const isRedirectingToAuth = useAuthRequired(authRequired)
  const shouldTrackPageView = matches?.path !== '/' && previousLocationPathname !== location.pathname

  useEffect(() => {
    // throw error for non-prod env only if accountablePartyId is not set via props. indeed-flex is default for prod
    if (!children && !metadata?.accountablePartyId && config.isDeployEnvTestOrDev()) {
      throw new TypeError('Metadata with accountablePartyId is required on Route component')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useLayoutEffect(() => {
    if (!children && matches?.isExact && shouldTrackPageView) {
      const viewName = matches?.path || 'Error404'

      datadogRum.startView(viewName)

      // start new DD view with an accountable party id applied after actual route change
      addGlobalContextTag('accountable_party_id', accountablePartyId)
    }
  }, [accountablePartyId, children, matches?.isExact, matches?.path, location.pathname, shouldTrackPageView])

  if (isRedirectingToAuth) {
    return <Spinner sx={{ position: 'absolute', left: '50%', top: '50%' }} title="Loading page" />
  }

  return <DomRoute {...props}>{children}</DomRoute>
}
