// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { isFunction } from 'lodash-es'

import { ButtonLink } from 'syft-acp-atoms/Button'
import './DataLink.css'

class DataLink extends PureComponent {
  static propTypes = {
    value: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
    parameters: PropTypes.object.isRequired,
    data: PropTypes.object.isRequired,
  }

  static defaultProps = {
    disabled: false,
  }

  render() {
    const { value, parameters, disabled, data } = this.props
    const target = isFunction(parameters.to) ? parameters.to(data) : parameters.to
    return (
      <ButtonLink
        className="DataLink"
        disabled={disabled || !value}
        to={target}
        target={parameters.target}
        external={parameters.external}
      >
        {value || 'n/a'}
      </ButtonLink>
    )
  }
}

export default DataLink
