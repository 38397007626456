import { entitySetName } from 'syft-acp-core/reducers/generators/utils'
import { entityList$, isLoadingData$, pagination$ } from 'syft-acp-core/reducers/generators/entities'

import deepEqualSelectorFactory from '../deepEqualSelectorFactory'
import { arrayGetPage, arrayPagination, createQuery, toggleQueryPage } from '../entityUtils'
import { getPageNumber } from 'syft-acp-core/store/filters/helpers'
import { EntityListConnectorFactoryOptions, OwnProps, StateProps } from './types'
import { DefaultRootState } from 'react-redux'

export const createEntityStoreSelector = <TEntity>({
  entityList,
  entityStore,
  noSets,
  localPagination,
  localPaginationSize,
  isLoadingData,
  staticArgs,
  pageKey,
}: EntityListConnectorFactoryOptions<TEntity> & { pageKey?: string }) => {
  const createDeepEqualSelector = deepEqualSelectorFactory()
  return createDeepEqualSelector(
    [
      (state: DefaultRootState) => state.routing,
      state => state.checkboxes,
      state => state[entityStore] as StateProps<TEntity>,
      (
        _state: DefaultRootState,
        {
          entityList: propsEntityList,
          query: propsQuery,
          disableRoutingQuery,
          isLoadingData: propsIsLoadingData,
        }: OwnProps<TEntity>,
      ) => ({ propsEntityList, propsQuery, disableRoutingQuery, propsIsLoadingData }),
    ],
    (
      routing,
      checkboxes,
      entityStoreState,
      { propsEntityList, propsQuery, disableRoutingQuery, propsIsLoadingData },
    ) => {
      // Retrieve our entity list page and filter state from the router.
      const routingQuery = routing.locationBeforeTransitions.query
      const routingPage = routingQuery.page ? { page: routingQuery.page } : {}
      const enabledRoutingQuery = disableRoutingQuery ? routingPage : routingQuery
      const query = createQuery({ queryFromProps: propsQuery, enabledRoutingQuery, pageKey, staticArgs })
      const queryString = entitySetName(query)
      const entityListFromOptions: TEntity[] =
        entityList ||
        (noSets
          ? ((entityStoreState?.entityMap ?? {}) as TEntity[])
          : entityList$(entityStoreState, toggleQueryPage(query, localPagination)))
      // By default, we connect to the Redux entity list state.
      // If needed, a list can be passed, in which case we do not connect
      // and the caller is responsible for providing updates.
      const entityListData = propsEntityList ?? entityListFromOptions

      const pagination = localPagination
        ? arrayPagination(entityListData, getPageNumber(), localPaginationSize ?? undefined)
        : pagination$(entityStoreState, query)

      const isLoadingStateFromProms = propsIsLoadingData ?? isLoadingData
      const isLoadingDataState = isLoadingStateFromProms ?? isLoadingData$(entityStoreState, query)
      const connectedEntityList = localPagination ? arrayGetPage(entityListData, pagination) : entityListData
      return {
        entityList: connectedEntityList,
        lastMessage: entityStoreState?.lastMessage,
        isLoadingData: isLoadingDataState,
        isSavingData: entityStoreState?.isSavingData,
        pagination,
        selectedItems: (checkboxes?.items[entityStore as string] ?? []) as TEntity[],
        // for ACP.EntityList use
        data: {
          data: connectedEntityList,
          meta: pagination,
        },
        queryString,
        pageKey,
        query,
      }
    },
  )
}
