import React from 'react'
import classNames from 'classnames'

import { ButtonProps as Props } from './Button.types'
import S from './Button.module.scss'

const Button: React.FC<Props> = ({
  className,
  onClick,
  children,
  disabled = false,
  confirm = false,
  primary = false,
  small = false,
}) => (
  <button
    className={classNames([
      S['button'],
      confirm && S['button--confirm'],
      primary && S['button--primary'],
      small && S['button--small'],
      className,
    ])}
    disabled={disabled}
    onClick={() => {
      onClick()
    }}
  >
    {children}
  </button>
)

export default Button
