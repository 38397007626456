import { put, takeLatest } from 'redux-saga/effects'

import { takeLatestApiCall } from 'syft-acp-core/sagas/helpers'
import { notificationCall } from 'syft-acp-core/sagas/calls'

import { users, banUser, patchUser, fetchUser, fetchUserBanReasons } from './api'
import { fetchWorker } from '../workers/actions'

import types from './action-types'

function* yieldFetchWorker(action: any) {
  if (action.request.banned) yield put(fetchWorker(action.request.workerID))
}

export default function* saga() {
  yield takeLatestApiCall(types.ENTITIES_FETCH_BEGIN, users)
  yield takeLatestApiCall(types.USER_FETCH_BEGIN, fetchUser)
  yield takeLatestApiCall(types.USER_BAN_BEGIN, banUser)
  yield takeLatestApiCall(types.USER_BAN_REASONS_FETCH_BEGIN, fetchUserBanReasons)
  yield takeLatestApiCall(types.USER_PATCH_BEGIN, patchUser)
  yield takeLatest(types.USER_BAN_SUCCEEDED, notificationCall)
  yield takeLatest(types.USER_BAN_SUCCEEDED, yieldFetchWorker)
}
