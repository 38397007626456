import { get } from 'lodash-es'
import { apiRequest, handleCall } from 'syft-acp-core/api/call'

export const fetchDocuments = ({ workerID }) =>
  new Promise((resolve, reject) => {
    const req = apiRequest({ path: `/workers/${workerID}/documents` })
    handleCall(req, resolve, reject, true, { workerID })
  })

export const fetchDocument = ({ workerID, documentID }) =>
  new Promise((resolve, reject) => {
    const req = apiRequest({ path: `/workers/${workerID}/documents/${documentID}` })
    handleCall(req, resolve, reject, true, { workerID })
  })

export const fetchDocumentTypes = () =>
  new Promise((resolve, reject) => {
    const req = apiRequest({ path: '/workers/document_types' })
    handleCall(req, resolve, reject, true, {})
  })

/**
 * Either posts a new document or modifies an existing one.
 */
export const saveDocument = ({ workerID, id, data }) =>
  new Promise((resolve, reject) => {
    const attachments = get(data, 'attachments', [])
    const suffix = get(data, 'suffix', '')
    const reqArgs = {
      title: `Document: worker ${workerID}, type: ${data.type}`,
      type: `${data.type}${suffix}`,
      number: data.docNumber,
      certificate_number: data.certificateNumber,
      expiry_date: data.expirationDate,
      ...(get(data, 'issueDate') ? { issue_date: data.issueDate } : {}),
      attachments,
    }
    // Update existing item if we have an ID already.
    if (id) {
      const req = apiRequest({ path: `/workers/${workerID}/documents/${id}`, reqArgs, method: 'PATCH' })
      handleCall(req, resolve, reject, true, { workerID })
    } else {
      const req = apiRequest({ path: `/workers/${workerID}/documents`, reqArgs, method: 'POST' })
      handleCall(req, resolve, reject, true, { workerID })
    }
  })

/**
 * Deletes a worker's existing document.
 */
export const deleteDocument = ({ workerID, id }) =>
  new Promise((resolve, reject) => {
    const req = apiRequest({ path: `/workers/${workerID}/documents/${id}`, method: 'DELETE' })
    handleCall(req, resolve, reject, true, { workerID })
  })
