import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import FilterFreeSelect from 'syft-acp-util/components/FilterForm/FilterFreeSelect'
import { noop } from 'lodash-es'

const DataFreeDropdown = ({
  name,
  header,
  disabled,
  value,
  editable,
  options,
  actions,
  showDisabled,
  parameters = {},
}) =>
  editable || showDisabled ? (
    <FilterFreeSelect
      name={name}
      value={value}
      options={options}
      header={header}
      actions={actions}
      type="id"
      disabled={(!editable && showDisabled) || disabled}
      allowAny={parameters.allowAny}
      anyOption={parameters.anyOption}
      ariaLabel={parameters.ariaLabel}
      convertIds={parameters.convertIds}
      categorized={parameters.categorized}
    />
  ) : (
    <div className="plain-text">{value}</div>
  )

DataFreeDropdown.propTypes = {
  name: PropTypes.string,
  header: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  editable: PropTypes.bool,
  disabled: PropTypes.bool,
  actions: PropTypes.object,
  options: PropTypes.array.isRequired,
  showDisabled: PropTypes.bool,
  parameters: PropTypes.object,
  onChange: PropTypes.func,
  ariaLabel: PropTypes.string,
}

DataFreeDropdown.defaultProps = {
  value: null,
  editable: false,
  showDisabled: false,
  name: '',
  header: null,
  parameters: {},
  disabled: false,
  onChange: noop,
  actions: {},
}

export default connect((state, { onChange }) => ({
  actions: {
    setFilter: (name, id, value) => onChange(value, id, name),
  },
}))(DataFreeDropdown)
