import * as types from '../action-types'

export function fetchAdminShiftListing(listingID: number, shiftID: number) {
  return { type: types.ADMIN_LISTING_SHIFT_FETCH_BEGIN, listingID, shiftID }
}
type FetchAdminShiftListingAction = ReturnType<typeof fetchAdminShiftListing>

export function fetchAdminShiftListingClear() {
  return { type: types.ADMIN_LISTING_SHIFT_CLEAR }
}
type FetchAdminShiftListingClearAction = ReturnType<typeof fetchAdminShiftListingClear>

export const editListingShift = (listingID: number, payload: Record<string, any>) => ({
  type: types.LISTING_EDIT_SHIFT_BEGIN,
  listingID,
  payload,
})
type EditListingShiftAction = ReturnType<typeof editListingShift>

export const editListingShiftStopOffers = (
  listingID: number,
  shiftID: number,
  stopOffersForWorkerType: string[],
) => {
  return { type: types.LISTING_EDIT_SHIFT_STOP_OFFERS, listingID, shiftID, stopOffersForWorkerType }
}
type EditListingShiftStopOffersAction = ReturnType<typeof editListingShiftStopOffers>

export type ListingShiftsAction =
  | FetchAdminShiftListingAction
  | FetchAdminShiftListingClearAction
  | EditListingShiftAction
  | EditListingShiftStopOffersAction
