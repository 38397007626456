import { Chip } from '@indeed/ifl-components'
import React from 'react'

type DataReasonsArrayItemProps = {
  index: number
  onDelete: (item: number) => void
  title?: string
}

const DataReasonsArrayItem = ({ index, onDelete, title }: DataReasonsArrayItemProps) => (
  <Chip key={`chip-${title}`} label={title} onRemove={() => onDelete(index)} type="removable" />
)

export default DataReasonsArrayItem
