import { WorkerReferralBonusSources, ReferralRoles } from 'syft-acp-core/store/workerReferralBonuses/types'

export const getReferralRole = (referralRole: ReferralRoles) =>
  ({
    claimant: 'Claimant',
    referrer: 'Referrer',
  }[referralRole])

export const getSource = (source: WorkerReferralBonusSources) =>
  ({
    missing_pay: 'Missing Pay',
    shift: 'Shift',
  }[source])
