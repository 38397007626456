import { useCallback, useMemo } from 'react'
import * as React from 'react'
import classnames from 'classnames'
import { HelpBlock, FormControl } from 'react-bootstrap'
import { Field } from 'react-final-form'
import moment from 'moment'

import EntityListInputWrapper from 'syft-acp-core/components/EntityList/EntityListInputWrapper'
import { addTimezone, formatISO8601 } from 'syft-acp-util/formatting'

import { getErrorFromMeta } from '../utils'

export const TimeInput = ({ input, meta, timeZone, ...rest }) => {
  const formatTimeInput = useCallback(
    value => {
      if (!value) return ''
      const parsedDate = addTimezone(moment(value), timeZone)

      if (parsedDate.isValid()) {
        return parsedDate.format('HH:mm')
      }

      return ''
    },
    [timeZone]
  )
  const parseTimeInput = useCallback(
    value => {
      const timeWithTimezone = addTimezone(moment(value, ['hh:mm a', 'HH:mm']), timeZone)

      if (timeWithTimezone.isValid()) {
        return formatISO8601(timeWithTimezone)
      }

      return null
    },
    [timeZone]
  )
  const formattedValue = useMemo(() => formatTimeInput(input.value), [formatTimeInput, input.value])
  const onChange = useCallback(
    e => {
      const value = parseTimeInput(e.target.value)

      return input.onChange(value)
    },
    [input, parseTimeInput]
  )

  const showError = getErrorFromMeta(meta)

  return (
    <EntityListInputWrapper className={classnames({ 'has-error': showError })}>
      <FormControl {...input} type="time" {...rest} value={formattedValue} onChange={onChange} />
      {showError && <HelpBlock>{meta.error}</HelpBlock>}
    </EntityListInputWrapper>
  )
}

const FormTimeInput = props => <Field {...props} component={TimeInput} />

export default FormTimeInput
