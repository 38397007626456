import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { history } from 'syft-acp-core/history'
import { useTrackingTrigger } from '@indeed/flex-tracking-context'

import LoadingIndicator from 'syft-acp-util/components/LoadingIndicator'
import { Button, ButtonGroup } from 'syft-acp-atoms/Button'
import RecordControls from 'syft-acp-core/components/RecordSection/RecordControls'
import { trackingEvents } from './AgencyView.tracking'

export const AgencyViewControls = ({ isSavingData, isLoadingData, isPristine, data, saveAction }) => {
  const triggerEvent = useTrackingTrigger()
  const backAction = () => {
    history.push('/agencies/list')
  }
  const handleSaveAction = useCallback(() => {
    const { employers, ...rest } = data
    const platformIds = data.employers.map(employer => employer.platform_id)
    saveAction({
      platform_ids: platformIds,
      ...rest,
    })
    triggerEvent(trackingEvents.SAVE_BUTTON.CLICKED)
  }, [data, saveAction, triggerEvent])

  return (
    <RecordControls>
      <div>
        <ButtonGroup>
          <Button onClick={backAction}>Back to list</Button>
        </ButtonGroup>
      </div>
      <div className="right">
        <LoadingIndicator loadingState={isSavingData || isLoadingData} />{' '}
        <Button kind="success" onClick={handleSaveAction} disabled={isSavingData || isPristine}>
          Save
        </Button>
      </div>
    </RecordControls>
  )
}

AgencyViewControls.propTypes = {
  isSavingData: PropTypes.bool.isRequired,
  isLoadingData: PropTypes.bool.isRequired,
  isPristine: PropTypes.bool.isRequired,
  data: PropTypes.object.isRequired,
  saveAction: PropTypes.func.isRequired,
}

export default AgencyViewControls
