import { ALLOWED_EMAIL_DOMAINS } from './constants'

export const validateEmail = (email: string) => {
  return String(email)
    .toLowerCase()
    .match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/)
}

export const validateIndeedflexOrSyftapp = (email: string) => {
  const emailLowerCase = String(email).toLowerCase()
  return ALLOWED_EMAIL_DOMAINS.map(domain => emailLowerCase.includes(domain)).includes(true)
}

export const validateEmailAndDomain = (email: string) => validateEmail(email) && validateIndeedflexOrSyftapp(email)
