import React, { useEffect } from 'react'
import { ConnectedProps } from 'react-redux'
import deepEqual from 'react-fast-compare'

import Acp from 'syft-acp-uikit'
import { createEntityListConnector } from 'syft-acp-util/entityList'
import { UsersEntity } from 'syft-acp-core/store/users/types'
import { fetchUsers } from 'syft-acp-core/store/users/actions'
import usePrevValue from 'syft-acp-util/hooks/usePrevValue'

type UsersListProps = ConnectedProps<typeof entityConnector>

export const entityConnector = createEntityListConnector<Partial<UsersEntity>>({
  entityActions: { fetchEntities: fetchUsers },
  entityStore: 'users',
})

export const UsersList = ({ actions, data, isLoadingData, query }: UsersListProps) => {
  const prevQuery = usePrevValue(query)

  useEffect(() => {
    if (!deepEqual(prevQuery, query)) {
      actions.fetchEntities?.({ options: query })
    }
  }, [query])
  return (
    <Acp.EntityList hasResultCount hasPagination idKeyValue="id" data={data} isLoading={isLoadingData}>
      <Acp.Table rowLink={row => `/entity/users/view/${row.id}`}>
        <Acp.Col.Text value="id" header="ID" isMinimal isNumeric />
        <Acp.Col.Email value="email" header="Email" isMain />
        <Acp.Col.Boolean value="admin" header="A" headerAbbr headerTooltip="User is admin" isMinimal />
        <Acp.Col.Boolean value="banned" header="B" headerAbbr headerTooltip="User is banned" isMinimal />
      </Acp.Table>
    </Acp.EntityList>
  )
}

export default entityConnector(UsersList)
