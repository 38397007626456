import React, { useEffect, useCallback } from 'react'
import { connect } from 'react-redux'
import { flowRight } from 'lodash-es'
import { bindActionCreators } from 'redux'
import deepEqual from 'react-fast-compare'
import { useTrackingTrigger, TrackingTrigger } from '@indeed/flex-tracking-context'
import { useFlexFlagIsOn } from '@indeed/flex-feature-flags'

import Acp from 'syft-acp-uikit'
import { RowCallbackData } from 'syft-acp-uikit/components/AcpTable/AcpTable.types'
import CountrySpecific from 'syft-acp-core/components/CountrySpecific'
import * as filtersActions from 'syft-acp-core/store/filters/actions'
import * as agencyShiftsActions from 'syft-acp-core/store/agency-shifts/actions'
import { AgencyShiftEntity } from 'syft-acp-core/store/agency-shifts/types'
import { createEntityListConnector } from 'syft-acp-util/entityList'
import { PeopleIcon } from 'syft-acp-uikit/components/AcpTable/headerComponents'
import { showModal } from 'syft-acp-core/store/modals/actions'
import {
  fetchAgencyUnfilledAllocations,
  bulkDeleteAgencyUnfilledAllocations,
} from 'syft-acp-core/store/agency-unfilled-allocations/actions'
import CopiedText from 'syft-acp-core/components/CopiedText'
import { SUPPORTED_COUNTRY_CODES } from 'syft-acp-core/lib/i18n'
import { getVenueCity } from 'syft-acp-util/cities'
import { Tooltip } from '@material-ui/core'
import { Error as ErrorIcon } from '@indeed/ifl-icons'
import {
  FilterSelectCities,
  FilterRolesSkills,
  FilterClientDeliveryTeam,
  FilterSelectIndustries,
  FilterBoolCheckbox,
  FilterAutocompleteEmployers,
  FilterOfferAgency,
  FilterTimeDropdown,
  FilterDateRange,
  FilterAutocompleteVenuesCities,
  FilterAgencyVenuesMultiselect,
  FilterShiftsViewFrom,
} from 'syft-acp-util/components/FilterForm'
import usePrevValue from 'syft-acp-util/hooks/usePrevValue'
import ShiftFulfilmentModal from 'syft-acp-core/components/Modal/ShiftFulfilmentModal'

import { allocatedTimeOptions } from './constants'
import {
  handleDisabledTooltip,
  getDropdownOptions,
  selectHighlight,
  selectWorkersValue,
  selectAgency,
  selectAllocationDate,
  selectRequiredRequested,
  selectBranchAccounts,
} from './helpers'
import {
  ShiftAgencyListProps as Props,
  ShiftAgencyListEntityConnectorProps as EntityConnectorProps,
  RowData,
  SubRowData,
} from './ShiftAgencyList.types'
import { trackingEvents } from './ShiftAgencyList.tracking'
import { ShiftEntity } from 'syft-acp-core/store/shifts/types'

export const entityConnector = createEntityListConnector<AgencyShiftEntity>({
  entityActions: { fetchEntities: agencyShiftsActions.fetchAgencyShifts },
  entityStore: 'agencyShifts',
})

export const storeConnector = connect(null, (dispatch, props: EntityConnectorProps) => ({
  actions: {
    ...props.actions,
    ...bindActionCreators(
      { ...filtersActions, fetchAgencyUnfilledAllocations, bulkDeleteAgencyUnfilledAllocations, showModal },
      dispatch,
    ),
  },
}))

export const branchAccountsPlaceholder = (subRowData?: SubRowData) => {
  if (subRowData?.agency_accounts === '') {
    return <b>NO ACCOUNTS</b>
  }
  return subRowData?.agency_accounts
}

const ShiftJobID = (rowData: RowData) => (
  <CopiedText copyValue={rowData.job_id}>
    <span>
      {`${rowData.id}/`}
      <strong>{rowData.job_id}</strong>
    </span>
  </CopiedText>
)

const defaultFiltersValue = {
  disable_pagination_counters: 'true',
}

export const ShiftAgencyList: React.FC<Props> = ({ actions, data, isLoadingData, query }) => {
  const triggerEvent = useTrackingTrigger()
  const nonViewableAgencyShifts = useFlexFlagIsOn('non_viewable_agency_shifts')
  const agencyBranchInformation = useFlexFlagIsOn('agency_branch_information')
  const acpNoMatchingAgencies = useFlexFlagIsOn('pte_30165_acp_no_matching_agencies')

  const modalName = 'shiftFulfilmentModal'

  const prevQuery = usePrevValue(query)
  const fetch = useCallback(
    (q: Record<string, any>) => actions.fetchEntities && actions.fetchEntities({ options: q }),
    [actions],
  )
  const refetch = () => {
    fetch(query)
  }

  useEffect(() => {
    if (Object.keys(query).length && !deepEqual(prevQuery, query)) fetch(query)
  }, [fetch, prevQuery, query])

  useEffect(() => {
    const queryJobId = query?.job_id
    if (prevQuery?.job_id !== queryJobId && typeof queryJobId !== 'undefined') {
      actions.resetFilters({
        job_id: queryJobId,
        disable_pagination_counters: defaultFiltersValue.disable_pagination_counters,
      })
    }
  }, [query, actions, prevQuery])

  const onFilterChange = useCallback(
    (key: string, value?: string | string[]): void => {
      if (value) {
        triggerEvent(trackingEvents.AGENCY_SHIFTS.FILTERS.CHANGED, { filter: key, value })
      }
    },
    [triggerEvent],
  )

  const onActionDropdownChanged = useCallback(
    (action: string, props: any): void => {
      triggerEvent(trackingEvents.AGENCY_SHIFTS.ACTION.CLICKED, { action, ...props })
    },
    [triggerEvent],
  )

  const rowCallback = (e: RowCallbackData<ShiftEntity>) => {
    triggerEvent(trackingEvents.AGENCY_SHIFTS.SHIFT.SELECTED, { shift_id: e.rowData.id })
    actions.showModal(modalName, {}, null, e)
  }

  return (
    <>
      <CountrySpecific countries={[SUPPORTED_COUNTRY_CODES.US]}>
        {(isUS: boolean) => (
          <Acp.EntityList data={data} isLoading={isLoadingData} idKeyValue="id" hasResultCount hasPagination>
            <Acp.Actions narrowContainer>
              <Acp.FilterGroup title="Job ID">
                <Acp.Filter.Text onChange={onFilterChange} name="job_id" placeholder="Job ID" small />
              </Acp.FilterGroup>
              <Acp.FilterGroup title="Employer:">
                <FilterAutocompleteEmployers
                  onChange={onFilterChange}
                  name="employer_id"
                  queryType="id"
                  placeholder="Employer name"
                />
              </Acp.FilterGroup>
              <Acp.FilterGroup title="Venue:">
                <FilterAgencyVenuesMultiselect onChange={onFilterChange} name="venue_ids" />
              </Acp.FilterGroup>
              {isUS ? (
                <Acp.FilterGroup title="Venue city">
                  <FilterAutocompleteVenuesCities onChange={onFilterChange} name="venue_city" />
                </Acp.FilterGroup>
              ) : (
                <Acp.FilterGroup title="City">
                  <FilterSelectCities onChange={onFilterChange} name="city_id" allowAny />
                </Acp.FilterGroup>
              )}
              {isUS && (
                <Acp.FilterGroup title="Region">
                  <FilterSelectCities onChange={onFilterChange} name="city_id" allowAny />{' '}
                </Acp.FilterGroup>
              )}
              <Acp.FilterGroup title="Client delivery team">
                <FilterClientDeliveryTeam
                  onChange={onFilterChange}
                  name="client_delivery_team_id"
                  allowAny
                  small
                />
              </Acp.FilterGroup>
              <Acp.FilterGroup title="Date:">
                <FilterDateRange
                  onChange={onFilterChange}
                  showShortTime
                  nameFrom="start_time_gte"
                  nameTo="start_time_lte"
                />
              </Acp.FilterGroup>
              <Acp.FilterGroup title="Agency:">
                <Acp.Filter.Text onChange={onFilterChange} name="agency_name" placeholder="Agency name" />
              </Acp.FilterGroup>
              <Acp.FilterGroup title="Industry:">
                <FilterSelectIndustries
                  onChange={onFilterChange}
                  name="industry_id"
                  queryKey="industry_id"
                  options={{ idKey: 'id' }}
                  allowAny
                />
              </Acp.FilterGroup>
              <Acp.FilterGroup title="Roles and skills:">
                <FilterRolesSkills
                  onChange={onFilterChange}
                  role_key="role_id"
                  skill_key="skill_id"
                  independentSkills
                  allowAny
                  small
                />
              </Acp.FilterGroup>
              <Acp.FilterGroup title="View from:">
                <FilterShiftsViewFrom onChange={onFilterChange} />
              </Acp.FilterGroup>
              <Acp.FilterGroup title="Other filters:">
                <FilterBoolCheckbox
                  onChange={onFilterChange}
                  name="pending_agency_jobs"
                  value="true"
                  label="Allocations not responded to:"
                />
                <FilterBoolCheckbox
                  onChange={onFilterChange}
                  name="unfilled"
                  value="true"
                  label="Unfilled only"
                />
                <FilterBoolCheckbox
                  onChange={onFilterChange}
                  name="gone_through_all_ranks"
                  value="true"
                  label="Gone through all ranks"
                />
                <FilterBoolCheckbox
                  onChange={onFilterChange}
                  name="counters_enabled"
                  value="true"
                  label="Enable counters"
                />
                {nonViewableAgencyShifts && (
                  <FilterBoolCheckbox
                    onChange={onFilterChange}
                    name="non_viewable_agency_shifts"
                    value="true"
                    label="Missing branch accounts"
                  />
                )}
                {acpNoMatchingAgencies && (
                  <FilterBoolCheckbox
                    onChange={onFilterChange}
                    name="no_matching_agency"
                    value="true"
                    label="No matching agency"
                  />
                )}
              </Acp.FilterGroup>
              <Acp.FilterGroup title="Agency offer:">
                <FilterOfferAgency onChange={onFilterChange} name="offer_to" allowAny small />
              </Acp.FilterGroup>
              <Acp.FilterGroup title="Time since sent to agency:">
                <FilterTimeDropdown
                  nameAfter="sent_to_agency_after"
                  nameBefore="sent_to_agency_before"
                  onChange={onFilterChange}
                  defaultOption="All Times"
                  options={allocatedTimeOptions}
                  name="allocated_time"
                  allowAny
                />
              </Acp.FilterGroup>
              <Acp.FilterGroup title="Requires agency:">
                <Acp.Filter.Dropdown placeholder="Any" name="requires_agency" onChange={onFilterChange}>
                  <Acp.Option slug="true">Requires agency: true</Acp.Option>
                  <Acp.Option slug="false">Requires agency: false</Acp.Option>
                </Acp.Filter.Dropdown>
              </Acp.FilterGroup>
              <Acp.FilterGroup title="Pagination">
                <FilterBoolCheckbox
                  name="disable_pagination_counters"
                  onChange={onFilterChange}
                  value="true"
                  defaultValue={defaultFiltersValue.disable_pagination_counters}
                  label="Disable pagination counters"
                />
              </Acp.FilterGroup>
            </Acp.Actions>
            <Acp.Table rowColor={selectHighlight} rowCallback={rowCallback} rowLink={null}>
              {acpNoMatchingAgencies && (
                <Acp.Col.Info
                  value={() => {
                    return (
                      <Tooltip
                        title={<div style={{ fontSize: 15 }}>No matching agency</div>}
                        placement="left"
                        arrow
                      >
                        <ErrorIcon color="red" />
                      </Tooltip>
                    )
                  }}
                  tooltip="No matching agency"
                  disableFunc={(rowData: AgencyShiftEntity) => !rowData.no_matching_agency}
                  header=""
                  pureCell
                  hasNoneStyles
                  disabled
                  isMinimal
                />
              )}
              <Acp.Col.Text value={ShiftJobID} header="Shift ID/Job ID" colNoCallback />
              <Acp.Col.Text value="client_name" header="Brand/company" />
              <Acp.Col.Text value="venue_name" header="Venue" />
              <Acp.Col.DateTime
                value={['start_time', 'end_time']}
                header="Start/end"
                options={{ timeZone: 'venue_timezone' }}
              />
              <Acp.Col.Text value={isUS ? getVenueCity : 'city'} header="City" isMinimal />
              <Acp.Col.Text value="role_title" header="Role" />
              <Acp.Col.Text
                value={selectWorkersValue}
                header={<PeopleIcon />}
                headerTooltip="Workers booked/required"
                isMinimal
              />
              <Acp.SubRows value="allocations" rowColor={selectHighlight}>
                <Acp.Col.Text value={selectAgency} header="Agency" colNoLink />
                {agencyBranchInformation && (
                  <Acp.Col.Text value={selectBranchAccounts} header="Branch accounts" colNoLink />
                )}
                <Acp.Col.Boolean
                  value="accepted"
                  options={{ isEditable: true }}
                  header="AR"
                  headerTooltip="Agency Responded"
                />
                <Acp.Col.Timestamp
                  value="sent_to_agency_at"
                  header="Time since sent to agency"
                  valueTooltip={selectAllocationDate}
                  isMinimal
                  options={{ showRelative: true, relativeOnly: true }}
                />
                <Acp.Col.Text
                  value={selectRequiredRequested}
                  header="P/R"
                  headerTooltip="Workers Provided/Requested"
                />
                <Acp.Col.ActionDropdown
                  options={getDropdownOptions(actions, refetch, onActionDropdownChanged)}
                  disabledTooltip={handleDisabledTooltip}
                  label="Actions"
                  header="Actions"
                  colNoLink
                  colNoCallback
                />
              </Acp.SubRows>
            </Acp.Table>
            <Acp.Footer>Shifts listed here have been posted on Flex+ to agencies only.</Acp.Footer>
          </Acp.EntityList>
        )}
      </CountrySpecific>
      <TrackingTrigger onLoad={{ event: trackingEvents.AGENCY_SHIFTS.PAGE.LOADED }} />
      <ShiftFulfilmentModal modalName={modalName} />
    </>
  )
}

export default flowRight(entityConnector, storeConnector)(ShiftAgencyList)
