export type FetchListOptions = {
  options: Partial<{
    [key: string]: any
  }>
}

export const DOCUMENT_TYPES = {
  VISA: 'visa',
  EEA_NATIONAL_ID: 'eea_national_id',
  PASSPORT: 'passport',
} as const

export type DocumentType = 'visa' | 'eea_national_id' | 'passport'

export type ExpiringDocumentEntity = {
  id: number
  count_booked_shifts_after_expiry_date: number
  document_type: DocumentType
  email: string
  expires_in: string
  resolved: boolean
  telephone_number: string
  worker_id: number
  worker_name: string
}
