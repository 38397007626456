import * as structureTypes from 'syft-acp-core/components/EditableTable'
import { CountryCode, SUPPORTED_COUNTRY_CODES } from 'syft-acp-core/lib/i18n'
import type { Option } from './types'

export const getDataStructure = ({
  venueCategories,
  employerVenues,
  roles,
  skills,
  shiftRateTypes = [],
  countryCode,
  awrEnabled,
}: {
  venueCategories: Option[]
  employerVenues: Option[]
  roles: Option[]
  skills: Option[]
  shiftRateTypes: Option[]
  countryCode: CountryCode
  awrEnabled: boolean
}) => {
  const awrField =
    awrEnabled && countryCode === SUPPORTED_COUNTRY_CODES.GB
      ? [
          [
            'awr_parity_rate',
            {
              header: 'AWR parity rate',
              type: structureTypes.TYPE_RATE,
              editable: true,
              allowEmpty: true,
              parameters: { ariaLabel: 'AWR parity rate' },
            },
          ],
        ]
      : []

  const feeField = [
    countryCode === SUPPORTED_COUNTRY_CODES.GB
      ? [
          'margins_percent',
          {
            header: 'Margin',
            type: structureTypes.TYPE_NUMERIC,
            editable: true,
            suffix: '%',
            prefix: '%',
            parameters: { ariaLabel: 'Margin' },
          },
        ]
      : [
          'fee_percent',
          {
            header: 'Fee',
            type: structureTypes.TYPE_NUMERIC,
            editable: true,
            suffix: '%',
            prefix: '%',
            parameters: { ariaLabel: 'Fee' },
          },
        ],
  ]

  const invoiceRateField =
    countryCode === SUPPORTED_COUNTRY_CODES.GB
      ? [
          [
            'invoice_rate',
            {
              header: 'Invoice rate',
              type: structureTypes.TYPE_RATE,
              editable: true,
              allowEmpty: true,
              parameters: {
                ariaLabel: 'Invoice rate',
              },
            },
          ],
        ]
      : []

  return [
    [
      'employer_id',
      {
        header: 'Employer Id',
        editable: false,
        type: structureTypes.TYPE_TXT,
      },
    ],
    [
      'venue_category_id',
      {
        header: 'Venue category',
        editable: true,
        type: structureTypes.TYPE_DROPDOWN,
        options: venueCategories,
        parameters: {
          allowAny: true,
          anyOption: 'No Venue Category',
          ariaLabel: 'Venue category',
        },
      },
    ],
    [
      'work_location_id',
      {
        header: 'Venue',
        editable: true,
        type: structureTypes.TYPE_DROPDOWN,
        options: employerVenues,
        parameters: {
          allowAny: true,
          anyOption: 'No Venue',
          ariaLabel: 'Venue',
        },
      },
    ],
    [
      'shift_rate_type_id',
      {
        header: 'Shift type',
        editable: true,
        type: structureTypes.TYPE_DROPDOWN,
        options: shiftRateTypes,
        parameters: {
          allowAny: true,
          anyOption: 'No Shift Type',
          ariaLabel: 'Shift type',
        },
      },
    ],
    ['', { type: structureTypes.TYPE_SEPARATOR, colspan: 4 }],
    [
      'role_id',
      {
        header: 'Role',
        editable: true,
        type: structureTypes.TYPE_DROPDOWN,
        options: roles,
        parameters: {
          allowAny: true,
          anyOption: 'No Role',
          ariaLabel: 'Role',
        },
      },
    ],
    [
      'skill_id',
      {
        header: 'Skill',
        editable: true,
        type: structureTypes.TYPE_DROPDOWN,
        options: skills,
        parameters: {
          allowAny: true,
          anyOption: 'No Skill',
          ariaLabel: 'Skill',
        },
      },
    ],
    ['', { type: structureTypes.TYPE_SEPARATOR, colspan: 4 }],
    [
      'default_payable_rate',
      {
        header: 'Default rate',
        type: structureTypes.TYPE_RATE,
        editable: true,
        allowEmpty: true,
        parameters: {
          ariaLabel: 'Default rate',
        },
      },
    ],
    [
      'min_payable_rate',
      {
        header: 'Minimum rate',
        type: structureTypes.TYPE_RATE,
        editable: true,
        allowEmpty: true,
        parameters: {
          ariaLabel: 'Minimum rate',
        },
      },
    ],
    [
      'max_payable_rate',
      {
        header: 'Maximum rate',
        type: structureTypes.TYPE_RATE,
        editable: true,
        allowEmpty: true,
        parameters: {
          ariaLabel: 'Maximum rate',
        },
      },
    ],
    ...awrField,
    ...invoiceRateField,
    ...feeField,
    ['', { type: structureTypes.TYPE_SEPARATOR, colspan: 4 }],
    ['effective_date', { header: 'Effective Date', type: structureTypes.TYPE_DATE, editable: true }],
    ['disabled_at', { header: 'Disabled Date', type: structureTypes.TYPE_TXT, editable: false }],
  ]
}
