// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import React from 'react'

import { TrackingTrigger, TrackingProvider } from '@indeed/flex-tracking-context'

import PageView from 'syft-acp-core/components/PageView'

import WorkerOnboardingDetail from 'syft-acp-core/entities/WorkerOnboarding/WorkerOnboardingDetail'
import { WorkerSubNavigation } from '../SubNavigation'
import { trackingEvents } from './EntityWorkersOnboarding.tracking'
import { RouteComponentProps } from 'react-router-dom'

type Props = RouteComponentProps<{
  id: string
}>
export const EntityWorkersOnboarding = ({ match: { params } }: Props) => (
  <TrackingProvider
    value={{
      context: {
        worker_id: params.id,
      },
    }}
  >
    <TrackingTrigger onLoad={{ event: trackingEvents.PAGE.VIEWED }} />
    <WorkerSubNavigation workerID={params.id} />
    <PageView title={['Workers', `Viewing worker ${params.id}`]} container>
      <WorkerOnboardingDetail id={Number(params.id)} />
    </PageView>
  </TrackingProvider>
)
