// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import React from 'react'
import { TrackingTrigger } from '@indeed/flex-tracking-context'

import ExpiringDocumentsList from 'syft-acp-core/entities/ExpiringDocumentsList'
import { trackingEvents } from 'syft-acp-core/entities/ExpiringDocumentsList/ExpiringDocumentsList.tracking'
import PageView from 'syft-acp-core/components/PageView'
import SubNavigationWatchlist from './SubNavigation'

export const WatchlistDocuments = () => (
  <>
    <SubNavigationWatchlist />
    <TrackingTrigger onLoad={{ event: trackingEvents.PAGE.VIEWED }} />
    <PageView title={['Watchlist', 'Expiring documents']} entityPage>
      <ExpiringDocumentsList />
    </PageView>
  </>
)
