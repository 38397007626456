// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { clone } from 'lodash-es'
import DataBoolean from './DataBoolean'

class DataBooleanCollection extends Component {
  static propTypes = {
    selected: PropTypes.array,
    options: PropTypes.array,
    onChange: PropTypes.func,
    editable: PropTypes.bool,
    display: PropTypes.string,
  }

  static defaultProps = {
    selected: [],
    onChange: () => {},
    options: [],
    editable: false,
    display: 'inline',
  }

  onItemChange = id => checked => {
    const selected = clone(this.props.selected || [])
    if (checked) {
      selected.push(id)
    } else {
      selected.splice(selected.indexOf(id), 1)
    }
    return this.props.onChange(selected)
  }

  render() {
    const selected = this.props.selected || []
    const options = this.props.options || []
    return (
      <div>
        {options.map((item, index) => {
          return (
            <DataBoolean
              key={String(index)}
              onChange={this.onItemChange(item[0])}
              checked={selected.indexOf(item[0]) > -1}
              editable={this.props.editable}
              label={item[1]}
              display={this.props.display}
            />
          )
        })}
      </div>
    )
  }
}

export default DataBooleanCollection
