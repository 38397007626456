// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { history } from 'syft-acp-core/history'

import { Button } from 'syft-acp-atoms/Button'
import { showConfirmModal } from 'syft-acp-core/store/modals/actions'
import * as actionTypes from 'syft-acp-core/store/employer-venues/actions'
import RecordControls from 'syft-acp-core/components/RecordSection/RecordControls'
import LoadingIndicator from 'syft-acp-util/components/LoadingIndicator'

class EmployerVenueAreaDetailControls extends Component {
  static propTypes = {
    actions: PropTypes.objectOf(PropTypes.func).isRequired,
    data: PropTypes.object.isRequired,
    employerID: PropTypes.number.isRequired,
    venueID: PropTypes.number.isRequired,
    areaID: PropTypes.number.isRequired,
    isLoadingData: PropTypes.bool.isRequired,
    isPristine: PropTypes.bool.isRequired,
    isSavingData: PropTypes.bool.isRequired,
  }

  backAction = () => {
    history.push(`/entity/employers/view/${this.props.employerID}/venues/view/${this.props.venueID}`)
  }

  saveAction = () => {
    const { actions, employerID, venueID, areaID, data } = this.props
    actions.saveEmployerVenueArea(employerID, venueID, areaID, data)
  }

  deleteAction = () => {
    const { actions, employerID, venueID, areaID } = this.props

    actions.showConfirmModal({
      question: 'Are you sure? This area will be permanently deleted.',
      onConfirm: () => actions.deleteEmployerVenueArea(employerID, venueID, areaID),
    })
  }

  render() {
    return (
      <RecordControls>
        <Button onClick={this.backAction}>Back to venue details</Button>
        <div className="right">
          <LoadingIndicator loadingState={this.props.isSavingData || this.props.isLoadingData} />{' '}
          <Button
            kind="success"
            onClick={this.saveAction}
            disabled={this.props.isSavingData || this.props.isPristine}
          >
            Save
          </Button>{' '}
          <Button kind="danger" onClick={this.deleteAction}>
            Delete
          </Button>
        </div>
      </RecordControls>
    )
  }
}

export default connect(
  ({ employerVenues: { isSavingData, isLoadingData } }) => ({
    isSavingData,
    isLoadingData,
  }),
  dispatch => ({
    actions: bindActionCreators({ ...actionTypes, showConfirmModal }, dispatch),
  }),
)(EmployerVenueAreaDetailControls)
