import { apiRequest, handleCall } from 'syft-acp-core/api/call'
import * as actions from './actions'
import type { ExternalEmployerAction } from './actions'

const externalEmployerBase = ({ payload: reqArgs }: ExternalEmployerAction, method = 'GET') =>
  new Promise((resolve, reject) => {
    const req = apiRequest({ path: `/admin/employers/external_employers`, reqArgs, method })
    handleCall(req, resolve, reject, true)
  })

export const fetchExternalEmployer = (action: ReturnType<typeof actions.fetchExternalEmployer>) =>
  externalEmployerBase(action)
export const updateExternalEmployer = (action: ReturnType<typeof actions.updateExternalEmployer>) =>
  externalEmployerBase(action, 'PATCH')
export const deleteExternalEmployer = (action: ReturnType<typeof actions.deleteExternalEmployer>) =>
  externalEmployerBase(action, 'DELETE')
export const createExternalEmployer = (action: ReturnType<typeof actions.createExternalEmployer>) =>
  externalEmployerBase(action, 'POST')
