// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import React from 'react'
import PropTypes from 'prop-types'

import './RecordHeader.css'

const RecordHeader = props => {
  let node

  if (props.main) {
    node = <h2>{props.children}</h2>
  }
  if (props.sub) {
    node = <h3>{props.children}</h3>
  }
  return (
    <div className={`item-header ${props.border ? 'border' : ''}${props.noPadding ? ' padding-0' : ''}`}>
      {node}
      {props.labels}
    </div>
  )
}
RecordHeader.propTypes = {
  children: PropTypes.node.isRequired,
  labels: PropTypes.node,
  border: PropTypes.bool,
  main: PropTypes.bool,
  sub: PropTypes.bool,
  noPadding: PropTypes.bool,
}
RecordHeader.defaultProps = {
  border: false,
}

export default RecordHeader
