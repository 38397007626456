import React, { useState } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Link } from 'react-router-dom'
import { get } from 'lodash-es'
import classnames from 'classnames'
import S from './DataAreasTabs.module.scss'
import { selectTabArea } from 'syft-acp-core/store/data-tabs/actions'
import DataTabs from '../../DataTabs'
import { Props } from './DataAreasTabs.types'

export const DataAreasTabs = ({
  value,
  data,
  withoutTableMargin,
  actions,
  hideContent,
  selectedAreaId,
}: Props) => {
  const area = data?.area
  const [tabId, setTypeId] = useState<number | undefined>(selectedAreaId || area?.id || value?.[0].id)
  const handleSelect = (areaID: number) => {
    setTypeId(areaID)
    actions.selectTabArea(areaID)
  }
  const selectedTab = value?.find(tab => tab.id === tabId)
  return (
    <DataTabs
      value={value}
      withoutTableMargin={withoutTableMargin}
      callback={handleSelect}
      attribute="area"
      tabId={tabId}
    >
      {selectedTab && !hideContent && (
        <div className={S['data_tap_info']}>
          <div>
            <div
              className={classnames(
                S['data_tab_access_instructions'],
                !withoutTableMargin && S['data_tab_access_instructions_margin'],
              )}
            >
              <strong>Access instructions: </strong>
            </div>
            <div className={withoutTableMargin && S['data_tab_access_instructions_gap']}>
              {selectedTab.access_instructions}
            </div>
          </div>
          <div className={!withoutTableMargin && S['data_tab_link']}>
            <Link
              to={`/entity/employers/view/${data?.employerID}/venues/view/${data?.venueID}/areas/view/${selectedTab.id}`}
            >
              Link to Area
            </Link>
          </div>
        </div>
      )}
    </DataTabs>
  )
}

export default connect(
  state => ({
    selectedAreaId: get(state, 'dataTabs.area_id'),
  }),
  dispatch => ({
    actions: bindActionCreators({ selectTabArea }, dispatch),
  }),
)(DataAreasTabs)
