import * as React from 'karet'
import * as U from 'karet.util'
import * as R from 'kefir.ramda'
import * as L from 'kefir.partial.lenses'
import PropTypes from 'prop-types'
import { useIntl } from 'react-intl'

import { Button } from 'syft-acp-atoms/Button'
import InputText from 'syft-acp-atoms/InputText'
import InputDatepicker from 'syft-acp-atoms/InputDatepicker'
import InputDropdown from 'syft-acp-atoms/InputDropdown'
import NotifyBar from 'syft-acp-atoms/NotifyBar'
import { getSafeCurrencySymbol } from 'syft-acp-util/formatting'
import Acp from 'syft-acp-uikit'
import { _hasSalesForceCurrencySupport } from 'syft-acp-core/api/properties'

import { PropTypesAtom } from 'syft-acp-util/observables'
import './EmployerSalesForceView.css'

/** Checks whether editing the active plan should be disabled. */
const isEditPlanDisabled = (data, isLoading) =>
  U.or(R.equals(U.view('commitment_end_date', data), undefined), R.equals(isLoading, true))

/** Checks whether adding new periods should be disabled. */
const isAddPeriodDisabled = (data, isLoading, hasSalesForceData) =>
  U.or(
    U.or(R.equals(U.view('commitment_end_date', data), undefined), R.equals(isLoading, true)),
    R.not(hasSalesForceData)
  )

/** Checks whether editing the active period should be disabled. */
const isEditPeriodDisabled = (planData, periodData, isLoading) =>
  U.or(
    U.or(R.equals(U.view('id', planData), undefined), R.equals(isLoading, true)),
    R.equals(U.view('promo_period', periodData), undefined)
  )

/** Small helper component for form rows. */
const FormRow = ({ title, children }) => (
  <div className="form-row">
    <div className="form-label">{title || <>&nbsp;</>}</div>
    <div className="form-input">{children}</div>
  </div>
)

FormRow.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node.isRequired,
}
FormRow.defaultProps = {
  title: null,
}

/** Helper function for getting the selected pricing plan name. */
const getPricingPlanName = pricingData => sales_pricing_plan_type_id => {
  const a = R.findIndex(R.propEq('slug', Number(sales_pricing_plan_type_id)))(pricingData)
  return U.ifElse(R.not(R.equals(a, -1)), U.view([a, 'label'], pricingData), '–')
}

const SalesForceViewPure = ({
  entityEmployerName,
  entityName,
  entityType,

  dataPlans,
  dataPlansMeta,
  selectedPlan,
  addPromotionPlan,
  selectPromotionPlan,
  deselectPromotionPlan,

  dataPeriods,
  selectedPeriod,
  addPromotionPeriod,
  selectPromotionPeriod,

  dataPricing,
  dataSalesForce,

  savePromotionData,

  isLoading,
  backLink,
}) => {
  const intl = useIntl()
  // Split the plans data.
  const dataPlansData = dataPlans
  const activePlan = U.view([selectedPlan, L.defaults({})], dataPlansData)
  const activePlanSelect = name => U.view(name, activePlan)
  const hasSalesForceData = R.not(R.equals(U.view(['sales_win_attributes', 'sales_force_id'], dataSalesForce), null))
  const canEditPlan = isEditPlanDisabled(activePlan, isLoading)
  const canAddPlan = R.not(hasSalesForceData)

  // Split the periods data.
  const activePeriod = U.view([selectedPeriod, L.defaults({})], dataPeriods)
  const activePeriodSelect = name => U.view(name, activePeriod)
  const canEditPromo = isEditPeriodDisabled(activePlan, activePeriod, isLoading)
  const canAddPromo = isAddPeriodDisabled(activePlan, isLoading, hasSalesForceData)

  const fnGetPricingPlanName = getPricingPlanName(dataPricing)

  // TODO: remove this. Can be removed when i18n has gone fully live./
  // The issue is that 'yearly_spend' can be either an object or an integer,
  // e.g. {amount: 12345, currency: 'GBP'} or just '12345'.
  // Check if it's an object, and if so, activate i18n enabled mode.
  //
  // NOTE: currently, the i18n environment curiously does NOT have an object for 'yearly_spend',
  // and regular staging DOES.
  const hasI18nValue = R.or(_hasSalesForceCurrencySupport(), R.is(Object, U.view('yearly_spend', activePlan)))

  // TODO: remove the U.ifElse() call.
  // Currently we have two duplicates of this whole component here. One that assumes 'yearly_spend' is an object,
  // and one that does not. This is needed because the table definitions are scanned once at runtime,
  // meaning we can't easily change them on the fly (i.e. we can't have U.ifElse() calls inside).
  //
  // When i18n has been launched, and 'yearly_spend' definitively becomes an object,
  // we can remove the 'hasI18nValue' variable and remove the 'else' branch in the following U.ifElse() call.
  return (
    <>
      {U.ifElse(
        hasI18nValue,
        <Acp.Component>
          <Acp.Actions>
            <Acp.Header>
              Promotional details page for{' '}
              <span>{U.ifElse(R.equals(entityType, 'Employer'), <>{entityEmployerName}</>, <>{entityName}</>)}</span>{' '}
              <>{U.unless(R.isEmpty(entityType), <span>({entityType})</span>)}</>
            </Acp.Header>
            <Acp.ButtonLink onClick={() => deselectPromotionPlan()} to={backLink}>
              Return to Salesforce
            </Acp.ButtonLink>
            <Acp.Button onClick={savePromotionData} kind="success">
              Save
            </Acp.Button>
          </Acp.Actions>

          <NotifyBar karet-lift noMargin conditional visible={R.not(hasSalesForceData)} severity="error">
            Promotion details can only be added after SalesForce data has been saved on the previous page.
          </NotifyBar>

          <div className="sales-forms">
            <div className="sales-form">
              <FormRow title="Price plan">
                <InputDropdown
                  disabled={canEditPlan}
                  value={activePlanSelect('sales_pricing_plan_type_id')}
                  selectOptions={dataPricing}
                />
              </FormRow>
              <FormRow title="Commitment start date">
                <InputDatepicker disabled={canEditPlan} value={activePlanSelect('commitment_start_date')} />
              </FormRow>
              <FormRow title="Commitment end date">
                <InputDatepicker disabled={canEditPlan} value={activePlanSelect('commitment_end_date')} />
              </FormRow>
              <FormRow title="Commitment period">
                <InputText disabled={canEditPlan} value={activePlanSelect('commitment_period')} textSuffix="months" />
              </FormRow>
              <FormRow title="Min. spend per year (required)">
                <InputText
                  disabled={canEditPlan}
                  value={activePlanSelect('yearly_spend')}
                  textSuffix={U.through(x => U.lift(getSafeCurrencySymbol)(x))(
                    U.view(['yearly_spend', 'currency', L.defaults(intl.currencyCode)], activePlan)
                  )}
                />
              </FormRow>
              <FormRow title={null}>
                <Button disabled={canAddPlan} kind="regular" onClick={addPromotionPlan}>
                  New promotion plan
                </Button>
              </FormRow>
            </div>

            <div className="sales-form">
              <FormRow title="Promotion period">
                <InputText disabled={canEditPromo} value={activePeriodSelect('promo_period')} textSuffix="months" />
              </FormRow>
              <FormRow title="Promotion fee">
                <InputText disabled={canEditPromo} value={activePeriodSelect('fee_percent')} textSuffix="%" />
              </FormRow>
              <FormRow title="Promotion start date">
                <InputDatepicker disabled={canEditPromo} value={activePeriodSelect('promo_start_date')} />
              </FormRow>
              <FormRow title={null}>
                <Button disabled={canAddPromo} kind="regular" onClick={addPromotionPeriod}>
                  Add promotion period
                </Button>
              </FormRow>
            </div>
          </div>

          <div className="sales-lists">
            <div className="sales-list-item">
              <Acp.EntityList
                hasNoMargin
                isIntegrated
                hasMinimumSize={1}
                rowSelection={{ key: '#', items: [selectedPlan] }}
                data={{ data: dataPlansData, meta: dataPlansMeta }}
                isLoading={isLoading}
              >
                <Acp.Table rowCallback={selectPromotionPlan}>
                  <Acp.Col.Text
                    value={data => (
                      <>{data ? fnGetPricingPlanName(data.sales_pricing_plan_type_id, dataPricing) : '–'}</>
                    )}
                    header="Price plan"
                    options={{ isNonWrapped: true }}
                  />
                  <Acp.Col.Timestamp
                    value="commitment_start_date"
                    header="Start date"
                    options={{ useFormat: 'date' }}
                  />
                  <Acp.Col.Timestamp value="commitment_end_date" header="End date" options={{ useFormat: 'date' }} />
                  <Acp.Col.Text value="commitment_period" header="Com. period" />
                  <Acp.Col.Money value="yearly_spend" header="Min. spend (PA)" />
                </Acp.Table>
              </Acp.EntityList>
            </div>

            <div className="sales-list-separator" />

            <div className="sales-list-item">
              <Acp.EntityList
                hasNoMargin
                isIntegrated
                hasMinimumSize={1}
                rowSelection={{ key: '#', items: [selectedPeriod] }}
                data={{ data: dataPeriods, meta: dataPlansMeta }}
                isLoading={isLoading}
              >
                <Acp.Table rowCallback={selectPromotionPeriod}>
                  <Acp.Col.Text value="promo_period" header="Promo period" />
                  <Acp.Col.Timestamp value="promo_start_date" header="Start date" options={{ useFormat: 'date' }} />
                  <Acp.Col.Timestamp value="promo_end_date" header="Finish date" options={{ useFormat: 'date' }} />
                  <Acp.Col.Text value="fee_percent" header="Promo fee" />
                </Acp.Table>
              </Acp.EntityList>
            </div>
          </div>

          <Acp.Footer>
            Start dates must be on Monday, and will be set to the last Monday when saved if they aren't. All periods
            outside the promo periods will be at standard fee.
          </Acp.Footer>
        </Acp.Component>,
        <Acp.Component>
          <Acp.Actions>
            <Acp.Header>
              Promotional details page for{' '}
              <span>{U.ifElse(R.equals(entityType, 'Employer'), <>{entityEmployerName}</>, <>{entityName}</>)}</span>{' '}
              <>{U.unless(R.isEmpty(entityType), <span>({entityType})</span>)}</>
            </Acp.Header>
            <Acp.ButtonLink onClick={() => deselectPromotionPlan()} to={backLink}>
              Return to Salesforce
            </Acp.ButtonLink>
            <Acp.Button onClick={savePromotionData} kind="success">
              Save
            </Acp.Button>
          </Acp.Actions>

          <NotifyBar karet-lift noMargin conditional visible={R.not(hasSalesForceData)} severity="error">
            Promotion details can only be added after SalesForce data has been saved on the previous page.
          </NotifyBar>

          <div className="sales-forms">
            <div className="sales-form">
              <FormRow title="Price plan">
                <InputDropdown
                  disabled={canEditPlan}
                  value={activePlanSelect('sales_pricing_plan_type_id')}
                  selectOptions={dataPricing}
                />
              </FormRow>
              <FormRow title="Commitment start date">
                <InputDatepicker disabled={canEditPlan} value={activePlanSelect('commitment_start_date')} />
              </FormRow>
              <FormRow title="Commitment end date">
                <InputDatepicker disabled={canEditPlan} value={activePlanSelect('commitment_end_date')} />
              </FormRow>
              <FormRow title="Commitment period">
                <InputText disabled={canEditPlan} value={activePlanSelect('commitment_period')} textSuffix="months" />
              </FormRow>
              <FormRow title="Min. spend per year (required)">
                <InputText
                  disabled={canEditPlan}
                  value={activePlanSelect(['yearly_spend'])}
                  textSuffix={getSafeCurrencySymbol()}
                />
              </FormRow>
              <FormRow title={null}>
                <Button disabled={canAddPlan} kind="regular" onClick={addPromotionPlan}>
                  New promotion plan
                </Button>
              </FormRow>
            </div>

            <div className="sales-form">
              <FormRow title="Promotion period">
                <InputText disabled={canEditPromo} value={activePeriodSelect('promo_period')} textSuffix="months" />
              </FormRow>
              <FormRow title="Promotion fee">
                <InputText disabled={canEditPromo} value={activePeriodSelect('fee_percent')} textSuffix="%" />
              </FormRow>
              <FormRow title="Promotion start date">
                <InputDatepicker disabled={canEditPromo} value={activePeriodSelect('promo_start_date')} />
              </FormRow>
              <FormRow title={null}>
                <Button disabled={canAddPromo} kind="regular" onClick={addPromotionPeriod}>
                  Add promotion period
                </Button>
              </FormRow>
            </div>
          </div>

          <div className="sales-lists">
            <div className="sales-list-item">
              <Acp.EntityList
                hasNoMargin
                isIntegrated
                hasMinimumSize={1}
                rowSelection={{ key: '#', items: [selectedPlan] }}
                data={{ data: dataPlansData, meta: dataPlansMeta }}
                isLoading={isLoading}
              >
                <Acp.Table rowCallback={selectPromotionPlan}>
                  <Acp.Col.Text
                    value={data => (
                      <>{data ? fnGetPricingPlanName(data.sales_pricing_plan_type_id, dataPricing) : '–'}</>
                    )}
                    header="Price plan"
                    options={{ isNonWrapped: true }}
                  />
                  <Acp.Col.Timestamp
                    value="commitment_start_date"
                    header="Start date"
                    options={{ useFormat: 'date' }}
                  />
                  <Acp.Col.Timestamp value="commitment_end_date" header="End date" options={{ useFormat: 'date' }} />
                  <Acp.Col.Text value="commitment_period" header="Com. period" />
                  <Acp.Col.Money value="yearly_spend" header="Min. spend (PA)" />
                </Acp.Table>
              </Acp.EntityList>
            </div>

            <div className="sales-list-separator" />

            <div className="sales-list-item">
              <Acp.EntityList
                hasNoMargin
                isIntegrated
                hasMinimumSize={1}
                rowSelection={{ key: '#', items: [selectedPeriod] }}
                data={{ data: dataPeriods, meta: dataPlansMeta }}
                isLoading={isLoading}
              >
                <Acp.Table rowCallback={selectPromotionPeriod}>
                  <Acp.Col.Text value="promo_period" header="Promo period" />
                  <Acp.Col.Timestamp value="promo_start_date" header="Start date" options={{ useFormat: 'date' }} />
                  <Acp.Col.Timestamp value="promo_end_date" header="Finish date" options={{ useFormat: 'date' }} />
                  <Acp.Col.Text value="fee_percent" header="Promo fee" />
                </Acp.Table>
              </Acp.EntityList>
            </div>
          </div>

          <Acp.Footer>
            Start dates must be on Monday, and will be set to the last Monday when saved if they aren't. All periods
            outside the promo periods will be at standard fee.
          </Acp.Footer>
        </Acp.Component>
      )}
    </>
  )
}

SalesForceViewPure.propTypes = {
  entityEmployerName: PropTypes.string.isRequired,
  entityName: PropTypesAtom.isRequired,
  entityType: PropTypesAtom.isRequired,

  dataPlans: PropTypesAtom.isRequired,
  dataPlansMeta: PropTypesAtom.isRequired,
  selectedPlan: PropTypesAtom.isRequired,
  addPromotionPlan: PropTypes.func.isRequired,
  selectPromotionPlan: PropTypes.func.isRequired,
  deselectPromotionPlan: PropTypes.func.isRequired,

  dataPeriods: PropTypesAtom.isRequired,
  selectedPeriod: PropTypesAtom.isRequired,
  addPromotionPeriod: PropTypes.func.isRequired,
  selectPromotionPeriod: PropTypes.func.isRequired,

  dataPricing: PropTypesAtom.isRequired,
  dataSalesForce: PropTypesAtom.isRequired,

  savePromotionData: PropTypes.func.isRequired,

  isLoading: PropTypesAtom.isRequired,
  backLink: PropTypes.string.isRequired,
}

export default SalesForceViewPure
