import { get } from 'lodash-es'
import { store } from 'syft-acp-core/store'

/**
 * Returns the string name of a city. If a city is not found,
 * it will return 'unknown city' with the ID.
 */
export const getCity = (id?: number): string => {
  if (!id) {
    return '–'
  }
  const cities = store.getState().cities.entityMap
  return cities[id] ? cities[id].name : `${id} (unknown city)`
}

export const getVenueCity = (rowData: Record<string, any>): string => {
  const addressKey = get(rowData, 'venue') ? 'venue.address' : 'address'
  if (!get(rowData, addressKey)) return '–'
  return [get(rowData, `${addressKey}.city`), get(rowData, `${addressKey}.federated_state_code`)]
    .filter(Boolean)
    .join(', ')
}
