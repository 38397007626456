// Syft ACP - Atoms <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import './SubNavigationBadge.css'

/**
 * Badge displaying e.g. number of active results on a subnav menu button.
 *
 * Used for the shifts/agency shifts navigation buttons.
 */
export const SubNavigationBadge = ({ children }) => (
  <div className={classNames('SubNavigationBadge', 'monospace-text')}>{children}</div>
)

SubNavigationBadge.propTypes = {
  children: PropTypes.node,
}

SubNavigationBadge.defaultProps = {
  children: null,
}
