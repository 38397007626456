export const trackingEvents = {
  PAGE: {
    VIEWED: 'agency_account_details.page.viewed',
  },
  FORM_SAVE_BUTTON: {
    CLICKED: 'agency_account_details.form_save_button.clicked',
  },
  NEW_LOCATION: {
    CLICKED: 'agency_account_details.new_location_button.clicked',
  },
}
