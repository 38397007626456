// Syft ACP - Lib <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import { history } from 'syft-acp-core/history'
import queryString from 'query-string'
import { toPairs, fromPairs, sortBy, filter, get, isFunction, mapValues, pickBy, isEqual } from 'lodash-es'

// Below this time, remove admin alerts.
const TIME_CUTOFF = 3600 * 24 * 1000 // 24 hours in ms

// In order to compare the timestamps of the alerts,
// we need to convert the created_at time into Date objects.
export const convertAlertsToDates = entities =>
  mapValues(entities, alert => ({
    ...alert,
    created_at: new Date(alert.created_at),
  }))

// Filter out alerts past our cutoff date.
export const filterAlertsByCutoff = alerts => {
  const cutoffDate = new Date(Date.now() - TIME_CUTOFF)
  return pickBy(alerts, alert => alert.created_at >= cutoffDate)
}

// Checks to see if, for an array of objects, any object contains a truthy attribute with a given name.
export const arrObjHasAttr = (arr, attr) => {
  for (const a of arr) {
    if (a[attr]) return true
  }
  return false
}

/**
 * Runs all query variables through some filters to remove unwanted items.
 * Also, if needed, extra variables can be inserted.
 * E.g. the page number is removed if it is 1. Null, undefined and empty strings are removed.
 * The output is returned sorted to minimize the items 'jumping around' in the string.
 */
const filterQueryVariables = (vars, options = {}, filterOptions = {}) => {
  const { includeEmpty } = filterOptions
  const a = toPairs({ ...vars, ...options })
  // eslint-disable-next-line valid-typeof
  const b = filter(a, pair => {
    const handleEmptyString = includeEmpty ? true : pair[1] !== ''
    return pair[1] !== null && handleEmptyString && pair[1] !== false
  })
  const c = sortBy(b, pair => pair[0])
  const params = fromPairs(c)

  // Remove the page number if it's 1 to reduce query string complexity.
  if (Number(params.page) === 1) {
    delete params.page
  }

  // Modify all parameters that are just boolean true to display as empty string.
  // We'll filter them out afterwards.
  return Object.keys(params).reduce((prms, k) => ({ ...prms, [k]: params[k] === true ? '' : params[k] }), {})
}

/**
 * Updates the current location displayed in the browser address bar.
 * DECOUPLE: This function is now heavily coupled with filterSetCall because it expects an object with shape { param: string, page: number, filterIndex: boolean }
 */
export function updateLocation({ filterIndex, ...options }, filterOptions = {}) {
  const vars = queryString.parse(window.location.search)
  const params = filterQueryVariables(vars, options, filterOptions)
  if (typeof filterIndex === 'number') {
    const { page, ...param } = options
    const paramKey = Object.keys(param)[0]
    const paramValue = Object.values(param)[0]?.toString() || undefined
    let valueArray = vars[paramKey] || []

    if (typeof valueArray === 'string') valueArray = [valueArray]
    if (valueArray.length < filterIndex) valueArray.push(paramValue)
    else valueArray[filterIndex] = paramValue
    params[paramKey] = valueArray
    if (filterIndex > 0) params.skills_filter_type = 'all'
  }

  const loc = [
    window.location.pathname,
    // In e.g. 'varname=&anothervar=something' constructs, remove the = in front of varname.
    queryString.stringify(params).replace(/([a-z0-9])=&/i, '$1&'),
  ].join('?')
  history.push(loc)
}

export function resetQueryfilters(filtersToKeep = {}) {
  const params = Object.entries(filtersToKeep)
    .map(param => param.join('='))
    .join('&')
  if (params.length) {
    history.push(
      `${window.location.pathname}?${queryString.stringify(filtersToKeep).replace(/([a-z0-9])=&/i, '$1&')}`,
    )
  }
}

/**
 * Returns the query variables in the window search string as an object.
 */
export const getQueryVariables = () => queryString.parse(window.location.search)

/**
 * Returns the current page number, or 1 if not set.
 */
export const getPageNumber = (pageName = 'page') => Number(get(getQueryVariables(), pageName, 1))

/**
 * Export stringify() so we don't need to import the dependency everywhere.
 */
export function stringify(query) {
  return queryString.stringify(query)
}

/**
 * Returns a new query variable string with the page number changed.
 */
export const getPageQueryString = (n, pageName = 'page', options = {}) => {
  const vars = getQueryVariables()
  return `?${stringify(filterQueryVariables({ ...vars, [pageName]: n }, {}, options))}`
}

/**
 * Efficient check to see if two arrays are the same (same elements, same order).
 */
export const arrayEqual = (arr1, arr2) => {
  if (arr1.length !== arr2.length) return false
  for (let n = 0; n < arr1.length; ++n) {
    if (arr1[n] !== arr2[n]) return false
  }
  return true
}

/**
 * Returns traits (attributes) visible in a table format.
 */
export const getVisibleTraits = tableFormat => {
  const regularTraits = tableFormat.map(n => n.val).filter(n => !isFunction(n) && n)
  const hintedTraits = tableFormat.reduce((acc, n) => (n.valHints ? [...acc, ...n.valHints] : acc), [])
  return [...regularTraits, ...hintedTraits]
}

/**
 * Compares properties on two objects to see if those properties are equal.
 * The default is a shallow equality check  - a flag can be set for deep equality checking properties (good option for checking arrays and objects)
 * @param {Object} objectA - First object to compare to the second
 * @param {Object} objectB - Second object to compare to the first
 * @param {string[]} propertyNames - Collection of the property names that will be compared on objectA and objectB
 * @param {boolean} deepEquality - flag to set deep equality checks, false = shallow equality. True for checking properties that are objects or arrays
 * @returns {boolean} Return true is all properties are equal
 */
export const arePropertiesEqual = (objectA, objectB, propertyNames, deepEquality = false) => {
  let propertyA
  let propertyB
  for (const propertyName of propertyNames) {
    propertyA = get(objectA, propertyName)
    propertyB = get(objectB, propertyName)

    if (deepEquality) {
      if (!isEqual(propertyA, propertyB)) return false
      else continue // eslint-disable-line no-continue
    }

    // Skip null values.
    if (propertyA == null && propertyB == null) {
      // eslint-disable-next-line no-continue
      continue
    }
    // Check if one of the values is null or undefined while the other is not.
    if ((propertyA == null && propertyB != null) || (propertyA != null && propertyB == null)) {
      return false
    }
    // Skip arrays and objects since they are never identical. This is a shallow check only.
    if (propertyA.constructor === Object || propertyB.constructor === Array) {
      // eslint-disable-next-line no-continue
      continue
    }
    if (propertyA !== propertyB) {
      return false
    }
  }
  return true
}

/**
 * Compares properties on objects in two Arrays
 * The default is a shallow equality check  - a flag can be set for deep equality checking properties (good option for checking arrays and objects)
 * @param {Array} collectionA - First collection of objects to compare to the second
 * @param {Array} collectionB - Second collection of objects to compare to the first
 * @param {string[]} propertyNames - Collection of the property names that will be compared on objectA and objectB
 * @param {boolean} deepEquality - flag to set deep equality checks, false (mean shallow equality checking). true check properties that are objects or arrays
 * @returns {boolean} Return true is all properties are equal
 */
export const areObjectCollectionsPropertiesEqual = (
  collectionA,
  collectionB,
  propertyNames,
  deepEquality = false,
) => {
  if (collectionA.length !== collectionB.length) return false

  for (let n = 0; n < collectionA.length; ++n) {
    const isIdentical = arePropertiesEqual(collectionA[n], collectionB[n], propertyNames, deepEquality)
    if (!isIdentical) return isIdentical
  }

  return true
}

/**
 * Return function to check the properties of of 2 objects or 2 collections of objects
 * @name ArePropertiesOnObjectEqual
 * @function
 * @param {Object|Object[]} itemA - First item will be checked against second item. When parent functions compareArrayOfObjects is true this will expect an array of objects
 * @param {Object|Object[]} itemB - Second item will be checked against first item. When parent functions compareArrayOfObjects is true this will expect an array of objects
 * @returns {boolean} Return true is all properties are equal
 */

/**
 * Create a function that compares properties on two objects/tow collections of objects to see if those properties are equal.
 * The default is a shallow equality check  - a flag can be set for deep equality checking properties (good option for checking arrays and objects)
 * @param {string[]} properties - Any array list of properties to check on the objects ['id', first_name']
 * @param {boolean} compareArrayOfObjects - if false the checking function expects objects, if true it expects arrays of objects
 * @param {boolean} deepEquality - flag to set deep equality checks, false (mean shallow equality checking). True check properties that are objects or arrays
 * @returs {ArePropertiesOnObjectEqual} A function that should accept either two objects or two array collections and
 * */
export const makePropertyCheck = (properties, compareArrayOfObjects = false, deepEquality = false) => {
  return (itemA, itemB) => {
    if (compareArrayOfObjects) {
      return areObjectCollectionsPropertiesEqual(itemA, itemB, properties, deepEquality)
    } else {
      return arePropertiesEqual(itemA, itemB, properties, deepEquality)
    }
  }
}
