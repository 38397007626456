// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import { keyBy, map, pull } from 'lodash-es'
import { history } from 'syft-acp-core/history'
import { delay } from 'redux-saga'
import { call, cancel, cancelled, fork, put, select, takeEvery, takeLatest } from 'redux-saga/effects'
import * as types from 'syft-acp-core/actions/action-types'
import { notify, toggleNotificationsGlobally } from 'syft-acp-core/actions/notifications'
import { getNotificationText } from 'syft-acp-core/lib/notifications'
import { store } from 'syft-acp-core/store'
import { convertAlertsToDates, filterAlertsByCutoff } from 'syft-acp-core/store/filters/helpers'
import { takeEveryApiCall } from 'syft-acp-core/sagas/helpers'
import { fetchAlerts as fetchAlertsAction, markAlertsAsNotified } from './actions'
import { fetchAlerts } from './api'

// Wait 1m in between fetching alerts.
const ALERTS_FETCH_DELAY = 60000

export const isNotificationsGloballyEnabledSelector = state => state?.settings?.notificationsGloballyEnabled

export const allowsNotifications = () => isNotificationsGloballyEnabledSelector(store.getState())

export const showNotifications = alerts => {
  // Mark these items as notified.
  if (!allowsNotifications()) return
  Object.keys(alerts).forEach(id => {
    const alert = alerts[id]
    const message = getNotificationText(alert.type, alert)
    if (message) {
      store.dispatch(notify(message.level, { ...message }))
    }
  })
}

export const showCondensedNotification = alertsNumber => {
  if (!allowsNotifications()) return
  store.dispatch(
    notify('info', {
      message: `There are ${alertsNumber} unread notifications. Go to the dashboard to view them.`,
      autoDismiss: 0,
      action: {
        label: 'Dashboard',
        callback: () => history.push('/dashboard'),
      },
    }),
  )
}

export const showNewAlerts = action => {
  if (!action || !action.payload || action.payload.length === 0) {
    return null
  }

  const payload = filterAlertsByCutoff(convertAlertsToDates(action.payload))

  // Reorder the alerts we've received from the server by ID, as per our reducer.
  const alerts = keyBy(payload, 'id')
  const newIDs = map(payload, 'id')
  const previouslyNotifiedIDs = store.getState().alerts.notifiedIDs

  // Remove all IDs that we already have in the store.
  const diff = pull(newIDs, ...previouslyNotifiedIDs)

  // Mark all new IDs as notified.
  store.dispatch(markAlertsAsNotified(diff))

  // If we have more than five notifications, display a "condensed" notification.
  // This is a single notification telling the user to go see the component on dashboard.
  if (diff.length > 5) {
    return showCondensedNotification(diff.length)
  }

  // Display notifications for all new alert IDs.
  return showNotifications(
    keyBy(
      diff.map(id => alerts[id]),
      'id',
    ),
  )
}

// Returns the latest ID so we can query only new items.
const getLatestAlertID = () => store.getState().alerts.latestID

function* fetchAlertsSaga() {
  // Repeatedly ask for alerts updates forever.
  while (true) {
    store.dispatch(fetchAlertsAction(getLatestAlertID()))
    yield call(delay, ALERTS_FETCH_DELAY)
  }
}

export function* startPollingAlertsIfEnabled() {
  yield takeLatest(
    [
      types.REHYDRATION_COMPLETE,
      types.DESKTOP_NOTIFICATIONS_SET,
      types.NOTIFICATIONS_GLOBALLY_TOGGLE,
      types.NOTIFICATIONS_GLOBALLY_ENABLE_IF_UNSET,
    ],
    function* startPollingAlerts() {
      let task
      try {
        const isEnabledFeature = yield select(isNotificationsGloballyEnabledSelector)

        if (isEnabledFeature) {
          task = yield fork(fetchAlertsSaga)
        }
      } finally {
        if (yield cancelled()) {
          if (task) yield cancel(task)
        }
      }
    },
  )
}

export function* updateSettings() {
  yield put(toggleNotificationsGlobally(false))
}

export default function* root() {
  yield takeEveryApiCall('ADMIN_ALERTS_FETCH', fetchAlerts)
  yield takeEvery(types.ADMIN_ALERTS_FETCH_SUCCEEDED, showNewAlerts)
  yield fork(startPollingAlertsIfEnabled)
}
