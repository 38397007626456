// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import React from 'react'

import { TrackingTrigger } from '@indeed/flex-tracking-context'
import StrikeList from 'syft-acp-core/entities/StrikeList'
import PageView from 'syft-acp-core/components/PageView'
import SubNavigationWatchlist from '../SubNavigation'
import { trackingEvents } from './WatchlistStrikes.tracking'

export const WatchlistStrikes = () => (
  <>
    <TrackingTrigger onLoad={{ event: trackingEvents.PAGE.VIEWED }} />
    <SubNavigationWatchlist />
    <PageView title={['Watchlist', 'Worker strikes']} entityPage>
      <StrikeList />
    </PageView>
  </>
)
