import React from 'react'
import { Button, ButtonGroup } from 'syft-acp-atoms/Button'
import { Link } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { useTrackingTrigger } from '@indeed/flex-tracking-context'

import * as appealsActions from 'syft-acp-core/store/appeals/actions'
import RecordControls from 'syft-acp-core/components/RecordSection/RecordControls'
import LoadingIndicator from 'syft-acp-util/components/LoadingIndicator'

import { trackingEvents } from '../AppealDetail.tracking'
import { AppealDetailsControlsProps as Props } from './AppealDetailsControls.types'

export const storeConnector = connect(
  ({ appeals: { isSavingData, isLoadingData } }) => ({
    isSavingData,
    isLoadingData,
  }),
  dispatch => ({
    actions: bindActionCreators(appealsActions, dispatch),
  }),
)

const defaultData = {
  notes: '',
  outcome: null,
}

const AppealDetailsControls = ({
  actions,
  data = defaultData,
  id = 0,
  isSavingData,
  isLoadingData,
  isPristine,
}: Props) => {
  const triggerEvent = useTrackingTrigger()
  const { notes, outcome, strike, appeal_reason, appeal_text, salesforce_case_url } = data
  const isClosed = isPristine && !!outcome

  const saveAction = () => {
    triggerEvent(trackingEvents.SAVE.CLICKED, { appeal_id: id })
    if (id) actions.updateAppeal(id, { notes, outcome })
    else
      actions.createAppeal({
        strike_id: strike?.id || 0,
        appeal_reason_id: appeal_reason?.id || 0,
        appeal_text: appeal_text || '',
        notes: notes || '',
        salesforce_case_url: salesforce_case_url || '',
      })
  }

  const onClickBack = () => {
    triggerEvent(trackingEvents.BACK.CLICKED, { appeal_id: id })
  }

  return (
    <RecordControls>
      <div className="spacer">
        <ButtonGroup>
          <Link to={`/conduct/appeals/list`}>
            <Button onClick={onClickBack}>Back to appeals</Button>
          </Link>
        </ButtonGroup>
      </div>
      <div className="right">
        <LoadingIndicator loadingState={isSavingData || isLoadingData} />{' '}
        <ButtonGroup>
          {!isClosed && (
            <Button kind="success" onClick={saveAction} disabled={isSavingData || isPristine}>
              Save
            </Button>
          )}
        </ButtonGroup>
      </div>
    </RecordControls>
  )
}

export default storeConnector(AppealDetailsControls)
