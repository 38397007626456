import { SaveBulkUploadParams } from './types'
import * as types from './action-types'

export const saveBulkUpload = ({
  employerId,
  spreadsheetUrl,
  commitConfirmationHash,
  isConfirmation,
}: SaveBulkUploadParams) => ({
  type: types.AGENCY_COVERAGE_BULK_UPLOAD_SAVE_BEGIN,
  employerId,
  spreadsheetUrl,
  commitConfirmationHash,
  isConfirmation,
})

export const clearBulkUpload = () => ({ type: types.AGENCY_COVERAGE_BULK_UPLOAD_CLEAR })
