import React from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import classnames from 'classnames'

import * as filtersActions from 'syft-acp-core/store/filters/actions'
import PaginationButtons from './PaginationButtons'

import './AcpPagination.css'

// Default objects to zero if they're undefined or null.
const zeroDefault = obj => (obj == null ? 0 : obj)

/**
 * Displays the result count at the top of an overview list.
 */
class AcpPagination extends React.PureComponent {
  static propTypes = {
    // 'data' contains the pagination metadata from the last request in camelCase format.
    data: PropTypes.shape({
      totalPages: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      page: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    }).isRequired,
    // Which query string key to modify when changing pages.
    paginationKey: PropTypes.string,
    // Whether to disable the automatic scroll back to top on click.
    disableAutoScroll: PropTypes.bool,
    // The first column is not visible
    withInfoColumn: PropTypes.bool,
    // Whether the pagination links are displayed inside the wrapper or not.
    // On overview list pages they're displayed outside; for detail page components they're inside.
    insideWrapper: PropTypes.bool,
    includeEmpty: PropTypes.bool,
  }

  static defaultProps = {
    data: {},
    insideWrapper: false,
    disableAutoScroll: false,
    withInfoColumn: false,
    paginationKey: 'page',
    pagination: {},
  }

  onPageChange = (page, ev) => {
    const { paginationKey, disableAutoScroll } = this.props
    ev.preventDefault()
    this.props.actions.setPage({
      page,
      disableAutoScroll,
      pageKey: paginationKey,
      filterOptions: { includeEmpty: true },
    })
  }

  render() {
    const { insideWrapper, data, withInfoColumn } = this.props
    return (
      <div className={classnames('acp-pagination', { 'inside-wrapper': insideWrapper, withInfoColumn })}>
        <PaginationButtons
          ellipsis
          totalPages={zeroDefault(data.totalPages)}
          activePage={zeroDefault(data.page)}
          onPageChange={this.onPageChange}
          prevNextOnly={data.totalPages === -1}
          includeEmpty={this.props.includeEmpty}
        />
      </div>
    )
  }
}

export default connect(null, dispatch => ({
  actions: bindActionCreators(filtersActions, dispatch),
}))(AcpPagination)
