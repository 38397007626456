// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import * as types from './action-types'

/**
 * @param id {Number} ID of the employer
 */
export function fetchEmployerStats(id) {
  return { type: types.STATS_EMPLOYER_FETCH_BEGIN, id }
}

export function fetchStatsWorkers(payload) {
  return { type: types.STATS_WORKER_FETCH_BEGIN, payload }
}
