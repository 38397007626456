import React from 'react'
import PropTypes from 'prop-types'

import FilterTimezones from 'syft-acp-util/components/FilterForm/FilterTimezones'

const DataTimezoneDropdown = ({ value, editable, onChange, enabled }) => (
  <FilterTimezones value={value} enabled={enabled && editable} onChange={onChange} />
)

DataTimezoneDropdown.propTypes = {
  value: PropTypes.string.isRequired,
  editable: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  enabled: PropTypes.bool.isRequired,
}

export default DataTimezoneDropdown
