import * as React from 'karet'
import * as U from 'karet.util'
import { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import classNames from 'classnames'
import { shortenSubIndustry } from 'syft-acp-core/lib/industries'
import { selectRole$ } from 'syft-acp-core/reducers/industries'
import { fetchIndustries } from 'syft-acp-core/actions/industries'

import { cellPropTypes, cellDefaultProps, PropTypesAtom } from '../dataProps'
import { colPlaceholderFactory } from '../../../placeholders/factory'

/** Returns the subindustry, possibly shortened or wrapped in parentheses. */
const getSubindustry = (subindustry, onlySubindustry, shorten) => {
  const sub = `${shorten ? shortenSubIndustry(subindustry) : subindustry}`
  return onlySubindustry ? sub : `(${sub})`
}

export const AcpColRoleSkill = colPlaceholderFactory('AcpCellRoleSkill')

class AcpCellRoleSkill extends PureComponent {
  static propTypes = {
    ...cellPropTypes,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypesAtom]),
    options: PropTypes.shape({
      onlyRoleTitle: PropTypes.bool,
      onlySubindustry: PropTypes.bool,
      shortenIndustry: PropTypes.bool,
    }),
  }

  static defaultProps = {
    ...cellDefaultProps,
  }

  render() {
    const { value, getRole, options } = this.props
    const { onlyRoleTitle, onlySubindustry, shortenIndustry = true } = options

    // Renders a string representation of this role.
    const plainRole = U.lift(liftValue => {
      if (!liftValue) return '–'
      const role = getRole({ roleID: liftValue })
      if (!role) return '–'

      const roleTitle = `${role.title}`
      const roleIndustry = `${getSubindustry(role.subindustry, onlySubindustry, shortenIndustry)}`

      const textValue = []
      if (!onlySubindustry) textValue.push(roleTitle)
      if (!onlyRoleTitle) textValue.push(roleIndustry)

      return textValue.join(' ').trim()
    })

    return <div className={classNames('acp-role-skill')}>{plainRole(value)}</div>
  }
}

export default connect(
  state => ({
    getRole: selectRole$(state.industries),
    isLoadingData: state.industries.isLoadingData,
  }),
  dispatch => ({ actions: bindActionCreators({ fetchIndustries }, dispatch) })
)(AcpCellRoleSkill)
