// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import React from 'react'

import ShiftAgencyList from 'syft-acp-core/entities2/ShiftAgencyList'
import PageView from 'syft-acp-core/components/PageView'
import CascadeFullJobModal from 'syft-acp-core/components/Modal/CascadeFullJobModal'

const modalName = 'cascadeFullModal'

export const AgencyShifts = () => (
  <PageView title={['Shifts', 'Agency shifts']} entityPage>
    <ShiftAgencyList />
    <CascadeFullJobModal name={modalName} />
  </PageView>
)
