import { set, get, cloneDeep } from 'lodash-es'
import { entitySetName } from 'syft-acp-core/reducers/generators/utils'

export const bookingUpdateState = (state, action) => {
  const { shiftID, id, attr, val } = action.payload

  const newState = cloneDeep(state.entityMap[shiftID])
  const newAgencyShifts = newState.agency_shift_bookings.find(item => item.id === id)
  // TODO: The whole underlying logic for (agency and Syft) shifts
  // and bookings should be redone at some point.
  set(newAgencyShifts, attr, val)

  return {
    ...state,
    entityMap: {
      ...state.entityMap,
      [shiftID]: newState,
    },
    hasChanges: true,
  }
}

export const failedBookingState = (state, action) => {
  const {
    payload: {
      message,
      response: { status },
      body,
    },
  } = action
  const error_description = get(body, 'error_description') || get(body, 'debug.message')
  const setKey = entitySetName({ shiftID: action.payload.options.shiftID })

  return {
    ...state,
    isSavingData: false,
    lastMessage: String(message),
    lastStatus: String(status),
    lastBody: {
      error_description: String(error_description),
    },
    entitySets: {
      ...state.entitySets,
      [setKey]: {
        ...state.entitySets[setKey],
        isLoadingData: false,
      },
    },
  }
}

export const successSaveBookingState = state => ({
  ...state,
  isSavingData: false,
  isLoadingData: false,
  hasChanges: false,
})
