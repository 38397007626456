export const FETCH_ADMIN_JOB_BEGIN = 'syftacp/FETCH_ADMIN_JOB_BEGIN' as const
export const FETCH_ADMIN_JOB_FAILED = 'syftacp/FETCH_ADMIN_JOB_FAILED' as const
export const FETCH_ADMIN_JOB_SUCCEEDED = 'syftacp/FETCH_ADMIN_JOB_SUCCEEDED' as const

export const JOB_RATE_WORKER_BEGIN = 'syftacp/JOB_RATE_WORKER_BEGIN' as const
export const JOB_RATE_WORKER_FAILED = 'syftacp/JOB_RATE_WORKER_FAILED' as const
export const JOB_RATE_WORKER_SUCCEEDED = 'syftacp/JOB_RATE_WORKER_SUCCEEDED' as const

export const JOB_UPDATE_BEGIN = 'syftacp/JOB_UPDATE_BEGIN' as const
export const JOB_UPDATE_FAILED = 'syftacp/JOB_UPDATE_FAILED' as const
export const JOB_UPDATE_SUCCEEDED = 'syftacp/JOB_UPDATE_SUCCEEDED' as const

export const JOB_STOP_OFFERS_UPDATE_BY_SHIFT = 'syftacp/JOB_STOP_OFFERS_UPDATE_BY_SHIFT' as const

export const RATING_DELETE_BEGIN = 'syftacp/RATING_DELETE_BEGIN' as const
export const RATING_DELETE_FAILED = 'syftacp/RATING_DELETE_FAILED' as const
export const RATING_DELETE_SUCCEEDED = 'syftacp/RATING_DELETE_SUCCEEDED' as const
