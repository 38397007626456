// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import React from 'react'

import AlertsComponent from 'syft-acp-core/components/Alerts'
import PageView from 'syft-acp-core/components/PageView'
import SubNavigationWatchlist from './SubNavigation'

export const WatchlistAlerts = () => (
  <>
    <SubNavigationWatchlist />
    <PageView title={['Watchlist', 'Alerts for workers, employers and listings']} entityPage>
      <AlertsComponent />
    </PageView>
  </>
)
