import { useCallback, useMemo } from 'react'
import { useIntl } from 'react-intl'
import { get, identity, pickBy } from 'lodash-es'
import currentIntl from 'syft-acp-util/intl'
import useDeepEqualMemoizedDependencies from './useDeepEqualMemoizedDependencies'

export const intlFormatDateTime =
  (intl = currentIntl) =>
  /**
   * Format the date using Intl.DateTimeFormat
   *
   * @param {Date} date - date instance to format
   * @param {Object} options - options
   * @param {Object} options.format - Intl.DateTimeFormat format, e.g. year: '2-digit', month: 'long', day: 'numeric'
   * @param {Boolean} options.toParts - returns array of date time parts
   * @param {Array} options.excludeParts - removes parts and surrounding literals from the formatted date.
   * Useful when you want to keep the order of formatted Date for specific format combination but want to hide some its part
   * @returns {string|*|Intl.DateTimeFormatPart[]}
   */
  (date, { format = {}, toParts, excludeParts } = {}) => {
    let fixedFormat = pickBy(format, identity)
    // for UK browser locale, hour12 means h11 hour cycle (0 - 11)
    // when hour12 and hourCycle set together, hour12 overrides hourCycle value
    // we need to show hours in 12 hour cycle for UK
    if (
      fixedFormat &&
      !fixedFormat.hourCycle &&
      (fixedFormat.hour || fixedFormat.hour12 || fixedFormat.minute) &&
      intl.locale === 'en-GB'
    ) {
      const { hour12, ...restFormat } = fixedFormat
      fixedFormat = {
        ...restFormat,
        hourCycle: 'h12',
      }
    }

    if (toParts) {
      return intl.formatDateToParts(date, fixedFormat)
    }

    return !excludeParts || !excludeParts.length
      ? intl.formatDate(date, fixedFormat)
      : intl
          .formatDateToParts(date, fixedFormat)
          .filter(part => !excludeParts.filter(Boolean).includes(part.type))
          .filter(
            // cleaning from surrounding literals
            (part, key, parts) =>
              part.type !== 'literal' ||
              (get(parts, key + 1, {}).type !== 'literal' &&
                get(parts, key - 1, {}).type !== 'literal' &&
                key < parts.length - 1 &&
                key > 0),
          )
          .map(part => part.value)
          .join('')
  }
/**
 * Hook to format the date based on intl context.
 * Acts as controlled hook when opts passed (date). Always returns uncontrolled formatDate method as 2nd item of returned array
 *
 * @param {string}  [date] - A string param.
 * @param {Object}  [options] - An optional param (Google Closure syntax)
 * @returns {[Object|string, *]}
 */
const useFormatDateTime = (date, options) => {
  const intl = useIntl()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const cleanedOptions = useMemo(() => pickBy(options, identity), useDeepEqualMemoizedDependencies([options]))
  const formatDate = useCallback((...args) => intlFormatDateTime(intl)(...args), [intl])
  const formatted = useMemo(() => {
    if (date) return formatDate(date, cleanedOptions)
    return ''
  }, [date, cleanedOptions, formatDate])

  return [formatted, formatDate]
}

export default useFormatDateTime
