import { FederatedState, Query } from './types'
import { without } from 'lodash-es'
import US_STATES_MAP from 'syft-acp-util/fixtures/us_states.json'

export const federatedStatesFromCodes = (codes: string[]): FederatedState[] => {
  return codes.reduce((acc: FederatedState[], code: string) => {
    const label = US_STATES_MAP[code as keyof typeof US_STATES_MAP]
    if (label) {
      acc.push({ value: code, label })
    }
    return acc
  }, [])
}

export const allStatesList = () => {
  const statesKeys = Object.keys(US_STATES_MAP)
  return federatedStatesFromCodes(statesKeys)
}

export const allStatesListWithAll = () => {
  let states = allStatesList()
  states.unshift({ value: 'all', label: 'All' })

  return states
}

export const getInitialValues = (query: Query): FederatedState[] => {
  const input = query?.federated_states

  if (!input) {
    return []
  }
  return Array.isArray(input) ? federatedStatesFromCodes(input) : federatedStatesFromCodes([input])
}

export const handleSelectAll = (prev: FederatedState[], next: FederatedState[]): FederatedState[] => {
  const prevValues = prev.map(state => state.value)
  const nextValues = next.map(state => state.value)

  const nextContainsAllStates = Object.keys(US_STATES_MAP).every(val => nextValues.includes(val as string))
  const nextValuesHasAll = nextValues.includes('all')
  const prevValuesHasAll = prevValues.includes('all')

  // Cases
  // 1. next contains 'all', prev does not contain 'all': we select all states and 'all'
  if (nextValuesHasAll && !prevValuesHasAll) {
    return allStatesListWithAll()
  }
  // 2. next contains 'all', prev contains 'all', next does not contain all states: we deselect 'all'
  if (nextValuesHasAll && prevValuesHasAll && !nextContainsAllStates) {
    return federatedStatesFromCodes(without(nextValues, 'all') as string[])
  }
  // 3. next does not contain 'all', prev contains 'all': we deselect everything
  if (!nextValuesHasAll && prevValuesHasAll) {
    return []
  }
  // 4. next does not contain 'all', prev does not contain 'all', next contains all states: we select 'all'
  if (!nextValuesHasAll && !prevValuesHasAll && nextContainsAllStates) {
    return allStatesListWithAll()
  }
  // Other cases, leave 'next' as is.
  return next
}
