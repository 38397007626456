import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { entityList$ } from 'syft-acp-core/reducers/generators/entities'
import { fetchEmployerFilteredVenuesList } from 'syft-acp-core/actions/employer-venues-filtered'

import FilterHierarchyMultiselect from './FilterHierarchyMultiselect'

const FilterVenuesMultiselect = ({ employerId, venues, onChange, actions }) => {
  useEffect(() => {
    actions.fetchVenues(employerId)
  }, [employerId, actions])
  const venueOptions = venues.map(venue => ({ value: venue.id, label: venue.name }))
  const venuesOptionsWithSelectAll =
    venues.length > 1 ? [{ value: null, label: 'Select all venues' }, ...venueOptions] : venueOptions

  return (
    <FilterHierarchyMultiselect
      values={[]}
      onChange={onChange}
      options={employerId ? venuesOptionsWithSelectAll : []}
      withSearch
    />
  )
}

export default connect(
  (state, { employerId }) => ({
    venues: entityList$(state.employerVenuesFiltered, { employerID: employerId }),
  }),
  dispatch => ({
    actions: bindActionCreators({ fetchVenues: fetchEmployerFilteredVenuesList }, dispatch),
  }),
)(FilterVenuesMultiselect)
