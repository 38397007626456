import { apiRequest, handleCall } from 'syft-acp-core/api/call'
import * as actions from './actions'
import type { ExternalWorkLocationAction } from './actions'

const externalWorkLocationBase = ({ payload: reqArgs }: ExternalWorkLocationAction, method = 'GET') =>
  new Promise((resolve, reject) => {
    const req = apiRequest({ path: `/admin/work_locations/external_work_locations`, reqArgs, method })
    handleCall(req, resolve, reject, true)
  })

export const fetchExternalWorkLocation = (action: ReturnType<typeof actions.fetchExternalWorkLocation>) =>
  externalWorkLocationBase(action)
export const updateExternalWorkLocation = (action: ReturnType<typeof actions.updateExternalWorkLocation>) =>
  externalWorkLocationBase(action, 'PATCH')
export const deleteExternalWorkLocation = (action: ReturnType<typeof actions.deleteExternalWorkLocation>) =>
  externalWorkLocationBase(action, 'DELETE')
export const createExternalWorkLocation = (action: ReturnType<typeof actions.createExternalWorkLocation>) =>
  externalWorkLocationBase(action, 'POST')
