import React from 'react'
import { Button, ButtonGroup } from 'syft-acp-atoms/Button'
import { Link } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { useTrackingTrigger } from '@indeed/flex-tracking-context'

import * as timeOffRequestsActions from 'syft-acp-core/store/timeOffRequests/actions'
import RecordControls from 'syft-acp-core/components/RecordSection/RecordControls'
import LoadingIndicator from 'syft-acp-util/components/LoadingIndicator'

import { trackingEvents } from '../TimeOffRequestDetail.tracking'
import { TimeOffRequestDetailControlsProps as Props } from './TimeOffRequestDetailControls.types'

export const storeConnector = connect(
  ({ timeOffRequests: { isSavingData, isLoadingData } }) => ({
    isSavingData,
    isLoadingData,
  }),
  dispatch => ({
    actions: bindActionCreators(timeOffRequestsActions, dispatch),
  }),
)

const TimeOffRequestDetailControls = ({
  data,
  actions,
  id,
  isSavingData,
  isLoadingData,
  isPristine,
}: Props) => {
  const triggerEvent = useTrackingTrigger()
  const disabledControls = data.status !== 'requested' || !!isLoadingData

  const approveAction = () => {
    triggerEvent(trackingEvents.APPROVE_BUTTON.CLICKED)
    actions.approveTimeOffRequest(id)
  }

  const rejectAction = () => {
    triggerEvent(trackingEvents.REJECT_BUTTON.CLICKED)
    actions.rejectTimeOffRequest(id)
  }

  const saveAction = () => {
    triggerEvent(trackingEvents.SAVE_BUTTON.CLICKED)
    actions.updateTimeOffRequest(id, { note: data.note || '' })
  }

  return (
    <RecordControls>
      <div className="spacer">
        <ButtonGroup>
          <Link to={`/admin/lta/time-off-requests`}>
            <Button>Back to requests</Button>
          </Link>
        </ButtonGroup>
      </div>
      <div className="right">
        <LoadingIndicator loadingState={isSavingData || isLoadingData} />{' '}
        <ButtonGroup>
          <Button kind="success" onClick={saveAction} disabled={disabledControls || isPristine}>
            Save
          </Button>
        </ButtonGroup>{' '}
        <ButtonGroup>
          <Button kind="success" onClick={approveAction} disabled={disabledControls}>
            Approve
          </Button>
          <Button onClick={rejectAction} disabled={disabledControls}>
            Reject
          </Button>
        </ButtonGroup>
      </div>
    </RecordControls>
  )
}

export default storeConnector(TimeOffRequestDetailControls)
