import deepEqual from 'react-fast-compare'

import { ShiftFulfilmentModalState as State, ShiftFulfilmentModalState } from './ShiftFulfilmentModal.types'
export const getOfferTypeOptions = (state: State, onChangeCheckBox: (value: string) => void) => {
  const offerToFlex = state?.offer_to_flex
  return [
    {
      label: 'All flexers',
      callback: () => onChangeCheckBox('all'),
      checked: offerToFlex === 'all',
    },
    {
      label: `Employer's trusted Network`,
      callback: () => onChangeCheckBox('network'),
      checked: offerToFlex === 'network',
    },
  ]
}

export const getKeysOfChangedValues = (a?: Record<string, any>, b?: Record<string, any>) => {
  if (!a || !b) return []
  return Object.keys(a).filter(key => !deepEqual(a[key], b[key]))
}

export const getState = (shift: ShiftFulfilmentModalState, shiftID?: number) => {
  return {
    id: shiftID,
    offer_rate: shift.offer_rate,
    fulfilment_note: shift.fulfilment_note,
    start_time: shift.start_time,
    end_time: shift.end_time,
    offer_to_flex: shift?.offer_to_flex,
    fulfilment_reviewed: shift.fulfilment_reviewed,
    stop_offers_for_worker_type: shift.stop_offers_for_worker_type,
    job: {
      pay_rate: shift.job?.pay_rate,
      bookable_individually: shift.job?.bookable_individually,
      skill_ids: shift.job?.skill_ids,
    },
  }
}

export const getOfferType = (
  employerOffers: Record<string, any>,
  offerTo: string | null | undefined,
  platformId?: number,
) => {
  if (platformId === 1 && offerTo) {
    return employerOffers[offerTo] || 'Unknown'
  }
  if (platformId === 1 && !offerTo) {
    return 'Unknown'
  }
  if (platformId !== 1 && offerTo) {
    return employerOffers[offerTo] || 'Flex+'
  }
  return 'Flex+'
}

export const convertShiftIdArrayToArrayOfObjects = (essencialSkills?: number[]) =>
  Array.isArray(essencialSkills)
    ? essencialSkills.map(skill => ({
        id: skill,
      }))
    : []
