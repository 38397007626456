// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Grid, Col, Row } from 'react-bootstrap'
import { sortBy, get } from 'lodash-es'

import { formatFullName } from 'syft-acp-util/formatting'
import entityDetail from 'syft-acp-util/entityDetail'
import Table from 'syft-acp-util/components/Table'
import * as networksActions from 'syft-acp-core/actions/employer-networks'
import * as rolesActions from 'syft-acp-core/store/roles/actions'
import DataRating from 'syft-acp-core/components/EditableTable/DataRating'
import EntityDetailItem from 'syft-acp-core/components/EntityDetail/EntityDetailItem'
import RecordHeader from 'syft-acp-core/components/RecordSection/RecordHeader'
import RecordSection from 'syft-acp-core/components/RecordSection'
import RecordSegment from 'syft-acp-core/components/RecordSection/RecordSegment'
import SiteComponent from 'syft-acp-core/components/SiteComponent'
import * as structureTypes from 'syft-acp-core/components/EditableTable'

import EmployerNetworkDetailControls from './EmployerNetworkDetailControls'
import './EmployerNetworkDetail.css'

const dataStructure = [
  ['id', { header: 'Worker ID' }],
  ['name', { header: 'Name', calc: e => formatFullName(e) }],
  ['telephone_number', { header: 'Tel.', type: structureTypes.TYPE_PHONE_NUMBER }],
  ['network_shifts_count', { header: 'Shifts worked in the network' }],
  ['total_shifts_count', { header: 'Total shifts worked' }],
  [
    'network_shifts_hours',
    {
      header: 'Hours worked in the network',
      calc: e => parseInt(e.network_shifts_hours, 10),
    },
  ],
  [
    'total_shifts_hours',
    {
      header: 'Total hours worked',
      calc: e => parseInt(e.total_shifts_hours, 10),
    },
  ],
]

const Roles = ({ roles }) => (
  <EntityDetailItem className="ratings roles">
    <RecordSegment noPadding>
      <RecordHeader main>Roles</RecordHeader>
    </RecordSegment>
    <RecordSegment noPadding withTable>
      <Table noRightBorder noRowBorders className="editable-table">
        <tbody>
          {sortBy(roles, r => get(r, 'role.title') || get(r, 'title')).map(role => (
            <tr key={String(role.role_id)}>
              <th>{get(role, 'role.title') || get(role, 'title')}</th>
              <td className="field middle">
                <DataRating amount={role.average_rating != null ? Number(role.average_rating) : null} />
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </RecordSegment>
  </EntityDetailItem>
)

Roles.propTypes = {
  roles: PropTypes.arrayOf(PropTypes.object).isRequired,
}

class EmployerNetworkDetail extends Component {
  static propTypes = {
    id: PropTypes.number.isRequired,
    data: PropTypes.shape({
      id: PropTypes.number.isRequired,
      first_name: PropTypes.string.isRequired,
      last_name: PropTypes.string.isRequired,
      telephone_number: PropTypes.string.isRequired,
      network_shifts_count: PropTypes.number.isRequired,
      total_shifts_count: PropTypes.number.isRequired,
      network_shifts_hours: PropTypes.number.isRequired,
      total_shifts_hours: PropTypes.number.isRequired,
      worker_roles: PropTypes.array,
    }),
    employerID: PropTypes.number.isRequired,
    isPristine: PropTypes.bool.isRequired,
    actions: PropTypes.objectOf(PropTypes.func).isRequired,
  }

  static defaultProps = {
    data: null,
  }

  componentDidMount() {
    this.props.actions.listRoles()
    this.props.actions.fetchEmployerNetwork(this.props.employerID, this.props.id)
  }

  render() {
    const { data } = this.props
    const workerRoles = (data.worker_roles || []).map(r =>
      Object.assign({}, r, {
        role: get(this.props, `roles[${r.role_id}]`, {}),
      })
    )
    return (
      <SiteComponent>
        <Grid>
          <Row>
            <Col md={12}>
              <EmployerNetworkDetailControls
                data={this.props.data}
                employerID={this.props.employerID}
                isPristine={this.props.isPristine}
              />
            </Col>
          </Row>
          <Row>
            <Col md={8}>
              <RecordSection data={this.props.data} structure={dataStructure} showName />
            </Col>
            <Col md={4}>
              <Roles roles={workerRoles} />
            </Col>
          </Row>
        </Grid>
      </SiteComponent>
    )
  }
}

export default connect(
  state => ({
    roles: state.roles.entityMap,
  }),
  dispatch => ({
    actions: bindActionCreators(
      {
        ...rolesActions,
        ...networksActions,
      },
      dispatch
    ),
  })
)(entityDetail('employerNetworks', EmployerNetworkDetail))
