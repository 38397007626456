// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import { findIndex, find, get, cloneDeep, keyBy } from 'lodash-es'

import entityReducer from 'syft-acp-core/reducers/generators/entityReducer'
import * as entityTypes from 'syft-acp-core/reducers/generators/entities'

import { ShiftsState, ShiftEntity } from './types'
import { ShiftBookingEntity } from '../shift-bookings/types'
import {
  UpdateShiftBookingsDisplayedAction,
  ClearShiftBookingsAction,
  ConfirmBulkShiftBookingsSucceededAction,
  FetchShiftBookingFailedAction,
  ConfirmShiftBookingSucceededAction,
  HighlightShiftBookingSucceededAction,
} from '../shift-bookings/actions'
import * as types from '../action-types'

export const setSavingData = (state: ShiftsState) => ({
  ...state,
  isSavingData: true,
})

export const shiftBookingGroupsToEntityMap = (
  shiftBookings: UpdateShiftBookingsDisplayedAction['payload'],
  state: ShiftsState
) =>
  shiftBookings.reduce((accum, shiftBookingItem) => {
    const { shift_id, bookings } = shiftBookingItem

    return {
      ...accum,
      [shift_id]: {
        ...state.entityMap[shift_id],
        shift_bookings: bookings,
      },
    }
  }, {})

export const bookingsFetchSucceed = (state: ShiftsState, action: UpdateShiftBookingsDisplayedAction) => ({
  ...state,
  entityMap: {
    ...state.entityMap,
    ...shiftBookingGroupsToEntityMap(action.payload, state),
  },
  lastMessage: '',
  lastStatus: null,
  lastBody: null,
  isLoadingData: false,
})

const removeShiftBookings = (shift: ShiftEntity) => (shift ? { [shift.id]: { ...shift, shift_bookings: [] } } : {})

export const bookingsClear = (state: ShiftsState, action: ClearShiftBookingsAction) => ({
  ...state,
  entityMap: {
    ...state.entityMap,
    ...removeShiftBookings(state.entityMap[action.id]),
  },
})

export const bookingHighlightChange = (state: ShiftsState, action: HighlightShiftBookingSucceededAction) => {
  // For some reason, the API sends us the OLD highlight in the response, not the new highlight.
  // So fix the color here based on what the request asked for.
  const { highlight } = action.request
  const rehighlightedPayload = action.payload.map(item => ({
    ...item,
    highlight,
  }))

  // TODO: this hack is in place to allow the entire entityMap to be cleanly updated
  // when replacing shift bookings. Without this, components will have their props
  // silently modified rather than properly changed (and nextProps can't catch this change).
  // Deep cloning is slow, but it only happens when we're updating the highlight of a
  // shift booking. The current reducer structure seems to necessitate this.
  const newEntityMap = cloneDeep(state.entityMap)

  Object.keys(newEntityMap).forEach(shiftID =>
    rehighlightedPayload.forEach(payloadBooking => {
      const existingBookings: ShiftBookingEntity[] = newEntityMap[shiftID].shift_bookings
      const index = findIndex(existingBookings, b => b.shift_booking_id === payloadBooking.id)

      if (index > -1) {
        existingBookings[index] = {
          ...existingBookings[index],
          highlight: payloadBooking.highlight,
        }
      }
    })
  )

  return {
    ...state,
    isSavingData: false,
    entityMap: newEntityMap,
  }
}

export const confirmBulkShiftsBookingsSuccess = (
  state: ShiftsState,
  action: ConfirmBulkShiftBookingsSucceededAction
) => {
  const { entityMap } = state
  const { payload } = action
  const entityMapValues = payload
    ? Object.values(entityMap)
        .filter(item => !!payload.find(booking => booking.job_id === item.job_id))
        .map(item => ({
          ...item,
          shift_bookings: item.shift_bookings.map(booking => ({ ...booking, confirmed: true })),
        }))
    : []
  const object = keyBy(entityMapValues, 'id')
  return {
    ...state,
    entityMap: {
      ...entityMap,
      ...object,
    },
    isSavingData: false,
  }
}

export default entityReducer(
  'shifts',
  {
    [entityTypes.ENTITIES_FETCH_BEGIN]: types.SHIFTS_FETCH_BEGIN,
    [entityTypes.ENTITIES_FETCH_SUCCEEDED]: types.SHIFTS_FETCH_SUCCEEDED,
    [entityTypes.ENTITIES_FETCH_FAILED]: types.SHIFTS_FETCH_FAILED,
    [types.SHIFT_BOOKINGS_FETCH_BEGIN]: (state: ShiftsState) => {
      return {
        ...state,
        lastMessage: '',
        lastStatus: null,
        lastBody: null,
        isLoadingData: true,
      }
    },
    [types.SHIFT_BOOKINGS_FETCH_FAILED]: (state: ShiftsState, action: FetchShiftBookingFailedAction) => {
      return {
        ...state,
        lastMessage: action.message || action.payload.message,
        lastStatus: action.payload.response ? action.payload.response.status : String(action.payload.response),
        lastBody: null,
        isLoadingData: false,
      }
    },
    [types.SHIFT_BOOKINGS_UPDATE]: bookingsFetchSucceed,
    [types.SHIFT_BOOKINGS_CLEAR]: bookingsClear,
    [types.CONFIRM_SHIFT_BOOKING_BEGIN]: setSavingData,
    [types.CONFIRM_SHIFT_BOOKING_FAILED]: setSavingData,
    [types.CONFIRM_SHIFT_BOOKING_SUCCEEDED]: (state: ShiftsState, action: ConfirmShiftBookingSucceededAction) => {
      // Note: return immediately if there is no request object.
      // This is the case if we ran an API call to confirm a shift booking manually,
      // without going through the regular saving mechanism.
      if (!action.request) return state

      const { entityMap } = state
      const shift = find(entityMap, s => s.job_id === action.request.jobID)

      // TODO: if we can't find the shift, something has gone badly wrong...
      // We just confirmed it, so the shift should always be there.
      if (!shift) {
        return {
          ...state,
          isSavingData: false,
        }
      }

      action.request.shiftIDs.forEach(confirmShiftID => {
        const n = findIndex(shift.shift_bookings, b => {
          // TODO: investigate why we have ShiftBooking.id here when it shouldn't exist
          // @ts-ignore
          if (b.id !== confirmShiftID) return false
          return get(b, 'worker.id') === action.request.workerID
        })

        // Same comment as above. Should always be here.
        if (n === -1) return false

        // Set shift to confirmed.
        shift.shift_bookings[n].confirmed = true
        return true
      })

      return {
        ...state,
        entityMap: {
          ...entityMap,
          [shift.id]: {
            ...shift,
          },
        },
        isSavingData: false,
      }
    },
    [types.CONFIRM_BULK_SHIFTS_BOOKING_BEGIN]: setSavingData,
    [types.CONFIRM_BULK_SHIFTS_BOOKING_FAILED]: setSavingData,
    [types.CONFIRM_BULK_SHIFTS_BOOKING_SUCCEEDED]: confirmBulkShiftsBookingsSuccess,
    [types.HIGHLIGHT_SHIFT_BOOKING_BEGIN]: setSavingData,
    [types.HIGHLIGHT_SHIFT_BOOKING_FAILED]: setSavingData,
    [types.HIGHLIGHT_SHIFT_BOOKING_SUCCEEDED]: bookingHighlightChange,
  },
  {
    // When we reach over 250 cached items in the store,
    // reduce the number of items to the 125 latest.
    itemLimit: [250, 125],
  }
)
