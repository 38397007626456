import { fork, put, select, takeLatest } from 'redux-saga/effects'
import { notificationCall } from 'syft-acp-core/sagas/calls'
import { takeLatestApiCall } from 'syft-acp-core/sagas/helpers'
import { EMPLOYER_VENUE_SAVE_SUCCEEDED } from 'syft-acp-core/actions/action-types'
import { getEmployerVenue$ } from 'syft-acp-core/selectors/employerVenues'
import externalWorkLocationsTypes from './action-types'
import * as api from './api'

function* updateWorkLocation() {
  yield takeLatest(
    [
      externalWorkLocationsTypes.ENTITY_CREATE_SUCCEEDED,
      externalWorkLocationsTypes.ENTITY_DELETE_SUCCEEDED,
      externalWorkLocationsTypes.ENTITY_UPDATE_SUCCEEDED,
    ],
    function* ({
      type,
      payload: { external_work_location: externalWorkLocationResponse },
      request: {
        payload: { work_location_id },
        employerID,
      },
    }: any) {
      const { external_work_location, ...venue } = (yield select(state =>
        // for some reasons TS things thank getRole$ requires only 1 argument and not 2
        // @ts-ignore
        getEmployerVenue$(state, work_location_id)
      )) as Record<string, any>

      yield put({
        type: EMPLOYER_VENUE_SAVE_SUCCEEDED,
        payload: {
          ...venue,
          ...(!type.includes('DELETE')
            ? {
                external_work_location: {
                  ...external_work_location,
                  ...externalWorkLocationResponse,
                },
              }
            : {}),
        },
        request: { employerID },
      })
    }
  )
}

function* notifyExternalWorkLocationChange() {
  yield takeLatest(
    [
      externalWorkLocationsTypes.ENTITY_CREATE_SUCCEEDED,
      externalWorkLocationsTypes.ENTITY_DELETE_SUCCEEDED,
      externalWorkLocationsTypes.ENTITY_UPDATE_SUCCEEDED,
      externalWorkLocationsTypes.ENTITY_CREATE_FAILED,
      externalWorkLocationsTypes.ENTITY_DELETE_FAILED,
      externalWorkLocationsTypes.ENTITY_UPDATE_FAILED,
      externalWorkLocationsTypes.ENTITY_FETCH_FAILED,
    ],
    notificationCall
  )
}

export default function* saga() {
  yield takeLatestApiCall(externalWorkLocationsTypes.ENTITY_FETCH_BEGIN, api.fetchExternalWorkLocation)
  yield takeLatestApiCall(externalWorkLocationsTypes.ENTITY_UPDATE_BEGIN, api.updateExternalWorkLocation)
  yield takeLatestApiCall(externalWorkLocationsTypes.ENTITY_CREATE_BEGIN, api.createExternalWorkLocation)
  yield takeLatestApiCall(externalWorkLocationsTypes.ENTITY_DELETE_BEGIN, api.deleteExternalWorkLocation)

  yield fork(notifyExternalWorkLocationChange)
  yield fork(updateWorkLocation)
}
