import { emptySet } from 'syft-acp-core/reducers/generators/constants'
import { entitySetName } from 'syft-acp-core/reducers/generators/utils'
import { stringify } from 'syft-acp-core/store/filters/helpers'

export const entitiesFetchFailed = (state, action, options) => {
  const setKey = entitySetName(action.options, options, action)
  const failedSetKey = action.options ? setKey : stringify(action.request.options)

  return {
    ...state,
    lastMessage: action.message || action.payload.message,
    lastStatus: action.payload.response ? action.payload.response.status : String(action.payload.response),
    lastBody: null,
    isLoadingData: false,
    entitySets: {
      ...state.entitySets,
      [failedSetKey]: {
        ...emptySet,
        ...state.entitySets[failedSetKey],
        isLoadingData: false,
      },
    },
  }
}
