import entityReducer from './generators/entityReducer'
import * as types from '../actions/action-types'
import * as entityTypes from './generators/entities'

// Boolean value that gets set to true and then doesn't change until the application is restarted.
// This allows us to update the no show reasons just once, which is all that's needed.
let hasUpdatedOnce = false

// Returns true during the first invocation, and false after.
export const needsNoShowReasonsUpdate$ = () => {
  const reply = !hasUpdatedOnce
  hasUpdatedOnce = true
  return reply
}

export default entityReducer('industries', {
  [entityTypes.ENTITIES_FETCH_BEGIN]: types.NO_SHOW_REASONS_FETCH_BEGIN,
  [entityTypes.ENTITIES_FETCH_SUCCEEDED]: types.NO_SHOW_REASONS_FETCH_SUCCEEDED,
  [entityTypes.ENTITIES_FETCH_FAILED]: types.NO_SHOW_REASONS_FETCH_FAILED,
})
