// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import React from 'react'
import PropTypes from 'prop-types'
import ReactTooltip from 'react-tooltip'
import { isString, get } from 'lodash-es'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import * as checkboxesActions from 'syft-acp-core/store/checkboxes/actions'

import './SelectAllEntities.css'

const tipText = 'Select all items on this page'

class SelectAllEntities extends React.Component {
  static propTypes = {
    entityList: PropTypes.arrayOf(PropTypes.object).isRequired,
    entityType: PropTypes.string.isRequired,
    subrowSelector: PropTypes.bool,
    dataGenerator: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
    actions: PropTypes.shape({
      clearItems: PropTypes.func,
      checkItems: PropTypes.func,
    }).isRequired,
    items: PropTypes.array.isRequired,
  }

  static defaultProps = {
    subrowSelector: false,
    dataGenerator: undefined,
  }

  handleChange = () => {
    const { actions, items, entityList, entityType, subrowSelector, dataGenerator } = this.props
    const selected = items.length === entityList.length

    if (subrowSelector && !isString(dataGenerator)) {
      const selectionData = entityList.reduce((acc, item) => [...acc, ...dataGenerator(item)], [])
      const subrowsChecked = !!items.length
      return subrowsChecked ? actions.clearItems(entityType) : actions.checkItems(selectionData, entityType, !selected)
    }
    // If dataGenerator is a string, it's a key from which to retrieve data
    // (other than the default 'id').
    if (isString(dataGenerator)) {
      actions.checkItems(
        entityList.map(i => get(i, dataGenerator)),
        entityType,
        !selected
      )
    } else {
      actions.checkItems(
        entityList.map(i => i.id),
        entityType,
        !selected
      )
    }
  }

  render() {
    const checked = this.props.items.length === this.props.entityList.length && this.props.items.length > 0
    const indeterminate = this.props.items.length < this.props.entityList.length && this.props.items.length > 0
    return (
      <div className="select-all-entities" aria-label={tipText}>
        <ReactTooltip effect="solid" />
        <input
          type="checkbox"
          checked={checked}
          onChange={this.handleChange}
          data-tip={tipText}
          data-delay-show="2000"
          ref={input => {
            if (input) {
              this.input = input
              this.input.indeterminate = indeterminate
            }
          }}
        />
      </div>
    )
  }
}

const mapStateToProps = (state, props) => ({
  items: state.checkboxes.items[props.entityType] || [],
})
const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(checkboxesActions, dispatch),
})
export default connect(mapStateToProps, mapDispatchToProps)(SelectAllEntities)
