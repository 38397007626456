// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import { keyBy, map } from 'lodash-es'
import entityReducer from './generators/entityReducer'
import { stringify } from 'syft-acp-core/store/filters/helpers'
import * as types from '../actions/action-types'
import * as entityTypes from './generators/entities'

const participant = (participants, role) => participants.find(p => p.role === role)

const adminConversationsFetchSucceededState = (state, action) => {
  const setKey = stringify(action.options)
  const idKey = 'id'
  const conversations = action.payload.map(c => {
    const worker = participant(c.participants, 'worker')
    const employer = participant(c.participants, 'employer')

    return {
      ...c,
      worker,
      employer,
    }
  })

  return {
    ...state,
    lastMessage: '',
    lastStatus: null,
    lastBody: null,
    entitySets: {
      ...state.entitySets,
      [setKey]: {
        isLoadingData: false,
        ids: map(conversations, idKey),
        totalPages: action.totalPages,
        totalWorkers: action.totalWorkers,
        total: action.total,
        activePage: action.activePage,
        perPage: action.perPage,
        nextPage: action.nextPage,
        page: action.page,
      },
    },
    entityMap: {
      ...state.entityMap,
      ...keyBy(conversations, idKey),
    },
  }
}

const adminConversationFetchSucceededState = (state, action) => {
  const idKey = 'id'
  const worker = participant(action.payload.participants, 'worker')
  const employer = participant(action.payload.participants, 'employer')
  const conversation = {
    ...action.payload,
    worker,
    employer,
  }

  return {
    ...state,
    lastMessage: '',
    lastStatus: null,
    lastBody: null,
    entityDetail: {
      ...state.entityDetail,
      [conversation[idKey]]: conversation,
    },
    isLoadingData: false,
  }
}

export default entityReducer('conversations', {
  [entityTypes.ENTITIES_FETCH_BEGIN]: types.ADMIN_CONVERSATIONS_FETCH_BEGIN,
  [types.ADMIN_CONVERSATIONS_FETCH_SUCCEEDED]: adminConversationsFetchSucceededState,
  [entityTypes.ENTITIES_FETCH_FAILED]: types.ADMIN_CONVERSATIONS_FETCH_FAILED,

  [entityTypes.ENTITY_FETCH_BEGIN]: types.ADMIN_CONVERSATION_FETCH_BEGIN,
  [types.ADMIN_CONVERSATION_FETCH_SUCCEEDED]: adminConversationFetchSucceededState,
  [entityTypes.ENTITY_FETCH_FAILED]: types.ADMIN_CONVERSATION_FETCH_FAILED,
})
