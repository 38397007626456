// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import React from 'react'
import PropTypes from 'prop-types'
import { TrackingProvider } from '@indeed/flex-tracking-context'

import ManagerCommissionsList from 'syft-acp-core/entities2/ManagerCommissionsList'
import PageView from 'syft-acp-core/components/PageView'
import SubNavigation from 'syft-acp-core/components/Navigation/SubNavigation'

export const ManagerCommissionsView = props => {
  const { match } = props
  const { params } = match

  return (
    <TrackingProvider
      value={{
        context: {
          employer_id: Number(params.id),
          sales_win_association_id: Number(params.sales_win_association_id),
          work_location_id: Number(params.work_location_id),
        },
      }}
    >
      <SubNavigation subPage="employers" data={{ employerID: Number(params.id ?? params.employer_id) }} />
      <PageView title={['Commissions', 'Overview']} entityPage>
        <ManagerCommissionsList {...match} />
      </PageView>
    </TrackingProvider>
  )
}

ManagerCommissionsView.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.object.isRequired,
  }),
}
