// Syft ACP - UI Library <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { FormControl } from 'react-bootstrap'
import usePrevValue from 'syft-acp-util/hooks/usePrevValue'

import { filterDecorator } from '../filterDecorator'
import { acpFilterDefaults } from '../dataProps'

const AcpFilterText = ({
  placeholder,
  isDisabled,
  type,
  name,
  setValue,
  initialValue,
  value,
  query,
  onChange,
}) => {
  const [innerValue, setInnerValue] = useState(initialValue || value || '')
  const prevQuery = usePrevValue(query)

  useEffect(() => {
    if (query?.[name] !== prevQuery?.[name]) {
      setInnerValue(query[name])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query, prevQuery])

  // NOTE: As of this writing (2022/11), `onChange` is used to fire tracking
  // events. As each event costs money, we don't want an event to be fired on
  // each key press. Therefore, we set a timeout, allowing the events to be
  // fired 2 secodns after the last key press.
  useEffect(() => {
    const t = setTimeout(() => {
      !!onChange && onChange(name, innerValue)
    }, 2000)

    return () => clearTimeout(t)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [innerValue])

  const handleChange = e => {
    const changedValue = e?.target?.value
    setInnerValue(changedValue)
    setValue(name, changedValue)
  }

  const valueText = innerValue || ''
  return (
    <FormControl
      type={type ?? 'text'}
      value={valueText}
      placeholder={placeholder}
      onChange={handleChange}
      disabled={isDisabled}
    />
  )
}

AcpFilterText.propTypes = {
  type: PropTypes.oneOf(['email', 'tel', 'number']),
}

AcpFilterText.defaultProps = acpFilterDefaults

AcpFilterText.requireDebounce = true

export default filterDecorator(AcpFilterText)
