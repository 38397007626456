// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import React from 'react'

import { defaultWrapperPropTypes } from 'syft-acp-util/entityList'
import AddWorkerToBlocklistModal from 'syft-acp-core/components/Modal/AddWorkerToBlocklistModal'

import EmployerDetailListWrapper from '../EmployerDetailListWrapper'

const EmployerBlocklistListWrapper = props => {
  return (
    <div>
      <AddWorkerToBlocklistModal employerID={props.employerID} isLoadingBlocklists={props.isLoadingBlocklists} />
      <EmployerDetailListWrapper controlsRight={props.filterForm} {...props} />
    </div>
  )
}
EmployerBlocklistListWrapper.propTypes = defaultWrapperPropTypes

export default EmployerBlocklistListWrapper
