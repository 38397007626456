import { connect } from 'react-redux'

import { updateAgencyBookings } from 'syft-acp-core/actions/agency-bookings'

import ListingNoShowCheckbox from './ListingNoShowCheckbox'

const mapDispatchToProps = dispatch => ({
  updateCheckbox: payload => dispatch(updateAgencyBookings(payload)),
})

export default connect(null, mapDispatchToProps)(ListingNoShowCheckbox)
