// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import React from 'react'
import Helmet from 'react-helmet'
import { filter, isArray } from 'lodash-es'
import { useIntl } from 'react-intl'
import { Props } from './PageTitle.types'

const appTitleSeparator = ' - '

/**
 * Wrapper class used to set a title for a page.
 *
 * The 'title' value is either an array of strings or a string.
 * Each item is added to the title with a separator and finally the app name last.
 *
 * Title items should be provided in order of importance, e.g. ['Workers', 'Onboarding'].
 */
const PageTitle = ({ title = [], children }: Props) => {
  const intl = useIntl()
  const appTitleSuffix = `${intl.formatMessage({ id: 'brand.name' })} ACP`
  const titleItems = isArray(title) ? title : [title]
  const pageTitle = filter([...titleItems, appTitleSuffix], n => !!n).join(appTitleSeparator)
  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      {children}
    </>
  )
}

export default PageTitle
