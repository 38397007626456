import React, { useMemo } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { bindActionCreators } from 'redux'
import { uniq, includes } from 'lodash-es'

import NotifyBar from 'syft-acp-atoms/NotifyBar'
import { entitySetName } from 'syft-acp-core/reducers/generators/utils'
import { getModal$ } from 'syft-acp-core/store/modals/selectors'
import { getWorker$ } from 'syft-acp-core/store/workers/selectors'
import { Modal } from 'syft-acp-core/components/Modal'
import { hideModal } from 'syft-acp-core/store/modals/actions'
import * as attachmentsActions from 'syft-acp-core/actions/attachments'
import * as workerDocumentsActions from 'syft-acp-core/actions/worker-documents'
import DocumentAttachment from 'syft-acp-core/entities/WorkerDetail/DocumentsTable/DocumentAttachment'

import './WorkerCertificateFilesModal.css'

const RESTRICTED_DOC_TYPES = ['drug-test-5-panel', 'drug-test-10-panel', 'Requires-DT-9-Panel']

export type WorkerCertificateFilesModalProps = { workerID: number } & ConnectedProps<typeof storeConnector>

type Attachment = Record<string, unknown>
const WorkerCertificateFilesModal = ({
  actions,
  workerDocuments,
  workers,
  workerDocumentTypes,
  item,
  workerID,
}: WorkerCertificateFilesModalProps) => {
  const handleClose = () => actions.hideModal(item.modalName)

  const isDeletingAttachment = !!workerDocuments?.isLoadingData

  const documents = useMemo(() => {
    const field = item?.data.activeField
    if (!field || !workerID) return []

    // Retrieve the documents uploaded for this worker.
    const set = entitySetName({ workerID })
    // FIXME: we need to uniq() this because otherwise we can sometimes get duplicates here,
    // until we retrieve all documents again for the user. Not sure why this happens.
    const docIDs = uniq(workerDocuments.entitySets[set]?.ids ?? [])
    const docs = docIDs.map(id => workerDocuments.entityMap[id])

    // Filter out only the documents for the field we're looking at right now.
    return docs.filter(doc => (doc?.type ?? null) === field)
  }, [item?.data.activeField, workerDocuments.entityMap, workerDocuments.entitySets, workerID])

  const getTitle = () => {
    const field = item?.data?.activeField ?? null
    const activeWorkerID = item?.data?.activeWorker ?? null

    // Retrieve document info.
    try {
      const docType = Object.values(workerDocumentTypes.entityMap).filter(doc => doc.code === field)[0]
      const workerData = getWorker$(workers)(activeWorkerID)
      return `Viewing ${docType.name} for ${workerData.name}`
    } catch (e) {
      return `Viewing certificate files`
    }
  }

  const handleDeleteAttachment = (documentID: number, attachment?: Record<string, unknown>) => {
    const activeWorkerID = item?.data?.activeWorker ?? null
    const token = attachment?.secure_token
    actions.deleteAttachment({ documentID, secureToken: token })
    actions.listWorkerDocuments({ workerID: activeWorkerID, options: { workerID: activeWorkerID } })
  }

  const [document] = documents
  const attachments = (document?.attachments || []) as Attachment[]
  const id = document?.id ?? 0
  const documentTypeCode = document?.document_type?.code
  const restrictedDoc = includes(RESTRICTED_DOC_TYPES, documentTypeCode)

  return (
    <Modal header={getTitle()} isShown={item.isShown} cancelText="Close" onClose={handleClose}>
      <div className="worker-certificate-files-modal">
        <NotifyBar conditional visible={documents.length > 1}>
          This item type has {documents.length} documents associated with it. It should only have 1.
        </NotifyBar>
        {attachments.length > 0 && (
          <p>
            This document has {attachments.length} attachment file{attachments.length > 1 ? 's' : ''}.
          </p>
        )}
        {attachments.length === 0 && <p>No files have been uploaded yet.</p>}
        {attachments.map((a, n) => (
          <DocumentAttachment
            busy={isDeletingAttachment}
            restrictedDoc={restrictedDoc}
            documentID={id}
            key={n}
            attachment={a}
            index={n}
            deleteAttachment={handleDeleteAttachment}
            hideDelete={item?.data?.hideDelete}
          />
        ))}
      </div>
    </Modal>
  )
}

const storeConnector = connect(
  state => ({
    item: getModal$(state.modals)('workerCertificateFilesModal'),
    workerDocuments: state?.workerDocuments ?? {},
    workerDocumentTypes: state?.workerDocumentTypes ?? {},
    workers: state?.workers ?? {},
  }),
  dispatch => ({
    actions: bindActionCreators({ ...attachmentsActions, ...workerDocumentsActions, hideModal }, dispatch),
  }),
)

export default storeConnector(WorkerCertificateFilesModal)
