// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import React from 'react'

import classnames from 'classnames'
import EntityDetailItem from 'syft-acp-core/components/EntityDetail/EntityDetailItem'
import RecordHeader from 'syft-acp-core/components/RecordSection/RecordHeader'
import RecordHeaderForm from 'syft-acp-core/components/RecordSection/RecordHeaderForm'
import RecordSegment from 'syft-acp-core/components/RecordSection/RecordSegment'
import { defaultWrapperPropTypes } from 'syft-acp-util/entityList'

const entityDetailList = ({
  title,
  smallWorkersList,
  noMargin,
  withPagination,
  className,
  notificationsNode,
  subHeaderNode,
}) => {
  const Wrapper = props => {
    const { filterForm, entityListResults, entityList, entityListPagination } = props
    return (
      <div>
        <EntityDetailItem
          noMargin={noMargin}
          withPagination={withPagination}
          withEntityList
          className={className}
        >
          <RecordSegment noPadding>
            <RecordHeaderForm>{filterForm}</RecordHeaderForm>
            <RecordHeader border main>
              {props.title ? props.title : title}
            </RecordHeader>
          </RecordSegment>
          {notificationsNode}
          {subHeaderNode}
          <div className={classnames('list', { 'small-workers-list': smallWorkersList })}>
            {entityListResults}
            {entityList}
            {entityListPagination}
          </div>
        </EntityDetailItem>
      </div>
    )
  }
  Wrapper.propTypes = defaultWrapperPropTypes

  return Wrapper
}

export default entityDetailList
