import { apiRequest, handleCall } from 'syft-acp-core/api/call'

import {
  DeleteListingInput,
  DeleteMultipleListingsInput,
  EmailTimesheetFromListingInput,
  FetchAdminListingBookingsInput,
  FetchAdminListingInput,
  FetchListingRatingsInput,
  FetchListingResendInput,
  PublishToAgency,
  SaveListingInput,
} from './types'

export const fetchAdminListing = ({ id }: FetchAdminListingInput) =>
  new Promise((resolve, reject) => {
    const req = apiRequest({ path: `/admin/listings/${id}` })
    handleCall(req, resolve, reject, true)
  })

export const fetchAdminListingBookings = ({ shiftID, options: reqArgs }: FetchAdminListingBookingsInput) =>
  new Promise((resolve, reject) => {
    const req = apiRequest({
      path: `/admin/shifts/${shiftID}/bookings`,
      reqArgs: { ...reqArgs, pay_bill_split: true },
    })
    handleCall(req, resolve, reject, true)
  })

export const fetchListingResend = ({ data, options: reqArgs }: FetchListingResendInput) =>
  new Promise((resolve, reject) => {
    const req = apiRequest({
      path: `/employers/${data.employerId}/listings/${data.listingId}/resend_approval_request`,
      reqArgs,
      method: 'PATCH',
    })
    handleCall(req, resolve, reject, true)
  })

export const saveListing = ({ id, data }: SaveListingInput) =>
  new Promise((resolve, reject) => {
    const req = apiRequest({
      path: `/admin/listings/${id}`,
      reqArgs: data,
      method: 'PATCH',
      allowNulls: true,
    })
    handleCall(req, resolve, reject)
  })

export const fetchListingRatings = ({ listingID }: FetchListingRatingsInput) =>
  new Promise((resolve, reject) => {
    const req = apiRequest({ path: `/listings/${listingID}/ratings` })
    handleCall(req, resolve, reject)
  })

export const deleteListing = ({ listingID }: DeleteListingInput) =>
  new Promise((resolve, reject) => {
    const req = apiRequest({ path: `/listings/${listingID}`, method: 'DELETE' })
    handleCall(req, resolve, reject)
  })

export const emailTimesheetFromListing = ({ listingID, email }: EmailTimesheetFromListingInput) =>
  new Promise((resolve, reject) => {
    const req = apiRequest({
      path: `/listings/${listingID}/email_timesheet`,
      reqArgs: { email },
      method: 'POST',
    })
    handleCall(req, resolve, reject)
  })

export const deleteMultipleListings = ({ listingIDs, callback }: DeleteMultipleListingsInput) => {
  const list = listingIDs.map(
    id =>
      new Promise((resolve, reject) =>
        handleCall(apiRequest({ path: `/listings/${id}`, method: 'DELETE' }), resolve, reject),
      ),
  )
  return new Promise((resolve, reject) => {
    Promise.all(list).then(
      // Call our callback handler.
      values => {
        resolve(values)
        callback({ values })
      },
      reason => {
        reject(reason)
        callback({ reason })
      },
    )
  })
}

export const publishListingToAgency = ({ id }: PublishToAgency) =>
  new Promise((resolve, reject) => {
    const req = apiRequest({
      path: `/listings/${id}/publish_to_agency`,
      reqArgs: { id, offer_to: 'agency' },
      method: 'PUT',
    })
    handleCall(req, resolve, reject)
  })
