// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import * as types from './action-types'

export function fetchWorkerTransfer(payload) {
  return { type: types.WORKER_TRANSFER_FETCH_BEGIN, payload }
}

export function createWorkerTransfer(payload) {
  return { type: types.WORKER_TRANSFER_CREATE_BEGIN, payload }
}

export function deleteWorkerTransfer(payload) {
  return { type: types.WORKER_TRANSFER_DELETE_BEGIN, payload }
}

export function updateWorkerTransfer(payload) {
  return { type: types.WORKER_TRANSFER_UPDATE_BEGIN, payload }
}

export function resendWorkerInvitation(email) {
  return { type: types.WORKER_TRANSFER_RESEND_INVITATION_BEGIN, email }
}
