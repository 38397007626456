// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import React from 'react'

import { default as FinanceAutoOfferRateList } from 'syft-acp-core/entities2/FinanceAutoOfferRate'
import PageView from 'syft-acp-core/components/PageView'
import SubNavigationFinance from './SubNavigation'

export const FinanceAutoOfferRate = () => (
  <>
    <SubNavigationFinance />
    <PageView title={['Finance', 'Auto offer rate effectiveness data']} entityPage>
      <FinanceAutoOfferRateList />
    </PageView>
  </>
)
