// Syft ACP - UI Library <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import * as U from 'karet.util'
import PropTypes from 'prop-types'

import { PropTypesAtom } from '../AcpTable/cellComponents/dataProps'

/**
 * PropTypes for the EntityList, which is applied to 'AcpEntityList' and 'AcpEntityListConstruct'.
 */
export const acpEntityListProps = {
  /** A lens to a resource. */
  resource: PropTypes.any,
  /** Source of information for the rows. Usually an array, but can be an object (if an error occurred). */
  data: PropTypes.oneOfType([PropTypes.array, PropTypes.object, PropTypesAtom]),
  /** Selection data. Always a lens. */
  stateSelection: PropTypes.object,
  stateSubSelection: PropTypes.object,
  /** Whether to display the result count. */
  hasResultCount: PropTypes.bool,
  /** Whether to display pagination. */
  hasPagination: PropTypes.bool,
  /** Class name passed on to the table component. */
  className: PropTypes.string,
  /** Name of the value to use as unique ID (especially useful for atoms). */
  idKeyValue: PropTypes.string,
  /** Whether the data being displayed is stale (old, from a previous request). */
  isStale: PropTypes.bool,
  /** Wraps the whole list in a narrow container, rather than making it take up the whole page width. */
  inContainer: PropTypes.bool,
  /** Style of the list; either a separate component (if true) or a full page list. */
  isIntegrated: PropTypes.bool,
  /** Whether the component is currently loading more data. */
  isLoading: PropTypes.oneOfType([PropTypes.bool, PropTypesAtom]),
  /** Whether we're displaying an error. */
  isOK: PropTypes.bool,
  /** Whether to display actions inline with header */
  actionsInline: PropTypes.bool,
}

/**
 * Default prop values for the EntityList, which is applied to 'AcpEntityList' and 'AcpEntityListConstruct'.
 */
export const acpEntityListDefaults = {
  data: [],
  meta: {},
  stateSelection: U.atom([]),
  stateSubSelection: U.atom([]),
  hasResultCount: false,
  hasPagination: false,
  idKeyValue: null,
  inContainer: false,
  isIntegrated: false,
  isLoading: false,
  isOK: true,
  actionsInline: false,
}
