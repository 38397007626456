import React from 'react'
import { capitalize } from 'lodash-es'

import { filterSelect } from 'syft-acp-util/components/FilterForm/FilterSelect'

import { DataAppealOutcomeDropdownProps as Props } from './DataAppealOutcomeDropdown.types'

const SelectOutcome = filterSelect(
  [
    {
      id: 'accepted',
      label: 'Accepted',
    },
    {
      id: 'declined',
      label: 'Declined',
    },
  ],
  'Keep open',
  'id',
  false
)

const DataAppealOutcomeDropdown = ({ disabled = false, editable = false, data, onChange }: Props) => {
  if (editable) return <SelectOutcome value={data.outcome} onChange={onChange} allowAny={true} disabled={disabled} />
  return <div className="plain-text">{data.outcome ? capitalize(data.outcome) : 'n/a'}</div>
}

export default DataAppealOutcomeDropdown
