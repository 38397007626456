import React, { useState, useEffect } from 'react'
import { Col, Row } from 'react-bootstrap'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { noop } from 'lodash-es'
import moment from 'moment'

import { Modal } from 'syft-acp-core/components/Modal'
import { getModal$ } from 'syft-acp-core/store/modals/selectors'
import { hideModal } from 'syft-acp-core/store/modals/actions'
import {
  selectWorkerHolidayPayAccruals,
  selectWorkerHolidayPayHistoricRequestValidation,
} from 'syft-acp-core/store/worker-holiday-pay/selectors'
import {
  makeWorkerHolidayPayHistoricRequest,
  validateWorkerHolidayPayHistoricRequest,
  resetValidation,
} from 'syft-acp-core/store/worker-holiday-pay/actions'
import DataRate from 'syft-acp-core/components/EditableTable/DataRate'
import DataDate from 'syft-acp-core/components/EditableTable/DataDate'
import { MonetaryAmount } from 'syft-acp-core/store/types'
import { SUPPORTED_COUNTRY_CODES } from 'syft-acp-core/lib/i18n'

import {
  HistoricHolidayPayModalProps as Props,
  HistoricHolidayPayModalOwnProps as OwnProps,
} from './HistoricHolidayPayModal.types'
import './HistoricHolidayPayModal.scss'

export const storeConnector = connect(
  (state, props: OwnProps) => ({
    modal: getModal$(state.modals)(props.modalName),
    isLoadingHistoricRequests: state.workerHolidayPay.isLoadingHistoricRequests,
    isValidating: state.workerHolidayPay.isValidatingHistoricRequest,
    historicAccruals: selectWorkerHolidayPayAccruals(state)(props.workerID).historic,
    validation: selectWorkerHolidayPayHistoricRequestValidation(state)(props.workerID),
  }),
  dispatch => ({
    actions: {
      ...bindActionCreators(
        { hideModal, makeWorkerHolidayPayHistoricRequest, validateWorkerHolidayPayHistoricRequest, resetValidation },
        dispatch
      ),
    },
  })
)

const createISOStringWithDateOnly = (date: string | number | Date) =>
  new Date(new Date(date).toDateString()).toISOString()

const getTimestamp = (date: string | number | Date) => new Date(date).getTime()

const HistoricHolidayPayModal = ({
  actions,
  modal,
  modalName,
  historicAccruals,
  isLoadingHistoricRequests,
  isValidating,
  validation,
  workerID,
}: Props) => {
  const isLoading = isLoadingHistoricRequests || isValidating
  const dateTomorrow = createISOStringWithDateOnly(moment().add(1, 'days').toString())
  const [amount, setAmount] = useState(0)
  const [available] = useState(historicAccruals.available)
  const [startDate, setStartDate] = useState(dateTomorrow)

  useEffect(() => {
    // close modal on successful request
    if (available !== historicAccruals.available) closeModal()
  }, [historicAccruals.available])

  const closeModal = () => {
    setAmount(0)
    setStartDate(dateTomorrow)
    actions.resetValidation(workerID)
    actions.hideModal(modalName)
  }

  const makeHistoricRequest = () => {
    if (!isLoading)
      actions.makeWorkerHolidayPayHistoricRequest({
        options: { workerID, amount, startDate: validation?.start_date_required ? startDate : undefined },
      })
  }

  const onConfirm = () => {
    if (validation) makeHistoricRequest()
    else validate()
  }

  const onChangeAmount = (value: MonetaryAmount) => {
    setAmount(value.amount)
    actions.resetValidation(workerID)
  }

  const onChangeStartDate = (date: Date) => {
    const dateString = createISOStringWithDateOnly(date)
    setStartDate(dateString)
  }

  const validate = () => {
    if (amount && !isValidating) actions.validateWorkerHolidayPayHistoricRequest({ options: { workerID, amount } })
  }

  const getAmountError = () => {
    if (amount > historicAccruals.available) return 'Value cannot be larger than available amount'
    return null
  }

  const getStartDateError = () => {
    const startDateTimestamp = getTimestamp(startDate)
    const isStartDatePastMax = () => {
      if (!validation?.maximum_start_date) return true
      const dTime = getTimestamp(validation.maximum_start_date) - startDateTimestamp
      return dTime >= 0
    }

    if (validation?.start_date_required && startDateTimestamp < getTimestamp(dateTomorrow))
      return 'Chosen date cannot be in the past'
    if (!isStartDatePastMax()) return 'Chosen date must be before the maximum start date'
    return null
  }

  const canSubmit = () => {
    if (isLoading) return false
    // submit button has two functions depending on whether validation api has been called yet
    if (validation) return !getStartDateError()
    if (amount) return !getAmountError()
    return false
  }

  const getErrorText = () => getAmountError() || getStartDateError() || ''

  return (
    <Modal
      className="historic-holiday-pay-modal"
      header="Make historic holiday pay request"
      isShown={modal.isShown}
      isLoading={isLoading}
      onClose={closeModal}
      onConfirm={onConfirm}
      canSubmit={canSubmit()}
      errorText={getErrorText()}
      cancelText="Cancel"
      confirmationText={validation ? 'Submit' : 'Calculate'}
    >
      <Row className="historic-holiday-pay-modal__row">
        <Col sm={3}>Available:</Col>
        <Col sm={9}>
          <DataRate
            onChange={noop}
            showZero={true}
            value={{ amount: historicAccruals.available, currency: SUPPORTED_COUNTRY_CODES.GB }}
            editable={false}
          />
        </Col>
      </Row>
      <Row className="historic-holiday-pay-modal__row">
        <Col sm={3}>Amount to request:</Col>
        <Col sm={9}>
          <DataRate
            onChange={onChangeAmount}
            showZero={true}
            value={{ amount, currency: SUPPORTED_COUNTRY_CODES.GB }}
            editable={true}
          />
        </Col>
      </Row>
      {validation && (
        <>
          {validation.message_html && (
            <Row className="historic-holiday-pay-modal__row">
              <Col sm={3}>Message to Flexer:</Col>
              <Col sm={9}>
                <div
                  className="historic-holiday-pay-modal__html"
                  dangerouslySetInnerHTML={{
                    __html: validation.message_html,
                  }}
                />
              </Col>
            </Row>
          )}
          {validation.maximum_start_date && (
            <Row className="historic-holiday-pay-modal__row">
              <Col sm={3}>Maximum start date:</Col>
              <Col sm={9}>
                <DataDate value={validation.maximum_start_date} onChange={noop} simple />
              </Col>
            </Row>
          )}
          {validation.start_date_required && (
            <Row className="historic-holiday-pay-modal__row">
              <Col sm={3}>Start date:</Col>
              <Col sm={9}>
                <DataDate editable={true} value={startDate} onChange={onChangeStartDate} />
              </Col>
            </Row>
          )}
        </>
      )}
    </Modal>
  )
}

export default storeConnector(HistoricHolidayPayModal)
