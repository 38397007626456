import entityReducer from 'syft-acp-core/reducers/generators/entityReducer'
import * as entityTypes from 'syft-acp-core/reducers/generators/entities'
import * as types from 'syft-acp-core/actions/action-types'
import { JOB_STOP_OFFERS_UPDATE_BY_SHIFT } from './action-types'

import { JobEntity, JobsState, AdminListingFetchSucceededAction } from './types'

export default entityReducer<JobEntity>('jobs', {
  [entityTypes.ENTITIES_FETCH_BEGIN]: types.FETCH_ADMIN_JOB_BEGIN,
  [types.FETCH_ADMIN_JOB_SUCCEEDED]: (state: JobsState, action: AdminListingFetchSucceededAction) => {
    const job = action.payload

    return {
      ...state,
      lastMessage: '',
      lastStatus: null,
      lastBody: null,
      isLoadingData: false,
      entitySets: {
        ...state.entitySets,
      },
      entityMap: {
        ...state.entityMap,
        [job.id]: job,
      },
      entityDetail: {
        ...state.entityDetail,
        [job.id]: job,
      },
    }
  },
  [entityTypes.ENTITIES_FETCH_FAILED]: [types.FETCH_ADMIN_JOB_FAILED, types.ADMIN_LISTING_FETCH_FAILED],

  [entityTypes.ENTITY_MODIFY_BEGIN]: types.JOB_UPDATE_BEGIN,
  // This is a hack because after update a job for the public endpoint doesn't return roles. So we call get endpoint.
  // Please uncomment this after new endpoint for job will be avaliable. https://syftapp.atlassian.net/browse/PTE-20205
  // And remove yield put(fetchAdminJob(action.request.id)) from jobs/saga.ts
  // [entityTypes.ENTITY_MODIFY_SUCCEEDED]: types.JOB_UPDATE_SUCCEEDED,
  [entityTypes.ENTITY_MODIFY_FAILED]: types.JOB_UPDATE_FAILED,
  [types.SHIFT_DELETE_SUCCEEDED]: (state: JobsState, action: any) => {
    const shifts = state.entityMap[action?.request?.jobID]?.shifts?.filter(shift => {
      return shift.id !== action.request.shiftID
    })
    return {
      ...state,
      entityMap: {
        ...state.entityMap,
        [action.request.jobID]: {
          ...state.entityMap[action.request.jobID],
          shifts: shifts ?? state.entityMap[action.request.jobID].shifts,
        },
      },
      entityDetail: {
        ...state.entityDetail,
        [action.request.jobID]: {
          ...state.entityMap[action.request.jobID],
          shifts: shifts ?? state.entityMap[action.request.jobID].shifts,
        },
      },
    }
  },

  [JOB_STOP_OFFERS_UPDATE_BY_SHIFT]: (state: JobsState, action: any) => {
    return {
      ...state,
      entityMap: {
        ...state.entityMap,
        [action.id]: {
          ...state.entityMap[action.id],
          stop_offers_for_worker_type: action.payload,
        },
      },
      entityDetail: {
        ...state.entityDetail,
        [action.id]: {
          ...state.entityMap[action.id],
          stop_offers_for_worker_type: action.shiftOfferForWorkerType,
        },
      },
    }
  },

  [entityTypes.ENTITY_DELETE_BEGIN]: types.RATING_DELETE_BEGIN,
  [entityTypes.ENTITY_DELETE_SUCCEEDED]: types.RATING_DELETE_SUCCEEDED,
  [entityTypes.ENTITY_DELETE_FAILED]: types.RATING_DELETE_FAILED,
})
