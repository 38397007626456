import React from 'react'
import PropTypes from 'prop-types'
import ClipboardButton from 'react-clipboard.js'
import classnames from 'classnames'

import iconFactory from 'syft-acp-atoms/Icons'

import './CopiedText.css'

// Copy icon from Octicons.
const ClippyIcon = iconFactory('clippy')

class CopiedText extends React.PureComponent {
  onClickHandle = value => ev => {
    navigator.clipboard.writeText(value)
    return ev.preventDefault()
  }

  render() {
    const { children, copyValue, noHide, noFloat, onClick } = this.props

    return (
      <div className={noHide ? 'copied-text' : 'copied-text hover-hidden'}>
        {children}
        <ClipboardButton
          /* Prevent click-through from activating if inside a link. */
          onClick={onClick || this.onClickHandle(copyValue)}
          onSuccess={this.onSuccess}
          className={classnames('Button', 'copy-button', { 'no-float': noFloat })}
          data-clipboard-text={copyValue || children}
        >
          <ClippyIcon iconSize={15} />
        </ClipboardButton>
      </div>
    )
  }
}

CopiedText.propTypes = {
  children: PropTypes.node,
  copyValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onClick: PropTypes.func,
  noHide: PropTypes.bool,
  noFloat: PropTypes.bool,
}

CopiedText.defaultProps = {
  children: null,
  copyValue: null,
  onClick: null,
  noHide: false,
  noFloat: false,
}

export default CopiedText
