import { get, noop } from 'lodash-es'

import * as types from 'syft-acp-core/actions/action-types'

import { createReducer } from '@reduxjs/toolkit'
import { InitialModalsState, ModalsItem } from './types'

const initialState: InitialModalsState = {
  items: {},
}

export const initialModalState: ModalsItem = {
  modalName: '',
  options: {
    modalConfirm: 'OK',
    modalHeader: 'Confirmation',
  },
  data: {},
  onClose: noop,
  isShown: false,
}

const reducer = createReducer(initialState, builder =>
  builder.addDefaultCase((state, action) => {
    // Merge in the defaults.
    const options = {
      ...initialModalState.options,
      ...get(action, 'options', {}),
    }
    switch (action.type) {
      case types.REHYDRATION_COMPLETE:
        return {
          ...state,
        }
      case types.MODAL_SHOW:
        return {
          ...state,
          items: {
            ...state.items,
            [action.modalName]: {
              ...get(state.items, action.modalName, initialModalState),
              modalName: action.modalName,
              options: {
                ...get(state.items, action.modalName, initialModalState).options,
                ...options,
              },
              onClose: action.onClose,
              isShown: true,
              data: get(action, 'data', get(state, `items[${action.modalName}].data`, {})),
            },
          },
        }
      case types.MODAL_HIDE:
        return {
          ...state,
          items: {
            ...state.items,
            [action.modalName]: {
              ...get(state.items, action.modalName, initialModalState),
              isShown: false,
            },
          },
        }
      case types.MODAL_SET_DATA:
        return {
          ...state,
          items: {
            ...state.items,
            [action.modalName]: {
              ...get(state.items, action.modalName, initialModalState),
              data: get(action, 'data', get(state, `items[${action.modalName}].data`, {})),
            },
          },
        }
      default:
        return state
    }
  })
)

export default reducer
