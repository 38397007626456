import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { get, unionBy, isEmpty } from 'lodash-es'
import { TrackingTrigger } from '@indeed/flex-tracking-context'

import { store } from 'syft-acp-core/store'
import { entityList$ } from 'syft-acp-core/reducers/generators/entities'
import { entitySetName } from 'syft-acp-core/reducers/generators/utils'
import * as jobsActions from 'syft-acp-core/store/jobs/actions'
import * as skillsActions from 'syft-acp-core/store/skills/actions'
import * as listingActions from 'syft-acp-core/store/listings/actions'
import entityDetailList from 'syft-acp-core/components/EntityDetail/entityDetailList'
import entityListGenerator from 'syft-acp-util/entityList'
import { makePropertyCheck } from 'syft-acp-core/store/filters/helpers'
import { structure } from './listingRatingsListStructure'
import { trackingEvents } from './tracking'

import './ListingRatings.css'

const ListingRatingsWrapper = entityDetailList({ title: 'Ratings', noMargin: true, withPagination: true })

const ListingRatingsList = ({ jobID, listingID, entityList = [], actions }) => {
  const queryString = entitySetName({ listingID })
  const ratingSet = get(store.getState(), `listingRatings.entitySets[${queryString}]`, {})
  const isLoading = get(ratingSet, 'isLoadingData', false)
  const hasItems = get(ratingSet, 'ids', []).length > 0
  useEffect(() => {
    if (!isLoading && !hasItems) {
      actions.fetchListingRatings(listingID)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const RatingsEntityList = entityListGenerator(
    ListingRatingsWrapper,
    structure(jobID),
    null,
    null,
    'listingRatings',
    {},
    {
      localPagination: true,
      localPaginationSize: 15,
      debugString: 'ListingRatings',
      idFunction: makePropertyCheck(['worker.id'], true),
      idKey: 'worker.id',
      recordList: true,
      showResultCount: true,
    },
  )
  if (!RatingsEntityList) return null
  return (
    <>
      <RatingsEntityList entityList={entityList} urlBase="/entity/workers/view/" title="Ratings" />
      <TrackingTrigger
        onLoad={{
          event: trackingEvents.LISTING_WORKER_INFO.PAGE.LOADED,
        }}
      />
    </>
  )
}

ListingRatingsList.propTypes = {
  jobID: PropTypes.number.isRequired,
  listingID: PropTypes.number.isRequired,
  entityList: PropTypes.arrayOf(PropTypes.shape({ worker: PropTypes.object })),
  actions: PropTypes.objectOf(PropTypes.func).isRequired,
}

export default connect(
  (state, ownProps) => {
    // Note: see comment above. Use the 'roles' reducer instead.
    // The 'workerRatings' array will look something like this:
    //
    //   [{ comments: null, skill_ids: [1375], stars: 5, worker: { ... } }, ...]
    //
    // See <ADMN-534> for details.
    const ratings = get(state, `listingRatings.entityMap.${ownProps.jobID}`, [])
    const workerRatings = get(ratings, `worker_ratings`, [])
    const teamSkillIDs = get(ratings, 'skill_ids', [])
    const teamStars = get(ratings, 'stars', null)

    return {
      entityList: unionBy(
        // Replace ratings with the global one if there is no individual rating.
        workerRatings.map(r => ({
          ...r,
          stars: r.stars == null ? teamStars : r.stars,
          skill_ids: r.skill_ids == null ? teamSkillIDs : get(r, 'skill_ids', []),
          skills: (r.skill_ids == null ? teamSkillIDs || [] : get(r, 'skill_ids', [])).map(
            id => state.skills.entityMap[id],
          ),
        })),
        entityList$(state.listingShiftBookings)
          .filter(b => b != null && !isEmpty(b.worker))
          .map(b => ({
            worker: b.worker,
            generated: true,
          })),
        b => b.worker.id,
      ),
    }
  },
  dispatch => ({
    actions: bindActionCreators(
      {
        ...jobsActions,
        ...skillsActions,
        ...listingActions,
      },
      dispatch,
    ),
  }),
)(ListingRatingsList)
