// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited
// loads core module which bootstraps the application
import './publicStaticUrl'
import 'core-js/stable'
import React from 'react'
import ReactDOM from 'react-dom'
import { datadogRum } from '@datadog/browser-rum-slim'
import { ErrorPage } from 'syft-acp-core/views2/ErrorBoundary'

// Make Bootstrap globally available.
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap/dist/css/bootstrap-theme.css'
import datadogConfig from 'syft-acp-core/config/dataDogConfig'

const boostrap = async () => {
  const rootEl = document.getElementById('app-root')
  try {
    // bootstrap lazily to be able to catch and report error to DD
    const { start } = await import('./bootstrap')
    await start(rootEl)
  } catch (error) {
    datadogRum.addError(error)
    ReactDOM.render(<ErrorPage error={error as Error} />, rootEl)
  }
}

datadogRum.onReady(async () => {
  datadogRum.init(datadogConfig(window.__initialState))

  await boostrap()
})
