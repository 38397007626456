// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import React from 'react'

import './PageEntityList.css'
import { Props } from './PageEntityList.types'

const PageEntityList = ({ children }: Props) => <div className="acp-page-entity-list">{children}</div>

export default PageEntityList
