import React, { useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { bindActionCreators } from 'redux'
import { get } from 'lodash-es'
import { createSelector } from 'reselect'

import * as listingsActions from 'syft-acp-core/store/listings/actions'
import * as listingShiftsActions from 'syft-acp-core/store/listing-shifts/actions'
import PageTitle from 'syft-acp-core/components/PageTitle'
import SubNavigation, { SubLink } from 'syft-acp-atoms/SubNavigation'
import { useLocation } from 'react-router-dom'

const listingSelector = createSelector(
  [(listings, id) => listings.entityDetail[id] || listings.entityMap[id] || {}],
  details => details,
)

const Listing = ({ children }) => {
  const { pathname } = useLocation()
  const dispatch = useDispatch()
  const actions = useMemo(
    () => bindActionCreators({ ...listingsActions, ...listingShiftsActions }, dispatch),
    [dispatch],
  )

  const splitedPath = pathname.split('/')
  const id = Number(get(splitedPath, '[3]', 0))
  const shiftID = Number(get(splitedPath, '[7]', 0))
  const jobID = Number(get(splitedPath, '[5]', 0))

  const data = useSelector(state => listingSelector(state.listings, id))

  const listingPath = pathname.includes('view') ? pathname : pathname.replace('ratings', 'view')
  const ratingsPath = pathname.includes('ratings') ? pathname : pathname.replace('view', 'ratings')
  const isImports = pathname.includes('imports')

  useEffect(() => {
    actions.fetchAdminListing(id)
    actions.fetchListingRatings(id)

    // Get the requested job and shift IDs, or default to whichever are the first.
    // Fetch information for the listing (including the bookings).
    if (shiftID) {
      actions.fetchAdminShiftListing(id, shiftID)
      actions.fetchAdminBookings(shiftID, jobID)
    } else {
      const firstJob = get(data, `jobs[0]`, {})
      const firstShift = get(firstJob, `shifts[0]`, {})
      if (firstShift.id) {
        actions.fetchAdminShiftListing(id, firstShift.id)
        actions.fetchAdminBookings(firstShift.id, firstJob.id)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <PageTitle title="Listing">
      <div>
        <SubNavigation>
          {!isImports && (
            <>
              <SubLink to={listingPath}>Listing info</SubLink>
              <SubLink to={ratingsPath}>Worker info</SubLink>
            </>
          )}
        </SubNavigation>

        {children}
      </div>
    </PageTitle>
  )
}

Listing.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Listing
