import React from 'react'
import PropTypes from 'prop-types'
import { formi9Statuses } from 'syft-acp-core/data/formi9Status'

import StatusLabel from './StatusLabel'

const Formi9Labels = ({ value, full }) => (
  <StatusLabel
    value={value}
    groupName={full && 'Form I9'}
    typesMap={formi9Statuses}
    defaultStatus={formi9Statuses.Incomplete}
  />
)

Formi9Labels.propTypes = {
  value: PropTypes.object,
  full: PropTypes.bool,
}

Formi9Labels.defaultProps = {
  value: null,
  full: false,
}
export default Formi9Labels
