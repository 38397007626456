import { connect } from 'react-redux'

import { updateBooking, updateBookingChanges } from 'syft-acp-core/actions/bookings'

import { ListingNoShowCheckbox } from './ListingNoShowCheckbox'

const mapDispatchToProps = dispatch => ({
  updateCheckbox: ({ id, attr, val }) => {
    dispatch(updateBooking({ id, attr, val }))
    dispatch(updateBookingChanges({ id, attr, val }))
  },
})

export default connect(null, mapDispatchToProps)(ListingNoShowCheckbox)
