import { DefaultRootState } from 'react-redux'
import { createSelector } from 'reselect'
import { UpdateWorkerMarketingPreferencesPayload, WorkerMarketingPreferences } from './types'
import { transformPreferenceToPayload } from './helpers'

const selectWorkerMarketingPreferences = (state: DefaultRootState) => state.workerMarketingPreferences
const selectWorkerId = (_state: DefaultRootState, workerId: number) => workerId
const selectPendingConsent = (state: DefaultRootState) => state.workerMarketingPreferences.pendingConsent

export const selectIsWorkerMarketingPreferencesUpdating = (state: DefaultRootState) =>
  state.workerMarketingPreferences.isUpdating

export const selectWorkerMarketingPendingConsentPreference = (state: DefaultRootState) =>
  state.workerMarketingPreferences.pendingConsent?.preference

export const selectWorkerMarketingPreferencesById = createSelector(
  [selectWorkerMarketingPreferences, selectWorkerId],
  (workerMarketingPreferences, workerID) => workerMarketingPreferences[workerID],
)

export const selectWorkerMarketingPreferencesToUpdatePayload = createSelector(
  [selectWorkerMarketingPreferencesById, selectPendingConsent, selectWorkerId],
  (currentPreferences, pendingConsent, workerId) => {
    if (!currentPreferences) return null

    const currentPayload = (Object.keys(currentPreferences) as WorkerMarketingPreferences[]).reduce(
      (accum, preference) => ({
        ...accum,
        ...transformPreferenceToPayload(preference, currentPreferences[preference]),
      }),
      {} as Partial<UpdateWorkerMarketingPreferencesPayload>,
    )

    return {
      ...currentPayload,
      ...(pendingConsent
        ? transformPreferenceToPayload(pendingConsent.preference, pendingConsent.value)
        : {}),
      worker_id: workerId,
    } as UpdateWorkerMarketingPreferencesPayload
  },
)
