import { createSelector } from 'reselect'
import { DefaultRootState } from 'react-redux'
import { memoize } from 'lodash-es'

import {
  FetchWorkerHolidayPayAccrualsSucceededPayload,
  // ValidateWorkerHolidayPayHistoricRequestSucceededPayload,
} from './types'

export const selectWorkerHolidayPayAccruals = createSelector(
  (state: DefaultRootState) => state.workerHolidayPay,
  workerHolidayPay =>
    memoize(
      (workerID: number) =>
        workerHolidayPay[workerID]?.accruals ||
        ({
          historic: {},
          weekly: {},
        } as FetchWorkerHolidayPayAccrualsSucceededPayload)
    )
)

export const selectWorkerHolidayPayHistoricRequests = createSelector(
  (state: DefaultRootState) => state.workerHolidayPay,
  workerHolidayPay => memoize((workerID: number) => workerHolidayPay[workerID]?.historicRequests || [])
)

export const selectWorkerHolidayPayHistoricRequestValidation = createSelector(
  (state: DefaultRootState) => state.workerHolidayPay,
  workerHolidayPay => memoize((workerID: number) => workerHolidayPay[workerID]?.validation)
)

export const selectWorkerHolidayPayWeeklyRequests = createSelector(
  (state: DefaultRootState) => state.workerHolidayPay,
  workerHolidayPay => memoize((workerID: number) => workerHolidayPay[workerID]?.weeklyRequests || [])
)
