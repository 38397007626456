// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import entityReducer from './generators/entityReducer'
import * as types from '../actions/action-types'
import * as entityTypes from './generators/entities'

export default entityReducer(
  'workerNetworks',
  {
    [entityTypes.ENTITIES_FETCH_BEGIN]: types.WORKER_NETWORKS_FETCH_BEGIN,
    [entityTypes.ENTITIES_FETCH_SUCCEEDED]: types.WORKER_NETWORKS_FETCH_SUCCEEDED,
    [entityTypes.ENTITIES_FETCH_FAILED]: types.WORKER_NETWORKS_FETCH_FAILED,
    [entityTypes.ENTITY_FETCH_BEGIN]: types.WORKER_NETWORK_FETCH_BEGIN,
    [entityTypes.ENTITY_FETCH_SUCCEEDED]: types.WORKER_NETWORK_FETCH_SUCCEEDED,
    [entityTypes.ENTITY_FETCH_FAILED]: types.WORKER_NETWORK_FETCH_FAILED,
    [entityTypes.ENTITY_MODIFY_BEGIN]: types.WORKER_NETWORK_UPDATE_BEGIN,
    [entityTypes.ENTITY_MODIFY_SUCCEEDED]: types.WORKER_NETWORK_UPDATE_SUCCEEDED,
    [entityTypes.ENTITY_MODIFY_FAILED]: types.WORKER_NETWORK_UPDATE_FAILED,
    [entityTypes.ENTITY_SAVE_BEGIN]: types.WORKER_NETWORK_CREATE_BEGIN,
    [entityTypes.ENTITY_SAVE_SUCCEEDED]: types.WORKER_NETWORK_CREATE_SUCCEEDED,
    [entityTypes.ENTITY_SAVE_FAILED]: types.WORKER_NETWORK_CREATE_FAILED,
  },
  {
    idKey: 'id',
    localEntityMap: true,
    groupBy: ['workerID'],
  }
)
