import { useTrackingTrigger } from '@indeed/flex-tracking-context'
import { get } from 'lodash-es'
import moment from 'moment'
import octicons from 'octicons'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import { connect } from 'react-redux'

import * as bookingsActions from 'syft-acp-core/actions/bookings'
import { downloadAsFileFromApi } from 'syft-acp-core/api/file'
import * as tableType from 'syft-acp-core/components/EntityList/'
import EntityListComponent from 'syft-acp-core/components/EntityList/EntityListComponent'
import InvoicingCSVModal from 'syft-acp-core/components/Modal/InvoicingCSVModal'
import InvoicingGdriveCSVModal from 'syft-acp-core/components/Modal/InvoicingGdriveCSVModal'
import PayrollCSVModal, { csv_types } from 'syft-acp-core/components/Modal/PayrollCSVModal'
import RetrospectiveAgencyHoursModal from 'syft-acp-core/components/Modal/RetrospectiveAgencyHoursModal'
import UserLine from 'syft-acp-core/components/UserAvatar/UserLine'
import { SUPPORTED_COUNTRY_CODES } from 'syft-acp-core/lib/i18n'
import { showModal } from 'syft-acp-core/store/modals/actions'
import { FilterDate, FilterEnum, FilterGroup, FilterText } from 'syft-acp-util/components/FilterForm'
import * as filterTypes from 'syft-acp-util/components/FilterForm/FilterForm'
import entityList from 'syft-acp-util/entityList'
import { formatCurrency, formatDurationAsTime, formatFullName, weekToMoment } from 'syft-acp-util/formatting'
import { dateAddTz } from 'syft-acp-util/time'
import { trackingEvents } from './tracking'

import './index.css'

const tableFormat = timePunchView => [
  { type: tableType.TXT, val: 'id', header: 'ID', numeric: true },
  {
    type: tableType.BOOL,
    val: 'approved',
    headerIcon: octicons.check,
    headerIconAlt: 'Checkmark',
    expl: 'Approved',
  },
  {
    type: tableType.TXT,
    val: e => <UserLine avatarUUID={get(e.employer, 'picture.uuid')} name={e.employer.company_name} />,
    header: 'Employer',
  },
  {
    type: tableType.TXT,
    val: e => <UserLine avatarUUID={get(e.worker, 'profile_picture.uuid')} name={formatFullName(e.worker)} />,
    header: 'Worker',
  },
  {
    type: tableType.TXT,
    val: e => (e.role?.id && e.job.role_id !== e.role.id ? e.role.name : e.job.name),
    header: 'Job role',
  },
  {
    type: tableType.DATETIME,
    val: `${timePunchView}.clock_in`,
    options: data => ({ timeZone: get(data, 'venue_timezone') }),
    header: 'Clock in',
  },
  {
    type: tableType.TXT,
    val: e =>
      !!e[timePunchView].break_duration && formatDurationAsTime(e[timePunchView].break_duration * 1000),
    header: 'Breaks (hrs)',
  },
  {
    type: tableType.DATETIME,
    val: `${timePunchView}.clock_out`,
    options: data => ({ timeZone: get(data, 'venue_timezone') }),
    header: 'Clock out',
  },
  {
    type: tableType.TXT,
    val: e => !!e.job.pay_rate && formatCurrency(e.job.pay_rate.amount, e.job.pay_rate.currency),
    header: 'Pay rate',
  },
  {
    type: tableType.TXT,
    val: e =>
      !!e[timePunchView].billable_duration && formatDurationAsTime(e[timePunchView].billable_duration * 1000),
    header: 'Duration (hrs)',
  },
  {
    type: tableType.TXT,
    val: e => !!e.wage && formatCurrency(e.wage.amount, e.wage.currency),
    header: 'Wage',
  },
]

const filters = (
  <div>
    <FilterGroup title="Week">
      <FilterDate
        name="start_date"
        defaultValue={moment().startOf('isoWeek')}
        caption={{
          icon: 'info',
          message: 'Reports generated will run from Monday to Sunday including your selected dates',
        }}
      />
    </FilterGroup>
    <FilterGroup title="Worker">
      <FilterText name="worker_name" placeholder="Worker name" />
    </FilterGroup>
    <FilterGroup title="Worker ID">
      <FilterText name="worker_id" placeholder="Worker ID" />
    </FilterGroup>
    <FilterGroup title="Employer">
      <FilterText name="employer_name" placeholder="Employer name" />
    </FilterGroup>
    <FilterGroup title="Approved">
      <FilterEnum
        name="approved"
        options={[
          [{ value: '', label: 'All' }],
          [
            { value: '1', label: 'Approved' },
            { value: '0', label: 'Non-approved' },
          ],
        ]}
      />
    </FilterGroup>
  </div>
)

// Note: we're disabling the camelcase check here. These identifiers need to be
// in underscore_case because that's how the API wants them.
// TODO: this should probably be moved to /lib.
/* eslint-disable camelcase */
/**
 *
 * @param {Object?} options
 * @param {string|Date|number} options.start_date
 * @returns {{start_date: Object}}
 */
export const formatCSVParams = ({ start_date }) => {
  const params = {}

  if (start_date) {
    params.start_date = dateAddTz(weekToMoment(start_date))
  }

  return params
}

export const formatWeekParam = ({ start_date }) => {
  const match = start_date.match(/W([0-9]{2})/i)

  if (match && match[1]) {
    return { week_number: match[1] }
  }

  return {}
}

const jobsCSVParams = () => {
  const date = moment().subtract(1, 'month')
  const start_time_gte = date.startOf('month').format()
  const start_time_lte = date.endOf('month').format()
  return {
    start_time_gte,
    start_time_lte,
  }
}

const InvoicingList = ({ query, dispatch }) => {
  const intl = useIntl()
  const triggerEvent = useTrackingTrigger()
  const [timePunchView, setTimePunchView] = useState('worker_time')
  const commonActions = [
    {
      label: 'Download Invoicing data CSV',
      action: () => dispatch(showModal('invoicingCsvModal', query)),
    },
    {
      label: 'Missing pay payroll CSV',
      action: () =>
        dispatch(
          showModal('payrollCsvModal', {
            ...query,
            type: csv_types.missingPayPayroll,
            parameters: { showTime: false },
          }),
        ),
    },
    {
      label: 'Missing pay invoice CSV',
      action: () =>
        dispatch(
          showModal('payrollCsvModal', {
            ...query,
            type: csv_types.missingPayInvoice,
            parameters: { showTime: false },
          }),
        ),
    },
    {
      label: 'Agency hours CSV',
      action: () =>
        dispatch(
          showModal('payrollCsvModal', {
            ...query,
            type: csv_types.agencyShiftWorkers,
            parameters: { showTime: false },
          }),
        ),
    },
    {
      label: 'Retrospective agency hours CSV',
      action: () =>
        dispatch(
          showModal('retrospectiveAgencyHoursModal', {
            ...query,
            type: csv_types.agencyShiftWorkers,
            parameters: { showTime: false },
          }),
        ),
    },
  ]
  const actionFormat = [
    {
      label: 'Download',
      type: filterTypes.TYPE_DROPDOWN,
      buttonType: 'primary',
      global: true,
      items:
        intl.countryCode === SUPPORTED_COUNTRY_CODES.US
          ? [
              {
                label: 'Upload invoicing CSV to Google Drive',
                action: () => dispatch(showModal('invoicingGdriveCsvModal', query)),
              },
              ...commonActions,
            ]
          : [
              {
                label: 'Download payroll CSV file',
                action: () =>
                  dispatch(
                    showModal('payrollCsvModal', {
                      ...query,
                      type: csv_types.common,
                      parameters: { showTime: false },
                    }),
                  ),
              },
              {
                label: 'Download payroll CSV for missing hours',
                action: () =>
                  dispatch(
                    showModal('payrollCsvModal', {
                      ...query,
                      type: csv_types.missingTimes,
                      parameters: { showTime: false },
                    }),
                  ),
              },
              {
                label: 'Upload invoicing data CSV to Google Drive',
                action: () => dispatch(showModal('invoicingGdriveCsvModal', query)),
              },
              {
                label: "Last month's jobs CSV",
                action: () => {
                  triggerEvent(trackingEvents.INVOICING.LAST_MONTHS_JOBS.CLICKED, { ...jobsCSVParams() })
                  downloadAsFileFromApi('/admin/jobs.csv', jobsCSVParams(), 'jobs')
                },
              },
              ...commonActions,
              {
                isDivider: true,
              },
              {
                isHeader: true,
                headerText: 'Legacy CSV files',
              },
              {
                label: 'New workers starting CSV',
                action: () => {
                  triggerEvent(trackingEvents.INVOICING.NEW_WORKERS_STARTING_CSV.CLICKED, { ...query })
                  downloadAsFileFromApi('/admin/workers/payroll.csv', formatCSVParams(query), 'payroll')
                },
              },
              {
                label: 'Overpayments CSV',
                action: () => {
                  triggerEvent(trackingEvents.INVOICING.OVERPAYMENTS_CSV.CLICKED, { ...query })
                  downloadAsFileFromApi('/admin/overpayments.csv', formatWeekParam(query), 'overpayments')
                },
              },
              {
                isDivider: true,
              },
              {
                isHeader: true,
                headerText: 'BETA',
              },
              {
                label: 'Download worker-time payroll CSV file',
                action: () =>
                  dispatch(
                    showModal('payrollCsvModal', {
                      ...query,
                      type: csv_types.workerTime,
                      parameters: { showTime: false },
                    }),
                  ),
              },
            ],
    },
  ]

  const EntityList = entityList(
    EntityListComponent,
    tableFormat(timePunchView),
    filters,
    actionFormat,
    'globalShiftBookings',
    {
      fetchEntities: bookingsActions.fetchGlobalShiftBookings,
    },
    {
      showResultCount: true,
      fullWidth: true,
      header: (
        <div className="time-punch-view-select">
          <span>Time punch view</span>
          <label>
            <input
              type="radio"
              value="worker_time"
              onChange={e => setTimePunchView(e.target.value)}
              checked={timePunchView === 'worker_time'}
            />{' '}
            <span>Worker</span>
          </label>
          <label>
            <input
              type="radio"
              value="client_time"
              onChange={e => setTimePunchView(e.target.value)}
              checked={timePunchView === 'client_time'}
            />{' '}
            <span>Client</span>
          </label>
        </div>
      ),
    },
  )

  const urlGenerator = d => `/listings/view/${d.job.listing_id}`

  return (
    <div>
      <PayrollCSVModal />
      <RetrospectiveAgencyHoursModal />
      <InvoicingCSVModal />
      <InvoicingGdriveCSVModal />
      <EntityList urlGenerator={urlGenerator} />
    </div>
  )
}

InvoicingList.propTypes = {
  query: PropTypes.object,
}

InvoicingList.defaultProps = {
  query: {},
}

export default connect(state => ({ query: state.routing.locationBeforeTransitions.query }))(InvoicingList)
