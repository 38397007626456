// Syft ACP - UI Library <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import './RecordFooter.css'

class RecordFooter extends React.PureComponent {
  static propTypes = {
    children: PropTypes.node.isRequired,
    hasBackground: PropTypes.bool,
    hasTopBorder: PropTypes.bool,
  }

  static defaultProps = {
    hasBackground: false,
    hasTopBorder: true,
  }

  render() {
    const { children, hasBackground, hasTopBorder } = this.props

    return <div className={classNames('acp-record-footer', { hasBackground, hasTopBorder })}>{children}</div>
  }
}

export default RecordFooter
