// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import { createSelector } from 'reselect'
import { get } from 'lodash-es'
import { getDateRange } from 'syft-acp-core/lib/dateFilter'
import { AGENCY_SHIFTS_ENTITY_SETS } from 'syft-acp-core/constants'

/**
 * This function is returning agency shift vacancies for counter
 *
 * @param {{}} state - the redux state
 * @returns {number} Number of vacancies for agency shifts counter
 */
export const getAgencyShiftsVacancies = state => {
  // TODO: import this helper getDateRangeShift from utils after merge
  const getDateRangeShift = getDateRange(0, 1, 'start_time_gte', 'start_time_lte', true, true, true)
  // Default parameters for agency shifts without data only for counter
  const defaultParams = `counters_enabled=true&only_counters=true&start_time_gte=${getDateRangeShift.start_time_gte}&start_time_lte=${getDateRangeShift.start_time_lte}`
  // Format ulr params to special characters
  const formatedDefaultParams = defaultParams.replace(/:/g, '%3A').replace(/\+/g, '%2B')
  return get(state, `${AGENCY_SHIFTS_ENTITY_SETS}.${formatedDefaultParams}.totalVacancies`, 0)
}
/**
 * This selector is returning agency shift vacancies
 */
export const getAgencyShiftsVacanciesSelector = createSelector(
  getAgencyShiftsVacancies,
  shiftVacancies => shiftVacancies
)
