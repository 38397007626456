import { createSelector } from 'reselect'
import { DefaultRootState } from 'react-redux'

const selectWorkerDuplicateAccountsCount = (state: DefaultRootState) => state.workerDuplicateAccountsCount
const selectWorkerId = (_state: DefaultRootState, workerId: number) => workerId

export const selectWorkerDuplicateAccountsCountIsLoading = (state: DefaultRootState) =>
  state.workerDuplicateAccountsCount.isLoadingData

export const selectCurrentWorkerDuplicateAccountsCount = createSelector(
  [selectWorkerDuplicateAccountsCount, selectWorkerId],
  (workerDuplicateAccountsCount, workerID) => workerDuplicateAccountsCount?.entityMap[workerID],
)

export const selectCurrentWorkerDuplicateAccountsCountTotal = createSelector(
  [selectCurrentWorkerDuplicateAccountsCount],
  data => data?.total ?? 0,
)

export const selectCurrentWorkerDuplicateAccountsCountVerified = createSelector(
  [selectCurrentWorkerDuplicateAccountsCount],
  data => data?.verified ?? 0,
)

export const selectCurrentWorkerDuplicateAccountsCountBanned = createSelector(
  [selectCurrentWorkerDuplicateAccountsCount],
  data => data?.banned ?? 0,
)

export const selectCurrentWorkerDuplicateAccountsCountUnverified = createSelector(
  [
    selectCurrentWorkerDuplicateAccountsCountTotal,
    selectCurrentWorkerDuplicateAccountsCountVerified,
    selectCurrentWorkerDuplicateAccountsCountBanned,
  ],
  (total, verified, banned) => total - verified - banned,
)
