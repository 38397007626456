// Syft ACP - UI Library <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import React from 'react'
import PropTypes from 'prop-types'
import { FormControl } from 'react-bootstrap'

import DatePicker from 'syft-acp-atoms/DatePicker'
import { dateAddTz, datetimeToDate } from 'syft-acp-util/time'

import FilterForm from '../FilterForm'
import { filterDecorator } from '../filterDecorator'
import { acpFilterDefaults } from '../dataProps'

class AcpFilterDateRange extends React.PureComponent {
  static propTypes = {
    // Instead of a single 'name', this one requires from and to values.
    nameFrom: PropTypes.string.isRequired,
    nameTo: PropTypes.string.isRequired,
  }

  static defaultProps = {
    ...acpFilterDefaults,
  }

  static requireDebounce = false

  constructor(props) {
    super(props)
    this.state = {
      // If we've got an initial value from the query string, it'll include time and timezone.
      // Reduce it back to a pure date for the form fields to accept.
      valueFrom: (props.initialValue && datetimeToDate(props.initialValue.from)) || '',
      valueTo: (props.initialValue && datetimeToDate(props.initialValue.to)) || '',
    }
  }

  handleChange = (name, valueName, newValue) => {
    // Form fields of type 'date' return only a date without time or timezone.
    // Since the API requires these, add them here.
    const { setValue } = this.props
    const tzNewValue = dateAddTz(newValue)
    setValue(name, tzNewValue)
    // Internally we should always keep the plain date value,
    // since that's all the form field accepts.
    this.setState({ [valueName]: newValue })
  }

  onDateFromChange = (name, value) => this.handleChange(name, 'valueFrom', value)
  onDateToChange = (name, value) => this.handleChange(name, 'valueTo', value)

  render() {
    const { isDisabled, nameFrom, nameTo } = this.props
    const { valueFrom, valueTo } = this.state
    return (
      <FilterForm multipleFields>
        <FormControl
          componentClass={DatePicker}
          value={valueFrom}
          name={nameFrom}
          onChange={this.onDateFromChange}
          disabled={isDisabled}
        />
        <FormControl
          componentClass={DatePicker}
          value={valueTo}
          name={nameTo}
          onChange={this.onDateToChange}
          disabled={isDisabled}
        />
      </FilterForm>
    )
  }
}

export default filterDecorator(AcpFilterDateRange)
