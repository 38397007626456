import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import './AcpCellPlaceholder.css'

/**
 * Used for when a page is loading or displaying an error, and there
 * is no real data to show. This prints a line inside of a cell to indicate
 * that some content will be there at some point in the future.
 */
export default class AcpCellPlaceholder extends React.PureComponent {
  static propTypes = {
    type: PropTypes.string.isRequired,
    typeGroup: PropTypes.string.isRequired,
    isLoading: PropTypes.bool,
  }

  static defaultProps = {
    isLoading: false,
  }

  render() {
    const { type, typeGroup, isLoading } = this.props
    const width = Math.random() * 100
    return (
      <div className={classNames('acp-cell-placeholder', `type-${type}`, `type-group-${typeGroup}`, { isLoading })}>
        <div className="acp-placeholder-line">
          <div className="acp-placeholder-line-inner" style={{ width: `${width}%` }} />
        </div>
        <div className="acp-placeholder-spacer">&nbsp;</div>
      </div>
    )
  }
}
