import * as React from 'react'
import { Form } from 'react-final-form'
import arrayMutators from 'final-form-arrays'
import { filter, get, isEmpty, isEqual } from 'lodash-es'
import moment from 'moment'

import { listingShiftBreakTypes } from 'syft-acp-core/constants'
import { ListingShiftBreakDetailsFormUI } from './ListingShiftBreakDetailsFormUI'

export const ListingShiftBreakDetailsForm = ({ shiftBooking, timeZone, attr, onSubmit, wrapper }) => {
  const initialValues = React.useMemo(
    () => ({
      [attr]: (isEmpty(get(shiftBooking, attr))
        ? [{ type: listingShiftBreakTypes.clocked, clock_in_time: null, clock_out_time: null }]
        : get(shiftBooking, attr)
      ).map(detail => ({
        ...detail,
        type: detail.type || listingShiftBreakTypes.clocked,
      })),
    }),
    [attr, shiftBooking]
  )

  const isValid = React.useCallback(
    fields => {
      const breakDetails = fields[attr]
      const breakDetailsErrors = breakDetails.map(({ clock_in_time, clock_out_time }, i, arr) => {
        if (clock_in_time && clock_out_time) {
          if (moment(clock_in_time).isSameOrAfter(clock_out_time)) {
            return { field: 'Start of break time should be before the end of break time' }
          }

          const hasOverlappingBreaks = arr.slice(0, i).some(
            breakDetail =>
              // duplicate
              isEqual([clock_in_time, clock_out_time], [breakDetail.clock_in_time, breakDetail.clock_out_time]) ||
              // is this break between previous breaks?
              [clock_in_time, clock_out_time].some(time =>
                moment(time).isBetween(breakDetail.clock_in_time, breakDetail.clock_out_time)
              ) ||
              // is previous breaks between this break?
              [breakDetail.clock_in_time, breakDetail.clock_out_time].some(time =>
                moment(time).isBetween(clock_in_time, clock_out_time)
              )
          )

          if (hasOverlappingBreaks) {
            return { field: 'Your breaks overlap. Please update.' }
          }
        }

        return undefined
      })
      const hasErrors = breakDetailsErrors.some(d => !!d)

      return {
        ...(hasErrors ? { break_details: breakDetailsErrors } : {}),
      }
    },
    [attr]
  )

  const normalizeBreaks = React.useCallback(
    values =>
      filter(values, ({ break_id, clock_in_time, clock_out_time, type }) => {
        switch (type) {
          case listingShiftBreakTypes.clocked:
            return clock_in_time && clock_out_time
          case listingShiftBreakTypes.deleted:
            return break_id
          default:
            break
        }
      }),
    []
  )

  const onFormSubmit = React.useCallback(
    fields => {
      onSubmit(normalizeBreaks(fields[attr]))
    },
    [attr, normalizeBreaks, onSubmit]
  )

  return (
    <Form
      initialValues={initialValues}
      onSubmit={onFormSubmit}
      validate={isValid}
      mutators={arrayMutators}
      wrapper={wrapper}
      component={ListingShiftBreakDetailsFormUI}
      shiftBooking={shiftBooking}
      timeZone={timeZone}
      attr={attr}
    />
  )
}
