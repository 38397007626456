import { takeLatest } from 'redux-saga/effects'

import * as api from 'syft-acp-core/api/resources/agency-profile'
import { notificationCall } from 'syft-acp-core/sagas/calls'
import { AGENCY_PROFILE_UPDATE_SUCCEEDED, AGENCY_PROFILE_UPDATE_FAILED } from 'syft-acp-core/actions/action-types'

import { takeLatestApiCall } from './helpers'

export default function* root() {
  yield takeLatestApiCall('AGENCY_PROFILE_FETCH', api.fetchAgencyProfile)
  yield takeLatestApiCall('AGENCY_PROFILE_UPDATE', api.saveAgencyProfile)
  yield takeLatest([AGENCY_PROFILE_UPDATE_SUCCEEDED, AGENCY_PROFILE_UPDATE_FAILED], notificationCall)
}
