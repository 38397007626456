import React from 'react'
import { Col, Grid, Row } from 'react-bootstrap'

import RecordControls from 'syft-acp-core/components/RecordSection/RecordControls'
import { EmployerPreferenceEntity } from 'syft-acp-core/store/employerPreferences/types'
import { Button } from 'syft-acp-atoms/Button'
import NotifyBar from 'syft-acp-atoms/NotifyBar'
import LoadingIndicator from 'syft-acp-util/components/LoadingIndicator'
import { RecordSegment } from 'syft-acp-uikit/components/AcpComponent/componentSegments'

import PreferencesListWrapper, { OwnProps, RenderProps } from '../PreferencesListWrapper'
import PreferenceDetail from '../PreferenceDetail'

export const isRootList = (entity: EmployerPreferenceEntity, jobID?: number, workLocationID?: number) =>
  (!jobID && !workLocationID) || (jobID === entity.job_id && workLocationID === entity.work_location_id)

const PreferencesListPage = ({
  data,
  isBusy,
  title,
  onSave,
  isPristine,
  lastMessage,
  onEntityUpdate,
  jobID,
  workLocationID,
}: RenderProps) => {
  const notificationMessage = lastMessage ? (
    <NotifyBar noMargin severity="error">
      {lastMessage}
    </NotifyBar>
  ) : (
    'No preferences found'
  )
  return (
    <Grid>
      <Row>
        <Col sm={12}>
          <RecordControls isDisabled={isBusy}>
            <h3>{title}</h3>
            <div className="right">
              <LoadingIndicator loadingState={isBusy} />
              <Button kind="success" onClick={onSave} disabled={isBusy || isPristine}>
                Save
              </Button>
            </div>
          </RecordControls>
        </Col>
      </Row>
      {!lastMessage && data?.length ? (
        data.map(entity => (
          <Row key={entity.id}>
            <Col sm={12}>
              <PreferenceDetail
                id={entity.id}
                onEntityUpdate={onEntityUpdate}
                root={isRootList(entity, jobID, workLocationID)}
              />
            </Col>
          </Row>
        ))
      ) : (
        <RecordSegment noPadding>{notificationMessage}</RecordSegment>
      )}
    </Grid>
  )
}

const PreferencesListPageContainer = (props: Omit<OwnProps, 'render'>) => (
  <PreferencesListWrapper {...props} render={renderProps => <PreferencesListPage {...renderProps} />} />
)
export default PreferencesListPageContainer
