import * as types from './action-types'

export const fetchCommissions = ({ options }) => ({
  type: types.COMMISSIONS_FETCH_BEGIN,
  options,
})

export const stopCommission = options => ({
  type: types.STOP_COMMISSION_BEGIN,
  options,
})

export const createCommission = options => ({
  type: types.COMMISSION_CREATE_BEGIN,
  options,
})
