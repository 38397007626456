// Transfrom attributes in events to user friendly labels
export const TRANSFORM_KEYS_TO_LABELS = {
  start_time: 'Start time',
  end_time: 'End time',
  pay_rate: 'Employer rate',
  stop_offers_for_worker_type: 'Stop offers',
  bookable_individually: 'Bookable individually',
  offer_to: 'Offer to',
  offer_to_syft: 'Offer to',
  desc: 'Job description',
}

// Filter attributes what users required
export const FILTERED_DETAILS = [
  'start_time',
  'end_time',
  'bookable_individually',
  'skill_id',
  'pay_rate',
  'stop_offers_for_worker_type',
  'uplifted_pay_rate',
  'offer_to',
  'offer_to_syft',
  'desc',
]
