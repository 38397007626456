// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

export const SHIFTS_ENTITY_SETS = 'shifts.entitySets'
export const AGENCY_SHIFTS_ENTITY_SETS = 'agencyShifts.entitySets'
// Key used to store our data in localStorage.
export const keyPrefix = `flex.`
export const listingShiftBreakTypes = {
  deleted: 'delete_break',
  clocked: 'clock_break',
}
export const CERT = 'certification'
export const LEGAL = 'legal compliance'
export const CLIENT = 'client compliance'
