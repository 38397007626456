import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import Acp from 'syft-acp-uikit'
import {
  fetchWorkerHolidayPayHistoricRequests,
  makeWorkerHolidayPayHistoricRequest,
  resetValidation,
} from 'syft-acp-core/store/worker-holiday-pay/actions'
import { WorkerHolidayPayHistoricRequestsEntity } from 'syft-acp-core/store/worker-holiday-pay/types'
import {
  selectWorkerHolidayPayAccruals,
  selectWorkerHolidayPayHistoricRequests,
} from 'syft-acp-core/store/worker-holiday-pay/selectors'
import HistoricHolidayPayModal from 'syft-acp-core/components/Modal/HistoricHolidayPayModal'
import DeleteHistoricHolidayPayModal from 'syft-acp-core/components/Modal/DeleteHistoricHolidayPayModal'
import { showModal } from 'syft-acp-core/store/modals/actions'

import {
  WorkerHolidayPayHistoricRequestsProps as Props,
  WorkerHolidayPayHistoricRequestsOwnProps as OwnProps,
} from './WorkerHolidayPayHistoricRequests.types'

export const storeConnector = connect(
  (state, props: OwnProps) => ({
    requests: selectWorkerHolidayPayHistoricRequests(state)(props.workerID),
    historicAccrualsAvailable: selectWorkerHolidayPayAccruals(state)(props.workerID).historic.available || 0,
    isLoading: state.workerHolidayPay.isLoadingHistoricRequests,
  }),
  dispatch => ({
    actions: bindActionCreators(
      { fetchWorkerHolidayPayHistoricRequests, makeWorkerHolidayPayHistoricRequest, resetValidation, showModal },
      dispatch
    ),
  })
)

const modalNames = {
  CREATE: 'historicHolidayPayModal',
  DELETE: 'deleteHistoricHolidayPayModal',
} as const

const dateTimeFormat = { hour: undefined, minute: undefined, second: undefined, timeZoneName: undefined }

const selectAmount = (row: WorkerHolidayPayHistoricRequestsEntity) => ({
  amount: row.amount,
  currency: row.holiday_rate_currency,
})

const selectHolidayRate = (row: WorkerHolidayPayHistoricRequestsEntity) => ({
  amount: row.holiday_rate,
  currency: row.holiday_rate_currency,
})

const WorkerHolidayPayHistoricRequests = ({
  actions,
  isLoading,
  historicAccrualsAvailable,
  requests,
  workerID,
}: Props) => {
  useEffect(() => {
    actions.resetValidation(workerID)
    actions.fetchWorkerHolidayPayHistoricRequests({ options: { workerID } })
  }, [actions, workerID])

  const openModal =
    (modalName: typeof modalNames[keyof typeof modalNames]) => (e: React.MouseEvent<HTMLButtonElement>) => {
      actions.showModal(modalName, {}, null, e)
    }

  return (
    <>
      <Acp.EntityList
        data={{ data: requests }}
        isLoading={isLoading}
        idKeyValue="id"
        isIntegrated
        hasMinimumSize
        actionsInline
      >
        <Acp.Actions>
          <Acp.Header>Requests from historic holiday pay pot</Acp.Header>
          <Acp.Button
            title={historicAccrualsAvailable ? undefined : 'No historic holiday pay available'}
            disabled={!historicAccrualsAvailable}
            onClick={openModal(modalNames.CREATE)}
          >
            Create request
          </Acp.Button>
          <Acp.Button
            title={requests.length ? undefined : 'No historic holiday pay requests'}
            disabled={!requests.length}
            onClick={openModal(modalNames.DELETE)}
          >
            Delete request
          </Acp.Button>
        </Acp.Actions>
        <Acp.Table>
          <Acp.Col.Text value="id" header="ID" align="left" isMinimal />
          <Acp.Col.DateTime value="created_at" header="Created at" align="left" isMinimal />
          <Acp.Col.Money value={selectAmount} header="Amount" align="left" isMinimal isNumeric />
          <Acp.Col.Money value={selectHolidayRate} header="Holiday pay rate" align="left" isMinimal isNumeric />
          <Acp.Col.DateTime value="start_date" header="Start date" align="left" isMinimal options={dateTimeFormat} />
          <Acp.Col.DateTime value="end_date" header="End date" align="left" isMinimal options={dateTimeFormat} />
          <Acp.Col.DateTime
            value="payout_period_beginning_on"
            header="Payout period start date"
            align="left"
            isMinimal
            options={dateTimeFormat}
          />
          <Acp.Col.DateTime
            value="working_week_ending_on"
            header="Week ending"
            align="left"
            isMinimal
            options={dateTimeFormat}
          />
          <Acp.Col.DateTime value="pays_on" header="Pays on" align="left" isMinimal options={dateTimeFormat} />
          <Acp.Col.DateTime value="deleted_at" header="Deleted at" align="left" isMinimal options={dateTimeFormat} />
        </Acp.Table>
      </Acp.EntityList>
      <HistoricHolidayPayModal modalName={modalNames.CREATE} workerID={workerID} />
      <DeleteHistoricHolidayPayModal modalName={modalNames.DELETE} workerID={workerID} />
    </>
  )
}

export default storeConnector(WorkerHolidayPayHistoricRequests)
