import * as React from 'react'
import { Field } from 'react-final-form'
import { ControlLabel, FormControl, FormGroup } from 'react-bootstrap'
import classnames from 'classnames'

import { getValidator, getValidationState } from '../AddWorkerMissingPayModal/helpers'
import { TextFieldProps as Props } from './TextField.types'

const TextField = ({
  name,
  label,
  required = false,
  type = 'text',
  placeholder = '',
  disabled = false,
  ...rest
}: Props) => (
  <Field name={name} validate={getValidator(required)} {...rest}>
    {({ input, meta }) => (
      <FormGroup validationState={getValidationState(meta)} className={classnames({ required })}>
        <ControlLabel>{label}</ControlLabel>
        {/* @ts-expect-error */}
        <FormControl disabled={disabled} type={type} placeholder={placeholder} {...input} />
      </FormGroup>
    )}
  </Field>
)

export default TextField
