import React from 'react'
import { Button } from 'syft-acp-atoms/Button'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { showConfirmModal } from 'syft-acp-core/store/modals/actions'
import { deleteWorkerNetwork } from 'syft-acp-core/actions/worker-networks'

import { DeleteNetworkButtonProps as Props } from './WorkerNetworkDetail.types'

const DeleteNetworkButton = ({ ownerID, ownerType, workerID, onDelete, actions }: Props) => {
  const deleteNetwork = () => {
    actions.showConfirmModal({
      modalHeader: 'Delete worker from network?',
      question: 'Are you sure? The worker will be removed from this network.',
      onConfirm: () => {
        const idType = ownerType === 'Employer' ? 'employerID' : 'locationID'
        actions.deleteWorkerNetwork(workerID, { [idType]: ownerID })
        onDelete()
      },
    })
  }
  return (
    <Button kind="danger" onClick={deleteNetwork}>
      Delete
    </Button>
  )
}

export const storeConnector = connect(null, dispatch => ({
  actions: bindActionCreators(
    {
      showConfirmModal,
      deleteWorkerNetwork,
    },
    dispatch
  ),
}))
export default storeConnector(DeleteNetworkButton)
