import { put, takeLatest } from 'redux-saga/effects'

import { takeLatestApiCall } from 'syft-acp-core/sagas/helpers'
import * as types from './action-types'

import * as workerTypes from '../workers/action-types'

import * as api from './api'
import { fetchWorkerDuplicateAccountsCount } from './actions'

function* yieldFetchDuplicateAccountsCount(action: any) {
  yield put(fetchWorkerDuplicateAccountsCount(action.request.id))
}

export default function* root() {
  yield takeLatestApiCall(
    types.WORKER_DUPLICATE_ACCOUNTS_COUNT_FETCH_BEGIN,
    api.fetchWorkerDuplicateAccountsCount,
  )
  // Refetch duplicates on worker changes
  yield takeLatest(
    [workerTypes.WORKER_SAVE_SUCCEEDED, workerTypes.WORKER_USER_DELETE_SUCCEEDED],
    yieldFetchDuplicateAccountsCount,
  )
}
