// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import { get, pickBy } from 'lodash-es'
import React from 'react'
import { history } from 'syft-acp-core/history'
import * as types from 'syft-acp-core/actions/action-types'
import * as notifyFn from 'syft-acp-core/actions/notifications'
import { store } from 'syft-acp-core/store'
import agencyShiftHistoryTypes from 'syft-acp-core/store/agency-shift-history/action-types'
import * as AgencyUnfilledAllocationsTypes from 'syft-acp-core/store/agency-unfilled-allocations/action-types'
import * as AgencyLoginTypes from 'syft-acp-core/store/agencyLogin/action-types'
import * as compliancePoliciesTypes from 'syft-acp-core/store/compliancePolicies/action-types'
import employerPreferencesTypes from 'syft-acp-core/store/employerPreferences/action-types'
import * as EmployerTypes from 'syft-acp-core/store/employers/action-types'
import externalEmployersTypes from 'syft-acp-core/store/externalEmployers/action-types'
import externalWorkLocationsTypes from 'syft-acp-core/store/externalWorkLocations/action-types'
import * as FulfilmentShiftsTypes from 'syft-acp-core/store/fulfilmentShifts/action-types'
import * as AdhocShiftTypes from 'syft-acp-core/store/adhocShiftBookings/action-types'
import matchingExplainabilityTypes from 'syft-acp-core/store/matching-explainability/action-types'
import usersTypes from 'syft-acp-core/store/users/action-types'
import * as adminAlertTypes from 'syft-acp-util/alerts/alertTypes'

import { rateCardNotificationMessages } from 'syft-acp-core/components/RateCard/constants'

import * as newEmployerRateCardsTypes from 'syft-acp-core/store/employerRateCards/action-types'
import workerReferralBonuses from 'syft-acp-core/store/workerReferralBonuses/action-types'

/** Dispatches a notification to the store directly. */
export const notify = (...args) => {
  return store.dispatch(notifyFn.notify(...args))
}

export const getMatchingExplainabilityErrorMessage = (errorMessage, request) => {
  if (errorMessage.includes('WorkerNotIndexedError')) {
    return "Failed to complete this worker's filter check. Please check that they are verified (at least 1 role), have completed their onboarding (Availability, City, and valid RTW) and are not banned by Flex."
  }
  if (errorMessage.includes('JobNotIndexedError')) {
    return `This tool is only applicable to jobs with upcoming shifts. Shifts within Job ID ${request?.payload?.jobId} have been completed or have already started, and can not be offered to individual workers.`
  }
  return errorMessage
}

/**
 * Display a single desktop notification. Due to the nature of desktop notifications,
 * we can't display a severity level and we can only include the main title, or the
 * message if the title is not available.
 */
export const showDesktopNotification = payload => {
  const displayMessage = payload.title ? payload.title : payload.message
  // Only proceed if there's something to display.
  if (!displayMessage) {
    return
  }
  // Note: the Notification object is available globally in the browser.
  if (Notification.permission === 'granted') {
    // eslint-disable-next-line no-new
    new Notification(displayMessage)
  }
}

/**
 * Returns an object containing errors returned by the API for consumption by
 * the notification system. Includes at least a 'message', and optionally an attribute 'children'
 * with an HTML node of fields that were erroneous.
 * TODO: consider moving the JSX for this to the Notifier component.
 *
 * @param {Object} errorObj Response object returned by the API containing errors
 * @param {Boolean} includeReasons Whether to include an HTML list of error reasons
 * @param {Boolean} retainOnReasons Whether we will not auto-dismiss if error reasons are present
 * @returns {Object} Options to send to the notification system
 */
export const getErrors = (errorObj, includeReasons, retainOnReasons = true) => {
  const rawMessage = get(errorObj, 'body.error_description', 'The server returned no error message.')
  const debug = get(errorObj, 'body.debug', null)
  const message =
    rawMessage.toLowerCase().trim() === 'invalid parameters' && debug.message
      ? `${rawMessage}: ${debug.message}.`
      : rawMessage

  // Include a list of error reasons. Normally available in 'error_details'.
  // Each reason must be an object: { [field: 'field_name',] message: 'field_name is not valid' }
  let reasons = []
  if (includeReasons) {
    reasons =
      get(errorObj, 'body.error_details.fields.length') > 0
        ? get(errorObj, 'body.error_details.fields', [])
        : get(errorObj, 'body.error_details.nested[0].items[0].fields', [])
  }

  return pickBy(
    {
      message,
      // Return autoDismiss: 0 if we have failure reasons and are displaying them.
      autoDismiss: retainOnReasons && includeReasons && reasons.length > 0 ? 0 : null,
      children: (
        <ul className="error-reasons">
          {reasons.map((reason, n) =>
            reason.field ? (
              <li key={reason.field}>
                <em>{reason.field}</em>
                {reason.message}
              </li>
            ) : (
              <li key={`reason_n_${n}`}>{reason.message}</li>
            ),
          )}
        </ul>
      ),
      // Filter out the null or undefined values.
    },
    n => n != null,
  )
}

/**
 * Returns a human readable notification message object for a given action type.
 * All supported action types have a corresponding message; if a type is not supported,
 * null is returned instead.
 *
 * @param {String} type Action type for which to return a notification message
 * @param {Object} object Object correlated with action
 * @param {Object} object Request correlated with action
 * @returns {Object|null} Title, (optionally) a message, and severity level; or null
 */
export const getNotificationText = (type, object, request) => {
  switch (type) {
    case types.APPEALS_ASSIGN_FAILED:
      return { title: 'Failed to assign appeals', level: 'error', ...getErrors(object, true) }
    case types.APPEAL_CREATE_FAILED:
      return { title: 'Failed to create appeal', level: 'error', ...getErrors(object, true) }
    case types.APPEAL_CREATE_SUCCEEDED:
      return { title: 'Created appeal', level: 'success' }
    case types.APPEAL_UPDATE_FAILED:
      return { title: 'Failed to update appeal', level: 'error', ...getErrors(object, true) }
    case types.APPEAL_UPDATE_SUCCEEDED:
      return { title: 'Updated appeal', level: 'success' }
    case types.CONDUCT_REVIEWS_ASSIGN_FAILED:
      return { title: 'Failed to assign conduct reviews', level: 'error', ...getErrors(object, true) }
    case types.CONDUCT_REVIEW_UPDATE_FAILED:
      return { title: 'Failed to update conduct review', level: 'error', ...getErrors(object, true) }
    case types.CONDUCT_REVIEW_UPDATE_SUCCEEDED:
      return { title: 'Updated conduct review', level: 'success' }
    case types.AGENCY_ACCOUNTS_UPDATE_SUCCEEDED:
      return { title: 'Your request is being processed', level: 'success' }
    case AgencyUnfilledAllocationsTypes.AGENCY_UNFILLED_ALLOCATIONS_DELETE_SUCCEEDED:
      return { title: 'Your request is being processed', level: 'success' }
    case AgencyUnfilledAllocationsTypes.AGENCY_UNFILLED_ALLOCATIONS_DELETE_FAILED:
      return { title: 'Failed to cascade shifts', level: 'error', ...getErrors(object, true) }
    case AgencyUnfilledAllocationsTypes.AGENCY_UNFILLED_ALLOCATIONS_FETCH_FAILED:
      return { title: 'Failed to fetch unfilled allocations', level: 'error', ...getErrors(object, true) }
    case AgencyUnfilledAllocationsTypes.AGENCY_BULK_DELETE_ALLOCATIONS_SUCCEEDED:
      return { title: 'Your request is being processed', level: 'success' }
    case AgencyUnfilledAllocationsTypes.AGENCY_BULK_DELETE_ALLOCATIONS_FAILED:
      return { title: 'Failed to delete unfilled allocations', level: 'error', ...getErrors(object, true) }
    case types.IMPORT_FILE_SUCCEEDED:
      return { title: 'Import saved!', level: 'success' }
    case types.IMPORT_FILE_FAILED:
      return { title: 'Failed to save import', level: 'error', ...getErrors(object, true) }
    case AgencyLoginTypes.AGENCY_LOGIN_AS_FAILED:
      return { title: 'Failed to login', level: 'error', ...getErrors(object, true) }
    case types.EMPLOYER_LOGIN_AS_FAILED:
      return { title: 'Failed to login', level: 'error', ...getErrors(object, true) }
    case types.WORKER_TRANSFER_RESEND_INVITATION_SUCCEEDED:
      return { title: 'Invitation sent', level: 'success' }
    case types.WORKER_TRANSFER_RESEND_INVITATION_FAILED:
      return { title: 'Failed to send invitation', level: 'error', ...getErrors(object, true) }
    case types.WORKER_HOLIDAY_PAY_HISTORIC_REQUEST_MAKE_SUCCEEDED:
      return { title: 'Historic holiday request successful', level: 'success' }
    case types.WORKER_HOLIDAY_PAY_HISTORIC_REQUEST_DELETE_SUCCEEDED:
      return { title: 'Historic holiday request deleted', level: 'success' }
    case types.WORKER_HOLIDAY_PAY_HISTORIC_REQUEST_MAKE_FAILED:
      return {
        title: 'Failed to create historic holiday request',
        level: 'error',
        ...getErrors(object, true),
      }
    case types.WORKER_HOLIDAY_PAY_HISTORIC_REQUEST_DELETE_FAILED:
      return {
        title: 'Failed to delete historic holiday request',
        level: 'error',
        ...getErrors(object, true),
      }
    case types.WORKER_HOLIDAY_PAY_HISTORIC_REQUEST_VALIDATE_FAILED:
      return {
        title: 'Failed to validate historic holiday request',
        level: 'error',
        ...getErrors(object, true),
      }
    case types.ADMIN_TIMESHEETS_BULK_EDIT_SUCCEEDED:
      return {
        title: 'Bulk edit request succeeded. Retroactive edit reason was saved.',
        level: 'success',
      }
    case types.ADMIN_TIMESHEETS_BULK_EDIT_FAILED:
      return {
        title: 'Bulk edit request failed. Retroactive edit reason was not saved.',
        level: 'error',
        ...getErrors(object, true),
      }
    case types.WORKER_HOLIDAY_PAY_WEEKLY_REQUEST_MAKE_SUCCEEDED:
      return { title: 'Weekly holiday request successful', level: 'success' }
    case types.WORKER_HOLIDAY_PAY_WEEKLY_REQUEST_MAKE_FAILED:
      return { title: 'Failed to create weekly holiday request', level: 'error', ...getErrors(object, true) }
    case agencyShiftHistoryTypes.AGENCY_SHIFT_HISTORIES_FETCH_FAILED:
      return { title: 'Failed to load agency shift history', level: 'error', ...getErrors(object, true) }
    case types.UPLOAD_TRANSFERRED_WORKER_CSV_SUCCEEDED:
      return { title: 'Upload complete', level: 'success' }
    case types.UPLOAD_TRANSFERRED_WORKER_CSV_FAILED:
      return { title: 'Failed to import worker CSV', level: 'error', ...getErrors(object, true) }
    case types.AGENCY_ACCOUNTS_UPDATE_FAILED:
      return { title: 'Failed to save agency account', level: 'error', ...getErrors(object, true) }
    case types.WORKER_DOCUMENT_DELETE_FAILED:
      return { title: 'Failed to delete document', level: 'error', ...getErrors(object, true) }
    case types.AGENCIES_LIST_CREATE_FAILED:
      return { title: 'Failed to save agency', level: 'error', ...getErrors(object, true) }
    case types.AGENCIES_LIST_CREATE_SUCCEEDED:
      return { title: 'Agency saved', level: 'success' }
    case types.AGENCY_PLATFORM_DELETE_FAILED:
      return { title: 'Failed to delete employer', level: 'error', ...getErrors(object, true) }
    case types.AGENCY_PLATFORM_DELETE_SUCCEEDED:
      return { title: 'Employer deleted successfully', level: 'success' }
    case types.AGENCY_PROFILE_UPDATE_SUCCEEDED:
      return { title: 'Agency updated', level: 'success' }
    case types.AGENCY_PROFILE_UPDATE_FAILED:
      return { title: 'Failed to update agency', level: 'error', ...getErrors(object, true) }
    case types.WORKER_STRIKE_CREATE_FAILED:
      return { title: 'Failed to create strike', level: 'error', ...getErrors(object, true) }
    case types.WORKER_DOCUMENT_DELETE_SUCCEEDED:
      return { title: 'Document deleted successfully', level: 'success' }
    case types.WORKER_DOCUMENT_SAVE_FAILED:
      return { title: 'Failed to save document', level: 'error', ...getErrors(object, true) }
    case types.WORKER_DOCUMENT_SAVE_SUCCEEDED:
      return { title: 'Document saved successfully', level: 'success' }
    case types.BOOKINGS_SAVE_FAILED:
      return { title: 'Failed to save booking changes', level: 'error', ...getErrors(object, true) }
    case types.BOOKINGS_FETCH_FAILED:
      return { title: 'Could not fetch worker details.', level: 'error', ...getErrors(object, true) }
    case types.STOP_COMMISSION_SUCCEEDED:
      return { title: 'Commission stopped.', level: 'success' }
    case types.STOP_COMMISSION_FAILED:
      return { title: 'Stop commission failed.', level: 'error', ...getErrors(object, true) }
    case types.COMMISSION_CREATE_SUCCEEDED:
      return { title: 'Commission was created.', level: 'success' }
    case types.COMMISSION_CREATE_FAILED:
      return { title: 'Add new commission failed.', level: 'error', ...getErrors(object, true) }
    case types.EMPLOYER_NETWORKS_FETCH_FAILED:
      return { title: 'Failed to get network data.', level: 'error', ...getErrors(object, true) }
    case types.BOOKINGS_SAVE_SUCCEEDED: {
      if (object?.worker) {
        return {
          title: `${object?.worker?.first_name} ${object?.worker?.last_name}'s long term assignment has successfully ended.`,
          level: 'success',
        }
      }
      return { title: 'Bookings saved successfully', level: 'success' }
    }
    case types.BLOCK_WORKER_CLIENT_SUCCEEDED: {
      if (object?.work_location_ids) {
        return { title: 'Worker blocked by venue.', level: 'success' }
      }
      return { title: 'Worker blocked by empolyer.', level: 'success' }
    }
    case types.BLOCK_WORKER_CLIENT_FAILED:
      return { title: 'Failed to block worker by empolyer.', level: 'error', ...getErrors(object, true) }
    case types.AGENCY_BOOKING_SAVE_FAILED:
      return { title: 'Failed to save agency booking changes', level: 'error', ...getErrors(object, true) }
    case types.AGENCY_BOOKING_SAVE_SUCCEEDED:
      return { title: 'Agency booking saved successfully', level: 'success' }
    case types.WORKER_SAVE_SUCCEEDED:
      return { title: 'Worker saved successfully', level: 'success' }
    case types.WORKER_SAVE_FAILED:
      return { title: 'Unable to save worker', level: 'error', ...getErrors(object, true) }
    case types.WORKER_USER_DELETE_SUCCEEDED:
      return { title: 'User deleted successfully', level: 'success' }
    case AdhocShiftTypes.ADHOC_SHIFT_FETCH_FAILED:
      return { title: 'Failed to fetch Ad-Hoc shift status', level: 'error', ...getErrors(object, true) }
    case AdhocShiftTypes.ADHOC_SHIFT_APPROVE_SUCCEEDED:
      return { title: 'Ad-Hoc shift was approved successfully', level: 'success' }
    case AdhocShiftTypes.ADHOC_SHIFT_APPROVE_FAILED:
      return { title: 'Unable to approve Ad-Hoc shift', level: 'error', ...getErrors(object, true) }
    case AdhocShiftTypes.ADHOC_SHIFT_REJECT_SUCCEEDED:
      return { title: 'Ad-Hoc shift was rejected successfully', level: 'success' }
    case AdhocShiftTypes.ADHOC_SHIFT_REJECT_FAILED:
      return { title: 'Unable to reject Ad-Hoc shift', level: 'error', ...getErrors(object, true) }
    case EmployerTypes.EMPLOYER_APPROVE_SUCCEEDED:
      return {
        title: `Employer was ${object.approved_at ? 'verified' : 'unverified'}`,
        level: 'success',
      }
    case EmployerTypes.EMPLOYER_APPROVE_FAILED:
      return {
        title: `Employer verification failed`,
        level: 'error',
        ...getErrors(object, true),
      }
    case EmployerTypes.EMPLOYER_SAVE_SUCCEEDED:
      return { title: 'Employer saved successfully', level: 'success' }
    case EmployerTypes.EMPLOYER_LOGIN_AS_FAILED:
      return { title: 'Could not get employer login URL', level: 'error', ...getErrors(object, true) }
    case EmployerTypes.EMPLOYER_SAVE_FAILED:
      return { title: 'Unable to save employer', level: 'error', ...getErrors(object, true) }
    case EmployerTypes.EMPLOYER_MIGRATE_SUCCEEDED:
      return {
        title: 'Thank you, you will be notified of the outcome within alerts and close modal',
        level: 'success',
      }
    case usersTypes.USER_BAN_SUCCEEDED:
      return {
        title: `User ${object.banned ? 'banned' : 'unbanned'} successfully`,
        level: 'success',
      }
    case usersTypes.USER_BAN_FAILED:
      return { title: 'Unable to ban/unban user', level: 'error' }
    case usersTypes.USER_PATCH_SUCCEEDED:
      return {
        title: `User settings updated successfully`,
        level: 'success',
      }
    case usersTypes.USER_PATCH_FAILED:
      return {
        title: `Unable to update user settings`,
        level: 'error',
        ...getErrors(object, true),
      }
    case types.EMPLOYER_VENUE_SAVE_SUCCEEDED:
      return { title: 'Venue saved successfully', level: 'success' }
    case types.EMPLOYER_VENUE_SAVE_FAILED:
      return { title: 'Could not save venue', level: 'error', ...getErrors(object) }
    case types.EMPLOYER_VENUE_DELETE_SUCCEEDED:
      return { title: 'Venue deleted successfully', level: 'success' }
    case types.EMPLOYER_VENUE_DELETE_FAILED:
      return { title: 'Venue cannot be deleted as it is used by job listings', level: 'error' }
    case types.EMPLOYER_VENUE_AREA_SAVE_SUCCEEDED:
      return { title: 'Venue area saved successfully', level: 'success' }
    case types.EMPLOYER_VENUE_AREA_SAVE_FAILED:
      return { title: 'Could not save venue area', level: 'error', ...getErrors(object) }
    case types.EMPLOYER_VENUE_AREA_DELETE_SUCCEEDED:
      return { title: 'Venue area deleted successfully', level: 'success' }
    case types.EMPLOYER_VENUE_AREA_DELETE_FAILED:
      return { title: 'Venue area could not be deleted', level: 'error', ...getErrors(object) }
    case types.DELETE_WORKER_FROM_NETWORK_SUCCEEDED:
      return { title: 'Worker was deleted from the network', level: 'success' }
    case types.DELETE_PREV_EXP_SUCCEEDED:
      return { title: "Worker's previous experience record was deleted", level: 'success' }
    case types.PREV_EXP_SAVE_SUCCEEDED:
      return { title: 'Previous experience saved successfully', level: 'success' }
    case types.PREV_EXP_SAVE_FAILED:
      return { title: 'Unable to save previous experience', level: 'error', ...getErrors(object) }
    case types.JOB_RATE_WORKER_SUCCEEDED:
      return { title: 'Rating was updated successfully', level: 'success' }
    case types.JOB_RATE_WORKER_FAILED:
      return {
        title: 'Could not update rating for worker',
        level: 'error',
        ...getErrors(object, true),
      }
    case types.RATING_DELETE_SUCCEEDED:
      return { title: 'Rating was updated successfully', level: 'success' }
    case types.RATING_DELETE_FAILED:
      return {
        title: 'Could not update rating for worker',
        level: 'error',
        ...getErrors(object, true),
      }
    case types.CONFIRM_SHIFT_BOOKING_FAILED:
      return { title: 'Could not confirm the user', level: 'error' }
    case types.CONFIRM_SHIFT_BOOKING_SUCCEEDED:
      return { title: 'Confirmed the user for their shift', level: 'success' }
    case types.CONFIRM_BULK_SHIFTS_BOOKING_FAILED:
      return { title: 'Could not confirm the users', level: 'error' }
    case types.CONFIRM_BULK_SHIFTS_BOOKING_SUCCEEDED:
      return { title: 'Confirmed all the users for their shifts', level: 'success' }
    case types.SHIFT_STOP_NEW_WORKERS_SUCCEEDED:
      return {
        title: object?.stop_offers_for_worker_type?.length
          ? 'Workers will not be offered this shift anymore'
          : 'Workers will be offered this shift',
        level: 'success',
      }
    case types.SHIFT_STOP_NEW_WORKERS_FAILED:
      return {
        title: 'Could not adjust worker offer status; please try again',
        level: 'error',
        ...getErrors(object, true),
      }
    case types.LISTING_EDIT_SHIFT_SUCCEEDED:
      return { title: 'Shift details have been altered successfully', level: 'success' }
    case types.LISTING_EDIT_SHIFT_FAILED:
    case FulfilmentShiftsTypes.FULFILMENT_SHIFT_EDIT_FAILED:
      return {
        title: 'Could not edit shift details',
        level: 'error',
        ...getErrors(object, true),
      }
    case types.LISTING_DELETE_SUCCEEDED:
      return { title: 'The listing was successfully deleted', level: 'success' }
    case types.LISTING_DELETE_FAILED:
      return { title: 'Could not delete listing', level: 'error', ...getErrors(object) }
    case types.LISTING_UPDATE_SUCCEEDED:
      return { title: 'The listing was successfully updated', level: 'success' }
    case types.LISTING_UPDATE_FAILED:
      return { title: 'Could not update listing', level: 'error', ...getErrors(object) }
    case types.LISTING_SAVE_SUCCEEDED:
      return { title: 'The listing was successfully saved', level: 'success' }
    case types.LISTING_SAVE_FAILED:
      return { title: 'Could not save listing', level: 'error', ...getErrors(object) }
    case types.LISTINGS_DELETE_SUCCEEDED:
      return { title: 'All listings were successfully deleted', level: 'success' }
    case types.LISTINGS_DELETE_FAILED:
      return {
        title: 'Could not delete all listings',
        level: 'error',
        ...getErrors(object),
      }
    case types.LISTINGS_RESEND_APPROVAL_SUCCEEDED:
      return { title: 'Booking has been sent for approval', level: 'success' }
    case types.LISTINGS_RESEND_APPROVAL_FAILED:
      return { title: 'Booking has not been sent for approval', level: 'error', ...getErrors(object, true) }
    case types.SHIFT_DELETE_FAILED:
      return { title: 'Shift can not be deleted', level: 'error', ...getErrors(object, true) }
    case types.SHIFT_DELETE_SUCCEEDED: {
      return { title: 'Shift deleted', level: 'success' }
    }
    case types.JOB_UPDATE_SUCCEEDED: {
      if (request?.data?.stop_offers_for_worker_type) {
        if (request?.data?.stop_offers_for_worker_type?.length) {
          return { title: 'The job offers were successfully stopped', level: 'success' }
        }
        return { title: 'The job offers were successfully resumed', level: 'success' }
      }
      return { title: 'The job was successfully updated', level: 'success' }
    }
    case types.JOB_UPDATE_FAILED:
      return { title: 'The job was not updated', level: 'error', ...getErrors(object, true) }
    // Admin alert types.
    case adminAlertTypes.ADMIN_BOOKING_CANCELLED_BY_EMPLOYER:
    case adminAlertTypes.ADMIN_SHIFT_DELETED_BY_EMPLOYER:
    case adminAlertTypes.ADMIN_TIMESHEET_NO_SHOW:
    case adminAlertTypes.ADMIN_SHIFT_IGNORED:
    case adminAlertTypes.ADMIN_SHIFT_STILL_NOT_CONFIRMED:
    case adminAlertTypes.ADMIN_SHIFT_STARTING_WITHIN_DAY:
    case adminAlertTypes.ADMIN_SHIFT_TIME_CHANGED:
    case adminAlertTypes.ADMIN_MESSAGE_STOP_WORD:
    case adminAlertTypes.ADMIN_JOB_DELETED_BY_EMPLOYER:
      return {
        message: get(object, 'message'),
        level: 'info',
        autoDismiss: 0,
        action: {
          label: 'View listing',
          callback: () => history.push(`/listings/view/${object.resource_id}`),
        },
      }
    case adminAlertTypes.ADMIN_WORKER_GOT_BAD_RATING:
      return {
        message: get(object, 'message'),
        level: 'info',
        autoDismiss: 0,
        action: {
          label: 'View worker',
          callback: () => history.push(`/entity/workers/view/${object.resource_id}`),
        },
      }
    case types.ADMIN_MANAGER_CREATE_SUCCEEDED:
      return { title: 'Manager created', level: 'success' }
    case types.ADMIN_MANAGER_UPDATE_SUCCEEDED:
      return { title: 'Manager updated', level: 'success' }
    case types.ADMIN_MANAGER_DELETE_SUCCEEDED:
      return { title: 'Manager deleted', level: 'success' }
    case types.WORKER_TYPE_UPDATE_SUCCEEDED:
      return { title: 'Worker type updated', level: 'success' }
    case types.WORKER_STRIKE_CREATE_SUCCEEDED:
      return { title: 'Strike created', level: 'success' }
    case types.WORKER_STRIKE_DELETE_SUCCEEDED:
      return { title: 'Strike deleted', level: 'success' }
    case types.WORKER_STRIKE_UPDATE_SUCCEEDED:
      return { title: 'Strike updated', level: 'success' }
    case types.WORKER_NETWORK_CREATE_SUCCEEDED:
      return { title: 'Trusted network created', level: 'success' }
    case types.WORKER_NETWORK_DELETE_SUCCEEDED:
      return { title: 'Trusted network deleted', level: 'success' }
    case types.WORKER_NETWORK_CREATE_FAILED:
      return {
        title: 'Could not create trusted network',
        level: 'error',
        ...getErrors(object, true),
      }
    case types.WORKER_NETWORK_DELETE_FAILED:
      return {
        title: 'Could not delete trusted network',
        level: 'error',
        ...getErrors(object, true),
      }
    case types.EMPLOYER_ACCOUNT_SAVE_SUCCEEDED:
      return { title: 'Employer account permissions updated', level: 'success' }
    case types.EMPLOYER_ACCOUNT_SAVE_FAILED:
      return {
        title: 'Could not update employer account permissions',
        level: 'error',
        ...getErrors(object, true),
      }
    case types.EMPLOYER_ACCOUNT_DESTROY_PERMISSIONS_SUCCEEDED:
      return { title: 'Employer account permissions removed', level: 'success' }
    case types.EMPLOYER_ACCOUNT_DESTROY_PERMISSIONS_FAILED:
      return {
        title: 'Could not remove employer account permissions',
        level: 'error',
        ...getErrors(object, true),
      }
    case types.EMPLOYER_ACCOUNT_DISABLE_SUCCEEDED:
      return { title: 'Disabled employer account', level: 'success' }
    case types.EMPLOYER_ACCOUNT_DISABLE_FAILED:
      return { title: 'Could not disable employer account', level: 'error', ...getErrors(object, true) }
    case types.EMPLOYER_ACCOUNT_INVITE_SUCCEEDED:
      return { title: 'Successfully sent a new invite', level: 'success' }
    case types.EMPLOYER_ACCOUNT_INVITE_FAILED:
      return { title: 'Could not send a new invite', level: 'error', ...getErrors(object, true) }
    case types.EMPLOYER_SALES_FORCE_SAVE_SUCCEEDED:
      return { title: 'Saved salesforce data.', level: 'success' }
    case types.EMPLOYER_SALES_FORCE_SAVE_FAILED:
      return { title: 'Could not save salesforce data.', level: 'error', ...getErrors(object, true) }
    case types.EMPLOYER_BLOCKLIST_ADD_SUCCEEDED:
      return { title: 'Added workers to blocklist.', level: 'success' }
    case types.EMPLOYER_BLOCKLIST_REMOVE_SUCCEEDED:
      return { title: 'Removed workers from blocklist.', level: 'success' }
    case types.EMPLOYER_BLOCKLIST_ADD_FAILED:
      return { title: 'Could not add workers to blocklist.', level: 'error', ...getErrors(object, true) }
    case types.EMPLOYER_BLOCKLIST_REMOVE_FAILED:
      return { title: 'Could not remove workers from blocklist.', level: 'error', ...getErrors(object, true) }
    case types.UPLOAD_ACCESS_REQUEST_DATA_FILE_FAILED:
      return { title: 'Could not submit data', level: 'error', ...getErrors(object, true) }
    case types.UPLOAD_ACCESS_REQUEST_DATA_FILE_SUCCEEDED:
      return { title: 'Successfully submitted data', level: 'success' }
    case types.TIME_DEPENDENT_RATES_SAVE_SUCCEEDED:
      return { title: rateCardNotificationMessages.timeDependentSaveSuccess, level: 'success' }
    case types.TIME_DEPENDENT_RATES_SAVE_FAILED:
      return {
        title: rateCardNotificationMessages.timeDependentSaveFail,
        level: 'error',
        ...getErrors(object, true),
      }
    case types.TIME_DEPENDENT_RATES_UPDATE_SUCCEEDED:
      return { title: rateCardNotificationMessages.timeDependentUpdateSuccess, level: 'success' }
    case types.TIME_DEPENDENT_RATES_UPDATE_FAILED:
      return {
        title: rateCardNotificationMessages.timeDependentUpdateFail,
        level: 'error',
        ...getErrors(object, true),
      }
    case types.WORKER_TRANSFER_DELETE_SUCCEEDED:
    case types.WORKER_TRANSFER_UPDATE_SUCCEEDED:
    case types.WORKER_TRANSFER_CREATE_SUCCEEDED:
    case externalEmployersTypes.ENTITY_DELETE_SUCCEEDED:
    case externalEmployersTypes.ENTITY_UPDATE_SUCCEEDED:
    case externalEmployersTypes.ENTITY_CREATE_SUCCEEDED:
    case externalWorkLocationsTypes.ENTITY_DELETE_SUCCEEDED:
    case externalWorkLocationsTypes.ENTITY_UPDATE_SUCCEEDED:
    case externalWorkLocationsTypes.ENTITY_CREATE_SUCCEEDED:
      return {
        title: 'Transfer record updated successfully',
        level: 'success',
      }
    case types.WORKER_TRANSFER_DELETE_FAILED:
    case types.WORKER_TRANSFER_UPDATE_FAILED:
    case types.WORKER_TRANSFER_CREATE_FAILED:
    case externalEmployersTypes.ENTITY_DELETE_FAILED:
    case externalEmployersTypes.ENTITY_UPDATE_FAILED:
    case externalEmployersTypes.ENTITY_CREATE_FAILED:
    case externalWorkLocationsTypes.ENTITY_DELETE_FAILED:
    case externalWorkLocationsTypes.ENTITY_UPDATE_FAILED:
    case externalWorkLocationsTypes.ENTITY_CREATE_FAILED:
      return {
        title: 'Could not update transfer record',
        level: 'error',
        ...getErrors(object, true),
      }
    case types.WORKER_TRANSFER_FETCH_FAILED:
      return {
        title: 'Could not fetch transfer record',
        level: 'error',
        ...getErrors(object, true),
      }
    case employerPreferencesTypes.ENTITIES_UPDATE_FAILED:
      return {
        title: 'Could not update client preferences',
        level: 'error',
        ...getErrors(object, true),
      }
    case compliancePoliciesTypes.COMPLIANCE_POLICIES_FETCH_FAILED:
      return {
        title: 'An error occured while fetching policies',
        level: 'error',
        ...getErrors(object, true),
      }
    case matchingExplainabilityTypes.ENTITY_FETCH_FAILED:
      return {
        title: 'An error occured while fetching filters',
        level: 'error',

        ...getErrors(
          {
            body: {
              error_description: getMatchingExplainabilityErrorMessage(object.body.error, request),
            },
          },
          true,
        ),
      }
    case compliancePoliciesTypes.COMPLIANCE_POLICIES_UPDATE_FAILED:
      return {
        title: 'Failed to updated policy',
        level: 'error',
        ...getErrors(object, true),
      }
    case compliancePoliciesTypes.COMPLIANCE_POLICIES_DELETE_FAILED:
      return {
        title: 'Failed to delete policy',
        level: 'error',
        ...getErrors(object, true),
      }
    case compliancePoliciesTypes.COMPLIANCE_POLICIES_CREATE_FAILED:
      return {
        title: 'Failed to create policy',
        level: 'error',
        ...getErrors(object, true),
      }
    case compliancePoliciesTypes.COMPLIANCE_POLICIES_UPDATE_SUCCEEDED:
      return {
        title: 'Policy was updated',
        level: 'success',
      }
    case compliancePoliciesTypes.COMPLIANCE_POLICIES_DELETE_SUCCEEDED:
      return {
        title: 'Policy was deleted',
        level: 'success',
      }
    case compliancePoliciesTypes.COMPLIANCE_POLICIES_CREATE_SUCCEEDED:
      return {
        title: 'Policy was created',
        level: 'success',
      }
    case FulfilmentShiftsTypes.FULFILMENT_SHIFT_EDIT_SUCCEEDED: {
      if (request?.payload?.fulfilment_reviewed === false) {
        return { title: 'Successfully changes as not reviewed.', level: 'success' }
      }
      return request?.payload?.fulfilment_reviewed
        ? { title: 'Successfully marked as reviewed.', level: 'success' }
        : { title: 'Successfully saved!', level: 'success' }
    }
    case newEmployerRateCardsTypes.NEW_EMPLOYER_RATE_CARD_SAVE_FAILED:
      return {
        title: 'Error saving rate card',
        level: 'error',
        ...getErrors(object, true),
      }
    case newEmployerRateCardsTypes.NEW_EMPLOYER_RATE_CARD_SAVE_SUCCEEDED:
      return {
        title: 'Rate card saved',
        level: 'success',
      }
    case newEmployerRateCardsTypes.NEW_EMPLOYER_RATE_CARD_DISABLE_FAILED:
      return {
        title: 'Error disabling rate card',
        level: 'error',
        ...getErrors(object, true),
      }
    case newEmployerRateCardsTypes.NEW_EMPLOYER_RATE_CARD_DISABLE_SUCCEEDED:
      return {
        title: 'Rate card disabled',
        level: 'success',
      }
    case newEmployerRateCardsTypes.NEW_EMPLOYER_RATE_CARD_ENABLE_FAILED:
      return {
        title: 'Error enabling rate card',
        level: 'error',
        ...getErrors(object, true),
      }
    case newEmployerRateCardsTypes.NEW_EMPLOYER_RATE_CARD_ENABLE_SUCCEEDED:
      return {
        title: 'Rate card enabled',
        level: 'success',
      }
    case newEmployerRateCardsTypes.NEW_EMPLOYER_RATE_CARD_BULK_DISABLE_SUCCEEDED:
      return {
        title: 'Rate cards disabled',
        level: 'success',
      }
    case newEmployerRateCardsTypes.NEW_EMPLOYER_RATE_CARD_BULK_DISABLE_FAILED:
      return {
        title: 'Error disabling rate cards',
        level: 'error',
        ...getErrors(object, true),
      }
    case newEmployerRateCardsTypes.NEW_EMPLOYER_RATE_CARD_BULK_ENABLE_SUCCEEDED:
      return {
        title: 'Rate cards enabled',
        level: 'success',
      }
    case newEmployerRateCardsTypes.NEW_EMPLOYER_RATE_CARD_BULK_ENABLE_FAILED:
      return {
        title: 'Error enabling rate cards',
        level: 'error',
        ...getErrors(object, true),
      }
    case types.TIME_OFF_REQUESTS_FETCH_FAILED:
      return {
        title: 'Failed to fetch time off requests',
        level: 'error',
        ...getErrors(object, true),
      }
    case types.TIME_OFF_REQUEST_FETCH_FAILED:
      return {
        title: 'Failed to fetch time off request',
        level: 'error',
        ...getErrors(object, true),
      }
    case types.TIME_OFF_REQUEST_APPROVE_FAILED:
      return {
        title: 'Failed to approve time off request',
        level: 'error',
        ...getErrors(object, true),
      }
    case types.TIME_OFF_REQUEST_APPROVE_SUCCEEDED:
      return {
        title: 'Time off request approved',
        level: 'success',
      }
    case types.TIME_OFF_REQUEST_REJECT_FAILED:
      return {
        title: 'Failed to reject time off request',
        level: 'error',
        ...getErrors(object, true),
      }
    case types.TIME_OFF_REQUEST_REJECT_SUCCEEDED:
      return {
        title: 'Time off request rejected',
        level: 'success',
      }
    case workerReferralBonuses.ENTITY_CREATE_FAILED:
      return {
        title: 'Error saving worker referral bonus',
        level: 'error',
        ...getErrors(object, true),
      }
    case workerReferralBonuses.ENTITY_CREATE_SUCCEEDED:
      return {
        title: 'Worker referral bonus saved',
        level: 'success',
      }
    case workerReferralBonuses.ENTITIES_DELETE_FAILED:
      return {
        title: 'Error deleting worker referral bonuses',
        level: 'error',
        ...getErrors(object, true),
      }
    case workerReferralBonuses.ENTITIES_DELETE_SUCCEEDED:
      return {
        title: 'Worker referral bonuses deleted',
        level: 'success',
      }
    default:
      return null
  }
}
