import { get } from 'lodash-es'
import PropTypes from 'prop-types'
import React, { useCallback, useMemo } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { formi9Statuses } from 'syft-acp-core/data/formi9Status'
import { payStatuses } from 'syft-acp-core/data/paycomStatus'
import { workerStatuses } from 'syft-acp-core/data/workerStatus'
import * as filtersActions from 'syft-acp-core/store/filters/actions'

import { useCountrySpecificStructs } from 'syft-acp-core/components/CountrySpecific'
import { filterSelect } from './FilterSelect'

const statusesByGroup = {
  status: workerStatuses,
  i9form_status: formi9Statuses,
  paycom_status: payStatuses,
}

const FilterWorkerStatuses = ({ actions, name, value, disabled, type, allowAny }) => {
  const statuses = useCountrySpecificStructs(statusesByGroup[name])
  // We need the format [{ id: 'string', label: 'string' }, ...].
  const options = useMemo(
    () => Object.entries(statuses).reduce((acc, [id, label]) => [...acc, { id, label: label.name || label }], []),
    [statuses]
  )
  const onChange = useCallback(val => actions.setFilter(name, val, val), [actions, name])
  const header = allowAny ? 'Any' : 'Status'
  const FilterSelect = filterSelect(options, header, type, false)

  return <FilterSelect name={name} onChange={onChange} value={value} allowAny={allowAny} disabled={disabled} />
}

FilterWorkerStatuses.propTypes = {
  name: PropTypes.string.isRequired,
  actions: PropTypes.objectOf(PropTypes.func).isRequired,
  disabled: PropTypes.bool,
  value: PropTypes.string,
  allowAny: PropTypes.bool,
  type: PropTypes.string,
}

FilterWorkerStatuses.defaultProps = {
  disabled: false,
  value: null,
  allowAny: false,
  type: null,
}

export default connect(
  (state, { value, name }) => ({
    value: value || get(state.routing.locationBeforeTransitions.query, name),
  }),
  dispatch => ({
    actions: bindActionCreators({ ...filtersActions }, dispatch),
  })
)(FilterWorkerStatuses)
