export const trackingEvents = {
  SHIFT_FULFILMENT: {
    LINK: {
      CLICKED: 'shift_fulfilment.link.clicked',
    },
    NOTE: {
      EDITED: 'shift_fulfilment.note.edited',
      REVIEWED: 'shift_fulfilment.note.reviewed',
    },
    JOB_DESCRIPTION: {
      CHANGED: 'shift_fulfilment.job_description.changed',
    },
    SHIFT: {
      CHANGED: 'shift_fulfilment.shift.changed',
      CLOSED: 'shift_fulfilment.shift.closed',
    },
    START_TIME: {
      CHANGED: 'shift_fulfilment.start_time.changed',
    },
    END_TIME: {
      CHANGED: 'shift_fulfilment.end_time.changed',
    },
    AUDIT_LOG: {
      VIEWED: 'shift_fulfilment.audit_log.viewed',
    },
  },
}
