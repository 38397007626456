// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import React from 'react'
import Table from 'syft-acp-util/components/Table'

import './ModalTable.css'

const ModalTable = ({ children }) => (
  <Table className="ModalTable" topRowBorder>
    <tbody>{children}</tbody>
  </Table>
)

export default ModalTable
