import * as React from 'react'

import { ChangedPropertiesProps } from './ChangedProperties.types'

export const mapKeysToPropertyNames = (key: string) => {
  switch (key) {
    case 'pay_rate':
      return 'Pay rate'
    default:
      return key
  }
}

const ChangedProperties = ({ changedProperties, id, text, listClassName }: ChangedPropertiesProps) => {
  if (!changedProperties.length) return null
  return (
    <>
      <strong>
        {text} {id}
      </strong>{' '}
      attributes will be changed:
      <ul className={listClassName}>
        {changedProperties.map(e => (
          <li key={e}>{mapKeysToPropertyNames(e)}</li>
        ))}
      </ul>
    </>
  )
}

export default ChangedProperties
