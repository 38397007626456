import { ExpiringDocumentEntity, DOCUMENT_TYPES } from 'syft-acp-core/store/expiringDocuments/types'

const hasExpired = (expiresIn: string) => expiresIn[0] === '-'
const expiresToday = (expiresIn: string) => expiresIn.slice(0, 2) === '0 '

/**
 * @param expiryDate string Expiry date in a format like this: "25 days"
 */
export const getFormattedExpiryDate = (expiryDate: string) => {
  if (!expiryDate) return ''
  // Minus means it's already expired.
  if (hasExpired(expiryDate)) {
    return `Expired (${expiryDate.slice(1)} ago)`
  }
  // If 0 days, just say "today".
  if (expiresToday(expiryDate)) {
    return 'Today'
  }
  return expiryDate
}

export const getFormattedDocType = (rowData: ExpiringDocumentEntity) => {
  const docType = rowData.document_type
  switch (docType) {
    case DOCUMENT_TYPES.PASSPORT:
      return 'Passport'
    case DOCUMENT_TYPES.EEA_NATIONAL_ID:
      return 'EEA national id'
    case DOCUMENT_TYPES.VISA:
      return 'VISA'
    default:
      return docType
  }
}

export const getRowValue = (row: ExpiringDocumentEntity) => [{ id: row.id }]

export const getRowColor = (rowData: ExpiringDocumentEntity) => {
  if (rowData.resolved) return 'green'
  if (hasExpired(rowData.expires_in)) return 'red'
  if (expiresToday(rowData.expires_in)) return 'yellow'
  return null
}
