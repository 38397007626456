// Syft ACP - UI Library <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import React from 'react'
import { values } from 'lodash-es'

import { workerAutocomplete } from 'syft-acp-core/api/resources/admin/workers'
import FilterAutocomplete from './FilterAutocomplete'
import { filterDecorator } from '../filterDecorator'
import { acpFilterDefaults } from '../dataProps'

class AcpFilterWorker extends React.PureComponent {
  static defaultProps = {
    ...acpFilterDefaults,
  }

  static requireDebounce = true

  constructor(props) {
    super(props)
    this.state = {
      value: props.initialValue || '',
    }
  }

  // Reformats the search results into a format usable by Typeahead.
  // Needs to be an array with 'id' and 'label'.
  formatOptions = queryResults =>
    values(queryResults).map(worker => ({ id: worker.data, label: worker.value }))

  handleChange = newValue => {
    const { name, setValue } = this.props
    if (!newValue.length) return
    const { id, label } = newValue[0]
    const formattedValue = `${id}$${label}`
    this.setState({ value: formattedValue })
    setValue(name, formattedValue)
  }

  render() {
    const { placeholder, isDisabled, initialValue, name } = this.props
    return (
      <FilterAutocomplete
        name={name}
        placeholder={placeholder}
        isDisabled={isDisabled}
        initialValue={initialValue}
        formatFunction={this.formatOptions}
        queryFunction={workerAutocomplete}
        handleChange={this.handleChange}
      />
    )
  }
}

export default filterDecorator(AcpFilterWorker)
