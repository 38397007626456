import { takeLatest } from 'redux-saga/effects'
import * as api from 'syft-acp-core/api/resources/bookings'
import * as types from 'syft-acp-core/actions/action-types'

import { notificationCall } from './calls'
import { takeLatestApiCall } from './helpers'
import * as calls from './calls/bookingCalls'
import { blockWorkerForClient } from 'syft-acp-core/store/shifts/api'

export default function* root() {
  yield takeLatestApiCall('BOOKINGS_OFFER', api.offerBooking)
  yield takeLatestApiCall('BOOKINGS_REPLACE', api.replaceBooking)
  yield takeLatest(types.BOOKINGS_SAVE_BEGIN, calls.saveBookingsCall)
  yield takeLatest(types.END_ASSIGNMENT_BEGIN, calls.saveEndAssignmentWorkerCall)
  yield takeLatestApiCall(types.BLOCK_WORKER_CLIENT_BEGIN, blockWorkerForClient)
  yield takeLatestApiCall('GLOBAL_SHIFT_BOOKINGS', api.fetchGlobalShiftBookings)
  yield takeLatest(
    [
      types.BOOKINGS_SAVE_FAILED,
      types.BOOKINGS_SAVE_SUCCEEDED,
      types.BLOCK_WORKER_CLIENT_SUCCEEDED,
      types.BLOCK_WORKER_CLIENT_FAILED,
    ],
    notificationCall,
  )
}
