import entityReducer from 'syft-acp-core/reducers/generators/entityReducer'
import * as entityTypes from 'syft-acp-core/reducers/generators/entities'

import * as types from './action-types'
import { DeliveryTeamEntity } from './types'

export default entityReducer<DeliveryTeamEntity>('delivery-teams', {
  [entityTypes.ENTITIES_FETCH_BEGIN]: types.DELIVERY_TEAMS_FETCH_BEGIN,
  [entityTypes.ENTITIES_FETCH_SUCCEEDED]: types.DELIVERY_TEAMS_FETCH_SUCCEEDED,
  [entityTypes.ENTITIES_FETCH_FAILED]: types.DELIVERY_TEAMS_FETCH_FAILED,
})
