import { generateEntityCrudReducer } from 'syft-acp-util/store/reducers'
import { WorkerReferralClaimsEntity } from './types'
import types from './action-types'

export default generateEntityCrudReducer<WorkerReferralClaimsEntity>({
  name: 'workerReferralClaims',
  actions: types,
  options: {
    localEntityMap: true,
  },
})
