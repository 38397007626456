import { WorkerExpensesEntity } from 'syft-acp-core/store/workerExpenses/types'

export const getExpenseType = ({ type }: WorkerExpensesEntity) =>
  ({
    travel: 'Travel',
    ppe: 'PPE',
  }[type])

export const getCompensation = ({ compensated }: WorkerExpensesEntity) =>
  ({
    indeed_flex: 'Indeed Flex',
    client: 'Client',
  }[compensated])
