// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import { takeLatest } from 'redux-saga/effects'
import * as api from 'syft-acp-core/api/resources/workers/trusted_network_employers'
import * as types from 'syft-acp-core/actions/action-types'
import { notificationCall, redirectCall } from './calls'
import { takeLatestApiCall } from './helpers'

export default function* root() {
  yield takeLatestApiCall('WORKER_NETWORKS_FETCH', api.listWorkerNetworks)
  yield takeLatestApiCall('WORKER_NETWORK_FETCH', api.fetchWorkerNetwork)
  yield takeLatestApiCall('WORKER_NETWORK_CREATE', api.createWorkerNetwork)
  yield takeLatestApiCall('WORKER_NETWORK_DELETE', api.deleteWorkerNetwork)
  yield takeLatest(
    types.WORKER_NETWORK_CREATE_SUCCEEDED,
    redirectCall,
    ({ request }) => `/entity/workers/view/${request.workerID}/networks/list`
  )
  yield takeLatest(
    types.WORKER_NETWORK_DELETE_SUCCEEDED,
    redirectCall,
    ({ request }) => `/entity/workers/view/${request.workerID}/networks/list`
  )
  yield takeLatest(
    [
      types.WORKER_NETWORK_CREATE_SUCCEEDED,
      types.WORKER_NETWORK_CREATE_FAILED,
      types.WORKER_NETWORK_DELETE_SUCCEEDED,
      types.WORKER_NETWORK_DELETE_FAILED,
    ],
    notificationCall
  )
}
