import { takeLatest, put } from 'redux-saga/effects'
import { history } from 'syft-acp-core/history'

import * as types from 'syft-acp-core/actions/action-types'
import { fetchAdminListingBookings, fetchListingResend } from 'syft-acp-core/store/listings/api'
import { fetchAdminListingShift } from 'syft-acp-core/store/listing-shifts/api'
import { deleteShift } from 'syft-acp-core/api/index'
import { patchShift } from 'syft-acp-core/store/shifts/api'
import { notificationCall } from 'syft-acp-core/sagas/calls'
import { takeLatestApiCall } from 'syft-acp-core/sagas/helpers'

import { fetchAdminShiftListing } from './actions'

function* editListingShiftCompletedCall(action: Record<string, any>) {
  yield put(fetchAdminShiftListing(action.request.listingID, action.payload.id || action.request.payload.id))
}

const succeededDeleteShift = (action: Record<string, any>) => {
  if (action?.request?.removeListing) {
    // OneHostRouteRedirect outside of listing after removed the last job and shift
    history.push('/shifts')
  } else {
    // OneHostRouteRedirect default listing detail page
    history.push(`/listings/view/${action?.request?.listingID}`)
  }
}

export default function* root() {
  yield takeLatestApiCall('ADMIN_LISTING_SHIFT_FETCH', fetchAdminListingShift)
  yield takeLatestApiCall('LISTINGS_RESEND_APPROVAL', fetchListingResend)
  yield takeLatestApiCall('BOOKINGS_FETCH', fetchAdminListingBookings)
  yield takeLatestApiCall('SHIFT_DELETE', deleteShift)
  yield takeLatest(types.SHIFT_DELETE_SUCCEEDED, succeededDeleteShift)
  yield takeLatestApiCall('LISTING_EDIT_SHIFT', patchShift)
  // reload listing shift bookings after shift update.
  yield takeLatest(types.LISTING_EDIT_SHIFT_SUCCEEDED, editListingShiftCompletedCall)
  yield takeLatest(types.LISTING_EDIT_SHIFT_FAILED, editListingShiftCompletedCall)
  yield takeLatest(
    [
      types.CONFIRM_SHIFT_BOOKING_FAILED,
      types.CONFIRM_SHIFT_BOOKING_SUCCEEDED,
      types.CONFIRM_BULK_SHIFTS_BOOKING_SUCCEEDED,
      types.CONFIRM_BULK_SHIFTS_BOOKING_FAILED,
      types.LISTING_EDIT_SHIFT_FAILED,
      types.LISTING_EDIT_SHIFT_SUCCEEDED,
      types.BOOKINGS_FETCH_FAILED,
      types.LISTINGS_RESEND_APPROVAL_SUCCEEDED,
      types.LISTINGS_RESEND_APPROVAL_FAILED,
      types.SHIFT_DELETE_SUCCEEDED,
      types.SHIFT_DELETE_FAILED,
    ],
    notificationCall,
  )
}
