// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import * as types from './action-types'

export function fetchEmployerAccounts(data = {}) {
  return { type: types.EMPLOYER_ACCOUNTS_FETCH_BEGIN, options: data.options }
}

export function fetchEmployerAccount(employerID, accountID) {
  return { type: types.EMPLOYER_ACCOUNT_FETCH_BEGIN, employerID, accountID }
}

export function saveEmployerAccount(employerID, accountID, data) {
  return { type: types.EMPLOYER_ACCOUNT_SAVE_BEGIN, employerID, accountID, data }
}

export function employerAccountLogin(employerAccountID) {
  return { type: types.EMPLOYER_ACCOUNT_LOGIN_BEGIN, employerAccountID }
}

export function inviteEmployerAccount(employerID, data) {
  return { type: types.EMPLOYER_ACCOUNT_INVITE_BEGIN, employerID, data }
}

export function destroyEmployerAccountPermissions(employerID, accountID, data) {
  return { type: types.EMPLOYER_ACCOUNT_DESTROY_PERMISSIONS_BEGIN, employerID, accountID, data }
}

export function disableEmployerAccount(employerID, accountID) {
  return { type: types.EMPLOYER_ACCOUNT_DISABLE_BEGIN, employerID, accountID }
}
