import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { ControlLabel, HelpBlock, FormGroup } from 'react-bootstrap'
import { get } from 'lodash-es'
import { withTrackingTriggerHoc } from 'syft-acp-util/withTrackingTriggerHoc'

import { addToEmployerBlocklist } from 'syft-acp-core/actions/employer-blocklist'
import { getModal$ } from 'syft-acp-core/store/modals/selectors'
import { Modal } from 'syft-acp-core/components/Modal'
import { showModal, hideModal } from 'syft-acp-core/store/modals/actions'
import WorkerQuickview from 'syft-acp-core/components/WorkerQuickview'
import FilterAutocompleteWorkersAvatar from 'syft-acp-util/components/FilterForm/FilterAutocompleteWorkersAvatar'
import { trackingEvents } from 'syft-acp-core/entities/EmployerDetail/EmployerBlocklistList/EmployerBlocklistList.tracking'

import './AddWorkerToBlocklistModal.css'

class AddWorkerToBlocklistModal extends Component {
  static propTypes = {
    isLoadingBlocklists: PropTypes.bool.isRequired,
    dispatch: PropTypes.func.isRequired,
    employerID: PropTypes.PropTypes.number.isRequired,
    employers: PropTypes.object.isRequired,
    item: PropTypes.object.isRequired,
    workers: PropTypes.object.isRequired,
    triggerEvent: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props)
    this.state = { valid: true, workerID: null, workerName: '' }
  }

  shouldComponentUpdate(nextProps) {
    return nextProps.item.isShown !== this.props.item.isShown
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    // If we are becoming visible, while not being visible, reset the form.
    if (this.props.item.isShown !== nextProps.item.isShown && nextProps.item.isShown === true) {
      this.setState({ ...this.state, msg: null, valid: true, workerID: null, workerName: '' })
    }
  }

  onConfirmHandler = () => {
    const isValid = this.validate()
    const { workerID } = this.state
    if (isValid) {
      this.props.dispatch(addToEmployerBlocklist(this.props.employerID, workerID))
      this.props.triggerEvent(trackingEvents.ADD_WORKER_TO_BLACKLIST.CLICKED, {
        worker_id: workerID,
      })
      this.closeModal()
    }
  }

  setWorkerID = (val, query) => {
    this.setState({ ...this.state, workerID: val, workerName: query })
    // TODO: unknown why we need this.
    this.forceUpdate()
  }

  validate = () => {
    const isWorkerBlank = this.state.workerID == null || this.state.workerID === '0'
    if (isWorkerBlank) {
      const msg = 'Please input a worker'
      this.setState({ ...this.state, valid: false, msg })
      return false
    }

    this.setState({ ...this.state, valid: true, msg: null })
    return true
  }

  closeModal = () => this.props.dispatch(hideModal(this.props.item.modalName))

  openModal = () => this.props.dispatch(showModal(this.props.item.modalName))

  render() {
    const employer = get(this.props.employers, this.props.employerID, {})
    const employerName = get(employer, 'brand_name', get(employer, 'company_name', ''))
    return (
      <Modal
        header="Add worker to blocklist"
        isShown={this.props.item.isShown}
        onClose={this.closeModal}
        onConfirm={this.onConfirmHandler}
        confirmationText="Add to blocklist"
      >
        <FormGroup validationState={this.state.valid ? null : 'error'} className="AddWorkerToBlocklistModal">
          <div className="section left">
            <ControlLabel>Find worker</ControlLabel>
            <FilterAutocompleteWorkersAvatar
              callback={this.setWorkerID}
              id={this.state.workerID}
              name={this.state.workerName}
              disabled={false}
            />
            <p className="explanation">This will add a worker to the blocklist for employer {employerName}.</p>
            {!this.state.valid && <HelpBlock>{this.state.msg}</HelpBlock>}
          </div>
          <div className="section right">
            {this.state.workerID && [
              <ControlLabel key="header">Information</ControlLabel>,
              <WorkerQuickview key="overview" id={this.state.workerID} />,
            ]}
          </div>
        </FormGroup>
      </Modal>
    )
  }
}

export default connect(state => ({
  item: getModal$(state.modals)('addWorkerToBlocklistModal'),
  employers: state.employers.entityMap,
  workers: state.workers.entityMap,
}))(withTrackingTriggerHoc(AddWorkerToBlocklistModal))
