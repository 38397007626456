import { put, takeLatest } from 'redux-saga/effects'

import { takeLatestApiCall } from 'syft-acp-core/sagas/helpers'
import { notificationCall } from 'syft-acp-core/sagas/calls'

import { fetchWorkerHolidayPayAccruals, fetchWorkerHolidayPayHistoricRequests } from './actions'
import * as types from './action-types'
import * as api from './api'

function* fetchAccruals({ request }: Record<string, any>) {
  const { workerID, meta } = request
  yield put(fetchWorkerHolidayPayAccruals(workerID || meta.workerID))
}

function* fetchHistoricRequests({ request }: Record<string, any>) {
  const { workerID, meta } = request
  yield put(fetchWorkerHolidayPayHistoricRequests({ options: { workerID: workerID || meta.workerID } }))
}

export default function* root() {
  yield takeLatestApiCall(types.WORKER_HOLIDAY_PAY_ACCRUALS_FETCH_BEGIN, api.fetchWorkerHolidayPayAccruals)
  yield takeLatestApiCall(
    types.WORKER_HOLIDAY_PAY_HISTORIC_REQUESTS_FETCH_BEGIN,
    api.fetchWorkerHolidayPayHistoricRequests
  )
  yield takeLatestApiCall(types.WORKER_HOLIDAY_PAY_HISTORIC_REQUEST_MAKE_BEGIN, api.makeWorkerHolidayPayHistoricRequest)
  yield takeLatestApiCall(
    types.WORKER_HOLIDAY_PAY_HISTORIC_REQUEST_DELETE_BEGIN,
    api.deleteWorkerHolidayPayHistoricRequest
  )
  yield takeLatestApiCall(
    types.WORKER_HOLIDAY_PAY_HISTORIC_REQUEST_VALIDATE_BEGIN,
    api.validateWorkerHolidayPayHistoricRequest
  )
  yield takeLatestApiCall(types.WORKER_HOLIDAY_PAY_WEEKLY_REQUESTS_FETCH_BEGIN, api.fetchWorkerHolidayPayWeeklyRequests)
  yield takeLatestApiCall(types.WORKER_HOLIDAY_PAY_WEEKLY_REQUEST_MAKE_BEGIN, api.makeWorkerHolidayPayWeeklyRequest)
  yield takeLatest(
    [
      types.WORKER_HOLIDAY_PAY_HISTORIC_REQUEST_MAKE_SUCCEEDED,
      types.WORKER_HOLIDAY_PAY_HISTORIC_REQUEST_DELETE_SUCCEEDED,
      types.WORKER_HOLIDAY_PAY_WEEKLY_REQUEST_MAKE_SUCCEEDED,
    ],
    fetchAccruals
  )
  yield takeLatest(
    [
      types.WORKER_HOLIDAY_PAY_HISTORIC_REQUEST_MAKE_SUCCEEDED,
      types.WORKER_HOLIDAY_PAY_HISTORIC_REQUEST_DELETE_SUCCEEDED,
    ],
    fetchHistoricRequests
  )
  yield takeLatest(
    [
      types.WORKER_HOLIDAY_PAY_HISTORIC_REQUEST_MAKE_SUCCEEDED,
      types.WORKER_HOLIDAY_PAY_HISTORIC_REQUEST_MAKE_FAILED,
      types.WORKER_HOLIDAY_PAY_HISTORIC_REQUEST_DELETE_SUCCEEDED,
      types.WORKER_HOLIDAY_PAY_HISTORIC_REQUEST_DELETE_FAILED,
      types.WORKER_HOLIDAY_PAY_HISTORIC_REQUEST_VALIDATE_FAILED,
      types.WORKER_HOLIDAY_PAY_WEEKLY_REQUEST_MAKE_SUCCEEDED,
      types.WORKER_HOLIDAY_PAY_WEEKLY_REQUEST_MAKE_FAILED,
    ],
    notificationCall
  )
}
