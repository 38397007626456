import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { noop } from 'lodash-es'

import { Button } from 'syft-acp-atoms/Button'

import './AcpNotifierBlock.css'

export default class AcpNoData extends React.PureComponent {
  static propTypes = {
    rowCount: PropTypes.number,
    messageError: PropTypes.string.isRequired,
    messageRetry: PropTypes.string,
    isIntegrated: PropTypes.bool,
    hasColGroups: PropTypes.bool,
    hasError: PropTypes.bool,
    reloadLastCall: PropTypes.func,
  }

  static defaultProps = {
    rowCount: 4,
    messageRetry: 'Retry request',
    isIntegrated: false,
    hasColGroups: false,
    hasError: false,
    reloadLastCall: null,
  }

  render() {
    const { messageError, messageRetry, rowCount, isIntegrated, hasColGroups, reloadLastCall } = this.props
    const hasReload = reloadLastCall != null

    // Calculate the block height: 38 for each row, minus the border of the bottom row, and minus 6px padding on both sides.
    const blockHeight = rowCount * 38 - 1 - 12

    return (
      <div
        className={classNames('acp-notifier-block', {
          'is-integrated': isIntegrated,
          'is-non-integrated': !isIntegrated,
          'has-col-groups': hasColGroups,
        })}
        data-testid="entity-list-feedback"
      >
        <div className="inner" style={{ height: `${blockHeight}px` }}>
          <div className="message">
            <p>{messageError}</p>
            <Button disabled={!hasReload} onClick={hasReload ? reloadLastCall : noop} kind="primary">
              {messageRetry}
            </Button>
          </div>
        </div>
      </div>
    )
  }
}
