import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { find, get, values } from 'lodash-es'
import { AsyncTypeahead } from 'react-bootstrap-typeahead'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import * as filtersActions from 'syft-acp-core/store/filters/actions'
import FilterUnit from '../FilterUnit'

import { Option, OptionAttrKey, OwnProps, Props, State, Suggestion } from './FilterAutocomplete.types'

import './FilterAutocomplete.css'

const autocompleteOptions = (suggestions: Suggestion[]): Option[] =>
  values(suggestions).map(suggestion => ({ id: suggestion.data, label: suggestion.value }))

// TODO remove when other dependant components transitioned to TS
export const autocompleteProps = {
  placeholder: PropTypes.string,
  name: PropTypes.string,
  fullWidth: PropTypes.bool,
  align: PropTypes.string,
  small: PropTypes.bool,
  callbackSaveLabel: PropTypes.func,
  forceValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  tiny: PropTypes.bool,
  disabled: PropTypes.bool,
  callback: PropTypes.func,
  queryType: PropTypes.string,
  onChange: PropTypes.func,
  autoFocus: PropTypes.bool,
  removable: PropTypes.bool,
  isSpotlightSearch: PropTypes.bool,
}

export const connector = connect<
  { query: Record<string, string | undefined | null>; forceValue?: string | number | null },
  { actions: typeof filtersActions },
  OwnProps,
  {
    routing: {
      locationBeforeTransitions: {
        query: Record<string, string | undefined | null>
      }
    }
  }
>(
  (state, { name, forceQueryParamValue, forceValue }) => ({
    query: state.routing.locationBeforeTransitions.query,
    forceValue:
      forceValue ||
      (forceQueryParamValue && name ? state.routing.locationBeforeTransitions.query[name] : undefined),
  }),
  dispatch => ({
    actions: bindActionCreators(filtersActions, dispatch),
  }),
)

const FilterAutocomplete = ({
  attr = 'id',
  align = 'justify',
  queryType = 'label',
  callback,
  forceValue,
  name,
  query,
  actions,
  queryFunction,
  fullWidth,
  callbackSaveLabel,
  tiny,
  small,
  onChange,
  autoFocus,
  removable = false,
  isSpotlightSearch,
  ...rest
}: Props) => {
  const mounted = useRef(false)
  const [state, setState] = useState<State>(() => {
    const forceValueString = forceValue ? String(forceValue) : undefined
    const label = name && !callbackSaveLabel ? query[name] : forceValueString
    return {
      options: [],
      isLoading: false,
      forceValue: forceValue,
      selected: !!label
        ? {
            id: 'id',
            label,
          }
        : undefined,
    }
  })
  const saveInput = (selectedItems: Option[]) => {
    const targetAttr = name || 'id'
    const selectedItemLabel = get(selectedItems, '[0].label')
    const resultObj = find(state.options, { label: selectedItemLabel })
    const resultID = selectedItems?.[0]?.id || resultObj?.[targetAttr as OptionAttrKey]

    if (name) {
      const val = get(selectedItems, `[0][${queryType}]`)
      !!onChange && onChange(name, val)
      actions.setFilter(name, val)
    }
    if (callback) {
      callback(resultID, selectedItemLabel)
    }
    if (callbackSaveLabel) {
      callbackSaveLabel(selectedItemLabel ?? '')
    }
  }

  const runSearch = (searchQuery: string): void => {
    if (!searchQuery) {
      return
    }
    setState(prevState => ({ ...prevState, isLoading: true }))
    queryFunction(searchQuery).then(results => {
      const options = autocompleteOptions(results)
      setState(prevState => ({
        ...prevState,
        options,
        isLoading: false,
      }))
    })
  }

  useEffect(() => {
    // first time this effect is run when component is mounted
    // but we actually need to run it only when attr or force value changed
    if (mounted.current) {
      setState(prevState => {
        const selectedItem = prevState.options.find(opt => opt[attr] === forceValue)
        return { ...prevState, forceValue: forceValue, selected: selectedItem }
      })
    }
    mounted.current = true
  }, [attr, forceValue])

  return (
    <FilterUnit
      fullWidth={fullWidth}
      small={small}
      tiny={tiny}
      className={classnames('FilterAutocomplete', {
        hasClearButton: removable,
        isSpotlightSearch: isSpotlightSearch,
      })}
    >
      <AsyncTypeahead<Option>
        align={align}
        autoFocus={autoFocus}
        {...rest}
        /* Don't do any filtering; return '1' for all so they keep their original API order. */
        filterBy={() => true}
        labelKey="label"
        defaultSelected={state.selected ? [state.selected] : []}
        emptyLabel="No matches."
        isLoading={state.isLoading}
        /* Max height must be 333px to fit 7 items and the
        "display additional results" link (with 5px padding). */
        maxHeight="333px"
        maxResults={7}
        delay={500}
        id={name || 'label'}
        options={state.options}
        selectHintOnEnter
        onSearch={runSearch}
        onChange={saveInput}
        minLength={0}
        clearButton={removable}
      />
    </FilterUnit>
  )
}

export default connector(FilterAutocomplete)
