import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { entityList$ } from 'syft-acp-core/reducers/generators/entities'
import { values, intersection } from 'lodash-es'
import * as filtersActions from 'syft-acp-core/store/filters/actions'
import * as managersActions from 'syft-acp-core/actions/managers'
import { Button } from 'syft-acp-atoms/Button'

import DataDropdown from './DataDropdown'
import './DataAgenciesDropdown.css'

// Converts the agencies map into a format for DataDropdown.
const makeOptions = managers =>
  values(managers).map(manager => ({
    id: manager.id,
    label: manager.name,
  }))

class DataMultipleManagersDropdown extends PureComponent {
  constructor(props) {
    super()
    const { managers } = props
    this.state = {
      results: [{ id: 0 }],
      ids: [],
      options: makeOptions(managers),
    }
  }

  componentDidMount() {
    const { managers, isLoadingManagers, actions } = this.props
    if (managers.length === 0 && !isLoadingManagers) {
      actions.fetchAdminManagers({ options: {} })
    }
  }

  handleChangeDropdown = (value, prevValue) => {
    const { onChange } = this.props
    const { results, options } = this.state

    const remainingResults = results.filter(manager => manager.id !== prevValue)
    const newOption = options.filter(manager => manager.id === value)
    const resultIds = [...remainingResults, ...newOption].map(item => item.id)

    this.setState(() => ({
      results: [...remainingResults, ...newOption],
      ids: resultIds,
    }))
    onChange(resultIds)
  }

  handleClick = () => {
    const { managers } = this.props
    const allOptions = makeOptions(managers)
    const slectedOptions = allOptions.filter(option => !this.state.ids.includes(option.id))

    const allData = [...this.state.results, { id: 0 }]
    this.setState(() => ({ results: allData, options: slectedOptions }))
  }

  setOptions = value => [value, ...this.state.options]

  render() {
    const { header, editable, disabled, name, ariaLabel } = this.props
    const { results } = this.state
    return (
      <div className="DataAgenciesDropdown">
        {results.map(manager => (
          <div style={{ marginBottom: '3px' }}>
            <DataDropdown
              name={name}
              onChange={value => this.handleChangeDropdown(value, manager.id)}
              options={this.setOptions(manager)}
              header={header}
              value={manager.id}
              editable={editable}
              disabled={disabled}
              parameters={{
                ariaLabel,
              }}
            />
          </div>
        ))}
        <Button kind="success" onClick={this.handleClick}>
          Add manager
        </Button>
      </div>
    )
  }
}

DataMultipleManagersDropdown.propTypes = {
  disabled: PropTypes.bool,
  editable: PropTypes.bool.isRequired,
  isLoadingManagers: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  header: PropTypes.string.isRequired,
  managers: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
  name: PropTypes.string,
  ariaLabel: PropTypes.string,
}

DataMultipleManagersDropdown.defaultProps = {
  disabled: false,
  ariaLabel: 'Managers',
}

const mapStateToProps = (state, { onChange, value, parameters, queryKey = 'syft_manager_id' }) => ({
  actions: {
    setFilter: (name, results) => onChange(results),
  },
  isLoadingManagers: state.managers.isLoadingData,
  managers:
    parameters && parameters.managerRoles
      ? entityList$(state.managers, {}).filter(
          manager => intersection(manager.manager_roles, parameters.managerRoles).length > 0
        )
      : entityList$(state.managers, {}),
  value: value || state.routing.locationBeforeTransitions.query[queryKey],
})

const mapDispatchToProps = (dispatch, { actions }) => {
  const boundActions = bindActionCreators(
    {
      ...filtersActions,
      ...managersActions,
    },
    dispatch
  )
  return {
    actions: {
      ...boundActions,
      ...actions,
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DataMultipleManagersDropdown)
