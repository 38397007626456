import React, { useEffect, useRef } from 'react'
import { capitalize, words } from 'lodash-es'
import { useFlexFlagIsOn } from '@indeed/flex-feature-flags'
import RecordSection from 'syft-acp-core/components/RecordSection'

import { entityDetailHOC } from 'syft-acp-util/entityDetail'

import { preferencesStructure } from './PreferenceDetail.structure'
import type { PreferenceDetailProps } from './PreferenceDetail.types'

const PreferenceDetail = ({
  root,
  onEntityUpdate,
  data,
  entityMap,
  entityDetail,
  isPristine,
  id,
  actionUpdate,
  hideSectionTitle,
}: PreferenceDetailProps) => {
  const enableOverbookPreferences = useFlexFlagIsOn('overbooked_preferences')
  const isMountedRef = useRef(false)
  const structure = preferencesStructure(entityDetail[id] || entityMap[id], root, enableOverbookPreferences)[
    data.group as keyof ReturnType<typeof preferencesStructure>
  ]

  useEffect(() => {
    if (isMountedRef.current) {
      onEntityUpdate({ value: data, isPristine })
    } else {
      isMountedRef.current = true
    }
    // eslint-disable-next-line
  }, [data, id, isPristine])

  return (
    <RecordSection
      title={hideSectionTitle ? '' : capitalize(words(data.group).join(' '))}
      data={data}
      structure={structure}
      update={actionUpdate}
    />
  )
}

export const entityDetailConnector = entityDetailHOC('employerPreferences')

export default entityDetailConnector(PreferenceDetail)
