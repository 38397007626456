import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { get, find } from 'lodash-es'
import { AsyncTypeahead } from 'react-bootstrap-typeahead'

import * as filtersActions from 'syft-acp-core/store/filters/actions'
import * as employerVenuesActions from 'syft-acp-core/store/employer-venues/actions'
import { selectEmployerVenueOptionsById } from 'syft-acp-core/store/employerVenuesFiltered/selectors'

import './FilterAutocompleteVenue.css'

const findSuggestionName = ({ venues, value }) =>
  get(
    find(venues, venue => String(venue.id) === String(value)),
    'name',
    '',
  )

class FilterAutocompleteVenue extends PureComponent {
  static propTypes = {
    name: PropTypes.string.isRequired,
    venues: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        label: PropTypes.string.isRequired,
      }),
    ).isRequired,
    prefixOptions: PropTypes.any,
    actions: PropTypes.objectOf(PropTypes.func).isRequired,
    disabled: PropTypes.bool,
    value: PropTypes.string,
    allowAny: PropTypes.bool,
    hasVenuesData: PropTypes.bool,
    type: PropTypes.string,
    queryAttribute: PropTypes.string,
    employerID: PropTypes.number,
    onSave: PropTypes.func,
  }

  static defaultProps = {
    disabled: false,
    value: null,
    allowAny: false,
    hasVenuesData: false,
    type: null,
    prefixOptions: [],
    onSave: () => {},
  }

  constructor(props) {
    super(props)

    this.state = {
      // The suggestions that match the user's input
      filteredSuggestions: [],
      // What the user has entered
      userInput: findSuggestionName(props),
    }
  }

  static getDerivedStateFromProps(nextProps) {
    return {
      userInput: findSuggestionName(nextProps),
    }
  }

  componentDidMount() {
    const { hasVenuesData, actions, employerID, value, venues, onSave } = this.props

    if (!hasVenuesData) {
      actions.fetchEmployerFilteredVenuesList(employerID)
    } else {
      if (value !== -1 && value) {
        onSave([venues.find(venue => venue.id === this.props.value)])
      }
    }
  }

  onChange = val => {
    const { venues } = this.props

    if (val.length > 0) {
      const filteredSuggestions = venues.filter(
        suggestion => suggestion.name.toLowerCase().indexOf(val.toLowerCase()) > -1,
      )
      this.setState({
        filteredSuggestions,
        userInput: val,
      })
    }
  }

  onSave = val => {
    const { actions, name, onSave } = this.props

    if (val.length > 0) {
      actions.setFilter(name, [val[0]['id']])
      onSave(val[0]['id'])
    } else {
      actions.setFilter(name, '')
      onSave(null)
    }
  }

  render() {
    const { filteredSuggestions, venues } = this.props
    const { userInput } = this.state

    return (
      <AsyncTypeahead
        clearButton
        filterBy={['name']}
        labelKey="name"
        disabled={false}
        placeholder="Search venue by name"
        defaultSelected={userInput ? [userInput] : 'Select value'}
        selected={userInput ? [userInput] : []}
        emptyLabel="No matches."
        isLoading={false}
        forcevalue={1}
        id={'name'}
        options={userInput ? filteredSuggestions : venues}
        minLength={0}
        onSearch={this.onChange}
        onChange={this.onSave}
        className="venueAutocomplete"
      />
    )
  }
}

export default connect(
  (state, { value, queryAttribute = 'venue_id', prefixOptions = [], employerID }) => ({
    venues: [...prefixOptions, ...selectEmployerVenueOptionsById(state, employerID)],
    hasVenuesData: state.employerVenuesFiltered.hasVenuesData,
    value: value || state.routing.locationBeforeTransitions.query[queryAttribute],
  }),
  (dispatch, { actions }) => {
    const boundActions = bindActionCreators(
      {
        ...filtersActions,
        ...employerVenuesActions,
      },
      dispatch,
    )
    return {
      actions: {
        ...boundActions,
        ...actions,
      },
    }
  },
)(FilterAutocompleteVenue)
