import React from 'react'
import PropTypes from 'prop-types'

import AcpNotifierInline from './AcpNotifierInline'

/**
 * Displayed on a table when there is no data to show.
 */
export default class AcpErrorInline extends React.PureComponent {
  static propTypes = {
    messageError: PropTypes.any,
    reloadLastCall: PropTypes.func,
  }

  static defaultProps = {
    messageError: '',
    reloadLastCall: null,
  }

  render() {
    const { messageError, reloadLastCall } = this.props
    return <AcpNotifierInline messageError={messageError} hasError reloadLastCall={reloadLastCall} />
  }
}
