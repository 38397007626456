import React from 'react'
import { connect } from 'react-redux'
import FilterClientDeliveryTeam from 'syft-acp-util/components/FilterForm/FilterClientDeliveryTeam'
import { DataClientDeliveryTeamDropdownProps as Props } from './DataClientDeliveryTeamDropdown.types'

const DataClientDeliveryTeamDropdown = ({
  value,
  editable,
  actions,
  allowAny,
  name,
  parameters = {},
}: Props) => (
  <FilterClientDeliveryTeam
    name={name}
    value={value}
    allowAny={allowAny}
    actions={actions}
    allowNameLabel
    type="id"
    disabled={!editable}
    parameters={parameters}
  />
)

export default connect((state, { onChange }: Props) => ({
  actions: {
    setFilter: (name: string, value: string) => onChange(value),
  },
}))(DataClientDeliveryTeamDropdown)
