import React, { useCallback, useEffect } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { flowRight } from 'lodash-es'
import { entityList$ } from 'syft-acp-core/reducers/generators/entities'

import Acp from 'syft-acp-uikit'
import { fetchShiftEvents } from 'syft-acp-core/store/shiftEvents/actions'
import { ShiftEventEntity, TransformedDetail } from 'syft-acp-core/store/shiftEvents/types'
import {
  ShiftFulfilmentAuditLogListOwnProps as OwnProps,
  ShiftFulfilmentAuditLogListProps as Props,
} from './ShiftFulfilmentAuditLogList.types'
import { getDataWithDetails } from './helpers'
import FormattedDateTime from 'syft-acp-core/components/FormattedDateTime'

import S from './ShiftFulfilmentAuditLogList.module.scss'

export const storeConnector = connect(
  (state, props: OwnProps) => {
    return {
      data: entityList$(state.shiftEvents, { id: props.shiftID }),
      isLoadingData: state.shiftEvents.isLoadingData,
      skills: Object.values(state?.skills?.entityMap),
    }
  },
  dispatch => ({
    actions: {
      ...bindActionCreators({ fetchShiftEvents }, dispatch),
    },
  }),
)

const ShiftFulfilmentAuditLogDetails = ({
  subRowData,
  fromValue,
}: {
  subRowData: TransformedDetail
  fromValue?: boolean
}) => {
  return (
    <div>
      {fromValue ? (
        <div>
          <b>{subRowData?.fromCell?.key}</b>
        </div>
      ) : (
        <br />
      )}

      <div className={S['acp-fulfilment-audit-detail-message']}>
        {fromValue ? subRowData?.fromCell?.from : subRowData?.toCell?.to}
      </div>
    </div>
  )
}

const ShiftFulfilmentAuditLogList = ({ actions, data, isLoadingData, shiftID, skills }: Props) => {
  const fetch = useCallback(
    () =>
      actions.fetchShiftEvents({
        id: shiftID,
      }),
    [actions, shiftID],
  )
  useEffect(() => {
    fetch()
  }, [fetch])
  const processedData = getDataWithDetails(data, skills)
  const allData = {
    data: processedData,
  }

  return (
    <div>
      <div className={S['acp-fulfilment-audit-log-list']}>
        <Acp.EntityList hasPopoverFilters data={allData} isLoading={isLoadingData} idKeyValue="created_at">
          <Acp.Table>
            <Acp.Col.Text
              value={(dataRow: ShiftEventEntity) => {
                return (
                  <>
                    <div>
                      {/* @ts-expect-error */}
                      <FormattedDateTime
                        value={dataRow?.created_at}
                        format={{
                          year: 'numeric',
                          month: '2-digit',
                          day: '2-digit',
                        }}
                      />
                    </div>
                    <div>
                      {/* @ts-expect-error */}
                      <FormattedDateTime
                        value={dataRow?.created_at}
                        format={{
                          hour: 'numeric',
                          minute: '2-digit',
                          second: '2-digit',
                          timeZoneName: 'short',
                        }}
                      />
                    </div>
                  </>
                )
              }}
              header="Date / time"
              isMinimal
            />
            <Acp.SubRows value="details">
              <Acp.Col.Text
                value={(_: ShiftEventEntity, subRowData: TransformedDetail) => (
                  <ShiftFulfilmentAuditLogDetails fromValue subRowData={subRowData} />
                )}
                header="Attributes changed from"
                isMinimal
                colNoLink
              />
              <Acp.Col.Text
                value={(_: ShiftEventEntity, subRowData: TransformedDetail) => (
                  <ShiftFulfilmentAuditLogDetails subRowData={subRowData} />
                )}
                header="Attributes changed to"
                isMinimal
                colNoLink
              />
            </Acp.SubRows>
            <Acp.Col.Text
              value={(rowData: ShiftEventEntity) => rowData.actor?.email || 'System'}
              header="Author"
              isMinimal
            />
          </Acp.Table>
        </Acp.EntityList>
      </div>
    </div>
  )
}

export default flowRight(storeConnector)(ShiftFulfilmentAuditLogList)
