import { takeLatest } from 'redux-saga/effects'
import * as api from 'syft-acp-core/api/resources/admin/agenciesListing'
import { AGENCIES_LIST_CREATE_SUCCEEDED, AGENCIES_LIST_CREATE_FAILED } from 'syft-acp-core/actions/action-types'

import { takeLatestApiCall } from './helpers'
import { notificationCall } from './calls'

export default function* root() {
  yield takeLatestApiCall('AGENCIES_LIST_FETCH', api.fetchAgencyListing)
  yield takeLatestApiCall('AGENCIES_LIST_CREATE', api.createAgency)
  yield takeLatest([AGENCIES_LIST_CREATE_SUCCEEDED, AGENCIES_LIST_CREATE_FAILED], notificationCall)
}
