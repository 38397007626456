import * as U from 'karet.util'
import * as R from 'kefir.ramda'
import { set, lensProp } from 'kefir.ramda'

import { notify } from 'syft-acp-core/lib/notifications'

// Default atom contents: used for making placeholders.
export const placeholderData = {
  isLoading: true,
  hasData: false,
  isOK: true,
  reqResult: {
    data: [],
    meta: {},
  },
}

/** Adds data from an object to a lens. */
export const addToAtom = (obj, lens, setName = null) => {
  if (obj == null) return
  const lensTarget = setName != null ? U.view(setName, lens) : lens
  for (const [key, value] of Object.entries(obj)) {
    lensTarget.modify(set(lensProp(key), value))
  }
}

/** Function that reports errors from save calls. TODO 'plan' and 'period' */
export const notifySaveError = (data, type = 'plan') => {
  notify('error', {
    title: `Could not save promotion ${type === 'plan' ? 'plan' : 'period'} details`,
    message:
      data.res.status === 500
        ? 'Internal server error: try again later.'
        : data.res.status === 400
        ? `The given values were rejected by the server. Check the input and try again.`
        : data.res.status
        ? `Reported error: ${data.res.statusText} (${data.res.status}).`
        : data.error
        ? `Reported error: ${data.error}`
        : `An unknown error occurred.`,
  })
}

/** Removes empty promotion periods. */
export const removeEmptyPeriods = (dataArray = []) => {
  return dataArray.map(row => ({
    ...row,
    pricing_promotions: row.pricing_promotions.filter(item => R.not(R.all(R.equals(''))(Object.values(item)))),
  }))
}
