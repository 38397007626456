import { apiRequest, handleCall } from 'syft-acp-core/api/call'

type Action<P = {}, M = {}> = {
  payload?: P
  meta?: M
}

type fetchEmployerRateCardPayload = {
  employerId: number
}

export const fetchEmployerShiftRateTypes = ({ payload }: Action<fetchEmployerRateCardPayload>) =>
  new Promise((resolve, reject) => {
    const req = apiRequest({
      path: `/admin/employers/${payload?.employerId}/shift_rate_types`,
    })
    handleCall(req, resolve, reject, true, { employerId: payload?.employerId })
  })
