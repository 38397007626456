// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import React from 'react'
import PropTypes from 'prop-types'
import ReactImageFallback from 'react-image-fallback'
import classNames from 'classnames'

import { avatarImage, guestAvatar } from './image'
import { EMPTY_AVATAR } from './constants'

import './NameAvatarImage.css'

class NameAvatarImage extends React.PureComponent {
  static propTypes = {
    // 'withName' is true if this component is inside a <NameAvatar /> component.
    // It causes the image to be positioned absolutely.
    withName: PropTypes.bool,
    uuid: PropTypes.string,
    alt: PropTypes.string,
  }

  static defaultProps = {
    withName: PropTypes.bool,
    uuid: EMPTY_AVATAR,
    alt: null,
  }

  render() {
    const { uuid, alt, withName } = this.props
    const [src, isFallback] = avatarImage(uuid)
    return (
      <div className={classNames('NameAvatarImage', { 'with-name': withName, 'with-fallback-image': isFallback })}>
        <div className="avatar-image-wrapper">
          <span className="avatar-border" />
          {/* If we have a valid image in 'src' but the image is not available, 'guestAvatar' is shown. */}
          <ReactImageFallback className="avatar-img" src={src} fallbackImage={guestAvatar} alt={alt} />
        </div>
      </div>
    )
  }
}

export default NameAvatarImage
