// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import React, { useCallback, useEffect } from 'react'
import { connect } from 'react-redux'
import { flowRight } from 'lodash-es'

import Acp from 'syft-acp-uikit'
import EmployerVenueListWrapper from 'syft-acp-core/entities/EmployerDetail/EmployerVenueListWrapper'
import { createEntityListConnector } from 'syft-acp-util/entityList'
import { EmployerVenueEntity } from 'syft-acp-core/store/employer-venues/types'
import * as employerVenuesActions from 'syft-acp-core/store/employer-venues/actions'

import {
  EmployerVenueListProps as Props,
  EmployerVenueListEntityConnectorProps as EntityConnectorProps,
} from './EmployerVenueList.types'

export const entityConnector = createEntityListConnector<EmployerVenueEntity>({
  entityActions: { fetchEntities: employerVenuesActions.fetchEmployerVenuesList },
  entityStore: 'employerVenues',
})

export const storeConnector = connect(null, (_dispatch, props: EntityConnectorProps) => ({
  actions: props.actions,
}))

const formatAddressValue = (el: Partial<EmployerVenueEntity>) =>
  el.address && Object.values(el.address).filter(Boolean).join(', ')

const formatAreasValue = (el: Partial<EmployerVenueEntity> = {}) => el.areas?.length || '-'

const EmployerVenueList = ({ actions, data, isLoadingData, employerID, urlBase, query }: Props) => {
  const { page, venue_name } = query
  const fetch = useCallback(() => {
    actions.fetchEntities && actions.fetchEntities({ options: { employerID, page, venue_name } }, false)
  }, [actions, employerID, page, venue_name])

  useEffect(() => {
    fetch()
  }, [fetch])

  const generateRowLink = (row: EmployerVenueEntity) => `${urlBase}${row.id}`

  return (
    <EmployerVenueListWrapper
      // @ts-expect-error
      employerID={employerID}
      entityList={
        <Acp.EntityList data={data} isLoading={isLoadingData} inContainer hasPagination hasResultCount idKeyValue="id">
          <Acp.Actions>
            <Acp.FilterGroup title="Venue">
              <Acp.Filter.Text name="venue_name" placeholder="Venue name" />
            </Acp.FilterGroup>
          </Acp.Actions>
          <Acp.Table rowLink={generateRowLink}>
            <Acp.Col.LegacySelector isMinimal />
            <Acp.Col.Text value="id" header="ID" isMinimal isNumeric />
            <Acp.Col.Text value="name" header="Name" />
            <Acp.Col.Text value={formatAddressValue} header="Address" />
            <Acp.Col.Boolean
              value="invoice_independently"
              header="I"
              headerAbbr
              headerTooltip="Invoice independently"
              isMinimal
            />
            <Acp.Col.Text value="maximum_arrival_time" header="Arrival minutes" />
            <Acp.Col.Text value={formatAreasValue} header="Areas" isNumeric isMinimal />
          </Acp.Table>
        </Acp.EntityList>
      }
    />
  )
}

export default React.memo(flowRight(entityConnector, storeConnector)(EmployerVenueList))
