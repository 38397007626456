import React from 'react'
import { employerAutocomplete } from 'syft-acp-core/api'

import FilterAutocomplete, { autocompleteProps } from './FilterAutocomplete'

const FilterAutocompleteEmployers = props => (
  <FilterAutocomplete {...props} queryFunction={employerAutocomplete} />
)

FilterAutocompleteEmployers.propTypes = autocompleteProps

export default FilterAutocompleteEmployers
