import {
  AGENCY_BOOKING_FETCH_BEGIN,
  AGENCY_BOOKING_SAVE_BEGIN,
  AGENCY_BOOKING_SAVE_FAILED,
  AGENCY_BOOKING_UPDATE,
} from './action-types'

export const failAgencySaveBookings = payload => ({
  type: AGENCY_BOOKING_SAVE_FAILED,
  payload,
})

export const fetchAgencyBookings = (listingId, shiftId) => ({
  type: AGENCY_BOOKING_FETCH_BEGIN,
  listingId,
  shiftId,
})

export const saveAgencyBookings = payload => ({
  type: AGENCY_BOOKING_SAVE_BEGIN,
  payload,
})

export const updateAgencyBookings = payload => ({
  type: AGENCY_BOOKING_UPDATE,
  payload,
})
