import React from 'react'
import * as tableType from 'syft-acp-core/components/EntityList/'
import octicons from 'octicons'
import { AgencyEmployerRemoveButton } from './AgencyEmployerRemoveButton'

export const agencyStructure = [
  [
    'id',
    {
      header: 'ID',
    },
  ],
  [
    'name',
    {
      header: 'Name',
      editable: true,
    },
  ],
  [
    'email',
    {
      header: 'Email',
      editable: true,
    },
  ],
  [
    'telephone_number',
    {
      header: 'Telephone number',
      editable: true,
    },
  ],
]

export const tableFormat = data => [
  {
    type: tableType.TXT,
    val: 'id',
    header: 'ID',
    numeric: true,
  },
  { type: tableType.IMAGE, headerIcon: octicons.person, headerIconAlt: 'Picture', val: 'picture' },
  {
    type: tableType.TXT,
    val: 'company_name',
    header: 'Company name',
  },
  {
    type: tableType.CUSTOM_COMPONENT,
    header: 'Actions',
    val: row => <AgencyEmployerRemoveButton row={row} data={data} />,
    classes: ['minimal'],
    hasLink: false,
  },
]
