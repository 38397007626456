import React from 'react'
import { get } from 'lodash-es'

import UserLine from 'syft-acp-core/components/UserAvatar/UserLine'
import { formatFullName } from 'syft-acp-util/formatting'
import * as tableType from 'syft-acp-core/components/EntityList/'

import ListingRatingsDataRating from './ListingRatingsDataRating'

export const structure = jobID => [
  {
    type: tableType.TXT,
    val: 'worker.id',
    header: 'Worker ID',
  },
  {
    type: tableType.TXT,
    val: e => <UserLine avatarUUID={get(e, 'worker.profile_picture.uuid', '')} name={formatFullName(e.worker)} />,
    header: 'Worker name',
  },
  {
    type: tableType.TXT,
    // Note: e.generated is 'true' if there is no rating yet.
    val: e => (
      <ListingRatingsDataRating
        generated={e.generated}
        stars={e.stars != null ? Number(e.stars) : null}
        worker={e.worker}
        jobID={jobID}
      />
    ),
    hasLink: false,
    header: 'Rating',
  },
  {
    type: tableType.TXT,
    val: r => (
      <div className="skills">
        {(r.skills || []).map((s, n) =>
          s ? s.special ? <strong key={n}>{s.title}</strong> : <span key={n}>{s.title}</span> : null
        )}
      </div>
    ),
    header: 'Skills',
    classes: ['nowrap', 'skills-list'],
  },
  {
    type: tableType.TXT,
    val: 'comments',
    classes: ['main'],
    header: 'Comments',
  },
]
