import React from 'react'
import classNames from 'classnames'

import { AcpCellTextProps as Props } from './AcpCellText.types'
import './AcpCellText.css'

/**
 * Very small presentational component for displaying text content, potentially with a link.
 */
const AcpCellText: React.FC<Props> = ({ label, isColGroup, children }) => (
  <div aria-label={label} className={classNames('acp-cell-text', { 'is-col-group': isColGroup })}>
    {children}
  </div>
)

export default AcpCellText
