import { pick, isEqual } from 'lodash-es'
import { store } from 'syft-acp-core/store'
import { formatISODate } from 'syft-acp-util/formatting'
import { dateAddTzSetTime, dateAddTz } from 'syft-acp-util/time'

/**
 * Generates a date range between two dates.
 * Dates are rounded to the day.
 */
export function getDateRange(
  startInDays: number,
  endInDays: number,
  gteAttribute: string,
  lteAttribute: string,
  includeTz = false,
  roundToHour = true,
  startCurrentTime = false
) {
  const date = new Date()
  const start = new Date()
  const end = new Date()

  // Round to the last day.
  if (roundToHour) {
    date.setSeconds(0)
    date.setMinutes(0)
    date.setUTCHours(0)
    start.setSeconds(0)
    if (!startCurrentTime) {
      start.setMinutes(0)
      start.setUTCHours(0)
    }
    end.setSeconds(0)
    end.setMinutes(0)
    end.setUTCHours(0)
  }

  start.setDate(date.getDate() + startInDays)
  end.setDate(date.getDate() + endInDays)
  if (includeTz) {
    return {
      [gteAttribute]: startCurrentTime ? dateAddTz(start) : dateAddTzSetTime(start, 0, 0),
      [lteAttribute]: dateAddTzSetTime(end, 0, 0),
    }
  } else {
    return {
      [gteAttribute]: formatISODate(start),
      [lteAttribute]: formatISODate(end),
    }
  }
}

/**
 * Verifies if the date range in the current query equals the given start/end days.
 */
export function checkDateQueryMatch(
  startInDays: number,
  endInDays: number,
  gteAttribute: string,
  lteAttribute: string,
  includeTz = false,
  roundToHour = true
) {
  return () => {
    const range = getDateRange(startInDays, endInDays, gteAttribute, lteAttribute, includeTz, roundToHour)
    const { query } = store.getState().routing.locationBeforeTransitions
    const subset = pick(query, [gteAttribute, lteAttribute])
    return isEqual(range, subset)
  }
}
