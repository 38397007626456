import { deserialize } from 'deserialize-json-api'

const intergerizeIds = data => ({
  ...data,
  id: Number(data.id),
  relationships: {
    [Object.keys(data.relationships)[0]]: {
      data: Object.values(data.relationships)[0].data.map(row => ({ ...row, id: Number(row.id) })),
    },
  },
})

export const deserializeResponse = payload => {
  if (payload?.data) {
    const transformedBody = {
      included: payload.included.map(row => ({ ...row, id: Number(row.id) })),
      data: payload.data.map(row => intergerizeIds(row)),
    }

    return deserialize(transformedBody, { transformKeys: 'camelCase' }).data
  }
  return payload
}
