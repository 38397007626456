import { Assignee } from './types'

export const assigneeInitialState = {
  tsLastAssigned: null,
  assignees: [],
}

export const setTsLastAssigned = (state: Record<string, any>) => ({
  ...state,
  tsLastAssigned: Date.now(),
})

export const setAssignees = (state: Record<string, any>, action: { payload: Assignee[] }) => ({
  ...state,
  assignees: action.payload,
})
