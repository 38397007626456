import React from 'react'
import PropTypes from 'prop-types'
import { get, pick } from 'lodash-es'

import { Button } from 'syft-acp-atoms/Button'

import { cellPropTypes, cellDefaultProps } from '../dataProps'
import { addConfirmLayer } from '../../confirm'
import CellContainer from '../CellContainer'
import { placeholderString } from '../../AcpTable'
import { colPlaceholderFactory } from '../../../placeholders/factory'

// Column placeholder component.
export const AcpColButton = colPlaceholderFactory('AcpCellButton', ['AcpButton', 'AcpButtonLink'])

/**
 * Column type definition for AcpTable components.
 *
 *    <AcpCol type={ tableType.BUTTON } onClick={ () => { doSomething(); } header="Action" />
 */
export default class AcpCellButton extends React.PureComponent {
  static propTypes = {
    ...cellPropTypes,
    // We don't need a value for a button. Usually it won't have one.
    value: PropTypes.any,
  }

  static defaultProps = {
    ...cellDefaultProps,
  }

  static cellProperties = ['form']

  render() {
    const { link, getTableData, subRowData, rowData, spec } = this.props

    // Assume we have a single button. If there's more, too bad. You should only have one.
    const buttonData = get(spec, '_nested[0]', false)

    // If for some reason we have a button type column without an actual button in it...
    if (!buttonData) {
      return <CellContainer>{placeholderString}</CellContainer>
    }

    // Handler for onClick. Could be preceded by a modal window.
    // Note: all button callbacks take the cell data as its only argument in the following format:
    const clickHandler = ev =>
      buttonData.onClick({
        tableData: getTableData(),
        subRowData,
        rowData,
        link,
        ev,
        needsConfirm: !!buttonData.needsConfirm,
      })

    const disabled =
      typeof buttonData.disabled === 'function'
        ? buttonData.disabled({
            tableData: getTableData(),
            subRowData,
            rowData,
          })
        : buttonData.disabled

    // Options passed along to the modal, if confirmation is required.
    const modalOptions = { ...pick(buttonData, ['confirmHeader', 'confirmText', 'confirmYes', 'confirmNo']) }
    const buttonOptions = { ...pick(buttonData, ['kind', 'bright', 'block', 'active']) }

    return (
      <Button
        {...buttonOptions}
        disabled={disabled}
        inTable
        onClick={buttonData.needsConfirm ? addConfirmLayer(clickHandler, modalOptions) : clickHandler}
      >
        {buttonData.children}
      </Button>
    )
  }
}
