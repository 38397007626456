// Syft ACP - Atoms <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import React from 'react'
import PropTypes from 'prop-types'

import iconFactory from './iconFactory'
import './BaseIcon.css'

export default class BaseIcon extends React.PureComponent {
  static propTypes = {
    iconSlug: PropTypes.string.isRequired,
    iconAlt: PropTypes.string.isRequired,
    iconSize: PropTypes.number,
    fill: PropTypes.string,
  }

  static defaultProps = {
    iconSize: 13,
    fill: '',
  }

  render() {
    const { iconSlug, iconAlt, iconSize, fill } = this.props
    const IconComponent = iconFactory(iconSlug, iconAlt)
    return <IconComponent fill={fill} className="acp-base-icon" iconSize={iconSize} />
  }
}
