// Syft ACP - Lib <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import { isEqual, noop, pick } from 'lodash-es'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import * as checkboxesActions from 'syft-acp-core/store/checkboxes/actions'
import { arePropertiesEqual } from 'syft-acp-core/store/filters/helpers'
import LoadingIndicator from 'syft-acp-util/components/LoadingIndicator'
import ActionButton from './ActionButton'
import ActionDropdown from './ActionDropdown'
import ActionGroup from './ActionGroup'
import ActionLink from './ActionLink'
import FilterWrapper from './FilterWrapper'

import './FilterForm.css'

// Action component types.
export const TYPE_BUTTON = Symbol('TYPE_BUTTON')
export const TYPE_DROPDOWN = Symbol('TYPE_DROPDOWN')
export const TYPE_LINK = Symbol('TYPE_LINK')
export const TYPE_CUSTOM_BUTTON = Symbol('TYPE_CUSTOM_BUTTON')

class FilterForm extends React.Component {
  static propTypes = {
    entityProps: PropTypes.object,
    filters: PropTypes.element,
    resultData: PropTypes.object,
    extraButtons: PropTypes.node,
    actionFormat: PropTypes.array,
    isLoadingData: PropTypes.bool,
    isSavingData: PropTypes.bool,
    selectedItems: PropTypes.array.isRequired,
    entityStore: PropTypes.string,
    refetchData: PropTypes.func.isRequired,
    noFilterContainer: PropTypes.bool,
    actions: PropTypes.objectOf(PropTypes.func).isRequired,
    type: PropTypes.string,
  }

  static defaultProps = {
    filters: <div />,
    actionFormat: [],
    entityProps: { isLoadingData: false },
    extraButtons: null,
    noFilterContainer: false,
    resultData: {},
    entityStore: '',
    type: '',
    isLoadingData: false,
    isSavingData: false,
  }

  shouldComponentUpdate(nextProps) {
    const sameArrays = isEqual(this.props.selectedItems, nextProps.selectedItems)
    const sameValues = arePropertiesEqual(this.props, nextProps, ['isLoadingData', 'isSavingData'])
    const sameValuesEntityProps = arePropertiesEqual(this.props.entityProps, nextProps.entityProps, [
      'isLoadingData',
      'isSavingData',
    ])
    return !sameArrays || !sameValues || !sameValuesEntityProps
  }

  decorateCallback = (callback, confirmCallback) => () => {
    const { actions, entityStore, selectedItems, refetchData, resultData } = this.props
    actions.clearItems(entityStore)
    if (callback) callback(selectedItems, refetchData, resultData, confirmCallback)
  }

  render() {
    const {
      entityProps,
      actionFormat,
      isLoadingData,
      isSavingData,
      selectedItems,
      filters,
      noFilterContainer,
      extraButtons,
    } = this.props

    return (
      <FilterWrapper
        type={this.props.type}
        noFilterContainer={noFilterContainer}
        hasFilters={!!(filters && actionFormat)}
      >
        <ActionGroup hasFilters={!!(filters && actionFormat)}>
          <LoadingIndicator loadingState={isSavingData || isLoadingData || entityProps.isLoadingData} />
          {actionFormat.map((actionUnit, n) => {
            const {
              action,
              confirmCallback,
              label,
              global,
              items,
              type,
              customButton,
              showForCountries,
              hideForCountries,
              ...rest
            } = actionUnit
            // Pass on any props from the entity list that we want in the filter.
            const passedFromEntity = pick(entityProps, actionUnit.passProps)
            const actionProps = {
              /* eslint-disable react/jsx-no-bind */
              callback: this.decorateCallback(action || noop, confirmCallback || noop),
              label,
              // Disable the element if we don't have a selection,
              // or are saving or loading data.
              // If the action is "global", it means it doesn't need to have anything selected.
              disabled:
                isSavingData ||
                isLoadingData ||
                !(global || type === TYPE_LINK || selectedItems.length) ||
                entityProps.isLoadingData,
              items: (items || []).map(item => ({
                ...item,
                action: this.decorateCallback(item.action),
              })),
              selectedItems,
              ...rest,
              ...passedFromEntity,
            }

            let component

            switch (type) {
              case TYPE_BUTTON:
                component = <ActionButton {...actionProps} />
                break
              case TYPE_DROPDOWN:
                component = <ActionDropdown {...actionProps} />
                break
              case TYPE_LINK:
                component = <ActionLink {...actionProps} />
                break
              case TYPE_CUSTOM_BUTTON: {
                const CustomButton = customButton
                component = <CustomButton {...actionProps} />
                break
              }
              default:
                throw TypeError('entityList() actionFormat has has an unimplemented type')
            }
            return (
              <span key={n} data-testid="entity-list-action">
                {component}
              </span>
            )
          })}
          {!!extraButtons && extraButtons}
        </ActionGroup>
        <span data-testid="entity-list-filters">{filters}</span>
      </FilterWrapper>
    )
  }
}

const mapStateToProps = (state, props) => ({
  selectedItems: state.checkboxes?.items[props.entityStore] || [],
})
const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(checkboxesActions, dispatch),
})
export default connect(mapStateToProps, mapDispatchToProps)(FilterForm)
