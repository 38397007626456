import React, { useCallback } from 'react'
import { FormControl, InputGroup, Grid, Col, Row } from 'react-bootstrap'
import NumericInput from 'react-numeric-input'

import './DataNumeric.css'

type DecimalInputProps = {
  value: string
  disabled: boolean
  onChange: (value: number | null) => void
}

const DecimalInput = ({ value, onChange, disabled, ...rest }: DecimalInputProps) => (
  <NumericInput {...rest} precision={2} step={0.25} disabled={disabled} value={value} onChange={onChange} />
)

type Props = {
  editable?: boolean
  disabled?: boolean
  value?: string
  prefix?: string
  suffix?: string
  ariaLabel?: string
  onChange: (value: number | null) => void
}

export const DataNumeric = ({ editable, disabled, value = '0', prefix, suffix, ariaLabel, onChange }: Props) => {
  const handleChange = useCallback(val => onChange(val), [onChange])

  return editable ? (
    <Grid className="data-numeric">
      <Row>
        <Col md={2}>
          <InputGroup>
            <InputGroup.Addon>{prefix}</InputGroup.Addon>
            <FormControl
              type="text"
              disabled={disabled}
              onChange={handleChange}
              value={value}
              aria-label={ariaLabel}
              componentClass={DecimalInput}
            />
          </InputGroup>
        </Col>
      </Row>
    </Grid>
  ) : (
    <Grid>
      <Row>
        <Col md={2}>
          {value}
          {suffix}
        </Col>
      </Row>
    </Grid>
  )
}
