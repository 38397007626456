import React, { useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { FormGroup } from 'react-bootstrap'
import { useTrackingTrigger } from '@indeed/flex-tracking-context'

import { getModal$ } from 'syft-acp-core/store/modals/selectors'
import { Modal } from 'syft-acp-core/components/Modal'
import { trackingEvents } from './Modal.tracking'
import PlatformsAutocomplete from 'syft-acp-core/components/EditableTable/PlatformsAutocomplete'
import { hideModal } from 'syft-acp-core/store/modals/actions'

export const AddAgencyEmployerModal = ({ saveAction, data, dispatch, item }) => {
  const triggerEvent = useTrackingTrigger()
  const [platformIds, setPlaftormIds] = useState(null)

  const closeModal = useCallback(() => {
    dispatch(hideModal(item.modalName))
    setPlaftormIds(null)
  }, [item, dispatch])

  const onConfirmHandler = useCallback(() => {
    const allPlatformIds = data.employers.map(employer => employer.platform_id)
    const updatedData = {
      ...data,
      platform_ids: [...platformIds, ...allPlatformIds],
    }
    saveAction(updatedData)
    triggerEvent(trackingEvents.ADD_AGENCY_EMPLOYER.SUBMIT, {
      agency_id: data.id,
      employer_platform_id: platformIds[0],
    })
    closeModal()
  }, [platformIds, saveAction, closeModal, data, triggerEvent])

  const onChangePlatform = useCallback(
    value => {
      setPlaftormIds(value)
    },
    [setPlaftormIds],
  )

  return (
    <Modal
      header="Add new employer"
      isShown={item.isShown}
      canSubmit={platformIds}
      onClose={closeModal}
      onConfirm={onConfirmHandler}
      confirmationText="Submit"
    >
      <FormGroup className="form-item">
        <PlatformsAutocomplete onChange={onChangePlatform} />
      </FormGroup>
    </Modal>
  )
}

AddAgencyEmployerModal.propTypes = {
  item: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  saveAction: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
}

export default connect(state => ({
  item: getModal$(state.modals)('addAgencyEmployerModal'),
}))(AddAgencyEmployerModal)
