// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import FilterAutocompleteEmployers from 'syft-acp-util/components/FilterForm/FilterAutocompleteEmployers'
import { listEmployerVenuesFiltered } from 'syft-acp-core/store/employer-venues/api'
import { filterSelect } from 'syft-acp-util/components/FilterForm/FilterSelect'

import './DataEmployerVenue.scss'

const DataEmployerVenue = ({ value, disabled, onChange, parameters }) => {
  const [employerVenues, setEmployerVenues] = useState([])

  const callback = (field, val) =>
    onChange({
      employer_id: value?.employer_id,
      venue_id: value?.venue_id,
      [field]: val,
    })

  useEffect(() => {
    if (value?.employer_id) {
      listEmployerVenuesFiltered({
        employerID: value?.employer_id,
      }).then(({ payload }) => {
        setEmployerVenues(
          payload.map(i => {
            i.label = i.name
            return i
          }),
        )
      })
    }
  }, [value?.employer_id])

  const FilterSelect = filterSelect(employerVenues, 'Select a venue', 'id', true)

  return (
    <div className="DataEmployer">
      <div className="employer-combobox">
        <FilterAutocompleteEmployers
          disabled={disabled}
          queryType="id"
          callback={employerID => {
            callback('employer_id', employerID)
          }}
          forceValue={value?.employer_id}
          dataTestId="employer-combobox"
          placeholder={parameters.employerPlaceholder}
          fullWidth={parameters.fullWidth}
        />
      </div>
      <div className="venue-combobox">
        <FilterSelect
          onChange={venueId => {
            callback('venue_id', venueId)
          }}
          value={value?.venue_id}
          ariaLabel="Select employer venue"
        />
      </div>
    </div>
  )
}

DataEmployerVenue.propTypes = {
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.any,
  parameters: PropTypes.object,
}

DataEmployerVenue.defaultProps = {
  parameters: {},
  disabled: false,
  value: {
    employer_id: '',
    venue_id: '',
  },
}

export default DataEmployerVenue
