import { bindActionCreators } from 'redux'
import { connect, DefaultRootState } from 'react-redux'

import { apiCsat } from 'syft-acp-util/csat'
import { CsatWorkerEntity } from 'syft-acp-core/store/csatWorkers/types'
import { CsatAgencyEntity } from 'syft-acp-core/store/csatAgencies/types'
import { CsatEmployerEntity } from 'syft-acp-core/store/csatEmployers/types'
import { createEntityListConnector, EntityActions } from 'syft-acp-util/entityList'
import * as csatActions from 'syft-acp-core/actions/csat'

import { CsatDataType, RowData, TableHelpers } from './CsatDataList.types'

export const getTableStructure = ({
  type,
  getReasonsValue,
  getAppIdentifiersValue,
}: {
  type: CsatDataType
  getReasonsValue: TableHelpers
  getAppIdentifiersValue: TableHelpers
}) => {
  if (type === apiCsat.employerCsat) {
    return {
      rowLink: (row: CsatEmployerEntity) => `/entity/employers/view/${row.employer_id}`,
      cols: [
        { type: 'text', value: 'id', header: 'ID', options: { isMinimal: true, isNumeric: true } },
        { type: 'text', value: 'employer_id', header: 'Employer ID', options: { isMinimal: true, isNumeric: true } },
        { type: 'text', value: 'score', header: 'Score', options: { isMinimal: true } },
        { type: 'text', value: getReasonsValue, header: 'Reasons', options: { isMinimal: true } },
        { type: 'text', value: 'comments', header: 'Comments', options: { isMain: true } },
        { type: 'text', value: getAppIdentifiersValue, header: 'App identifies', options: { isMain: true } },
        { type: 'text', value: 'created_at', header: 'Created at', options: { isMain: true } },
        { type: 'text', value: 'update_at', header: 'Updated at', options: { isMain: true } },
      ],
    }
  } else if (type === apiCsat.workerCsat) {
    return {
      rowLink: (row: CsatWorkerEntity) => `/entity/workers/view/${row.worker_id}`,
      cols: [
        { type: 'text', value: 'id', header: 'ID', options: { isMinimal: true, isNumeric: true } },
        { type: 'text', value: 'worker_id', header: 'Worker ID', options: { isMinimal: true, isNumeric: true } },
        { type: 'text', value: 'score', header: 'Score', options: { isMinimal: true } },
        { type: 'bool', value: 'skipped', header: 'Skipped', options: { isMinimal: true } },
        { type: 'text', value: getReasonsValue, header: 'Reasons', options: { isMinimal: true } },
        { type: 'text', value: 'comments', header: 'Comments', options: { isMain: true } },
        { type: 'text', value: getAppIdentifiersValue, header: 'App identifies', options: { isMain: true } },
        { type: 'text', value: 'created_at', header: 'Created at', options: { isMain: true } },
      ],
    }
  }
  return {
    rowLink: (row: CsatAgencyEntity) => `/agencies/view/${row.agency_id}`,
    cols: [
      { type: 'text', value: 'id', header: 'ID', options: { isMinimal: true, isNumeric: true } },
      { type: 'text', value: 'agency_id', header: 'Agency ID', options: { isMinimal: true, isNumeric: true } },
      { type: 'text', value: 'score', header: 'Score', options: { isMinimal: true } },
      { type: 'bool', value: 'skipped', header: 'Skipped', options: { isMinimal: true } },
      { type: 'text', value: getReasonsValue, header: 'Reasons', options: { isMinimal: true } },
      { type: 'text', value: 'comments', header: 'Comments', options: { isMain: true } },
      { type: 'text', value: 'created_at', header: 'Created at', options: { isMain: true } },
      { type: 'text', value: 'update_at', header: 'Updated at', options: { isMain: true } },
    ],
  }
}

export const passEntityStore = (entityStore: keyof DefaultRootState, fetchFn: EntityActions['fetchEntities']) => {
  return createEntityListConnector<RowData>({
    entityActions: {
      fetchEntities: fetchFn,
    },
    entityStore: entityStore,
  })
}

export const storeConnector = connect(null, dispatch => {
  return bindActionCreators(
    {
      fetchCsatCsvData: csatActions.fetchCsatCsvData,
    },
    dispatch
  )
})

export const DOWNLOAD_CSAT_DATA_CSV_BUTTON_CLICKED = 'generic.csat_data.download_csv_button.clicked'
