import React from 'react'
import PropTypes from 'prop-types'
import { payStatuses, paycomStatusKeys } from 'syft-acp-core/data/paycomStatus'

import StatusLabel from './StatusLabel'

const PaycomLabels = ({ value, full }) => (
  <StatusLabel
    value={value}
    groupName={full && 'Pay'}
    typesMap={payStatuses}
    defaultStatus={payStatuses[paycomStatusKeys.unverified]}
  />
)

PaycomLabels.propTypes = {
  value: PropTypes.object,
  full: PropTypes.bool,
}

PaycomLabels.defaultProps = {
  value: null,
  full: false,
}

export default PaycomLabels
