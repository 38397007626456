// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import React from 'react'
import { isString } from 'lodash-es'
import PageContainer from 'syft-acp-atoms/PageContainer'
import PageEntityList from 'syft-acp-atoms/PageEntityList'
import PageText from 'syft-acp-atoms/PageText'
import PageTitle from 'syft-acp-core/components/PageTitle'

import { Props } from './PageView.types'

/**
 * Component that sets a valid page title to a view; this is used for subpages.
 *
 * NOTE: see <ADMN-282> for planned improvements.
 */
const PageView = ({ entityPage, textPage, title, container, narrow, wide, children, customColSizes }: Props) => {
  const pageContent = textPage && isString(children) ? <PageText>{children}</PageText> : children
  const content = (
    <PageTitle title={title}>{entityPage ? <PageEntityList>{children}</PageEntityList> : pageContent}</PageTitle>
  )
  return container ? (
    <PageContainer customColSizes={customColSizes} narrow={narrow} wide={wide}>
      {content}
    </PageContainer>
  ) : (
    content
  )
}

export default PageView
