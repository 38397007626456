// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import React from 'react'
import { TrackingTrigger } from '@indeed/flex-tracking-context'
import TransferredWorkersList from 'syft-acp-core/entities2/TransferredWorkersList'
import { trackingEvents } from 'syft-acp-core/entities2/TransferredWorkersList/TransferredWorkersList.tracking'
import PageView from 'syft-acp-core/components/PageView'
import TransferredWorkerCSVModal from 'syft-acp-core/components/Modal/TransferredWorkerCSVModal'
import { WorkersSubNavigation } from './SubNavigation'

export const EntityTransferredWorkersList = () => (
  <>
    <TrackingTrigger onLoad={{ event: trackingEvents.PAGE.VIEWED }} />
    <WorkersSubNavigation />
    <PageView title={['Transferred workers', 'Overview']} entityPage>
      <TransferredWorkersList />
      <TransferredWorkerCSVModal />
    </PageView>
  </>
)
