import React, { useCallback, useEffect } from 'react'
import { Modal, ModalBody, ModalFooter } from '@indeed/ipl-modal'
import { Box, Button, Flex, Text } from '@indeed/ifl-components'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { getModal$ } from 'syft-acp-core/store/modals/selectors'
import { hideModal } from 'syft-acp-core/store/modals/actions'
import {
  clearPendingConsentWorkerMarketingPreferences,
  updateWorkerMarketingPreferences,
} from 'syft-acp-core/store/workerMarketingPreferences/actions'

import {
  MarketingPreferencesModalOwnProps,
  MarketingPreferencesModalProps as Props,
} from './MarketingPreferencesModal.types'
import { MapPreferenceToCopy } from './MarketingPreferencesModal.constants'
import {
  selectIsWorkerMarketingPreferencesUpdating,
  selectWorkerMarketingPendingConsentPreference,
  selectWorkerMarketingPreferencesToUpdatePayload,
} from 'syft-acp-core/store/workerMarketingPreferences/selectors'
import { useTrackingTrigger } from '@indeed/flex-tracking-context'
import { trackingEvents } from './MarketingPreferencesModal.tracking'

export const modalName = 'marketingPreferencesModal'

export const storeConnector = connect(
  (state, ownProps: MarketingPreferencesModalOwnProps) => ({
    modal: getModal$(state.modals)(modalName),
    pendingPreference: selectWorkerMarketingPendingConsentPreference(state),
    payloadWithConsent: selectWorkerMarketingPreferencesToUpdatePayload(state, ownProps.workerID),
    isUpdating: selectIsWorkerMarketingPreferencesUpdating(state),
  }),
  dispatch => ({
    actions: {
      ...bindActionCreators(
        {
          hideModal,
          updatePreferences: updateWorkerMarketingPreferences,
          clearPending: clearPendingConsentWorkerMarketingPreferences,
        },
        dispatch,
      ),
    },
  }),
)

const MarketingPreferencesModal = ({
  actions,
  modal,
  workerID,
  payloadWithConsent,
  pendingPreference,
  isUpdating,
}: Props) => {
  const triggerEvent = useTrackingTrigger()

  const closeModal = useCallback(() => {
    actions.hideModal(modalName)
  }, [actions])

  const handleConsent = () => {
    if (payloadWithConsent) {
      actions.updatePreferences(workerID, payloadWithConsent)
      triggerEvent(trackingEvents.CONSENT, {
        preference: pendingPreference,
        value: true,
      })
    }
  }

  const handleDoNotConsent = () => {
    closeModal()
    actions.clearPending()
    triggerEvent(trackingEvents.CONSENT, {
      preference: pendingPreference,
      value: false,
    })
  }

  /** Closes modal if we got here without a preference to update in state */
  useEffect(() => {
    if (!pendingPreference) closeModal()
  }, [pendingPreference, closeModal])

  if (!pendingPreference) return null

  return (
    <Modal
      focusTrapObserver
      focusTrapOptions={{ allowOutsideClick: true }}
      isOpen={modal.isShown}
      title={MapPreferenceToCopy[pendingPreference].TITLE}
      size="sm"
      onExit={closeModal}
    >
      <ModalBody>
        <Text as="p" level={3}>
          Please read this to the worker and check the worker agrees to consent.
        </Text>
        <Text as="p" level={3}>
          <Box as="strong">”{MapPreferenceToCopy[pendingPreference].COPY}”</Box>
        </Text>
      </ModalBody>
      <ModalFooter sx={{ display: 'flex', justifyContent: 'center' }}>
        <Flex sx={{ flexDirection: 'column', inlineSize: '100%' }}>
          <Button onClick={handleConsent} full disabled={isUpdating}>
            Consent
          </Button>
          <Button onClick={handleDoNotConsent} full sx={{ marginBlockStart: 5 }} disabled={isUpdating}>
            Do not consent
          </Button>
        </Flex>
      </ModalFooter>
    </Modal>
  )
}

export default storeConnector(MarketingPreferencesModal)
