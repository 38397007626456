// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import * as React from 'karet'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import classnames from 'classnames'
import { noop, isString, isArray, omit, compact } from 'lodash-es'
import { LinkContainer } from 'react-router-bootstrap'
import { Checkbox, DropdownButton, MenuItem as BootstrapMenuItem } from 'react-bootstrap'

import iconFactory from 'syft-acp-atoms/Icons'

import './Button.css'

// Simply pass on ButtonGroup from React Bootstrap verbatim. We don't need any changes for now.
export { ButtonGroup } from 'react-bootstrap'

// Don't put these props in the DOM.
const omitPropItems = [
  'hideDisabledState',
  'bright',
  'kind',
  'inline',
  'isBright',
  'block',
  'active',
  'isActive',
  'inTable',
  'data',
  'parameters',
  'link',
]

const omitProps = props => omit(props, omitPropItems)

/** Standard proptypes for anything utilizing <Button /> */
const buttonPropTypes = {
  to: PropTypes.string,
  className: PropTypes.string,
  disabled: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
  hideDisabledState: PropTypes.bool,
  kind: PropTypes.oneOf(['primary', 'regular', 'neutral', 'success', 'warning', 'danger', 'highlighted']),
  bright: PropTypes.bool,
  block: PropTypes.bool,
  active: PropTypes.bool,
  type: PropTypes.string,
  inTable: PropTypes.bool,
  onClick: PropTypes.func,
  children: PropTypes.node,
}

const buttonDefaultProps = {
  to: null,
  className: '',
  disabled: false,
  hideDisabledState: false,
  kind: 'regular',
  bright: false,
  block: false,
  active: false,
  type: null,
  inTable: false,
  onClick: noop,
  children: null,
}

/**
 * Creates a button component with a custom render function.
 * This is used to generate all button types below.
 *
 * A <Button /> can be of kind 'primary', 'regular', 'neutral', 'success', 'warning' or 'danger'.
 * Also, a button can pass on a 'bright' attribute which makes its coloring lighter.
 * When passing 'active', the button will appear pressed down.
 */
const buttonFactory = renderFunction => props => {
  const { className, kind, inTable, bright, block, active, hideDisabledState } = props
  const fullClassName = classnames(className, kind, 'btn', {
    bright,
    block,
    active,
    'no-disabled': hideDisabledState,
    'in-table': inTable,
  })
  return renderFunction({ ...props, className: fullClassName })
}

buttonFactory.propTypes = buttonPropTypes

buttonFactory.defaultProps = buttonDefaultProps

/** Regular button component */
export const Button = buttonFactory(({ children, className, onClick, disabled, ...props }) => (
  <button
    type="button"
    className={classnames(className, 'Button')}
    onClick={onClick}
    disabled={disabled}
    {...omitProps(props)}
  >
    {children}
  </button>
))

Button.propTypes = buttonPropTypes

Button.defaultProps = buttonDefaultProps

/** Button containing an icon, either an Octicons string or a React component */
export const ButtonIcon = ({
  children,
  className,
  onClick,
  icon,
  iconSize,
  left,
  right,
  iconLabel,
  ...props
}) => {
  const IconNode = isString(icon) ? iconFactory(icon, iconLabel) : icon
  return (
    <Button {...{ ...props, onClick, className: classnames(className, 'ButtonIcon') }}>
      {left && [<IconNode iconSize={iconSize} key="icon" />, children ? '\u00a0' : '']}
      {children}
      {(right || (!left && !right)) && [
        children ? '\u00a0' : '',
        <IconNode iconSize={iconSize} key="icon" />,
      ]}
    </Button>
  )
}

ButtonIcon.propTypes = {
  ...buttonPropTypes,
  iconSize: PropTypes.number,
  icon: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  left: PropTypes.bool,
  right: PropTypes.bool,
  className: PropTypes.string,
  iconLabel: PropTypes.string,
}

ButtonIcon.defaultProps = {
  ...buttonDefaultProps,
  iconSize: 12,
  icon: null,
  left: false,
  right: false,
  className: undefined,
  iconLabel: undefined,
}

/** Link component that looks like a button. */
export const ButtonLink = buttonFactory(
  ({ children, className, to, external, onClick, disabled, hideDisabledState, target }) => {
    const LinkComponent = external ? 'a' : Link
    return (
      <LinkComponent
        className={classnames(className, 'Button', 'ButtonLink', { 'no-disabled': hideDisabledState })}
        role="button"
        target={target}
        {...(external ? { href: to } : { to })}
        onClick={e => {
          if (!to) {
            e.preventDefault()
          }
          onClick?.(e)
        }}
        disabled={disabled}
      >
        {children}
      </LinkComponent>
    )
  },
)

ButtonLink.propTypes = {
  ...buttonPropTypes,
  to: PropTypes.string,
  external: PropTypes.bool,
  target: PropTypes.string,
}

ButtonLink.defaultProps = {
  ...buttonDefaultProps,
  to: null,
  external: false,
  target: null,
}

/** A button containing a checkbox */
export const ButtonBoolean = ({
  children,
  className,
  onClick,
  checked,
  component: Component = Checkbox,
  ...props
}) => (
  <Button {...{ ...props, onClick, className: classnames(className, 'ButtonBoolean', { checked }) }}>
    <Component checked={checked} onChange={onClick} />
    {children}
  </Button>
)

ButtonBoolean.propTypes = {
  ...buttonPropTypes,
  checked: PropTypes.bool,
}

ButtonBoolean.defaultProps = {
  ...buttonDefaultProps,
  checked: false,
}

/**
 * A dropdown menu button containing a menu.
 *
 * Use as follows:
 *
 *    <ButtonDropdown title="Actions" id="dropdown_actions">
 *      <MenuItem key="1" to="/dashboard">Link to dashboard</MenuItem>
 *      <MenuItem key="2" onClick={ this.doSomething }>Do something</MenuItem>
 *    </ButtonDropdown>
 *
 * If a <MenuItem> has a 'to' field, it will be wrapped in a <LinkContainer>
 * and behave as a link rather than as a button (including being openable in a new tab).
 */
export const ButtonDropdown = ({
  children,
  kind,
  className,
  bright,
  block,
  hideDisabledState,
  disabled,
  ...props
}) => {
  // Despite the name, 'children' is not an array if it consists of one item.
  const childList = children == null || isArray(children) ? children : [children]
  return (
    <DropdownButton
      karet-lift
      {...{
        ...omitProps(props),
        className: classnames(className, 'Button', 'ButtonDropdown', kind, {
          bright,
          block,
          'no-disabled': hideDisabledState,
        }),
      }}
      disabled={disabled}
    >
      {childList &&
        compact(childList).map((child, n) => {
          // If this MenuItem has a 'to' prop, wrap it in a LinkContainer.
          const { to, ...rest } = child.props || {}
          return to ? (
            <LinkContainer to={to} key={`ButtonDropdownMenuItem_${n}`} {...rest}>
              {child}
            </LinkContainer>
          ) : (
            child
          )
        })}
    </DropdownButton>
  )
}

ButtonDropdown.propTypes = {
  ...buttonPropTypes,
  title: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
}

ButtonDropdown.defaultProps = {
  ...buttonDefaultProps,
}

/**
 * Dropdown menu item.
 *
 * Note: we're not passing on 'bright' as prop because the name clashes with one of Bootstrap's props.
 */
export const MenuItem = props => {
  return <BootstrapMenuItem {...omitProps(props)} className={classnames(props.className, 'MenuItem')} />
}

MenuItem.propTypes = {
  ...buttonPropTypes,
  checked: PropTypes.bool,
}

MenuItem.defaultProps = {
  ...buttonDefaultProps,
  checked: false,
}

/** Icon of a checkmark */
const MenuItemBooleanIcon = iconFactory('check', 'On')

/** Dropdown menu item, containing an optional checkmark. */
export const MenuItemBoolean = ({
  checked,
  className,
  bright,
  block,
  hideDisabledState,
  children,
  ...props
}) => {
  const fullClassName = classnames(className, 'MenuItemBoolean', {
    bright,
    block,
    'no-disabled': hideDisabledState,
  })
  if (props.divider) {
    return <MenuItem {...{ ...omitProps(props), checked, className: fullClassName }} />
  }
  return (
    <MenuItem {...{ ...omitProps(props), checked, className: fullClassName }}>
      {checked && <MenuItemBooleanIcon />}
      {children}
    </MenuItem>
  )
}

MenuItemBoolean.propTypes = {
  ...buttonPropTypes,
  checked: PropTypes.bool,
}

MenuItemBoolean.defaultProps = {
  ...buttonDefaultProps,
  checked: false,
}
