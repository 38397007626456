import React from 'react'
import entityList from 'syft-acp-util/entityList'
import EntityListComponent from 'syft-acp-core/components/EntityList/EntityListComponent'
import { fetchAdminConversations } from 'syft-acp-core/actions/conversations'
import * as tableType from 'syft-acp-core/components/EntityList/'
import { FilterGroup, FilterText } from 'syft-acp-util/components/FilterForm'

const tableFormat = [
  { type: tableType.TXT, val: 'id', header: 'ID', numeric: true },
  {
    type: tableType.USER_LINE,
    val: 'worker',
    header: 'Worker',
  },
  {
    type: tableType.USER_LINE,
    val: 'employer',
    header: 'Employer',
  },
  {
    type: tableType.DATETIME,
    val: 'last_message_at',
    header: 'Last message',
  },
  {
    type: tableType.TXT,
    val: 'job.venue_name',
    header: 'Last job venue',
  },
  {
    type: tableType.TXT,
    val: 'job.role_name',
    header: 'Last job role',
  },
  {
    type: tableType.DATETIME,
    val: 'job.start_time',
    header: 'Start time',
  },
  {
    type: tableType.TXT,
    val: 'flagged_words_count',
    header: 'Flagged words',
  },
]

const filters = (
  <div>
    <FilterGroup title="Filter by worker">
      <FilterText name="worker_name" placeholder="Worker name" />
    </FilterGroup>
    <FilterGroup title="Filter by employer">
      <FilterText name="employer_name" placeholder="Employer name" />
    </FilterGroup>
  </div>
)

const ConversationsListComponent = entityList(
  EntityListComponent,
  tableFormat,
  filters,
  [],
  'conversations',
  { fetchEntities: fetchAdminConversations },
  {
    showResultCount: true,
    fullWidth: true,
    urlEntity: false,
    rowClasses: data => data.flagged_words_count > 0 && 'yellow',
  }
)

const ConversationsList = () => {
  return <ConversationsListComponent urlGenerator={row => `/watchlist/conversations/view/${row.id}`} />
}

export default ConversationsList
