import * as placeholders from './components/placeholders'
import * as colComponents from './components/AcpTable/cellComponents/dataTypes/placeholders'
import * as filterComponents from './components/AcpTable/filterComponents'

import AcpApp from './components/AcpApp'
import AcpComponent from './components/AcpComponent'
import AcpEntityList from './components/AcpEntityList'
import AcpTable, { iconTypes } from './components/AcpTable'

export { AbbrHeader } from './components/AcpTable/headerComponents'

const Acp = {
  App: AcpApp,
  Component: AcpComponent,
  GenericComponent: placeholders.AcpGenericComponent,
  EntityList: AcpEntityList,
  Icon: iconTypes,
  Table: AcpTable,
  Filter: {
    Text: filterComponents.AcpFilterText,
    Number: filterComponents.AcpFilterNumber,
    Worker: filterComponents.AcpFilterWorker,
    DateRange: filterComponents.AcpFilterDateRange,
    Dropdown: filterComponents.AcpFilterDropdown,
    Enum: filterComponents.AcpFilterEnum,
    Switch: filterComponents.AcpFilterSwitch,
  },
  FilterGroup: placeholders.AcpFilterGroup,
  Col: {
    Boolean: colComponents.AcpColBoolean,
    Button: colComponents.AcpColButton,
    Duration: colComponents.AcpColDuration,
    Email: colComponents.AcpColEmail,
    Employer: colComponents.AcpColEmployer,
    Enum: colComponents.AcpColEnum,
    Industries: colComponents.AcpColIndustries,
    Money: colComponents.AcpColMoney,
    Phone: colComponents.AcpColPhone,
    Rating: colComponents.AcpColRating,
    RoleSkill: colComponents.AcpColRoleSkill,
    Selector: colComponents.AcpColSelector,
    LegacySelector: colComponents.AcpColSelectorLegacy,
    ActionDropdown: colComponents.AcpColActionDropdown,
    Text: colComponents.AcpColText,
    Image: colComponents.AcpColImage,
    Timestamp: colComponents.AcpColTimestamp,
    RolesIndustriesMultiselect: colComponents.AcpColRolesIndustriesMultiselect,
    DateTime: colComponents.AcpColDateTime,
    Worker: colComponents.AcpColWorker,
    WorkerStatus: colComponents.AcpColWorkerStatus,
    Status: colComponents.AcpColStatus,
    Popover: colComponents.AcpColPopover,
    Info: colComponents.AcpColInfo,
  },
  ColGroup: placeholders.AcpColGroup,
  ColEditable: {
    Dropdown: colComponents.AcpEditableColDropdown,
    Text: colComponents.AcpEditableColText,
    Boolean: colComponents.AcpEditableColBoolean,
    Manager: colComponents.AcpEditableColManager,
  },
  Actions: placeholders.AcpActions,
  ControlGroup: placeholders.AcpControlGroup,
  Button: placeholders.AcpButton,
  ButtonDropdown: placeholders.AcpButtonDropdown,
  ButtonLink: placeholders.AcpButtonLink,
  Enum: placeholders.AcpEnum,
  Option: placeholders.AcpOption,
  Footer: placeholders.AcpFooter,
  Header: placeholders.AcpHeader,
  SubRows: placeholders.AcpSubRows,
}

export default Acp
