// Syft ACP - UI Library <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import './RecordSegment.css'

class RecordSegment extends React.PureComponent {
  static propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    footer: PropTypes.bool,
    noBorder: PropTypes.bool,
    noPadding: PropTypes.bool,
    withTable: PropTypes.bool,
    withText: PropTypes.bool,
  }

  static defaultProps = {
    children: null,
    className: '',
    footer: false,
    noBorder: false,
    noPadding: false,
    withTable: false,
    withText: false,
  }

  getClass() {
    const { withTable, withText, noPadding, footer, noBorder, className } = this.props
    return classnames('acp-record-segment', {
      'with-table': withTable,
      'with-text': withText,
      'no-padding': noPadding,
      'no-border': noBorder,
      footer,
      className,
    })
  }

  render() {
    const { children } = this.props
    return <div className={this.getClass()}>{children}</div>
  }
}

export default RecordSegment
