import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Field, Form } from 'react-final-form'
import { FormApi } from 'final-form'
import { ControlLabel, FormControl, FormGroup } from 'react-bootstrap'
import { omit } from 'lodash-es'

import { getModal$ } from 'syft-acp-core/store/modals/selectors'
import { notify } from 'syft-acp-core/actions/notifications'
import { showModal, hideModal } from 'syft-acp-core/store/modals/actions'
import { Modal } from 'syft-acp-core/components/Modal'
import { getModalCanSubmit, getModalErrorText, getValidationState, getValidator } from './helpers'
import { AddWorkerRetroactiveShiftModalProps as Props } from './AddWorkerRetroactiveShiftModal.types'
import TextField from '../TextField'
import { RolesSelect } from '../../RolesSelect'
import { reqDefaults } from 'syft-acp-core/api/call'
import { apiURL } from 'syft-acp-core/api/endpoints'
import FormDateTimePickerInput from '../../Form/FormDateTimePickerInput'
import { getErrors } from 'syft-acp-core/lib/notifications'
import { fetchWorkerShifts } from 'syft-acp-core/store/workerShifts/actions'
import classnames from 'classnames'
import { Button } from '../../../../atoms/Button'

export const storeConnector = connect(
  state => ({
    item: getModal$(state.modals)('addWorkerRetroactiveShiftModal'),
  }),
  dispatch => ({
    actions: {
      ...bindActionCreators({ notify, showModal, hideModal, fetchWorkerShifts }, dispatch),
    },
  }),
)

const insertMealTimePunches = (timePunches: any[]) => {
  if (timePunches.length <= 1) {
    return timePunches.map(timePunch => ({ ...timePunch, code: 'REG' }))
  }

  const newTimePunches: any[] = []
  for (let i = 0; i < timePunches.length - 1; i++) {
    newTimePunches.push({ ...timePunches[i], code: 'REG' })
    newTimePunches.push({
      punch_in: timePunches[i]?.punch_out,
      punch_out: timePunches[i + 1]?.punch_in,
      code: 'MEAL',
    })
  }
  newTimePunches.push({ ...timePunches[timePunches.length - 1], code: 'REG' })

  return newTimePunches
}

const retroactive_reasons = [
  'Shift not in App',
  'Shift outside pay cycle',
  'Workweek problem',
  'Compensation pay',
  'Time Submitted after deadline',
  'Other',
]

type Shift = {
  id: number
  venue_id: number
}

type PayRate = {
  amount: number
  currency: string
}

type ReferenceShift = {
  worker_id: number
  employer_id: number
  shift: Shift
  role_id: number
  job_description: string
  worker_pay_rate: PayRate
  break_duration: number
  purchase_order_number: string
  area_id: string
}

type State = {
  timeZone: string | null
  hasTimePunches: boolean
  shiftInfo: ReferenceShift[]
  referenceShift: ReferenceShift | null
}

class AddWorkerRetroactiveShiftModal extends PureComponent<Props, State> {
  private isFullyMounted: boolean = false

  constructor(props: Props) {
    super(props)

    this.state = {
      ...this.state,
      timeZone: null,
      hasTimePunches: false,
    }
  }

  async componentDidMount(): Promise<void> {
    this.isMounted = true
    this.fetchShift(this.props.worker.id).then(result => {
      if (this.isMounted) this.setState({ shiftInfo: result })
    })
  }

  componentWillUnmount() {
    this.isMounted = false
  }

  public set isMounted(status: boolean) {
    this.isFullyMounted = status
  }

  public get isMounted() {
    return this.isFullyMounted
  }

  submit = (values: any, form: FormApi<any, any>) =>
    new Promise<void | string>(async resolve => {
      const { actions, worker } = this.props

      const [role_id] = values.role_id.split(';')

      const data = {
        ...omit(values, [
          'pay_rate',
          'role_id',
          'clock_in_time',
          'clock_out_time',
          'break_duration',
          'syft_compensation',
          'retroactive_reason',
          'other_retroactive_reason',
        ]),
        worker_id: worker.id,
        role_id,
        pay_rate: {
          amount: parseFloat(values.pay_rate),
          currency: worker.earnings_to_date?.currency,
        },
        syft_compensation: values.syft_compensation,
        retroactive_reason: values.retroactive_reason,
        other_retroactive_reason: values.other_retroactive_reason,
      }

      const dataWithClockInOut = this.state.hasTimePunches
        ? {
            ...data,
            time_punches: insertMealTimePunches(values.time_punches),
            clock_in: undefined,
            clock_out: undefined,
          }
        : {
            ...data,
            clock_in: values.clock_in_time,
            clock_out: values.clock_out_time,
            break_duration: values.break_duration * 60,
            time_punches: undefined,
          }

      try {
        const req = new Request(apiURL(`/admin/retroactive/shift_bookings`), {
          ...reqDefaults('POST', 'application/json'),
          body: JSON.stringify(dataWithClockInOut),
        })
        const result = await fetch(req)
        const resultData = await result.json()
        if (result.ok) {
          form.reset()
          this.closeModal()

          actions.notify('success', {
            title: 'Added Retroactive Shift',
            message: <>Retroactive Shift entry has been added.</>,
          })
          return resolve()
        } else {
          const errorDetails = getErrors({ body: resultData }, true) as Record<string, any>

          actions.notify('error', {
            title: 'Could not add missing pay',
            ...errorDetails,
          })

          actions.fetchWorkerShifts(worker.id, {})
          return resolve(errorDetails.message)
        }
      } catch (e) {
        const message =
          'Something went wrong while sending data to the API. See the error page for more details.'

        actions.notify('error', {
          title: 'Could not add retroactive shift',
          message,
          autoDismiss: 0,
        })

        return resolve(message)
      }
    })

  fetchVenue = async (employerId: string, venueId: string) => {
    if (!employerId || !venueId) {
      return
    }

    const req = new Request(apiURL(`/employers/${employerId}/venues/${venueId}`), {
      ...reqDefaults('GET', 'application/json'),
      body: null,
    })
    const result = await fetch(req)
    const resultData = await result.json()
    if (result.ok) {
      this.setState({
        timeZone: resultData.timezone,
        hasTimePunches: resultData.address.federated_state_code === 'CA',
      })
      return
    }

    this.setState({ timeZone: null, hasTimePunches: false })
  }

  fetchShift = async (workerId: string) => {
    if (!workerId) {
      return
    }

    const req = new Request(apiURL(`/admin/retroactive/shift_bookings/recent/${workerId}`), {
      ...reqDefaults('GET', 'application/json'),
      body: null,
    })
    const result = await fetch(req)
    return await result.json()
  }

  closeModal = () => this.props.actions.hideModal(this.props.item.modalName)

  openModal = () => this.props.actions.showModal(this.props.item.modalName)

  render() {
    const data = {
      employer_id: this.state.referenceShift?.employer_id,
      venue_id: this.state.referenceShift?.shift.venue_id,
      role_id: this.state.referenceShift?.role_id,
      job_description: this.state.referenceShift?.job_description,
      pay_rate: this.state.referenceShift?.worker_pay_rate.amount,
      clock_in_time: new Date(),
      clock_out_time: new Date(),
      time_punches: [{ punch_in: new Date(), punch_out: new Date() }],
      syft_compensation: false,
      purchase_order_number: this.state.referenceShift?.purchase_order_number,
      area_id: this.state.referenceShift?.area_id,
    }

    const formatBoolToString = (input: Boolean): String => {
      return input ? 'true' : 'false'
    }
    const parseStringToBool = (input: String): Boolean => {
      return input === 'true'
    }

    return (
      <Form
        onSubmit={this.submit}
        initialValues={data}
        keepDirtyOnReinitialize
        render={({ handleSubmit, invalid, submitting, submitFailed, submitErrors, values }) => (
          <Modal
            header="Add worker retroactive shift"
            isShown={this.props.item.isShown}
            onClose={this.closeModal}
            onConfirm={handleSubmit}
            canSubmit={getModalCanSubmit(invalid, submitting, submitFailed)}
            errorText={getModalErrorText(submitFailed, submitting, submitErrors)}
            cancelText="Cancel"
            confirmationText="Add Retroactive Shift"
          >
            <form onSubmit={handleSubmit}>
              <FormGroup className="missing-pay-modal">
                <FormGroup>
                  <Field name="hasShiftId" type="radio" value="false" validate={getValidator(true)}>
                    {({ input }) => (
                      <label className="radio-inline">
                        <input {...input} disabled={submitting} type="radio" />
                        No Reference Shift ID
                      </label>
                    )}
                  </Field>
                  <Field
                    name="hasShiftId"
                    type="radio"
                    value="true"
                    defaultValue="true"
                    validate={getValidator(true)}
                  >
                    {({ input }) => (
                      <label className="radio-inline">
                        <input {...input} disabled={submitting} type="radio" />
                        Reference Shift ID
                      </label>
                    )}
                  </Field>
                </FormGroup>
                {values.hasShiftId && this.state.shiftInfo && (
                  <FormGroup>
                    <Field name="shift_id">
                      {({ input }) => (
                        <FormGroup className="optional">
                          <ControlLabel>Copy from Shift id</ControlLabel>
                          <FormControl
                            value={input.value}
                            disabled={submitting}
                            onChange={(shift_id: any) => {
                              input.onChange(shift_id)
                              this.setState({ referenceShift: this.state.shiftInfo[shift_id.target.value] })
                              this.fetchVenue(
                                String(this.state.shiftInfo[shift_id.target.value]?.employer_id),
                                String(this.state.shiftInfo[shift_id.target.value]?.shift.venue_id),
                              )
                            }}
                            componentClass="select"
                            placeholder="select"
                          >
                            <option value="select">— Select a shift ID —</option>
                            {this.state.shiftInfo.map((info, index) => (
                              <option value={index}>{info?.shift.id}</option>
                            ))}
                          </FormControl>
                        </FormGroup>
                      )}
                    </Field>
                  </FormGroup>
                )}
                <FormGroup>
                  <Field name="employer_id" validate={getValidator(true)}>
                    {({ input, meta }) => (
                      <FormGroup
                        validationState={getValidationState(meta)}
                        className={classnames({ required: true })}
                      >
                        <ControlLabel>Employer ID</ControlLabel>
                        {/* @ts-expect-error */}
                        <FormControl
                          type="text"
                          disabled={submitting}
                          placeholder=""
                          {...input}
                          onChange={async (e: any) => {
                            input.onChange(e.target.value)
                            this.fetchVenue(e.target.value, values.venue_id)
                          }}
                        />
                      </FormGroup>
                    )}
                  </Field>
                </FormGroup>
                <FormGroup>
                  <Field name="venue_id" validate={getValidator(true)}>
                    {({ input, meta }) => (
                      <FormGroup
                        validationState={getValidationState(meta)}
                        className={classnames({ required: true })}
                      >
                        <ControlLabel>Venue ID</ControlLabel>
                        {/* @ts-expect-error */}
                        <FormControl
                          type="text"
                          disabled={submitting}
                          placeholder=""
                          {...input}
                          onChange={async (e: any) => {
                            input.onChange(e.target.value)
                            this.fetchVenue(values.employer_id, e.target.value)
                          }}
                        />
                      </FormGroup>
                    )}
                  </Field>
                  <RolesSelect
                    disabled={submitting}
                    label="Worker Role"
                    name="role_id"
                    required
                    initialValues={data}
                  />
                  <TextField
                    disabled={submitting}
                    label="Job Description"
                    name="job_description"
                    required
                    type="textarea"
                  />
                  <TextField disabled={submitting} label="Pay Rate" name="pay_rate" required />
                  {this.state.hasTimePunches ? (
                    <>
                      {Array.from({ length: values.time_punches.length }).map((_, index) => (
                        <>
                          <Field
                            name={`time_punches.${index}.punch_in`}
                            type="time"
                            validate={getValidator(true)}
                          >
                            {({ input }) => (
                              <FormGroup>
                                <ControlLabel>Punch In {index + 1}</ControlLabel>
                                <FormDateTimePickerInput
                                  initialValue={values.time_punches[index].punch_in}
                                  onChange={(punchIn: any) => input.onChange(punchIn)}
                                  name={`time_punches.${index}.punch_in`}
                                  id={`time_punches.${index}.punch_in`}
                                  timeZone={this.state.timeZone}
                                  disabled={submitting || !this.state.timeZone}
                                  required={false}
                                  allowNull
                                  customFormat={'YYYY-MM-DD hh:mm:ss A'}
                                />
                              </FormGroup>
                            )}
                          </Field>
                          <Field
                            name={`time_punches.${index}.punch_out`}
                            type="time"
                            validate={getValidator(true)}
                          >
                            {({ input }) => (
                              <FormGroup>
                                <ControlLabel>Punch Out {index + 1}</ControlLabel>
                                <FormDateTimePickerInput
                                  initialValue={values.time_punches[index]?.punch_out}
                                  onChange={(punchOut: any) => input.onChange(punchOut)}
                                  name={`time_punches.${index}.punch_out`}
                                  id={`time_punches.${index}.punch_out`}
                                  timeZone={this.state.timeZone}
                                  disabled={submitting || !this.state.timeZone}
                                  allowNull
                                  customFormat={'YYYY-MM-DD hh:mm:ss A'}
                                />
                              </FormGroup>
                            )}
                          </Field>
                        </>
                      ))}

                      {values.time_punches.length < 3 && (
                        <Field name="time_punches">
                          {({ input }) => (
                            <div>
                              <Button
                                type="button"
                                onClick={() =>
                                  input.onChange([
                                    ...values.time_punches,
                                    { punch_in: new Date(), punch_out: new Date() },
                                  ])
                                }
                              >
                                Add a time punch
                              </Button>
                            </div>
                          )}
                        </Field>
                      )}
                    </>
                  ) : (
                    <>
                      <Field name="clock_in_time" type="time" validate={getValidator(true)}>
                        {({ input }) => (
                          <FormGroup className="required">
                            <ControlLabel>Clock In</ControlLabel>
                            <FormDateTimePickerInput
                              value={values.clock_in_time}
                              onChange={(clockIn: any) => input.onChange(clockIn)}
                              name="clock_in_time"
                              id="clock_in_time"
                              timeZone={this.state.timeZone}
                              required
                              disabled={submitting || !this.state.timeZone}
                              customFormat={'YYYY-MM-DD hh:mm:ss A'}
                            />
                          </FormGroup>
                        )}
                      </Field>
                      <Field name="clock_out_time" type="time" validate={getValidator(true)}>
                        {({ input }) => (
                          <FormGroup className="required">
                            <ControlLabel>Clock Out</ControlLabel>
                            <FormDateTimePickerInput
                              value={values.clock_out_time}
                              onChange={(clockOut: any) => input.onChange(clockOut)}
                              name="clock_out_time"
                              id="clock_out_time"
                              timeZone={this.state.timeZone}
                              required
                              disabled={submitting || !this.state.timeZone}
                              customFormat={'YYYY-MM-DD hh:mm:ss A'}
                            />
                          </FormGroup>
                        )}
                      </Field>
                      <TextField disabled={submitting} label="Break Duration (mins)" name="break_duration" />
                    </>
                  )}
                  <TextField
                    disabled={submitting}
                    label="Purchase Order Number"
                    name="purchase_order_number"
                  />
                  <TextField disabled={submitting} label="Area ID" name="area_id" />
                  <Field name="syft_compensation" format={formatBoolToString} parse={parseStringToBool}>
                    {({ input }) => (
                      <FormGroup>
                        <ControlLabel>Should the client be billed for this shift?</ControlLabel>
                        <FormControl
                          value={input.value.toString()}
                          onChange={(syftCompensation: any) => input.onChange(syftCompensation)}
                          componentClass="select"
                        >
                          <option value="false">Yes</option>
                          <option value="true">No</option>
                        </FormControl>
                      </FormGroup>
                    )}
                  </Field>
                  <Field name="retroactive_reason">
                    {({ input }) => (
                      <FormGroup className="required">
                        <ControlLabel>
                          What is the reason this retroactive shift is being created?
                        </ControlLabel>
                        <FormControl
                          value={input.value}
                          onChange={(retroactive_reason: any) => {
                            input.onChange(retroactive_reason)
                          }}
                          componentClass="select"
                          placeholder="select"
                        >
                          <option value="select">— Select a reason —</option>
                          {retroactive_reasons.map(reason => (
                            <option value={reason}>{reason}</option>
                          ))}
                        </FormControl>
                      </FormGroup>
                    )}
                  </Field>
                  <TextField
                    disabled={values.retroactive_reason !== 'Other'}
                    label="Provide reason:"
                    name="other_retroactive_reason"
                  />
                </FormGroup>
              </FormGroup>
            </form>
          </Modal>
        )}
      />
    )
  }
}

export default storeConnector(AddWorkerRetroactiveShiftModal)
