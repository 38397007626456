import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { get } from 'lodash-es'

import { isDraft } from 'syft-acp-core/sagas/calls/bookingCalls'
import * as filterTypes from 'syft-acp-util/components/FilterForm/FilterForm'
import * as tableType from 'syft-acp-core/components/EntityList/'
import { fetchAgencyBookings } from 'syft-acp-core/actions/agency-bookings'
import entityDetailList from 'syft-acp-core/components/EntityDetail/entityDetailList'
import entityList from 'syft-acp-util/entityList'
import AddNoShowReasonModal from 'syft-acp-core/components/Modal/AddNoShowReasonModal'
import { EntityAlertConnected } from 'syft-acp-core/components/Alerts/EntityAlert'
import { makePropertyCheck } from 'syft-acp-core/store/filters/helpers'
import { getListingAgencyShiftBookingsSelector } from 'syft-acp-core/selectors'
import { ListingSaveAgencyBookingsButton } from './ListingButtons'
import { ListingAgencyTimeInput, ListingAgencyBreakInput, ListingAgencyNoShowCheckbox } from './ListingInputs'

import './ListingShiftBookings.css'

// eslint-disable-next-line camelcase
const isDisabled = ({ id, no_show } = {}) => isDraft(id) || no_show

const tableFormat = (enableListingDetailRoleChanges, roles) => [
  {
    type: tableType.TXT,
    val: 'id',
    header: 'ID',
    numeric: true,
  },
  {
    type: tableType.START_END,
    vals: ['startTime', 'endTime'],
    noBold: true,
    classes: ['time'],
    header: 'Time',
    options: data => ({ timeZone: get(data, 'timezone') }),
  },
  {
    type: tableType.CUSTOM_COMPONENT,
    val: v => (
      <ListingAgencyTimeInput
        attr="clock_in_time"
        isClockIn
        shiftBooking={v}
        disabled={isDisabled(v)}
        timeZone={get(v, 'timezone')}
      />
    ),
    classes: ['in'],
    valHints: ['clock_in_time', 'syft_unpaid_hours_compensation'],
    passProps: ['shiftID'],
    header: 'In',
    hasLink: false,
  },
  {
    type: tableType.CUSTOM_COMPONENT,
    val: v => (
      <ListingAgencyTimeInput
        attr="clock_out_time"
        isClockOut
        shiftBooking={v}
        disabled={isDisabled(v)}
        timeZone={get(v, 'timezone')}
      />
    ),
    header: 'Out',
    classes: ['out'],
    valHints: ['clock_out_time', 'syft_unpaid_hours_compensation'],
    hasLink: false,
  },
  {
    type: tableType.CUSTOM_COMPONENT,
    val: v => (
      <ListingAgencyBreakInput
        attr="break.duration"
        shiftBooking={v}
        type="time"
        disabled={isDisabled(v)}
        isDuration
        step="1"
      />
    ),
    valHints: ['break.duration'],
    classes: ['booking-break'],
    header: 'Break',
    hasLink: false,
  },
  {
    type: tableType.TXT,
    val: 'full_name',
    header: 'Agency Worker',
  },
  !!enableListingDetailRoleChanges && {
    type: tableType.TXT,
    val: row => {
      return row?.role_id && roles?.[row?.role_id]?.title
    },
    header: 'Role',
  },
  {
    type: tableType.TXT,
    val: 'venue.name',
    header: 'Venue',
  },
  {
    type: tableType.TXT,
    val: 'area.name',
    header: 'Area',
  },
  {
    type: tableType.CUSTOM_COMPONENT,
    val: v => <ListingAgencyNoShowCheckbox shiftBooking={v} disabled={isDraft(v.id)} />,
    header: 'NS',
    expl: 'No show',
    classes: ['tiny', 'minimal'],
    valHints: ['no_show', 'no_show_reason'],
    hasLink: false,
  },
]

const filters = null

const actionFormat = [
  {
    label: 'Save',
    type: filterTypes.TYPE_CUSTOM_BUTTON,
    passProps: ['shiftID'],
    customButton: ListingSaveAgencyBookingsButton,
  },
]

export const entityStore = 'listingAgencyShiftBookings'

const StoreAlerts = EntityAlertConnected(entityStore)
const ListingShiftBookingsListWrapper = entityDetailList({
  title: 'Agency booking subsection',
  className: 'listing-shift-workers',
  notificationsNode: <StoreAlerts />,
})

const listingAgencyShiftBookingsList = ({ enableListingDetailRoleChanges, roles }) =>
  entityList(
    ListingShiftBookingsListWrapper,
    tableFormat(enableListingDetailRoleChanges, roles),
    filters,
    actionFormat,
    entityStore,
    {},
    {
      debugString: 'ListingAgencyShiftBookingsList',
      localPagination: true,
      localPaginationSize: 8,
      disableAutoScroll: true,
      idFunction: makePropertyCheck(
        [
          'id',
          'no_show',
          'break.duration',
          'shiftID',
          'clock_out_time',
          'clock_in_time',
          'startTime',
          'endTime',
        ],
        true,
      ),
      recordList: true,
    },
  )

class ListingAgencyShiftBookings extends React.PureComponent {
  componentDidMount() {
    const { listingID, shiftID, actions } = this.props
    actions.fetchAgencyBookings(listingID, shiftID)
  }

  render() {
    const { entityListData, venue, roles, enableListingDetailRoleChanges } = this.props
    const data = entityListData.agency_shift_bookings.map(item => ({
      startTime: item.start_time,
      endTime: item.end_time,
      shiftID: this.props.shiftID,
      timezone: get(venue, 'timezone'),
      ...item,
    }))
    const ListingAgencyShiftBookingsListComponent = listingAgencyShiftBookingsList({
      enableListingDetailRoleChanges,
      roles,
    })
    return (
      <div className="ListingShiftBookingsList">
        <AddNoShowReasonModal />
        <ListingAgencyShiftBookingsListComponent
          hasLinks={false}
          {...this.props}
          entityList={data}
          venue={venue}
          roles={roles}
        />
      </div>
    )
  }
}

ListingAgencyShiftBookings.propTypes = {
  shiftID: PropTypes.number.isRequired,
  enableListingDetailRoleChanges: PropTypes.bool.isRequired,
  listingID: PropTypes.number.isRequired,
  actions: PropTypes.object.isRequired,
  entityListData: PropTypes.object.isRequired,
  venue: PropTypes.object.isRequired,
}

export default connect(
  (state, props) => ({
    entityListData: getListingAgencyShiftBookingsSelector(state, props.shiftID),
    roles: state.roles.entityMap,
  }),
  dispatch => ({
    actions: bindActionCreators({ fetchAgencyBookings }, dispatch),
  }),
)(ListingAgencyShiftBookings)
