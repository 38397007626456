// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import * as types from './action-types'

export function fetchReferralCodes() {
  return { type: types.MARKETING_REF_CODES_FETCH_BEGIN }
}

export function fetchReferralClaims() {
  return { type: types.MARKETING_REF_CLAIMS_FETCH_BEGIN }
}
