import PropTypes from 'prop-types'
import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as workerTransferActions from 'syft-acp-core/actions/worker-transfer'
import * as workersActions from 'syft-acp-core/store/workers/actions'
import TransferRecordSection from 'syft-acp-core/components/TransferRecordSection'
import { dataPropTypes } from 'syft-acp-core/entities/WorkerDetail/structure'
import { entityDetailHOC } from 'syft-acp-util/entityDetail'

import { trackingEvents } from './WorkerTransferDetail.tracking'

const WorkerTransferRecordSection = entityDetailHOC('workersTransfers')(TransferRecordSection)

export class WorkerTransferDetailUI extends PureComponent {
  static propTypes = {
    id: PropTypes.number.isRequired,
    workerID: PropTypes.number.isRequired,
    worker: dataPropTypes,
    data: PropTypes.shape({
      id: PropTypes.number,
      worker_id: PropTypes.number,
      external_platform_id: PropTypes.number,
      external_platform_reference: PropTypes.string,
      transferred_on: PropTypes.string,
    }),
    actions: PropTypes.shape({
      fetchWorker: PropTypes.func.isRequired,
      fetchWorkerTransfer: PropTypes.func.isRequired,
      createWorkerTransfer: PropTypes.func.isRequired,
      deleteWorkerTransfer: PropTypes.func.isRequired,
      updateWorkerTransfer: PropTypes.func.isRequired,
    }).isRequired,
    triggerEvent: PropTypes.func.isRequired,
  }

  static defaultProps = {
    lastMessage: '',
    worker: {},
    data: {},
  }

  componentDidMount() {
    const { id, workerID, worker, actions } = this.props
    const needWorkerUpdate = (!id && workerID) || !worker?.id

    if (needWorkerUpdate) {
      actions.fetchWorker(workerID)
    } else if (id) {
      actions.fetchWorkerTransfer({ id })
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.id !== this.props.id && this.props.id) {
      this.props.actions.fetchWorkerTransfer({ id: this.props.id })
    }
  }

  createTransferRecord = data => {
    this.props.actions.createWorkerTransfer({ worker_id: this.props.worker.id, ...data })
    this.props.triggerEvent(trackingEvents.TRANSFER.CREATED, {
      worker_id: this.props.workerID,
      external_platform_id: data.external_platform_id,
    })
  }

  updateTransferRecord = data => {
    this.props.triggerEvent(trackingEvents.TRANSFER.UPDATED, {
      worker_id: this.props.workerID,
      transfer_id: data.id,
      external_platform_id: data.external_platform_id,
    })
    return this.props.actions.updateWorkerTransfer({ worker_id: this.props.worker.id, ...data })
  }

  deleteTransferRecord = data => {
    this.props.actions.deleteWorkerTransfer({ id: data.id, worker_id: data.worker_id })
    this.props.triggerEvent(trackingEvents.TRANSFER.DELETED, {
      worker_id: this.props.workerID,
      transfer_id: data.id,
    })
  }

  render() {
    const { createTransferRecord, updateTransferRecord, deleteTransferRecord } = this
    const { id, worker } = this.props
    return (
      <WorkerTransferRecordSection
        actions={{
          createTransferRecord,
          updateTransferRecord,
          deleteTransferRecord,
        }}
        id={id}
        isLoadingData={worker.isLoadingData}
        isSavingData={worker.isSavingData}
        showTransferredOnField
        showBranchField
        showTimekeepingField
      />
    )
  }
}

const WorkerTransferDetail = connect(
  (state, props) => {
    const worker = state.workers.entityDetail[props.workerID] || state.workers.entityMap[props.workerID]
    return {
      worker,
      workerID: worker?.id || props.workerID,
      id: worker?.worker_transfer?.id,
    }
  },
  dispatch => ({
    actions: bindActionCreators({ ...workersActions, ...workerTransferActions }, dispatch),
  })
)(WorkerTransferDetailUI)

export default WorkerTransferDetail
