// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { noop } from 'lodash-es'

import './Checkbox.css'

/**
 * Checkbox for use in the EntityList and EntityDetail components.
 * This allows you to select a boolean value.
 */
class LabeledCheckbox extends PureComponent {
  static propTypes = {
    callback: PropTypes.func,
    checked: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
    indeterminate: PropTypes.bool,
    editable: PropTypes.bool,
    className: PropTypes.string,
    isHeader: PropTypes.bool,
    label: PropTypes.string,
    testId: PropTypes.string,
    labelClassName: PropTypes.string,
    ariaLabel: PropTypes.string,
  }

  static defaultProps = {
    editable: true,
    callback: noop,
    indeterminate: false,
    className: '',
    isHeader: false,
    checked: false,
    label: '',
    labelClassName: '',
    testId: null,
  }

  render() {
    return (
      <div
        className={classnames('entity-checkbox', this.props.className, { disabled: !this.props.editable })}
      >
        <label className={this.props.isHeader ? 'header-label-checkbox' : null}>
          <input
            type="checkbox"
            checked={this.props.checked}
            ref={el => el && (el.indeterminate = this.props.indeterminate)}
            onChange={e => this.props.callback(e.target.checked)}
            disabled={!this.props.editable}
            data-testid={this.props.testId}
            aria-label={this.props.ariaLabel}
          />
          {this.props.label && <span className={this.props.labelClassName}>{this.props.label}</span>}
        </label>
      </div>
    )
  }
}

export default LabeledCheckbox
