import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { values } from 'lodash-es'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { needsIndustriesUpdate$ } from 'syft-acp-core/reducers/industries'
import * as filtersActions from 'syft-acp-core/store/filters/actions'
import * as industriesActions from 'syft-acp-core/actions/industries'
import { filterSelect } from './FilterSelect'

// TODO: simplify this?
const getOptions = (entityMap, idKey) =>
  values(entityMap).map(({ id, code, title: label }) => (idKey === 'id' ? { id, label } : { id: code, label }))

class FilterSelectIndustries extends PureComponent {
  static propTypes = {
    name: PropTypes.string.isRequired,
    entityMap: PropTypes.object.isRequired,
    idKey: PropTypes.string.isRequired,
    actions: PropTypes.objectOf(PropTypes.func).isRequired,
    disabled: PropTypes.bool,
    className: PropTypes.string,
    small: PropTypes.bool,
    tiny: PropTypes.bool,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    allowAny: PropTypes.bool,
    type: PropTypes.string,
    queryKey: PropTypes.string,
    options: PropTypes.object,
    onChange: PropTypes.func,
  }

  static defaultProps = {
    disabled: false,
    small: false,
    tiny: false,
    allowAny: false,
    value: null,
    type: null,
    className: '',
  }

  componentDidMount() {
    if (needsIndustriesUpdate$()) {
      this.props.actions.fetchIndustries()
    }
  }

  onChange = val => {
    const { actions, name, onChange } = this.props

    !!onChange && onChange(name, val)
    actions.setFilter(name, val, val)
  }

  render() {
    const { entityMap, idKey, name, small, tiny, value, disabled, type, className } = this.props
    const options = getOptions(entityMap, idKey)
    const FilterSelect = filterSelect(options, this.props.allowAny ? 'Any' : 'Industry', type, false)
    return (
      <FilterSelect
        name={name}
        small={small}
        tiny={tiny}
        onChange={this.onChange}
        className={className}
        value={value}
        allowAny={this.props.allowAny}
        disabled={disabled}
        ariaLabel="Industry"
      />
    )
  }
}

export default connect(
  ({ industries, routing }, { value, options = { idKey: 'code' }, queryKey = 'industry_code' }) => ({
    entityMap: industries.entityMap,
    idKey: options.idKey,
    value: value || routing.locationBeforeTransitions.query[queryKey],
  }),
  (dispatch, { actions }) => {
    const boundActions = bindActionCreators(
      {
        ...filtersActions,
        ...industriesActions,
      },
      dispatch
    )

    return {
      actions: {
        ...boundActions,
        ...actions,
      },
    }
  }
)(FilterSelectIndustries)
