import React, { useCallback, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useTrackingTrigger } from '@indeed/flex-tracking-context'
import { trackingEvents } from './ShiftFulfilmentModal.tracking'
import { editFulfilmentShift } from 'syft-acp-core/store/fulfilmentShifts/actions'
import { bindActionCreators } from 'redux'
import EditableTextbox from 'syft-acp-core/components/EditableTextbox'
import Button from 'syft-acp-atoms/ButtonNew'

import { currentSortOrder } from 'syft-acp-core/entities2/ShiftFulfilmentList/ShiftFulfilmentList.helpers'

import S from './ShiftFulfilmentModalJobDescription.module.scss'

import { ShiftFulfilmentModalJobDescriptionProps as Props } from './ShiftFulfilmentModal.types'

export const ShiftFulfilmentModalJobDescription = ({
  shift,
  shiftIndex,
  actions,
  callback,
  roleChange,
}: Props) => {
  const triggerEvent = useTrackingTrigger()
  const [value, setValue] = useState(shift?.job?.desc)
  useEffect(() => {
    setValue(shift?.job?.desc)
  }, [shift?.job?.desc])

  const handleValue = useCallback((val?: string) => {
    setValue(val)
  }, [])
  const handleSave = useCallback(() => {
    triggerEvent(trackingEvents.SHIFT_FULFILMENT.JOB_DESCRIPTION.CHANGED, {
      id: shift?.id,
      shift_index: shiftIndex,
      desc: value,
    })

    actions.editFulfilmentShift({
      id: shift?.id,
      job: {
        ...shift?.job,
        desc: value,
      },
      sort_order: currentSortOrder(),
      shift_index: shiftIndex,
    })
    callback()
  }, [actions, shift?.id, shift?.job, shiftIndex, triggerEvent, value, callback])
  return (
    <>
      <EditableTextbox
        message="Saving job description changes will automatically mark this shift as reviewed."
        value={value}
        placeholder="Job description"
        onSave={handleValue}
        hasControls={false}
      />
      {roleChange && (
        <div className={S['shift-fulfilment-modal-job-description__role_changes']}>
          {roleChange} worker's Role was updated, which may have resulted in a change in their Job Desciption.
        </div>
      )}
      <div className={S['shift-fulfilment-modal-job-description__button']}>
        <Button small primary onClick={handleSave}>
          Save
        </Button>
      </div>
    </>
  )
}

export default connect(null, dispatch => ({
  actions: {
    ...bindActionCreators({ editFulfilmentShift }, dispatch),
  },
  dispatch,
}))(ShiftFulfilmentModalJobDescription)
