import { apiRequest, handleCall } from 'syft-acp-core/api/call'
import { formatDatetime8601Tz, getStartOfISOWeek } from 'syft-acp-util/formatting'

export const offerBooking = ({ payload: reqArgs }) =>
  new Promise((resolve, reject) => {
    const req = apiRequest({ path: '/bookings/offer', reqArgs, method: 'PUT' })
    handleCall(req, resolve, reject)
  })

export const confirmBooking = ({ payload: reqArgs }) =>
  new Promise((resolve, reject) => {
    const req = apiRequest({ path: '/bookings/confirm', reqArgs, method: 'PUT' })
    handleCall(req, resolve, reject)
  })

export const confirmAllBookings = ({ payload }) =>
  new Promise((resolve, reject) => {
    const req = apiRequest({
      path: '/bookings/bulk_confirm',
      reqArgs: { confirmations: payload },
      method: 'PUT',
    })
    handleCall(req, resolve, reject)
  })

export const offerBulkBooking = ({ payload: reqArgs }) =>
  new Promise((resolve, reject) => {
    const req = apiRequest({ path: '/bookings/bulk_offer', reqArgs, method: 'PUT' })
    handleCall(req, resolve, reject)
  })

export const createBooking = ({ payload: reqArgs }) =>
  new Promise((resolve, reject) => {
    const req = apiRequest({ path: '/bookings/create', reqArgs, method: 'PUT' })
    handleCall(req, resolve, reject, false, { shiftID: reqArgs.shift_ids[0] })
  })

export const deleteBooking = ({ payload: reqArgs }) =>
  new Promise((resolve, reject) => {
    const req = apiRequest({ path: '/bookings/delete', reqArgs, method: 'PUT' })
    handleCall(req, resolve, reject)
  })

export const replaceBooking = ({ payload: reqArgs }) =>
  new Promise((resolve, reject) => {
    const req = apiRequest({ path: '/bookings/replace', reqArgs, method: 'PUT' })
    handleCall(req, resolve, reject)
  })

/**
 * Confirms a worker for a shift. Note: you cannot unconfirm.
 * In that case, remove the worker from the shift.
 */
export const confirmShiftBooking = ({ jobID, workerID, shiftIDs }) =>
  new Promise((resolve, reject) => {
    const req = apiRequest({
      path: '/bookings/confirm',
      reqArgs: { job_id: jobID, worker_id: workerID, shift_ids: shiftIDs },
      method: 'PUT',
    })
    handleCall(req, resolve, reject, true)
  })

/**
 * @param {String} options.start_date Week number in format like 2017-W23.
 */
export const fetchGlobalShiftBookings = ({ options }) =>
  new Promise((resolve, reject) => {
    const reqArgs = Object.assign({}, options)
    if (reqArgs.start_date) {
      reqArgs.start_date = formatDatetime8601Tz(getStartOfISOWeek(options.start_date))
    }
    const req = apiRequest({ path: '/admin/shift_bookings', reqArgs: { ...reqArgs, pay_bill_split: true } })
    handleCall(req, resolve, reject, true, options)
  })
