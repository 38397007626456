export const tdrProps = {
  rateMin: 'min_payable_rate',
  rateMax: 'max_payable_rate',
  rateTotal: 'invoice_rate',
  default_payable_rate: 'default_payable_rate',
  bankHolidayRegionId: 'bank_holiday_region_id',
  start: 'start',
  end: 'end',
  weekDays: 'week_days',
  label: 'label',
  rate_card_id: 'rate_card_id',
  id: 'id',
}

export const agencyTDRProps = {
  ...tdrProps,
  rate_card_id: 'agency_rate_card_id',
}

export const rateCardNotificationMessages = {
  timeDependentSaveSuccess: 'Dependency successfully saved.',
  timeDependentSaveFail: 'Could not save the dependency.',
  timeDependentUpdateSuccess: 'Dependency successfully updated.',
  timeDependentUpdateFail: 'Could not update the dependency.',
  rateCardSaveSuccess: 'Saved new rate card.',
  rateCardSaveFail: 'Could not save new rate card.',
  rateCardUpdateSuccess: 'Updated rate card.',
  rateCardUpdateFail: 'Could not update rate card.',
}
