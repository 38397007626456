import PropTypes from 'prop-types'

import placeholderFactory from './factory'

export default placeholderFactory('AcpGenericComponent', {
  children: PropTypes.node,
  isLoading: PropTypes.bool,
  isOK: PropTypes.bool,
  noPadding: PropTypes.bool,
  noMargin: PropTypes.bool,
  isFullWidth: PropTypes.bool,
})
