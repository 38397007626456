export const trackingEvents = {
  DELETE_BUTTON: {
    CLICKED: 'employer_venue_detail.delete_button.clicked',
  },
  SAVE_BUTTON: {
    CLICKED: 'employer_venue_detail.save_button.clicked',
  },
  CREATE_BUTTON: {
    CLICKED: 'employer_venue_new.save_button.clicked',
  },
  TRANSFER_RECORD: {
    CREATED: 'employer_venue_detail.transfer_record.created',
    UPDATED: 'employer_venue_detail.transfer_record.updated',
    DELETED: 'employer_venue_detail.transfer_record.deleted',
  },
}
