// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { FormControl, InputGroup } from 'react-bootstrap'

import iconFactory from 'syft-acp-atoms/Icons'
import './DataCoordinates.css'

const WorldIcon = iconFactory('globe', 'Coordinates')

class DataCoordinates extends Component {
  static propTypes = {
    value: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])).isRequired,
    onChange: PropTypes.func.isRequired,
  }

  callback = field => ev =>
    this.props.onChange({
      latitude: this.props.value.latitude,
      longitude: this.props.value.longitude,
      [field]: ev.target.value,
    })

  render() {
    return (
      <InputGroup className="boxed-input coordinate-input">
        <InputGroup.Addon>
          <WorldIcon iconSize={15} />
        </InputGroup.Addon>
        <FormControl
          type="text"
          className="latitude coord"
          onChange={this.callback('latitude')}
          value={String(this.props.value.latitude)}
          placeholder="Latitude"
        />
        <FormControl
          type="text"
          className="longitude coord"
          onChange={this.callback('longitude')}
          value={String(this.props.value.longitude)}
          placeholder="Longitude"
        />
      </InputGroup>
    )
  }
}

export default DataCoordinates
