// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import React from 'react'
import PropTypes from 'prop-types'
import { toLocaleString } from 'syft-acp-util/formatting'

import paginationProps from './paginationStructure'
import EntityListWrapper from './EntityListWrapper'

import './EntityListResults.css'

// Returns a string of item counts.
const itemNumbers = ({
  countersDisabled,
  offset,
  totalOfCurrPage,
  totalOfAllPages,
  totalWorkers,
  totalBookings,
  totalShifts,
  totalVacancies,
  isSinglePage,
}) => {
  // The last item index is either the last on the page, or the total number of items (last page).
  const lastItem = totalOfCurrPage > totalOfAllPages ? totalOfAllPages : totalOfCurrPage
  const offsetPrint =
    totalOfCurrPage !== offset ? `${toLocaleString(offset)}-${toLocaleString(lastItem)}` : toLocaleString(offset)
  const totalPagePrint = !isSinglePage ? ` of ${toLocaleString(totalOfAllPages)}` : ''
  const pluralPrint = offset && totalOfAllPages === 1 ? '' : 's'

  if (countersDisabled) {
    return `Showing ${toLocaleString(offset)}-${toLocaleString(totalOfCurrPage)} item${pluralPrint}
            of one or more pages — re-enable pagination counters above for more precise data`
  }

  // Display any meta information we receive from the server.
  const metaItemsData = [
    [totalWorkers, 'workers'],
    [totalBookings, 'bookings'],
    [totalShifts, 'shifts'],
    [totalVacancies, 'vacancies'],
  ]
    .map(n => (n[0] ? `${toLocaleString(n[0])} ${n[1]}` : ''))
    .filter(n => n !== '')
    .join(', ')

  const metaItems = metaItemsData ? ` (${metaItemsData})` : ''

  // TODO: this has been reduced to one single string call so that it is a little easier
  // to translate in the future.
  return `Showing ${offsetPrint}${totalPagePrint} item${pluralPrint}${metaItems}`
}

/**
 * Table body used for displaying error messages.
 *
 * @param {Object} props Properties
 * @constructor {XML} Pure EntityListFeedback component
 */
const EntityListResults = ({
  pagination: { total, totalPages, totalWorkers, perPage, activePage, totalBookings, totalShifts, totalVacancies },
  type,
}) => {
  // If 'total' is null, we don't have pagination for this list or we are currently
  // loading data. Just display nothing in that case.
  if (!total && total !== 0) {
    return (
      <EntityListWrapper type={type}>
        <div className="entity-list-results" data-testid="entity-list-results">
          &nbsp;
        </div>
      </EntityListWrapper>
    )
  }
  // If `total` is -1, and `totalPages` is -1, we have disabled counters,
  // including the pagination counter.
  const countersDisabled = total === -1 && totalPages === -1

  // If we have only one page, then page one displays the total number of records.
  // If we have more than one page, page one displays the number of records per page.
  const pageOneItems = totalPages === 1 ? total : perPage
  // Whether we've got only one page of results.
  const isSinglePage = pageOneItems === total

  // Offset is 1-indexed.
  const offset = (activePage - 1) * perPage + 1
  const totalOfCurrPage = offset - 1 + pageOneItems

  // Produce the final string.
  const content =
    total === 0
      ? 'No results'
      : itemNumbers({
          countersDisabled,
          offset,
          totalOfCurrPage,
          totalOfAllPages: total,
          totalWorkers,
          totalBookings,
          totalShifts,
          totalVacancies,
          isSinglePage,
        })

  return (
    <EntityListWrapper type={type}>
      <div className="entity-list-results" data-testid="entity-list-results">
        <span>{content}</span>
      </div>
    </EntityListWrapper>
  )
}

EntityListResults.propTypes = {
  type: PropTypes.string,
  pagination: paginationProps,
}

EntityListResults.defaultProps = {
  type: null,
  pagination: null,
}

export default EntityListResults
