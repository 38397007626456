// Syft ACP - Lib <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

/**
 * Simple Promisified timeout.
 */
const wait = ms =>
  new Promise(resolve => {
    setTimeout(() => resolve(), ms)
  })

// Wait a single frame and then perform a function.
export const queue = fn => setTimeout(fn, 0)

export default wait
