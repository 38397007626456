import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import './AcpWrapper.css'

/**
 * Wrapper component that provides the basic styling for AcpComponent items.
 */
class AcpWrapper extends React.PureComponent {
  static propTypes = {
    children: PropTypes.node.isRequired,
    isConnected: PropTypes.bool,
    isIntegrated: PropTypes.bool,
    noBottomBorder: PropTypes.bool,
    noRounding: PropTypes.bool,
  }

  static defaultProps = {
    isConnected: false,
    isIntegrated: false,
    noBottomBorder: false,
    noRounding: false,
  }

  render() {
    const { children, className, noRounding, hasNoMargin, noBottomBorder, isIntegrated, isConnected } = this.props
    const wrapperClass = classnames('acp-wrapper', className, {
      noRounding,
      noBottomBorder,
      hasNoMargin,
      isIntegrated,
      isConnected,
    })
    return (
      <div className={wrapperClass} data-testid="acp-wrapper">
        {children}
      </div>
    )
  }
}

export default AcpWrapper
