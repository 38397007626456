import React from 'react'
import PageTitle from 'syft-acp-core/components/PageTitle'
import AgencyAccountsList from 'syft-acp-core/entities2/AgencyAccountsList'
import { AgencySubNavigation } from './AgencySubNavigation'

import { AgenciesTrackingProvider } from './AgenciesTrackingProvider'

export const AgencyAccounts = ({ match: { params } }) => {
  const agencyIDParam = params.id ? Number(params.id) : undefined

  return (
    <PageTitle title="Agencies rate card list">
      <AgencySubNavigation agencyID={agencyIDParam} />
      <AgenciesTrackingProvider agencyId={agencyIDParam}>
        <AgencyAccountsList agencyId={agencyIDParam} />
      </AgenciesTrackingProvider>
    </PageTitle>
  )
}
