import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { capitalize, flowRight } from 'lodash-es'
import useBasicTracking from 'syft-acp-util/hooks/useBasicTracking'

import Acp from 'syft-acp-uikit'
import { setFilter } from 'syft-acp-core/store/filters/actions'
import { assignAppeals, fetchAppeals } from 'syft-acp-core/store/appeals/actions'
import { getAppealDetailPath } from 'syft-acp-core/store/appeals/helpers'
import { AppealEntity } from 'syft-acp-core/store/types'
import { createEntityListConnector } from 'syft-acp-util/entityList'
import { FilterText } from 'syft-acp-util/components/FilterForm'
import FilterOutcomeReached from 'syft-acp-util/components/FilterForm/FilterOutcomeReached'
import FilterReasons from 'syft-acp-util/components/FilterForm/FilterReasons'
import FilterAssignees from 'syft-acp-util/components/FilterForm/FilterAssignees'
import useResetFilterAssignees from 'syft-acp-util/hooks/useResetFilterAssignees'
import useFetchEntity from 'syft-acp-util/hooks/useFetchEntity'

import { AppealsListProps as Props, AppealsListEntityProps as EntityProps } from './AppealsList.types'
import { trackingEvents } from './AppealsList.tracking'

export const entityConnector = createEntityListConnector<AppealEntity>({
  entityActions: {
    fetchEntities: ({ options }: Record<string, any>) => fetchAppeals(options),
  },
  entityStore: 'appeals',
})

export const storeConnector = connect(
  state => ({
    tsLastAssigned: state.appeals.tsLastAssigned,
    me: state.auth.userData,
  }),
  (dispatch, { actions }: EntityProps) => ({
    actions: {
      ...actions,
      ...bindActionCreators({ assignAppeals, setFilter }, dispatch),
    },
  }),
)

const getRowLink = ({ id }: AppealEntity) => getAppealDetailPath(id)

const getStrikeLink = ({ worker, strike }: AppealEntity) =>
  strike ? `/entity/workers/view/${worker.id}/strikes/view/${strike.id}` : undefined

const getWorkerLink = ({ worker }: AppealEntity) => `/entity/workers/view/${worker.id}`

const selectAppealOutcome = ({ outcome }: AppealEntity) => capitalize(outcome || '')

const AppealsList = ({ actions, data, isLoadingData, query, tsLastAssigned, me }: Props) => {
  const reasonQueryKey = 'appeal_reason_id'

  const { onClickAssign, onChangeOutcome, onChangeReason, onChangeAssignee } = useBasicTracking(
    trackingEvents,
    reasonQueryKey,
  )

  const onClickAssignFn = () => {
    onClickAssign()
    actions.assignAppeals()
  }

  const onChangeReasonFn = (val: string) => {
    onChangeReason(val)
    actions.setFilter(reasonQueryKey, val)
  }

  useFetchEntity(query, actions.fetchEntities)

  useResetFilterAssignees(actions.setFilter, tsLastAssigned, me.id + '')

  return (
    <Acp.EntityList data={data} isLoading={isLoadingData} idKeyValue="id" hasPagination>
      <Acp.Actions>
        <Acp.Button disabled={isLoadingData} kind="primary" onClick={onClickAssignFn}>
          Assign new cases to me
        </Acp.Button>
        <Acp.FilterGroup title="Outcome reached">
          <FilterOutcomeReached onChange={onChangeOutcome} />
        </Acp.FilterGroup>
        <Acp.FilterGroup title="Reason">
          <FilterReasons type="appeals" onChange={onChangeReasonFn} allowAny />
        </Acp.FilterGroup>
        <Acp.FilterGroup title="Assignee">
          <FilterAssignees storeKey="appeals" onChange={onChangeAssignee} />
        </Acp.FilterGroup>
        <Acp.FilterGroup title="Worker Email">
          <FilterText type="email" name="email" placeholder="Type at least 5 characters" minLength={5} />
        </Acp.FilterGroup>
      </Acp.Actions>
      <Acp.Table rowLink={getRowLink}>
        <Acp.Col.Text value="id" header="ID" align="left" isMinimal isNumeric />
        <Acp.Col.Text value={selectAppealOutcome} header="Outcome" align="left" isMinimal />
        <Acp.Col.Text
          value="strike.id"
          header="Strike ID"
          align="left"
          colLink={getStrikeLink}
          isMinimal
          isNumeric
        />
        <Acp.Col.Text
          value="worker.id"
          header="Worker ID"
          align="left"
          colLink={getWorkerLink}
          isMinimal
          isNumeric
        />
        <Acp.Col.Text value="worker.full_name" header="Name" align="left" isMinimal />
        <Acp.Col.Text value="worker.email" header="Email" align="left" isMinimal />
        <Acp.Col.Text
          value="strike.strike_reason.display_name"
          header="Strike reason"
          align="left"
          isMinimal
        />
        <Acp.Col.Text value="appeal_reason.display_name" header="Appeal reason" align="left" isMinimal />
        <Acp.Col.DateTime value="created_at" header="Appeal date" align="left" isMinimal />
        <Acp.Col.Text value="assignee.full_name" header="Case assigned to" align="left" isMinimal />
      </Acp.Table>
    </Acp.EntityList>
  )
}

export default flowRight(entityConnector, storeConnector)(AppealsList)
