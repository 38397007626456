import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { get } from 'lodash-es'
import DataBoolean from 'syft-acp-core/components/EditableTable/DataBoolean'
import EntityListInputWrapper from 'syft-acp-core/components/EntityList/EntityListInputWrapper'
import { createBookingChanges, updateBooking, updateBookingChanges } from 'syft-acp-core/actions/bookings'
import { isDraft } from 'syft-acp-core/sagas/calls/bookingCalls'

const ListingCheckbox = ({ dispatch, attr, shiftBooking, disabled = false }) => {
  const checked = get(shiftBooking, attr)
  const onChange = val => {
    const { id } = shiftBooking
    const action = isDraft(id) ? createBookingChanges : updateBookingChanges

    dispatch(updateBooking({ id, attr, val }))
    dispatch(action({ id, attr, val }))
  }

  return (
    <EntityListInputWrapper>
      <DataBoolean onChange={onChange} checked={checked} editable={!disabled} />
    </EntityListInputWrapper>
  )
}

ListingCheckbox.propTypes = {
  dispatch: PropTypes.func.isRequired,
  attr: PropTypes.string.isRequired,
  shiftBooking: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
}

export default connect()(ListingCheckbox)
