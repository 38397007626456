import externalWorkLocationTypes from './action-types'
import { ExternalWorkLocationEntity } from './types'
import { generateEntityCrudReducer } from 'syft-acp-util/store/reducers'

export default generateEntityCrudReducer<ExternalWorkLocationEntity>({
  name: 'externalWorkLocations',
  actions: externalWorkLocationTypes,
  options: {
    idKey: 'id',
    payloadProcess: ({
      external_work_location,
      external_work_locations,
    }: {
      external_work_location: ExternalWorkLocationEntity
      external_work_locations: ExternalWorkLocationEntity
    }) => external_work_location || external_work_locations,
  },
})
