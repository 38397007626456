// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import { TrackingTrigger } from '@indeed/flex-tracking-context'
import React from 'react'
import { Link } from 'react-router-dom'
import { Grid } from 'react-bootstrap'

import PageTitle from 'syft-acp-core/components/PageTitle'
import { useAddComponentRumTag } from 'syft-acp-util/tracking/useGlobalTags'
import SiteComponent from '../../components/SiteComponent'

const Error404 = () => {
  useAddComponentRumTag('pageStatus', '404')
  return (
    <PageTitle title="404">
      <TrackingTrigger
        onLoad={{
          event: 'page.not_found.viewed',
        }}
      />
      <TrackingTrigger
      onLoad={{
        event: 'page.not_found.viewed',
      }}
    />
    <div className="page error">
        <SiteComponent>
          <Grid>
            <h2>404 - Page Not Found</h2>
            <p>
              Unfortunately, this page could not be found. This page has either been deleted, or its URL (page
              address) might have been altered.
            </p>
            <p>
              Go to the <Link to="/">homepage</Link>.
            </p>
          </Grid>
        </SiteComponent>
      </div>
    </PageTitle>
  )
}

export default Error404
