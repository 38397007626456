// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import React from 'react'
import PropTypes from 'prop-types'

import EmployerVenueAreaDetail from 'syft-acp-core/entities/EmployerDetail/EmployerVenueAreaDetail'
import SubNavigation from 'syft-acp-core/components/Navigation/SubNavigation'

const EmployerVenueAreaNew = ({ match: { params } }) => (
  <div>
    <SubNavigation subPage="employers" data={{ employerID: Number(params.id) }} />
    {/* Note: we need to send the venueID as 'id', because that's how we will find the store data. */}
    <EmployerVenueAreaDetail id={Number(params.venueID)} employerID={Number(params.id)} areaID={0} />
  </div>
)

EmployerVenueAreaNew.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired,
      areaID: PropTypes.string,
      venueID: PropTypes.string.isRequired,
    }).isRequired,
  }),
}

export default EmployerVenueAreaNew
