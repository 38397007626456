import entityReducer from './generators/entityReducer'
import * as types from '../actions/action-types'
import * as entityTypes from './generators/entities'
import * as states from './states/listingOfferedWorkersStates'

export default entityReducer('listingOfferedWorkers', {
  [entityTypes.ENTITIES_FETCH_BEGIN]: [
    types.ADMIN_LISTING_SHIFT_FETCH_BEGIN,
    types.BOOKINGS_OFFER_BEGIN,
    types.BOOKINGS_BULK_OFFER_BEGIN,
  ],
  [entityTypes.ENTITIES_FETCH_FAILED]: [
    types.ADMIN_LISTING_SHIFT_FETCH_FAILED,
    types.BOOKINGS_OFFER_FAILED,
    types.BOOKINGS_BULK_OFFER_FAILED,
  ],
  [types.ADMIN_LISTING_SHIFT_FETCH_SUCCEEDED]: states.adminListingShiftFetchSucceededState,
  [types.BOOKINGS_OFFER_SUCCEEDED]: states.bookingsOfferSucceededState,
  [types.BOOKINGS_BULK_OFFER_SUCCEEDED]: states.bookingsBulkOfferSucceededState,
})
