import React from 'react'
import PropTypes from 'prop-types'

import AcpNotifierInline from './AcpNotifierInline'

/**
 * Displayed on a table when there is no data to show.
 */
export default class AcpNoDataInline extends React.PureComponent {
  static propTypes = {
    hasError: PropTypes.bool,
    hideReloadIfNull: PropTypes.bool,
    reloadLastCall: PropTypes.func,
  }

  static defaultProps = {
    hasError: false,
    hideReloadIfNull: false,
    reloadLastCall: null,
  }

  render() {
    const { hasError, hideReloadIfNull, reloadLastCall } = this.props
    const message = hasError ? 'Could not load data.' : 'No items found.'
    return (
      <AcpNotifierInline
        hideReloadIfNull={hideReloadIfNull}
        messageError={message}
        hasError={hasError}
        reloadLastCall={reloadLastCall}
      />
    )
  }
}
