// Syft ACP - Lib <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import React, { useCallback, useEffect } from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import DatePicker from 'syft-acp-atoms/DatePicker'
import { dateAddTz, datetimeToDate } from 'syft-acp-util/time'
import * as filtersActions from 'syft-acp-core/store/filters/actions'

import FilterUnit from './FilterUnit'
import './FilterDateRange.css'

const FilterDateRange = ({ nameFrom, nameTo, actions, query, defaultValue, showShortTime, useDate, onChange }) => {
  const handleChange = useCallback(
    (name, value) => {
      const formattedValue = useDate ? datetimeToDate(value) : dateAddTz(value)

      !!onChange && onChange(name, formattedValue)
      actions.setFilter(name, formattedValue)
    },
    [useDate, actions, onChange]
  )

  useEffect(() => {
    if (!query[nameFrom] && defaultValue[nameFrom]) {
      handleChange(nameFrom, dateAddTz(defaultValue[nameFrom]))
    }

    if (!query[nameTo] && defaultValue[nameTo]) {
      handleChange(nameTo, dateAddTz(defaultValue[nameTo]))
    }
  }, [defaultValue, handleChange, nameFrom, nameTo, query])

  return (
    <FilterUnit>
      <DatePicker
        className={showShortTime ? 'date-time-from' : 'date-from'}
        name={nameFrom}
        value={query[nameFrom]}
        defaultValue={defaultValue[nameFrom]}
        onChange={handleChange}
        showShortTime={showShortTime}
      />{' '}
      <DatePicker
        className={showShortTime ? 'date-time-from' : 'date-to'}
        name={nameTo}
        value={query[nameTo]}
        defaultValue={defaultValue[nameTo]}
        onChange={handleChange}
        showShortTime={showShortTime}
      />
    </FilterUnit>
  )
}
FilterDateRange.displayName = 'FilterDateRange'
FilterDateRange.propTypes = {
  nameFrom: PropTypes.string.isRequired,
  nameTo: PropTypes.string.isRequired,
  actions: PropTypes.objectOf(PropTypes.func).isRequired,
  query: PropTypes.object.isRequired,
  showShortTime: PropTypes.bool,
  useDate: PropTypes.bool,
  defaultValue: PropTypes.object,
  onChange: PropTypes.func,
}

FilterDateRange.defaultProps = {
  defaultValue: {},
  showShortTime: false,
  useDate: false,
  onChange: null,
}

export default connect(
  state => ({
    query: state.routing.locationBeforeTransitions.query,
  }),
  dispatch => ({
    actions: bindActionCreators(filtersActions, dispatch),
  })
)(FilterDateRange)
