// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import React from 'react'
import entityList from 'syft-acp-util/entityList'
import * as tableType from 'syft-acp-core/components/EntityList/'
import * as workersActions from 'syft-acp-core/store/workers/actions'
import { formatCurrency } from 'syft-acp-util/formatting'
import { FilterGroup, FilterText } from 'syft-acp-util/components/FilterForm'

const tableFormat = [
  { type: tableType.SELECTOR },
  { type: tableType.TXT, val: 'id', header: 'ID', numeric: true },
  { type: tableType.TXT, val: 'first_name', header: 'First name' },
  { type: tableType.TXT, val: 'last_name', header: 'Last name' },
  { type: tableType.TXT, val: 'city', header: 'City' },
  { type: tableType.TXT, val: 'a', header: 'Job roles' },
  { type: tableType.TXT, val: 'a', header: 'Completed shifts', numeric: true },
  {
    type: tableType.TXT,
    val: 'a',
    header: 'Trusted networks',
    expl: 'How many trusted networks this user is a member of',
    numeric: true,
  },
  { type: tableType.RATING, val: 'average_rating', char: '★', header: 'Avg. Rating' },
  { type: tableType.TXT, val: 'a', header: 'Strike/ban status' },
  {
    type: tableType.TXT,
    val: 'minimum_rate',
    header: 'Min. Rate',
    format: aux => (aux ? formatCurrency(aux.amount, aux.currency) : '–'),
  },
  { type: tableType.BOOL, val: 'complete', header: 'V', expl: 'Verified' },
  { type: tableType.BOOL, val: 'complete', header: 'A', expl: 'Active' },
  { type: tableType.BOOL, val: 'complete', header: 'C', expl: 'Confirmed' },
  { type: tableType.BOOL, val: 'a', header: 'P', expl: 'Has photo' },
  { type: tableType.BOOL, val: 'a', header: 'I', expl: 'Has been interviewed' },
  { type: tableType.BOOL, val: 'a', header: 'B', expl: 'Booked induction' },
  { type: tableType.DATETIME, val: 'a', header: 'Last shift application' },
  { type: tableType.DATETIME, val: 'a', header: 'Account creation' },
]

const filters = (
  <div>
    <FilterGroup title="Filter by worker name">
      <FilterText name="name" placeholder="Worker name" />
    </FilterGroup>
  </div>
)

const actionFormat = []

export default entityList(
  null,
  tableFormat,
  filters,
  actionFormat,
  'workers',
  {
    fetchEntities: workersActions.fetchWorkers,
  },
  {
    fullWidth: true,
    showResultCount: true,
  }
)
