import entityReducer from './generators/entityReducer'
import * as types from '../actions/action-types'
import * as entityTypes from './generators/entities'

export default entityReducer(
  'clientApplications',
  {
    [entityTypes.ENTITIES_FETCH_BEGIN]: types.ADMIN_CLIENT_APPLICATIONS_FETCH_BEGIN,
    [entityTypes.ENTITIES_FETCH_SUCCEEDED]: types.ADMIN_CLIENT_APPLICATIONS_FETCH_SUCCEEDED,
    [entityTypes.ENTITIES_FETCH_FAILED]: types.ADMIN_CLIENT_APPLICATIONS_FETCH_FAILED,
  },
  {
    idKey: 'identifier',
  },
)
