// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import * as types from './action-types'

export function listWorkerDocuments({ options, workerID }, props, toCSV = false) {
  return { type: types.WORKER_DOCUMENTS_FETCH_BEGIN, workerID: workerID || options.workerID, toCSV }
}

export function fetchWorkerDocument({ workerID, documentID }, props, toCSV = false) {
  return { type: types.WORKER_DOCUMENT_FETCH_BEGIN, workerID, documentID, toCSV }
}

export function listWorkerDocumentTypes() {
  return { type: types.WORKER_DOCUMENT_TYPES_FETCH_BEGIN }
}

export function deleteWorkerDocument({ workerID, id }, props, toCSV = false) {
  return { type: types.WORKER_DOCUMENT_DELETE_BEGIN, workerID, id, toCSV }
}

export function saveWorkerDocument({ workerID, id, data }, props, toCSV = false) {
  return { type: types.WORKER_DOCUMENT_SAVE_BEGIN, workerID, id, data, toCSV }
}

export function updateWorkerDocumentAttachments({ workerID, id, amount }) {
  return { type: types.WORKER_DOCUMENT_UPDATE_ATTACHMENTS, workerID, id, amount }
}
