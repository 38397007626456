import React from 'react'
import PropTypes from 'prop-types'
import { get, compact } from 'lodash-es'

import NameAvatar from 'syft-acp-core/components/NameAvatar'
import { cellPropTypes, cellDefaultProps } from '../dataProps'
import { emptyTextFallback } from './fallback'
import { colPlaceholderFactory } from '../../../placeholders/factory'

import './AcpCellWorker.css'

// Returns the worker's full name.
const formatFullName = user => user && compact([user.first_name, user.last_name]).join(' ')

// Column placeholder component.
export const AcpColWorker = colPlaceholderFactory('AcpCellWorker')

/**
 * Component for displaying a worker's name and avatar.
 * Takes an object like { first_name: 'a', last_name: 'b', profile_picture: { uuid: 'c' } }
 *
 *    <AcpCol type={ tableType.WORKER } value="worker" header="Worker" />
 *
 * If the 'profile_picture' data is not present, the avatar will not be displayed.
 * A guest avatar will be displayed if the worker's UUID is not present or if the image is broken.
 */
export default class AcpCellWorker extends React.PureComponent {
  static propTypes = {
    ...cellPropTypes,
    value: PropTypes.shape({
      first_name: PropTypes.string.isRequired,
      last_name: PropTypes.string.isRequired,
      profile_picture: PropTypes.shape({
        uuid: PropTypes.string,
      }),
    }),
  }

  static defaultProps = {
    ...cellDefaultProps,
  }

  render() {
    const { value, options } = this.props
    const avatarData = get(value, 'profile_picture', false)
    const avatarUUID = get(avatarData, 'uuid', null)
    const workerName = formatFullName(value)

    // Normally we never break a user's name. This can be adjusted.
    const useWrapping = get(options, 'useWrapping', false)

    // If we have a worker name, but no avatar UUID, display the guest avatar.
    const isGuest = workerName != null && workerName !== '' && avatarUUID == null

    return (
      <div className="acp-cell-worker">
        <NameAvatar
          userName={workerName || `\u00A0${emptyTextFallback}`}
          userUUID={avatarUUID}
          useGuestAvatar={isGuest}
          useWrapping={useWrapping}
        />
      </div>
    )
  }
}
