import React, { useEffect } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { entityList$ } from 'syft-acp-core/reducers/generators/entities'
import { fetchEmployerVenueNamesList } from 'syft-acp-core/actions/employer-venue-names'
import DataDropdown from 'syft-acp-core/components/EditableTable/DataDropdown'
import { AllProps } from './index.types'

export const connector = connect(
  (state: { employerVenueNames: Function }, { employerID }: { employerID?: number }) => ({
    venues: entityList$(state.employerVenueNames, { employerID }),
  }),
  dispatch => ({
    actions: bindActionCreators({ fetchVenues: fetchEmployerVenueNamesList }, dispatch),
  })
)

const VenueNamesField = ({
  venues,
  activeVenue,
  updateVenue,
  employerID,
  disabled,
  actions: { fetchVenues },
}: AllProps) => {
  useEffect(() => {
    if (employerID) {
      fetchVenues(employerID)
    }
  }, [employerID, fetchVenues])

  const options = venues ? venues.map(({ id, name }) => ({ id, label: name })) : []

  return (
    <DataDropdown
      name="venues"
      options={options}
      value={activeVenue}
      editable={!disabled}
      disabled={!options.length}
      onChange={updateVenue}
    />
  )
}

VenueNamesField.defaultProps = {
  activeVenue: null,
  disabled: false,
}

export default connector(VenueNamesField)
