import React from 'react'
import { Icon, Toolbar, Typography } from '@material-ui/core'
import PropTypes from 'prop-types'

import './DatePickerCaption.css'

const DatePickerCaption = React.forwardRef(({ children, className, style, icon, message }, ref) => (
  <div ref={ref} className={`${className} DatePickerCaption`} style={style}>
    <Toolbar className="DatePickerCaptionToolbar">
      <Icon>{icon}</Icon>
      <Typography variant="body2" align="left">
        {message}
      </Typography>
    </Toolbar>
    <div>{children}</div>
  </div>
))

DatePickerCaption.propTypes = {
  children: PropTypes.node.isRequired,
  style: PropTypes.object,
  className: PropTypes.string,
  icon: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
}

DatePickerCaption.defaultProps = {
  style: null,
  className: '',
}

export default DatePickerCaption
