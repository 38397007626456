// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { Badge } from 'react-bootstrap'
import { connect } from 'react-redux'
import { map } from 'lodash-es'

import './EmployerAlertBadge.css'

class EmployerAlertBadge extends PureComponent {
  static propTypes = {
    employerID: PropTypes.number.isRequired,
    employerData: PropTypes.object.isRequired,
    alerts: PropTypes.object.isRequired,
  }

  get alertAmount() {
    // Return number of alerts that contain the employer's brand name or company name.
    if (!this.props.employerData) {
      return 0
    }
    const findName = this.props.employerData.brand_name
      ? this.props.employerData.brand_name
      : this.props.employerData.company_name
    const alerts = Object.values(this.props.alerts)
    const valid = alerts
      .filter(alert => alert.message.toLowerCase().indexOf(findName.toLowerCase()) > -1)
      .sort()
      .reverse()
    const ids = map(valid, 'id')
    return alerts.filter(alert => ids.indexOf(alert.id) > -1).length
  }

  render() {
    const n = this.alertAmount
    if (!n) {
      return null
    } else {
      return (
        <span>
          {' '}
          <Badge className="EmployerAlertBadge">{n}</Badge>
        </span>
      )
    }
  }
}

export default connect((state, ownProps) => ({
  alerts: state.alerts.entityMap,
  employerData: state.employers.entityDetail[ownProps.employerID],
}))(EmployerAlertBadge)
