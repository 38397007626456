// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import React, { useCallback, useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { get } from 'lodash-es'
import classnames from 'classnames'

import DatePicker from 'syft-acp-atoms/DatePicker'
import { formatDate, formatRelative } from 'syft-acp-util/formatting'
import { IconClock } from 'syft-acp-core/components/IconClock'
import './DataDate.css'

const DataDate = ({
  value,
  defaultValue,
  editable,
  onChange,
  onBlur,
  onFocus,
  format = formatDate,
  simple = false,
  parameters,
  disabled = false,
  testId,
}) => {
  const [initialValue] = useState(value)
  const onDatePickerChange = useCallback((name, val) => onChange(val), [onChange])
  const auto = useMemo(
    () => !initialValue && defaultValue && defaultValue === value,
    [value, initialValue, defaultValue],
  )

  useEffect(() => {
    if (!initialValue && !value && editable && defaultValue && get(parameters, 'autoDate', false)) {
      onChange(defaultValue)
    }
  }, [value, defaultValue, editable, initialValue, parameters, onChange])

  if (editable) {
    return (
      <DatePicker
        name="data-date"
        value={value ? String(value) : ''}
        onChange={onDatePickerChange}
        onBlur={onBlur}
        onFocus={onFocus}
        className={classnames('date-picker', 'boxed-input', { auto })}
        disabled={disabled}
        testId={testId}
        {...parameters}
      />
    )
  } else {
    return (
      <div className="plain-text" data-testid={testId}>
        {value ? format(value) : '—'}
        {value && !simple ? (
          <span className="secondary">
            <IconClock />
            {formatRelative(value)}
          </span>
        ) : null}
      </div>
    )
  }
}

DataDate.propTypes = {
  // eslint-disable-next-line react/no-unused-prop-types
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  editable: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  simple: PropTypes.bool,
  // eslint-disable-next-line react/no-unused-prop-types
  parameters: PropTypes.object,
  disabled: PropTypes.bool,
  testId: PropTypes.string,
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
}

DataDate.defaultProps = {
  defaultValue: null,
  value: null,
  editable: false,
  simple: false,
  disabled: false,
  parameters: null,
  testId: null,
  onBlur: null,
  onFocus: null,
}

export default React.memo(DataDate)
