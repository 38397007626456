import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import { toLocaleString } from 'syft-acp-util/formatting'

import './AcpResultCount.css'

// Returns a string of item counts.
const itemNumbers = ({
  countersDisabled,
  offset,
  totalOfCurrPage,
  totalOfAllPages,
  totalWorkers,
  totalBookings,
  totalShifts,
  totalVacancies,
  isSinglePage,
}) => {
  // The last item index is either the last on the page, or the total number of items (last page).
  const lastItem = totalOfCurrPage > totalOfAllPages ? totalOfAllPages : totalOfCurrPage
  const offsetPrint =
    totalOfCurrPage !== offset
      ? `${toLocaleString(offset)}-${toLocaleString(lastItem)}`
      : toLocaleString(offset)
  const totalPagePrint = !isSinglePage ? ` of ${toLocaleString(totalOfAllPages)}` : ''
  const pluralPrint = offset && totalOfAllPages === 1 ? '' : 's'

  if (countersDisabled) {
    return `Showing ${toLocaleString(offset)}-${toLocaleString(totalOfCurrPage)} item${pluralPrint}
            of one or more pages — re-enable pagination counters above for more precise data`
  }

  // Display any meta information we receive from the server.
  const metaItemsData = [
    [totalWorkers, 'workers'],
    [totalBookings, 'bookings'],
    [totalShifts, 'shifts'],
    [totalVacancies, 'vacancies'],
  ]
    .map(n => (n[0] ? `${toLocaleString(n[0])} ${n[1]}` : ''))
    .filter(n => n !== '')
    .join(', ')

  const metaItems = metaItemsData ? ` (${metaItemsData})` : ''

  // TODO: this has been reduced to one single string call so that it is a little easier
  // to translate in the future.
  return `Showing ${offsetPrint}${totalPagePrint} item${pluralPrint}${metaItems}`
}

const getResultString = (data = {}, isOK = true, isLoading = false) => {
  const { totalPages, total, perPage, page, totalWorkers, totalBookings, totalShifts, totalVacancies } = data

  // Display nothing if we're still loading, have no results, or if an error occurred.
  if (!total) {
    if (isLoading) return 'Loading...'
    if (!isOK) return 'Could not load data'
    return 'No results'
  }

  // If `total` is -1, and `totalPages` is -1, we have disabled counters,
  // including the pagination counter.
  const countersDisabled = total === -1 && totalPages === -1

  // If we have only one page, then page one displays the total number of records.
  // If we have more than one page, page one displays the number of records per page.
  const pageOneItems = totalPages === 1 ? total : perPage
  // Whether we've got only one page of results.
  const isSinglePage = pageOneItems === total

  // Offset is 1-indexed.
  const startOffset = (page - 1) * perPage + 1
  const totalOfCurrPage = startOffset - 1 + pageOneItems

  // Produce the final string.
  return itemNumbers({
    countersDisabled,
    offset: startOffset,
    totalOfCurrPage,
    totalOfAllPages: total,
    totalWorkers,
    totalBookings,
    totalShifts,
    totalVacancies,
    isSinglePage,
  })
}

/**
 * Displays the result count at the top of an overview list.
 */
export default class AcpResultCount extends React.PureComponent {
  static propTypes = {
    meta: PropTypes.object,
    isIntegrated: PropTypes.bool,
    withInfoColumn: PropTypes.bool,
    isOK: PropTypes.oneOfType([PropTypes.bool]),
    isLoading: PropTypes.oneOfType([PropTypes.bool]),
  }

  static defaultProps = {
    meta: {},
    isIntegrated: false,
    withInfoColumn: false,
    isOK: true,
    isLoading: false,
  }

  render() {
    const { meta, isOK, isIntegrated, isLoading, withInfoColumn } = this.props
    const inner = getResultString(meta, isOK, isLoading, isIntegrated)
    return (
      <div className={classnames('acp-result-count', { isIntegrated, withInfoColumn })}>
        <span>{inner}</span>
      </div>
    )
  }
}
