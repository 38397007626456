// Note: copied verbatim from <https://github.com/catcher-in-the-try/async-csv/blob/master/index.js>.
// Public domain.
// This is temporary. See 'objectsToCSV.js'.

import { generate, parse, stringify, transform } from 'csv'

class CsvAsync {
  /**
   * @param {object} options
   * @param {number} options.seed
   * @param {number} options.columns
   * @param {number} options.length
   */
  static generate(options) {
    return new Promise((resolve, reject) => {
      generate(options, (error, output) => {
        if (error) {
          reject(error)
        } else {
          resolve(output)
        }
      })
    })
  }

  /**
   *
   * @param {string} input
   * @param {object} options
   */
  static parse(input, options) {
    return new Promise((resolve, reject) => {
      parse(input, options, (error, output) => {
        if (error) {
          reject(error)
        } else {
          resolve(output)
        }
      })
    })
  }

  /**
   *
   * @param {string[][]} data
   * @param {function} handler
   * @param {object} [options]
   */
  static transform(data, handler, options) {
    return new Promise((resolve, reject) => {
      if (options) {
        transform(data, handler, options, (error, dataResolve) => {
          if (error) {
            reject(error)
          } else {
            resolve(dataResolve)
          }
        })
      } else {
        transform(data, handler, (error, dataResolve) => {
          if (error) {
            reject(error)
          } else {
            resolve(dataResolve)
          }
        })
      }
    })
  }

  /**
   *
   * @param {string[][]} data
   * @param {object} options
   */
  static stringify(data, options) {
    return new Promise((resolve, reject) => {
      if (options) {
        stringify(data, options, (error, output) => {
          if (error) {
            reject(error)
          } else {
            resolve(output)
          }
        })
      } else {
        stringify(data, (error, output) => {
          if (error) {
            reject(error)
          } else {
            resolve(output)
          }
        })
      }
    })
  }
}

export default CsvAsync
