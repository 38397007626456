import { isEqual, flowRight, pick } from 'lodash-es'
import React, { useCallback, useEffect } from 'react'
import deepEqual from 'react-fast-compare'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { useTrackingTrigger } from '@indeed/flex-tracking-context'

import { workerCitiesAutocomplete } from 'syft-acp-core/api/resources/admin/workers'
import CountrySpecific from 'syft-acp-core/components/CountrySpecific'
import { SUPPORTED_COUNTRY_CODES } from 'syft-acp-core/lib/i18n'
import * as modalActions from 'syft-acp-core/store/modals/actions'
import * as workersActions from 'syft-acp-core/store/workers/actions'
import { WorkerEntity } from 'syft-acp-core/store/workers/types'
import * as managersActions from 'syft-acp-core/actions/managers'
import Acp from 'syft-acp-uikit'
import { CheckIcon, PersonIcon } from 'syft-acp-uikit/components/AcpTable/headerComponents'
import {
  FilterAutocomplete,
  FilterBoolCheckbox,
  FilterRolesSkills,
  FilterSelectCities,
  FilterSelectIndustries,
  FilterWorkerStatuses,
} from 'syft-acp-util/components/FilterForm'
import { showModal } from 'syft-acp-core/store/modals/actions'
import DownloadCSVModal from 'syft-acp-core/components/Modal/DownloadCSVModal/DownloadCSVModal'
import { createEntityListConnector } from 'syft-acp-util/entityList'
import usePrevValue from 'syft-acp-util/hooks/usePrevValue'

import { formi9Statuses, formi9StatusKeys, paycomStatusKeys, payStatuses } from 'syft-acp-core/data'
import { WorkerListDispatchProps as DispatchProps, WorkersListProps as Props } from './WorkerList.types'
import { trackingEvents } from './WorkerList.tracking'

export const entityConnector = createEntityListConnector<WorkerEntity>({
  entityActions: { fetchEntities: workersActions.fetchWorkers },
  entityStore: 'workers',
})

export const storeConnector = connect(null, (dispatch, props: DispatchProps) => ({
  actions: {
    ...props.actions,
    ...bindActionCreators({ ...modalActions, ...workersActions, ...managersActions, showModal }, dispatch),
  },
}))

/** If any of these states are true, the worker needs attention. */
const needsAttentionStates = [
  'failed_induction',
  'failed_training',
  'need_certificates',
  'need_id',
  'need_ni_no',
  'requires_training',
]

const statusGroups = {
  i9form_status: {
    value: 'us_i9_form',
    title: 'Form I9 status',
    statuses: formi9Statuses,
    defaultStatus: (formi9StatusKeys as typeof formi9Statuses).Incomplete,
  },
  paycom_status: {
    value: 'us_paycom',
    title: 'Pay status',
    statuses: payStatuses,
    defaultStatus: (paycomStatusKeys as typeof payStatuses).unverified,
  },
}

const MODAL_NAME = 'downloadEmployersCSV'

const WorkersList = ({ actions, query, entityList, isLoadingData, pagination }: Props) => {
  const trackEvent = useTrackingTrigger()
  const prevQuery = usePrevValue(query)
  const fetch = useCallback(
    (q: Record<string, any>, toCSV = false) =>
      actions.fetchEntities && actions.fetchEntities({ options: q }, {}, toCSV),
    [actions]
  )

  useEffect(() => {
    /**
     * REVIEW: This hook prevents the initial fetching from happening unless at least one filter has been set.
     * Since the `disable_pagination_counters` filter is now set by default, we need to ignore it in making our
     * check, hence the filter call below.
     *
     * See also: https://syftapp.atlassian.net/browse/INTOPS-961
     */

    if (
      Object.keys(query).filter(key => key !== 'disable_pagination_counters').length &&
      !deepEqual(prevQuery, query)
    ) {
      fetch(query)
    }
  }, [fetch, prevQuery, query])

  const actionDownloadCSV = useCallback(
    (view?: string) => () => {
      const hasFilters = !isEqual(query, {})
      actions.showModal(MODAL_NAME, {
        onConfirm: () => {
          actions.fetchWorkersCSV(query, view)
          trackEvent(trackingEvents.DOWNLOAD_CSV_BUTTON.CLICKED)
        },
        query: query,
        total: pagination.total,
        hasFilters: hasFilters,
      })
    },
    [actions, pagination.total, query]
  )

  return (
    <CountrySpecific countries={[SUPPORTED_COUNTRY_CODES.US]}>
      {(isUS: boolean) => (
        <>
          <Acp.EntityList
            data={{
              data: entityList,
              meta: pagination,
            }}
            isLoading={isLoadingData}
            idKeyValue="id"
            hasResultCount
            hasPagination
            inContainer={!isUS}
          >
            <Acp.Actions narrowContainer={isUS}>
              <Acp.Button
                disabled={isLoadingData || !pagination.total || pagination.total === 0}
                kind="primary"
                onClick={actionDownloadCSV()}
              >
                Download CSV
              </Acp.Button>
              {isUS && (
                <Acp.Button
                  disabled={isLoadingData || !pagination.total || pagination.total === 0}
                  kind="primary"
                  onClick={actionDownloadCSV('paycom')}
                >
                  Download Paycom Users CSV
                </Acp.Button>
              )}
              <Acp.FilterGroup title="ID/Name">
                <Acp.Filter.Text name="query" placeholder="ID/Name" />
              </Acp.FilterGroup>
              <Acp.FilterGroup title="Email">
                <Acp.Filter.Text name="email" placeholder="Email" type="email" />
              </Acp.FilterGroup>
              <Acp.FilterGroup title="Phone number">
                <Acp.Filter.Text name="telephone_number" placeholder="Phone number" type="tel" />
              </Acp.FilterGroup>
              <Acp.FilterGroup title="Completed shifts ≥">
                <Acp.Filter.Number name="completed_shifts_gte" placeholder="0" min={0} />
              </Acp.FilterGroup>
              <Acp.FilterGroup title="Age ≥">
                <Acp.Filter.Number name="age_gte" placeholder="0" min={0} max={5} />
              </Acp.FilterGroup>
              <Acp.FilterGroup title="Interviewed date">
                <Acp.Filter.DateRange nameFrom="interviewed_on_gte" nameTo="interviewed_on_lte" />
              </Acp.FilterGroup>
              <Acp.FilterGroup title="Sign up date">
                <Acp.Filter.DateRange nameFrom="created_at_gte" nameTo="created_at_lte" />
              </Acp.FilterGroup>
              <Acp.FilterGroup title="Verified date">
                <Acp.Filter.DateRange nameFrom="verified_date_gte" nameTo="verified_date_lte" />
              </Acp.FilterGroup>
              <Acp.FilterGroup title="Rating">
                <Acp.Filter.Number min={0} max={5} name="rating_gte" placeholder="≥" small />
                <Acp.Filter.Number min={0} max={5} name="rating_lte" placeholder="≤" small />
              </Acp.FilterGroup>
              <Acp.FilterGroup title="Active in city">
                {isUS ? (
                  <FilterAutocomplete
                    name="venue_city"
                    placeholder="City"
                    queryFunction={workerCitiesAutocomplete}
                    forceQueryParamValue
                  />
                ) : (
                  <FilterSelectCities name="active_in_city_id" queryAttribute="active_in_city_id" allowAny />
                )}
              </Acp.FilterGroup>
              <Acp.FilterGroup title="Industry">
                <FilterSelectIndustries name="industry_code" allowAny />
              </Acp.FilterGroup>
              <Acp.FilterGroup title="Pagination">
                <FilterBoolCheckbox
                  name="disable_pagination_counters"
                  value="true"
                  defaultValue="true"
                  label="Disable pagination counters"
                />
              </Acp.FilterGroup>
              <Acp.FilterGroup title="Number of networks">
                <Acp.Filter.Number min={0} name="trusted_networks_gte" placeholder="≥" small />
                <Acp.Filter.Number min={0} name="trusted_networks_lte" placeholder="≤" small />
              </Acp.FilterGroup>
              <Acp.FilterGroup title="Status">
                {/* @ts-ignore */}
                <FilterWorkerStatuses name="status" allowAny />
              </Acp.FilterGroup>
              {isUS && (
                <Acp.FilterGroup title="Form I9 Status">
                  {/* @ts-ignore */}
                  <FilterWorkerStatuses name="i9form_status" allowAny />
                </Acp.FilterGroup>
              )}
              {isUS && (
                <Acp.FilterGroup title="Pay status">
                  {/* @ts-ignore */}
                  <FilterWorkerStatuses name="paycom_status" allowAny />
                </Acp.FilterGroup>
              )}
              <Acp.FilterGroup title="Roles and skills" maxWidth>
                <FilterRolesSkills role_key="role_id" skill_key="skill_id" independentSkills allowAny multiSkill />
              </Acp.FilterGroup>
            </Acp.Actions>
            <Acp.Table
              rowLink={row => `/entity/workers/view/${row.id}`}
              rowColor={data => {
                if (!data.status) return undefined
                // Workers we haven't seen yet make up the majority of people on the first page of the workers list.
                // If they haven't shown up yet, there's little point in marking them as lacking.
                // So in that case we always just show a plain white row.
                // const notSeen = get(data, 'status.not_seen', false)
                const notSeen = data.status.not_seen || false
                const needsAttention = Object.values(pick(data.status, needsAttentionStates)).indexOf(true) !== -1
                const isBanned = data.status.banned || false
                if (notSeen) return ''
                if (isBanned) return 'red'
                if (needsAttention) return 'yellow'
                return undefined
              }}
            >
              <Acp.Col.LegacySelector />
              <Acp.Col.Text value="id" header="ID" isCopyable isNumeric isMinimal />
              <Acp.Col.Boolean value="trusted" header={<CheckIcon />} headerTooltip="Trusted" isMinimal />
              <Acp.Col.Image value="profile_picture" header={<PersonIcon />} headerTooltip="Picture" isMinimal />
              <Acp.Col.Text value="name" header="Name" isCopyable isMain />
              <Acp.Col.Email value="email" header="Email" isCopyable isMinimal />
              <Acp.Col.Phone value="telephone_number" header="Tel. number" isCopyable isMinimal />
              <Acp.Col.WorkerStatus value="status" header="Status" isTags isMinimal />
              {isUS &&
                Object.entries(statusGroups).map(([, { value, defaultStatus, statuses, title }]) => (
                  <Acp.Col.Status
                    // @ts-ignore
                    value={(e: WorkerEntity) => ({ [e[value]?.status || defaultStatus]: true })}
                    options={{ displayUnknownValue: false, statuses: statuses }}
                    header={title}
                  />
                ))}
              <Acp.Col.Industries value="industry_ids" header="Industry" withTooltip isMinimal />
              <Acp.Col.Money value="minimum_rate" header="Min. rate" isMinimal />
              <Acp.Col.Rating value="average_rating" withTooltip header="Avg. rating" isMinimal />
            </Acp.Table>
          </Acp.EntityList>
          <DownloadCSVModal name={MODAL_NAME} />
        </>
      )}
    </CountrySpecific>
  )
}

export default flowRight(entityConnector, storeConnector)(WorkersList)
