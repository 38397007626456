// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import React from 'react'

import PageView from 'syft-acp-core/components/PageView'
import SubNavigationFinance from '../SubNavigation'
import LeaveRequestList from '../../../entities/LeaveRequestList'

export const FinanceLeaveRequests = () => (
  <>
    <SubNavigationFinance />
    <PageView title={['Finance', 'Leave Requests']} entityPage>
      <LeaveRequestList />
    </PageView>
  </>
)
