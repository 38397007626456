import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import ReactTooltip from 'react-tooltip'
import { connect } from 'react-redux'
import { isFunction } from 'lodash-es'
import { Button } from 'syft-acp-atoms/Button'
import LoadingIndicator from 'syft-acp-util/components/LoadingIndicator'

import DataText from './DataText'
import DataLink from './DataLink'

import './DataFeedbackText.css'

const DataFeedbackText = ({ value, disabled, dispatch, data, parameters }) => {
  const {
    tooltipProps,
    value: buttonText,
    onClick: onButtonCLick,
    isLoading,
    link,
    disableOnEmpty,
    ...restButtonProps
  } = isFunction(parameters) ? parameters(data) : parameters || {}
  const isDisabled = disabled || (disableOnEmpty && !value)
  const onClick = useCallback(() => onButtonCLick(dispatch), [onButtonCLick, dispatch])
  const button = link ? (
    <DataLink data={data} parameters={link} disabled={isDisabled} value={buttonText} />
  ) : (
    <Button {...restButtonProps} onClick={onClick} disabled={isDisabled}>
      {buttonText}
    </Button>
  )
  return (
    <div className="DataFeedbackText">
      <div className="feedback-text-container">
        {value && <DataText value={value} parameters={{ parseUrl: true }} />}
        <div className="feedback-button">
          {tooltipProps ? (
            <>
              <ReactTooltip {...tooltipProps} />
              <span data-tip={tooltipProps.message} data-for={tooltipProps.id}>
                {button}
              </span>
            </>
          ) : (
            button
          )}
          <div className={classNames('feedback', { loading: isLoading })}>
            <div className="indicator">
              <LoadingIndicator loadingState={isLoading} />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

DataFeedbackText.propTypes = {
  value: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  parameters: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  dispatch: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
}

DataFeedbackText.defaultProps = {
  disabled: false,
  parameters: {},
}

export default connect()(DataFeedbackText)
