// Syft ACP - Lib <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import { get } from 'lodash-es'

import { notify } from 'syft-acp-core/actions/notifications'
import { store } from 'syft-acp-core/store'

/**
 * Clears the current localStorage and refreshes.
 */
export const clearState = (refresh = true) => {
  localStorage.clear()
  if (refresh) {
    window.location.reload()
  }
}

export const getAuthToken = () => {
  const auth = get(store.getState(), 'auth.oauthData.access_token', false)
  return auth
}

export const copyAuthTokenOrNotify = () => {
  const auth = getAuthToken()
  if (auth === false) {
    store.dispatch(
      notify('error', {
        title: 'Could not copy auth',
        message: `Auth does not seem to be available.`,
        autoDismiss: 0,
      })
    )
  }
  return auth
}
