// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import React from 'react'
import PropTypes from 'prop-types'
import { get } from 'lodash-es'
import classNames from 'classnames'

import { ButtonIcon, ButtonLink } from 'syft-acp-atoms/Button'
import { apiRequest } from 'syft-acp-core/api/call'
import FileUpload from 'syft-acp-core/components/FileUpload'
import LoadingIndicator from 'syft-acp-util/components/LoadingIndicator'

import './FieldUploader.css'

class FieldUploader extends React.PureComponent {
  static propTypes = {
    disabled: PropTypes.bool,
    isGlobalLoading: PropTypes.bool,
    performInitialLoad: PropTypes.bool,
    documentUpload: PropTypes.func.isRequired,
    documentView: PropTypes.func.isRequired,
    documentLoad: PropTypes.func.isRequired,
    documentType: PropTypes.string,
    workerName: PropTypes.string,
    workerID: PropTypes.number,
    workerDoc: PropTypes.object,
    viewOnly: PropTypes.bool,
  }

  static defaultProps = {
    performInitialLoad: false,
    isGlobalLoading: false,
    disabled: false,
    documentType: null,
    workerName: '(unknown)',
    workerID: null,
    workerDoc: {},
  }

  constructor() {
    super()
    this.state = {
      isLoading: false,
    }
  }

  componentDidMount() {
    const { documentLoad, performInitialLoad } = this.props
    if (performInitialLoad) documentLoad()
  }

  get isDisabled() {
    const { documentType } = this.props
    const disabledProp = this.props.disabled
    const disabledState = this.state.disabled
    const loadingState = this.isLoading
    return disabledProp || disabledState || loadingState || documentType == null
  }

  get isLoading() {
    const disabledProp = this.props.isGlobalLoading
    const disabledState = this.state.isLoading
    return disabledProp || disabledState
  }

  get hasDocument() {
    const { workerDoc } = this.props
    return !!workerDoc.id
  }

  /**
   * Creates a document object on the server that we can upload files to.
   *
   * In order to upload files, we first need to create an object - just as on compliance.
   * After we do, it becomes possible to upload files and view them.
   */
  createDocument = async () => {
    const { workerID, workerName, documentType, documentLoad } = this.props
    this.setState({ isLoading: true })
    await this._documentPost(workerID, workerName, documentType)
    documentLoad()
    this.setState({ isLoading: false })
  }

  /**
   * Sends a document creation call to the server.
   */
  _documentPost = (workerID, workerName, documentType) =>
    new Promise(async resolve => {
      const reqArgs = {
        title: `Onboarding document: worker ${workerName} (ID: ${workerID}), type: ${documentType}`,
        type: documentType,
        attachments: [],
      }
      const req = await fetch(apiRequest({ path: `/workers/${workerID}/documents`, reqArgs, method: 'POST' }))
      resolve(req)
    })

  render() {
    const { documentUpload, documentView, workerDoc, viewOnly } = this.props
    const attachmentCount = get(workerDoc, 'attachments.length', 0)

    if (viewOnly && !this.hasDocument) {
      return null
    }

    return (
      <div className="FieldUploader">
        <div className="main">
          <div className={classNames('feedback', { loading: this.isLoading })}>
            <div className={classNames('indicator')}>
              <LoadingIndicator loadingState={this.isLoading} colorDark="#757575" />
            </div>
          </div>
          <div className="uploader">
            {this.hasDocument ? (
              <>
                {!viewOnly && (
                  <FileUpload
                    accept={{}}
                    onDrop={documentUpload}
                    disabled={this.isDisabled}
                    className="uploader"
                  />
                )}
                <ButtonLink
                  onClick={documentView}
                  kind="regular"
                  className="view"
                  disabled={this.isDisabled || attachmentCount === 0}
                >
                  View
                </ButtonLink>
              </>
            ) : (
              <ButtonIcon
                icon="file"
                left
                iconSize={12}
                kind="regular"
                className="saver"
                onClick={this.createDocument}
                disabled={this.isDisabled}
              >
                Create document
              </ButtonIcon>
            )}
          </div>
        </div>
      </div>
    )
  }
}

export default FieldUploader
