import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { filterSelect } from 'syft-acp-util/components/FilterForm/FilterSelect'
import { selectInvoicingTypes$ } from 'syft-acp-core/store/employers/selectors'

const DataInvoicingTypeDropdown = ({
  value,
  editable = false,
  onChange = () => {},
  allowAny = false,
  col = 'period',
  invoicingTypes = { period: [], type: [] },
  options = [],
}) => {
  const finalOptions = options.length === 0 ? invoicingTypes[col] : options

  // This component is designed to support both 'invoicing_format',
  // as well as the 'invoicing_period' and 'invoicing_type' combination.
  // * If this is the 'format' item and there is data for 'period' and 'type', hide it.
  // * Otherwise, if there is no data for this item, hide it.
  if (col === 'format' && invoicingTypes.period.length > 0 && invoicingTypes.type.length > 0) {
    return null
  } else if (finalOptions.length === 0) {
    return null
  }

  const opts = finalOptions.map(t => ({ id: t, label: t }))
  const defaultOpt = 'Select an invoicing type'
  const FilterSelect = filterSelect(opts, defaultOpt, 'id', false)

  return editable ? (
    <FilterSelect value={value} allowAny={allowAny} onChange={onChange} />
  ) : (
    <div className="plain-text">{value}</div>
  )
}

DataInvoicingTypeDropdown.propTypes = {
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  allowAny: PropTypes.bool,
  invoicingTypes: PropTypes.object.isRequired,
  editable: PropTypes.bool,
  onChange: PropTypes.func,
  options: PropTypes.array,
  col: PropTypes.string,
}

export default connect(state => ({
  invoicingTypes: selectInvoicingTypes$(state.employers)(),
}))(DataInvoicingTypeDropdown)
