// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import { apiRequest, handleCall } from 'syft-acp-core/api/call'

/**
 * Retrieves a list of strikes. Implements pagination.
 *
 * @param params {Object}
 * @param params.options {Object} Other query options
 */
export const fetchStrikes = ({ options = {} }) =>
  new Promise((resolve, reject) => {
    const req = apiRequest({ path: '/admin/watchlists/workers_with_strikes', reqArgs: options })
    handleCall(req, resolve, reject, true, options)
  })

export const fetchHealth = ({ options = {} }) =>
  new Promise((resolve, reject) => {
    const req = apiRequest({
      path: '/admin/watchlists/important_document_responses/health_questionnaires/positive',
      reqArgs: options,
    })
    handleCall(req, resolve, reject, true, options)
  })

/**
 * Retrieves a list of documents that will expire soon. Implements pagination.
 *
 * @param params {Object}
 * @param params.options {Object} Other query options
 */
export const fetchExpiringDocuments = ({ options = {} }) =>
  new Promise((resolve, reject) => {
    const req = apiRequest({ path: '/admin/workers/expiring_documents', reqArgs: options })
    handleCall(req, resolve, reject, true, options)
  })

/**
 * Marks a set of documents as resolved.
 * Note: this runs multiple calls, one per ID. In the future this will be changed.
 *
 * @param params.ids {Array} IDs to resolve
 */
export const markExpiringDocumentsAsResolved = ({ ids = [] }) =>
  Promise.all(
    ids.map(
      id =>
        new Promise((resolve, reject) => {
          const req = apiRequest({
            path: `/admin/workers/expiring_documents/${id}`,
            reqArgs: { resolved: true },
            method: 'PUT',
          })
          handleCall(req, resolve, reject, true)
        })
    )
  )
