import externalWorkLocationTypes from './action-types'

import {
  CreateExternalWorkLocationActionPayload,
  DeleteExternalWorkLocationActionPayload,
  FetchExternalWorkLocationActionPayload,
  UpdateExternalWorkLocationActionPayload,
} from './types'

export type ExternalWorkLocationAction =
  | ReturnType<typeof fetchExternalWorkLocation>
  | ReturnType<typeof createExternalWorkLocation>
  | ReturnType<typeof updateExternalWorkLocation>
  | ReturnType<typeof deleteExternalWorkLocation>

export const fetchExternalWorkLocation = (payload: FetchExternalWorkLocationActionPayload) => ({
  type: externalWorkLocationTypes.ENTITY_FETCH_BEGIN,
  payload,
})
export const createExternalWorkLocation = (payload: CreateExternalWorkLocationActionPayload) => ({
  type: externalWorkLocationTypes.ENTITY_CREATE_BEGIN,
  payload,
  employerID: payload.employer_id,
})
export const updateExternalWorkLocation = (payload: UpdateExternalWorkLocationActionPayload) => ({
  type: externalWorkLocationTypes.ENTITY_UPDATE_BEGIN,
  payload,
  employerID: payload.employer_id,
})
export const deleteExternalWorkLocation = (payload: DeleteExternalWorkLocationActionPayload) => ({
  type: externalWorkLocationTypes.ENTITY_DELETE_BEGIN,
  payload,
  employerID: payload.employer_id,
})
