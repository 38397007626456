import * as types from './action-types'

export const fetchMissingPay = (workerID: number, options: Record<string, any> = {}) => ({
  type: types.WORKER_MISSING_PAY_FETCH_BEGIN,
  workerID,
  options,
})
type FetchMissingPayAction = ReturnType<typeof fetchMissingPay>

export const deleteMissingPay = (workerID: number, missingPayID: number, options: Record<string, any> = {}) => ({
  type: types.WORKER_MISSING_PAY_DELETE_BEGIN,
  workerID,
  missingPayID,
  options,
})
type DeleteMissingPayAction = ReturnType<typeof deleteMissingPay>

export type WorkerMissingPayAction = FetchMissingPayAction | DeleteMissingPayAction
