import { createSelector } from 'reselect'
import { Store } from 'syft-acp-core/store'
import { formatToShortHandDays } from 'syft-acp-util/time/helpers'
import { selectCheckboxItems } from 'syft-acp-core/store/checkboxes/selectors'
import type {
  EmployerRateCardEntity,
  TimeDependentRate,
  EmployerRateCardListCheckbox,
} from 'syft-acp-core/store/employerRateCards/types'
import { selectBankHolidayRegions } from '../bankHolidayRegions/selectors'
import type { BankHolidayRegionsEntity } from '../bankHolidayRegions/types'

type Entity<T> = {
  entityMap: Record<string, T>
}

const selectEmployerRateCards = (state: Store) => state.newEmployerRateCards

export const selectEmployerRateCard = (state: Store, rateCardId: number) =>
  state.newEmployerRateCards.entityMap[rateCardId]

export const selectTimeDependentRates = createSelector(
  selectEmployerRateCard,
  selectBankHolidayRegions,
  (
    { time_dependent_rates = [] }: Partial<EmployerRateCardEntity> = {},
    bankHolidayRegions: Entity<BankHolidayRegionsEntity>
  ) =>
    time_dependent_rates.map((rate: TimeDependentRate) => ({
      id: rate.id,
      label: rate.label,
      weekDays: formatToShortHandDays(rate.week_days, true, ', ', 'ddd'),
      timeDependency: rate.start && rate.end ? `${rate.start} - ${rate.end}` : '-',
      holidayRegion:
        rate.bank_holiday_region_id !== null ? bankHolidayRegions.entityMap[rate.bank_holiday_region_id]?.name : '-',
      default_rate: rate.default_payable_rate,
      minimum: rate.min_payable_rate,
      maximum: rate.max_payable_rate,
      invoiceRate: rate.invoice_rate,
      marginsPercent: rate.margins_percent ? `${rate.margins_percent}%` : '-',
    }))
)

export const selectRateCardsToDisable = createSelector(
  selectEmployerRateCards,
  selectCheckboxItems,
  (employerRateCards: Entity<EmployerRateCardEntity>, selectedItems: EmployerRateCardListCheckbox[] = []) =>
    selectedItems.flatMap(({ id }: EmployerRateCardListCheckbox) =>
      employerRateCards.entityMap[id]?.disabled_at === null ? [id] : []
    )
)

export const selectRateCardsToEnable = createSelector(
  selectEmployerRateCards,
  selectCheckboxItems,
  (employerRateCards: Entity<EmployerRateCardEntity>, selectedItems: EmployerRateCardListCheckbox[] = []) =>
    selectedItems.flatMap(({ id }: EmployerRateCardListCheckbox) =>
      employerRateCards.entityMap[id]?.disabled_at !== null ? [id] : []
    )
)
