// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import { call, put } from 'redux-saga/effects'
import { history } from 'syft-acp-core/history'
import { isFunction, get } from 'lodash-es'
import { notify } from 'syft-acp-core/actions/notifications'
import { getNotificationText } from 'syft-acp-core/lib/notifications'

/**
 * Saga for standard API calls. This calls the requested API method
 * and returns the payload given by the promise (once it resolves),
 * or returns the error in case the promise is rejected.
 *
 * @param {Function} fn Desired API call
 * @param {String} successType Action type called in case of success
 * @param {String} failureType Action type called in case of failure
 * @param {Object} action Action object
 */
export function* apiCall(fn, successType, failureType, action) {
  try {
    const result = yield call(fn, action)
    // If nothing happened, we'll assume that the API call did not need to be run.
    // This happens when fetching a new auth token is unnecessary, for example.
    // Pass on 'null' as payload and ignore it in the reducer.
    const payload = get(result, 'payload', null)
    const meta = get(result, 'meta', {})
    yield put({ type: successType, payload, request: action, ...meta })
  } catch (payload) {
    // Turn the error into a string so that it can be serialized.
    const errorPayload = get(payload, 'body', {})
    const message = get(payload, 'message', null)
    yield put({ payload: { body: errorPayload }, message, type: failureType, request: action })
  }
}

/**
 * Sends a notification following an API call.
 *
 * @param {String} title nofification title
 * @param {String} message notification message
 */
export function* notificationCall(action) {
  const message = getNotificationText(action.type, action.payload, action.request)

  // Send a notification to the user if a message could be found.
  if (message) {
    yield put(notify(message.level, { ...message, ...(message.level === 'error' ? { autoDismiss: 0 } : {}) }))
  }
}

/**
 * Sends an notification error.
 *
 * @param {String} message notification message
 */
export function* notificationErrorCall(action) {
  const message =
    get(getNotificationText(action.type, action.payload), 'title') ||
    get(action, 'payload.body.error_description') ||
    get(action, 'payload.message') ||
    'Something went wrong'
  yield put(notify('error', { message, title: 'Error' }))
}

/**
 * Redirects to specified URL.

 * @param url Can be string or function that takes
 * an object with properties `payload` and `request` and returns string.
 * @param {Object} action Action object
 */
export function redirectCall(url, action) {
  const { payload, request } = action
  history.push(isFunction(url) ? url({ payload, request }) : url)
}
