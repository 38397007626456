const rehydrateReset = {
  isSavingData: false,
  isLoadingData: false,
  lastMessage: '',
  lastStatus: null,
  lastBody: null,
}

export const rehydrationComplete = state => {
  return {
    ...state,
    ...rehydrateReset,
  }
}
