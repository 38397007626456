// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import { takeLatest } from 'redux-saga/effects'

import { takeLatestApiCall } from 'syft-acp-core/sagas/helpers'
import { notificationCall } from 'syft-acp-core/sagas/calls'

import { IMPORT_FILE_BEGIN, IMPORT_FILE_SUCCEEDED, IMPORT_FILE_FAILED } from './action-types'
import { importFile } from './api'
import { GenericAction } from '../types'
import config from 'syft-acp-core/config'

export const redirectLocation = (action: GenericAction) => {
  const longTaskId = action.payload?.long_task_id

  if (longTaskId) {
    window.open(`${config.apiConfig.apiBaseUrl}inspector/long_tasks/${longTaskId}`)
  }
}

export default function* root() {
  yield takeLatestApiCall(IMPORT_FILE_BEGIN, importFile)
  yield takeLatest([IMPORT_FILE_SUCCEEDED], redirectLocation)
  yield takeLatest([IMPORT_FILE_SUCCEEDED, IMPORT_FILE_FAILED], notificationCall)
}
