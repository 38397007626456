import { createSelector } from 'reselect'
import { keys, pick, some } from 'lodash-es'
import { ListingWorkerShiftEntity } from './types'

export const selectListingShiftBookings = (state: any) => state.listingShiftBookings

export const selectListingWorkerShifts = createSelector(selectListingShiftBookings, listingShiftBookings => {
  const { entityMapChanges = {}, entityMap } = listingShiftBookings
  const changedIds = keys(entityMapChanges)
  return pick(entityMap, changedIds)
})

export const selectProvisionalBooking = createSelector(
  selectListingWorkerShifts,
  (entityMap: Record<string, ListingWorkerShiftEntity>) =>
    some(Object.values(entityMap), (booking: ListingWorkerShiftEntity) => booking?.provisional),
)
