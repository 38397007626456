import { throttle } from 'lodash-es'
import { delay, eventChannel } from 'redux-saga'
import { call, cancelled, put, race, select, take, fork } from 'redux-saga/effects'
import { keyPrefix } from 'syft-acp-core/constants'
import * as types from 'syft-acp-core/actions/action-types'
import { logOut, resetAuth } from 'syft-acp-core/actions/auth'
import { redirectCall } from './calls'

const events = ['keydown', 'mousedown', 'touchstart', 'MSPointerDown', 'scroll']
export const userActiveKey = `${keyPrefix}user_last_active_at`

export const createEventsChannel = () =>
  eventChannel(emitter => {
    const handle = throttle(event => emitter(event), 1000)
    events.forEach(event => window.addEventListener(event, handle, { passive: true, capture: true }))
    return () =>
      events.forEach(event => window.removeEventListener(event, handle, { passive: true, capture: true }))
  })

const checkIfUserIdling = function* (userIdleTimeout) {
  const eventsChan = yield call(createEventsChannel)
  const idleTimeout = yield select(state => state.settings.userIdleTimeout)
  const expiresIn = Number(userIdleTimeout) || 1000 * 60 * 60 * (parseFloat(idleTimeout) || 0.5)
  const { isLoggedIn } = yield select(state => state.auth)

  if (isLoggedIn) {
    // set last active at time on initialize
    localStorage.setItem(userActiveKey, Date.now())
  }

  try {
    // always monitor activity even in logged out state
    while (true) {
      const { active, idle, loggedOut } = yield race({
        active: take(eventsChan),
        idle: delay(expiresIn, true),
        loggedOut: take([types.AUTH_LOG_OUT, types.AUTH_LOG_OUT_BEGIN]),
      })

      const auth = yield select(state => state.auth)

      if (!!auth.userData && auth.isLoggedIn) {
        const lastActive = localStorage.getItem(userActiveKey)

        if (active) {
          // update last active time
          localStorage.setItem(userActiveKey, Date.now())
          // idle here not always means that the user is inactive because he might be active in another tab
          // check his last active time and if expired, clear localstorage, logout & redirect to login page
        } else if (loggedOut || Date.now() - lastActive >= expiresIn) {
          localStorage.removeItem(userActiveKey)

          if (idle) {
            yield put(logOut())
            yield put(resetAuth())
            yield call(redirectCall, '/login', {})
          }
        }
      }
    }
  } finally {
    if (yield cancelled()) {
      // guard against edge case when the work might been cancelled
      eventsChan.close()
    }
  }
}

export default function* watchForUserIdle(userIdleTimeout) {
  yield fork(checkIfUserIdling, userIdleTimeout)
}
