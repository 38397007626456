import { takeLatest } from 'redux-saga/effects'

import { takeLatestApiCall } from 'syft-acp-core/sagas/helpers'

import * as api from './api'
import types from './action-types'
import { handleConsentChange } from './calls'

export default function* root() {
  yield takeLatestApiCall(types.ENTITY_FETCH_BEGIN, api.fetchWorkerMarketingPreferences)
  yield takeLatestApiCall(types.ENTITY_UPDATE_BEGIN, api.updateWorkerMarketingPreferences)
  yield takeLatest(types.CONSENT_WORKER_MARKETING_PREFERENCES_BEGIN, handleConsentChange)
}
