import React from 'react'
import classnames from 'classnames'

import CellLink from '../CellLink'
import CellCopy from '../CellCopy'

import { CellContainerProps as Props } from './CellContainer.types'
import './CellContainer.scss'

/**
 * Wrapper for either text-like content or other types of content.
 *
 * Text content is the default. The following other attributes can be passed:
 *
 * * isPure: ensures there is no extra padding whatsoever
 * * isForm: adds a 1px padding to the cell
 *
 * If a cell is either pure or a form, it will not get a clickable link.
 */
export default class CellContainer extends React.PureComponent<Props> {
  static defaultProps: Omit<Props, 'children'> = {
    className: '',
    hasCellLink: false,
    isPure: false,
    isForm: false,
    isHeader: false,
  }

  render() {
    const {
      children,
      isCopyable,
      link,
      isPure,
      isForm,
      isHeader,
      callback,
      hasCellLink,
      className,
      isPopover,
      callbackAndLink,
    } = this.props
    /** Erase the link if this cell is not supposed to be clickable. */
    const isClickable = !isPure && !isForm
    const containerClass = classnames('acp-table-cell-container', className, {
      'is-pure': isPure,
      'is-form': isForm,
      'is-header': isHeader,
      'has-callback': callback != null,
      'is-clickable': (isClickable && link) || isPopover,
      'has-cell-link': hasCellLink && link,
      'is-not-clickable': !isClickable,
    })
    // If this is a form cell or clickable params haven't been passed in, don't wrap it in a link.
    if (isForm || (!callback && !link)) return <div className={containerClass}>{children}</div>

    const cellContent = (
      <CellLink
        isClickable={isClickable}
        callback={!isPopover ? callback : undefined}
        to={isClickable && (!callback || callbackAndLink) ? link : ''}
        className={containerClass}
      >
        {children}
      </CellLink>
    )

    if (isCopyable) return <CellCopy value={isCopyable}>{cellContent}</CellCopy>

    return cellContent
  }
}
