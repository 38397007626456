import React from 'react'

import { FormattedMessage } from 'react-intl'
import { Modal } from '@indeed/ipl-modal'
import { Text } from '@indeed/ifl-components'
import { LoginTroubleModalProps as Props } from './LoginTroubleModal.types'

const LoginTroubleModal = ({ isOpen, handleOnExit, handleSubmit }: Props) => {
  return (
    <Modal
      isOpen={isOpen}
      onExit={handleOnExit}
      title="Confirmation"
      footerButtons={[
        {
          label: 'Close',
          props: {
            onClick: () => handleOnExit(),
          },
        },
        {
          label: 'Ok',
          props: {
            variant: 'secondary',
            onClick: () => handleSubmit(),
          },
        },
      ]}
      data-testid="login-trouble-modal"
    >
      <Text as="p" level={3}>
        If you are having trouble logging in, you could try doing a full reset of cached data.
      </Text>
      <Text as="p" level={3}>
        Close all other <FormattedMessage id="brand.name" /> ACP windows, then click "OK" to clear the data
        and reload the page. You can then try logging in again.
      </Text>
    </Modal>
  )
}

export default LoginTroubleModal
