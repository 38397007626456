import * as types from './action-types'
import { FetchListOptions } from './types'

export const fetchEmployerRateCards = ({ options }: FetchListOptions, toCSV = false) => ({
  type: types.NEW_EMPLOYER_RATE_CARDS_FETCH_BEGIN,
  toCSV,
  options: options,
})

export const fetchEmployerRateCard = (rateCardID: number) => ({
  type: types.NEW_EMPLOYER_RATE_CARD_FETCH_BEGIN,
  rateCardID,
})

export const saveEmployerRateCard = (employerID: number, id: number, data: Record<string, any>) => ({
  type: types.NEW_EMPLOYER_RATE_CARD_SAVE_BEGIN,
  employerID,
  id,
  data,
})

export const disableEmployerRateCard = (id: number) => ({
  type: types.NEW_EMPLOYER_RATE_CARD_DISABLE_BEGIN,
  payload: {
    id,
  },
})

export const enableEmployerRateCard = (id: number) => ({
  type: types.NEW_EMPLOYER_RATE_CARD_ENABLE_BEGIN,
  payload: {
    id,
  },
})

export const bulkDisableEmployerRateCard = (ids: number[]) => ({
  type: types.NEW_EMPLOYER_RATE_CARD_BULK_DISABLE_BEGIN,
  payload: {
    ids,
  },
})

export const bulkEnableEmployerRateCard = (ids: number[]) => ({
  type: types.NEW_EMPLOYER_RATE_CARD_BULK_ENABLE_BEGIN,
  payload: {
    ids,
  },
})
