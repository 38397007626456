// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { get } from 'lodash-es'

import entityList from 'syft-acp-util/entityList'
import * as employerHistoryActions from 'syft-acp-core/actions/employer-history'
import * as tableType from 'syft-acp-core/components/EntityList/'

import EmployerDetailListWrapper from './EmployerDetailListWrapper'
import './EmployerAccountsList/EmployerAccountsList.css'

// All possible event types.
const eventTypes = {
  account_manager_assigned: {
    name: 'Account manager assigned',
    color: '#5740b6',
  },
  sales_manager_assigned: {
    name: 'Sales manager assigned',
    color: '#405ab6',
  },
  marked_as_preauth: {
    name: 'Marked as preauth',
    color: '#4266ba',
  },
  marked_as_no_preauth: {
    name: 'Marked as no preauth',
    color: '#42a2ba',
  },
  verified: {
    name: 'Verified',
    color: '#0bd536',
  },
  unverified: {
    name: 'Unverified',
    color: '#ff034a',
  },
  impersonated: {
    name: 'Admin logged in to account',
    color: '#ffaa02',
  },
}

// Returns either a sales manager name or an account manager name, if any.
const getManagerName = data => get(data, 'payload.sales_manager_name', get(data, 'payload.account_manager_name', '–'))

// Same, but with their IDs.
const getManagerID = data => get(data, 'payload.sales_manager_id', get(data, 'payload.account_manager_id', '–'))

const tableFormat = [
  { type: tableType.TXT, val: 'id', header: 'ID', numeric: true },
  { type: tableType.TAGS, val: 'event_type', header: 'Event type', typesMap: eventTypes },
  { type: tableType.DATETIME, val: 'event_created_at', header: 'Timestamp' },
  { type: tableType.TXT, val: 'payload.actor_id', header: 'Actor ID', numeric: true },
  { type: tableType.TXT, val: 'payload.actor_email', header: 'Actor Email' },
  { type: tableType.TXT, val: getManagerID, header: 'Manager ID', numeric: true },
  { type: tableType.TXT, val: getManagerName, header: 'Manager name' },
]

const filterFormat = null
const actionFormat = []
const EntityList = entityList(
  EmployerDetailListWrapper,
  tableFormat,
  filterFormat,
  actionFormat,
  'employerHistory',
  {
    fetchEntities: employerHistoryActions.fetchEmployerHistory,
  },
  {
    hasLinks: false,
    showResultCount: true,
    // Display a row background for some events.
    rowClasses: data => {
      if (data.event_type === 'verified') return 'green'
      if (data.event_type === 'unverified') return 'red'
      if (data.event_type === 'account_manager_assigned' || data.event_type === 'sales_manager_assigned') return 'cyan'
    },
  }
)

class EmployerHistoryList extends PureComponent {
  static propTypes = {
    actions: PropTypes.objectOf(PropTypes.func).isRequired,
    employerID: PropTypes.number.isRequired,
  }

  render() {
    return (
      <div className="EmployerRatingsList">
        <EntityList
          {...this.props}
          pagination
          employerID={this.props.employerID}
          query={{ employerID: this.props.employerID }}
        />
      </div>
    )
  }
}

export default connect(null, dispatch => ({
  actions: bindActionCreators(employerHistoryActions, dispatch),
}))(EmployerHistoryList)
