import React, { useEffect, useCallback } from 'react'
import { ConnectedProps } from 'react-redux'
import deepEqual from 'react-fast-compare'

import Acp from 'syft-acp-uikit'
import { fetchWatchlistCompliance } from 'syft-acp-core/store/watchlistCompliance/actions'
import { WatchlistComplianceEntity } from 'syft-acp-core/store/watchlistCompliance/types'
import usePrevValue from 'syft-acp-util/hooks/usePrevValue'

import { createEntityListConnector } from 'syft-acp-util/entityList'

export const entityConnector = createEntityListConnector<Partial<WatchlistComplianceEntity>>({
  entityActions: { fetchEntities: fetchWatchlistCompliance },
  entityStore: 'watchlistCompliance',
})

type Props = ConnectedProps<typeof entityConnector>

const WatchlistCompliance = ({ data, actions, query }: Props) => {
  const prevQuery = usePrevValue(query)

  const fetch = useCallback((q: Record<string, any>) => actions.fetchEntities?.({ options: q }), [actions])

  useEffect(() => {
    if (!deepEqual(prevQuery, query)) fetch(query)
  }, [fetch, prevQuery, query])
  return (
    <Acp.EntityList hasResultCount hasPagination idKeyValue="audit_id" data={data}>
      <Acp.Actions>
        <Acp.FilterGroup title="Name:">
          <Acp.Filter.Worker name="worker_name" placeholder="Worker name" />
        </Acp.FilterGroup>
        <Acp.FilterGroup title="Shift:">
          <Acp.Filter.Text name="shift_id" placeholder="ID" />
        </Acp.FilterGroup>
        <Acp.FilterGroup title="Sent at:">
          <Acp.Filter.DateRange nameFrom="sent_at_gte" nameTo="sent_at_lte" />
        </Acp.FilterGroup>
      </Acp.Actions>
      <Acp.Table
        rowLink={row =>
          `/listings/view/${row?.shift?.listing_id}/job/${row?.shift?.job_id}/shift/${row?.shift?.shift_id}`
        }
      >
        <Acp.Col.Text value="audit_id" header="ID" isMinimal isNumeric />
        <Acp.Col.Employer
          value="employer"
          header="Employer"
          isMinimal
          colLink={(row: WatchlistComplianceEntity) => `/entity/employers/view/${row?.employer?.employer_id}`}
        />
        <Acp.Col.Email value="recipient" header="Recipient" isMinimal />
        <Acp.Col.Text
          value="venue.name"
          header="Venue"
          isMain
          colLink={(row: WatchlistComplianceEntity) =>
            `/entity/employers/view/${row?.employer?.employer_id}/venues/view/${row?.venue?.venue_id}`
          }
        />
        <Acp.Col.Text value="workers.length" header="Workers #" isMinimal isNumeric />
        <Acp.Col.Timestamp
          value="shift.start_time"
          header="Shift start time"
          isMinimal
          options={{ useFormat: 'datetime', showRelative: false }}
        />
        <Acp.Col.Timestamp
          value="shift.start_time"
          isMinimal
          options={{ relativeOnly: true, showRelative: true }}
        />
        <Acp.Col.Timestamp
          value="sent_at"
          header="Sent at"
          isMinimal
          options={{ useFormat: 'datetime', showRelative: false }}
        />
        <Acp.Col.Timestamp value="sent_at" isMinimal options={{ relativeOnly: true, showRelative: true }} />
      </Acp.Table>
    </Acp.EntityList>
  )
}

export default entityConnector(WatchlistCompliance)
