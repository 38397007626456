import { apiRequest, handleCall } from 'syft-acp-core/api/call'
import { UnfilledAllocationsPayload } from './types'

/**
 * Retrieves a list of unfilled allocations.
 */
export const unfilledAllocations = ({ id, payload }: { id: string; payload: UnfilledAllocationsPayload }) =>
  new Promise((resolve, reject) => {
    const req = apiRequest({ path: `/admin/agencies/${id}/unfilled_allocations`, reqArgs: payload })
    handleCall(req, resolve, reject, false, payload)
  })

/**
 * Retrieves delete of unfilled allocations.
 */
export const removeUnfilledAllocations = ({ ids }: { ids: string[] }) =>
  new Promise((resolve, reject) => {
    const req = apiRequest({
      path: `/admin/agencies/remove_unfilled_allocations`,
      reqArgs: { agency_shift_ids: ids },
      method: 'POST',
    })
    handleCall(req, resolve, reject)
  })

/**
 * Deletes agency unfilled allocations.
 */
export const bulkDeleteAgencyShifts = ({ ids }: { ids: string[] }) =>
  new Promise((resolve, reject) => {
    const req = apiRequest({
      path: '/admin/agency_shifts/unfilled',
      reqArgs: { agency_shift_ids: ids },
      method: 'DELETE',
    })
    handleCall(req, resolve, reject)
  })
