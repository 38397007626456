import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

import './CellLink.css'

/**
 * Link element for the cells inside ACP table rows.
 */
export default class CellLink extends React.PureComponent {
  static propTypes = {
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
    callback: PropTypes.func,
    to: PropTypes.string,
  }

  static defaultProps = {
    className: '',
    callback: null,
    to: null,
  }

  render() {
    const { children, className, callback, to } = this.props
    return (
      <Link
        to={to}
        onClick={e => {
          if (!to) {
            e.preventDefault()
          }
          callback?.(e)
        }}
        className={`acp-table-cell-link ${to ? 'is-clickable' : 'is-not-clickable'} ${className}`}
      >
        {children}
      </Link>
    )
  }
}
