import entityList from 'syft-acp-util/entityList'
import { fetchAdminConversationMessages } from 'syft-acp-core/actions/conversations'
import entityDetailList from 'syft-acp-core/components/EntityDetail/entityDetailList'
import { messagesTableFormat } from './structure'

const MessageWrapper = entityDetailList({ title: 'Messages', withPagination: true })

export default entityList(
  MessageWrapper,
  messagesTableFormat,
  null,
  null,
  'conversationMessages',
  {
    fetchEntities: ({ options }, { id }) => fetchAdminConversationMessages(id, options),
  },
  {
    recordList: true,
    disableAutoScroll: true,
    hasLinks: false,
    rowClasses: data => data.flagged_words_count > 0 && 'yellow',
  }
)
