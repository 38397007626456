// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import { fetchDocuments, fetchDocument, deleteDocument, saveDocument } from 'syft-acp-core/api/resources/documents'
import {
  fetchAttachments,
  fetchAttachment,
  saveAttachment,
  deleteAttachment,
} from 'syft-acp-core/api/resources/attachments'
import { takeEveryApiCall } from './helpers'

export default function* root() {
  yield takeEveryApiCall('WORKER_DOCUMENTS_FETCH', fetchDocuments)
  yield takeEveryApiCall('WORKER_DOCUMENT_FETCH', fetchDocument)
  yield takeEveryApiCall('WORKER_DOCUMENT_DELETE', deleteDocument)
  yield takeEveryApiCall('WORKER_DOCUMENT_SAVE', saveDocument)
  yield takeEveryApiCall('ATTACHMENTS_FETCH', fetchAttachments)
  yield takeEveryApiCall('ATTACHMENT_FETCH', fetchAttachment)
  yield takeEveryApiCall('ATTACHMENT_SAVE', saveAttachment)
  yield takeEveryApiCall('ATTACHMENT_DELETE', deleteAttachment)
}
