// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import { put, takeLatest } from 'redux-saga/effects'

import { saveTimeDependentRates, updateTimeDependentRates } from 'syft-acp-core/api/resources/admin/timeDependentRates'
import * as types from 'syft-acp-core/actions/action-types'
import { showModal } from 'syft-acp-core/store/modals/actions'
import { notificationCall } from './calls'
import { takeLatestApiCall } from './helpers'

export function* rateCardReplacedModal(action) {
  const { request = {}, payload = {} } = action
  const { meta = {} } = request
  const requestRateCardId = request.data.rate_card_id
  const responseRateCardId = payload.rate_card_id
  const rateCardIdReplaced = requestRateCardId !== responseRateCardId

  if (meta.replaceModal && rateCardIdReplaced) {
    yield put(
      showModal('rateCardReplacement', {}, null, {
        newRateCardId: responseRateCardId,
      })
    )
  }
}

export default function* root() {
  yield takeLatestApiCall('TIME_DEPENDENT_RATES_UPDATE', updateTimeDependentRates)
  yield takeLatestApiCall('TIME_DEPENDENT_RATES_SAVE', saveTimeDependentRates)
  yield takeLatest(
    [
      types.TIME_DEPENDENT_RATES_SAVE_SUCCEEDED,
      types.TIME_DEPENDENT_RATES_SAVE_FAILED,
      types.TIME_DEPENDENT_RATES_UPDATE_SUCCEEDED,
      types.TIME_DEPENDENT_RATES_UPDATE_FAILED,
    ],
    notificationCall
  )
  yield takeLatest(
    [types.TIME_DEPENDENT_RATES_UPDATE_SUCCEEDED, types.TIME_DEPENDENT_RATES_SAVE_SUCCEEDED],
    rateCardReplacedModal
  )
}
