// Syft ACP - UI Library <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import classNames from 'classnames'
import * as React from 'karet'
import PropTypes from 'prop-types'
import LoadingSpinner from 'syft-acp-atoms/LoadingSpinner'
import PageView from 'syft-acp-core/components/PageView'
import { extractTypeNodeSpec } from '../../../../lib/childNodes'
import RecordHeader from '../RecordHeader'
import {
  iterateButtonDropdowns,
  iterateButtonLinks,
  iterateButtons,
  iterateFilters,
  iterateGenericComponents,
  iterateControlGroup,
} from '../utils'
import ButtonNew from 'syft-acp-atoms/ButtonNew'

import './RecordHeaderForm.scss'

/** Returns header text from the AcpHeader component. */
const getHeaderContent = componentData => {
  if (componentData.AcpHeader.children) return componentData.AcpHeader.children
  if (componentData.AcpHeader.length && componentData.AcpHeader[0].children) return componentData.AcpHeader[0].children
  return null
}

/** Header with form (containing filters, buttons and actions) for EntityList components. */
const RecordHeaderForm = ({
  children,
  isLoading,
  isHeader,
  data,
  subDataSpec,
  stateSelection,
  resetButton,
  handleReset,
  stateSubSelection,
  isNarrow = false,
  actionsInline = false,
}) => {
  const componentData = extractTypeNodeSpec(children, [
    'AcpGenericComponent',
    'AcpControlGroup',
    'AcpButton',
    'AcpButtonLink',
    'AcpButtonDropdown',
    'AcpHeader',
    'AcpFilterGroup',
  ])
  const headerContent = getHeaderContent(componentData)
  const callbackData = { tableData: data }
  const tableData = { tableData: data, stateSelection, stateSubSelection }
  const actionContent = (
    <div className="acp-record-header-form">
      {!!componentData.AcpFilterGroup.length && (
        <div className="acp-record-header-filters" data-testid="entity-list-filters">
          {iterateFilters(componentData.AcpFilterGroup)}
        </div>
      )}

      <div className={classNames('acp-record-header-actions', resetButton && 'acp-list-popover-container')}>
        {resetButton && (
          <ButtonNew onClick={handleReset} className="acp-list-popover-button">
            Clear all filters
          </ButtonNew>
        )}
        <LoadingSpinner isLoading={isLoading} />
        {iterateButtons(componentData.AcpButton, callbackData, tableData, subDataSpec, true)}
        {iterateButtonLinks(componentData.AcpButtonLink, callbackData, tableData, subDataSpec, true)}
        {iterateButtonDropdowns(componentData.AcpButtonDropdown, callbackData, tableData, subDataSpec, true)}
      </div>
    </div>
  )

  const formContent = (
    <div
      className={classNames('acp-record-header-form-container', {
        'acp-record-header-form-container--actions-inline': actionsInline,
        'is-header': isHeader,
      })}
      data-testid="acp-header-form"
    >
      {!!componentData.AcpControlGroup.length && (
        <div className="acp-record-header-control-group">{iterateControlGroup(componentData.AcpControlGroup)}</div>
      )}
      <React.Fragment>{headerContent && <RecordHeader>{headerContent}</RecordHeader>}</React.Fragment>
      {iterateGenericComponents(componentData.AcpGenericComponent, callbackData, tableData, subDataSpec, true)}
      {actionContent}
    </div>
  )

  return isNarrow ? <PageView container>{formContent}</PageView> : formContent
}

RecordHeaderForm.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object, PropTypes.arrayOf(PropTypes.object)]),
  subDataSpec: PropTypes.array,
  stateSelection: PropTypes.object,
  stateSubSelection: PropTypes.object,
  children: PropTypes.node,
  resetButton: PropTypes.node,
  isNarrow: PropTypes.bool,
  isHeader: PropTypes.bool,
  handleReset: PropTypes.func,

  isLoading: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
}

RecordHeaderForm.defaultProps = {
  data: {},
  subDataSpec: [],
  stateSelection: null,
  stateSubSelection: null,
  children: null,
  handleReset: null,
  resetButton: null,
  isNarrow: false,
  isHeader: false,
  isLoading: false,
}

export default RecordHeaderForm
