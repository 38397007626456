import { avatarURL } from 'syft-acp-core/api/endpoints'
import guestImage from 'syft-acp-core/static/acp-avatar-guest.png'
import emptyImage from 'syft-acp-core/static/acp-avatar-empty.png'

import { GUEST_AVATAR, EMPTY_AVATAR } from './constants'

/**
 * Retrieves the image source for an avatar or a fallback if not available.
 * Returns an array containing the image and a boolean indicating whether it's a fallback or not,
 * e.g. ['static/guestImage.png', true], ['https://cdn.com/image1234567.jpg', false].
 *
 * The 'code' can be a UUID value string, or one of GUEST_AVATAR or EMPTY_AVATAR.
 * If nothing or an empty string is passed, the EMPTY_AVATAR will be returned.
 */
export const avatarImage = code => {
  if (code === GUEST_AVATAR) return [guestImage, true]
  if (code === EMPTY_AVATAR) return [emptyImage, true]
  if (code) return [avatarURL(code), false]
  return [emptyImage, true]
}

export const guestAvatar = guestImage
export const emptyAvatar = emptyImage
