import { includes, omit } from 'lodash-es'
import moment from 'moment'

export const updatedEntityMapChanges = (changes, params) => {
  // Note: this is confusing and probably not very performant.
  // We're making an exception for fee_percent.
  // TODO: find out how we can simplify this and everything related to this code.
  // This is only used by the listingShiftBookingStates reducer.
  // See <https://syftapp.atlassian.net/browse/CMS2-400>.
  const { attr, val, originalVal } = params
  const notDateAttrs = [
    'fee_percent',
    'no_show',
    'no_show_reason',
    'syft_unpaid_hours_compensation',
    'syft_compensation',
    'fee_not_charged',
  ]
  const isNotDate = includes(notDateAttrs, attr)

  const isDate = isNotDate ? false : moment.isMoment(moment(val))
  const isValueOriginal = isDate ? moment(originalVal).isSame(val) : originalVal === val
  const withoutAttrChanges = { ...omit(changes, attr) }
  const withAttrChanges = { ...changes, [attr]: val }

  return isValueOriginal ? withoutAttrChanges : withAttrChanges
}
