import { apiRequest, handleCall } from 'syft-acp-core/api/call'

import * as types from './types'

export const fetchWorkerHolidayPayAccruals = ({ workerID }: types.FetchWorkerHolidayPayInput) =>
  new Promise<types.FetchWorkerHolidayPayAccrualsSucceededPayload>((resolve, reject) => {
    const req = apiRequest({ path: `/workers/${workerID}/holiday_accruals` })
    handleCall(req, resolve, reject, true)
  })

export const fetchWorkerHolidayPayHistoricRequests = ({ workerID }: types.FetchWorkerHolidayPayInput) =>
  new Promise<types.FetchWorkerHolidayPayHistoricRequestsSucceededPayload>((resolve, reject) => {
    const req = apiRequest({ path: `/admin/workers/${workerID}/historic_holiday_requests` })
    handleCall(req, resolve, reject, true)
  })

export const makeWorkerHolidayPayHistoricRequest = ({
  workerID,
  amount,
  startDate,
}: types.MakeWorkerHolidayPayHistoricRequestInput) =>
  new Promise<types.WorkerHolidayPayHistoricRequestsEntity>((resolve, reject) => {
    const req = apiRequest({
      path: `/workers/${workerID}/historic_holiday_requests`,
      method: 'post',
      reqArgs: {
        amount,
        start_date: startDate,
      },
    })
    handleCall(req, resolve, reject, true)
  })

export const deleteWorkerHolidayPayHistoricRequest = ({
  payload,
}: types.DeleteWorkerHolidayPayHistoricRequestInput) => {
  return new Promise<types.WorkerHolidayPayHistoricRequestsEntity>((resolve, reject) => {
    const req = apiRequest({ path: `/admin/holiday_pay/historic_requests/${payload.id}`, method: 'DELETE' })
    handleCall(req, resolve, reject)
  })
}

export const validateWorkerHolidayPayHistoricRequest = ({
  workerID,
  amount,
}: types.ValidateWorkerHolidayPayHistoricRequestInput) =>
  new Promise<types.ValidateWorkerHolidayPayHistoricRequestSucceededPayload>((resolve, reject) => {
    const req = apiRequest({ path: `/workers/${workerID}/historic_holiday_request_requirements`, reqArgs: { amount } })
    handleCall(req, resolve, reject, true)
  })

export const fetchWorkerHolidayPayWeeklyRequests = ({ workerID }: types.FetchWorkerHolidayPayInput) =>
  new Promise<types.FetchWorkerHolidayPayWeeklyRequestsSucceededPayload>((resolve, reject) => {
    const req = apiRequest({ path: `/workers/${workerID}/weekly_holiday_requests` })
    handleCall(req, resolve, reject, true)
  })

export const makeWorkerHolidayPayWeeklyRequest = ({ workerID }: types.FetchWorkerHolidayPayInput) =>
  new Promise<types.WorkerHolidayPayWeeklyRequestsEntity>((resolve, reject) => {
    const req = apiRequest({ path: `/workers/${workerID}/weekly_holiday_requests`, method: 'post' })
    handleCall(req, resolve, reject, true)
  })
