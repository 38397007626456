import React, { FC } from 'react'

import EmployerRateCardForm from 'syft-acp-core/entities/EmployerDetail/EmployerRateCardForm'
import SubNavigation from 'syft-acp-core/components/Navigation/SubNavigation'
import { RouteComponentProps } from 'react-router-dom'

type Props = RouteComponentProps<{
  id: string
  rateCardID: string
}>

const NewEmployerRateCardView: FC<Props> = ({ match: { params } }) => (
  <div>
    <SubNavigation subPage="employers" data={{ employerID: Number(params.id) }} />
    <EmployerRateCardForm id={Number(params.rateCardID)} employerId={Number(params.id)} />
  </div>
)

export default NewEmployerRateCardView
