import { useFlexFeatureFlagsContext } from '@indeed/flex-feature-flags'
import { useEffect, useLayoutEffect, useRef, useState } from 'react'
import { Action, Location } from 'history'
import { doRedirect, shouldRedirectToOneHost } from './utils'
import { history } from 'syft-acp-core/history'

/**
 * Hooks listens for history changes and blocks transitions to OneHost routes.
 */
export const useOneHostRedirect = () => {
  const { isLoading: featuresLoading, client } = useFlexFeatureFlagsContext()
  const [isBlocked, setIsBlocked] = useState(false)
  const blockedLocationRef = useRef<[Location, Action?] | null>(null)

  useLayoutEffect(() => {
    const checkOneHostRedirect = (loc: Location, action?: Action): false | undefined => {
      if (isBlocked) return

      const { isRedirecting: nextIsRedirecting, routeConfig: nextRouteConfig } = shouldRedirectToOneHost(
        loc.pathname,
        client,
      )

      // block navigation if we're redirecting to OneHost or possibly redirecting (and store blocked location with action to retry later)
      if (nextIsRedirecting || (nextRouteConfig && featuresLoading)) {
        setIsBlocked(true)

        if (nextIsRedirecting) {
          doRedirect(nextRouteConfig, loc)
        } else {
          blockedLocationRef.current = [loc, action]
        }

        return false
      }

      return
    }

    // if was blocked but not redirected (because features were loading), retry navigation
    if (!featuresLoading && blockedLocationRef.current) {
      const [location, action] = blockedLocationRef.current

      switch (action) {
        case 'PUSH':
          history.push(location)
          break
        case 'REPLACE':
          history.replace(location)
          break
        case 'POP':
          /* istanbul ignore next */
          /* c8 ignore next */
          history.goBack()
          /* istanbul ignore next */
          /* c8 ignore next */
          break
        default:
          break
      }

      blockedLocationRef.current = null
      setIsBlocked(false)
    } else {
      checkOneHostRedirect(window.location as unknown as Location)
    }

    return history.block((loc, action) => checkOneHostRedirect(loc, action))
  }, [client, featuresLoading, isBlocked])

  return isBlocked
}

export const usePreviousValue = (value: any) => {
  const ref = useRef()
  useEffect(() => {
    ref.current = value
  })
  return ref.current
}
