import React from 'react'
import { isPlainObject, isString } from 'lodash-es'

import { formatLocalDatetime, formatLocalDateRange, formatLocalDate } from 'syft-acp-util/time'

import { colPlaceholderFactory } from '../../../../placeholders/factory'
import { cellDefaultProps } from '../../dataProps'
import { emptyTextFallback } from '../fallback'
import { AcpCellTimestampProps as Props } from './AcpCellTimestamp.types'

/** Column placeholder component. */
export const AcpColTimestamp = colPlaceholderFactory('AcpCellTimestamp')

/** Check whether a string is a date only, without time or timezone. */
const isOnlyDate = (str: Props['value']) => isString(str) && /^[0-9]{4}-[0-9]{2}-[0-9]{2}$/.test(str.trim())

/**
 * Cell component for displaying timestamps. It supports a number of formatting options.
 * Example usage:
 *
 *    <Acp.Col.Timestamp value="last_updated" header="Last updated" options={{ useFormat: 'datetime', showSeconds: false }} />
 *    <Acp.Col.Timestamp value={ ['start_time', 'end_time'] } header="Start/end" options={{ useFormat: 'datetime', showSeconds: false }} />
 *
 * The options object is defined in './AcpCellTimestamp.types'
 *
 * It's possible to pass two values to this column, e.g.:
 *
 *    <Acp.Col.Timestamp value={ ['startTime', 'endTime'] } header="Time" options={{ useFormat: 'time' }} />
 *
 * This will change the rendering method to show both values as a range.
 * For example, '18:30–00:30' or '15 Jul 01:00–08:00'.
 */
export default class AcpCellTimestamp extends React.PureComponent<Props> {
  static defaultProps = {
    ...cellDefaultProps,
    options: {
      showRelative: false,
      showSeconds: false,
      relativeOnly: false,
      relativeUnits: [],
      useISO: false,
      showTimezone: false,
      showTime: true,
    },
  }

  /** Displays a relative time for the tooltip. */
  static getTooltip = (value: string) => {
    const ts = formatLocalDatetime(value, { useFormat: 'datetime', showRelative: true, relativeOnly: true })
    return ts.formatted
  }

  /** Returns the formatted value to display. */
  getFormatted = (value?: Props['value'], options: Props['options'] = {}) => {
    if (!value) return null

    // Check whether the value is a date only.
    // If it is, we can't format it using our regular functions, as the value
    // will be interpreted as UTC and then formatted using the local timezone,
    // which can lead to dates being changed. E.g. 2019-10-02 00:00 UTC rendered as 2019-10-01 23:00 CET.
    if (!isPlainObject(value) && isOnlyDate(value)) {
      return formatLocalDate(value, { ...options, showTimezone: true })
    }

    // If this column is set up to have multiple values (e.g. value={ ['startTime', 'endTime'] }),
    // the value will be an object like { startTime: 'original', endTime: 'original' }.
    if (isPlainObject(value)) {
      // Create a range between start and end.
      const range = formatLocalDateRange(Object.values(value))
      const formatted = (
        <>
          <strong>
            {range.startDate ? <>{range.startDate}&nbsp;</> : null}
            {range.startTime}
          </strong>
          –
          <span>
            {range.endDate ? <>{range.endDate}&nbsp;</> : null}
            {range.endTime}
          </span>
        </>
      )
      return {
        formatted,
      }
    }

    // In regular cases, just create one timestamp.
    return formatLocalDatetime(value, options)
  }

  render() {
    const { value, options } = this.props
    const ts = this.getFormatted(value, options)
    return <span>{value && ts ? ts.formatted : emptyTextFallback}</span>
  }
}
