import React from 'react'
import { values, sortBy, filter } from 'lodash-es'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import * as filtersActions from 'syft-acp-core/store/filters/actions'
import { Store } from 'syft-acp-core/store'

import { FilterClientDeliveryTeamProps as Props, FilterData } from './FilterClientDeliveryTeam.types'
import { filterSelect } from '../FilterSelect'

export const storeConnector = connect(
  (state: Store) => ({
    deliveryTeams: state.clientDeliveryTeams.entityMap,
    query: state.routing.locationBeforeTransitions.query as Record<string, string | string[]>,
  }),
  (dispatch, { actions }: any) => {
    const boundActions = bindActionCreators(filtersActions, dispatch)
    return {
      actions: {
        ...boundActions,
        ...actions,
      },
    }
  },
)

export const getFilterData = (contentDeliveryTeamsMap: any): FilterData => ({
  key: 'client_delivery_team_id',
  values: values(sortBy(filter(contentDeliveryTeamsMap, ['active', true]), ['id', 'name'])).map(team => ({
    label: team.name,
    id: team.id,
  })),
})

// Data for this component are called similary to roles during initial loading here loadInitData
const FilterClientDeliveryTeam = ({
  deliveryTeams,
  defaultOption = 'Any',
  className = '',
  actions,
  allowAny = false,
  type,
  allowNameLabel = false,
  small = false,
  tiny = false,
  query,
  name,
  value,
  onChange,
}: Props) => {
  const filterData = getFilterData(deliveryTeams)
  const onChangeFilterHandler = (key: string) => (val: string | string[]) => {
    !!onChange && onChange(key, val?.toString())
    actions.setFilter(key, val)
  }

  const val = value || query[filterData.key]
  const FilterSelectElement = filterSelect(filterData.values, defaultOption || 'Any', type, false, false)

  const additionalProps = allowNameLabel
    ? {
        ariaLabel: name,
      }
    : {}

  return (
    <FilterSelectElement
      name={filterData.key}
      className={`${className}`}
      onChange={onChangeFilterHandler(filterData.key)}
      value={val}
      small={small}
      tiny={tiny}
      allowAny={allowAny}
      {...additionalProps}
    />
  )
}

export default storeConnector(FilterClientDeliveryTeam)
