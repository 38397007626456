// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import { createSelector } from 'reselect'
import { get } from 'lodash-es'
import { stringify } from 'syft-acp-core/store/filters/helpers'

import { getDateRange } from 'syft-acp-core/lib/dateFilter'
import { SHIFTS_ENTITY_SETS } from 'syft-acp-core/constants'

/**
 * This function is returning shift vacancies for counter
 *
 * @param {{}} state - the redux state
 * @returns {number} Number of vacancies for shifts counter
 */
export const getShiftsVacancies = (state, isSyftPlatform) => {
  // TODO: import this helper getDateRangeShift from utils after merge
  const getDateRangeShift = getDateRange(0, 1, 'start_time_gte', 'start_time_lte', true, true, true)
  const platformParam = isSyftPlatform ? { platform: 'syft' } : {}
  const platformStringify = stringify({
    counters_enabled: true,
    exclude_bookings: true,
    ...platformParam,
    start_time_gte: getDateRangeShift.start_time_gte,
    start_time_lte: getDateRangeShift.start_time_lte,
  })

  return get(state, `${SHIFTS_ENTITY_SETS}.${platformStringify}.totalVacancies`, 0)
}
/**
 * This selector is returning shift vacancies
 */
export const getShiftsVacanciesSelector = createSelector(getShiftsVacancies, shiftVacancies => shiftVacancies)
