// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import React from 'react'
import entityList from 'syft-acp-util/entityList'
import * as tableType from 'syft-acp-core/components/EntityList/'
import { FilterGroup, FilterText } from 'syft-acp-util/components/FilterForm'
import * as marketingActions from 'syft-acp-core/actions/marketing'
import EntityListComponent from 'syft-acp-core/components/EntityList/EntityListComponent'

const tableFormat = [
  { type: tableType.SELECTOR },
  { type: tableType.TXT, val: 'id', header: 'ID', numeric: true },
  { type: tableType.TXT, classes: ['nowrap'], val: 'value', header: 'Value' },
  { type: tableType.TXT, classes: ['main', 'nowrap'], val: 'value', header: 'Owner' },
  { type: tableType.TXT, classes: ['nowrap'], val: 'value', header: 'Amount' },
  {
    type: tableType.TXT,
    classes: ['nowrap'],
    val: 'value',
    header: 'Count',
    expl: 'Number of accounts that have claimed this code',
  },
  {
    type: tableType.DATETIME,
    classes: ['nowrap'],
    val: 'value',
    header: 'Creation date',
  },
  {
    type: tableType.DATETIME,
    classes: ['nowrap'],
    val: 'value',
    header: 'Expiry date',
  },
]

const filters = (
  <div>
    <FilterGroup title="Filter by worker name">
      <FilterText name="name" placeholder="Worker name" />
    </FilterGroup>
  </div>
)

const actionFormat = []

export default entityList(
  EntityListComponent,
  tableFormat,
  filters,
  actionFormat,
  'referralCodes',
  {
    fetchEntities: marketingActions.fetchReferralCodes,
  },
  {
    showResultCount: true,
  }
)
