// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import entityReducer, { GenericState } from 'syft-acp-core/reducers/generators/entityReducer'
import * as entityTypes from 'syft-acp-core/reducers/generators/entities'

import * as types from './action-types'
import type { EmployerEntity } from './types'
import { AnyAction } from 'redux'

const finished = (state: GenericState<EmployerEntity>, _action: AnyAction) => ({
  ...state,
  isLoadingData: true,
})

export default entityReducer<EmployerEntity>(
  'employers',
  {
    [entityTypes.ENTITIES_FETCH_BEGIN]: types.EMPLOYERS_FETCH_BEGIN,
    [entityTypes.ENTITIES_FETCH_SUCCEEDED]: types.EMPLOYERS_FETCH_SUCCEEDED,
    [entityTypes.ENTITIES_FETCH_FAILED]: types.EMPLOYERS_FETCH_FAILED,
    [entityTypes.ENTITY_FETCH_BEGIN]: types.EMPLOYER_FETCH_BEGIN,
    [entityTypes.ENTITY_FETCH_SUCCEEDED]: types.EMPLOYER_FETCH_SUCCEEDED,
    [entityTypes.ENTITY_FETCH_FAILED]: types.EMPLOYER_FETCH_FAILED,
    [entityTypes.ENTITY_SAVE_BEGIN]: types.EMPLOYER_SAVE_BEGIN,
    [entityTypes.ENTITY_SAVE_SUCCEEDED]: types.EMPLOYER_SAVE_SUCCEEDED,
    [entityTypes.ENTITY_SAVE_FAILED]: types.EMPLOYER_SAVE_FAILED,
    [entityTypes.ENTITY_MODIFY_SUCCEEDED]: [
      types.EMPLOYER_APPROVE_SUCCEEDED,
      types.EMPLOYER_MIGRATE_SUCCEEDED,
    ],
    [entityTypes.ENTITY_MODIFY_FAILED]: types.EMPLOYER_MIGRATE_FAILED,
    [types.EMPLOYER_LOGIN_AS_BEGIN]: finished,
    [types.EMPLOYER_LOGIN_AS_SUCCEEDED]: finished,
    [types.EMPLOYER_LOGIN_AS_FAILED]: finished,
  },
  {
    // When we reach over 250 cached items in the store,
    // reduce the number of items to the 125 latest.
    itemLimit: [250, 125],
  },
)
