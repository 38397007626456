import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { Button } from 'syft-acp-atoms/Button'
import { removeAdminShiftSpot } from 'syft-acp-core/store/shifts/actions'
import { removeBookingChanges } from 'syft-acp-core/actions/bookings'

const ListingRemoveSpotButton = ({ dispatch, shiftID, shiftBooking: { id } }) => {
  const onClick = () => {
    dispatch(removeAdminShiftSpot({ id, shiftID }))
    dispatch(removeBookingChanges({ id, shiftID }))
  }

  return (
    <div className="small-padded">
      <Button onClick={onClick} block>
        Remove
      </Button>
    </div>
  )
}

ListingRemoveSpotButton.propTypes = {
  dispatch: PropTypes.func.isRequired,
  shiftID: PropTypes.number.isRequired,
  shiftBooking: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  }).isRequired,
}

export default connect()(ListingRemoveSpotButton)
