import { cloneDeep, isEqual, pick, without } from 'lodash-es'

const CACHE_DURATION = 3 * 86400 * 1000

/** Returns true if an lastUpdate is older than 3 days compared to currentUpdate. */
const isOutdated = (lastUpdate, currentUpdate) => lastUpdate == null || currentUpdate - lastUpdate >= CACHE_DURATION

export const appRemoveUnusedData = state => {
  // Note: we are modifying the state. For that reason, we deep clone what we return
  // after everything is done. TODO: possibly optimize this if possible. May not be needed?
  const lastUpdate = Number(new Date())
  const entityIDs = Object.keys(state.entityUpdateTimes)
  const freshIDs = entityIDs.filter(n => !isOutdated(state.entityUpdateTimes[n], lastUpdate)).sort()

  // If no data needs to be cleaned up, return the same state.
  if (isEqual(entityIDs.sort(), freshIDs)) {
    return state
  }

  // Filter out all items in entityMap and entityDetail that are in freshIDs.
  // Also delete all entitySets that contain these IDs. They need to be refetched.
  const freshEntitySetKeys = Object.keys(state.entitySets).filter(
    k => without(state.entitySets[k].ids, ...freshIDs).length === 0
  )
  const freshEntitySets = pick(state.entitySets, freshEntitySetKeys)
  const freshEntityDetail = pick(state.entityDetail, freshIDs)
  const freshEntityMap = pick(state.entityMap, freshIDs)
  const freshEntityUpdateTimes = pick(state.entityUpdateTimes, freshIDs)

  return {
    ...state,
    entitySets: cloneDeep(freshEntitySets),
    entityDetail: cloneDeep(freshEntityDetail),
    entityUpdateTimes: cloneDeep(freshEntityUpdateTimes),
    entityMap: cloneDeep(freshEntityMap),
  }
}
