// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import React from 'react'
import PropTypes from 'prop-types'
import { Grid, Row, Col } from 'react-bootstrap'

import './PageContainer.css'

/** Container for regular pages that don't take up the full viewport width. */
const PageContainer = ({ narrow, wide, children, customColSizes }) => {
  // Determine size of the middle column.
  const regularColSize = { md: 12 }
  const narrowColSize = { mdOffset: 4, md: 4, smOffset: 3, sm: 6, xsOffset: 1, xs: 10 }

  const narrowOrRegularColSize = narrow ? narrowColSize : regularColSize

  const colSizes = customColSizes ? customColSizes : narrowOrRegularColSize

  return (
    <div className="acp-page-container">
      {wide && <div className="wide-container">{children}</div>}
      {!wide && (
        <Grid>
          <Row>
            <Col {...colSizes}>{children}</Col>
          </Row>
        </Grid>
      )}
    </div>
  )
}

PageContainer.propTypes = {
  children: PropTypes.node,
  narrow: PropTypes.bool,
  customColSizes: PropTypes.object,
  wide: PropTypes.bool,
}

PageContainer.defaultProps = {
  children: null,
  narrow: false,
  wide: false,
}

export default PageContainer
