import { createSelector } from 'reselect'
import { get, memoize } from 'lodash-es'
import entityReducer from './generators/entityReducer'
import { entitySetName } from 'syft-acp-core/reducers/generators/utils'

import { formatFullName } from 'syft-acp-util/formatting'
import * as entityTypes from './generators/entities'
import * as types from '../actions/action-types'
import * as states from './states/listingShiftBookingsStates'

/**
 * Selects all items associated with salesforce: venues, areas and the employer.
 */
export const selectShiftBookings$ = createSelector(
  state => state,
  store =>
    memoize(({ shiftID }) => {
      if (!shiftID) {
        return []
      }

      return get(store, `listingShifts.entityMap[${shiftID}].shift_bookings`, [])
    }),
)

export const selectListingShiftBookings$ = createSelector(
  state => state,
  store =>
    memoize(({ shiftID }) => {
      if (!shiftID) {
        return []
      }

      const setKey = entitySetName({ shiftID })
      const entitySet = store?.listingShiftBookings?.entitySets[setKey]
      return (entitySet?.ids ?? []).map(id => store?.listingShiftBookings?.entityMap[id])
    }),
)

/**
 * Returns a list of all missing times items for all bookings in a shift.
 */
export const selectShiftUnpaidTimes$ = createSelector(
  state => state,
  store =>
    memoize(({ shiftID }) => {
      if (!shiftID) {
        return []
      }

      const bookings = selectShiftBookings$(store)({ shiftID })
      return bookings.reduce((allBookings, booking) => {
        const unpaidTimes = (get(booking, 'unpaid_times') || []).reduce(
          (allUnpaidTimes, time) => [
            ...allUnpaidTimes,
            {
              shiftID,
              shiftBookingID: booking.id,
              worker: booking.worker,
              workerPayRate: booking.worker_pay_rate,
              unpaidTime: time,
            },
          ],
          [],
        )
        return [...allBookings, ...unpaidTimes]
      }, [])
    }),
)

/**
 * Returns a list of all workers for a current shift.
 */
export const selectShiftWorkers$ = createSelector(
  state => state,
  store =>
    memoize(({ shiftID }) => {
      if (!shiftID) {
        return []
      }

      const bookings = selectShiftBookings$(store)({ shiftID })
      return bookings.reduce((allWorkers, booking) => {
        return [
          ...allWorkers,
          {
            ...booking.worker,
            worker_pay_rate: booking.worker_pay_rate,
            label: formatFullName(booking.worker),
          },
        ]
      }, [])
    }),
)

export default entityReducer(
  'listingShiftBookings',
  {
    [entityTypes.ENTITY_FETCH_BEGIN]: types.ADMIN_LISTING_FETCH_BEGIN,
    [entityTypes.ENTITY_FETCH_SUCCEEDED]: types.ADMIN_LISTING_FETCH_SUCCEEDED,
    [entityTypes.ENTITY_FETCH_FAILED]: types.ADMIN_LISTING_FETCH_FAILED,
    [entityTypes.ENTITIES_FETCH_BEGIN]: types.BOOKINGS_SAVE_BEGIN,
    [entityTypes.ENTITIES_FETCH_SUCCEEDED]: types.ADMIN_LISTING_FETCH_SUCCEEDED,
    [entityTypes.ENTITIES_FETCH_FAILED]: types.BOOKINGS_FETCH_FAILED,
    // next two blocks look buggy (array instead of type or function).
    // better to use sagas here.
    [entityTypes.ENTITY_MODIFY_BEGIN]: [types.BOOKINGS_REPLACE_BEGIN, types.TIMEKEEPING_BULK_APPROVE_BEGIN],
    [entityTypes.ENTITY_MODIFY_SUCCEEDED]: [
      types.SHIFT_EDIT_SUCCEEDED,
      types.BOOKINGS_REPLACE_SUCCEEDED,
      types.TIMEKEEPING_BULK_APPROVE_SUCCEEDED,
    ],
    [types.BOOKINGS_FETCH_BEGIN]: states.adminListingShiftFetchBeginState,
    [types.BOOKINGS_FETCH_SUCCEEDED]: states.adminListingShiftFetchSucceededState,
    [types.BOOKINGS_DELETE_BEGIN]: states.bookingsDeleteSucceededState,
    [types.END_ASSIGNMENT_BEGIN]: states.bookingsDeleteSucceededState,
    [types.BOOKING_ADD]: states.bookingsAddState,
    [types.BOOKING_UPDATE]: states.bookingUpdateState,
    [types.SHIFT_EDIT_FAILED]: states.shiftEditFailedState,
    [types.REMOVE_ADMIN_SHIFTS_SPOT_BEGIN]: states.removeAdminShiftsSpotSucceededState,
    [types.BOOKINGS_REPLACE_FAILED]: states.failedBookingState,
    [types.TIMEKEEPING_BULK_APPROVE_FAILED]: states.failedBookingState,
    [types.BOOKINGS_DELETE_FAILED]: states.failedBookingState,
    [types.REMOVE_ADMIN_SHIFTS_SPOT_FAILED]: states.failedBookingState,
    [types.BOOKINGS_SAVE_FAILED]: states.failedBookingState,
    [types.BOOKINGS_UPDATE_CHANGES]: states.bookingsUpdateChangesState,
    [types.BOOKINGS_CANCEL_CHANGES]: states.bookingsCancelChangesState,
    [types.BOOKINGS_REMOVE_CHANGES]: states.bookingsRemoveChangesState,
    [types.BOOKINGS_CREATE_CHANGES]: states.bookingsCreateChangesState,
    [types.BOOKINGS_REPLACE_CHANGES]: states.bookingsReplaceChangesState,
  },
  {
    idKey: 'id',
    localEntityMap: false,
    groupBy: ['shiftID'],
  },
)
