import { MonetaryAmount } from 'syft-acp-core/store/types'

import * as actionTypes from './ShiftFulfilmentModal.action-types'
import { ShiftFulfilmentModalState } from './ShiftFulfilmentModal.types'

export const setShift = (payload: ShiftFulfilmentModalState) => ({
  type: actionTypes.SET_SHIFT,
  payload,
})
export type SetShiftAction = ReturnType<typeof setShift>

export const updateOfferRate = (payload: MonetaryAmount) => ({
  type: actionTypes.UPDATE_OFFER_RATE,
  payload,
})
export type UpdateOfferRateAction = ReturnType<typeof updateOfferRate>

export const updateClientRate = (payload: MonetaryAmount) => ({
  type: actionTypes.UPDATE_CLIENT_RATE,
  payload,
})
export type UpdateClientRateAction = ReturnType<typeof updateClientRate>

export const updateBookableIndividually = (payload: boolean) => ({
  type: actionTypes.UPDATE_BOOKABLE_INDIVIDUALLY,
  payload,
})
export type UpdateBookableIndividuallyAction = ReturnType<typeof updateBookableIndividually>

export const updateStartTime = (payload: any) => ({
  type: actionTypes.UPDATE_START_TIME,
  payload,
})
export type UpdateStartTimeAction = ReturnType<typeof updateStartTime>

export const updateFulfilmentReviewed = (payload: any) => ({
  type: actionTypes.UPDATE_FULFILMENT_REVIEWED,
  payload,
})
export type UpdateFulfilmentReviewedAction = ReturnType<typeof updateFulfilmentReviewed>

export const updateEndTime = (payload: any) => ({
  type: actionTypes.UPDATE_END_TIME,
  payload,
})
export type UpdateEndtimeAction = ReturnType<typeof updateEndTime>

export const updateStopOffers = (payload: string[]) => ({
  type: actionTypes.UPDATE_STOP_OFFERS,
  payload,
})
export type UpdateStopOffersAction = ReturnType<typeof updateStopOffers>

export const updateHardSkills = (payload: number[]) => ({
  type: actionTypes.UPDATE_HARD_SKILLS,
  payload,
})
export type updateHardSkillsAction = ReturnType<typeof updateHardSkills>

export const updateOfferToFlex = (payload: string) => ({
  type: actionTypes.UPDATE_OFFER_TO_FLEX,
  payload,
})
export type UpdateOfferToFlexAction = ReturnType<typeof updateOfferToFlex>

export type ShiftFulfilmentModalAction =
  | SetShiftAction
  | UpdateOfferRateAction
  | UpdateClientRateAction
  | UpdateOfferToFlexAction
  | UpdateBookableIndividuallyAction
  | updateHardSkillsAction
  | UpdateStopOffersAction
  | UpdateStartTimeAction
  | UpdateEndtimeAction
  | UpdateFulfilmentReviewedAction
