import React from 'react'
import PropTypes from 'prop-types'
import { get, isString } from 'lodash-es'

import { useCountrySpecificStructs } from 'syft-acp-core/components/CountrySpecific'
import TypeLabel from './TypeLabel'

const TypeLabels = ({ value, typesMap }) => {
  const countrySpecificTypes = useCountrySpecificStructs(typesMap || {})
  return (
    value &&
    Object.entries(isString(value) ? { [value]: true } : value).map(([key, val]) => {
      const typeData = get(countrySpecificTypes, key, {})
      const style = get(typeData, 'style')
      const color = get(typeData, 'color')
      const exclusive = get(typeData, 'exclusive')
      return (
        val &&
        (((style || color) && (
          <TypeLabel
            key={key}
            name={get(typeData, 'name')}
            message={get(typeData, 'message')}
            style={style}
            color={color}
          />
        )) ||
          (exclusive && <span>{get(typeData, 'name')}</span>))
      )
    })
  )
}
TypeLabels.propTypes = {
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  typesMap: PropTypes.object,
}

TypeLabels.defaultProps = {
  value: {},
  typesMap: {},
}

export default TypeLabels
