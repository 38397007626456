import {
  TOGGLE_SPOTLIGHT_SEARCH,
  TOGGLE_WORKER_SPOTLIGHT_SEARCH,
  TOGGLE_EMPLOYER_SPOTLIGHT_SEARCH,
} from './action-types'

interface SearchState {
  isSpotlightSearchOpen: boolean
  isWorkerSpotlightSearchOpen: boolean
  isEmployerSpotlightSearchOpen: boolean
}

type SearchAction = {
  type: string
  value: boolean
}

const initialState: SearchState = {
  isSpotlightSearchOpen: false,
  isWorkerSpotlightSearchOpen: false,
  isEmployerSpotlightSearchOpen: false,
}

export const reducer = (state: SearchState = initialState, action: SearchAction): SearchState => {
  switch (action.type) {
    case TOGGLE_SPOTLIGHT_SEARCH:
      return { ...state, isSpotlightSearchOpen: action.value }
    case TOGGLE_WORKER_SPOTLIGHT_SEARCH:
      return { ...state, isWorkerSpotlightSearchOpen: action.value }
    case TOGGLE_EMPLOYER_SPOTLIGHT_SEARCH:
      return { ...state, isEmployerSpotlightSearchOpen: action.value }
    default:
      return state
  }
}

export default reducer
