import { createSelector } from 'reselect'
import { RoleEntity } from 'syft-acp-core/store/types'

type State = {
  roles: { entityMap: Record<string, any> }
}

const selectRoles = (state: State) => state.roles

export const selectRolesOptions = createSelector(selectRoles, ({ entityMap = {} }) =>
  Object.values(entityMap)
    .map((role: RoleEntity) => ({ id: role.id, label: role.title }))
    .sort((a, b) => (a.label.toUpperCase() < b.label.toUpperCase() ? -1 : 1))
)
