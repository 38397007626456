import React from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { flowRight } from 'lodash-es'

import Acp from 'syft-acp-uikit'
import { createEntityListConnector } from 'syft-acp-util/entityList'
import useFetchEntity from 'syft-acp-util/hooks/useFetchEntity'
import { fetchWorkerExpenses } from 'syft-acp-core/store/workerExpenses/actions'

import { getExpenseType, getCompensation } from './WorkerExpenses.utils'

type EntityProps = ConnectedProps<typeof entityConnector>
type ConnectProps = ConnectedProps<typeof storeConnector>
type Props = {
  workerId: number
} & EntityProps &
  ConnectProps

const WorkerExpenses = ({ workerId, actions, query, data, isLoadingData }: Props) => {
  useFetchEntity({ ...query, workerID: workerId }, actions.fetchEntities)

  return (
    <Acp.EntityList
      data={data}
      isLoading={isLoadingData}
      isIntegrated
      idKeyValue="id"
      hasMinimumSize
      actionsInline
    >
      <Acp.Actions>
        <Acp.Header>Worker Expenses</Acp.Header>
      </Acp.Actions>
      <Acp.Table hasMinimumSize={5} maxRows={5}>
        <Acp.Col.Selector align="center" isMinimal colNoLink />
        <Acp.Col.Text value="id" header="ID" isMinimal isNumeric />
        <Acp.Col.Text value={getExpenseType} header="Type" />
        <Acp.Col.Text value="shift_id" header="Shift ID" />
        <Acp.Col.Text value="employer_name" header="Employer" />
        <Acp.Col.Text value="venue_name" header="Venue" />
        <Acp.Col.DateTime value="date_incurred" header="Date" />
        <Acp.Col.Money value="bonus_amount" header="Amount" align="left" />
        <Acp.Col.Text value={getCompensation} header="Compensation" />
        <Acp.Col.DateTime value="created_at" header="Date Added" />
        <Acp.Col.Text value="created_by" header="Added By" />
      </Acp.Table>
    </Acp.EntityList>
  )
}

const entityConnector = createEntityListConnector({
  entityActions: { fetchEntities: fetchWorkerExpenses },
  paginationKey: 'workerExpensesPage',
  entityStore: 'workerExpenses',
})

const storeConnector = connect()

export default flowRight(entityConnector, storeConnector)(WorkerExpenses)
