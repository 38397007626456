import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import SubNavigation from 'syft-acp-core/components/Navigation/SubNavigation'

import { fetchAgencyProfile } from 'syft-acp-core/actions/agency-profile'

type AgencySubNavigationProps = {
  agencyID: number
}

export const AgencySubNavigation = ({ agencyID }: AgencySubNavigationProps) => {
  const dispatch = useDispatch()
  const agencyName = useSelector(state => state?.agencyProfile?.entityDetail[agencyID]?.name)

  useEffect(() => {
    if (!agencyName) {
      dispatch(fetchAgencyProfile({ agencyId: agencyID }))
    }
  }, [agencyID, agencyName, dispatch])

  return <SubNavigation subPage="agencies" data={{ agencyID, agencyName }} data-testid="agency-sub-nav" />
}
