import React, { useCallback, useMemo } from 'react'
import { Grid, Row, Col } from 'react-bootstrap'
import { Button } from 'syft-acp-atoms/Button'

import DataDropdown from './DataDropdown'
import { filterSelectedFromOptions } from './dataAgenciesDropdownHelpers'

import { DataAgenciesDropdownItemProps } from './DataAgenciesDropdown.types'

const DataAgenciesDropdownItem = ({
  agency, options, values, header, deletable, editable, disabled, onChange, onDeleteClick
}: DataAgenciesDropdownItemProps) => {
  const filteredOptions = useMemo(() => filterSelectedFromOptions({
    options: options,
    values,
    selected: agency.agency_id
  }), [options, values, agency])

  const handleChange = useCallback((value) => onChange(value, agency.rank, agency.id), [agency.rank, agency.id, onChange])
  const handleDelete = useCallback(() => onDeleteClick(agency.rank), [agency.rank, onDeleteClick])

  return (
    <Grid key={agency.rank}>
      <Row className="dropdown-row">
        <Col md={1}># {agency.rank}</Col>
        <Col md={deletable ? 5 : 8}>
          <DataDropdown
            onChange={handleChange}
            options={filteredOptions}
            header={header}
            value={agency.agency_id}
            editable={editable}
            disabled={disabled}
          />
        </Col>
        {deletable && (
          <Col md={3}>
            <Button
              kind="danger"
              onClick={handleDelete}
              disabled={!agency.agency_id && !values?.length}
            >
              Delete agency rank
            </Button>
          </Col>
        )}
      </Row>
    </Grid>
  )
}

export default DataAgenciesDropdownItem
