import { omit } from 'lodash-es'

import * as entityTypes from 'syft-acp-core/reducers/generators/entities'
import entityReducer from 'syft-acp-core/reducers/generators/entityReducer'

import * as types from '../action-types'
import { ListingShiftEntity, ListingShiftsState } from './types'

export default entityReducer<ListingShiftEntity>(
  'listingShifts',
  {
    [entityTypes.ENTITY_FETCH_BEGIN]: types.ADMIN_LISTING_SHIFT_FETCH_BEGIN,
    [entityTypes.ENTITY_FETCH_SUCCEEDED]: types.ADMIN_LISTING_SHIFT_FETCH_SUCCEEDED,
    [entityTypes.ENTITY_FETCH_FAILED]: types.ADMIN_LISTING_SHIFT_FETCH_FAILED,
    [entityTypes.ENTITY_SAVE_BEGIN]: types.LISTING_EDIT_SHIFT_BEGIN,
    [entityTypes.ENTITY_SAVE_SUCCEEDED]: types.LISTING_EDIT_SHIFT_SUCCEEDED,
    [entityTypes.ENTITY_SAVE_FAILED]: types.LISTING_EDIT_SHIFT_FAILED,
    [entityTypes.ENTITY_DELETE_FAILED]: types.SHIFT_DELETE_FAILED,
    [entityTypes.ENTITY_DELETE_BEGIN]: types.SHIFT_DELETE_BEGIN,
    [types.SHIFT_DELETE_SUCCEEDED]: (state: ListingShiftsState, action: Record<string, any>) => {
      // Remove shiftID element from the entityDetail object
      omit(state.entityDetail, action.request.shiftID)
      return state
    },
    [types.SHIFT_STOP_NEW_WORKERS_BEGIN]: (state: ListingShiftsState) => ({
      ...state,
      isLoadingData: true,
    }),
    [types.SHIFT_STOP_NEW_WORKERS_SUCCEEDED]: (state: ListingShiftsState, action: Record<string, any>) => ({
      ...state,
      isLoadingData: false,
      entityDetail: {
        ...state.entityDetail,
        [action.request.shiftID]: {
          ...state.entityDetail[action.request.shiftID],
          stop_new_workers: !action.request.payload.resume,
          stop_offers_for_worker_type: action.request.payload.stop_offers_for_worker_type,
        },
      },
    }),
    [types.LISTING_EDIT_SHIFT_STOP_OFFERS]: (state: ListingShiftsState, action: Record<string, any>) => {
      return {
        ...state,
        isLoadingData: false,
        entityDetail: {
          ...state.entityDetail,
          [action.shiftID]: {
            ...state.entityDetail[action.shiftID],
            stop_new_workers: !!action.stopOffersForWorkerType.length,
            stop_offers_for_worker_type: action.stopOffersForWorkerType,
          },
        },
      }
    },

    [types.SHIFT_STOP_NEW_WORKERS_FAILED]: (state: ListingShiftsState) => ({
      ...state,
      isLoadingData: false,
    }),
  },
  {
    idKey: 'id',
    localEntityMap: false,
    groupBy: ['shiftID'],
  },
)
