// Syft ACP - Lib <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import { getSupportedTimezones, getTimezoneInfo } from 'syft-acp-util/time/timezone'
import { filterSelect } from './FilterSelect'

/**
 * Formats a timezone as e.g.: Europe/London - Greenwich Mean Time (GMT, +00:00)
 */
const formatTz = tz => {
  const { timezone, abbr, long, offset } = getTimezoneInfo(tz)
  return `${timezone} - ${long} (${abbr}, ${offset})`
}

// Create a list of id / label pairs.
const timezonesList = getSupportedTimezones().map(tz => ({ id: tz, label: formatTz(tz) }))

export default filterSelect(timezonesList, 'Select a timezone', 'id', false)
