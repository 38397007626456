// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import React from 'react'
import PropTypes from 'prop-types'

import LoadingIndicator from 'syft-acp-util/components/LoadingIndicator'
import UserAvatar from 'syft-acp-core/components/UserAvatar'
import RecordSegment from './RecordSegment'

import './RecordName.css'

const RecordName = props => (
  <RecordSegment noPadding className="record-name-segment">
    <div className={`RecordName ${props.avatar ? 'has-avatar' : ''}`}>
      <div className="content-wrapper">
        <div>
          {props.avatar && (
            <UserAvatar
              verified={props.verified}
              avatar={props.avatar}
              shape="rounded-square"
              size={props.avatarSize}
              name={props.name}
            />
          )}
          {props.tempData ? (
            <div className="user-main-info">
              <h2 className="user-email">&nbsp;</h2>
              <p className="user-data-bio">&nbsp;</p>
              <div className="loading-container">
                <LoadingIndicator loadingState />
              </div>
            </div>
          ) : (
            <div className="user-main-info">
              <h2 className="user-email">{props.name} </h2>
              <p className="user-data-bio">{(props.bio && props.bio.trim()) || <span>&nbsp;</span>}</p>
              {props.extraContent ? <div className="user-data">{props.extraContent}</div> : null}
            </div>
          )}
        </div>
        {props.children}
      </div>
    </div>
  </RecordSegment>
)
RecordName.propTypes = {
  name: PropTypes.string,
  avatar: PropTypes.string,
  bio: PropTypes.string,
  tempData: PropTypes.bool,
  verified: PropTypes.bool,
  avatarSize: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  extraContent: PropTypes.node,
  children: PropTypes.node,
}
RecordName.defaultProps = {
  name: '',
  bio: '',
  tempData: false,
  verified: false,
  avatarSize: 'medium',
  avatar: null,
  extraContent: null,
}

export default RecordName
