import React from 'react'
import PropTypes from 'prop-types'
import { Label } from 'react-bootstrap'
import ReactTooltip from 'react-tooltip'

import './TypeLabel.css'

/**
 * A single "tag" or label for e.g. workers or listings.
 */
class TypeLabel extends React.PureComponent {
  static propTypes = {
    name: PropTypes.string,
    message: PropTypes.string,
    style: PropTypes.string,
    color: PropTypes.string,
  }

  static defaultProps = {
    name: null,
    message: null,
    style: null,
    color: null,
  }

  render() {
    const { name, message, color, style = 'default' } = this.props
    const labelStyle = color ? { backgroundColor: color } : {}
    const id = `type-label-${name}`
    const tooltipProps = message
      ? {
          'data-for': id,
          'data-tip': message,
        }
      : {}

    return (
      <>
        {message && <ReactTooltip id={id} />}
        <span data-testid="type-label" className="type-label" {...tooltipProps}>
          <Label bsStyle={style} className={`label-${style}`} style={labelStyle}>
            {name}
          </Label>
        </span>
      </>
    )
  }
}

export default TypeLabel
