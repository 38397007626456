import { apiRequest, handleCall } from 'syft-acp-core/api/call'
import * as actions from './actions'
import type { ShiftEventsAction } from './actions'

const fetchEventsBase = ({ payload: reqArgs }: ShiftEventsAction, method = 'GET') =>
  new Promise((resolve, reject) => {
    const req = apiRequest({ path: `/admin/shifts/${reqArgs.id}/events`, reqArgs, method })
    handleCall(req, resolve, reject, true, reqArgs)
  })

export const fetchShiftEvents = (action: ReturnType<typeof actions.fetchShiftEvents>) => fetchEventsBase(action)
