import { generateEntityCrudReducer } from 'syft-acp-util/store/reducers'
import allTypes, { usersTypes } from './action-types'
import * as entityTypes from 'syft-acp-core/reducers/generators/entities'

import { FetchUserBanReasonsSucceededAction } from './actions'
import { UsersEntity, UsersState, UsersReducerType } from './types'

export default generateEntityCrudReducer<UsersEntity>({
  name: 'users',
  actions: usersTypes,
  extraActions: {
    [entityTypes.ENTITY_FETCH_BEGIN]: allTypes.USER_FETCH_BEGIN,
    [entityTypes.ENTITY_FETCH_SUCCEEDED]: allTypes.USER_FETCH_SUCCEEDED,
    [entityTypes.ENTITY_FETCH_FAILED]: allTypes.USER_FETCH_FAILED,
    [entityTypes.ENTITY_MODIFY_BEGIN]: allTypes.USER_PATCH_BEGIN,
    [entityTypes.ENTITY_MODIFY_SUCCEEDED]: allTypes.USER_PATCH_SUCCEEDED,
    [entityTypes.ENTITY_MODIFY_FAILED]: allTypes.USER_PATCH_FAILED,
    [allTypes.USER_BAN_REASONS_FETCH_SUCCEEDED]: (state: UsersState, action: FetchUserBanReasonsSucceededAction) => ({
      ...state,
      userBanReasons: action.payload,
    }),
  },
}) as UsersReducerType
