import * as types from './action-types'

export const fetchAdminConversations = ({ options = {} }) => ({
  type: types.ADMIN_CONVERSATIONS_FETCH_BEGIN,
  options,
})

export const fetchAdminConversation = id => ({
  type: types.ADMIN_CONVERSATION_FETCH_BEGIN,
  id,
})

export const fetchAdminConversationMessages = (id, options) => ({
  type: types.ADMIN_CONVERSATION_MESSAGES_FETCH_BEGIN,
  id,
  options,
})
