// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import React from 'react'

import PageView from 'syft-acp-core/components/PageView'
import ShiftSyftList from 'syft-acp-core/entities/ShiftList'

export const AllShifts = () => (
  <PageView title={['Shifts', 'All shifts']} entityPage>
    <ShiftSyftList allShifts />
  </PageView>
)
