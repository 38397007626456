import { UpdateWorkerMarketingPreferencesPayload, WorkerMarketingPreferences } from './types'

export const enum MapPreferenceToDescription {
  marketing_emails_consent = 'You (the worker) must consent to receive marketing emails from Indeed Flex at the email provided in my profile. Agreement is not required to obtain products and services.',
  marketing_calls_consent = 'You (the worker) must consent to receive marketing calls and texts from Indeed Flex at the number provided in my profile. Agreement is not required to obtain products and services',
}

export const MapPreferenceToPayload = {
  marketing_emails_consent: {
    field_value: 'email_consented',
    field_description: 'email_description',
    description_value: MapPreferenceToDescription.marketing_emails_consent,
  },
  marketing_calls_consent: {
    field_value: 'phone_consented',
    field_description: 'phone_description',
    description_value: MapPreferenceToDescription.marketing_calls_consent,
  },
}

export const transformPreferenceToPayload = (
  preference: WorkerMarketingPreferences,
  consented: boolean,
): Partial<UpdateWorkerMarketingPreferencesPayload> => {
  const payloadConfig = MapPreferenceToPayload[preference]

  return {
    [payloadConfig.field_value]: consented,
    [payloadConfig.field_description]: consented ? payloadConfig.description_value : '',
  }
}
