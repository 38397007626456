import { apiRequest, handleCall } from 'syft-acp-core/api/call'

import { ImportFileRequestInput } from './types'

/**
 * Sends an updated data object to the server, overwriting that entity data.
 */
export const importFile = ({ file, path }: ImportFileRequestInput) =>
  new Promise((resolve, reject) => {
    const reqArgs = new FormData()
    reqArgs.append('file', file)
    const req = apiRequest({
      path: '/admin/imports' + path,
      reqArgs,
      method: 'POST',
      utilizePagination: false,
    })
    handleCall(req, resolve, reject)
  })
