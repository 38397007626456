// Syft ACP - Lib <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import React from 'react'
import octicons from 'octicons'
import classnames from 'classnames'

// eslint-disable-next-line react/prop-types
const iconFactory =
  (icon, label = 'Icon') =>
  ({ iconSize = 12, className = '' }) => {
    return (
      <div
        className={classnames('icon', className)}
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{
          __html: octicons[icon].toSVG({
            'aria-label': label,
            width: iconSize,
            class: 'img',
          }),
        }}
      />
    )
  }

export default iconFactory
