import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { noop } from 'lodash-es'

import { Button } from 'syft-acp-atoms/Button'

import './AcpNotifierInline.css'

export default class AcpNotifierInline extends React.PureComponent {
  static propTypes = {
    messageError: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
    messageRetry: PropTypes.string,
    hideReloadIfNull: PropTypes.bool,
    hasError: PropTypes.bool,
    reloadLastCall: PropTypes.func,
  }

  static defaultProps = {
    messageRetry: 'Retry request',
    hideReloadIfNull: false,
    hasError: false,
    reloadLastCall: null,
  }

  render() {
    const { messageError, messageRetry, hideReloadIfNull, reloadLastCall } = this.props
    const hasReload = reloadLastCall != null
    return (
      <div className={classNames('acp-notifier-inline')}>
        <div className="inner">
          <div className="section message">{messageError}</div>
          {!hideReloadIfNull && !hasReload && (
            <div className="section retry">
              <Button disabled={!hasReload} onClick={hasReload ? reloadLastCall : noop} kind="regular">
                {messageRetry}
              </Button>
            </div>
          )}
        </div>
      </div>
    )
  }
}
