import { Query, Venue } from './types'

const noVenueOptions: null[] = []

export const getInitialValues = (query: Query) => {
  const initialValuesString = query?.venue_ids ? [{ value: query?.venue_ids, label: query?.venue_ids }] : []
  return Array.isArray(query?.venue_ids)
    ? query?.venue_ids?.map((item: string) => ({ value: item, label: item }))
    : initialValuesString
}

export const getVenueOptions = (venueName: string, venues: Venue[]) =>
  venueName !== '' ? venues?.map(venue => ({ value: venue.id, label: [venue.company_name, venue.name].filter(item => item).join(' - ') })) : noVenueOptions
