// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import React from 'react'
import { formatCurrency, formatFullName } from 'syft-acp-util/formatting'

import entityList from 'syft-acp-util/entityList'
import * as tableType from 'syft-acp-core/components/EntityList/'
import { FilterGroup, FilterText, FilterDateRange } from 'syft-acp-util/components/FilterForm'
import * as strikesActions from 'syft-acp-core/actions/strikes'
import EntityListComponent from 'syft-acp-core/components/EntityList/EntityListComponent'

const formatMinimumRate = aux => (aux ? formatCurrency(aux.amount, aux.currency) : '–')

const tableFormat = [
  { type: tableType.SELECTOR },
  { type: tableType.TXT, val: 'id', header: 'ID', numeric: true },
  {
    type: tableType.TXT,
    classes: ['nowrap'],
    val: e => formatFullName(e),
    valHints: ['first_name', 'last_name'],
    header: 'Name',
  },
  { type: tableType.TXT, classes: ['nowrap'], val: 'active_strikes_count', header: 'Active strikes' },
  {
    type: tableType.TXT,
    classes: ['nowrap'],
    val: 'expired_strikes_count',
    header: 'Expired strikes',
    expl: 'Strikes ≥90 days old',
  },
  { type: tableType.TXT, classes: ['nowrap'], val: 'completed_shifts_count', header: 'Completed shifts' },
  { type: tableType.DATETIME, classes: ['nowrap'], val: 'last_strike_date', header: 'Last strike' },
  { type: tableType.PHONE_NUMBER, defaultValue: '–', val: 'telephone_number', header: 'Tel. number' },
  { type: tableType.TXT, val: 'minimum_rate', header: 'Min. rate', format: formatMinimumRate },
]

const actionFormat = []

const filters = (
  <div>
    <FilterGroup title="Worker name">
      <FilterText name="worker_name" placeholder="Name" />
    </FilterGroup>
    <FilterGroup title="Active strikes">
      <FilterText name="active_strikes_count_gte" placeholder="≥" small type="number" />
      <FilterText name="active_strikes_count_lte" placeholder="≤" small type="number" />
    </FilterGroup>
    <FilterGroup title="Last strike date">
      <FilterDateRange nameFrom="last_strike_date_gte" nameTo="last_strike_date_lte" />
    </FilterGroup>
    {
      // TODO: uncomment when API is ready.
      /* <FilterGroup title="Filter by city">
      <FilterSelectCities name="city_id" allowAny />
    </FilterGroup> */
    }
  </div>
)

// Generates URLs that link to the workers.
const urlGenerator = rowData => `/entity/workers/view/${rowData.id}`

export default entityList(
  EntityListComponent,
  tableFormat,
  filters,
  actionFormat,
  'strikes',
  {
    fetchEntities: strikesActions.fetchStrikes,
  },
  {
    showResultCount: true,
    urlGenerator,
  }
)
