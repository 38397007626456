// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import ReactTooltip from 'react-tooltip'
import classnames from 'classnames'
import { noop, get } from 'lodash-es'

import Table from 'syft-acp-util/components/Table'
import EntityListWrapper from './EntityListWrapper'
import EntityListHeader from './EntityListHeader'
import EntityListFeedback from './EntityListFeedback'
import EntityListFooter from './EntityListFooter'
import EntityListRow from './EntityListRow'
import tableFormatPropTypes from './tableFormatPropTypes'

import './EntityList.css'

/**
 * Entity list table component. This is a pure component that takes a table format
 * and a list of data, and then constructs a table out of it. It's a reusable component
 * that's used for e.g. the UserList, WorkerList and EmployerList.
 *
 * @param {Object} props Properties
 * @returns {XML} Pure EntityListTable component
 */
class EntityList extends PureComponent {
  static propTypes = {
    entityList: PropTypes.arrayOf(PropTypes.object).isRequired,
    entityType: PropTypes.string.isRequired,
    footer: PropTypes.node,
    hasFooter: PropTypes.bool,
    header: PropTypes.node,
    hasLinks: PropTypes.bool,
    // The idKey is used for generating links.
    idKey: PropTypes.string,
    // Function that filters the entity list's data before it renders the rows.
    rowDataFilter: PropTypes.func,
    isLoadingData: PropTypes.bool.isRequired,
    message: PropTypes.string.isRequired,
    onSelect: PropTypes.func,
    refetchData: PropTypes.func,
    rowClass: PropTypes.func,
    editCallback: PropTypes.func,
    selectCallback: PropTypes.func,
    selectedItems: PropTypes.array,
    selectedRow: PropTypes.shape({ key: PropTypes.string, value: PropTypes.any }),
    selectFullRow: PropTypes.bool,
    selectSingleRow: PropTypes.bool,
    tableFormat: tableFormatPropTypes,
    title: PropTypes.string,
    type: PropTypes.string,
    urlBase: PropTypes.string,
    urlEntity: PropTypes.bool,
    urlGenerator: PropTypes.func,
  }

  static defaultProps = {
    editCallback: noop,
    footer: null,
    hasFooter: false,
    header: null,
    hasLinks: true,
    idKey: 'id',
    onSelect: undefined,
    refetchData: undefined,
    rowClass: undefined,
    rowDataFilter: undefined,
    selectCallback: undefined,
    selectFullRow: undefined,
    selectSingleRow: false,
    selectedItems: [],
    selectedRow: undefined,
    tableFormat: [],
    title: null,
    type: '',
    urlBase: null,
    urlEntity: true,
    urlGenerator: undefined,
    noOverflow: false,
  }

  /**
   * Returns the classes we want on the entity list table.
   */
  tableAttributes = () => {
    switch (this.props.type) {
      case 'record':
        return { className: 'entity-list' }
      default:
        return { bordered: true, rounded: true, className: 'entity-list' }
    }
  }

  // Either display the regular list body, if we have data.
  // If not, display the feedback component to tell the user something is wrong.
  getListContent = (urlBase, hasLinks) => {
    if (this.props.entityList.length === 0) {
      // If we don't have any items, return a placeholder.
      return (
        <EntityListFeedback
          cols={this.props.tableFormat.length}
          isLoadingData={this.props.isLoadingData}
          refetchData={this.props.refetchData}
          status={this.props.isLoadingData ? 'Loading data...' : 'No items found.'}
          feedback={this.props.message ? this.props.message : ''}
        />
      )
    } else {
      // Return all table rows.
      let listData = this.props.entityList
      if (this.props.rowDataFilter) {
        listData = listData.filter(this.props.rowDataFilter)
      }
      return listData.map((item, n) => (
        <EntityListRow
          data={item}
          entityType={this.props.entityType}
          hasLinks={hasLinks}
          idKey={this.props.idKey}
          key={get(item, this.props.idKey) || String(n)}
          rowNumber={n}
          editCallback={this.props.editCallback}
          rowClasses={this.props.rowClasses}
          selectCallback={this.props.selectCallback}
          selectedItems={this.props.selectedItems}
          selectedRow={this.props.selectedRow}
          selectFullRow={this.props.selectFullRow}
          tableFormat={this.props.tableFormat}
          urlBase={urlBase}
          urlGenerator={this.props.urlGenerator}
        />
      ))
    }
  }

  render() {
    // FIXME: turning off jsx-indent rule for eslint, because it generates
    // a false positive for the ternary syntax.
    /* eslint react/jsx-indent: 0 */
    // Either pass on the URL base given to us, or construct a default one based on entity type.
    const urlBase = this.props.urlBase
      ? this.props.urlBase
      : `/${this.props.urlEntity ? 'entity/' : ''}${this.props.entityType}/view/`
    const listContent = this.getListContent(urlBase, this.props.hasLinks)

    return (
      <EntityListWrapper
        type={this.props.type}
        className={classnames('entity-list-wrapper', {
          'has-footer': this.props.footer || this.props.hasFooter,
          'no-footer': !this.props.footer && !this.props.hasFooter,
          'has-links': this.props.hasLinks,
          'no-links': !this.props.hasLinks,
          'no-overflow': this.props.noOverflow,
        })}
      >
        {this.props.header}
        {/* Displays a tooltip for header cells that have specified one. */}
        <ReactTooltip effect="solid" id={`tooltip-${this.props.entityType}`} />
        <Table colBorders {...this.tableAttributes()}>
          {/* The header row. */}
          <EntityListHeader
            title={this.props.title}
            selectSingleRow={this.props.selectSingleRow}
            tableFormat={this.props.tableFormat}
            entityType={this.props.entityType}
            entityList={this.props.entityList}
          />
          {/* The actual table data (either a placeholder, or the rows) goes here. */}
          {listContent}
          {/* Footer component, if specified. */}
          {this.props.footer && (
            <EntityListFooter tableSize={this.props.tableFormat.length} footerContent={this.props.footer} />
          )}
        </Table>
      </EntityListWrapper>
    )
  }
}

export default EntityList
