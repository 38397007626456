// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { isArray } from 'lodash-es'
import classnames from 'classnames'

import DatePicker from 'syft-acp-atoms/DatePicker'
import { Button } from 'syft-acp-atoms/Button'

import './DataTermDates.css'

// Empty item used as placeholder.
const emptyItem = { to_date: null, from_date: null }

class DataTermDates extends PureComponent {
  static propTypes = {
    // eslint-disable-next-line react/no-unused-prop-types
    value: PropTypes.array,
    editable: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
  }

  static defaultProps = {
    value: null,
    editable: false,
    disabled: false,
  }

  constructor(props) {
    super(props)
    this.state = {}
  }

  static getDerivedStateFromProps(nextProps) {
    const hasValidValue = isArray(nextProps.value) && nextProps.value.length > 0
    return { value: hasValidValue ? nextProps.value : [emptyItem] }
  }

  /**
   * Constructs the new value for the current date pair and sends it back.
   */
  sendChange = (field, direction) => (_name, date) => {
    const { value } = this.state
    const oldItem = value[field].to_date != null || value[field].from_date != null ? value[field] : emptyItem
    const newItem = { ...oldItem, [direction]: date }

    this.props.onChange([...value.slice(0, field), newItem, ...value.slice(field + 1, value.length)])
  }

  addItem = () => {
    this.props.onChange([...this.state.value, emptyItem])
  }

  removeItem = (field, undo) => () => {
    const { value } = this.state
    const oldItem = value[field]
    const removeLocally = oldItem.to_date == null || oldItem.from_date == null
    if (removeLocally) {
      this.props.onChange([...value.slice(0, field), ...value.slice(field + 1, value.length)])
    } else {
      this.props.onChange([
        ...value.slice(0, field),
        { ...oldItem, _destroy: !undo },
        ...value.slice(field + 1, value.length),
      ])
    }
  }

  /**
   * Renders a single FormControl for either the 'from_date' or 'to_date' date.
   */
  renderFormControl = (val, n, disabled, direction) => (
    <DatePicker
      value={val}
      onChange={this.sendChange(n, direction)}
      className={classnames('boxed-input', 'subitem', `n-${n}`)}
      disabled={disabled}
    />
  )

  render() {
    const { editable, disabled = false } = this.props
    const value = this.state.value ? this.state.value : [{ to_date: null, from_date: null }]
    return (
      <div className="DataTermDates">
        {value.map((val, n) => {
          const willBeDestroyed = val._destroy === true
          const fullClassName = classnames('item', { 'will-destroy': willBeDestroyed })
          return (
            <div
              key={`${val.from_date}$${val.to_date}$${willBeDestroyed ? '_' : ''}$${n}`}
              className={fullClassName}
            >
              <div className="n">#{n + 1}</div>
              {this.renderFormControl(val.from_date, n, disabled && editable, 'from_date')}
              <div className="arrow">⇥</div>
              {this.renderFormControl(val.to_date, n, disabled && editable, 'to_date')}
              {willBeDestroyed && (
                <Button
                  kind="success"
                  className="toggle"
                  onClick={this.removeItem(n, true)}
                  disabled={disabled && editable}
                >
                  ＋
                </Button>
              )}
              {!willBeDestroyed && (
                <Button
                  kind="danger"
                  className="toggle"
                  onClick={this.removeItem(n, false)}
                  disabled={disabled && editable}
                >
                  －
                </Button>
              )}
            </div>
          )
        })}
        <Button onClick={this.addItem} disabled={disabled && editable}>
          Add new
        </Button>
        {disabled && (
          <div className="notice">
            The term dates can be set only when course start and end dates have been entered.
          </div>
        )}
      </div>
    )
  }
}

export default DataTermDates
