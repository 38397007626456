// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import * as types from './action-types'

export type FilterOptions = {
  includeEmpty: boolean
}

export function setFilter(
  name: string,
  value: string | string[] | null | undefined,
  filterIndex?: string | number,
  filterOptions?: FilterOptions,
) {
  return { type: types.FILTER_SET_BEGIN, name, value, filterIndex, meta: { filterOptions } }
}

export function resetFilters(filtersToKeep: Record<string, string | null>, filterIndex?: number) {
  return { type: types.FILTER_RESET_BEGIN, filtersToKeep, filterIndex }
}

export function setPage({
  page,
  pageKey,
  disableAutoScroll,
  filterOptions,
}: {
  page: string
  pageKey: string
  disableAutoScroll?: boolean
  filterOptions?: FilterOptions
}) {
  return { type: types.PAGE_SET_BEGIN, page, pageKey, disableAutoScroll, meta: { filterOptions } }
}
