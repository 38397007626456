import { takeLatest } from 'redux-saga/effects'
import {
  ADMIN_TIMESHEETS_BULK_EDIT_BEGIN,
  ADMIN_TIMESHEETS_BULK_EDIT_FAILED,
  ADMIN_TIMESHEETS_BULK_EDIT_SUCCEEDED,
  ADMIN_TIMESHEETS_EDIT_ALL_BEGIN,
} from 'syft-acp-core/actions/action-types'
import { notificationCall } from 'syft-acp-core/sagas/calls'
import * as api from 'syft-acp-core/api/resources/admin/timesheets'
import { takeLatestApiCall } from './helpers'

export default function* root() {
  yield takeLatestApiCall(ADMIN_TIMESHEETS_BULK_EDIT_BEGIN, api.bulkEditAdminTimesheets)
  yield takeLatestApiCall(ADMIN_TIMESHEETS_EDIT_ALL_BEGIN, api.editAllAdminTimesheets)
  yield takeLatest(
    [ADMIN_TIMESHEETS_BULK_EDIT_FAILED, ADMIN_TIMESHEETS_BULK_EDIT_SUCCEEDED],
    notificationCall,
  )
}
