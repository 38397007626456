// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import * as React from 'karet'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { ButtonDropdown, MenuItem } from 'syft-acp-atoms/Button'

import { actionPropTypes, actionDefaultProps } from './props'
import { checkSelection, checkDisabled, decorateOnClick } from './selection'

import './ButtonDropdown.scss'

const AcpMenuItem = props => {
  return <MenuItem {...props} className={classnames('acp-menu-item', props.color ? `color-${props.color}` : null)} />
}

AcpMenuItem.propTypes = {
  color: PropTypes.string,
}
AcpMenuItem.defaultProps = {
  color: null,
}

/**
 * A dropdown menu button containing a menu.
 *
 * Use as follows:
 *
 *    <Acp.ButtonDropdown kind="primary" title="Actions">
 *      <Acp.Option to="/dashboard">Link to dashboard</Acp.Option>
 *      <Acp.Option onClick={ this.doSomething }>Do something</Acp.Option>
 *    </Acp.ButtonDropdown>
 *
 * Use 'requiresSelection' or 'requiresSubSelection' to indicate that items
 * need to be selected in order to use the dropdown menu.
 *
 * The 'onClick' callbacks will be called with the table's current state
 * and selections.
 *
 * See <src/core/entities2/ComponentTest/Selection.js> for an example.
 */

const AcpButtonDropdown = ({
  children,
  kind,
  id,
  title,
  disabled,
  subDataSpec,
  requiresSelection,
  requiresSubSelection,
  componentArgs,
  callbackArgs,
}) => {
  const { tableData, stateSelection, stateSubSelection } = componentArgs
  const { disabledForSelection, disabledForSubSelection } = checkSelection(
    stateSelection,
    stateSubSelection,
    requiresSelection,
    requiresSubSelection
  )

  const childItems = React.Children.map(children, (el, n) => {
    const onClickFn = decorateOnClick(null, el, callbackArgs, subDataSpec, tableData, stateSelection, stateSubSelection)
    return <AcpMenuItem key={n} {...el.props} onClick={onClickFn} />
  })

  return (
    <ButtonDropdown
      pullRight
      kind={kind}
      title={title}
      disabled={checkDisabled(disabled, disabledForSelection, disabledForSubSelection)}
      id={id || title}
    >
      {childItems}
    </ButtonDropdown>
  )
}
AcpButtonDropdown.propTypes = {
  ...actionPropTypes,
  title: PropTypes.string.isRequired,
}
AcpButtonDropdown.defaultProps = actionDefaultProps

export default AcpButtonDropdown
