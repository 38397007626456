export const trackingEvents = {
  FLEX_SHIFTS: {
    PAGE: {
      LOADED: 'flex_shifts.page.loaded',
    },
    SHIFT: {
      SELECTED: 'flex_shifts.shift.selected',
    },
    FILTERS: {
      CHANGED: 'flex_shifts.filters.changed',
    },
    MARK_WORKERS_DROP_DOWN: {
      CHANGED: 'flex_shifts.mark_workers_drop_down.changed',
    },
  },
  ALL_SHIFTS: {
    PAGE: {
      LOADED: 'all_shifts.page.loaded',
    },
    FILTERS: {
      CHANGED: 'all_shifts.filters.changed',
    },
    SHIFT: {
      SELECTED: 'all_shifts.shift.selected',
    },
    MARK_WORKERS_DROP_DOWN: {
      CHANGED: 'all_shifts.mark_workers_drop_down.changed',
    },
  },
}
