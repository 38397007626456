// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import React from 'react'
import { fetchEmployers } from 'syft-acp-core/store/employers/actions'
import * as tableType from 'syft-acp-core/components/EntityList/'
import EntityListComponent from 'syft-acp-core/components/EntityList/EntityListComponent'
import { FilterDateRange, FilterEnum, FilterGroup } from 'syft-acp-util/components/FilterForm'
import entityList from 'syft-acp-util/entityList'

const tableFormat = [
  { type: tableType.SELECTOR },
  { type: tableType.TXT, val: 'id', header: 'ID', numeric: true },
  {
    type: tableType.TXT,
    classes: ['nowrap'],
    val: 'company_name',
    header: 'Employer',
  },
  { type: tableType.TXT, classes: ['main'], val: 'tmp', header: 'Account manager' },
  { type: tableType.TXT, numeric: true, val: 'tmp', header: 'Tier' },
  {
    type: tableType.DATETIME,
    classes: ['nowrap'],
    val: 'tmp',
    header: 'Active since',
  },
  {
    type: tableType.DURATION,
    classes: ['nowrap'],
    val: 'tmp',
    header: 'Time to conversion',
    expl: 'Amount of time between verification of the employer and their first completed shift',
  },
]

const tierOptions = [
  [{ value: '', label: 'All' }],
  [
    { value: '1', label: '1' },
    { value: '2', label: '2' },
    { value: '3', label: '3' },
    { value: '4', label: '4' },
  ],
]

const filters = (
  <div>
    <FilterGroup title="Filter by date">
      <FilterDateRange nameFrom="last_active_gte" nameTo="last_active_lte" />
    </FilterGroup>
    <FilterGroup title="Filter by tier">
      <FilterEnum name="tier" options={tierOptions} />
    </FilterGroup>
  </div>
)

const actionFormat = []

export default entityList(
  EntityListComponent,
  tableFormat,
  filters,
  actionFormat,
  'employers',
  {
    fetchEntities: fetchEmployers,
  },
  {
    showResultCount: true,
  }
)
