import React, { useEffect } from 'react'
import { TrackingTrigger } from '@indeed/flex-tracking-context'
import { connect } from 'react-redux'
import { AdhocShiftActionProps as Props } from './AdhocShiftActions.types'
import { AdhocShiftActionsTrackingProvider } from 'syft-acp-core/entities2/AdhocShifts/AdhocShiftActionsTrackingProvider'
import { trackingEvents } from './AdhocShiftActions.tracking'
import AdhocShiftActionControls from 'syft-acp-core/entities2/AdhocShifts/AdhocShiftActionControls'
import { bindActionCreators } from 'redux'
import * as adhocShiftApprovalActions from 'syft-acp-core/store/adhocShiftBookings/actions'
import entityDetail from 'syft-acp-util/entityDetail'

export const storeConnector = connect(null, dispatch => ({
  actions: bindActionCreators(adhocShiftApprovalActions, dispatch),
}))

const AdhocShiftActions = ({ shiftBookingId, actions, data }: Props) => {
  useEffect(() => {
    actions.fetchAdhocShiftStatus(shiftBookingId)
  }, [actions, shiftBookingId])

  return (
    <AdhocShiftActionsTrackingProvider adhocShiftBookingId={shiftBookingId}>
      <AdhocShiftActionControls shiftBookingId={shiftBookingId} data={data} />
      <TrackingTrigger onLoad={{ event: trackingEvents.PAGE.VIEWED }} />
    </AdhocShiftActionsTrackingProvider>
  )
}

export default storeConnector(entityDetail('adhocShiftsApproval', AdhocShiftActions))
