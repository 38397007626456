// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import * as types from 'syft-acp-core/actions/action-types'
import * as entityTypes from './generators/entities'
import entityReducer from './generators/entityReducer'

export default entityReducer('employerVenueNames', {
  [entityTypes.ENTITIES_FETCH_BEGIN]: types.EMPLOYER_VENUE_NAMES_FETCH_BEGIN,
  [entityTypes.ENTITIES_FETCH_SUCCEEDED]: types.EMPLOYER_VENUE_NAMES_FETCH_SUCCEEDED,
  [entityTypes.ENTITIES_FETCH_FAILED]: types.EMPLOYER_VENUE_NAMES_FETCH_FAILED,
})
