// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import * as types from './action-types'

export const checkItems = (items: any[], scope: string, checked = true) => ({
  type: types[checked ? 'CHECKBOXES_CHECK_ITEM' : 'CHECKBOXES_UNCHECK_ITEM'],
  scope,
  items,
})
export type CheckItemsAction = ReturnType<typeof checkItems>

export const clearItems = (scope: string) => ({ type: types.CHECKBOXES_CLEAR_ITEMS, scope })
export type ClearItemsAction = ReturnType<typeof clearItems>

export type CheckboxAction = CheckItemsAction | ClearItemsAction
