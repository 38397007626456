import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { FormControl, ControlLabel, HelpBlock, Row, Col, FormGroup, InputGroup } from 'react-bootstrap'
import { get } from 'lodash-es'

import { reqDefaults } from 'syft-acp-core/api/call'
import { getModal$ } from 'syft-acp-core/store/modals/selectors'
import { Modal } from 'syft-acp-core/components/Modal'
import { apiURL } from 'syft-acp-core/api/endpoints'
import { trackingEvents } from 'syft-acp-core/entities/ListingDetail/tracking'
import TimeInput from 'syft-acp-core/entities/ListingDetail/ListingShiftTabs/ListingInputs/TimeInput'
import { selectShiftSimple$, selectShiftUnpaidTimes$ } from 'syft-acp-core/store/listing-shifts/selectors'
import { showModal, hideModal } from 'syft-acp-core/store/modals/actions'
import { notify } from 'syft-acp-core/actions/notifications'
import { withTrackingTriggerHoc } from 'syft-acp-util/withTrackingTriggerHoc'
import { zeroPad, formatCurrency, getSafeCurrencySymbol } from 'syft-acp-util/formatting'
import FilterSelectWorkers from 'syft-acp-util/components/FilterForm/FilterSelectWorkers'
import RoleAndSkills from 'syft-acp-core/components/RoleAndSkills'
import Table from 'syft-acp-util/components/Table'

import './AddNewMissingTimeModal.css'

// Displays some basic information about the current shift.
export const ShortShiftInfo = ({ shiftID, shiftInfo, roleID }) => (
  <Table bordered colBorders className="tiny-headers job-info">
    <tbody>
      <tr>
        <th>Shift ID</th>
        <td>{shiftID}</td>
      </tr>
      <tr>
        <th>Job description</th>
        <td>{shiftInfo.desc ? shiftInfo.desc : '(No job description)'}</td>
      </tr>
      <tr>
        <th>{shiftInfo.inThePast ? 'Started' : 'Starts'}</th>
        <td>{shiftInfo.startTimeHR}</td>
      </tr>
      <tr>
        <th>Role</th>
        <td>{roleID ? <RoleAndSkills roleID={roleID} skills={shiftInfo.skills} /> : '–'}</td>
      </tr>
    </tbody>
  </Table>
)

ShortShiftInfo.propTypes = {
  shiftID: PropTypes.number.isRequired,
  shiftInfo: PropTypes.object.isRequired,
  roleID: PropTypes.number.isRequired,
}

class AddNewMissingTimeModal extends PureComponent {
  static propTypes = {
    shiftID: PropTypes.number.isRequired,
    item: PropTypes.object.isRequired,
    dispatch: PropTypes.func.isRequired,
    triggerEvent: PropTypes.func.isRequired,
    workers: PropTypes.arrayOf(PropTypes.object).isRequired,
    shiftInfo: PropTypes.object.isRequired,
  }

  constructor(props) {
    super(props)
    this.initialState = {
      msg: null,
      valid: true,
      workerID: null,
      missingTime: 0,
      isSendingData: false,
      payRate: { amount: 0 },
    }
    this.state = this.initialState
  }

  componentDidUpdate(prevProps) {
    // If we are becoming visible, while not being visible, reset the form.
    if (prevProps.item.isShown !== this.props.item.isShown && this.props.item.isShown === true) {
      // above check makes this setState safe from infinite update
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState(this.initialState)
    }
  }

  resetForm = () => {
    this.setState(this.initialState)
  }

  submitData = data =>
    new Promise(async resolve => {
      const { dispatch } = this.props
      try {
        const req = new Request(apiURL('/admin/timesheets/bulk_edit'), {
          ...reqDefaults('PUT', 'application/json'),
          body: JSON.stringify(data),
        })
        const result = await fetch(req)

        if (result.ok) {
          this.resetForm()
          this.closeModal()
          dispatch(
            notify('success', {
              title: 'Added missing time',
              message: 'Scheduled the worker to be sent extra pay per the entered values.',
            })
          )
          return resolve(true)
        } else {
          // Successful POST, but something went wrong after all.
          const resultText = JSON.parse(await result.text())
          dispatch(
            notify('error', {
              title: 'Could not add missing time',
              message: get(resultText, 'error_description', 'Check the error page for more details.'),
              autoDismiss: 0,
            })
          )
          return resolve(false)
        }
      } catch (err) {
        // Could not successfully POST.
        dispatch(
          notify('error', {
            title: 'Could not add missing time',
            message: 'Something went wrong while sending data to the API. See the error page for more details.',
            autoDismiss: 0,
          })
        )
        return resolve(false)
      }
    })

  onConfirmHandler = async () => {
    const { shiftID, triggerEvent } = this.props
    const { isSendingData, workerID, missingTime } = this.state
    const isValid = this.validate()

    if (!isValid || isSendingData) {
      return
    }

    // Make an array with a single entry to add.
    // Convert milliseconds to seconds.
    const missingTimeInSeconds = missingTime / 1000
    const newEntry = { duration: missingTimeInSeconds }
    const entries = {
      entries: [
        {
          shift_id: shiftID,
          worker_id: workerID,
          unpaid_times: [newEntry],
        },
      ],
    }
    triggerEvent(trackingEvents.LISTING_INFO.WORKER_MISSING_TIME.ADDED, {
      shift_id: shiftID,
      worker_id: workerID,
      unpaid_times: [newEntry],
    })
    this.setState({ isSendingData: true })
    await this.submitData(entries)
    this.setState({ isSendingData: false, msg: null })
  }

  // Note: 'onlyTurnOff' will remove the validation error state if the form is valid.
  // If set to true, it will not set the error state to true, but leave it on true if it already is.

  validate = (onlyTurnOff = false, newState = {}) => {
    const currentData = { ...this.state, ...newState }
    const { workerID, missingTime } = currentData
    let valid

    if (!onlyTurnOff || (onlyTurnOff && currentData.valid !== true)) {
      // No worker selected.
      if (!workerID || workerID <= 0) {
        valid = false
        this.setState({ ...currentData, valid, msg: 'Select a worker.' })
        return valid
      }
      // No missing time input.
      if (missingTime <= 0) {
        valid = false
        this.setState({
          ...currentData,
          valid,
          msg: "Set a value for the amount of missing time to add to the worker's pay.",
        })
        return valid
      }
    }

    valid = true
    this.setState({ ...currentData, valid })

    return valid
  }

  closeModal = () => {
    if (this.props.item.onClose) {
      this.props.item.onClose()
    }
    this.props.dispatch(hideModal(this.props.item.modalName))
  }

  openModal = () => this.props.dispatch(showModal(this.props.item.modalName))

  checkConfirm = ev => {
    if (ev.key === 'Enter') {
      this.onConfirmHandler()
    }
  }

  setSelectedWorker = worker => {
    this.validate(true, { workerID: worker.id, payRate: { amount: worker.worker_pay_rate } })
  }

  setDuration = (_, ms) => {
    this.validate(true, { missingTime: ms })
  }

  durationInText = val => {
    // Changes a duration in milliseconds, e.g. 15600000, into a string value.
    let remainder = val
    const hours = (val / 3600000) << 0
    remainder -= hours * 3600000
    const mins = (remainder / 60000) << 0
    return `${zeroPad(hours << 0)}:${zeroPad(mins << 0)}`
  }

  render() {
    const { workerID, missingTime, payRate, valid, isSendingData, msg } = this.state
    const { shiftID, workers, shiftInfo } = this.props
    const roleID = get(shiftInfo, 'role.id', null)

    // Adds worker placeholders for spots that were not filled.
    const workersWithPlaceholders = workers.map((w, n) => (w.id ? w : { id: -n, label: `– (Spot ${n + 1})` }))

    // Whether to display a summary of the changes we'll save (if the form is correct).
    const displaySummary = valid && missingTime && workerID > 0

    return (
      <Modal
        header="Add missing time for worker"
        isShown={this.props.item.isShown}
        onClose={this.closeModal}
        onConfirm={this.onConfirmHandler}
        errorText={msg}
        isLoading={isSendingData}
        confirmationText="Add"
      >
        <div className="missing-time-modal">
          {/* Display information about the shift. */}
          <ShortShiftInfo shiftID={shiftID} shiftInfo={shiftInfo} roleID={roleID} />

          {/* Edit form for the shift. */}
          <FormGroup className="form-item" disabled={isSendingData}>
            <Row>
              <Col md={4}>
                <ControlLabel>Worker</ControlLabel>
                <FilterSelectWorkers
                  disabled={isSendingData}
                  name="worker"
                  options={workersWithPlaceholders}
                  onChange={this.setSelectedWorker}
                />
              </Col>
              <Col md={4}>
                <ControlLabel>Missing time</ControlLabel>
                <TimeInput
                  disabled={isSendingData}
                  onChange={this.setDuration}
                  limitHours={false}
                  value={this.durationInText(missingTime)}
                />
              </Col>
              <Col md={4}>
                <ControlLabel>Pay rate</ControlLabel>
                <InputGroup>
                  <InputGroup.Addon>{payRate.currency ? payRate.currency : getSafeCurrencySymbol()}</InputGroup.Addon>
                  <FormControl value={payRate.amount ? payRate.amount : '–'} disabled />
                </InputGroup>
              </Col>
            </Row>
          </FormGroup>
          <HelpBlock className="bottom-help">
            {displaySummary
              ? `The worker will be paid out an extra ${this.durationInText(
                  missingTime
                )} hour(s) at rate ${formatCurrency(payRate.amount, payRate.currency)}.`
              : 'Select a worker and enter a time.'}
          </HelpBlock>
        </div>
      </Modal>
    )
  }
}

export default connect((state, ownProps) => ({
  item: getModal$(state.modals)('addNewMissingTimeModal'),
  shiftInfo: selectShiftSimple$(state)(ownProps.shiftID),
  unpaidTimesInfo: selectShiftUnpaidTimes$(state)(ownProps.shiftID),
}))(withTrackingTriggerHoc(AddNewMissingTimeModal))
