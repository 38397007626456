export const APPEALS_FETCH_BEGIN = 'syftacp/APPEALS_FETCH_BEGIN'
export const APPEALS_FETCH_FAILED = 'syftacp/APPEALS_FETCH_FAILED'
export const APPEALS_FETCH_SUCCEEDED = 'syftacp/APPEALS_FETCH_SUCCEEDED'

export const APPEAL_FETCH_BEGIN = 'syftacp/APPEAL_FETCH_BEGIN'
export const APPEAL_FETCH_FAILED = 'syftacp/APPEAL_FETCH_FAILED'
export const APPEAL_FETCH_SUCCEEDED = 'syftacp/APPEAL_FETCH_SUCCEEDED'

export const APPEALS_ASSIGN_BEGIN = 'syftacp/APPEALS_ASSIGN_BEGIN'
export const APPEALS_ASSIGN_FAILED = 'syftacp/APPEALS_ASSIGN_FAILED'
export const APPEALS_ASSIGN_SUCCEEDED = 'syftacp/APPEALS_ASSIGN_SUCCEEDED'

export const APPEALS_ASSIGNEES_FETCH_BEGIN = 'syftacp/APPEALS_ASSIGNEES_FETCH_BEGIN'
export const APPEALS_ASSIGNEES_FETCH_FAILED = 'syftacp/APPEALS_ASSIGNEES_FETCH_FAILED'
export const APPEALS_ASSIGNEES_FETCH_SUCCEEDED = 'syftacp/APPEALS_ASSIGNEES_FETCH_SUCCEEDED'

export const APPEAL_CREATE_BEGIN = 'syftacp/APPEAL_CREATE_BEGIN'
export const APPEAL_CREATE_FAILED = 'syftacp/APPEAL_CREATE_FAILED'
export const APPEAL_CREATE_SUCCEEDED = 'syftacp/APPEAL_CREATE_SUCCEEDED'

export const APPEAL_UPDATE_BEGIN = 'syftacp/APPEAL_UPDATE_BEGIN'
export const APPEAL_UPDATE_FAILED = 'syftacp/APPEAL_UPDATE_FAILED'
export const APPEAL_UPDATE_SUCCEEDED = 'syftacp/APPEAL_UPDATE_SUCCEEDED'

export const APPEAL_REASONS_FETCH_BEGIN = 'syftacp/APPEAL_REASONS_FETCH_BEGIN'
export const APPEAL_REASONS_FETCH_FAILED = 'syftacp/APPEAL_REASONS_FETCH_FAILED'
export const APPEAL_REASONS_FETCH_SUCCEEDED = 'syftacp/APPEAL_REASONS_FETCH_SUCCEEDED'
