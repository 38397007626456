import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { FormControl } from 'react-bootstrap'
import { get, filter } from 'lodash-es'

import './FilterSelect.css'

// Use string headerID for the selector. This is necessary since all form values must be strings.
// If the selector is chosen, a real null is sent to the callback which will work,
// but it's disabled so that should never happen anyway.
const headerID = 'null'

const renderInnerOptions = (value, allowAny, optionsData = []) =>
  optionsData.map(({ id, label }) => (
    <option
      value={id}
      key={id}
      disabled={id === headerID && !allowAny}
      defaultValue={id === headerID && (value === headerID || value === null)}
    >
      {label}
    </option>
  ))

const renderOptions = (value, allowAny, categorizedData, optionsData = []) => {
  if (categorizedData) {
    // Render with <optgroup> nodes if our input is categorized.
    return optionsData.map(opt => {
      // If there is no label, just render plain options too.
      // This is used for the 'any' option.
      if (opt[0] === null) {
        return renderInnerOptions(value, allowAny, opt[1])
      } else {
        return (
          <optgroup label={opt[0]} key={opt[0]}>
            {renderInnerOptions(value, allowAny, opt[1])}
          </optgroup>
        )
      }
    })
  } else {
    // Otherwise, return plain options.
    return renderInnerOptions(value, allowAny, optionsData)
  }
}

/** Return the ID for a specific label, if we are working with labels instead of IDs. */
export const getValue = (value, returnItem, optionsData = []) => {
  switch (returnItem) {
    case 'label':
      return get(
        filter(optionsData, item => item.label === value),
        '0.id'
      )
    case 'object':
      return get(value, 'id')
    case 'id':
    default:
      return value
  }
}

/**
 * Higher order component for creating select filters.
 *
 * @param {Object} options Options that the user can choose from (must contain 'id' and 'label')
 * @param {String} header Unselectable header displayed above the items
 * @param {String} returnItem Type of data to return:
 *   * 'id' - returns ID
 *   * 'label' - returns label
 *   * 'object' - returns the whole object from the options
 * @param {Boolean} convertIDs Whether we need to convert IDs to numbers for lookup in the options
 * @param {Boolean} categorized Whether we need to add optgroup nodes
 */
export const filterSelect = (
  options = [],
  header = 'Any',
  returnItem = 'id',
  convertIDs = true,
  categorized = false
) => {
  // Add the header item to the list of options.
  const anyOption = { id: headerID, label: header }
  const selectOptions = [
    // Wrap in an array if we're displaying a categorized list.
    categorized ? [null, [anyOption]] : anyOption,
    ...options,
  ]

  const callback = cb => e => {
    // Return null if the header was selected.
    if (e.target.value === headerID) {
      return cb(null)
    }

    const id = convertIDs ? Number(e.target.value) : e.target.value
    const obj = get(
      filter(selectOptions, item => item.id === id),
      0
    )
    const label = get(obj, 'label')

    switch (returnItem) {
      case 'label':
        return cb(label)
      case 'object':
        return cb(obj)
      case 'id':
      default:
        return cb(id)
    }
  }

  const FilterSelectItem = ({
    name,
    value,
    allowAny,
    small,
    tiny,
    onChange,
    disabled,
    enabled = true,
    className,
    ariaLabel,
    testID,
  }) => {
    const opts = renderOptions(value, allowAny, categorized, selectOptions)

    return (
      <FormControl
        data-testid={testID}
        aria-label={ariaLabel}
        componentClass="select"
        name={name}
        className={classnames(className, 'FilterSelectItem', { small, tiny })}
        value={getValue(value, returnItem, selectOptions) || selectOptions[0].id}
        onChange={callback(onChange)}
        disabled={!enabled || disabled}
      >
        {opts}
      </FormControl>
    )
  }

  FilterSelectItem.propTypes = {
    name: PropTypes.string,
    className: PropTypes.string,
    allowAny: PropTypes.bool,
    enabled: PropTypes.bool,
    small: PropTypes.bool,
    tiny: PropTypes.bool,
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.object]),
    onChange: PropTypes.func,
    disabled: PropTypes.bool,
    ariaLabel: PropTypes.string,
    testID: PropTypes.string,
  }

  return FilterSelectItem
}
