import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { ControlLabel, HelpBlock, Row, Col, FormGroup } from 'react-bootstrap'
import { get } from 'lodash-es'
import FormattedTimeRange from 'syft-acp-core/components/FormattedTimeRange'

import { filterSelect } from 'syft-acp-util/components/FilterForm/FilterSelect'
import { formatFullName } from 'syft-acp-util/formatting'
import { getModal$ } from 'syft-acp-core/store/modals/selectors'
import { Modal } from 'syft-acp-core/components/Modal'
import { showModal, hideModal } from 'syft-acp-core/store/modals/actions'
import DataTextarea from 'syft-acp-core/components/EditableTable/DataTextarea'
import DataText from 'syft-acp-core/components/EditableTable/DataText'
import Table from 'syft-acp-util/components/Table'
import UserLine from 'syft-acp-core/components/UserAvatar/UserLine'

import './AddFlexCompReasonModal.css'

//Functionality of this modal requires 'Other' to have id 5
//If you change this, change the other spots in code that check for id 5
const syftCompReasonOptions = [
  { id: 0, label: 'Overbook - client not responsible' },
  { id: 1, label: 'System issue - already billed' },
  { id: 2, label: 'Orientation / training pay' },
  { id: 3, label: 'Provisional Hours' },
  { id: 4, label: 'Show up pay' },
  { id: 5, label: 'Other' },
]

// Displays some basic information about the booking.
export const ShortBookingInfo = ({ shiftBooking, timeZone }) => (
  <Table bordered colBorders className="tiny-headers booking-info">
    <tbody>
      <tr>
        <th>Booking ID</th>
        <td>{shiftBooking.id}</td>
      </tr>
      <tr className="worker-line">
        <th>Worker</th>
        <td>
          <UserLine
            avatarUUID={get(shiftBooking, 'worker.profile_picture.uuid', '')}
            name={shiftBooking.worker ? formatFullName(shiftBooking.worker) : shiftBooking.full_name}
          />
        </td>
      </tr>
      {timeZone && (
        <tr>
          <th>Shift</th>
          <td>
            <FormattedTimeRange
              value={[
                get(
                  shiftBooking,
                  'clock_in.time',
                  get(shiftBooking, 'clock_in_time', get(shiftBooking, 'shift.start_time')),
                ),
                get(
                  shiftBooking,
                  'clock_out.time',
                  get(shiftBooking, 'clock_out_time', get(shiftBooking, 'shift.end_time')),
                ),
              ]}
              format={{ month: 'short', day: 'numeric', hour: '2-digit', minute: '2-digit', timeZone }}
            />
          </td>
        </tr>
      )}
    </tbody>
  </Table>
)

class AddFlexCompReasonModal extends React.PureComponent {
  static propTypes = {
    shiftBooking: PropTypes.object,
  }

  constructor(props) {
    super(props)
    this.initialState = {
      syftCompReason: null,
      approver: '',
      otherReason: '',
      valid: false,
    }
    this.state = this.initialState
  }

  resetForm = () => {
    this.setState(this.initialState)
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    // If we are becoming visible, while not being visible, reset the form.
    if (this.props.item.isShown !== nextProps.item.isShown && nextProps.item.isShown === true) {
      this.setState(this.initialState)
    }
  }

  onConfirmHandler = async () => {
    const isValid = this.validate()

    if (isValid) {
      this.closeModal()
    }
  }

  // Note: 'onlyTurnOff' will remove the validation error state if the form is valid.
  // If set to true, it will not set the error state to true, but leave it on true if it already is.
  validate = (onlyTurnOff = false, newState = {}) => {
    const currentData = { ...this.state, ...newState }
    const { syftCompReason, approver, otherReason } = currentData
    let valid

    if (!onlyTurnOff || (onlyTurnOff && currentData.valid !== true)) {
      // No worker selected.
      if (!syftCompReason) {
        valid = false
        this.setState({ ...currentData, valid, msg: 'Select a reason' })
        return valid
      }
      if (!approver) {
        valid = false
        this.setState({ ...currentData, valid, msg: 'Set an approver' })
        return valid
      }
      if (syftCompReason === '5' && !otherReason) {
        valid = false
        this.setState({ ...currentData, valid, msg: 'Set a reason for other' })
        return valid
      }
    }

    valid = true
    this.setState({ ...currentData, valid })

    return valid
  }

  closeModal = () => {
    if (this.props.item.onClose) {
      const reason = this.state.syftCompReason
        ? syftCompReasonOptions[this.state.syftCompReason].label
        : this.state.syftCompReason
      this.props.item.onClose({
        syftCompReason: reason,
        approver: this.state.approver,
        otherReason: this.state.otherReason,
      })
    }
    this.props.dispatch(hideModal(this.props.item.modalName))
  }

  openModal = () => this.props.dispatch(showModal(this.props.item.modalName))

  setSyftCompReason = syftCompReason => {
    this.validate(true, { syftCompReason })
    if (syftCompReason !== '5') {
      this.setState({ otherReason: '' })
    }
  }

  setOtherReason = otherReason => {
    this.validate(true, { otherReason })
  }

  setApprover = approver => {
    this.validate(true, { approver })
  }

  render() {
    const shiftBooking = get(this.props.item, 'options.shiftBooking', false)
    const { syftCompReason, approver, otherReason } = this.state

    const FilterSelectReason = filterSelect(
      syftCompReasonOptions,
      'Reason',
      false,
      false,
      false,
      this.setSyftCompReason,
    )

    return (
      <Modal
        header="Set flex compensation reason for shift"
        isShown={this.props.item.isShown}
        onClose={this.closeModal}
        onConfirm={this.onConfirmHandler}
        confirmationText="Set reason"
        canSubmit={this.state.valid}
      >
        <div className="syft-compensation-modal">
          {shiftBooking && <ShortBookingInfo shiftBooking={shiftBooking} />}
          <FormGroup className="form-item">
            <Row>
              <Col md={12}>
                <ControlLabel>Flex compensation reason</ControlLabel>
                <FilterSelectReason
                  name="reason"
                  options={syftCompReasonOptions}
                  value={syftCompReason}
                  onChange={this.setSyftCompReason}
                />
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <ControlLabel>Other reason:</ControlLabel>
                <DataTextarea
                  name="other"
                  value={otherReason}
                  onChange={this.setOtherReason}
                  disabled={this.state.syftCompReason !== '5'}
                />
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <ControlLabel>Who approved this?</ControlLabel>
                <DataText name="approver" value={approver} onChange={this.setApprover} editable enabled />
              </Col>
            </Row>
          </FormGroup>
          <HelpBlock className="bottom-help">Select a reason and press "set".</HelpBlock>
        </div>
      </Modal>
    )
  }
}

export default connect((state, ownProps) => ({
  item: getModal$(state.modals)('addFlexCompReasonModal'),
  syftCompReasons: state.syftCompReasons,
  shiftBooking: ownProps.shiftBooking,
}))(AddFlexCompReasonModal)
