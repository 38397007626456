// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import * as types from './action-types'
import { PatchShiftInputPayload } from '../shifts/types'

export const fetchFulfilmentShift = (shiftID: number) => ({
  type: types.FULFILMENT_SHIFT_FETCH_BEGIN,
  payload: { id: shiftID },
})
export type FetchFulfilmentShiftAction = ReturnType<typeof fetchFulfilmentShift>

export const editFulfilmentShift = (payload: Partial<PatchShiftInputPayload>) => {
  return {
    type: types.FULFILMENT_SHIFT_EDIT_BEGIN,
    payload,
  }
}
export type EditFulfilmentShiftAction = ReturnType<typeof editFulfilmentShift>
