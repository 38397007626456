import { apiRequest, handleCall } from 'syft-acp-core/api/call'

export const highlightShiftBooking = ({ bookingIDs, highlight }) =>
  new Promise((resolve, reject) => {
    const req = apiRequest({
      path: '/admin/shift_bookings/highlight',
      reqArgs: { shift_booking_ids: bookingIDs, highlight },
      method: 'PATCH',
    })
    handleCall(req, resolve, reject, true)
  })
