import * as U from 'karet.util'
import * as R from 'kefir.ramda'

import { stringify } from 'syft-acp-core/store/filters/helpers'

/** Returns the current timestamp in ms. */
const now = () => +new Date()

// Container for all cache data used by AcpResources.
const cacheData = U.atom({
  resources: {},
})

/** Retrieves the complete cache as a plain object. For debugging. */
export const getCompleteCache = () => {
  return cacheData.get()
}

// Lenses to access the data in the cache.
const cacheLenses = {
  resources: U.view('resources', cacheData),
}
// Lenses for specific resources.
const resourceLenses = {}

/** Initial data for a new resource item. Each item inside 'resources' is initialized this way. */
const initialResourceData = name => ({
  name,
  // The 'lastSet' value links to the last set we retrieved data from successfully.
  // Used to display old data if e.g. a table is loading the next page.
  lastSet: null,
  // 'sets' contains cached data per individual query to the endpoint.
  sets: {},
})

/** Initial data for a set inside of a resource. */
const initialSetData = setName => ({
  setName,
  reqArgs: {},
  reqResult: {
    data: [],
    meta: {},
  },
  reqTime: 0,
  reqError: null,
  hasData: false,
  isOK: true,
  isLoading: false,
})

/** Generates a cache set name. If no query is provided, '$' is used. */
export const cacheSet = query => {
  const set = stringify(query)
  return set === '' ? '$' : set
}

/** Returns lenses for a resource's specific call. */
const getResLenses = (resName, args) => {
  const setName = cacheSet(args)
  const resLens = resourceLenses[resName]
  const resSetsLens = U.view('sets', resLens)
  const resLastSet = U.view('lastSet', resLens).get()
  const setLens = U.view(setName, resSetsLens)
  const lastSetLens = resLastSet ? U.view(resLastSet, resSetsLens) : setLens

  return { setName, resLens, resSetsLens, resLastSet, setLens, lastSetLens }
}

/** Saves new content to a set. */
const setResSetData = (setLens, result, values) => {
  if (result) {
    setLens.modify(R.assoc('reqResult', { data: result.data, meta: result.meta }))
  }
  for (const [key, value] of Object.entries(values)) {
    setLens.modify(R.set(R.lensProp(key), value))
  }
}

/** Inserts a new resource into the store: this is done once at startup and ensures there's initial data. */
const resCacheInit = resName => {
  // Return if the data already exists. This happens during hot reloading.
  // Note: this would technically also happen if there are multiple resources with conflicting names.
  if (resourceLenses[resName]) return

  // Inject the initial data.
  resourceLenses[resName] = U.view(resName, cacheLenses.resources)
  resourceLenses[resName].set(initialResourceData(resName))
}

/** Returns the lens for a single resource. Used once at the initialization phase. */
export const getResLens = resName => {
  if (!resourceLenses[resName]) resCacheInit(resName)
  return resourceLenses[resName]
}

/**
 * Returns a view for a specific set of a resource.
 *
 * This returns a lens to the set data, and plain values for the
 * most important meta-information such as isLoading, hasData.
 *
 * If all the meta-information remains the same, the lens data
 * will also not have changed.
 */
export const getSetView = (resName, args) => {
  const { setLens } = getResLenses(resName, args)
  const { hasData, isLoading, isOK, reqTime, reqError } = U.destructure(setLens)
  return {
    setLens,
    hasData: hasData.get(),
    isLoading,
    isOK: isOK.get(),
    isStale: false, //! hasData.get(),
    reqTime: reqTime.get(),
    reqError: reqError.get(),
  }
}

/** Rehydrates the cache data from localStorage after opening the page. */
export const resCacheRehydrate = data => {
  cacheData.set(data)
  // TODO: go over each item and set isLoading to false.
}

/** Initiates a remote call for a resource. */
const resCacheCallBegin = (resName, args) => {
  const { setLens, setName } = getResLenses(resName, args)

  // Check if we need to initialize this call.
  const setContent = setLens.get()
  if (setContent == null) {
    setResSetData(setLens, null, { ...initialSetData(setName) })
  }

  // Inject the initial data.
  setResSetData(setLens, null, { reqArgs: args, isLoading: true })
}

/** Finishes a remote resource call. */
const resCacheCallDone = (resName, args, status, result) => {
  const { setLens } = getResLenses(resName, args)

  // Inject the data we retrieved.
  setResSetData(setLens, result, {
    hasData: true,
    isOK: status,
    isLoading: false,
    reqError: result.err ? result.err : null,
    reqTime: now(),
  })
}

/** Dispatch initial scaffolding for a resource. */
export const dispatchInit = name => resCacheInit(name)
/** Dispatch the start of a resource remote call. */
export const dispatchCallBegin = (name, args = {}) => resCacheCallBegin(name, args)
/** Dispatch the end of a resource remote call, whether successful or failed. */
export const dispatchCallFinish = (name, args = {}, json = {}, success = true) =>
  resCacheCallDone(name, args, success, json)
