import React, { useMemo } from 'react'
import { bindActionCreators } from 'redux'
import { connect, ConnectedProps } from 'react-redux'
import { flowRight } from 'lodash-es'
import { useTrackingTrigger } from '@indeed/flex-tracking-context'

import Acp from 'syft-acp-uikit'
import { RowCallbackData } from 'syft-acp-uikit/components/AcpTable/AcpTable.types'
import { createEntityListConnector } from 'syft-acp-util/entityList'
import useFetchEntity from 'syft-acp-util/hooks/useFetchEntity'
import {
  fetchWorkerReferralBonuses,
  deleteWorkerReferralBonuses,
} from 'syft-acp-core/store/workerReferralBonuses/actions'
import {
  WorkerReferralBonusEntity,
  WorkerReferralBonusSources,
} from 'syft-acp-core/store/workerReferralBonuses/types'
import { getModal$ } from 'syft-acp-core/store/modals/selectors'
import { showModal, hideModal } from 'syft-acp-core/store/modals/actions'
import { selectCheckboxItems } from 'syft-acp-core/store/checkboxes/selectors'
import { showConfirmModal } from 'syft-acp-core/store/modals/actions'

import CreateWorkerReferralBonus from './CreateWorkerReferralBonus'
import { WorkerReferralBonusModals } from './WorkerReferralBonuses.constants'
import { trackingEvents } from './WorkerReferralBonuses.tracking'
import { getReferralRole } from './WorkerReferralBonus.utils'

const selectionKey = 'worker_referral_bonuses_list'

type EntityProps = ConnectedProps<typeof entityConnector>
type ConnectProps = ConnectedProps<typeof storeConnector>
type Props = {
  workerId: number
} & EntityProps &
  ConnectProps

const WorkerReferralBonuses = ({
  workerId,
  actions,
  query,
  data,
  isLoadingData,
  addModal,
  selectedRows,
}: Props) => {
  const track = useTrackingTrigger()
  useFetchEntity({ ...query, workerID: workerId }, actions.fetchEntities)

  const selectSourceId =
    (sourceKey: WorkerReferralBonusSources) =>
    ({ source, source_id }: WorkerReferralBonusEntity) =>
      source === sourceKey ? source_id : '-'

  const selectReferralRole = ({ referral_role }: WorkerReferralBonusEntity) => getReferralRole(referral_role)

  const rowsToDelete = useMemo(() => selectedRows?.map(({ id }: { id: number }) => id), [selectedRows])

  const handleAdd = () => {
    actions.showModal(WorkerReferralBonusModals.AddReferralBonus)
  }

  const handleAddClose = () => {
    actions.hideModal(WorkerReferralBonusModals.AddReferralBonus)
  }

  const handleDelete = () => {
    actions.showConfirmModal({
      modalHeader: 'Delete adhoc referral bonuses',
      modalConfirm: 'Delete',
      question: `Are you sure you want to delete the following adhoc referral bonuses: ${rowsToDelete.join(
        ', ',
      )}`,
      onConfirm: () => {
        actions.deleteWorkerReferralBonuses(workerId, rowsToDelete)
        track(trackingEvents.DELETE.CONFIRM_CLICKED, {
          worker_id: workerId,
          worker_referral_bonus_ids: rowsToDelete,
        })
      },
    })
  }

  const handleSelection = ({ id }: WorkerReferralBonusEntity) => {
    return [{ id }]
  }

  const handleSelectionLabel = ({ id }: WorkerReferralBonusEntity) => {
    return `Select row ${id}`
  }

  const handleDisabled = ({ deletable }: WorkerReferralBonusEntity) => {
    return !deletable
  }

  const handleRowClick = ({ rowData }: RowCallbackData<WorkerReferralBonusEntity>) => {
    actions.showModal(WorkerReferralBonusModals.AddReferralBonus, undefined, null, {
      id: rowData.id,
    })
  }

  return (
    <>
      <Acp.EntityList
        data={data}
        isLoading={isLoadingData}
        isIntegrated
        idKeyValue="id"
        hasMinimumSize
        actionsInline
      >
        <Acp.Actions>
          <Acp.Header>Adhoc Referral Bonuses</Acp.Header>
          <Acp.Button onClick={handleDelete} disabled={!(selectedRows?.length > 0)}>
            Delete
          </Acp.Button>
          <Acp.Button onClick={handleAdd}>Add adhoc referral bonus</Acp.Button>
        </Acp.Actions>
        <Acp.Table hasMinimumSize={5} maxRows={5} rowCallback={handleRowClick}>
          <Acp.Col.Selector
            align="center"
            isMinimal
            colNoLink
            value={handleSelection}
            headerValue={handleSelection}
            headerTooltip="Select all adhoc referral bonuses"
            options={{
              scope: selectionKey,
              valueKeys: ['id'],
              ariaLabel: handleSelectionLabel,
              disabled: handleDisabled,
            }}
          />
          <Acp.Col.Text value="id" header="ID" isMinimal isNumeric />
          <Acp.Col.Text value={selectSourceId('shift')} header="Shift ID" />
          <Acp.Col.Text value={selectSourceId('missing_pay')} header="Missing Pay ID" />
          <Acp.Col.Text value="employer_name" header="Employer" />
          <Acp.Col.Text value="venue_name" header="Venue" />
          <Acp.Col.DateTime value="date_of_work" header="Date of Work" />
          <Acp.Col.Text value={selectReferralRole} header="Referral Role" />
          <Acp.Col.Money value="bonus_amount" header="Amount" align="left" />
          <Acp.Col.DateTime value="created_at" header="Date Added" />
          <Acp.Col.Text value="created_by" header="Added By" />
        </Acp.Table>
      </Acp.EntityList>
      <CreateWorkerReferralBonus
        id={addModal.data.id}
        workerId={workerId}
        isOpen={addModal.isShown}
        onClose={handleAddClose}
      />
    </>
  )
}

export const entityConnector = createEntityListConnector({
  entityActions: { fetchEntities: fetchWorkerReferralBonuses },
  paginationKey: 'referralBonusesPage',
  entityStore: 'workerReferralBonuses',
})

export const storeConnector = connect(
  state => ({
    addModal: getModal$(state.modals)(WorkerReferralBonusModals.AddReferralBonus),
    selectedRows: selectCheckboxItems(state, selectionKey),
  }),
  (dispatch, props: EntityProps) => ({
    actions: {
      ...props.actions,
      ...bindActionCreators(
        {
          showModal,
          hideModal,
          showConfirmModal,
          deleteWorkerReferralBonuses,
        },
        dispatch,
      ),
    },
  }),
)

export default flowRight(entityConnector, storeConnector)(WorkerReferralBonuses)
