export const structure = [
  [
    'score',
    {
      header: 'CSAT score',
    },
  ],
  [
    'skipped',
    {
      header: 'Skipped %',
    },
  ],
]

export const DOWNLOAD_CSAT_RESULT_CSV_BUTTON_CLICKED = 'generic.csat_result.download_csv_button.clicked'
