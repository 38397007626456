export const trackingEvents = {
  PAGE: { VIEWED: 'conduct_reviews.page.viewed' },
  ASSIGN: { CLICKED: 'conduct_reviews.assign.clicked' },
  FILTERS: {
    OUTCOME: {
      CHANGED: 'conduct_reviews.filter_outcome.changed',
    },
    REASON: {
      CHANGED: 'conduct_reviews.filter_reason.changed',
    },
    ASSIGNEE: {
      CHANGED: 'conduct_reviews.filter_assignee.changed',
    },
  },
}
