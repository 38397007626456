import { apiRequest, handleCall } from 'syft-acp-core/api/call'

export const fetchWorkerExpenses = ({ options }: Record<string, any>) =>
  new Promise((resolve, reject) => {
    const req = apiRequest({
      path: `/admin/workers/${options.workerID}/worker_expenses`,
      reqArgs: options,
      method: 'GET',
    })
    handleCall(req, resolve, reject, true)
  })
