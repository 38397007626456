// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import React from 'react'
import { TrackingProvider, TrackingTrigger } from '@indeed/flex-tracking-context'

import PageView from 'syft-acp-core/components/PageView'
import WorkerNetworksListComponent from 'syft-acp-core/entities/WorkerDetail/WorkerNetworksList'
import { trackingEvents } from 'syft-acp-core/entities/WorkerDetail/WorkerNetworksList/WorkerNetworksList.tracking'
import { WorkerSubNavigation } from './SubNavigation'

export const EntityWorkersNetworksList = ({ match: { params } }) => (
  <TrackingProvider
    value={{
      context: {
        worker_id: params.id,
      },
    }}
  >
    <TrackingTrigger onLoad={{ event: trackingEvents.PAGE.VIEWED }} />
    <WorkerSubNavigation workerID={params.id} />
    <PageView title={[`Networks for worker ${params.id}`]}>
      <WorkerNetworksListComponent
        workerID={Number(params.id)}
        urlBase={`/entity/workers/view/${params.id}/networks/view/`}
      />
    </PageView>
  </TrackingProvider>
)
