import { takeLatest } from 'redux-saga/effects'

import * as api from 'syft-acp-core/api/resources/agency-platform'
import { notificationCall } from 'syft-acp-core/sagas/calls'
import { AGENCY_PLATFORM_DELETE_FAILED, AGENCY_PLATFORM_DELETE_SUCCEEDED } from 'syft-acp-core/actions/action-types'

import { takeLatestApiCall } from './helpers'

export default function* root() {
  yield takeLatestApiCall('AGENCY_PLATFORM_DELETE', api.deleteAgencyPlatform)
  yield takeLatest([AGENCY_PLATFORM_DELETE_FAILED, AGENCY_PLATFORM_DELETE_SUCCEEDED], notificationCall)
}
