export const trackingEvents = {
  PAGE: {
    VIEW: 'agencies_list.page.viewed',
  },
  ADD_AGENCY: {
    CLICKED: 'agencies_list.add_agency.clicked',
  },
  ADD_AGENCY_INVITE: {
    CLICKED: 'agencies_list.add_agency_invite_button.clicked',
  },
}
