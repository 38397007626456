// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import Notifications from 'react-notification-system-redux'
import { store } from 'syft-acp-core/store'
import { history } from 'syft-acp-core/history'

import * as types from './action-types'

/**
 * Show a regular notification that appears as a visible object inside the page.
 * @param {string} level Severity level (success, error, warning or info)
 * @param {object} payload An object containing arguments for react-notification-system-redux
 */
const showPageNotification = (level, payload) => Notifications[level](payload)

/**
 * Show a desktop notification (used when the page is hidden).
 * @param {string} level Severity level
 * @param {object} payload An object containing arguments for react-notification-system-redux
 */
const showDesktopNotification = (level, payload) => ({
  type: types.DESKTOP_NOTIFICATION_SHOW,
  level,
  payload,
})

/**
 * Display a notification. This will call a regular, visible notification,
 * and a desktop notification if the page is not visible.
 * @param {string} level Severity level
 * @param {object} payload An object containing arguments for react-notification-system-redux
 */
export const notify = (level, payload) => {
  const { desktopNotificationsEnabled } = store.getState().settings

  // Call an extra desktop notification if necessary.
  if (desktopNotificationsEnabled && document.hidden) {
    store.dispatch(showDesktopNotification(level, payload))
  }

  return showPageNotification(level, payload)
}

/**
 * Notifies the user that there is an update available.
 */
export const notifyUpdateAvailable = (newVersion, oldVersion) => {
  store.dispatch(
    notify('warning', {
      title: 'An update is available!',
      message: `Refresh the page to update ACP to version ${newVersion} (current version
is ${oldVersion}).`,
      autoDismiss: 0,
      action: {
        label: 'Refresh',
        callback: () => window.location.reload(),
      },
    }),
  )
}

/**
 * Notifies the user that we have just updated the application.
 */
export const notifyUpdated = newVersion => {
  store.dispatch(
    notify('success', {
      title: `Updated to version ${newVersion}`,
      message: "View the changelog to see what's new.",
      autoDismiss: 0,
      action: {
        label: 'Changelog',
        callback: () => history.push(`/changelog`),
      },
    }),
  )
}

/**
 * Request that desktop notifications be turned on or off.
 * This is fired as a result of the user's action.
 * @param {boolean} enabled On or off
 */
export const toggleDesktopNotifications = enabled => ({
  type: types.DESKTOP_NOTIFICATIONS_TOGGLE,
  enabled,
})

/**
 * Turns all notifications (desktop or otherwise) on or off.
 * @param {boolean} enabled On or off
 */
export const toggleNotificationsGlobally = enabled => ({
  type: types.NOTIFICATIONS_GLOBALLY_TOGGLE,
  enabled,
})

/**
 * Turns all notifications (desktop or otherwise) on or off.
 * @param {boolean} enabled On or off
 */
export const enableNotificationsGloballyIfUnset = () => ({
  type: types.NOTIFICATIONS_GLOBALLY_ENABLE_IF_UNSET,
})

/**
 * Request the user to give us permission to show desktop notifications.
 */
export const requestNotificationPermission = () => ({
  type: types.DESKTOP_NOTIFICATIONS_REQ_PERMISSION,
})

/**
 * Sets the desktop notifications to enabled or disabled.
 * This is after we have checked for permission.
 * @param {boolean} enabled On or off
 */
export const setDesktopNotifications = enabled => ({
  type: types.DESKTOP_NOTIFICATIONS_SET,
  enabled,
})
