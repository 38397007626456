import React from 'react'
import { useFlexFlagIsOn } from '@indeed/flex-feature-flags'
import { Button, ButtonGroup } from 'syft-acp-atoms/Button'
import { Link } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { noop } from 'lodash-es'

import * as workerStrikesActions from 'syft-acp-core/store/workerStrikes/actions'
import RecordControls from 'syft-acp-core/components/RecordSection/RecordControls'
import LoadingIndicator from 'syft-acp-util/components/LoadingIndicator'
import { WorkerStrikeDetailsControlsProps as Props } from './WorkerStrikeDetails/WorkerStrikeDetails.types'

const WorkerStrikeDetailsControls = ({
  id = 0,
  data = { id: 0, email_sent_at: null, activated_at: null },
  workerID,
  onSave = noop,
  actions,
  isSavingData,
  isLoadingData,
}: Props) => {
  const isNegativeAction = useFlexFlagIsOn('pte_34577_negative_actions')

  const saveAction = () => {
    if (id) actions.updateWorkerStrike(workerID, id, data)
    else
      actions.createWorkerStrike(workerID, {
        ...data,
        activate: true,
        send_email: true,
      })
    onSave()
  }
  return (
    <RecordControls>
      <div className="spacer">
        <ButtonGroup>
          {isNegativeAction ? (
            <Link to={`/entity/workers/view/${workerID}/negative-actions/list`}>
              <Button>Back to negative actions</Button>
            </Link>
          ) : (
            <Link to={`/entity/workers/view/${workerID}/strikes/list`}>
              <Button>Back to strikes</Button>
            </Link>
          )}
        </ButtonGroup>{' '}
      </div>
      <div className="right">
        <LoadingIndicator loadingState={isSavingData || isLoadingData} />{' '}
        <ButtonGroup>
          <Button kind="success" onClick={saveAction} disabled={isSavingData}>
            Save
          </Button>
        </ButtonGroup>
      </div>
    </RecordControls>
  )
}

export const storeConnector = connect(
  ({ workerStrikes: { isSavingData, isLoadingData } }) => ({
    isSavingData,
    isLoadingData,
  }),
  dispatch => ({
    actions: bindActionCreators(workerStrikesActions, dispatch),
  }),
)
export default storeConnector(WorkerStrikeDetailsControls)
