// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import React from 'react'

import FeedbackList from 'syft-acp-core/entities/FeedbackList'
import PageView from 'syft-acp-core/components/PageView'
import SubNavigationWatchlist from './SubNavigation'

export const WatchlistFeedback = () => (
  <>
    <SubNavigationWatchlist />
    <PageView title={['Watchlist', 'User feedback']} entityPage>
      <FeedbackList />
    </PageView>
  </>
)
