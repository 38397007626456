import React, { useEffect } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import { fetchUserBanReasons } from 'syft-acp-core/store/users/actions'
import { ConductReviewEntity } from 'syft-acp-core/store/types'

import { DataBanReasonsProps as Props } from './DataBanReasons.types'

export const storeConnector = connect(
  state => ({
    banReasons: state.users.userBanReasons,
  }),
  dispatch => ({
    actions: bindActionCreators({ fetchUserBanReasons }, dispatch),
  })
)

/**
 * Takes a list of reasons and formats them to use display names retrieved from the backend.
 */
const formatBanReasons = (reasons: ConductReviewEntity['reasons'], banReasons: Props['banReasons']) => {
  if (!banReasons) return reasons.join(', ')
  const reasonMap = {} as Record<string, string>
  banReasons.forEach(reason => {
    reasonMap[reason.code] = reason.display_name
  })
  return reasons.map(reason => reasonMap[reason]).join(', ')
}

const DataBanReasons = ({ actions, data, banReasons }: Props) => {
  useEffect(() => {
    if (!banReasons) actions.fetchUserBanReasons()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return <div className="plain-text">{formatBanReasons(data.reasons || [], banReasons)}</div>
}

export default storeConnector(DataBanReasons)
