import React from 'react'
import { Link } from 'react-router-dom'
import * as structureTypes from 'syft-acp-core/components/EditableTable'
import { hideAllAreasOrVenues } from '../helpers'

export const jobStructure = (timeDependenceTimes = [], selectedAreaId) => [
  [
    'id',
    {
      header: 'Job ID',
    },
  ],
  [
    'purchase_order_number',
    {
      header: 'Purchase order number',
      editable: true,
    },
  ],
  [
    'desc',
    {
      header: 'Description',
      editable: true,
    },
  ],
  [
    'skill_ids',
    {
      header: 'Skills',
      editable: true,
      type: structureTypes.TYPE_JOB_SKILLS,
    },
  ],
  [
    'open_to_discuss_competencies',
    {
      header: 'I am flexible',
      editable: true,
      type: structureTypes.TYPE_CHECKBOX,
    },
  ],
  [
    'bookable_individually',
    {
      header: 'Bookable individually',
      editable: true,
      type: structureTypes.TYPE_CHECKBOX,
    },
  ],

  ['area.id', { type: structureTypes.TYPE_SEPARATOR, hideIfEmpty: true }],
  [
    'areas',
    {
      type: structureTypes.TYPE_AREAS_TABS,
      header: 'Areas',
      hideIfEmpty: true,
      hide: hideAllAreasOrVenues('area'),
    },
  ],
  [
    'area.name',
    {
      header: 'Area',
      type: structureTypes.TYPE_LINK,
      getData: data => `${data.area.name} (${data.area.id})`,
      parameters: {
        to: data =>
          `/entity/employers/view/${data.employerID}/venues/view/${data.venueID}/areas/view/${data.area.id}`,
      },
      editable: false,
      hideIfEmpty: true,
      hide: data => !hideAllAreasOrVenues('area')(data),
    },
  ],
  [
    'area.access_instructions',
    {
      header: 'Access instructions',
      editable: false,
      hide: data => !hideAllAreasOrVenues('area')(data),
      hideIfEmpty: true,
    },
  ],
  [
    'area.access_instructions',
    {
      header: 'Access instructions',
      getData: data => {
        if (selectedAreaId) {
          return data?.areas?.find(area => area.id === selectedAreaId)?.access_instructions
        }
        return data?.areas?.[0]?.access_instructions
      },
      hide: hideAllAreasOrVenues('area'),
      editable: false,
    },
  ],
  [
    'area',
    {
      header: '',
      getData: data => (
        <Link
          to={`/entity/employers/view/${data?.employerID}/venues/view/${data?.venueID}/areas/view/${
            selectedAreaId || data?.areas?.[0].id
          }`}
        >
          Link to Area
        </Link>
      ),
      editable: false,
      hide: data => hideAllAreasOrVenues('area')(data),
    },
  ],
  ['area.id', { type: structureTypes.TYPE_SEPARATOR, hideIfEmpty: true }],
  [
    'remote',
    {
      header: 'Remote job',
      type: structureTypes.TYPE_CHECKBOX,
      editable: true,
    },
  ],
  [
    'shift_rate_type.name',
    {
      header: 'Shift type',
      editable: false,
      hideIfEmpty: true,
    },
  ],
  [
    '',
    {
      header: 'Job dependent rates',
    },
  ],
  ...timeDependenceTimes.map((item, idx) => {
    const { start, end, label } = item?.time_dependent_rate | {}
    const suffix = start && end ? `${label} (${start} - ${end})` : label
    const highlightSufix = item.selected ? <b>{suffix}</b> : suffix
    const header = ''
    return [
      `job_time_dependent_rates[${idx}].pay_rate`,
      {
        header,
        type: structureTypes.TYPE_RATE,
        suffix: highlightSufix,
        showZero: false,
        editable: false,
      },
    ]
  }),
]
