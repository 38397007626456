import * as React from 'karet'
import * as U from 'karet.util'
import * as R from 'kefir.ramda'
import * as L from 'kefir.partial.lenses'
import { useLayoutEffect } from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import ReactTooltip from 'react-tooltip'
import { isString, isFunction, isBoolean, get, isArray, keys, noop, includes } from 'lodash-es'

import Table from 'syft-acp-atoms/Table'

import { AbbrHeader, HeaderSelector } from './headerComponents'
import { AcpNoDataBlock, AcpNoDataInline, AcpErrorInline } from './notifications'
import { getFooter } from './childNodes'
import { randomLabel } from '../../lib/random'
import * as cellComponents from './cellComponents/dataTypes'
import AcpCellPlaceholder from './AcpCellPlaceholder'
import AcpCellText from './AcpCellText'
import CellContainer from './cellComponents/CellContainer'

import './AcpTable.scss'

/**
 * Placeholder string for empty cells. This can be seen as a 'null' rather than an empty string.
 * The actual string is an en dash.
 */
export const placeholderString = '–'

/** List of accepted colors for rows and cells. */
export const tableColors = ['none', 'red', 'orange', 'yellow', 'green', 'cyan', 'gray']
export const tableDefaultColor = 'none'

/** Number of rows to display at the least for minimal-sized components. */
const tableMinRows = 5

/** FIXME. Check if an item is a Combine(). */
const isCombine = obj => obj && obj._activating != null && !obj.get

// If there are fewer rows than this amount (whether populated with data or not),
// we will display a small inline error message instead of a large floating one.
const smallNotificationTreshold = 3

/**
 * Checks to see whether the current row needs to be hidden,
 * based on the table's 'hideIfNull' value.
 */
const shouldHideRow = (hideIfNull, row = {}) => {
  if (!hideIfNull) return false
  const val = row[hideIfNull]
  return val == null
}

/**
 * Returns the content to be placed in a column header cell.
 * This returns an object with the actual 'content' as an item,
 * and 'isText' as a boolean.
 */
const getHeaderContent = (item, { pageSelectionLens }, data) => {
  // Return plain strings.
  const headerValue = item.header != null ? item.header : placeholderString

  // The 'select all' checkbox for Acp.Col.Selector nodes.
  if (['AcpCellSelector', 'AcpCellSelectorLegacy'].includes(item._type)) {
    return {
      content: (
        <HeaderSelector
          tableData={data}
          rowState={{ pageSelectionLens }}
          isSubRow={item._subRow}
          value={item.headerValue}
          scope={item.options?.scope}
          valueKeys={item.options?.valueKeys}
        />
      ),
      isText: false,
    }
  }

  if (isString(headerValue) && headerValue !== '') {
    return { content: headerValue, isText: true }
  }

  return { content: headerValue, isText: false }
}

/** Returns a list of CSS classes to add to the column. */
const getColClasses = (spec, isHeader = false) => {
  const colClasses = []
  if (spec.isNumeric) colClasses.push('is-numeric')
  if (spec.isMinimal) colClasses.push('is-minimal')
  if (spec.isMain) colClasses.push('is-main')
  if (spec.isTags) colClasses.push('is-tags')
  if (spec.hasLeftBorder) colClasses.push('has-left-border')
  // Headers normally don't have text wrapping, unless 'headerWrapping' is passed.
  if (isHeader && spec.headerWrapping) colClasses.push('allow-wrapping')
  if (spec.hasNoneStyles) colClasses.push('acp-table-none-border')
  // We pass on the 'align' value from the spec, but only for headers;
  // for table cells, we might also use the cell component's preferred alignment.
  if (isHeader && spec.align) colClasses.push(`align-${spec.align}`)
  return colClasses
}

/**
 * Returns the actual data to display inside a cell, taken from the table definition.
 *
 * In the table definition, a 'value' option is either a string, a function, or an array of strings.
 * If it's a function, we pass it the full row data and use the result as content.
 * If the value is a string, we pass on the row's data by that key; when an array is passed,
 * we send an object containing the multiple values from the row's data.
 *
 * Note that some items may not get a value, e.g. a cell containing a button.
 * Items that do not get a value get passed 'null' instead.
 *
 * TODO: improve documentation.
 * When a subrow is being queried, a 'subRowN' value is passed as well.
 * This assumes there's a 'parent' value as well containing a path to an array of objects.
 */
const getCellData = (item, row, attrName = 'value', value = null, subRowN = null, parentAttrName = null) => {
  const isSubRow = parentAttrName != null

  // Get the value, assuming the attribute is a plain string value.
  const cellValue = value || get(item, attrName, '')
  // Note: 'parentValue' should always be an array of objects if it exists.
  const parentValue = get(row, parentAttrName, [])

  // In regular rows, the data we need to fetch is inside 'row', which is an object.
  // In subrows, the data is actually inside one of row's values.
  // Determine what the parent is.
  const parentData = isSubRow ? get(parentValue, subRowN, {}) : row

  if (isFunction(cellValue)) {
    // Call the provided function and return the result as data.
    // For subrows, we send along a copy of the current subrow's data too.
    return cellValue.call(null, row, get(parentValue, subRowN, null), subRowN, parentAttrName)
  }

  if (isArray(cellValue)) {
    // Return an object with all keys provided by the array.
    // We don't pass on the 'n' value for these,
    // since array values aren't used for subrows.
    return cellValue.reduce((all, v) => ({ ...all, [v]: get(parentData, v, null) }), {})
  }

  // In the case of a simple string, return the single value plain.
  const singleValue = get(parentData, cellValue, null)
  return singleValue
}

/**
 * Returns the link that a row leads to when clicked.
 * Works like getCellData() and supports a value string or a function.
 * The 'n' is the index number used for subrows. It's null for main rows.
 *
 * If the link is null, no link will be added to the DOM.
 */
const getRowData = (row, value, n = null) => getCellData(null, row, null, value, n)

/**
 * Returns the component to be used for rendering a specific cell.
 * If an editable component is needed but it doesn't exist, the read-only
 * component will be used instead. If the cell requests a type that
 * does not exist, this throws.
 */
const getCellComponent = item => {
  const cellComponent = get(cellComponents, item._type, false)
  if (cellComponent === false) {
    throw TypeError(
      `AcpTable: a column requested cell type "${String(item._type)}" which does not exist or is not implemented yet.`
    )
  }
  return cellComponent
}

/**
 * Returns the tooltip for a table cell.
 * Either we use the one generated by the component itself,
 * or we use the value generated from 'valueTooltip' earlier.
 */
const getCellTooltip = (spec, value, valueTooltip, options, CellComponent) => {
  // If we're rendering multiple values (subrows), return multiple values for tooltips too.
  const multipleValues = isArray(value)

  // If we ask for the cell component's own tooltip (i.e. only pass 'withTooltip' and no 'valueTooltip'),
  // then run its tooltip generator with the regular value.
  if (spec.withTooltip && CellComponent.getTooltip && !spec.valueTooltip) {
    return multipleValues
      ? value.map(subValue => CellComponent.getTooltip(subValue, options))
      : CellComponent.getTooltip(value, options)
  }
  // If the user indicated a 'valueTooltip', return that (we've already calculated it).
  if (spec.valueTooltip) {
    return valueTooltip
  }
  return null
}

/**
 * Returns the copyable text for a table cell.
 *
 * Either we use the one generated by the component itself,
 * or we use the value generated from 'valueCopy' earlier.
 */
const getCellCopytext = (spec, value, valueCopy, options, CellComponent) => {
  // If the user indicated a 'valueCopy', return that (we've already calculated it).
  if (spec.valueCopy) {
    return valueCopy
  }
  if (spec.isCopyable) {
    if (CellComponent.getCopytext) {
      // If the cell has a specific way to generate the copyable text, return that.
      return CellComponent.getCopytext(value, options)
    } else {
      // Otherwise, return the plain value as a string (to avoid console.errors)
      return value ? value.toString() : value
    }
  }
  return null
}

/**
 * Used by e.g. rowColor - runs function if it is one, or gets a plain value.
 */
const handleFnOrPlain = (specValue, ...args) => {
  if (isFunction(specValue)) return specValue(...args)
  return specValue
}

/**
 * Returns the table cell text alignment. We either use the alignment
 * provided by the table spec, or the component's preferred alignment.
 * For example, the boolean cell type prefers to be centered.
 */
const getCellAlignment = (spec, CellComponent) => {
  // If the user specified 'align' in the table definition, use that.
  if (spec.align) {
    return `align-${spec.align}`
  }
  // If the user did not specify anything, but the cell component
  // has a preferred alignment, use that.
  if (CellComponent.preferredAlignment) {
    return `align-${CellComponent.preferredAlignment}`
  }
  return null
}

/** Returns the amount of subrows we'll display in this row. */
const rowMaxSubrows = rowRenderedData => {
  // Runs through the cells and returns the max number of values to display.
  return rowRenderedData.reduce((currMax, cell) => {
    const amount = get(cell, 'subRowItems', 0)
    return Math.max(amount, currMax)
  }, 1)
}

/**
 * Returns a color value string to be used on either a row or a cell.
 *
 * The color has to be one of our accepted values; otherwise null is returned.
 */
const tableColor = (reqColor, useDefault = false) => {
  // Note: accept all colors except 'white'. The proper color for that is "none".
  // This is also necessary to support the dark theme.
  const color = reqColor === 'white' ? 'none' : reqColor
  const colorValue = color || (useDefault ? tableDefaultColor : null)
  if (!colorValue) return null
  if (!tableColors.indexOf(colorValue) > 1) return null
  return `acp-color-${colorValue}`
}

/** Returns a selected class if true. */
const tableSelected = selectedValue => {
  return U.when(R.equals(selectedValue, true), 'acp-selected')
}

/**
 * Returns the content of a table header cell, wrapped in a text container
 * if it's text, or wrapped in an abbreviation component if needed.
 */
const wrapHeader = (item = {}, colContentInfo = {}) => {
  /**
   * This is the amount of characters necessary for a table header to rely on
   * its own text for an aria-label value rather than the headerTooltip prop
   */
  const HEADER_TEXT_LABEL_THRESHOLD = 3

  if (item.headerAbbr) {
    return <AbbrHeader label={item.headerTooltip}>{colContentInfo.content}</AbbrHeader>
  }
  if (colContentInfo.isText) {
    // If the header text is shorter in length than the threshold then set the
    // aria-label as the tooltip value. This is useful for ASCII symbols, acronyms, etc.
    const label = colContentInfo.content.length < HEADER_TEXT_LABEL_THRESHOLD ? item.headerTooltip : undefined
    return <AcpCellText label={label}>{colContentInfo.content}</AcpCellText>
  }
  return item.headerTooltip ? (
    <div aria-label={item.headerTooltip}>{colContentInfo.content}</div>
  ) : (
    colContentInfo.content
  )
}

/** Returns a cell to display inside a col group. */
const wrapColGroup = (item = {}, usePlaceholder = true) => {
  return <AcpCellText isColGroup>{item._colGroup || (usePlaceholder ? placeholderString : '')}</AcpCellText>
}

/** Iterates over table rows with ID if available. */
const iterateRows = (data, isAtom, idKey, fn) => {
  if (isAtom) {
    // Lensed data require Karet's utils for mapping.
    if (idKey) return U.mapElemsWithIds(idKey, fn, data)
    else return U.mapElems(fn, data)
  } else {
    // Regular data simply uses the map() method.
    // FIXME
    if (isCombine(data)) return []
    return data.map(fn)
  }
}

/** Returns a lens for a particular value from a data row. */
const makeLens = (value, rowAtom) => {
  // Split the value up so we can go as deep in the atom as needed.
  // E.g. 'sales_win_associations.fee_percent' needs to go two levels deep.
  const valueChain = value.split('.')
  return valueChain.reduce((lens, valueItem) => U.view(valueItem, lens), rowAtom)
}

/** Returns a value (link, color, selected) to be used for a table cell, based on which overrides it has. */
const chooseCellValue = (cellValueFn, subRowValueFn, rowValue, n) => {
  if (cellValueFn) return cellValueFn(n)
  if (subRowValueFn) return subRowValueFn(n)
  return rowValue
}

/**
 * Table component used for displaying and editing admin panel data.
 * This component is flexible and can display many different kinds of data.
 * See the ACP Lib README.md file for a number of usage examples.
 *
 * The <Acp.Table /> component itself takes the following attributes:
 *
 *    data             - an array of data to display as table rows (or an object; results in a "no data" message being shown)
 *    hasMinimumSize   - whether to display a minimum number of rows (boolean to use default; number to use a specific amount)
 *    hasTopSection    - whether this table is part of a component that has a top section (a header or action section)
 *    hasBottomSection - as above, true if there's a footer section in the component
 *    hideIfNullKey    - checks each row for whether this key is null and hides the whole row if so (use this with "id")
 *    idKeyValue       - the value we'll use as unique ID; usually 'id'
 *    isIntegrated     - whether the component has a block component wrapper; determines styling
 *    isStale          - whether we're displaying old data from a previous request (usually briefly true while waiting for new data to arrive)
 *    maxRows          - maximum number of rows to display, if not overridden by the data array length
 *    rowLink          - provides the value for a row's link (similar to the 'value' column attribute); can be a function too, and can be overridden by a column
 *    rowColor         - provides the color for a row, provided by a function that can return one of the valid color values (red, orange, yellow, green, cyan, gray)
 *    rowSelected      - sets a row to selected (same as hover state); takes a value or function that needs to be either true or false; does not get overridden by the cells
 *    rowSelection     - [deprecated] contains a list of what rows are selected and on what data value we are matching
 *
 * Describing the data is done with the <Acp.Col /> component. It supports a range of formatting options.
 * All column types support the following attributes:
 *
 *    align            - the direction the content of the cell should be aligned to
 *    colLink          - provides the value for the col's link (overrides a row's link value)
 *    colNoLink        - ensures the col has no link at all
 *    colNoCallback    - ensures the col has no callback at all
 *    header           - the text displayed in the table header for this column
 *    headerAbbr       - displays the header inside a circle (use for single letter headers)
 *    headerTooltip    - displays a tooltip when hovering over the column header
 *    headerWrapping   - allows the header text to wrap (normally it never does)
 *    hasNoneStyles    - allows to render raw table without styles
 *    isMain           - grows the column to maximum size (used for the most important data column)
 *    isMinimal        - makes the column as small as possible (without wrapping the contents)
 *    isNumeric        - used for numeric columns - aligns the text to the right
 *    isCopyable       - allows the value to be copied
 *    options          - object of options passed on to the formatting component
 *    type             - determines the component used to render the data
 *    value            - the name of the value we'll display in this column - can be either a string ("id", "worker.id", etc.) or a function that returns the cell's output
 *    valueTooltip     - displays a tooltip when hovering over the cell - as 'value', this can be a string referring to a data value, or a function
 *    valueCopy        - as 'value' and 'valueTooltip', this allows a specific value to be copied
 *    withTooltip      - attaches a tooltip made by the component to the table cell
 *
 * For a full list of values for the 'type' attribute, see 'syft-acp-uikit/AcpTable/cellComponents/colTypes.js'.
 *
 * Finally, a number of <Acp.Component /> attributes affect the table:
 *
 *    resultCount     - displays the result count at the top of the table
 *    innerPagination - displays the pagination buttons inside the wrapper (for detail page components; overview list components should not use this)
 *
 * This replaces the old EntityList component and can be used for both displaying data and editing it.
 */
const AcpTable = ({
  children,
  reloadLastCall,
  dataDefinition,
  hasMinimumSize,
  hasTopSection,
  hasBottomSection,
  isIntegrated,
  isStale,
  isLoading,
  maxRows,
  data,
  stateSelection,
  stateSubSelection,
  rowSelection,
  rowLink,
  rowColor,
  rowSelected,
  rowCallback,
  hideIfNullKey,
  idKeyValue,
  notFoundMessage,
  callbackAndLink,
}) => {
  // Same, but retrieve only the footer. If there are multiple <AcpFooter /> elements, this will throw.
  const tableFooter = getFooter(children)

  // Generate a random value for this table so that our tooltip ID is unique.
  const tableID = `tooltip-${randomLabel()}`

  // Check whether the data is an atom or not; atoms are a more efficient data format.
  const isAtom = data.get != null

  /** Function that returns the table's complete data. */
  const getTableData = () => (isAtom ? data.get() : data)

  // FIXME: sometimes the tooltip does not show up, and the only way to get it to show up
  // is to rebuild() every active tooltip after the component has finished rendering,
  // and after the screen has been updated. This is the simplest way to do it.
  useLayoutEffect(() => {
    const id = setTimeout(ReactTooltip.rebuild, 0)
    return () => clearTimeout(id)
  })

  // If any column has a _colGroup defined, all other columns must also show a column group.
  // Columns that don't have a column group title will get a blank one.
  const hasColGroups = dataDefinition.find(item => includes(keys(item), '_colGroup')) != null

  // Minimum number of rows to display; if we're showing a table with a minimum size set, this value is larger.
  const minRows = hasMinimumSize ? (isBoolean(hasMinimumSize) ? tableMinRows : hasMinimumSize) : 1
  const rowBaseCount = hasMinimumSize ? minRows : maxRows

  // Selection lens for the whole page.
  const allIDs = R.map(n => n.id, data)
  const pageSelectionLens = U.view([L.define([]), L.is(allIDs)], stateSelection)

  return (
    <div className={classNames('acp-table', { 'has-old-data': isStale })} data-testid="entity-list-table">
      <ReactTooltip effect="solid" id={tableID} />
      <Table
        className={classNames('acp-table-inner', { isIntegrated, isNotIntegrated: !isIntegrated })}
        hasTopMargin={false}
        hasBottomMargin={false}
        hasTopBorder={!hasTopSection}
        hasBottomBorder={!hasBottomSection}
        hasLeftBorder={!isIntegrated}
        hasRightBorder={!isIntegrated}
        hasTopLeftRounding={!hasTopSection}
        hasTopRightRounding={!hasTopSection}
        hasBottomLeftRounding={!hasBottomSection}
        hasBottomRightRounding={!hasBottomSection}
      >
        <thead>
          {
            // Column groups
            // Only display this extra row if we have any col groups to display.
            hasColGroups && (
              <tr>
                {
                  // Go over all table columns and render the groups.
                  dataDefinition.map((item, n) => {
                    if (item._colGroupMember) return null
                    const colGroupCell = wrapColGroup(item)
                    return (
                      <th key={`colGroup.${n}`} colSpan={item._colGroupItems + 1}>
                        {colGroupCell}
                      </th>
                    )
                  })
                }
              </tr>
            )
          }
          <tr>
            {
              // Column headers
              // Begin rendering the table columns containing the data definition labels.
              dataDefinition.map((item, n) => {
                const colClasses = getColClasses(item, true)
                const colContentInfo = getHeaderContent(item, { pageSelectionLens }, data)
                const headerCell = wrapHeader(item, colContentInfo)
                if (item.header == null && !item._hasForcedHeader && n > 0) return null
                return (
                  <th
                    key={n}
                    colSpan={item._subsequentEmptyHeaders + 1}
                    className={colClasses.join(' ')}
                    // Adds a tooltip to the column header if an 'headerTooltip' was added to the <AcpCol />.
                    {...(item.headerTooltip
                      ? {
                          'data-tip': item.headerTooltip,
                          'data-for': tableID,
                        }
                      : {})}
                  >
                    {headerCell}
                  </th>
                )
              })
            }
          </tr>
        </thead>
        {
          // Placeholder rows
          // These are displayed when loading, or after loading has finished but there are no rows to display.
          // If loading is complete and there are no rows, a notification will be included.
          U.ifElse(
            R.equals(R.length(data), 0),
            U.mapElems(
              (_, n) => (
                // Remove empty rows, cells, etc. from accessibility tree
                <tbody aria-hidden="true" key={`noData.${n}`}>
                  <tr>
                    {
                      // Render the placeholder rows, potentially with a 'no data' row at the top.
                      // If there are fewer rows than a threshold, the notification will be inline in the top row.
                      U.ifElse(
                        R.and(R.equals(isLoading, false), R.equals(n, 0)),
                        U.ifElse(
                          // If we have only a few rows to display, show an inline notification.
                          // Otherwise, show a larger one (inside of a hidden row).
                          R.lt(rowBaseCount, smallNotificationTreshold),
                          <td colSpan={dataDefinition.length}>
                            <AcpNoDataInline hideReloadIfNull reloadLastCall={reloadLastCall} />
                          </td>,
                          <td colSpan={dataDefinition.length} className="acp-table-nodata-block">
                            <AcpNoDataBlock
                              rowCount={rowBaseCount}
                              isIntegrated={isIntegrated}
                              hasColGroups={hasColGroups}
                              reloadLastCall={reloadLastCall}
                              notFoundMessage={notFoundMessage}
                            />
                          </td>
                        ),
                        U.mapElems(
                          (_, m) => (
                            <td key={`noData.${n}.${m}`}>
                              <AcpCellPlaceholder
                                typeGroup={get(dataDefinition[m], '_typeGroup', null)}
                                type={get(dataDefinition[m], '_type', null)}
                              />
                            </td>
                          ),
                          R.range(0, dataDefinition.length)
                        )
                      )
                    }
                  </tr>
                </tbody>
              ),
              R.range(
                0,
                U.ifElse(
                  // TODO: document.
                  // We need one extra placeholder row if we're including a block level 'no data' message.
                  // But if we're loading, we'll never display such a message.
                  R.equals(isLoading, false),
                  U.ifElse(R.lt(rowBaseCount, smallNotificationTreshold), rowBaseCount, rowBaseCount + 1),
                  rowBaseCount
                )
              )
            ),
            null
          )
        }
        {
          // Error message
          // Displayed in case an error occurred, instead of the table rows (next section).
          U.ifElse(
            R.equals(U.view(['length'], data), undefined),
            <tbody key="noData.error">
              <tr>
                <td colSpan={dataDefinition.length}>
                  <AcpErrorInline
                    messageError={<>Error: {U.or(U.view('error_description', data), 'No error message')}</>}
                  />
                </td>
              </tr>
            </tbody>,
            null
          )
        }
        {
          // Table rows
          // An important detail to be kept in mind is that we have multiple <tbody> tags, one for each row.
          // This is so we can have sub-rows and utilize highlighting for both of them.
          U.unless(
            R.equals(U.view(['length'], data), undefined),
            iterateRows(data, isAtom, idKeyValue, (rowAtom, m) => {
              const isAtomValue = !!rowAtom.get
              const plainRowRender = U.lift((rowPlain, n, selectData) => {
                // Check if we need to hide this row (based on the table's hideIfNullKey value).
                if (shouldHideRow(hideIfNullKey, rowPlain)) {
                  return null
                }
                // If the data is an atom, fetch its value for rendering the cells.
                // This value is only passed to the component if it's read-only.
                // Editable cells get the atom itself passed on.
                const rowData = rowPlain

                // Retrieve or generate row level data.
                const rowLinkValue = getRowData(rowData, rowLink)
                const rowColorValue = getRowData(rowData, rowColor)
                const rowSelectedValue = getRowData(rowData, rowSelected)

                const rowValueID = rowData[idKeyValue] || rowData.id

                // Run through all the cells in this row and generate their data.
                const rowRenderedData = dataDefinition.map(spec => {
                  // Whether this component is editable.
                  const isEditable = !!spec.isEditable

                  // The value is the primary data that we want to display,
                  // taken from the table data based on what value this cell needs to display.
                  // The valueTooltip works the same way.
                  const value = getCellData(spec, rowData, 'value')
                  const valueTooltip = getCellData(spec, rowData, 'valueTooltip')
                  const valueCopy = getCellData(spec, rowData, 'valueCopy')

                  // Generate a view for the atom if this cell is editable.
                  // Editable cells can only have a plain string value; not a function or array.
                  const valueAtom = isAtomValue && isEditable ? makeLens(spec.value, rowAtom) : null
                  if (isEditable && !isString(spec.value)) {
                    throw TypeError(
                      `AcpTable: editable cells may only have a plain string for their "value" attribute. Type: "${String(
                        spec._type
                      )}"; value: "${String(spec.value)}".`
                    )
                  }

                  // Additional options, if specified.
                  const options = get(spec, 'options', {})

                  // These are CSS classes that change the way this cell is displayed,
                  // e.g. to minimize its size or change the text indentation if it's a numeric column.
                  const colClasses = getColClasses(spec, false)
                  // This is the component that will be used to render the cell.
                  const CellComponent = getCellComponent(spec)

                  // Check whether the cell component has special properties to pass on to the container.
                  const cellProperties = CellComponent.cellProperties || []

                  // Some components can generate a tooltip; do so here and attach it to the table cell.
                  const tooltipContent = getCellTooltip(spec, value, valueTooltip, options, CellComponent)
                  // Some components have a preferred alignment.
                  const cellAlignment = getCellAlignment(spec, CellComponent)
                  // Some components can have their content copied; retrieve the copyable value.
                  const copyContent = getCellCopytext(spec, value, valueCopy, options, CellComponent)

                  // Whether this item is a subrow container and what data object it points to.
                  const isSubRow = !!spec._subRow
                  // TODO: allow functions for subrows
                  const subRowValue = get(spec, '_subRowValue')
                  // If this is a subrow, see how many items we need to print.
                  const subRowItems = subRowValue ? get(rowData, `${subRowValue}.length`, 0) : null

                  // If this is a subrow, check if we've defined any of the three row specifiers.
                  // Rather than pre-calculating the value, we'll return a function that is then called
                  // with the subrow's data, its subrow number and the full row data.
                  const subRowData = i => get(rowData, `${subRowValue}.${i}`, null)
                  const subRowValueFn = subRowValue
                    ? i => getCellData(spec, rowData, 'value', null, i, subRowValue)
                    : null
                  const subRowValueTooltipFn = subRowValue
                    ? i => getCellData(spec, rowData, 'valueTooltip', null, i, subRowValue)
                    : null
                  const subRowLinkFn = spec._subRowLink
                    ? i => handleFnOrPlain(spec._subRowLink, subRowData(i), rowData, i)
                    : null
                  const subRowColorFn = spec._subRowColor
                    ? i => handleFnOrPlain(spec._subRowColor, subRowData(i), rowData, i)
                    : null
                  const subRowSelectedFn = spec._subRowSelected
                    ? i => handleFnOrPlain(spec._subRowSelected, subRowData(i), rowData, i)
                    : null

                  // Create a function for getting a cell's link or color override.
                  // This is needed for subrows, so we can pass on the index number.
                  // For main rows we'll pass null as the index.
                  // Note: if colNoLink is set, we'll set the a no-op as colLink.
                  // Setting colNoLink is equivalent to setting colLink={ () => null }.
                  const colLinkFnOrig = spec.colNoLink ? noop : spec.colLink
                  const colLinkFn = colLinkFnOrig ? i => getRowData(rowData, colLinkFnOrig, i) : null
                  const colColorFn = spec.colColor ? i => getRowData(rowData, spec.colColor, i) : null

                  return {
                    CellComponent,
                    cellAlignment,
                    cellProperties,
                    colClasses,
                    colColorFn,
                    colLinkFn,
                    copyContent,
                    isEditable,
                    isSubRow,
                    options,
                    spec,
                    subRowData,
                    subRowItems,
                    subRowValueFn,
                    subRowValueTooltipFn,
                    subRowColorFn,
                    subRowLinkFn,
                    subRowSelectedFn,
                    tooltipContent,
                    value,
                    valueAtom,
                  }
                })

                // See if we need to render subrows. If no subrows are present, we'll render just one <tr>.
                // If subrows are present, we'll render multiple <tr>s and the non-subrows will have a rowSpan
                // equal to the max number of subrows. The resulting value is a minimum of 1.
                const rowSpanValue = rowMaxSubrows(rowRenderedData)

                // FIXME: either this or 'rowSelected'.
                // FIXME: Deprecated.
                // Check whether this row is currently selected.
                const isRowSelected__DEPRECATED =
                  selectData && selectData.items ? selectData.items.indexOf(n) > -1 : false

                // Checks whether this row is currently selected.
                const rowSelectionLens = U.view([L.find(R.equals(rowValueID)), L.is(rowValueID)], stateSelection)

                // Whether the current row is selected--either by a selector column checkbox or through its rowSelected function.
                const isRowSelectedAny = R.or(R.or(rowSelectionLens, rowSelectedValue), isRowSelected__DEPRECATED)

                return (
                  <tbody key={`tbody.${n}`}>
                    {R.map(subRowN => {
                      // Lens for selecting subrows.
                      const subRowValueID = `${rowValueID}.${subRowN - 1}`
                      const rowSubSelectionLens = U.view(
                        [L.find(R.equals(subRowValueID)), L.is(subRowValueID)],
                        stateSubSelection
                      )
                      return (
                        <tr
                          key={`row.${n}.${subRowN}`}
                          data-testid="entity-list-row"
                          className={U.cns(
                            U.when(R.gt(subRowN, 1), 'noLeftBorder'),
                            tableColor(rowColorValue, true),
                            U.when(isRowSelectedAny, 'acp-selected'),
                            'acp-item'
                          )}
                        >
                          {
                            // Render data for each column.
                            U.mapElems((renderedData, columnN) => {
                              const { CellComponent, cellAlignment, cellProperties } = renderedData
                              const { colClasses, colColorFn, colLinkFn } = renderedData
                              const {
                                subRowValueFn,
                                subRowColorFn,
                                subRowLinkFn,
                                subRowValueTooltipFn,
                                subRowSelectedFn,
                                subRowData,
                              } = renderedData
                              const {
                                copyContent,
                                isEditable,
                                isSubRow,
                                options,
                                spec,
                                tooltipContent,
                                value,
                                valueAtom,
                              } = renderedData

                              // If this column type doesn't display subrows, and we've already rendered the first row, render nothing here.
                              // This forces the non-subrow columns to display only one value and span multiple rows.
                              if (!isSubRow && subRowN > 1) {
                                return null
                              }

                              // We've already calculated the link and color for the whole row,
                              // but if this is a subrow with a specifier function we can override it.
                              // This allows the <Acp.SubRows rowColor={ ... }> etc. functions to override the other values.
                              // Similarly, the subrow value (and row value) can be overridden by a cell-specific value.
                              // Only the 'selected' value can't be overridden on a per cell basis.
                              const cellLinkValue = chooseCellValue(colLinkFn, subRowLinkFn, rowLinkValue, subRowN - 1)
                              const cellColorValue = chooseCellValue(
                                colColorFn,
                                subRowColorFn,
                                rowColorValue,
                                subRowN - 1
                              )
                              const cellSelectedValue = subRowSelectedFn ? subRowSelectedFn(subRowN - 1) : null
                              // Clicking fires row callback unless the cell has a link
                              const cellCallbackValue =
                                (cellLinkValue && !callbackAndLink) || spec.colNoCallback ? null : rowCallback
                              // Determine cell properties for the container.
                              const isPure = cellProperties.includes('pure')
                              const isForm = cellProperties.includes('form')
                              const isPopover = cellProperties.includes('popover')
                              // Render the column, with multiple values for subrows.
                              const cellValue = isSubRow ? subRowValueFn(subRowN - 1) : value
                              const cellTooltipContent = isSubRow ? subRowValueTooltipFn(subRowN - 1) : tooltipContent
                              const cellClass = classNames({
                                'is-main-cell': !isSubRow,
                                'is-sub-cell': isSubRow,
                              })
                              return (
                                <td
                                  rowSpan={isSubRow ? 1 : rowSpanValue}
                                  key={`column.${columnN}`}
                                  className={
                                    spec.hasNoneStyles
                                      ? 'acp-table-none-border'
                                      : U.cns(
                                          ...colClasses,
                                          cellClass,
                                          cellAlignment,
                                          tableColor(cellColorValue, isSubRow),
                                          U.when(R.and(rowSubSelectionLens, isSubRow), 'acp-selected'),
                                          tableSelected(cellSelectedValue),
                                          'acp-cell',
                                          'acp-ctarget'
                                        )
                                  }
                                  // Adds a tooltip to the cell itself.
                                  {...(cellTooltipContent
                                    ? {
                                        'data-tip': cellTooltipContent,
                                        'data-for': tableID,
                                      }
                                    : {})}
                                >
                                  <CellContainer
                                    callback={
                                      cellCallbackValue &&
                                      (() =>
                                        cellCallbackValue({
                                          rowN: n,
                                          subRowData: isSubRow && subRowData(subRowN - 1),
                                          rowData,
                                          columnN,
                                        }))
                                    }
                                    callbackAndLink={callbackAndLink}
                                    isCopyable={copyContent}
                                    isPure={isPure}
                                    isForm={isForm}
                                    isPopover={isPopover}
                                    link={cellLinkValue}
                                    hasCellLink={!!colLinkFn}
                                  >
                                    <CellComponent
                                      isEditable={isEditable}
                                      options={options}
                                      spec={spec}
                                      getTableData={getTableData}
                                      isSubRow={isSubRow}
                                      rowState={{ rowSelectionLens, rowSubSelectionLens }}
                                      value={valueAtom || cellValue}
                                      rowData={rowData}
                                      subRowData={subRowData(subRowN - 1)}
                                      link={cellLinkValue}
                                      tableID={tableID}
                                    />
                                  </CellContainer>
                                </td>
                              )
                            }, rowRenderedData)
                          }
                        </tr>
                      )
                    }, R.range(1, rowSpanValue + 1))}
                  </tbody>
                )
              })
              return <React.Fragment key={`tbodywrap.${m}`}>{plainRowRender(rowAtom, m, rowSelection)}</React.Fragment>
            })
          )
        }
        {
          // Footer
          // Only rendered if it's been configured.
          tableFooter.map((footer, n) => (
            <tfoot key={`footer-${n}`}>
              <tr>
                <th colSpan={dataDefinition.length} className="acp-table-footer">
                  <AcpCellText>{footer.children}</AcpCellText>
                </th>
              </tr>
            </tfoot>
          ))
        }
      </Table>
    </div>
  )
}

AcpTable.propTypes = {
  children: PropTypes.node,
  dataDefinition: PropTypes.array,
  reloadLastCall: PropTypes.func,
  hasMinimumSize: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
  rowSelection: PropTypes.object,
  rowColor: PropTypes.func,
  rowSelected: PropTypes.func,
  rowCallback: PropTypes.func,
  idKeyValue: PropTypes.string,
  notFoundMessage: PropTypes.string,
  // These are lenses:
  isLoading: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  stateSelection: PropTypes.object,
  stateSubSelection: PropTypes.object,
  // Source of information for the rows. Usually an array, but can be an object (if an error occurred).
  data: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  // Determines table styling in case it's inside a block component.
  hasTopSection: PropTypes.bool,
  hasBottomSection: PropTypes.bool,
  // Whether the data we're displaying is old (usually while waiting for new data).
  isStale: PropTypes.bool,
  // Use link with callback
  callbackAndLink: PropTypes.bool,
  // Hide table rows that have this key as null, regardless of what other data they contain.
  hideIfNullKey: PropTypes.string,
  // Whether this table is currently inside of a component wrapper.
  // If so, we don't display external borders, since the component will contain borders.
  isIntegrated: PropTypes.bool,
  // The maximum number of rows we display; if not overridden by the array length.
  maxRows: PropTypes.number,
  // The key used to get the row link, or the function that produces it (can be overridden by a column).
  rowLink: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
}

AcpTable.defaultProps = {
  children: null,
  reloadLastCall: null,
  hasMinimumSize: null,
  rowSelection: null,
  rowColor: null,
  rowSelected: null,
  callbackAndLink: false,
  rowCallback: null,
  idKeyValue: 'id',
  isLoading: null,
  stateSelection: null,
  stateSubSelection: null,
  dataDefinition: [],
  data: [],
  hasTopSection: false,
  hasBottomSection: false,
  isStale: false,
  hideIfNullKey: null,
  isIntegrated: false,
  maxRows: 25,
  rowLink: null,
  notFoundMessage: null,
}

// The AcpTable component has an identityName, despite not being a placeholder element.
// We need to be able to identify that it is inside an AcpComponent.
AcpTable.identityName = 'AcpTable'

export default AcpTable
