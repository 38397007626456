// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import React from 'react'
import PropTypes from 'prop-types'

import AbbrHeader from 'syft-acp-core/components/AbbrHeader'

import * as types from './cellTypes'
import tableFormatPropTypes from './tableFormatPropTypes'
import SelectAllEntities from './SelectAllEntities'

const validAlignments = ['left', 'center', 'right']

const getColContent = (colFormat, entityList, entityType, selectSingleRow) => {
  // If this is a selector, return the selector component, unless we are only allowed to select single rows.
  if (colFormat.type === types.SELECTOR || colFormat.type === types.SUBROW_SELECTOR) {
    if (selectSingleRow) return null
    const subrowSelector = colFormat.type === types.SUBROW_SELECTOR
    return (
      <SelectAllEntities
        entityList={entityList}
        entityType={entityType}
        dataGenerator={colFormat.val}
        subrowSelector={subrowSelector}
      />
    )
  }

  if (colFormat.header && typeof colFormat.header === 'function') {
    return colFormat.header(entityList)
  }

  if (colFormat.abbr && colFormat.header !== '') {
    return <AbbrHeader label={colFormat.expl}>{colFormat.header}</AbbrHeader>
  }

  // If we have a plain header string, return it.
  if (colFormat.header && colFormat.header !== '') {
    return colFormat.header
  }

  // If we have an icon, return an icon image.
  if (colFormat.headerIcon) {
    const headerSVG = colFormat.headerIcon.toSVG({
      'aria-label': colFormat.expl || colFormat.headerIconAlt,
      width: 13,
      class: 'img',
    })
    return <div className="header-icon" dangerouslySetInnerHTML={{ __html: headerSVG }} />
  }

  // Else, return an empty node.
  return <span>&nbsp;</span>
}

/**
 * Table header for the EntityList component. This iterates over the table format
 * and produces a single row with information about the table's data.
 *
 * @param {Object} props Properties
 * @returns {XML} Pure EntityListHeader component
 */
const EntityListHeader = props => (
  <thead data-testid="entity-list-header">
    {props.title && (
      <tr>
        <th colSpan={props.tableFormat.length} className="title">
          {props.title}
        </th>
      </tr>
    )}
    <tr>
      {
        // Produce one cell for every item in the table format.
        props.tableFormat.map((colFormat, n) => {
          // Use the provided classes, or infer the required class from the data type.
          const classes = colFormat.classes ? [...colFormat.classes] : [types.typeClasses[colFormat.type]]

          // Add the ICON class if this is a boolean.
          if (colFormat.type === types.BOOL) {
            classes.push(types.typeClasses[types.ICON])
          }
          if (colFormat.alignment) {
            if (validAlignments.indexOf(colFormat.alignment) !== -1) {
              classes.push(`align-${colFormat.alignment}`)
            } else {
              throw TypeError('EntityList table format: Invalid alignment')
            }
          }
          if (colFormat.headerIcon) {
            classes.push('has-header-icon')
          }
          // For the (subrow) selector column, add a "select all" feature.
          if (colFormat.type === types.SELECTOR || colFormat.type === types.SUBROW_SELECTOR) {
            classes.push('has-selector')
          }

          const content = getColContent(colFormat, props.entityList, props.entityType, props.selectSingleRow)

          return (
            <th
              key={n}
              className={classes.join(' ')}
              // Tooltip content, linked to this specific EntityList's tooltip element.
              data-tip={colFormat.expl}
              data-for={colFormat.expl ? `tooltip-${props.entityType}` : null}
            >
              {content}
            </th>
          )
        })
      }
    </tr>
  </thead>
)
EntityListHeader.propTypes = {
  // eslint-disable-next-line react/require-default-props
  setSortColumn: PropTypes.func,
  // eslint-disable-next-line react/require-default-props
  title: PropTypes.string,
  // eslint-disable-next-line react/require-default-props
  tableFormat: tableFormatPropTypes,
  // eslint-disable-next-line react/require-default-props
  selectSingleRow: PropTypes.bool.isRequired,
  entityType: PropTypes.string.isRequired,
  entityList: PropTypes.arrayOf(PropTypes.object).isRequired,
}
EntityListHeader.defaultProps = {}

export default EntityListHeader
