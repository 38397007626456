import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import FilterSelectManagerRoles from 'syft-acp-util/components/FilterForm/FilterSelectManagerRoles'

const DataManagerRoles = ({ value, editable, actions }) =>
  editable ? (
    <FilterSelectManagerRoles name="manager_role" value={value} actions={actions} type="id" disabled={!editable} />
  ) : (
    <div className="plain-text">{value}</div>
  )

DataManagerRoles.propTypes = {
  value: PropTypes.array.isRequired,
  editable: PropTypes.bool.isRequired,
  actions: PropTypes.object.isRequired,
}

export default connect((state, { onChange }) => ({
  actions: {
    setFilter: onChange,
  },
}))(DataManagerRoles)
