import entityReducer from './generators/entityReducer'
import {
  AGENCY_ACCOUNTS_FETCH_BEGIN,
  AGENCY_ACCOUNTS_FETCH_FAILED,
  AGENCY_ACCOUNTS_FETCH_SUCCEEDED,
} from '../actions/action-types'
import * as entityTypes from './generators/entities'

export default entityReducer(
  'agencyAccounts',
  {
    [entityTypes.ENTITIES_FETCH_BEGIN]: AGENCY_ACCOUNTS_FETCH_BEGIN,
    [entityTypes.ENTITIES_FETCH_SUCCEEDED]: AGENCY_ACCOUNTS_FETCH_SUCCEEDED,
    [entityTypes.ENTITIES_FETCH_FAILED]: AGENCY_ACCOUNTS_FETCH_FAILED,
  },
  {
    idKey: 'id',
    localEntityMap: true,
  }
)
