import React from 'react'
import { bindActionCreators } from 'redux'
import { connect, ConnectedProps } from 'react-redux'

import { get } from 'lodash-es'
import Acp from 'syft-acp-uikit'

import { fetchImportLogEvents } from 'syft-acp-core/actions/import-log-events'
import { entityList$, pagination$ } from 'syft-acp-core/reducers/generators/entities'
import './ImportView.css'

const connector = connect(
  state => {
    const query = get(state, 'routing.locationBeforeTransitions.query')
    return {
      data: entityList$(get(state, 'importLogEvents')),
      meta: pagination$(get(state, 'importLogEvents')),
      isLoading: get(state, 'importLogEvents.isLoadingData'),
      query,
    }
  },
  dispatch => ({
    actions: bindActionCreators({ fetchImportLogEvents }, dispatch),
  }),
)

type PropsFromRedux = ConnectedProps<typeof connector>

class ImportResultList extends React.PureComponent<PropsFromRedux> {
  componentDidMount() {
    const { actions, query } = this.props
    actions.fetchImportLogEvents(query)
  }

  componentDidUpdate(prevProps: PropsFromRedux) {
    if (this.props.query !== prevProps.query) {
      this.props.actions.fetchImportLogEvents(this.props.query)
    }
  }

  render() {
    const { data, meta, isLoading } = this.props
    return (
      <div className="import-log-events-list">
        <Acp.EntityList
          hasResultCount
          hasPagination
          idKeyValue="id"
          data={{ data, meta }}
          isLoading={isLoading}
        >
          <Acp.Table>
            <Acp.Col.Text value="employer_id" header="Employer ID" isMinimal />
            <Acp.Col.Text value="external_id" header="Schedule number" isMinimal />
            <Acp.Col.Text value="job_id" header="Job ID" isMinimal />
            <Acp.Col.Text value="listing_id" header="Listing ID" isMinimal />
            <Acp.Col.Boolean value="pay_rate_changed" header="Pay rate changed" isMinimal />
          </Acp.Table>
        </Acp.EntityList>
      </div>
    )
  }
}

export default connector(ImportResultList)
