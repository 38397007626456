import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Button } from 'syft-acp-atoms/Button'
import { withTrackingTriggerHoc } from 'syft-acp-util/withTrackingTriggerHoc'

import { fetchAdminBookings } from 'syft-acp-core/store/listings/actions'
import { showModal } from 'syft-acp-core/store/modals/actions'
import { editAllAdminTimesheets } from 'syft-acp-core/api/resources/admin/timesheets'
import ListingShiftEditAllModal from 'syft-acp-core/components/Modal/ListingShiftEditAllModal'
import { trackingEvents } from '../../tracking'

const ListingEditShiftsButton = ({
  label,
  disabled,
  openModal,
  modalName,
  shiftID,
  startTime,
  endTime,
  breakDuration,
  timeZone,
  triggerEvent,
}) => {
  const onClick = useCallback(() => {
    triggerEvent(trackingEvents.LISTING_INFO.BOOKINGS_EDIT_ALL.CLICKED, {
      shiftID,
      startTime,
      endTime,
      breakDuration,
      timeZone,
    })

    openModal({ shiftID, startTime, endTime, breakDuration, timeZone })
  }, [breakDuration, endTime, openModal, shiftID, startTime, timeZone, triggerEvent])

  return (
    <>
      <Button kind="info" onClick={onClick} disabled={disabled}>
        {label}
      </Button>
      <ListingShiftEditAllModal modalName={modalName} />
    </>
  )
}

ListingEditShiftsButton.propTypes = {
  openModal: PropTypes.func.isRequired,
  triggerEvent: PropTypes.func.isRequired,
  modalName: PropTypes.string,
  label: PropTypes.string.isRequired,
  shiftID: PropTypes.number.isRequired,
  startTime: PropTypes.string.isRequired,
  endTime: PropTypes.string.isRequired,
  breakDuration: PropTypes.number.isRequired,
  timeZone: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
}

export default connect(null, (dispatch, { jobID, shiftID, modalName, hasPayBillSplit, subject }) => {
  const onConfirm = async data => {
    await editAllAdminTimesheets({
      payload: { shift_id: shiftID, ...data, subject: hasPayBillSplit ? subject : undefined },
    })
    dispatch(fetchAdminBookings(shiftID, jobID, { page: 1, per_page: 8 }))
  }
  return {
    openModal: modalData => dispatch(showModal(modalName, { ...modalData, onConfirm }, null)),
  }
})(withTrackingTriggerHoc(ListingEditShiftsButton))
