import React from 'react'
import PropTypes from 'prop-types'

import AcpNotifierBlock from './AcpNotifierBlock'

/**
 * Displayed on a table when there is no data to show.
 */
export default class AcpNoDataBlock extends React.PureComponent {
  static propTypes = {
    rowCount: PropTypes.number,
    isIntegrated: PropTypes.bool,
    hasColGroups: PropTypes.bool,
    hasError: PropTypes.bool,
    reloadLastCall: PropTypes.func,
    notFoundMessage: PropTypes.string,
  }

  static defaultProps = {
    rowCount: 4,
    isIntegrated: false,
    hasColGroups: false,
    hasError: false,
    reloadLastCall: null,
    notFoundMessage: null,
  }

  render() {
    const { hasError, rowCount, reloadLastCall, isIntegrated, hasColGroups, notFoundMessage } = this.props
    const findNotFoundMessage = notFoundMessage ? notFoundMessage : 'No items found.'
    const message = hasError ? 'Could not load data.' : findNotFoundMessage
    return (
      <AcpNotifierBlock
        rowCount={Math.min(rowCount, 4)}
        messageError={message}
        hasError={hasError}
        reloadLastCall={reloadLastCall}
        isIntegrated={isIntegrated}
        hasColGroups={hasColGroups}
      />
    )
  }
}
