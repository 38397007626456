// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import { store } from 'syft-acp-core/store'
import { addError } from 'syft-acp-core/actions/errors'

// Returns an ISO timestamp, e.g. '2019-08-13T16:48:37.491Z'.
const getTimestamp = () => new Date().toISOString()

/** Simply passes on the given error to the store. */
export const registerError = (error, res) => {
  store.dispatch(addError({ error, res, timestamp: getTimestamp() }))
}
