// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import React, { useCallback, useEffect } from 'react'
import { history } from 'syft-acp-core/history'
import deepEqual from 'react-fast-compare'

import Acp from 'syft-acp-uikit'
import { formatDatetime } from 'syft-acp-util/formatting'
import * as workerShiftsActions from 'syft-acp-core/store/workerShifts/actions'
import { WorkerShiftEntity } from 'syft-acp-core/store/types'
import { createEntityListConnector } from 'syft-acp-util/entityList'

import { WorkerShiftsListProps as Props } from './WorkerShiftsList.types'
import usePrevValue from 'syft-acp-util/hooks/usePrevValue'

export const entityConnector = createEntityListConnector<WorkerShiftEntity>({
  entityActions: {
    fetchEntities: ({ options }: Record<string, any>, { workerID }: { workerID: number }) =>
      workerShiftsActions.fetchWorkerShifts(workerID, options),
  },
  entityStore: 'workerShifts',
})

const selectRole = (e: WorkerShiftEntity) => e.role && e.role.title
// @ts-expect-error
const selectStartTime = (e: WorkerShiftEntity) => formatDatetime(e.start_time)
// @ts-expect-error
const selectEndTime = (e: WorkerShiftEntity) => formatDatetime(e.end_time)

const onClickListingLink = ({ rowData }: { rowData: WorkerShiftEntity }) => {
  history.push(`/listings/view/${rowData.listing_id}`)
}

const WorkerShiftsList = ({ actions, data, isLoadingData, query, showListingLink, workerID }: Props) => {
  const prevQuery = usePrevValue(query)
  const fetch = useCallback(
    (q: Record<string, any>) => actions.fetchEntities && actions.fetchEntities({ options: q }, { workerID }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [workerID],
  )

  useEffect(() => {
    if (!deepEqual(prevQuery, query)) fetch(query)
  }, [fetch, prevQuery, query])

  return (
    <Acp.EntityList data={data} isLoading={isLoadingData} idKeyValue="id" isIntegrated hasPagination>
      <Acp.Header>Worker shifts</Acp.Header>
      <Acp.Table>
        {/* {props.selectCallback && (
          Note: since selecting normally is broken if using full row selection, this is disabled for now.
          <Acp.Col.Selector />
        )} */}
        <Acp.Col.Text value="shift_id" header="Shift ID" align="left" isMinimal isNumeric />
        <Acp.Col.Text value="listing_id" header="Listing ID" align="left" isMinimal isNumeric />
        <Acp.Col.Employer value="employer" header="Employer" align="left" options={{ hideAvatar: true }} />
        <Acp.Col.Text value="status" header="Status" align="left" isMinimal />
        <Acp.Col.Text value={selectRole} header="Job role" align="left" isMinimal />
        <Acp.Col.Text value={selectStartTime} header="Start time" align="left" isMinimal />
        <Acp.Col.Text value={selectEndTime} header="End time" align="left" isMinimal />
        {showListingLink && (
          <Acp.Col.Button header="Listing link" isMinimal>
            <Acp.Button onClick={onClickListingLink}>View listing</Acp.Button>
          </Acp.Col.Button>
        )}
      </Acp.Table>
    </Acp.EntityList>
  )
}

export default entityConnector(WorkerShiftsList)
