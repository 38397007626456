import { generateEntityCrudReducer } from 'syft-acp-util/store/reducers'
import externalEmployerTypes from './action-types'
import type { ShiftEventEntity } from './types'

export default generateEntityCrudReducer<ShiftEventEntity>({
  name: 'shiftEvents',
  actions: externalEmployerTypes,
  options: {
    idKey: 'id',
  },
})
