import { Store } from 'syft-acp-core/store'
import { createSelector } from 'reselect'

const selectCheckboxes = (state: Store) => state.checkboxes
const checkboxKey = (state: Store, key: string) => key

export const selectCheckboxItems = createSelector(
  selectCheckboxes,
  checkboxKey,
  (checkboxes: Store['checkboxes'], key: string) => checkboxes.items[key]
)
