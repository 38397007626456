import { AGENCY_PROFILE_FETCH_BEGIN, AGENCY_PROFILE_UPDATE_BEGIN } from './action-types'

export const fetchAgencyProfile = options => ({
  type: AGENCY_PROFILE_FETCH_BEGIN,
  options,
})

export const saveAgencyProfile = payload => ({
  type: AGENCY_PROFILE_UPDATE_BEGIN,
  payload,
})
