import * as types from './action-types'
import { ImportFileActionInput, ImportListingsDataActionInput } from './types'

export const importListingsData = ({
  file,
  venueId,
  templateId,
  employerId,
  ...args
}: ImportListingsDataActionInput) =>
  importFile({
    file,
    path: `/external_jobs?venue_id=${venueId}&listing_template_id=${templateId}&employer_id=${employerId}&`,
    ...args,
  })

export const importFile = ({ file, path, ...args }: ImportFileActionInput) => ({
  type: types.IMPORT_FILE_BEGIN,
  file,
  path,
  ...args,
})
