// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import React from 'react'
import SubNavigation, { SubLink } from 'syft-acp-atoms/SubNavigation'

export const SubNavigationAdmin = () => {
  return (
    <SubNavigation>
      <SubLink to="/admin/appeals">Appeals</SubLink>
      <SubLink to="/admin/conduct-reviews">Conduct reviews</SubLink>
      <SubLink to="/admin/managers">Managers</SubLink>
      <SubLink to="/admin/client-applications">Client applications</SubLink>
      <SubLink to="/admin/lta/time-off-requests?status=requested">LTA time off</SubLink>
    </SubNavigation>
  )
}

export default SubNavigationAdmin
