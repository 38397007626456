// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { history } from 'syft-acp-core/history'
import { get } from 'lodash-es'

import { withTrackingTriggerHoc } from 'syft-acp-util/withTrackingTriggerHoc'
import { showConfirmModal } from 'syft-acp-core/store/modals/actions'
import { Button } from 'syft-acp-atoms/Button'
import { defaultWrapperPropTypes } from 'syft-acp-util/entityList'
import { fetchEmployerVenuesList } from 'syft-acp-core/store/employer-venues/actions'
import { pagination$ } from 'syft-acp-core/reducers/generators/entities'

import EmployerDetailListWrapper from './EmployerDetailListWrapper'
import { trackingEvents } from './EmployerVenueList/EmployerVenueList.tracking'

const newAction = employerID => () => {
  history.push(`/entity/employers/view/${employerID}/venues/new`)
}

class VenuesControls extends React.PureComponent {
  openModal = () => {
    const { employerID, fetchEmployerVenuesCSV, showModal, employerVenues, triggerEvent } = this.props
    const totalItems = get(employerVenues, 'total', 0)
    showModal({
      modalConfirm: 'Download',
      question: (
        <div>
          <p>Download a CSV file with these search results?</p>
          <p>
            There will be {totalItems} item{totalItems !== 1 ? 's' : ''} in the file.
          </p>
        </div>
      ),
      onConfirm: () => {
        fetchEmployerVenuesCSV(employerID)
        triggerEvent(trackingEvents.DOWNLOAD_CSV_BUTTON.CLICKED)
      },
    })
  }

  render() {
    const { employerID } = this.props
    return (
      <div>
        <Button kind="primary" onClick={() => this.openModal()}>
          Download CSV
        </Button>{' '}
        <Button kind="success" onClick={newAction(employerID)}>
          New venue
        </Button>
      </div>
    )
  }
}

VenuesControls.propTypes = {
  employerID: PropTypes.number,
  fetchEmployerVenuesCSV: PropTypes.func.isRequired,
  showModal: PropTypes.func.isRequired,
  employerVenues: PropTypes.array.isRequired,
}

VenuesControls.defaultProps = {
  employerID: null,
}

const VenuesControlsContainer = connect(
  state => ({
    employerVenues: pagination$(state.employerVenues),
  }),
  dispatch => ({
    fetchEmployerVenuesCSV: employerID =>
      dispatch(fetchEmployerVenuesList({ options: { employerID } }, true)),
    showModal: data => dispatch(showConfirmModal(data)),
  }),
)(withTrackingTriggerHoc(VenuesControls))

const EmployerVenueListWrapper = props => (
  <EmployerDetailListWrapper controlsRight={<VenuesControlsContainer />} {...props} />
)

EmployerVenueListWrapper.propTypes = defaultWrapperPropTypes

export default EmployerVenueListWrapper
