// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Grid, Col, Row } from 'react-bootstrap'

import entityDetail from 'syft-acp-util/entityDetail'
import * as accountsActions from 'syft-acp-core/actions/employer-accounts'
import RecordSection from 'syft-acp-core/components/RecordSection'
import SiteComponent from 'syft-acp-core/components/SiteComponent'
import * as structureTypes from 'syft-acp-core/components/EditableTable'

import EmployerAccountsDetailControls from './EmployerAccountsDetailControls'

const dataStructure = [
  ['id', { header: 'ID' }],
  ['user.id', { header: 'User ID' }],
  [
    'level',
    {
      header: 'Level',
      editable: true,
      type: structureTypes.TYPE_ENUM,
      options: [
        { value: 'main', label: 'Main' },
        { value: 'manager', label: 'Manager' },
        { value: 'disabled', label: 'Disabled' },
      ],
    },
  ],
  ['owner', { header: 'Owner', type: structureTypes.TYPE_CHECKBOX }],
  ['', { type: structureTypes.TYPE_SEPARATOR }],
  ['name', { header: 'Name', editable: true }],
  ['user.email', { header: 'Email' }],
  ['telephone_number', { header: 'Tel. nr', editable: true, type: structureTypes.TYPE_PHONE_NUMBER }],
  ['created_at', { header: 'Created at', type: structureTypes.TYPE_TIMESTAMP }],
  ['invite_accepted_at', { header: 'Invite accepted at', type: structureTypes.TYPE_TIMESTAMP }],
  ['permissions', { header: 'Permissions', type: structureTypes.TYPE_PERMISSIONS, editable: true }],
]
const dataStructureNew = [
  [
    'level',
    {
      header: 'Level',
      editable: true,
      type: structureTypes.TYPE_ENUM,
      options: [
        { value: 'main', label: 'Main' },
        { value: 'manager', label: 'Manager' },
        { value: 'disabled', label: 'Disabled' },
      ],
    },
  ],
  ['name', { header: 'Name', editable: true }],
  ['user.email', { header: 'Email', editable: true }],
  ['permissions', { header: 'Permissions', type: structureTypes.TYPE_PERMISSIONS, editable: true }],
]

class EmployerAccountsDetail extends Component {
  static propTypes = {
    id: PropTypes.number.isRequired,
    data: PropTypes.object,
    employerID: PropTypes.number.isRequired,
    isPristine: PropTypes.bool.isRequired,
    actions: PropTypes.objectOf(PropTypes.func).isRequired,
    actionUpdate: PropTypes.func.isRequired,
  }

  static defaultProps = {
    data: {},
  }

  componentDidMount() {
    if (this.props.id) {
      this.props.actions.fetchEmployerAccount(this.props.employerID, this.props.id)
    }
  }

  render() {
    const { data } = this.props
    const name = data && data.user ? data.user.email : ''
    const isNewItem = !this.props.id

    // Every new account should at least be able to set company level permissions.
    if (isNewItem && !data.permissions) {
      data.permissions = [{ work_location_id: null, codes: [] }]
    }

    return (
      <SiteComponent>
        <Grid>
          <Row>
            <Col md={12}>
              <EmployerAccountsDetailControls
                data={data}
                isNewItem={isNewItem}
                employerID={this.props.employerID}
                isPristine={this.props.isPristine}
              />
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              {isNewItem ? (
                <RecordSection
                  data={data}
                  structure={dataStructureNew}
                  context={{ employerID: this.props.employerID }}
                  update={this.props.actionUpdate}
                  title="Invite new member"
                />
              ) : (
                <RecordSection
                  data={data}
                  structure={dataStructure}
                  context={{ employerID: this.props.employerID }}
                  update={this.props.actionUpdate}
                  nameValue={name}
                  nameBio={data && data.level}
                  nameAvatar={data && data.picture && data.picture.uuid}
                  showName
                />
              )}
            </Col>
          </Row>
        </Grid>
      </SiteComponent>
    )
  }
}

export default connect(null, dispatch => ({
  actions: bindActionCreators(accountsActions, dispatch),
}))(entityDetail('employerAccounts', EmployerAccountsDetail))
