// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import React from 'react'
import { connect } from 'react-redux'
import { includes, sortBy } from 'lodash-es'

import { IconCheckmark } from 'syft-acp-core/components/IconCheckmark'
import AbbrHeader from 'syft-acp-core/components/AbbrHeader'
import EntityDetailItem from 'syft-acp-core/components/EntityDetail/EntityDetailItem'
import RecordHeader from 'syft-acp-core/components/RecordSection/RecordHeader'
import RecordSegment from 'syft-acp-core/components/RecordSection/RecordSegment'
import Table from 'syft-acp-util/components/Table'
import { formatBytes } from 'syft-acp-util/formatting'

import { store as globalStore } from 'syft-acp-core/store'

import './StoreSize.css'

/** Returns size of an object in bytes. */
const getObjectSize = obj => unescape(encodeURIComponent(JSON.stringify(obj))).length

/** Returns a color based on the size of the store in bytes. */
const getSizeColor = sizeInBytes => {
  if (sizeInBytes < 5000) return ''
  if (sizeInBytes < 25000) return 'green'
  if (sizeInBytes < 100000) return 'yellow'
  if (sizeInBytes <= 500000) return 'orange'
  if (sizeInBytes > 500000) return 'red'
  return 'gray'
}

class StoreSize extends React.PureComponent {
  static getDerivedStateFromProps(nextProps, prevState) {
    return { ...prevState, storeData: nextProps.state }
  }

  constructor() {
    super()
    this.state = {
      storeData: globalStore.getState(),
    }
  }

  retrieveStoreSize = store => {
    const storeItems = Object.keys(store).map(k => {
      const size = getObjectSize(store[k])
      const color = getSizeColor(size)
      return {
        name: k,
        // Check if this is a store made by the entityReducer() factory. */
        isEntityBased: includes(Object.keys(store[k]), 'entityMap', 'entityList', 'entitySets', 'entityUpdateTimes'),
        size,
        sizeSort: -size,
        color,
      }
    })
    return sortBy(storeItems, ['sizeSort', 'name'])
  }

  render() {
    const { storeData } = this.state
    const items = this.retrieveStoreSize(storeData)
    return (
      <EntityDetailItem className="StoreSize">
        <RecordSegment noPadding noBorder>
          <RecordHeader main>Store size report</RecordHeader>
        </RecordSegment>
        <RecordSegment withTable>
          <div className="list">
            <Table className="entity-list" colBorders noBottomMargin noLastRowBorder>
              <thead>
                <tr>
                  <th>Store name</th>
                  <th>
                    <AbbrHeader>E</AbbrHeader>
                  </th>
                  <th>Size</th>
                </tr>
              </thead>
              {items.map((item, n) => (
                <tbody key={n}>
                  <tr className={`clickable ${item.color}`}>
                    <td className="main">
                      <span className="disabled entity-row">
                        <span className="monospace-text">{item.name}</span>
                      </span>
                    </td>
                    <td className="minimal icon">
                      <span className="disabled entity-row">{item.isEntityBased ? <IconCheckmark /> : ' '}</span>
                    </td>
                    <td className="minimal numeric">
                      <span className="disabled entity-row">{formatBytes(item.size)}</span>
                    </td>
                  </tr>
                </tbody>
              ))}
            </Table>
          </div>
        </RecordSegment>
        <RecordSegment footer>Note: store size will be reset back to zero when logging in.</RecordSegment>
      </EntityDetailItem>
    )
  }
}

export default connect(
  // Connect to the whole state.
  state => ({ state })
)(StoreSize)
