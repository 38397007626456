// Syft ACP - Lib <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import React from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { isFunction, compact } from 'lodash-es'

import { Button, ButtonGroup } from 'syft-acp-atoms/Button'
import * as filtersActions from 'syft-acp-core/store/filters/actions'

import FilterUnit from './FilterUnit'
import './FilterEnum.css'

// TODO: refactor this component. It's gotten pretty complicated.
class FilterEnum extends React.Component {
  componentDidMount() {
    // Run through our items to see if we need to enable any filters by default.
    this.props.options.forEach(group =>
      group.forEach(item => {
        // If the item has a default value, check if we can activate it.
        // Verify whether we don't already have our values set in the query string.
        const hasValues = item.name ? compact(item.name.map(n => this.props.query[n])).length > 0 : null
        if (
          hasValues ||
          !item.defaultValue ||
          (isFunction(item.defaultValue) && !item.defaultValue(this.props.query))
        ) {
          return
        }
        // If we're here, it means defaultValue() returned true. We can activate this by default.
        if (item.filterFunction) {
          this.runFilterFunction(item.filterFunction)()
        } else {
          this.handleChange(item.value)()
        }
      })
    )
  }

  turnOff = item => {
    if (item.filterFunction) {
      const filterValues = item.filterFunction()
      Object.keys(filterValues).forEach(n => {
        !!this.props.onChange && this.props.onChange(n)
        this.props.actions.setFilter(n, null)
      })
    } else {
      this.props.actions.setFilter(this.props.name, null)
    }
  }

  // If something more complicated is needed, we run a custom filter.
  // This is used by e.g. the "tomorrow", etc. buttons in the shifts list page.
  runFilterFunction = (customFilter, item) => () => {
    if (this.activeHandler(item)) {
      return this.turnOff(item)
    }
    const filterValues = customFilter()
    Object.keys(filterValues).forEach(key => {
      // prevent initiall call onChange by adding !!item
      !!this.props.onChange && !!item && this.props.onChange(key, filterValues[key])
      this.props.actions.setFilter(key, filterValues[key])
    })
    return null
  }

  handleChange = (value, item) => () => {
    if (this.activeHandler(item)) {
      return this.turnOff(item)
    }
    // By default, we set a filter with the item's value.
    this.props.actions.setFilter(this.props.name, value)
    !!this.props.onChange && this.props.onChange(this.props.name, value)
    return null
  }

  clickHandler = item => {
    // If active, deactivate it.
    if (item.filterFunction) {
      return this.runFilterFunction(item.filterFunction, item)
    } else {
      return this.handleChange(item.value, item)
    }
  }

  activeHandler = item => {
    if (!item) return
    if (item.activeFunction) {
      return item.activeFunction(item.value)
    } else if (item.value === true || item.value === false) {
      // Special case: if 'value' is a boolean (normally it's a string),
      // check if its [name] exists in the query string.
      return this.hasSegment(item.name)
    } else {
      return this.isActive(item.value)
    }
  }

  /** Returns whether a certain segment is present in the query string. */
  hasSegment = name => {
    return Object.keys(this.props.query).indexOf(name) === -1
  }

  /** Returns whether a query string segment equals a specific value. */
  isActive = value => this.props.name && (this.props.query[this.props.name] || '') === value

  render() {
    return (
      <FilterUnit>
        {this.props.options.map((group, i) => (
          <ButtonGroup className="filter-enum-form-group" key={String(i)}>
            {group.map((item, idx) => (
              <Button
                key={`${item.value}-${idx}`}
                kind={item.type}
                active={this.activeHandler(item)}
                bright={item.bright}
                onClick={this.clickHandler(item)}
                aria-label={item.label}
              >
                {item.label}
              </Button>
            ))}
          </ButtonGroup>
        ))}
      </FilterUnit>
    )
  }
}

FilterEnum.propTypes = {
  options: PropTypes.array,
  name: PropTypes.string,
  actions: PropTypes.objectOf(PropTypes.func).isRequired,
  query: PropTypes.object.isRequired,
  onChange: PropTypes.func,
}

FilterEnum.defaultProps = {
  options: [],
  name: null,
  onChange: null,
}

export default connect(
  state => ({
    query: state.routing.locationBeforeTransitions.query,
  }),
  dispatch => ({
    actions: bindActionCreators(filtersActions, dispatch),
  })
)(FilterEnum)
