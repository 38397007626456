import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Grid, Col, Row } from 'react-bootstrap'

import entityDetail from 'syft-acp-util/entityDetail'

import SiteComponent from 'syft-acp-core/components/SiteComponent'
import RecordSection from 'syft-acp-core/components/RecordSection'
import * as conversationsActions from 'syft-acp-core/actions/conversations'
import ConversationsDetailMessagesList from './ConversationsDetailMessagesList'
import ConversationsDetailControls from './ConversationsDetailControls'

import { dataStructure } from './structure'

import './ConversationsDetail.css'

class ConversationsDetail extends Component {
  static propTypes = {
    id: PropTypes.number.isRequired,
    data: PropTypes.object.isRequired,
    actionUpdate: PropTypes.func.isRequired,
    actions: PropTypes.objectOf(PropTypes.func).isRequired,
  }

  componentDidMount() {
    const { actions, id } = this.props
    actions.fetchAdminConversation(id)
  }

  render() {
    return (
      <SiteComponent>
        <Grid>
          <Row>
            <Col md={12} sm={12}>
              <ConversationsDetailControls />
            </Col>
          </Row>
          <Row>
            <Col md={12} sm={12}>
              <RecordSection
                title="Conversation information"
                data={this.props.data}
                structure={dataStructure}
                update={this.props.actionUpdate}
              />
              {this.props.id && <ConversationsDetailMessagesList title="Messages" id={this.props.id} />}
            </Col>
          </Row>
        </Grid>
      </SiteComponent>
    )
  }
}

export default connect(null, dispatch => ({
  actions: bindActionCreators(conversationsActions, dispatch),
}))(entityDetail('conversations', ConversationsDetail))
