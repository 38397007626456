// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import { put, takeLatest } from 'redux-saga/effects'
import { fetchEmployerStats } from 'syft-acp-core/actions/stats'
import { notificationCall } from 'syft-acp-core/sagas/calls'
import { takeLatestApiCall } from 'syft-acp-core/sagas/helpers'

import * as types from './action-types'
import {
  approveEmployer,
  fetchEmployer,
  listEmployers,
  loginAsEmployer,
  migrateEmployerToSyftForce,
  saveEmployer,
} from './api'

export default function* saga() {
  yield takeLatestApiCall(types.EMPLOYERS_FETCH_BEGIN, listEmployers)
  yield takeLatestApiCall(types.EMPLOYER_FETCH_BEGIN, fetchEmployer)
  yield takeLatestApiCall(types.EMPLOYER_LOGIN_AS_BEGIN, loginAsEmployer)
  // Fetch employer stats with every call to fetch an employer
  yield takeLatest(types.EMPLOYER_FETCH_BEGIN, ({ id }) => put(fetchEmployerStats(id)))
  yield takeLatestApiCall(types.EMPLOYER_SAVE_BEGIN, saveEmployer)
  yield takeLatestApiCall(types.EMPLOYER_APPROVE_BEGIN, approveEmployer)
  yield takeLatestApiCall(types.EMPLOYER_MIGRATE_BEGIN, migrateEmployerToSyftForce)
  yield takeLatest(
    [
      types.EMPLOYER_APPROVE_FAILED,
      types.EMPLOYER_APPROVE_SUCCEEDED,
      types.EMPLOYER_SAVE_FAILED,
      types.EMPLOYER_SAVE_SUCCEEDED,
      types.EMPLOYER_MIGRATE_SUCCEEDED,
    ],
    notificationCall,
  )
}
