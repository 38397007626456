import React, { useState, useEffect } from 'react'
import classNames from 'classnames'

import Button from 'syft-acp-atoms/ButtonNew'

import { EditableTextboxProps as Props } from './EditableTextbox.types'
import S from './EditableTextbox.module.scss'

const EditableTextbox = ({
  className,
  value,
  onSave,
  placeholder,
  onFocus,
  message,
  hasControls = true,
}: Props) => {
  const [isEditable, setIsEditable] = useState(!hasControls)
  const [isEdited, setIsEdited] = useState(false)
  const [isSaving, setIsSaving] = useState(false)
  const [text, setText] = useState(value)

  const onChangeText = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setText(e.target.value)
  }

  const onClickEdit = () => {
    if (isEditable) setText(value)
    setIsEditable(!isEditable)
  }

  const onClickSave = async () => {
    setIsSaving(true)
    await onSave(text)
    setIsSaving(false)
    setIsEditable(false)
  }

  useEffect(() => {
    if (isEditable && text !== value) setIsEdited(true)
    if (isEditable && text === value) setIsEdited(false)
  }, [text, value, isEditable])

  useEffect(() => {
    setText(value)
  }, [value])

  useEffect(() => {
    // If there are no controls then updating should happen as the user types
    if (!hasControls && isEdited) onSave(text)
  }, [text, isEdited, hasControls, onSave])

  return (
    <div className={classNames([S['editable-textbox'], className])}>
      <textarea
        placeholder={placeholder}
        className={S['editable-textbox__input']}
        value={text}
        disabled={!isEditable}
        onFocus={onFocus}
        onChange={onChangeText}
      />
      {message && <div className={S['editable-textbox__message']}>{message}</div>}
      {hasControls && (
        <div>
          <Button small className={S['editable-textbox__button']} onClick={onClickEdit}>
            {isEditable ? 'Cancel' : 'Edit'}
          </Button>
          <Button
            small
            primary
            className={S['editable-textbox__button']}
            disabled={!isEditable || !isEdited || isSaving}
            onClick={onClickSave}
          >
            {isSaving ? 'Saving' : 'Save'}
          </Button>
        </div>
      )}
    </div>
  )
}

export default EditableTextbox
