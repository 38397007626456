import * as actionTypes from 'syft-acp-core/actions/action-types'

import { entitySaveFailed } from './common-reducers/entity-save-failed'
import { entitySaveSucceed } from './common-reducers/entity-save-succeed'
import { entitiesFetchBegin } from './common-reducers/entities-fetch-begin'
import { entitiesFetchSucceed } from './common-reducers/entities-fetch-succeed'
import { entitiesFetchFailed } from './common-reducers/entities-fetch-failed'
import { entityFetchBegin } from './common-reducers/entity-fetch-begin'
import { entityFetchSucceed } from './common-reducers/entity-fetch-succeed'
import { entityFetchFailed } from './common-reducers/entity-fetch-failed'
import { entityDeleteSucceed } from './common-reducers/entity-delete-succeed'
import { entityDeleteFailed } from './common-reducers/entity-delete-failed'
import { entityFetchingBegin } from './common-reducers/entity-fetching-begin'
import { rehydrationComplete } from './common-reducers/rehydration-complete'
import { appRemoveUnusedData } from './common-reducers/app-remove-unused-data'

import {
  ENTITIES_FETCH_BEGIN,
  ENTITIES_FETCH_FAILED,
  ENTITIES_FETCH_SUCCEEDED,
  ENTITY_CREATE_BEGIN,
  ENTITY_CREATE_FAILED,
  ENTITY_CREATE_SUCCEEDED,
  ENTITY_DELETE_BEGIN,
  ENTITY_DELETE_FAILED,
  ENTITY_DELETE_SUCCEEDED,
  ENTITY_FETCH_BEGIN,
  ENTITY_FETCH_FAILED,
  ENTITY_FETCH_SUCCEEDED,
  ENTITY_MODIFY_BEGIN,
  ENTITY_MODIFY_FAILED,
  ENTITY_MODIFY_SUCCEEDED,
  ENTITY_SAVE_BEGIN,
  ENTITY_SAVE_FAILED,
  ENTITY_SAVE_SUCCEEDED,
  ENTITY_UPDATE_BEGIN,
  ENTITY_UPDATE_FAILED,
  ENTITY_UPDATE_SUCCEEDED,
} from './constants'

// Checks whether a symbol array matches an action type.
const matchesType = (symbol, type) => {
  return symbol && symbol.indexOf(type) > -1
}

/**
 * Modifies the state based on an action, or array of actions.
 *
 * When using entityReducer() (see './entities.js), the types object contains a key (usually one of the predefined symbols at the top
 * of this file), and a value, which is either a single action type or an array of types.
 *
 * In this function we perform the actual work of updating the state based on what action was dispatched.
 */
export const modifyState = (state, action, types, idKey, options = {}, initialStoreState) => {
  // Run through user defined actions based on the standard entity symbols defined at the top of this file.
  if (matchesType(types[ENTITIES_FETCH_BEGIN], action.type)) {
    return entitiesFetchBegin(state, action, options)
  }

  if (matchesType(types[ENTITIES_FETCH_SUCCEEDED], action.type)) {
    return entitiesFetchSucceed(state, action, idKey, options)
  }

  if (matchesType(types[ENTITIES_FETCH_FAILED], action.type)) {
    return entitiesFetchFailed(state, action, options)
  }

  if (matchesType(types[ENTITY_FETCH_BEGIN], action.type)) {
    return entityFetchBegin(state)
  }

  if (matchesType(types[ENTITY_FETCH_SUCCEEDED], action.type)) {
    return entityFetchSucceed(state, action, idKey, options)
  }

  if (matchesType(types[ENTITY_FETCH_FAILED], action.type)) {
    return entityFetchFailed(state, action)
  }

  if (matchesType(types[ENTITY_DELETE_BEGIN], action.type)) {
    return entityFetchingBegin(state)
  }

  if (matchesType(types[ENTITY_DELETE_SUCCEEDED], action.type)) {
    return entityDeleteSucceed(state, action, options)
  }

  if (matchesType(types[ENTITY_DELETE_FAILED], action.type)) {
    return entityDeleteFailed(state, action)
  }

  if (
    matchesType(types[ENTITY_UPDATE_BEGIN], action.type) ||
    matchesType(types[ENTITY_CREATE_BEGIN], action.type) ||
    matchesType(types[ENTITY_MODIFY_BEGIN], action.type) ||
    matchesType(types[ENTITY_SAVE_BEGIN], action.type)
  ) {
    return entityFetchingBegin(state)
  }

  if (
    matchesType(types[ENTITY_UPDATE_SUCCEEDED], action.type) ||
    matchesType(types[ENTITY_CREATE_SUCCEEDED], action.type) ||
    matchesType(types[ENTITY_MODIFY_SUCCEEDED], action.type) ||
    matchesType(types[ENTITY_SAVE_SUCCEEDED], action.type)
  ) {
    return entitySaveSucceed(state, action, null, idKey, null, options)
  }

  if (
    matchesType(types[ENTITY_UPDATE_FAILED], action.type) ||
    matchesType(types[ENTITY_CREATE_FAILED], action.type) ||
    matchesType(types[ENTITY_MODIFY_FAILED], action.type) ||
    matchesType(types[ENTITY_SAVE_FAILED], action.type)
  ) {
    return entitySaveFailed(state, action)
  }

  // Aside from the standard actions, a few global types are handled here too.
  switch (action.type) {
    case actionTypes.AUTH_DELETE:
      return initialStoreState
    case actionTypes.REHYDRATION_COMPLETE:
      return rehydrationComplete(state)
    case actionTypes.APP_REMOVE_UNUSED_DATA: {
      return appRemoveUnusedData(state)
    }
    default:
      return state
  }
}
