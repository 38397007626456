// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import { put, select, takeLatest } from 'redux-saga/effects'

import * as types from 'syft-acp-core/actions/action-types'
import usersTypes from 'syft-acp-core/store/users/action-types'
import { listWorkerDocuments } from 'syft-acp-core/actions/worker-documents'
import { fetchDeletedRTWDocuments } from 'syft-acp-core/actions/worker-deleted-rtw-documents'
import { updateWorkerType } from 'syft-acp-core/api/resources/admin/workers'
import { notificationCall, redirectCall } from 'syft-acp-core/sagas/calls'
import { takeLatestApiCall, takeEveryApiCall } from 'syft-acp-core/sagas/helpers'

import * as api from './api'

const getWorker = (state, action) =>
  Object.values(state.workers.entityDetail).find(w => w.user_id === action.payload.id)

export function* workerFetchSucceededCall(action) {
  yield put({ type: types.WORKER_GENERAL_PREFERENCES_FETCH_BEGIN, id: action.payload.id })
}

/**
 * Notifies a worker when worker's user gets banned or unbanned.
 * @param {Object} action Action object
 */
export function* workerUserBanSucceededCall(action) {
  const worker = yield select(state => getWorker(state, action))
  if (worker) {
    const payload = {
      ...worker,
      banned: action.payload.banned,
      status: { ...worker.status, banned: action.payload.banned },
    }
    yield put({ type: types.WORKER_MODIFY_SUCCEEDED, payload })
  } else {
    throw TypeError(`Unable to find worker with user_id=${action.payload.id}.`)
  }
}

/**
 * Reloads the worker roles and documents when worker is successful saved
 * @param {Object} action Action object with options
 */
function* workerSuccessfulSaved({ options: { workerID } }) {
  yield put(listWorkerDocuments({ workerID }))
  yield put(fetchDeletedRTWDocuments(workerID))
}

export default function* root() {
  yield takeLatestApiCall('WORKER_FETCH', api.fetchWorker)
  yield takeLatest(types.WORKER_FETCH_SUCCEEDED, workerFetchSucceededCall)
  yield takeEveryApiCall('WORKERS_FETCH', api.listWorkers)
  yield takeLatestApiCall(types.WORKER_GENERAL_PREFERENCES_FETCH_BEGIN, api.fetchWorkerGeneralPreferences)
  yield takeLatestApiCall('WORKER_SAVE', api.saveWorker)
  yield takeLatestApiCall('WORKER_USER_DELETE', api.deleteUsers)
  yield takeLatest(types.WORKER_USER_DELETE_SUCCEEDED, redirectCall, () => '/entity/workers/list')
  yield takeLatest(usersTypes.USER_BAN_SUCCEEDED, workerUserBanSucceededCall)
  yield takeLatest(
    [
      types.WORKER_SAVE_SUCCEEDED,
      types.WORKER_SAVE_FAILED,
      types.WORKER_USER_DELETE_SUCCEEDED,
      types.WORKER_TYPE_UPDATE_SUCCEEDED,
    ],
    notificationCall,
  )
  yield takeLatestApiCall('WORKER_TYPE_UPDATE', updateWorkerType)

  // Reload the worker's skills each time we save their data.
  // This allows us to pick up skills that automatically get ticked by the system.
  yield takeLatest([types.WORKER_SAVE_SUCCEEDED], workerSuccessfulSaved)
}
