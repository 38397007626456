const baseUrl = `/admin/time_off_requests`

export const API_URL = {
  LIST: baseUrl,
  FETCH: (id: number) => `${baseUrl}/${id}`,
  UPDATE: (id: number) => `${baseUrl}/${id}`,
  APPROVE: (id: number) => `${baseUrl}/${id}/approve`,
  REJECT: (id: number) => `${baseUrl}/${id}/reject`,
  EMPLOYERS: (employer: string) => `${baseUrl}/employers${employer ? '?employer_name=' + employer : ''}`,
  VENUES: (venue: string) => `${baseUrl}/venues${venue ? '?venue_name=' + venue : ''}`,
}
