import React, { useCallback } from 'react'
import { FORM_ERROR } from 'final-form'
import { ControlLabel, FormGroup } from 'react-bootstrap'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { partial, range } from 'lodash-es'

import FormNumberInput from 'syft-acp-core/components/Form/FormNumberInput'
import { getModal$ } from 'syft-acp-core/store/modals/selectors'
import { hideModal as hideModalAction } from 'syft-acp-core/store/modals/actions'
import { downloadAsFileFromApi } from 'syft-acp-core/api/file'
import { formatCSVParams } from 'syft-acp-core/entities/InvoicingList'

import WorkingWeekInvoicingOptions, { defaultFieldNames } from '../WorkingWeekInvoicingOptions'
import ModalForm from '../ModalForm'
import { validateForm, validateInvoicingNumber } from './validations'
import { withTrackingTriggerHoc } from 'syft-acp-util/withTrackingTriggerHoc'
import { trackingEvents } from '../Modal.tracking'

import type { FormValues, Props } from './InvoicingCSVModal.types'

export const connectModal = connect(
  state => ({
    item: getModal$(state.modals)('invoicingCsvModal'),
  }),
  dispatch => bindActionCreators({ hideModal: hideModalAction }, dispatch),
  (stateProps, { hideModal }) => ({
    ...stateProps,
    hideModal: () => hideModal(stateProps.item.modalName),
  })
)

const initialValues = {
  [defaultFieldNames.workweekInvoicingData]: [],
  [defaultFieldNames.workingWeekOptions]: range(0, 7).map(String),
  [defaultFieldNames.masterInvoicingData]: ['true'],
}

const DOWNLOAD_INVOICING_CSV_URL = '/admin/employers/invoicing.csv'

const formProps = { initialValues, validate: validateForm }

export const InvoicingCSVModal = ({ hideModal, item, triggerEvent }: Props) => {
  const onConfirmHandler = useCallback(
    async ({ invoiceNumber, masterInvoicingData, workweekInvoicingData, workingWeekOptions }: FormValues) => {
      const [downloadMasterCSVOption] = masterInvoicingData
      const [downloadWorkweeCSVOption] = workweekInvoicingData

      const params = formatCSVParams(item.options)
      const extendedParams = { ...params, invoice_number: invoiceNumber }
      const download = partial(
        downloadAsFileFromApi,
        DOWNLOAD_INVOICING_CSV_URL,
        partial.placeholder,
        'employers-invoicing'
      )
      const downloadMasterCSV = downloadMasterCSVOption === 'true' && download(extendedParams)

      const downloadWorkweekCSV =
        downloadWorkweeCSVOption === 'true' &&
        workingWeekOptions?.length > 0 &&
        download({ ...extendedParams, invoicing_week_ends_on: workingWeekOptions })
      try {
        triggerEvent(trackingEvents.CSV_INVOICE_MODAL.DOWNLOAD, {
          invoiceNumber,
          workingWeekOptions,
          downloadMasterCSV: !!downloadMasterCSVOption,
          downloadWorkweekCSV: !!downloadWorkweeCSVOption,
        })

        await Promise.all([downloadMasterCSV, downloadWorkweekCSV].filter(Boolean))
        hideModal()
      } catch (e: any) {
        return {
          [FORM_ERROR]: `An unknown error occurred. Message ${e.message}`,
        }
      }
    },
    [hideModal, item.options]
  )

  return (
    (item.isShown && (
      <ModalForm<FormValues, typeof initialValues>
        header="Invoices CSV"
        confirmationText="Download"
        isShown={item.isShown}
        onConfirm={onConfirmHandler}
        onClose={hideModal}
        form={formProps}
      >
        {() => (
          <>
            <WorkingWeekInvoicingOptions fieldNames={defaultFieldNames} />
            <FormGroup>
              <ControlLabel>Invoice Number</ControlLabel>
              <FormNumberInput min={0} name="invoiceNumber" validate={validateInvoicingNumber} required />
            </FormGroup>
          </>
        )}
      </ModalForm>
    )) ||
    null
  )
}

export default connectModal(withTrackingTriggerHoc(InvoicingCSVModal))
