import * as types from './action-types'

import entityReducer from 'syft-acp-core/reducers/generators/entityReducer'
import * as entityTypes from 'syft-acp-core/reducers/generators/entities'

export default entityReducer('skills', {
  [entityTypes.ENTITIES_FETCH_BEGIN]: types.SKILLS_FETCH_BEGIN,
  [entityTypes.ENTITIES_FETCH_SUCCEEDED]: types.SKILLS_FETCH_SUCCEEDED,
  [entityTypes.ENTITIES_FETCH_FAILED]: types.SKILLS_FETCH_FAILED,
  [entityTypes.ENTITY_SAVE_BEGIN]: types.SKILLS_BULK_TOGGLE_BEGIN,
  [entityTypes.ENTITY_SAVE_SUCCEEDED]: types.SKILLS_BULK_TOGGLE_SUCCEEDED,
  [entityTypes.ENTITY_SAVE_FAILED]: types.SKILLS_BULK_TOGGLE_FAILED,
})
