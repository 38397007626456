import { takeLatest } from 'redux-saga/effects'
import { notificationCall } from 'syft-acp-core/sagas/calls'
import { takeLatestApiCall } from 'syft-acp-core/sagas/helpers'

import * as types from './action-types'
import { listSkills, bulkToggleSkill } from './api'

export default function* saga() {
  yield takeLatestApiCall(types.SKILLS_FETCH_BEGIN, listSkills)
  yield takeLatestApiCall(types.SKILLS_BULK_TOGGLE_BEGIN, bulkToggleSkill)
  yield takeLatest([types.SKILLS_BULK_TOGGLE_FAILED, types.SKILLS_BULK_TOGGLE_SUCCEEDED], notificationCall)
}
