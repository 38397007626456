import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { createEntityListConnector } from 'syft-acp-util/entityList'
import { fetchCompliancePolicies } from 'syft-acp-core/store/compliancePolicies/actions'
import { CompliancePolicyEntity } from 'syft-acp-core/store/compliancePolicies/types'
import { Props, OwnProps, RenderProps } from './CompliancesListWrapper.types'

const CompliancesListContainer = ({
  employerID,
  roleID,
  title,
  actions,
  render,
  query,
  data: entityListData,
}: Props) => {
  useEffect(() => {
    actions.fetchEntities &&
      actions.fetchEntities({
        options: query,
      })
    // eslint-disable-next-line
  }, [])
  return <>{render({ title, employerID, data: entityListData.data, roleID })}</>
}
export const listConnector = createEntityListConnector<CompliancePolicyEntity>({
  entityActions: { fetchEntities: fetchCompliancePolicies },
  entityStore: 'compliancePolicies',
})

const ConnectedCompliancesListContainer = listConnector(CompliancesListContainer)

const CompliancesListWrapper = ({ render, ...props }: OwnProps) => {
  const [clientComplianceData, setClientComplianceData] = useState([] as CompliancePolicyEntity[])

  const legalQuery = useMemo(
    () => ({
      reason: 'legal_compliance',
      role_id: props.roleID,
    }),
    [props.roleID],
  )

  const employerQuery = useMemo(
    () => ({
      employer_id: props.employerID,
      role_id: props.roleID,
    }),
    [props.employerID, props.roleID],
  )

  const clientRender = useCallback(({ data }: RenderProps) => {
    setClientComplianceData(data)
    return <div />
  }, [])

  const legalRender = useCallback(
    ({ data, ...rest }: RenderProps) => {
      return render({ data: [...data, ...clientComplianceData], ...rest })
    },
    [clientComplianceData, render],
  )

  return (
    <>
      <ConnectedCompliancesListContainer {...props} query={employerQuery} render={clientRender} />
      <ConnectedCompliancesListContainer {...props} query={legalQuery} render={legalRender} />
    </>
  )
}

export default CompliancesListWrapper
