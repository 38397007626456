// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import * as types from '../actions/action-types'

// Initialization for all types of stats we have.
const statsModel = {
  message: '',
  isLoadingData: false,
  data: {},
}

const statsModelRehydrateReset = {
  message: '',
  isLoadingData: false,
}

const initialState = {
  employers: { ...statsModel },
  workers: { ...statsModel },
}

// TODO: this should likely be rewritten as a factory.
// There's a lot of duplicate logic here and we can't cache data per employer/worker ID.
// TODO: add begin/failure cases for worker stats.
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.AUTH_DELETE:
      return initialState
    case types.REHYDRATION_COMPLETE:
      return {
        ...state,
        employers: {
          ...state.employers,
          ...statsModelRehydrateReset,
          // TODO: this is technically not needed, but this will convert the old reducer
          // structure to the new structure.
          // Added: 2017-10-05. Can be removed in a month or so when we're sure
          // everybody has been converted to the new structure.
          data: state.employers.data || {},
        },
        workers: {
          ...state.workers,
          ...statsModelRehydrateReset,
          data: state.workers.data || {},
        },
      }
    case types.STATS_EMPLOYER_FETCH_BEGIN:
      return {
        ...state,
        employers: {
          ...state.employers,
          message: '',
          isLoadingData: true,
          data: {
            ...state.employers.data,
            [action.id]: {},
          },
        },
      }
    case types.STATS_EMPLOYER_FETCH_SUCCEEDED:
      return {
        ...state,
        employers: {
          ...state.employers,
          message: '',
          isLoadingData: false,
          data: {
            ...state.employers.data,
            [action.request.id]: action.payload,
          },
        },
      }
    case types.STATS_EMPLOYER_FETCH_FAILED:
      return {
        ...state,
        employers: {
          ...state.employers,
          message: action.payload.message,
          isLoadingData: false,
          data: {
            ...state.employers.data,
            [action.request.id]: {},
          },
        },
      }
    case types.STATS_WORKER_FETCH_SUCCEEDED:
      return {
        ...state,
        workers: {
          ...state.workers,
          message: '',
          isLoadingData: false,
          [action.request.payload.worker_id]: action.payload,
        },
      }
    default:
      return state
  }
}

export default reducer
