// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import * as types from './action-types'

/**
 * Save the current app version into the store.
 * If the user opens the app and its version is different than the one in their local storage,
 * it means the app has updated.
 */
export function saveCurrentVersion(version) {
  return { type: types.APP_SAVE_CURRENT_VERSION, version }
}

/**
 * Removes old data from the store. We cache a lot of data, and this data adds up over time.
 * For example, if you visit the detail page of an employer this will store its data forever
 * until you revisit the page (at which point a fresh copy is fetched from the server).
 */
export function runRemoveUnusedData() {
  return { type: types.APP_REMOVE_UNUSED_DATA }
}

/**
 * Always "succeeds" (no distinction between success and failure condition).
 */
export function afterRemoveUnusedData() {
  return { type: types.APP_REMOVE_UNUSED_DATA_AFTER }
}

/**
 * Resets local storage and reloads the app.
 */
export function resetAndReload() {
  return { type: types.APP_RESET_AND_RELOAD }
}

export function setCountryCode(countryCode) {
  return { type: types.APP_SET_COUNTRY_CODE, countryCode }
}
