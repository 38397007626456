/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { flowRight, isEmpty } from 'lodash-es'
import { Link } from 'react-router-dom'
import deepEqual from 'react-fast-compare'

import Acp from 'syft-acp-uikit'
import { RowCallbackData } from 'syft-acp-uikit/components/AcpTable/AcpTable.types'
import { showModal } from 'syft-acp-core/store/modals/actions'
import * as shiftsActions from 'syft-acp-core/store/shifts/actions'
import * as shiftBookingsActions from 'syft-acp-core/store/shift-bookings/actions'
import * as filtersActions from 'syft-acp-core/store/filters/actions'
import * as helpers from 'syft-acp-core/entities/ShiftList/helpers/tableHelpers'
import { RowValue } from 'syft-acp-core/entities/ShiftList/helpers/helpers.types'
import { createEntityListConnector } from 'syft-acp-util/entityList'
import FilterSelectIndustries from 'syft-acp-util/components/FilterForm/FilterSelectIndustries'
import FilterAutocompleteEmployers from 'syft-acp-util/components/FilterForm/FilterAutocompleteEmployers'
import usePrevValue from 'syft-acp-util/hooks/usePrevValue'
import ShiftFulfilmentModal from 'syft-acp-core/components/Modal/ShiftFulfilmentModal'
import { getVenueCity } from 'syft-acp-util/cities'
import { RoleEntity } from 'syft-acp-core/store/roles/types'
import { getPriorityLabel } from 'syft-acp-util/components/FilterForm/FilterTiers'
import { Tooltip } from '@material-ui/core'

import { Error as ErrorIcon } from '@indeed/ifl-icons'

import { useFlexFlagIsOn } from '@indeed/flex-feature-flags'
import { useTrackingTrigger } from '@indeed/flex-tracking-context'
import { trackingEvents } from './ShiftFulfilmentList.tracking'
import { currentSortOrder } from './ShiftFulfilmentList.helpers'

import {
  FilterDateRange,
  FilterRolesSkills,
  FilterOfferTypes,
  FilterTiers,
  FilterSelectCities,
  FilterBoolCheckbox,
  FilterShiftsViewFrom,
  FilterClientDeliveryTeam,
} from 'syft-acp-util/components/FilterForm'
import { getDateRange } from 'syft-acp-core/lib/dateFilter'

import {
  ShiftFulfilmentListProps as Props,
  ShiftFulfilmentListEntityConnectorProps as EntityConnectorProps,
  ShiftLegendType,
} from './ShiftFulfilmentList.types'
import { ShiftEntity } from 'syft-acp-core/store/shifts/types'

import S from './ShiftFulfilmentList.module.scss'
import ShiftFulfilmentBookingsList from './ShiftFulfilmentBookingsList'

export const entityConnector = createEntityListConnector<ShiftEntity>({
  entityActions: { fetchEntities: shiftsActions.fetchShifts },
  entityStore: 'shifts',
})

export const getRowColor = (rowData: ShiftEntity) => {
  // Unfilled late cancellation - PTE-10243
  if (rowData.late_cancellation && rowData.workers_required !== rowData.workers_booked) return 'red'
  // A company's first ever job with Syft:
  if (rowData.venue_trial_period_shift) return 'cyan'
  return ''
}

const ShiftLegend = ({
  title,
  description,
  className = 'shift_fulfilment-legend__first-shifts-legend',
}: ShiftLegendType) => {
  return (
    <div className={S['shift_fulfilment-legend__container']}>
      <div className={S['shift_fulfilment-legend__first-shifts']}>
        <div className={S[className]} />
        <div className={S['shift_fulfilment-legend__text']}>
          <div className={S['shift_fulfilment-legend__header']}>{title}</div>
          <div className={S['shift_fulfilment-legend__sub-text']}>{description}</div>
        </div>
      </div>
    </div>
  )
}

export const storeConnector = connect(
  state => ({
    roles: state.roles.entityMap as RoleEntity[],
    isSavingData: state.shifts.isSavingData,
  }),
  (dispatch, props: EntityConnectorProps) => ({
    actions: {
      ...props.actions,
      ...bindActionCreators({ ...shiftBookingsActions, showModal, ...filtersActions }, dispatch),
    },
  }),
)

const modalName = 'shiftFulfilmentModal'

export const openModalHandler = ({
  event,
  openModal,
}: {
  event: RowCallbackData<ShiftEntity>
  openModal: typeof showModal
}) => {
  if (event.columnN !== 0) {
    openModal(modalName, {}, null, event)
  }
}

export const BookableIndividuallyHeader = () => (
  <div className={S['shift-fulfilment-list__header']}>
    <div>Bookable</div> <div>individually</div>
  </div>
)

const ShiftFulfilmentList = ({ actions, data, isLoadingData, query, roles }: Props) => {
  const impactFeatureEnabled = useFlexFlagIsOn('pte_7246_shift_fulfilment_impact')
  const impactDropdownShown = useFlexFlagIsOn('pte_7246_shift_fulfilment_impact_enable_dropdown')
  const shiftRoleChanges = useFlexFlagIsOn('pte_20653_shift_role_changes')
  const dateRangeShift = getDateRange(0, 1, 'start_time_gte', 'start_time_lte', true, true, true)
  const dates = helpers.addDayIfSameDay(dateRangeShift.start_time_gte, dateRangeShift.start_time_lte)
  const triggerEvent = useTrackingTrigger()
  // Initial filters for reset button
  const initialFilters = {
    ...dates,
    filled: 'false',
    sort_order: impactFeatureEnabled ? 'fulfilment_review_impact_score_desc' : 'start_time_asc',
  }
  const prevQuery = usePrevValue(query)
  const fetch = useCallback(
    (q: Record<string, any>, toCSV = false, sourceComponent = 'shiftFulfilment') =>
      // @ts-ignore
      actions.fetchEntities && actions.fetchEntities({ options: q }, {}, toCSV, false, sourceComponent),
    [actions],
  )

  const onFilterChange = useCallback((key: string, value?: string | string[]): void => {
    if (value && value.length > 0) {
      triggerEvent(trackingEvents.SHIFT_FULFILMENT.VIEW_FILTERS.CHANGED, { filter: key, value })
    }
    if (key === 'fulfilment_reviewed' && !value) {
      triggerEvent(trackingEvents.SHIFT_FULFILMENT.VIEW_FILTERS.CHANGED, { filter: key, value: 'any' })
    }
  }, [])

  const onSortChange = (_key: string, value?: string): void => {
    triggerEvent(trackingEvents.SHIFT_FULFILMENT.VIEW_SORT.CHANGED, { sort: value })
  }

  const [employersName, setEmployersName] = useState<string>()
  useEffect(() => {
    // Reset filters on mount in case query is empty
    if (isEmpty(query)) {
      actions.resetFilters(initialFilters)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query])

  // NOTE: See https://syftapp.atlassian.net/browse/PTE-8512
  // Because the feature set is loaded from Growthbook after the initial table
  // filters are set, we need to ensure that the `sort_order` filter is reset to
  // the correct value after the features are loaded, if needed.
  useEffect(() => {
    if (impactFeatureEnabled) {
      actions.setFilter('sort_order', 'fulfilment_review_impact_score_desc')
    }
  }, [impactFeatureEnabled])

  useEffect(() => {
    if (!deepEqual(prevQuery, query) && !isEmpty(query)) fetch(query)
  }, [fetch, prevQuery, query])

  useEffect(() => {
    if (isLoadingData === false && !!data?.meta?.total) {
      triggerEvent(trackingEvents.SHIFT_FULFILMENT.PAGE.LOADED, { result_count: data.meta.total })
    }
  }, [isLoadingData])

  const getRole = (e: RowValue) =>
    helpers.getNameWithOverride(e, 'role', shiftRoleChanges, helpers.formatRole(roles[e.role_id], e.category))
  const rowCallback = (e: RowCallbackData<ShiftEntity>) => {
    const trackingEvent =
      e.columnN === 0
        ? trackingEvents.SHIFT_FULFILMENT.SHIFT_LINK.CLICKED
        : trackingEvents.SHIFT_FULFILMENT.SHIFT.SELECTED
    triggerEvent(trackingEvent, {
      shift_id: e.rowData.id,
      sort_order: currentSortOrder(),
      fulfilment_review_impact_score: e.rowData.fulfilment_review_impact_score,
    })
    openModalHandler({ event: e, openModal: actions.showModal })
  }

  const popoverFilterOpened = () => {
    triggerEvent(trackingEvents.SHIFT_FULFILMENT.VIEW_FILTERS.OPENED)
  }

  const popoverFilterClosed = () => {
    triggerEvent(trackingEvents.SHIFT_FULFILMENT.VIEW_FILTERS.CLOSED)
  }

  const fieldClassName = 'shift-fulfilment-list__field'
  return (
    <>
      <Acp.EntityList
        hasPopoverFilters
        popoverButtonLabel={impactFeatureEnabled && impactDropdownShown ? 'Filter & Sort' : null}
        initialFilters={initialFilters}
        withInfoColumn
        data={data}
        legend={
          <>
            <ShiftLegend title="First shifts at venue" description="(2 week period)" />
            <ShiftLegend
              className="shift_fulfilment-legend__last_minute_cancelations-legend"
              title="Last minute cancellations"
              description="(within 24hrs of the start of the shift)"
            />
          </>
        }
        isLoading={isLoadingData}
        hasResultCount
        hasPagination
        idKeyValue="id"
        onPopoverFilterOpen={popoverFilterOpened}
        onPopoverFilterClose={popoverFilterClosed}
      >
        <Acp.Actions narrowContainer>
          {impactFeatureEnabled && impactDropdownShown && (
            <Acp.FilterGroup title="Sort by">
              <Acp.Filter.Dropdown className={S[fieldClassName]} name="sort_order" onChange={onSortChange}>
                <Acp.Option slug="start_time_asc">Start Time</Acp.Option>
                <Acp.Option slug="fulfilment_review_impact_score_desc">Impact</Acp.Option>
              </Acp.Filter.Dropdown>
            </Acp.FilterGroup>
          )}
          <Acp.FilterGroup title="View from">
            <div style={{ marginRight: impactFeatureEnabled && impactDropdownShown ? 323 : 500 }}>
              <FilterShiftsViewFrom onChange={onFilterChange} />
            </div>
          </Acp.FilterGroup>
          <Acp.FilterGroup title="Date">
            <FilterDateRange
              showShortTime
              nameFrom="start_time_gte"
              nameTo="start_time_lte"
              onChange={onFilterChange}
            />
          </Acp.FilterGroup>
          <Acp.FilterGroup title="Bookable individually">
            <Acp.Filter.Dropdown
              className={S[fieldClassName]}
              name="bookable_individually"
              placeholder="Any"
              onChange={onFilterChange}
            >
              <Acp.Option slug={true}>Yes</Acp.Option>
              <Acp.Option slug={false}>No</Acp.Option>
            </Acp.Filter.Dropdown>
          </Acp.FilterGroup>
          <Acp.FilterGroup title="Reviewed">
            <Acp.Filter.Dropdown
              className={S[fieldClassName]}
              name="fulfilment_reviewed"
              placeholder="Any"
              onChange={onFilterChange}
            >
              <Acp.Option slug={true}>Yes</Acp.Option>
              <Acp.Option slug={false}>No</Acp.Option>
            </Acp.Filter.Dropdown>
          </Acp.FilterGroup>
          <Acp.FilterGroup title="Employer">
            <FilterAutocompleteEmployers
              callbackSaveLabel={(name?: string) => {
                setEmployersName(name)
              }}
              name="employer_id"
              placeholder="Employer name"
              queryType="id"
              forceValue={employersName}
              onChange={onFilterChange}
            />
          </Acp.FilterGroup>
          <Acp.FilterGroup title="Venue">
            <Acp.Filter.Text name="venue_name" placeholder="Venue name" small onChange={onFilterChange} />
          </Acp.FilterGroup>
          <Acp.FilterGroup title="City">
            <FilterSelectCities name="city_id" allowAny onChange={onFilterChange} />
          </Acp.FilterGroup>
          <Acp.FilterGroup title="Client delivery team">
            <FilterClientDeliveryTeam
              onChange={onFilterChange}
              name="client_delivery_team_id"
              allowAny
              small
            />
          </Acp.FilterGroup>
          <Acp.FilterGroup title="Priority">
            <FilterTiers name="tier" allowAny className={S[fieldClassName]} onChange={onFilterChange} />
          </Acp.FilterGroup>
          <Acp.FilterGroup title="Shift">
            <Acp.Filter.Text
              name="shift_id"
              placeholder="Shift ID"
              queryType="id"
              small
              onChange={onFilterChange}
            />
          </Acp.FilterGroup>
          <Acp.FilterGroup title="Job">
            <Acp.Filter.Text
              name="job_id"
              placeholder="Job ID"
              queryType="id"
              small
              onChange={onFilterChange}
            />
          </Acp.FilterGroup>
          <Acp.FilterGroup title="Listing">
            <Acp.Filter.Text
              name="listing_id"
              placeholder="Listing ID"
              queryType="id"
              small
              onChange={onFilterChange}
            />
          </Acp.FilterGroup>
          <Acp.FilterGroup compact={true}>
            <FilterOfferTypes allowAny className={S[fieldClassName]} onChange={onFilterChange} />
          </Acp.FilterGroup>
          <Acp.FilterGroup title="Industry">
            <FilterSelectIndustries
              name="industry_id"
              queryKey="industry_id"
              options={{ idKey: 'id' }}
              allowAny
              className={S[fieldClassName]}
              onChange={onFilterChange}
            />
          </Acp.FilterGroup>
          <Acp.FilterGroup title="Roles and skills">
            <div style={{ marginRight: '150px' }}>
              <FilterRolesSkills
                role_key="role_id"
                skill_key="skill_id"
                independentSkills
                allowAny
                small
                onChange={onFilterChange}
              />
            </div>
          </Acp.FilterGroup>
          <Acp.FilterGroup title="">
            <FilterBoolCheckbox name="filled" value="false" label="Unfilled" onChange={onFilterChange} />
            <>
              <FilterBoolCheckbox
                name="venue_first_shifts"
                value="true"
                label="First shifts at venue"
                onChange={onFilterChange}
              />
              <FilterBoolCheckbox
                name="late_cancellation"
                value="true"
                label="Last minute cancellations"
                onChange={onFilterChange}
              />
              <FilterBoolCheckbox
                name="unconfirmed_cancellation"
                value="true"
                label="Unconfirmed worker cancellations"
                onChange={onFilterChange}
              />
            </>
          </Acp.FilterGroup>
        </Acp.Actions>
        <Acp.Table rowColor={getRowColor} rowCallback={rowCallback}>
          <Acp.Col.Info
            value={() => {
              return (
                <Tooltip
                  title={<div style={{ fontSize: 15 }}>Unconfirmed worker cancellation</div>}
                  placement="left"
                  arrow
                >
                  <ErrorIcon color="red" />
                </Tooltip>
              )
            }}
            tooltip="Unconfirmed worker cancellation"
            disableFunc={(rowData: ShiftEntity) => !rowData.unconfirmed_cancellation}
            header=""
            pureCell
            hasNoneStyles
            disabled
            isMinimal
          />
          <Acp.Col.Text
            value={(rowData: ShiftEntity) => (
              <Link to={`/listings/view/${rowData.listing_id}/job/${rowData.job_id}/shift/${rowData.id}`}>
                {rowData.id}
              </Link>
            )}
            header="Shift ID"
            colNoCallback
            hasLeftBorder
          />
          <Acp.Col.Text value={helpers.getBrandCompany} header="Brand/company" />
          <Acp.Col.Text value={helpers.getVenue(shiftRoleChanges)} header="Venue" />
          <Acp.Col.Text value={helpers.getArea(shiftRoleChanges)} header="Area" />
          <Acp.Col.DateTime
            value={['start_time', 'end_time']}
            header="Start/end"
            options={{ timeZone: 'venue.timezone' }}
            isMinimal
            withOverrides
          />
          <Acp.Col.Text value={getVenueCity} header="City" />
          <Acp.Col.Text value={getRole} header="Role" />
          <Acp.Col.Popover
            value={helpers.getWorkersBookedRequired}
            disableFunc={(rowData: ShiftEntity) => !rowData.workers_booked}
            header="Required"
            isMinimal
            isNumeric
            container={({ rowData }: { rowData?: any }) => {
              return (
                <ShiftFulfilmentBookingsList
                  jobId={rowData.job_id}
                  roleId={rowData.role_id}
                  area={rowData.area}
                  startTime={rowData.start_time}
                  endTime={rowData.end_time}
                  venue={rowData.venue}
                  shiftID={rowData.id}
                  payRate={rowData.pay_rate}
                  shiftBookings={rowData.shift_bookings}
                  workersBooked={rowData.workers_booked}
                />
              )
            }}
            align="center"
          />
          <Acp.Col.Text value="booking_stats.applied" header="Applied" isMinimal isNumeric align="center" />
          <Acp.Col.Text value="booking_stats.offered" header="Offered" isMinimal isNumeric align="center" />
          <Acp.Col.Text
            value={helpers.getOfferTypeValue}
            valueTooltip={helpers.getOfferTypeValueTooltip}
            align="center"
            header="OT"
            headerTooltip="Offer type (hover over letters for more information)"
            isMinimal
          />
          <Acp.Col.Boolean
            value="bookable_individually"
            header={<BookableIndividuallyHeader />}
            isMinimal
            align="center"
          />
          <Acp.Col.Text
            value={(rowData: ShiftEntity) => helpers.getHardSkills(rowData, roles)}
            header="Hard skills"
          />
          <Acp.Col.Text
            value={(rowData: ShiftEntity) => helpers.getPayRate(rowData, shiftRoleChanges)}
            header="Employer rate"
            isMinimal
            isNumeric
            align="center"
          />
          <Acp.Col.Money value="offer_rate" header="Offer rate" isMinimal isNumeric align="center" />
          {impactFeatureEnabled && (
            <Acp.Col.Text
              value={(rowData: ShiftEntity) => helpers.impactScoreToPercentage(rowData)}
              header="Impact"
              headerTooltip="Priority order of edits (% likelihood of a shift remaining unfilled)"
            />
          )}
          <Acp.Col.Text
            value={(rowData: ShiftEntity) => getPriorityLabel(rowData?.employer?.tier)}
            header="Employer priority"
            isMinimal
            align="center"
          />
          <Acp.Col.Boolean value="fulfilment_reviewed" header="Reviewed" isMinimal align="center" />
        </Acp.Table>
      </Acp.EntityList>
      <ShiftFulfilmentModal modalName={modalName} />
    </>
  )
}

export default flowRight(entityConnector, storeConnector)(ShiftFulfilmentList)
