// Syft ACP - Lib <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import React from 'react'
import PropTypes from 'prop-types'

import './ActionGroup.css'

const ActionGroup = ({ children, hasFilters }) => (
  <div className="ActionGroup" data-testid="entity-list-actions-group">
    {hasFilters && <span className="button-group-label">&nbsp;</span>}
    <div className="group-items">{children}</div>
  </div>
)

ActionGroup.propTypes = {
  hasFilters: PropTypes.bool,
  children: PropTypes.node,
}

ActionGroup.defaultProps = {
  hasFilters: false,
  children: null,
}
export default ActionGroup
