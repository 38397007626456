// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import React from 'react'
import { differenceInYears, endOfToday, isDate } from 'date-fns'
import iconFactory from 'syft-acp-atoms/Icons'
import DataDate from './DataDate'

import './DataDate.css'

const currentDate = endOfToday()

const defaultParams = {
  message: 'Worker is younger than 18 years old',
  minAge: 18,
}

const AlertIcon = iconFactory('alert')

const DataDateMinAge = ({ value, parameters = defaultParams, ...props }) => {
  const { message, minAge } = { ...defaultParams, ...parameters }

  const showMessage = () => {
    const dateValue = new Date(value)
    if (!value || !isDate(dateValue)) {
      return false
    }
    const age = differenceInYears(currentDate, dateValue)
    return age < minAge
  }

  return (
    <div className="date-picker-wrapper">
      <DataDate {...props} value={value} />
      {showMessage() ? (
        <div className="date-picker-message">
          <AlertIcon className="date-picker-message-icon" />
          {message}
        </div>
      ) : null}
    </div>
  )
}

export default React.memo(DataDateMinAge)
