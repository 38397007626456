import { get } from 'lodash-es'
import entityReducer from './generators/entityReducer'
import * as types from '../actions/action-types'
import * as entityTypes from './generators/entities'

export const getNewState =
  type =>
  (state, action = {}) => {
    const { payload = {} } = action
    const csatType = get(action, 'request.payload.type')
    return {
      ...state,
      lastMessage: '',
      lastStatus: null,
      lastBody: null,
      isLoadingData: false,
      entityMap: {
        ...state.entityMap,
        [csatType]: {
          ...state.entityMap[csatType],
          ...(type === 'skip' || type === 'score' ? payload : { reasons: payload.reasons }),
        },
      },
    }
  }

export default entityReducer('csat', {
  [entityTypes.ENTITIES_FETCH_BEGIN]: types.CSAT_SKIPPED_FETCH_BEGIN,
  [types.CSAT_SKIPPED_FETCH_SUCCEEDED]: getNewState('skip'),
  [entityTypes.ENTITIES_FETCH_FAILED]: types.CSAT_SKIPPED_FETCH_FAILED,
  [entityTypes.ENTITIES_FETCH_BEGIN]: types.CSAT_SCORE_FETCH_BEGIN,
  [types.CSAT_SCORE_FETCH_SUCCEEDED]: getNewState('score'),
  [entityTypes.ENTITIES_FETCH_FAILED]: types.CSAT_SCORE_FETCH_FAILED,
  [entityTypes.ENTITIES_FETCH_BEGIN]: types.CSAT_RESULTS_FETCH_BEGIN,
  [types.CSAT_RESULTS_FETCH_SUCCEEDED]: getNewState(),
  [entityTypes.ENTITIES_FETCH_FAILED]: types.CSAT_RESULTS_FETCH_FAILED,
})
