import React, { memo } from 'react'
import classNames from 'classnames'

import { cellDefaultProps } from '../../dataProps'
import { emptyTextFallback } from './../fallback'
import { colPlaceholderFactory } from '../../../../placeholders/factory'

import { AcpCellEmailProps as Props } from './AcpCellEmail.types'
import './AcpCellEmail.scss'

/** Column placeholder component. */
export const AcpColEmail = colPlaceholderFactory('AcpCellEmail')

/**
 * Column type for displaying emails.
 *
 * ```
 * <Acp.Col.Email value="email_address" header="Email" />
 * ```
 */
const AcpCellEmail = ({ value }: Props) => (
  // Check if value is null / undefined - in that case we display a fallback string to indicate the cell is empty.
  <span className={classNames('acp-cell-email')}>{value == null ? emptyTextFallback : value}</span>
)

AcpCellEmail.defaultProps = cellDefaultProps

export default memo(AcpCellEmail)
