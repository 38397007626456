import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { Button } from 'syft-acp-atoms/Button'
import { withTrackingTriggerHoc } from 'syft-acp-util/withTrackingTriggerHoc'
import { saveAgencyBookings } from 'syft-acp-core/actions/agency-bookings'
import { trackingEvents } from '../../tracking'

const ListingSaveAgencyBookingsButton = ({ label, disabled, entityMap, actions, shiftID, triggerEvent }) => {
  const onClick = () => {
    triggerEvent(trackingEvents.LISTING_INFO.AGENCY_BOOKING_SUBSECTION.SAVED, { shiftID })
    const entityMapWithBreakDuration = entityMap[shiftID].agency_shift_bookings.map(
      ({ break: { duration }, ...rest }) => ({
        break_duration: duration,
        ...rest,
      })
    )

    actions.saveAgencyBookings({
      entries: entityMapWithBreakDuration,
    })
  }

  return (
    <Button kind="success" onClick={onClick} disabled={disabled}>
      {label}
    </Button>
  )
}

ListingSaveAgencyBookingsButton.propTypes = {
  actions: PropTypes.object.isRequired,
  disabled: PropTypes.bool.isRequired,
  triggerEvent: PropTypes.func.isRequired,
  entityMap: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  shiftID: PropTypes.number.isRequired,
}

const mapStateToProps = state => {
  const { entityMap, isSavingData, hasChanges = false } = state.listingAgencyShiftBookings

  const disabled = isSavingData || !hasChanges

  return {
    disabled,
    entityMap,
  }
}

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({ saveAgencyBookings }, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(withTrackingTriggerHoc(ListingSaveAgencyBookingsButton))
