import entityList from 'syft-acp-util/entityList'
import * as actions from 'syft-acp-core/actions/clientApplications'
import * as tableType from 'syft-acp-core/components/EntityList/'
import EntityListComponent from 'syft-acp-core/components/EntityList/EntityListComponent'

const tableFormat = [
  {
    type: tableType.TXT,
    val: 'identifier',
    header: 'Identifier',
  },
  {
    type: tableType.TXT,
    val: 'version',
    header: 'Oldest allowed version',
  },
]

const filters = null

const actionFormat = null

export default entityList(
  EntityListComponent,
  tableFormat,
  filters,
  actionFormat,
  'clientApplications',
  { fetchEntities: actions.fetchAdminClientApplications },
  {
    hasLinks: false,
  },
)
