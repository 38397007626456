import { extractNodeSpec } from '../../lib/childNodes'

/** Extracts table specification for only subrows. */
export const getSubRowSpec = dataDefinition => dataDefinition.filter(item => item._subRow && item.value)

/** Extracts the table specification from an AcpTable's child nodes. */
export const getTableSpec = childNodes => {
  // First get the raw data; AcpCol group items, AcpSubRows and AcpColGroup items.
  const deepSpec = extractNodeSpec(childNodes, ['AcpSubRows', 'AcpColGroup'], ['AcpCol', 'AcpEditableCol'])

  // Flatten the spec by merging nested subrow items into the main list.
  const flatSpec = deepSpec.reduce((all, item) => {
    // For ColGroup and SubRows items, merge the nested items into the main spec and tag them.
    const items =
      item._nested && item._type === 'AcpSubRows'
        ? item._nested.map(subItem => ({
            ...subItem,
            _subRow: true,
            // SubRows should have a 'value' attribute for getting their data.
            // E.g. if the value is set to 'myArray', then a Col.Text field with
            // value 'myText' will end up getting 'myArray[n].myText' for each subrow.
            _subRowValue: item.value,
            // SubRows items can also have the same three row specifiers that Table also gets.
            _subRowColor: item.rowColor,
            _subRowLink: item.rowLink,
            _subRowSelected: item.rowSelected,
          }))
        : item._nested && item._type === 'AcpColGroup'
        ? item._nested.map(subItem => ({
            ...subItem,
            _colGroup: item.empty ? ' ' : item.title,
          }))
        : [item]
    return [...all, ...items]
  }, [])

  // Check if any of the items has a colGroup defined.
  // If it does, we need to count the number of consecutive items without a colGroup defined.
  // If not, we can ignore all colGroups.
  const hasColGroups = flatSpec.find(item => item._colGroup != null) != null

  // Final specification filtering.
  const spec = flatSpec
    // Go through all items to determine the colSpan values for col groups.
    .map((item, n, items) => {
      // Ignore if there are no colGroups.
      if (!hasColGroups) return item

      // Check the current item's colGroup, setting it to an empty string if undefined.
      const itemColGroup = item._colGroup != null ? item._colGroup : ''

      // All items that follow the current one.
      const subsequent = items.filter((_, m) => m > n)

      // Check all subsequent items to see how many items this group has.
      let groupItems = 0
      for (const _item of subsequent) {
        const subsqItemColGroup = _item._colGroup != null ? _item._colGroup : ''
        const hasSameColGroup = subsqItemColGroup === itemColGroup
        if (!hasSameColGroup) break
        groupItems += 1
      }
      return { ...item, _colGroupItems: groupItems }
    })
    // One more pass to check which col group items are inside another group.
    // E.g. the second item of a group of 3 should not have its own column rendered.
    .map((item, n, items) => {
      // Ignore if there are no colGroups.
      if (!hasColGroups) return item

      // Skip the first item since it can't be inside anything.
      const previous = items[n - 1]
      const itemColGroup = item._colGroup
      if (!previous) return item
      const prevColGroup = previous._colGroup

      // Check whether this item is inside another group.
      const isGroupMember = prevColGroup === itemColGroup && previous._colGroupItems > 0
      return { ...item, _colGroupMember: isGroupMember }
    })
    // Some items have no headers; in this case the previous header is spanned across its column.
    // Go through the list of items and calculate the colSpan values.
    // The only exception is selection columns, which always retain a header for the 'select all'.
    .map((item, n, items) => {
      // All items that follow the current one.
      const subsequent = items.filter((_, m) => m > n)

      // Count the number of empty headers following the current item.
      let emptyHeaders = 0
      for (const _item of subsequent) {
        const hasHeader = _item.header != null
        const hasForcedHeader = _item._hasForcedHeader
        if (hasHeader || hasForcedHeader) break
        emptyHeaders += 1
      }
      return { ...item, _subsequentEmptyHeaders: emptyHeaders }
    })
    // Mark items as editable if they were created using ColEditable rather than Col.
    .map(item => {
      return { ...item, isEditable: item.isEditable || item._typeGroup === 'AcpEditableCol' }
    })

  return spec
}

/** Extracts table footer components. */
export const getFooter = childNodes => extractNodeSpec(childNodes, ['AcpFooter'])
