// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import React from 'react'

import PageTitle from 'syft-acp-core/components/PageTitle'
import MarketingWorkersList from 'syft-acp-core/entities/MarketingWorkersList'

const MarketingUsers = () => (
  <PageTitle title={['Users', 'Marketing']}>
    <div className="page">
      <MarketingWorkersList />
    </div>
  </PageTitle>
)

export default MarketingUsers
