import { useEffect } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import { setFilter } from 'syft-acp-core/store/filters/actions'

import { SetFilterProps as Props, SetFilterOwnProps as OwnProps } from './SetFilter.types'

export const storeConnector = connect(
  (state, { queryKey }: OwnProps) => ({
    hasQuery: !!state.routing.locationBeforeTransitions.query[queryKey],
  }),
  dispatch => ({
    actions: bindActionCreators({ setFilter }, dispatch),
  })
)

/**
 * Ensures that a query parameter is always set.
 * NOTE: This would be better suited as a hook but we do not have access to redux hooks.
 */
export const SetFilter = ({ actions, hasQuery, queryKey, value, disabled = false }: Props) => {
  useEffect(() => {
    if (!disabled && !hasQuery) actions.setFilter(queryKey, value || 'true')
  }, [actions, hasQuery, queryKey, value, disabled])

  return null
}

export default storeConnector(SetFilter)
