// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import React from 'react'

import ListingsList from 'syft-acp-core/entities/ListingsList'
import PageTitle from 'syft-acp-core/components/PageTitle'

import './EntityList.css'

const Listings = () => (
  <PageTitle title="Listings">
    <div className="page entity-list">
      <div className="list">
        <ListingsList />
      </div>
    </div>
  </PageTitle>
)

export default Listings
