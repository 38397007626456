import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { sortBy } from 'lodash-es'
import { FormControl, ControlLabel, FormGroup } from 'react-bootstrap'

import { getModal$ } from 'syft-acp-core/store/modals/selectors'
import { Modal } from 'syft-acp-core/components/Modal'
import { showModal, hideModal } from 'syft-acp-core/store/modals/actions'

class EmployerAccountsNewLocationModal extends PureComponent {
  static propTypes = {
    employerLocations: PropTypes.array.isRequired,
    newLocationCallback: PropTypes.func.isRequired,
    item: PropTypes.object.isRequired,
    dispatch: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props)
    this.initialState = { msg: null, valid: true, chosenLocation: '' }
    this.state = this.initialState
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    // If we are becoming visible, while not being visible, reset the form.
    if (this.props.item.isShown !== nextProps.item.isShown && nextProps.item.isShown === true) {
      this.setState(this.initialState)
    }
  }

  onConfirmHandler = () => {
    if (this.validate()) {
      this.props.newLocationCallback(Number(this.state.chosenLocation))
      this.closeModal()
    }
  }

  setWeekValue = ev => {
    this.setState({ weekValue: ev.target.value })
  }

  validate = () => {
    if (!this.state.chosenLocation) {
      this.setState({ valid: false, msg: 'Select a location' })
      return false
    }

    this.setState({ valid: true, msg: null })
    return true
  }

  closeModal = () => this.props.dispatch(hideModal(this.props.item.modalName))

  openModal = () => this.props.dispatch(showModal(this.props.item.modalName))

  setLocation = ev => {
    this.setState({ chosenLocation: ev.target.value })
  }

  makeLocationOptions = () => {
    const locs = this.props.employerLocations
    const locList = locs.map(l => [l.id, l.name || ''])
    return sortBy(locList, [1, 0]).map(l => {
      return (
        <option value={l[0]} key={l[0]}>
          {l[0]}: {l[1]}
        </option>
      )
    })
  }

  render() {
    return (
      <Modal
        header="Add new location to employer account"
        isShown={this.props.item.isShown}
        onClose={this.closeModal}
        onConfirm={this.onConfirmHandler}
        confirmationText="Add location"
      >
        <div className="employer-email-invoices-modal">
          <FormGroup className="form-item">
            <ControlLabel>Location</ControlLabel>
            <FormControl
              componentClass="select"
              name="location"
              value={this.state.chosenLocation}
              onChange={e => this.setLocation(e)}
              disabled={false}
            >
              <option disabled value="">
                Choose a location
              </option>
              {this.makeLocationOptions()}
            </FormControl>
          </FormGroup>
        </div>
      </Modal>
    )
  }
}

export default connect(state => ({
  item: getModal$(state.modals)('employerAccountsNewLocationModal'),
}))(EmployerAccountsNewLocationModal)
