import React, { useCallback, useEffect } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { flowRight } from 'lodash-es'
import { entityList$, pagination$ } from 'syft-acp-core/reducers/generators/entities'
import * as helpers from 'syft-acp-core/entities/ShiftList/helpers/tableHelpers'
import { formatFullName } from 'syft-acp-util/formatting'

import Acp from 'syft-acp-uikit'
import { fetchFulfilmentBookings } from 'syft-acp-core/store/listings/actions'
import {
  ShiftFulfilmentBookingsListOwnProps as OwnProps,
  ShiftFulfilmentBookingsListProps as Props,
  LimitedBookingsShownNoteProps as NoteProps,
} from './ShiftFulfilmentBookingsList.types'
import { ListingShiftBooking } from 'syft-acp-core/store/listing-shifts/types'

import S from './ShiftFulfilmentBookingsList.module.scss'

const MAX_BOOKED_WORKERS = 250

const perPageFromWorkersBooked = (bookedWorkers: number): number =>
  bookedWorkers < MAX_BOOKED_WORKERS ? bookedWorkers : MAX_BOOKED_WORKERS

export const storeConnector = connect(
  (state, props: OwnProps) => {
    return {
      data: entityList$(state.listingShiftBookings, { shiftID: props.shiftID }),
      meta: pagination$(state.listingShiftBookings, { shiftID: props.shiftID }),
      roles: state.roles.entityMap,
      isLoadingData: state.listingShiftBookings.isLoadingData,
    }
  },
  dispatch => ({
    actions: {
      ...bindActionCreators({ fetchFulfilmentBookings }, dispatch),
    },
  }),
)

export const ShiftFulfilmentBookingsWorkerCell = ({ row }: { row: ListingShiftBooking }) => {
  const workerPlatform = row?.worker?.worker_platform
  const agencyName = row?.worker?.agency?.name
  return (
    <>
      {agencyName ? (
        formatFullName(row?.worker)
      ) : (
        <a target="_blank" rel="noopener noreferrer" href={`/entity/workers/view/${row?.worker?.id}`}>
          {formatFullName(row?.worker)}
        </a>
      )}

      {(workerPlatform === 'own' || (workerPlatform === 'agency' && agencyName)) && (
        <span className={S['platform-name']}>{agencyName || 'Flex+'}</span>
      )}
    </>
  )
}

const LimitedBookingsShownNote = ({ numBookings }: NoteProps) => {
  if (numBookings <= MAX_BOOKED_WORKERS) {
    return null
  }

  return (
    <p role="note">
      <strong>Note:</strong> a maximum of 250 workers are displayed per shift, please access the listing page
      for more details.
    </p>
  )
}

const ShiftFulfilmentBookingsList = ({
  actions,
  data,
  isLoadingData,
  roles,
  shiftID,
  meta,
  jobId,
  workersBooked,
  shiftBookings,
  startTime,
  endTime,
  area,
  roleId,
  payRate,
  venue,
}: Props) => {
  const fetch = useCallback(
    () =>
      actions.fetchFulfilmentBookings({
        options: {
          shiftID,
          without_empty_slots: true,
          all_bookings: true,
          per_page: perPageFromWorkersBooked(workersBooked),
        },
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [actions, shiftID],
  )

  useEffect(() => {
    fetch()
  }, [fetch])
  const modifiedData = data?.map(workerData => {
    const shiftBooking = shiftBookings?.find((booking: any) => booking.worker.id === workerData.worker.id)
    return { ...workerData, shiftBooking }
  })
  const allData = {
    data: modifiedData,
    meta,
  }
  const shiftAttributes = { area, venue, pay_rate: payRate, roleId, startTime, endTime }
  return (
    <div className={S['acp-fulfilment-bookings-list']}>
      <LimitedBookingsShownNote numBookings={workersBooked} />
      <Acp.EntityList
        hasPopoverFilters
        data={allData}
        isLoading={isLoadingData}
        hasResultCount
        idKeyValue="id"
      >
        <Acp.Table>
          <Acp.Col.Text
            value={(row: ListingShiftBooking) => {
              return <ShiftFulfilmentBookingsWorkerCell row={row} />
            }}
            header="Workers booked"
            isMinimal
          />
          <Acp.Col.Text
            value={(rowData: ListingShiftBooking) =>
              // @ts-ignore
              helpers.getConfirmed({ job_id: jobId, id: shiftID }, rowData)
            }
            header="Confirmed"
            align="center"
            isMinimal
          />
          <Acp.Col.Text
            // @ts-ignore
            value={(rowData: ListingShiftBooking) => helpers.getTelephoneNumber(rowData, rowData)}
            header="Tel. number"
            isMinimal
          />
          <Acp.Col.Text
            value="worker.completed_shifts_count"
            header="C"
            headerTooltip="Number of previously completed shifts"
            align="center"
            headerAbbr
            isMinimal
            isNumeric
          />
          <Acp.Col.Text
            value="worker.late_cancellations_count"
            header="✕"
            headerTooltip="Worker cancellations ≤ 24 hours"
            align="center"
            isMinimal
            isNumeric
          />
          <Acp.Col.Text value={helpers.getWorkerChanges(shiftAttributes, roles)} header="Updates" isMinimal />
          <Acp.Col.DateTime value="clock_in.time" header="Clock in" isMinimal />
          <Acp.Col.DateTime value="clock_out.time" header="Clock out" isMinimal />
        </Acp.Table>
      </Acp.EntityList>
    </div>
  )
}

export default flowRight(storeConnector)(ShiftFulfilmentBookingsList)
