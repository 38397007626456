import { get } from 'lodash-es'
import moment from 'moment'
import PropTypes from 'prop-types'
import React, { PureComponent } from 'react'

import EntityListInputWrapper from 'syft-acp-core/components/EntityList/EntityListInputWrapper'
import { addTimezone, formatISO8601, to24hTimeFormat } from 'syft-acp-util/formatting'
import { moveToReferenceTime } from 'syft-acp-util/time'
import TimeInput from './TimeInput'

import './ListingTimeInput.css'

export default class ListingTimeInput extends PureComponent {
  static propTypes = {
    isClockIn: PropTypes.bool,
    isClockOut: PropTypes.bool,
    attr: PropTypes.string.isRequired,
    shiftBooking: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    enforceDeadline: PropTypes.bool,
    timeZone: PropTypes.string,
  }

  static defaultProps = {
    disabled: false,
    isClockIn: false,
    isClockOut: false,
    enforceDeadline: false,
    timeZone: '',
  }

  // It's not possible to edit the clock in and clock out times after the deadline passes.
  get isPostDeadline() {
    const { shiftBooking, isClockIn, isClockOut } = this.props
    return (isClockIn || isClockOut) && shiftBooking.deadline_passed
  }

  handleOnChange = changedValue => {
    const { attr, shiftBooking, isClockIn, onChange, timeZone } = this.props
    // The start time of the shift is the reference time
    // (regardless of whether this is the check in or the check out time).
    const referenceTimeStart = moment(get(shiftBooking, 'startTime'))
    const referenceTimeClockIn = moment(get(shiftBooking, 'clock_in_time'))
    const referenceTime =
      referenceTimeStart < referenceTimeClockIn ? referenceTimeStart : referenceTimeClockIn
    const datetime =
      get(shiftBooking, attr) && get(shiftBooking, attr) !== 'Invalid date'
        ? get(shiftBooking, attr)
        : referenceTimeStart

    // Assumes time is split by colon; will break on some languages like Thai.
    // Just in case we start translating and find that it breaks mysteriously.
    const [hour, minute] = changedValue.split(':')
    const { id, shiftID } = shiftBooking

    // Else, calculate a timestamp.
    let clockedTime = addTimezone(moment(datetime), timeZone).set({ hour, minute, second: 0 })
    if (referenceTime.isValid()) {
      clockedTime = moveToReferenceTime(clockedTime, referenceTime, isClockIn)
    }
    const val = formatISO8601(clockedTime)
    onChange({ shiftID, id, attr, val })
  }

  render() {
    const { disabled, enforceDeadline, shiftBooking, attr, timeZone } = this.props
    const attrValue = get(shiftBooking, attr)
    const value = attrValue ? to24hTimeFormat(attrValue, timeZone) : null
    return (
      <EntityListInputWrapper className="listingTimeInput">
        <TimeInput
          onChange={this.handleOnChange}
          value={value}
          disabled={disabled || (this.isPostDeadline && enforceDeadline)}
        />
      </EntityListInputWrapper>
    )
  }
}
