// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import { takeEvery } from 'redux-saga/effects'

import * as types from 'syft-acp-core/actions/action-types'
import { afterRemoveUnusedData } from 'syft-acp-core/actions/app'
import { store } from 'syft-acp-core/store'

export default function* root() {
  yield takeEvery(types.APP_REMOVE_UNUSED_DATA, () => {
    store.dispatch(afterRemoveUnusedData())
  })
  yield takeEvery(types.AUTH_DELETE, () => {
    // Since we have logged out, we're going to delete the entire local storage.
    // That way no sensitive information will be available by accident.
    localStorage.clear()
  })
  yield takeEvery(types.APP_RESET_AND_RELOAD, () => {
    // Delete the entire local storage and immediately reload.
    // Occasionally people get the app in an inconsistent state. This might help in that case.
    localStorage.clear()
    window.location.reload(true)
  })
}
