// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import entityReducer from './generators/entityReducer'
import * as types from '../actions/action-types'
import * as entityTypes from './generators/entities'

export default entityReducer(
  'strikes',
  {
    [entityTypes.ENTITIES_FETCH_BEGIN]: types.STRIKES_FETCH_BEGIN,
    [entityTypes.ENTITIES_FETCH_SUCCEEDED]: types.STRIKES_FETCH_SUCCEEDED,
    [entityTypes.ENTITIES_FETCH_FAILED]: types.STRIKES_FETCH_FAILED,
  },
  {
    // When we reach over 250 cached items in the store,
    // reduce the number of items to the 125 latest.
    itemLimit: [250, 125],
  }
)
