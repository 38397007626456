export const endJobAssignmentReasons = [
  {
    id: 1,
    value: 'completed_assignment',
    label: 'Completed Assignment',
  },
  {
    id: 2,
    value: 'reduced_temp_worker_demand',
    label: 'Reduced Temp Worker Demand',
  },
  {
    id: 3,
    value: 'hired_by_client',
    label: 'Hired by Client',
  },
  {
    id: 4,
    value: 'worker_never_showed',
    label: 'Worker Never Showed',
  },
  {
    id: 5,
    value: 'attendance_policy',
    label: 'Attendance Policy',
  },
  {
    id: 6,
    value: 'compliance_policy',
    label: 'Compliance Policy',
  },
  {
    id: 7,
    value: 'poor_performance',
    label: 'Poor Performance',
  },
  {
    id: 8,
    value: 'employee_discontentment',
    label: 'Employee Discontentment',
  },
  {
    id: 9,
    value: 'employee_quit_no_reason_given',
    label: 'Employee Quit - No Reason Given',
  },
  {
    id: 10,
    value: 'walked_off_during_shift',
    label: 'Walked-off During Shift',
  },
  {
    id: 11,
    value: 'employee_moved',
    label: 'Employee Moved',
  },
  {
    id: 12,
    value: 'employee_found_another_job',
    label: 'Employee Found Another Job',
  },
  {
    id: 13,
    value: 'return_to_school',
    label: 'Return to School',
  },
  {
    id: 14,
    value: 'transportation_commute',
    label: 'Transportation/Commute',
  },
  {
    id: 15,
    value: 'incompatible_hours_work_schedule',
    label: 'Incompatible Hours/Work Schedule',
  },
  {
    id: 16,
    value: 'incompatible_pay',
    label: 'Incompatible Pay',
  },
  {
    id: 17,
    value: 'other_involuntary',
    label: 'Other - Involuntary',
  },
  {
    id: 18,
    value: 'other_voluntary',
    label: 'Other - Voluntary',
  },
]

export const eligibleReturnToWork = [
  {
    value: 'employer',
    label: 'Block by Client',
  },
  {
    value: 'venue',
    label: 'Block by Venue',
  },
]
