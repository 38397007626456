// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import React, { useCallback, useEffect } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { compact, flowRight, values, isEqual, omit } from 'lodash-es'
import { Link } from 'react-router-dom'
import deepEqual from 'react-fast-compare'
import { useTrackingTrigger } from '@indeed/flex-tracking-context'
import { useFlexFlagIsOn } from '@indeed/flex-feature-flags'

import Acp from 'syft-acp-uikit'
import { createEntityListConnector } from 'syft-acp-util/entityList'
import CountrySpecific from 'syft-acp-core/components/CountrySpecific'
import { SUPPORTED_COUNTRY_CODES } from 'syft-acp-core/lib/i18n'
import {
  FilterEnum,
  FilterBoolCheckbox,
  FilterRolesSkills,
  FilterOfferTypes,
  FilterClientDeliveryTeam,
  FilterAutocompleteWorkers,
  FilterAutocompleteEmployers,
  FilterSelectIndustries,
  FilterSelectCities,
  FilterAutocompleteManagers,
  FilterTiers,
  FilterAutocompleteVenuesCities,
  FilterDateRange,
  FilterShiftsViewFrom,
} from 'syft-acp-util/components/FilterForm'
import * as shiftsActions from 'syft-acp-core/store/shifts/actions'
import * as shiftBookingsActions from 'syft-acp-core/store/shift-bookings/actions'
import { showModal } from 'syft-acp-core/store/modals/actions'
import { makePropertyCheck } from 'syft-acp-core/store/filters/helpers'
import { ShiftEntity } from 'syft-acp-core/store/shifts/types'
import { RowCallbackData } from 'syft-acp-uikit/components/AcpTable/AcpTable.types'
import GeolocationTooltip from 'syft-acp-util/components/GeolocationTooltip'
import usePrevValue from 'syft-acp-util/hooks/usePrevValue'
import { getVenueCity } from 'syft-acp-util/cities'
import iconFactory from 'syft-acp-atoms/Icons'
import { Store } from 'syft-acp-core/store'
import QueryTable from 'syft-acp-core/components/QueryTable'
import ShiftFulfilmentModal from 'syft-acp-core/components/Modal/ShiftFulfilmentModal'
import { trackingEvents } from './ShiftList.tracking'

import * as helpers from './helpers/tableHelpers'
import { RowValue } from './helpers/helpers.types'
import { ShiftListProps as Props, ShiftEntityConnectedProps as EntityConnectorProps } from './ShiftList.types'
import S from './ShiftList.module.scss'

export const HIGHLIGHT_BOOKING_NOT_CONTACTED = ''
export const HIGHLIGHT_BOOKING_CLOCKED_IN = 'gray'
export const HIGHLIGHT_BOOKING_NO_ANSWER = 'yellow'
export const HIGHLIGHT_BOOKING_LATE = 'red'
export const HIGHLIGHT_BOOKING_CONFIRMED = 'green'

const OrganizationIcon = iconFactory('organization', 'People')
const UnorderedListIcon = iconFactory('list-unordered', 'Bookings')

const SHIFT_BOOKINGS_CHECKBOX_KEY = 'shift-bookings'

export const entityConnector = createEntityListConnector<ShiftEntity>({
  entityActions: { fetchEntities: shiftsActions.fetchShifts },
  entityStore: 'shifts',
})

const defaultFilterValues = {
  exclude_bookings: 'true',
  disable_pagination_counters: 'true',
}

export const storeConnector = connect(
  (state: Store) => ({
    roles: state.roles.entityMap,
    selectedShiftBookings: state.checkboxes.items[SHIFT_BOOKINGS_CHECKBOX_KEY] || [],
    isSavingData: state.shifts.isSavingData,
  }),
  (dispatch, props: EntityConnectorProps) => ({
    actions: {
      ...props.actions,
      ...bindActionCreators({ ...shiftBookingsActions, showModal }, dispatch),
    },
  }),
)

export const checkCategoryIsEqual = (oldState: Record<string, any>, newState: Record<string, any>) =>
  makePropertyCheck(['category'], true)(values(oldState), values(newState))

export const checkShiftBookingsAreEqual = (oldState: Record<string, any>, newState: Record<string, any>) =>
  makePropertyCheck(['shift_bookings'], true, true)(values(oldState), values(newState))

export const checkForRerender = (oldEntityList: Record<string, any>, newEntityList: Record<string, any>) =>
  checkShiftBookingsAreEqual(oldEntityList, newEntityList) &&
  checkCategoryIsEqual(oldEntityList, newEntityList)

const getListOfBookingIds = (bookingsIds: Record<string, any>[]) =>
  compact(bookingsIds.map(i => i.shiftBookingID))

const ShiftList = ({
  allShifts,
  roles,
  actions,
  query,
  data,
  isLoadingData,
  selectedShiftBookings,
  isSavingData,
}: Props) => {
  const prevQuery = usePrevValue(query)
  const shiftRoleChanges = useFlexFlagIsOn('pte_20653_shift_role_changes')
  const isClientAccountManagerVenueEnabled = useFlexFlagIsOn('client_display_account_managers_venue_fe_v1')
  const triggerEvent = useTrackingTrigger()

  const modalName = 'shiftFulfilmentModal'

  useEffect(() => {
    if (isLoadingData === false && !!data?.meta?.total) {
      if (allShifts) {
        triggerEvent(trackingEvents.ALL_SHIFTS.PAGE.LOADED, { result_count: data.meta.total })
      } else {
        triggerEvent(trackingEvents.FLEX_SHIFTS.PAGE.LOADED, { result_count: data.meta.total })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoadingData])

  const fetch = useCallback(
    (q: Record<string, any>, toCSV = false) =>
      actions.fetchEntities && actions.fetchEntities({ options: q }, {}, toCSV),
    [actions],
  )

  useEffect(() => {
    if (Object.keys(query).length && prevQuery && !deepEqual(prevQuery, query)) fetch(query)
  }, [fetch, prevQuery, query])

  const onFilterChange = (key: string, value?: string | string[]): void => {
    if (value) {
      if (allShifts) {
        triggerEvent(trackingEvents.ALL_SHIFTS.FILTERS.CHANGED, { filter: key, value })
      } else {
        triggerEvent(trackingEvents.FLEX_SHIFTS.FILTERS.CHANGED, { filter: key, value })
      }
    }
  }

  const actionDownloadCSV = useCallback(() => {
    const filteredQuery = omit(query, ['disable_pagination_counters', 'exclude_bookings', 'counters_enabled'])
    const hasFilters = !isEqual(filteredQuery, {})
    actions.showConfirmModal({
      modalConfirm: 'Download',
      question: (
        <div>
          <p>
            Download a CSV file with these search results?
            {hasFilters ? ' The following search filters will be applied:' : ''}
          </p>
          {hasFilters && <QueryTable queryData={filteredQuery} />}
        </div>
      ),
      onConfirm: () => fetch(query, true),
    })
  }, [fetch, actions, query])

  const getRole = (enableShiftRoleChanges: boolean) => (e: RowValue) =>
    helpers.getNameWithOverride(
      e,
      'role',
      enableShiftRoleChanges,
      helpers.formatRole(roles[e.role_id], e.category),
    )

  const getRowCallback = useCallback(
    (e: RowCallbackData<ShiftEntity>) => {
      if (allShifts) {
        triggerEvent(trackingEvents.ALL_SHIFTS.SHIFT.SELECTED, {
          shift_id: e.rowData.id,
        })
      } else {
        triggerEvent(trackingEvents.FLEX_SHIFTS.SHIFT.SELECTED, {
          shift_id: e.rowData.id,
        })
      }
      if (e.columnN < 12) {
        actions.showModal(modalName, {}, null, e)
      }
    },
    [allShifts, triggerEvent, actions],
  )

  const markWorkersDropdownOptions = [
    {
      markAs: 'not contacted',
      action: () =>
        actions.highlightShiftBooking(
          getListOfBookingIds(selectedShiftBookings),
          HIGHLIGHT_BOOKING_NOT_CONTACTED,
        ),
    },
    {
      markAs: 'clocked in',
      action: () =>
        actions.highlightShiftBooking(
          getListOfBookingIds(selectedShiftBookings),
          HIGHLIGHT_BOOKING_CLOCKED_IN,
        ),
      color: 'gray',
    },
    {
      markAs: 'no answer',
      action: () =>
        actions.highlightShiftBooking(
          getListOfBookingIds(selectedShiftBookings),
          HIGHLIGHT_BOOKING_NO_ANSWER,
        ),
      color: 'yellow',
    },
    {
      markAs: 'late/missing',
      action: () =>
        actions.highlightShiftBooking(getListOfBookingIds(selectedShiftBookings), HIGHLIGHT_BOOKING_LATE),
      color: 'red',
    },
    {
      markAs: 'confirmed',
      action: () =>
        actions.highlightShiftBooking(
          getListOfBookingIds(selectedShiftBookings),
          HIGHLIGHT_BOOKING_NOT_CONTACTED,
        ),
      color: 'green',
    },
  ]

  const handleWorkersDrowpdownOption = (option: { markAs: string; action: () => void }) => () => {
    if (allShifts) {
      triggerEvent(trackingEvents.ALL_SHIFTS.MARK_WORKERS_DROP_DOWN.CHANGED, { mark_as: option.markAs })
    } else {
      triggerEvent(trackingEvents.FLEX_SHIFTS.MARK_WORKERS_DROP_DOWN.CHANGED, { mark_as: option.markAs })
    }
    return option.action()
  }

  return (
    <>
      <CountrySpecific countries={[SUPPORTED_COUNTRY_CODES.US]}>
        {(isUS: boolean) => (
          <Acp.EntityList data={data} isLoading={isLoadingData} idKeyValue="id" hasResultCount hasPagination>
            <Acp.Actions narrowContainer>
              <Acp.ButtonDropdown
                title="Mark workers"
                id="dropdown_actions"
                pullRight
                disabled={isLoadingData || isSavingData || !selectedShiftBookings.length}
              >
                {markWorkersDropdownOptions.map((option, i) => (
                  <Acp.Option key={i} color={option.color} onClick={handleWorkersDrowpdownOption(option)}>
                    Mark as {option.markAs}
                  </Acp.Option>
                ))}
              </Acp.ButtonDropdown>
              <Acp.Button
                onClick={() => actionDownloadCSV()}
                kind="primary"
                disabled={isLoadingData || isSavingData}
              >
                Download Check In Sheet
              </Acp.Button>
              <Acp.FilterGroup title="Shift">
                <Acp.Filter.Text
                  onChange={onFilterChange}
                  name="shift_id"
                  placeholder="Shift ID"
                  queryType="id"
                  small
                />
              </Acp.FilterGroup>
              <Acp.FilterGroup title="Worker">
                <FilterAutocompleteWorkers
                  onChange={onFilterChange}
                  name="worker_id"
                  placeholder="Worker name"
                  queryType="id"
                />
              </Acp.FilterGroup>
              <Acp.FilterGroup title="Employer">
                <FilterAutocompleteEmployers
                  onChange={onFilterChange}
                  name="employer_id"
                  placeholder="Employer name"
                  queryType="id"
                  small
                />
              </Acp.FilterGroup>
              <Acp.FilterGroup title="Venue">
                <Acp.Filter.Text onChange={onFilterChange} name="venue_name" placeholder="Venue name" small />
              </Acp.FilterGroup>
              <Acp.FilterGroup title="Workers required">
                <Acp.Filter.Text
                  onChange={onFilterChange}
                  name="workers_required_gte"
                  placeholder="≥"
                  small
                  type="number"
                />
                <Acp.Filter.Text
                  onChange={onFilterChange}
                  name="workers_required_lte"
                  placeholder="≤"
                  small
                  type="number"
                />
              </Acp.FilterGroup>
              {isUS && (
                <Acp.FilterGroup title="Venue city">
                  <FilterAutocompleteVenuesCities onChange={onFilterChange} name="venue_city" />
                </Acp.FilterGroup>
              )}
              {isUS && (
                <Acp.FilterGroup title="Region">
                  <FilterSelectCities onChange={onFilterChange} name="city_id" allowAny />
                </Acp.FilterGroup>
              )}
              {isUS || (
                <Acp.FilterGroup title="City">
                  <FilterSelectCities onChange={onFilterChange} name="city_id" allowAny />
                </Acp.FilterGroup>
              )}
              <Acp.FilterGroup title="Date">
                <FilterDateRange
                  onChange={onFilterChange}
                  showShortTime
                  nameFrom="start_time_gte"
                  nameTo="start_time_lte"
                />
              </Acp.FilterGroup>
              <Acp.FilterGroup title="Creation date">
                <FilterDateRange
                  onChange={onFilterChange}
                  showShortTime
                  nameFrom="created_at_gte"
                  nameTo="created_at_lte"
                />
              </Acp.FilterGroup>
              <Acp.FilterGroup title="Account manager">
                <FilterAutocompleteManagers
                  name="account_manager_id"
                  queryKey="account_manager_id"
                  onChange={onFilterChange}
                  {...(!isClientAccountManagerVenueEnabled && {
                    parameters: { managerRoles: ['account_manager'] },
                  })}
                  allowAny
                  small
                />
              </Acp.FilterGroup>
              <Acp.FilterGroup title="Sales manager">
                <FilterAutocompleteManagers
                  name="sales_manager_id"
                  queryKey="sales_manager_id"
                  onChange={onFilterChange}
                  parameters={{ managerRoles: ['sales_manager'] }}
                  allowAny
                  small
                />
              </Acp.FilterGroup>
              <Acp.FilterGroup title="Priority">
                <FilterTiers onChange={onFilterChange} name="tier" allowAny tiny />
              </Acp.FilterGroup>
              <Acp.FilterGroup title="Industry">
                <FilterSelectIndustries
                  onChange={onFilterChange}
                  name="industry_id"
                  queryKey="industry_id"
                  options={{ idKey: 'id' }}
                  allowAny
                  tiny
                />
              </Acp.FilterGroup>
              <Acp.FilterGroup title="View from">
                <FilterShiftsViewFrom onChange={onFilterChange} />
              </Acp.FilterGroup>
              {/*
                Commented out as per CMS2-871.
                Platform is always 'syft'; see 'syft-acp-core/api/resources/admin/shifts.js'.
                See also 'syft-acp-core/entities/ListingList/index.js'.
              */}
              {allShifts && (
                <Acp.FilterGroup title="Platform">
                  <FilterEnum
                    name="platform"
                    onChange={onFilterChange}
                    options={[
                      [{ value: '', label: 'Both', defaultValue: true }],
                      [
                        { value: 'syft', label: 'Flex' },
                        { value: 'syft_force', label: 'Flex+' },
                      ],
                    ]}
                  />
                </Acp.FilterGroup>
              )}
              <Acp.FilterGroup title="Statistics">
                {/* Display all counters, e.g. '37 workers, 37 bookings, 1,406 shifts, 1,369 vacancies'. */}
                <FilterBoolCheckbox
                  onChange={onFilterChange}
                  name="counters_enabled"
                  value="true"
                  label="Enable counters"
                />
              </Acp.FilterGroup>
              <Acp.FilterGroup compact={true}>
                <FilterOfferTypes onChange={onFilterChange} allowAny small />
              </Acp.FilterGroup>
              <Acp.FilterGroup title="Client delivery team">
                <FilterClientDeliveryTeam
                  onChange={onFilterChange}
                  name="client_delivery_team_id"
                  allowAny
                  small
                />
              </Acp.FilterGroup>
              <Acp.FilterGroup isBreak />
              <Acp.FilterGroup title="Longitude">
                <Acp.Filter.Number onChange={onFilterChange} name="longitude" placeholder="0" small />
              </Acp.FilterGroup>
              <Acp.FilterGroup title="Latitude">
                <Acp.Filter.Number onChange={onFilterChange} name="latitude" placeholder="0" small />
              </Acp.FilterGroup>
              <Acp.FilterGroup title="Radius">
                <Acp.Filter.Number onChange={onFilterChange} name="radius" placeholder="10" small />
              </Acp.FilterGroup>
              <Acp.FilterGroup title="Radius unit">
                <FilterEnum
                  onChange={onFilterChange}
                  name="radius_unit"
                  options={[
                    [
                      { value: 'km', label: 'Kilometres' },
                      { value: 'mi', label: 'Miles' },
                    ],
                  ]}
                />
              </Acp.FilterGroup>
              <Acp.FilterGroup>
                <GeolocationTooltip />
              </Acp.FilterGroup>
              <Acp.FilterGroup title="Other filters">
                <FilterBoolCheckbox
                  onChange={onFilterChange}
                  name="confirmed"
                  value="false"
                  label="Unconfirmed only"
                />
                <FilterBoolCheckbox
                  onChange={onFilterChange}
                  name="filled"
                  value="false"
                  label="Unfilled only"
                />
                {isUS && (
                  <FilterBoolCheckbox
                    onChange={onFilterChange}
                    name="no_admin_input"
                    value="false"
                    label="Admin input"
                  />
                )}
                <FilterBoolCheckbox
                  onChange={onFilterChange}
                  name="highest_unfilled_auto_rate"
                  value="true"
                  label="Unfilled top rate"
                />
                <FilterBoolCheckbox
                  onChange={onFilterChange}
                  name="exclude_bookings"
                  value="true"
                  defaultValue={defaultFilterValues.exclude_bookings}
                  label="Exclude bookings"
                />
                <FilterBoolCheckbox
                  name="venue_first_shifts"
                  value="true"
                  label="First shifts at venue"
                  onChange={onFilterChange}
                />
                <FilterBoolCheckbox
                  onChange={onFilterChange}
                  name="only_syft_employers"
                  value="true"
                  label="Flex employers only"
                />
              </Acp.FilterGroup>
              <Acp.FilterGroup title="Roles and skills">
                <FilterRolesSkills
                  onChange={onFilterChange}
                  role_key="role_id"
                  skill_key="skill_id"
                  independentSkills
                  allowAny
                  multiRole
                />
              </Acp.FilterGroup>
              <Acp.FilterGroup title="Status">
                <FilterEnum
                  name="highlight"
                  onChange={onFilterChange}
                  options={[
                    [{ value: '', label: 'All' }],
                    [
                      { value: 'white', label: 'Not contacted', bright: true },
                      { value: 'gray', label: 'Clocked in', type: 'neutral', bright: true },
                      { value: 'yellow', label: 'No answer', type: 'warning', bright: true },
                      { value: 'red', label: 'Late/missing', type: 'danger', bright: true },
                      { value: 'green', label: 'Confirmed', type: 'success', bright: true },
                    ],
                  ]}
                />
              </Acp.FilterGroup>
              <Acp.FilterGroup title="Pagination">
                <FilterBoolCheckbox
                  onChange={onFilterChange}
                  name="disable_pagination_counters"
                  value="true"
                  defaultValue={defaultFilterValues.disable_pagination_counters}
                  label="Disable pagination counters"
                />
              </Acp.FilterGroup>
            </Acp.Actions>
            <Acp.Table rowCallback={getRowCallback} rowColor={helpers.getRowColor} rowLink={null}>
              <Acp.Col.Text
                value={(rowData: ShiftEntity) => (
                  <Link to={`/listings/view/${rowData.listing_id}/job/${rowData.job_id}/shift/${rowData.id}`}>
                    {rowData.id}
                  </Link>
                )}
                header="Shift ID"
                colNoCallback
                isMinimal
              />
              <Acp.Col.Text value={helpers.getBrandCompany} header="Brand/company" isMinimal />
              <Acp.Col.Text value={helpers.getVenue(shiftRoleChanges)} header="Venue" isMinimal />
              <Acp.Col.Text value={helpers.getArea(shiftRoleChanges)} header="Area" isMinimal />
              <Acp.Col.DateTime
                value={['start_time', 'end_time']}
                header="Start/end"
                options={{ timeZone: 'venue.timezone' }}
                isMinimal
                withOverrides
              />
              <Acp.Col.Text value={getVenueCity} header="City" isMinimal />
              <Acp.Col.Text value={getRole(shiftRoleChanges)} header="Role" isMinimal />
              <Acp.Col.Text
                value={helpers.getWorkersBookedRequired}
                header={<OrganizationIcon iconSize={15} className={S['shift-list__header-icon']} />}
                headerTooltip="Workers booked/required"
                align="center"
                isMinimal
                isNumeric
              />
              <Acp.Col.Text
                value={helpers.getWorkersNeeded}
                header="N"
                headerTooltip="Workers needed for full capacity"
                align="center"
                isMinimal
                isNumeric
                headerAbbr
              />
              <Acp.Col.Text
                value={helpers.getWorkersApplied}
                header="A"
                headerTooltip="Workers applied"
                align="center"
                isMinimal
                isNumeric
                headerAbbr
              />
              <Acp.Col.Text
                value={helpers.getWorkersOffered}
                header="O"
                headerTooltip="Workers offered"
                align="center"
                isMinimal
                isNumeric
                headerAbbr
              />
              <Acp.Col.Text
                value={helpers.getOfferTypeValue}
                valueTooltip={helpers.getOfferTypeValueTooltip}
                align="center"
                header="OT"
                headerTooltip="Offer type (hover over letters for more information)"
                isMinimal
              />
              <Acp.Col.Text
                value={helpers.getShowListOfBookingsValue}
                header={<UnorderedListIcon iconSize={15} className={S['shift-list__header-icon']} />}
                headerTooltip="Show list of bookings"
                align="center"
                isMinimal
                colNoLink
              />
              <Acp.SubRows
                value="shift_bookings"
                rowColor={helpers.getSubRowColor}
                rowLink={helpers.getSubRowLink}
              >
                <Acp.Col.Text value={helpers.getWorkerBooked(roles)} header="Worker booked" isMinimal />
                <Acp.Col.Text
                  value={helpers.getConfirmed}
                  header={<div aria-label="Confirmed">{helpers.getConfirmedHeader(data.data)}</div>}
                  headerTooltip="Confirmed"
                  align="center"
                  isMinimal
                  colNoLink
                />
                <Acp.Col.Text value={helpers.getTelephoneNumber} header="Tel. number" isMinimal />
                <Acp.Col.Text
                  value={helpers.getPreviouslyCompletedShifts}
                  header="C"
                  headerTooltip="Number of previously completed shifts"
                  align="center"
                  headerAbbr
                  isMinimal
                  isNumeric
                />
                <Acp.Col.Text
                  value={helpers.getWorkerCancellations}
                  header="✕"
                  headerTooltip="Worker cancellations ≤ 24 hours"
                  align="center"
                  isMinimal
                  isNumeric
                />
                <Acp.Col.Text value={helpers.getClockedIn} header="Clocked in" isMinimal />
                <Acp.Col.Text value={helpers.getClockedOut} header="Clocked out" isMinimal />
                <Acp.Col.Selector
                  value={helpers.getShiftBookingSelectValue}
                  headerValue={helpers.getShiftBookingSelectHeaderValue}
                  headerTooltip="Select all shift bookings on this page"
                  options={{ scope: SHIFT_BOOKINGS_CHECKBOX_KEY, valueKeys: ['shiftBookingID', 'workerID'] }}
                  align="center"
                  isMinimal
                  colNoLink
                />
              </Acp.SubRows>
            </Acp.Table>
          </Acp.EntityList>
        )}
      </CountrySpecific>
      <ShiftFulfilmentModal modalName={modalName} />
    </>
  )
}

export default flowRight(entityConnector, storeConnector)(ShiftList)
