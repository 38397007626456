import React from 'react'
import PropTypes from 'prop-types'
import { get } from 'lodash-es'

import { showModal } from 'syft-acp-core/lib/modals'
import DataBoolean from 'syft-acp-core/components/EditableTable/DataBoolean'
import EntityListInputWrapper from 'syft-acp-core/components/EntityList/EntityListInputWrapper'

export const ListingNoShowCheckbox = ({ updateCheckbox, shiftBooking, disabled = false }) => {
  const checked = get(shiftBooking, 'no_show')
  const onChange = async val => {
    const { id, shiftID } = shiftBooking
    updateCheckbox({ shiftID, id, attr: 'no_show', val })

    if (!val) return
    const noShowReasonData = await showModal('addNoShowReasonModal', { shiftBooking })
    if (!get(noShowReasonData, 'noShowReasonID', false)) {
      updateCheckbox({ shiftID, id, attr: 'no_show', val: false })
    }
    const reason = Number(noShowReasonData.noShowReasonID)
    const { comments } = noShowReasonData
    updateCheckbox({ shiftID, id, attr: 'no_show_reason', val: { no_show_reason_id: reason, comments } })
  }

  return (
    <EntityListInputWrapper>
      <DataBoolean onChange={onChange} checked={checked} editable={!disabled} />
    </EntityListInputWrapper>
  )
}

ListingNoShowCheckbox.propTypes = {
  updateCheckbox: PropTypes.func.isRequired,
  shiftBooking: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
}

ListingNoShowCheckbox.defaultProps = {
  disabled: false,
}

export default ListingNoShowCheckbox
