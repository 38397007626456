// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import { takeLatest } from 'redux-saga/effects'
import * as api from 'syft-acp-core/api'
import { notificationCall } from 'syft-acp-core/sagas/calls'
import * as types from 'syft-acp-core/actions/action-types'
import { takeLatestApiCall } from './helpers'

export default function* root() {
  yield takeLatestApiCall('UPLOAD_ACCESS_REQUEST_DATA_FILE', api.uploadAccessRequestDataFile)
  yield takeLatest(
    [types.UPLOAD_ACCESS_REQUEST_DATA_FILE_SUCCEEDED, types.UPLOAD_ACCESS_REQUEST_DATA_FILE_FAILED],
    notificationCall
  )
}
