// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import { createReducer } from '@reduxjs/toolkit'
import authReducer from './auth'

const initialState = {}

const reducer = createReducer(initialState, builder =>
  builder.addDefaultCase((state = initialState, action) => {
    const authState = authReducer(state, action)

    return authState.oauthData
  })
)

export default reducer
