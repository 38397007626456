// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import { put, takeLatest } from 'redux-saga/effects'
import { LOCATION_CHANGE } from 'connected-react-router'
import * as types from 'syft-acp-core/actions/action-types'

/**
 * Clear selected checkboxes after location change event.
 */
function* clearItemsCall() {
  yield put({ type: types.CHECKBOXES_CLEAR_ITEMS })
}

export default function* root() {
  yield takeLatest(LOCATION_CHANGE, clearItemsCall)
}
