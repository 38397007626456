export const enrichReplaceBookingParams = (acc, attr, value, changedEntity) => {
  const {
    shiftID,
    jobID,
    worker: { originalWorkerId },
  } = changedEntity
  const attrs = {
    job_id: jobID,
    current_worker_id: originalWorkerId,
    new_worker_id: Number(value),
    shift_ids: [shiftID],
  }

  acc.push(attrs)
}
