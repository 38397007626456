import React from 'react'
import classnames from 'classnames'
import { HelpBlock, FormControl } from 'react-bootstrap'
import { Field, FieldProps } from 'react-final-form'
import DataDatetime from 'syft-acp-core/components/EditableTable/DataDatetime'
import EntityListInputWrapper from 'syft-acp-core/components/EntityList/EntityListInputWrapper'

import { getErrorFromMeta } from '../utils'

import { Props } from './FormDateTimePickerInput.types'

export const DateTimePickerInput = ({ input, meta, timeZone, ...rest }: Props) => {
  const showError = getErrorFromMeta(meta)

  return (
    <EntityListInputWrapper className={classnames({ 'has-error': showError })}>
      <FormControl
        editable
        enabled
        {...rest}
        componentClass={DataDatetime}
        value={input.value}
        onChange={input.onChange}
        // @ts-ignore TODO FormControl does not accept extra props that are passed to componentClass
        parameters={{ timeZone, customFormat: rest.customFormat }}
      />
      {showError && <HelpBlock>{meta.error}</HelpBlock>}
    </EntityListInputWrapper>
  )
}

const FormDateTimePickerInput = (props: FieldProps<string, Props>) => (
  <Field {...props} component={DateTimePickerInput} />
)

export default FormDateTimePickerInput
