import React from 'react'
import { Field } from 'react-final-form'
import { Collapse } from 'react-bootstrap'
import { isEqual } from 'lodash-es'

const FormCondition = ({ when, is, useTransition = false, children }) => (
  <Field name={when} subscription={{ value: true }}>
    {({ input: { value } }) => {
      const visible = isEqual(value, is)
      if (useTransition)
        return (
          <Collapse in={visible} mountOnEnter unmountOnExit>
            <div>{children}</div>
          </Collapse>
        )
      return visible ? children : null
    }}
  </Field>
)

export default FormCondition
