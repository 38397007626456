// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Button, ButtonGroup, ButtonLink } from 'syft-acp-atoms/Button'

import { downloadLocalStorage } from 'syft-acp-core/lib/export'
import LoadingIndicator from 'syft-acp-util/components/LoadingIndicator'
import RecordControls from 'syft-acp-core/components/RecordSection/RecordControls'

// Temporarily add an export button for some debugging.
const addExportButton = true

class UserDetailControls extends PureComponent {
  static propTypes = {
    isSavingData: PropTypes.bool.isRequired,
    isCurrentlyLoggedInUser: PropTypes.bool,
    isLoadingData: PropTypes.bool,
  }

  static defaultProps = {
    isCurrentlyLoggedInUser: false,
    isLoadingData: false,
  }

  render() {
    const { isSavingData, isLoadingData, isCurrentlyLoggedInUser } = this.props
    return (
      <RecordControls>
        <div>
          {!isCurrentlyLoggedInUser && (
            <ButtonGroup>
              <ButtonLink to="/entity/users/list">Back to list</ButtonLink>
            </ButtonGroup>
          )}
          {addExportButton && isCurrentlyLoggedInUser && (
            <ButtonGroup key="export">
              <Button onClick={downloadLocalStorage}>Export application state</Button>
            </ButtonGroup>
          )}
        </div>
        <div className="right">
          <LoadingIndicator loadingState={isSavingData || isLoadingData} />
        </div>
      </RecordControls>
    )
  }
}

export default connect(({ users: { isSavingData, isLoadingData } }) => ({
  isSavingData,
  isLoadingData,
}))(UserDetailControls)
