// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import entityReducer from './generators/entityReducer'
import * as types from '../actions/action-types'
import * as entityTypes from './generators/entities'

export default entityReducer('marketingRefCodes', {
  [entityTypes.ENTITIES_FETCH_BEGIN]: types.MARKETING_REF_CODES_FETCH_BEGIN,
  [entityTypes.ENTITIES_FETCH_SUCCEEDED]: types.MARKETING_REF_CODES_FETCH_SUCCEEDED,
  [entityTypes.ENTITIES_FETCH_FAILED]: types.MARKETING_REF_CODES_FETCH_FAILED,
})
