// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { useIntl } from 'react-intl'

import { toPhoneNumberParts } from 'syft-acp-util/formatting'
import CopiedText from 'syft-acp-core/components/CopiedText'
import { TypeLabel } from 'syft-acp-core/components/TypeMaps'

import './PhoneNumber.css'

const prettierValue = (value, localCountryCode) => {
  const { localFormat, countryCode } = toPhoneNumberParts(value, localCountryCode)

  if (countryCode !== localCountryCode) {
    return (
      <div className="inner international">
        <span>{localFormat}</span>
        <TypeLabel name={countryCode} />
      </div>
    )
  }

  return localFormat
}

const PhoneNumber = React.memo(({ value, copyable }) => {
  const { countryCode } = useIntl()
  const prettyValue = useMemo(() => prettierValue(value, countryCode), [value, countryCode])
  return (
    <div className="PhoneNumber">
      {copyable ? <CopiedText copyValue={value}>{prettyValue}</CopiedText> : prettyValue}
    </div>
  )
})

PhoneNumber.propTypes = {
  copyable: PropTypes.bool,
  value: PropTypes.string,
}

PhoneNumber.defaultProps = {
  copyable: true,
  value: '',
}

export default PhoneNumber
