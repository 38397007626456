import React from 'react'
import PropTypes from 'prop-types'

import classnames from 'classnames'

import './EntityDetailItem.css'

/**
 * Note: this component is deprecated. Use <AcpWrapper /> instead.
 */

const EntityDetailItem = ({
  children,
  type,
  noMargin = false,
  noRounding = false,
  padded = false,
  withEntityList = false,
  withPagination = false,
  connected = false,
  className = null,
}) => (
  <div
    className={classnames('entity-detail-item', className, {
      'no-margin': noMargin,
      'no-rounding': noRounding,
      'with-entity-list': withEntityList,
      'with-pagination': withPagination,
      'without-pagination': !withPagination,
      connected,
      padded,
    })}
    {...{ type }}
    data-testid="entity-detail-item"
  >
    {children || null}
  </div>
)

EntityDetailItem.propTypes = {
  children: PropTypes.node.isRequired,
  type: PropTypes.string,
  noMargin: PropTypes.bool,
  noRounding: PropTypes.bool,
  withEntityList: PropTypes.bool,
  withPagination: PropTypes.bool,
  padded: PropTypes.bool,
  connected: PropTypes.bool,
  className: PropTypes.string,
}

export default EntityDetailItem
