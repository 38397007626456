// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { history } from 'syft-acp-core/history'
import { withTrackingTriggerHoc } from 'syft-acp-util/withTrackingTriggerHoc'

import { Button, ButtonGroup } from 'syft-acp-atoms/Button'
import { showConfirmModal } from 'syft-acp-core/store/modals/actions'
import * as actionTypes from 'syft-acp-core/actions/employer-accounts'
import RecordControls from 'syft-acp-core/components/RecordSection/RecordControls'
import LoadingIndicator from 'syft-acp-util/components/LoadingIndicator'
import { trackingEvents } from './EmployerAccountsDetail.tracking'

class EmployerAccountsDetailControls extends Component {
  static propTypes = {
    data: PropTypes.object.isRequired,
    employerID: PropTypes.number.isRequired,
    actions: PropTypes.objectOf(PropTypes.func).isRequired,
    isLoadingData: PropTypes.bool,
    isNewItem: PropTypes.bool,
    triggerEvent: PropTypes.func.isRequired,
  }

  backAction = () => {
    history.push(`/entity/employers/view/${this.props.employerID}/accounts/list`)
  }

  saveAction = () => {
    const { actions, employerID, data, triggerEvent } = this.props
    if (!data.id) {
      // If we don't have an ID, we're creating a new item.
      actions.inviteEmployerAccount(employerID, data)
      triggerEvent(trackingEvents.CREATE.CLICKED)
    } else {
      actions.saveEmployerAccount(employerID, data.id, data)
      triggerEvent(trackingEvents.SAVE.CLICKED)
    }
  }

  removePermissionsAction = () => {
    const { actions, employerID, data, triggerEvent } = this.props
    actions.showConfirmModal({
      question: 'Are you sure? This account will have its access privileges revoked.',
      onConfirm: () => {
        actions.destroyEmployerAccountPermissions(employerID, data.id, data)
        triggerEvent(trackingEvents.REMOVE_PERMISSIONS.CLICKED)
      },
    })
  }

  disableAction = () => {
    const { actions, employerID, data, triggerEvent } = this.props
    actions.showConfirmModal({
      question: 'Are you sure? This account will be disabled.',
      onConfirm: () => {
        actions.disableEmployerAccount(employerID, data.id)
        triggerEvent(trackingEvents.DISABLE_ACCOUNT.CLICKED)
      },
    })
  }

  render() {
    return (
      <RecordControls>
        <ButtonGroup>
          <Button onClick={this.backAction}>Back to accounts</Button>
        </ButtonGroup>
        <div className="right">
          <LoadingIndicator loadingState={this.props.isLoadingData} />{' '}
          <Button kind="success" onClick={this.saveAction}>
            Save
          </Button>
          {!this.props.isNewItem && [
            ' ',
            <ButtonGroup key="extra">
              <Button kind="danger" onClick={this.removePermissionsAction}>
                Remove all permissions
              </Button>
              <Button kind="danger" onClick={this.disableAction}>
                Disable account
              </Button>
            </ButtonGroup>,
          ]}
        </div>
      </RecordControls>
    )
  }
}

export default connect(
  ({ employerAccounts: { isLoadingData } }) => ({
    isLoadingData,
  }),
  dispatch => ({
    actions: bindActionCreators({ ...actionTypes, showConfirmModal }, dispatch),
  }),
)(withTrackingTriggerHoc(EmployerAccountsDetailControls))
