// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import React from 'react'
import PropTypes from 'prop-types'
import LabeledCheckbox from 'syft-acp-core/components/LabeledCheckbox'

import './DataBoolean.css'

const DataBoolean = ({ onChange, checked, editable, label, testId, display = 'inline', parameters = {} }) => (
  <LabeledCheckbox
    callback={onChange}
    className={`data-boolean data-boolean-${display}`}
    checked={!!checked}
    editable={editable}
    label={label}
    testId={testId}
    {...parameters}
  />
)

DataBoolean.propTypes = {
  onChange: PropTypes.func.isRequired,
  checked: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  editable: PropTypes.bool,
  label: PropTypes.string,
  testId: PropTypes.string,
  display: PropTypes.string,
}

DataBoolean.defaultProps = {
  checked: false,
  editable: false,
  label: '',
  testId: null,
}

export default DataBoolean
