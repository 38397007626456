import { apiRequest, handleCall } from 'syft-acp-core/api/call'

export const fetchAgencyListing = ({ options: reqArgs }) =>
  new Promise((resolve, reject) => {
    const req = apiRequest({ path: '/admin/agencies', reqArgs })
    handleCall(req, resolve, reject, true, reqArgs)
  })

export const createAgency = ({ payload: reqArgs }) =>
  new Promise((resolve, reject) => {
    const req = apiRequest({ path: '/admin/agencies', reqArgs, method: 'POST' })
    handleCall(req, resolve, reject)
  })
