import classNames from 'classnames'
import React, { FC } from 'react'

import './ScreenReaderOnlyText.css'

export const ScreenReaderOnlyText: FC = ({ children, ...rest }) => (
  <span role="alert" className={classNames('ScreenReaderOnlyText')} {...rest}>
    {children}
  </span>
)
