// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import * as React from 'react'

import { AbbrHeaderProps as Props } from './AbbrHeader.types'
import './AbbrHeader.scss'

const AbbrHeader: React.FC<Props> = ({ children, label }) => (
  <div className="abbr-header" aria-label={label}>
    <div className="abbr-header__display">{children}</div>
    {children}
  </div>
)

export default React.memo(AbbrHeader)
