import React from 'react'
import { Button } from 'syft-acp-atoms/Button'
import { history } from 'syft-acp-core/history'

type Props = { label: string }

const onClick = () => history.push('/admin/managers/new')

const NewManagerButton = ({ label }: Props) => (
  <div className="small-padded" style={{ float: 'right' }}>
    <Button kind="success" onClick={onClick}>
      {label}
    </Button>
  </div>
)

export default NewManagerButton
