import { apiRequest, handleCall } from 'syft-acp-core/api/call'
import { SaveBulkUploadParams } from './types'

export const saveBulkUpload = ({
  employerId,
  spreadsheetUrl,
  commitConfirmationHash,
}: SaveBulkUploadParams): Promise<any> =>
  new Promise((resolve, reject) => {
    if (!employerId) return reject('Employer ID is empty')
    if (!spreadsheetUrl) return reject('Spreadsheet URL is empty')

    const req = apiRequest({
      path: `/admin/agencies/agency_coverage`,
      reqArgs: {
        employer_id: employerId,
        spreadsheet_url: spreadsheetUrl,
        commit_confirmation_hash: commitConfirmationHash,
      },
      method: 'POST',
    })

    return handleCall(req, resolve, reject)
  })
