import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useTrackingTrigger } from '@indeed/flex-tracking-context'

import { createEntityListConnector } from 'syft-acp-util/entityList'
import { EmployerPreferenceEntity } from 'syft-acp-core/store/employerPreferences/types'
import { fetchEmployerPreferences, updateEmployerPreferences } from 'syft-acp-core/store/employerPreferences/actions'
import { Props, OwnProps } from './PreferencesListWrapper.types'
import { trackingEvents } from '../ClientPreferences.tracking'

const PreferencesListContainer = ({
  isSavingData,
  isLoadingData,
  actions,
  employerID,
  workLocationID,
  jobID,
  data: entityListData,
  title,
  lastMessage,
  render,
  query,
}: Props) => {
  const triggerEvent = useTrackingTrigger()
  const [dirtyEntities, setDirtyEntities] = useState<Record<number, typeof entityListData>>({})
  const isPristine = useMemo(() => Object.values(dirtyEntities).every(dirty => !dirty), [dirtyEntities])
  const isBusy = isSavingData || isLoadingData

  const onEntityUpdate = useCallback(({ value: entity, isPristine: entityIsPristine }) => {
    setDirtyEntities(previousDirtyEntities => ({
      ...previousDirtyEntities,
      [entity.id]: !entityIsPristine ? entity : null,
    }))
  }, [])

  const saveEmployerPreferences = useCallback(() => {
    const updatedPreferences = Object.values(dirtyEntities).filter(Boolean)
    actions.updateEntities &&
      actions.updateEntities({
        options: { ...query, preferences: updatedPreferences },
      })
    triggerEvent(trackingEvents.SAVE_BUTTON.CLICKED, {
      employer_id: employerID,
    })
  }, [actions, dirtyEntities, query, triggerEvent, employerID])

  useEffect(() => {
    actions.fetchEntities &&
      actions.fetchEntities({
        options: query,
      })
    // eslint-disable-next-line
  }, [])

  return (
    <>
      {render({
        isBusy,
        title,
        onSave: saveEmployerPreferences,
        isPristine,
        lastMessage,
        data: entityListData.data,
        onEntityUpdate,
        employerID,
        jobID,
        workLocationID,
      })}
    </>
  )
}

export const listConnector = createEntityListConnector<EmployerPreferenceEntity>({
  entityActions: { fetchEntities: fetchEmployerPreferences, updateEntities: updateEmployerPreferences },
  entityStore: 'employerPreferences',
})

const ConnectedPreferencesListContainer = listConnector(PreferencesListContainer)

const PreferencesListWrapper = ({ render, ...props }: OwnProps) => {
  const query = useMemo(
    () => ({
      employer_id: props.employerID,
      job_id: props.jobID,
      work_location_id: props.workLocationID,
    }),
    [props.employerID, props.jobID, props.workLocationID]
  )
  return <ConnectedPreferencesListContainer {...props} query={query} render={render} />
}
export default PreferencesListWrapper
