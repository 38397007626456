import { takeLatest } from 'redux-saga/effects'

import { redirectCall, notificationCall } from 'syft-acp-core/sagas/calls'
import { takeEveryApiCall, takeLatestApiCall } from 'syft-acp-core/sagas/helpers'
import * as actionTypes from './action-types'
import { listCompliancePolicies, updateCompliancePolicy, deleteCompliancePolicy, createCompliancePolicy } from './api'

export default function* root() {
  yield takeEveryApiCall(actionTypes.COMPLIANCE_POLICIES_FETCH_BEGIN, listCompliancePolicies)
  yield takeLatestApiCall(actionTypes.COMPLIANCE_POLICIES_UPDATE_BEGIN, updateCompliancePolicy)
  yield takeLatestApiCall(actionTypes.COMPLIANCE_POLICIES_DELETE_BEGIN, deleteCompliancePolicy)
  yield takeLatestApiCall(actionTypes.COMPLIANCE_POLICIES_CREATE_BEGIN, createCompliancePolicy)

  yield takeLatest(
    [actionTypes.COMPLIANCE_POLICIES_CREATE_SUCCEEDED, actionTypes.COMPLIANCE_POLICIES_DELETE_SUCCEEDED],
    redirectCall,
    () => '/admin/compliance-policies'
  )

  yield takeLatest(
    [
      actionTypes.COMPLIANCE_POLICIES_FETCH_FAILED,
      actionTypes.COMPLIANCE_POLICIES_UPDATE_FAILED,
      actionTypes.COMPLIANCE_POLICIES_DELETE_FAILED,
      actionTypes.COMPLIANCE_POLICIES_CREATE_FAILED,
      actionTypes.COMPLIANCE_POLICIES_UPDATE_SUCCEEDED,
      actionTypes.COMPLIANCE_POLICIES_DELETE_SUCCEEDED,
      actionTypes.COMPLIANCE_POLICIES_CREATE_SUCCEEDED,
    ],
    notificationCall
  )
}
