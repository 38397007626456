import { takeLatest } from 'redux-saga/effects'

import { notificationCall } from 'syft-acp-core/sagas/calls'
import { takeLatestApiCall } from 'syft-acp-core/sagas/helpers'

import { AGENCY_SHIFT_HISTORIES_FETCH_FAILED, AGENCY_SHIFT_HISTORIES_FETCH_BEGIN } from './action-types'
import * as api from './api'

export default function* saga() {
  yield takeLatestApiCall(AGENCY_SHIFT_HISTORIES_FETCH_BEGIN, api.fetchAgencyShiftHistory)
  yield takeLatest(AGENCY_SHIFT_HISTORIES_FETCH_FAILED, notificationCall)
}
