import { history } from 'syft-acp-core/history'
import moment from 'moment'

import { formatLocalDatetime } from 'syft-acp-util/time'
import { filterAllocationName } from 'syft-acp-core/lib/shifts'

import { branchAccountsPlaceholder } from './ShiftAgencyList'
import { ShiftAgencyListProps as Props, SubRowData, RowData } from './ShiftAgencyList.types'
import {
  fetchAgencyUnfilledAllocations,
  bulkDeleteAgencyUnfilledAllocations,
} from 'syft-acp-core/store/agency-unfilled-allocations/actions'

export const handleDisabledTooltip = (_rowData?: RowData, subRowData?: SubRowData) => {
  if (subRowData?.allocation_type !== 'AgencyShift') {
    return 'Shift must be allocated to agency first.'
  }
  if (!subRowData?.workers_requested) {
    return 'There are no more spots available to cascade.'
  }
  return 'Disabled'
}

/** Formats the allocation date as a regular timestamp. */
export const formatAllocationDate = (subRowData?: SubRowData) => {
  const date = subRowData?.sent_to_agency_at
  return date != null ? formatLocalDatetime(date, { showTimezone: true }).abs : null
}

const convertNullishToDash = (value: any) => (value == null ? '-' : value)

export const formatProvidedRequestedWorkers = (subRowData?: SubRowData) => {
  const workersProvided = convertNullishToDash(subRowData?.workers_provided)
  const workersRequested = convertNullishToDash(subRowData?.workers_requested)
  return `${workersProvided}/${workersRequested}`
}

export const isPast = (date: Date | string) => {
  const today = moment()
  const startDate = moment(date)
  return startDate.isBefore(today)
}

export const selectHighlight = (rowData: RowData) => rowData.highlight

export const selectRowLink = (rowData: RowData) =>
  `/listings/view/${rowData.listing_id}/job/${rowData.job_id}/shift/${rowData.id}`

export const selectWorkersValue = (rowData: RowData) =>
  `${rowData.workers_booked}/${rowData.workers_required}`

export const selectAgency = (_: RowData, subRowData: SubRowData) => filterAllocationName(subRowData)

export const selectAllocationDate = (_: RowData, subRowData: SubRowData) => formatAllocationDate(subRowData)

export const selectRequiredRequested = (_: RowData, subRowData: SubRowData) =>
  formatProvidedRequestedWorkers(subRowData)

export const selectBranchAccounts = (_: RowData, subRowData: SubRowData) =>
  branchAccountsPlaceholder(subRowData)

export const handleDropdownCascadeShifts = ({
  data: { start_time, agency_id, job_id, allocation_id },
  fullJob,
  action,
  refetch,
}: {
  fullJob: boolean
  action: (...args: Parameters<typeof fetchAgencyUnfilledAllocations>) => void
  data: { start_time: string; agency_id: number; job_id: number; allocation_id: number }
  refetch: () => void
}) => {
  if (!isPast(start_time)) {
    action(
      String(agency_id),
      {
        job_id,
        id: String(agency_id),
        allocation_id,
      },
      fullJob,
      refetch,
    )
  }
}

export const handleBulkDeleteUnfilledAllocations = ({
  data: { ids },
  action,
  refetch,
}: {
  action: (...args: Parameters<typeof bulkDeleteAgencyUnfilledAllocations>) => void
  data: { ids: number[] }
  refetch: () => void
}) => {
  action(ids, refetch)
}

export const getDropdownOptions = (
  actions: Props['actions'],
  refetch: () => void,
  onActionDropdownChanged: (
    key: string,
    {
      agency_id,
      allocation_id,
      job_id,
      start_time,
    }: {
      agency_id?: number
      allocation_id?: number
      job_id?: number
      start_time?: string
      id?: number
    },
  ) => void,
) => [
  {
    label: 'View history',
    action: (data: RowData) => () => {
      onActionDropdownChanged('view_history', { id: data.id })
      history.push(`/shifts/agency-shift-history?shift_id=${data.id}`)
    },
  },
  {
    label: 'Cascade shift',
    action:
      // @ts-expect-error

        ({ job_id, start_time }, { agency_id, allocation_id }) =>
        async () => {
          onActionDropdownChanged('cascade_shift', { agency_id, allocation_id, job_id, start_time })
          return handleDropdownCascadeShifts({
            data: { agency_id, allocation_id, job_id, start_time },
            fullJob: false,
            action: actions.fetchAgencyUnfilledAllocations,
            refetch,
          })
        },
    disabled: (data: RowData) => isPast(data?.start_time),
    disabledTooltip: 'You can not cascade shifts in the past',
  },
  {
    label: 'Cascade full job',
    action:
      // @ts-expect-error

        ({ job_id, start_time }, { agency_id, allocation_id }) =>
        async () => {
          onActionDropdownChanged('cascade_full_job', { agency_id, allocation_id, job_id, start_time })
          return handleDropdownCascadeShifts({
            data: { agency_id, allocation_id, job_id, start_time },
            fullJob: true,
            action: actions.fetchAgencyUnfilledAllocations,
            refetch,
          })
        },
    // @ts-expect-error
    disabled: data => isPast(data?.start_time),
    disabledTooltip: 'You can not cascade shifts in the past',
  },
  {
    label: 'Delete unfilled allocations',
    action:
      // @ts-expect-error

        ({ job_id, start_time }, { agency_id, allocation_id }) =>
        async () => {
          onActionDropdownChanged('delete_unfilled_allocations', {
            agency_id,
            allocation_id,
            job_id,
            start_time,
          })
          return handleBulkDeleteUnfilledAllocations({
            data: { ids: [allocation_id] },
            action: actions.bulkDeleteAgencyUnfilledAllocations,
            refetch,
          })
        },
  },
]
