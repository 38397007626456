// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import React from 'react'
import PropTypes from 'prop-types'
import LabeledCheckbox from 'syft-acp-core/components/LabeledCheckbox'

import './DataDarkModeToggle.css'

class DataDarkModeToggle extends React.PureComponent {
  static propTypes = {
    onChange: PropTypes.func.isRequired,
    checked: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
    editable: PropTypes.bool,
    label: PropTypes.string,
  }

  static defaultProps = {
    checked: false,
    editable: false,
    label: '',
  }

  render() {
    const { onChange, checked, editable, label } = this.props
    return (
      <LabeledCheckbox
        callback={onChange}
        className="data-dark-mode-toggle"
        checked={!!checked}
        editable={editable}
        label={label}
      />
    )
  }
}

export default DataDarkModeToggle
