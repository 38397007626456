import { MapPreferenceToDescription } from 'syft-acp-core/store/workerMarketingPreferences/helpers'

export const MapPreferenceToCopy = {
  marketing_emails_consent: {
    TITLE: 'Marketing emails',
    COPY: MapPreferenceToDescription.marketing_emails_consent,
  },
  marketing_calls_consent: {
    TITLE: 'Marketing calls and texts',
    COPY: MapPreferenceToDescription.marketing_calls_consent,
  },
} as const
