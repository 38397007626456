import { useEffect } from 'react'
import deepEqual from 'react-fast-compare'

import usePrevValue from 'syft-acp-util/hooks/usePrevValue'

const useFetchEntity = (query: Record<string, any>, fetchEntities?: (args: any) => void) => {
  const prevQuery = usePrevValue(query)

  useEffect(() => {
    if (fetchEntities && !deepEqual(prevQuery, query)) fetchEntities({ options: query })
  }, [fetchEntities, prevQuery, query])
}

export default useFetchEntity
