// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import React, { useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import { get, values } from 'lodash-es'

import { filterSelect } from 'syft-acp-util/components/FilterForm/FilterSelect'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { listSkills } from 'syft-acp-core/store/skills/actions'

const DataSkill = ({ value, onChange, skills, actions }) => {
  useEffect(() => {
    if (Object.keys(skills).length === 0) {
      actions.listSkills()
    }
  }, [skills, actions])

  const skillOptions = useMemo(
    () => values(skills).map(skill => ({ id: skill.id, value: skill.id, label: skill.title })),
    [skills]
  )

  const FilterSelect = filterSelect(skillOptions, 'Select a skill')

  return (
    <div className="DataEmployer">
      <FilterSelect onChange={onChange} value={value} />
    </div>
  )
}

DataSkill.propTypes = {
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.any,
  actions: PropTypes.array.isRequired,
  skills: PropTypes.array,
}

DataSkill.defaultProps = {
  disabled: false,
  value: '',
  skills: [],
}

export default connect(
  state => {
    return {
      skills: get(state, 'skills.entityMap'),
    }
  },
  dispatch => ({
    actions: bindActionCreators({ listSkills }, dispatch),
  })
)(DataSkill)
