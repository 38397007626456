// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import { takeLatest } from 'redux-saga/effects'

import { downloadCSV } from 'syft-acp-core/api/resources/admin/workers'
import * as types from 'syft-acp-core/actions/action-types'

// TODO: there are some CSV downloads on the finances list page that could probably be moved here.
// Opens any URL - we use this to prompt the browser to download files.
const openURL = url => {
  window.open(url, '_blank')
}

export default function* root() {
  // Responds to the "Download CSV" button on the workers list page.
  yield takeLatest(types.WORKERS_FETCH_CSV, downloadCSV, openURL)
}
