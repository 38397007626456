// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import * as types from '../action-types'
import { PublishToAgency } from './types'

export const fetchListings = ({ options }: { options: Record<string, any> }) => ({
  type: types.LISTINGS_FETCH_BEGIN,
  options,
})

export const fetchAdminListing = (id: number) => ({ type: types.ADMIN_LISTING_FETCH_BEGIN, id })

export const saveListing = (id: number, data: Record<string, any>) => ({ type: types.LISTING_SAVE_BEGIN, id, data })

export const fetchAdminBookings = (shiftID: number, jobID: number, options = { per_page: 8 }) => ({
  type: types.BOOKINGS_FETCH_BEGIN,
  shiftID,
  jobID,
  options,
})

export const fetchFulfilmentBookings = ({ options = { per_page: 8 } }: { options: any }) => ({
  type: types.BOOKINGS_FETCH_BEGIN,
  shiftID: options.shiftID,
  jobID: options.jobID,
  options,
})

export const fetchListingRatings = (listingID: number) => ({
  type: types.LISTING_FETCH_RATINGS_BEGIN,
  listingID,
})

export const deleteListing = (listingID: number) => ({
  type: types.LISTING_DELETE_BEGIN,
  listingID,
})

export const deleteListings = (listingIDs: number[], callback: Function) => ({
  type: types.LISTINGS_DELETE_BEGIN,
  listingIDs,
  callback,
})

export const resendApproval = (data: Record<string, any>) => ({
  type: types.LISTINGS_RESEND_APPROVAL_BEGIN,
  data,
})

export const publishListingToAgency = ({ id }: PublishToAgency) => ({
  type: types.LISTING_UPDATE_BEGIN,
  id,
})
