// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import React from 'react'
import PropTypes from 'prop-types'
import ReactMarkdown from 'react-markdown'

import PageTextContainer from 'syft-acp-atoms/PageTextContainer'

import './PageText.css'

const PageText = ({ children }) => (
  <div className="acp-page-text">
    <PageTextContainer>
      <ReactMarkdown source={children} />
    </PageTextContainer>
  </div>
)

PageText.propTypes = {
  children: PropTypes.node.isRequired,
}

export default PageText
