import { generateEntityCrudActionTypes } from 'syft-acp-util/store/action-types'

export const usersTypes = generateEntityCrudActionTypes('users')

export const USER_BAN_BEGIN = 'syftacp/USER_BAN_BEGIN'
export const USER_BAN_FAILED = 'syftacp/USER_BAN_FAILED'
export const USER_BAN_SUCCEEDED = 'syftacp/USER_BAN_SUCCEEDED'
export const USER_BAN_REASONS_FETCH_BEGIN = 'syftacp/USER_BAN_REASONS_FETCH_BEGIN'
export const USER_BAN_REASONS_FETCH_FAILED = 'syftacp/USER_BAN_REASONS_FETCH_FAILED'
export const USER_BAN_REASONS_FETCH_SUCCEEDED = 'syftacp/USER_BAN_REASONS_FETCH_SUCCEEDED'
export const USER_PATCH_BEGIN = 'syftacp/USER_PATCH_BEGIN'
export const USER_PATCH_FAILED = 'syftacp/USER_PATCH_FAILED'
export const USER_PATCH_SUCCEEDED = 'syftacp/USER_PATCH_SUCCEEDED'
export const USER_FETCH_BEGIN = 'syftacp/USER_FETCH_BEGIN'
export const USER_FETCH_FAILED = 'syftacp/USER_FETCH_FAILED'
export const USER_FETCH_SUCCEEDED = 'syftacp/USER_FETCH_SUCCEEDED'

export default {
  ...usersTypes,
  USER_BAN_BEGIN,
  USER_BAN_FAILED,
  USER_BAN_SUCCEEDED,
  USER_BAN_REASONS_FETCH_BEGIN,
  USER_BAN_REASONS_FETCH_FAILED,
  USER_BAN_REASONS_FETCH_SUCCEEDED,
  USER_PATCH_BEGIN,
  USER_PATCH_FAILED,
  USER_PATCH_SUCCEEDED,
  USER_FETCH_BEGIN,
  USER_FETCH_FAILED,
  USER_FETCH_SUCCEEDED,
}
