import React from 'react'
import CopiedText from 'syft-acp-core/components/CopiedText'
import './EmailText.css'

export default class EmailText extends React.PureComponent {
  render() {
    const { value } = this.props
    return (
      <CopiedText copyValue={value}>
        <div className="EmailText">{value}</div>
      </CopiedText>
    )
  }
}
