import React, { useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { get } from 'lodash-es'
import { connect } from 'react-redux'
import { useTrackingTrigger } from '@indeed/flex-tracking-context'

import { PreferencesListSection } from 'syft-acp-core/components/ClientPreferences'
import { MatchingExplainability } from 'syft-acp-core/components/MatchingExplainability'
import { CompliancesListSection } from 'syft-acp-core/components/Compliances'
import RecordSection from 'syft-acp-core/components/RecordSection'
import entityDetail from 'syft-acp-util/entityDetail'
import { TypeLabel } from 'syft-acp-core/components/TypeMaps'
import ListingJobDetailControls from './ListingJobDetailControls'
import ListingWarningModal from './ListingWarningModal'
import { jobStructure } from './structure'
import { TYPES_MAP } from './IndividualBookingTypes'
import { useFetchDisabledSkills } from 'syft-acp-core/api/resources/skills'
import { useFlexFlagIsOn } from '@indeed/flex-feature-flags'

import { trackingEvents } from '../../tracking'

import './ListingJobDetail.css'

const ListingJobDetail = ({
  id,
  roleID,
  listingID,
  data,
  isPristine,
  actionUpdate,
  employerID,
  employerName,
  venueID,
  shift,
  dispatch,
  allSkills,
  selectedAreaId,
}) => {
  const [updatedData, setUpdatedData] = useState({})

  const triggerEvent = useTrackingTrigger()

  const isCompliancePoliciesFeatureEnabled = useFlexFlagIsOn('pte_16678_admin_compliance_policies')
  const isClientPreferencesFeatureEnabled = useFlexFlagIsOn('pte_16680_admin_client_preferences')
  const isReadOnlySkills = useFlexFlagIsOn('job_posting_acp_read_only_skills')

  const filteredJobTimeDependentRates = useMemo(() => {
    const jobTimeDependentRates = get(data, 'job_time_dependent_rates', [])
    return jobTimeDependentRates.map(timeDependentRate => {
      const shiftTDRs = get(shift, 'job_time_dependent_rates', []).map(item => item.id)
      if (shiftTDRs.includes(timeDependentRate.id)) {
        return { ...timeDependentRate, selected: true }
      }
      return timeDependentRate
    })
  }, [data, shift])
  const handlePreferencesSave = () => {
    triggerEvent(trackingEvents.LISTING_INFO.PREFERENCES.SAVED, { jobId: id })
  }

  const handleVenuePreferencesLink = () => {
    triggerEvent(trackingEvents.LISTING_INFO.VENUE_PREFERENCES.CLICKED, { jobId: id })
  }

  const onUpdate = (path, value, ...rest) => {
    const newState = updatedData
    newState[path] = value
    setUpdatedData(newState)
    actionUpdate(path, value, ...rest)
  }
  const { disabledSkillIds } = useFetchDisabledSkills(dispatch)
  const skills = data.skills || (allSkills && data.skill_ids?.map(skillId => ({ ...allSkills[skillId] })))
  const essentialSkills =
    data.essential_skills ||
    data?.essential_skill_ids?.map(eSkillId => skills?.find(skill => eSkillId === skill.id))
  return (
    <div className="listing-job-detail">
      <ListingJobDetailControls
        updatedData={updatedData}
        data={data}
        isPristine={isPristine}
        setUpdatedData={setUpdatedData}
        listingID={listingID}
        shift={shift}
      />
      <RecordSection
        title="Job information"
        data={{
          ...data,
          employerID,
          venueID,
          disabledSkills: disabledSkillIds,
          essential_skills: essentialSkills,
        }}
        structure={jobStructure(filteredJobTimeDependentRates, selectedAreaId).filter(
          item => !(item?.[0] === 'open_to_discuss_competencies' && isReadOnlySkills),
        )}
        update={onUpdate}
        titleLabels={
          <span>
            <TypeLabel value={data.bookable_individually} typesMap={TYPES_MAP} />
          </span>
        }
      />
      <ListingWarningModal />
      <div className="col-md-12 " style={{ marginTop: 20 }}>
        <MatchingExplainability
          venueID={venueID}
          jobId={id}
          employerName={employerName}
          employerID={employerID}
        />
      </div>
      {isClientPreferencesFeatureEnabled ? (
        <div className="col-md-12 " style={{ marginTop: 20 }}>
          <PreferencesListSection
            key={id}
            employerID={employerID}
            workLocationID={venueID}
            jobID={id}
            title="Fulfilment preferences"
            callbackOnSave={handlePreferencesSave}
            callbackOnVenuePreferences={handleVenuePreferencesLink}
          />
        </div>
      ) : null}

      {isCompliancePoliciesFeatureEnabled ? (
        <div className="col-md-12 " style={{ marginTop: 20 }}>
          <CompliancesListSection key={id} employerID={employerID} roleID={roleID} title="Compliances" />
        </div>
      ) : null}
    </div>
  )
}

ListingJobDetail.propTypes = {
  id: PropTypes.string.isRequired,
  listingID: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired,
  employerID: PropTypes.number.isRequired,
  employerName: PropTypes.string.isRequired,
  venueID: PropTypes.number.isRequired,
  roleID: PropTypes.number,
  isPristine: PropTypes.bool.isRequired,
  listingShift: PropTypes.object.isRequired,
  actionUpdate: PropTypes.func.isRequired,
  shift: PropTypes.object,
  dispatch: PropTypes.func,
  allSkills: PropTypes.object.isRequired,
}

const stateConnector = connect(state => ({
  allSkills: get(state, 'skills.entityMap', {}),
  selectedAreaId: get(state, 'dataTabs.area_id'),
}))

export default entityDetail('jobs', stateConnector(ListingJobDetail))
