import React, { useCallback, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Alert, Col, ControlLabel, FormGroup, Grid, Row } from 'react-bootstrap'
import { bindActionCreators } from 'redux'
import { flowRight } from 'lodash-es'

import DataText from 'syft-acp-core/components/EditableTable/DataText'
import SiteComponent from 'syft-acp-core/components/SiteComponent'
import PageTitle from 'syft-acp-core/components/PageTitle'
import PageView from 'syft-acp-core/components/PageView'
import FilterAutocompleteEmployers from 'syft-acp-util/components/FilterForm/FilterAutocompleteEmployers'
import { Button } from 'syft-acp-atoms/Button'
import { ScreenReaderOnlyText } from 'syft-acp-atoms/ScreenReaderOnlyText'

import * as agencyBulkUploadActions from '../../store/agency-bulk-upload/actions'
import AgencyCoverageImportModal from './AgencyCoverageImportModal'

import { AgencyBulkUploadFormProps } from './AgencyCoverageImportForm.types'

const AgencyCoverageImportFormUI = ({
  actions,
  commitConfirmationHash,
  changes,
  errors,
  isConfirmation,
  isLoading,
}: AgencyBulkUploadFormProps) => {
  const [formData, setFormData] = useState({ employerId: '', spreadsheetUrl: '' })
  const handleSetFormData = useCallback(
    name => (value: string) => {
      setFormData({ ...formData, [name]: value })
    },
    [formData, setFormData],
  )
  useEffect(() => {
    actions.clearBulkUpload()
  }, [actions])

  const handleUpload = useCallback(() => {
    actions.saveBulkUpload({ ...formData, commitConfirmationHash: '', isConfirmation: false })
  }, [actions, formData])
  const handleUploadConfirm = useCallback(() => {
    actions.saveBulkUpload({ ...formData, commitConfirmationHash, isConfirmation: true })
  }, [actions, formData, commitConfirmationHash])
  const hasErrors = errors.length > 0

  return (
    <PageView container textPage>
      <SiteComponent>
        <Grid className="entity-detail-item">
          {hasErrors ? (
            <ScreenReaderOnlyText aria-live="assertive">
              {errors.map((error: string) => (
                <span key={error}>{error}</span>
              ))}
            </ScreenReaderOnlyText>
          ) : null}
          <AgencyCoverageImportModal changes={changes} onUpload={handleUploadConfirm} />
          <Row>
            <Col md={10} sm={10}>
              <div className="item-header">
                <h2>Agency coverage bulk upload</h2>
              </div>
              <FormGroup className="segment form-item">
                {!isConfirmation && hasErrors && (
                  <Row>
                    <Col md={3}></Col>
                    <Col md={9}>
                      <Alert bsStyle="danger">
                        There have been some issues with processing the data:
                        <ul>
                          {errors.map((error: string) => (
                            <li key={error}>{error}</li>
                          ))}
                        </ul>
                      </Alert>
                    </Col>
                  </Row>
                )}
                <Row>
                  <Col md={3}>
                    <ControlLabel htmlFor="employerId">Employer</ControlLabel>
                  </Col>
                  <Col md={9}>
                    <FilterAutocompleteEmployers
                      disabled={false}
                      forceValue={formData['employerId']}
                      callback={handleSetFormData('employerId')}
                      fullWidth
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md={3}>
                    <ControlLabel htmlFor="google-sheet">Google sheet URL</ControlLabel>
                  </Col>
                  <Col md={9}>
                    <DataText
                      id="name"
                      value={formData['spreadsheetUrl']}
                      onChange={handleSetFormData('spreadsheetUrl')}
                      editable
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>&nbsp;</Col>
                </Row>
                <Row>
                  <Col md={3}></Col>
                  <Col md={9}>
                    <Alert bsStyle="warning">
                      IF THERE ARE MULTIPLE TABS, PLEASE MAKE SURE THE RIGHT TAB IS SELECTED WHEN COPY AND
                      PASTING THE URL. The upload will only import the selected tab on the Google Sheet.
                    </Alert>
                  </Col>
                </Row>
                <Row className="segment">
                  <Button
                    kind="success"
                    onClick={handleUpload}
                    disabled={isLoading || !formData['spreadsheetUrl'] || !formData['employerId']}
                  >
                    Upload
                  </Button>
                </Row>
              </FormGroup>
            </Col>
          </Row>
        </Grid>
      </SiteComponent>
    </PageView>
  )
}

export const storeConnector = connect(
  state => ({
    changes: state.agencyBulkUpload.changes,
    commitConfirmationHash: state.agencyBulkUpload.commitConfirmationHash,
    errors: state.agencyBulkUpload.errors,
    isConfirmation: state.agencyBulkUpload.isConfirmation,
    isLoading: state.agencyBulkUpload.isLoading,
  }),
  dispatch => ({
    actions: bindActionCreators(agencyBulkUploadActions, dispatch),
  }),
)

const ImportForm = flowRight(storeConnector)(AgencyCoverageImportFormUI)

export const AgencyCoverageImportForm = () => (
  <PageTitle title="Agencies coverage bulk import">
    <ImportForm />
  </PageTitle>
)
