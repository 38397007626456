// Syft ACP - Lib <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import moment from 'moment'
import { addTimezone } from 'syft-acp-util/formatting'

export const latestDateAsIsoString = () => new Date().toISOString()

/** Checks whether two dates occur within 24 hours of one another. */
export const isWithin24Hours = (dateOne, dateTwo) => {
  const tsOne = +new Date(dateOne)
  const tsTwo = +new Date(dateTwo)

  return Math.abs(tsTwo - tsOne) < 3600000 * 24
}

/**
 * Returns only the year of a timestamp.
 */
export const formatYear = ts => addTimezone(moment(ts)).format('Y')

/**
 * Turns a timestamp number into a formatted time string.
 *
 * E.g. "12:03:05", "12:03".
 */
export const formatTime = (date, sec = true) => addTimezone(moment(date)).format(sec ? 'HH:mm:ss' : 'HH:mm')

/**
 * Returns a month and optionally year formatted locally.
 *
 * E.g. "14 Oct", "14 Oct 2020".
 */
export const formatShortDate = (ts, trimYear = true) => {
  const shortMonthAndYear = addTimezone(moment(ts)).format('ll')
  if (trimYear) {
    const year = addTimezone(moment(ts)).format('Y')
    return shortMonthAndYear.split(year).join('').trim()
  } else {
    return shortMonthAndYear
  }
}

/**
 * Returns a locally formatted month and optionally year with time.
 *
 * E.g. "14 Oct 15:03:03", etc.
 */
export const formatShortDateTime = (ts, trimYear = true, sec = true) => {
  const shortDate = formatShortDate(ts, trimYear)
  const shortTime = formatTime(ts, sec)

  return `${shortDate} ${shortTime}`
}

/**
 * Return an array of shorthanded days depending of the locale
 *
 * days - ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"]
 *  E.g. ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"]
 */

/**
 *
 * @param {Array} days - days to formatting
 * @param {Bool} toShortHand - Condition in order to format the days
 * @param {*} separation - Separation character in bettween the array items when conver to string
 *
 * @example ["Sunday", "Monday", "Tuesday"] => ["Sun", "Mon", "Tue",]
 *
 */

export const formatToShortHandDays = (days = [], toShortHand, separation = ', ', format = 'dd') => {
  const currentMoment = moment()
  return (toShortHand ? days.map(day => currentMoment.day(day).format(format)) : days).join(separation)
}
