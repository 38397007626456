// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { Checkbox } from 'react-bootstrap'
import { get, pull } from 'lodash-es'
import { connect } from 'react-redux'

import { needsIndustriesUpdate$ } from 'syft-acp-core/reducers/industries'
import { fetchIndustries } from 'syft-acp-core/actions/industries'

import './DataIndustriesList.css'

class DataIndustriesList extends PureComponent {
  static propTypes = {
    value: PropTypes.arrayOf(PropTypes.number),
    header: PropTypes.string,
    editable: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    parameters: PropTypes.object,
  }

  static defaultProps = {
    value: [],
    header: '',
    editable: false,
    disabled: false,
    parameters: {},
  }

  componentDidMount() {
    if (needsIndustriesUpdate$()) {
      this.props.dispatch(fetchIndustries())
    }
  }

  setValue = (check, item) => {
    if (check) {
      this.props.onChange([...this.props.value, item.id])
    } else {
      this.props.onChange(pull([...this.props.value], item.id))
    }
  }

  render() {
    const { value, editable } = this.props
    return (
      <div className="DataIndustriesList entity-checkbox">
        {Object.values(this.props.industries).map((item, n) => {
          const isChecked = value && value.indexOf(get(item, 'id')) > -1
          return (
            <Checkbox
              inline
              disabled={!editable}
              checked={isChecked}
              key={n}
              onChange={() => this.setValue(!isChecked, item)}
            >
              <span className="item">{get(item, 'title')}</span>
            </Checkbox>
          )
        })}
      </div>
    )
  }
}

export default connect(({ industries }) => ({ industries: industries.entityMap }))(DataIndustriesList)
