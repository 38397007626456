// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import React from 'react'

import { TrackingTrigger } from '@indeed/flex-tracking-context'
import { useFlexFlagIsOn } from '@indeed/flex-feature-flags'

import PageView from 'syft-acp-core/components/PageView'
import WorkerStrikesListWrapper from 'syft-acp-core/entities/WorkerStrikes/WorkerStrikesListWrapper'
import { trackingEvents } from 'syft-acp-core/entities/WorkerStrikes/WorkerStrikesList/WorkerStrikesList.tracking'
import { WorkerSubNavigation } from './SubNavigation'
import { RouteComponentProps } from 'react-router-dom'

type Props = RouteComponentProps<{
  id: string
}>

export const EntityWorkersStrikesList = ({ match: { params } }: Props) => {
  const isNegativeAction = useFlexFlagIsOn('pte_34577_negative_actions')
  return (
    <>
      <TrackingTrigger
        onLoad={{
          event: trackingEvents.PAGE.VIEWED,
          payload: {
            worker_id: params.id,
          },
        }}
      />
      <WorkerSubNavigation workerID={params.id} />
      <PageView title={[`${isNegativeAction ? 'Negative actions' : 'Strikes'} for worker ${params.id}`]}>
        <WorkerStrikesListWrapper workerID={params.id} />
      </PageView>
    </>
  )
}
