import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { isFunction, memoize, pickBy } from 'lodash-es'
import { showConfirmModal } from 'syft-acp-core/store/modals/actions'

import { createEntityStoreSelector } from './selectors'
import { DispatchProps, EntityListConnectorFactoryOptions, OwnProps } from './types'

export const createEntityListConnector = <TEntity>(options: EntityListConnectorFactoryOptions<TEntity>) => {
  const { entityActions = {}, paginationKey } = options
  // The key we will use to store the page number. If not defined, 'page' is used.
  const pageKey = paginationKey ? `$${paginationKey}` : undefined
  const getEntityActions = isFunction(entityActions) ? entityActions : () => entityActions

  return connect(
    createEntityStoreSelector({ ...options, pageKey }),
    memoize(
      (dispatch, props: OwnProps<TEntity>): DispatchProps<OwnProps<TEntity>> => ({
        actions: {
          ...(props.actions || {}),
          ...bindActionCreators({ ...pickBy(getEntityActions(props)), showConfirmModal }, dispatch),
        },
      }),
    ),
  )
}
