// Syft ACP - UI Library <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import { acpTheme } from 'syft-acp-uikit/lib'

class AcpApp extends React.PureComponent {
  static propTypes = {
    theme: PropTypes.oneOf(['light', 'dark']),
    children: PropTypes.node,
  }

  static defaultProps = {
    theme: 'light',
    children: null,
  }

  render() {
    const { children, theme } = this.props
    return <div className={classNames('App', `acp-theme-${theme}`)}>{children}</div>
  }
}

export default acpTheme()(AcpApp)
