import { apiRequest, handleCall } from 'syft-acp-core/api/call'

/**
 * Retrieves a list of manager commissions.
 */
type OptionsType = {
  target_id?: number
  target_type?: string
  page?: string
}

export const managerCommissions = ({ options = {} }: { options: OptionsType }): Promise<any> =>
  new Promise((resolve, reject) => {
    const req = apiRequest({ path: '/admin/sales/new_manager_commissions', reqArgs: options })

    handleCall(req, resolve, reject, true, { page: options?.page })
  })
