// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import { get, isFunction, isString } from 'lodash-es'
import React from 'react'

import { ButtonLink } from 'syft-acp-atoms/Button'
import ButtonTableWrapper from 'syft-acp-core/components/ButtonTableWrapper'
import { DataIndustries, DataRating, DataSubRows } from 'syft-acp-core/components/EditableTable'
import UserAvatar from 'syft-acp-core/components/UserAvatar'
import ConnectedUserAvatar from 'syft-acp-core/components/UserAvatar/ConnectedUserAvatar'
import {
  formatCurrency,
  formatDate,
  formatDatetime,
  formatDuration,
  formatRelative,
  formatTime,
} from 'syft-acp-util/formatting'

// Components used in the entity list.
import AgencyEmployerDisplay from 'syft-acp-core/components/AgencyEmployerDisplay'
import CityDisplay from 'syft-acp-core/components/CityDisplay'
import CopiedText from 'syft-acp-core/components/CopiedText'
import DataIcon from 'syft-acp-core/components/DataIcon'
import EmailText from 'syft-acp-core/components/EmailText'
import { IconCheckmark } from 'syft-acp-core/components/IconCheckmark'
import { IconX } from 'syft-acp-core/components/IconXSign'
import ManagerDisplay from 'syft-acp-core/components/ManagerDisplay'
import PhoneNumber from 'syft-acp-core/components/PhoneNumber'
import { RoleDisplay, SkillDisplay } from 'syft-acp-core/components/RoleSkillDisplay'
import { Formi9Labels, PaycomLabels, TypeLabels, WorkerLabels } from 'syft-acp-core/components/TypeMaps'
import UserLine from 'syft-acp-core/components/UserAvatar/UserLine'
import VenueDisplay from 'syft-acp-core/components/VenueDisplay'
import StartEnd from 'syft-acp-util/components/StartEnd'

// Icon files for use with the LINK type.
import octiconsFilePDF from 'octicons/build/svg/file-pdf.svg'
import octiconsFile from 'octicons/build/svg/file-text.svg'
import octiconsLink from 'octicons/build/svg/link-external.svg'
import FormattedDateTime, { fullLocalDateTimeFormat } from 'syft-acp-core/components/FormattedDateTime'
import * as types from './cellTypes'
import EntitySelector from './EntitySelector'
import SubrowSelector from './SubrowSelector'

/** Empty cell, displayed e.g. when a BOOL cell's value is false. */
const emptyCell = <span className="empty-cell">&nbsp;</span>

const dateFormatters = {
  datetime: (val, options) => formatDatetime(val, get(options, 'timeZone')),
  datetimeRel: formatRelative,
  date: formatDate,
  time: formatTime,
}

/** Returns a formatted date value, or a fallback value if this is not a date. */
const dateFallback = (dateVal, type, fallback, options) => {
  const dateFormatter = dateFormatters[type]
  return dateVal ? dateFormatter(dateVal, options) : fallback
}

const cellFactories = {
  // Type SELECTOR is for the checkbox.
  [types.SELECTOR]: (cellValue, rowFormat, data = {}, entityType) => ({
    content: <EntitySelector value={cellValue || data.id} scope={entityType} />,
    hasOuterLink: false,
  }),
  // Type WORKER_TAGS displays a worker's status tags.
  [types.WORKER_TAGS]: cellValue => ({
    content: <WorkerLabels value={cellValue} />,
  }),
  // Type WORKER_FORMI9_TAGS displays a worker's Formi9 tags.
  [types.WORKER_FORMI9_TAGS]: cellValue => ({
    content: <Formi9Labels value={cellValue} />,
  }),
  [types.WORKER_PAYCOM_TAGS]: cellValue => ({
    content: <PaycomLabels value={cellValue} />,
  }),
  // Type TAGS is like WORKER_TAGS, but takes a custom types map from the row format.
  [types.TAGS]: (cellValue, rowFormat) => ({
    content: (
      <TypeLabels value={isString(cellValue) ? { [cellValue]: true } : cellValue} typesMap={rowFormat.typesMap} />
    ),
  }),
  // Type PHONE_NUMBER displays a formatted phone number.
  [types.PHONE_NUMBER]: cellValue => ({
    content: <PhoneNumber value={cellValue} copyable />,
  }),
  // Type EMAIL displays an email address.
  [types.EMAIL]: (cellValue, rowFormat) => ({
    content: <EmailText value={cellValue} rowFormat={rowFormat} />,
  }),
  // Type START_END is a duration, such as 'May 20 15:00-16:00'.
  [types.START_END]: (cellValue, rowFormat) => ({
    content: <StartEnd values={cellValue} rowFormat={rowFormat} />,
  }),
  // Type TXT is simple text, e.g. an ID or username.
  [types.TXT]: (cellValue, rowFormat, data) => {
    const rowAttrs = []
    if (rowFormat.numeric) {
      rowAttrs.push('numeric')
    }
    if (rowFormat.alignment) {
      rowAttrs.push(`align-${rowFormat.alignment}`)
    }
    const copyVal = rowFormat.copyable ? (rowFormat.copyValFn ? rowFormat.copyValFn(data) : cellValue) : null

    return {
      content: rowFormat.copyable ? (
        <CopiedText copyValue={copyVal}>{rowFormat.format ? rowFormat.format(cellValue) : cellValue}</CopiedText>
      ) : rowFormat.format ? (
        rowFormat.format(cellValue)
      ) : (
        cellValue
      ),
      rowAttributes: rowAttrs,
    }
  },
  // Type MANAGER is for displaying a manager by ID.
  [types.MANAGER]: cellValue => ({
    content: <ManagerDisplay value={cellValue} />,
  }),
  // Type CITY is for displaying a city by ID.
  [types.CITY]: cellValue => ({
    content: <CityDisplay value={cellValue} />,
  }),
  // Type MONO is like text, but monospaced.
  [types.MONO]: (cellValue, rowFormat) => {
    const rowAttrs = []
    if (rowFormat.numeric) {
      rowAttrs.push('numeric')
    }
    if (rowFormat.alignment) {
      rowAttrs.push(`align-${rowFormat.alignment}`)
    }
    if (rowFormat.noWrap) {
      rowAttrs.push(`nowrap`)
    }
    return {
      content: rowFormat.copyable ? (
        <CopiedText copyValue={cellValue}>
          <span className="monospace-text">{rowFormat.format ? rowFormat.format(cellValue) : cellValue}</span>
        </CopiedText>
      ) : (
        <span className="monospace-text">{rowFormat.format ? rowFormat.format(cellValue) : cellValue}</span>
      ),
      rowAttributes: rowAttrs,
    }
  },
  // Type IMAGE displays an image, e.g. a profile photo.
  [types.IMAGE]: (cellValue, rowFormat) => {
    const uuid = cellValue && cellValue.uuid ? cellValue.uuid : cellValue
    return {
      content: rowFormat.connectedTo ? (
        <ConnectedUserAvatar id={cellValue} connectedTo={rowFormat.connectedTo} size="small" shape="rounded-square" />
      ) : (
        <UserAvatar avatar={uuid} size="small" shape="rounded-square" />
      ),
    }
  },
  // Type MONEY will attempt to format the value as currency.
  [types.MONEY]: cellValue => ({
    content: formatCurrency(get(cellValue, 'amount', cellValue), get(cellValue, 'currency', undefined)),
  }),
  // Type ROLE displays the name of a role by its ID.
  [types.ROLE]: cellValue => ({
    content: <RoleDisplay id={cellValue} />,
  }),
  // Type SKILL displays the name of a skill by its ID and the associated role ID.
  [types.SKILL]: (cellValue, rowFormat, data) => ({
    content: <SkillDisplay id={cellValue} data={data} />,
  }),
  // Type VENUE displays the name of a venue by its ID
  [types.VENUE]: cellValue => ({
    content: <VenueDisplay id={cellValue} />,
  }),
  // Type VENUE displays the name of a venue by its ID
  [types.AGENCY_EMPLOYER]: cellValue => ({
    content: <AgencyEmployerDisplay id={cellValue} />,
  }),
  // Type UUID is like a regular text field, but simplified a bit since UUIDs are large.
  [types.UUID]: cellValue => ({
    content: cellValue,
    rowAttributes: ['shortened', 'one-liner'],
  }),
  // Type DATETIME is a formatted timestamp, e.g. 'Thu Dec 29 2016' for 1483018387403.
  [types.DATETIME]: (cellValue, rowFormat) => ({
    // Ensure it's a number. parseFloat() makes sure an empty string is also not a number.
    // Note that 0 is January 1, 1970. If we mistakenly get a zero, that is what we show.
    content: (
      <FormattedDateTime
        value={cellValue}
        format={{
          ...fullLocalDateTimeFormat,
          ...rowFormat.options,
        }}
      />
    ),
  }),
  // Type DATETIME_REL is a timestamp with relative time included.
  [types.DATETIME_REL]: cellValue => ({
    content: dateFallback(cellValue, 'datetimeRel', null),
  }),
  // Type DATE is a formatted timestamp, e.g. 'Thu Dec 29 2016' for 1483018387403.
  [types.DATE]: cellValue => ({
    content: dateFallback(cellValue, 'date', null),
  }),
  // Type TIME is a formatted timestamp, e.g. 'Thu Dec 29 2016' for 1483018387403.
  [types.TIME]: cellValue => ({
    content: dateFallback(cellValue, 'time', null),
  }),
  // Type DURATION is a given number as a duration in time, e.g. '2 days' for 17280000.
  [types.DURATION]: cellValue => ({
    content: Number.isFinite(parseFloat(cellValue)) && formatDuration(Number(cellValue)),
  }),
  // For ENUM, whether the state is within the expected set of values.
  [types.ENUM]: (cellValue, rowFormat) => {
    const attrs = ['enum']
    let content
    if (Object.keys(rowFormat.enumStates).indexOf(cellValue) >= 0) {
      attrs.push(`val-${rowFormat.enumStates[cellValue]}`)
      content = rowFormat.format ? rowFormat.format(rowFormat.enumLabels[cellValue]) : rowFormat.enumLabels[cellValue]
    } else {
      attrs.push('invalid-enum')
      content = cellValue
    }
    return {
      content,
      rowAttributes: attrs,
    }
  },
  // Type LINK simply links to somewhere and displays an icon.
  [types.LINK]: (cellValue, rowFormat) => {
    // Determine which icon we'll show.
    let icon
    switch (rowFormat.linkType) {
      case types.LINK_FILE:
        icon = octiconsFilePDF
        break
      case types.LINK_SITE:
        icon = octiconsLink
        break
      default:
        icon = octiconsFile
        break
    }
    return {
      content: (
        <DataIcon>
          <img src={icon} alt="External link" />
        </DataIcon>
      ),
      rowAttributes: [types.typeClasses[types.ICON]],
      rowURL: cellValue,
    }
  },
  // Type ICON is for displaying a single icon per a boolean value.
  // This optionally takes a custom function for determining whether we display the icon
  // or not, which defaults to a simple boolean check.
  [types.ICON]: (cellValue, rowFormat) => {
    const checkFn = rowFormat.check ? rowFormat.check : aux => !!aux
    return {
      content: checkFn(cellValue) ? rowFormat.node : emptyCell,
    }
  },
  // Type BOOL is essentially a simpler form of ICON.
  [types.BOOL]: (cellValue, rowFormat) => {
    const content = cellValue ? <IconCheckmark /> : rowFormat.showFalsy ? <IconX /> : emptyCell
    return {
      content,
      rowAttributes: [types.typeClasses[types.ICON]],
    }
  },
  // Type RATING is number of stars 1-5 that takes a float value.
  [types.RATING]: cellValue => ({
    content: <DataRating amount={cellValue != null ? Number(cellValue) : null} showAmount={false} editable={false} />,
    rowAttributes: ['rating'],
  }),
  // Type INDUSTRIES contains a list of industries that a user or employer is active/interested in.
  [types.INDUSTRIES]: cellValue => ({
    content: <DataIndustries ids={cellValue} />,
    rowAttributes: ['industries'],
  }),
  // Type SUBROW is rendered multiple times depending on how many items are in its 'data' value.
  [types.SUBROW]: (cellValue, rowFormat, data) => {
    const rowAttrs = ['sub-row']
    const linkAttrs = []
    const innerURLs = isFunction(rowFormat.href) ? rowFormat.href(data) : rowFormat.href
    let content
    let inner
    if (cellValue && cellValue.length) {
      inner = !!rowFormat.href
      content = <DataSubRows data={cellValue} padded={!rowFormat.noPadding} href={inner ? innerURLs : null} />
      if (rowFormat.numeric) {
        rowAttrs.push('numeric')
      }
      linkAttrs.push('no-padding')
    } else if (rowFormat.defaultValue) {
      content = rowFormat.defaultValue
      inner = false
    }
    if (rowFormat.alignment) {
      rowAttrs.push(`align-${rowFormat.alignment}`)
    }
    return {
      content,
      rowClass: rowFormat.trClassName ? rowFormat.trClassName(data) : null,
      rowAttributes: rowAttrs,
      linkAttributes: linkAttrs,
      hasInnerLink: inner,
      hasOuterLink: !content ? true : !inner && rowFormat.hasLink !== false,
    }
  },
  // Type SUBROW_SELECTOR contains a selector that only selects subrows.
  [types.SUBROW_SELECTOR]: (cellValue, rowFormat, data, entityType) => ({
    content:
      cellValue.length > 0 ? (
        <SubrowSelector
          data={cellValue}
          trClassName={rowFormat.trClassName ? rowFormat.trClassName(data) : null}
          scope={entityType}
        />
      ) : (
        emptyCell
      ),
    rowClass: rowFormat.trClassName ? rowFormat.trClassName(data) : null,
    rowAttributes: ['sub-row'],
    hasOuterLink: cellValue.length === 0,
  }),
  // Type USER_LINE displays a user's name with avatar.
  [types.USER_LINE]: (cellValue, rowFormat, data) => {
    const workerPicture = get(cellValue, 'picture', get(cellValue, 'profile_picture', null))
    const employerPicture = get(data, 'employer_picture', null)
    const name = get(
      cellValue,
      'name',
      get(data, 'employer_name', get(data, 'employer.brand_name', get(data, 'employer.company_name')))
    )
    return {
      content: name ? (
        <UserLine
          avatarUUID={workerPicture ? workerPicture.uuid : employerPicture ? employerPicture.uuid : null}
          name={name}
        />
      ) : (
        <span>-</span>
      ),
    }
  },
  // Displays a button linking to another page.
  [types.BUTTON_LINK]: (cellValue, rowFormat, data) => {
    const target = rowFormat.val(data)
    // If we have a 'disabledFn' function, use that to determine if the button
    // should be disabled or not.
    const disabled = rowFormat.disabledFn && isFunction(rowFormat.disabledFn) ? rowFormat.disabledFn(data) : false

    return {
      content: (
        <ButtonTableWrapper>
          <ButtonLink to={target} kind="regular" disabled={disabled}>
            {rowFormat.text}
          </ButtonLink>
        </ButtonTableWrapper>
      ),
    }
  },
  // This type simply passes on the cell value verbatim. Use when full customization is required.
  [types.CUSTOM_COMPONENT]: cellValue => ({
    content: <div className="custom-component-wrapper">{cellValue}</div>,
  }),
}

export default cellFactories
