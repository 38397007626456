// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import React from 'react'
import { connect } from 'react-redux'
import { TrackingProvider } from '@indeed/flex-tracking-context'
import PageTitle from 'syft-acp-core/components/PageTitle'
import SubNavigation from 'syft-acp-core/components/Navigation/SubNavigation'
import UserSettings from 'syft-acp-core/components/UserSettings'

const User = ({ userData }) => {
  const userID = Number(userData.id)
  return (
    <TrackingProvider
      value={{
        context: {
          user_id: userID,
        },
      }}
    >
      <PageTitle title="User Profile">
        <div>
          <SubNavigation subPage="currentUser" data={{ userID }} />
          <UserSettings id={userID} />
        </div>
      </PageTitle>
    </TrackingProvider>
  )
}

export default connect(state => ({
  userData: state.auth.userData,
}))(User)
