import React from 'react'
import { Information, CheckCircle, CloseCircle } from '@indeed/ifl-icons'
import { Tooltip } from '@material-ui/core'

import { FILTERS_SCHEMA } from './schema'

import { SchemaProps, WorkerFilterResultsData } from './types'
import S from './MatchingExplainability.module.scss'

export const getWorkersFilterResults = (data: WorkerFilterResultsData) => {
  const passed = data.metadata.number_of_passed_filters === data.metadata.number_of_filters
  return (
    <div>
      {passed ? (
        <>
          <b>{data.metadata.number_of_passed_filters}</b> out of {data.metadata.number_of_filters} passed
          <span data-testid="passed_icon" className={S['worker-filter-results__container']}>
            <CheckCircle color="green" size="sm" />
          </span>
        </>
      ) : (
        <>
          <b>{data.metadata.number_of_failed_filters}</b> out of {data.metadata.number_of_filters} failed
          <span data-testid="failed_icon" className={S['worker-filter-results__container']}>
            <CloseCircle color="red" size="sm" />
          </span>
        </>
      )}
    </div>
  )
}

export const getFiltersData = (matchingExplainability: { filters: { name: string }[] }) => {
  const filters = matchingExplainability?.filters
  const isBlockedByClient = filters?.find((filter: any) => filter.name === 'blocked_by_employer_filter')
  return filters
    ?.map((filter: any) => {
      const filteredSchema = FILTERS_SCHEMA.filter((schema: any) => {
        return schema.code === filter.name
      })
      return { filterData: filter, schema: filteredSchema[0] }
    })
    .filter((filter: any) => {
      // We display only blocked by client in case the worker was blocked by employer & venue same time
      return !!isBlockedByClient ? filter?.schema?.code !== 'blocked_at_work_location_filter' : true
    })
}

export const getReasonValue =
  ({
    workerId,
    roles,
    skills,
    employerName,
    employerID,
    venues,
    cities,
    actions,
    userData,
    token,
    venueID,
  }: Partial<SchemaProps>) =>
  (
    _: any,
    subRowData: {
      schema: { title: string; tooltip: string; description: (data: Partial<SchemaProps>) => void }
    },
  ) => {
    return subRowData?.schema?.description({
      workerId,
      subRowData,
      roles,
      skills,
      employerName,
      employerID,
      venues,
      cities,
      actions,
      userData,
      venueID,
      token,
    })
  }

export const getTitleWithTooltip = (_: {}, subRowData: { schema: { title: string; tooltip: string } }) => {
  return (
    <div>
      <div className={S['title-with-tooltip__container']}>{subRowData?.schema?.title}</div>
      <div className={S['title-with-tooltip__tooltip']}>
        <Tooltip
          title={<div className={S['title-with-tooltip__font']}>{subRowData?.schema?.tooltip}</div>}
          placement="top"
          arrow
        >
          <Information />
        </Tooltip>
      </div>
    </div>
  )
}

export const getSkillTitles = (requiredSkillIds: number[], actualSkillIds: number[], data: SchemaProps) => {
  return requiredSkillIds
    ?.filter((requiredId: number) => !actualSkillIds?.includes(requiredId))
    .map((id: number) => data?.skills?.[id]?.title)
    .join(', ')
}
