// Alert types. For more information:
// <https://syftapp.atlassian.net/wiki/spaces/SV/pages/30509764/Notification+Types>

// %{employer_name} has cancelled booking for %{worker_name}
export const ADMIN_BOOKING_CANCELLED_BY_EMPLOYER = 'admin/booking_cancelled_by_employer'
// %{employer_name} has deleted job %{relative_start_time}
export const ADMIN_JOB_DELETED_BY_EMPLOYER = 'admin/job_deleted_by_employer'
// %{id} listing has got rated ≤3 stars
export const ADMIN_LISTING_GOT_BAD_RATING = 'admin/listing_got_bad_rating'
// Conversation for job %{relative_start_time} has flagged word
export const ADMIN_MESSAGE_STOP_WORD = 'admin/message_stop_word'
// %{employer_name} has deleted job shift %{relative_start_time}
export const ADMIN_SHIFT_DELETED_BY_EMPLOYER = 'admin/shift_deleted_by_employer'
// %{worker_name} has failed to confirm shift at %{employer_name}
export const ADMIN_SHIFT_IGNORED = 'admin/shift_ignored'
// %{employer_name} has created new shift %{relative_start_time}
export const ADMIN_SHIFT_STARTING_WITHIN_DAY = 'admin/shift_starting_within_day'
// %{worker_name} has not confirmed shift at %{employer_name} %{relative_start_time}
export const ADMIN_SHIFT_STILL_NOT_CONFIRMED = 'admin/shift_still_not_confirmed'
// %{employer_name} has changed time for shift. Shift now %{relative_start_time}
export const ADMIN_SHIFT_TIME_CHANGED = 'admin/shift_time_changed'
// %{employer_name} marked %{worker_name} timesheet as `no_show`
export const ADMIN_TIMESHEET_NO_SHOW = 'admin/timesheet_no_show'
// %{worker_name} has got rated ≤3 stars
export const ADMIN_WORKER_GOT_BAD_RATING = 'admin/worker_got_bad_rating'
// %{agency name} has returned %{spots returned}/%{spots allocated} for %{Job ID}, %{employer name}, at %{location} starting on %{start date and time}
export const ADMIN_AGENCY_ALLOCATION_RETURNED = 'admin/agency_allocation_returned'
export const ADMIN_AGENCY_DECLINED_SHIFT = 'admin/shift_declined_by_agency'
export const ADMIN_AGENCY_IGNORED_SHIFT = 'admin/shift_ignored_by_agency'
