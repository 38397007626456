import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { useTrackingTrigger } from '@indeed/flex-tracking-context'

import Acp from 'syft-acp-uikit'
import {
  fetchWorkerHolidayPayWeeklyRequests,
  makeWorkerHolidayPayWeeklyRequest,
} from 'syft-acp-core/store/worker-holiday-pay/actions'
import { selectWorkerHolidayPayWeeklyRequests } from 'syft-acp-core/store/worker-holiday-pay/selectors'

import { trackingEvents } from '../WorkerDetail.tracking'

import {
  WorkerHolidayPayWeeklyRequestsProps as Props,
  WorkerHolidayPayWeeklyRequestsOwnProps as OwnProps,
} from './WorkerHolidayPayWeeklyRequests.types'

export const storeConnector = connect(
  (state, ownProps: OwnProps) => ({
    requests: selectWorkerHolidayPayWeeklyRequests(state)(ownProps.workerID),
    isLoading: state.workerHolidayPay.isLoadingWeeklyRequests,
  }),
  dispatch => ({
    actions: bindActionCreators({ fetchWorkerHolidayPayWeeklyRequests, makeWorkerHolidayPayWeeklyRequest }, dispatch),
  })
)

const dateTimeFormat = { hour: undefined, minute: undefined, second: undefined, timeZoneName: undefined }

const WorkerWeeklyHolidayPayRequests = ({ actions, isLoading, requests, workerID }: Props) => {
  const triggerEvent = useTrackingTrigger()

  useEffect(() => {
    actions.fetchWorkerHolidayPayWeeklyRequests({ options: { workerID } })
  }, [actions, workerID])

  const makeWeeklyRequest = () => {
    triggerEvent(trackingEvents.CREATE_WEEKLY_HOLIDAY_PAY_REQUEST.CLICKED)
    return actions.makeWorkerHolidayPayWeeklyRequest({ options: { workerID } })
  }

  return (
    <Acp.EntityList
      data={{ data: requests }}
      isLoading={isLoading}
      idKeyValue="id"
      isIntegrated
      hasMinimumSize
      actionsInline
    >
      <Acp.Actions>
        <Acp.Header>Weekly holiday pay requests</Acp.Header>
        <Acp.Button onClick={makeWeeklyRequest}>Create request</Acp.Button>
      </Acp.Actions>
      <Acp.Table>
        <Acp.Col.Text value="id" header="Request ID" align="left" isMinimal isNumeric />
        <Acp.Col.DateTime value="requested_at" header="Request timestamp" align="left" isMinimal isNumeric />
        <Acp.Col.DateTime
          value="payout_period_beginning_on"
          header="Payout period start date"
          align="left"
          isMinimal
          isNumeric
          options={dateTimeFormat}
        />
        <Acp.Col.DateTime
          value="working_week_ending_on"
          header="Week ending"
          align="left"
          isMinimal
          isNumeric
          options={dateTimeFormat}
        />
        <Acp.Col.DateTime value="pays_on" header="Pays on" align="left" isMinimal isNumeric options={dateTimeFormat} />
      </Acp.Table>
    </Acp.EntityList>
  )
}

export default storeConnector(WorkerWeeklyHolidayPayRequests)
