import { isEqual } from 'lodash-es'
import { useRef } from 'react'

/**
 * Hook to deeply compare and return memoized dependencies, original otherwise
 * Must be only used when you don't control the deps and the computation of effect/memo is harder than comparing deps deeply
 *
 * @param {Array} [dependencies = []]
 * @returns {Array}
 */
export default (dependencies = []) => {
  const ref = useRef([])

  if (!isEqual(ref.current, dependencies)) {
    ref.current = dependencies
  }

  return ref.current
}
