import { takeLatest, put } from 'redux-saga/effects'
import moment from 'moment'

import { takeLatestApiCall } from 'syft-acp-core/sagas/helpers'
import { notificationCall } from 'syft-acp-core/sagas/calls'
import { editListingShiftStopOffers } from 'syft-acp-core/store/listing-shifts/actions'
import { store } from 'syft-acp-core/store'
import { showModal } from 'syft-acp-core/store/modals/actions'
import { fetchAdminJob } from './actions'

import * as types from '../action-types'
import * as api from './api'

function* onSuccessCallback(action: any) {
  if (action?.payload?.warnings.length > 0) {
    store.dispatch(
      showModal('listingWarningModal', {
        warningData: action.payload.warnings,
      }),
    )
  }
  if (action?.request?.onSuccessCallback) {
    yield action?.request?.onSuccessCallback({})
  }
  const pastShift = moment(action.request?.shift?.start_time).isBefore(moment())
  // We are able to edit stop offers only in the future
  if (action.request.listingId && action.request?.shift?.id && !pastShift) {
    yield put(
      editListingShiftStopOffers(
        action.request.listingId,
        action.request.shift.id,
        action.request.data.stop_offers_for_worker_type,
      ),
    )
  }
  // This is a hack, because of roles are not returned after saving a job.
  // Remove this after new endpoint for job will be avaliable. https://syftapp.atlassian.net/browse/PTE-20205
  // and uncomment line in jobs/reducer.ts for job update.
  yield put(fetchAdminJob(action.request.id))
}

export default function* root() {
  yield takeLatestApiCall(types.FETCH_ADMIN_JOB_BEGIN, api.fetchAdminJob)
  yield takeLatestApiCall('JOB_UPDATE', api.updateJob)
  yield takeLatestApiCall('JOB_RATE_WORKER', api.rateWorker)
  yield takeLatest([types.JOB_UPDATE_SUCCEEDED, types.JOB_UPDATE_FAILED], notificationCall)
  yield takeLatest([types.JOB_UPDATE_SUCCEEDED], onSuccessCallback)
  yield takeLatestApiCall('RATING_DELETE', api.ratingDelete)
  yield takeLatest([types.RATING_DELETE_FAILED, types.RATING_DELETE_SUCCEEDED], notificationCall)
}
