import { has, isNil } from 'lodash-es'
import { apiRequest, handleCall } from 'syft-acp-core/api/call'

/**
 * Fetches File from API and downloads in the same tab.
 * Used to track such downloads in DataDog
 *
 * @param path
 * @param reqArgs
 * @param fallbackName
 * @returns {Promise<unknown>}
 */
export const downloadAsFileFromApi = (path, reqArgs, fallbackName = 'result') =>
  new Promise((resolve, reject) => {
    const returnAll = !has(reqArgs, 'per_page') || isNil(reqArgs.per_page)
    const req = apiRequest({ path, reqArgs, returnAll, method: reqArgs.method })
    // The function either loads a CSV as a readable stream or regular data for the reducer.
    handleCall(req, resolve, reject, true, {}, null, false, fallbackName, reqArgs.method !== 'POST')
  })
