import { omit } from 'lodash-es'
import { apiRequest, handleCall } from 'syft-acp-core/api/call'

const basePath = '/admin/sales'

/**
 * Returns salesforce data for an employer.
 * Documentation: <https://syftapp.atlassian.net/wiki/spaces/SV/pages/327319643>
 */
export const getSalesWinAssociations = ({ employerID }) =>
  new Promise((resolve, reject) => {
    const req = apiRequest({ path: `${basePath}/sales_win_associations/${employerID}` })
    handleCall(req, resolve, reject, true, { employerID })
  })

/**
 * Updates existing sales win associations or creates new ones if they don't exist yet.
 */
export const saveSalesWinAssociations = ({ employerID, data }) =>
  new Promise(async (resolve, reject) => {
    // Split data into what we can POST right away, and items we need to reassociate.
    const postableData = data.filter(i => i._associateWithExisting !== true).map(i => omit(i, '_associateWithExisting'))
    const reassociateData = data
      .filter(i => i._associateWithExisting === true)
      .map(i => omit(i, '_associateWithExisting'))

    const postableReq = apiRequest({
      path: `${basePath}/sales_win_associations/${employerID}`,
      reqArgs: { sales_win_associations: postableData },
      method: 'POST',
    })
    const reassociateReq = apiRequest({
      path: `${basePath}/sales_win_associations/${employerID}`,
      reqArgs: { sales_win_associations: reassociateData },
      method: 'POST',
    })

    // Some hacks to update the items without salesforce ID before we update the main employer and/or other items.
    if (!postableData.length) {
      return handleCall(reassociateReq, resolve, reject, true, { employerID })
    }
    if (reassociateData.length) {
      await new Promise((resolve2, reject2) => handleCall(reassociateReq, resolve2, reject2, true, { employerID }))
    }
    return handleCall(postableReq, resolve, reject, true, { employerID })
  })
