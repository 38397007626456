// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import { get } from 'lodash-es'
import PropTypes from 'prop-types'
import React from 'react'

import { Button, Flex, Text, Tooltip } from '@indeed/ifl-components'
import { Information } from '@indeed/ifl-icons'
import * as structureTypes from 'syft-acp-core/components/EditableTable'
import { SUPPORTED_COUNTRY_CODES } from 'syft-acp-core/lib/i18n'
import { getWorkWeekVariants } from 'syft-acp-util/hooks/useWorkWeekVariants'
import './structure.css'

export const dataPropTypes = PropTypes.shape({
  id: PropTypes.number,
  company_name: PropTypes.string,
  contact_name: PropTypes.string,
  contact_mobile: PropTypes.string,
  address: PropTypes.shape({
    city: PropTypes.string,
    line_1: PropTypes.string,
    line_2: PropTypes.string,
    post_code: PropTypes.string,
  }),
  email: PropTypes.string,
  brand_name: PropTypes.string,
  company_number: PropTypes.string,
  vat_number: PropTypes.string,
  summary: PropTypes.string,
  approved_at: PropTypes.string,
  picture: PropTypes.shape({
    uuid: PropTypes.string,
  }),
  skip_minimum_shift_duration: PropTypes.bool,
})

const mainData = [
  [
    'id',
    {
      header: 'ID',
    },
  ],
  [
    'brand_name',
    {
      header: 'Brand name',
      isRequired: true,
      editable: true,
    },
  ],
  [
    'company_name',
    {
      header: 'Company name',
      isRequired: true,
      editable: true,
    },
  ],
  [
    'contact_name',
    {
      header: 'Contact name',
      editable: true,
    },
  ],
  [
    'contact_mobile',
    {
      header: 'Contact telephone no.',
      editable: true,
      type: structureTypes.TYPE_PHONE_NUMBER,
    },
  ],
  [
    'email',
    {
      header: 'Registration email',
    },
  ],
  [
    'city_id',
    {
      header: 'City',
      editable: true,
      type: structureTypes.TYPE_CITY_DROPDOWN,
      showForCountries: [SUPPORTED_COUNTRY_CODES.GB],
    },
  ],
  [
    'address.city',
    {
      header: 'City',
      editable: false,
      showForCountries: [SUPPORTED_COUNTRY_CODES.US],
    },
  ],
  [
    'address.federated_state_code',
    {
      header: 'State',
      editable: false,
      type: structureTypes.TYPE_STATE_DROPDOWN,
      showForCountries: [SUPPORTED_COUNTRY_CODES.US],
    },
  ],
  [
    'industry_ids',
    {
      header: 'Industry',
      isRequired: true,
      editable: true,
      type: structureTypes.TYPE_INDUSTRIES_LIST,
    },
  ],
  ['', { type: structureTypes.TYPE_SEPARATOR }],
  [
    'company_number',
    {
      header: 'Company number',
      isRequired: true,
      editable: true,
    },
  ],
  [
    'vat_number',
    {
      header: 'VAT number',
      editable: true,
      showForCountries: [SUPPORTED_COUNTRY_CODES.GB],
    },
  ],
  [
    'vat_number',
    {
      header: 'TAX ID',
      editable: true,
      showForCountries: [SUPPORTED_COUNTRY_CODES.US],
    },
  ],
  [
    'syft_account_manager_id',
    {
      header: 'Account manager',
      editable: true,
      // Hack for syft_account_manager_id. We receive this data from the API as an object
      // named syft_account_manager. But we must send it as syft_account_manager_id.
      // TODO: remove this hack somehow.
      // https://syftapp.atlassian.net/browse/API2-942
      getData: data => get(data, 'syft_account_manager_id') || get(data, 'syft_account_manager.id'),
      parameters: { managerRoles: ['account_manager'] },
      type: structureTypes.TYPE_MANAGER_DROPDOWN,
    },
  ],
  [
    'syft_sales_manager_id',
    {
      header: 'Sales manager',
      isRequired: true,
      editable: true,
      getData: data => get(data, 'syft_sales_manager_id') || get(data, 'syft_sales_manager.id'),
      parameters: { managerRoles: ['sales_manager'] },
      type: structureTypes.TYPE_MANAGER_DROPDOWN,
    },
  ],
  [
    'syft_account_director_id',
    {
      header: 'Account director',
      editable: true,
      getData: data => data?.syft_account_director_id,
      parameters: { managerRoles: ['account_director'] },
      type: structureTypes.TYPE_MANAGER_DROPDOWN,
    },
  ],
  [
    'tier',
    {
      header: 'Tier',
      isRequired: true,
      editable: true,
      type: structureTypes.TYPE_TIER_DROPDOWN,
    },
  ],
  [
    'client_delivery_team_id',
    {
      header: 'Client delivery team',
      isRequired: true,
      editable: true,
      type: structureTypes.TYPE_CLIENT_DELIVERY_TEAM_DROPDOWN,
    },
  ],
  [
    'picture',
    {
      header: 'Photo',
      type: structureTypes.TYPE_IMAGE,
      editable: true,
    },
  ],
  [
    'created_at',
    {
      header: 'Created on',
      type: structureTypes.TYPE_TIMESTAMP,
    },
  ],
  [
    'last_active_at',
    {
      header: 'Last active on',
      type: structureTypes.TYPE_TIMESTAMP,
    },
  ],
  [
    'approved_at',
    {
      header: 'Approved on',
      type: structureTypes.TYPE_TIMESTAMP,
    },
  ],
  [
    'rating.average',
    {
      header: 'Avg. rating by workers',
      type: structureTypes.TYPE_RATING,
    },
  ],
  [
    'approved_at',
    {
      header: 'Verified',
      type: structureTypes.TYPE_CHECKBOX,
    },
  ],
  [
    'referrer_name',
    {
      header: 'Referrer',
      editable: false,
      defaultValue: '–',
    },
  ],
  [
    'allow_reason_for_booking',
    {
      header: 'Enable custom reasons',
      editable: true,
      type: structureTypes.TYPE_CHECKBOX,
    },
  ],
  [
    'listing_preset_reasons',
    {
      header: 'Add reasons',
      editable: true,
      type: structureTypes.TYPE_REASONS_ARRAY,
    },
  ],
  [
    'show_booking_reason_on_finance_report',
    {
      header: 'Show reason for booking in finance report',
      editable: true,
      type: structureTypes.TYPE_CHECKBOX,
    },
  ],
]

const editableChecbox = {
  editable: true,
  type: structureTypes.TYPE_CHECKBOX,
}

const regulationsData = [
  [
    'requires_preauth',
    {
      ...editableChecbox,
      header: 'Requires pre-auth',
      showForCountries: [SUPPORTED_COUNTRY_CODES.GB],
    },
  ],
  [
    'no_admin_input',
    {
      ...editableChecbox,
      header: 'No admin input',
      showForCountries: [SUPPORTED_COUNTRY_CODES.US],
    },
  ],
  [
    'skip_minimum_shift_duration',
    {
      ...editableChecbox,
      header: '≤4 hour jobs allowed',
    },
  ],
  [
    'working_time_directive',
    {
      ...editableChecbox,
      header: 'Enforce working time directive',
      showForCountries: [SUPPORTED_COUNTRY_CODES.GB],
    },
  ],
  [
    'auto_no_show_shifts',
    {
      ...editableChecbox,
      header: 'Auto No-Show Shifts',
    },
  ],
]

const invoicingData = [
  // FIXME: See CMS2-673
  [
    'invoicing_name',
    {
      header: 'Invoicing client entity name',
      expl: 'Name of the client as you want it to appear on the invoice',
      editable: true,
    },
  ],
  [
    'invoicing_email',
    {
      header: 'Email',
      editable: true,
    },
  ],
  [
    'credit_limit',
    {
      header: 'Credit limit',
      editable: true,
    },
  ],
  [
    'financial_risk',
    {
      header: 'Financial risk',
      editable: true,
      type: structureTypes.TYPE_ENUM,
      options: [
        { value: 'low', label: 'Low' },
        { value: 'medium', label: 'Medium' },
        { value: 'high', label: 'High' },
      ],
    },
  ],
  [
    'invoicing_week_ends_on',
    {
      header: 'Workweek',
      editable: true,
      type: structureTypes.TYPE_ENUM,
      parameters: {
        vertical: true,
      },
      options: getWorkWeekVariants().map(({ range, label }) => ({
        value: range[1],
        label,
      })),
    },
  ],
  [
    'use_cost_centre',
    {
      editable: true,
      translationKey: 'use_cost_centre',
      type: structureTypes.TYPE_CHECKBOX,
    },
  ],
  [
    'add_worker_id_to_finance_reports',
    {
      editable: true,
      translationKey: 'add_worker_id_to_finance_reports',
      type: structureTypes.TYPE_CHECKBOX,
    },
  ],
  [
    'allow_externally_billable_jobs',
    {
      header: (
        <Flex sx={{ alignItems: 'center', mt: -2 }}>
          <Text sx={{ color: 'black', mr: -1 }}>Billable jobs</Text>
          <Tooltip
            delay="400"
            inverse
            label="This shows up on the client portal's cost summary screen to help determine if the job can be billed"
          >
            <Button iconOnly variant="tertiary" aria-label="More information" sx={{ mx: 1, p: 0 }} size="sm">
              <Information aria-hidden size="sm" />
            </Button>
          </Tooltip>
        </Flex>
      ),
      editable: true,
      type: structureTypes.TYPE_CHECKBOX,
    },
  ],
  [
    'invoicing_note.body',
    {
      header: 'Invoicing notes',
      editable: true,
      type: structureTypes.TYPE_TEXTAREA,
    },
  ],
]

const addressData = [
  ['address.line_1', { header: 'Address line 1', editable: true }],
  ['address.line_2', { header: 'Address line 2', editable: true }],
  ['address.post_code', { editable: true, translationKey: 'postcode' }],
  ['address.city', { header: 'City (company office)', editable: true }],
  [
    'address.federated_state_code',
    {
      header: 'State',
      editable: true,
      type: structureTypes.TYPE_STATE_DROPDOWN,
      showForCountries: [SUPPORTED_COUNTRY_CODES.US],
    },
  ],
]

const agencyPermissions = [
  [
    'enable_agency_job_creation',
    {
      header: 'Enable job posting',
      editable: true,
      type: structureTypes.TYPE_CHECKBOX,
    },
  ],
  [
    'enable_agency_cost_centre_code',
    {
      header: 'Enabled cost centre code',
      editable: true,
      type: structureTypes.TYPE_CHECKBOX,
    },
  ],
  [
    'enable_agency_job_approval',
    {
      header: 'Client approval required',
      editable: true,
      type: structureTypes.TYPE_CHECKBOX,
    },
  ],
]

const granularShiftEdits = [
  [
    'allow_worker_level_edit_venue',
    {
      header: 'Allow Venue edits',
      editable: true,
      type: structureTypes.TYPE_CHECKBOX,
    },
  ],
  [
    '',
    {
      header: null,
      rowOptions: {
        colSpan: 10,
      },
      getData: () => (
        <div className="GranularShiftEdits-text">
          Area, Pay Rate and Role edits are enabled for all clients by default, any issues please reach out to
          R&D support.
        </div>
      ),
    },
  ],
]

export const dataStructure = {
  mainData,
  invoicingData,
  regulationsData,
  addressData,
  agencyPermissions,
  granularShiftEdits,
}
