import { apiRequest, handleCall } from 'syft-acp-core/api/call'

// Retrieves a list of deleted right to work documents for a worker.

export const fetchDeletedRTWDocuments = ({ workerId }) => {
  return new Promise((resolve, reject) => {
    const req = apiRequest({ path: `/admin/workers/${workerId}/deleted_rtw_documents` })
    handleCall(req, resolve, reject, true)
  })
}
