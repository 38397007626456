// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import * as types from './action-types'

export function setValue(name, value, userID) {
  return { type: types.USER_SETTINGS_SET_VALUE, name, value, userID }
}

export function setValues(settings, userID) {
  return { type: types.USER_SETTINGS_SET_VALUES, settings, userID }
}

export function ensureDefaultSettings(userID) {
  return { type: types.USER_SETTINGS_SET_DEFAULTS, userID }
}
