import React from 'react'
import classNames from 'classnames'
import { FormattedMessage } from 'react-intl'

import Table from 'syft-acp-util/components/Table'
import { EmployerVenueEntity } from 'syft-acp-core/store/employer-venues/types'

import { useFormatDateTime } from 'syft-acp-core/lib/hooks'

import './VenueAccountManagersTable.css'

const VenueAccountManagersTable = ({
  currentAccountManagers = [],
}: {
  currentAccountManagers: EmployerVenueEntity['current_account_managers']
}) => {
  const [_, formatDate] = useFormatDateTime()
  const data = currentAccountManagers?.map(({ area_name, date_from, name, industry }) => (
    <tr key={`${name} ${date_from}`}>
      <td>{name || '-'}</td>
      <td>{area_name || '-'}</td>
      <td>{industry || '-'}</td>
      <td>{date_from ? formatDate(date_from) : '-'}</td>
    </tr>
  ))
  return (
    <Table colBorders noBottomMargin noRightBorder noLastRowBorder>
      <tbody>
        <tr>
          <th>
            <FormattedMessage id="accountManager.header.name" defaultMessage="Manager Name" />
          </th>
          <th>
            <FormattedMessage id="accountManager.header.area" defaultMessage="Area" />
          </th>
          <th>
            <FormattedMessage id="accountManager.header.industry" defaultMessage="Industry/Subindustry" />
          </th>
          <th>
            <FormattedMessage id="accountManager.header.dateFrom" defaultMessage="Effective date from" />
          </th>
        </tr>
        {data.length ? (
          data
        ) : (
          <tr>
            <td colSpan={4} className={classNames('no-data')}>
              <FormattedMessage id="accountManager.body.noData" defaultMessage="No items found." />
            </td>
          </tr>
        )}
      </tbody>
    </Table>
  )
}

export default VenueAccountManagersTable
