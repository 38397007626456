import { apiRequest, handleCall } from 'syft-acp-core/api/call'

export const listEmployerBlocklist = ({ options = {} }) =>
  new Promise((resolve, reject) => {
    const { employerID, ...reqArgs } = options
    const path = `/employers/${employerID}/blacklist`
    const { workerIdOrName } = reqArgs
    const req = !!workerIdOrName
      ? apiRequest({ path, reqArgs: { worker_id_or_name: workerIdOrName }, returnAll: false })
      : apiRequest({ path, reqArgs, returnAll: false })
    handleCall(req, resolve, reject, true, options, null, false, 'employer-blocklist', false)
  })

export const addToEmployerBlocklist = ({ employerID, workerID }) =>
  new Promise((resolve, reject) => {
    // Should be either an array, or a single value. Only an array seems to work, however. <API2-1884>
    const req = apiRequest({
      path: `/employers/${employerID}/blacklist/add`,
      reqArgs: { worker_id: [workerID] },
      method: 'POST',
    })
    handleCall(req, resolve, reject)
  })

export const removeFromEmployerBlocklist = ({ employerID, workerID }) =>
  new Promise((resolve, reject) => {
    // 'workerID' can be a number or an array of numbers.
    const req = apiRequest({
      path: `/employers/${employerID}/blacklist/remove`,
      reqArgs: { worker_id: Array.isArray(workerID) ? workerID : [workerID] },
      method: 'POST',
    })
    handleCall(req, resolve, reject)
  })
