import { FeatureFlagClient, FlexFeatureFlags } from '@indeed/flex-feature-flags'
import { matchPath, RedirectProps, Switch } from 'react-router-dom'

import { oneHostRoutes, PathConfig, OneHostRouteRedirect } from 'syft-acp-core/oneHostRoutes'
import { Location } from 'history'

import React, { ComponentType, PropsWithChildren } from 'react'
import { Route, RouteMetadata, RouteProps } from './Route'
import { routesConfig } from 'routesConfig'
import { Redirect } from 'syft-acp-util/router/Redirect'

export const ONE_HOST_PATH_PREFIX = '/o'

export type RouteConfig =
  | (Omit<RouteProps, 'component' | 'children'> &
      (
        | {
            layout?: ComponentType<PropsWithChildren<any>>
            metadata?: null
            children: RouteConfig[]
          }
        | { component: RouteProps['component']; children?: never; metadata: RouteMetadata }
      ))
  | RedirectProps

/**
 * Is path is unconditionally redirecting to OneHost
 *
 * @param routeConfig
 */
export const isAutoRedirect = (routeConfig: PathConfig | null): routeConfig is OneHostRouteRedirect =>
  routeConfig ? 'redirect' in routeConfig : false

/**
 * Is OneHost route configuration indicating a redirect
 * @param routeConfig
 * @param client
 */
const isRedirectEnabled = (
  routeConfig: PathConfig | null,
  client: FeatureFlagClient<FlexFeatureFlags>,
): routeConfig is PathConfig =>
  isAutoRedirect(routeConfig) ||
  (!!routeConfig?.flag && client.isFeatureEnabled(routeConfig.flag as keyof FlexFeatureFlags))

// Some routes contain tokens, e.g. `/path/:id` which
// we should try to match against our internal list
export const findMatchingRoute = (
  route: string,
): (typeof oneHostRoutes)[keyof typeof oneHostRoutes] | null => {
  const match = Object.entries(oneHostRoutes).find(([path]) => {
    const matched = matchPath(route, {
      path,
      exact: true,
      strict: false,
    })

    return !!matched
  })

  return match ? match[1] : null
}
/**
 * returns onehost route config or null if no match
 *
 * @param route
 */
export const getOneHostRoute = (route: string) => oneHostRoutes[route] || findMatchingRoute(route)

export const shouldRedirectToOneHost = (pathName: string, client: FeatureFlagClient<FlexFeatureFlags>) => {
  const routeConfig = getOneHostRoute(pathName)
  const isOneHostPrefix = pathName.split('/', 2)[1] === 'o' ? '/o' : undefined
  const isRedirecting = !!isOneHostPrefix || isRedirectEnabled(routeConfig, client)

  return {
    isRedirecting,
    routeConfig,
  }
}

export const buildBaseRedirectPath = (path: string) => {
  const basePath = `/a${path.replace(/\/o\/a\//, '/')}`.replace(/^\/o\//, '/')

  return `/o${basePath}`
}

export const doRedirect = (routeConfig: PathConfig | null, location: Location) => {
  window.location.assign(
    `${buildBaseRedirectPath(routeConfig?.pathname ?? location.pathname)}${location.search}`,
  )
}

export const buildPath = (
  path?: string | readonly string[] | string[],
  parentPaths?: string | string[] | readonly string[],
): string | string[] | readonly string[] | undefined => {
  if (Array.isArray(path)) {
    return path.map(p => {
      if (parentPaths) {
        return Array.isArray(parentPaths) ? parentPaths.concat(p).join('') : `${parentPaths}${p}`
      }
      return p
    })
  }

  if (!path) {
    return undefined
  }

  if (parentPaths) {
    return Array.isArray(parentPaths) ? parentPaths.concat(path).join('') : `${parentPaths}${path}`
  }

  return path
}

export const createRoutesConfig = () => {
  return buildPaths(routesConfig)
}

const buildPaths = (routes: RouteConfig[], parentPaths?: string): React.ReactElement[] =>
  routes.flatMap((route, i) => {
    if ('to' in route) {
      const parentPath = (Array.isArray(parentPaths) ? parentPaths[0] : parentPaths) ?? ''
      const to = typeof route.to === 'string' ? route.to : route.to.pathname
      const resolvedFrom = parentPath + (route.from ?? '')
      const resolvedTo = to?.startsWith('/') ? to : `${parentPath}/${to}`
      return (
        <Redirect
          key={`${resolvedFrom}-${resolvedTo}`}
          {...{
            ...route,
            to: resolvedTo,
            from: resolvedFrom,
          }}
        />
      )
    }

    const path = buildPath(route.path, parentPaths)

    if ('component' in route) {
      return (
        <Route
          key={`${i}-${path}`}
          {...{
            ...route,
            path,
          }}
        />
      )
    }

    const pathWithParent = `${parentPaths || ''}${route.path || ''}`

    const childrenPaths = buildPaths(route.children ?? [], pathWithParent)

    if (route.layout) {
      const { layout, ...routeProps } = route
      return (
        <Route
          key={`${i}-${path}`}
          {...{
            ...routeProps,
            path,
            children: () =>
              React.createElement(layout, null, React.createElement(Switch, null, childrenPaths)),
          }}
        />
      )
    }

    return childrenPaths
  })
