// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import React, { PureComponent } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { get } from 'lodash-es'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import ReactTooltip from 'react-tooltip'
import config from 'syft-acp-core/config'
import uuidV4 from 'uuid/v4'

import { apiURL } from 'syft-acp-core/api/endpoints'
import { Button, ButtonLink } from 'syft-acp-atoms/Button'
import { store } from 'syft-acp-core/store'
import FileUpload from 'syft-acp-core/components/FileUpload'
import LoadingIndicator from 'syft-acp-util/components/LoadingIndicator'
import UserAvatar from 'syft-acp-core/components/UserAvatar'
import UserPicture from 'syft-acp-core/components/UserPicture'

import { uploadImage } from '../../actions/images'
import { notify } from '../../actions/notifications'
import './DataFileUpload.css'

/**
 * Displays a file upload input field.
 */
class DataFileUpload extends PureComponent {
  static propTypes = {
    actions: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    otherData: PropTypes.object,
    // eslint-disable-next-line react/no-unused-prop-types
    isLoadingData: PropTypes.bool.isRequired,
    value: PropTypes.shape({
      uuid: PropTypes.string,
    }),
    // eslint-disable-next-line react/no-unused-prop-types
    lastUploadedImage: PropTypes.object,
    // eslint-disable-next-line react/no-unused-prop-types
    errorMessage: PropTypes.string,
  }

  static defaultProps = {
    lastUploadedImage: null,
    errorMessage: null,
    otherData: {},
    value: {
      uuid: null,
    },
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    // If the next 'last uploaded image' in the store is equal to the last UUID we tried to upload,
    // it means the upload was successful.
    if (nextProps.errorMessage && nextProps.errorMessage !== '') {
      store.dispatch(
        notify('error', {
          title: 'Error',
          message: `Can't upload image. Please try again. Error message: ${nextProps.errorMessage}.`,
        }),
      )

      return {
        ...prevState,
        isLoading: false,
        error: nextProps.errorMessage,
        lastUUID: null,
      }
    }
    if (nextProps.lastUploadedImage.uuid === prevState.lastUUID) {
      // Report successful image upload.
      store.dispatch(
        notify('success', {
          title: 'Success',
          message: `Image uploaded successfully. Click the "save" button at the top to save your changes.`,
        }),
      )
      // Send back the UUID. (do we need this?)
      nextProps.onChange({ uuid: nextProps.lastUploadedImage.uuid })
      return { ...prevState, isLoading: false, lastUUID: null, error: null }
    }
    return null
  }

  constructor() {
    super()
    // Last UUID we uploaded. Keeping track of this to see if the upload succeeded.
    this.state = { lastUUID: null, isLoading: false, error: null }
  }

  // eslint-disable-next-line class-methods-use-this
  get guest_image() {
    // Reference to the 'guest' image that shows up before someone gets a profile photo.
    if (config.stagingLevel === 'prod') {
      return 'ddb1bbf3-6b56-43ba-8193-7df8e9aa8fef'
    } else {
      return '8c24e82d-8715-44ac-b531-756bc38130ee'
    }
  }

  uploadImage = (accepted, rejected) => {
    if (accepted.length !== 1 || rejected.length !== 0) {
      this.setState(prevState => ({ ...prevState, error: 'Please select one image (.jpg or .png).' }))
      return
    }
    const uuid = uuidV4()
    this.setState({ isLoading: true, lastUUID: uuid, error: null })
    this.props.actions.uploadImage(uuid, accepted[0])
  }

  // Allows the user to remove the file that's currently up.
  removeFile = () => {
    // We CAN'T remove profile photos, so instead we're replacing it with a guest image.
    this.props.onChange({ uuid: this.guest_image })
  }

  render() {
    const { isLoading, error } = this.state
    const currentFileUUID = get(this.props, 'value.uuid')
    const currentFileLink = apiURL(`/images/${currentFileUUID}`)
    const { otherData } = this.props
    const name = get(otherData, 'company_name', get(otherData, 'name'))
    return (
      <div className="DataFileUpload">
        {/* Don't display the tooltip if we have no photo. */}
        {currentFileUUID && (
          <ReactTooltip effect="solid" id="original-image" className="image-tooltip">
            <UserPicture uuid={currentFileUUID} alt={name} />
          </ReactTooltip>
        )}
        {/* Display the file uploader and the current file. */}
        <div className="main">
          <div data-tip data-for="original-image">
            <UserAvatar
              avatar={currentFileUUID}
              inline
              shape="rounded-square"
              size="small"
              className="current-avatar"
            />
          </div>
          <FileUpload
            accept={{
              'image/jpeg': [],
              'image/png': [],
            }}
            buttonText="Choose file"
            onDrop={this.uploadImage}
          />
          <ButtonLink
            onClick={null}
            target="_blank"
            external
            to={currentFileLink}
            kind="regular"
            className="view"
            disabled={currentFileUUID === this.guest_image}
          >
            View image
          </ButtonLink>
          <Button
            kind="danger"
            className="delete"
            onClick={this.removeFile}
            disabled={currentFileUUID === this.guest_image}
          >
            Delete
          </Button>
        </div>

        <div className={classnames('feedback')}>
          <div className={classnames('error', { hidden: isLoading || !error })}>{error}</div>
          {/* Loading indicator. */}
          {isLoading && (
            <div>
              <LoadingIndicator loadingState colorDark="#757575" />
              <span> Uploading...</span>
            </div>
          )}
        </div>
      </div>
    )
  }
}

export default connect(
  state => ({
    isLoadingData: state.images.isLoadingData,
    errorMessage: state.images.message,
    lastUploadedImage: state.images.lastUploadedImage,
  }),
  dispatch => ({
    actions: bindActionCreators({ uploadImage }, dispatch),
  }),
)(DataFileUpload)
