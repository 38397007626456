import React from 'react'
import { useFlexFlagIsOn } from '@indeed/flex-feature-flags'

import FilterReasons from 'syft-acp-util/components/FilterForm/FilterReasons'

import { DataConductReviewOutcomeDropdownProps as Props } from './DataConductReviewOutcomeDropdown.types'

const options = [
  {
    id: 'no_ban',
    label: 'No ban',
  },
]

const DataConductReviewOutcomeDropdown = ({ disabled = false, editable = false, data, onChange }: Props) => {
  const hideStrikesAddBans = useFlexFlagIsOn('pte_32272_hide_strikes_add_bans')

  if (editable)
    return (
      <FilterReasons
        allowAny
        type="bans"
        headerLabel="Keep open"
        extraOptions={!hideStrikesAddBans ? options : undefined}
        onChange={onChange}
        value={data.outcome as any}
        disabled={disabled}
        banPrefix
      />
    )
  return <div className="plain-text">{data.ban ? data.ban?.reason?.display_name : 'No ban'}</div>
}

export default DataConductReviewOutcomeDropdown
