export const trackingEvents = {
  PAGE: {
    VIEWED: 'lta_time_off_requests.page.viewed',
  },
  APPROVE_BUTTON: {
    CLICKED: 'lta_time_off_requests.approve_button.clicked',
  },
  REJECT_BUTTON: {
    CLICKED: 'lta_time_off_requests.reject_button.clicked',
  },
  SAVE_BUTTON: {
    CLICKED: 'lta_time_off_requests.save_button.clicked',
  },
}
