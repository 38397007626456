import React from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { compact, omit, sortBy, get } from 'lodash-es'

import { selectListingShiftBookings$ } from 'syft-acp-core/reducers/listing-shift-bookings'
import { selectShift$ } from 'syft-acp-core/store/listing-shifts/selectors'
import * as listingShiftsActions from 'syft-acp-core/store/listing-shifts/actions'
import BelowMinHours from './BelowMinHours'

// Hour in milliseconds.
const hour = 3600 * 1000
// Minimum number of hours; note: this is 6 for chefs, but we currently have no way to check that.
const minHours = hour * 4

/** Returns whether a booking is below the minimum amount of hours. */
const isBelowMinHours = booking => {
  if (booking.no_show) {
    // True if the worker did not show.
    return true
  }
  if (!hasCheckInAndOut(booking)) {
    // Don't display them yet if they're still working.
    return false
  }
  const duration = bookingWorkDuration(booking)
  if (duration <= minHours) {
    // True if duration is less than 4 hours.
    return true
  }
  return false
}

/** Returns whether a booking has a clock in and clock out time. */
const hasCheckInAndOut = booking => {
  const inTime = get(booking, 'clock_in.time', null)
  const outTime = get(booking, 'clock_out.time', null)
  return inTime != null && outTime != null
}

/** Returns the time between a booking's clock in/out time in milliseconds. */
const bookingWorkDuration = booking => {
  // Note: evaluates to 0 if there is no clock in/out time.
  const inTime = new Date(get(booking, 'clock_in.time', null)).getTime()
  const outTime = new Date(get(booking, 'clock_out.time', null)).getTime()
  return outTime - inTime
}

class ListingBelowMinimumHours extends React.PureComponent {
  static propTypes = {
    listingID: PropTypes.number.isRequired,
    shiftID: PropTypes.number.isRequired,
    shiftBookings: PropTypes.array.isRequired,
  }

  reloadData = () => {
    const { listingID, shiftID, actions } = this.props
    actions.fetchAdminShiftListing(listingID, shiftID)
  }

  getBelowBookings = (shiftBookings, shift) => {
    const simpleShift = omit(shift, ['job', 'shift_bookings'])
    return compact(
      shiftBookings.map(b => {
        if (!isBelowMinHours(b)) return null
        // Note: convert milliseconds to seconds for the 'duration' cell type.
        const durationInSeconds = Math.round(bookingWorkDuration(b) / 1000)
        return { ...b, _shift: simpleShift, _diff: durationInSeconds }
      }),
    )
  }

  render() {
    const { shiftBookings, noShowReasons, shift, isLoading } = this.props
    const bookings = this.getBelowBookings(shiftBookings, shift)

    return (
      <BelowMinHours
        noShowReasons={sortBy(Object.values(noShowReasons), 'id')}
        isLoading={isLoading}
        bookings={bookings}
      />
    )
  }
}

export default connect(
  (state, ownProps) => ({
    isLoading: state.listingShifts.isLoadingData,
    noShowReasons: state.noShowReasons.entityMap,
    shift: selectShift$(state)({ shiftID: ownProps.shiftID }),
    shiftBookings: selectListingShiftBookings$(state)({ shiftID: ownProps.shiftID }),
  }),
  dispatch => ({
    actions: bindActionCreators(listingShiftsActions, dispatch),
  }),
)(ListingBelowMinimumHours)
