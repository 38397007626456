import { MODAL_SHOW, MODAL_HIDE, MODAL_SET_DATA } from './action-types'

export type OnClose = (() => void) | null
export type Options = Record<string, any>

/** Generic modal show action */
export const showModal = (
  modalName: string,
  options: Options = {},
  onClose: OnClose = null,
  data: Record<string, any> = {}
) => ({
  type: MODAL_SHOW,
  modalName,
  options,
  onClose,
  data,
})
export type ShowModalAction = ReturnType<typeof showModal>

/** Generic modal hide action */
export const hideModal = (modalName: string) => ({
  type: MODAL_HIDE,
  modalName,
})
export type HideModalAction = ReturnType<typeof hideModal>

/** Show confirmation modal */
export const showConfirmModal = (options: Options, onClose?: OnClose) => showModal('confirm', options, onClose)
export type ShowConfirmModalAction = ReturnType<typeof showConfirmModal>

/** Hide confirmation modal */
export const hideConfirmModal = () => hideModal('confirm')
export type HideConfirmModalAction = ReturnType<typeof hideConfirmModal>

export const setModalData = (modalName: string, data = {}) => ({
  type: MODAL_SET_DATA,
  modalName,
  data,
})
export type SetModalDataAction = ReturnType<typeof setModalData>

export type ModalsAction =
  | ShowModalAction
  | HideModalAction
  | ShowConfirmModalAction
  | HideConfirmModalAction
  | SetModalDataAction
