import { AGENCIES_FETCH_BEGIN, AGENCIES_CLEAR_ITEMS } from './action-types'

export const fetchAgencies = platformId => ({
  type: AGENCIES_FETCH_BEGIN,
  platformId,
})

export const clearAgencies = () => ({
  type: AGENCIES_CLEAR_ITEMS,
})
