import { includes } from 'lodash-es'
import { entitySetName } from 'syft-acp-core/reducers/generators/utils'

export const adminListingShiftFetchSucceededState = (state, action) => {
  const setKey = entitySetName({ shiftID: action.request.shiftID })

  return {
    ...state,
    lastMessage: '',
    lastStatus: null,
    lastBody: null,
    entitySets: {
      ...state.entitySets,
      [setKey]: {
        isLoadingData: false,
        ids: [],
      },
    },
    entityMap: state.entityMap,
  }
}

export const bookingsOfferSucceededState = (state, action) => {
  const workerID = action.request.payload.worker_id
  const setKey = entitySetName({ shiftID: action.request.shiftID })

  return {
    ...state,
    entitySets: {
      ...state.entitySets,
      [setKey]: {
        isLoadingData: false,
        ids: state.entitySets[setKey].ids.filter(id => id !== workerID),
      },
    },
  }
}

export const bookingsBulkOfferSucceededState = (state, action) => {
  const workerIDs = action.request.payload.worker_ids
  const setKey = entitySetName({ shiftID: action.request.shiftID })
  const ids = state.entitySets[setKey].ids.filter(id => !includes(workerIDs, id))

  return {
    ...state,
    lastMessage: '',
    lastStatus: null,
    lastBody: null,
    entitySets: {
      ...state.entitySets,
      [setKey]: {
        isLoadingData: false,
        ids,
      },
    },
  }
}
