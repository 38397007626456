// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import { routerMiddleware } from 'connected-react-router'
import { applyMiddleware, createStore } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly'
import { autoRehydrate, persistStore } from 'redux-persist'
import createSagaMiddleware from 'redux-saga'
import { createStateSyncMiddleware } from 'redux-state-sync'
import { keyPrefix } from 'syft-acp-core/constants'
import { rehydrationComplete } from './actions/rehydrate'
import appReducers from './reducers'
import { history } from './history'
import rootSaga from './sagas'

// Middleware for executing sagas.
const sagaMW = createSagaMiddleware()

// Filter to ensure we don't sync e.g. routing type or init events.
const syncFilter = ({ type }) => {
  const isRoute = type.startsWith('@@')
  const isNotification = type.startsWith('RNS_')
  const isPersist = type.startsWith('persist/')
  const isModal = type.startsWith('syftacp/MODAL_')
  // Note: this is used for a few actions that pass around non-duplicatable data,
  // such as errors or functions. They should be fixed not to do that, however.
  const isNoPost = type.startsWith('syftacp/__NO_POST__')

  return !isRoute && !isNotification && !isPersist && !isModal && !isNoPost
}

// Export our store. The root component must run initStore() and wait for it to complete.
export const store = createStore(
  appReducers,
  composeWithDevTools(
    applyMiddleware(sagaMW, routerMiddleware(history)),
    applyMiddleware(createStateSyncMiddleware({ predicate: syncFilter })), // this middleware should always be applied 'alone', don't put it in an array with other middlewares in 1 apply.
    autoRehydrate(),
  ),
)

let storeReady = false

/**
 * Initializes the store and resolves when ready, with the initialized store as result.
 * @returns {Promise} Store promise
 */
const initStore = () =>
  new Promise((resolve, reject) => {
    // Return the store directly if we've already initialized it.
    if (storeReady) {
      resolve(store)
    }

    try {
      // Start listening for sagas.
      sagaMW.run(rootSaga)

      // Send a signal when rehydration is complete.
      persistStore(
        store,
        {
          // We're not including the localstorage store state, which is used when logging out,
          // and notifications, which duplicates the notification windows if it's rehydrated.
          blacklist: ['localstorage', 'notifications', 'checkboxes'],
          keyPrefix,
        },
        () => {
          storeReady = true
          window.store = store

          store.dispatch(rehydrationComplete())
          // Expose the store to the window object for easier debugging.
          resolve(store)
        },
      )
    } catch (e) {
      reject(e)
    }
  })

export default initStore
