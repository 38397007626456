export const trackingEvents = {
  LISTING_WORKER_INFO: {
    PAGE: {
      LOADED: 'listing_worker_info.page.loaded',
    },
    WORKER_RATING: {
      CHANGED: 'listing_worker_info.worker_rating.changed',
      DELETED: 'listing_worker_info.worker_rating.deleted',
    },
    VIEW_EMPLOYER: {
      CLICKED: 'listing_worker_info.view_employer.clicked',
    },
    VIEW_LISTING_ON_PORTAL: {
      CLICKED: 'listing_worker_info.view_listing_on_portal.clicked',
    },
    RESEND_APPROVAL: {
      CLICKED: 'listing_worker_info.resend_approval.clicked',
    },
    BACK_TO_SHIFTS: {
      CLICKED: 'listing_worker_info.back_to_shifts.clicked',
    },
    EMAIL_TIMESHEET: {
      CLICKED: 'listing_worker_info.email_timesheet.clicked',
    },
    FLEX_ASSISTANT: {
      CLICKED: 'listing_worker_info.flex_assistant.clicked',
    },
  },
}
