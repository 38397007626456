import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { useFormatTimeRange } from 'syft-acp-core/lib/hooks'

const FormattedTimeRange = ({ value, format, children, Component, ...options }) => {
  const [formattedTimeRange, formatTimeRange] = useFormatTimeRange(value, { ...options, format })

  if (typeof children === 'function') {
    return children(formattedTimeRange, formatTimeRange)
  }

  return <Component>{formattedTimeRange}</Component>
}

FormattedTimeRange.defaultProps = {
  format: undefined,
  children: undefined,
  Component: Fragment,
}

FormattedTimeRange.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.instanceOf(Date)]).isRequired,
  format: PropTypes.object,
  children: PropTypes.func,
  Component: PropTypes.elementType,
}

export default React.memo(FormattedTimeRange)
