// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { defaultWrapperPropTypes } from 'syft-acp-util/entityList'
import EntityDetailItem from 'syft-acp-core/components/EntityDetail/EntityDetailItem'
import RecordSegment from 'syft-acp-core/components/RecordSection/RecordSegment'
import RecordHeader from 'syft-acp-core/components/RecordSection/RecordHeader'
import RecordHeaderForm from 'syft-acp-core/components/RecordSection/RecordHeaderForm'
import LoadingIndicator from 'syft-acp-util/components/LoadingIndicator'
import { store } from 'syft-acp-core/store'
import { showModal } from 'syft-acp-core/store/modals/actions'

class WorkerMissingPayWrapper extends React.PureComponent {
  static propTypes = {
    ...defaultWrapperPropTypes,
    isLoading: PropTypes.bool,
  }

  static defaultProps = {
    isLoading: false,
  }

  addWorkerMissingPay = () => {
    store.dispatch(showModal('addWorkerMissingPayModal'))
  }

  render() {
    const { isLoading, entityListResults, entityList, entityListPagination, filterForm } = this.props
    return (
      <div>
        <EntityDetailItem withPagination withEntityList>
          <RecordSegment noPadding>
            <RecordHeaderForm>
              <LoadingIndicator loadingState={isLoading} />
              {filterForm}
            </RecordHeaderForm>
            <RecordHeader border main>
              Workers Non Shift (Missing Pay) hours
            </RecordHeader>
          </RecordSegment>
          <div className={classnames('list')}>
            {entityListResults}
            {entityList}
            {entityListPagination}
          </div>
        </EntityDetailItem>
      </div>
    )
  }
}

export default WorkerMissingPayWrapper
