// Syft ACP - UI Library <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import './FilterForm.css'

class FilterForm extends React.PureComponent {
  static propTypes = {
    multipleFields: PropTypes.bool,
    children: PropTypes.node.isRequired,
  }

  static defaultProps = {
    multipleFields: false,
  }

  render() {
    const { children, multipleFields } = this.props
    return <span className={classNames('acp-filter-form', { multipleFields })}>{children}</span>
  }
}

export default FilterForm
