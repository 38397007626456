// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import React from 'react'

import PageTitle from 'syft-acp-core/components/PageTitle'
import ImportsViewBody from 'syft-acp-core/entities2/ImportsView'

const Imports = () => {
  return (
    <PageTitle title="Imports">
      <ImportsViewBody />
    </PageTitle>
  )
}

export default Imports
