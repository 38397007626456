// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import { size } from 'lodash-es'
import { API_VERSION_V2, v3APIs, API_VERSION_V3 } from 'syft-acp-core/api/constants'
import config from 'syft-acp-core/config'
import { queryString } from './params'

const defaultAvatarOptions = {
  width: '200',
  height: '200',
  crop: 'fill',
}

/**
 * Makes an URL to API endpoint from path and query object.
 *
 * @param path API path to make a call to
 * @param args Key/value pairs to be sent in query string
 * @returns {String} absolute URL to API endpoint
 */
export const apiURL = (path, args = {}) => {
  const API_BASE = v3APIs.includes(path) ? API_VERSION_V3 : API_VERSION_V2
  let baseUrlParts = [config.apiConfig.apiBaseUrl.replace(/\/?$/, '/'), API_BASE]

  return (
    baseUrlParts
      // add common URL parts
      .concat([path, size(args) > 0 ? queryString(args) : ''])
      .join('')
  )
}

/**
 * Makes an image URL from image id and options object.
 *
 * @param id uuid of the image
 * @param options Key/value pairs to be sent in query string
 * @returns {String} absolute URL to image
 */
export const imageURL = (id, options = {}) => apiURL(`/images/${id}`, options)

/**
 * Makes an image URL from image id and options object. Merges options
 * with default avatar options (size and crop)
 *
 * @param id uuid of the image
 * @param options Key/value pairs to be sent in query string
 * @returns {String} absolute URL to image
 */
export const avatarURL = (id, opts = {}) => imageURL(id, Object.assign({}, defaultAvatarOptions, opts))
