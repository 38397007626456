// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import { entityReducer } from 'syft-acp-core/reducers/generators/entityReducer'
import * as entityTypes from 'syft-acp-core/reducers/generators/entities'

import { AGENCY_SHIFTS_FETCH_BEGIN, AGENCY_SHIFTS_FETCH_FAILED, AGENCY_SHIFTS_FETCH_SUCCEEDED } from './action-types'
import { AgencyShiftEntity } from './types'

export default entityReducer<AgencyShiftEntity>('agencyShifts', {
  [entityTypes.ENTITIES_FETCH_BEGIN]: AGENCY_SHIFTS_FETCH_BEGIN,
  [entityTypes.ENTITIES_FETCH_SUCCEEDED]: AGENCY_SHIFTS_FETCH_SUCCEEDED,
  [entityTypes.ENTITIES_FETCH_FAILED]: AGENCY_SHIFTS_FETCH_FAILED,
})
