// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import * as types from './action-types'

export function beginLogin(email, password) {
  return { type: types.LOGIN_BEGIN, email, password }
}

export function beginLoginSSO(email) {
  return { type: types.LOGIN_SSO_BEGIN, email }
}

export function consumeIndeedToken(code) {
  return { type: types.LOGIN_REDEEM_BEGIN, code }
}

export function loginSucceeded(payload, redirect) {
  return { type: types.LOGIN_SUCCEEDED, payload, redirect }
}

export function loginFailed(payload) {
  return { type: types.LOGIN_FAILED, payload }
}

export function loginSSOFailed(payload) {
  return { type: types.LOGIN_SSO_FAILED, payload }
}

export function resetPassword(email) {
  return { type: types.RESET_PASSWORD_BEGIN, email }
}

export function resetPasswordSucceeded(payload) {
  return { type: types.RESET_PASSWORD_SUCCEEDED, payload }
}

export function resetPasswordFailed(payload) {
  return { type: types.RESET_PASSWORD_FAILED, payload }
}

export function logOut() {
  return { type: types.AUTH_LOG_OUT }
}

export function beginLogOut() {
  return { type: types.AUTH_LOG_OUT_BEGIN }
}

export function resetAuth() {
  return { type: types.AUTH_DELETE }
}

export function refreshAuthTokenIfOld() {
  return { type: types.AUTH_REFRESH_OLD_TOKEN_BEGIN }
}

export function refreshAuthToken() {
  return { type: types.AUTH_REFRESH_TOKEN_BEGIN }
}

export function forceRefreshAuthToken(payload) {
  return { type: types.AUTH_REFRESH_TOKEN_FORCE_UPDATE, payload }
}
