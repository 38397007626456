// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import entityReducer from 'syft-acp-core/reducers/generators/entityReducer'
import * as entityTypes from 'syft-acp-core/reducers/generators/entities'

import * as types from '../action-types'
import { ListingEntity, ListingsState } from './types'

export default entityReducer<ListingEntity>(
  'listings',
  {
    [entityTypes.ENTITIES_FETCH_BEGIN]: types.LISTINGS_FETCH_BEGIN,
    [entityTypes.ENTITIES_FETCH_SUCCEEDED]: types.LISTINGS_FETCH_SUCCEEDED,
    [entityTypes.ENTITIES_FETCH_FAILED]: types.LISTINGS_FETCH_FAILED,
    [entityTypes.ENTITY_FETCH_BEGIN]: types.ADMIN_LISTING_FETCH_BEGIN,
    [entityTypes.ENTITY_FETCH_SUCCEEDED]: types.ADMIN_LISTING_FETCH_SUCCEEDED,
    [entityTypes.ENTITY_FETCH_FAILED]: types.ADMIN_LISTING_FETCH_FAILED,
    [entityTypes.ENTITY_SAVE_BEGIN]: types.LISTING_SAVE_BEGIN,
    [entityTypes.ENTITY_SAVE_SUCCEEDED]: types.LISTING_SAVE_SUCCEEDED,
    [entityTypes.ENTITY_SAVE_FAILED]: types.LISTING_SAVE_FAILED,
    [entityTypes.ENTITY_DELETE_BEGIN]: types.LISTING_DELETE_BEGIN,
    [entityTypes.ENTITY_DELETE_SUCCEEDED]: types.LISTING_DELETE_SUCCEEDED,
    [entityTypes.ENTITY_DELETE_FAILED]: types.LISTING_DELETE_FAILED,
    [types.LISTINGS_DELETE_BEGIN]: (state: ListingsState) => {
      return {
        ...state,
        isSavingData: true,
      }
    },
    [types.LISTINGS_DELETE_SUCCEEDED]: (state: ListingsState) => {
      return {
        ...state,
        isSavingData: false,
      }
    },
    [types.LISTINGS_DELETE_FAILED]: (state: ListingsState) => {
      return {
        ...state,
        isSavingData: false,
      }
    },
  },
  {
    // When we reach over 250 cached items in the store,
    // reduce the number of items to the 125 latest.
    itemLimit: [250, 125],
  },
)
