// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import React from 'react'
import PropTypes from 'prop-types'

import './PageTextChangelog.css'

const PageTextChangelog = ({ children }) => <div className="page-text-changelog">{children}</div>

PageTextChangelog.propTypes = {
  children: PropTypes.node.isRequired,
}

export default PageTextChangelog
