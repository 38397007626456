import * as React from 'react'
import { Col, Grid, Row } from 'react-bootstrap'

import PageView from 'syft-acp-core/components/PageView'
import TimeOffRequestList from 'syft-acp-core/entities2/TimeOffRequestList'

import SubNavigationAdmin from './SubNavigation'

export const AdminTimeOffRequestList = () => (
  <>
    <SubNavigationAdmin />
    <PageView title={['LTA', 'Viewing time off requests']}>
      <Grid fluid>
        <Row>
          <Col md={12}>
            <TimeOffRequestList />
          </Col>
        </Row>
      </Grid>
    </PageView>
  </>
)
