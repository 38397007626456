// Syft ACP - Lib <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import classnames from 'classnames'
import PropTypes from 'prop-types'
import React, { useCallback, useContext, useRef, useState } from 'react'

import { KeyboardDatePicker, KeyboardDateTimePicker, MuiPickersContext } from '@material-ui/pickers'
import DatePickerCaption from './DatePickerCaption'

import moment from 'moment'
import './DatePicker.css'

const DatePicker = ({
  name,
  value,
  defaultValue,
  onChange,
  showTime,
  showShortTime,
  className,
  disabled,
  caption,
  testId,
  customFormat,
  ...rest
}) => {
  const ref = useRef()
  const pickersContext = useContext(MuiPickersContext)
  const [invalidValue, setInvalidValue] = useState(null)
  const handleChange = useCallback(
    date => {
      if (date && !date.isValid()) {
        setInvalidValue(date)
      } else if (date && date.isValid()) {
        setInvalidValue(null)
        onChange(name, moment(date))
      }
    },
    [onChange, name],
  )

  const dateTime24hFormat = customFormat
    ? customFormat
    : showShortTime
    ? pickersContext.datetime24hFormatShort
    : pickersContext.dateTime24hFormat

  const baseProps = {
    ...rest,
    name,
    disabled,
    ampm: true,
    autoOk: true,
    onChange: handleChange,
    value: invalidValue || value || null,
    variant: 'inline',
    'data-testid': testId,
    PopoverProps: {
      // By default, DatePicker attaches to the body
      // When opened in Modal, modal loses its focus and tries to regain (DP also tries to regain its focus) hence max callstack exceed occurs
      // This detects whether the current DP instance is opened within a modal and if so, changes its container
      container: () => (ref && ref.current && ref.current.closest("[role='dialog']")) || document.body,
      PaperProps: {
        component: caption
          ? React.forwardRef((props, captionRef) => (
              <DatePickerCaption ref={captionRef} {...props} {...caption} />
            ))
          : 'div',
      },
    },
  }

  return (
    <span
      ref={ref}
      className={classnames(
        'form-control',
        'acp-datepicker-group',
        { 'acp-datepicker-group--disabled': disabled },
        className,
      )}
    >
      {showTime || showShortTime ? (
        <KeyboardDateTimePicker
          placeholder={dateTime24hFormat?.toLowerCase()}
          format={dateTime24hFormat}
          inputProps={{ 'data-testid': `${testId}-input` }}
          {...baseProps}
        />
      ) : (
        <KeyboardDatePicker
          placeholder={pickersContext.dateFormat.toLowerCase()}
          inputProps={{ 'data-testid': `${testId}-input` }}
          {...baseProps}
        />
      )}
    </span>
  )
}

DatePicker.displayName = 'DatePicker'

DatePicker.propTypes = {
  name: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  className: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  showTime: PropTypes.bool,
  showShortTime: PropTypes.bool,
  caption: PropTypes.object,
  disabled: PropTypes.bool,
  testId: PropTypes.string,
  customFormat: PropTypes.string,
}

DatePicker.defaultProps = {
  name: 'datePicker',
  value: null,
  defaultValue: null,
  className: '',
  showTime: false,
  showShortTime: false,
  caption: null,
  disabled: false,
  testId: null,
  onBlur: null,
  onFocus: null,
  customFormat: null,
}

export default React.memo(DatePicker)
