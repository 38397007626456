import type { EmployerRateCardEntity } from 'syft-acp-core/store/employerRateCards/types'
import type { EmployerShiftRateTypeEntity } from 'syft-acp-core/store/employerShiftRateTypes/types'
import { SkillEntity, RoleEntity, EmployerVenueEntity, VenueCategory } from 'syft-acp-core/store/types'

export const roleKey = 'role_ids'
export const skillKey = 'skill_ids'
export const selectionKey = 'employer_rate_cards_list'

export const rateCardStates = {
  Active: {
    name: 'Active',
    color: 'green-light',
  },
  NotEffective: {
    name: 'Not yet',
    color: 'yellow-light',
  },
  Disabled: {
    name: 'Disabled',
    color: 'red-light',
  },
}

export const getVenueName = (venues: EmployerVenueEntity[]) => (row: EmployerRateCardEntity) => {
  return (row.work_location_id && venues[row.work_location_id]?.name) ?? '-'
}

export const getVenueCategoryName = (venueCategories: VenueCategory[]) => (row: EmployerRateCardEntity) => {
  return venueCategories[row.venue_category_id]?.name ?? '-'
}

export const getRole = (roles: RoleEntity[]) => (row: EmployerRateCardEntity) => {
  return roles[row.role_id]?.title ?? '-'
}

export const getSkill = (skills: SkillEntity[]) => (row: EmployerRateCardEntity) => {
  return (row.skill_id && skills[row.skill_id]?.title) ?? '-'
}

export const getPercentage =
  (key: 'margins_percent' | 'burdens_percent') => (row: EmployerRateCardEntity) => {
    return row[key] ? `${row[key]}%` : '-'
  }

export const getShiftType = (shiftTypes: EmployerShiftRateTypeEntity[]) => (row: EmployerRateCardEntity) => {
  const shiftType = row.shift_rate_type_id && shiftTypes[row.shift_rate_type_id]
  const shiftTypeDisabledLabel = shiftType && shiftType.disabled ? '(Disabled)' : ''
  return shiftType ? `${shiftType?.name} ${shiftTypeDisabledLabel}` : '-'
}

export const handleSelection = ({ id }: EmployerRateCardEntity) => {
  return [{ id }]
}

export const handleSelectionLabel = ({ id }: EmployerRateCardEntity) => {
  return `Select rate card ${id}`
}
