import React from 'react'

import PageView from 'syft-acp-core/components/PageView'
import AppealDetail from 'syft-acp-core/entities/AppealDetail'

import ConductSubNav from './ConductSubNav'

export const AppealsNew = () => (
  <>
    <ConductSubNav />
    <PageView title={['Appeals', 'New appeal']}>
      <AppealDetail id={0} />
    </PageView>
  </>
)
