// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import entityReducer from './generators/entityReducer'
import * as types from '../actions/action-types'
import * as entityTypes from './generators/entities'

export default entityReducer(
  'workerTransfers',
  {
    [entityTypes.ENTITY_FETCH_BEGIN]: types.WORKER_TRANSFER_FETCH_BEGIN,
    [entityTypes.ENTITY_FETCH_SUCCEEDED]: types.WORKER_TRANSFER_FETCH_SUCCEEDED,
    [entityTypes.ENTITY_FETCH_FAILED]: types.WORKER_TRANSFER_FETCH_FAILED,

    [entityTypes.ENTITY_MODIFY_BEGIN]: types.WORKER_TRANSFER_UPDATE_BEGIN,
    [entityTypes.ENTITY_MODIFY_SUCCEEDED]: types.WORKER_TRANSFER_UPDATE_SUCCEEDED,
    [entityTypes.ENTITY_MODIFY_FAILED]: types.WORKER_TRANSFER_UPDATE_FAILED,

    [entityTypes.ENTITY_SAVE_BEGIN]: types.WORKER_TRANSFER_CREATE_BEGIN,
    [entityTypes.ENTITY_SAVE_SUCCEEDED]: types.WORKER_TRANSFER_CREATE_SUCCEEDED,
    [entityTypes.ENTITY_SAVE_FAILED]: types.WORKER_TRANSFER_CREATE_FAILED,

    [entityTypes.ENTITY_DELETE_BEGIN]: types.WORKER_TRANSFER_DELETE_BEGIN,
    [entityTypes.ENTITY_DELETE_SUCCEEDED]: types.WORKER_TRANSFER_DELETE_SUCCEEDED,
    [entityTypes.ENTITY_DELETE_FAILED]: types.WORKER_TRANSFER_DELETE_FAILED,
  },
  { idKey: 'id', payloadProcess: ({ worker_transfer }) => worker_transfer }
)
