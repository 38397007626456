export const setObjectProperty = (object: any, path: string, value: string) => {
  const parts = path.split('.')
  const limit = parts.length - 1
  for (let i = 0; i < limit; ++i) {
    const key = parts[i]
    object = object[key] ?? (object[key] = {})
  }
  const key = parts[limit]
  object[key] = value
}

export const hideAllAreasOrVenues = (attribute: 'area' | 'venue') => (data: any) =>
  !data[`${attribute}s`] ||
  data[`${attribute}s`]?.length < 1 ||
  (data[`${attribute}s`]?.length === 1 && data[`${attribute}s`]?.[0]?.name === data[attribute]?.name)

export const handleConfirmationMessage = ({
  overridePayrate,
  differentRate,
  differentTime,
  overrideTime,
}: {
  overrideTime?: boolean
  overridePayrate?: boolean
  differentRate?: number
  differentTime?: string
}) => {
  if (differentRate && overridePayrate && differentTime && overrideTime) {
    return `This shift contains worker specific Times and Rates, which won’t be affected by higher level edits.`
  }
  if (differentRate && overridePayrate) {
    return `This job contains worker specific rates, which won't be affected by job rate edits.`
  }
  if (differentTime && overrideTime) {
    return `This shift contains worker specific Times, which won't be affected by shift Time edits.`
  }
  return null
}
