import { isEqual, find, flowRight, get } from 'lodash-es'
import React, { useCallback, useEffect } from 'react'
import deepEqual from 'react-fast-compare'
import { connect, ConnectedProps } from 'react-redux'
import { bindActionCreators } from 'redux'
import { useTrackingTrigger } from '@indeed/flex-tracking-context'

import BaseIcon from 'syft-acp-atoms/Icons/base/BaseIcon'
import { resendWorkerInvitation } from 'syft-acp-core/actions/worker-transfer'
import { entityList$ } from 'syft-acp-core/reducers/generators/entities'
import * as externalPlatformsActions from 'syft-acp-core/store/external-platforms/actions'
import * as modalActions from 'syft-acp-core/store/modals/actions'
import { ExternalPlatformEntity } from 'syft-acp-core/store/external-platforms/types'
import * as workersActions from 'syft-acp-core/store/workers/actions'
import Acp from 'syft-acp-uikit/index'
import { createEntityListConnector } from 'syft-acp-util/entityList'
import { formatFullName } from 'syft-acp-util/formatting'
import usePrevValue from 'syft-acp-util/hooks/usePrevValue'

import { Button, ButtonGroup } from 'syft-acp-atoms/Button'
import CountrySpecific from 'syft-acp-core/components/CountrySpecific'
import DownloadCSVModal from 'syft-acp-core/components/Modal/DownloadCSVModal/DownloadCSVModal'
import { formi9Statuses, formi9StatusKeys } from 'syft-acp-core/data/formi9Status'
import { paycomStatusKeys, payStatuses } from 'syft-acp-core/data/paycomStatus'
import { workerStatuses } from 'syft-acp-core/data/workerStatus'
import { SUPPORTED_COUNTRY_CODES } from 'syft-acp-core/lib/i18n'

import { actionUploadCSV } from '../../entities/helpers'
import { Props, WorkerEntity } from './TransferredWorkersList.types'
import { trackingEvents } from './TransferredWorkersList.tracking'

const MODAL_NAME = 'downloadEmployersCSV'

const entityConnector = createEntityListConnector<WorkerEntity>({
  entityActions: {
    fetchEntities: workersActions.fetchWorkers,
  },
  staticArgs: { show_transferred: true },
  entityStore: 'workers',
})

const storeConnector = connect(
  state => ({
    // TODO entityList$ should return base entity type from the passed reducer state
    externalPlatforms: entityList$(state.externalPlatforms) as ExternalPlatformEntity[],
  }),
  dispatch =>
    bindActionCreators(
      {
        fetchExternalPlatforms: externalPlatformsActions.fetchExternalPlatforms,
        resendInvitation: resendWorkerInvitation,
        showModal: modalActions.showModal,
        fetchWorkersCSV: workersActions.fetchWorkersCSV,
      },
      dispatch
    )
)

type AllProps = ConnectedProps<typeof entityConnector> & ConnectedProps<typeof storeConnector> & Props

const statusGroups = {
  i9form_status: {
    value: 'us_i9_form',
    title: 'Form I9 status',
    statuses: formi9Statuses,
    defaultStatus: (formi9StatusKeys as typeof formi9Statuses).Incomplete,
  },
  paycom_status: {
    value: 'us_paycom',
    title: 'Pay status',
    statuses: payStatuses,
    defaultStatus: (paycomStatusKeys as typeof payStatuses).unverified,
  },
}

const actionUploadWorkersCsv = actionUploadCSV()

const renderOption = <T extends { id: number | string; name: string }>({ id, name }: T): React.ReactNode => (
  <Acp.Option slug={id} key={`${name}-${id}`}>
    {name}
  </Acp.Option>
)

const rowLink = (row: WorkerEntity) => `/entity/workers/view/${row.id}`

const TransferredWorkersList = ({
  actions,
  query,
  data,
  externalPlatforms,
  isLoadingData,
  pagination,
  resendInvitation,
  fetchExternalPlatforms,
  showModal,
  fetchWorkersCSV,
}: AllProps) => {
  const triggerEvent = useTrackingTrigger()
  const prevQuery = usePrevValue(query)
  const fetch = useCallback(
    (q: Record<string, any>, toCSV = false) =>
      actions.fetchEntities && actions.fetchEntities({ options: q }, {}, toCSV),
    [actions]
  )

  const handleDownloadCSV = useCallback(
    (view?: string) => () => {
      const hasFilters = !isEqual(query, {})
      showModal(MODAL_NAME, {
        onConfirm: () => {
          fetchWorkersCSV(query, view)
          triggerEvent(trackingEvents.DOWNLOAD_CSV_BUTTON.CLICKED)
        },
        query: query,
        total: pagination.total,
        hasFilters: hasFilters,
      })
    },
    [fetchWorkersCSV, showModal, triggerEvent, pagination.total, query]
  )

  const handleResendInvitation = useCallback(
    ({ rowData }) => {
      resendInvitation(rowData.email)
      triggerEvent(trackingEvents.RESEND_INVITATION_BUTTON.CLICKED, {
        worker_id: rowData.id,
      })
    },
    [triggerEvent, resendInvitation]
  )

  useEffect(() => {
    fetchExternalPlatforms()
  }, [fetchExternalPlatforms])

  useEffect(() => {
    if (!deepEqual(prevQuery, query)) {
      fetch(query)
    }
  }, [fetch, prevQuery, query])

  return (
    <CountrySpecific countries={[SUPPORTED_COUNTRY_CODES.US]}>
      {(isUS: boolean) => (
        <>
          <Acp.EntityList data={data} isLoading={isLoadingData} hasPagination hasResultCount inContainer>
            <Acp.Actions>
              <Acp.ControlGroup>
                <ButtonGroup style={{ marginLeft: 'auto' }}>
                  <Button kind="primary" onClick={() => actionUploadWorkersCsv()}>
                    Upload CSV
                  </Button>
                  <Button
                    disabled={isLoadingData || !pagination.total || pagination.total === 0}
                    kind="primary"
                    onClick={handleDownloadCSV()}
                  >
                    Download CSV
                  </Button>
                </ButtonGroup>
              </Acp.ControlGroup>

              <Acp.FilterGroup title="ID/Name">
                <Acp.Filter.Text name="query" placeholder="ID/Name" />
              </Acp.FilterGroup>

              <Acp.FilterGroup title="External platform">
                <Acp.Filter.Dropdown name="external_platform_id" hasAnyOption placeholder="Any">
                  {externalPlatforms.map(renderOption)}
                </Acp.Filter.Dropdown>
              </Acp.FilterGroup>

              <Acp.FilterGroup title="Transferred date">
                <Acp.Filter.DateRange nameFrom="transferred_on_gte" nameTo="transferred_on_lte" />
              </Acp.FilterGroup>

              <Acp.FilterGroup title="Verified date">
                <Acp.Filter.DateRange nameFrom="verified_date_gte" nameTo="verified_date_lte" />
              </Acp.FilterGroup>

              <Acp.FilterGroup title="Email">
                <Acp.Filter.Text name="email" placeholder="Email" type="email" />
              </Acp.FilterGroup>

              <Acp.FilterGroup title="Completed shifts ≥">
                <Acp.Filter.Text name="completed_shifts_gte" placeholder="0" type="number" />
              </Acp.FilterGroup>

              <Acp.FilterGroup title="Status">
                <Acp.Filter.Dropdown name="status" hasAnyOption placeholder="Any">
                  {Object.entries(workerStatuses).map(([id, { name }]) => renderOption({ id, name }))}
                </Acp.Filter.Dropdown>
              </Acp.FilterGroup>

              {isUS &&
                Object.entries(statusGroups).map(([paramName, { title, statuses }]) => (
                  <Acp.FilterGroup title={title} key={title}>
                    <Acp.Filter.Dropdown name={paramName} hasAnyOption placeholder="Any">
                      {Object.entries(statuses).map(([id, { name }]) => renderOption({ id, name }))}
                    </Acp.Filter.Dropdown>
                  </Acp.FilterGroup>
                ))}
            </Acp.Actions>

            <Acp.Table rowLink={rowLink}>
              <Acp.Col.LegacySelector />
              <Acp.Col.Text value="id" header="Worker ID" headerTooltip="Worker ID" isMinimal isCopyable />
              <Acp.Col.Image
                value="profile_picture"
                header={<BaseIcon iconSlug="person" iconSize={13} iconAlt="Picture" />}
                isMinimal
              />
              <Acp.Col.Text value={formatFullName} header="Name" headerTooltip="Worker full name" isCopyable />
              <Acp.Col.Text
                value={(e: WorkerEntity) =>
                  get(find(externalPlatforms, { id: e.worker_transfer?.external_platform_id }), 'name')
                }
                header="External platform"
                headerTooltip="Worker transferred from"
                isCopyable
              />
              <Acp.Col.Email value="email" header="Email" isMinimal />
              <Acp.Col.Phone value="telephone_number" header="Tel. number" isMinimal />
              <Acp.Col.WorkerStatus value="status" header="Status" options={{ displayUnknownValue: false }} />
              {isUS &&
                Object.entries(statusGroups).map(([, { value, defaultStatus, statuses, title }]) => (
                  <Acp.Col.Status
                    value={(e: WorkerEntity) => ({ [e[value]?.status || defaultStatus]: true })}
                    options={{ displayUnknownValue: false, statuses: statuses }}
                    header={title}
                  />
                ))}
              <Acp.Col.Industries value="industry_ids" header="Industry" />
              <Acp.Col.Button header="Invite">
                <Acp.Button
                  onClick={handleResendInvitation}
                  needsConfirm
                  confirmHeader="Resend invitation?"
                  confirmText="This will resend the invitation email to the worker."
                  confirmYes="Send"
                  confirmNo="Cancel"
                >
                  Resend
                </Acp.Button>
              </Acp.Col.Button>
            </Acp.Table>
          </Acp.EntityList>
          <DownloadCSVModal name={MODAL_NAME} />
        </>
      )}
    </CountrySpecific>
  )
}

export default flowRight(entityConnector, storeConnector)(React.memo(TransferredWorkersList))
