import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { fetchIndustries } from 'syft-acp-core/actions/industries'
import * as filtersActions from 'syft-acp-core/store/filters/actions'
import { needsIndustriesUpdate$ } from 'syft-acp-core/reducers/industries'
import { EntitiesState } from 'syft-acp-core/reducers'

import { getValueArray } from '../filterHelpers'
import { filterSelect } from '../FilterSelect'
import { FilterOptGroupsProps as Props } from './FilterOptGroups.types'

class FilterOptGroups extends PureComponent<Props> {
  static defaultProps = {
    defaultOption: 'Any',
    value: null,
    options: [],
    entityMap: {},
    allowAny: false,
    small: false,
    tiny: false,
    disabled: false,
    type: null,
    callback: null,
    shouldFetch: null,
    generateOptionsFunc: null,
    className: '',
    filterIndex: 0,
    fireCallbackBeforeMount: true,
  }

  constructor(props: Props) {
    super(props)
    if (props.callback && props.fireCallbackBeforeMount) props.callback(props.name, props.value)
  }

  options: Props['options'] = []

  componentDidMount() {
    if (this.props.shouldFetch && this.props.shouldFetch()) this.props.actions.fetchAction()
    this.options = this.props.options
  }

  onChange = (val: string | string[]) => {
    const { actions, name, callback, filterIndex, filterOptions, onChange } = this.props
    actions.setFilter(name, val, filterIndex, filterOptions)
    onChange && onChange(name, val)
    if (callback) callback(name, val)
  }

  UNSAFE_componentWillReceiveProps() {
    this.options = this.props.options
  }

  render() {
    const {
      value,
      allowAny,
      disabled,
      name,
      defaultOption,
      className,
      generateOptionsFunc,
      small,
      tiny,
      filterIndex,
      ariaLabel,
    } = this.props
    const items = Object.keys(this.options).length > 0 ? this.options : this.props.entityMap
    const FilterSelect = filterSelect(
      generateOptionsFunc ? generateOptionsFunc(items) : this.options,
      defaultOption || 'Any',
      undefined,
      true,
      true
    )

    let theValue = value
    if (typeof filterIndex !== 'undefined') {
      const valueArray = getValueArray(value)
      theValue = valueArray[filterIndex]
      if (valueArray.length === 1) theValue = valueArray[0]
      if (filterIndex + 1 > valueArray.length) theValue = null
    }

    return (
      <FilterSelect
        ariaLabel={ariaLabel}
        name={name}
        className={`${className} l-${Object.keys(this.options).length}`}
        onChange={this.onChange}
        value={theValue}
        small={small}
        tiny={tiny}
        allowAny={allowAny}
        disabled={disabled}
      />
    )
  }
}

export const connector = connect(
  (
    state,
    { storeName = 'industries', name, queryAttribute }: { [key: string]: any; storeName: keyof EntitiesState }
  ) => ({
    storeName,
    options: state[storeName].entityMap,
    entityMap: state[storeName || 'industries'].entityMap,
    // FIXME: `props.value` has always `undefined` value, thus I had to take the value from the query
    value: state.routing.locationBeforeTransitions.query[queryAttribute || name] || null,
  }),
  (dispatch, { fetchAction = fetchIndustries, shouldFetch = needsIndustriesUpdate$ }: Record<string, any>) => ({
    shouldFetch,
    actions: bindActionCreators(
      {
        ...filtersActions,
        fetchAction,
      },
      dispatch
    ),
  })
)
export default connector(FilterOptGroups)
