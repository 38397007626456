// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import { get } from 'lodash-es'
import { apiRequest, handleCall } from 'syft-acp-core/api/call'

/**
 * Retrieves a list of employer rate cards.
 *
 * @param params {Object}
 * @param params.employerID {Number} the employer ID
 */
export const listEmployerRatecards = ({ options: { employerID, venueID, ...rest } }) =>
  new Promise((resolve, reject) => {
    const req = apiRequest({
      path: `/admin/rate_cards`,
      reqArgs: {
        employer_ids: [employerID],
        ...(venueID && venueID !== -1 ? { work_location_ids: [venueID] } : {}),
        ...rest,
      },
    })
    handleCall(req, resolve, reject, true, { employerID, venueID, ...rest })
  })

/** Transforms our data structure into the structure needed by the API. */
const makePostData = (target, data) => ({
  employer_id: target.employerID,
  work_location_id: data.venueID,
  role_id: data.roleID,
  skill_id: data.skillID,
  max_payable_rate: data.rateMax,
  min_payable_rate: data.rateMin,
  ...(get(data, 'rateTotal.amount') ? { invoice_rate: data.rateTotal } : {}),
})

/**
 * Saves a new rate card.
 *
 * @param params {Object}
 * @param params.target.employerID {Number} the employer ID
 * @param params.target.venueID {Number} the venue ID (if saving for a venue)
 * @param params.data {Number} the rate card data
 */
export const saveRatecard = ({ target, data }) =>
  new Promise((resolve, reject) => {
    const reqArgs = makePostData(target, data)
    const req = apiRequest({ path: `/admin/rate_cards`, reqArgs, method: 'POST' })
    const skillId = get(data, 'skillID') ? { skill_ids: data.skillID } : {}
    const venueID = get(data, 'venueID') !== -1 && get(data, 'venueID') ? { venueID: data.venueID } : {}

    handleCall(req, resolve, reject, true, {
      employerID: target.employerID,
      role_ids: data.roleID,
      ...skillId,
      ...venueID,
    })
  })

/**
 * Updates an existing rate card.
 *
 * @param params {Object}
 * @param params.target.employerID {Number} the employer ID
 * @param params.target.venueID {Number} the venue ID (if saving for a venue)
 * @param params.data {Number} the rate card data
 */
export const updateRatecard = ({ target, data }) =>
  new Promise((resolve, reject) => {
    const reqArgs = makePostData(target, data)
    const req = apiRequest({
      path: `/admin/rate_cards/${target.rateCardID}`,
      reqArgs,
      method: 'PATCH',
      allowNulls: true,
    })
    handleCall(req, resolve, reject, true, { employerID: target.employerID })
  })
