// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import React, { useEffect } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Grid, Col, Row } from 'react-bootstrap'
import { useIntl } from 'react-intl'
import { flowRight } from 'lodash-es'
import { useFlexFlagIsOn } from '@indeed/flex-feature-flags'

import entityDetail from 'syft-acp-util/entityDetail'
import NotifyBar from 'syft-acp-atoms/NotifyBar'
import * as workersActions from 'syft-acp-core/store/workers/actions'
import * as workerTierActions from 'syft-acp-core/store/workerTier/actions'
import RecordSection from 'syft-acp-core/components/RecordSection'
import RecordWorkerStats from 'syft-acp-core/components/RecordSection/RecordWorkerStats'
import RecordWorkerHolidayPay from 'syft-acp-core/components/RecordSection/RecordWorkerHolidayPay'
import RecordWorkerMarketingPreferences from 'syft-acp-core/components/RecordSection/RecordWorkerMarketingPreferences'
import SiteComponent from 'syft-acp-core/components/SiteComponent'
import WorkerHeader from 'syft-acp-core/entities/WorkerDetail/WorkerHeader'
import CountrySpecific from 'syft-acp-core/components/CountrySpecific'
import { SUPPORTED_COUNTRY_CODES } from 'syft-acp-core/lib/i18n'
import WorkerMissingPay from 'syft-acp-core/components/WorkerMissingPay'
import BanWorkerModal from 'syft-acp-core/components/Modal/BanWorkerModal'

import { WorkerReferralBonuses } from './WorkerReferralBonuses'
import { WorkerExpenses } from './WorkerExpenses'
import WorkerHolidayPayHistoricRequests from './WorkerHolidayPayHistoricRequests'
import WorkerHolidayPayWeeklyRequests from './WorkerHolidayPayWeeklyRequests'
import WorkerCompletedShifts from './WorkerCompletedShifts'
import WorkerDetailControls from './WorkerDetailControls/WorkerDetailControls'
import { dataStructure } from './structure'
import { WorkerDetailProps as Props } from './WorkerDetail.types'
import { WorkerEntity } from '../../store/types'
import MarketingPreferencesModal from 'syft-acp-core/components/Modal/MarketingPreferencesModal'

export const storeConnector = connect(
  (state: any) => ({
    workerTier: state.workerTier,
    countryCode: state.auth?.userData?.country_code,
  }),
  dispatch => ({
    actions: bindActionCreators({ ...workersActions, ...workerTierActions }, dispatch),
  }),
)

export const urlGenerator = (rowData: { listing_id: number; job_id: number; shift_id: number }) =>
  `/listings/view/${rowData.listing_id}/job/${rowData.job_id}/shift/${rowData.shift_id}`

const formatGeoLocationData = (data: WorkerEntity) => {
  const geoLocationPreferences = data.geo_location_preferences
  const roleId = [
    ...(data.worker_roles?.filter(workerRole => workerRole.verified).map(workerRole => workerRole.role_id) ||
      []),
    ...(data.general_preferences?.desired_role_ids || []),
  ]

  return geoLocationPreferences
    ? {
        coordinates: {
          longitude: geoLocationPreferences.longitude,
          latitude: geoLocationPreferences.latitude,
        },
        radius: geoLocationPreferences.radius || 25,
        radius_unit: geoLocationPreferences.radius_unit || 'mi',
        shifts_within_radius: `Link to relevant shifts`,
        role_id: roleId,
      }
    : {
        coordinates: null,
        radius: 'N/A',
        radius_unit: null,
        shifts_within_radius: 'N/A',
        role_id: roleId,
      }
}

const WorkerDetail = ({
  id,
  actions,
  data,
  isPristine,
  lastMessage,
  actionUpdate,
  workerTier,
  countryCode,
}: Props) => {
  const { formatDate } = useIntl()
  const payAdjustmentsEnabled = useFlexFlagIsOn('acp_worker_pay_adjustments')
  const workerExpensesEnabled = useFlexFlagIsOn('acp_worker_expenses')
  const dataTierWorker = workerTier?.entityDetail?.[data.id]
  const getBanReasonText = () => {
    const { active_ban: activeBan } = data
    if (!activeBan?.banner || !activeBan?.reason) return null
    const banDate = formatDate(new Date(activeBan.banned_at))
    return ` ${activeBan.banner.full_name} banned this user on ${banDate} for "${activeBan.reason.display_name}".`
  }
  useEffect(() => {
    actions.fetchWorker(id)
    actions.fetchTierRewards(id)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const country = countryCode === 'GB' ? 'UK' : 'US'

  return (
    <SiteComponent>
      <Grid>
        <Row>
          <Col md={12} sm={12}>
            <WorkerDetailControls data={data} isPristine={isPristine} />
            <NotifyBar conditional noMargin visible={!!data.banned}>
              This user is currently banned from the system and cannot be assigned to do any jobs.
              {getBanReasonText()}
            </NotifyBar>
            <NotifyBar conditional noMargin visible={!!lastMessage}>
              {lastMessage}
            </NotifyBar>
            <RecordSection
              data={{ ...data, ...dataTierWorker, country }}
              structure={dataStructure.bio}
              update={actionUpdate}
              header={<WorkerHeader noWrapper data={data} />}
            />
            <RecordWorkerMarketingPreferences workerID={data.id} />
            <RecordSection
              title="Profile information"
              data={data}
              structure={dataStructure.profile}
              update={actionUpdate}
            />
            <RecordSection
              title="Location preferences"
              data={formatGeoLocationData(data)}
              structure={dataStructure.geolocationPreferences}
              update={actionUpdate}
            />
            <RecordSection
              title="Address information"
              data={data}
              structure={dataStructure.admin}
              update={actionUpdate}
            />
            <CountrySpecific countries={[SUPPORTED_COUNTRY_CODES.GB]}>
              <RecordSection
                title="Bank account"
                data={data}
                footerText="Bank details can only be edited by workers themselves via their own app."
                structure={dataStructure.bank}
                update={actionUpdate}
              />
            </CountrySpecific>
            <RecordSection
              title="Next of kin"
              data={data}
              structure={dataStructure.workerNextOfKin}
              update={actionUpdate}
            />
            {data.id && (
              <div>
                <RecordWorkerStats workerID={data.id} />
                <CountrySpecific countries={[SUPPORTED_COUNTRY_CODES.GB]}>
                  <RecordWorkerHolidayPay workerID={data.id} />
                  <WorkerHolidayPayHistoricRequests workerID={data.id} />
                  <WorkerHolidayPayWeeklyRequests workerID={data.id} />
                </CountrySpecific>
                <WorkerCompletedShifts
                  workerID={data.id}
                  query={{ workerID: data.id }}
                  urlGenerator={urlGenerator}
                />
                <WorkerMissingPay
                  workerID={data.id}
                  worker={data}
                  query={{ workerID: data.id }}
                  urlGenerator={urlGenerator}
                />
                {payAdjustmentsEnabled && (
                  <CountrySpecific countries={[SUPPORTED_COUNTRY_CODES.GB]}>
                    <WorkerReferralBonuses workerId={data.id} />
                  </CountrySpecific>
                )}
                {workerExpensesEnabled && (
                  <CountrySpecific countries={[SUPPORTED_COUNTRY_CODES.GB]}>
                    <WorkerExpenses workerId={data.id} />
                  </CountrySpecific>
                )}
              </div>
            )}
            <WorkerDetailControls data={data} isPristine={isPristine} />
          </Col>
        </Row>
      </Grid>
      <BanWorkerModal userID={data.user_id} workerID={data.id} />
      <MarketingPreferencesModal workerID={data.id} />
    </SiteComponent>
  )
}

export default entityDetail('workers', flowRight(storeConnector)(WorkerDetail))
