import React, { useEffect } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Grid, Col, Row } from 'react-bootstrap'
import { TrackingTrigger } from '@indeed/flex-tracking-context'

import entityDetail from 'syft-acp-util/entityDetail'
import SiteComponent from 'syft-acp-core/components/SiteComponent'
import RecordSection from 'syft-acp-core/components/RecordSection'

import * as timeOffRequestActions from 'syft-acp-core/store/timeOffRequests/actions'

import TimeOffRequestDetailControls from './TimeOffRequestDetailControls'
import { TimeOffRequestDetailTrackingProvider } from './TimeOffRequestDetailTrackingProvider'
import { TimeOffRequestDetailProps as Props } from './TimeOffRequestDetail.types'
import { dataStructure } from './TimeOffRequestDetail.constants'
import { trackingEvents } from './TimeOffRequestDetail.tracking'

export const storeConnector = connect(null, dispatch => ({
  actions: bindActionCreators(timeOffRequestActions, dispatch),
}))

const TimeOffRequestDetail = ({ id, actions, actionUpdate, data, isPristine }: Props) => {
  useEffect(() => {
    actions.fetchTimeOffRequest(id)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <TimeOffRequestDetailTrackingProvider timeOffRequestId={id}>
      <SiteComponent>
        <Grid>
          <Row>
            <Col md={12} sm={12}>
              <TimeOffRequestDetailControls id={id} data={data} isPristine={isPristine} />
              {dataStructure(data).map(({ title, structure }) => (
                <RecordSection
                  key={title}
                  title={title}
                  data={data}
                  structure={structure}
                  update={actionUpdate}
                />
              ))}
            </Col>
          </Row>
        </Grid>
      </SiteComponent>
      <TrackingTrigger onLoad={{ event: trackingEvents.PAGE.VIEWED }} />
    </TimeOffRequestDetailTrackingProvider>
  )
}

export default storeConnector(entityDetail('timeOffRequests', TimeOffRequestDetail))
