import * as React from 'react'
import { isFunction, omit } from 'lodash-es'

import RecordControls from 'syft-acp-core/components/RecordSection/RecordControls'
import { ButtonLink } from 'syft-acp-atoms/Button'

import Button from '../actionComponents/Button'
import ButtonDropdown from '../actionComponents/ButtonDropdown'
import { decorateOnClick } from '../actionComponents/selection'
import FilterGroup from '../../AcpTable/filterComponents/FilterGroup'
import { GenericComponent, FormActionWrapper, FormFilterWrapper } from './RecordWrappers'

/** Adds the callback arguments (table data, selection state) to an onClick handler. */
const addCallbackArgs = (callbackArgs, tableDataArgs, subDataSpec, el) => {
  const { tableData, stateSelection, stateSubSelection } = tableDataArgs
  return decorateOnClick(el.onClick, null, callbackArgs, subDataSpec, tableData, stateSelection, stateSubSelection)
}

// Make sure there's a space before each button.
const iterateElements =
  (ComponentElement, ComponentWrapper) =>
  (arr, callbackArgs = {}, tableDataArgs = {}, subDataSpec, passOnArgs = false) =>
    arr.map((el, n) => {
      if (!el) return null
      // Add our passed arguments to onClick functions if they exists.
      const hasOnClick = el.onClick && isFunction(el.onClick)
      const hasMaxWidth = el.maxWidth // If child is max width, also make container max width
      const hasBreak = el.isBreak // If child is a line break, make element display inline
      const modifiedOnClick = hasOnClick ? addCallbackArgs(callbackArgs, tableDataArgs, subDataSpec, el) : el.onClick
      // Pass on componentArgs and callbackArgs if needed.
      // Note: Button (et al.) pass on most of what it gets to the DOM, hence this is optional.
      const passedArgs = passOnArgs ? { componentArgs: tableDataArgs, callbackArgs } : {}
      return (
        <ComponentWrapper key={n} maxWidth={hasMaxWidth} inline={hasBreak}>
          {/* Add the new onClick function, remove the original one, and remove extra attributes generated from the table definition. */}
          <ComponentElement
            {...(hasOnClick ? { onClick: modifiedOnClick } : {})}
            {...omit(el, [
              'onClick',
              '_type',
              '_typeGroup',
              ...((ComponentElement === React.Fragment && ['_node', '_hasForcedHeader']) || []),
            ])}
            {...passedArgs}
            subDataSpec={subDataSpec}
          />
        </ComponentWrapper>
      )
    })

export const iterateGenericComponents = iterateElements(GenericComponent, React.Fragment)
export const iterateFilters = iterateElements(FilterGroup, FormFilterWrapper)
export const iterateButtons = iterateElements(Button, FormActionWrapper)
export const iterateButtonLinks = iterateElements(ButtonLink, FormActionWrapper)
export const iterateButtonDropdowns = iterateElements(ButtonDropdown, FormActionWrapper) // Helper functions that display every button or filter attached to the form.
export const iterateControlGroup = iterateElements(React.Fragment, RecordControls)
