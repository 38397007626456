import { apiRequest, handleCall } from 'syft-acp-core/api/call'

/**
 * Retrieves a list of overpayments. Implements pagination.
 */
export const listOverpayments = ({ options = {}, toCSV }) =>
  new Promise((resolve, reject) => {
    // Default to page 1. For some reason we need to have this explicit.
    const page = options.page ? options.page : 1
    const req = apiRequest({ path: `/admin/overpayments`, reqArgs: { ...options, page }, returnAll: toCSV })
    handleCall(req, resolve, reject, true, options, null, toCSV, 'overpayments')
  })

/**
 * Saves a new overpayment deduction or updates an existing one.
 */
export const saveOverpayment = ({ data: reqArgs }) =>
  new Promise((resolve, reject) => {
    // If the data contains an id value, the data will be updated instead.
    const req = apiRequest({ path: '/admin/overpayments', reqArgs, method: 'POST' })
    handleCall(req, resolve, reject, true)
  })
