import { createSelector } from 'reselect'
import type { BankHolidayRegionsEntity } from './types'

type State = {
  bankHolidayRegions: { entityMap: Record<string, BankHolidayRegionsEntity> }
}

export const selectBankHolidayRegions = (state: State) => state.bankHolidayRegions

export const selectBankHolidayRegionOptions = createSelector(selectBankHolidayRegions, ({ entityMap }) =>
  Object.values<BankHolidayRegionsEntity>(entityMap).map(({ name, id }) => ({
    label: name,
    id,
  }))
)
