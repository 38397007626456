import { get } from 'lodash-es'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { notify } from 'syft-acp-core/actions/notifications'
import { reqDefaults } from 'syft-acp-core/api/call'
import { apiURL } from 'syft-acp-core/api/endpoints'
import { selectShiftUnpaidTimes$, selectShiftWorkers$ } from 'syft-acp-core/reducers/listing-shift-bookings'
import * as listingShiftsActions from 'syft-acp-core/store/listing-shifts/actions'
import MissingTimeManager from './MissingTimeManager'

/**
 * This class contains the 'missing time' component used to issue corrections for workers.
 *
 * The purpose of missing time is to give extra payment to workers who were sent home
 * early for some reason.
 *
 * When assigned missing time, a worker will get extra payment the next time payments
 * get sent out by the system.
 */
class ListingMissingTime extends React.PureComponent {
  static propTypes = {
    listingID: PropTypes.number.isRequired,
    shiftID: PropTypes.number.isRequired,
    shiftUnpaidTimes: PropTypes.array.isRequired,
    dispatch: PropTypes.func,
  }

  state = {
    isDeletingItem: false,
  }

  reloadData = () => {
    const { listingID, shiftID, actions } = this.props
    actions.fetchAdminShiftListing(listingID, shiftID)
  }

  notifyModification = type => {
    const { dispatch } = this.props

    if (type === 'errorDeleteUndeletable') {
      dispatch(
        notify('error', {
          title: 'Could not remove missing time',
          message: 'This item has already been paid out and cannot be deleted.',
        })
      )
    }
  }

  deleteItem = async ({ shiftID, workerID, entry }) => {
    this.setState({ isDeletingItem: true })
    const newData = {
      entries: [
        {
          shift_id: shiftID,
          worker_id: workerID,
          unpaid_times: [{ ...entry, _destroy: true }],
        },
      ],
    }
    await this.submitData(newData, true)
    this.setState({ isDeletingItem: false })
    this.reloadData()
  }

  // Submits data to the missing time endpoint.
  submitData = (data, isDeleting = false) =>
    new Promise(async resolve => {
      const { dispatch } = this.props
      try {
        const req = new Request(apiURL('/admin/timesheets/bulk_edit'), {
          ...reqDefaults('PUT', 'application/json'),
          body: JSON.stringify(data),
        })
        const result = await fetch(req)

        if (result.ok) {
          if (isDeleting) {
            dispatch(
              notify('success', {
                title: 'Removed missing time',
                message: 'Removed the selected item.',
              })
            )
          } else {
            dispatch(
              notify('success', {
                title: 'Added missing time',
                message: 'Scheduled the worker to be sent extra pay per the entered values.',
              })
            )
          }
          return resolve(true)
        } else {
          // Successful POST, but something went wrong after all.
          const json = JSON.parse(await result.text())
          dispatch(
            notify('error', {
              title: `Could not ${isDeleting ? 'delete' : 'add'} missing time`,
              message: get(json, 'error_description', 'Check the error page for more details.'),
              autoDismiss: 0,
            })
          )
          return resolve(false)
        }
      } catch (err) {
        // Could not successfully POST.
        dispatch(
          notify('error', {
            title: `Could not ${isDeleting ? 'delete' : 'add'} missing time`,
            message: 'Something went wrong while sending data to the API. See the error page for more details.',
            autoDismiss: 0,
          })
        )
        return resolve(false)
      }
    })

  render() {
    const { shiftUnpaidTimes, shiftID, isLoading, availableWorkers } = this.props
    const { isDeletingItem } = this.state
    const isLoadingRn = isLoading || isDeletingItem
    return (
      <MissingTimeManager
        availableWorkers={availableWorkers}
        deleteItem={this.deleteItem}
        isLoading={isLoadingRn}
        notifyModification={this.notifyModification}
        reloadData={this.reloadData}
        shiftID={shiftID}
        shiftUnpaidTimes={shiftUnpaidTimes}
      />
    )
  }
}

export default connect(
  (state, ownProps) => ({
    isLoading: state.listingShifts.isLoadingData,
    shiftUnpaidTimes: selectShiftUnpaidTimes$(state)({ shiftID: ownProps.shiftID }),
    availableWorkers: selectShiftWorkers$(state)({ shiftID: ownProps.shiftID }),
  }),
  dispatch => ({
    dispatch,
    actions: bindActionCreators(listingShiftsActions, dispatch),
  })
)(ListingMissingTime)
