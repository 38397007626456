import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import * as jobsActions from 'syft-acp-core/store/jobs/actions'
import DataRating from 'syft-acp-core/components/EditableTable/DataRating'
import { useTrackingTrigger } from '@indeed/flex-tracking-context'
import { trackingEvents } from './tracking'

import { ListingRatingsDataRatingProps } from './types'

const ListingRatingsDataRating = ({
  jobID,
  stars = null,
  generated = false,
  worker,
  actions,
}: ListingRatingsDataRatingProps) => {
  const triggerEvent = useTrackingTrigger()

  const updateRating = (value: number) => {
    if (value === 0) {
      triggerEvent(trackingEvents.LISTING_WORKER_INFO.WORKER_RATING.DELETED, {
        value,
      })
      return actions.deleteRating(jobID, worker.id)
    }
    triggerEvent(trackingEvents.LISTING_WORKER_INFO.WORKER_RATING.CHANGED, {
      value,
    })
    return actions.rateWorker(jobID, worker.id, value)
  }

  return <DataRating notRatedYet={generated} amount={stars} onChange={updateRating} editable />
}

export default connect(null, dispatch => ({
  actions: bindActionCreators(jobsActions, dispatch),
}))(ListingRatingsDataRating)
