// Syft ACP - Lib <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import React, { useCallback, useEffect } from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import * as filtersActions from 'syft-acp-core/store/filters/actions'
import DatePicker from 'syft-acp-atoms/DatePicker'

import FilterUnit from './FilterUnit'

const FilterDate = ({ name, actions, query, defaultValue = {}, ...rest }) => {
  const handleChange = useCallback((fieldName, value) => actions.setFilter(fieldName, value), [actions])

  useEffect(() => {
    if (!query[name] && defaultValue) {
      handleChange(name, defaultValue)
    }
  }, [query, defaultValue, handleChange, name])

  return (
    <FilterUnit>
      <DatePicker name={name} value={query[name]} defaultValue={defaultValue} onChange={handleChange} {...rest} />
    </FilterUnit>
  )
}

FilterDate.propTypes = {
  name: PropTypes.string.isRequired,
  actions: PropTypes.objectOf(PropTypes.func).isRequired,
  query: PropTypes.object.isRequired,
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
}

export default connect(
  state => ({
    query: state.routing.locationBeforeTransitions.query,
  }),
  dispatch => ({
    actions: bindActionCreators(filtersActions, dispatch),
  })
)(FilterDate)
