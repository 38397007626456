// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import { ReducerWithInitialState } from '@reduxjs/toolkit/dist/createReducer'

import entityReducer from 'syft-acp-core/reducers/generators/entityReducer'

import * as types from './action-types'
import { GenericAction, ImportFileState } from '../types'

export const beginImportFileState = (state: ImportFileState, action: GenericAction) => ({
  ...state,
  message: '',
  isLoadingData: true,
  meta: action.meta,
})

export const failedImportFileState = (state: ImportFileState, action: GenericAction) => ({
  ...state,
  isLoadingData: false,
  message: action.message || action.payload.message,
})

export const successImportFileState = (state: ImportFileState, action: GenericAction) => ({
  ...state,
  data: action.payload,
  isLoadingData: false,
  hasData: true,
  message: '',
  lastImport: Date.now(),
})

export default entityReducer('importFile', {
  [types.IMPORT_FILE_BEGIN]: beginImportFileState,
  [types.IMPORT_FILE_SUCCEEDED]: successImportFileState,
  [types.IMPORT_FILE_FAILED]: failedImportFileState,
}) as ReducerWithInitialState<ImportFileState>
