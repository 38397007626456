import React from 'react'
import { FilterAutocompleteMultiSelect } from 'syft-acp-util/components/FilterForm'
import { fetchTimeOffRequestEmployers } from 'syft-acp-core/store/timeOffRequests/api'
import { getEmployerName } from 'syft-acp-util/formatting'
import { FilterObjectType } from 'syft-acp-util/components/FilterForm/FilterAutocompleteMultiSelect/FilterAutocompleteMultiSelect.types'

const mapFetchedEmployers = async (employer: string) =>
  fetchTimeOffRequestEmployers(employer).then(result =>
    result.payload.map(
      data =>
        ({
          value: data.id,
          label: getEmployerName(data),
        } as FilterObjectType),
    ),
  )

const FilterTimeOffRequestEmployer = () => {
  return <FilterAutocompleteMultiSelect name="employer_ids" queryFunction={mapFetchedEmployers} />
}

export default FilterTimeOffRequestEmployer
