// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited
import { max, map, keyBy, uniq, isEmpty } from 'lodash-es'
import { convertAlertsToDates, filterAlertsByCutoff } from 'syft-acp-core/store/filters/helpers'
import * as types from 'syft-acp-core/actions/action-types'
import { ADMIN_ALERTS_FETCH_BEGIN, ADMIN_ALERTS_FETCH_FAILED, ADMIN_ALERTS_FETCH_SUCCEEDED, ADMIN_ALERTS_MARK_AS_NOTIFIED } from './action-types'
import { Action } from './types'

// Returns the current date as an ISO string.
export const latestDate = () => new Date().toISOString()

export const initialState = {
  hasData: false,
  lastUpdated: latestDate(),
  isLoadingData: false,
  message: '',
  latestID: null,
  // IDs that we've shown a notification box for.
  notifiedIDs: [],
  entityMap: {},
  entityList: [],
}

export const rehydrateReset = {
  isLoadingData: false,
  message: '',
}

const reducer = (state = initialState, action: Action = {}) => {
  switch (action.type) {
    case types.AUTH_DELETE:
      return initialState
    case types.REHYDRATION_COMPLETE:
      return {
        ...state,
        // Transform created_at strings back to Date objects.
        entityMap: convertAlertsToDates(state.entityMap),
        ...rehydrateReset,
      }
    case ADMIN_ALERTS_FETCH_BEGIN:
      return {
        ...state,
        message: '',
        isLoadingData: true,
        lastUploadedImage: {},
      }
    case ADMIN_ALERTS_FETCH_SUCCEEDED: {
      // Get new IDs, sorted descending. Ensure we have unique values only.
      // Also, we're removing all old IDs that are more than an hour old.
      const payloadData = convertAlertsToDates(action.payload)

      // Get the latest ID, before we filter anything out.
      const latestID = max(map(action.payload, 'id')) || state.latestID
      const entities = filterAlertsByCutoff({ ...state.entityMap, ...keyBy(payloadData, 'id') })
      const entityIDs = map(entities, 'id')

      return {
        ...state,
        latestID,
        // If notified IDs is completely empty, fill it with the IDs we've received.
        // This prevents the user from getting a massive amount of notifications
        // on the initial call.
        notifiedIDs: isEmpty(state.notifiedIDs) ? entityIDs : state.notifiedIDs,
        isLoadingData: false,
        hasData: true,
        message: '',
        entityList: entityIDs,
        entityMap: entities,
        lastUpdated: latestDate(),
      }
    }
    case ADMIN_ALERTS_FETCH_FAILED:
      return {
        ...state,
        isLoadingData: false,
        lastUpdated: latestDate(),
        message: action?.payload?.message,
      }
    case ADMIN_ALERTS_MARK_AS_NOTIFIED:
      return {
        ...state,
        notifiedIDs: uniq([...state.notifiedIDs, ...(action?.ids || [])])
      }
    default:
      return state
  }
}

export default reducer

