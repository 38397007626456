// Syft ACP - Lib <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import React from 'react'
import PropTypes from 'prop-types'
import { uniqueId } from 'lodash-es'
import ReactTooltip from 'react-tooltip'

import { ButtonDropdown, MenuItem } from 'syft-acp-atoms/Button'

const tooltipIdDisabled = 'disabledDropdownTooltip'

const ActionDropdown = ({ label, items, disabled, buttonType, disabledTooltip }) => {
  const renderDropdown = () => (
    <ButtonDropdown
      id={uniqueId('button-dropdown-')}
      kind={buttonType}
      title={label}
      disabled={disabled}
      pullRight
    >
      {items.map((item, n) => {
        if (item.isDivider) {
          return <MenuItem divider key={n} />
        }
        if (item.isHeader) {
          return (
            <MenuItem header key={n}>
              {item.headerText}
            </MenuItem>
          )
        }
        const tooltipID = 'disabled_option_tooltip'
        return (
          <MenuItem disabled={item.disabled} key={n} onClick={item.action} className={item.className}>
            {item.disabled && item.disabledTooltip ? (
              <>
                <ReactTooltip effect="solid" place="bottom" id={tooltipID} />
                <span data-tip={item.disabledTooltip} data-for={tooltipID}>
                  {item.label}
                </span>
              </>
            ) : (
              item.label
            )}
          </MenuItem>
        )
      })}
    </ButtonDropdown>
  )
  if (disabled) {
    return (
      <>
        <ReactTooltip effect="solid" place="bottom" id={tooltipIdDisabled} />
        <span data-tip={disabledTooltip} data-for={tooltipIdDisabled}>
          {renderDropdown()}
        </span>
      </>
    )
  }
  return renderDropdown()
}

ActionDropdown.propTypes = {
  label: PropTypes.string.isRequired,
  buttonType: PropTypes.string,
  disabledTooltip: PropTypes.string,
  items: PropTypes.array,
  disabled: PropTypes.bool,
}

ActionDropdown.defaultProps = {
  items: [],
  disabled: false,
  buttonType: 'regular',
  disabledTooltip: null,
}

export default ActionDropdown
