import { takeLatest } from 'redux-saga/effects'

import { takeLatestApiCall } from 'syft-acp-core/sagas/helpers'
import { notificationCall, redirectCall } from 'syft-acp-core/sagas/calls'

import * as api from './api'
import * as types from './action-types'
import { CreateWorkerStrikeSucceededAction, DeleteWorkerStrikeSucceededAction } from './actions'

export default function* root() {
  yield takeLatestApiCall('WORKER_STRIKES_FETCH', api.fetchWorkerStrikes)
  yield takeLatestApiCall('WORKER_STRIKE_FETCH', api.fetchWorkerStrike)
  yield takeLatestApiCall('WORKER_STRIKE_UPDATE', api.updateWorkerStrike)
  yield takeLatestApiCall('WORKER_STRIKE_CREATE', api.createWorkerStrike)
  yield takeLatestApiCall('WORKER_STRIKE_DELETE', api.deleteWorkerStrike)
  yield takeLatestApiCall(types.WORKER_STRIKE_REASONS_FETCH_BEGIN, api.fetchWorkerStrikeReasons)
  yield takeLatest(
    types.WORKER_STRIKE_CREATE_SUCCEEDED,
    redirectCall,
    ({ payload: { id }, request: { workerID } }: CreateWorkerStrikeSucceededAction) =>
      `/entity/workers/view/${workerID}/strikes/view/${id}`
  )
  yield takeLatest(
    types.WORKER_STRIKE_DELETE_SUCCEEDED,
    redirectCall,
    ({ request }: DeleteWorkerStrikeSucceededAction) => `/entity/workers/view/${request.workerID}/strikes/list`
  )
  yield takeLatest(
    [
      types.WORKER_STRIKE_CREATE_SUCCEEDED,
      types.WORKER_STRIKE_DELETE_SUCCEEDED,
      types.WORKER_STRIKE_UPDATE_SUCCEEDED,
      types.WORKER_STRIKE_CREATE_FAILED,
    ],
    notificationCall
  )
}
