import React, { useCallback } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import 'moment-duration-format'
import DataText from 'syft-acp-core/components/EditableTable/DataText'
import EntityListInputWrapper from 'syft-acp-core/components/EntityList/EntityListInputWrapper'
import { updateBooking, updateBookingChanges } from 'syft-acp-core/actions/bookings'

import { Props } from './ListingPayrateInput.types'
export const storeConnector = connect(null, dispatch => ({
  actions: {
    ...bindActionCreators({ updateBooking, updateBookingChanges }, dispatch),
  },
}))

export const ListingPayrateInput = ({ id, attr, value, placeholder, disabled, actions }: Props) => {
  const parsedValue = !isNaN(Number(value))
    ? Number(value).toFixed(Math.max(2, (value?.toString().split('.')[1] || []).length))
    : '–'

  const handleOnChange = useCallback(
    (newValue: number) => {
      actions.updateBooking({ id, attr, val: newValue })
      actions.updateBookingChanges({ id, attr, val: newValue })
    },
    [id, attr, actions],
  )

  return (
    <EntityListInputWrapper>
      <DataText
        onChange={handleOnChange}
        value={parsedValue}
        editable
        header={placeholder}
        disabled={disabled}
      />
    </EntityListInputWrapper>
  )
}

export default storeConnector(ListingPayrateInput)
