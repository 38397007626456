// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import * as types from 'syft-acp-core/actions/action-types'
import { takeLatest } from 'redux-saga/effects'
import { fetchDocumentTypes } from 'syft-acp-core/api/resources/documents'
import { takeEveryApiCall } from './helpers'
import { notificationCall } from './calls'

export default function* root() {
  yield takeEveryApiCall('WORKER_DOCUMENT_TYPES_FETCH', fetchDocumentTypes)
  yield takeLatest(
    [
      types.WORKER_DOCUMENT_DELETE_SUCCEEDED,
      types.WORKER_DOCUMENT_DELETE_FAILED,
      types.WORKER_DOCUMENT_SAVE_SUCCEEDED,
      types.WORKER_DOCUMENT_SAVE_FAILED,
    ],
    notificationCall
  )
}
