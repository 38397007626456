// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

/** Default data type - simply displays the value as string. */
export const TXT = 'ENTITYLIST_TYPE_TXT'
/** Mono - displays as a string with monospaced text. */
export const MONO = 'ENTITYLIST_TYPE_MONO'
/** A start/end duration formatter. */
export const START_END = 'ENTITYLIST_TYPE_START_END'
/** Picture - used mainly for workers' profile photos. */
export const IMAGE = 'ENTITYLIST_TYPE_IMAGE'
/** UUID - shortened form of UUID. */
export const UUID = 'ENTITYLIST_TYPE_UUID'
/** Money - formatted currency string. */
export const MONEY = 'ENTITYLIST_TYPE_MONEY'
/** Role - name of a role by ID. */
export const ROLE = 'ENTITYLIST_TYPE_ROLE'
/** Skill - name of a skill by ID (and role_id). */
export const SKILL = 'ENTITYLIST_TYPE_SKILL'
/** Venue - name of a venue by ID. */
export const VENUE = 'ENTITYLIST_TYPE_VENUE'
/** Employer - company name of an employer by ID. */
export const AGENCY_EMPLOYER = 'ENTITYLIST_TYPE_AGENCY_EMPLOYER'
/** Link - link to a URL or file. */
export const LINK = 'ENTITYLIST_TYPE_LINK'
export const LINK_FILE = 'ENTITYLIST_TYPE_LINK_FILE' // This and below are used in 'linkType'.
export const LINK_SITE = 'ENTITYLIST_TYPE_LINK_SITE'
/** Selector - displays a checkbox that allows you to select a row. */
export const SELECTOR = 'ENTITYLIST_TYPE_SELECTOR'
/** Enum - expects a value from a set, and displays the label associated with that value. */
export const ENUM = 'ENTITYLIST_TYPE_ENUM'
/** Icon - displays an icon if a condition is met. */
export const ICON = 'ENTITYLIST_TYPE_ICON'
/** Bool - displays an icon if the value is true. Simpler version of ICON. */
export const BOOL = 'ENTITYLIST_TYPE_BOOL'
/** Rating - shows a number of stars (or some other character) for a float value [0..5]. */
export const RATING = 'ENTITYLIST_TYPE_RATING'
/** Datetime - displays a formatted timestamp. */
export const DATETIME = 'ENTITYLIST_TYPE_DATETIME'
/** DatetimeRel - displays a formatted timestamp with relative time. */
export const DATETIME_REL = 'ENTITYLIST_TYPE_DATETIME_REL'
/** Date - displays a formatted timestamp. */
export const DATE = 'ENTITYLIST_TYPE_DATE'
/** Time - displays a formatted timestamp. */
export const TIME = 'ENTITYLIST_TYPE_TIME'
/** Duration - displays a timestamp as duration, e.g. '2 days' for 17280000. */
export const DURATION = 'ENTITYLIST_TYPE_DURATION'
/** SUBROW - similar to table > tr html element */
export const SUBROW = 'ENTITYLIST_TYPE_SUBROW'
/** SUBROW selector - selectors for sub-rows */
export const SUBROW_SELECTOR = 'ENTITYLIST_TYPE_SUBROW_SELECTOR'
/** UserLine component */
export const USER_LINE = 'ENTITYLIST_TYPE_USER_LINE'
/** CustomComponent */
export const CUSTOM_COMPONENT = 'ENTITYLIST_TYPE_CUSTOM_COMPONENT'
/** Industries list */
export const INDUSTRIES = 'ENTITYLIST_TYPE_INDUSTRIES'
/** Manager by ID */
export const MANAGER = 'ENTITYLIST_TYPE_MANAGER'
/** City by ID */
export const CITY = 'ENTITYLIST_TYPE_CITY'
/** Worker status tags list */
export const WORKER_TAGS = 'ENTITYLIST_TYPE_WORKER_TAGS'
export const WORKER_VIDEOS_AND_ASSESSMENTS_TAGS = 'ENTITYLIST_TYPE_WORKER_VIDEOS_AND_ASSESSMENTS_TAGS'
export const WORKER_FORMI9_TAGS = 'ENTITYLIST_TYPE_WORKER_FORMI9_TAGS'
export const WORKER_PAYCOM_TAGS = 'ENTITYLIST_TYPE_WORKER_PAYCOM_TAGS'
/** Custom tags list */
export const TAGS = 'ENTITYLIST_TYPE_TAGS'
/** Worker status tags list */
export const EMAIL = 'ENTITYLIST_TYPE_EMAIL'
/** Formatted phone number */
export const PHONE_NUMBER = 'ENTITYLIST_TYPE_PHONE_NUMBER'
/** Button linking to another page */
export const BUTTON_LINK = 'ENTITYLIST_TYPE_BUTTON_LINK'

/** Sorting directions. */
export const SORT_ASC = 'SORT_ASC'
export const SORT_DESC = 'SORT_DESC'

/** CSS classes used to target table cells of specific types. */
export const typeClasses = {
  [TXT]: 'txt',
  [MONO]: 'mono',
  [UUID]: 'uuid',
  [MONEY]: 'money',
  [ROLE]: 'role',
  [SKILL]: 'skill',
  [IMAGE]: 'image',
  [LINK]: 'link',
  [SELECTOR]: 'selector',
  [ENUM]: 'enum',
  [ICON]: 'icon',
  [BOOL]: 'bool',
  [RATING]: 'rating',
  [DATETIME]: 'datetime',
  [DATETIME_REL]: 'datetime-rel',
  [DATE]: 'date',
  [DURATION]: 'duration',
  [SUBROW]: 'sub-row',
  [SUBROW_SELECTOR]: 'selector',
  [USER_LINE]: 'user-line',
  [CUSTOM_COMPONENT]: 'data-custom-component',
  [INDUSTRIES]: 'industries',
  [WORKER_TAGS]: 'tags',
  [WORKER_FORMI9_TAGS]: 'tags',
  [WORKER_PAYCOM_TAGS]: 'tags',
  [WORKER_VIDEOS_AND_ASSESSMENTS_TAGS]: 'tags',
}

/** Subrow types; these are treated differently in table HTML generation. */
export const subRowTypes = [SUBROW, SUBROW_SELECTOR]
