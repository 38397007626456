// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import React from 'react'
import PropTypes from 'prop-types'
import { times, constant } from 'lodash-es'
import ReactTooltip from 'react-tooltip'
import classnames from 'classnames'

import './DataRating.css'

const STARS_SIZE = 5

class DataRating extends React.Component {
  static propTypes = {
    amount: PropTypes.number,
    className: PropTypes.string,
    showAmount: PropTypes.bool,
    editable: PropTypes.bool,
    notRatedYet: PropTypes.bool,
    onChange: PropTypes.func,
  }

  static defaultProps = {
    showAmount: false,
    amount: 0,
    editable: false,
    notRatedYet: false,
    onChange: () => null,
    className: '',
  }

  constructor(props) {
    super(props)
    this.state = {}
  }

  getAmount = () => {
    let { amount } = this.props

    // Ensure amount is shown as 0.5 stars if it's 0.
    // This is to distinguish it from the null value.
    if (amount === 0) {
      amount = 0.5
    }

    if (this.state.isMouseIn) {
      return times(this.state.onHoverAmount, constant('whole'))
    } else {
      // If amount is null, just an empty list is fine because we don't need to display anything.
      if (!amount) {
        return []
      }
      const val = amount
      const valWhole = Math.floor(amount)
      if (val === valWhole) {
        return times(valWhole, constant('whole'))
      } else {
        return [...times(valWhole, constant('whole')), ...times(Math.ceil(val - valWhole), constant('half'))]
      }
    }
  }

  handleMouseMove = () => {
    if (!this.props.editable) return
    this.setState({
      isMouseIn: true,
    })
  }

  handleStarMouseMove = i => () => {
    if (!this.props.editable) return
    this.setState({
      onHoverAmount: i,
    })
  }

  handleMouseLeave = () => {
    if (!this.props.editable) return
    this.setState({
      isMouseIn: false,
      onHoverAmount: 0,
    })
  }

  removeIcon = () => (
    <div>
      <ReactTooltip effect="solid" id="remove-stars-tooltip" className="remove-stars-tooltip" />
      <span
        onClick={this.handleClick(0)}
        data-tip="Remove stars"
        className="remove-stars-icon"
        data-for="remove-stars-tooltip"
        data-testid="remove-stars"
      >
        ✕
      </span>
    </div>
  )

  handleClick = i => () => {
    if (!this.props.editable) return
    this.props.onChange(i)
  }

  render() {
    return (
      <div
        className={classnames('data-rating', 'plain-text', this.props.className, {
          'not-rated-yet': this.props.notRatedYet,
        })}
      >
        {this.props.editable ? this.removeIcon() : null}
        <span className="rows" onMouseMove={this.handleMouseMove} onMouseLeave={this.handleMouseLeave}>
          <span className="bg">
            {times(STARS_SIZE).map(i => (
              <i onMouseMove={this.handleStarMouseMove(i + 1)} key={String(i)}>
                ★
              </i>
            ))}
          </span>
          <span className="fg">
            {this.getAmount().map((type, i) => (
              <i
                className={type}
                onMouseMove={this.handleStarMouseMove(i + 1)}
                onClick={this.handleClick(i + 1)}
                data-testid={`star-${i}`}
                key={String(i)}
              >
                ★
              </i>
            ))}
          </span>
        </span>
        {this.props.showAmount ? <span className="value">{Number(this.props.amount).toFixed(1)}</span> : null}
      </div>
    )
  }
}

export default DataRating
