import { DefaultRootState } from 'react-redux'
import { createSelector } from 'reselect'
import { isWithin24Hours } from 'syft-acp-util/time'

const selectDismissedAlerts = (state: DefaultRootState) => state.dismissibleAlerts?.dismissed

const selectAlertId = (_state: DefaultRootState, alertId: string) => alertId

export const selectIsAlertDismissed = createSelector(
  [selectAlertId, selectDismissedAlerts],
  (alertId, dismissedState) =>
    !!dismissedState.find(
      ({ id, dismissed_at: dismissedAt }) =>
        // Only return if alert was dismissed less than 24 hours ago
        id === alertId && isWithin24Hours(dismissedAt, new Date().toISOString()),
    ),
)
