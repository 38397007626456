// Syft ACP - Lib <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited
// Disabling react/no-unused-prop-types because they're used dynamically.
/* eslint-disable react/no-unused-prop-types */

import React from 'react'
import PropTypes from 'prop-types'
import { param } from 'change-case'

import './Table.css'

// CSS classes that are derived directly from passed props.
const cssClasses = [
  'altRows',
  'altCols',
  'hover',
  'colBorders',
  'colGroupBorders',
  'bordered',
  'rounded',
  'responsive',
  'noRightBorder',
  'noRowBorders',
  'minimal',
  'noLastRowBorder',
  'topRowBorder',
  'noBottomMargin',
  'noPadding',
  'blackText',
]

const Table = props => {
  // Derive list of CSS classes from props.
  const classes = Object.keys(props)
    .filter(item => props[item] !== false)
    .filter(item => cssClasses.indexOf(item) > -1)
    .map(item => param(item))
    .join(' ')

  if (props.responsive) {
    // Return the table wrapped in a <div> that helps with responsiveness.
    return (
      <div className="table-responsive">
        <table data-testid="entity-list-table" className={`table ${props.className ? props.className : ''} ${classes}`}>
          {props.children}
        </table>
      </div>
    )
  } else {
    // Return a plain <table>.
    return (
      <table data-testid="entity-list-table" className={`table ${props.className ? props.className : ''} ${classes}`}>
        {props.children}
      </table>
    )
  }
}
Table.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  altRows: PropTypes.bool,
  altCols: PropTypes.bool,
  hover: PropTypes.bool,
  colBorders: PropTypes.bool,
  colGroupBorders: PropTypes.bool,
  bordered: PropTypes.bool,
  rounded: PropTypes.bool,
  noLastRowBorder: PropTypes.bool,
  topRowBorder: PropTypes.bool,
  responsive: PropTypes.bool,
  noRightBorder: PropTypes.bool,
  noRowBorders: PropTypes.bool,
  noBottomMargin: PropTypes.bool,
  minimal: PropTypes.bool,
}

export default Table
