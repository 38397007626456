// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import * as entityTypes from 'syft-acp-core/reducers/generators/entities'
import entityReducer, { GenericState } from 'syft-acp-core/reducers/generators/entityReducer'

import { AnyAction } from 'redux'
import * as types from '../action-types'
import { WorkerEntity } from './types'

const workerUpdateSucceeded = (state: GenericState<WorkerEntity>, action: AnyAction) => ({
  ...state,
  entityDetail: {
    ...state.entityDetail,
    [action.payload.id]: {
      ...state.entityDetail[action.payload.id],
      status: action.payload.status,
    },
  },
  entityMap: {
    ...state.entityMap,
    [action.payload.id]: {
      ...state.entityMap[action.payload.id],
      status: action.payload.status,
    },
  },
  skipLocalDataUpdating: false, // rerender page with new statuses
})

const workerUpdateFailed = (state: GenericState<WorkerEntity>, action: AnyAction) => ({
  ...state,
  message: action.payload.message,
})

const workerGeneralPreferencesFetchSucceeded = (state: GenericState<WorkerEntity>, action: AnyAction) => ({
  ...state,
  entityDetail: {
    ...state.entityDetail,
    [action.payload.worker_id]: {
      ...state.entityDetail[action.payload.worker_id],
      general_preferences: action.payload,
    },
  },
})

export default entityReducer<WorkerEntity>(
  'workers',
  {
    [entityTypes.ENTITIES_FETCH_BEGIN]: types.WORKERS_FETCH_BEGIN,
    [entityTypes.ENTITIES_FETCH_SUCCEEDED]: types.WORKERS_FETCH_SUCCEEDED,
    [entityTypes.ENTITIES_FETCH_FAILED]: types.WORKERS_FETCH_FAILED,
    [entityTypes.ENTITY_FETCH_BEGIN]: types.WORKER_FETCH_BEGIN,
    [entityTypes.ENTITY_FETCH_SUCCEEDED]: types.WORKER_FETCH_SUCCEEDED,
    [entityTypes.ENTITY_FETCH_FAILED]: types.WORKER_FETCH_FAILED,
    [entityTypes.ENTITY_SAVE_BEGIN]: types.WORKER_SAVE_BEGIN,
    [entityTypes.ENTITY_SAVE_SUCCEEDED]: types.WORKER_SAVE_SUCCEEDED,
    [entityTypes.ENTITY_SAVE_FAILED]: types.WORKER_SAVE_FAILED,
    [entityTypes.ENTITY_MODIFY_SUCCEEDED]: types.WORKER_MODIFY_SUCCEEDED,
    [entityTypes.ENTITY_DELETE_BEGIN]: types.WORKER_USER_DELETE_BEGIN,
    [entityTypes.ENTITY_DELETE_SUCCEEDED]: types.WORKER_USER_DELETE_SUCCEEDED,
    [entityTypes.ENTITY_DELETE_FAILED]: types.WORKER_USER_DELETE_FAILED,
    [types.WORKER_GENERAL_PREFERENCES_FETCH_SUCCEEDED]: workerGeneralPreferencesFetchSucceeded,
    [types.WORKER_TYPE_UPDATE_SUCCEEDED]: workerUpdateSucceeded,
    [types.WORKER_TYPE_UPDATE_FAILED]: workerUpdateFailed,
  },
  {
    // When we reach over 250 cached items in the store,
    // reduce the number of items to the 125 latest.
    itemLimit: [250, 125],
  },
)
