// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import React, { useCallback, useMemo, useState } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { history } from 'syft-acp-core/history'
import moment from 'moment'

import { useTrackingTrigger } from '@indeed/flex-tracking-context'
import { Alert } from '@indeed/ifl-components'

import { Button, ButtonGroup, ButtonLink, ButtonDropdown, MenuItem } from 'syft-acp-atoms/Button'
import * as modalActions from 'syft-acp-core/store/modals/actions'
import * as workersActions from 'syft-acp-core/store/workers/actions'
import * as usersActions from 'syft-acp-core/store/users/actions'
import RecordControls from 'syft-acp-core/components/RecordSection/RecordControls'
import LoadingIndicator from 'syft-acp-util/components/LoadingIndicator'
import CountrySpecific from 'syft-acp-core/components/CountrySpecific'
import { SUPPORTED_COUNTRY_CODES } from 'syft-acp-core/lib/i18n'

import WorkerTypeDropdown from '../WorkerTypeDropdown/WorkerTypeDropdown'
import WorkerReferenceDownloadLink from '../WorkerReferenceDownloadLink'
import { WorkerDetailControlsProps as Props } from './WorkerDetailControls.types'
import { trackingEvents } from './WorkerDetailControls.tracking'

export const storeConnector = connect(
  ({ workers: { isSavingData, isLoadingData } }) => ({
    isSavingData,
    isLoadingData,
  }),
  dispatch => ({
    actions: { ...bindActionCreators({ ...modalActions, ...workersActions, ...usersActions }, dispatch) },
  }),
)

const backAction = () => {
  history.push('/entity/workers/list')
}

export const expiryErrorMessage =
  'License check certificate expiry date cannot be after the driver license expiry date!'

const WorkerDetailControls = ({
  actions,
  data,
  isPristine,
  isSavingData,
  isLoadingData,
  displayDelete = false,
}: Props) => {
  const triggerEvent = useTrackingTrigger()
  const [error, setError] = useState<string>()

  const isValid = useMemo(() => {
    // If either field is missing, no need to validate
    if (!data.driver_license_check_certificate_expiry_date || !data.driver_license_expiry_date) return true
    const checkCertExpiry = moment(data.driver_license_check_certificate_expiry_date)
    const licenseExpiry = moment(data.driver_license_expiry_date)
    return checkCertExpiry.isBefore(licenseExpiry)
  }, [data.driver_license_check_certificate_expiry_date, data.driver_license_expiry_date])

  const saveAction = () => {
    if (isValid) {
      actions.saveWorker(data.id, data)
      setError(undefined)
      triggerEvent(trackingEvents.FORM_SAVE.CLICKED, { workerId: data.id, isValid, error: undefined })
    } else {
      setError(expiryErrorMessage)
      triggerEvent(trackingEvents.FORM_SAVE.CLICKED, {
        workerId: data.id,
        isValid,
        error: expiryErrorMessage,
      })
    }
  }

  const banAction = () => {
    if (data.banned) actions.banUser(data.user_id, false)
    else
      actions.showModal(
        'banWorkerModal',
        {
          onBanConfirm,
        },
        undefined,
        data,
      )
    triggerEvent(
      data.banned ? trackingEvents.ACTIONS_UNBAN_WORKER.CLICKED : trackingEvents.ACTIONS_BAN_WORKER.CLICKED,
    )
  }

  const onDownloadClick = useCallback(() => {
    triggerEvent(trackingEvents.ACTIONS_DOWNLOAD_REFERENCE.CLICKED)
  }, [triggerEvent])

  const onWorkerTypeUpdate = useCallback(
    workerTypes => {
      triggerEvent(trackingEvents.WORKER_TYPE.UPDATED, {
        worker_types: workerTypes,
      })
    },
    [triggerEvent],
  )

  const deleteAction = () => {
    actions.showConfirmModal({
      question: 'Are you sure? This user will be permanently deleted.',
      onConfirm: () => {
        actions.deleteWorkerUser(data.user_id)
        triggerEvent(trackingEvents.WORKER_USER_DELETE.CLICKED)
      },
    })
  }

  const onBanConfirm = (reasonId: string) =>
    triggerEvent(trackingEvents.ACTIONS_BAN_WORKER.CONFIRMED, {
      reason_id: reasonId,
    })

  return (
    <>
      <RecordControls>
        <div>
          <ButtonGroup>
            <Button onClick={backAction}>Back to list</Button>
          </ButtonGroup>{' '}
          <ButtonGroup>
            <ButtonLink to={`/shifts?worker_id=${data.id}`}>View shifts</ButtonLink>
          </ButtonGroup>
        </div>
        <div className="right">
          <LoadingIndicator loadingState={isSavingData || isLoadingData} />{' '}
          <Button kind="success" onClick={saveAction} disabled={isSavingData || isPristine}>
            Save
          </Button>{' '}
          <WorkerTypeDropdown
            // @ts-ignore
            title="Worker type"
            pullRight
            workerID={data.id}
            onUpdate={onWorkerTypeUpdate}
          />{' '}
          {/* @ts-expect-error */}
          <ButtonDropdown title="Actions" id="dropdown_actions" pullRight disabled={isSavingData}>
            <MenuItem key="1" onClick={banAction}>
              {data.banned ? 'Unban' : 'Ban'} worker
            </MenuItem>
            {displayDelete && (
              <MenuItem key="2" onClick={deleteAction}>
                Delete worker
              </MenuItem>
            )}
            <CountrySpecific countries={[SUPPORTED_COUNTRY_CODES.GB]}>
              {/* @ts-expect-error */}
              <MenuItem divider />
              <WorkerReferenceDownloadLink workerId={data.id} onClick={onDownloadClick} />
            </CountrySpecific>
          </ButtonDropdown>
        </div>
      </RecordControls>

      {error && (
        <div>
          <Alert status="critical" sx={{ mb: '1rem' }}>
            <span style={{ fontWeight: 'bold' }}>Error saving worker details:</span> {error}
          </Alert>
        </div>
      )}
    </>
  )
}

export default storeConnector(WorkerDetailControls)
