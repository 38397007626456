import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { get } from 'lodash-es'

import * as workersActions from 'syft-acp-core/store/workers/actions'
import UserAvatar from './UserAvatar'

const UserAvatarFromID = props => {
  const workerData = get(props.workers, props.id)
  return <UserAvatar {...props} avatar={get(workerData, 'profile_picture.uuid', 'guest')} />
}
export default connect(
  state => ({
    workers: state.workers.entityMap,
  }),
  dispatch => ({
    actions: bindActionCreators(workersActions, dispatch),
  })
)(UserAvatarFromID)
