import entityReducer from 'syft-acp-core/reducers/generators/entityReducer'
import * as entityTypes from 'syft-acp-core/reducers/generators/entities'
import { TimeOffRequestEntity } from './types'
import * as types from './action-types'

export default entityReducer<TimeOffRequestEntity>('timeOffRequests', {
  [entityTypes.ENTITIES_FETCH_BEGIN]: types.TIME_OFF_REQUESTS_FETCH_BEGIN,
  [entityTypes.ENTITIES_FETCH_SUCCEEDED]: types.TIME_OFF_REQUESTS_FETCH_SUCCEEDED,
  [entityTypes.ENTITIES_FETCH_FAILED]: types.TIME_OFF_REQUESTS_FETCH_FAILED,
  [entityTypes.ENTITY_FETCH_BEGIN]: types.TIME_OFF_REQUEST_FETCH_BEGIN,
  [entityTypes.ENTITY_FETCH_SUCCEEDED]: types.TIME_OFF_REQUEST_FETCH_SUCCEEDED,
  [entityTypes.ENTITY_FETCH_FAILED]: types.TIME_OFF_REQUEST_FETCH_FAILED,
  [entityTypes.ENTITY_UPDATE_BEGIN]: types.TIME_OFF_REQUEST_UPDATE_BEGIN,
  [entityTypes.ENTITY_UPDATE_SUCCEEDED]: [
    types.TIME_OFF_REQUEST_UPDATE_SUCCEEDED,
    types.TIME_OFF_REQUEST_APPROVE_SUCCEEDED,
    types.TIME_OFF_REQUEST_REJECT_SUCCEEDED,
  ],
  [entityTypes.ENTITY_UPDATE_FAILED]: [
    types.TIME_OFF_REQUEST_UPDATE_FAILED,
    types.TIME_OFF_REQUEST_APPROVE_FAILED,
    types.TIME_OFF_REQUEST_REJECT_FAILED,
  ],
})
