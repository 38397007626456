// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import React from 'react'

import ClientApplicationsList from 'syft-acp-core/entities/ClientApplicationsList'
import PageView from 'syft-acp-core/components/PageView'
import SubNavigationAdmin from './SubNavigation'

export const AdminClientApplicationsList = () => (
  <>
    <SubNavigationAdmin />
    <PageView title={['Client applications', 'Overview']} entityPage>
      <ClientApplicationsList urlBase="/admin/client-applications/view/" />
    </PageView>
  </>
)
