// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited
import entityReducer from 'syft-acp-core/reducers/generators/entityReducer'
import * as entityTypes from 'syft-acp-core/reducers/generators/entities'

import * as types from './action-types'

export default entityReducer('fulfilmentShifts', {
  [entityTypes.ENTITY_FETCH_BEGIN]: types.FULFILMENT_SHIFT_FETCH_BEGIN,
  [entityTypes.ENTITY_FETCH_SUCCEEDED]: types.FULFILMENT_SHIFT_FETCH_SUCCEEDED,
  [entityTypes.ENTITY_FETCH_FAILED]: types.FULFILMENT_SHIFT_FETCH_FAILED,
  [entityTypes.ENTITY_UPDATE_BEGIN]: types.FULFILMENT_SHIFT_EDIT_BEGIN,
  [entityTypes.ENTITY_UPDATE_SUCCEEDED]: types.FULFILMENT_SHIFT_EDIT_SUCCEEDED,
  [entityTypes.ENTITY_UPDATE_FAILED]: types.FULFILMENT_SHIFT_EDIT_FAILED,
})
