// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import { takeLatestApiCall } from 'syft-acp-core/sagas/helpers'

import { fetchWorkerShifts } from './api'

export default function* root() {
  yield takeLatestApiCall('WORKER_SHIFTS_FETCH', fetchWorkerShifts)
}
