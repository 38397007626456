import React, { useMemo } from 'react'
import { Box } from '@indeed/ifl-components'
import NotifyBar from 'syft-acp-atoms/NotifyBar'
import { TYPE_CUSTOM } from 'syft-acp-core/components/EditableTable'
import RecordSection from 'syft-acp-core/components/RecordSection'
import lockIconUrl from 'syft-acp-core/static/lock-icon.svg'
import CompliancesListWrapper, { OwnProps, RenderProps } from '../CompliancesListWrapper'
import ComplianceDetail, { Compliances } from '../ComplianceDetail'
import { CLIENT, LEGAL } from '../../../constants'
import './ComplianceListSection.scss'

const useSectionStructure = (fields: { name: string; header: React.ReactNode; hideRoleTitle: boolean }[]) =>
  useMemo(
    () =>
      fields.map(({ name, header, hideRoleTitle }) => [
        name,
        {
          header,
          type: TYPE_CUSTOM,
          getData: (compliances: Record<string, Compliances>) => {
            return (
              <div style={{ marginTop: 10 }} className="compliance-list-section">
                <ComplianceDetail
                  hideSectionTitle
                  hideRoleTitle={hideRoleTitle}
                  root
                  compliances={compliances?.[name]}
                />
              </div>
            )
          },
        },
      ]),
    [fields],
  )

const CompliancesListSection = ({ lastMessage, title, data, roleID }: RenderProps) => {
  const sectionData = useMemo(() => {
    return data.reduce(
      (acc, { skill, role, reason }) => {
        acc[reason].skills.push({
          skill,
          role,
        })
        return acc
      },
      { [LEGAL]: { skills: [] }, [CLIENT]: { skills: [] } } as { [key: string]: any },
    )
  }, [data])

  const structure = useSectionStructure([
    {
      name: 'legal compliance',
      hideRoleTitle: !!roleID,
      header: (
        <div>
          Legal:
          <div className="enforcement">
            <Box as="img" src={lockIconUrl} />
            <span className="enforcement-text">Enforced by Flex</span>
          </div>
        </div>
      ),
    },
    {
      name: 'client compliance',
      hideRoleTitle: !!roleID,
      header: (
        <div>
          Client:
          <div className="enforcement">
            <Box as="img" src={lockIconUrl} />
            <span className="enforcement-text">Enforced by Head office</span>
          </div>
        </div>
      ),
    },
  ])

  const notificationsNode = useMemo(
    () =>
      lastMessage ? (
        <NotifyBar severity="error">{lastMessage}</NotifyBar>
      ) : (
        <NotifyBar severity="warning">No compliances found</NotifyBar>
      ),
    [lastMessage],
  )
  return !lastMessage &&
    (Object.keys(sectionData[LEGAL].skills).length || Object.keys(sectionData[CLIENT].skills).length) ? (
    <RecordSection
      header={null}
      title={title}
      data={sectionData}
      structure={structure}
      noRowBorders={false}
    />
  ) : (
    <RecordSection title={title} header={null} notificationsNode={notificationsNode} />
  )
}

const render = (renderProps: RenderProps) => <CompliancesListSection {...renderProps} />
const CompliancesListSectionWrapped = (props: Omit<OwnProps, 'render'>) => (
  <CompliancesListWrapper {...props} render={render} />
)

export default CompliancesListSectionWrapped
