// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import { createSelector } from 'reselect'
import { get } from 'lodash-es'

/**
 * This selector returns entityMap of the agency shift bookings
 *
 * @param {{}} state - the redux state
 * @param {number} shiftID - a shift of id
 * @returns {object} entityMap of agency shift bookings
 */
const getEntityListAgencyShiftBookings = (state, shiftID) =>
  get(state, `listingAgencyShiftBookings.entityMap[${shiftID}]`, { agency_shift_bookings: [] })

/**
 * This selector returns entityMap of the agency shift bookings
 */
export const getListingAgencyShiftBookingsSelector = createSelector(
  getEntityListAgencyShiftBookings,
  listingAgencyShiftBookingsEntityMap => listingAgencyShiftBookingsEntityMap
)
