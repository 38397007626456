// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import { GUEST_AVATAR, EMPTY_AVATAR } from './constants'
import NameAvatarImage from './NameAvatarImage'

import './NameAvatar.css'

class NameAvatar extends React.PureComponent {
  static propTypes = {
    useGuestAvatar: PropTypes.bool,
    useEmptyAvatar: PropTypes.bool,
    useWrapping: PropTypes.bool,
    hideAvatar: PropTypes.bool,
    userUUID: PropTypes.string,
    userName: PropTypes.string.isRequired,
  }

  static defaultProps = {
    useGuestAvatar: false,
    useEmptyAvatar: true,
    useWrapping: false,
    hideAvatar: false,
    userUUID: '',
  }

  avatarFallback = (userName, userUUID, useGuestAvatar, useEmptyAvatar) => {
    if (userUUID) return userUUID
    if (useGuestAvatar) return GUEST_AVATAR
    if (useEmptyAvatar) return EMPTY_AVATAR
    // If neither of these are set but we don't have a UUID either,
    // fall back to the guest avatar if we do have a name,
    // or to the empty avatar if we don't.
    if (!userName) return EMPTY_AVATAR
    return GUEST_AVATAR
  }

  render() {
    const { useGuestAvatar, useEmptyAvatar, useWrapping, userUUID, userName, hideAvatar } = this.props
    const avatarString = this.avatarFallback(userName, userUUID, useGuestAvatar, useEmptyAvatar) // '\u00A0'
    // If there's no username, display a non-breaking space to get the right line height.
    const nameFallback = !userName
    return (
      <div
        className={classNames('NameAvatar', {
          'with-avatar': !hideAvatar,
          'with-nbsp': nameFallback,
          nowrap: !useWrapping,
        })}
      >
        {!hideAvatar && <NameAvatarImage uuid={avatarString} alt={userName} withName />}
        <span className="username">{nameFallback ? '\u00A0' : userName}</span>
      </div>
    )
  }
}

export default NameAvatar
