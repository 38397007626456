// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import { uniqWith, differenceWith, isEqual } from 'lodash-es'

import * as types from './action-types'
import { CheckboxAction } from './actions'

const initialState = {
  items: {} as Record<string, any>,
}

const reducer = (state = initialState, action: CheckboxAction) => {
  const items = state.items[action.scope] || []
  switch (action.type) {
    case types.CHECKBOXES_CHECK_ITEM:
      return {
        ...state,
        items: {
          ...state.items,
          [action.scope]: uniqWith(items.concat(action.items), isEqual),
        },
      }
    case types.CHECKBOXES_UNCHECK_ITEM:
      return {
        ...state,
        items: {
          ...state.items,
          [action.scope]: differenceWith(items, action.items, isEqual),
        },
      }
    case types.CHECKBOXES_CLEAR_ITEMS:
      if (action.scope) {
        return {
          ...state,
          items: {
            ...state.items,
            [action.scope]: [],
          },
        }
      } else {
        return initialState
      }
    default:
      return state
  }
}

export default reducer
