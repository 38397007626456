import * as U from 'karet.util'
import * as R from 'kefir.ramda'
import { set, lensProp } from 'kefir.ramda'
import { pick, omit } from 'lodash-es'

import { notify } from 'syft-acp-core/lib/notifications'
import { stringify } from 'syft-acp-core/store/filters/helpers'
import { resPricingPlans, resSalesWinAssociations } from 'syft-acp-core/api/res/admin/sales'
import { resSalesWins } from 'syft-acp-core/api/res/admin/sales/sales_wins/pricing_plans'
import wait from 'syft-acp-util/wait'

import { addToAtom, notifySaveError, removeEmptyPeriods, placeholderData } from './helpers'

// FIXME FIXME FIXME FIXME FIXME FIXME FIXME FIXME
const NO_SELECTION = 999

// SalesForce promotions have two aspects: promotion plans (left), and promotion periods (right).
// Periods are defined as an array that's part of a plan; so a plan can have multiple periods,
// and there can be multiple plans.
// One of the settings for the plan data ('sales_pricing_plan_type_id') has to be set to an ID
// from a list obtained through an API call.

/** Default data for promotion plans (left hand side). */
export const defaultPlan = {
  yearly_spend: '',
  commitment_start_date: '',
  commitment_end_date: '',
  commitment_period: '',
  sales_pricing_plan_type_id: '',
  pricing_promotions: [],
}

/** Default data for promotion periods (right hand side). */
export const defaultPromotion = {
  promo_period: '',
  promo_start_date: '',
  fee_percent: '',
}

// Promotion data
// The data is collected using '/admin/sales/pricing_plans' and '/admin/sales/sales_win_associations'.
export const promotionData = U.atom({
  pricingData: {
    ...placeholderData,
  },
  salesWinData: {
    // This item contains promotion period details.
  },
  salesforceData: {
    // Here will be objects named after a query; e.g. 'employerID=40761'.
    // Inside, there's all the data normally used by resource queries.
    // Also: an extra list of entity data by ID.
    entityDataByID: null,
  },
})

export const salesforcePricingData = U.view(['pricingData'], promotionData)
export const salesforcePlanData = U.view(['salesforceData'], promotionData)
export const salesforcePeriodData = U.view(['salesWinData'], promotionData)

/**
 * Checks whether a SalesForce item row is currently sharing data with another.
 */
const isSharingData = (rowData, tableData) => {
  const currID = rowData.id
  const currSWID = rowData.sales_win_id

  // Filter out the current item.
  const allOtherItems = tableData.map(item => (item.id === currID ? null : item)).filter(item => item)

  // Check if any item (except itself) has the same 'sales_win_id'.
  return allOtherItems.find(item => item.sales_win_id === currSWID) != null
}

/** Selects a specific period for editing. Requires that a plan is selected. */
export const selectPromotionPeriod =
  salesforceID =>
  async ({ rowN }) => {
    const selectionView = U.view(
      [stringify({ salesWinID: salesforceID }), 'reqResult', `periodSelection`],
      salesforcePeriodData
    )
    selectionView.set({ rowN })
  }

/** Selects a specific plan for editing. */
export const selectPromotionPlan =
  salesforceID =>
  async ({ rowN }) => {
    const selectionView = U.view(
      [stringify({ salesWinID: salesforceID }), 'reqResult', `planSelection`],
      salesforcePeriodData
    )
    selectionView.set({ rowN })
    selectPromotionPeriod(salesforceID)({ rowN: NO_SELECTION })
  }

/**
 * Loads Salesforce data for a specific employer.
 */
export const loadPromotionData = async salesWinID => {
  const remoteLens = await resSalesWins.getRemote({ salesWinID })
  const setName = stringify({ salesWinID })
  addToAtom(remoteLens, salesforcePeriodData, setName)
  selectPromotionPlan(salesWinID)({ rowN: 0 })
}

/**
 * Saves updated Salesforce data for a specific row.
 *
 * We can send the data including 'sales_win_id', or without.
 *
 * (1) When sending without 'sales_win_id', we are creating a new item,
 * which will also not have any existing promotional data.
 *
 * (2) When sending with 'sales_win_id', we're updating the existing item
 * and all other items that use the same ID. Promotional data is preserved.
 *
 * We should create a new item if a SalesForce entry is currently sharing its data
 * with other items. If it isn't, we need to update the item. See <SLUG-316>.
 */
export const saveSalesforceData = async (
  { rowData, tableData },
  employerID,
  setResults,
  isLoading,
  res = resSalesWinAssociations,
  routingQuery = {}
) => {
  // Create a new item if the current SalesForce item is sharing its data with another.
  // If so, create a new item.
  const createNewItem = isSharingData(rowData, tableData)
  const body = {
    sales_win_associations: [
      {
        // Either send only 'id' (1), or it and 'sales_win_id' (2).
        ...pick(rowData, createNewItem ? ['id'] : ['id', 'sales_win_id']),
        // Omit the ID from 'sales_win_attributes', which is the same as 'sales_win_id'.
        sales_win_attributes: omit(rowData.sales_win_attributes, createNewItem ? ['id'] : []),
      },
    ],
    ...routingQuery,
  }

  isLoading.set(true)
  const result = await res.post({ employerID }, body, {
    onFail: data => notifySaveError(data, 'true'),
    onSuccess: () => {
      notify('success', { title: 'Salesforce data saved!' })
    },
  })

  // Move the modified data back into the atom.
  isLoading.set(false)
  if (!result || !result.reqResult) return
  setResults.modify(set(lensProp('data'), result.reqResult.data))
  setResults.modify(set(lensProp('meta'), result.reqResult.meta))
}

/**
 * Saves updated promotion plans/periods.
 */
export const savePromotionData = salesforceID => async () => {
  const itemData = U.view([stringify({ salesWinID: salesforceID })], salesforcePeriodData)
  const itemIsLoading = U.view(['isLoading'], itemData)
  const itemPlanDataRaw = U.view(['reqResult', 'data'], itemData)
  const itemPlanData = removeEmptyPeriods(itemPlanDataRaw.get())
  itemIsLoading.set(true)
  await Promise.all(
    itemPlanData.map((itemPlan, n) => {
      const { yearly_spend } = itemPlan

      const fixedItemPlan = {
        ...itemPlan,
        yearly_spend: yearly_spend || 0,
      }

      resSalesWins.post({ salesWinID: salesforceID }, fixedItemPlan, {
        onSuccess: () => {
          // Only notify of success once.
          if (n === 0) {
            notify('success', { title: 'Saved promotion details' })
            // FIXME: set new data like loadPromotionData() does.
            setTimeout(() => loadPromotionData(salesforceID), 1)
          }
        },
        onFail: data => notifySaveError(data, 'period'),
      })
      return null
    })
  )
  itemIsLoading.set(false)
}

/**
 * Loads Salesforce data for a specific employer.
 */
export const loadSalesForceData = async (employerID, retry = false, routingQuery = {}, isLoading) => {
  if (isLoading) {
    isLoading.set(true)
  }
  const remoteLens = await resSalesWinAssociations.getRemote({ employerID, ...routingQuery })
  const localLens = U.view('salesforceData', promotionData)
  const setName = stringify({ employerID })
  addToAtom(remoteLens, localLens, setName)

  // Extract the entity names from the data, and make them accessible by ID in the atom.
  if (!remoteLens && !retry) {
    await wait(500)
    return loadSalesForceData(employerID, true)
  }
  if (!!remoteLens?.reqResult?.data) {
    const entityDataByID = remoteLens.reqResult.data.reduce(
      (acc, item) => ({ ...acc, [item.sales_win_id]: { ...item } }),
      {}
    )
    addToAtom({ entityDataByID }, localLens, setName)
  }
  return null
}

/** Adds a new row to the plan list. */
export const addPromotionPlan = salesforceID => async () => {
  const ppView = U.view(['salesWinData', stringify({ salesWinID: salesforceID }), 'reqResult', 'data'], promotionData)
  const isFirst = U.view('length', ppView).get() === 0
  ppView.modify(R.append({ ...defaultPlan }))
  if (isFirst) {
    selectPromotionPlan(salesforceID)({ rowN: 0 })
  }
}

/** Adds a new row to the period list. Requires that a plan is selected. */
export const addPromotionPeriod = salesforceID => async () => {
  const ppView = U.view(
    ['salesWinData', stringify({ salesWinID: salesforceID }), 'reqResult', 'data', 0, 'pricing_promotions'],
    promotionData
  )
  const isFirst = U.view('length', ppView).get() === 0
  ppView.modify(R.append({ ...defaultPromotion }))

  // If this is the first item we've added, select it so the user can begin editing.
  if (isFirst) {
    selectPromotionPeriod(salesforceID)({ rowN: 0 })
  }
}

/**
 * Loads pricing plans from the API.
 * This populates the dropdown menu on the promotion edit page.
 */
export const loadPricingPlans = async () => {
  const remoteLens = await resPricingPlans.getRemote()
  // FIXME: modifications to the data.
  if (!!remoteLens?.reqResult?.data) {
    remoteLens.reqResult.data = remoteLens.reqResult.data.map(item => ({ slug: item.id, label: item.title }))

    const localLens = U.view('pricingData', promotionData)
    addToAtom(remoteLens, localLens, null)
  }
}
