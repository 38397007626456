import React, { useState } from 'react'
import classNames from 'classnames'
import { Popover } from '@material-ui/core'
import iconFactory from 'syft-acp-atoms/Icons'

import { colPlaceholderFactory } from '../../../../placeholders/factory'
import './AcpCellPopover.scss'
import { AcpCellPopoverProps } from './AcpCellPopover.type'

const ChevronDownIcon = iconFactory('chevron-down', 'Chevron down')
const ChevronUpIcon = iconFactory('chevron-right', 'Chevron left')

/** Column placeholder component. */
export const AcpColPopover = colPlaceholderFactory('AcpCellPopover')

/**
 * Column type for displaying popovers.
 *
 * ```
 * <Acp.Col.Popover container={<RequiredContainer/>} value="12/15" header="Required" />
 * ```
 */
const AcpCellPopover = ({ value, spec, rowData }: AcpCellPopoverProps) => {
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null)
  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }
  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined
  const disablePopover = spec?.disableFunc(rowData)
  if (disablePopover) {
    return <div className="acp-cell-popover-container">{value}</div>
  }
  return (
    <>
      <div className="acp-cell-popover-container" onClick={handleClick}>
        <div className={classNames('acp-cell-popover')}>{value}</div>
        <div>
          {anchorEl === null ? (
            <ChevronUpIcon className="acp-cell-popover-icon" height={16} iconSize={18} />
          ) : (
            <ChevronDownIcon className="acp-cell-popover-icon" height={16} iconSize={18} />
          )}
        </div>
      </div>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        style={{ zIndex: 1000 }}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        {spec.container({ rowData })}
      </Popover>
    </>
  )
}

AcpCellPopover.cellProperties = ['popover', 'pure']

export default AcpCellPopover
