import { createSelector } from 'reselect'
import { entitySetName } from 'syft-acp-core/reducers/generators/utils'
import { GenericState } from 'syft-acp-core/reducers/generators/entityReducer'
import { Store } from 'syft-acp-core/store'

type EmployerVenuesFilteredEntity = {
  id: number
  name: string
  timezone: string
  requires_approver_for_booking: boolean
}

const selectEmployerVenues = (state: Store) => state.employerVenuesFiltered
const selectEmployerId = (state: Store, employerId: number) => employerId

export const selectEmployerVenueOptions = createSelector(
  selectEmployerVenues,
  ({ entityMap = {} }: GenericState<EmployerVenuesFilteredEntity>) =>
    Object.values(entityMap)
      .map((venue: EmployerVenuesFilteredEntity) => ({ id: venue.id, label: venue.name }))
      .sort((a, b) => (a.label.toUpperCase() < b.label.toUpperCase() ? -1 : 1)),
)

export const selectEmployerVenueOptionsById = createSelector(
  [selectEmployerVenues, selectEmployerId],
  (venues: GenericState<EmployerVenuesFilteredEntity>, employerId: number) => {
    const setKey = entitySetName({ employerID: employerId })
    const entitySet = venues?.entitySets[setKey]
    return entitySet
      ? entitySet.ids.map(id => ({
          id: venues?.entityMap[id].id,
          name: venues?.entityMap[id].name,
        }))
      : []
  },
)
