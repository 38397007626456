// Syft ACP - Atoms <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import './Star.css'

const Star = ({ isFull, isHalf, isEmpty }) => (
  <span className={classNames('acp-star', { 'is-full': isFull, 'is-half': isHalf, 'is-empty': isEmpty })}>
    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 260 245">
      <g id="full">
        <path d="m55,237 74-228 74,228L9,96h240" />
      </g>
      <g id="half">
        <polygon points="129,183.2 129,9 100.8,96 9,96 83.2,150 55,237" />
      </g>
    </svg>
  </span>
)

Star.propTypes = {
  isFull: PropTypes.bool,
  isHalf: PropTypes.bool,
  isEmpty: PropTypes.bool,
}

Star.defaultProps = {
  isFull: false,
  isHalf: false,
  isEmpty: false,
}

export default Star
