import * as types from './action-types'

import { UpdateTimeOffRequestInput } from './types'

export const fetchTimeOffRequests = ({ options }: { options: Record<string, any> }) => ({
  type: types.TIME_OFF_REQUESTS_FETCH_BEGIN,
  options,
})

export const fetchTimeOffRequest = (requestID: number) => ({
  type: types.TIME_OFF_REQUEST_FETCH_BEGIN,
  payload: { requestID },
})

export const updateTimeOffRequest = (id: number, options: UpdateTimeOffRequestInput['payload']['options']) => ({
  type: types.TIME_OFF_REQUEST_UPDATE_BEGIN,
  payload: {
    id,
    options,
  },
})

export const approveTimeOffRequest = (id: number) => ({
  type: types.TIME_OFF_REQUEST_APPROVE_BEGIN,
  payload: {
    id,
  },
})

export const rejectTimeOffRequest = (id: number) => ({
  type: types.TIME_OFF_REQUEST_REJECT_BEGIN,
  payload: {
    id,
  },
})
