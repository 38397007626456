import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { AsyncTypeahead } from 'react-bootstrap-typeahead'

import './FilterAutocomplete.css'

class FilterAutocomplete extends Component {
  static propTypes = {
    placeholder: PropTypes.string.isRequired,
    isDisabled: PropTypes.bool.isRequired,
    initialValue: PropTypes.any,
    queryFunction: PropTypes.func.isRequired,
    formatFunction: PropTypes.func.isRequired,
    handleChange: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
  }

  static defaultProps = {
    initialValue: '',
  }

  state = {
    isLoading: false,
  }

  // Runs a remote search with the given query.
  runSearch = async query => {
    if (!query) return

    this.setState({ isLoading: true })

    const { queryFunction, formatFunction } = this.props
    const results = await queryFunction(query)
    const options = formatFunction(results)

    this.setState({ isLoading: false, options })
  }

  unpackValue = value => value.split('$')

  render() {
    const { isLoading, options } = this.state
    const { handleChange, initialValue, placeholder, name } = this.props
    const [initID, initLabel] = this.unpackValue(initialValue)
    return (
      <div className="FilterAutocomplete">
        <AsyncTypeahead
          /* Don't do any filtering; return '1' for all so they keep their original API order. */
          filterBy={() => 1}
          /* We require that options use 'id' and 'label'. */
          labelKey="label"
          placeholder={placeholder}
          emptyLabel="No matches."
          isLoading={isLoading}
          clearButton
          defaultInputValue={initLabel}
          /* Max height must be 333px to fit 7 items and the "display
          additional results" link (with 5px padding). */
          maxHeight="333px"
          maxResults={7}
          delay={500}
          options={options}
          onSearch={this.runSearch}
          onChange={handleChange}
          id={`autocomplete_${name}_${initID}`}
          minLength={0}
        />
      </div>
    )
  }
}

export default FilterAutocomplete
