// Syft ACP - Lib <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import moment from 'moment'
import humanizeDuration from 'humanize-duration'
import { addTimezone } from 'syft-acp-util/formatting'
import { formatShortDate, formatYear, formatTime, isWithin24Hours } from './helpers'

/**
 * Adds a time and timezone offset to a plain date (e.g. '1980-01-30').
 * Returns an ISO 8601 timestamp such as '2018-12-31T23:00:00+0100'.
 */
export const dateAddTz = dateStr => {
  if (!dateStr) return null
  return moment(dateStr).format('YYYY-MM-DDTHH:mm:ssZZ')
}

/**
 * Adds a time and timezone offset to a plain date (e.g. '1980-01-30').
 * Returns an ISO 8601 timestamp with hours and minutes such as '2018-12-31T23:00:00+0100'.
 *
 */
export const dateAddTzSetTime = (dateStr, hour, minute) => {
  if (!dateStr) return null
  return moment(dateStr).set({ hour, minute }).format('YYYY-MM-DDTHH:mm:ssZZ')
}

/**
 * Reduces a full datetime to a plain date.
 * E.g. '2018-12-31T23:00:00+0100' becomes '2018-12-31'.
 */
export const datetimeToDate = datetimeStr => {
  if (!datetimeStr) return null
  return moment(datetimeStr).format('YYYY-MM-DD')
}

/**
 * Formats a group of two timestamps as a time/date range.
 *
 * When the start timestamp is today:
 *   When the start/end timestamps take place within 24 hours:
 *     12:00-13:00
 *   When the end timestamp is over 24 hours later than the start timestamp:
 *     12:00-15 Oct 13:00
 *   When the end timestamp takes place in a different year than the start timestamp:
 *     12:00-15 Oct 2020 13:00
 *
 * When the start timestamp is not today:
 *   When the start/end timestamps take place within 24 hours:
 *     14 Oct 12:00-13:00
 *   When the start timestamp takes place in a different year than today:
 *     14 Oct 2020 12:00-13:00
 *   When the end timestamp is over 24 hours later than the start timestamp:
 *     14 Oct 2020 12:00-15 Oct 13:00
 *   When the end timestamp takes place in a different year than the start timestamp:
 *     14 Oct 2020 12:00-15 Oct 2021 13:00
 *
 * Returns an object containing multiple sections of the formatted date.
 */
export const formatLocalDateRange = (tsArr, comparison = new Date()) => {
  const [startTime, endTime] = tsArr

  // The formatting of the date range is dependent on the current date and year.
  // If the displayed timestamps are from today, we can simplify them to just a time display without date.
  // If they are from the current year, we don't need to include the year.
  const thisDate = formatShortDate(comparison)
  const thisYear = formatYear(comparison)
  const startYear = formatYear(startTime)
  const endYear = formatYear(endTime)

  // Now format the given dates themselves.
  const startDate = formatShortDate(startTime) // e.g. "14 Oct"
  const endDate = formatShortDate(endTime)
  const startDateWithYear = formatShortDate(startTime, false) // e.g. "14 Oct 2020"
  const endDateWithYear = formatShortDate(endTime, false)
  const startTimeFormatted = formatTime(startTime, false) // e.g. "12:00"
  const endTimeFormatted = formatTime(endTime, false)

  // Whether the date is today:
  const startsToday = thisDate === startDate
  // Whether the start/end timestamps take place within 24 hours:
  const rangeIn24 = isWithin24Hours(startTime, endTime)
  // Whether the start date is in the same year as today:
  const startsThisYear = startYear === thisYear
  // Whether the end date is in the same year as the start date:
  const endsSameYear = endYear === startYear

  const returnValue = {
    startDate: null,
    startTime: startTimeFormatted,
    endDate: null,
    endTime: endTimeFormatted,
  }

  if (startsToday && startsThisYear) {
    returnValue.startDate = null
  } else if (startsThisYear) {
    returnValue.startDate = startDate
  } else {
    returnValue.startDate = startDateWithYear
  }

  if (rangeIn24) {
    returnValue.endDate = null
  } else if (endsSameYear) {
    returnValue.endDate = endDate
  } else {
    returnValue.endDate = endDateWithYear
  }

  return returnValue
}

/**
 * Formats a timestamp per locale.
 *
 * Returns an object containing an absolute time, a relative time, and the correctly formatted string.
 */
export const formatLocalDatetime = (ts, opts) => {
  const defaults = {
    useFormat: 'datetime',
    showSeconds: false,
    showRelative: false,
    relativeOnly: false,
    useISO: false,
    showTimezone: false,
  }
  const options = { ...defaults, ...opts }

  const m = addTimezone(moment(ts))

  const abs =
    options.useFormat === 'datetime' && options.useISO && !options.showTimezone
      ? m.format('YYYY-MM-DDTHH:mm:ss')
      : options.useFormat === 'datetime' && options.useISO && options.showTimezone
      ? m.format('YYYY-MM-DDTHH:mm:ssZ')
      : options.useFormat === 'date' && options.useISO
      ? m.format('YYYY-MM-DD')
      : options.useFormat === 'time' && options.useISO && !options.showTimezone
      ? m.format('HH:mm:ss')
      : options.useFormat === 'time' && options.useISO && options.showTimezone
      ? m.format('HH:mm:ssZ')
      : options.useFormat === 'datetime' && options.showSeconds && !options.showTimezone
      ? m.format('L LTS')
      : options.useFormat === 'datetime' && options.showSeconds && options.showTimezone
      ? m.format('L LTS z')
      : options.useFormat === 'datetime' && !options.showSeconds && !options.showTimezone
      ? m.format('L LT')
      : options.useFormat === 'datetime' && !options.showSeconds && options.showTimezone
      ? m.format('L LT z')
      : options.useFormat === 'date'
      ? m.format('L')
      : options.useFormat === 'time' && options.showSeconds && options.showTimezone
      ? m.format('LTS z')
      : options.useFormat === 'time' && !options.showSeconds && options.showTimezone
      ? m.format('LT z')
      : options.useFormat === 'time' && options.showSeconds && !options.showTimezone
      ? m.format('LTS')
      : options.useFormat === 'time' && !options.showSeconds && !options.showTimezone
      ? m.format('LT')
      : options.useFormat === 'week'
      ? m.format('YYYY-[W]W')
      : ''

  const rel = !options.relativeUnits
    ? m.fromNow()
    : options.relativeUnits.length === 0
    ? m.fromNow()
    : humanizeDuration(moment().diff(m, 'seconds'), { round: true, units: options.relativeUnits })

  return {
    abs,
    rel,
    formatted: options.relativeOnly ? rel : `${abs}${options.showRelative ? ` (${rel})` : ''}`,
  }
}

/**
 * Formats a date-only time string per locale.
 *
 * Dates that miss a time and timezone can't be formatted using our regular functions, as the value
 * will be interpreted as UTC and then formatted using the local timezone, which can lead
 * to dates being changed. E.g. 2019-10-02 00:00 UTC rendered as 2019-10-01 23:00 CET.
 *
 * Returns an object containing an absolute time, a relative time, and the correctly formatted string.
 */
export const formatLocalDate = (date, opts) => {
  // To correctly display a date only, we'll add the current timezone to it.
  // Convert e.g. '2019-07-31' to '2019-07-31T00:00:00+02:00'.
  const tz = addTimezone(moment()).format('Z')
  const ts = `${date}T00:00:00${tz}`
  return formatLocalDatetime(ts, opts)
}

/**
 * Returns an array with two items: the ISO 8601 week for a given Date(), as a number
 * between [1..53], and its associated year.
 * For more information, see <https://en.wikipedia.org/wiki/ISO_week_date>.
 *
 * @param {Date} date Date for which to get the week and year
 * @returns {Array} Year and week
 */
export const getYearWeek = date => {
  // Copy the date so we don't alter it.
  const target = new Date(date.valueOf())

  // Set the date to a Thursday.
  target.setDate(target.getDate() - ((target.getDay() + 6) % 7) + 3)

  const currThu = target.valueOf()

  // Now change the month to January. Set the date to the first Thursday.
  target.setMonth(0, 1)
  if (target.getDay() !== 4) {
    target.setMonth(0, 1 + ((4 - target.getDay() + 7) % 7))
  }

  const firstThu = target.valueOf()

  // Calculate week number by counting the number of weeks in between
  // the first Thursday of the year and the current week's thursday.
  // 604800000 is the number of milliseconds in a week.
  return [target.getFullYear(), 1 + Math.ceil((currThu - firstThu) / 604800000)]
}

/**
 * Returns the year and week of a timestamp. Given as a string in the format "{YYYY}-W{WW}".
 * The output from this function matches that provided by a "week" input field.
 *
 * @param {Date} date Date for which to get the week and year
 * @returns {Array} Year and week
 */
export const getISOYearWeek = date => {
  const yearWeek = getYearWeek(date)

  // Null pad the week number.
  yearWeek[1] = yearWeek[1] < 10 ? `0${yearWeek[1]}` : yearWeek[1]

  return `${yearWeek[0]}-W${yearWeek[1]}`
}

/**
 * Checks whether a time can be closer to a reference time by moving its date
 * up or down a number of days. See moveToReferenceTime() for an example.
 */
export const getClosestTime = (mBaseTime, mRef, transforms, onlyLater) => {
  const mRefTime = Number(mRef.format('x'))

  let closestTimeDifference = Infinity
  let closestMoment = null

  let tmpTime
  let tmpMoment
  let tmpTransform
  let tmpDiff
  for (let a = 0; a < transforms.length; ++a) {
    tmpTransform = transforms[a]
    // Add or subtract n number of days.
    tmpMoment = mBaseTime.clone()[tmpTransform < 0 ? 'subtract' : 'add'](tmpTransform, 'days')
    tmpTime = Number(tmpMoment.format('x'))
    tmpDiff = tmpTime - mRefTime
    if (closestTimeDifference >= Math.abs(tmpDiff)) {
      if ((onlyLater && tmpDiff > 0) || !onlyLater) {
        closestTimeDifference = Math.abs(tmpDiff)
        closestMoment = tmpMoment
      }
    }
  }

  return closestMoment
}

/**
 * This modifies a clock in/out times to include the correct date.
 * It does this by taking the clock time and moving the date to be as close
 * as possible to the reference time (which is the shift start timestamp).
 *
 * E.g. if a shift starts at 2017-01-01 12:00 and our clock in time is given
 * as 2017-01-02 12:05, this function will return 2017-01-01 12:05.
 * If a shift starts at 2017-01-02 00:00 and our clock in time is given as
 * 2017-01-04 23:59, this function returns 2017-01-01 23:59. (Because
 * the worker clocked in 1 minute early; not 23 hours and 59 minutes late.)
 *
 * If we are measuring the check out time, we assume that it is in the future
 * of the shift start time.
 *
 * Plainly speaking, it takes a timestamp and changes the year, month and date
 * to be as close to the reference time as possible.
 */
export const moveToReferenceTime = (mIn, mRef, isInTime) => {
  mIn.set({
    year: mRef.year(),
    month: mRef.month(),
    date: mRef.date(),
  })

  // Check whether we can get closer to the reference time by adding/subtracting a day.
  // If we are checking the check out time, only check a day ahead.
  return getClosestTime(mIn, mRef, isInTime ? [-1, 0, 1] : [0, 1], !isInTime)
}

/**
 * Promisified version of setTimeout. Simply waits and resolves.
 */
export const wait = ms => new Promise(resolve => setTimeout(() => resolve(), ms))
