import { apiRequest, handleCall } from 'syft-acp-core/api/call'

export const fetchMissingPay = ({ workerID, options = {} }: { workerID: number; options: Record<string, any> }) =>
  new Promise((resolve, reject) => {
    const req = apiRequest({ path: `/admin/workers/${workerID}/missing_pays`, reqArgs: options })
    handleCall(req, resolve, reject, true, options)
  })

export const deleteMissingPay = ({
  workerID,
  missingPayID,
  options,
}: {
  workerID: number
  missingPayID: number
  options: Record<string, any>
}) =>
  new Promise((resolve, reject) => {
    const req = apiRequest({
      path: `/admin/workers/${workerID}/missing_pays/${missingPayID}`,
      reqArgs: options,
      method: 'DELETE',
    })
    handleCall(req, resolve, reject)
  })
