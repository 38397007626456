import PropTypes from 'prop-types'
import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { withTrackingTriggerHoc } from 'syft-acp-util/withTrackingTriggerHoc'
import * as employerVenuesActions from 'syft-acp-core/store/employer-venues/actions'
import * as externalWorkLocationsActions from 'syft-acp-core/store/externalWorkLocations/actions'
import TransferRecordSection from 'syft-acp-core/components/TransferRecordSection'
import entityDetailHOC from 'syft-acp-util/entityDetail'
import { trackingEvents } from './EmployerVenueDetail/EmployerVenueDetail.tracking'

const ExternalWorkLocationsTransferRecords = entityDetailHOC('externalWorkLocations', TransferRecordSection)

export class EmployerVenueTransferRecordUI extends PureComponent {
  static propTypes = {
    id: PropTypes.number.isRequired,
    venue: PropTypes.shape({
      id: PropTypes.number.isRequired,
      external_work_location: PropTypes.shape({
        id: PropTypes.number.isRequired,
      }),
    }),
    employerID: PropTypes.number.isRequired,
    data: PropTypes.shape({
      id: PropTypes.number,
      employer_id: PropTypes.number,
      external_platform_id: PropTypes.number,
      external_platform_reference: PropTypes.string,
    }),
    actions: PropTypes.shape({
      fetchExternalWorkLocation: PropTypes.func.isRequired,
      createExternalWorkLocation: PropTypes.func.isRequired,
      deleteExternalWorkLocation: PropTypes.func.isRequired,
      updateExternalWorkLocation: PropTypes.func.isRequired,
    }).isRequired,
    triggerEvent: PropTypes.func.isRequired,
  }

  static defaultProps = {
    venue: {},
    data: {},
  }

  componentDidUpdate(prevProps) {
    if (prevProps.id !== this.props.id && this.props.id) {
      this.props.actions.fetchExternalWorkLocation({ id: this.props.id })
    }
  }

  createTransferRecord = data => {
    const { actions, triggerEvent, employerID, venue } = this.props
    actions.createExternalWorkLocation({
      work_location_id: venue.id,
      ...data,
      employer_id: employerID,
    })
    triggerEvent(trackingEvents.TRANSFER_RECORD.CREATED, {
      external_platform_id: data.external_platform_id,
      external_platform_reference: data.external_platform_reference,
    })
  }

  updateTransferRecord = data => {
    const { actions, triggerEvent, employerID, venue } = this.props
    actions.updateExternalWorkLocation({
      work_location_id: venue.id,
      ...data,
      employer_id: employerID,
    })
    triggerEvent(trackingEvents.TRANSFER_RECORD.UPDATED, {
      transfer_record_id: data.id,
      external_platform_id: data.external_platform_id,
      external_platform_reference: data.external_platform_reference,
    })
  }

  deleteTransferRecord = data => {
    const { actions, triggerEvent, employerID } = this.props
    actions.deleteExternalWorkLocation({
      id: data.id,
      work_location_id: data.work_location_id,
      employer_id: employerID,
    })
    triggerEvent(trackingEvents.TRANSFER_RECORD.DELETED, {
      transfer_record_id: data.id,
    })
  }

  render() {
    const { createTransferRecord, updateTransferRecord, deleteTransferRecord } = this
    const { id } = this.props
    return (
      <ExternalWorkLocationsTransferRecords
        actions={{
          createTransferRecord,
          updateTransferRecord,
          deleteTransferRecord,
        }}
        id={id}
      />
    )
  }
}

const EmployerVenueTransferRecord = connect(
  (state, props) => ({
    id: props.venue?.external_work_location?.id,
  }),
  dispatch => ({
    actions: bindActionCreators({ ...externalWorkLocationsActions, ...employerVenuesActions }, dispatch),
  })
)(withTrackingTriggerHoc(EmployerVenueTransferRecordUI))

export default EmployerVenueTransferRecord
