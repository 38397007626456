import React, { FC } from 'react'

import SubNavigation from 'syft-acp-core/components/Navigation/SubNavigation'
import EmployerRateCardList, {
  EmployerRateCardTrackingProvider,
} from 'syft-acp-core/entities/EmployerDetail/EmployerRateCardList'
import { RouteComponentProps } from 'react-router-dom'

type Props = RouteComponentProps<{
  id: string
}>

const NewEmployerRateCardList: FC<Props> = ({ match: { params } }: Props) => {
  const employerID = Number(params.id)

  return (
    <div className="page entity-list">
      <SubNavigation subPage="employers" data={{ employerID }} />
      <EmployerRateCardTrackingProvider employerId={employerID}>
        <EmployerRateCardList
          employerID={employerID}
          query={{ employer_ids: [employerID] }}
          urlBase={`/entity/employers/view/${params.id}/ratecards-v2/view/`}
        />
      </EmployerRateCardTrackingProvider>
    </div>
  )
}

export default NewEmployerRateCardList
