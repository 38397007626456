import * as React from 'karet'
import * as U from 'karet.util'
import * as R from 'kefir.ramda'
import PropTypes from 'prop-types'
import InputContainer from 'syft-acp-atoms/InputContainer'
import { PropTypesAtom } from 'syft-acp-util/observables'

import './InputText.css'

const InputText = ({ value, disabled, className, isMonospaced, isNonWrapped, textSuffix, textPrefix, isFullWidth }) => {
  const hasSuffix = R.is(String, textSuffix)
  const hasPrefix = R.is(String, textPrefix)
  return (
    <InputContainer>
      <span
        className={U.cns(
          'input-group',
          'acp-input-text-group',
          U.when(hasSuffix, 'has-suffix'),
          U.when(hasPrefix, 'has-prefix'),
          U.when(R.equals(isFullWidth, true), 'is-full-width'),
          U.when(R.equals(isNonWrapped, true), 'is-non-wrapped')
        )}
      >
        {U.when(hasPrefix, <span className={U.cns('input-group-addon')}>{hasPrefix}</span>)}
        <U.Input
          disabled={disabled}
          type="text"
          value={value}
          className={U.cns(
            'acp-input',
            'acp-input-text',
            className,
            U.when(R.equals(isMonospaced, true), 'monospace-text')
          )}
        />
        {U.when(hasSuffix, <span className={U.cns('input-group-addon')}>{textSuffix}</span>)}
      </span>
    </InputContainer>
  )
}

InputText.propTypes = {
  className: PropTypes.string,
  isMonospaced: PropTypes.oneOfType([PropTypesAtom, PropTypes.bool]),
  disabled: PropTypes.oneOfType([PropTypesAtom, PropTypes.bool]),
  isNonWrapped: PropTypes.oneOfType([PropTypesAtom, PropTypes.bool]),
  isFullWidth: PropTypes.oneOfType([PropTypesAtom, PropTypes.bool]),
  textPrefix: PropTypes.oneOfType([PropTypesAtom, PropTypes.bool, PropTypes.string]),
  textSuffix: PropTypes.oneOfType([PropTypesAtom, PropTypes.bool, PropTypes.string]),
  value: PropTypesAtom,
}

InputText.defaultProps = {
  className: '',
  isMonospaced: false,
  disabled: false,
  isNonWrapped: false,
  isFullWidth: false,
  textPrefix: false,
  textSuffix: false,
  value: null,
}

export default InputText
