// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import React from 'react'

import PageTitle from 'syft-acp-core/components/PageTitle'
import ReferralCodeList from 'syft-acp-core/entities/ReferralCodeList'

const ReferralCodes = () => (
  <PageTitle title={['Referral Codes', 'Marketing']}>
    <div className="page">
      <ReferralCodeList />
    </div>
  </PageTitle>
)
ReferralCodes.propTypes = {}

export default ReferralCodes
