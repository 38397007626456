import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { ControlLabel, HelpBlock, FormGroup } from 'react-bootstrap'
import { downloadAsFileFromApi } from 'syft-acp-core/api/file'

import { getModal$ } from 'syft-acp-core/store/modals/selectors'
import { Modal } from 'syft-acp-core/components/Modal'
import { showModal, hideModal } from 'syft-acp-core/store/modals/actions'
import { formatDatetime8601Tz } from 'syft-acp-util/formatting'

import './EmployerDownloadInvoicesModal.css'
import DatePicker from 'syft-acp-atoms/DatePicker'

class EmployerDownloadInvoicesModal extends PureComponent {
  static propTypes = {
    employerID: PropTypes.number.isRequired,
    item: PropTypes.object,
    dispatch: PropTypes.func.isRequired,
  }

  static defaultProps = {
    item: {},
  }

  constructor(props) {
    super(props)
    this.initialState = { msg: null, valid: true, fileType: 'zip' }
    this.state = this.initialState
  }

  componentDidUpdate(prevProps) {
    if (this.props.item.isShown !== prevProps.item.isShown && this.props.item.isShown === true) {
      this.setDefaultState()
    }
  }

  setDefaultState = () => this.setState(this.initialState)

  receiveData = data => {
    if (Object.keys(data.payload.attributes).length !== 0) {
      const msg = 'Something went wrong while downloading. Check the input data.'
      this.setState({ downloading: false, valid: false, msg })
    } else {
      this.setState({ downloading: false })
      this.closeModal()
    }
  }

  onConfirmHandler = weekValue => {
    const isValid = this.validate(weekValue)
    const type = this.state.fileType === 'zip' ? 'zip' : null

    if (isValid && this.state.downloading !== true) {
      this.setState({ downloading: true })
      const weekDate = formatDatetime8601Tz(weekValue.day('Monday'))
      const params = { employer_id: this.props.employerID, start_date: weekDate }
      const fileName = `one_line_invoicing.${type}`
      downloadAsFileFromApi(
        `/admin/employers/${fileName}`,
        params,
        fileName,
        type === 'zip' && 'application/octet-stream'
      ).finally(() => {
        this.setState({ downloading: false })
        this.closeModal()
      })
    }
  }

  validate = value => {
    if (!value) {
      this.setState({ valid: false, msg: 'Select a week number' })
      return false
    }

    this.setState({ valid: true, msg: null })
    return true
  }

  closeModal = () => this.props.dispatch(hideModal(this.props.item.modalName))

  openModal = () => this.props.dispatch(showModal(this.props.item.modalName))

  setFileType = type => {
    const prevState = this.state
    this.setState({ ...prevState, fileType: type })
  }

  render() {
    return (
      <Modal
        header="Download one line invoices"
        isShown={this.props.item.isShown}
        onClose={this.closeModal}
        onConfirm={this.onConfirmHandler}
        confirmationText="Download"
      >
        <div className="employer-email-invoices-modal">
          <FormGroup className="form-item" validationState={this.state.valid ? null : 'error'}>
            <div>
              <ControlLabel>Starting week</ControlLabel>
              <DatePicker
                name="oli_start_week"
                onChange={(name, val) => this.onConfirmHandler(val)}
                caption={{
                  icon: 'info',
                  message: 'Reports generated will run from Monday to Sunday of your selected dates',
                }}
              />
            </div>
            {this.state.downloading && <HelpBlock>Please wait...</HelpBlock>}
            {!this.state.valid && <HelpBlock>{this.state.msg}</HelpBlock>}
          </FormGroup>
        </div>
      </Modal>
    )
  }
}

export default connect(state => ({
  item: getModal$(state.modals)('employerDownloadInvoicesModal'),
}))(EmployerDownloadInvoicesModal)
