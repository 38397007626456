import * as structureTypes from 'syft-acp-core/components/EditableTable'

export const structure = [
  [
    'id',
    {
      header: 'ID',
    },
  ],
  [
    'owner_id',
    {
      header: 'Employer',
      editable: true,
      // Only display when the record has been saved.
      parameters: { displayOnSave: true, removable: true },
      type: structureTypes.TYPE_EMPLOYER,
    },
  ],
  [
    'created_at',
    {
      header: 'Created at',
      type: structureTypes.TYPE_TIMESTAMP,
    },
  ],
  [
    'updated_at',
    {
      header: 'Updated at',
      type: structureTypes.TYPE_TIMESTAMP,
    },
  ],
]
