// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import React from 'react'

import PageTitle from 'syft-acp-core/components/PageTitle'
import EmployerList from 'syft-acp-core/entities/EmployerList'

/**
 * Lists all employers.
 *
 * @returns {XML} ListEntities component
 */
const EmployerListView = () => (
  <PageTitle title="Employers">
    <div className="page entity-list">
      <EmployerList />
    </div>
  </PageTitle>
)

export default EmployerListView
