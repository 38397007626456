// Syft ACP - Atoms <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import Star from './Star'
import './RatingStars.css'

export default class RatingStars extends React.PureComponent {
  static propTypes = {
    // Whether to show the amount as a float value as well.
    showAmount: PropTypes.bool,
    // Actual rating we should display (Should always be between 0-5 or null).
    amount: PropTypes.oneOf([PropTypes.number, PropTypes.string]),
    // The maximum amount of stars shown. (Should always be 5.)
    maxAmount: PropTypes.number,
  }

  static defaultProps = {
    showAmount: false,
    amount: 0,
    maxAmount: 5,
  }

  /** Returns an array of stars that matches the given amount. */
  starNodes = (amount, maxAmount) => {
    const starNodes = []
    for (let a = 0; a < maxAmount; ++a) {
      const remainder = amount - a
      starNodes.push(
        <Star
          key={String(a)}
          isFull={remainder >= 1}
          isHalf={remainder < 1 && remainder >= 0}
          isEmpty={remainder < 0 || amount == null}
        />
      )
    }
    return starNodes
  }

  render() {
    const { amount, showAmount, maxAmount } = this.props

    // Convert the text amount to a float with 1 decimal.
    const amountText = showAmount ? Number(amount).toFixed(1) : null
    const starNodes = this.starNodes(amount, maxAmount)

    return (
      <div className={classnames('acp-atom-rating-stars')}>
        <div className="stars">{starNodes}</div>
        {amountText}
      </div>
    )
  }
}
