import { flowRight, isEqual } from 'lodash-es'
import React, { useCallback, useEffect, Fragment } from 'react'
import deepEqual from 'react-fast-compare'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import * as modalActions from 'syft-acp-core/store/modals/actions'
import * as leaveActions from 'syft-acp-core/store/leaveRequests/actions'
import { LeaveRequestEntity } from 'syft-acp-core/store/leaveRequests/types'
import Acp from 'syft-acp-uikit'
import { CheckIcon, PersonIcon } from 'syft-acp-uikit/components/AcpTable/headerComponents'
import { FilterEnum, FilterWorkerStatuses } from 'syft-acp-util/components/FilterForm'
import { createEntityListConnector } from 'syft-acp-util/entityList'
import usePrevValue from 'syft-acp-util/hooks/usePrevValue'
import { Store } from 'syft-acp-core/store'

import { WorkerListDispatchProps as DispatchProps, WorkersListProps as Props } from './LeaveRequestList.types'
import { showModal } from 'syft-acp-core/store/modals/actions'
import DownloadCSVModal from '../../components/Modal/DownloadCSVModal/DownloadCSVModal'
import { RowValue } from '../ShiftList/helpers/helpers.types'
import { useTrackingTrigger } from '@indeed/flex-tracking-context'
import { trackingEvents } from './tracking'

const LEAVE_REQUESTS_CHECKBOX_KEY = 'leaveRequests'

export const entityConnector = createEntityListConnector<LeaveRequestEntity>({
  entityActions: { fetchEntities: leaveActions.fetchLeaveRequests },
  entityStore: 'workerLeaveRequests',
})

export const storeConnector = connect(
  (state: Store) => ({
    selectedLeaveRequests: state.checkboxes.items[LEAVE_REQUESTS_CHECKBOX_KEY] || [],
  }),
  (dispatch, props: DispatchProps) => ({
    actions: {
      ...props.actions,
      ...bindActionCreators({ ...modalActions, ...leaveActions, showModal }, dispatch),
    },
  })
)

const rowValue = (row: RowValue) => [{ id: row.id }]

const getSelectedIds = (selectedLeaveRequests: LeaveRequestEntity[]) => selectedLeaveRequests.map(item => item.id)

const MODAL_NAME = 'downloadLeaveRequestsCSV'

const dateFormat = { hour: undefined, minute: undefined, second: undefined, timeZoneName: undefined }

const modalLabelTableRange = {
  created_at: 'Leave date',
}

const LeaveRequestList = ({ actions, query, entityList, isLoadingData, pagination, selectedLeaveRequests }: Props) => {
  const prevQuery = usePrevValue(query)
  const fetch = useCallback(
    (q: Record<string, any>, toCSV = false) =>
      actions.fetchEntities && actions.fetchEntities({ options: q }, {}, toCSV),
    [actions]
  )

  const triggerEvent = useTrackingTrigger()

  const actionDownloadCSV = useCallback(
    (view?: string) => () => {
      const hasFilters = !isEqual(query, {})
      actions.showModal(MODAL_NAME, {
        onConfirm: () => {
          triggerEvent(
            view === 'paycircle'
              ? trackingEvents.LEAVERS_REPORT.DOWNLOAD_PAYCIRCLE_CSV
              : trackingEvents.LEAVERS_REPORT.DOWNLOAD_FULL_CSV,
            { ...query }
          )
          actions.fetchLeaveRequestsCSV(query, view)
        },
        query: query,
        total: pagination.total,
        hasFilters: hasFilters,
      })
    },
    [actions, pagination.total, query]
  )

  const downloadCSVDropdownOptions = [
    {
      label: 'Download Paycircle CSV',
      action: actionDownloadCSV('paycircle'),
    },
    {
      label: 'Download Full CSV',
      action: actionDownloadCSV(),
    },
  ]

  const markAsDropdownOptions = [
    {
      label: 'Processed',
      action: () => {
        const ids = getSelectedIds(selectedLeaveRequests)
        triggerEvent(trackingEvents.LEAVERS_REPORT.MARK_AS_PROCESSED, { ids })
        actions.changeProcessedStatusLeaveRequests(ids, true)
      },
    },
    {
      label: 'Not processed',
      action: () => {
        const ids = getSelectedIds(selectedLeaveRequests)
        triggerEvent(trackingEvents.LEAVERS_REPORT.MARK_AS_UNPROCESSED, { ids })
        actions.changeProcessedStatusLeaveRequests(ids, false)
      },
    },
  ]

  useEffect(() => {
    if (!deepEqual(prevQuery, query)) {
      fetch(query)
    }
  }, [fetch, prevQuery, query])

  return (
    <Fragment>
      <Acp.EntityList
        data={{
          data: entityList,
          meta: pagination,
        }}
        isLoading={isLoadingData}
        idKeyValue="id"
        hasResultCount
        hasPagination
        inContainer
      >
        <Acp.Actions>
          <Acp.ButtonDropdown
            title="Download CSV"
            pullRight
            disabled={isLoadingData || !pagination.total || pagination.total === 0}
          >
            {downloadCSVDropdownOptions.map((option, i) => (
              <Acp.Option key={i} onClick={option.action}>
                {option.label}
              </Acp.Option>
            ))}
          </Acp.ButtonDropdown>
          <Acp.ButtonDropdown title="Mark as" pullRight disabled={selectedLeaveRequests.length === 0}>
            {markAsDropdownOptions.map((option, i) => (
              <Acp.Option key={i} onClick={option.action} data-testid={`Mark as ${option.label}`}>
                {option.label}
              </Acp.Option>
            ))}
          </Acp.ButtonDropdown>
          <Acp.FilterGroup title="Worker id">
            <Acp.Filter.Text name="worker_id" placeholder="ID" />
          </Acp.FilterGroup>
          <Acp.FilterGroup title="Name">
            <Acp.Filter.Text name="worker_name" placeholder="Name" />
          </Acp.FilterGroup>
          <Acp.FilterGroup title="Leave date">
            <Acp.Filter.DateRange nameFrom="created_at_gte" nameTo="created_at_lte" />
          </Acp.FilterGroup>
          <Acp.FilterGroup title="Worker status">
            <FilterWorkerStatuses name="status" allowAny />
          </Acp.FilterGroup>
          <Acp.FilterGroup title="Status">
            <FilterEnum
              name="processed"
              options={[
                [{ value: '', label: 'All' }],
                [
                  { value: 'true', label: 'Processed', type: 'success', bright: true },
                  { value: 'false', label: 'Not processed', type: 'danger', bright: true },
                ],
              ]}
            />
          </Acp.FilterGroup>
        </Acp.Actions>
        <Acp.Table>
          <Acp.Col.Selector
            value={rowValue}
            headerValue={rowValue}
            options={{ scope: 'leaveRequests', valueKeys: ['id'] }}
            align="center"
            isMinimal
            colNoLink
          />
          <Acp.Col.Boolean value="processed" header={<CheckIcon />} headerTooltip="Processed" isMinimal />
          <Acp.Col.Text value="worker.id" header="Worker ID" isCopyable isNumeric isMinimal />
          <Acp.Col.Image value="worker.profile_picture" header={<PersonIcon />} headerTooltip="Picture" isMinimal />
          <Acp.Col.Text value="worker.title" header="Title" isCopyable isMinimal />
          <Acp.Col.Text value="worker.full_name" header="Name" isCopyable isMain />
          <Acp.Col.DateTime value="created_at" header="Leave date" isMinimal options={dateFormat} />
          <Acp.Col.WorkerStatus value="worker.status" header="Worker status" isTags isMinimal />
          <Acp.Col.Text value="reason_code" header="Reason" isCopyable />
          <Acp.Col.Text value="comment" header="Comment" isCopyable options={{ isNonWrapped: true }} />
          <Acp.Col.Text value="app_rating.feedback" header="Feedback" isCopyable />
          <Acp.Col.Rating value="app_rating.star" header="Rating" />
        </Acp.Table>
      </Acp.EntityList>
      <DownloadCSVModal name={MODAL_NAME} labelTableRange={modalLabelTableRange} />
    </Fragment>
  )
}

export default flowRight(entityConnector, storeConnector)(LeaveRequestList)
