// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited
import { reduce, isEmpty, get } from 'lodash-es'
import { tdrProps } from 'syft-acp-core/components/RateCard/constants'

import { apiRequest, handleCall } from 'syft-acp-core/api/call'

const transformData = data => {
  return reduce(
    data,
    (acc, val, key) => {
      if (
        val === 0 ||
        (Array.isArray(val) && isEmpty(val)) ||
        (key === 'rateTotal' && get(val, 'amount') === '') ||
        ((key === 'end' || key === 'start') && val === '')
      )
        return acc
      return {
        ...acc,
        [tdrProps[key]]: val,
      }
    },
    {},
  )
}

export const saveTimeDependentRates = ({ data, meta = { transform: true } }) => {
  return new Promise((resolve, reject) => {
    const req = apiRequest({
      path: '/admin/time_dependent_rates',
      reqArgs: meta.transform ? transformData(data) : data,
      method: 'POST',
    })
    handleCall(req, resolve, reject)
  })
}

export const updateTimeDependentRates = ({ data, meta = { transform: true } }) => {
  return new Promise((resolve, reject) => {
    const req = apiRequest({
      path: `/admin/time_dependent_rates/${data.id}`,
      reqArgs: meta.transform ? transformData(data) : data,
      method: 'PATCH',
    })
    handleCall(req, resolve, reject)
  })
}
