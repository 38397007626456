// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import * as types from './action-types'

export function listWorkerNetworks({ options }, _ /**props */, toCSV = false) {
  return { type: types.WORKER_NETWORKS_FETCH_BEGIN, ...options, toCSV }
}

export function fetchWorkerNetwork(workerID, networkID) {
  return { type: types.WORKER_NETWORK_FETCH_BEGIN, workerID, networkID }
}

export function createWorkerNetwork(workerID, objectIDs) {
  return { type: types.WORKER_NETWORK_CREATE_BEGIN, workerID, objectIDs }
}

export function deleteWorkerNetwork(workerID, objectIDs) {
  return { type: types.WORKER_NETWORK_DELETE_BEGIN, workerID, objectIDs }
}
