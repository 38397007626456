// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import React from 'react'
import PropTypes from 'prop-types'

import EmployerRatingsListComponent from 'syft-acp-core/entities/EmployerDetail/EmployerRatingsList'
import SubNavigation from 'syft-acp-core/components/Navigation/SubNavigation'

const EmployerRatingsList = ({ match: { params } }) => (
  <div className="page entity-list">
    <SubNavigation subPage="employers" data={{ employerID: Number(params.id) }} />
    <EmployerRatingsListComponent employerID={Number(params.id)} />
  </div>
)

EmployerRatingsList.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      // Note: ID gets passed as a string directly from the URL.
      id: PropTypes.string.isRequired,
    }).isRequired,
  }),
}

export default EmployerRatingsList
