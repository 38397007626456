import React, { useState } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { Grid, Row, Col } from 'react-bootstrap'
import { FileWithPath } from 'react-dropzone'
import { get } from 'lodash-es'
import { bindActionCreators } from 'redux'
import { useIntl } from 'react-intl'

import SiteComponent from 'syft-acp-core/components/SiteComponent'
import FileUpload from 'syft-acp-core/components/FileUpload'
import RecordSection from 'syft-acp-core/components/RecordSection'
import RecordSegment from 'syft-acp-core/components/RecordSection/RecordSegment'
import { Button } from 'syft-acp-atoms/Button'
import Table from 'syft-acp-util/components/Table'

import FilterAutocompleteEmployers from 'syft-acp-util/components/FilterForm/FilterAutocompleteEmployers'
import VenueNamesField from 'syft-acp-core/components/RateCard/RateCardForm/RateCardFormFields/VenueNamesField'
import TemplatesDropdown from 'syft-acp-core/components/RateCard/RateCardForm/RateCardFormFields/TemplatesDropdown'
import { importListingsData } from 'syft-acp-core/store/import-file/actions'

import ImportResultList from './ImportResultList'
import './ImportView.css'

const connector = connect(null, dispatch => ({
  actions: bindActionCreators({ importListingsData }, dispatch),
}))

type PropsFromRedux = ConnectedProps<typeof connector>

export const ImportsView = ({ actions }: PropsFromRedux) => {
  const intl = useIntl()
  const [employerId, setEmployerId] = useState<number>()
  const [venueId, setVenueId] = useState<number>()
  const [templateId, setTemplateId] = useState<number>()
  const [file, setFile] = useState<FileWithPath>()

  const enabled = !!employerId && !!venueId && !!templateId && !!file

  const uploadImage = (accepted: FileWithPath[]) => {
    setFile(accepted[0])
  }

  const handleSubmit = () => {
    if (!enabled) return
    actions.importListingsData({
      file,
      employerId,
      venueId,
      templateId,
      countryCode: get(intl, 'countryCode'),
    })
    const undefinedState = undefined
    setEmployerId(undefinedState)
    setVenueId(undefinedState)
    setTemplateId(undefinedState)
    setFile(undefinedState)
  }

  return (
    <SiteComponent>
      <Grid>
        <Row>
          <Col md={12}>
            <RecordSection
              footerText={
                <Button
                  disabled={!enabled}
                  onClick={handleSubmit}
                  className="import-submit-button"
                  kind="primary"
                >
                  IMPORT
                </Button>
              }
              title="Imports"
            >
              <RecordSegment withTable>
                <Table noRightBorder noRowBorders className="editable-table RateCardFormFields">
                  <tbody>
                    <tr>
                      <th className="pre-icon text-nowrap">Employer</th>
                      <td className="field">
                        <FilterAutocompleteEmployers
                          disabled={false}
                          forceValue={employerId}
                          callback={setEmployerId}
                          fullWidth
                        />
                      </td>
                    </tr>
                    <tr className="import-row">
                      <th className="pre-icon text-nowrap">Venue</th>
                      <td className="field">
                        <VenueNamesField
                          activeVenue={venueId}
                          employerID={employerId}
                          updateVenue={setVenueId}
                        />
                      </td>
                    </tr>
                    <tr className="import-row">
                      <th className="pre-icon text-nowrap">Template</th>
                      <td className="field">
                        <TemplatesDropdown
                          templateId={templateId}
                          // @ts-expect-error
                          employerID={employerId}
                          updateVenue={setTemplateId}
                        />
                      </td>
                    </tr>
                    <tr>
                      <th className="pre-icon text-nowrap">Upload</th>
                      <td className="field">
                        <FileUpload
                          accept={{ 'text/csv': ['.csv'] }}
                          buttonText="Choose file"
                          onDrop={uploadImage}
                        />
                        {file?.name}
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </RecordSegment>
            </RecordSection>
            <RecordSection title="Result log">
              <div>
                <ImportResultList />
              </div>
            </RecordSection>
          </Col>
        </Row>
      </Grid>
    </SiteComponent>
  )
}

export default connector(ImportsView)
