import * as types from './action-types'

/**
 * Calls the API to receive a login token for a specific employer.
 * Once the employer's login URL is received, it's sent to the callback.
 *
 * @param id {Number} ID of the employer
 * @param callback {Function} Callback function to open the URL
 */
export function loginAsAgency(id: number, callback: () => void) {
  return { type: types.AGENCY_LOGIN_AS_BEGIN, id, callback }
}
