import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { useFlexFlagIsOn } from '@indeed/flex-feature-flags'
import { Button } from 'syft-acp-atoms/Button'
import { history } from 'syft-acp-core/history'

const NewStrikeButton = ({ workerID }) => {
  const isNegativeAction = useFlexFlagIsOn('pte_34577_negative_actions')
  const handleOnClick = useCallback(() => {
    isNegativeAction
      ? history.push(`/entity/workers/view/${workerID}/negative-actions/new`)
      : history.push(`/entity/workers/view/${workerID}/strikes/new`)
  }, [isNegativeAction, workerID])

  return (
    <div className="small-padded" style={{ display: 'inline-block' }}>
      <Button kind="success" onClick={handleOnClick}>
        {isNegativeAction ? 'New negative action' : 'New Strike'}
      </Button>
    </div>
  )
}

NewStrikeButton.propTypes = {
  workerID: PropTypes.number.isRequired,
  label: PropTypes.string.isRequired,
}

export default NewStrikeButton
