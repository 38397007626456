import { AdhocShiftResponseEntity } from 'syft-acp-core/store/adhocShiftBookings/types'
import entityReducer from 'syft-acp-core/reducers/generators/entityReducer'
import * as entityTypes from 'syft-acp-core/reducers/generators/entities'
import {
  ADHOC_SHIFT_APPROVE_BEGIN,
  ADHOC_SHIFT_APPROVE_FAILED,
  ADHOC_SHIFT_APPROVE_SUCCEEDED,
  ADHOC_SHIFT_FETCH_BEGIN,
  ADHOC_SHIFT_FETCH_FAILED,
  ADHOC_SHIFT_FETCH_SUCCEEDED,
  ADHOC_SHIFT_REJECT_BEGIN,
  ADHOC_SHIFT_REJECT_FAILED,
  ADHOC_SHIFT_REJECT_SUCCEEDED,
} from './action-types'

export default entityReducer<AdhocShiftResponseEntity>('adhocShiftsApproval', {
  [entityTypes.ENTITY_UPDATE_BEGIN]: [ADHOC_SHIFT_APPROVE_BEGIN, ADHOC_SHIFT_REJECT_BEGIN],
  [entityTypes.ENTITY_UPDATE_SUCCEEDED]: [ADHOC_SHIFT_APPROVE_SUCCEEDED, ADHOC_SHIFT_REJECT_SUCCEEDED],
  [entityTypes.ENTITY_UPDATE_FAILED]: [ADHOC_SHIFT_APPROVE_FAILED, ADHOC_SHIFT_REJECT_FAILED],
  [entityTypes.ENTITY_FETCH_BEGIN]: ADHOC_SHIFT_FETCH_BEGIN,
  [entityTypes.ENTITY_FETCH_SUCCEEDED]: ADHOC_SHIFT_FETCH_SUCCEEDED,
  [entityTypes.ENTITY_FETCH_FAILED]: ADHOC_SHIFT_FETCH_FAILED,
})
