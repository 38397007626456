import { merge, omit } from 'lodash-es'

import { getPageNumber } from 'syft-acp-core/store/filters/helpers'
import { DEFAULT_PAGE_SIZE } from 'syft-acp-core/api/call'

/**
 * Unifies page key
 *
 * @param query
 * @param pageKey
 */
export const unifyPageKey = (query: Record<string, any>, pageKey?: string) => {
  // Remove all page numbers from the query.
  const cleanQuery = Object.keys(query).reduce(
    (acc, k) => (k.startsWith('$') ? acc : { ...acc, [k]: query[k] }),
    {} as Record<string, any>,
  )

  if (pageKey) {
    return { ...cleanQuery, page: query[pageKey] }
  }

  return cleanQuery
}

/**
 * Returns unified query params
 */
export const createQuery = ({
  queryFromProps,
  staticArgs,
  enabledRoutingQuery,
  pageKey,
}: {
  queryFromProps?: Record<string, any>
  staticArgs?: Record<string, any>
  enabledRoutingQuery: Record<string, string | string[] | null | undefined>
  pageKey?: string
}) =>
  unifyPageKey(
    queryFromProps
      ? merge({}, enabledRoutingQuery, queryFromProps, staticArgs)
      : merge({}, enabledRoutingQuery, staticArgs),
    pageKey,
  )

/**
 * Returns pagination details for an array.
 * This can be used to display large arrays with pagination,
 * in absence of the standard pagination variables sent by the API.
 */
export const arrayPagination = (list: any[], activePage = getPageNumber(), perPage = DEFAULT_PAGE_SIZE) => {
  const total = list.length
  const totalPages = Math.ceil(total / perPage)
  return {
    activePage,
    nextPage: activePage > totalPages ? null : activePage + 1,
    page: activePage,
    perPage,
    total,
    totalPages,
  }
}
/**
 * Removes pagination variables from the query if we are using local pagination.
 */
export const toggleQueryPage = (query: Record<string, any>, removePage = false, pageVariable = 'page') =>
  removePage ? omit(query, pageVariable) : query

/**
 * Slices the data for the current page out of an array of items.
 * Used in conjunction with arrayPagination().
 */
export const arrayGetPage = <T>(entityListData: T[], pagination: Record<string, any>) => {
  const start = (pagination.activePage - 1) * pagination.perPage
  const end = start + pagination.perPage
  return entityListData.slice(start, end)
}

/**
 *  Removes the 'page' variable from a list of query arguments.
 */
export const removePagination = (args: object) => omit(args, ['page'])
