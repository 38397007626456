import React from 'react'
import { datadogRum } from '@datadog/browser-rum-slim'

type TagValue = string | number | boolean
type RumGlobalContextTags = { [key: string]: TagValue }

export const removeGlobalContextTag = (key: string): void => {
  const context = datadogRum.getGlobalContext()
  const tags = (context.globalTags as RumGlobalContextTags) || {}
  const { [key]: _, ...outputTags } = tags

  datadogRum.setGlobalContextProperty('globalTags', outputTags)
}

export const addGlobalContextTag = (key: string, value?: TagValue): void => {
  const context = datadogRum.getGlobalContext()
  const tags = (context.globalTags as RumGlobalContextTags) || {}

  datadogRum.setGlobalContextProperty('globalTags', {
    ...tags,
    [key]: value ?? true,
  })
}

export const useAddComponentRumTag = (key: string, value?: TagValue): void => {
  React.useEffect(() => {
    addGlobalContextTag(key, value)

    return () => {
      removeGlobalContextTag(key)
    }
  }, [key, value])
}
