import { useCallback, useMemo } from 'react'
import { map, min, max, uniq, get, pickBy, identity } from 'lodash-es'
import moment from 'moment'
import { addTimezone } from 'syft-acp-util/formatting'
import useDeepEqualMemoizedDependencies from './useDeepEqualMemoizedDependencies'

import useFormatDateTime from './useFormatDateTime'

const intlFormatTimeRange =
  formatDate =>
  (dates, options = {}) => {
    const format = options.format || {}
    const separator = options.separator || '-'
    const parsedDates = map(dates, date => addTimezone(date, format.timeZone))
    const range = [min(parsedDates), max(parsedDates)]
    const timeSpanParts = range.map(date =>
      formatDate(date, {
        toParts: true,
        format: {
          ...format,
          ...(parseInt(moment(date).format('mm'), 10) === 0
            ? { hour: 'numeric' }
            : { hour: 'numeric', minute: '2-digit' }),
        },
      }),
    )

    const areTimezoneNamesEqual =
      uniq(
        timeSpanParts.map(parts =>
          get(
            parts.find(part => part.type === 'timeZoneName'),
            'value',
          ),
        ),
      ).length === 1

    return timeSpanParts
      .map((parts, idx) =>
        // we want to show timezone names for both start & end time only if they are different
        (format.timeZoneName && idx === 0 && areTimezoneNamesEqual
          ? parts.slice(0, parts.length - 2) // literal & timeZoneName parts
          : parts
        )
          .map(part => part.value)
          .join(''),
      )
      .join(separator)
  }

const useFormatTimeRange = (dates, options) => {
  // eslint-disable-next-line no-unused-vars
  const [_, formatDate] = useFormatDateTime()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const cleanedOptions = useMemo(() => pickBy(options, identity), useDeepEqualMemoizedDependencies([options]))
  const formatTimeRange = useCallback((...args) => intlFormatTimeRange(formatDate)(...args), [formatDate])
  const formatted = useMemo(() => {
    if (dates) return formatTimeRange(dates, cleanedOptions)
    return ''
  }, [formatTimeRange, dates, cleanedOptions])

  return [formatted, formatTimeRange]
}

export default useFormatTimeRange
