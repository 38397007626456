import React, { useCallback, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import Acp from 'syft-acp-uikit'
import { Modal } from 'syft-acp-core/components/Modal'
import { checkItems } from 'syft-acp-core/store/checkboxes/actions'
import { deleteAgencyUnfilledAllocations } from 'syft-acp-core/store/agency-unfilled-allocations/actions'

import { getModal$ } from 'syft-acp-core/store/modals/selectors'
import { hideModal } from 'syft-acp-core/store/modals/actions'
import { formatProvidedRequestedWorkers } from 'syft-acp-core/entities2/ShiftAgencyList/helpers'

import {
  CascadeFullJobModalOwnProps as OwnProps,
  CascadeFullJobModalProps as Props,
  AgencyUnfilledAllocation,
  Checkbox,
} from './CascadeFullJobModal.types'

const defaultModalName = 'cascadeFullModal'
const scopeName = 'cascadeWorkers'

export const storeConnector = connect<
  Record<string, any>,
  { closeModal: () => ReturnType<typeof hideModal> },
  OwnProps
>(
  (state, { name }) => ({
    item: getModal$(state.modals)(name || defaultModalName),
    // @ts-expect-error
    agencyUnfilledAllocations: state?.agencyUnfilledAllocations?.entityDetail?.undefined
      ?.agency_shifts as AgencyUnfilledAllocation[],
    checkboxes: state.checkboxes?.items?.cascadeWorkers as Checkbox[],
  }),
  (dispatch, { name }) => ({
    closeModal: () => dispatch(hideModal(name || defaultModalName)),
    checkItemsHandle: ({ items, scope, checked }: { items: Checkbox[]; scope: string; checked: boolean }) =>
      dispatch(checkItems(items, scope, checked)),
    deleteAgencyUnfilledAllocationsHandle: (ids: number[], refetch: () => void) =>
      dispatch(deleteAgencyUnfilledAllocations(ids, refetch)),
  })
)

const getData = (e: AgencyUnfilledAllocation) => [
  {
    id: e.id,
  },
]

const CascadeFullJobModal = ({
  item = {},
  closeModal,
  agencyUnfilledAllocations,
  checkboxes,
  checkItemsHandle,
  deleteAgencyUnfilledAllocationsHandle,
}: Props) => {
  const { isShown, data } = item
  const onConfirm = useCallback(() => {
    const ids = checkboxes
      .filter((checkboxData: Checkbox) =>
        agencyUnfilledAllocations.find(
          (agencyUnfilledAllocationData: AgencyUnfilledAllocation) =>
            agencyUnfilledAllocationData.id === checkboxData.id
        )
      )
      .map(({ id }: Checkbox) => id)
    deleteAgencyUnfilledAllocationsHandle(ids, data.refetch)
    closeModal()
  }, [closeModal, checkboxes, deleteAgencyUnfilledAllocationsHandle, agencyUnfilledAllocations, data])
  const onClose = useCallback(() => {
    closeModal()
  }, [closeModal])

  const [modalData, setModalData] = useState(agencyUnfilledAllocations)
  useEffect(() => {
    setModalData(agencyUnfilledAllocations)
    const checkedItems = agencyUnfilledAllocations?.map(({ id }: { id: number }) => ({
      id,
    }))
    checkItemsHandle({ items: checkedItems, scope: scopeName, checked: true })
  }, [agencyUnfilledAllocations, checkItemsHandle])

  return (
    <Modal
      header="Cascade job"
      cancelText="Cancel"
      confirmationText="Confirm"
      isShown={isShown}
      onClose={onClose}
      onConfirm={onConfirm}
    >
      <Acp.EntityList hasResultCount hasPagination idKeyValue="id" data={{ data: modalData }}>
        <Acp.Table>
          <Acp.Col.Selector
            value={getData}
            headerValue={getData}
            options={{ scope: scopeName, valueKeys: ['id'] }}
            align="center"
            isMinimal
            colNoLink
          />
          <Acp.Col.Timestamp value={['start_time', 'end_time']} header="Start/end" isMinimal hasNoneStyles />
          <Acp.Col.Text
            value={formatProvidedRequestedWorkers}
            header="P/R"
            headerTooltip="Workers Provided/Requested"
            hasNoneStyles
          />
        </Acp.Table>
      </Acp.EntityList>
    </Modal>
  )
}

export default storeConnector(CascadeFullJobModal)
