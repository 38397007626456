import { FORM_ERROR } from 'final-form'
import { FormValues } from './InvoicingCSVModal.types'
import { isEmpty } from 'lodash-es'

export const formValidationError = {
  [FORM_ERROR]: 'Please choose at least one type of report',
}
export const invoiceNumberValidationError = 'Invoice number must be greater than 0'

export const validateForm = ({ masterInvoicingData, workweekInvoicingData, workingWeekOptions }: FormValues) => {
  if (isEmpty(masterInvoicingData) && (isEmpty(workweekInvoicingData) || isEmpty(workingWeekOptions))) {
    return formValidationError
  }

  return undefined
}

export const validateInvoicingNumber = (value: number) => {
  const isNotValid = !value || !Number(value) || value < 0
  if (isNotValid) {
    return invoiceNumberValidationError
  }

  return undefined
}
