// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { get } from 'lodash-es'

import Table from 'syft-acp-util/components/Table'
import LabeledCheckbox from 'syft-acp-core/components/LabeledCheckbox'

import './DataBooleanMatrix.css'

const DataBooleanMatrix = ({ structure, labels, editable, onChange, data }) => {
  const onChangeValue = useCallback(
    (value, [parent, child]) => {
      if (value === data[parent][child]) return
      const newData = {
        ...data,
        [parent]: {
          ...data[parent],
          [child]: value,
        },
      }
      onChange(newData)
    },
    [data, onChange]
  )
  return (
    <Table bordered rounded minimal colBorders className="DataBooleanMatrix">
      <tbody>
        {/* Header row, for the column labels. */}
        <tr>
          {/* Extra column for the row labels. */}
          <th>&nbsp;</th>
          {structure.cols.map((col, n) => (
            <th key={n}>{labels[col]}</th>
          ))}
        </tr>
        {structure.rows.map((row, n) => (
          <tr key={n}>
            {/* Header column, for the row labels. */}
            <th>{labels[row]}</th>
            {/* The actual values. */}
            {structure.cols.map((col, m) => (
              <td key={m}>
                {
                  <LabeledCheckbox
                    callback={() => onChangeValue(!data[col][row], [col, row])}
                    editable={editable}
                    checked={get(data, [col, row], false)}
                  />
                }
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </Table>
  )
}

DataBooleanMatrix.defaultProps = {
  editable: true,
}

DataBooleanMatrix.propTypes = {
  structure: PropTypes.shape({
    rows: PropTypes.arrayOf(PropTypes.string),
    cols: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
  labels: PropTypes.objectOf(PropTypes.string).isRequired,
  data: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  editable: PropTypes.bool,
}

export default DataBooleanMatrix
