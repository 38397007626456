import * as React from 'react'
import { connect } from 'react-redux'

import { FilterShiftsViewFromProps } from './types'
import { filterFunctions } from './helpers'

import { FilterEnum } from '../index'

const FilterShiftsViewFrom = ({ userId, userSettingsDetail, onChange }: FilterShiftsViewFromProps) => {
  const timezone = userSettingsDetail?.[userId]?.timezone
  const todaySettings = filterFunctions({
    timezone,
    fromUK: 0,
    toUK: 1,
    fromUS: 0,
    toUS: 2,
    currentTime: true,
  })

  const tomorrowSettings = filterFunctions({
    timezone,
    fromUK: 1,
    toUK: 2,
    fromUS: 2,
    toUS: 3,
  })

  const sevenDaysSettings = filterFunctions({
    timezone,
    fromUK: 0,
    toUK: 8,
    fromUS: 1,
    toUS: 9,
  })

  return (
    <FilterEnum
      onChange={onChange}
      options={[
        [
          {
            ...todaySettings,
            label: 'Today',
            name: ['start_time_gte', 'start_time_lte'],
            defaultValue: true,
          },
        ],
        [
          {
            ...tomorrowSettings,
            label: 'Tomorrow',
          },
          {
            ...sevenDaysSettings,
            label: 'Next 7 days',
          },
        ],
      ]}
    />
  )
}

export default connect(state => ({
  userId: state.auth?.userData?.id,
  userSettingsDetail: state.userSettings?.entityDetail,
}))(FilterShiftsViewFrom)
