// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import { keyBy } from 'lodash-es'
import PropTypes from 'prop-types'
import React from 'react'

import ModalTable from 'syft-acp-core/components/ModalTable'
import { Formi9Labels, PaycomLabels, WorkerLabels } from 'syft-acp-core/components/TypeMaps'
import { store } from 'syft-acp-core/store'

// Table for displaying human readable labels for our search query items.
// FIXME: this is optimized only for the workers query variables.
// Double check if they're correct when using on other pages' query variables.
const defaultLabelTable = {
  active_in_city_id: 'Active in city',
  email: 'Email address',
  industry_code: 'Industry',
  query: 'Worker name',
  role_id: 'Role',
  status: 'Worker status',
  telephone_number: 'Telephone number',
  i9form_status: 'Form I9 Status',
  paycom_status: 'Pay Status',
}
// These have '_gte' and '_lte' variants.
const defaultLabelTableRange = {
  age: 'Age',
  completed_shifts: 'Completed shifts',
  created_at: 'Joined Syft',
  rating: 'Rating',
  trusted_networks: 'Number of networks',
}

// Capitalizes the first letter of a string.
const capitalizeFirst = string => `${string.charAt(0).toUpperCase()}${string.slice(1)}`

// Makes a key slightly prettier. E.g. 'skill_id' becomes 'Skill ID'.
const prettyPrintKey = key =>
  key
    .split('_')
    .map(n => {
      if (n === 'id') return 'ID'
      else return capitalizeFirst(n)
    })
    .join(' ')
    // Fix camelCase ID names.
    .replace(/([^.])ID/g, '$1 ID')

const formatQuery = (queryVars, labelTable, labelTableRange) => {
  // Retrieve all roles from the store's industries state. Put them in an object by ID.
  const storeState = store.getState()
  const industries = storeState.industries.entityMap || {}
  const industryRoles = keyBy(
    Object.values(industries).reduce((acc, curr) => [...acc, ...(curr.roles || [])], []),
    'id'
  )
  const cities = storeState.cities.entityMap

  // Keep track of which ranged items we've seen so far.
  // That way we only display items like created_at_gte/created_at_lte only once.
  const seenItems = []
  return Object.keys(queryVars)
    .map(k => {
      if (k in labelTable) {
        switch (k) {
          case 'role_id':
            return [labelTable[k], industryRoles[queryVars[k]].title]
          case 'active_in_city_id':
            return [labelTable[k], cities[queryVars[k]].name]
          case 'industry_code':
            return [labelTable[k], Object.values(industries).filter(i => i.code === queryVars[k])[0].title]
          case 'status':
            return [
              labelTable[k],
              <div>
                <WorkerLabels value={{ [queryVars[k]]: true }} />
              </div>,
            ]
          case 'paycom_status':
            return [
              labelTable[k],
              <div>
                <PaycomLabels value={queryVars[k]} />
              </div>,
            ]
          case 'i9form_status':
            return [
              labelTable[k],
              <div>
                <Formi9Labels value={queryVars[k]} />
              </div>,
            ]
          default:
            return [labelTable[k], queryVars[k]]
        }
      }
      // If this is a range, e.g. an item with _gte and _lte, display only one row.
      const kPlain = k.split('_lte').join('').split('_gte').join('')
      const from = `${kPlain}_gte`
      const to = `${kPlain}_lte`
      if (kPlain in labelTableRange) {
        if (seenItems.includes(kPlain)) return null
        seenItems.push(kPlain)
        const fromData = queryVars[from]
        const toData = queryVars[to]
        const formattedData =
          fromData && toData ? `between ${fromData} and ${toData}` : fromData ? `≥${fromData}` : `≤${toData}`
        return [labelTableRange[kPlain], formattedData]
      }
      return [prettyPrintKey(k), queryVars[k]]
    })
    .filter(k => k !== null)
    .sort((a, b) => (a[0] >= b[0] ? 1 : -1))
}

const QueryTable = ({ queryData, labelTable = defaultLabelTable, labelTableRange = defaultLabelTableRange }) => {
  const tableData = formatQuery(queryData, labelTable, labelTableRange)
  return (
    <ModalTable>
      {tableData.map((row, n) => (
        <tr key={n}>
          <th>{row[0]}</th>
          <td>{row[1]}</td>
        </tr>
      ))}
    </ModalTable>
  )
}

QueryTable.propTypes = {
  queryData: PropTypes.object,
  labelTable: PropTypes.object,
  labelTableRange: PropTypes.object,
}

QueryTable.defaultProps = {
  queryData: undefined,
  labelTable: undefined,
  labelTableRange: undefined,
}

export default QueryTable
