import React from 'react'
import { useWorkWeekVariants } from 'syft-acp-util/hooks/useWorkWeekVariants'
import { Props } from './WorkWeekVariants.types'

const WeekRange: React.FC = ({ children }) => <div>{children}</div>

export const WorkWeekVariants = ({ component: Component, ...rest }: Props) => {
  const weekStartRanges = useWorkWeekVariants()
  return (
    <React.Fragment>
      {weekStartRanges.map(({ range: daysRange, label }) => (
        <Component key={daysRange.join()} range={daysRange} {...rest}>
          {label}
        </Component>
      ))}
    </React.Fragment>
  )
}

WorkWeekVariants.defaultProps = {
  component: WeekRange,
}
