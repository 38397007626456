// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited
// TODO - fix the eslint-disable-next-line consistent-return comments in this file

import FileSaver from 'file-saver'
import { get, isArray, isPlainObject, pick, pickBy } from 'lodash-es'
import { history } from 'syft-acp-core/history'
import { addError } from 'syft-acp-core/actions/errors'

import { store } from 'syft-acp-core/store'
import config from 'syft-acp-core/config'
import { APPLICATION_ID } from 'syft-acp-util/package'
import { logOut, resetAuth } from '../actions/auth'

import downloadAsCSV from './csv'
import { apiURL } from './endpoints'
import { getContentDispositionFileName, jsonBody } from './params'
import { getEnvCountryCode } from './properties'
import { deserializeResponse } from './deserialization'

const { ctk } = config

/** List of meta headers the backend might potentially send. */
const META_HEADERS = {
  // Extra information (for shifts).
  // This is not strictly pagination information but we currently only show this information there.
  totalWorkers: ['X-Total-Workers', { isInteger: true }],
  totalBookings: ['X-Total-Bookings', { isInteger: true }],
  totalShifts: ['X-Total-Shifts', { isInteger: true }],
  totalVacancies: ['X-Total-Vacancies', { isInteger: true }],
  // Pagination.
  totalPages: ['X-Total-Pages', { isInteger: true }],
  total: ['X-Total', { isInteger: true }],
  perPage: ['X-Per-Page', { isInteger: true }],
  nextPage: ['X-Next-Page', { isInteger: true }],
  page: ['X-Page', { isInteger: true }],
}

// Default page size for list based endpoints.
export const DEFAULT_PAGE_SIZE = 25

export const DEFAULT_PAGE = 1

// Number of results we request per page when returning 'all' records.
// Currently set to 600 max.
export const A_VERY_LARGE_NUMBER = 600
/** requested items return size results for future reference:
 * 1000 === success after 12 secs
 * 3625 === success after 20.4 secs
 * 3750 === internal server error 500 after 20.7secs
 */

const FORCE_GATE_FALSE = false

/**
 * Returns auth tokens from the store.
 * @returns {Object} An object auth tokens, if available
 */
export const getAuth = () => store.getState().auth.oauthData

/**
 * Returns platform ID from the store.
 * @returns {Number} Platform ID
 */
export const getPlatformID = () => store.getState().auth.platformID

/**
 * Returns auth headers from the store for use in requests. If the auth headers have not
 * been retrieved yet, an empty object will be returned instead.
 *
 * @returns {Object} An object containing authentication headers for use in a request
 */
export const getAuthHeaders = () => {
  const oauth = getAuth()
  if (!oauth || !oauth.token_type || !oauth.access_token) {
    return {}
  } else {
    return {
      Authorization: `${oauth.token_type} ${oauth.access_token}`,
    }
  }
}

/**
 * Retrieves a header field value from a Fetch API response.
 *
 * @param {Response} response
 * @param {String} headerName Name we're looking for
 * @param {Object} options e.g. isInteger
 */
const parseHeader = (response, headerName, { isInteger } = {}) => {
  const { headers } = response
  const value = headers && headers.get(headerName)
  return isInteger ? value && parseInt(value, 10) : value
}

/**
 * If we're downloading as CSV, don't add the items we fetched to the store.
 * Otherwise, we'll end up dumping all items we fetched for the CSV in there.
 * This would mean the user ends up with a gigantic entity list of hundreds of items.
 * For that reason, We're including 'noPayloadUpdate' whenever 'toCSV' is true.
 * See /syft-acp-core/reducers/generators/entities.js for how this is used.
 * If a custom callback function is defined, call it after resolve.
 *
 * @param resolve Resolution handler
 * @param payload TBD
 * @param response TBD
 * @param meta TBD
 * @param toCSV TBD
 * @param callback TBD
 */
const resolveAndCallbackCSV = (resolve, payload, response, meta, toCSV, callback) => {
  resolve({ payload, response, meta: { ...meta, noPayloadUpdate: toCSV } })
  if (callback) {
    callback({ payload, response, meta })
  }
}

/**
 * In Cypress, there is no easy way to wait until all fetch calls has finished so we can start running commands after
 * (Until Cypress support fetch API calls)
 * This should at least track most of saga's fetch requests
 * In conjunction with waitUntilAllDataFetched, we can wait until all fetch calls has been completed
 *
 * @param cb
 * @returns {Q.Promise<any> | Bluebird<any> | Promise<number>}
 */
const trackActiveFetchCalls = cb => {
  window.fetch_calls = (window.fetch_calls || 0) + 1
  return cb().finally(() => --window.fetch_calls)
}
/**
 * Handles a single API call according to the default procedure,
 * which is to check for the HTTP status code, and then either reject
 * with a standard error message, or resolve with the unpacked JSON data
 * if the status is 200.
 *
 * @param req API request
 * @param resolve Resolution handler
 * @param reject Rejection handler
 * @param pagination TBD
 * @param options TBD
 * @param callback TBD
 * @param toCSV TBD
 * @param fileName TBD
 * @param isStream TBD
 * @param prevData payload from previous pages for CSV with multiple pages
 * @param urlMultiplePagesCsv API url for CSV with multiple pages
 */
export const handleCall = (
  req,
  resolve,
  reject,
  pagination = false,
  options = {},
  callback = null,
  toCSV = false,
  fileName = 'results',
  isStream = false,
  prevData = [],
  urlMultiplePagesCsv = null,
) =>
  trackActiveFetchCalls(() =>
    fetch(req)
      .then(async response => {
        // Reject if we have any status code other than 200. // temp: 201
        // TODO: remove 201 status check. See if one line invoicing for employers still works after removing.
        if (!response.ok && response.status !== 201) {
          if (response.status === 401 && !response?.url?.includes('users/login')) {
            store.dispatch(logOut())
            store.dispatch(resetAuth())
            history.push('/login')
            return
          }
          // Response wasn't OK. Save debugging info, including the page we're currently on.
          const routing = get(store.getState(), 'routing.locationBeforeTransitions', {})

          // Attempt to parse JSON, if it exists. Otherwise just return the response.
          let bodyValue
          let headers

          try {
            bodyValue = JSON.parse(await response.clone().text())
          } catch (err) {
            bodyValue = null
          }
          try {
            headers = {
              platformURL: response.headers.get('x-platform-url'),
              requestID: response.headers.get('x-request-id'),
              runtime: response.headers.get('x-runtime'),
            }
          } catch (err) {
            headers = {}
          }

          try {
            const safeResponse = pick(response, [
              'status',
              'statusText',
              'type',
              'url',
              'redirected',
              'ok',
              'bodyUsed',
            ])
            const errorObject = {
              message: `Error ${response.status}: ${response.statusText}`,
              body: bodyValue,
              ...headers,
              options,
              response: safeResponse,
              url: req.url,
              type: req.method,
              status: response.status,
              page: {
                path: get(routing, 'pathname', ''),
                query: get(routing, 'query', {}),
              },
              // Check if we were able to parse the body.
              _cleanError: bodyValue != null,
            }
            store.dispatch(addError(errorObject))
            if (callback) {
              callback({ errorObject, response })
            }
            return reject(errorObject) // eslint-disable-line consistent-return
          } catch (err) {
            // Extract data from the request.
            const reqItems = pick(req, ['url', 'method'])
            const errorObject = {
              message: `Error ${response.status}: ${response.statusText}`,
              body: bodyValue,
              ...headers,
              options,
              response,
              status: response.status,
              page: {
                path: get(routing, 'pathname', ''),
                query: get(routing, 'query', {}),
              },
              // If the standard addError() path fails, try to get the URL/method
              // from the original Request() object instead.
              ...{ url: reqItems.url, type: reqItems.method },
              _cleanError: bodyValue != null,
              _errorObject: err,
            }
            store.dispatch(addError(errorObject))
            if (callback) {
              callback({ errorObject, response })
            }
            return reject(errorObject) // eslint-disable-line consistent-return
          }
        }

        // If we implement pagination for this call, retrieve the pagination headers.
        let meta = {}
        if (pagination) {
          // Filter out null or undefined values.
          const parseMeta = Object.keys(META_HEADERS).reduce(
            (acc, key) => ({
              ...acc,
              [key]: parseHeader(response, ...META_HEADERS[key]),
            }),
            {},
          )
          meta = pickBy(parseMeta, n => n != null)
        }
        // Include the filters used for this call.
        meta.options = options
        if (isStream) {
          // get the file name from the response header if provided by BE
          const contentName = getContentDispositionFileName(response.headers.get('content-disposition'))
          const contentType = response.headers.get('content-type')
          // Unpack the stream response data. Use blob if contentType is type of application
          const payload = await (/application\//.test(contentType) ? response.blob() : response.text())
          resolveAndCallbackCSV(resolve, payload, response, meta, true, callback)
          const blob = new Blob([payload], { type: contentType })
          return FileSaver.saveAs(
            blob,
            contentName || (toCSV && !/\.csv$/.test(fileName) ? `${fileName}.csv` : fileName),
          )
        }
        // Unpack the json response data.
        // eslint-disable-next-line consistent-return
        return response.json().then(payload => {
          const currentPage = response.headers.get('x-page')
          const totalPages = response.headers.get('x-total-pages')
          // Resolve callback after last response for CSV with multiple pages
          if (!urlMultiplePagesCsv || currentPage === totalPages) {
            resolveAndCallbackCSV(resolve, deserializeResponse(payload), response, meta, toCSV, callback)
          }
          // If we are downloading the results as CSV, do so now.
          if (toCSV) {
            downloadWholeCSV({
              toCSV,
              response,
              prevData,
              payload,
              meta,
              fileName,
              urlMultiplePagesCsv,
              resolve,
              reject,
              pagination,
              options,
              callback,
              currentPage,
              totalPages,
            })
          }
        })
      })
      .catch(e =>
        reject({
          message: e.message,
          response: e.name,
        }),
      ),
  )

export const downloadWholeCSV = ({
  response,
  prevData,
  payload,
  meta,
  fileName,
  urlMultiplePagesCsv,
  resolve,
  reject,
  pagination,
  options,
  callback,
  currentPage,
  totalPages,
}) => {
  if (totalPages === currentPage || !urlMultiplePagesCsv || totalPages === '0') {
    downloadAsCSV({ payload: [...prevData, ...payload], response, meta }, fileName)
  } else {
    const newReq = apiRequest({
      path: urlMultiplePagesCsv,
      reqArgs: { page: Number(currentPage) + 1, per_page: A_VERY_LARGE_NUMBER },
      returnAll: true,
      utilizePagination: false,
    })
    handleCall(
      newReq,
      resolve,
      reject,
      pagination,
      options,
      callback,
      true,
      fileName,
      false,
      [...prevData, ...payload],
      urlMultiplePagesCsv,
    )
  }
  return { payload: [...payload, ...prevData], page: currentPage }
}

/**
 * Returns the default request parameters.
 *
 * @returns {Object} Request object parameters
 */
export const reqDefaults = (method = 'GET', contentType, sfPlatform, useAuth = true) => {
  const headers = useAuth ? getAuthHeaders() : {}
  const countryCode = getEnvCountryCode()

  if (contentType) {
    headers['Content-Type'] = contentType
  }
  // Internal resourcing platform ID used for whitelabeling.
  // TODO: For now, this is hardcoded to 1.
  headers['X-Platform-Id'] = getPlatformID()
  headers['X-Client-Version'] = APPLICATION_ID

  // Non-admin calls made for Flex+ platforms require a special header.
  if (sfPlatform && sfPlatform !== 1) {
    headers['X-Platform-Id'] = sfPlatform
  }

  // Adds a country code header for localized calls.
  if (countryCode) {
    headers['X-Country-Code'] = countryCode
  }

  // Add the device ID if present
  if (ctk) {
    headers['X-Device-Id'] = ctk
  }

  // Remove null and undefined values.
  return {
    method,
    headers: { ...pickBy(headers, n => n != null) },
    cache: 'default',
  }
}

/**
 * Modifies a request arguments list to return all results.
 * TODO: currently 'paginated' is always false. That's fine. The API defaults to '25' anyway.
 * The problem is that we don't know at this point whether the endpoint supports pagination,
 * and adding a 'per_page' variable to non-paginated endpoints results in an error.
 * To fix, make sure we send some kind of extra parameter to apiRequest() that indicates it's a paginated request.
 */
const returnAllResults = (args, returnAll = false, paginated = false, perPage = DEFAULT_PAGE_SIZE) => ({
  // Return page requested page with an arbitrarily large page size when 'returnAll' is true.
  ...args,
  ...(returnAll
    ? { page: args.page || DEFAULT_PAGE, per_page: A_VERY_LARGE_NUMBER }
    : paginated
    ? { per_page: perPage }
    : {}),
})

/**
 * Returns a single request for one API call.
 *
 * @param path API path to make a call to
 * @param reqArgs Key/value pairs or a FormData instance to be sent in the body or query string
 * @param method HTTP method to use for this call, defaults to GET
 * @param returnAll Whether to return ALL results
 * @param utilizePagination Whether to utilize the pagination system
 * @param forceGeneralStaging Forces the request to go to the general staging backend
 * @param sfPlatform
 * @param forceGate Forces the request to go to the Gate isntead of API V2
 * @param allowNulls Moving to allow PATCH/POST with null values
 * @returns {Request} New request for making the desired API call
 */
export const makeRequest = (
  path,
  reqArgs = {},
  method = 'GET',
  returnAll = false,
  utilizePagination = true,
  forceGeneralStaging = false,
  sfPlatform = null,
  forceGate = false,
  allowNulls = false,
) => {
  let body = null
  let query = {}
  let contentType
  const args = utilizePagination ? returnAllResults(reqArgs, returnAll) : reqArgs

  // GET requests are always sent as a plain query string.
  if (method === 'GET') {
    query = args
    contentType = 'application/x-www-form-urlencoded'
  }
  // For all other methods, we send JSON in case we're using a plain key/value object or array as data.
  else if (isPlainObject(args) || isArray(args)) {
    body = jsonBody(args, allowNulls)
    contentType = 'application/json'
  }
  // If we're sending FormData, the Content-Type header will be set automatically.
  else {
    body = args
    contentType = false
  }

  const headers = reqDefaults(method, contentType, sfPlatform, true)
  return new Request(apiURL(path, query, forceGeneralStaging, forceGate), { ...headers, body })
}

/**
 * Returns a single request for one API V2 call.
 *
 * @param path optional paginated page to download
 * @param reqArgs Key/value pairs or a FormData instance to be sent in the body or query string
 * @param method HTTP method to use for this call, defaults to GET
 * @param returnAll Whether to return ALL results
 * @param utilizePagination Whether to utilize the pagination system
 * @param forceGeneralStaging Forces the request to go to the general staging backend
 * @param sfPlatform
 * @param allowNulls Moving to allow PATCH/POST with null values
 * @returns {Request} New request for making the desired API call
 */
export const apiRequest = ({
  path,
  reqArgs = {},
  method = 'GET',
  returnAll = false,
  utilizePagination = true,
  forceGeneralStaging = false,
  sfPlatform = null,
  allowNulls = false,
}) =>
  makeRequest(
    path,
    reqArgs,
    method,
    returnAll,
    utilizePagination,
    forceGeneralStaging,
    sfPlatform,
    FORCE_GATE_FALSE,
    allowNulls,
  )

/**
 * As apiRequest(), but for sending files as form data (application/x-www-form-urlencoded).
 * TODO: refactor and re-include into apiRequest().
 */
export const fileRequest = (path, file, method) => {
  // Let the Content-Type header be determined automatically.
  const defaults = reqDefaults(method, false)

  // Append the file to a form object.
  const form = new FormData()
  form.append('file', file)
  return new Request(apiURL(path), { method, ...defaults, body: form })
}

/**
 * Returns a URL that includes the access_token that can be linked to directly.
 * @param {string} path Path to link to
 * @param {object} args Arguments to be included aside from the access_token
 */
export const apiAuthorizedURL = (path, args = {}) =>
  apiURL(path, { access_token: getAuth().access_token, ...args })
