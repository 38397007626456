// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import React from 'react'
import PropTypes from 'prop-types'
import { DataSubRows } from 'syft-acp-core/components/EditableTable'

import EntitySelector from './EntitySelector'

import './SubrowSelector.css'

const SubrowSelector = ({ data, trClassName, scope, rowNumber }) => (
  <DataSubRows
    data={data.map((id, n) => (
      <EntitySelector key={String(n)} value={id} valueKeys={['shiftID', 'workerID']} scope={scope} />
    ))}
    rowNumber={rowNumber}
    padded={false}
    trClassName={trClassName}
  />
)

SubrowSelector.propTypes = {
  data: PropTypes.array.isRequired,
  trClassName: PropTypes.array.isRequired,
  scope: PropTypes.string.isRequired,
  rowNumber: PropTypes.string.isRequired,
}

export default SubrowSelector
