export const TIME_OFF_REQUESTS_FETCH_BEGIN = 'syftacp/TIME_OFF_REQUESTS_FETCH_BEGIN'
export const TIME_OFF_REQUESTS_FETCH_FAILED = 'syftacp/TIME_OFF_REQUESTS_FETCH_FAILED'
export const TIME_OFF_REQUESTS_FETCH_SUCCEEDED = 'syftacp/TIME_OFF_REQUESTS_FETCH_SUCCEEDED'

export const TIME_OFF_REQUEST_FETCH_BEGIN = 'syftacp/TIME_OFF_REQUEST_FETCH_BEGIN'
export const TIME_OFF_REQUEST_FETCH_FAILED = 'syftacp/TIME_OFF_REQUEST_FETCH_FAILED'
export const TIME_OFF_REQUEST_FETCH_SUCCEEDED = 'syftacp/TIME_OFF_REQUEST_FETCH_SUCCEEDED'

export const TIME_OFF_REQUEST_UPDATE_BEGIN = 'syftacp/TIME_OFF_REQUEST_UPDATE_BEGIN'
export const TIME_OFF_REQUEST_UPDATE_FAILED = 'syftacp/TIME_OFF_REQUEST_UPDATE_FAILED'
export const TIME_OFF_REQUEST_UPDATE_SUCCEEDED = 'syftacp/TIME_OFF_REQUEST_UPDATE_SUCCEEDED'

export const TIME_OFF_REQUEST_APPROVE_BEGIN = 'syftacp/TIME_OFF_REQUEST_APPROVE_BEGIN'
export const TIME_OFF_REQUEST_APPROVE_FAILED = 'syftacp/TIME_OFF_REQUEST_APPROVE_FAILED'
export const TIME_OFF_REQUEST_APPROVE_SUCCEEDED = 'syftacp/TIME_OFF_REQUEST_APPROVE_SUCCEEDED'

export const TIME_OFF_REQUEST_REJECT_BEGIN = 'syftacp/TIME_OFF_REQUEST_REJECT_BEGIN'
export const TIME_OFF_REQUEST_REJECT_FAILED = 'syftacp/TIME_OFF_REQUEST_REJECT_FAILED'
export const TIME_OFF_REQUEST_REJECT_SUCCEEDED = 'syftacp/TIME_OFF_REQUEST_REJECT_SUCCEEDED'
