import React, { useMemo } from 'react'
import iconFactory from 'syft-acp-atoms/Icons'
import { useFlexFlagIsOn } from '@indeed/flex-feature-flags'

import { getSafeCurrencySymbol } from 'syft-acp-util/formatting'
import { useFormatTimeRange } from 'syft-acp-core/lib/hooks'

import { Props } from './ShiftWorkerUpdates.types'

import S from './ShiftWorkerUpdates.module.scss'

const AlertFillIcon = iconFactory('alert', 'Alert fill')

const ShiftWorkerUpdates = ({ shiftBooking, shift, roles }: Props) => {
  const timeRangeOptions = useMemo(
    () => ({
      separator: '-',
      format: {
        timeZone: shift?.venue?.timezone,
        timeZoneName: 'short',
      },
    }),
    [shift],
  )
  const [timeRange] = useFormatTimeRange([shiftBooking.start_time, shiftBooking.end_time], timeRangeOptions)

  const enableRoleChanges = useFlexFlagIsOn('pte_20169_shifts_roles_changes')
  const data = []
  if (shiftBooking?.role_id && shiftBooking?.role_id !== shift?.role_id && enableRoleChanges) {
    data.push({ name: 'Role', value: String(roles?.[shiftBooking?.role_id]?.title) })
  }

  if (
    (shift.start_time !== shiftBooking.start_time || shift.end_time !== shiftBooking.end_time) &&
    shiftBooking.end_time &&
    shiftBooking.start_time
  ) {
    const [start, end] = (timeRange as string).split(timeRangeOptions.separator)

    data.push({
      name: 'Time',
      value: `${start}-${end}`,
    })
  }

  if (shiftBooking?.area?.name !== shift?.area?.name && shiftBooking?.area?.name) {
    data.push({ name: 'Area', value: shiftBooking?.area?.name })
  }
  if (shiftBooking?.venue?.name !== shift?.venue?.name && !!shiftBooking?.venue?.name) {
    data.push({ name: 'Venue', value: shiftBooking?.venue?.name })
  } else if (shiftBooking?.area?.name !== shift?.area?.name && !shiftBooking?.area?.name) {
    // Reflected area change null, only in case venue was not changed
    data.push({ name: 'Area', value: 'Null' })
  }
  if (
    shiftBooking?.client_pay_rate?.amount !== shift?.pay_rate?.amount &&
    shiftBooking?.client_pay_rate?.amount &&
    !shiftBooking.agency_name &&
    !shiftBooking.agency_shift_worker_id
  ) {
    data.push({
      name: 'Pay rate',
      value: `${getSafeCurrencySymbol(shiftBooking?.client_pay_rate.currency)}${
        shiftBooking?.client_pay_rate?.amount
      }`,
    })
  }
  if (data.length) {
    return (
      <div>
        <div className={S['shift_worker_changes_container']}>
          <div className={S['shift_worker_changes_icon']}>
            <AlertFillIcon iconSize={16} fill="#e7b421" />
          </div>
          <div className={S['shift_worker_changes_text']}>
            <b>Updates:</b>
          </div>
        </div>
        <ul className={S['shift_worker_changes_list']}>
          {data.map(change => {
            return (
              <li key={change.name}>
                <b>{change.name}:</b> {change.value}
              </li>
            )
          })}
        </ul>
      </div>
    )
  }
  return null
}

export default ShiftWorkerUpdates
