import { SUPPORTED_COUNTRY_CODES } from 'syft-acp-core/lib/i18n'

type CountryCode = keyof typeof SUPPORTED_COUNTRY_CODES

type WorkerStatus = {
  name: string
  color?: string
  style?: string
  editable?: boolean
  exclusive?: boolean
  exclusiveWith?: WorkerStatusType
  showForCountries?: CountryCode[]
}

export type WorkerStatusType = keyof typeof statuses

/** List of all accepted worker statuses. */
export const statuses = {
  banned: {
    name: 'Banned',
    style: 'danger',
    color: '#d9534f',
  },
  profile_created: {
    name: 'Profile created',
    color: '#42a2ba',
    style: 'info',
  },
  failed_induction: {
    name: 'Failed induction',
    style: 'info',
    color: '#EC7000',
    editable: true,
    showForCountries: [SUPPORTED_COUNTRY_CODES.GB],
  },
  failed_training: {
    name: 'Needs RTW',
    style: 'info',
    color: '#CC0000',
    editable: true,
    showForCountries: [SUPPORTED_COUNTRY_CODES.GB],
  },
  need_certificates: {
    name: 'Need certificates',
    style: 'info',
    color: '#B36D00',
    editable: true,
    showForCountries: [SUPPORTED_COUNTRY_CODES.GB],
  },
  need_id: {
    name: 'Need ID',
    color: '#42a2ba',
    style: 'info',
    showForCountries: [SUPPORTED_COUNTRY_CODES.GB],
  },
  not_seen: {
    name: 'Not seen',
    color: '#5A6986',
    style: 'default',
    showForCountries: [SUPPORTED_COUNTRY_CODES.GB],
  },
  // CMS2-901
  experience_needed: {
    name: 'Experience needed',
    style: 'info',
    color: '#de5ba2',
    editable: true,
    showForCountries: [SUPPORTED_COUNTRY_CODES.GB],
  },
  // CMS2-900
  needs_new_photo: {
    name: 'Needs new photo',
    style: 'info',
    color: '#a15bde',
    editable: true,
    showForCountries: [SUPPORTED_COUNTRY_CODES.GB],
  },
  // CMS2-899
  interviewed: {
    name: 'Interviewed',
    style: 'info',
    color: '#00bd82',
    showForCountries: [SUPPORTED_COUNTRY_CODES.GB],
  },
  requires_training: {
    name: 'On hold',
    style: 'info',
    color: '#5586e6',
    editable: true,
    showForCountries: [SUPPORTED_COUNTRY_CODES.GB],
  },
  verified: {
    name: 'Verified',
    style: 'success',
    color: '#5cb85c',
  },
  need_ni_no: {
    name: 'Need NINo',
    style: 'success',
    color: '#5229A3',
    editable: true,
    showForCountries: [SUPPORTED_COUNTRY_CODES.GB],
  },
}

/** Typed list of all accepted worker statuses. */
export const workerStatuses = statuses as Record<WorkerStatusType, WorkerStatus>
