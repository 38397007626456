import * as types from './action-types'

import { ListAppealsInput, CreateAppealInput, UpdateAppealInput } from './types'

export const fetchAppeals = (options: ListAppealsInput['payload']['options']) => ({
  type: types.APPEALS_FETCH_BEGIN,
  payload: { options },
})

export const fetchAppeal = (appealID: number) => ({
  type: types.APPEAL_FETCH_BEGIN,
  payload: { appealID },
})

export const assignAppeals = () => ({ type: types.APPEALS_ASSIGN_BEGIN })

export const fetchAssignees = () => ({ type: types.APPEALS_ASSIGNEES_FETCH_BEGIN })

export const createAppeal = (data: CreateAppealInput['payload']) => ({
  type: types.APPEAL_CREATE_BEGIN,
  payload: data,
})

export const updateAppeal = (id: number, options: UpdateAppealInput['payload']['options']) => ({
  type: types.APPEAL_UPDATE_BEGIN,
  payload: {
    id,
    options,
  },
})

export const fetchAppealReasons = () => ({ type: types.APPEAL_REASONS_FETCH_BEGIN })
