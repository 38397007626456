import React from 'react'
import { connect } from 'react-redux'
import { flowRight, capitalize } from 'lodash-es'

import Acp from 'syft-acp-uikit'
import useFetchEntity from 'syft-acp-util/hooks/useFetchEntity'
import { fetchTimeOffRequests } from 'syft-acp-core/store/timeOffRequests/actions'
import { getEmployerName } from 'syft-acp-util/formatting'
import { TimeOffRequestEntity } from 'syft-acp-core/store/types'
import { createEntityListConnector } from 'syft-acp-util/entityList'
import { FilterText } from 'syft-acp-util/components/FilterForm'

import FilterTimeOffRequestStatus from './FilterTimeOffRequestStatus'
import FilterTimeOffRequestVenue from './FilterTimeOffRequestVenue'
import FilterTimeOffRequestEmployer from './FilterTimeOffRequestEmployer'
import {
  getTimeOffRequestDetailPath,
  getRowColorByStatus,
  getWorkerLink,
  getShiftLink,
} from './TimeOffRequestList.helpers'
import {
  TimeOffRequestsListProps as Props,
  TimeOffRequestsListEntityProps as EntityProps,
} from './TimeOffRequestList.types'

export const entityConnector = createEntityListConnector<TimeOffRequestEntity>({
  entityActions: { fetchEntities: fetchTimeOffRequests },
  entityStore: 'timeOffRequests',
})

export const storeConnector = connect(undefined, (dispatch, { actions }: EntityProps) => ({
  actions,
}))

const getRowLink = ({ id }: TimeOffRequestEntity) => getTimeOffRequestDetailPath(id)
const getName = ({ employer }: TimeOffRequestEntity) => getEmployerName(employer)
const getStatus = ({ status }: TimeOffRequestEntity) => capitalize(status)

const TimeOffRequestList = ({ actions, data, isLoadingData, query }: Props) => {
  useFetchEntity(query, actions.fetchEntities)

  return (
    <Acp.EntityList data={data} isLoading={isLoadingData} idKeyValue="id" hasPagination hasResultCount>
      <Acp.Actions>
        <Acp.FilterGroup title="Client name">
          <FilterTimeOffRequestEmployer />
        </Acp.FilterGroup>
        <Acp.FilterGroup title="Venue name">
          <FilterTimeOffRequestVenue />
        </Acp.FilterGroup>
        <Acp.FilterGroup title="Worker email">
          <FilterText type="email" name="worker_email" placeholder="Filter by email" minLength={2} />
        </Acp.FilterGroup>
        <Acp.FilterGroup title="Request status">
          <FilterTimeOffRequestStatus />
        </Acp.FilterGroup>
      </Acp.Actions>
      <Acp.Table rowLink={getRowLink} rowColor={getRowColorByStatus}>
        <Acp.Col.Text value="id" header="ID" align="left" isMinimal isNumeric />
        <Acp.Col.DateTime
          value="created_at"
          header="Request date/time"
          options={{ timeZone: 'venue.timezone' }}
        />
        <Acp.Col.Text
          value="worker.id"
          header="Worker ID"
          align="left"
          colLink={getWorkerLink}
          isMinimal
          isNumeric
        />
        <Acp.Col.Text value="worker.name" header="Worker name" align="left" isMinimal isNumeric />
        <Acp.Col.Text value="worker.email" header="Worker email" align="left" isMinimal isNumeric />
        <Acp.Col.DateTime
          value={['shift.start_time', 'shift.end_time']}
          header="Shift start/end"
          options={{ timeZone: 'venue.timezone' }}
          colLink={getShiftLink}
        />
        <Acp.Col.Text value="reason_category.description" header="Reason" align="left" />
        <Acp.Col.Text value="reason.description" header="Reason detail" align="left" />
        <Acp.Col.Text value={getStatus} header="Status" align="left" isMinimal />
        <Acp.Col.Text value={getName} header="Client name" align="left" />
        <Acp.Col.Text value="venue.name" header="Venue name" align="left" />
        <Acp.Col.Text value="reviewer.full_name" header="Reviewed by" align="left" />
      </Acp.Table>
    </Acp.EntityList>
  )
}

export default flowRight(entityConnector, storeConnector)(TimeOffRequestList)
