export const trackingEvents = {
  NEW: {
    PAGE: {
      VIEWED: 'worker_networks_new.page.viewed',
    },
    SAVE_BUTTON: {
      CLICKED: 'worker_networks_new.save_button.clicked',
    },
  },
  DETAIL: {
    PAGE: {
      VIEWED: 'worker_networks_detail.page.viewed',
    },
    DELETE_BUTTON: {
      CLICKED: 'worker_networks_detail.delete_button.clicked',
    },
  },
}
