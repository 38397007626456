import { apiErrorMessage } from 'syft-acp-core/reducers/generators/utils'

export const entityDeleteFailed = (state, action) => {
  return {
    ...state,
    isSavingData: false,
    lastMessage: apiErrorMessage(action.payload),
    lastStatus: null,
    lastBody: action.payload.body,
    isLoadingData: false,
  }
}
