import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import FilterTiers from 'syft-acp-util/components/FilterForm/FilterTiers'

const DataTierDropdown = ({ value, editable, allowAny = false, actions }) =>
  editable ? (
    <FilterTiers
      allowNameLabel
      name="tier"
      value={value}
      actions={actions}
      allowAny={allowAny}
      type="id"
      disabled={!editable}
    />
  ) : (
    <div className="plain-text">{value}</div>
  )

DataTierDropdown.propTypes = {
  value: PropTypes.number.isRequired,
  editable: PropTypes.bool.isRequired,
  allowAny: PropTypes.bool,
  actions: PropTypes.object.isRequired,
}

export default connect((state, { onChange }) => ({
  actions: {
    setFilter: (name, id, value) => onChange(value),
  },
}))(DataTierDropdown)
