// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import React from 'react'
import { get } from 'lodash-es'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import classnames from 'classnames'

const DataSubRows = ({ data, padded, href, rowNumber = 0 }) => {
  const Container = href ? Link : 'div'
  const content = get(data, rowNumber)
  return (
    <Container {...(href ? { to: href[rowNumber] } : null)} className={classnames('sub-row', { padded })}>
      {content}
    </Container>
  )
}

DataSubRows.propTypes = {
  data: PropTypes.array.isRequired,
  rowNumber: PropTypes.number,
  padded: PropTypes.bool,
  href: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
}

DataSubRows.defaultProps = {
  padded: true,
  trClassName: [],
}

export default DataSubRows
