import EntityListComponent from 'syft-acp-core/components/EntityList/EntityListComponent'
import entityList from 'syft-acp-util/entityList'
import * as tableType from 'syft-acp-core/components/EntityList/'
import * as overpaymentsActions from 'syft-acp-core/actions/overpayments'
// import * as filterTypes from 'syft-acp-util/components/FilterForm/FilterForm';

const tableFormat = [
  { type: tableType.TXT, val: 'id', header: 'ID', expl: 'Overpayment ID', numeric: true },
  { type: tableType.USER_LINE, val: 'worker', header: 'Worker' },
  { type: tableType.USER_LINE, val: 'employer', header: 'Employer' },
  { type: tableType.MONEY, val: 'overpaid_amount', header: 'Overpaid amount' },
  { type: tableType.TXT, val: 'description', header: 'Description' },
  { type: tableType.DATETIME, val: 'overpaid_at', header: 'Overpaid at', classes: ['no-wrap'] },
  { type: tableType.DATETIME, val: 'created_at', header: 'Created at', classes: ['no-wrap'] },
  { type: tableType.DATETIME, val: 'updated_at', header: 'Updated at', classes: ['no-wrap'] },
]

const filters = null
const actionFormat = [
  /*
  {
    label: 'New payment deduction',
    type: filterTypes.TYPE_BUTTON,
    buttonType: 'primary',
    global: true,
    action: () => {
      console.log('new');
    }
  }
  */
]

const PaymentsList = entityList(
  EntityListComponent,
  tableFormat,
  filters,
  actionFormat,
  'overpayments',
  {
    fetchEntities: overpaymentsActions.fetchOverpayments,
  },
  {
    showResultCount: true,
    fullWidth: true,
  }
)

export default PaymentsList
