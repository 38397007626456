// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { get, isEqual } from 'lodash-es'

import EntityDetailItem from 'syft-acp-core/components/EntityDetail/EntityDetailItem'
import EditableTable from 'syft-acp-core/components/EditableTable'
import RecordHeader from 'syft-acp-core/components/RecordSection/RecordHeader'

import RecordSegment from './RecordSegment'
import RecordName from './RecordName'

import './RecordSection.css'

export default class RecordSection extends Component {
  static propTypes = {
    context: PropTypes.object,
    title: PropTypes.string,
    data: PropTypes.object,
    dataPrefix: PropTypes.string,
    structure: PropTypes.array,
    update: PropTypes.func,
    header: PropTypes.node,
    noRounding: PropTypes.bool,
    connected: PropTypes.bool,
    showName: PropTypes.bool,
    nameValue: PropTypes.string,
    nameBio: PropTypes.string,
    nameAvatar: PropTypes.string,
    nameVerified: PropTypes.bool,
    nameExtraContent: PropTypes.node,
    fieldData: PropTypes.object,
    footerText: PropTypes.string,
    isSaved: PropTypes.bool,
    notificationsNode: PropTypes.node,
    titleLabels: PropTypes.node,
    children: PropTypes.node,
    defaultData: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
    hideAvatar: PropTypes.bool,
    noRowBorders: PropTypes.bool,
    noBottomMargin: PropTypes.bool,
  }

  static defaultProps = {
    title: '',
    titleLabels: null,
    children: null,
    context: null,
    data: {},
    header: null,
    dataPrefix: '',
    structure: [],
    showName: null,
    noRounding: false,
    connected: false,
    fieldData: undefined,
    defaultData: undefined,
    footerText: '',
    hideAvatar: false,
    update: () => {},
    isSaved: false, // whether this record has been saved once or not
    nameExtraContent: null,
    nameValue: '',
    nameBio: '',
    nameAvatar: '',
    nameVerified: false,
    notificationsNode: undefined,
    noRowBorders: true,
    noBottomMargin: false,
  }

  shouldComponentUpdate(nextProps) {
    let hasUpdates = false
    for (const n of nextProps.structure) {
      if (!isEqual(get(this.props.data, n[0]), get(nextProps.data, n[0]))) {
        hasUpdates = true
        break
      }
    }
    hasUpdates = hasUpdates || isEqual(this.props.nameExtraContent, nextProps.nameExtraContent)
    return hasUpdates
  }

  render() {
    const {
      title,
      context,
      data,
      header,
      dataPrefix,
      structure,
      noRounding,
      connected,
      update,
      showName,
      footerText,
      nameExtraContent,
      notificationsNode,
      isSaved,
      fieldData,
      defaultData,
      titleLabels,
      children,
      hideAvatar,
      noRowBorders,
      noBottomMargin,
    } = this.props
    // TODO: refactor this. We should just pass the data directly.
    const nameValue = get(this.props, 'nameValue') || get(data, 'first_name') || ''
    const nameBio = get(this.props, 'nameBio') || get(data, 'bio') || ''
    const nameVerified = get(this.props, 'nameVerified') || !!get(data, 'approved_at') || false
    const nameAvatar = get(this.props, 'nameAvatar') || get(data, 'profile_picture.uuid') || 'guest'
    const tempData = nameValue === '' && nameBio === ''
    const tableProps = {
      data,
      context,
      isSaved,
      structure,
      dataPrefix,
      fieldData,
      defaultData,
      update,
      noRowBorders,
      noBottomMargin,
    }

    return (
      <EntityDetailItem noRounding={noRounding} connected={connected}>
        {header && header}
        {showName ? (
          <RecordName
            name={nameValue}
            bio={nameBio}
            verified={nameVerified}
            avatar={hideAvatar ? null : nameAvatar}
            tempData={tempData}
            extraContent={nameExtraContent}
          />
        ) : (
          (title || notificationsNode) && (
            <RecordSegment noPadding>
              {title && (
                <RecordHeader labels={titleLabels} main>
                  {title}
                </RecordHeader>
              )}
              {notificationsNode}
            </RecordSegment>
          )
        )}
        {(children || structure.length > 0) && (
          <RecordSegment withTable>
            {structure.length > 0 && <EditableTable {...tableProps} />}
            {!!children && children}
            {footerText && <div className="table-footer">{footerText}</div>}
          </RecordSegment>
        )}
      </EntityDetailItem>
    )
  }
}
