import { SUPPORTED_COUNTRY_CODES } from 'syft-acp-core/lib/i18n'
import { get } from 'lodash-es'
import * as tableType from 'syft-acp-core/components/EntityList/'
import * as structureTypes from 'syft-acp-core/components/EditableTable'
import octicons from 'octicons'
import { getCity, getVenueCity } from 'syft-acp-util/cities'
import { getPriorityLabel } from 'syft-acp-util/components/FilterForm/FilterTiers'

export const dataStructure = [
  [
    'id',
    {
      header: 'ID',
    },
  ],
  [
    'name',
    {
      header: 'Name',
      editable: true,
    },
  ],
  [
    'email',
    {
      header: 'Email',
      editable: true,
    },
  ],
  [
    'manager_roles',
    {
      header: 'Role',
      editable: true,
      type: structureTypes.TYPE_MANAGER_ROLES,
    },
  ],
]

export const tableFormat = [
  { type: tableType.TXT, val: 'id', header: 'ID', numeric: true },
  {
    type: tableType.IMAGE,
    headerIcon: octicons.person,
    headerIconAlt: 'Picture',
    val: 'picture',
  },
  {
    type: tableType.TXT,
    classes: ['main'],
    val: 'company_name',
    header: 'Company name',
  },
  {
    type: tableType.TXT,
    val: e => getCity(e.city_id),
    header: 'City',
    showForCountries: [SUPPORTED_COUNTRY_CODES.GB],
  },
  {
    type: tableType.TXT,
    val: getVenueCity,
    header: 'City',
    showForCountries: [SUPPORTED_COUNTRY_CODES.US],
  },
  {
    type: tableType.TXT,
    val: e => getPriorityLabel(get(e, 'employer.tier')),
    header: 'Priority',
  },
  { type: tableType.TXT, val: 'active_jobs_count', header: 'Active jobs' },
  {
    type: tableType.DATETIME,
    val: 'last_active_at',
    header: 'Last active',
    classes: ['nowrap'],
  },
  { type: tableType.BOOL, val: 'approved_at', header: 'V', expl: 'Verified' },
  { type: tableType.BOOL, val: 'requires_preauth', header: 'R', expl: 'Requires preauth' },
  { type: tableType.BOOL, val: 'converted', header: 'C', expl: 'Converted' },
]
