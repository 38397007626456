import PropTypes from 'prop-types'

export const tabsProps = {
  className: PropTypes.string,
  id: PropTypes.string,
  activeKey: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onSelect: PropTypes.func,
  children: PropTypes.node,
  noBorder: PropTypes.bool,
  defaultActiveKey: PropTypes.string,
}
