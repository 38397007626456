// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import RecordSection from 'syft-acp-core/components/RecordSection'
import { connect } from 'react-redux'
import { statsStructure } from 'syft-acp-core/entities/WorkerDetail/structure'
import * as actionsStats from 'syft-acp-core/actions/stats'

class RecordWorkerStats extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    workerID: PropTypes.number.isRequired,
    workerStats: PropTypes.object,
  }

  static defaultProps = {
    workerStats: {},
  }

  componentDidMount() {
    const { dispatch, workerID } = this.props
    const params = { worker_id: workerID }

    dispatch(actionsStats.fetchStatsWorkers(params))
  }

  render() {
    const { workerStats, workerID } = this.props

    const statsData = workerStats[workerID] || {}

    return <RecordSection title="Stats" data={statsData} structure={statsStructure(statsData)} />
  }
}

export default connect(state => ({ workerStats: state.stats.workers }))(RecordWorkerStats)
