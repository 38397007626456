import React from 'react'
// eslint-disable-next-line no-restricted-imports
import { Redirect as DOMRedirect, RedirectProps, useLocation } from 'react-router-dom'

export const Redirect = (props: RedirectProps) => {
  const location = useLocation()
  return (
    <DOMRedirect
      {...props}
      to={{
        ...location,
        ...(typeof props.to === 'object' ? props.to : {}),
        pathname: typeof props.to === 'object' ? props.to.pathname : props.to,
      }}
    />
  )
}
