import React from 'react'

import FilterReasons from 'syft-acp-util/components/FilterForm/FilterReasons'

import { DataAppealReasonDropdownProps as Props } from './DataAppealReasonDropdown.types'

const DataAppealReasonDropdown = ({ disabled = false, editable = false, data, onChange }: Props) => {
  if (editable)
    return (
      <FilterReasons
        type="appeals"
        value={data.appeal_reason?.id ? data.appeal_reason.id.toString() : undefined}
        onChange={onChange}
        allowAny={false}
        disabled={disabled}
      />
    )
  return <div className="plain-text">{data.appeal_reason ? data.appeal_reason.display_name : 'n/a'}</div>
}

export default DataAppealReasonDropdown
