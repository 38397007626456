import { uniq } from 'lodash-es'
import { entitySetName } from 'syft-acp-core/reducers/generators/utils'

export const adminListingShiftFetchSucceededState = (state, action) => {
  const setKey = entitySetName({ shiftID: action.request.shiftID })

  return {
    ...state,
    lastMessage: '',
    lastStatus: null,
    lastBody: null,
    entitySets: {
      ...state.entitySets,
      [setKey]: {
        isLoadingData: false,
        ids: [],
      },
    },
    entityMap: state.entityMap,
  }
}

export const bookingsOfferSucceededState = (state, action) => {
  const workerID = action.request.payload.worker_id
  const setKey = entitySetName({ shiftID: action.request.shiftID })
  let ids = state.entitySets[setKey].ids.map(id => id)
  ids.push(workerID)
  ids = uniq(ids)

  return {
    ...state,
    entitySets: {
      ...state.entitySets,
      [setKey]: {
        isLoadingData: false,
        ids,
      },
    },
  }
}

export const bookingsBulkOfferSucceededState = (state, action) => {
  const workerIDs = action.request.payload.worker_ids
  const setKey = entitySetName({ shiftID: action.request.shiftID })
  let ids = state.entitySets[setKey].ids.map(id => id)
  ids = ids.concat(workerIDs)
  ids = uniq(ids)

  return {
    ...state,
    entitySets: {
      ...state.entitySets,
      [setKey]: {
        isLoadingData: false,
        ids,
      },
    },
  }
}
