import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import PropTypes from 'prop-types'
import { noop } from 'lodash-es'

import { useTrackingTrigger } from '@indeed/flex-tracking-context'

import * as csatActions from 'syft-acp-core/actions/csat'
import { Button } from 'syft-acp-atoms/Button'
import Acp from 'syft-acp-uikit'
import RecordSection from 'syft-acp-core/components/RecordSection'
import { FilterGroup, FilterDateRange } from 'syft-acp-util/components/FilterForm'
import { convertReasons } from 'syft-acp-util/csat'

import { getDateRangeCsat } from '../../lib/shifts'
import { structure, DOWNLOAD_CSAT_RESULT_CSV_BUTTON_CLICKED } from './constants'

import './styles.css'

const initialDate = getDateRangeCsat(0, 1)

const CsatResultList = ({ actions, query, type, csat, isLoading }) => {
  const triggerEvent = useTrackingTrigger()
  useEffect(() => {
    const { fetchCsatScore, fetchCsatResults, fetchCsatSkipped } = actions
    const { from_date, to_date } = query
    if (from_date && to_date) {
      fetchCsatSkipped({ from_date, to_date }, type)
      fetchCsatScore({ from_date, to_date }, type)
      fetchCsatResults({ from_date, to_date }, type)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query, query.from_date, query.to_date, type])

  const handleDownloadCSV = () => {
    const { fetchCsatCsvData } = actions
    const { from_date, to_date } = query
    triggerEvent(DOWNLOAD_CSAT_RESULT_CSV_BUTTON_CLICKED, {
      from_date,
      to_date,
    })
    return fetchCsatCsvData({ from_date, to_date }, type)
  }

  const renderTables = () => {
    const { reasons } = csat

    const goodReasons = convertReasons(reasons, true)
    const badReasons = convertReasons(reasons)

    return (
      <div>
        <div className="acp-item-header header-text">Bad reasons:</div>
        <Acp.EntityList
          isIntegrated
          hasMinimumSize
          isLoading={isLoading}
          data={{ data: badReasons }}
          idKeyValue="id"
        >
          <Acp.Table>
            <Acp.Col.Text value="id" header="ID" isMinimal isNumeric />
            <Acp.Col.Text value="title" header="Title" />
            <Acp.Col.Text value="first" header="Score 1 (%)" />
            <Acp.Col.Text value="second" header="Score 2 (%)" />
            <Acp.Col.Text value="third" header="Score 3 (%)" />
          </Acp.Table>
        </Acp.EntityList>
        <div className="acp-item-header header-text">Good reasons:</div>
        <Acp.EntityList
          isIntegrated
          hasMinimumSize
          isLoading={isLoading}
          data={{ data: goodReasons }}
          idKeyValue="id"
        >
          <Acp.Table>
            <Acp.Col.Text value="id" header="ID" isMinimal isNumeric />
            <Acp.Col.Text value="title" header="Title" />
            <Acp.Col.Text value="fourth" header="Score 4 (%)" />
            <Acp.Col.Text value="fifth" header="Score 5 (%)" />
          </Acp.Table>
        </Acp.EntityList>
      </div>
    )
  }
  const data = {
    score: csat.average_rating,
    skipped: csat.percent_skipped ? Number(csat.percent_skipped).toFixed(2) : 0,
  }
  return (
    <div className="entity-detail-item csat-list-container">
      <div className="entity-detail-controls">
        <div>
          <FilterGroup title="Date">
            <FilterDateRange key={type} defaultValue={initialDate} nameFrom="from_date" nameTo="to_date" />
          </FilterGroup>
        </div>
        <div className="csat-list-container-button">
          <Button kind="primary" onClick={handleDownloadCSV}>
            Download CSV
          </Button>
        </div>
      </div>
      <RecordSection
        title="CSAT Employer information"
        data={data}
        footerText="CSAT Score - Calculated with: count(score=4,5)/count(all responses)"
        structure={structure}
        isLoading={isLoading}
      />
      {renderTables()}
    </div>
  )
}

CsatResultList.propTypes = {
  actions: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
  csat: PropTypes.object.isRequired,
  query: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired,
  onCsvDownload: PropTypes.func,
}

CsatResultList.defaultProps = {
  onCsvDownload: noop,
}

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(csatActions, dispatch),
})

export default connect(
  (state, props) => ({
    csat: state.csat.entityMap[props.type] || {},
    isLoading: state.csat.isLoadingData,
    query: state.routing.locationBeforeTransitions.query,
  }),
  mapDispatchToProps,
)(CsatResultList)
