// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import { put, takeLatest } from 'redux-saga/effects'
import * as api from 'syft-acp-core/api/resources/admin/employers/blocklist'
import * as types from 'syft-acp-core/actions/action-types'
import { fetchEmployerBlocklist } from 'syft-acp-core/actions/employer-blocklist'

import { takeLatestApiCall } from './helpers'
import { notificationCall } from './calls'

export default function* root() {
  yield takeLatestApiCall('EMPLOYER_BLOCKLIST_FETCH', api.listEmployerBlocklist)
  yield takeLatestApiCall('EMPLOYER_BLOCKLIST_ADD', api.addToEmployerBlocklist)
  yield takeLatestApiCall('EMPLOYER_BLOCKLIST_REMOVE', api.removeFromEmployerBlocklist)
  yield takeLatest(types.EMPLOYER_BLOCKLIST_ADD_SUCCEEDED, ({ request }) =>
    put(fetchEmployerBlocklist({ options: { employerID: request.employerID } }))
  )
  yield takeLatest(
    [
      types.EMPLOYER_BLOCKLIST_ADD_FAILED,
      types.EMPLOYER_BLOCKLIST_REMOVE_FAILED,
      types.EMPLOYER_BLOCKLIST_ADD_SUCCEEDED,
      types.EMPLOYER_BLOCKLIST_REMOVE_SUCCEEDED,
    ],
    notificationCall
  )
}
