import React, { useCallback, ChangeEvent, FC } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { isEqual } from 'lodash-es'

import { colPlaceholderFactory } from 'syft-acp-uikit/components/placeholders/factory'
import * as checkboxesActions from 'syft-acp-core/store/checkboxes/actions'
import { Store } from 'syft-acp-core/store'

import { AcpCellSelectorOwnProps as OwnProps, AcpCellSelectorProps as Props } from './AcpCellSelector.types'

export const storeConnector = connect(
  (state: Store, { options }: OwnProps) => ({
    items: (options?.scope && (state.checkboxes.items[options.scope] as Record<string, any>[])) || [],
  }),
  dispatch => ({
    actions: bindActionCreators(checkboxesActions, dispatch),
  }),
)

export const AcpColSelector = colPlaceholderFactory('AcpCellSelector')

/**
 * All of the values with keys defined as valueKeys must be present in order for the
 * checkbox to appear. If none are defined then the checkbox will render but will not
 * interact with the redux store. Header select is also always shown.
 */
const shouldShow = (value?: Props['value'], valueKeys?: string[], isHeader = false) => {
  if (isHeader || !value || !valueKeys) return true
  else return valueKeys.map(key => !!value[0][key]).filter(Boolean).length === valueKeys.length
}

/** Works with both normal rows and sub rows alike. */
const AcpCellSelector: FC<Props> & { cellProperties: string[] } = ({
  actions,
  value,
  options,
  isHeader,
  items,
  rowData,
}) => {
  const { scope, valueKeys, ariaLabel, disabled } = options || {}

  const handleCheck = (e: ChangeEvent<HTMLInputElement>) =>
    value && valueKeys && scope && actions.checkItems(value, scope, e.target.checked)

  const handleAriaLabel = () => {
    if (typeof ariaLabel === 'function') return ariaLabel(rowData)
    return ariaLabel
  }

  const isDisabled = useCallback(() => {
    if (isHeader) return !value?.length
    if (typeof disabled === 'function') {
      return disabled(rowData)
    }
    return false
  }, [isHeader, value, rowData, disabled])

  /**
   * Header select has to check that every shown sub row has been checked.
   * Regular select just checks whether the specific row has been checked.
   */
  const isSelected = useCallback(() => {
    if (!value?.length || !items?.length) return false
    if (isHeader) value.every(e => !!items.find(f => isEqual(e, f)))
    return !isDisabled() && !!items.find(e => isEqual(e, value[0]))
  }, [value, items, isHeader, isDisabled])

  if (!shouldShow(value, valueKeys, isHeader)) return null

  return (
    <input
      type="checkbox"
      checked={isSelected()}
      onChange={handleCheck}
      disabled={isDisabled()}
      aria-label={handleAriaLabel()}
    />
  )
}

AcpCellSelector.cellProperties = ['form']

export default storeConnector(AcpCellSelector)
