// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import React from 'react'
import PropTypes from 'prop-types'

import './NoAlerts.css'

const NoAlerts = ({ isLoading, hasData }) => {
  let msg
  if (!isLoading || hasData) {
    msg = 'No alerts.'
  } else {
    msg = 'Loading...'
  }
  return (
    <div className="AlertsNoAlerts">
      <span>{msg}</span>
    </div>
  )
}

NoAlerts.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  hasData: PropTypes.bool.isRequired,
}

export default NoAlerts
