// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import entityReducer from './generators/entityReducer'
import * as types from '../actions/action-types'
import * as entityTypes from './generators/entities'

export default entityReducer(
  'timeDependentRates',
  {
    [entityTypes.ENTITY_SAVE_BEGIN]: types.TIME_DEPENDENT_RATES_SAVE_BEGIN,
    [entityTypes.ENTITY_SAVE_SUCCEEDED]: types.TIME_DEPENDENT_RATES_SAVE_SUCCEEDED,
    [entityTypes.ENTITY_SAVE_FAILED]: types.TIME_DEPENDENT_RATES_SAVE_FAILED,
    [entityTypes.ENTITY_MODIFY_BEGIN]: types.TIME_DEPENDENT_RATES_UPDATE_BEGIN,
    [entityTypes.ENTITY_MODIFY_SUCCEEDED]: types.TIME_DEPENDENT_RATES_UPDATE_SUCCEEDED,
    [entityTypes.ENTITY_MODIFY_FAILED]: types.TIME_DEPENDENT_RATES_UPDATE_FAILED,
  },
  {
    idKey: 'id',
    localEntityMap: true,
  }
)
