import * as React from 'karet'
import PropTypes from 'prop-types'
import { isArray } from 'lodash-es'

import { SUPPORTED_COUNTRY_CODES } from 'syft-acp-core/lib/i18n'
import CountrySpecific from 'syft-acp-core/components/CountrySpecific'
import { PropTypesAtom } from 'syft-acp-util/observables'
import { PRIORITIES } from 'syft-acp-util/components/FilterForm/FilterTiers'
import Acp from 'syft-acp-uikit'

import './SalesForceList.css'

/** Returns what type of entity this is: a WorkLocation can be either a Venue or Area. */
const pickEntityType = ({ target_type, target }) => {
  const hasAreas = isArray(target.areas)
  if (target_type === 'Employer') return target_type
  if (target_type === 'WorkLocation' && hasAreas) return 'Venue'
  if (target_type === 'WorkLocation' && !hasAreas) return 'Area'
  return 'WorkLocation'
}

/** Returns whether this entity can take promotions. Areas cannot. */
const canGetPromotions = data => {
  const type = pickEntityType(data)
  return type === 'Employer' || type === 'Venue'
}

/**
 * Generic Salesforce EntityList component.
 *
 * This component isn't connected to a resource; it gets its data from the 'ConnectedSalesForce' component.
 * All data variables that get passed to the component are lenses, except 'employerID'.
 */
const EmployerSalesForce = ({ employerID, employerName, dataSalesforce, entitySave, isLoading }) => {
  return (
    <CountrySpecific countries={[SUPPORTED_COUNTRY_CODES.GB]}>
      {isGBSpecific => (
        <Acp.EntityList
          className="sales-list-min-content"
          hasResultCount
          hasPagination
          isIntegrated
          hasMinimumSize
          idKeyValue="id"
          data={dataSalesforce}
          isLoading={isLoading}
        >
          <Acp.Actions>
            <Acp.Header>
              Salesforce settings <span>{employerName ? `for ${employerName}` : ``}</span>
            </Acp.Header>
          </Acp.Actions>
          <Acp.Table
            rowLink={data =>
              canGetPromotions(data)
                ? `/entity/employers/view/${employerID}/salesforce/view/${data.sales_win_id}`
                : null
            }
          >
            <Acp.ColGroup title="Salesforce details">
              <Acp.Col.Enum
                value={pickEntityType}
                header="Type"
                valueTooltip={data => `Salesforce ID: #${data.id}`}
                headerTooltip="Type of item (employer, venue, area)"
                isMinimal
              >
                <Acp.Enum slug="Employer" color="purple">
                  Employer
                </Acp.Enum>
                <Acp.Enum slug="Venue" color="blue">
                  Venue
                </Acp.Enum>
                <Acp.Enum slug="Area" color="aqua">
                  Area
                </Acp.Enum>
                <Acp.Enum slug="WorkLocation" color="orange">
                  WorkLocation
                </Acp.Enum>
              </Acp.Col.Enum>
              <Acp.Col.Text value="target.name" header="Name" options={{ isNonWrapped: true }} />

              <Acp.Col.Text value="target.created_by.email" header="Creator Email" />

              <Acp.ColEditable.Text value="sales_win_attributes.sales_force_id" header="Salesforce ID" />

              <Acp.ColEditable.Manager
                value="sales_win_attributes.syft_sales_manager_id"
                header="Sales mgr."
                headerTooltip="Sales manager"
                options={{ managerType: 'sales_manager' }}
              />
              <Acp.ColEditable.Manager
                value="sales_win_attributes.syft_account_manager_id"
                header="Acc. mgr."
                headerTooltip="Account manager"
                options={{ managerType: 'account_manager' }}
              />
            </Acp.ColGroup>

            <Acp.ColGroup title="Invoicing details">
              <Acp.ColEditable.Text
                value="sales_win_attributes.fee_percent"
                header="Flex Fee %"
                options={{ suffix: '%' }}
              />

              <Acp.ColEditable.Dropdown value="sales_win_attributes.tier" header="Priority">
                {PRIORITIES.map(priority => (
                  <Acp.Option slug={priority.id}>{priority.label}</Acp.Option>
                ))}
              </Acp.ColEditable.Dropdown>
              <Acp.ColEditable.Dropdown
                value="sales_win_attributes.invoicing_type"
                header="Type"
                headerTooltip="Invoicing type"
              >
                <Acp.Option slug="venue">By venue</Acp.Option>
                {isGBSpecific && <Acp.Option slug="by_period">By period</Acp.Option>}
                <Acp.Option slug="purchase_order_number">By purchase order no.</Acp.Option>
                <Acp.Option slug="event_name">By event name</Acp.Option>
                <Acp.Option slug="area">By area</Acp.Option>
                <Acp.Option slug="shift_type">By shift type</Acp.Option>
                <Acp.Option slug="worker">By worker</Acp.Option>
                <Acp.Option slug="job_role">By job role</Acp.Option>
              </Acp.ColEditable.Dropdown>
              <Acp.ColEditable.Dropdown
                value="sales_win_attributes.invoicing_period"
                header="Period"
                headerTooltip="Invoicing period"
              >
                <Acp.Option slug="week">Week</Acp.Option>
                <Acp.Option slug="date">Date</Acp.Option>
              </Acp.ColEditable.Dropdown>

              <Acp.ColEditable.Text
                value="sales_win_attributes.invoicing_name"
                header="Inv. name"
                headerTooltip="Name of the client as you want it to appear on the invoice"
              />
              <Acp.ColEditable.Text
                value="sales_win_attributes.invoicing_email"
                header="Inv. email"
                headerTooltip="Email address of the client used for invoicing"
              />
              <Acp.ColEditable.Text
                value="sales_win_attributes.payment_credit_days"
                header="PCD"
                headerTooltip="Payment credit days"
              />
              <Acp.ColEditable.Boolean
                value="sales_win_attributes.one_line_invoicing"
                header="1LI"
                headerTooltip="One line invoicing"
              />
            </Acp.ColGroup>

            <Acp.ColGroup title="Current promotional plan">
              <Acp.Col.Text value="sales_win_attributes.strategy" header="Strategy" isMinimal />
              <Acp.Col.Text
                value="sales_win_attributes.promo_period"
                header="Period"
                headerTooltip="Promotion period"
              />
              <Acp.Col.Text
                value="sales_win_attributes.promo_fee_percent"
                header="Fee"
                headerTooltip="Promotion fee"
              />
              <Acp.Col.Timestamp
                value="sales_win_attributes.promo_start_date"
                header="Effective date"
                options={{ useFormat: 'date' }}
              />
              <Acp.Col.Timestamp
                value="sales_win_attributes.promo_end_date"
                header="Finish date"
                options={{ useFormat: 'date' }}
              />
              <Acp.Col.Text value="sales_win_attributes.commitment_period" header="Com. period" />
              <Acp.Col.Text
                value="sales_win_attributes.minimum_annual_client_spend"
                header="Min. spend (PA)"
              />
            </Acp.ColGroup>

            <Acp.ColGroup empty>
              <Acp.Col.Button header="Save" isMinimal>
                <Acp.Button kind="success" onClick={entitySave}>
                  Save
                </Acp.Button>
              </Acp.Col.Button>
            </Acp.ColGroup>
          </Acp.Table>
        </Acp.EntityList>
      )}
    </CountrySpecific>
  )
}

EmployerSalesForce.propTypes = {
  employerID: PropTypes.number.isRequired,
  employerName: PropTypes.string.isRequired,
  dataSalesforce: PropTypesAtom.isRequired,
  entitySave: PropTypes.func.isRequired,
  isLoading: PropTypesAtom.isRequired,
}

export default EmployerSalesForce
