import { apiRequest, handleCall } from 'syft-acp-core/api/call'

/**
 * Retrieves a list of payments. Implements pagination.
 *
 * @param params {Object}
 * @param params.options {Object} Other query options
 */
export const fetchPayments = ({ options = {} }) =>
  new Promise((resolve, reject) => {
    const req = apiRequest({ path: '/admin/payments', reqArgs: options })
    handleCall(req, resolve, reject, true, options)
  })
