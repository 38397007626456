import { isEmpty, omit } from 'lodash-es'

import { apiRequest, handleCall } from 'syft-acp-core/api/call'

import {
  PatchShiftInput,
  ListShiftsInput,
  StopNewWorkersInput,
  RemoveAdminShiftSpotInput,
  Geolocation as GeolocationOptions,
} from '../types'

const PERMITTED_SORT_ORDERS = [
  // Default: sort by shift start time
  'start_time_asc',
  // Used on Shifts Fulfilment page: sort by impact score
  // See: src/core/entities2/ShiftFulfilmentList/ShiftFulfilmentList.tsx:154
  'fulfilment_review_impact_score_desc',
]

const validateFilterSortOrder = (sortOrder: string): string =>
  PERMITTED_SORT_ORDERS.includes(sortOrder) ? sortOrder : 'start_time_asc'

export const patchShift = ({ payload }: PatchShiftInput) =>
  new Promise((resolve, reject) => {
    // We send 'external_booking_uuids_attributes' and get 'external_booking_uuids' back.
    // eslint-disable-next-line no-param-reassign
    // @ts-expect-error
    payload.external_booking_uuids_attributes = payload.external_booking_uuids
    // eslint-disable-next-line no-param-reassign
    delete payload.external_booking_uuids
    const req = apiRequest({
      path: `/shifts/${payload.id}`,
      reqArgs: payload,
      method: 'PATCH',
      allowNulls: true,
    })
    handleCall(req, resolve, reject)
  })

/** Only add parameters to request if they are all present */
export const geolocation = (options: Partial<GeolocationOptions>) => {
  const { longitude, latitude, radius, radius_unit } = options
  if (latitude != null && longitude != null && radius != null && radius_unit != null)
    return { longitude, latitude, radius, radius_unit }
  return { longitude: undefined, latitude: undefined, radius: undefined, radius_unit: undefined }
}

/**
 * Retrieves a list of shifts. Implements pagination.
 */
export const listShifts = ({ options = {}, isCSV, sourceComponent }: ListShiftsInput) => {
  const { sort_order = null } = options
  const path = isCSV ? '/admin/shifts.csv' : '/admin/shifts'
  return new Promise((resolve, reject) => {
    // Check whether we are using filters, to determine how to sort the results.
    // See CMS2-315 for details. Don't take 'page' into account.
    const usingFilters = !isEmpty(omit(options, ['page']))

    // Note: ONLY pass on the sort_order to the request. We can't handle our internal call
    // with the sort_order because it will cause the result set to receive a different hash.
    // In other words, sort order is done opaquely without the rest of the system knowing it.
    const req = apiRequest({
      path,
      reqArgs: {
        ...{ ...options, exclude_bookings: true },
        ...(isCSV && { report: 'time_role_name' }),
        ...(sourceComponent && { source_page: sourceComponent }),
        ...geolocation(options),
        // If we are not using any filters, sort by creation date, descending.
        // If we are using filters of any kind, sort by shift start date, ascending.
        sort_order: usingFilters ? validateFilterSortOrder(sort_order) : 'created_at_desc',
      },
    })
    // Pass the original options back so we don't save the cache under a different key.
    handleCall(req, resolve, reject, true, options, null, isCSV, undefined, isCSV)
  })
}

// https://syftapp.atlassian.net/wiki/display/SV/Admin+Stop+New+Workers+API
export const stopNewWorkers = ({ shiftID, payload: reqArgs }: StopNewWorkersInput) =>
  new Promise((resolve, reject) => {
    const req = apiRequest({ path: `/admin/shifts/${shiftID}/stop_new_workers`, reqArgs, method: 'PUT' })
    handleCall(req, resolve, reject)
  })

export const removeAdminShiftSpot = ({ payload: reqArgs }: RemoveAdminShiftSpotInput) =>
  new Promise((resolve, reject) => {
    const req = apiRequest({ path: `/admin/shifts/${reqArgs.shift_id}/remove_spot`, reqArgs, method: 'POST' })
    handleCall(req, resolve, reject, true)
  })

export const blockWorkerForClient = ({
  workerId,
  employerId,
  venueId,
}: {
  workerId: number
  employerId: number
  venueId?: number
}) =>
  new Promise((resolve, reject) => {
    const workLocationIds = venueId ? { work_location_ids: [venueId] } : {}
    const req = apiRequest({
      path: `/employers/${employerId}/blacklists/manage_worker/${workerId}`,
      reqArgs: { worker_id: workerId, ...workLocationIds },
      method: 'PUT',
    })
    handleCall(req, resolve, reject)
  })
