export const CONDUCT_REVIEWS_FETCH_BEGIN = 'syftacp/CONDUCT_REVIEWS_FETCH_BEGIN'
export const CONDUCT_REVIEWS_FETCH_FAILED = 'syftacp/CONDUCT_REVIEWS_FETCH_FAILED'
export const CONDUCT_REVIEWS_FETCH_SUCCEEDED = 'syftacp/CONDUCT_REVIEWS_FETCH_SUCCEEDED'

export const CONDUCT_REVIEW_FETCH_BEGIN = 'syftacp/CONDUCT_REVIEW_FETCH_BEGIN'
export const CONDUCT_REVIEW_FETCH_FAILED = 'syftacp/CONDUCT_REVIEW_FETCH_FAILED'
export const CONDUCT_REVIEW_FETCH_SUCCEEDED = 'syftacp/CONDUCT_REVIEW_FETCH_SUCCEEDED'

export const CONDUCT_REVIEWS_ASSIGN_BEGIN = 'syftacp/CONDUCT_REVIEWS_ASSIGN_BEGIN'
export const CONDUCT_REVIEWS_ASSIGN_FAILED = 'syftacp/CONDUCT_REVIEWS_ASSIGN_FAILED'
export const CONDUCT_REVIEWS_ASSIGN_SUCCEEDED = 'syftacp/CONDUCT_REVIEWS_ASSIGN_SUCCEEDED'

export const CONDUCT_REVIEWS_ASSIGNEES_FETCH_BEGIN = 'syftacp/CONDUCT_REVIEWS_ASSIGNEES_FETCH_BEGIN'
export const CONDUCT_REVIEWS_ASSIGNEES_FETCH_FAILED = 'syftacp/CONDUCT_REVIEWS_ASSIGNEES_FETCH_FAILED'
export const CONDUCT_REVIEWS_ASSIGNEES_FETCH_SUCCEEDED = 'syftacp/CONDUCT_REVIEWS_ASSIGNEES_FETCH_SUCCEEDED'

export const CONDUCT_REVIEW_UPDATE_BEGIN = 'syftacp/CONDUCT_REVIEW_UPDATE_BEGIN'
export const CONDUCT_REVIEW_UPDATE_FAILED = 'syftacp/CONDUCT_REVIEW_UPDATE_FAILED'
export const CONDUCT_REVIEW_UPDATE_SUCCEEDED = 'syftacp/CONDUCT_REVIEW_UPDATE_SUCCEEDED'
