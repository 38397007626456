// Syft ACP - Lib <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import * as React from 'karet'
import * as U from 'karet.util'
import PropTypes from 'prop-types'
import { range, get } from 'lodash-es'

import './LoadingSpinner.css'

// Validates a Calmm atom prop.
const PropTypeAtom = PropTypes.shape({
  _source: PropTypes.object.isRequired,
  _lens: PropTypes.any.isRequired,
})

/**
 * Renders a spinner style loading indicator.
 *
 * The 'isLoading' determines whether the indicator is visible or not,
 * and it can be either a boolean or a lens evaluating to a boolean.
 */
const LoadingSpinner = ({ isLoading, bars, colorDark, colorLight }) =>
  get(isLoading, 'get', get(isLoading, '_alive', null)) !== null &&
  get(isLoading, 'get') &&
  isLoading.get() !== undefined ? (
    isLoading.get()
  ) : isLoading ? (
    <div data-testid="loading-spinner" className={U.cns('LoadingSpinner', U.ifElse(isLoading, 'isLoading', null))}>
      <svg
        width="28px"
        height="28px"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        viewBox="0 0 14 14"
        shapeRendering="geometricPrecision"
        preserveAspectRatio="xMidYMid"
        style={{ background: 'none' }}
      >
        <g id="loader">
          {range(bars).map(n => {
            const rotate = n * (360 / bars)
            const begin = (bars - n) / -bars
            return (
              <g key={n} transform={`rotate(${rotate} 7 7)`}>
                <rect x="6.72" y="1.35" rx="0.294" ry="0.135" width="0.68" height="3.32">
                  <animate
                    attributeName="fill"
                    values={`${colorDark};${colorLight}`}
                    dur="1s"
                    begin={`${begin}s`}
                    repeatCount="indefinite"
                  />
                </rect>
              </g>
            )
          })}
        </g>
      </svg>
    </div>
  ) : null

LoadingSpinner.defaultProps = {
  isLoading: false,
  bars: 12,
  colorDark: '#1f2022',
  colorLight: '#f3f4f5',
}

LoadingSpinner.propTypes = {
  isLoading: PropTypes.oneOfType([PropTypes.bool, PropTypeAtom]),
  bars: PropTypes.number,
  colorDark: PropTypes.string,
  colorLight: PropTypes.string,
}

export default LoadingSpinner
