// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import { get, memoize, keyBy, cloneDeep } from 'lodash-es'
import { createSelector } from 'reselect'
import entityReducer from './generators/entityReducer'

import { entitySetName } from 'syft-acp-core/reducers/generators/utils'
import * as types from 'syft-acp-core/actions/action-types'
import * as entityTypes from 'syft-acp-core/reducers/generators/entities'

/**
 * Selects all documents for a specific worker.
 * Returns items in format:
 *
 * { 'driving-licence': ...,
 *   'passport': ... }
 */
export const selectWorkerDocuments$ = createSelector(
  state => state,
  store =>
    memoize(workerID => {
      const ids = get(store, `workerDocuments.entitySets[${entitySetName({ workerID })}].ids`, [])
      const items = ids.map(id => get(store, `workerDocuments.entityMap[${id}]`, {}))
      return keyBy(items, 'type')
    }),
)

export default entityReducer(
  'workerDocuments',
  {
    [entityTypes.ENTITIES_FETCH_BEGIN]: types.WORKER_DOCUMENTS_FETCH_BEGIN,
    [entityTypes.ENTITIES_FETCH_FAILED]: types.WORKER_DOCUMENTS_FETCH_FAILED,
    [entityTypes.ENTITY_FETCH_BEGIN]: types.WORKER_DOCUMENT_FETCH_BEGIN,
    [entityTypes.ENTITY_FETCH_SUCCEEDED]: types.WORKER_DOCUMENT_FETCH_SUCCEEDED,
    [entityTypes.ENTITY_FETCH_FAILED]: types.WORKER_DOCUMENT_FETCH_FAILED,
    [entityTypes.ENTITY_SAVE_BEGIN]: types.WORKER_DOCUMENT_SAVE_BEGIN,
    [entityTypes.ENTITY_SAVE_SUCCEEDED]: types.WORKER_DOCUMENT_SAVE_SUCCEEDED,
    [entityTypes.ENTITY_SAVE_FAILED]: types.WORKER_DOCUMENT_SAVE_FAILED,
    [entityTypes.ENTITY_DELETE_BEGIN]: [types.WORKER_DOCUMENT_DELETE_BEGIN, types.ATTACHMENT_DELETE_BEGIN],
    [entityTypes.ENTITY_DELETE_SUCCEEDED]: types.WORKER_DOCUMENT_DELETE_SUCCEEDED,
    [entityTypes.ENTITY_DELETE_FAILED]: [types.WORKER_DOCUMENT_DELETE_FAILED, types.ATTACHMENT_DELETE_FAILED],
    [types.WORKER_DOCUMENTS_FETCH_SUCCEEDED]: (state, action) => {
      // Replace the local state, because one of the documents might have been
      // deleted and the UI needs to be aware of this.
      const entityMap = keyBy(action.payload, 'id')

      return {
        ...state,
        isSavingData: false,
        lastMessage: '',
        lastStatus: null,
        lastBody: null,
        isLoadingData: false,
        entityMap,
        entitySets: {
          ...state.entitySets,
          [`workerID=${action.request.workerID}`]: {
            ...state.entitySets[`workerID=${action.request.workerID}`],
            entityMap,
            ids: action.payload.map(doc => doc.id),
          },
        },
      }
    },
    [types.WORKER_DOCUMENT_UPDATE_ATTACHMENTS]: (state, action) => {
      // When adding a new attachment, briefly add an extra 'null' to the array of attachments.
      // This updates the number in the UI before we've actually fetched the document.
      return {
        ...state,
        entityMap: {
          ...state.entityMap,
          [action.id]: {
            ...state.entityMap[action.id],
            attachments: [
              ...state.entityMap[action.id].attachments,
              ...new Array(action.amount).fill(null).slice(state.entityMap[action.id].attachments.length),
            ],
          },
        },
      }
    },
    [types.ATTACHMENT_DELETE_SUCCEEDED]: (state, action) => {
      // We get an update of the attachment after deleting it.
      // Update the existing item.
      const { documentID, secureToken } = action.request

      // Clone the list of attachments, except use the new payload for the one we've deleted.
      const newAttachments = cloneDeep(state.entityMap[documentID].attachments).map(a =>
        a.secure_token === secureToken ? action.payload : a,
      )

      return {
        ...state,
        isSavingData: false,
        lastMessage: '',
        lastStatus: null,
        lastBody: null,
        isLoadingData: false,
        entityMap: {
          ...state.entityMap,
          [documentID]: {
            ...state.entityMap[documentID],
            attachments: newAttachments,
          },
        },
      }
    },
  },
  {
    localEntityMap: true,
    groupBy: ['workerID'],
  },
)
