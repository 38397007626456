// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import React from 'react'
import { Link } from 'react-router-dom'
import { useTrackingTrigger } from '@indeed/flex-tracking-context'

import { Button } from 'syft-acp-atoms/Button'

import { trackingEvents } from './EmployerRateCardList.tracking'
import EmployerDetailListWrapper from '../EmployerDetailListWrapper'

type RateCardsControlsProps = {
  disableRateCardsSelected: boolean
  enableRateCardsSelected: boolean
  employerID: number
  onDisableClick: () => void
  onEnableClick: () => void
}

const RateCardsControls = ({
  employerID,
  disableRateCardsSelected,
  enableRateCardsSelected,
  onDisableClick,
  onEnableClick,
}: RateCardsControlsProps) => {
  const trigger = useTrackingTrigger()

  const handleNew = () => {
    trigger(trackingEvents.NEW.CLICKED)
  }

  return (
    <>
      <div style={{ display: 'flex', gap: '10px' }}>
        <Button kind="danger" disabled={!disableRateCardsSelected} onClick={onDisableClick}>
          Disable
        </Button>
        <Button kind="primary" disabled={!enableRateCardsSelected} onClick={onEnableClick}>
          Enable
        </Button>
        <Link
          to={`/entity/employers/view/${employerID}/ratecards-v2/new`}
          className="btn Button success new-rate-card"
          onClick={handleNew}
        >
          New rate card
        </Link>
      </div>
    </>
  )
}

type Props = {
  entityList: React.ReactElement
} & RateCardsControlsProps

const EmployerRateCardListWrapper = (props: Props) => (
  <EmployerDetailListWrapper
    controlsRight={
      <RateCardsControls
        disableRateCardsSelected={props.disableRateCardsSelected}
        enableRateCardsSelected={props.enableRateCardsSelected}
        employerID={props.employerID}
        onDisableClick={props.onDisableClick}
        onEnableClick={props.onEnableClick}
      />
    }
    {...props}
  />
)

export default EmployerRateCardListWrapper
