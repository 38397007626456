import * as structureTypes from 'syft-acp-core/components/EditableTable'
import * as tableType from 'syft-acp-core/components/EntityList/'

export const dataStructure = [
  [
    'id',
    {
      header: 'ID',
    },
  ],
  [
    'worker',
    {
      header: 'Worker',
      type: structureTypes.TYPE_PARTICIPANT,
    },
  ],
  [
    'employer',
    {
      header: 'Employer',
      type: structureTypes.TYPE_PARTICIPANT,
    },
  ],
  [
    'last_message_at',
    {
      header: 'Last message',
      type: structureTypes.TYPE_TIMESTAMP,
    },
  ],
  [
    'job.venue_name',
    {
      header: 'Last job venue',
    },
  ],
  [
    'job.role_name',
    {
      header: 'Last job role',
    },
  ],
  [
    'job.start_time',
    {
      header: 'Last job start time',
      type: structureTypes.TYPE_TIMESTAMP,
    },
  ],
]

export const messagesTableFormat = [
  {
    type: tableType.DATE,
    val: 'sent_at',
    header: 'Date',
    classes: ['no-wrap'],
  },
  {
    type: tableType.TIME,
    val: 'sent_at',
    header: 'Time',
    classes: ['no-wrap'],
  },
  {
    type: tableType.USER_LINE,
    val: 'sender',
    header: 'Sender',
    classes: ['no-wrap'],
  },
  {
    type: tableType.TXT,
    val: 'body',
    header: 'Message',
    classes: ['main'],
  },
]
