// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import React, { useMemo } from 'react'
import { flowRight } from 'lodash-es'
import { bindActionCreators, Dispatch, AnyAction } from 'redux'
import { connect } from 'react-redux'
import { useTrackingTrigger } from '@indeed/flex-tracking-context'

import Acp from 'syft-acp-uikit'
import { createEntityListConnector } from 'syft-acp-util/entityList'
import {
  markExpiringDocumentsAsResolved,
  fetchExpiringDocuments,
} from 'syft-acp-core/store/expiringDocuments/actions'
import { ExpiringDocumentEntity } from 'syft-acp-core/store/expiringDocuments/types'
import { showConfirmModal } from 'syft-acp-core/store/modals/actions'

import { CheckIcon } from 'syft-acp-uikit/components/AcpTable/headerComponents'
import {
  ExpiringDocumentsListEntityConnectedProps as EntityConnectorProps,
  ExpiringDocumentsListProps as Props,
} from './ExpiringDocumentsList.types'
import {
  getFormattedExpiryDate,
  getFormattedDocType,
  getRowColor,
  getRowValue,
} from './ExpiringDocumentsList.helpers'
import { trackingEvents } from './ExpiringDocumentsList.tracking'
import useFetchEntity from 'syft-acp-util/hooks/useFetchEntity'

const ExpiringDocumentsList = ({ data, isLoadingData, actions, selectedItems, query }: Props) => {
  const triggerEvent = useTrackingTrigger()
  useFetchEntity(query, actions.fetchEntities)

  const markResolved = () => {
    actions.showConfirmModal({
      question:
        selectedUnresolvedItems.length === 1
          ? 'Mark this document as resolved?'
          : `Mark these ${selectedUnresolvedItems.length} documents as resolved?`,
      onConfirm: () => {
        const ids = selectedUnresolvedItems.map(items => items.id)
        triggerEvent(trackingEvents.RESOLVE_BTN.CLICKED, {
          resolved_document_ids: ids,
        })
        actions.markExpiringDocumentsAsResolved(ids)
      },
    })
  }

  const cleanExpiryDate = (rowData: ExpiringDocumentEntity) => getFormattedExpiryDate(rowData.expires_in)

  const selectedUnresolvedItems: ExpiringDocumentEntity[] = useMemo(() => {
    return selectedItems.filter(selectedItem =>
      data.data
        .filter(item => !item.resolved)
        .map(item => item.id)
        .includes(selectedItem.id),
    )
  }, [selectedItems, data.data])

  return (
    <Acp.EntityList data={data} isLoading={isLoadingData} inContainer hasResultCount hasPagination>
      <Acp.Actions>
        <Acp.Button
          disabled={isLoadingData || selectedUnresolvedItems.length === 0}
          kind="primary"
          onClick={markResolved}
        >
          Mark as resolved
        </Acp.Button>
      </Acp.Actions>
      <Acp.Table rowLink={row => `/entity/workers/view/${row.worker_id}`} rowColor={getRowColor}>
        <Acp.Col.Selector
          colNoLink
          isMinimal
          align="center"
          value={getRowValue}
          headerValue={getRowValue}
          options={{ scope: 'expiringDocuments', valueKeys: ['id'] }}
        />
        <Acp.Col.Text value="id" header="ID" isNumeric />
        <Acp.Col.Text value="worker_id" header="W.ID" headerTooltip="Worker ID" isNumeric />
        <Acp.Col.Text value="worker_name" header="Name" headerWrapping={false} isNonWrapped />
        <Acp.Col.Text value="email" header="Email" />
        <Acp.Col.Phone value="telephone_number" header="Tel." />
        <Acp.Col.Text value={getFormattedDocType} header="Document type" />
        <Acp.Col.Text value={cleanExpiryDate} header="Expires in" isNonWrapped />
        <Acp.Col.Text
          value="count_booked_shifts_after_expiry_date"
          header="Booked shifts after expiry"
          isNumberic
        />
        <Acp.Col.Boolean value="resolved" header={<CheckIcon />} headerTooltip="Checkmark" />
      </Acp.Table>
    </Acp.EntityList>
  )
}

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>, props: EntityConnectorProps) => ({
  actions: {
    ...props.actions,
    ...bindActionCreators(
      { markExpiringDocumentsAsResolved, showConfirmModal },

      dispatch,
    ),
  },
})

export const entityConnector = createEntityListConnector<ExpiringDocumentEntity>({
  entityActions: { fetchEntities: fetchExpiringDocuments },
  entityStore: 'expiringDocuments',
})

export const storeConnector = connect(null, mapDispatchToProps)

export default flowRight(entityConnector, storeConnector)(ExpiringDocumentsList)
