import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { getModal$ } from 'syft-acp-core/store/modals/selectors'
import { Modal } from 'syft-acp-core/components/Modal'
import { hideModal } from 'syft-acp-core/store/modals/actions'
import * as modalActions from 'syft-acp-core/store/modals/actions'
import { notify } from 'syft-acp-core/actions/notifications'
import { toLocalISO8601 } from 'syft-acp-util/formatting'
import { stopCommission } from 'syft-acp-core/api/resources/admin/commissions'

import './StopManagerCommissionModal.css'

class StopManagerCommissionModal extends PureComponent {
  static propTypes = {
    employerId: PropTypes.string.isRequired,
    item: PropTypes.object.isRequired,
    dispatch: PropTypes.func.isRequired,
    actions: PropTypes.object.isRequired,
  }

  onConfirmHandler = () => {
    const { item, employerId: employer_id, dispatch } = this.props
    const data = {
      employer_id,
      manager_commission_id: item.options.id,
      id: item.options.dataId,
      effective_time_to: toLocalISO8601(Date.now()),
    }
    stopCommission({ options: data })
      .then(result => {
        dispatch(
          notify('success', {
            title: 'Success',
            message: 'Commission stopped',
          }),
        )
        this.props.item.options.onConfirm(result)
      })
      .catch(error => {
        const message = error.body.error_description
        dispatch(
          notify('error', {
            title: 'Error',
            message,
          }),
        )
      })
    return this.closeModal()
  }

  closeModal = () => {
    this.props.dispatch(hideModal(this.props.item.modalName))
    this.setState(this.initialState)
  }

  render() {
    const { item } = this.props
    return (
      <Modal
        header="Stop commission"
        isShown={item.isShown}
        onClose={this.closeModal}
        onConfirm={this.onConfirmHandler}
        confirmationText="Submit"
      >
        <div>
          <p>
            This action will stop the commission with an effective date of today. If you need to specify a
            different end date there are two options:
          </p>
          <ol>
            <li>
              Add a new commission with the desired start date. The system will automatically stop current
              active commission(s) for this item with an effective end date of the day before the start date.
            </li>
            <li>Modify that stop date directly with the Inspector tool.</li>
          </ol>
        </div>
      </Modal>
    )
  }
}

export default connect(
  state => ({
    item: getModal$(state.modals)('stopManagerCommissionModal'),
  }),
  dispatch => ({
    dispatch,
    actions: bindActionCreators({ ...modalActions }, dispatch),
  }),
)(StopManagerCommissionModal)
