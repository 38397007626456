export const trackingEvents = {
  workerCsat: {
    VIEWED: 'worker_csat.page.viewed',
    DOWNLOAD_DATA_CSV: {
      CLICKED: 'worker_csat.data_download_csv.clicked',
    },
    DOWNLOAD_RESULT_CSV: {
      CLICKED: 'worker_csat.result_download_csv.clicked',
    },
  },
  employerCsat: {
    VIEWED: 'employer_csat.page.viewed',
    DOWNLOAD_DATA_CSV: {
      CLICKED: 'employer_csat.data_download_csv.clicked',
    },
    DOWNLOAD_RESULT_CSV: {
      CLICKED: 'employer_csat.result_download_csv.clicked',
    },
  },
  agencyCsat: {
    VIEWED: 'agency_csat.page.viewed',
    DOWNLOAD_DATA_CSV: {
      CLICKED: 'agency_csat.data_download_csv.clicked',
    },
    DOWNLOAD_RESULT_CSV: {
      CLICKED: 'agency_csat.result_download_csv.clicked',
    },
  },
}
