export const managerPositionReasons = [
  {
    value: 'AM',
    label: 'AM',
    message: 'AM',
  },
  {
    value: 'BDM',
    label: 'BDM',
    message: 'BDM',
  },
]

export const subcategories = [
  {
    id: 1,
    value: 'Front of House',
    label: 'Front of House',
    message: 'Front of House',
  },
  {
    id: 2,
    value: 'Back of House',
    label: 'Back of House',
    message: 'Back of House',
  },
]
