import PropTypes from 'prop-types'
import React from 'react'
import { useTrackingTrigger, TrackingProvider, TrackingTrigger } from '@indeed/flex-tracking-context'

import WorkerTransferDetail from 'syft-acp-core/entities/WorkerTransferDetail/WorkerTransferDetail'
import { trackingEvents } from 'syft-acp-core/entities/WorkerTransferDetail/WorkerTransferDetail.tracking'
import PageView from 'syft-acp-core/components/PageView'
import { WorkerSubNavigation } from './SubNavigation'

export const EntityWorkersTransferDetailView = ({ match: { params } }) => {
  const triggerEvent = useTrackingTrigger()
  return (
    <TrackingProvider
      value={{
        context: {
          worker_id: params.id,
        },
      }}
    >
      <TrackingTrigger onLoad={{ event: trackingEvents.PAGE.VIEWED }} />

      <WorkerSubNavigation workerID={params.id} />
      <PageView title={['Workers', `Worker ${params.id} transfer details`]} container>
        <WorkerTransferDetail workerID={params.id} triggerEvent={triggerEvent} />
      </PageView>
    </TrackingProvider>
  )
}

EntityWorkersTransferDetailView.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.object.isRequired,
  }),
}
