// Syft ACP - Lib <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import React from 'react'
import PropTypes from 'prop-types'
import LoadingSpinner from 'syft-acp-atoms/LoadingSpinner'

const LoadingSpinnerWrapper = props => {
  const { loadingState } = props
  return <LoadingSpinner {...props} isLoading={loadingState} />
}
LoadingSpinnerWrapper.propTypes = {
  loadingState: PropTypes.bool,
}
LoadingSpinnerWrapper.defaultProps = {
  loadingState: false,
}

// TODO: remove references to LoadingIndicator. Use LoadingSpinner everywhere,
// and rename 'loadingState' to 'isLoading'.
export default LoadingSpinnerWrapper
