// Syft ACP - UI Library <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import classnames from 'classnames'
import React from 'react'
import PropTypes from 'prop-types'
import { isArray, isPlainObject } from 'lodash-es'
import { FormControl } from 'react-bootstrap'

import { filterDecorator } from '../../filterDecorator'
import { acpFilterDefaults } from '../../dataProps'

const anyValue = 'null'

/**
 * Filter component connected to the location query
 *
 * If Option contains more than 1 children, than 1st children is considered as opt group label and others are opt group items
 * If placeholder is passed, it will be shown as first option (Any) with 'null' value
 *
 * Example of FilterDropdown usage
 *
 * <Acp.Filter.Dropdown placeholder="Any" name="city_id">
 *   <Acp.Option slug={1}>I'm the label</Acp.Option>
 *   <Acp.Option>
 *     I'm opt group label
 *     <Acp.Option slug={21}>I'm opt group item label</Acp.Option>
 *   </Acp.Option>
 * </Acp.Filter.Dropdown>
 */
class AcpFilterDropdown extends React.PureComponent {
  static propTypes = {
    children: PropTypes.node.isRequired,
  }

  static defaultProps = {
    ...acpFilterDefaults,
    children: [],
  }

  // disables debounce set by filter decorator
  static requireDebounce = false

  constructor(props) {
    super(props)
    this.state = {
      value: this.props.initialValue || '',
    }
    this.inputRef = React.createRef()
  }

  componentDidMount() {
    // on mount, select input is uncontrolled and default value is controlled by defaultValue prop
    // check if component state is different than select input value and update internal state
    if (this.state.value !== this.inputRef.current.value && this.inputRef.current.value !== anyValue) {
      this.setState({
        value: this.parseValue(this.inputRef.current.value),
      })
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { name, setValue, onChange } = this.props

    // set query parameter on state value change
    if (prevState.value !== this.state.value) {
      !!onChange && onChange(name, this.state.value)
      setValue(name, this.state.value)
    }
    if (prevProps.query[this.props.name] !== this.props.query[this.props.name]) {
      this.setState({ value: this.props.query[this.props.name] || '' })
    }
  }

  parseValue = val => {
    const id = parseInt(val, 10)
    return isNaN(id) ? val : id
  }

  handleChange = e => {
    const value = e.target.value

    if (value === anyValue) {
      return this.setState({ value: '' })
    }

    this.setState({
      value: this.parseValue(value),
    })
  }

  /**
   *
   * @returns {Array<Exclude<unknown, boolean | null | undefined>>}
   */
  renderInnerOptions = options =>
    (options &&
      options.map((option, id) => {
        if (isPlainObject(option)) {
          return (
            <option value={option.slug} key={`${option.slug}-${id}`}>
              {option.children}
            </option>
          )
        } else if (isArray(option)) {
          return (
            <optgroup label={option[0]} key={`${option[0]}-${id}`}>
              {this.renderInnerOptions(option[1])}
            </optgroup>
          )
        }
        return null
      })) ||
    null

  /**
   * Iterates through the FilterDropdown children and creates options for FormControl
   *
   * If Option contains more than 1 children, than 1st children is considered as opt group label and others are opt group items
   */
  renderOptions = () => {
    const { childrenOptions, placeholder } = this.props
    const hasAnyOption = !!placeholder
    const options = this.renderInnerOptions(childrenOptions)
    const anyOption = (
      <option value={anyValue} key="any-option-null" defaultValue={anyValue}>
        {placeholder}
      </option>
    )
    return !!hasAnyOption ? [anyOption, ...options] : options
  }

  render() {
    const { className, isSmall, isTiny, isDisabled, name } = this.props

    return (
      <FormControl
        componentClass="select"
        name={name}
        className={classnames(className, 'FilterSelectItem', { small: isSmall, tiny: isTiny })}
        value={this.state.value}
        onChange={this.handleChange}
        disabled={isDisabled}
        inputRef={this.inputRef}
        data-testid="acp-filter-dropdown"
      >
        {this.renderOptions()}
      </FormControl>
    )
  }
}

export default filterDecorator(AcpFilterDropdown)
