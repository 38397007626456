import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { values, sortBy } from 'lodash-es'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as filtersActions from 'syft-acp-core/store/filters/actions'
import * as countriesActions from 'syft-acp-core/actions/countries'
import { filterSelect } from './FilterSelect'
import S from './FilterSelectCities.module.scss'

// NOTE: this autocomplete filter does not use an autocomplete endpoint,
// but a regular API call. It works this way because we have a single
// static list of cities that only needs to be refreshed once per session.

const options = citiesMap =>
  values(sortBy(citiesMap, ['order_number', 'name'])).map(city => ({
    id: city.id,
    label: city.name,
  }))

class FilterSelectCities extends PureComponent {
  static propTypes = {
    name: PropTypes.string.isRequired,
    cities: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        label: PropTypes.string.isRequired,
      }),
    ).isRequired,
    actions: PropTypes.objectOf(PropTypes.func).isRequired,
    disabled: PropTypes.bool,
    allowNameLabel: PropTypes.bool,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    allowAny: PropTypes.bool,
    hasCitiesData: PropTypes.bool,
    type: PropTypes.string,
    queryAttribute: PropTypes.string,
    onChange: PropTypes.func,
  }

  static defaultProps = {
    disabled: false,
    value: null,
    allowAny: false,
    hasCitiesData: false,
    type: null,
  }

  componentDidMount() {
    const { hasCitiesData, actions } = this.props
    if (!hasCitiesData) {
      actions.fetchCountries()
    }
  }

  onChange = val => {
    const { actions, name, onChange } = this.props
    const value = val !== null && !!val ? String(val) : val

    !!onChange && onChange(name, value)
    actions.setFilter(name, value, value)
  }

  render() {
    const { cities, name, value, disabled, type, allowNameLabel } = this.props
    const FilterSelect = filterSelect(cities, this.props.allowAny ? 'Any' : 'City', type)
    const additionalProps = allowNameLabel
      ? {
          ariaLabel: name,
        }
      : {}
    return (
      <FilterSelect
        name={name}
        className={S['select-cities']}
        onChange={this.onChange}
        value={value}
        allowAny={this.props.allowAny}
        disabled={disabled}
        {...additionalProps}
      />
    )
  }
}

export default connect(
  (state, { value, queryAttribute = 'city_id' }) => ({
    cities: options(state.cities.entityMap),
    hasCitiesData: state.cities.hasData,
    value: value || state.routing.locationBeforeTransitions.query[queryAttribute],
  }),
  (dispatch, { actions }) => {
    const boundActions = bindActionCreators(
      {
        ...filtersActions,
        ...countriesActions,
      },
      dispatch,
    )
    return {
      actions: {
        ...boundActions,
        ...actions,
      },
    }
  },
)(FilterSelectCities)
