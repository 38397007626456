import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as filtersActions from 'syft-acp-core/store/filters/actions'
import * as citiesActions from 'syft-acp-core/actions/cities'
import { filterSelect } from './FilterSelect'

class FilterFreeSelect extends PureComponent {
  static propTypes = {
    name: PropTypes.string.isRequired,
    options: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
        label: PropTypes.string.isRequired,
      }),
    ).isRequired,
    actions: PropTypes.objectOf(PropTypes.func).isRequired,
    disabled: PropTypes.bool,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    allowAny: PropTypes.bool,
    type: PropTypes.string,
    header: PropTypes.string,
    ariaLabel: PropTypes.string,
    convertIds: PropTypes.bool,
    categorized: PropTypes.bool,
  }

  static defaultProps = {
    disabled: false,
    value: null,
    allowAny: false,
    type: null,
    header: null,
  }

  onChange = val => {
    const { actions, name } = this.props
    actions.setFilter(name, val, val)
  }

  render() {
    const {
      options,
      name,
      value,
      disabled,
      type,
      header,
      anyOption,
      ariaLabel,
      convertIds = true,
      categorized = false,
    } = this.props
    const FilterSelect = filterSelect(
      options,
      this.props.allowAny ? anyOption ?? 'Any' : header || 'Select',
      type,
      convertIds,
      categorized,
    )

    return (
      <FilterSelect
        name={name}
        onChange={this.onChange}
        value={value}
        allowAny={this.props.allowAny}
        disabled={disabled}
        ariaLabel={ariaLabel}
        categorized={categorized}
      />
    )
  }
}

export default connect(
  (state, { value, queryAttribute = 'city_id' }) => ({
    value: value || state.routing.locationBeforeTransitions.query[queryAttribute],
  }),
  (dispatch, { actions }) => {
    const boundActions = bindActionCreators(
      {
        ...filtersActions,
        ...citiesActions,
      },
      dispatch,
    )
    return {
      actions: {
        ...boundActions,
        ...actions,
      },
    }
  },
)(FilterFreeSelect)
