import React from 'react'
import PropTypes from 'prop-types'

import { showModal } from 'syft-acp-core/lib/modals'
import AddNewMissingTimeModal from 'syft-acp-core/components/Modal/AddNewMissingTimeModal'
import Acp from 'syft-acp-uikit'
import { get } from 'lodash-es'

class MissingTimeManager extends React.PureComponent {
  static propTypes = {
    shiftID: PropTypes.number.isRequired,
    shiftUnpaidTimes: PropTypes.array.isRequired,
  }

  static getDerivedStateFromProps(props, state) {
    return {
      ...state,
      items: [...props.shiftUnpaidTimes, ...state.localItems],
    }
  }

  state = {
    localItems: [],
  }

  addNewModal = async () => {
    const { reloadData } = this.props
    await showModal('addNewMissingTimeModal')
    // Reload data after modal closes.
    reloadData()
  }

  deleteItem = ({ rowData }) => {
    const { deleteItem, notifyModification, shiftID } = this.props
    const workerID = get(rowData, 'worker.id', 0)

    // Sanity check. Should only ever display items with a valid worker ID anyway.
    if (!workerID) {
      return false
    }

    const entry = get(rowData, 'unpaidTime', false)

    // This entry either doesn't exist or is undeletable. Notify the user.
    if (!entry || entry.deletable === false) {
      notifyModification('errorDeleteUndeletable')
    }

    deleteItem({ shiftID, workerID, entry })
  }

  render() {
    const { isLoading, shiftID, availableWorkers } = this.props
    const { items } = this.state
    return (
      <div>
        <AddNewMissingTimeModal shiftID={shiftID} workers={availableWorkers} onClose={this.reloadData} />
        <Acp.EntityList
          hasMinimumSize
          data={{ data: items }}
          isIntegrated
          hasResultCount={false}
          isLoading={isLoading}
          hasPagination={false}
        >
          <Acp.Actions>
            <Acp.Header>Worker missing time</Acp.Header>
            <Acp.Button onClick={this.addNewModal}>Add new</Acp.Button>
          </Acp.Actions>

          <Acp.Table>
            <Acp.Col.Text
              value="unpaidTime.id"
              header="ID"
              headerTooltip="ID of the extra payment given to this worker"
              isNumeric
              isMinimal
            />
            <Acp.Col.Text value="worker.id" header="W.ID" headerTooltip="Worker ID" isNumeric isMinimal />
            <Acp.Col.Text value="shiftID" header="Shift ID" headerTooltip="Shift ID" isNumeric isMinimal />
            <Acp.Col.Worker value="worker" header="Worker" />
            <Acp.Col.Money
              value="workerPayRate"
              header="Pay rate"
              headerTooltip="The hourly pay rate for the booking"
              isMinimal
            />
            <Acp.Col.Duration
              value="unpaidTime.duration"
              header="Missing time"
              headerTooltip="Amount of time that was missing from the worker's payment (hours:minutes)"
              isMinimal
            />
            <Acp.Col.Boolean
              value="unpaidTime.deletable"
              header="Deletable"
              headerTooltip="Whether this item can be deleted (false if the payment has already been made)"
              isMinimal
            />
            <Acp.Col.Button header="Action" isMinimal>
              <Acp.Button
                onClick={this.deleteItem}
                needsConfirm
                confirmHeader="Delete payment correction?"
                confirmText="Payment corrections for workers can be deleted up until the payment deadline."
                confirmYes="Yes"
                confirmNo="No"
              >
                Delete
              </Acp.Button>
            </Acp.Col.Button>
          </Acp.Table>

          <Acp.Footer>
            <p>The extra payments listed here can only be deleted if they have not yet been paid out.</p>
          </Acp.Footer>
        </Acp.EntityList>
      </div>
    )
  }
}

export default MissingTimeManager
