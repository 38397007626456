// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import { createReducer } from '@reduxjs/toolkit'
import * as types from '../actions/action-types'

const initialState = {
  isLoading: false,
  error: null,
}

const rehydrateReset = {
  isLoading: false,
  error: null,
}
/**
 * @param {{isLoading: boolean, error: *}} state
 * @param {Object} action
 * @returns {Reducer<{isLoading: boolean, error: *}>}
 */
const reducer = createReducer(initialState, builder =>
  builder.addDefaultCase((state = initialState, action) => {
    switch (action.type) {
      case types.AUTH_DELETE:
        return initialState
      case types.REHYDRATION_COMPLETE:
        return {
          ...state,
          ...rehydrateReset,
        }
      case types.UPLOAD_ACCESS_REQUEST_DATA_FILE_BEGIN:
        return {
          ...state,
          isLoading: true,
        }
      case types.UPLOAD_ACCESS_REQUEST_DATA_FILE_SUCCEEDED:
        return {
          ...state,
          isLoading: false,
        }
      case types.UPLOAD_ACCESS_REQUEST_DATA_FILE_FAILED:
        return {
          ...state,
          error: 'Failed to upload data.',
          isLoading: false,
        }
      default:
        return state
    }
  })
)

export default reducer
