import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withTrackingTriggerHoc } from 'syft-acp-util/withTrackingTriggerHoc'
import { Button, ButtonGroup } from 'syft-acp-atoms/Button'
import * as settingsActions from 'syft-acp-core/actions/user-settings'
import { ButtonLink } from 'syft-acp-atoms/Button'
import RecordControls from 'syft-acp-core/components/RecordSection/RecordControls'
import LoadingIndicator from 'syft-acp-util/components/LoadingIndicator'
import { trackingEvents } from './UserSettings.tracking'

class UserSettingsControls extends PureComponent {
  static propTypes = {
    id: PropTypes.number.isRequired,
    userData: PropTypes.object.isRequired,
    data: PropTypes.object.isRequired,
    isPristine: PropTypes.bool.isRequired,
    isSavingData: PropTypes.bool.isRequired,
    actions: PropTypes.objectOf(PropTypes.func).isRequired,
    isLoadingData: PropTypes.bool,
    triggerEvent: PropTypes.func,
  }

  static defaultProps = {
    isLoadingData: false,
  }

  saveAction = () => {
    this.props.actions.setValues(this.props.data, this.props.id)
    this.props.triggerEvent(trackingEvents.SAVE_BUTTON.CLICKED)
  }

  render() {
    const { isSavingData, isLoadingData, isPristine } = this.props
    const workerID = this.props.userData.worker && this.props.userData.worker.id
    const employerID = this.props.userData.employer && this.props.userData.employer.id
    return (
      <RecordControls>
        <div className="spacer">
          {workerID && employerID && (
            <ButtonGroup>
              <ButtonLink to={`/entity/workers/view/${workerID}`}>View worker page</ButtonLink>
              <ButtonLink to={`/entity/employers/view/${employerID}`}>View employer page</ButtonLink>
            </ButtonGroup>
          )}
        </div>
        <div className="right">
          <LoadingIndicator loadingState={isSavingData || isLoadingData} />{' '}
          <ButtonGroup>
            <Button kind="success" onClick={this.saveAction} disabled={isSavingData || isPristine}>
              Save
            </Button>
          </ButtonGroup>
        </div>
      </RecordControls>
    )
  }
}

export default connect(
  state => ({
    userData: state.auth.userData,
    isSavingData: state.userSettings.isSavingData,
    isLoadingData: state.userSettings.isLoadingData,
  }),
  dispatch => ({
    actions: bindActionCreators(settingsActions, dispatch),
  })
)(withTrackingTriggerHoc(UserSettingsControls))
