import { upperCase } from 'lodash-es'

// TODO: Try to make these types less hard-coded so that we can easily accommodate more languages in the future

export type CountryCode = 'GB' | 'US'

type SupportCountryCodes = {
  GB: 'GB'
  US: 'US'
}

type Translations = {
  GB: Record<string, string>
  US: Record<string, string>
}

type I18nData = {
  locale: string
  currencySymbol: string
  countryCode: CountryCode
  currencyCode: string
  messages: Record<string, string>
  timeZone: string
}

type CountriesI18nData = {
  GB: I18nData
  US: I18nData
}

export const SUPPORTED_COUNTRY_CODES: SupportCountryCodes = {
  GB: 'GB', // Default country code
  US: 'US',
}

export const translations: Translations = {
  [SUPPORTED_COUNTRY_CODES.GB]: {
    'brand.name': 'Indeed Flex',
    postcode: 'Postcode',
    'platform.syftForce': 'SyftForce',
    use_cost_centre: 'Cost centre code',
    add_worker_id_to_finance_reports: 'Add worker ID in Portal Finance Report',
    'accountManager.header.name': 'Manager Name',
    'accountManager.header.area': 'Area',
    'accountManager.header.industry': 'Industry/Subindustry',
    'accountManager.header.dateFrom': 'Effective date from',
    'accountManager.body.noData': 'No items found.',
  },
  [SUPPORTED_COUNTRY_CODES.US]: {
    'brand.name': 'Indeed Flex',
    postcode: 'Zip code',
    'platform.syftForce': 'Indeed Flex+',
    use_cost_centre: 'Cost center code',
    add_worker_id_to_finance_reports: 'Add worker ID in Portal Finance Report',
    'accountManager.header.name': 'Manager Name',
    'accountManager.header.area': 'Area',
    'accountManager.header.industry': 'Industry/Subindustry',
    'accountManager.header.dateFrom': 'Effective date from',
    'accountManager.body.noData': 'No items found.',
  },
}

const COUNTRIES_I18N_DATA: CountriesI18nData = {
  [SUPPORTED_COUNTRY_CODES.GB]: {
    locale: 'en-GB',
    currencySymbol: '£',
    countryCode: 'GB',
    currencyCode: 'GBP',
    messages: translations[SUPPORTED_COUNTRY_CODES.GB],
    timeZone: 'Europe/London',
  },
  [SUPPORTED_COUNTRY_CODES.US]: {
    locale: 'en',
    currencySymbol: '$',
    countryCode: 'US',
    currencyCode: 'USD',
    messages: translations[SUPPORTED_COUNTRY_CODES.US],
    timeZone: 'America/Chicago',
  },
}

const countryCodes = Object.keys(SUPPORTED_COUNTRY_CODES) as CountryCode[]

export const DEFAULT_TIMEZONES = countryCodes.map(code => COUNTRIES_I18N_DATA[code].timeZone)
export const SUPPORTED_LOCALES = countryCodes.map(code => COUNTRIES_I18N_DATA[code].locale)

/**
 * Get i18n data by country code
 *
 * If country not supported, will throw an error rather fallback to avoid unexpected behavior
 *
 * If country code isn't passed, returning default i18n data, which is UK
 *
 * @param countryCode
 * @returns {*|{countryCode: string, currencySymbol: string, locale: string}}
 */
export const getCountryI18nData = (countryCode = SUPPORTED_COUNTRY_CODES.GB): I18nData => {
  const i18nData = COUNTRIES_I18N_DATA[upperCase(countryCode) as CountryCode]

  if (!i18nData) {
    throw new Error(
      `'${countryCode}' is not currently supported. Update 'COUNTRIES_I18N_DATA' with the i18n data for this country.`,
    )
  }

  return i18nData
}
