import entityReducer from './generators/entityReducer'
import {
  AGENCY_PROFILE_FETCH_BEGIN,
  AGENCY_PROFILE_FETCH_SUCCEEDED,
  AGENCY_PROFILE_FETCH_FAILED,
  AGENCY_PROFILE_UPDATE_BEGIN,
  AGENCY_PROFILE_UPDATE_SUCCEEDED,
  AGENCY_PROFILE_UPDATE_FAILED,
} from '../actions/action-types'
import * as entityTypes from './generators/entities'

export default entityReducer('agency-profile', {
  [entityTypes.ENTITY_FETCH_BEGIN]: AGENCY_PROFILE_FETCH_BEGIN,
  [entityTypes.ENTITY_FETCH_SUCCEEDED]: AGENCY_PROFILE_FETCH_SUCCEEDED,
  [entityTypes.ENTITY_FETCH_FAILED]: AGENCY_PROFILE_FETCH_FAILED,
  [entityTypes.ENTITY_MODIFY_BEGIN]: AGENCY_PROFILE_UPDATE_BEGIN,
  [entityTypes.ENTITY_MODIFY_SUCCEEDED]: AGENCY_PROFILE_UPDATE_SUCCEEDED,
  [entityTypes.ENTITY_MODIFY_FAILED]: AGENCY_PROFILE_UPDATE_FAILED,
})
