import * as types from '../action-types'
import { JobEntity } from './types'
import { ShiftEntity } from 'syft-acp-core/store/shifts/types'

export const updateJob = (
  id: number,
  data: JobEntity,
  onSuccessCallback?: () => void,
  listingId?: number,
  shift?: ShiftEntity,
) => ({
  type: types.JOB_UPDATE_BEGIN,
  id,
  data,
  onSuccessCallback,
  listingId,
  shift,
})
export type UpdateJobAction = ReturnType<typeof updateJob>

export const fetchAdminJob = (id: number) => ({
  type: types.FETCH_ADMIN_JOB_BEGIN,
  payload: {
    id,
  },
})
export type FetchAdminJob = ReturnType<typeof fetchAdminJob>

export const updateStopJobOffersByShift = (id: number, payload: string[]) => ({
  type: types.JOB_STOP_OFFERS_UPDATE_BY_SHIFT,
  id,
  payload,
})
export type UpdateStopJobOfersByShiftAction = ReturnType<typeof updateStopJobOffersByShift>

export const rateWorker = (jobID: number, workerID: number, rate: number) => ({
  type: types.JOB_RATE_WORKER_BEGIN,
  jobID,
  workerID,
  rate,
})
export type RateWorkerAction = ReturnType<typeof rateWorker>

export const deleteRating = (jobID: number, workerID: number) => ({
  type: types.RATING_DELETE_BEGIN,
  jobID,
  workerID,
})
export type DeleteRatingAction = ReturnType<typeof deleteRating>

export type JobAction = UpdateJobAction | RateWorkerAction | DeleteRatingAction
