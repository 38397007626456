import { generateEntityCrudReducer } from 'syft-acp-util/store/reducers'
import matchingExplainabilityTypes from './action-types'
import type { MatchingExplainabilityEventEntity } from './types'

export default generateEntityCrudReducer<MatchingExplainabilityEventEntity>({
  name: 'matchingExplainability',
  actions: matchingExplainabilityTypes,
  options: {
    optionsKey: 'workerId',
  },
})
