import React, { memo } from 'react'

import { toPhoneNumberParts } from 'syft-acp-util/formatting'

import CellTag from '../../CellTag'
import { cellDefaultProps } from '../../dataProps'
import { emptyTextFallback } from '../fallback'
import { colPlaceholderFactory } from '../../../../placeholders/factory'

import { AcpCellPhoneProps as Props } from './AcpCellPhone.types'

/** Column placeholder component. */
export const AcpColPhone = colPlaceholderFactory('AcpCellPhone')

/** Returns node for displaying phone numbers. */
const prettierPhoneNumber = (value: string, localCountryCode?: string, intl?: boolean) => {
  // TODO: Correctly type 'toPhoneNumberParts' function
  const { localFormat, countryCode } = toPhoneNumberParts(value, localCountryCode) as unknown as {
    localFormat: string
    countryCode: string
  }

  if (!countryCode || countryCode !== localCountryCode) {
    return (
      <>
        <span>{localFormat}</span>
        {intl && (
          <>
            &nbsp;
            <CellTag value={countryCode} slug={countryCode} />
          </>
        )}
      </>
    )
  }

  return localFormat
}

/**
 * Column type for displaying phone numbers.
 *
 * ```
 * <Acp.Col.Phone value="phone_number" header="Tel. nr" />
 * ```
 */
const AcpCellPhone = ({ value, options }: Props) => (
  <div className="acp-cell-phone">
    {value == null ? emptyTextFallback : prettierPhoneNumber(value, undefined, options?.intl)}
  </div>
)

AcpCellPhone.defaultProps = cellDefaultProps

export default memo(AcpCellPhone)
