import { FetchUserBanReasonsSucceededPayload } from './types'
import types from './action-types'

export const fetchUsers = ({ options }: { options: {} }) => ({
  type: types.ENTITIES_FETCH_BEGIN,
  options,
})
export type FetchUsersAction = ReturnType<typeof fetchUsers>

/**
 * Ban or unban a user.
 * Worker ID is used to refetch the worker after a successful ban in order to update the UI.
 */
export const banUser = (id: number, banned: boolean, reasonID?: string, workerID?: number) => ({
  type: types.USER_BAN_BEGIN,
  id,
  banned,
  reasonID,
  workerID,
})
export type BanUserAction = ReturnType<typeof banUser>

export const fetchUserBanReasons = () => ({ type: types.USER_BAN_REASONS_FETCH_BEGIN })
export type FetchUserBanReasonsAction = ReturnType<typeof fetchUserBanReasons>

export const fetchUserBanReasonsSucceeded = (
  payload: FetchUserBanReasonsSucceededPayload,
  request: FetchUserBanReasonsAction
) => ({
  type: types.USER_BAN_REASONS_FETCH_SUCCEEDED,
  payload,
  request,
})
export type FetchUserBanReasonsSucceededAction = ReturnType<typeof fetchUserBanReasonsSucceeded>

/**
 * Fetch user data.
 */
export const fetchUser = (id: number) => ({ type: types.USER_FETCH_BEGIN, id })
export type FetchUserAction = ReturnType<typeof fetchUser>

export const patchUser = (id: number, timezone: string) => ({ type: types.USER_PATCH_BEGIN, options: { id, timezone } })
export type PatchUserAction = ReturnType<typeof patchUser>
