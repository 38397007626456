// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { history } from 'syft-acp-core/history'
import { withTrackingTriggerHoc } from 'syft-acp-util/withTrackingTriggerHoc'

import { Button, ButtonGroup } from 'syft-acp-atoms/Button'
import { showConfirmModal } from 'syft-acp-core/store/modals/actions'
import * as actionTypes from 'syft-acp-core/actions/employer-networks'
import RecordControls from 'syft-acp-core/components/RecordSection/RecordControls'
import LoadingIndicator from 'syft-acp-util/components/LoadingIndicator'
import { trackingEvents } from './EmployerNetworkDetail.tracking'
/**
 * Form with entity detail actions, such as ban user.
 *
 * @param {Object} props Properties
 * @returns {XML} Pure EntityDetailControls component
 */
class EmployerNetworksDetailControls extends Component {
  static propTypes = {
    data: PropTypes.object.isRequired,
    employerID: PropTypes.number.isRequired,
    actions: PropTypes.objectOf(PropTypes.func).isRequired,
    isLoadingData: PropTypes.bool,
    triggerEvent: PropTypes.func.isRequired,
  }

  backAction = () => {
    history.push(`/entity/employers/view/${this.props.employerID}/networks/list`)
  }

  openWorkerAction = () => {
    history.push(`/entity/workers/view/${this.props.data.id}`)
  }

  deleteAction = () => {
    const { actions, employerID, data, triggerEvent } = this.props
    actions.showConfirmModal({
      question: 'Are you sure? This worker will be deleted from the network.',
      onConfirm: () => {
        actions.deleteWorkerFromNetwork(employerID, data.id)
        triggerEvent(trackingEvents.DELETE_NETWORK.CLICKED)
      },
    })
  }

  render() {
    return (
      <RecordControls>
        <ButtonGroup>
          <Button onClick={this.backAction}>Back to networks</Button>
          <Button onClick={this.openWorkerAction}>Open worker profile</Button>
        </ButtonGroup>
        <div className="right">
          <LoadingIndicator loadingState={this.props.isLoadingData} />{' '}
          <Button kind="danger" onClick={this.deleteAction}>
            Delete from network
          </Button>
        </div>
      </RecordControls>
    )
  }
}

export default connect(
  ({ employerNetworks: { isLoadingData } }) => ({
    isLoadingData,
  }),
  dispatch => ({
    actions: bindActionCreators({ ...actionTypes, showConfirmModal }, dispatch),
  }),
)(withTrackingTriggerHoc(EmployerNetworksDetailControls))
