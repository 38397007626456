import React from 'react'

import { CheckIcon } from 'syft-acp-atoms/Icons'

import LabeledCheckbox from 'syft-acp-core/components/LabeledCheckbox'
import { CellPropTypes } from '../../dataProps.types'
import { colPlaceholderFactory } from '../../../../placeholders/factory'

// Column placeholder component.
export const AcpColBoolean = colPlaceholderFactory('AcpCellBoolean')

/**
 * Column type definition for AcpTable components.
 *
 *    <Acp.Col type={ tableType.BOOLEAN } value="is_true_or_false" header="Is true or false" />
 */

type AcpCellBooleanProps = CellPropTypes & {
  value?: boolean
  isEditable?: boolean
  options?: { isEditable?: boolean, 'data-testid'?: string }
}

const AcpCellBooleanComp = ({ value, isEditable, options }: AcpCellBooleanProps) =>
  isEditable || options?.isEditable ? (
    <LabeledCheckbox checked={!!value} editable={false} data-testid={options?.['data-testid']} />
  ) : value ? (
    < CheckIcon fill="green" />
  ) : null

AcpCellBooleanComp.cellProperties = ['pure']
AcpCellBooleanComp.preferredAlignment = 'center'

export default AcpCellBooleanComp
