import { get } from 'lodash-es'

export const enrichCancelBookingParams = (acc, changedEntity) => {
  const { shiftID, jobID, worker } = changedEntity
  const params = {
    job_id: jobID,
    worker_id: get(worker, 'id'),
    shift_ids: [shiftID],
  }

  acc.push(params)
}
