import { apiRequest, handleCall } from 'syft-acp-core/api/call'
import { Query } from './types'
import * as actions from './actions'

export const listCompliancePolicies = ({ options }: Query) =>
  new Promise((resolve, reject) => {
    const req = apiRequest({ path: '/admin/compliance_policies', reqArgs: options })
    handleCall(req, resolve, reject, true, options)
  })

export const updateCompliancePolicy = ({
  payload: { id, employer, role, skill, ...reqArgs },
}: ReturnType<typeof actions.updateCompliancePolicy>) =>
  new Promise((resolve, reject) => {
    const req = apiRequest({
      path: `/admin/compliance_policies/${id}`,
      reqArgs: { employer_id: employer?.id, role_id: role?.id, skill_id: skill?.id, ...reqArgs },
      method: 'PUT',
    })
    handleCall(req, resolve, reject, false, { ...reqArgs })
  })

export const deleteCompliancePolicy = ({
  payload: { id, ...reqArgs },
}: ReturnType<typeof actions.deleteCompliancePolicy>) =>
  new Promise((resolve, reject) => {
    const req = apiRequest({
      path: `/admin/compliance_policies/${id}`,
      reqArgs: { ...reqArgs },
      method: 'DELETE',
    })
    handleCall(req, resolve, reject, false, { ...reqArgs })
  })

export const createCompliancePolicy = ({
  payload: { employer, role, skill, ...reqArgs },
}: ReturnType<typeof actions.createCompliancePolicy>) =>
  new Promise((resolve, reject) => {
    const req = apiRequest({
      path: '/admin/compliance_policies',
      reqArgs: { employer_id: employer?.id, role_id: role?.id, skill_id: skill?.id, ...reqArgs },
      method: 'POST',
    })
    handleCall(req, resolve, reject, false, reqArgs)
  })
