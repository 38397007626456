import React, { useState } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { get } from 'lodash-es'
import { selectTabRole } from 'syft-acp-core/store/data-tabs/actions'
import DataTabs from './../../DataTabs'

import { Props } from './DataRolesTabs.types'

export const DataRolesTabs = ({ value, actions, selectedRoleId, data }: Props) => {
  const [tabId, setTypeId] = useState<number | undefined>(
    selectedRoleId || data?.job?.role?.id || value?.[0]?.id,
  )
  const handleSelect = (areaID: number) => {
    actions.selectTabRole(areaID)
    setTypeId(areaID)
  }
  const convertedValue = value?.map(tab => ({
    id: tab.id,
    name: tab.title,
    workers_count: tab.workers_count,
  }))
  return <DataTabs value={convertedValue} callback={handleSelect} attribute="role" tabId={tabId} />
}

export default connect(
  state => ({
    selectedRoleId: get(state, 'dataTabs.role_id'),
  }),
  dispatch => ({
    actions: bindActionCreators({ selectTabRole }, dispatch),
  }),
)(DataRolesTabs)
