import React from 'react'
import NumericInput from 'react-numeric-input'
import classNames from 'classnames'

import { currentIntl } from 'syft-acp-util/intl'

import { InputProps as Props } from './Input.types'
import S from './Input.module.scss'

const Input = ({ className, value, onChange, disabled = false, currency }: Props) => {
  return (
    <div className={classNames([S['input'], (value?.currency === 'USD' || currency === 'USD') && S['input--usd'], className])}>
      <NumericInput
        className={S['input__field']}
        precision={2}
        step={0.25}
        disabled={disabled}
        value={value?.amount}
        onChange={e => {
          onChange({
            currency: value?.currency || currentIntl.currencyCode,
            amount: e || 0,
          })
        }}
      />
    </div>
  )
}

export default Input
