import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import FilterSelectCities from 'syft-acp-util/components/FilterForm/FilterSelectCities'

const DataCityDropdown = ({ value, editable, actions, showDisabled }) =>
  editable || showDisabled ? (
    <FilterSelectCities
      allowNameLabel
      name="city"
      value={value}
      actions={actions}
      type="id"
      disabled={!editable}
    />
  ) : (
    <div className="plain-text">{value}</div>
  )

DataCityDropdown.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  editable: PropTypes.bool,
  showDisabled: PropTypes.bool,
  actions: PropTypes.object,
}

DataCityDropdown.defaultProps = {
  value: '',
  editable: false,
  showDisabled: false,
  actions: {},
}

export default connect((state, { onChange }) => ({
  actions: {
    setFilter: (name, id, value) => onChange(value),
  },
}))(DataCityDropdown)
