// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import { isEqual, get } from 'lodash-es'
import { useLayoutEffect } from 'react'
import { useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import { keyPrefix } from 'syft-acp-core/constants'

import { store } from 'syft-acp-core/store'
import { forceRefreshAuthToken } from 'syft-acp-core/actions/auth'

// Whether we have attached the auth callback handler yet.
let hasAuthCallback = false

export const setAuthCallback = () => {
  // Only run once.
  if (hasAuthCallback) return
  window.addEventListener('storage', ev => {
    if (ev.key === `${keyPrefix}auth`) {
      const nextData = JSON.parse(ev.newValue)
      const prevData = store.getState().auth
      const nextOauthData = get(nextData, 'oauthData')
      const prevOauthData = get(prevData, 'oauthData')
      if (nextOauthData && !isEqual(nextOauthData, prevOauthData)) {
        store.dispatch(forceRefreshAuthToken({ oauth: nextOauthData, user: nextData.userData }))
      }
    }
  })
  hasAuthCallback = true
}

/**
 * Called before entering a route that requires authentication. This checks the store
 * to see if a user is logged in, and if not, redirects to /login.
 *
 * @param enabled
 */
export const useAuthRequired = enabled => {
  const isLoggedIn = useSelector(state => state.auth.isLoggedIn)
  const history = useHistory()
  const { pathname, search, hash } = useLocation()

  const isRedirecting = !!enabled && !isLoggedIn && pathname !== '/login'

  useLayoutEffect(() => {
    if (isRedirecting) {
      const encodedOriginalUrl = encodeURIComponent([pathname, search, hash].join(''))
      const emptyPath = '%2F' // '/'
      const redirectPath =
        encodedOriginalUrl === emptyPath ? '/login' : `/login?redirectTo=${encodedOriginalUrl}`
      history.replace(redirectPath)
    }
  }, [hash, history, isRedirecting, pathname, search])

  return isRedirecting
}
