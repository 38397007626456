import entityReducer from './generators/entityReducer'
import {
  AGENCY_BOOKING_FETCH_BEGIN,
  AGENCY_BOOKING_FETCH_FAILED,
  AGENCY_BOOKING_FETCH_SUCCEEDED,
  AGENCY_BOOKING_SAVE_BEGIN,
  AGENCY_BOOKING_SAVE_FAILED,
  AGENCY_BOOKING_UPDATE,
  AGENCY_BOOKING_SAVE_SUCCEEDED,
} from '../actions/action-types'
import * as entityTypes from './generators/entities'
import * as states from './states/listingShiftAgencyBookingsStates'

export default entityReducer('listingAgencyShiftBookings', {
  [entityTypes.ENTITY_FETCH_BEGIN]: AGENCY_BOOKING_FETCH_BEGIN,
  [entityTypes.ENTITY_FETCH_SUCCEEDED]: AGENCY_BOOKING_FETCH_SUCCEEDED,
  [entityTypes.ENTITIES_FETCH_SUCCEEDED]: AGENCY_BOOKING_FETCH_FAILED,
  [entityTypes.ENTITY_MODIFY_BEGIN]: AGENCY_BOOKING_SAVE_BEGIN,
  [AGENCY_BOOKING_SAVE_SUCCEEDED]: states.successSaveBookingState,
  [AGENCY_BOOKING_SAVE_FAILED]: states.failedBookingState,
  [AGENCY_BOOKING_UPDATE]: states.bookingUpdateState,
})
