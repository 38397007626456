import { apiRequest, handleCall } from 'syft-acp-core/api/call'

/**
 * Retrieves a URL that can be used to instantly log in to a specific agency.
 *
 * @param id {Number} Employer ID
 * @param callback {Function} Function that will handle opening a window to the returned URL
 */
export const loginAsAgency = ({ id, callback }: { id: number; callback: () => void }) =>
  new Promise((resolve, reject) => {
    const req = apiRequest({
      path: `/admin/users/login_as_agency_account/${id}`,
      reqArgs: {},
      method: 'POST',
    })
    // @ts-expect-error
    handleCall(req, resolve, reject, false, {}, callback)
  })
