import { apiRequest, handleCall } from 'syft-acp-core/api/call'
import * as types from './types'

type Action<P = {}, M = {}> = {
  payload?: P
  meta?: M
}

/**
 * Retrieves a list of employer rate cards. Implements pagination.
 */
export const listEmployerRateCards = ({ options = {}, toCSV }: types.ListEmployerRateCardsInput) =>
  new Promise((resolve, reject) => {
    const req = apiRequest({
      path: `/admin/rate_cards`,
      reqArgs: { ...options, version: 2 },
      returnAll: toCSV,
    })
    handleCall(req, resolve, reject, true, options)
  })

/**
 * Retrieves an employer rate card.
 *
 * @param params {Object}
 * @param params.rateCardID {Object} the rate card ID
 */
export const fetchEmployerRateCard = ({ rateCardID }: types.FetchEmployerRateCardInput) =>
  new Promise((resolve, reject) => {
    const req = apiRequest({ path: `/admin/rate_cards/${rateCardID}` })
    handleCall(req, resolve, reject, true)
  })

/**
 * Saves an employer rate card or creates a new one.
 *
 * @param params {Object}
 * @param params.employerID {Number} Employer ID
 * @param params.id {Number} Rate card ID
 * @param params.data {Object} Request arguments
 */
export const saveEmployerRateCard = ({ id, data, employerID }: types.SaveEmployerRateCardInput) =>
  new Promise((resolve, reject) => {
    let req
    // assign employer_id in case of unassigned from the form side.
    if (!data['employer_id']) data['employer_id'] = employerID
    if (id) {
      req = apiRequest({
        path: `/admin/rate_cards/${id}`,
        reqArgs: data,
        method: 'PATCH',
        allowNulls: true,
      })
    } else {
      req = apiRequest({ path: `/admin/rate_cards`, reqArgs: data, method: 'POST' })
    }
    handleCall(req, resolve, reject, true)
  })

type DisablePayload = Partial<{
  id: number
}>

export const disableEmployerRateCard = ({ payload = {} }: Action<DisablePayload>) =>
  new Promise((resolve, reject) => {
    const req = apiRequest({ path: `/admin/rate_cards/${payload.id}/disable`, method: 'PATCH' })
    handleCall(req, resolve, reject, true)
  })

type EnablePayload = Partial<{
  id: number
}>

export const enableEmployerRateCard = ({ payload = {} }: Action<EnablePayload>) =>
  new Promise((resolve, reject) => {
    const req = apiRequest({ path: `/admin/rate_cards/${payload.id}/enable`, method: 'PATCH' })
    handleCall(req, resolve, reject, true)
  })

export const bulkDisableEmployerRateCard = ({ payload = {} }: Action<Partial<types.BulkPayload>>) =>
  new Promise((resolve, reject) => {
    const req = apiRequest({
      path: `/admin/rate_cards/bulk_disable`,
      method: 'POST',
      reqArgs: {
        rate_card_ids: payload.ids,
      },
    })
    handleCall(req, resolve, reject, true)
  })

export const bulkEnableEmployerRateCard = ({ payload = {} }: Action<Partial<types.BulkPayload>>) =>
  new Promise((resolve, reject) => {
    const req = apiRequest({
      path: `/admin/rate_cards/bulk_enable`,
      method: 'POST',
      reqArgs: {
        rate_card_ids: payload.ids,
      },
    })
    handleCall(req, resolve, reject, true)
  })
