// Syft ACP - Lib <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { get } from 'lodash-es'
import moment from 'moment'

import { useFormatDateTime, useFormatTimeRange } from 'syft-acp-core/lib/hooks'

import './StartEnd.css'

// Formats the duration of e.g. a shift. E.g. 'May 10, 15:00 - 16:00'.
const StartEnd = ({ values, rowFormat }) => {
  const { timeZone } = get(rowFormat, 'options', {})
  const timeRangeOptions = useMemo(
    () => ({
      separator: '-',
      format: {
        timeZone,
        timeZoneName: 'short',
      },
    }),
    [timeZone],
  )
  const [startTime] = values
  const [timeRange] = useFormatTimeRange(values, timeRangeOptions)
  const [start, end] = timeRange.split(timeRangeOptions.separator)
  const thisDay = moment(startTime).isSame(new Date(), 'day')
  const thisYear = moment(startTime).isSame(new Date(), 'year')
  const [date] = useFormatDateTime(startTime, {
    format: {
      year: !thisYear ? 'numeric' : undefined,
      month: 'short',
      day: 'numeric',
      timeZone,
    },
  })

  if (thisDay) {
    // This duration takes place today, so hide the date.
    return (
      <span className={classnames('start-end', { 'no-bold': rowFormat.noBold })}>
        <strong>{start}</strong>-{end}
      </span>
    )
  }

  // By default, display the date and the start/end times.
  // If this occurs at a different year (when looking at the archive or planned events),
  // display the year.
  return (
    <span className={classnames('start-end', { 'no-bold': rowFormat.noBold })}>
      <strong>
        {date} {start}
      </strong>
      -{end}
    </span>
  )
}

StartEnd.propTypes = {
  values: PropTypes.array.isRequired,
  rowFormat: PropTypes.object.isRequired,
}

export default StartEnd
