import React from 'react'
import PropTypes from 'prop-types'
import { history } from 'syft-acp-core/history'
import { connect } from 'react-redux'

import { Button, ButtonGroup } from 'syft-acp-atoms/Button'
import RecordControls from 'syft-acp-core/components/RecordSection/RecordControls'
import LoadingIndicator from 'syft-acp-util/components/LoadingIndicator'

const backAction = () => {
  history.push('/watchlist/conversations')
}

const ConversationDetailControls = ({ isLoadingData }) => (
  <RecordControls>
    <ButtonGroup>
      <Button onClick={backAction}>Back to conversations</Button>
    </ButtonGroup>
    <div className="right">
      <LoadingIndicator loadingState={isLoadingData} />
    </div>
  </RecordControls>
)

ConversationDetailControls.propTypes = {
  isLoadingData: PropTypes.bool,
}

export default connect(({ conversations: { isLoadingData } }) => ({
  isLoadingData,
}))(ConversationDetailControls)
