import entityReducer from './generators/entityReducer'
import * as types from 'syft-acp-core/actions/action-types'
import * as entityTypes from './generators/entities'

export default entityReducer('managers', {
  [entityTypes.ENTITIES_FETCH_BEGIN]: types.ADMIN_MANAGERS_FETCH_BEGIN,
  [entityTypes.ENTITIES_FETCH_SUCCEEDED]: types.ADMIN_MANAGERS_FETCH_SUCCEEDED,
  [entityTypes.ENTITIES_FETCH_FAILED]: types.ADMIN_MANAGERS_FETCH_FAILED,

  [entityTypes.ENTITY_FETCH_BEGIN]: types.ADMIN_MANAGER_FETCH_BEGIN,
  [entityTypes.ENTITY_FETCH_SUCCEEDED]: types.ADMIN_MANAGER_FETCH_SUCCEEDED,
  [entityTypes.ENTITY_FETCH_FAILED]: types.ADMIN_MANAGER_FETCH_FAILED,

  [entityTypes.ENTITY_SAVE_BEGIN]: types.ADMIN_MANAGER_CREATE_BEGIN,
  [entityTypes.ENTITY_SAVE_SUCCEEDED]: types.ADMIN_MANAGER_CREATE_SUCCEEDED,
  [entityTypes.ENTITY_SAVE_FAILED]: types.ADMIN_MANAGER_CREATE_FAILED,

  [entityTypes.ENTITY_MODIFY_BEGIN]: types.ADMIN_MANAGER_UPDATE_BEGIN,
  [entityTypes.ENTITY_MODIFY_SUCCEEDED]: types.ADMIN_MANAGER_UPDATE_SUCCEEDED,
  [entityTypes.ENTITY_MODIFY_FAILED]: types.ADMIN_MANAGER_UPDATE_FAILED,

  [entityTypes.ENTITY_DELETE_BEGIN]: types.ADMIN_MANAGER_DELETE_BEGIN,
  [entityTypes.ENTITY_DELETE_SUCCEEDED]: types.ADMIN_MANAGER_DELETE_SUCCEEDED,
  [entityTypes.ENTITY_DELETE_FAILED]: types.ADMIN_MANAGER_DELETE_FAILED,
})
