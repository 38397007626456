// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import { apiRequest, handleCall } from 'syft-acp-core/api/call'

/**
 * Retrieves a list of employer accounts.
 *
 * @param params {Object}
 * @param params.employerID {Number} the employer ID
 */
export const listEmployerAccounts = ({ options = {} }) =>
  new Promise((resolve, reject) => {
    const { employerID, ...reqArgs } = options
    const req = apiRequest({
      path: `/employers/${employerID}/employer_accounts`,
      reqArgs,
    })
    handleCall(req, resolve, reject, true, options)
  })

/**
 * Invites a new user to become an employer account.
 *
 * @param params {Object}
 * @param params.employerID {Number} the employer ID
 * @param params.data {Number} the account data
 */
export const inviteEmployerAccount = ({ employerID, data }) =>
  new Promise((resolve, reject) => {
    const req = apiRequest({
      path: `/employers/${employerID}/employer_accounts`,
      reqArgs: data,
      method: 'POST',
    })
    handleCall(req, resolve, reject, true)
  })

/**
 * Retrieves a specific employer account.
 *
 * @param params {Object}
 * @param params.employerID {Number} the employer ID
 * @param params.accountID {Number} the account ID
 */
export const fetchEmployerAccount = ({ employerID, accountID }) =>
  new Promise((resolve, reject) => {
    const req = apiRequest({ path: `/employers/${employerID}/employer_accounts/${accountID}` })
    handleCall(req, resolve, reject, true)
  })

/**
 * Saves an employer account.
 *
 * @param params {Object}
 * @param params.employerID {Number} the employer ID
 * @param params.accountID {Number} the account ID
 * @param params.data {Number} the account data
 */
export const saveEmployerAccount = ({ employerID, accountID, data }) =>
  new Promise((resolve, reject) => {
    const req = apiRequest({
      path: `/employers/${employerID}/employer_accounts/${accountID}`,
      reqArgs: data,
      method: 'PATCH',
    })
    handleCall(req, resolve, reject, true)
  })

/**
 * Removes/destroys all permissions for an employer account.
 *
 * @param params {Object}
 * @param params.employerID {Number} the employer ID
 * @param params.accountID {Number} the account ID
 * @param params.data {Number} the account data
 */
export const destroyEmployerAccountPermissions = ({ employerID, accountID, data }) =>
  new Promise((resolve, reject) => {
    // Set _destroy to true. See <https://syftapp.atlassian.net/wiki/spaces/SV/pages/56098817/Employer+Accounts+Api>.
    const reqArgs = { ...data, permissions: data.permissions.map(n => ({ ...n, _destroy: true })) }
    const req = apiRequest({
      path: `/employers/${employerID}/employer_accounts/${accountID}`,
      reqArgs,
      method: 'PATCH',
    })
    handleCall(req, resolve, reject, true)
  })

/**
 * Disables an employer account, disallowing any employer API actions.
 *
 * @param params {Object}
 * @param params.employerID {Number} the employer ID
 * @param params.accountID {Number} the account ID
 */
export const disableEmployerAccount = ({ employerID, accountID }) =>
  new Promise((resolve, reject) => {
    const req = apiRequest({
      path: `/employers/${employerID}/employer_accounts/${accountID}`,
      reqArgs: null,
      method: 'DELETE',
    })
    handleCall(req, resolve, reject, true)
  })

/**
 * Retrieves an access token for the user associated with an employer account.
 *
 * @param params {Object}
 * @param params.employerAccountID {Number} the employer account ID
 */
export const loginAsEmployerAccount = ({ employerAccountID }) =>
  new Promise((resolve, reject) => {
    const req = apiRequest({
      path: `/admin/users/login_as_employer_account/${employerAccountID}`,
      reqArgs: null,
      method: 'POST',
    })
    handleCall(req, resolve, reject, false)
  })
