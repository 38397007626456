import { createSelector } from 'reselect'
import { Store } from 'syft-acp-core/store'
import { GenericState } from 'syft-acp-core/reducers/generators/entityReducer'
import { entitySetName } from 'syft-acp-core/reducers/generators/utils'

//import { selectEmployerRateCard } from '../employerRateCards/selectors'
import { EmployerShiftRateTypeEntity } from './types'

const selectEmployerShiftRateTypes = (state: Store) => state.employerShiftRateTypes
const selectEmployerId = (state: Store, employerId: number) => employerId
const selectEmployerRateCardShiftType = (state: Store, employerId: number, rateCardId: number) =>
  state.newEmployerRateCards.entityMap[rateCardId]?.shift_rate_type_id

export const selectEmployerShiftRateTypesOptions = createSelector(
  [selectEmployerShiftRateTypes, selectEmployerId],
  (employerShiftRateTypes: GenericState<EmployerShiftRateTypeEntity>, employerId: number) => {
    const setKey = entitySetName({ employerId })
    const entitySet = employerShiftRateTypes?.entitySets[setKey]
    return entitySet
      ? entitySet.ids
          .filter(id => !entitySet.entityMap[id].disabled)
          .map(id => ({
            id: entitySet.entityMap[id].id,
            label: entitySet.entityMap[id].name,
          }))
      : []
  },
)

export const selectEmployerShiftRateTypesOptionsWithDisabled = createSelector(
  [selectEmployerShiftRateTypes, selectEmployerId, selectEmployerRateCardShiftType],
  (
    employerShiftRateTypes: GenericState<EmployerShiftRateTypeEntity>,
    employerId: number,
    rateCardShiftType: number,
  ) => {
    const setKey = entitySetName({ employerId })
    const entitySet = employerShiftRateTypes?.entitySets[setKey]
    return entitySet
      ? entitySet.ids
          .filter(id => !entitySet.entityMap[id].disabled || rateCardShiftType === id)
          .map(id => ({
            id: entitySet.entityMap[id].id,
            label: `${entitySet.entityMap[id].name} ${entitySet.entityMap[id].disabled ? '(Disabled)' : ''}`,
          }))
      : []
  },
)
