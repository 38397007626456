import React from 'react'
import PropTypes from 'prop-types'
import UserAvatar from 'syft-acp-core/components/UserAvatar'
import ConnectedUserAvatar from 'syft-acp-core/components/UserAvatar/ConnectedUserAvatar'

import { cellPropTypes, cellDefaultProps } from '../dataProps'
import { colPlaceholderFactory } from '../../../placeholders/factory'

export const AcpColImage = colPlaceholderFactory('AcpCellImage')

/**
 * Generic column for displaying text values.
 *
 *    <Acp.Col.Image value="uaui-asdas-12ada1-asd123-asd" header="<octo.Avatar />" />
 */
export default class AcpCellImage extends React.PureComponent {
  static propTypes = {
    ...cellPropTypes,
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.exact({ uuid: PropTypes.string.isRequired }),
    ]),
    options: PropTypes.shape({
      connectedTo: PropTypes.string,
    }),
  }

  static defaultProps = {
    ...cellDefaultProps,
  }

  render() {
    const { value, options } = this.props
    const uuid = value && value.uuid ? value.uuid : value
    return options.connectedTo ? (
      <ConnectedUserAvatar id={value} connectedTo={options.connectedTo} size="small" shape="rounded-square" />
    ) : (
      <UserAvatar avatar={uuid} size="small" shape="rounded-square" />
    )
  }
}
