import PropTypes from 'prop-types'
import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import entityDetailHOC from 'syft-acp-util/entityDetail'

import * as externalEmployersActions from 'syft-acp-core/store/externalEmployers/actions'
import TransferRecordSection from 'syft-acp-core/components/TransferRecordSection'
import { dataPropTypes } from 'syft-acp-core/entities/EmployerDetail/structure'

const ExternalEmployersRecordSection = entityDetailHOC('externalEmployers', TransferRecordSection)

export class EmployerDetailTransferRecordUI extends PureComponent {
  static propTypes = {
    id: PropTypes.number.isRequired,
    employer: dataPropTypes,
    data: PropTypes.shape({
      id: PropTypes.number,
      employer_id: PropTypes.number,
      external_platform_id: PropTypes.number,
      external_platform_reference: PropTypes.string,
    }),
    actions: PropTypes.shape({
      fetchExternalEmployer: PropTypes.func.isRequired,
      createExternalEmployer: PropTypes.func.isRequired,
      deleteExternalEmployer: PropTypes.func.isRequired,
      updateExternalEmployer: PropTypes.func.isRequired,
    }).isRequired,
  }

  static defaultProps = {
    employer: {},
    data: {},
  }

  componentDidMount() {
    const { id, actions } = this.props

    if (id) {
      actions.fetchExternalEmployer({ id })
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.id !== this.props.id && this.props.id) {
      this.props.actions.fetchExternalEmployer({ id: this.props.id })
    }
  }

  createTransferRecord = data => {
    this.props.actions.createExternalEmployer({ employer_id: this.props.employer.id, ...data })
  }

  updateTransferRecord = data => {
    return this.props.actions.updateExternalEmployer({ employer_id: this.props.employer.id, ...data })
  }

  deleteTransferRecord = data => {
    this.props.actions.deleteExternalEmployer({ id: data.id, employer_id: data.employer_id })
  }

  render() {
    const { createTransferRecord, updateTransferRecord, deleteTransferRecord } = this
    const { id } = this.props
    return (
      <ExternalEmployersRecordSection
        actions={{
          createTransferRecord,
          updateTransferRecord,
          deleteTransferRecord,
        }}
        id={id}
        showBranchField
      />
    )
  }
}

const EmployerDetailTransferRecord = connect(
  (state, props) => ({
    id: props.employer?.external_employer?.id,
  }),
  dispatch => ({
    actions: bindActionCreators(externalEmployersActions, dispatch),
  })
)(EmployerDetailTransferRecordUI)

export default EmployerDetailTransferRecord
