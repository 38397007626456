import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { connect } from 'react-redux'
import { get } from 'lodash-es'

import { cellPropTypes, cellDefaultProps } from '../dataProps'
import { emptyTextFallback } from './fallback'
import { colPlaceholderFactory } from '../../../placeholders/factory'

import './AcpCellIndustries.css'

// Column placeholder component.
export const AcpColIndustries = colPlaceholderFactory('AcpCellIndustries')

/**
 * Column type that displays the industries a worker is active in.
 *
 *    <Acp.Col.Industries value="industries" header="Industries" />
 *
 * Connected to the store to obtain industry data.
 */

class AcpCellIndustries extends React.PureComponent {
  static propTypes = {
    ...cellPropTypes,
    value: PropTypes.arrayOf(PropTypes.number),
  }

  static defaultProps = {
    ...cellDefaultProps,
  }

  renderValue(ids, industries = [], tableID = '') {
    if (ids.length === 0) {
      return emptyTextFallback
    }
    return [...ids].sort().map(id => {
      const industryName = get(industries, `${id}.title`, '–')
      const industryCode = get(industries, `${id}.code`, 'unknown')
      const cls = classNames('industry', `type-${industryCode}`)
      return (
        <span className={cls} key={id} data-tip={industryName} data-for={tableID}>
          {industryName[0]}
        </span>
      )
    })
  }

  render() {
    const { value, industries, tableID } = this.props

    const valueNode = this.renderValue(value, industries, tableID)

    return (
      <div className={classNames('acp-cell-industries')}>
        <div>{valueNode}</div>
      </div>
    )
  }
}

export default connect(state => ({ industries: state.industries.entityMap }))(AcpCellIndustries)
