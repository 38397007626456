// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import React from 'react'
import { TrackingTrigger } from '@indeed/flex-tracking-context'
import WorkerList from 'syft-acp-core/entities/WorkerList'
import { trackingEvents } from 'syft-acp-core/entities/WorkerList/WorkerList.tracking'
import PageView from 'syft-acp-core/components/PageView'

import { WorkersSubNavigation } from '../SubNavigation'

export const EntityWorkersList = () => (
  <>
    <TrackingTrigger onLoad={{ event: trackingEvents.PAGE.VIEWED }} />
    <WorkersSubNavigation />
    <PageView title={['Workers', 'Overview']} entityPage>
      <WorkerList />
    </PageView>
  </>
)
