import React, { useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import { get, uniqueId } from 'lodash-es'
import { agencyPlatformsAutocomplete } from 'syft-acp-core/actions/agencies-list'
import { AsyncTypeahead } from 'react-bootstrap-typeahead'

const defaultAutoCompleteLabel = uniqueId('autocomplete-label-')

const PlatformsAutocomplete = ({ id = defaultAutoCompleteLabel, onChange }) => {
  const [options, setOptions] = useState([])
  const [selected, setSelected] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  const runSearch = useCallback(
    async query => {
      if (!query) {
        return
      }
      setIsLoading(true)
      const results = await agencyPlatformsAutocomplete(query)
      const convertedOptions = results.map(item => ({ id: item.id, label: item.name }))
      setOptions(convertedOptions)
      setIsLoading(false)
    },
    [setOptions, setIsLoading],
  )

  const saveInput = value => {
    setSelected(value[0])
    onChange([].concat(get(value, '[0].id', [])))
  }
  return (
    <AsyncTypeahead
      labelKey="label"
      defaultSelected={[]}
      selected={selected ? [selected] : []}
      emptyLabel="No matches."
      isLoading={isLoading}
      maxResults={7}
      delay={500}
      id="label"
      options={options}
      selectHintOnEnter
      onSearch={runSearch}
      onChange={saveInput}
      minLength={0}
      inputProps={{ id }}
    />
  )
}

PlatformsAutocomplete.propTypes = {
  id: PropTypes.string,
  onChange: PropTypes.func.isRequired,
}

export default PlatformsAutocomplete
