// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import React from 'react'
import PropTypes from 'prop-types'

import PageTitle from 'syft-acp-core/components/PageTitle'
import SubNavigation from 'syft-acp-core/components/Navigation/SubNavigation'

const Marketing = ({ children }) => (
  <PageTitle title="Marketing">
    <div>
      <SubNavigation subPage="bizdev" />
      {children}
    </div>
  </PageTitle>
)

Marketing.propTypes = {
  children: PropTypes.node,
}

Marketing.defaultProps = {
  children: null,
}

export default Marketing
