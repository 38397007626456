// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import React from 'react'
import PropTypes from 'prop-types'
import TextareaAutosize from 'react-autosize-textarea'
import { FormControl } from 'react-bootstrap'

import './DataTextarea.css'

class DataTextarea extends React.PureComponent {
  render() {
    const { onChange, value, header, rows, monospace, disabled, infinite, editable } = this.props
    return editable ? (
      <FormControl
        componentClass={TextareaAutosize}
        rows={rows}
        disabled={disabled}
        onChange={ev => onChange(ev.target.value)}
        value={value}
        placeholder={header}
        className={`boxed-input ${monospace ? 'monospace-text' : ''} ${infinite ? 'resize-infinite' : ''}`}
      />
    ) : (
      <div className="plain-text">{value}</div>
    )
  }
}

DataTextarea.propTypes = {
  value: PropTypes.string,
  header: PropTypes.string,
  disabled: PropTypes.bool,
  rows: PropTypes.number,
  monospace: PropTypes.bool,
  infinite: PropTypes.bool,
  onChange: PropTypes.func,
  editable: PropTypes.bool,
}

DataTextarea.defaultProps = {
  value: '',
  header: null,
  rows: 2,
  monospace: false,
  infinite: false,
  editable: true,
  onChange: () => {},
}

export default DataTextarea
