import React, { useEffect } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { entityList$ } from 'syft-acp-core/reducers/generators/entities'
import { fetchTemplatesList } from 'syft-acp-core/actions/employer-templates'
import DataDropdown from 'syft-acp-core/components/EditableTable/DataDropdown'

import { AllProps } from './index.types'

export const connector = connect(
  (state: any, { employerID }: { employerID?: string }) => ({
    templates: entityList$(state.employerTemplates, { employerID }),
  }),
  dispatch => ({
    actions: bindActionCreators({ fetchTemplates: fetchTemplatesList }, dispatch),
  })
)

const TemplatesDropdown = ({
  templates,
  templateId,
  updateVenue,
  employerID,
  disabled,
  actions: { fetchTemplates },
}: AllProps) => {
  useEffect(() => {
    if (employerID) {
      fetchTemplates(employerID)
    }
  }, [employerID, fetchTemplates])
  const options = templates ? templates.map(({ id, template_name }) => ({ id, label: template_name })) : []
  return (
    <DataDropdown
      name="templates"
      options={options}
      value={templateId}
      editable={!disabled}
      disabled={!options.length}
      onChange={updateVenue}
    />
  )
}

TemplatesDropdown.defaultProps = {
  templateId: null,
  disabled: false,
  templates: [],
}

export default connector(TemplatesDropdown)
