import { get, omit, without } from 'lodash-es'
import { emptySet } from 'syft-acp-core/reducers/generators/constants'
import { entitySetName } from 'syft-acp-core/reducers/generators/utils'

export const entityDeleteSucceed = (state, action, options) => {
  const setKey = entitySetName(action.options, options, action)
  const currentSet = get(state, `entitySets[${setKey}]`, {})
  const removeID = get(action, 'payload.id', get(action, 'request.payload.id', 0))

  if (removeID) {
    // If an ID to remove has been included in the payload,
    // remove that ID from both the global entityMap and entityDetail stores
    // and also for the current set.
    const oldGlobalMap = get(state, 'entityMap', {})
    const oldGlobalDetail = get(state, 'entityDetail', {})
    const oldSetMap = get(currentSet, 'entityMap', {})
    const oldSetIDs = get(currentSet, 'ids', {})

    const newGlobalMap = omit(oldGlobalMap, removeID)
    const newGlobalDetail = omit(oldGlobalDetail, removeID)
    const newSetMap = omit(oldSetMap, removeID)
    const newSetIDs = without(oldSetIDs, removeID)

    return {
      ...state,
      entitySets: {
        ...state.entitySets,
        [setKey]: {
          ...emptySet,
          ...currentSet,
          ids: newSetIDs,
          entityMap: newSetMap,
        },
      },
      entityMap: newGlobalMap,
      entityDetail: newGlobalDetail,
      isSavingData: false,
      lastMessage: '',
      lastStatus: null,
      lastBody: null,
      isLoadingData: false,
    }
  } else {
    return {
      ...state,
      isSavingData: false,
      lastMessage: '',
      lastStatus: null,
      lastBody: null,
      isLoadingData: false,
    }
  }
}
