import { takeEvery, put, select } from 'redux-saga/effects'

import { notificationCall } from 'syft-acp-core/sagas/calls'
import { takeLatestApiCall } from 'syft-acp-core/sagas/helpers'
import { showModal, hideModal } from 'syft-acp-core/store/modals/actions'

import * as types from './action-types'
import { clearBulkUpload } from './actions'
import { saveBulkUpload } from './api'

export function* handleSaveSuccess() {
  // @ts-ignore
  const isConfirmation = yield select(state => state?.agencyBulkUpload?.isConfirmation)

  if (isConfirmation) {
    yield put(hideModal('agencyCoverageImportModal'))
    yield put(clearBulkUpload())
    window.location.reload()
  } else {
    yield put(showModal('agencyCoverageImportModal'))
  }
}
export default function* saga() {
  yield takeLatestApiCall(types.AGENCY_COVERAGE_BULK_UPLOAD_SAVE_BEGIN, saveBulkUpload)
  yield takeEvery(types.AGENCY_COVERAGE_BULK_UPLOAD_SAVE_SUCCEEDED, handleSaveSuccess)
  yield takeEvery(
    [types.AGENCY_COVERAGE_BULK_UPLOAD_SAVE_FAILED, types.AGENCY_COVERAGE_BULK_UPLOAD_SAVE_SUCCEEDED],
    notificationCall
  )
}
