import US_STATES_MAP from 'syft-acp-util/fixtures/us_states.json'

/**
 * Returns the string name of a state. If a state is not found,
 * it will return 'unknown state' with the code.
 */
export const getFederatedState = code => {
  if (!code) {
    return '–'
  }
  return US_STATES_MAP[code] ? US_STATES_MAP[code] : `${code} (unknown state)`
}
