import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import FilterReasons from 'syft-acp-util/components/FilterForm/FilterReasons'

const DataStrikesDropdown = ({ value, editable, actions }) =>
  editable ? (
    <FilterReasons type="strikes" idKey="code" value={value} actions={actions} disabled={!editable} />
  ) : (
    <div className="plain-text">{value}</div>
  )

DataStrikesDropdown.propTypes = {
  value: PropTypes.string,
  editable: PropTypes.bool,
  actions: PropTypes.object,
}

DataStrikesDropdown.defaultProps = {
  value: null,
  editable: false,
  actions: {},
}

export default connect((_state, { onChange }) => ({
  actions: {
    setFilter: (_name, value) => onChange(value),
  },
}))(DataStrikesDropdown)
