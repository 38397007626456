// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import entityReducer from './generators/entityReducer'
import * as types from '../actions/action-types'
import * as entityTypes from './generators/entities'

export default entityReducer('overpayments', {
  [entityTypes.ENTITIES_FETCH_BEGIN]: types.OVERPAYMENTS_FETCH_BEGIN,
  [entityTypes.ENTITIES_FETCH_SUCCEEDED]: types.OVERPAYMENTS_FETCH_SUCCEEDED,
  [entityTypes.ENTITIES_FETCH_FAILED]: types.OVERPAYMENTS_FETCH_FAILED,

  [entityTypes.ENTITY_MODIFY_BEGIN]: types.OVERPAYMENT_SAVE_BEGIN,
  [entityTypes.ENTITY_MODIFY_SUCCEEDED]: types.OVERPAYMENT_SAVE_SUCCEEDED,
  [entityTypes.ENTITY_MODIFY_FAILED]: types.OVERPAYMENT_SAVE_FAILED,
})
