import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import FilterAutocompleteManagers from 'syft-acp-util/components/FilterForm/FilterAutocompleteManagers'

const DataManagerDropdown = ({ value, editable, actions, allowAny, name, parameters = {} }) => {
  return editable ? (
    <FilterAutocompleteManagers
      name={name}
      value={value}
      allowAny={allowAny}
      actions={actions}
      type="id"
      allowNameLabel
      disabled={!editable}
      parameters={parameters}
      isDataManager
    />
  ) : (
    <div className="plain-text">{value}</div>
  )
}

DataManagerDropdown.propTypes = {
  value: PropTypes.number,
  editable: PropTypes.bool,
  allowAny: PropTypes.bool,
  actions: PropTypes.object.isRequired,
  name: PropTypes.string,
  parameters: PropTypes.object,
}

DataManagerDropdown.defaultProps = {
  value: null,
  editable: false,
  allowAny: false,
  name: '',
  parameters: {},
}

export default connect((state, { onChange }) => ({
  actions: {
    setFilter: (name, id, value) => onChange(value, id, name),
  },
}))(DataManagerDropdown)
