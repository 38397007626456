// Note: copied verbatim from <https://github.com/catcher-in-the-try/objects-to-csv/blob/master/index.js>.
// I had to copy it over here because the module is not published as ES5 code.
// Therefore, our build fails to minify it. See <http://bit.ly/2tRViJ9> for more information.
// TODO: replace with a properly ES5 transpiled version on npm.
// Public domain.

import CsvAsync from './asyncCSV'

function handleEqualScriptInjections(value) {
  if (typeof value === 'string' && value.startsWith('=')) {
    return `'${value}`
  }
  return value
}

/**
 * Private method to run the actual conversion of array of objects to CSV data.
 * @param {object[]} data
 */
async function convert(data) {
  if (data.length === 0) {
    return ''
  }

  // This will hold data in the format that `async-csv` can accept, i.e.
  // an array of arrays.
  const csvInput = []

  // Figure out the columns from the first item in the array:
  const columnNames = Object.keys(data[0])

  // Add header row
  csvInput.push(columnNames)

  // Add all the other rows:
  for (const row of data) {
    const item = columnNames.map(column => handleEqualScriptInjections(row[column]))
    csvInput.push(item)
  }

  return await CsvAsync.stringify(csvInput)
}

/**
 * Converts an array of objects into a CSV file.
 */
class ObjectsToCsv {
  /**
   * Creates a new instance of the object array to csv converter.
   * @param {object[]} objectArray
   */
  constructor(objectArray) {
    if (!Array.isArray(objectArray)) {
      throw new Error('The input to objects-to-csv must be an array of objects.')
    }

    if (objectArray.length > 0) {
      if (typeof objectArray[0] !== 'object') {
        throw new Error('The array must contain objects, not other data types.')
      }
    }

    this.data = objectArray
  }

  /**
   * Returns the CSV file as string.
   */
  async toString() {
    return await convert(this.data)
  }
}

export default ObjectsToCsv
