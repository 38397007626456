// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import { entityReducer } from 'syft-acp-core/reducers/generators/entityReducer'
import * as entityTypes from 'syft-acp-core/reducers/generators/entities'

import * as types from '../action-types'
import * as actionTypes from 'syft-acp-core/actions/action-types'
import { EmployerRateCardEntity, TimeDependentRate, BulkPayload } from './types'

type Action<Response = {}, Request = {}> = {
  type: string
  payload: Response
  request: Request
}

type State = {
  entityMap: Record<number, any>
  entityDetail: Record<number, any>
}

const updateTimeDependencyRates = (payload: TimeDependentRate, rates: TimeDependentRate[] = []) => {
  return !rates.length ? [payload] : rates.map((rate: TimeDependentRate) => (rate.id === payload.id ? payload : rate))
}

type TdrRequest = {
  data: TimeDependentRate
}

export const updateTdrForRateCard = (state: State, action: Action<TimeDependentRate, TdrRequest>) => {
  const { payload, request } = action
  const requestRateCardId = request.data.rate_card_id
  const responseRateCardId = payload.rate_card_id

  switch (action.type) {
    case actionTypes.TIME_DEPENDENT_RATES_UPDATE_SUCCEEDED: {
      return {
        ...state,
        entityMap: {
          ...state.entityMap,
          [responseRateCardId]: {
            ...state.entityMap[requestRateCardId],
            id: responseRateCardId,
            time_dependent_rates: updateTimeDependencyRates(
              payload,
              state.entityMap[requestRateCardId].time_dependent_rates
            ),
          },
        },
      }
    }
    case actionTypes.TIME_DEPENDENT_RATES_SAVE_SUCCEEDED:
      return {
        ...state,
        entityMap: {
          ...state.entityMap,
          [responseRateCardId]: {
            ...state.entityMap[requestRateCardId],
            time_dependent_rates: [...(state.entityMap[requestRateCardId].time_dependent_rates ?? []), payload],
          },
        },
      }
    default:
      return state
  }
}

type BulkRequest = {
  payload: BulkPayload
}

const setDisabledDate = (rateCardIds: number[], state: Record<number, any>, date: string | null = null) =>
  rateCardIds.reduce<Record<number, EmployerRateCardEntity>>(
    (acc, curr) => ({
      ...acc,
      [curr]: {
        ...state[curr],
        disabled_at: date,
      },
    }),
    {}
  )

export const bulkDisableEnableReducer = (state: State, action: Action<{}, BulkRequest>) => {
  const { request } = action
  const rateCardIds = request.payload.ids

  switch (action.type) {
    case types.NEW_EMPLOYER_RATE_CARD_BULK_DISABLE_SUCCEEDED:
    case types.NEW_EMPLOYER_RATE_CARD_BULK_ENABLE_SUCCEEDED: {
      const disabledDate =
        action.type === types.NEW_EMPLOYER_RATE_CARD_BULK_DISABLE_SUCCEEDED ? new Date().toString() : null
      const newEntityMap = setDisabledDate(rateCardIds, state.entityMap, disabledDate)
      const newEntityDetail = setDisabledDate(rateCardIds, state.entityDetail, disabledDate)

      return {
        ...state,
        entityMap: {
          ...state.entityMap,
          ...newEntityMap,
        },
        entityDetail: {
          ...state.entityDetail,
          ...newEntityDetail,
        },
        isSavingData: false,
        isLoadingData: false,
        lastMessage: '',
        lastStatus: null,
        lastBody: null,
      }
    }
    default:
      return state
  }
}

export default entityReducer<EmployerRateCardEntity>(
  'newEmployerRateCards',
  {
    [entityTypes.ENTITIES_FETCH_BEGIN]: types.NEW_EMPLOYER_RATE_CARDS_FETCH_BEGIN,
    [entityTypes.ENTITIES_FETCH_SUCCEEDED]: types.NEW_EMPLOYER_RATE_CARDS_FETCH_SUCCEEDED,
    [entityTypes.ENTITIES_FETCH_FAILED]: types.NEW_EMPLOYER_RATE_CARDS_FETCH_FAILED,
    [entityTypes.ENTITY_FETCH_BEGIN]: types.NEW_EMPLOYER_RATE_CARD_FETCH_BEGIN,
    [entityTypes.ENTITY_FETCH_SUCCEEDED]: types.NEW_EMPLOYER_RATE_CARD_FETCH_SUCCEEDED,
    [entityTypes.ENTITY_FETCH_FAILED]: types.NEW_EMPLOYER_RATE_CARD_FETCH_FAILED,
    [entityTypes.ENTITY_SAVE_BEGIN]: types.NEW_EMPLOYER_RATE_CARD_SAVE_BEGIN,
    [entityTypes.ENTITY_SAVE_SUCCEEDED]: types.NEW_EMPLOYER_RATE_CARD_SAVE_SUCCEEDED,
    [entityTypes.ENTITY_SAVE_FAILED]: types.NEW_EMPLOYER_RATE_CARD_SAVE_FAILED,
    [entityTypes.ENTITY_MODIFY_BEGIN]: [
      types.NEW_EMPLOYER_RATE_CARD_SAVE_BEGIN,
      types.NEW_EMPLOYER_RATE_CARD_DISABLE_BEGIN,
      types.NEW_EMPLOYER_RATE_CARD_ENABLE_BEGIN,
      types.NEW_EMPLOYER_RATE_CARD_BULK_DISABLE_BEGIN,
      types.NEW_EMPLOYER_RATE_CARD_BULK_ENABLE_BEGIN,
    ],
    [entityTypes.ENTITY_MODIFY_SUCCEEDED]: [
      types.NEW_EMPLOYER_RATE_CARD_SAVE_SUCCEEDED,
      types.NEW_EMPLOYER_RATE_CARD_DISABLE_SUCCEEDED,
      types.NEW_EMPLOYER_RATE_CARD_ENABLE_SUCCEEDED,
    ],
    [entityTypes.ENTITY_MODIFY_FAILED]: [
      types.NEW_EMPLOYER_RATE_CARD_SAVE_FAILED,
      types.NEW_EMPLOYER_RATE_CARD_DISABLE_FAILED,
      types.NEW_EMPLOYER_RATE_CARD_ENABLE_FAILED,
      types.NEW_EMPLOYER_RATE_CARD_BULK_DISABLE_FAILED,
      types.NEW_EMPLOYER_RATE_CARD_BULK_ENABLE_FAILED,
    ],
    [types.NEW_EMPLOYER_RATE_CARD_BULK_DISABLE_SUCCEEDED]: bulkDisableEnableReducer,
    [types.NEW_EMPLOYER_RATE_CARD_BULK_ENABLE_SUCCEEDED]: bulkDisableEnableReducer,
    [actionTypes.TIME_DEPENDENT_RATES_UPDATE_SUCCEEDED]: updateTdrForRateCard,
    [actionTypes.TIME_DEPENDENT_RATES_SAVE_SUCCEEDED]: updateTdrForRateCard,
  },
  {
    idKey: 'id',
    localEntityMap: true,
  }
)
