import React from 'react'
import { connect } from 'react-redux'
import { datetimeToDate } from 'syft-acp-util/time'
import { enGB } from 'date-fns/locale'

import { Modal, ModalBody, ModalFooter } from '@indeed/ipl-modal'
import { Box, Button, Text } from '@indeed/ifl-components'
import { DatePicker, DateRangePicker } from '@indeed/ipl-date-picker'

import { useFlexFlagIsOn } from '@indeed/flex-feature-flags'

import { getModal$ } from 'syft-acp-core/store/modals/selectors'
import { withTrackingTriggerHoc } from 'syft-acp-util/withTrackingTriggerHoc'

import { hideModal } from 'syft-acp-core/store/modals/actions'
import { downloadAsFileFromApi } from 'syft-acp-core/api/file'

import { RetrospectiveAgencyHoursModalProps as Props } from './RetrospectiveAgencyHoursModal.types'
import { trackingEvents } from './RetrospectiveAgencyHoursModal.tracking'
import { DateRange } from 'react-day-picker'

const fetchRetrospectiveAgencyHours = (endDate: Date) => {
  return downloadAsFileFromApi('/admin/retrospective_agency_shift_workers.csv', {
    invoicing_end_date: datetimeToDate(endDate),
  })
}
const fetchRetrospectiveAgencyHoursByDateRange = (dateRange: DateRange) => {
  return downloadAsFileFromApi('/admin/retrospective_agency_shift_workers.csv', {
    start_date: dateRange.from?.toISOString(),
    end_date: dateRange.to?.toISOString(),
  })
}
export const RetrospectiveAgencyHoursModal = ({ dispatch, item, triggerEvent }: Props) => {
  const closeModal = () => dispatch(hideModal(item.modalName))

  const isDateRangeEnabled = useFlexFlagIsOn('retrospective_agency_hours_date_range')

  const handleOnExit = () => {
    triggerEvent(trackingEvents.CLOSE_BUTTON.CLICKED)
    closeModal()
  }

  const [activeDay, setActiveDay] = React.useState(new Date())
  const [dateRange, setDateRange] = React.useState<DateRange>({ from: new Date() })

  const handleOnChange = (day: Date | undefined) => {
    if (day) {
      triggerEvent(trackingEvents.DATE.CHANGED, { day: datetimeToDate(day) })
      setActiveDay(day)
    }
  }
  const handleOnDateRangeChange = (range: DateRange | undefined) => {
    if (range) {
      triggerEvent(trackingEvents.DATE_RANGE.CHANGED, {
        from: datetimeToDate(range.from),
        to: datetimeToDate(range.to),
      })
      setDateRange(range)
    }
  }
  const handleCancel = () => {
    triggerEvent(trackingEvents.CANCEL_BUTTON.CLICKED)
    closeModal()
  }

  const handleDownload = () => {
    fetchRetrospectiveAgencyHours(activeDay)
    triggerEvent(trackingEvents.DOWNLOAD_BUTTON.CLICKED, { day: datetimeToDate(activeDay) })
    closeModal()
  }

  const handleDateRangeDownload = () => {
    if (dateRange.from && dateRange.to) {
      fetchRetrospectiveAgencyHoursByDateRange(dateRange)
      triggerEvent(trackingEvents.DOWNLOAD_BUTTON.CLICKED, {
        from: datetimeToDate(dateRange.from),
        to: datetimeToDate(dateRange.to),
      })
    }
    closeModal()
  }

  return (
    <Modal
      focusTrapObserver
      focusTrapOptions={{ allowOutsideClick: true }}
      isOpen={item.isShown}
      onExit={handleOnExit}
      title="Download Retrospective agency hours CSV"
      sx={{ top: '-120px' }}
    >
      <ModalBody>
        <Text as="p" level={3}>
          Invoicing end date
        </Text>
        <Box sx={{ height: 50 }}>
          <Box sx={{ position: 'fixed', width: '550px', zIndex: 1000 }}>
            {isDateRangeEnabled ? (
              <DateRangePicker
                locale={enGB}
                selected={dateRange}
                onChange={handleOnDateRangeChange}
                formFieldProps={{
                  label: 'Select date range',
                  inputProps: {
                    placeholder: 'Select date range',
                  },
                }}
              />
            ) : (
              <DatePicker
                locale={enGB}
                selected={activeDay}
                onChange={handleOnChange}
                formFieldProps={{
                  label: 'Select date',
                  inputProps: {
                    placeholder: 'Select date',
                  },
                }}
              />
            )}
          </Box>
        </Box>
      </ModalBody>
      <ModalFooter sx={{ pl: 5, pr: 5 }}>
        <Button onClick={handleCancel} variant="secondary">
          Cancel
        </Button>
        <Button onClick={isDateRangeEnabled ? handleDateRangeDownload : handleDownload}>Download</Button>
      </ModalFooter>
    </Modal>
  )
}

export default connect(state => ({
  item: getModal$(state.modals)('retrospectiveAgencyHoursModal'),
}))(withTrackingTriggerHoc(RetrospectiveAgencyHoursModal))
