// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import React from 'react'
import PropTypes from 'prop-types'
import { history } from 'syft-acp-core/history'

import { Button, ButtonGroup } from 'syft-acp-atoms/Button'
import { defaultWrapperPropTypes } from 'syft-acp-util/entityList'
import EmployerDetailListWrapper from '../EmployerDetailListWrapper'

const newAction = employerID => () => {
  history.push(`/entity/employers/view/${employerID}/accounts/new`)
}

const AccountsControls = ({ employerID }) => (
  <ButtonGroup>
    <Button kind="success" disabled={!employerID} onClick={newAction(employerID)}>
      Invite new member
    </Button>
  </ButtonGroup>
)

AccountsControls.propTypes = {
  employerID: PropTypes.number,
}

AccountsControls.defaultProps = {
  employerID: null,
}

const EmployerAccountsListWrapper = props => (
  <EmployerDetailListWrapper controlsRight={<AccountsControls />} {...props} />
)
EmployerAccountsListWrapper.propTypes = defaultWrapperPropTypes

export default EmployerAccountsListWrapper
