import {
  RumViewEvent,
  RumErrorEvent,
  RumResourceEvent,
  RumFetchResourceEventDomainContext,
  RumInitConfiguration,
  RumViewEventDomainContext,
} from '@datadog/browser-rum-slim'
import { matchPath, RedirectProps } from 'react-router-dom'

import { InitialState } from '../../server/utils/initialState'
import { routesConfig } from 'routesConfig'
import { buildPath, RouteConfig } from 'syft-acp-util/router/utils'

export const replaceSensitiveFromUrl = (url = '') =>
  url
    .replace(/query=[^&]*/, 'query=REDACTED')
    .replace(/email=[^&]*/, 'email=REDACTED')
    .replace(/telephone_number=[^&]*/, 'telephone_number=REDACTED')

const flattenChildRoute = (route: RouteConfig): RouteConfig | RouteConfig[] => {
  if ('to' in route && route.to) return route
  if ('children' in route && route.children) {
    return [
      route,
      ...route.children.flatMap(child =>
        flattenChildRoute({
          ...child,
          path: buildPath(child.path, route.path),
        } as Exclude<RouteConfig, RedirectProps>),
      ),
    ]
  }
  return route
}

const flattenedRoutes = routesConfig.flatMap(route => flattenChildRoute(route))

const findAccountablePartyId = (context: RumViewEventDomainContext): string | null => {
  const matchingRoute = flattenedRoutes.find(route =>
    ([] as string[]).concat(route.path ?? []).some(path =>
      matchPath(context.location.pathname, {
        path,
        exact: true,
        strict: false,
      }),
    ),
  )

  if (matchingRoute && 'metadata' in matchingRoute) {
    return matchingRoute.metadata?.accountablePartyId ?? null
  }

  return null
}

export const beforeSend: RumInitConfiguration['beforeSend'] = (event, context) => {
  if (event.type === 'view') {
    const viewEvent = event as RumViewEvent
    const viewContext = context as RumViewEventDomainContext

    if (!event.context) {
      event.context = {
        globalTags: {},
      }
    }

    const viewAccountablePartyId = findAccountablePartyId(viewContext)

    event.context.globalTags = {
      ...((event.context.globalTags as Record<string, any>) || {}),
      accountable_party_id:
        viewAccountablePartyId || (event.context.globalTags as Record<string, any>)?.accountable_party_id,
    }

    viewEvent.view.url = replaceSensitiveFromUrl(viewEvent.view.url)
  }

  if (event.type === 'resource') {
    const resourceEvent = event as RumResourceEvent
    const resourceContext = context as RumFetchResourceEventDomainContext

    resourceEvent.resource.url = replaceSensitiveFromUrl(resourceEvent.resource.url)

    if (resourceEvent.resource.type === 'fetch') {
      event.context = {
        ...event.context,
        requestId: resourceContext.response?.headers.get('X-Request-Id'),
      }
    }
  }

  if (event.type === 'error') {
    const errorEvent = event as RumErrorEvent

    if (errorEvent.error.resource) {
      errorEvent.error.resource.url = replaceSensitiveFromUrl(errorEvent.error.resource.url)
    }
  }

  return true
}

/**
 * @param {InitialState} initialState
 */
export default ({
  apiConfig,
  version,
  client,
  isLemma,
  stagingLevel,
  reactAppOverrides,
}: InitialState): RumInitConfiguration => {
  const sessionSampleRate =
    stagingLevel === 'prod' ? 100 : parseFloat(reactAppOverrides.REACT_APP_DD_RUM_SAMPLE_RATE || '0')
  const traceSampleRate =
    stagingLevel === 'prod' ? 100 : parseFloat(reactAppOverrides.REACT_APP_DD_RUM_TRACING_RATE || '0')

  return {
    service: `indeed-flex-admin-control-panel`,
    applicationId: 'b1bc40f3-d60f-413c-9622-9ca230621e1a',
    clientToken: 'pub51504753d6467c75c3acd28a34644c9e',
    env: apiConfig.apiRegion?.includes('DEMO') ? 'demo' : stagingLevel,
    enableExperimentalFeatures: ['feature_flags'],
    version: version.releaseVersion,
    site: 'datadoghq.com',
    beforeSend,
    sessionSampleRate: isLemma ? 0 : sessionSampleRate,
    traceSampleRate: isLemma ? 0 : traceSampleRate,
    defaultPrivacyLevel: 'mask-user-input',
    trackLongTasks: true,
    trackUserInteractions: false,
    sessionReplaySampleRate: 0,
    trackResources: true,
    useCrossSiteSessionCookie: true,
    useSecureSessionCookie: true,
    proxy: client.datadog_proxy_endpoint_url,
    trackViewsManually: true,
    allowFallbackToLocalStorage: true,
    trackSessionAcrossSubdomains: true,
    storeContextsAcrossPages: false,
    allowedTracingUrls: [
      { match: /.*\.qa\.indeed\.net/, propagatorTypes: ['datadog'] },
      { match: /.*\.indeed\.com/, propagatorTypes: ['datadog'] },
    ],
  }
}
