import * as React from 'karet'
import * as L from 'partial.lenses'
import InputCheckbox from 'syft-acp-atoms/InputCheckbox'

import { cellPropTypes, cellDefaultProps, PropTypesAtom } from '../dataProps'
import { colEditablePlaceholderFactory } from '../../../placeholders/factory'

export const AcpEditableColBoolean = colEditablePlaceholderFactory('AcpEditableCellBoolean')

const AcpEditableCellBoolean = ({ value }) => {
  return <InputCheckbox value={value.view(L.valueOr(''))} />
}

AcpEditableCellBoolean.propTypes = {
  ...cellPropTypes,
  value: PropTypesAtom,
}

AcpEditableCellBoolean.defaultProps = {
  ...cellDefaultProps,
}

AcpEditableCellBoolean.cellProperties = ['form']

export default AcpEditableCellBoolean
