export const trackingEvents = {
  SAVE_BUTTON: {
    CLICKED: 'employer_detail.save_btn.clicked',
  },
  VERIFY_EMPLOYER_BUTTON: {
    CLICKED: 'employer_detail.verify_employer_btn.clicked',
  },
  UNVERIFY_EMPLOYER_BUTTON: {
    CLICKED: 'employer_detail.unverify_employer_btn.clicked',
  },
  MIGRATE_TO_SYFTFORCE_BUTTON: {
    CLICKED: 'employer_detail.migrate_to_syftforce_btn.clicked',
  },
  TIER_DROPDOWN: {
    CLICKED: 'employer_detail.tier_dropdown.clicked',
  },
}
