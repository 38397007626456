// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import EditableTable from 'syft-acp-core/components/EditableTable'

const structure = [
  [
    'total_listings',
    {
      header: 'Total listings',
    },
  ],
  [
    'total_jobs',
    {
      header: 'Total jobs',
    },
  ],
  [
    'total_completed_jobs',
    {
      header: 'Total completed jobs',
    },
  ],
  [
    'total_shifts_posted',
    {
      header: 'Total shifts posted',
    },
  ],
  [
    'total_hours_posted',
    {
      header: 'Total hours posted',
    },
  ],
  [
    'total_hours_worked',
    {
      header: 'Total hours worked',
    },
  ],
  [
    'average_wage_posted',
    {
      header: 'Average wage posted',
    },
  ],
  [
    'average_length_of_shift_posted',
    {
      header: 'Average length of shifts posted',
    },
  ],
  [
    'average_shifts_per_job',
    {
      header: 'Average shifts per job',
    },
  ],
  [
    'total_applications',
    {
      header: 'Total applications',
    },
  ],
  [
    'total_shifts_worked',
    {
      header: 'Total shifts worked',
    },
  ],
  [
    'total_hours_worked',
    {
      header: 'Total hours worked',
    },
  ],
  [
    'total_wages_worked',
    {
      header: 'Total wages worked',
    },
  ],
  [
    'average_wage_worked',
    {
      header: 'Average wage worked',
    },
  ],
  [
    'average_length_of_shift_worked',
    {
      header: 'Average length of shift worked',
    },
  ],
]

const EmployerDetailStats = ({ id, employerStats }) => (
  <EditableTable data={employerStats[id] || {}} structure={structure} />
)

EmployerDetailStats.propTypes = {
  id: PropTypes.number.isRequired,
  employerStats: PropTypes.object.isRequired,
}

export default connect(state => ({
  employerStats: state.stats.employers.data,
}))(EmployerDetailStats)
