// Syft ACP - UI Library <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import classnames from 'classnames'
import { isNil } from 'lodash-es'
import PropTypes from 'prop-types'
import React from 'react'
import { Checkbox, Radio } from 'react-bootstrap'
import { ButtonBoolean } from 'syft-acp-atoms/Button'
import { acpFilterDefaults } from '../dataProps'

import { filterDecorator } from '../filterDecorator'

/**
 * Display option groups inline, only one at a time can be enabled
 * Slug can be either value or function that generates the value on change
 *
 * <Acp.Filter.Switch kind="checkbox" name="Tier" value={1}>
 *   Label
 * </Acp.Filter.Switch>
 *
 * <Acp.Filter.Switch kind="radio" name="type" value="type1>
 *   Label
 * </Acp.Filter.Switch>
 * <Acp.Filter.Switch kind="radio" name="type" value="type2>
 *   Label
 * </Acp.Filter.Switch>
 */
class AcpFilterSwitch extends React.PureComponent {
  static propTypes = {
    children: PropTypes.node.isRequired,
  }

  static defaultProps = {
    ...acpFilterDefaults,
    children: [],
  }

  // disables debounce set by filter decorator
  static requireDebounce = false

  constructor(props) {
    super(props)
    this.state = {
      value: this.props.initialValue || false,
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { name, value, setValue } = this.props

    // set query parameter on state value change
    if (prevState.value !== this.state.value) {
      setValue(name, this.state.value ? (isNil(value) ? String(this.state.value) : value) : '')
    }
  }

  handleChange = () => {
    this.setState(prevState => ({
      value: !prevState.value,
    }))
  }

  get buttonComponent() {
    const { kind = '' } = this.props
    switch (kind.toLowerCase()) {
      case 'checkbox':
        return Checkbox
      case 'radio':
        return Radio
      default:
        return Checkbox
    }
  }

  render() {
    const { className, children } = this.props
    return (
      <div className={classnames('AcpFilterSwitch', className)} data-testid="acp-filter-switch">
        <ButtonBoolean
          checked={this.state.value}
          onClick={this.handleChange}
          component={this.buttonComponent}
        >
          {children}
        </ButtonBoolean>
      </div>
    )
  }
}

export default filterDecorator(AcpFilterSwitch)
