import React from 'react'
import { workerAutocomplete } from 'syft-acp-core/api/resources/admin/workers'

import FilterAutocomplete, { autocompleteProps } from './FilterAutocomplete'

const FilterAutocompleteWorkers = props => (
  <FilterAutocomplete {...props} attr="label" queryFunction={workerAutocomplete} />
)

FilterAutocompleteWorkers.propTypes = autocompleteProps

export default FilterAutocompleteWorkers
