import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { isPast, isFuture } from 'date-fns'
import { useFlexFlagIsOn } from '@indeed/flex-feature-flags'

import { Button, ButtonDropdown, MenuItem } from 'syft-acp-atoms/Button'
import { showModal } from 'syft-acp-core/store/modals/actions'
import ReactTooltip from 'react-tooltip'

import { cancelBookingChanges, deleteBooking } from 'syft-acp-core/actions/bookings'
import { getModal$ } from 'syft-acp-core/store/modals/selectors'

const ListingShiftCancelButton = ({
  dispatch,
  shiftID,
  shiftBooking: { id, worker, shift, jobID, long_term_assignment },
  jobs,
}) => {
  const onClick = () => {
    dispatch(deleteBooking({ id, shiftID }))
    dispatch(cancelBookingChanges({ id, shiftID }))
  }

  const job = jobs?.entityDetail?.[jobID]
  const shiftIds = job?.shifts?.filter(s => isFuture(Date.parse(s.start_time))).map(s => s.id)

  const openModal = () => {
    dispatch(
      showModal('EndJobAssignmentModal', {
        worker,
        jobID,
        shiftIds,
        longTermAssignment: long_term_assignment,
      }),
    )
  }
  const pastShift = isPast(Date.parse(shift?.start_time))

  const featureFlag = useFlexFlagIsOn('pte_13454_end_job_assignment')

  const disabledTermination = pastShift || !long_term_assignment
  return !featureFlag || disabledTermination ? (
    <div className="small-padded">
      <Button onClick={onClick} block>
        Cancel
      </Button>
    </div>
  ) : (
    <div style={{ position: 'absolute' }} className="small-padded">
      <ButtonDropdown title="Cancel" id="dropdown_actions" pullRight>
        <MenuItem
          data-tip="Cancel worker from this individual shift"
          data-for="cancel-tooltip"
          onClick={onClick}
          key="1"
        >
          Cancel shift
        </MenuItem>
        <MenuItem
          data-tip="End worker's long term assignment"
          data-for="cancel-tooltip"
          key="2"
          onClick={openModal}
        >
          End assignment
        </MenuItem>
      </ButtonDropdown>
      <ReactTooltip effect="solid" id="cancel-tooltip" />
    </div>
  )
}

ListingShiftCancelButton.propTypes = {
  dispatch: PropTypes.func.isRequired,
  shiftID: PropTypes.number.isRequired,
  shiftBooking: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  }).isRequired,
}

export default connect(state => ({
  item: getModal$(state.modals)('EndJobAssignmentModal'),
  jobs: state?.jobs,
}))(ListingShiftCancelButton)
