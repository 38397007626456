import employerPreferences from './action-types'
import { FetchEmployerPreferencesActionPayload, UpdateEmployerPreferencesActionPayload } from './types'

export type EmployerPreferencesAction =
  | ReturnType<typeof fetchEmployerPreferences>
  | ReturnType<typeof updateEmployerPreferences>

export const fetchEmployerPreferences = ({ options }: { options: FetchEmployerPreferencesActionPayload }) => ({
  type: employerPreferences.ENTITIES_FETCH_BEGIN,
  options,
  payload: options,
})

export const updateEmployerPreferences = ({ options }: { options: UpdateEmployerPreferencesActionPayload }) => ({
  type: employerPreferences.ENTITIES_UPDATE_BEGIN,
  options,
  payload: options,
})
