import { apiRequest, handleCall } from 'syft-acp-core/api/call'
import { store } from 'syft-acp-core/store'

import { FetchAlerts } from './types'

export const fetchAlerts = ({ id_gt, employer_id }: FetchAlerts) => {
  // Check with the store to see if we are authenticated. If not, cancel.
  if (!store.getState().auth.isLoggedIn) {
    return
  }
  // eslint-disable-next-line consistent-return
  return new Promise((resolve, reject) => {
    const req = apiRequest({ path: '/admin_alerts', reqArgs: { id_gt, employer_id } })
    handleCall(req, resolve, reject)
  })
}
