// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import { fetchStatsWorkers, fetchStatsEmployer } from 'syft-acp-core/api/resources/stats'
import { takeLatestApiCall } from './helpers'

export default function* root() {
  yield takeLatestApiCall('STATS_WORKER_FETCH', fetchStatsWorkers)
  yield takeLatestApiCall('STATS_EMPLOYER_FETCH', fetchStatsEmployer)
}
