import React from 'react'
import { Button, ButtonLink, ButtonGroup } from 'syft-acp-atoms/Button'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { noop } from 'lodash-es'

import * as workerNetworkActions from 'syft-acp-core/actions/worker-networks'
import RecordControls from 'syft-acp-core/components/RecordSection/RecordControls'
import LoadingIndicator from 'syft-acp-util/components/LoadingIndicator'
import { WorkerNetworkDetailControlsProps as Props } from './WorkerNetworkDetail.types'
import DeleteNetworkButton from './DeleteNetworkButton'

const WorkerNetworkDetailControls = ({
  workerID,
  data = { id: 0, owner_id: 0, owner_type: 'Employer', name: '', employer_id: 0, picture: { uuid: '' } },
  isPristine,
  isLoadingData,
  isSavingData,
  actions,
  onSave = noop,
  onDelete = noop,
}: Props) => {
  const saveAction = () => {
    // NOTE: Currently, we can only make trusted networks for EMPLOYERS,
    // and not LOCATIONS. This will have to be changed in the future.
    actions.createWorkerNetwork(workerID, { employerID: data.owner_id })
    if (data.owner_id) {
      onSave(data.owner_id)
    }
  }
  return (
    <RecordControls>
      <div className="spacer">
        <ButtonGroup>
          <ButtonLink to={`/entity/workers/view/${workerID}/networks/list`}>Back to trusted networks</ButtonLink>
        </ButtonGroup>{' '}
      </div>
      <div className="right">
        <LoadingIndicator loadingState={isSavingData || isLoadingData} />{' '}
        <ButtonGroup>
          {!data.id && (
            <Button kind="success" onClick={saveAction} disabled={isSavingData || isPristine}>
              Save
            </Button>
          )}
        </ButtonGroup>{' '}
        <ButtonGroup>
          {data.id && (
            <DeleteNetworkButton
              isPristine={isPristine}
              workerID={workerID}
              ownerID={data.owner_id}
              ownerType={data.owner_type}
              onDelete={onDelete}
            />
          )}
        </ButtonGroup>
      </div>
    </RecordControls>
  )
}

export const storeConnector = connect(
  ({ workerNetworks: { isSavingData, isLoadingData } }) => ({
    isSavingData,
    isLoadingData,
  }),
  dispatch => ({
    actions: bindActionCreators(workerNetworkActions, dispatch),
  })
)
export default storeConnector(WorkerNetworkDetailControls)
