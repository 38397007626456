// Syft ACP - UI Library <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import React from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { debounce, get } from 'lodash-es'
import { acpFilterDefaults } from './dataProps'

import { setFilter } from 'syft-acp-core/store/filters/actions'
import AcpOption from '../../placeholders/AcpOption'
import FilterContainer from './FilterContainer'
/**
 * Connects filter component to query parameter and makes debounced filter input (can be disabled)
 *
 * @param FilterComponent
 * @returns {*}
 */
export const filterDecorator = FilterComponent => {
  class DecoratedComponent extends React.PureComponent {
    static propTypes = {
      setValue: PropTypes.func,
    }

    static defaultProps = {
      ...acpFilterDefaults,
    }

    constructor(props) {
      super(props)
      // Make a debounced version of this filter's callback.
      this.setValueDebounced = debounce(props.actions.setFilter, 300, { trailing: true })
      // Get the initial value if the query string contains it.
      this.initialValue = this.getInitialValue()
    }

    setValue = (name, value) => {
      const { actions } = this.props
      actions.setFilter(name, value)
    }

    getInitialValue = () => {
      const { name, nameFrom, nameTo, query, initialValue } = this.props
      let value
      if (name) {
        value = query[name]
      }
      // If this filter uses a 'from' and 'to' type input, grab both values.
      if (nameFrom || nameTo) {
        value = { from: query[nameFrom], to: query[nameTo] }
      }
      return value || initialValue
    }

    /**
     * Parses children components and builds a tree from Acp.Option placeholders
     *
     * @param children
     * @returns {any}
     */
    parseChildrenOptions = children => {
      if (children) {
        const childrenArray = React.Children.toArray(children)
        const options = []

        childrenArray.forEach(element => {
          const isFragment = element.type === React.Fragment
          const elementProps = element.props

          // unwrap fragments
          if (isFragment) {
            return options.push(...this.parseChildrenOptions(elementProps.children))
          }

          const isOptionType = element.type === AcpOption
          // Only Acp.Option children allowed at top level
          if (!isOptionType) return

          // if this first element of the group is option as well, then this group has not heading
          const groupEmptyHeading = get(elementProps, 'children.0.type') === AcpOption
          const isGroup = React.Children.count(get(elementProps, 'children')) > 1
          if (isGroup) {
            if (groupEmptyHeading) {
              /**
               * ['', [{slug: 'code', children: 'Title'}]
               */
              return options.push(['', this.parseChildrenOptions(elementProps.children.slice(0))])
            }

            const groupLabel = elementProps.children[0]
            const groupChildren = elementProps.children.slice(1)
            /**
             * ['group title', [{slug: 'code', children: 'Title'}]
             */
            return options.push([groupLabel, this.parseChildrenOptions(groupChildren)])
          }

          /**
           * {
           *   slug: 'code',
           *   children: 'Title' || <some node>
           * }
           */
          options.push(elementProps)
        })

        return options.filter(Boolean)
      }
      return null
    }

    render() {
      // Check if the filter requires debouncing (true for all text input fields, false otherwise).
      const setValue = FilterComponent.requireDebounce ? this.setValueDebounced : this.setValue
      const childrenOptions = this.parseChildrenOptions(this.props.children)

      return (
        <FilterContainer>
          <FilterComponent
            {...this.props}
            childrenOptions={childrenOptions}
            setValue={setValue}
            initialValue={this.initialValue}
          />
        </FilterContainer>
      )
    }
  }

  return connect(
    state => ({
      query: state.routing.locationBeforeTransitions.query,
    }),
    dispatch => ({
      actions: bindActionCreators({ setFilter }, dispatch),
    }),
  )(DecoratedComponent)
}
