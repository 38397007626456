// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import { flowRight } from 'lodash-es'
import React, { useEffect } from 'react'
import deepEqual from 'react-fast-compare'
import { connect } from 'react-redux'
import { AnyAction, Dispatch, bindActionCreators } from 'redux'
import { history } from 'syft-acp-core/history'

import { TrackingTrigger, useTrackingTrigger } from '@indeed/flex-tracking-context'
import { Modal } from 'syft-acp-core/components/Modal'
import { SUPPORTED_COUNTRY_CODES } from 'syft-acp-core/lib/i18n'
import { Store } from 'syft-acp-core/store'
import * as venuesActions from 'syft-acp-core/store/employer-venues/actions'
import * as employerRateCardsActions from 'syft-acp-core/store/employerRateCards/actions'
import {
  selectRateCardsToDisable,
  selectRateCardsToEnable,
} from 'syft-acp-core/store/employerRateCards/selectors'
import { EmployerRateCardEntity, ModalNames } from 'syft-acp-core/store/employerRateCards/types'
import { fetchEmployerShiftRateTypes } from 'syft-acp-core/store/employerShiftRateTypes/actions'
import { hideModal, showModal } from 'syft-acp-core/store/modals/actions'
import { getModal$ } from 'syft-acp-core/store/modals/selectors'
import * as rolesActions from 'syft-acp-core/store/roles/actions'
import * as venueCategoriesActions from 'syft-acp-core/store/venueCategories/actions'
import Acp from 'syft-acp-uikit'
import {
  FilterAutocompleteVenue,
  FilterEnum,
  FilterRolesSkills,
  FilterSelectVenueCategories,
} from 'syft-acp-util/components/FilterForm'
import { createEntityListConnector } from 'syft-acp-util/entityList'
import usePrevValue from 'syft-acp-util/hooks/usePrevValue'

import {
  getPercentage,
  getRole,
  getShiftType,
  getSkill,
  getVenueCategoryName,
  getVenueName,
  handleSelection,
  handleSelectionLabel,
  rateCardStates,
  roleKey,
  selectionKey,
  skillKey,
} from './EmployerRateCardList.constants'
import { trackingEvents } from './EmployerRateCardList.tracking'
import type {
  EmployerRateCardListEntityConnectedProps as EntityConnectorProps,
  EmployerRateCardListProps as Props,
} from './EmployerRateCardList.types'
import EmployerRateCardListWrapper from './EmployerRateCardListWrapper'

import './EmployerRateCardList.styles.css'

const getRateCardState = (row: EmployerRateCardEntity) => {
  if (row.disabled_at != null) return 'Deactivated'
  const now = new Date()
  const effectiveDate = new Date(row.effective_date)
  return effectiveDate < now ? 'Active' : 'In-active'
}

enum ConfirmModals {
  Disable = 'Disable',
  Enable = 'Enable',
}

const EmployerRateCardList = ({
  actions,
  data,
  isLoadingData,
  isLoading,
  roles,
  skills,
  employerVenues,
  venueCategories,
  employerID,
  query,
  urlBase,
  countryCode,
  confirmModal,
  isSaving,
  rateCardsToDisable,
  rateCardsToEnable,
  employerShiftRateTypes,
}: Props) => {
  const isGB = countryCode === SUPPORTED_COUNTRY_CODES.GB
  const isDisableModal = confirmModal.data.type === ConfirmModals.Disable

  const prevQuery = usePrevValue(query)
  const triggerEvent = useTrackingTrigger()

  useEffect(() => {
    if (actions.fetchEntities && (!prevQuery || (prevQuery && !deepEqual(prevQuery, query)))) {
      actions.fetchEntities({ options: query })
    }
  }, [prevQuery, query, actions])

  useEffect(() => {
    actions.fetchEmployerShiftRateTypes(employerID)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleAdvanced = ({ rowData }: any) => {
    triggerEvent(trackingEvents.ADVANCED.CLICKED, { rate_card_id: rowData.id })
    history.push(`${urlBase}${rowData.id}`)
  }

  const handleFilterTracking = (filter: string) => (value?: string) => {
    triggerEvent(trackingEvents.FILTER.CHANGED, {
      filter,
      filter_value: value,
    })
  }

  const handleRolesFilterChange = (key: string, value?: string) => {
    handleFilterTracking(key === roleKey ? 'role' : 'skill')(value)
  }

  const handleDisableClick = () => {
    actions.showModal(confirmModal.modalName, {}, null, {
      header: 'Disable rate cards',
      type: ConfirmModals.Disable,
      confirmText: 'Are you sure you want to disable the following rate cards?',
      confirmList: rateCardsToDisable,
      warningText: 'The following rate cards are already disabled',
      warningList: rateCardsToEnable,
    })
    triggerEvent(trackingEvents.BULK_DISABLE.CLICKED, {
      rate_card_ids: rateCardsToDisable,
    })
  }

  const handleEnableClick = () => {
    actions.showModal(confirmModal.modalName, {}, null, {
      header: 'Enable rate cards',
      type: ConfirmModals.Enable,
      confirmText: 'Are you sure you want to enable the following rate cards?',
      confirmList: rateCardsToEnable,
      warningText: 'The following rate cards are already enabled',
      warningList: rateCardsToDisable,
    })
    triggerEvent(trackingEvents.BULK_ENABLE.CLICKED, {
      rate_card_ids: rateCardsToEnable,
    })
  }

  const handleDisableConfirm = () => {
    actions.bulkDisable(rateCardsToDisable)
    triggerEvent(trackingEvents.BULK_DISABLE.CONFIRM_CLICKED, {
      rate_card_ids: rateCardsToDisable,
    })
  }

  const handleEnableConfirm = () => {
    actions.bulkEnable(rateCardsToEnable)
    triggerEvent(trackingEvents.BULK_ENABLE.CONFIRM_CLICKED, {
      rate_card_ids: rateCardsToEnable,
    })
  }

  const handleClose = () => {
    actions.hideModal(confirmModal.modalName)
  }

  return (
    <div className="employer-rate-cards-list">
      <EmployerRateCardListWrapper
        employerID={employerID}
        disableRateCardsSelected={rateCardsToDisable.length > 0}
        enableRateCardsSelected={rateCardsToEnable.length > 0}
        onDisableClick={handleDisableClick}
        onEnableClick={handleEnableClick}
        entityList={
          <Acp.EntityList
            data={data}
            isLoading={isLoadingData || isLoading}
            inContainer
            hasPagination
            hasResultCount
            idKeyValue="id"
            pagination={{ includeEmpty: true }}
          >
            <Acp.Actions>
              <Acp.FilterGroup title="Venue">
                <FilterAutocompleteVenue
                  name="venue_id"
                  employerID={employerID}
                  prefixOptions={[{ id: '', name: 'No venue' }]}
                  onSave={handleFilterTracking('venue')}
                />
              </Acp.FilterGroup>
              <Acp.FilterGroup title="Venue Category">
                <FilterSelectVenueCategories
                  name="venue_category_id"
                  employerID={employerID}
                  onChange={handleFilterTracking('venue_category')}
                  allowAny
                  filterOptions={{ includeEmpty: true }}
                />
              </Acp.FilterGroup>
              <Acp.FilterGroup title="Roles and skills">
                <FilterRolesSkills
                  role_key={roleKey}
                  skill_key={skillKey}
                  independentSkills
                  allowAny
                  small
                  filterOptions={{ includeEmpty: true }}
                  onChange={handleRolesFilterChange}
                  fireCallbackBeforeMount={false}
                />
              </Acp.FilterGroup>
              <Acp.FilterGroup title="Status">
                <FilterEnum
                  name="include_disabled"
                  options={[[{ value: '1', label: 'Include Deactivated' }]]}
                />
              </Acp.FilterGroup>
            </Acp.Actions>
            <Acp.Table>
              <Acp.Col.Selector
                value={handleSelection}
                headerValue={handleSelection}
                headerTooltip="Select all rate cards on this page"
                options={{
                  scope: selectionKey,
                  valueKeys: ['id'],
                  ariaLabel: handleSelectionLabel,
                }}
                align="center"
                isMinimal
                colNoLink
              />
              <Acp.Col.Text value="id" header="ID" isMinimal isNumeric />
              <Acp.Col.Text value={getVenueName(employerVenues)} header="Venue" />
              <Acp.Col.Text value={getVenueCategoryName(venueCategories)} header="Venue Category" />
              <Acp.Col.Text value={getShiftType(employerShiftRateTypes)} header="Shift Type" isMinimal />
              <Acp.Col.Text value={getRole(roles)} header="Role" />
              <Acp.Col.Text value={getSkill(skills)} header="Skill" />
              <Acp.Col.Money value="default_payable_rate" header="Default Pay Rate" isMinimal />
              {isGB && (
                <Acp.Col.Text value={getPercentage('burdens_percent')} header="Burdens (%)" isMinimal />
              )}
              {isGB && (
                <Acp.Col.Text value={getPercentage('margins_percent')} header="Margins (%)" isMinimal />
              )}
              {!isGB && <Acp.Col.Text value="fee_percent" header="Fee (%)" isMinimal />}
              {isGB && <Acp.Col.Money value="invoice_rate" header="Invoice Rate" isMinimal />}
              <Acp.Col.Enum value={getRateCardState} header="Status">
                {Object.entries(rateCardStates).map(([slug, option]) => (
                  <Acp.Enum slug={slug} color={option.color} key={slug}>
                    {option.name}
                  </Acp.Enum>
                ))}
              </Acp.Col.Enum>
              <Acp.Col.DateTime value="effective_date" header="Effective date" />
              <Acp.Col.DateTime value="disabled_at" header="Disabled date" />
              <Acp.Col.Button header="">
                <Acp.Button kind="success" onClick={handleAdvanced}>
                  Advanced
                </Acp.Button>
              </Acp.Col.Button>
            </Acp.Table>
          </Acp.EntityList>
        }
      />

      <Modal
        header={confirmModal.data.header}
        cancelText="Cancel"
        confirmationText="Confirm"
        isShown={confirmModal.isShown}
        isLoading={isSaving}
        onConfirm={isDisableModal ? handleDisableConfirm : handleEnableConfirm}
        onClose={handleClose}
      >
        <p>{confirmModal.data.confirmText}</p>
        <p>{confirmModal.data.confirmList?.join(', ')}</p>
        {confirmModal.data.warningList?.length > 0 && (
          <>
            <p>{confirmModal.data.warningText}</p>
            <p>{confirmModal.data.warningList.join(', ')}</p>
          </>
        )}
        <TrackingTrigger
          onLoad={{
            event: trackingEvents[`${isDisableModal ? 'BULK_DISABLE' : 'BULK_ENABLE'}`].MODAL_VIEWED,
            payload: {
              employer_id: employerID,
              rate_card_ids: confirmModal.data.confirmList,
            },
          }}
        />
      </Modal>
      <TrackingTrigger
        onLoad={{
          event: trackingEvents.PAGE.VIEWED,
          payload: {
            employer_id: employerID,
          },
        }}
      />
    </div>
  )
}

const mapStateToProps = (state: Store) => ({
  roles: state.roles.entityMap,
  skills: state.skills.entityMap,
  employerVenues: state.employerVenuesFiltered.entityMap,
  venueCategories: state.venueCategories.entityMap,
  employerShiftRateTypes: state.employerShiftRateTypes.entityMap,
  countryCode: state.app.countryCode,
  isSaving: state.newEmployerRateCards.isSavingData,
  confirmModal: getModal$(state.modals)(ModalNames.RateCardsConfirm),
  rateCardsToDisable: selectRateCardsToDisable(state, selectionKey),
  rateCardsToEnable: selectRateCardsToEnable(state, selectionKey),
  isLoading:
    state.industries.isLoadingData ||
    state.roles.isLoadingData ||
    state.skills.isLoadingData ||
    state.employerShiftRateTypes.isLoadingData ||
    state.venueCategories.isLoadingData ||
    state.employerVenuesFiltered.isLoadingData,
})

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>, props: EntityConnectorProps) => ({
  actions: {
    ...props.actions,
    ...bindActionCreators(
      {
        ...venuesActions,
        ...venueCategoriesActions,
        ...rolesActions,
        hideModal,
        showModal,
        bulkDisable: employerRateCardsActions.bulkDisableEmployerRateCard,
        bulkEnable: employerRateCardsActions.bulkEnableEmployerRateCard,
        fetchEmployerShiftRateTypes: fetchEmployerShiftRateTypes,
      },
      dispatch,
    ),
  },
})

export const entityConnector = createEntityListConnector<EmployerRateCardEntity>({
  entityActions: { fetchEntities: employerRateCardsActions.fetchEmployerRateCards },
  entityStore: 'newEmployerRateCards',
})

export const storeConnector = connect(mapStateToProps, mapDispatchToProps)
export default flowRight(entityConnector, storeConnector)(EmployerRateCardList)
