// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import { fetchExpiringDocuments, markExpiringDocumentsAsResolved } from 'syft-acp-core/api/resources/admin/watchlists'
import { takeEveryApiCall } from 'syft-acp-core/sagas/helpers'

export default function* saga() {
  yield takeEveryApiCall('EXPIRING_DOCUMENTS_FETCH', fetchExpiringDocuments)
  yield takeEveryApiCall('EXPIRING_DOCUMENTS_RESOLVE', markExpiringDocumentsAsResolved)
}
