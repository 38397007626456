export const AGENCY_UNFILLED_ALLOCATIONS_FETCH_BEGIN = 'syftacp/AGENCY_UNFILLED_ALLOCATIONS_FETCH_BEGIN'
export const AGENCY_UNFILLED_ALLOCATIONS_FETCH_FAILED = 'syftacp/AGENCY_UNFILLED_ALLOCATIONS_FETCH_FAILED'
export const AGENCY_UNFILLED_ALLOCATIONS_FETCH_SUCCEEDED =
  'syftacp/AGENCY_UNFILLED_ALLOCATIONS_FETCH_SUCCEEDED'
export const AGENCY_UNFILLED_ALLOCATIONS_DELETE_BEGIN = 'syftacp/AGENCY_UNFILLED_ALLOCATIONS_DELETE_BEGIN'
export const AGENCY_UNFILLED_ALLOCATIONS_DELETE_FAILED = 'syftacp/AGENCY_UNFILLED_ALLOCATIONS_DELETE_FAILED'
export const AGENCY_UNFILLED_ALLOCATIONS_DELETE_SUCCEEDED =
  'syftacp/AGENCY_UNFILLED_ALLOCATIONS_DELETE_SUCCEEDED'
export const AGENCY_BULK_DELETE_ALLOCATIONS_BEGIN = 'syftacp/AGENCY_BULK_DELETE_ALLOCATIONS_BEGIN'
export const AGENCY_BULK_DELETE_ALLOCATIONS_FAILED = 'syftacp/AGENCY_BULK_DELETE_ALLOCATIONS_FAILED'
export const AGENCY_BULK_DELETE_ALLOCATIONS_SUCCEEDED = 'syftacp/AGENCY_BULK_DELETE_ALLOCATIONS_SUCCEEDED'
