// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { isArray, get } from 'lodash-es'

import { entityList$ } from 'syft-acp-core/reducers/generators/entities'

/**
 * Simple component that displays a city by name from ID (or array of IDs).
 * Based on <syft-acp-core/components/ManagerDisplay>.
 */
class CityDisplay extends React.PureComponent {
  static propTypes = {
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.arrayOf(PropTypes.number)]).isRequired,
    cities: PropTypes.array.isRequired,
  }

  // Returns either the empty placeholder, or the name of the city by ID.
  // If an array was passed, the city names will be comma separated.
  getValue = value => {
    if (!value) return '–'
    return isArray(value) ? value.map(v => this.idToCity(v)).join(', ') : this.idToCity(value)
  }

  // Returns a city's name by ID, or the string '(unknown: 123)' if they aren't in our list.
  idToCity = id => {
    const { cities } = this.props
    return get(
      cities.find(m => m.id === id),
      'name',
      `(unknown: ${id})`
    )
  }

  render() {
    const { value } = this.props
    return <div className="CityDisplay">{this.getValue(value)}</div>
  }
}

export default connect(({ cities }) => ({
  cities: entityList$(cities, {}),
}))(CityDisplay)
