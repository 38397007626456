import React, { useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { Modal } from 'syft-acp-core/components/Modal'
import { getModal$ } from 'syft-acp-core/store/modals/selectors'
import { hideModal } from 'syft-acp-core/store/modals/actions'
import { banUser, fetchUserBanReasons } from 'syft-acp-core/store/users/actions'
import FilterReasons from 'syft-acp-util/components/FilterForm/FilterReasons'
import AppealBanWarning from 'syft-acp-core/components/AppealBanWarning'

import { BanWorkerModalProps as Props } from './BanWorkerModal.types'

export const modalName = 'banWorkerModal'

export const storeConnector = connect(
  state => ({
    modal: getModal$(state.modals)(modalName),
    banReasons: state.users.userBanReasons,
  }),
  dispatch => ({
    actions: {
      ...bindActionCreators(
        {
          hideModal,
          banUser,
          fetchUserBanReasons,
        },
        dispatch
      ),
    },
  })
)

const BanWorkerModal = ({ actions, modal, userID, workerID }: Props) => {
  const [reasonID, setReasonID] = useState<string>()

  const closeModal = () => {
    actions.hideModal(modalName)
  }

  const onChange = (val: string) => setReasonID(val)

  const handleConfirm = () => {
    if (reasonID) {
      actions.banUser(userID, true, reasonID, workerID)
      modal.options.onBanConfirm(reasonID)
      closeModal()
    }
  }

  return (
    <Modal
      header={'Ban user #' + userID}
      isShown={modal.isShown}
      onClose={closeModal}
      onConfirm={handleConfirm}
      canSubmit={!!reasonID}
      cancelText="Cancel"
      errorText=""
      confirmationText="Confirm"
    >
      <AppealBanWarning workerID={modal.data.id} />
      <Row>
        <Col sm={3}>Reason:</Col>
        <Col sm={9}>
          <FilterReasons type="bans" onChange={onChange} value={reasonID} testID="ban_reason_select" />
        </Col>
      </Row>
    </Modal>
  )
}

export default storeConnector(BanWorkerModal)
