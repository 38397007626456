import React, { memo } from 'react'
import ActionDropdown from 'syft-acp-util/components/FilterForm/ActionDropdown'

import { cellDefaultProps } from '../../dataProps'
import { colPlaceholderFactory } from '../../../../placeholders/factory'
import { Props } from './types'

/** Column placeholder component. */
export const AcpColActionDropdown = colPlaceholderFactory('AcpCellActionDropdown')

/**
 * Column type for actions in dropdown.
 *
 */
const AcpCellActionDropdown = ({ options = [], spec, rowData, subRowData }: Props) => {
  const items = options.map(item => ({
    action: item.action(rowData, subRowData),
    label: item.label,
    disabled: item?.disabled && item?.disabled(rowData),
    disabledTooltip: item.disabledTooltip,
  }))
  return (
    <ActionDropdown
      disabled={spec.disabled && spec.disabled(rowData, subRowData)}
      disabledTooltip={spec.disabledTooltip && spec.disabledTooltip(rowData, subRowData)}
      label={spec.label}
      items={items}
    />
  )
}

AcpCellActionDropdown.defaultProps = cellDefaultProps

export default memo(AcpCellActionDropdown)
