import entityReducer from './generators/entityReducer'
import {
  AGENCIES_FETCH_BEGIN,
  AGENCIES_FETCH_SUCCEEDED,
  AGENCIES_FETCH_FAILED,
  AGENCIES_CLEAR_ITEMS,
} from '../actions/action-types'
import * as entityTypes from './generators/entities'

export default entityReducer('agencies', {
  [entityTypes.ENTITIES_FETCH_BEGIN]: AGENCIES_FETCH_BEGIN,
  [entityTypes.ENTITIES_FETCH_SUCCEEDED]: AGENCIES_FETCH_SUCCEEDED,
  [entityTypes.ENTITIES_FETCH_FAILED]: AGENCIES_FETCH_FAILED,
  [AGENCIES_CLEAR_ITEMS]: state => ({
    ...state,
    entityMap: [],
  }),
})
