import entityReducer from 'syft-acp-core/reducers/generators/entityReducer'
import * as entityTypes from 'syft-acp-core/reducers/generators/entities'

import {
  AGENCY_SHIFT_HISTORIES_FETCH_BEGIN,
  AGENCY_SHIFT_HISTORIES_FETCH_SUCCEEDED,
  AGENCY_SHIFT_HISTORIES_FETCH_FAILED,
} from './action-types'

export default entityReducer('agencyShiftHistory', {
  [entityTypes.ENTITIES_FETCH_BEGIN]: AGENCY_SHIFT_HISTORIES_FETCH_BEGIN,
  [entityTypes.ENTITIES_FETCH_SUCCEEDED]: AGENCY_SHIFT_HISTORIES_FETCH_SUCCEEDED,
  [entityTypes.ENTITIES_FETCH_FAILED]: AGENCY_SHIFT_HISTORIES_FETCH_FAILED,
})
