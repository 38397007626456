import { InitialState } from '../../server/utils/initialState'
import { AppConfig } from './types'
import { CountryCode } from 'syft-acp-core/lib/i18n'
import { getMaintenanceMode } from 'syft-acp-util/maintenanceMode'

export default (initialState: InitialState): AppConfig => ({
  ...initialState,
  app: 'flex-acp',
  appVersion: initialState.version.releaseVersion?.split('-')[0] ?? '',
  build: initialState.version.hash ?? '',
  ctk: initialState.ctk ?? '',
  region: initialState.apiConfig.apiRegion.replace('DEMO_', '') as CountryCode,
  isDeployEnvTestOrDev: () => initialState.stagingLevel !== 'prod',
  MAINTENANCE_MODE: getMaintenanceMode(initialState),
})
