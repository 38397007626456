import { createIntl as _createIntl, createIntlCache } from 'react-intl'
import { get, flow } from 'lodash-es'
import moment from 'moment'
import momentTz from 'moment-timezone'
import config from 'syft-acp-core/config'

import { getCountryI18nData } from 'syft-acp-core/lib/i18n'

const intlCache = createIntlCache()

/**
 * Assigns timezone abbreviation & offset to Intl
 *
 * @param timeZone
 * @returns {function(intl: *): intl}
 */
const intlWithTZAbbrOffset = timeZone => intl =>
  Object.assign(intl, {
    tzAbbr: momentTz.tz(momentTz(), timeZone || intl.timeZone).format('zz'),
    tzOffset: momentTz.tz(momentTz(), timeZone || intl.timeZone).format('Z'),
  })

/**
 * Assigns i18n data to Intl
 *
 * @param countryCode
 * @param currencyCode
 * @param currencySymbol
 * @param messages
 * @returns {function(intl: *): intl}
 */
const intlWithCountryI18nData =
  ({ countryCode, currencyCode, currencySymbol, messages }) =>
  intl =>
    Object.assign(intl, {
      currencyCode,
      currencySymbol,
      countryCode,
      messages,
    })

/**
 * Creates default Internationalization object with memoized formatters
 *
 * @returns {*}
 */
const createDefaultIntl = () => {
  const i18nData = getCountryI18nData(config.region)
  const { locale, timeZone, countryCode, messages } = i18nData

  moment.locale(locale)
  moment.tz.setDefault(timeZone)

  return flow(
    intlWithTZAbbrOffset(timeZone),
    intlWithCountryI18nData(i18nData),
  )(
    _createIntl(
      {
        locale,
        timeZone,
        countryCode,
        messages,
      },
      intlCache,
    ),
  )
}

// eslint-disable-next-line import/no-mutable-exports
export let currentIntl = createDefaultIntl() // default intl to UK

/**
 * Creates Intl object from countryCode, currencyCode & timeZone
 *
 * @param countryCode
 * @param currencyCode
 * @param timeZone
 * @param localeCountryCode
 * @returns {*}
 */
export const createIntl = async ({ countryCode, currencyCode, timeZone, localeCountryCode }) => {
  const i18nData = Object.assign({}, getCountryI18nData(countryCode), { countryCode, currencyCode })
  const locale = get(getCountryI18nData(localeCountryCode), 'locale', currentIntl.locale)
  // create new Intl object in cache only if has different key values
  if (locale !== currentIntl.locale || timeZone !== currentIntl.timeZone) {
    // load moment locale package safely for the selected locale
    try {
      await import(`moment/locale/${locale.toLowerCase()}`)
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(`Can not find locale data for ${locale}.`)
    }

    moment.locale(locale)
    moment.tz.setDefault(timeZone)

    currentIntl = flow(intlWithTZAbbrOffset(timeZone))(
      _createIntl({ locale, timeZone, messages: i18nData.messages }, intlCache),
    )
  }

  // update current Intl object with additional i18n data values
  if (currentIntl.countryCode !== countryCode || currentIntl.currencyCode !== currencyCode) {
    currentIntl = flow(intlWithCountryI18nData(i18nData))(currentIntl)
  }

  return currentIntl
}

export default currentIntl
