// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { isArray, get } from 'lodash-es'

import { entityList$ } from 'syft-acp-core/reducers/generators/entities'

/**
 * Simple component that displays a manager by name from ID (or array of IDs).
 */
class ManagerDisplay extends React.PureComponent {
  static propTypes = {
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.arrayOf(PropTypes.number)]).isRequired,
    managers: PropTypes.array.isRequired,
  }

  // Returns either the empty placeholder, or the name of the manager by ID.
  // If an array was passed, the manager names will be comma separated.
  getValue = value => {
    if (!value) return '–'
    return isArray(value) ? value.map(v => this.idToManager(v)).join(', ') : this.idToManager(value)
  }

  // Returns a manager's name by ID, or the string '(unknown: 123)' if they aren't in our list.
  idToManager = id => {
    const { managers } = this.props
    return get(
      managers.find(m => m.id === id),
      'name',
      `(unknown: ${id})`
    )
  }

  render() {
    const { value } = this.props
    return <div className="ManagerDisplay">{this.getValue(value)}</div>
  }
}

export default connect(({ managers }) => ({
  managers: entityList$(managers, {}),
}))(ManagerDisplay)
