import { get, memoize } from 'lodash-es'
import { createSelector } from 'reselect'

import { DefaultRootState } from 'react-redux'

/**
 * Selects the areas of a specific venue.
 *
 * For example, use as follows (in a connect()'s first argument):
 * venueAreas: selectVenueAreas$(state.employerVenues)(ownProps.venueID)
 */
export const selectVenueAreas$ = createSelector(
  (state: DefaultRootState['employerVenues']) => state,
  // Retrieve the areas for the requested venue.
  store => memoize((venueID: number) => get(store.entityDetail, `${venueID}.areas`) || [])
)
