// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import React from 'react'
import PropTypes from 'prop-types'

import './RecordHeaderForm.css'

const RecordHeaderForm = ({ children, ...rest }) => {
  return (
    <div className="record-header-form" {...rest}>
      {children}
    </div>
  )
}
RecordHeaderForm.propTypes = {
  children: PropTypes.node.isRequired,
}

export default RecordHeaderForm
