import { takeLatestApiCall } from 'syft-acp-core/sagas/helpers'
import * as types from './action-types'
import * as api from './api'
import { takeLatest } from 'redux-saga/effects'
import { notificationCall } from 'syft-acp-core/sagas/calls'

export default function* saga() {
  yield takeLatestApiCall(types.ADHOC_SHIFT_APPROVE_BEGIN, api.approveAdhocShift)
  yield takeLatestApiCall(types.ADHOC_SHIFT_REJECT_BEGIN, api.rejectAdhocShift)
  yield takeLatestApiCall(types.ADHOC_SHIFT_FETCH_BEGIN, api.fetchAdhocShiftStatus)

  yield takeLatest(
    [
      types.ADHOC_SHIFT_FETCH_FAILED,
      types.ADHOC_SHIFT_APPROVE_SUCCEEDED,
      types.ADHOC_SHIFT_APPROVE_FAILED,
      types.ADHOC_SHIFT_REJECT_SUCCEEDED,
      types.ADHOC_SHIFT_REJECT_FAILED,
    ],
    notificationCall,
  )
}
