import { put, call, takeLatest } from 'redux-saga/effects'

import * as types from 'syft-acp-core/actions/action-types'
import { showDesktopNotification } from 'syft-acp-core/lib/notifications'
import {
  requestNotificationPermission,
  enableNotificationsGloballyIfUnset,
  setDesktopNotifications,
} from 'syft-acp-core/actions/notifications'
import { store } from 'syft-acp-core/store'

// Display a single desktop notification.
const displayNotification = action => {
  showDesktopNotification(action.payload)
}

// Check for permissions if we are enabling desktop notifications.
export function* checkPermissions({ enabled }) {
  if (enabled) {
    yield put(requestNotificationPermission())
  } else {
    yield put(setDesktopNotifications(false))
  }
}

const setRequestNotificationPermission = async () => {
  // Disregard on devices that do not support notifications.
  if (!window.Notification) return

  if (Notification.permission === 'granted') {
    // If we've already received permission, set to true right away.
    store.dispatch(setDesktopNotifications(true))
  } else {
    // Otherwise, ask for permission.
    const permission = (await Notification.requestPermission()) === 'granted'
    store.dispatch(setDesktopNotifications(permission))
  }
}

const enableNotificationsGloballyIfUnsetFn = () => {
  // Turn on notifications if unset (i.e. the user hasn't decided yet).
  store.dispatch(enableNotificationsGloballyIfUnset())
}

export default function* root() {
  // On startup, request permission to use desktop notifications.
  yield call(setRequestNotificationPermission)
  yield call(enableNotificationsGloballyIfUnsetFn)
  yield takeLatest(types.DESKTOP_NOTIFICATION_SHOW, displayNotification)
  yield takeLatest(types.DESKTOP_NOTIFICATIONS_TOGGLE, checkPermissions)
  yield takeLatest(types.DESKTOP_NOTIFICATIONS_REQ_PERMISSION, setRequestNotificationPermission)
}
