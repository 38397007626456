import { uniqBy, merge } from 'lodash-es'

/**
 * Returns the array converted with the unique prop identifier
 */
const convertArrayToUniqueProp = (data, prop) =>
  data.map(item => ({ id: item.id, title: item.title, [prop]: item.percentage }))

/**
 * Returns the concat of array with unique properties depends on
 * good or bad reasons for separation data to two tables
 */
const concatReasons = (data, good) => {
  if (good) {
    const fourth = data[4] || []
    const fifth = data[5] || []
    return [...convertArrayToUniqueProp(fourth, 'fourth'), ...convertArrayToUniqueProp(fifth, 'fifth')]
  }
  const first = data[1] || []
  const second = data[2] || []
  const third = data[3] || []
  return [
    ...convertArrayToUniqueProp(first, 'first'),
    ...convertArrayToUniqueProp(second, 'second'),
    ...convertArrayToUniqueProp(third, 'third'),
  ]
}

/**
 * Returns the array with unique objects with the unique props
 * for the good and bad reasons - tables `EmployerCsatResultList` and `WorkerCsatResultList`
 */
const convertReasons = (data, good) => {
  if (!data) return []

  const concatedReasons = concatReasons(data, good)
  const uniqueGoodReasonsMerged = concatedReasons.map(item => {
    const reasons = concatedReasons.filter(reason => reason.title === item.title)
    return merge(...reasons)
  })
  return uniqBy(uniqueGoodReasonsMerged, 'title')
}

export { convertReasons }

/**
 * Map url paths with the csat type
 */
export const paths = {
  workerCsat: 'workerCsat',
  employerCsat: 'employerCsat',
  agencyCsat: 'agencyCsat',
}

/**
 * Map the csat type with the acp resource name
 */
export const apiCsat = {
  workerCsat: 'csatWorkers',
  employerCsat: 'csatEmployers',
  agencyCsat: 'csatAgencies',
}

/**
 * Map csat path type
 */
export const csatPathType = {
  [paths.workerCsat]: 'workers',
  [paths.employerCsat]: 'employers',
  [paths.agencyCsat]: 'agencies',
}

/**
 * Map csat path for CSV
 */
export const csatCsvType = {
  [paths.workerCsat]: 'worker_csat',
  [paths.employerCsat]: 'employer_csat',
  [paths.agencyCsat]: 'agency_csat',
}

export const csatPageTitle = {
  [paths.workerCsat]: 'Worker Csat',
  [paths.employerCsat]: 'Employer Csat',
  [paths.agencyCsat]: 'Agency Csat',
}
