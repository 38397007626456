// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import entityReducer from 'syft-acp-core/reducers/generators/entityReducer'
import * as entityTypes from 'syft-acp-core/reducers/generators/entities'

import * as types from '../action-types'
import { EmployerVenueEntity } from './types'

export default entityReducer<EmployerVenueEntity>(
  'employerVenues',
  {
    [entityTypes.ENTITIES_FETCH_BEGIN]: types.EMPLOYER_VENUES_FETCH_BEGIN,
    [entityTypes.ENTITIES_FETCH_SUCCEEDED]: types.EMPLOYER_VENUES_FETCH_SUCCEEDED,
    [entityTypes.ENTITIES_FETCH_FAILED]: types.EMPLOYER_VENUES_FETCH_FAILED,
    [entityTypes.ENTITY_FETCH_BEGIN]: types.EMPLOYER_VENUE_FETCH_BEGIN,
    [entityTypes.ENTITY_FETCH_SUCCEEDED]: types.EMPLOYER_VENUE_FETCH_SUCCEEDED,
    [entityTypes.ENTITY_FETCH_FAILED]: types.EMPLOYER_VENUE_FETCH_FAILED,
    [entityTypes.ENTITY_SAVE_BEGIN]: types.EMPLOYER_VENUE_SAVE_BEGIN,
    [entityTypes.ENTITY_SAVE_SUCCEEDED]: types.EMPLOYER_VENUE_SAVE_SUCCEEDED,
    [entityTypes.ENTITY_SAVE_FAILED]: types.EMPLOYER_VENUE_SAVE_FAILED,
    [entityTypes.ENTITY_MODIFY_BEGIN]: types.EMPLOYER_VENUE_SAVE_BEGIN,
    [entityTypes.ENTITY_MODIFY_SUCCEEDED]: types.EMPLOYER_VENUE_SAVE_SUCCEEDED,
    [entityTypes.ENTITY_MODIFY_FAILED]: types.EMPLOYER_VENUE_SAVE_FAILED,
    [entityTypes.ENTITY_DELETE_BEGIN]: types.EMPLOYER_VENUE_DELETE_BEGIN,
    [entityTypes.ENTITY_DELETE_SUCCEEDED]: types.EMPLOYER_VENUE_DELETE_SUCCEEDED,
    [entityTypes.ENTITY_DELETE_FAILED]: types.EMPLOYER_VENUE_DELETE_FAILED,
  },
  {
    idKey: 'id',
    localEntityMap: true,
  }
)
