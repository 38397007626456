import { takeLatest } from 'redux-saga/effects'

import { takeLatestApiCall } from 'syft-acp-core/sagas/helpers'
import { notificationCall, redirectCall } from 'syft-acp-core/sagas/calls'

import { getAppealDetailPath } from './helpers'
import * as types from './action-types'
import * as api from './api'

export default function* saga() {
  yield takeLatestApiCall(types.APPEALS_FETCH_BEGIN, api.listAppeals)
  yield takeLatestApiCall(types.APPEAL_FETCH_BEGIN, api.fetchAppeal)
  yield takeLatestApiCall(types.APPEALS_ASSIGN_BEGIN, api.assignAppeals)
  yield takeLatestApiCall(types.APPEALS_ASSIGNEES_FETCH_BEGIN, api.fetchAssignees)
  yield takeLatestApiCall(types.APPEAL_CREATE_BEGIN, api.createAppeal)
  yield takeLatestApiCall(types.APPEAL_UPDATE_BEGIN, api.updateAppeal)
  yield takeLatestApiCall(types.APPEAL_REASONS_FETCH_BEGIN, api.fetchAppealReasons)
  yield takeLatest(
    [
      types.APPEALS_ASSIGN_FAILED,
      types.APPEAL_CREATE_FAILED,
      types.APPEAL_CREATE_SUCCEEDED,
      types.APPEAL_UPDATE_FAILED,
      types.APPEAL_UPDATE_SUCCEEDED,
    ],
    notificationCall
  )
  yield takeLatest(types.APPEAL_CREATE_SUCCEEDED, redirectCall, ({ payload: { id } }: { payload: { id: number } }) =>
    getAppealDetailPath(id)
  )
}
