import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { ControlLabel, FormGroup, Row, Col } from 'react-bootstrap'

import { getModal$ } from 'syft-acp-core/store/modals/selectors'
import { Modal } from 'syft-acp-core/components/Modal'
import DataDate from 'syft-acp-core/components/EditableTable/DataDate'
import DataText from 'syft-acp-core/components/EditableTable/DataText'
import DataMultipleManagersDropdown from 'syft-acp-core/components/EditableTable/DataMultipleManagersDropdown'
import { hideModal } from 'syft-acp-core/store/modals/actions'
import { notify } from 'syft-acp-core/actions/notifications'
import DataEnum from 'syft-acp-core/components/EditableTable/DataEnum'
import DataDropdown from 'syft-acp-core/components/EditableTable/DataDropdown'
import DataIndustriesDropdown from 'syft-acp-core/components/EditableTable/DataIndustriesDropdown'
import { formatDatetime8601Tz } from 'syft-acp-util/formatting'
import { createCommission } from 'syft-acp-core/api/resources/admin/commissions'

import { managerPositionReasons, subcategories } from './modalCommissionsSchema'

class ManagerCommissionModal extends PureComponent {
  static propTypes = {
    employerId: PropTypes.string.isRequired,
    item: PropTypes.object.isRequired,
    dispatch: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props)
    this.initialState = {
      industry_id: null,
      syft_manager_position: 'AM',
      errorText: null,
      syft_manager_ids: null,
      subindustry: null,
      city_id: null,
      effective_time_from: null,
      sales_force_id: null,
    }
    this.state = this.initialState
  }

  onConfirmHandler = () => {
    const isValid = this.validate()
    const { dispatch } = this.props

    if (isValid) {
      const { effective_time_from, subindustry, ...rest } = this.state
      const subindustryObject = subindustry
        ? { subindustry: subcategories[subindustry - 1].value }
        : { subindustry: '' }
      const effectiveTimeObject = effective_time_from
        ? { effective_time_from: formatDatetime8601Tz(effective_time_from) }
        : { effective_time_from: '' }
      const data = {
        ...effectiveTimeObject,
        ...subindustryObject,
        sales_win_association_id: this.props.item.options.id,
        employer_id: parseInt(this.props.employerId, 10),
        ...rest,
      }
      createCommission({ options: data })
        .then(() => {
          dispatch(
            notify('success', {
              title: 'Success',
              message: 'The commission was successfully created',
            }),
          )
          this.props.item.options.onConfirm(data)
        })
        .catch(error => {
          const message = error.body.error_description
          dispatch(
            notify('error', {
              title: 'Error',
              message,
            }),
          )
        })
      this.closeModal()
    }
  }

  validate = () => {
    if (!this.state.industry_id) {
      this.setState({ valid: false, errorText: 'The industry is required!' })
      return false
    }
    if (!this.state.syft_manager_ids) {
      this.setState({ valid: false, errorText: 'The manager is required!' })
      return false
    }

    this.setState({ valid: true, msg: null })
    return true
  }

  closeModal = () => {
    this.props.dispatch(hideModal(this.props.item.modalName))
    this.setState(this.initialState)
  }

  checkConfirm = ev => {
    if (ev.key === 'Enter') {
      this.onConfirmHandler()
    }
  }

  onChangeValue = name => data => {
    const value = name === 'industry_id' ? Number(data) : data
    if (value !== 'apply_to_children' && name === 'stop_action') {
      this.setState(() => ({ [name]: value, city_id: 0 }))
    } else {
      this.setState(() => ({ [name]: value }))
    }
  }

  render() {
    const labelWidth = 3
    const fieldWidth = 9
    return (
      <Modal
        header={`Add New Commission (${this.props.item.options.targetType}, ID: ${this.props.item.options.id})`}
        isShown={this.props.item.isShown}
        onClose={this.closeModal}
        onConfirm={this.onConfirmHandler}
        errorText={this.state.errorText || null}
        confirmationText="Submit"
      >
        <div className="missing-time-modal">
          <FormGroup className="form-item">
            <Row>
              <Col md={labelWidth}>
                <ControlLabel>Industry</ControlLabel>
              </Col>
              <Col md={fieldWidth}>
                <DataIndustriesDropdown
                  onChange={this.onChangeValue('industry_id')}
                  id="industry_id"
                  name="industry_id"
                  queryKey="industry_id"
                  value={this.state.industry_id}
                  options={{ idKey: 'id' }}
                  allowAny
                />
              </Col>
            </Row>
            <Row>
              <Col md={labelWidth}>
                <ControlLabel>Subindustry</ControlLabel>
              </Col>
              <Col md={fieldWidth}>
                <DataDropdown
                  name="subindustry"
                  onChange={this.onChangeValue('subindustry')}
                  editable={this.state.industry_id === 1}
                  value={this.state.subindustry}
                  options={subcategories}
                  showDisabled
                />
              </Col>
            </Row>
            <Row>
              <Col md={labelWidth}>
                <ControlLabel htmlFor="syft_manager_ids">Manager</ControlLabel>
              </Col>
              <Col md={fieldWidth}>
                <DataMultipleManagersDropdown
                  name="syft_manager_ids"
                  onChange={this.onChangeValue('syft_manager_ids')}
                  editable
                  allowAny
                />
              </Col>
            </Row>
            <Row>
              <Col md={labelWidth}>
                <ControlLabel>Effective date</ControlLabel>
              </Col>
              <Col md={fieldWidth}>
                <DataDate
                  value={this.state.effective_time_from}
                  onChange={this.onChangeValue('effective_time_from')}
                  editable
                  enabled
                />
              </Col>
            </Row>
            <Row>
              <Col md={labelWidth}>
                <ControlLabel>Salesforce id</ControlLabel>
              </Col>
              <Col md={fieldWidth}>
                <DataText
                  value={this.state.sales_force_id}
                  onChange={this.onChangeValue('sales_force_id')}
                  editable
                  enabled
                />
              </Col>
            </Row>
          </FormGroup>
          <FormGroup className="form-item">
            <Row>
              <Col md={labelWidth}>
                <ControlLabel>Manager position</ControlLabel>
              </Col>
              <Col md={fieldWidth}>
                <DataEnum
                  editable
                  options={managerPositionReasons}
                  componentValue={this.state.syft_manager_position}
                  onChange={this.onChangeValue('syft_manager_position')}
                />
              </Col>
            </Row>
          </FormGroup>
        </div>
      </Modal>
    )
  }
}

export default connect(state => ({
  item: getModal$(state.modals)('managerCommissionModal'),
}))(ManagerCommissionModal)
