import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { ControlLabel, HelpBlock, FormGroup } from 'react-bootstrap'

import { withTrackingTriggerHoc } from 'syft-acp-util/withTrackingTriggerHoc'
import { Button } from 'syft-acp-atoms/Button'
import { getModal$ } from 'syft-acp-core/store/modals/selectors'
import { Modal } from 'syft-acp-core/components/Modal'
import { showModal, hideModal } from 'syft-acp-core/store/modals/actions'
import { deleteAdminManager } from 'syft-acp-core/actions/managers'
import DataManagerDropdown from 'syft-acp-core/components/EditableTable/DataManagerDropdown'
import { trackingEvents } from './ManagerDetail.tracking'

class ManagerDeleteButton extends PureComponent {
  static propTypes = {
    id: PropTypes.number.isRequired,
    dispatch: PropTypes.func.isRequired,
    item: PropTypes.object.isRequired,
    triggerEvent: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props)
    this.state = { valid: true }
  }

  setManagerID = assignedManagerID => {
    const isCurrentManager = Number(assignedManagerID) === Number(this.props.id)
    const valid = !isCurrentManager
    const msg = "Current manager can't be selected"

    this.setState({ assignedManagerID, valid, msg })
  }

  deleteManager = () => {
    const { id, dispatch, triggerEvent } = this.props
    const { valid, assignedManagerID } = this.state
    const params = { assign_employees_to: assignedManagerID }

    if (valid && assignedManagerID) {
      this.closeModal()
      dispatch(deleteAdminManager(id, params))
      triggerEvent(trackingEvents.DELETE_BUTTON.CLICKED, {
        assigned_manager_id: this.state.assignedManagerID,
      })
    } else {
      this.setState({ valid: false })
    }
  }

  closeModal = () => this.props.dispatch(hideModal(this.props.item.modalName))

  openModal = () => this.props.dispatch(showModal(this.props.item.modalName))

  render() {
    return (
      <div>
        <Modal
          header="Delete manager"
          isShown={this.props.item.isShown}
          onClose={this.closeModal}
          onConfirm={this.deleteManager}
          confirmationText="Delete"
        >
          <FormGroup validationState={this.state.valid ? null : 'error'}>
            <ControlLabel>Reassign employers to:</ControlLabel>
            <DataManagerDropdown onChange={id => this.setManagerID(id)} value={this.state.assignedManagerID} editable />
            {!this.state.valid && <HelpBlock>{this.state.msg || 'Select manager'}</HelpBlock>}
          </FormGroup>
        </Modal>

        <Button kind="danger" onClick={this.openModal}>
          Delete
        </Button>
      </div>
    )
  }
}

export default connect(state => ({
  item: getModal$(state.modals)('deleteManager'),
}))(withTrackingTriggerHoc(ManagerDeleteButton))
