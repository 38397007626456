import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { get } from 'lodash-es'
import { FormControl, InputGroup } from 'react-bootstrap'

import EntityListInputWrapper from 'syft-acp-core/components/EntityList/EntityListInputWrapper'

import './ListingBreakInput.css'

export class ListingBreakInput extends PureComponent {
  static propTypes = {
    onChange: PropTypes.func.isRequired,
    attr: PropTypes.string.isRequired,
    shiftBooking: PropTypes.object.isRequired,
    disabled: PropTypes.bool,
  }

  static defaultProps = {
    disabled: false,
  }

  handleOnChange = ev => {
    const { attr, shiftBooking, onChange } = this.props
    const { id, shiftID } = shiftBooking
    const val = Number(ev.target.value) * 60
    onChange({ shiftID, id, attr, val })
  }

  render() {
    const { attr, shiftBooking } = this.props

    const val = Number(get(shiftBooking, attr, 0))
    const value = String(val / 60)
    return (
      <EntityListInputWrapper>
        <InputGroup className="listing-break-input-group">
          <FormControl type="text" value={value} onChange={this.handleOnChange} disabled={this.props.disabled} />
          <InputGroup.Addon>min.</InputGroup.Addon>
        </InputGroup>
      </EntityListInputWrapper>
    )
  }
}
