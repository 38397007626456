import { apiRequest, handleCall } from 'syft-acp-core/api/call'

export const bulkEditAdminTimesheets = ({ payload: reqArgs }) =>
  new Promise((resolve, reject) => {
    const req = apiRequest({ path: '/admin/timesheets/bulk_edit', reqArgs, method: 'PUT' })
    handleCall(req, resolve, reject, true, { shiftID: reqArgs.entries[0].shift_id })
  })

export const editAllAdminTimesheets = ({ payload: reqArgs }) =>
  new Promise((resolve, reject) => {
    const req = apiRequest({ path: '/admin/timesheets/edit_all', reqArgs, method: 'PUT' })
    handleCall(req, resolve, reject)
  })
