import * as React from 'react'

import PageView from 'syft-acp-core/components/PageView'
import UnfilledShiftsList from 'syft-acp-core/entities2/ShiftFulfilmentList'

export const ShiftsFulfilment = () => (
  <PageView title={['Shifts', 'Fulfilment']} entityPage>
    <UnfilledShiftsList />
  </PageView>
)
