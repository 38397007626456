import { result } from 'lodash-es'

import { store } from 'syft-acp-core/store'
import { showConfirmModal } from 'syft-acp-core/store/modals/actions'

/**
 * Adds a confirmation modal that pops up when calling a function.
 * The original function call will only go through if the user confirms
 * the modal window first.
 */
export const addConfirmLayer = (fn, options) => () => {
  store.dispatch(
    showConfirmModal({
      question: result(options, 'confirmText') || 'Confirm this action',
      modalConfirm: result(options, 'confirmYes') || 'OK',
      modalCancel: result(options, 'confirmNo') || 'Cancel',
      modalHeader: result(options, 'confirmHeader') || 'Confirmation',
      onConfirm: fn,
    })
  )
}
