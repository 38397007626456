// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { get } from 'lodash-es'

import './RoleSkillDisplay.css'

class SkillDisplay extends React.PureComponent {
  static propTypes = {
    id: PropTypes.number.isRequired,
    data: PropTypes.object.isRequired,
    roles: PropTypes.object.isRequired,
  }

  render() {
    const { id, data, roles } = this.props
    const roleID = get(data, 'role_id')
    const skill = roles[roleID] ? roles[roleID].skills?.find(s => s.id === id) : null
    return <div className="RoleSkillDisplay">{skill ? skill.title : '–'}</div>
  }
}

export default connect(state => ({
  roles: state.roles.entityMap,
}))(SkillDisplay)
