import * as React from 'karet'
import * as R from 'kefir.ramda'
import * as U from 'karet.util'
import * as L from 'kefir.partial.lenses'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { useTrackingTrigger } from '@indeed/flex-tracking-context'

import { stringify } from 'syft-acp-core/store/filters/helpers'
import { getEmployerName } from 'syft-acp-util/formatting'
import {
  salesforcePricingData,
  salesforcePlanData,
  salesforcePeriodData,
  savePromotionData,
  loadPricingPlans,
  loadPromotionData,
  addPromotionPlan,
  addPromotionPeriod,
  selectPromotionPlan,
  selectPromotionPeriod,
} from '../EmployerSalesForceList/data'

import SalesForceViewPure from './SalesForceViewPure'
import { trackingEvents } from './EmployerSalesForceView.tracking'

// FIXME FIXME FIXME FIXME FIXME FIXME FIXME FIXME
const NO_SELECTION = 999

// Finds the item for the current sales win ID.
const findSalesWin = salesforceID => R.find(R.propEq('sales_win_id', salesforceID))

/**
 * Connected SalesForce component for editing promotional plans and periods.
 */
const SalesForceViewConnected = ({ employerName, employerID, salesforceID }) => {
  const triggerEvent = useTrackingTrigger()
  // Start loading necessary data.
  loadPricingPlans()
  loadPromotionData(salesforceID)

  // Bind data manipulation functions to the current SalesForce ID.
  const fnAddPromotionPlan = addPromotionPlan(salesforceID)
  const fnAddPromotionPeriod = addPromotionPeriod(salesforceID)
  const fnSelectPromotionPlan = selectPromotionPlan(salesforceID)
  const fnSelectPromotionPeriod = selectPromotionPeriod(salesforceID)
  const fnSavePromotionData = savePromotionData(salesforceID)

  // Set to 'no selection' by default.
  const fnDeselectPlan = () => fnSelectPromotionPlan({ rowN: NO_SELECTION })
  fnDeselectPlan()
  fnSelectPromotionPlan({ rowN: 0 })
  fnSelectPromotionPeriod({ rowN: NO_SELECTION })

  // Select general SalesForce data.
  const dataSalesForceAll = U.view([stringify({ employerID }), 'reqResult', `data`, L.defaults([])], salesforcePlanData)
  const dataSalesForce = findSalesWin(salesforceID)(dataSalesForceAll)
  const entityType = L.get(['target_type', L.defaults('')], dataSalesForce)
  const entityName = L.get(['target', 'name', L.defaults(`ID #${salesforceID}`)], dataSalesForce)

  // Select promotion plan data.
  const dataPlansCall = U.view([stringify({ salesWinID: salesforceID })], salesforcePeriodData)
  const dataPlansResult = U.view(['reqResult'], dataPlansCall)
  const dataPlansIsLoading = U.view(['isLoading'], dataPlansCall)
  const dataPlans = U.view([`data`, L.defaults([])], dataPlansResult)
  const dataPlansMeta = U.view([`meta`], dataPlansResult)
  const selectedPlan = U.view(['planSelection', 'rowN', L.defaults(NO_SELECTION)], dataPlansResult)

  // Select promotion period data.
  const dataPeriods = U.view([selectedPlan, 'pricing_promotions', L.defaults([])], dataPlans)
  const selectedPeriod = U.view(['periodSelection', 'rowN', L.defaults(NO_SELECTION)], dataPlansResult)

  // Other data needed to render the component.
  const dataPricing = U.view(['reqResult', 'data'], salesforcePricingData)
  const linkSalesForce = `/entity/employers/view/${employerID}/salesforce/list`

  return (
    <SalesForceViewPure
      // Basic information about the employer/venue currently being edited.
      entityEmployerName={employerName}
      entityType={entityType}
      entityName={entityName}
      // Promotion plan data (left)
      dataPlans={dataPlans}
      dataPlansMeta={dataPlansMeta}
      selectedPlan={selectedPlan}
      addPromotionPlan={() => {
        fnAddPromotionPlan()
        triggerEvent(trackingEvents.NEW_PROMOTION_PLAN.CLICKED)
      }}
      selectPromotionPlan={fnSelectPromotionPlan}
      deselectPromotionPlan={fnDeselectPlan}
      // Promotion period data (right)
      dataPeriods={dataPeriods}
      selectedPeriod={selectedPeriod}
      addPromotionPeriod={() => {
        fnAddPromotionPeriod()
        triggerEvent(trackingEvents.ADD_PROMOTION_PERIOD.CLICKED)
      }}
      selectPromotionPeriod={fnSelectPromotionPeriod}
      // Pricing types data for the dropdown menu.
      dataPricing={dataPricing}
      dataSalesForce={dataSalesForce}
      // Saves all current data to the server.
      savePromotionData={() => {
        fnSavePromotionData()
        triggerEvent(trackingEvents.SAVE.CLICKED)
      }}
      isLoading={dataPlansIsLoading}
      backLink={linkSalesForce}
    />
  )
}

SalesForceViewConnected.propTypes = {
  salesforceID: PropTypes.number.isRequired,
  employerID: PropTypes.number.isRequired,
  employerName: PropTypes.string,
}

SalesForceViewConnected.defaultProps = {
  employerName: null,
}

export default connect((state, ownProps) => ({
  employerName: getEmployerName(state.employers.entityMap[ownProps.employerID]),
}))(SalesForceViewConnected)
