// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import * as types from '../actions/action-types'

const initialState = {
  employers: [],
}

const reducer = (state = initialState, action) => {
  const { id, url } = action
  switch (action.type) {
    case types.REHYDRATION_COMPLETE:
      return {
        ...state,
        employers: {
          ...state.employers,
          // Ensure all items are set to not loading.
          // eslint-disable-next-line no-shadow
          ...Object.keys(state.employers).reduce((acc, employerId) => {
            return { ...acc, [employerId]: { ...state.employers[employerId], loading: false } }
          }, {}),
        },
      }
    case types.EMPLOYER_TOKEN_FETCH_BEGIN:
      return {
        ...state,
        employers: {
          ...state.employers,
          [id]: {
            ...state.employers[id],
            loading: true,
          },
        },
      }
    case types.EMPLOYER_TOKEN_SAVE:
      return {
        ...state,
        employers: {
          ...state.employers,
          [id]: {
            ...state.employers[id],
            url,
            created: +new Date(),
            loading: false,
          },
        },
      }
    default:
      return state
  }
}

export default reducer
