import React, { useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import { useIntl } from 'react-intl'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { Modal } from 'syft-acp-core/components/Modal'
import { getModal$ } from 'syft-acp-core/store/modals/selectors'
import { hideModal } from 'syft-acp-core/store/modals/actions'
import { selectWorkerHolidayPayHistoricRequests } from 'syft-acp-core/store/worker-holiday-pay/selectors'
import { WorkerHolidayPayHistoricRequestsEntity } from 'syft-acp-core/store/types'
import { filterSelect } from 'syft-acp-util/components/FilterForm/FilterSelect'
import { deleteWorkerHolidayPayHistoricRequest } from 'syft-acp-core/store/worker-holiday-pay/actions'

import {
  DeleteHistoricHolidayPayModalProps as Props,
  DeleteHistoricHolidayPayModalOwnProps as OwnProps,
} from './DeleteHistoricHolidayPayModal.types'

export const storeConnector = connect(
  (state, props: OwnProps) => ({
    modal: getModal$(state.modals)(props.modalName),
    isLoading: state.workerHolidayPay.isLoadingHistoricRequests,
    requests: selectWorkerHolidayPayHistoricRequests(state)(props.workerID),
  }),
  dispatch => ({
    actions: {
      ...bindActionCreators(
        {
          hideModal,
          deleteWorkerHolidayPayHistoricRequest,
        },
        dispatch
      ),
    },
  })
)

const DeleteHistoricHolidayPayModal = ({ actions, modal, modalName, requests, isLoading, workerID }: Props) => {
  const intl = useIntl()
  const [requestID, setRequestID] = useState<number>()

  const closeModal = () => {
    setRequestID(undefined)
    actions.hideModal(modalName)
  }

  const onConfirm = () => {
    if (requestID) {
      actions.deleteWorkerHolidayPayHistoricRequest(requestID, workerID)
      closeModal()
    }
  }

  const canSubmit = () => {
    if (isLoading || !requestID) return false
    return true
  }

  const mapRequests = (data: WorkerHolidayPayHistoricRequestsEntity[]) =>
    data
      .filter(({ deletable }) => deletable)
      .map(({ id, created_at }) => ({
        id,
        label: `${id} - ${intl.formatDate(created_at)}`,
      }))

  const FilterSelect = filterSelect(mapRequests(requests), 'Choose one', 'id', false)

  return (
    <Modal
      header="Delete historic holiday pay request"
      isShown={modal.isShown}
      isLoading={isLoading}
      onClose={closeModal}
      onConfirm={onConfirm}
      canSubmit={canSubmit()}
      cancelText="Cancel"
      confirmationText="Submit"
    >
      <Row>
        <Col sm={3}>Request id:</Col>
        <Col sm={9}>
          <FilterSelect value={requestID} onChange={setRequestID} />
        </Col>
      </Row>
    </Modal>
  )
}

export default storeConnector(DeleteHistoricHolidayPayModal)
