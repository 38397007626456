import * as modalActions from 'syft-acp-core/store/modals/actions'
import { store } from 'syft-acp-core/store'

export const closeAndResolve =
  (modalName, resolve, onClose = null) =>
  data => {
    store.dispatch(modalActions.hideModal(modalName))
    if (onClose) {
      onClose()
    }
    resolve(data)
  }

// TODO: document.
export const showModal = (modalName, options = {}, onClose = null, data = {}) =>
  new Promise(resolve => {
    store.dispatch(modalActions.showModal(modalName, options, closeAndResolve(modalName, resolve, onClose), data))
  })
