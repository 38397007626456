// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import * as alertsActions from 'syft-acp-core/store/alerts/actions'

import AlertsContainer from './AlertsContainer'

class Alerts extends Component {
  static propTypes = {
    isLoadingData: PropTypes.bool.isRequired,
    hasData: PropTypes.bool.isRequired,
    lastMessage: PropTypes.string.isRequired,
    lastUpdated: PropTypes.string.isRequired,
    employerID: PropTypes.number.isRequired,
    filter: PropTypes.object.isRequired,
    entityMap: PropTypes.shape({
      id: PropTypes.shape({
        id: PropTypes.number.isRequired,
        type: PropTypes.string.isRequired,
        message: PropTypes.string.isRequired,
        resource_type: PropTypes.string.isRequired,
        resource_id: PropTypes.number.isRequired,
        created_at: PropTypes.string.isRequired,
        employer_id: PropTypes.number.isRequired,
      }),
    }).isRequired,
    entityList: PropTypes.arrayOf(PropTypes.number).isRequired,
  }

  constructor(props) {
    super(props)
    this.state = {
      search: '',
      matchingIDs: [],
    }
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    this.runSearch(this.state.search, newProps)
  }

  componentDidMount() {
    // Fetch alerts. This is also done automatically periodically.
    this.props.actions.fetchAlerts(null, this.props.employerID)
    this.runSearch(this.state.search)
  }

  runSearch = (val = '', props = this.props) => {
    // Update list of matching alerts.
    const { employerID } = props
    const alerts = props.entityMap
    const valLc = val.toLowerCase()
    const newState = {
      search: val,
      matchingIDs: Object.keys(alerts)
        .filter(id => alerts[id].message.toLowerCase().indexOf(valLc) > -1)
        .filter(id => (employerID ? alerts[id].employer_id === employerID : id))
        .sort()
        .reverse(),
    }
    this.setState(newState)
  }

  render() {
    const { hasData, entityMap, filter, employerID, isLoadingData, lastUpdated } = this.props
    const alertItems = this.state.matchingIDs.map(id => entityMap[id])

    return (
      <AlertsContainer
        searchValue={this.state.search}
        employerID={employerID}
        filter={filter}
        isLoading={isLoadingData}
        hasData={hasData}
        lastUpdated={lastUpdated}
        runSearch={this.runSearch}
        allItems={alertItems}
      />
    )
  }
}

export default connect(
  state => ({
    isLoadingData: state.alerts.isLoadingData,
    hasData: state.alerts.hasData,
    lastMessage: state.alerts.message,
    lastUpdated: state.alerts.lastUpdated,
    entityMap: state.alerts.entityMap,
    entityList: state.alerts.entityList,
  }),
  dispatch => ({
    actions: bindActionCreators(alertsActions, dispatch),
  })
)(Alerts)
