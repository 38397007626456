import { SELECT_TAB_AREA, SELECT_TAB_ROLE, SELECT_TAB_VENUE, RESET_ALL_TABS } from './action-types'

import { DataTabsAction } from './types'

export const initialState = {
  venue_id: undefined,
  area_id: undefined,
  role_id: undefined,
}

export const rehydrateReset = {
  isLoadingData: false,
  message: '',
}

const reducer = (state = initialState, action: DataTabsAction) => {
  switch (action.type) {
    case SELECT_TAB_AREA:
      return {
        ...state,
        area_id: action.id,
      }
    case SELECT_TAB_ROLE:
      return {
        ...state,
        role_id: action.id,
      }
    case SELECT_TAB_VENUE:
      return {
        ...state,
        venue_id: action.id,
      }
    case RESET_ALL_TABS:
      return initialState
    default:
      return state
  }
}

export default reducer
