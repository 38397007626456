import React from 'react'
import { venuesCitiesAutocomplete } from 'syft-acp-core/api/resources/admin/workers'

import FilterAutocomplete from '../FilterAutocomplete'

type OwnProps = {
  name: string
  onChange?: (id: any, query: any) => void
}

const FilterAutocompleteVenuesCities = ({ name, onChange }: OwnProps) => (
  <FilterAutocomplete
    name={name}
    attr="label"
    placeholder="City"
    callback={onChange}
    queryFunction={venuesCitiesAutocomplete}
    forceQueryParamValue
  />
)

export default FilterAutocompleteVenuesCities
