export const trackingEvents = {
  NEW: {
    VIEWED: 'employer_rate_cards_form.new.viewed',
    SUBMITTED: 'employer_rate_cards_form.new.submitted',
  },
  EDIT: {
    VIEWED: 'employer_rate_cards_form.edit.viewed',
    SUBMITTED: 'employer_rate_cards_form.edit.submitted',
  },
  SAVE: {
    CLICKED: 'employer_rate_cards_form.save_button.clicked',
  },
  DISABLE: {
    CLICKED: 'employer_rate_cards_form.disable.clicked',
  },
  ENABLE: {
    CLICKED: 'employer_rate_cards_form.enable.clicked',
  },
  ENABLE_MODAL: {
    VIEWED: 'employer_rate_cards_form.enable_modal.viewed',
  },
  ENABLE_MODAL_OK: {
    CLICKED: 'employer_rate_cards_form.enable_modal_ok.clicked',
  },
  ENABLE_MODAL_CANCEL: {
    CLICKED: 'employer_rate_cards_form.enable_modal_cancel.clicked',
  },
  DISABLE_MODAL: {
    VIEWED: 'employer_rate_cards_form.disable_modal.viewed',
  },
  DISABLE_MODAL_OK: {
    CLICKED: 'employer_rate_cards_form.disable_modal_ok.clicked',
  },
  DISABLE_MODAL_CANCEL: {
    CLICKED: 'employer_rate_cards_form.disable_modal_cancel.clicked',
  }
}

export const tdrTrackingEvents = {
  ADD_TDR: {
    CLICKED: 'employer_time_dependent_rates.add_tdr_button.clicked',
    VIEWED: 'employer_time_dependent_rates.add_tdr_form.viewed',
  },
  EDIT_TDR: {
    CLICKED: 'employer_time_dependent_rates.edit_tdr_button.clicked',
    VIEWED: 'employer_time_dependent_rates.edit_tdr_form.viewed',
  },
  SAVE_TDR: {
    CLICKED: 'employer_time_dependent_rates.save_tdr_button.clicked',
  },
  UPDATE_TDR: {
    CLICKED: 'employer_time_dependent_rates.update_tdr_button.clicked',
  },
}
