// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited
import { takeEvery, takeLatest } from 'redux-saga/effects'
import * as types from 'syft-acp-core/actions/action-types'
import { apiCall } from './calls'

const beginActionTypePattern = /(_BEGIN)$/

const watchApiCall = (takeFn, type, action) => {
  const beginActionType = beginActionTypePattern.test(type) ? type : types[`${type}_BEGIN`]
  const succeededActionType = beginActionType.replace(beginActionTypePattern, '_SUCCEEDED')
  const failedActionType = beginActionType.replace(beginActionTypePattern, '_FAILED')
  return takeFn(beginActionType, apiCall, action, succeededActionType, failedActionType)
}

export const takeEveryApiCall = (type, action) => watchApiCall(takeEvery, type, action)
export const takeLatestApiCall = (type, action) => watchApiCall(takeLatest, type, action)

// Helper to get modified url for SSO
export const withRedirectUrlSearchParam = (redirectUrl, location) => {
  const redirectURL = new URL(redirectUrl)
  redirectURL.searchParams.set('redirect_url', location)
  return redirectURL.toString()
}
