export const enrichCreateBookingParams = (acc, props, changedEntity, jobShiftIDs) => {
  const { shiftID, jobID, worker, provisional } = changedEntity
  const workerID = props?.['worker.id'] ? Number(props['worker.id']) : worker.id

  const attrs = {
    shift_ids: [shiftID],
    job_id: jobID,
    worker_id: workerID,
    provisional,
  }

  if (jobShiftIDs && jobShiftIDs.length > 1) {
    attrs.shift_ids = jobShiftIDs
  }

  acc.push(attrs)
}
