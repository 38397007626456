// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Grid, Col, Row } from 'react-bootstrap'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import entityDetail from 'syft-acp-util/entityDetail'
import * as actions from 'syft-acp-core/store/employer-venues/actions'
import RecordSection from 'syft-acp-core/components/RecordSection'
import SiteComponent from 'syft-acp-core/components/SiteComponent'

import EmployerVenueAreaDetailControls from './EmployerVenueAreaDetailControls'

const dataStructure = [
  ['id', { header: 'ID' }],
  ['name', { header: 'Name', editable: true }],
  ['desc', { header: 'Description', editable: true }],
  ['access_instructions', { header: 'Access instructions', editable: true }],
  ['maximum_arrival_time', { header: 'Max. arrival time', editable: true }],
  ['arrival_minutes', { header: 'Arrival minutes', editable: true }],
]

class EmployerVenueAreaDetail extends Component {
  static propTypes = {
    data: PropTypes.shape({
      id: PropTypes.number,
      areas: PropTypes.object,
    }).isRequired,
    areaID: PropTypes.number.isRequired,
    employerID: PropTypes.number.isRequired,
    id: PropTypes.number.isRequired,
    actionUpdate: PropTypes.func.isRequired,
    isPristine: PropTypes.bool.isRequired,
    actions: PropTypes.objectOf(PropTypes.func).isRequired,
  }

  componentDidMount() {
    if (this.props.id) {
      this.props.actions.fetchEmployerVenue(this.props.employerID, this.props.id)
    }
  }

  render() {
    if (!this.props.data.areas) {
      this.props.data.areas = {}
    }
    const areaN = this.props.areaID
      ? // If we're editing an existing area, pick the correct area object from the array by ID.
        Object.keys(this.props.data.areas).find(n => this.props.data.areas[n].id === this.props.areaID)
      : // If making a new one, look at how many valid ones we have (that have an ID) and make sure it's the next one.
        Object.values(this.props.data.areas).filter(n => !!n.id).length
    return (
      <SiteComponent>
        <Grid>
          <Row>
            <Col md={12}>
              <EmployerVenueAreaDetailControls
                data={this.props.data.areas[areaN]}
                employerID={this.props.employerID}
                venueID={this.props.id}
                areaID={this.props.areaID}
                isPristine={this.props.isPristine}
              />
              <RecordSection
                data={this.props.data.areas[areaN]}
                dataPrefix={`areas[${areaN}]`}
                structure={dataStructure}
                update={this.props.actionUpdate}
                title={
                  this.props.data.areas[areaN] && this.props.data.areas[areaN].name
                    ? this.props.data.areas[areaN].name
                    : 'New area'
                }
              />
            </Col>
          </Row>
        </Grid>
      </SiteComponent>
    )
  }
}

export default connect(null, dispatch => ({
  actions: bindActionCreators(actions, dispatch),
}))(entityDetail('employerVenues', EmployerVenueAreaDetail))
