export const SET_SHIFT = 'SET_SHIFT' as const
export const UPDATE_OFFER_RATE = 'UPDATE_OFFER_RATE' as const
export const UPDATE_CLIENT_RATE = 'UPDATE_CLIENT_RATE' as const
export const UPDATE_START_TIME = 'UPDATE_START_TIME' as const
export const UPDATE_FULFILMENT_REVIEWED = 'UPDATE_FULFILMENT_REVIEWED' as const
export const UPDATE_END_TIME = 'UPDATE_END_TIME' as const
export const UPDATE_BOOKABLE_INDIVIDUALLY = 'UPDATE_BOOKABLE_INDIVIDUALLY ' as const
export const UPDATE_OFFER_TO_FLEX = 'UPDATE_OFFER_TO_FLEX' as const
export const UPDATE_HARD_SKILLS = 'UPDATE_HARD_SKILLS' as const
export const UPDATE_STOP_OFFERS = 'UPDATE_STOP_OFFERS' as const
