// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import React from 'react'

import {
  FilterAutocompleteWorkers,
  FilterRating,
  FilterGroup,
  FilterBoolCheckbox,
  FilterText,
} from 'syft-acp-util/components/FilterForm'
import entityList from 'syft-acp-util/entityList'
import * as tableType from 'syft-acp-core/components/EntityList/'
import * as workerFeedbackActions from 'syft-acp-core/actions/worker-feedback'
import EntityListComponent from 'syft-acp-core/components/EntityList/EntityListComponent'

const tableFormat = [
  { type: tableType.TXT, val: 'id', header: 'ID', numeric: true },
  { type: tableType.TXT, val: 'worker.id', header: 'W.ID', expl: 'Worker ID', numeric: true },
  { type: tableType.USER_LINE, val: 'worker', header: 'Worker', classes: ['no-wrap'] },
  { type: tableType.TXT, val: 'app_provider', header: 'OS' },
  { type: tableType.TXT, val: 'app_version', header: 'Version' },
  { type: tableType.DATETIME, classes: ['nowrap'], val: 'created_at', header: 'Date received' },
  { type: tableType.RATING, val: 'star', header: 'Rating', editable: false },
  { type: tableType.TXT, val: 'feedback', header: 'Feedback' },
]

const actionFormat = []

const filters = (
  <div>
    <FilterGroup title="Filter by name">
      <FilterAutocompleteWorkers queryType="id" name="worker_id" placeholder="Worker name" />
    </FilterGroup>
    <FilterGroup title="App provider">
      <FilterText name="app_provider" placeholder="" />
    </FilterGroup>
    <FilterGroup title="App version">
      <FilterText name="app_version" placeholder="" />
    </FilterGroup>
    <FilterGroup title="Rating">
      <FilterRating nameFrom="star" nameTo="star" withoutGreaterButton withoutLessButton />
    </FilterGroup>
    <FilterGroup title="Feedback">
      <FilterBoolCheckbox name="hide_empty_feedback" value="true" label="Hide ratings without feedback" />
    </FilterGroup>
  </div>
)

// Generates URLs that link to the workers.
const urlGenerator = rowData => `/entity/workers/view/${rowData.worker.id}`

export default entityList(
  EntityListComponent,
  tableFormat,
  filters,
  actionFormat,
  'workerFeedback',
  {
    fetchEntities: workerFeedbackActions.fetchWorkerFeedback,
  },
  {
    showResultCount: true,
    urlGenerator,
  }
)
