// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import entityList from 'syft-acp-util/entityList'
import * as employerRatingsActions from 'syft-acp-core/actions/employer-ratings'
import * as tableType from 'syft-acp-core/components/EntityList/'

import EmployerDetailListWrapper from './EmployerDetailListWrapper'
import './EmployerAccountsList/EmployerAccountsList.css'

const tableFormat = [
  { type: tableType.SELECTOR },
  { type: tableType.TXT, val: 'listing_rating_id', header: 'ID', numeric: true },
  { type: tableType.TXT, val: 'worker.id', header: 'W.ID', expl: 'Worker ID', numeric: true },
  { type: tableType.TXT, val: 'listing.id', header: 'L.ID', expl: 'Listing ID', numeric: true },
  { type: tableType.TXT, val: 'listing.event_name', header: 'Event name' },
  { type: tableType.TXT, val: 'listing.venue.name', header: 'Venue' },
  { type: tableType.TXT, val: 'worker.display_name', header: 'Worker' },
  { type: tableType.RATING, val: 'stars', char: '★', header: 'Avg. Rating' },
  { type: tableType.DATETIME, val: 'created_at', header: 'Created at', classes: ['nowrap'] },
  { type: tableType.DATETIME, val: 'updated_at', header: 'Updated at', classes: ['nowrap'] },
]

const filterFormat = null
const actionFormat = []

class EmployerRatingsList extends PureComponent {
  static propTypes = {
    actions: PropTypes.objectOf(PropTypes.func).isRequired,
    employerID: PropTypes.number.isRequired,
  }

  render() {
    const { employerID } = this.props
    const EntityList = entityList(
      EmployerDetailListWrapper,
      tableFormat,
      filterFormat,
      actionFormat,
      'employerRatings',
      {
        fetchEntities: employerRatingsActions.fetchEmployerRatings,
      },
      {
        idKey: 'listing_rating_id',
        showResultCount: true,
        urlGenerator: rowData => `/listings/view/${rowData.listing.id}`,
      }
    )

    return (
      <div className="EmployerRatingsList">
        <EntityList {...this.props} pagination employerID={employerID} query={{ employerID }} />
      </div>
    )
  }
}

export default connect(null, dispatch => ({
  actions: bindActionCreators(employerRatingsActions, dispatch),
}))(EmployerRatingsList)
