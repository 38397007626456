import { takeLatest } from 'redux-saga/effects'

import { takeLatestApiCall } from 'syft-acp-core/sagas/helpers'
import { notificationCall } from 'syft-acp-core/sagas/calls'

import * as types from './action-types'
import * as api from './api'

export default function* saga() {
  yield takeLatestApiCall(types.CONDUCT_REVIEWS_FETCH_BEGIN, api.listConductReviews)
  yield takeLatestApiCall(types.CONDUCT_REVIEW_FETCH_BEGIN, api.fetchConductReview)
  yield takeLatestApiCall(types.CONDUCT_REVIEWS_ASSIGN_BEGIN, api.assignConductReviews)
  yield takeLatestApiCall(types.CONDUCT_REVIEWS_ASSIGNEES_FETCH_BEGIN, api.fetchAssignees)
  yield takeLatestApiCall(types.CONDUCT_REVIEW_UPDATE_BEGIN, api.updateConductReview)
  yield takeLatest(
    [types.CONDUCT_REVIEWS_ASSIGN_FAILED, types.CONDUCT_REVIEW_UPDATE_FAILED, types.CONDUCT_REVIEW_UPDATE_SUCCEEDED],
    notificationCall
  )
}
