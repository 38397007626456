import { apiErrorMessage } from 'syft-acp-core/reducers/generators/utils'

export const entitySaveFailed = (state = {}, action) => ({
  ...state,
  isSavingData: false,
  lastMessage: apiErrorMessage(action.payload),
  lastStatus: action.payload.response ? action.payload.response.status : String(action.payload.response),
  lastBody: action.payload.body,
  isLoadingData: false,
})
