import { apiRequest, handleCall } from 'syft-acp-core/api/call'

const path = '/admin/shifts/bookings'

export const getShiftBookings = ({ options: { shiftIds } }) =>
  new Promise((resolve, reject) => {
    const req = apiRequest({
      path,
      reqArgs: {
        shift_ids: shiftIds,
      },
    })

    handleCall(req, resolve, reject, false)
  })
