import React, { useMemo } from 'react'

import { useDetailStructure } from './ComplianceDetail.structure'
import type { ComplianceDetailProps, Skill, GroupedComplianceDetails } from './ComplianceDetail.types'
import './ComplianceDetail.scss'

const ComplianceDetail = ({ compliances, hideRoleTitle }: ComplianceDetailProps) => {
  const groupedData = useDetailStructure(compliances)

  const groupKeys = useMemo(() => Object.keys(groupedData as GroupedComplianceDetails), [groupedData])

  return (
    <div className="compliance-detail">
      {groupKeys.length
        ? groupKeys.map((key: string) => {
            return (
              <div key={key}>
                {!hideRoleTitle && <div className="role-name">{groupedData && groupedData[key].title}:</div>}

                <div>
                  {groupedData &&
                    groupedData[key].skills.map(({ title }: Skill) => <span className="policy-name">{title}</span>)}
                </div>
              </div>
            )
          })
        : null}
    </div>
  )
}

export default ComplianceDetail
