// TODO: use correct options object types

import * as types from '../action-types'
import { WorkerEntity } from './types'

/**
 * Initiates a worker list fetching process.
 * @param {Object} options Other options
 * @param {Object} [_props] Props (unused here, required for EntityList API)
 * @param {boolean} [toCSV=false] Whether to export as CSV
 * @returns {{options: *, type: string, toCSV: boolean}}
 */
export const fetchWorkers = (
  { options }: { options: Record<string, any> },
  _props: Record<string, any>,
  toCSV = false,
) => ({ type: types.WORKERS_FETCH_BEGIN, options, toCSV })

/** Same as fetchWorkers(), but offers a CSV download instead. */
export const fetchWorkersCSV = (options: Record<string, any>, view?: string) => ({
  type: types.WORKERS_FETCH_CSV,
  options,
  view,
})

/** Initiates a saving process of updated data object for a worker. */
export const saveWorker = (workerID: number, data: Partial<Omit<WorkerEntity, 'id'>>) => ({
  type: types.WORKER_SAVE_BEGIN,
  id: workerID,
  data,
})

/** Initiates a worker fetching process. */
export const fetchWorker = (workerID: number) => ({ type: types.WORKER_FETCH_BEGIN, id: workerID })

export const deleteWorkerUser = (userID: number) => ({ type: types.WORKER_USER_DELETE_BEGIN, ids: [userID] })

export const fetchCompletedShifts = (workerID: number, options: Record<string, any> = {}) => ({
  type: types.WORKER_COMPLETED_SHIFTS_FETCH_BEGIN,
  workerID,
  options,
})

export const updateWorkerType = (workerID: number, workerType: string) => ({
  type: types.WORKER_TYPE_UPDATE_BEGIN,
  workerID,
  workerType,
})

export const fetchUpcomingWorkerJobs = (workerID: number, options: Record<string, any> = {}) => ({
  type: types.WORKER_UPCOMING_JOBS_FETCH_BEGIN,
  workerID,
  options,
})
