import { omit } from 'lodash-es'

import { apiRequest, handleCall } from 'syft-acp-core/api/call'

import { DeleteUsersInput, FetchWorkerInput, SaveWorkerInput } from './types'
import { sanitizeCourseDates, sanitizeTermDates } from './helpers'

/**
 * Retrieves a list of workers. Implements pagination.
 *
 * @param params {Object}
 * @param params.options {Object} Other query options
 * @param params.toCSV {Boolean} Whether we will handle this as a CSV download
 */
export const listWorkers = ({ options = {}, toCSV = false }) =>
  new Promise((resolve, reject) => {
    const req = apiRequest({ path: '/admin/workers', reqArgs: options, returnAll: toCSV })
    handleCall(req, resolve, reject, true, options, null, toCSV, 'workers')
  })

/**
 * Retrieves a worker from server.
 *
 * @param id {Number} Worker id
 */
export const fetchWorker = ({ id }: FetchWorkerInput) =>
  new Promise((resolve, reject) => {
    const req = apiRequest({ path: `/admin/workers/${id}` })
    handleCall(req, resolve, reject, true)
  })

/**
 * Retrieves worker's general preferences from server.
 *
 * @param id {Number} Worker id
 */
export const fetchWorkerGeneralPreferences = ({ id }: FetchWorkerInput) =>
  new Promise((resolve, reject) => {
    const req = apiRequest({ path: `/workers/${id}/general_preferences` })
    handleCall(req, resolve, reject, true)
  })

/**
 * Sends an updated data object for a worker to the server,
 * overwriting that entity data.
 *
 * @param id {Number} ID of the entity
 * @param data {Object} new data object for Entity
 */
export const saveWorker = ({ id, data }: SaveWorkerInput) =>
  new Promise((resolve, reject) => {
    // eslint-disable no-param-reassign
    // Clean up worker_term_dates to remove items without a date.
    data.worker_term_dates = data.worker_term_dates.filter(d => d.to_date != null && d.from_date != null)

    // Sanitize date formats for term and course dates (YYYY-MM-DD)
    if (!!data.worker_term_dates) {
      data.worker_term_dates = sanitizeTermDates(data.worker_term_dates)
    }
    if (!!data.worker_course_dates) {
      data.worker_course_dates = sanitizeCourseDates(data.worker_course_dates)
    }
    // eslint-enable no-param-reassign

    // Dont send old availability. Remove when BE delete the backward compatibility to this property. https://syftapp.atlassian.net/browse/WORKER-467
    const dataWithNoAvailability = omit(data, 'availability')
    const req = apiRequest({
      path: `/admin/workers/${id}/profile`,
      reqArgs: dataWithNoAvailability,
      method: 'PATCH',
    })
    handleCall(req, resolve, reject, false, { workerID: id })
  })

/**
 * Removes a user from server.
 *
 * @param id {Number} User id
 */
export const deleteUsers = ({ ids }: DeleteUsersInput) => {
  // TODO: currently, we can only remove a single user.
  const id = ids[0]
  return new Promise((resolve, reject) => {
    const req = apiRequest({ path: `/admin/users/${id}`, method: 'DELETE' })
    handleCall(req, resolve, reject)
  })
}
