// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import React from 'react'

import PageView from 'syft-acp-core/components/PageView'
import SubNavigationWatchlist from './SubNavigation'

export const WatchlistConversationsContainer = ({ children }) => (
  <>
    <SubNavigationWatchlist />
    <PageView title={['Watchlist', 'Worker conversations']} entityPage>
      {children}
    </PageView>
  </>
)
