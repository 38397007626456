import * as React from 'karet'
import { PureComponent } from 'react'
import Acp from 'syft-acp-uikit'
import { connectAcpResource } from 'syft-acp-core/api/connect'
import { workers as workersResource } from 'syft-acp-core/api/res/admin/workers'

class WorkersList extends PureComponent {
  render() {
    const { workers } = this.props
    return (
      <Acp.EntityList hasResultCount hasPagination idKeyValue="id" resource={workers.activeView}>
        <Acp.Actions>
          <Acp.Button onClick={(a, b) => console.log(a, b)} kind="success">
            Save
          </Acp.Button>
        </Acp.Actions>
        <Acp.Table rowLink={row => `/entity/users/view/${row.id}`}>
          <Acp.Col.Text value="id" header="ID" isMinimal isNumeric />
          <Acp.ColEditable.Text value="account_number" header="Acc. number" isMain />
        </Acp.Table>
      </Acp.EntityList>
    )
  }
}

export default connectAcpResource({ workers: workersResource })(WorkersList)
