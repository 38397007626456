// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { useFlexFlagIsOn } from '@indeed/flex-feature-flags'
import { getDateRange } from 'syft-acp-core/lib/dateFilter'
import SubNavigation, { SubLink } from 'syft-acp-atoms/SubNavigation'
import PageTitle from 'syft-acp-core/components/PageTitle'
import { fetchAgencyShifts } from 'syft-acp-core/store/agency-shifts/actions'
import { fetchShifts } from 'syft-acp-core/store/shifts/actions'
import { getShiftsVacanciesSelector, getAgencyShiftsVacanciesSelector } from 'syft-acp-core/selectors'

import { ShiftsProps as Props } from './Shifts.types'
import '../../views/EntityList.css'

export const storeConnector = connect(
  state => ({
    shiftSyftVacancies: getShiftsVacanciesSelector(state, true),
    shiftVacancies: getShiftsVacanciesSelector(state, false),
    agencyVacancies: getAgencyShiftsVacanciesSelector(state),
  }),
  dispatch => ({
    actions: bindActionCreators({ fetchAgencyShifts, fetchShifts }, dispatch),
  }),
)

const getDateRangeShift = getDateRange(0, 1, 'start_time_gte', 'start_time_lte', true, true, true)

const Shifts: React.FC<Props> = ({
  actions,
  children,
  shiftVacancies = 0,
  shiftSyftVacancies = 0,
  agencyVacancies = 0,
}) => {
  const agencyShiftHistoryEnabled = useFlexFlagIsOn('pte_16679_agency_shift_history')
  const withShiftsCounters = useFlexFlagIsOn('pte_13701_shifts_counters')

  useEffect(() => {
    if (!!shiftSyftVacancies && !shiftVacancies) {
      actions.fetchShifts({
        options: {
          counters_enabled: true,
          exclude_bookings: true,
          ...getDateRangeShift,
        },
      })
    }
  }, [actions, shiftSyftVacancies, shiftVacancies])

  return (
    <PageTitle title="Shifts">
      <div className="page entity-list with-help">
        <div className="list">
          <SubNavigation>
            <SubLink to="/shifts/all-shifts" withCounter={withShiftsCounters} counterLength={shiftVacancies}>
              All shifts
            </SubLink>
            <SubLink to="/shifts/flex" withCounter={withShiftsCounters} counterLength={shiftSyftVacancies}>
              Flex shifts
            </SubLink>
            <SubLink
              to="/shifts/agency"
              withCounter={withShiftsCounters}
              counterLength={agencyVacancies}
              highlighted={agencyVacancies > 0}
            >
              Agency shifts
            </SubLink>

            {agencyShiftHistoryEnabled && (
              <SubLink to="/shifts/agency-shift-history">Agency shift history</SubLink>
            )}
            <SubLink to="/shifts/fulfilment">Fulfilment</SubLink>
          </SubNavigation>

          {children}
        </div>
      </div>
    </PageTitle>
  )
}

export default storeConnector(Shifts)
