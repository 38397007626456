// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import React, { useEffect, useMemo } from 'react'
import { flowRight } from 'lodash-es'
import { Grid, Col, Row } from 'react-bootstrap'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { useFlexFlagIsOn } from '@indeed/flex-feature-flags'

import entityDetail from 'syft-acp-util/entityDetail'
import * as employerVenueActions from 'syft-acp-core/store/employer-venues/actions'
import * as employerActions from 'syft-acp-core/store/employers/actions'
import { fetchVenueCategories } from 'syft-acp-core/store/venueCategories/actions'
import RecordSection from 'syft-acp-core/components/RecordSection'
import SiteComponent from 'syft-acp-core/components/SiteComponent'

import VenueAccountManagersTable from './components/VenueAccountManagersTable'
import EmployerVenueAreaList from '../EmployerVenueAreaList'
import EmployerVenueDetailControls from './EmployerVenueDetailControls'
import EmployerVenueTransferRecord from '../EmployerVenueTransferRecord'
import { EmployerVenueDetailProps } from './EmployerVenueDetail.types'
import { PreferencesListSection } from '../../../components/ClientPreferences'
import { CompliancesListSection } from '../../../components/Compliances'
import {
  getDataStructure,
  shiftRequirementsStructure,
  agenciesStructure,
  complianceStructure,
} from './constans'
import { formatVenueCategories, getEmployerVenueCategories, getNeeds } from './helpers'
import CountrySpecific from '../../../components/CountrySpecific'
import { SUPPORTED_COUNTRY_CODES } from '../../../lib/i18n'

export const EmployerVenueDetail = ({
  data,
  employerID,
  isPristine,
  actionUpdate,
  platformID,
  id,
  saleforceVenueData,
  venueCategories,
  actions,
}: EmployerVenueDetailProps) => {
  const isCompliancePoliciesFeatureEnabled = useFlexFlagIsOn('pte_16678_admin_compliance_policies')
  const isClientPreferencesFeatureEnabled = useFlexFlagIsOn('pte_16680_admin_client_preferences')
  const isClientAccountManagerVenueEnabled = useFlexFlagIsOn('client_display_account_managers_venue_fe_v1')

  useEffect(() => {
    actions.fetchVenueCategories(employerID)
    // The platform ID comes from the employer data.
    if (platformID == null) {
      actions.fetchEmployer(employerID)
    }
    if (id) {
      actions.fetchEmployerVenue(employerID, id)
    }
  }, [actions, id, platformID, employerID])

  const agencyData = useMemo(() => ({ platformID, ...data }), [data, platformID])
  const formattedVenueCategories = useMemo(
    () => formatVenueCategories(getEmployerVenueCategories(employerID, venueCategories)),
    [employerID, venueCategories],
  )
  const venueDataStructure = useMemo(
    () =>
      getDataStructure({
        venueCategories: formattedVenueCategories,
      }),
    [formattedVenueCategories],
  )
  return (
    <SiteComponent>
      <Grid>
        <Row>
          <Col md={12}>
            <EmployerVenueDetailControls
              venueID={id}
              data={data}
              // @ts-ignore TODO: investigate existence of unused prop saleforceVenueData
              saleforceVenueData={saleforceVenueData}
              employerID={employerID}
              isPristine={isPristine}
            />
            <RecordSection
              data={data}
              structure={venueDataStructure}
              update={actionUpdate}
              title={data.name ? data.name : 'New venue'}
            />
            <CountrySpecific countries={[SUPPORTED_COUNTRY_CODES.US]}>
              <RecordSection
                data={agencyData}
                structure={shiftRequirementsStructure}
                update={actionUpdate}
                title="Shift requirements"
              />
            </CountrySpecific>
            <RecordSection
              data={agencyData}
              structure={agenciesStructure}
              update={actionUpdate}
              title="Agencies"
            />
            <RecordSection
              data={data}
              structure={complianceStructure}
              update={actionUpdate}
              title="Compliance"
            />
            {isClientAccountManagerVenueEnabled && (
              <RecordSection title="Account Managers">
                <VenueAccountManagersTable currentAccountManagers={data?.current_account_managers} />
              </RecordSection>
            )}
            {data.id ? <EmployerVenueAreaList venueID={id} employerID={employerID} /> : null}
            <EmployerVenueTransferRecord venue={data} employerID={employerID} />
            {isClientPreferencesFeatureEnabled ? (
              <PreferencesListSection
                title="Fulfilment preferences"
                employerID={employerID}
                workLocationID={id}
              />
            ) : null}
            {isCompliancePoliciesFeatureEnabled ? (
              <CompliancesListSection title="Compliances" employerID={employerID} />
            ) : null}
          </Col>
        </Row>
      </Grid>
    </SiteComponent>
  )
}

export const storeConnector = connect(getNeeds, dispatch => ({
  actions: bindActionCreators(
    { ...employerVenueActions, ...employerActions, fetchVenueCategories },
    dispatch,
  ),
}))

export default entityDetail('employerVenues', flowRight(storeConnector)(EmployerVenueDetail))
