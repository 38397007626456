import { Text } from '@indeed/ifl-components'
import classnames from 'classnames'
import { FormApi } from 'final-form'
import React, { PureComponent } from 'react'
import { ControlLabel, FormControl, FormGroup } from 'react-bootstrap'
import { Field, Form } from 'react-final-form'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { notify } from 'syft-acp-core/actions/notifications'
import { reqDefaults } from 'syft-acp-core/api/call'
import { apiURL } from 'syft-acp-core/api/endpoints'
import DataDate from 'syft-acp-core/components/EditableTable/DataDate'
import { Modal } from 'syft-acp-core/components/Modal'
import { RolesSelect } from 'syft-acp-core/components/RolesSelect'
import { getErrors } from 'syft-acp-core/lib/notifications'
import { hideModal, showModal } from 'syft-acp-core/store/modals/actions'
import { getModal$ } from 'syft-acp-core/store/modals/selectors'
import { fetchMissingPay } from 'syft-acp-core/store/worker-missing-pay/actions'
import TimeInput from '../../../entities/ListingDetail/ListingShiftTabs/ListingInputs/TimeInput'

import { WorkerMissingPayEntity } from 'syft-acp-core/store/worker-missing-pay/types'
import TextField from '../TextField'
import './AddWorkerMissingPayModal.scss'
import {
  FormattedWorkerMissingPay,
  AddWorkerMissingPayModalProps as Props,
} from './AddWorkerMissingPayModal.types'
import {
  formatFromAPI,
  getModalCanSubmit,
  getModalCancelText,
  getModalErrorText,
  getModalHeader,
  getModalOnConfirm,
  getValidationState,
  getValidator,
  isInputDisabled,
} from './helpers'
export const storeConnector = connect(
  state => ({
    item: getModal$(state.modals)('addWorkerMissingPayModal'),
  }),
  dispatch => ({
    actions: {
      ...bindActionCreators({ notify, showModal, hideModal, fetchMissingPay }, dispatch),
    },
  }),
)

const getWorkerHasRequiredMinimumRateSet = (values: any, worker: Record<string, any>) =>
  values.hasShiftId === 'true' || !!worker.minimum_rate

class AddWorkerMissingPayModal extends PureComponent<Props> {
  submit = (values: any, form: FormApi<any, any>) =>
    new Promise<void | string>(async resolve => {
      const { actions, worker } = this.props

      const notes = [
        values.missing_pay_type === 'other' ? `${values.missing_pay_type_other}\n` : '',
        values.authorisation_type === 'other' ? `${values.authorisation_type_other}\n\n` : '',
        values.notes,
      ].join('')

      const data: Record<string, any> = {
        missing_time: values.missing_time,
        missing_pay_type: values.missing_pay_type,
        client_authorisation_name: values.client_authorisation_name,
        authorisation_type: values.authorisation_type,
        shift_po_number: values.shift_po_number,
        compensated_by_syft: values.compensated_by_syft,
        notes,
      }

      if (values.hasShiftId === 'true') {
        data.shift_id = values.shift_id
      } else {
        data.date_of_work = values.date_of_work
        data.employer_id = values.employer_id
        data.venue_id = values.venue_id
        data.client_pay_rate = {
          amount: parseFloat(values.client_pay_rate),
          currency: worker.minimum_rate?.currency,
        }
        data.worker_pay_rate = {
          amount: parseFloat(values.worker_pay_rate),
          currency: worker.minimum_rate?.currency,
        }

        /* Temporary, until `data.worker_role` is removed.
         * See: https://syftapp.atlassian.net/browse/WORKER-641 */
        const [role_id, worker_role] = values.role_id.split(';')
        data.worker_role = worker_role
        data.role_id = role_id
        /* End temporary code */
      }

      try {
        const req = new Request(apiURL(`/admin/workers/${worker.id}/missing_pays`), {
          ...reqDefaults('POST', 'application/json'),
          body: JSON.stringify(data),
        })
        const result = await fetch(req)
        const resultData = await result.json()
        if (result.ok) {
          form.reset()
          this.closeModal()

          actions.notify('success', {
            title: 'Added Missing Pay',
            message: <>Missing Pay entry has been added.</>,
          })
          actions.fetchMissingPay(worker.id, {})
          return resolve()
        } else {
          const errorDetails = getErrors({ body: resultData }, true) as Record<string, any>

          actions.notify('error', {
            title: 'Could not add missing pay',
            ...errorDetails,
          })

          return resolve(errorDetails.message)
        }
      } catch (e) {
        const message =
          'Something went wrong while sending data to the API. See the error page for more details.'

        actions.notify('error', {
          title: 'Could not add missing pay',
          message,
          autoDismiss: 0,
        })

        return resolve(message)
      }
    })

  closeModal = () => this.props.actions.hideModal(this.props.item.modalName)

  openModal = () => this.props.actions.showModal(this.props.item.modalName)

  showVariableFields = (hasShiftId: boolean, disabled: boolean) => {
    if (hasShiftId) return <TextField name="shift_id" label="Shift ID" required />

    return (
      <FormGroup>
        <Field name="date_of_work" validate={getValidator(true)}>
          {({ input, meta }) => (
            <FormGroup validationState={getValidationState(meta)} className={classnames({ required: true })}>
              <ControlLabel>Date of Work</ControlLabel>
              <br />
              <DataDate editable={!disabled} {...input} />
            </FormGroup>
          )}
        </Field>
        <TextField disabled={disabled} label="Employer ID" name="employer_id" required />
        <TextField disabled={disabled} label="Venue ID" name="venue_id" required />
        <TextField disabled={disabled} label="Client Pay Rate" name="client_pay_rate" required />
        <TextField disabled={disabled} label="Worker Pay Rate" name="worker_pay_rate" required />
        <RolesSelect disabled={disabled} label="Worker Role" name="role_id" required />
      </FormGroup>
    )
  }

  render() {
    const data: FormattedWorkerMissingPay = this.props.item.data.id
      ? formatFromAPI(this.props.item.data as WorkerMissingPayEntity) // TODO it should be possible to tell what type of data is in modal selector
      : { missing_time: '00:00' }

    return (
      <Form
        onSubmit={this.submit}
        initialValues={data}
        render={({ handleSubmit, invalid, submitting, submitFailed, submitErrors, values }) => (
          <Modal
            header={getModalHeader(data)}
            isShown={this.props.item.isShown}
            onClose={this.closeModal}
            onConfirm={getModalOnConfirm(data, handleSubmit)}
            canSubmit={
              getModalCanSubmit(invalid, submitting, submitFailed) &&
              getWorkerHasRequiredMinimumRateSet(values, this.props.worker)
            }
            errorText={getModalErrorText(submitFailed, submitting, submitErrors)}
            cancelText={getModalCancelText(data)}
            confirmationText="Add Missing Pay"
          >
            <form onSubmit={handleSubmit}>
              <FormGroup className="missing-pay-modal">
                <FormGroup>
                  <Field name="hasShiftId" type="radio" value="false" validate={getValidator(true)}>
                    {({ input }) => (
                      <label className="radio-inline">
                        <input {...input} disabled={isInputDisabled(data, submitting)} type="radio" />
                        No Reference Shift ID
                      </label>
                    )}
                  </Field>
                  <Field
                    name="hasShiftId"
                    type="radio"
                    value="true"
                    defaultValue="true"
                    validate={getValidator(true)}
                  >
                    {({ input }) => (
                      <label className="radio-inline">
                        <input
                          {...input}
                          disabled={isInputDisabled(data, submitting)}
                          type="radio"
                          {...input}
                        />
                        Reference Shift ID
                      </label>
                    )}
                  </Field>
                </FormGroup>
                {this.showVariableFields(values.hasShiftId === 'true', isInputDisabled(data, submitting))}
                <Field name="missing_time" type="time" validate={getValidator(true)}>
                  {({ input }) => (
                    <FormGroup className="required">
                      <ControlLabel>Missing Time</ControlLabel>
                      <TimeInput
                        onChange={(missingTime: any) => input.onChange(missingTime)}
                        disabled={isInputDisabled(data, submitting)}
                        canBeCleared
                        value={values.missing_time}
                        limitHours={false}
                        required
                      />
                    </FormGroup>
                  )}
                </Field>
                <Field name="missing_pay_type" type="select" validate={getValidator(true)}>
                  {({ input }) => (
                    <FormGroup className="required">
                      <ControlLabel>Type of Missing Hours</ControlLabel>
                      {/* @ts-expect-error */}
                      <FormControl
                        disabled={isInputDisabled(data, submitting)}
                        componentClass="select"
                        {...input}
                      >
                        <option value="">Select a Reason</option>
                        <option value="cancellation_fee">Cancellation Fee</option>
                        <option value="check_in">Check-In</option>
                        <option value="overbook">Overbook</option>
                        <option value="compensation">Compensation</option>
                        <option value="training">Training</option>
                        <option value="missing_pay_worker_dispute">Worker Dispute</option>
                        <option value="missing_pay_unbooked_external_work">Unbooked External Work</option>
                        <option value="other">Other</option>
                      </FormControl>
                    </FormGroup>
                  )}
                </Field>
                {values.missing_pay_type === 'other' ? (
                  <TextField
                    disabled={isInputDisabled(data, submitting)}
                    label="Type of Missing Hours (Other)"
                    name="missing_pay_type_other"
                    required
                  />
                ) : null}
                <TextField
                  disabled={isInputDisabled(data, submitting)}
                  label="Client Authorisation Name"
                  name="client_authorisation_name"
                  required
                />
                <Field name="authorisation_type" type="select" validate={getValidator(true)}>
                  {({ input }) => (
                    <FormGroup className="required">
                      <ControlLabel>Type of Authorisation</ControlLabel>
                      {/* @ts-expect-error */}
                      <FormControl
                        disabled={isInputDisabled(data, submitting)}
                        componentClass="select"
                        {...input}
                      >
                        <option value="">Select Authorisation Type</option>
                        <option value="client_success_phone_call">Client Success Phone Call</option>
                        <option value="salesforce_case">Salesforce Case</option>
                        <option value="account_manager_approval">Account Manager Approval</option>
                        <option value="check_in_payment">Check-In Payment</option>
                        <option value="other">Other</option>
                      </FormControl>
                    </FormGroup>
                  )}
                </Field>
                {values.authorisation_type === 'other' ? (
                  <TextField
                    disabled={isInputDisabled(data, submitting)}
                    label="Type of Authorisation (Other)"
                    name="authorisation_type_other"
                    required
                  />
                ) : null}
                <TextField
                  disabled={isInputDisabled(data, submitting)}
                  label="Shift PO Number"
                  name="shift_po_number"
                />
                <TextField
                  disabled={isInputDisabled(data, submitting)}
                  label="Notes"
                  required
                  name="notes"
                  type="textarea"
                />
                <Text sx={{ color: 'red' }} as="p">
                  Please include SF case number OR date and approver phone number if approved over phone call
                </Text>
                <FormGroup className="required">
                  <ControlLabel>Compensated by Syft</ControlLabel>
                  <FormGroup>
                    <Field name="compensated_by_syft" type="radio" value="true" validate={getValidator(true)}>
                      {({ input }) => (
                        <label className="radio-inline">
                          <input {...input} type="radio" disabled={isInputDisabled(data, submitting)} />
                          Yes
                        </label>
                      )}
                    </Field>
                    <Field
                      name="compensated_by_syft"
                      type="radio"
                      value="false"
                      validate={getValidator(true)}
                    >
                      {({ input }) => (
                        <label className="radio-inline">
                          <input {...input} type="radio" disabled={isInputDisabled(data, submitting)} />
                          No
                        </label>
                      )}
                    </Field>
                  </FormGroup>
                </FormGroup>
              </FormGroup>

              {!getWorkerHasRequiredMinimumRateSet(values, this.props.worker) && (
                <FormGroup>
                  <Text sx={{ color: 'red' }} as="p">
                    Worker minimum pay rate is not set. Please set it in the worker profile to be able to add
                    missing pay.
                  </Text>
                </FormGroup>
              )}
            </form>
          </Modal>
        )}
      />
    )
  }
}

export default storeConnector(AddWorkerMissingPayModal)
