import { formatNoTzISODate } from 'syft-acp-util/formatting'
import { WorkerCourseDates as CourseDates, WorkerTermDate as TermDate } from './types'

export const sanitizeTermDates = (termDates: TermDate[]): TermDate[] =>
  termDates.map((termDate: TermDate) => ({
    ...termDate,
    from_date: formatNoTzISODate(termDate.from_date),
    to_date: formatNoTzISODate(termDate.to_date),
  }))

export const sanitizeCourseDates = ({ from_date, to_date }: CourseDates): CourseDates => ({
  ...(!!from_date ? { from_date: formatNoTzISODate(from_date) } : {}),
  ...(!!to_date ? { to_date: formatNoTzISODate(to_date) } : {}),
})
