// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import React, { useCallback, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage, useIntl } from 'react-intl'
import { useTrackingTrigger } from '@indeed/flex-tracking-context'

import { Button, ButtonDropdown, MenuItem } from 'syft-acp-atoms/Button'
import LoadingIndicator from 'syft-acp-util/components/LoadingIndicator'
import { Modal } from 'syft-acp-core/components/Modal'
import { trackingEvents } from './EmployerDetail.tracking'
import './EmployerDetailControlRight.css'

export const EmployerDetailControlRight = ({ data, actions, isSavingData, isLoadingData, isPristine }) => {
  const intl = useIntl()
  const triggerEvent = useTrackingTrigger()
  const [showModal, setShowModal] = useState(false)
  const canVerify = useMemo(
    () =>
      data.syft_sales_manager &&
      data.tier &&
      data.company_name.trim() !== '' &&
      data.client_delivery_team_id &&
      data.brand_name !== '' &&
      data.company_number !== '' &&
      Array.isArray(data.industry_ids) &&
      data.industry_ids.length > 0,
    [
      data.syft_sales_manager,
      data.tier,
      data.company_name,
      data.client_delivery_team_id,
      data.brand_name,
      data.company_number,
      data.industry_ids,
    ],
  )
  const isApproved = !!data.approved_at

  const verifyAction = useCallback(() => {
    actions.approveEmployer(data.id, !data.approved_at)
    triggerEvent(
      !data.approved_at
        ? trackingEvents.VERIFY_EMPLOYER_BUTTON.CLICKED
        : trackingEvents.UNVERIFY_EMPLOYER_BUTTON.CLICKED,
    )
  }, [actions, data, triggerEvent])

  const saveAction = useCallback(() => {
    actions.saveEmployer(data.id, data)
    triggerEvent(trackingEvents.SAVE_BUTTON.CLICKED)
  }, [actions, data, triggerEvent])

  const handleShowModal = useCallback(() => {
    setShowModal(!showModal)
  }, [showModal])

  const handleConfirm = useCallback(() => {
    actions.migrateToSyftForce(data.id)
    triggerEvent(trackingEvents.MIGRATE_TO_SYFTFORCE_BUTTON.CLICKED)
    handleShowModal()
  }, [actions, data.id, handleShowModal, triggerEvent])

  return (
    <div>
      <LoadingIndicator loadingState={isSavingData || isLoadingData} />{' '}
      <Button
        data-testid="EmployerDetailControlRight-save-button"
        kind="success"
        onClick={saveAction}
        disabled={isSavingData || isPristine}
      >
        Save
      </Button>
      {'\xa0'}
      <ButtonDropdown title="Actions" id="employer_actions_dropdown" pullRight disabled={isSavingData}>
        <MenuItem
          key="1"
          onSelect={verifyAction}
          disabled={!isApproved && !canVerify}
          data-place="top"
          data-tip={
            !isApproved && !canVerify
              ? 'Cannot verify employer. The following fields are required: Brand name - Company name - Industry - Company number - Sales manager - Tier - Client delivery team'
              : null
          }
        >
          {isApproved ? 'Unverify' : 'Verify'} employer
        </MenuItem>
        <MenuItem
          onSelect={handleShowModal}
          disabled={data.platform_id !== 1}
          data-tip={
            data.platform_id !== 1
              ? `Employer already migrated to ${intl.formatMessage({ id: 'platform.syftForce' })}`
              : null
          }
        >
          Migrate to <FormattedMessage id="platform.syftForce" />
        </MenuItem>
      </ButtonDropdown>
      <Modal
        isShown={showModal}
        header={
          <>
            Migrate Employer to <FormattedMessage id="platform.syftForce" />
          </>
        }
        onClose={handleShowModal}
        onConfirm={handleConfirm}
        confirmationText="Confirm"
      >
        <div className="employerDetailControlRight-content" data-testid="modal-migrate-content">
          <p>
            Confirming this action will migrate all data for this employer to their own platform. The employer
            will be able to access internal worker functionality, and we will be able to align agency
            coverage.
          </p>
          <p>
            ADDITIONAL SIGNAGE MAY BE REQUIRED. Please confirm what additional signage is required from your
            manager if you do not already know. This action cannot be undone through ACP so please ensure you
            are 100% sure before confirming and triggering the process. Ideally, you should only trigger the
            migration during a time when the client is not creating data.
          </p>
          <p>
            All future empty shifts will be offered to internal users, so ensure they are set up. Shifts
            already filled by Flex workers remain unchanged. Unfilled shifts will need to be re-offered to
            Flex workers if required.
          </p>
        </div>
      </Modal>
    </div>
  )
}

EmployerDetailControlRight.defaultProps = {
  actions: {},
  isPristine: false,
  data: null,
  isSavingData: false,
  isLoadingData: false,
}

EmployerDetailControlRight.propTypes = {
  data: PropTypes.object,
  isPristine: PropTypes.bool,
  actions: PropTypes.objectOf(PropTypes.func),
  isSavingData: PropTypes.bool,
  isLoadingData: PropTypes.bool,
}
