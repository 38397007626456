// @ts-expect-error
import * as React from 'karet'
// @ts-expect-error
import * as U from 'karet.util'
import { FC } from 'react'

import InputCheckbox from 'syft-acp-atoms/InputCheckbox'
import { colPlaceholderFactory } from 'syft-acp-uikit/components/placeholders/factory'

import { AcpCellSelectorLegacyProps as Props } from './AcpCellSelector.types'

export const AcpColSelectorLegacy = colPlaceholderFactory('AcpCellSelectorLegacy')

const AcpCellSelectorLegacy: FC<Props> & { cellProperties: string[] } = ({ rowState, isSubRow }) => {
  return <InputCheckbox value={U.ifElse(isSubRow, rowState.rowSubSelectionLens, rowState.rowSelectionLens)} />
}

AcpCellSelectorLegacy.cellProperties = ['form']

export default AcpCellSelectorLegacy
