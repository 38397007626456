import { ShiftEventEntity, TransformedTableEvent, TransformedDetail } from 'syft-acp-core/store/shiftEvents/types'
import { BaseEntity } from 'syft-acp-core/reducers/generators/entityReducer'
import { TRANSFORM_KEYS_TO_LABELS, FILTERED_DETAILS } from './constants'

const checkSyftValue = (value: string | string[] | null) => value?.[0] === 'syft' || value === 'syft'

export const transformValue = (value: string | string[] | null, code: string) => {
  // Offer to
  if (value === 'network,random') {
    return 'All flexers'
  }
  // Offer to
  if (value === 'network') {
    return `Employer's trusted Network`
  }
  // Offer to
  if (value === 'individual') {
    return 'Individuals'
  }
  // Offer to Flex
  if (checkSyftValue(value)) {
    if (code !== 'stop_offers_for_worker_type') {
      return 'Flex'
    }
    return 'true'
  }
  // Stop offers
  if (!value?.length && code === 'stop_offers_for_worker_type') {
    return 'false'
  }
  return String(value)
}

// Transform data from specific events to user friedly data
export const findEventsNames = (data: ShiftEventEntity, skills: BaseEntity[]) => [
  {
    code: 'admin_note.updated',
    label: 'Ops internal notes',
    from: data?.details?.body?.[0] || 'null',
    to: data?.details?.body?.[1],
  },
  {
    code: 'admin_note.created',
    label: 'Ops internal notes',
    from: data?.details?.body?.[0] || 'null',
    to: data?.details?.body?.[1],
  },
  {
    code: 'shift.fulfilment_reviewed',
    label: 'Shift marked as reviewed',
    from: 'false',
    to: 'true',
  },
  {
    code: 'shift.fulfilment_unreviewed',
    label: 'Shift marked as reviewed',
    from: 'true',
    to: 'false',
  },
  {
    code: 'job_skill.created',
    label: 'Skill added',
    from: skills.find((item: BaseEntity) => item.id === data?.details?.skill_id?.[0])?.title || 'null',
    to: skills.find((item: BaseEntity) => item.id === data?.details?.skill_id?.[1])?.title || 'null',
  },
  {
    code: 'job_skill.deleted',
    label: 'Skill removed',
    from: skills.find((item: BaseEntity) => item.id === data?.details?.skill_id?.[0])?.title || 'null',
    to: skills.find((item: BaseEntity) => item.id === data?.details?.skill_id?.[1])?.title || 'null',
  },
  {
    code: 'offer_rate.updated',
    label: 'Offer rate',
    from: data?.details?.uplifted_pay_rate?.[0],
    to: data?.details?.uplifted_pay_rate?.[1],
  },
]

// Create data to the table
export const createEventData = (
  item: ShiftEventEntity,
  label?: string,
  valueFrom?: string | number,
  valueTo?: string | number,
  code?: string
) => {
  return {
    ...item,
    details: [
      {
        code,
        fromCell: {
          key: label,
          from: String(valueFrom),
        },
        toCell: {
          to: String(valueTo),
        },
      },
    ],
  }
}

export const findTransformedKey = (code: string, toValue: string, fromValue: string) => {
  if ((checkSyftValue(fromValue) || checkSyftValue(toValue)) && code === 'offer_to') {
    return 'Offer to Flex'
  }
  return TRANSFORM_KEYS_TO_LABELS[code as keyof typeof TRANSFORM_KEYS_TO_LABELS] || code
}

// This helper filter and transform BE data due to requirements in PTE-7971
export const getDataWithDetails = (data: ShiftEventEntity[], skills: BaseEntity[]) => {
  return data
    .map((item: ShiftEventEntity) => {
      const findEvent = findEventsNames(item, skills).find((event: TransformedTableEvent) => event.code === item.event)
      if (findEvent) {
        // Creates one specific event with a single detail for one subrow (for example Reviewed)
        return createEventData(item, findEvent?.label, findEvent?.from, findEvent?.to, findEvent?.code)
      }
      const details = Object.keys(item.details)
        .map((code, i) => {
          const value = Object.values(item.details)[i]
          const from = Array.isArray(value) ? value?.[0] : 'null'
          const to = Array.isArray(value) ? value?.[1] : value
          const key = findTransformedKey(code, to, from)
          return {
            code,
            fromCell: {
              key,
              from: transformValue(from, code),
            },
            toCell: {
              to: transformValue(to, code),
            },
          }
        })
        .filter((detail: TransformedDetail) => {
          return FILTERED_DETAILS.includes(detail?.code)
        })
      // Returns an event with a multiple details (subrows), filters specific events & attributes (for example strat_time)
      return {
        ...item,
        details,
      }
    })
    .filter(row => {
      // Filter not required events or events without specific details
      return (
        row.details.length &&
        (row.event !== 'job.updated' ||
          row.details[0]?.code === 'bookable_individually' ||
          row.details[0]?.code === 'pay_rate' ||
          row.details[0]?.code === 'desc') &&
        row.event !== 'job.created' &&
        row.event !== 'offer_rate.created'
      )
    })
}
