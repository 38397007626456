export type AcpFilterDataProps = {
  setValue: (name: string, value: string) => void
  isSmall?: boolean
  isTiny?: boolean
  isDisabled?: boolean
  placeholder?: string
  name?: string
  value?: string | null
  initialValue?: string
  onChange?: (key: string, value?: string) => void
}

export const acpFilterDefaults: AcpFilterDataProps = {
  setValue: () => undefined,
  isSmall: false,
  isTiny: false,
  isDisabled: false,
  placeholder: '',
  value: null,
}
