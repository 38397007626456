// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import entityReducer from 'syft-acp-core/reducers/generators/entityReducer'
import * as entityTypes from 'syft-acp-core/reducers/generators/entities'

import { CompliancePolicyEntity } from './types'
import * as types from '../action-types'

export default entityReducer<CompliancePolicyEntity>(
  'compliancePolicies',
  {
    [entityTypes.ENTITIES_FETCH_BEGIN]: types.COMPLIANCE_POLICIES_FETCH_BEGIN,
    [entityTypes.ENTITIES_FETCH_SUCCEEDED]: types.COMPLIANCE_POLICIES_FETCH_SUCCEEDED,
    [entityTypes.ENTITIES_FETCH_FAILED]: types.COMPLIANCE_POLICIES_FETCH_FAILED,
    [entityTypes.ENTITY_MODIFY_SUCCEEDED]: types.COMPLIANCE_POLICIES_UPDATE_SUCCEEDED,
    [entityTypes.ENTITY_DELETE_SUCCEEDED]: types.COMPLIANCE_POLICIES_DELETE_SUCCEEDED,
    [entityTypes.ENTITY_CREATE_SUCCEEDED]: types.COMPLIANCE_POLICIES_CREATE_SUCCEEDED,
  },
  {
    // When we reach over 250 cached items in the store,
    // reduce the number of items to the 125 latest.
    itemLimit: [250, 125],
  }
)
