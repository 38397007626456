import React from 'react'
import PropTypes from 'prop-types'

const venueAddress = ({ city, line_1, line_2, post_code, federated_state_code }) =>
  [post_code, federated_state_code, city, line_1, line_2].filter(e => e).join(', ')

const DataVenueAddress = ({ address }) => <div className="plain-text">{venueAddress(address)}</div>

DataVenueAddress.propTypes = {
  address: PropTypes.object.isRequired,
}

export default DataVenueAddress
