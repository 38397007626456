export const ADMIN_LISTING_SHIFT_CLEAR = 'syftacp/ADMIN_LISTING_SHIFT_CLEAR' as const

export const ADMIN_LISTING_SHIFT_FETCH_BEGIN = 'syftacp/ADMIN_LISTING_SHIFT_FETCH_BEGIN' as const
export const ADMIN_LISTING_SHIFT_FETCH_FAILED = 'syftacp/ADMIN_LISTING_SHIFT_FETCH_FAILED' as const
export const ADMIN_LISTING_SHIFT_FETCH_SUCCEEDED = 'syftacp/ADMIN_LISTING_SHIFT_FETCH_SUCCEEDED' as const

export const LISTING_EDIT_SHIFT_BEGIN = 'syftacp/LISTING_EDIT_SHIFT_BEGIN' as const
export const LISTING_EDIT_SHIFT_FAILED = 'syftacp/LISTING_EDIT_SHIFT_FAILED' as const
export const LISTING_EDIT_SHIFT_SUCCEEDED = 'syftacp/LISTING_EDIT_SHIFT_SUCCEEDED' as const
export const LISTING_EDIT_SHIFT_STOP_OFFERS = 'syftacp/LISTING_EDIT_SHIFT_STOP_OFFERS' as const
