// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import { put, takeEvery, takeLatest } from 'redux-saga/effects'

import { listShifts, stopNewWorkers } from '../api'
import { confirmShiftBooking } from 'syft-acp-core/api/resources/bookings'
import { highlightShiftBooking } from 'syft-acp-core/api/resources/admin/shift_bookings'
import { notificationCall } from 'syft-acp-core/sagas/calls'
import { takeLatestApiCall, takeEveryApiCall } from 'syft-acp-core/sagas/helpers'
import { updateStopJobOffersByShift } from 'syft-acp-core/store/jobs/actions'

import * as types from '../action-types'

function* updateJobStopOffers(action: Record<string, any>) {
  const stopOffersForWorkerType = action?.payload?.job?.stop_offers_for_worker_type
  const jobId = action?.payload?.job?.id
  yield put(updateStopJobOffersByShift(jobId, stopOffersForWorkerType))
}

export default function* root() {
  yield takeLatestApiCall('SHIFTS_FETCH', listShifts)
  yield takeEveryApiCall('HIGHLIGHT_SHIFT_BOOKING', highlightShiftBooking)
  yield takeEveryApiCall('CONFIRM_SHIFT_BOOKING', confirmShiftBooking)
  yield takeEveryApiCall('SHIFT_STOP_NEW_WORKERS', stopNewWorkers)
  yield takeLatest(types.SHIFT_STOP_NEW_WORKERS_SUCCEEDED, updateJobStopOffers)
  yield takeEvery(
    [types.SHIFT_STOP_NEW_WORKERS_FAILED, types.SHIFT_STOP_NEW_WORKERS_SUCCEEDED],
    notificationCall,
  )
}
