// Syft ACP - Lib <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import moment from 'moment'
import momentTz from 'moment-timezone'
import { parsePhoneNumberFromString } from 'libphonenumber-js'
import 'moment/locale/en-gb'
import { compact, get, isString } from 'lodash-es'
import React from 'react'

import prettyBytes from 'pretty-bytes'
import { getTimezoneName } from 'syft-acp-util/time/timezone'
import { currentIntl } from 'syft-acp-util/intl'
import { TypeLabel } from 'syft-acp-core/components/TypeMaps'
import config from 'syft-acp-core/config'

/** Sets a Moment object to our chosen timezone. */
export const addTimezone = (date, tz) => momentTz.tz(date, typeof tz === 'string' ? tz : currentIntl.timeZone)

/** String containing the current timezone abbreviation and offset, e.g. UTC +02:00. */
export const timezoneAbbrOffset = (ms, tz) => {
  const abbr = addTimezone(momentTz(ms), tz).format('zz')
  const offset = addTimezone(momentTz(ms), tz).format('Z')
  return { abbr, offset }
}

/** As timezoneAbbrOffset(), but as a string with abbreviation and offset. */
export const timezoneAbbrOffsetStr = (ms, tz) => {
  const { abbr, offset } = timezoneAbbrOffset(ms, tz)
  return `${abbr} ${offset}`
}

/** String containing the current timezone offset, e.g. UTC +02:00. */
export const timezoneName = (withOffset, tz) => {
  const { abbr, offset } = timezoneAbbrOffset(0, tz)
  return `${getTimezoneName(isString(tz) ? abbr : currentIntl.tzAbbr)}${
    withOffset ? ` ${abbr} ${offset}` : ''
  }`
}

/**
 * Returns the currency symbol for a given locale.
 * TODO: very hackish method, must be some better way to get this.
 *
 * @param {String} currency in human readable form
 */
export const getCurrencySymbol = currency =>
  (currency &&
    currentIntl
      .formatNumber(0, {
        style: 'currency',
        useGrouping: false,
        currency,
      })
      // Take out all commas, periods, zeroes and spaces.
      // That should leave us with only the currency symbol.
      .replace(/,|\.|0| /g, '')) ||
  currentIntl.currencyCode

/**
 * Returns the currency symbol for a given locale.
 * If the given string is not a valid currency, the original string will be returend.
 */
export const getSafeCurrencySymbol = currency => {
  let output = currency
  try {
    output = getCurrencySymbol(currency)
    // Note: empty catch is unavoidable since you can't omit it.
    // eslint-disable-next-line no-empty
  } catch {}
  return output
}

/**
 * Returns a formatted human readable time string. Meant to be bound with
 * a default value for 'options'.
 *
 * @param {Object} showTime Whether to display time
 * @param {Number} ts Timestamp as any time format Moment will accept
 * @param {String|Boolean} tz Display timezone, if string, display in specific timezone
 * @returns {String} Human readable time string
 */
const formatlocaleDate = (showTime, ts, tz) =>
  addTimezone(moment(ts), isString(tz) ? tz : null).format(
    `L, HH:mm${showTime ? ':ss' : ''}${tz ? ' zz Z' : ''}`,
  )

/**
 * Returns a formatted currency string. Takes the amount and a denomination
 * in ISO 4217 form (e.g. GBP). A full list of currencies is available
 * here: http://www.currency-iso.org/en/home/tables/table-a1.html
 *
 * @returns {String} Currency in human readable form
 */
export const formatCurrency = (amount, currency = currentIntl.currencyCode) => {
  if (amount == null) return '—'
  // eslint-disable-next-line no-restricted-globals
  const val = isNaN(Number(amount)) ? 0 : Number(amount)
  return currentIntl.formatNumber(val, { style: 'currency', currency })
}

/**
 * Formats a duration using Moment.js and returns it as a human readable string.
 *
 * @param {Number} ms Number of milliseconds of the duration
 * @returns {String} Human readable duration of the given milliseconds
 */
export const formatDuration = ms => moment.duration(ms).humanize()

/**
 * Formats a duration using Moment.js and returns it as a "hh:mm:ss" string.
 *
 * @param {Number} ms Number of milliseconds of the duration
 * @returns {String} Duration of the given milliseconds
 */
export const formatDurationAsTime = ms => moment.utc(ms).format('H:mm:ss')

/** Formats a number of seconds as hours:minutes. Used for 'missing time' payments. */
export const formatMissingTimeDuration = s => {
  // Greater that 24 hours; this is unlikely to happen though.
  if (s >= 86400) {
    return moment.duration(s * 1000).format('HH:mm')
  } else {
    return moment.utc(s * 1000).format('HH:mm')
  }
}

/**
 * This adds the central timezone to a datetime. This must ONLY be used if:
 *
 *    * the datetime came from a 'datetime-local' field
 *    * that field was given a value without timezone, from removeTimezone()
 *
 * In all other cases you'll be corrupting the actual value of the datetime.
 */
export const assumeTimezone = (t, offset) =>
  `${moment(t).format('YYYY-MM-DDTHH:mm:ss.SSS')}${offset || currentIntl.tzOffset}`

/**
 * Formats a week string (e.g. 2018-W2) to a Moment object.
 *
 * @param {String} week Week string in format 2018-W2
 * @returns {Moment} Moment object
 */
export const weekToMoment = week => {
  const parts = week.replace('-', '')
  return moment(parts).day('Monday')
}

/**
 * Turns a timestamp into a formatted date string per the standard locale.
 *
 * @param {Number} ts Timestamp as any format Moment will accept
 * @returns {String} Human readable datetime string
 */
export const formatDatetime = formatlocaleDate.bind(this, true)

/**
 * Turns a timestamp into a formatted date string per the standard locale.
 *
 * @param {Number} ts Timestamp as any format Moment will accept
 * @returns {String} Human readable datetime string
 */
export const formatDate = str => addTimezone(moment(str)).format('L')

/** Format bytes as human readable string. */
export const formatBytes = sizeInBytes => prettyBytes(sizeInBytes).toUpperCase()

/**
 * Turns a timestamp into a date string per locale with relative time included.
 */
export const formatLocalDateAndRelative = str => {
  const m = addTimezone(moment(str))
  return `${m.format('L LT')} (${m.fromNow()})`
}

/**
 * Formats a standard timestamp in ISO 8601 using our central timezone.
 * e.g. 2018-04-23T14:30:00+01:00
 * This should be used for all time formatting that is processed by code or saved in the database.
 */
export const formatShortDate = (ts, trimYear = true, tz) => {
  const shortMonthAndYear = addTimezone(moment(ts), tz).format('ll')
  if (trimYear) {
    const year = addTimezone(moment(ts)).format('Y')
    return shortMonthAndYear.split(year).join('').trim()
  } else {
    return shortMonthAndYear
  }
}

/**
 * Returns only the year of a timestamp.
 */
export const formatYear = ts => addTimezone(moment(ts)).format('Y')

/**
 * Formats a standard timestamp in ISO 8601 using our central timezone.
 * e.g. 2018-04-23T14:30:00+01:00
 * This should be used for all time formatting that is processed by code or saved in the database.
 */
export const formatDatetime8601Tz = ts => addTimezone(moment(ts)).format(`YYYY-MM-DDTHH:mm:ssZ`)
export const formatISO8601 = ts => moment(ts).format(`YYYY-MM-DDTHH:mm:ss.SSSZ`)

/**
 * Formats a standard timestamp in ISO 8601 using our central timezone,
 * without actually including the timezone. This is used e.g. in the DataDatetime component.
 */
export const formatDatetime8601 = (ts, tz) => addTimezone(moment(ts), tz).format(`YYYY-MM-DDTHH:mm:ss`)

/**
 * Turns a timestamp number into a formatted time string.
 *
 * @param {Number} ts Timestamp as milliseconds since January 1, 1970
 * @param {Boolean} sec Whether to display seconds or not
 * @returns {String} Human readable datetime string
 */
export const formatTime = (date, sec = true) => addTimezone(moment(date)).format(sec ? 'HH:mm:ss' : 'HH:mm')

/**
 * Returns start of ISO week (Mon) for the date
 *
 * @param {Date} date
 * @returns string
 */
export const getStartOfISOWeek = date => addTimezone(moment(date).startOf('isoWeek').format())

/**
 * Returns relative date string, e.g. '2 days ago'.
 *
 * @param {Date} date
 * @returns string in format like '2 days ago'.
 */
export const formatRelative = date => addTimezone(moment(date)).fromNow()

/**
 * Returns a time string in format '31/08/2018, 17:49:02 (BST +01:00) (29 minutes ago)'
 */
export const formatTimeAndRelative = date => {
  const rel = formatRelative(date)
  const dateString = addTimezone(moment(date)).format(`YYYY-MM-DD, HH:mm:ss (zz Z)`)
  return `${dateString} (${rel})`
}

/**
 * As formatTimeAndRelative() but returns only a date.
 */
export const formatDateAndRelative = date => {
  const rel = formatRelative(date)
  // Don't add a timezone. This will cause the wrong date to be shown.
  const dateString = moment(date).format(`YYYY-MM-DD`)
  return `${dateString} (${rel})`
}

/**
 * Turns a datetime object into a YYYY-MM-DD HH:mm:ss formatted date string
 *
 * @param {String} datetime string
 * @returns {String} Date in YYYY-MM-DD HH:mm:ss format
 */
export const formatISODateTime = date => addTimezone(moment(date)).format('YYYY-MM-DD HH:mm:ss')

/**
 * Same as formatISODateTime() but without seconds.
 *
 * @param {String} datetime string
 * @returns {String} Date in YYYY-MM-DD HH:mm format
 */
export const formatISODateTimeNoSecs = date => addTimezone(moment(date)).format('YYYY-MM-DD HH:mm')

/**
 * Turns a datetime object into a YYYY-MM-DD formatted date string
 *
 * @param {String} datetime string
 * @returns {String} Date in YYYY-MM-DD format
 */
export const formatISODate = date => addTimezone(moment(date)).format('YYYY-MM-DD')

/**
 * Turns a datetime string or Moment object into a YYYY-MM-DD formatted date string without
 * timezone information.
 *
 * @param {String} datetime string
 * @returns {String} Date in YYYY-MM-DD format
 */
export const formatNoTzISODate = date => moment.parseZone(date).format('YYYY-MM-DD')

/** Returns a formatted employer name (either the brand name or employer name). */
export const getEmployerName = employerObj =>
  get(employerObj, 'brand_name', get(employerObj, 'company_name', null))

/**
 * Transforms an array of objects containing {id: 1, ...} into an object with the ID as key.
 * @param {Array} entityList List of entities (users, workers or employers)
 */
export const sortByID = entityList =>
  entityList.reduce((obj, item) => Object.assign({}, obj, { [item.id]: item }), {})

/**
 * Returns a formatted geolocation string. Geolocation coordinates are rounded to four digits
 * for display purposes. If the geolocation object does not have coordinates, null is returned.
 *
 * @param {Object} geo API geolocation object containing { latitude, longitude }.
 * @returns {string} String displaying the geolocation
 */
export const formatGeolocation = ({ latitude, longitude }) => {
  if (!latitude || !longitude) {
    return null
  }
  return `${latitude.toFixed(4)}; ${longitude.toFixed(4)}`
}

/**
 * Formats a user's full name using the standard API structure { first_name, last_name }.
 * @param {Object} user Username object from the API
 * @returns {string} User's full name
 */
export const formatFullName = user => user && compact([user.first_name, user.last_name]).join(' ')

// Datetime format for backend requests, enriched with browser's time zone
// Example: 2017-11-13T18:00:00+03:00
export const toLocalISO8601 = datetime => moment(datetime).local().format()

// Return Example: 23:25
export const to24hTimeFormat = (datetime, tz) => addTimezone(moment(datetime), tz).format('HH:mm')

// brings 1234567 to 1,234,567 kind
export const toLocaleString = val => val && Number(val).toLocaleString()

// Ensures a number is at least two values in size.
export const zeroPad = obj => {
  const str = String(obj)
  return str.length > 1 ? str : `${'0'.repeat(2 - str.length)}${str}`
}

export const getShortDateFormat = () => {
  const data = moment.localeData()
  return data.longDateFormat('L')
}

// Format with attribute short returns format HH:mm
export const getTimeFormat = short => {
  const data = moment.localeData()
  const type = short ? 'LT' : 'LTS'
  return data.longDateFormat(type)
}

// Format DD/MM/YYYY HH:mm
export const getShorterDateTimeFormat = () => `${getShortDateFormat()} ${getTimeFormat(true)}`

// Format DD/MM/YYYY HH:mm:ss
export const getShortDateTimeFormat = () => `${getShortDateFormat()} ${getTimeFormat()}`

/**
 * Formats an array of objects with attribust prop and value to the obejct without props null or minus one or undefined
 * @param {Array} arr prop array of objects with prop and value
 * @returns {Object} reduced object without props -1, null or undefined
 */
export const removeEmptyNullOrMinusOne = obj => {
  return Object.entries(obj).reduce((acc, [key, value]) => {
    if (value && value > -1) {
      acc[key] = value
    }
    return acc
  }, {})
}

/**
 * Parses phone number
 *
 * @param value
 * @param localCountryCode
 * @returns {{number: E164Number, countryCode: CountryCode, national: string, formattedLocal: (string), international: string}|{number: *, formattedValue: *}}
 */
export const toPhoneNumberParts = (value, localCountryCode = currentIntl.countryCode) => {
  const number = String(value || '')
  const parsed = parsePhoneNumberFromString(number, localCountryCode)

  if (!parsed) {
    // If libphonenumber-js can't make sense out of the number, return it verbatim.
    return {
      number: value,
      localFormat: value,
      nationalFormat: value,
      internationalFormat: value,
    }
  }
  // Use local formatting if parsed number is the same as the ENV country code
  // Otherwise use international
  const national = parsed.formatNational()
  const international = parsed.formatInternational()
  return {
    number: parsed.number,
    countryCallingCode: parsed.countryCallingCode,
    nationalFormat: national,
    internationalFormat: international,
    localFormat: parsed.country === localCountryCode ? national : international,
    countryCode: parsed.country,
  }
}

/**
 *
 * @param {String|Number} number - String or number to format
 *
 * @returns String with rounded to two digits
 */

export const fixToTwodecimals = number => Number(number).toFixed(2)

/**
 *
 * @param {Number} day
 * @returns {string}
 */
export const formatWeekDay = day => moment().day(day).format('dddd')

export const formatWorkerLink = obj => {
  const link = `${config.apiConfig.apiBaseUrl}inspector/users/${obj.user_id}`

  return (
    <a href={link} target="_blank" rel="noreferrer">
      {obj.user_id}
    </a>
  )
}

const getTierColor = code => {
  if (code === 'bronze') {
    return '#CFA06E'
  }
  if (code === 'silver') {
    return '#D4D4D4'
  }
  if (code === 'gold') {
    return '#F6CA69'
  }
  if (code === 'platinum') {
    return '#DFE5E6'
  }
  if (code === 'ban_review') {
    return '#FF5252'
  }
  if (code === 'ban_warning') {
    return '#F6C956'
  }
  return '#FB73DA'
}

export const formatWorkerFlexLink = (name, code, workerID, country) => {
  if (name) {
    const link = `https://flex-redash.indeed.tech/queries/24494?p_Worker%20ID=${workerID}${country}`

    const color = getTierColor(code)
    return (
      <a href={link} target="_blank" rel="noreferrer">
        <TypeLabel color={color} name={name} />
      </a>
    )
  }
  return 'Not enrolled'
}
