import * as types from './action-types'

export const offerBooking = payload => ({
  type: types.BOOKINGS_OFFER_BEGIN,
  payload,
})

export const offerBulkBooking = payload => ({
  type: types.BOOKINGS_BULK_OFFER_BEGIN,
  payload,
})

export const deleteBooking = payload => ({
  type: types.BOOKINGS_DELETE_BEGIN,
  payload,
})

export const endAssignmentWorker = (payload, blockWorker, employerId, venueId) => ({
  type: types.END_ASSIGNMENT_BEGIN,
  payload,
  blockWorker,
  employerId,
  venueId,
})

export const blockWorkerForClient = (workerId, employerId, venueId) => ({
  type: types.BLOCK_WORKER_CLIENT_BEGIN,
  workerId,
  employerId,
  venueId,
})

export const addBooking = payload => ({
  type: types.BOOKING_ADD,
  payload,
})

export const updateBooking = payload => ({
  type: types.BOOKING_UPDATE,
  payload,
})

export const replaceBooking = payload => ({
  type: types.BOOKINGS_REPLACE_BEGIN,
  payload,
})

export const saveBookings = payload => ({
  type: types.BOOKINGS_SAVE_BEGIN,
  payload,
})

export const failSaveBookings = payload => ({
  type: types.BOOKINGS_SAVE_FAILED,
  payload,
})

export const successSaveBookings = payload => ({
  type: types.BOOKINGS_SAVE_SUCCEEDED,
  payload,
})

export const updateBookingChanges = payload => ({
  type: types.BOOKINGS_UPDATE_CHANGES,
  payload,
})

export const cancelBookingChanges = payload => ({
  type: types.BOOKINGS_CANCEL_CHANGES,
  payload,
})

export const removeBookingChanges = payload => ({
  type: types.BOOKINGS_REMOVE_CHANGES,
  payload,
})

export const createBookingChanges = payload => ({
  type: types.BOOKINGS_CREATE_CHANGES,
  payload,
})

export const replaceBookingChanges = payload => ({
  type: types.BOOKINGS_REPLACE_CHANGES,
  payload,
})

export const fetchGlobalShiftBookings = ({ options }) => ({
  type: types.GLOBAL_SHIFT_BOOKINGS_BEGIN,
  options,
})
