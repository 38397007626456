import { DefaultRootState } from 'react-redux'
import { createSelector } from 'reselect'
import { get, memoize } from 'lodash-es'

import { WorkerEntity } from './types'

/**
 * Simple selector for retrieving a worker.
 */
export const getWorker$ = createSelector(
  // TODO: change this to accept root state as first arg and refactor all usages of selector
  (state: DefaultRootState['workers']) => state,
  workers =>
    memoize((id: number) => {
      const fromDetail = get(workers, `entityDetail[${id}]`, {})
      const fromMap = get(workers, `entityMap[${id}]`, {})
      return { ...fromDetail, ...fromMap } as WorkerEntity
    })
)
