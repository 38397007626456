import React from 'react'
import PropTypes from 'prop-types'
import { Glyphicon } from 'react-bootstrap'
import ReactTooltip from 'react-tooltip'

import iconFactory from 'syft-acp-atoms/Icons'
import { formatGeolocation } from 'syft-acp-util/formatting'
import { googleMapsLink } from 'syft-acp-util/gmaps'
import { emptyCellPlaceholder } from 'syft-acp-core/components/EntityList'

import './ShiftBookingGeoLocation.css'

const tip = 'Click to open map in new window'
const tooltipID = 'geo-location-tooltip'
const IconCheck = iconFactory('link-external', 'View location')

const ShiftBookingGeoLocation = ({ geoLocation = {}, small = false }) => {
  if (!geoLocation || !geoLocation.latitude || !geoLocation.longitude) {
    // No location available. Display placeholder.
    return <div className="geo-location-cell padded disabled-link">{emptyCellPlaceholder}</div>
  }
  if (small) {
    return (
      <div className="geo-location-cell">
        <ReactTooltip effect="solid" id={tooltipID} />
        <a
          data-tip={`${tip} - ${formatGeolocation(geoLocation)}`}
          data-for={tooltipID}
          href={googleMapsLink(geoLocation)}
          target="_blank"
          rel="noopener noreferrer"
          style={{ textDecoration: 'none' }}
        >
          <IconCheck />
        </a>
      </div>
    )
  } else {
    return (
      <div className="geo-location-cell">
        <ReactTooltip effect="solid" id={tooltipID} />

        <a
          data-tip={tip}
          data-for={tooltipID}
          href={googleMapsLink(geoLocation)}
          target="_blank"
          rel="noopener noreferrer"
          style={{ textDecoration: 'none' }}
        >
          <Glyphicon glyph="map-marker" />
          &nbsp;
          <span>{formatGeolocation(geoLocation)}</span>
        </a>
      </div>
    )
  }
}

ShiftBookingGeoLocation.propTypes = {
  geoLocation: PropTypes.object,
  small: PropTypes.bool,
}

export default ShiftBookingGeoLocation
