import { cloneDeep, set } from 'lodash-es'

export const updatedShiftBookingEntityMap = (entityMap, payload) => {
  const { id, attr, val } = payload
  const entity = entityMap[id]
  // TODO: remove this cloneDeep() call. We need it because otherwise we modify the old state.
  const newShiftBooking = cloneDeep(entity)
  set(newShiftBooking, attr, val)

  return {
    ...entityMap,
    [id]: newShiftBooking,
  }
}
