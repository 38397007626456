import React from 'react'
import PropTypes from 'prop-types'

import ListingDetail from 'syft-acp-core/entities/ListingDetail/ListingDetail'

const ListingView = ({ match: { params } }) => (
  <ListingDetail
    id={Number(params.id)}
    jobID={params.jobID ? Number(params.jobID) : 0}
    shiftID={params.shiftID ? Number(params.shiftID) : 0}
  />
)

ListingView.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }).isRequired,
  }),
}

export default ListingView
