// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import { REHYDRATE } from 'redux-persist/constants'
import config from 'syft-acp-core/config'
import * as types from 'syft-acp-core/actions/action-types'

const initialState = {
  version: null,
  countryCode: config.region,
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.AUTH_DELETE:
      return initialState
    case types.APP_SAVE_CURRENT_VERSION:
      return {
        ...state,
        version: action.version,
      }
    case types.APP_SET_COUNTRY_CODE:
      return {
        ...state,
        countryCode: action.countryCode,
      }
    case REHYDRATE:
      return state
    default:
      return state
  }
}

export default reducer
