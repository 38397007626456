import { takeLatest, put } from 'redux-saga/effects'
import { takeLatestApiCall } from 'syft-acp-core/sagas/helpers'
import { notificationCall } from 'syft-acp-core/sagas/calls'
import { hideModal } from 'syft-acp-core/store/modals/actions'

import { WorkerReferralBonusModals } from 'syft-acp-core/entities/WorkerDetail/WorkerReferralBonuses/WorkerReferralBonuses.constants'

import * as api from './api'
import types from './action-types'

function* closeAddModal() {
  yield put(hideModal(WorkerReferralBonusModals.AddReferralBonus))
}

export default function* root() {
  yield takeLatestApiCall(types.ENTITIES_FETCH_BEGIN, api.fetchWorkerReferralBonuses)
  yield takeLatestApiCall(types.ENTITY_CREATE_BEGIN, api.saveWorkerReferralBonus)
  yield takeLatestApiCall(types.ENTITIES_DELETE_BEGIN, api.deleteWorkerReferralBonuses)
  yield takeLatest([types.ENTITY_CREATE_SUCCEEDED, types.ENTITY_CREATE_FAILED], notificationCall)
  yield takeLatest(types.ENTITY_CREATE_SUCCEEDED, closeAddModal)
}
