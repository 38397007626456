import React, { useCallback, useEffect } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { flowRight } from 'lodash-es'
import deepEqual from 'react-fast-compare'
import Acp from 'syft-acp-uikit'

import { createEntityListConnector } from 'syft-acp-util/entityList'
import usePrevValue from 'syft-acp-util/hooks/usePrevValue'
import { downloadAutoOfferRateCSV, fetchAutoOfferRate } from 'syft-acp-core/store/autoOfferRates/actions'
import { AutoOfferRate } from 'syft-acp-core/store/autoOfferRates/types'

import {
  FinanceAutoOfferRateConnectorProps as EntityConnectorProps,
  FinanceAutoOfferRateProps,
  FinanceAutoOfferRateQuery,
  FinanceAutoOfferRateRow as RowData,
} from './FinanceAutoOfferRate.types'

import { trackingEvents } from './tracking'
import { useTrackingTrigger } from '@indeed/flex-tracking-context'

export const entityConnector = createEntityListConnector<AutoOfferRate>({
  entityActions: {
    fetchEntities: fetchAutoOfferRate,
  },
  entityStore: 'autoOfferRates',
})

export const storeConnector = connect(null, (dispatch, props: EntityConnectorProps) => ({
  actions: { ...props.actions, ...bindActionCreators({ downloadAutoOfferRateCSV }, dispatch) },
}))

const FinanceAutoOfferRate = ({ actions, data, query, isLoadingData }: FinanceAutoOfferRateProps) => {
  const triggerEvent = useTrackingTrigger()
  const prevQuery = usePrevValue(query)

  const fetch = useCallback(
    (q: FinanceAutoOfferRateQuery) => {
      actions.fetchEntities && actions.fetchEntities({ options: q })
    },
    [actions]
  )

  useEffect(() => {
    if (!deepEqual(prevQuery, query)) {
      fetch(query)
    }
  }, [query, prevQuery])

  return (
    <Acp.EntityList hasResultCount hasPagination idKeyValue="id" isLoadingData={isLoadingData} data={data}>
      <Acp.Actions>
        {/*
          -city_id
          -tier
          -role_id
          */}
        <Acp.FilterGroup title="Employer:">
          <Acp.Filter.Text name="employer_id" placeholder="Employer ID" />
        </Acp.FilterGroup>
        <Acp.FilterGroup title="Start/end time:">
          <Acp.Filter.DateRange nameFrom="start_time_gte" nameTo="start_time_lte" />
        </Acp.FilterGroup>

        <Acp.Button
          onClick={() => {
            triggerEvent(trackingEvents.DOWNLOAD_CSV.CLICKED, {
              employer_id: query.employer_id,
              start_time_gte: query.start_time_gte,
              start_time_lte: query.start_time_lte,
            })
            actions.downloadAutoOfferRateCSV({ options: query as FinanceAutoOfferRateQuery })
          }}
          kind="primary"
        >
          Download CSV
        </Acp.Button>
      </Acp.Actions>
      <Acp.Table rowLink={(row: RowData) => `/listings/view/${row.listing_id}/job/${row.job_id}/shift/${row.shift_id}`}>
        <Acp.Col.Text value="id" header="ID" isMinimal isNumeric />
        <Acp.Col.Employer
          value="employer"
          header="Employer"
          colLink={(row: RowData) => `/entity/employers/view/${row.employer.id}`}
          options={{ hideAvatar: true }}
        />
        <Acp.Col.Text
          value="venue.name"
          header="Venue"
          colLink={(row: RowData) => `/entity/employers/view/${row.employer.id}/venues/view/${row.venue.id}`}
        />
        <Acp.Col.Enum value="tier" header="Tier">
          <Acp.Enum slug="1" color="blue-light">
            Tier 1 (≥2000 hrs/month)
          </Acp.Enum>
          <Acp.Enum slug="2" color="cyan-light">
            Tier 2 (1000-2000 hrs/month)
          </Acp.Enum>
          <Acp.Enum slug="3" color="blue">
            Tier 3 (250-1000 hrs/month)
          </Acp.Enum>
          <Acp.Enum slug="4" color="cyan">
            Tier 4 (≤250 hrs/month)
          </Acp.Enum>
        </Acp.Col.Enum>
        <Acp.Col.Text value="shift_booking_id" header="B.ID" headerTooltip="Booking ID" isMinimal isNumeric />
        <Acp.Col.RoleSkill value="role_id" options={{ onlyRoleTitle: true }} header="Role" isMinimal />
        <Acp.Col.RoleSkill value="role_id" options={{ onlySubindustry: true }} isMinimal />
        <Acp.Col.Text
          value="shifts_on_day_count"
          header="S"
          headerAbbr
          headerTooltip="Shifts on day"
          isMinimal
          isNumeric
        />
        <Acp.Col.Text
          value="empty_shifts_before_start_time_count"
          header="E"
          headerAbbr
          headerTooltip="Number of empty shifts before start time"
          isMinimal
          isNumeric
        />
        <Acp.Col.Money value="worker_pay_rate" header="Worker pay rate" isMinimal />
        <Acp.Col.Money value="client_pay_rate" header="Client pay rate" isMinimal />
        <Acp.Col.DateTime
          value={['start_time', 'end_time']}
          header="Start/end time"
          options={{
            timeZone: 'venue.timezone',
          }}
        />
        <Acp.Col.Money value="syft_ops_invoiced_amount" header="Amount being invoiced to Flex Ops" isMinimal />
      </Acp.Table>
    </Acp.EntityList>
  )
}

export default flowRight(entityConnector, storeConnector)(FinanceAutoOfferRate)
