export const NEW_EMPLOYER_RATE_CARDS_FETCH_BEGIN = 'syftacp/NEW_EMPLOYER_RATE_CARDS_FETCH_BEGIN' as const
export const NEW_EMPLOYER_RATE_CARDS_FETCH_FAILED = 'syftacp/NEW_EMPLOYER_RATE_CARDS_FETCH_FAILED' as const
export const NEW_EMPLOYER_RATE_CARDS_FETCH_SUCCEEDED = 'syftacp/NEW_EMPLOYER_RATE_CARDS_FETCH_SUCCEEDED' as const

export const NEW_EMPLOYER_RATE_CARD_FETCH_BEGIN = 'syftacp/NEW_EMPLOYER_RATE_CARD_FETCH_BEGIN' as const
export const NEW_EMPLOYER_RATE_CARD_FETCH_FAILED = 'syftacp/NEW_EMPLOYER_RATE_CARD_FETCH_FAILED' as const
export const NEW_EMPLOYER_RATE_CARD_FETCH_SUCCEEDED = 'syftacp/NEW_EMPLOYER_RATE_CARD_FETCH_SUCCEEDED' as const

export const NEW_EMPLOYER_RATE_CARD_SAVE_BEGIN = 'syftacp/NEW_EMPLOYER_RATE_CARD_SAVE_BEGIN' as const
export const NEW_EMPLOYER_RATE_CARD_SAVE_FAILED = 'syftacp/NEW_EMPLOYER_RATE_CARD_SAVE_FAILED' as const
export const NEW_EMPLOYER_RATE_CARD_SAVE_SUCCEEDED = 'syftacp/NEW_EMPLOYER_RATE_CARD_SAVE_SUCCEEDED' as const

export const NEW_EMPLOYER_RATE_CARD_DISABLE_BEGIN = 'syftacp/NEW_EMPLOYER_RATE_CARD_DISABLE_BEGIN' as const
export const NEW_EMPLOYER_RATE_CARD_DISABLE_FAILED = 'syftacp/NEW_EMPLOYER_RATE_CARD_DISABLE_FAILED' as const
export const NEW_EMPLOYER_RATE_CARD_DISABLE_SUCCEEDED = 'syftacp/NEW_EMPLOYER_RATE_CARD_DISABLE_SUCCEEDED' as const

export const NEW_EMPLOYER_RATE_CARD_ENABLE_BEGIN = 'syftacp/NEW_EMPLOYER_RATE_CARD_ENABLE_BEGIN' as const
export const NEW_EMPLOYER_RATE_CARD_ENABLE_FAILED = 'syftacp/NEW_EMPLOYER_RATE_CARD_ENABLE_FAILED' as const
export const NEW_EMPLOYER_RATE_CARD_ENABLE_SUCCEEDED = 'syftacp/NEW_EMPLOYER_RATE_CARD_ENABLE_SUCCEEDED' as const

export const NEW_EMPLOYER_RATE_CARD_BULK_DISABLE_BEGIN = 'syftacp/NEW_EMPLOYER_RATE_CARD_BULK_DISABLE_BEGIN' as const
export const NEW_EMPLOYER_RATE_CARD_BULK_DISABLE_FAILED = 'syftacp/NEW_EMPLOYER_RATE_CARD_BULK_DISABLE_FAILED' as const
export const NEW_EMPLOYER_RATE_CARD_BULK_DISABLE_SUCCEEDED =
  'syftacp/NEW_EMPLOYER_RATE_CARD_BULK_DISABLE_SUCCEEDED' as const

export const NEW_EMPLOYER_RATE_CARD_BULK_ENABLE_BEGIN = 'syftacp/NEW_EMPLOYER_RATE_CARD_BULK_ENABLE_BEGIN' as const
export const NEW_EMPLOYER_RATE_CARD_BULK_ENABLE_FAILED = 'syftacp/NEW_EMPLOYER_RATE_CARD_BULK_ENABLE_FAILED' as const
export const NEW_EMPLOYER_RATE_CARD_BULK_ENABLE_SUCCEEDED =
  'syftacp/NEW_EMPLOYER_RATE_CARD_BULK_ENABLE_SUCCEEDED' as const
