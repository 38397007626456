import * as React from 'react'
import { Col, Grid, Row } from 'react-bootstrap'

import PageView from 'syft-acp-core/components/PageView'
import ConductReviewList from 'syft-acp-core/entities2/ConductReviewList'

import ConductSubNav from './ConductSubNav'

export const ConductReviewsList = () => (
  <>
    <ConductSubNav />
    <PageView title={['Conduct reviews', 'Viewing conduct reviews']}>
      <Grid fluid>
        <Row>
          <Col md={12}>
            <ConductReviewList />
          </Col>
        </Row>
      </Grid>
    </PageView>
  </>
)
