// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Grid, Col, Row } from 'react-bootstrap'
import { get, isEmpty } from 'lodash-es'
import classnames from 'classnames'

import entityDetail from 'syft-acp-util/entityDetail'
import NotifyBar from 'syft-acp-atoms/NotifyBar'
import * as usersActions from 'syft-acp-core/store/users/actions'
import RecordSection from 'syft-acp-core/components/RecordSection'
import SiteComponent from 'syft-acp-core/components/SiteComponent'
import * as structureTypes from 'syft-acp-core/components/EditableTable'

import UserDetailControls from './UserDetailControls'

const structure = {
  basicData: [
    ['id', { header: 'User ID' }],
    ['email', { header: 'Email' }],
  ],
  furtherData: [
    ['main_profile_type', { header: 'Profile type' }],
    ['referral_code', { header: 'Referral code' }],

    ['', { type: structureTypes.TYPE_SEPARATOR }],

    ['platform.id', { header: 'Platform ID' }],
    ['platform.name', { header: 'Platform name' }],
    [
      'platform.internal_resourcing',
      { header: 'Internal resourcing', val: data => (data.platform.internal_resourcing ? 'Yes' : 'No') },
    ],
    [
      'platform.support_details.name',
      {
        header: 'Support email',
        val: data => `${data.platform.support_details.name} (${data.platform.support_details.email})`,
      },
    ],
    ['platform.support_details.telephone_number', { header: 'Support tel.', type: structureTypes.TYPE_PHONE_NUMBER }],
  ],
}

const dataPropTypes = PropTypes.shape({
  id: PropTypes.number,
  email: PropTypes.string,
  admin: PropTypes.bool,
})

class UserDetail extends Component {
  static propTypes = {
    id: PropTypes.number.isRequired,
    data: dataPropTypes.isRequired,
    actionUpdate: PropTypes.func.isRequired,
    isPristine: PropTypes.bool.isRequired,
    actions: PropTypes.objectOf(PropTypes.func).isRequired,
    userData: PropTypes.object,
  }

  static defaultProps = {
    userData: null,
  }

  constructor(props) {
    super()
    props.actions.fetchUser(props.id)
  }

  componentDidMount() {
    this.props.actions.fetchUser(this.props.id)
  }

  render() {
    const { data, userData, isPristine, actionUpdate } = this.props
    const isCurrentlyLoggedInUser = get(data, 'id') === get(userData, 'userData.id', get(userData, 'id'))
    const isLoading = isEmpty(data)
    const isBanned = !!get(data, 'banned')
    return (
      <SiteComponent className={classnames({ 'is-loading': isLoading })}>
        <Grid>
          <Row>
            <Col md={12} sm={12}>
              <UserDetailControls isPristine={isPristine} isCurrentlyLoggedInUser={isCurrentlyLoggedInUser} />
              <NotifyBar conditional visible={isBanned}>
                This user is currently banned from the system and cannot be assigned to do any jobs.
              </NotifyBar>
              {!isCurrentlyLoggedInUser && (
                <RecordSection
                  title="User information"
                  data={data}
                  structure={structure.basicData}
                  update={actionUpdate}
                />
              )}
              {isCurrentlyLoggedInUser && (
                <div>
                  <RecordSection
                    data={data}
                    structure={structure.basicData}
                    update={actionUpdate}
                    nameValue={userData.worker && userData.worker.name}
                    nameBio={userData.worker && userData.worker.bio}
                    nameAvatar={userData.worker && userData.worker.profile_picture.uuid}
                    showName={!!userData.worker}
                  />
                  <RecordSection data={userData} structure={structure.furtherData} update={actionUpdate} />
                </div>
              )}
            </Col>
          </Row>
        </Grid>
      </SiteComponent>
    )
  }
}

export default entityDetail(
  'users',
  connect(
    state => ({
      userData: state.auth.userData,
    }),
    dispatch => ({
      actions: bindActionCreators(usersActions, dispatch),
    })
  )(UserDetail)
)
