import * as React from 'karet'
import * as U from 'karet.util'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import InputContainer from 'syft-acp-atoms/InputContainer'

import './InputCheckbox.css'

const InputCheckbox = ({ value, className, onChange }) => (
  <InputContainer>
    {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
    <label className="acp-input-checkbox-label">
      <U.Input
        onChange={onChange}
        type="checkbox"
        checked={value}
        className={classNames('acp-input', 'acp-input-checkbox', className)}
      />
    </label>
  </InputContainer>
)

InputCheckbox.propTypes = {
  value: PropTypes.shape({
    _source: PropTypes.object.isRequired,
    _lens: PropTypes.any.isRequired,
  }),
  className: PropTypes.string,
  onChange: PropTypes.func,
}

InputCheckbox.defaultProps = {
  value: null,
  className: '',
}

export default InputCheckbox
