// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import React from 'react'

import PageTitle from 'syft-acp-core/components/PageTitle'
import ErrorLog from 'syft-acp-core/components/ErrorLog'

import './EntityList.css'

const ErrorLogPage = () => (
  <PageTitle title="Error log">
    <ErrorLog />
  </PageTitle>
)

export default ErrorLogPage
