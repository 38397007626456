// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import * as types from './action-types'

/**
 * Initiates a app ratings list fetching process.
 * @param {Object} options Other options
 */
export function fetchWorkerFeedback({ options }) {
  return { type: types.WORKER_FEEDBACK_FETCH_BEGIN, options }
}
