import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { get } from 'lodash-es'
import moment from 'moment'
import 'moment-duration-format'
import DataText from 'syft-acp-core/components/EditableTable/DataText'
import EntityListInputWrapper from 'syft-acp-core/components/EntityList/EntityListInputWrapper'
import { updateBooking, updateBookingChanges } from 'syft-acp-core/actions/bookings'

class ListingInput extends PureComponent {
  onChange = value => {
    const { attr, dispatch, shiftBooking, type, isDuration } = this.props
    let val = type === 'number' ? Number(value) : value
    const { id } = shiftBooking

    if (isDuration) {
      val = moment.duration(value).asSeconds()
    }

    dispatch(updateBooking({ id, attr, val }))
    dispatch(updateBookingChanges({ id, attr, val }))
  }

  /**
   * Returns the value for the field.
   * If the value is a duration, convert it to our format using Moment.
   * TODO: this is hackish.
   */
  get value() {
    const { attr, shiftBooking, isDuration } = this.props
    const val = get(shiftBooking, attr)
    let value = val == null ? val : String(val)

    if (isDuration) {
      const defaultValue = Number(value)
      const seconds = defaultValue < 0 ? 0 : defaultValue
      const duration = { seconds }
      const format = 'HH:mm:ss'

      value = moment.duration(duration).format(format, { trim: false })
    }

    return value == null ? '–' : value
  }

  render() {
    return (
      <EntityListInputWrapper>
        <DataText
          onChange={this.onChange}
          value={this.value}
          editable
          header={this.props.placeholder}
          type={this.props.type}
          disabled={this.props.disabled}
          step={this.props.step}
        />
      </EntityListInputWrapper>
    )
  }
}

ListingInput.propTypes = {
  dispatch: PropTypes.func.isRequired,
  attr: PropTypes.string.isRequired,
  shiftBooking: PropTypes.object.isRequired,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  disabled: PropTypes.bool,
  isDuration: PropTypes.bool,
  step: PropTypes.string,
}

export default connect()(ListingInput)
