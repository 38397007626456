import * as types from './action-types'

import entityReducer from 'syft-acp-core/reducers/generators/entityReducer'
import * as entityTypes from 'syft-acp-core/reducers/generators/entities'

export default entityReducer('agencyVenues', {
  [entityTypes.ENTITIES_FETCH_BEGIN]: types.AGENCY_VENUES_FETCH_BEGIN,
  [entityTypes.ENTITIES_FETCH_SUCCEEDED]: types.AGENCY_VENUES_FETCH_SUCCEEDED,
  [entityTypes.ENTITIES_FETCH_FAILED]: types.AGENCY_VENUES_FETCH_FAILED,
})
