// Syft ACP - Lib <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import React, { useState, useMemo, useCallback, useEffect, FC } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { FormControl } from 'react-bootstrap'
import { debounce } from 'lodash-es'

import * as filtersActions from 'syft-acp-core/store/filters/actions'

import FilterUnit from '../FilterUnit'
import { setFilterValue } from './helpers'
import { FilterTextProps, Name, FilterTextConnected, ValueType } from './types'
import './FilterText.css'

export const FilterText: FC<FilterTextProps> = ({
  name,
  placeholder,
  small = false,
  min = null,
  max = null,
  minLength = null,
  tiny = false,
  actions,
  currentQuery,
  disabled = false,
  type = 'text',
  onChange,
}) => {
  const debouncedSetFilter = useMemo(
    () => debounce((filterName, filterValue) => actions.setFilter(filterName, filterValue), 300, { trailing: true }),
    [actions]
  )
  const [value, setValue] = useState<ValueType>(currentQuery || '')
  useEffect(() => {
    setValue(currentQuery)
  }, [currentQuery])
  const handleChange = useCallback(
    ev => {
      setFilterValue({
        name,
        filterValue: ev?.target?.value,
        type,
        min,
        max,
        minLength,
        setValue,
        debouncedSetFilter,
      })
      onChange && onChange(name, ev?.target?.value)
    },
    [min, max, minLength, type, debouncedSetFilter, name, onChange]
  )

  return (
    <FilterUnit small={small} tiny={tiny}>
      <FormControl
        type={type}
        value={value || ''}
        placeholder={placeholder}
        onChange={handleChange}
        disabled={disabled}
      />
    </FilterUnit>
  )
}

export default connect(
  (state: FilterTextConnected, { name }: { name: Name }) => ({
    currentQuery: state.routing.locationBeforeTransitions.query[name],
  }),
  dispatch => ({
    actions: bindActionCreators(filtersActions, dispatch),
  })
)(FilterText)
