import { apiRequest, handleCall } from 'syft-acp-core/api/call'

/**
 * Retrieves a list of employers. Implements pagination.
 *
 * @param params {Object}
 * @param params.options {Object} Other query options
 * @param params.toCSV {Boolean} Whether we will handle this as a CSV download
 */
export const listEmployers = ({ options = {}, toCSV = false }) =>
  new Promise((resolve, reject) => {
    const req = apiRequest({ path: '/admin/employers', reqArgs: options, returnAll: toCSV })
    handleCall(req, resolve, reject, true, options, null, toCSV, 'employers')
  })
/**
 * Retrieves an employer data from server.
 *
 * @param id {Number} Employer ID
 */
export const fetchEmployer = ({ id }) =>
  new Promise((resolve, reject) => {
    const req = apiRequest({ path: `/admin/employers/${id}` })
    handleCall(req, resolve, reject, true)
  })
/**
 * Sends an updated data object for an employer to the server,
 * overwriting that entity data.
 *
 * @param id {Number} ID of the entity
 * @param data {Object} new data object for Entity
 */
export const saveEmployer = ({ id, data }) =>
  new Promise((resolve, reject) => {
    const req = apiRequest({ path: `/admin/employers/${id}`, reqArgs: data, method: 'PATCH' })
    handleCall(req, resolve, reject)
  })
/**
 * Approves or unapproves employers.
 *
 * @param params {Object} Function parameters as object
 * @param params.id {Number} Employer ID
 * @param params.approved {Boolean}
 */
export const approveEmployer = ({ id, approved }) =>
  new Promise((resolve, reject) => {
    const req = apiRequest({ path: `/admin/employers/${id}/approve`, reqArgs: { approved }, method: 'PATCH' })
    handleCall(req, resolve, reject)
  })
/**
 * Migrate employers to SyftForce.
 *
 * @param params.id {Number} Employer ID
 */

export const migrateEmployerToSyftForce = ({ id }) =>
  new Promise((resolve, reject) => {
    const req = apiRequest({ path: `/admin/employers/${id}/migrate_to_syftforce`, reqArgs: { id }, method: 'PATCH' })
    handleCall(req, resolve, reject)
  })
/**
 * Retrieves a URL that can be used to instantly log in to a specific employer.
 *
 * @param id {Number} Employer ID
 * @param callback {Function} Function that will handle opening a window to the returned URL
 */
export const loginAsEmployer = ({ id, callback }) =>
  new Promise((resolve, reject) => {
    const req = apiRequest({ path: `/admin/users/login_as_employer/${id}`, reqArgs: null, method: 'POST' })
    handleCall(req, resolve, reject, false, {}, callback)
  })
