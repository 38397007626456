import React, { useState, useEffect, useReducer } from 'react'
import { connect, DefaultRootState } from 'react-redux'
import { bindActionCreators } from 'redux'
import { createSelector } from 'reselect'
import { useTrackingTrigger } from '@indeed/flex-tracking-context'

import { MonetaryAmount, ShiftEntity } from 'syft-acp-core/store/types'
import { getModal$ } from 'syft-acp-core/store/modals/selectors'
import { hideModal } from 'syft-acp-core/store/modals/actions'
import { selectShift$ } from 'syft-acp-core/store/fulfilmentShifts/selectors'
import { fetchFulfilmentShift, editFulfilmentShift } from 'syft-acp-core/store/fulfilmentShifts/actions'
import {
  ShiftFulfilmentModalState as State,
  ShiftFulfilmentModalProps as Props,
  ShiftFulfilmentModalOwnProps as OwnProps,
  ChangedProperties as ChangedPropertiesType,
  ShiftFulfilmentTrackingPayload as TrackingPayload,
} from './ShiftFulfilmentModal.types'
import {
  setShift,
  updateOfferRate,
  updateClientRate,
  updateOfferToFlex,
  updateHardSkills,
  updateBookableIndividually,
  updateStopOffers,
  updateStartTime,
  updateEndTime,
  updateFulfilmentReviewed,
} from './ShiftFulfilmentModal.actions'
import ShiftFulfilmentModalView from './ShiftFulfilmentModalView'
import { getOfferTypeOptions, getKeysOfChangedValues, getState } from './ShiftFulfilmentModal.helpers'
import { reducer } from './ShiftFulfilmentModal.reducer'
import { useFetchDisabledSkills } from 'syft-acp-core/api/resources/skills'
import { trackingEvents } from './ShiftFulfilmentModal.tracking'
import { currentSortOrder } from 'syft-acp-core/entities2/ShiftFulfilmentList/ShiftFulfilmentList.helpers'

const selectShift = (modalName: string) =>
  createSelector(
    (state: DefaultRootState) => state,
    state => getModal$(state.modals)(modalName),
    (state, item) => selectShift$(state)({ shiftID: item.data?.rowData?.id }),
  )

export const storeConnector = connect(
  (state, props: OwnProps) => ({
    item: getModal$(state.modals)(props.modalName),
    shift: selectShift(props.modalName)(state),
    allShifts: state.shifts.entityMap,
    isLoadingData: state.fulfilmentShifts.isLoadingData,
    isSavingData: state.fulfilmentShifts.isSavingData,
    auth: state.auth,
  }),
  dispatch => ({
    actions: {
      ...bindActionCreators({ hideModal, editFulfilmentShift, fetchFulfilmentShift }, dispatch),
    },
    dispatch,
  }),
)

const ShiftFulfilmentModal = ({
  actions,
  isLoadingData,
  isSavingData,
  item,
  modalName,
  shift,
  allShifts,
  dispatch: reduxDispatch,
  auth,
}: Props) => {
  const shiftID = item.data?.rowData?.id
  const trackingPayload: TrackingPayload = {
    shift_id: shiftID,
    fulfilment_review_impact_score: item.data?.rowData?.fulfilment_review_impact_score,
    sort_order: currentSortOrder(),
  }
  const initialOfferTo: any = shift?.offer_to_flex
  const initialState: State = getState(shift, shiftID)
  const initialNotesValue = initialState.fulfilment_note || ''
  const [state, dispatch] = useReducer(reducer, initialState)
  const [recentChanges, setRecentChanges] = useState<Partial<ShiftEntity>>({})
  const [initial, setInitial] = useReducer(reducer, initialState)

  const essentialSkills = shift.job?.essential_skill_ids
  const [notes, setNotes] = useState(initialNotesValue)

  const [confirm, setConfirm] = useState(false)
  const [changedShiftProperties, setChangedShiftProperties] = useState<string[]>([])
  const [changedProperties, setChangedProperties] = useState<string[]>([])
  const [changedJobProperties, setChangedJobProperties] = useState<ChangedPropertiesType<'job'>>([])
  const changedStopOffers =
    state.stop_offers_for_worker_type?.length !== initial.stop_offers_for_worker_type?.length
  const [changedListingProperties, setChangedListingProperties] = useState<ChangedPropertiesType<'listing'>>(
    [],
  )

  const { disabledSkillIds } = useFetchDisabledSkills(reduxDispatch)

  const shiftIndex = Object.keys(allShifts).indexOf(`${shiftID}`)

  const triggerEvent = useTrackingTrigger()

  useEffect(() => {
    setNotes(initialNotesValue)
  }, [initialNotesValue])

  useEffect(() => {
    shiftID && actions.fetchFulfilmentShift(shiftID)
  }, [actions, shiftID])

  // reinitialise the state when a new shift is clicked on
  useEffect(() => {
    const shiftState: State = getState(shift, shift.id)
    dispatch(setShift(shiftState))
    setInitial(setShift(shiftState))
    setChangedShiftProperties([])
    setRecentChanges({})
  }, [shift])

  useEffect(() => {
    setChangedProperties(getKeysOfChangedValues(state, initial))
    setChangedJobProperties(getKeysOfChangedValues(state.job, initial.job) as ChangedPropertiesType<'job'>)
    setChangedListingProperties(
      getKeysOfChangedValues(state.listing, initial.listing) as ChangedPropertiesType<'listing'>,
    )
  }, [state, initial])

  if (!shiftID) return null

  const isLoading = isLoadingData || isSavingData

  const toggleConfirm = () => {
    setConfirm(!confirm)
  }

  const closeModal = () => {
    if (confirm) setConfirm(false)
    triggerEvent(trackingEvents.SHIFT_FULFILMENT.SHIFT.CLOSED, trackingPayload)
    actions.hideModal(modalName)
  }

  const markAsReviewed = () => {
    triggerEvent(trackingEvents.SHIFT_FULFILMENT.NOTE.REVIEWED, {
      shift_index: shiftIndex,
      fulfilment_reviewed: !state?.fulfilment_reviewed,
      ...trackingPayload,
    })
    actions.editFulfilmentShift({
      id: state.id,
      fulfilment_reviewed: !state?.fulfilment_reviewed,
      sort_order: currentSortOrder(),
      shift_index: shiftIndex,
    })
    dispatch(updateFulfilmentReviewed(!state?.fulfilment_reviewed))
  }

  const buildShiftChangedPayload = () =>
    changedProperties
      .map((property: string) => {
        switch (property) {
          case 'job':
            return changedJobProperties.map(jobProperty => `job.${jobProperty}`)
          case 'listing':
            return changedListingProperties.map(listingProperty => `listing.${listingProperty}`)
          default:
            return property
        }
      })
      .flat()

  const onConfirm = () => {
    triggerEvent(trackingEvents.SHIFT_FULFILMENT.SHIFT.CHANGED, {
      ...trackingPayload,
      changes: buildShiftChangedPayload(),
    })
    actions.editFulfilmentShift({
      id: state.id,
      ...recentChanges,
      sort_order: currentSortOrder(),
      shift_index: shiftIndex,
    })
    toggleConfirm()
    setRecentChanges({})
    setChangedProperties([])
    setChangedShiftProperties([])
    setInitial(setShift(state))
    dispatch(updateFulfilmentReviewed(true))
  }

  const onChangeOfferRate = (offerRate: MonetaryAmount) => {
    dispatch(updateOfferRate(offerRate))
    setRecentChanges({
      ...recentChanges,
      offer_rate: offerRate,
    })
  }

  const onChangeClientRate = (clientRate: MonetaryAmount) => {
    dispatch(updateClientRate(clientRate))
    setRecentChanges({
      ...recentChanges,
      job: {
        ...recentChanges?.job,
        pay_rate: clientRate,
      },
    })
  }

  const onChangeBookableIndividually = (checked: boolean) => {
    dispatch(updateBookableIndividually(checked))
    setRecentChanges({
      ...recentChanges,
      job: {
        ...recentChanges?.job,
        bookable_individually: checked,
      },
    })
  }

  const onChangeStartTime = (date: any) => {
    if (!changedShiftProperties.includes('Start time')) {
      setChangedShiftProperties([...changedShiftProperties, 'Start time'])
    }
    triggerEvent(trackingEvents.SHIFT_FULFILMENT.START_TIME.CHANGED, trackingPayload)
    dispatch(updateStartTime(date))
    setRecentChanges({
      ...recentChanges,
      start_time: date,
    })
  }

  const onChangeEndTime = (date: any) => {
    if (!changedShiftProperties.includes('End time')) {
      setChangedShiftProperties([...changedShiftProperties, 'End time'])
    }
    triggerEvent(trackingEvents.SHIFT_FULFILMENT.END_TIME.CHANGED, trackingPayload)
    dispatch(updateEndTime(date))
    setRecentChanges({
      ...recentChanges,
      end_time: date,
    })
  }

  const onChangeStopOffers = (checked: boolean) => {
    dispatch(updateStopOffers(checked ? ['syft'] : []))
    setRecentChanges({
      ...recentChanges,
      stop_offers_for_worker_type: checked ? ['syft'] : [],
    })
  }

  const onChangeHardSkills = (skillIds: number[]) => {
    dispatch(updateHardSkills(skillIds))
    setRecentChanges({
      ...recentChanges,
      job: {
        ...recentChanges?.job,
        skill_ids: skillIds,
      },
    })
  }

  const onSaveShiftNotes = (value?: string) => {
    triggerEvent(trackingEvents.SHIFT_FULFILMENT.NOTE.EDITED, { shift_index: shiftIndex, ...trackingPayload })
    actions.editFulfilmentShift({
      id: state.id,
      fulfilment_note: value,
      sort_order: currentSortOrder(),
      shift_index: shiftIndex,
    })
    dispatch(updateFulfilmentReviewed(true))
  }

  const onChangeCheckBox = (value: string) => {
    dispatch(updateOfferToFlex(value))

    setRecentChanges({
      ...recentChanges,
      offer_to_flex: value,
    })
  }

  const onSaveJobDescription = () => dispatch(updateFulfilmentReviewed(true))

  const offerTypeOptions = getOfferTypeOptions(state, onChangeCheckBox)
  const extendedShift = { ...shift, area: item?.data?.rowData?.area }

  return (
    <ShiftFulfilmentModalView
      closeModal={closeModal}
      notes={notes}
      shift={extendedShift}
      isShown={item.isShown}
      trackingPayload={trackingPayload}
      shiftsCount={shift?.listing?.shifts_count}
      jobsCount={shift?.listing?.jobs_count}
      onSaveShiftNotes={onSaveShiftNotes}
      onSaveJobDescription={onSaveJobDescription}
      changedStopOffers={changedStopOffers}
      state={state}
      client_preferences={shift?.client_preferences}
      onChangeOfferRate={onChangeOfferRate}
      onChangeClientRate={onChangeClientRate}
      onChangeBookableIndividually={onChangeBookableIndividually}
      onChangeStopOffers={onChangeStopOffers}
      onChangeEndTime={onChangeEndTime}
      onChangeStartTime={onChangeStartTime}
      onChangeHardSkills={onChangeHardSkills}
      confirm={confirm}
      offerTypeOptions={offerTypeOptions}
      changedListingProperties={changedListingProperties}
      changedJobProperties={changedJobProperties}
      toggleConfirm={toggleConfirm}
      shiftIndex={shiftIndex}
      onConfirm={onConfirm}
      isLoading={isLoading}
      isSavingData={isSavingData}
      changedProperties={changedProperties}
      markAsReviewed={markAsReviewed}
      initialOfferToFlex={initialOfferTo}
      essentialSkills={essentialSkills}
      disabledSkillsIds={disabledSkillIds}
      changedShiftProperties={changedShiftProperties}
      userData={auth?.userData}
    />
  )
}

export default storeConnector(ShiftFulfilmentModal)
