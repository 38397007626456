import { apiRequest, handleCall } from 'syft-acp-core/api/call'
import { queryString } from 'syft-acp-core/api/params'
import * as actions from './actions'

export const fetchEmployerPreferences = ({
  payload: { employer_id, ...reqArgs },
}: ReturnType<typeof actions.fetchEmployerPreferences>) =>
  new Promise((resolve, reject) => {
    const req = apiRequest({
      path: `/admin/employers/${employer_id}/preferences`,
      reqArgs,
    })
    handleCall(req, resolve, reject, false, { employer_id, ...reqArgs })
  })

export const updateEmployerPreferences = ({
  payload: { employer_id, preferences, ...reqArgs },
}: ReturnType<typeof actions.updateEmployerPreferences>) =>
  new Promise((resolve, reject) => {
    const req = apiRequest({
      path: `/admin/employers/${employer_id}/preferences${queryString(reqArgs)}`,
      reqArgs: { preferences },
      method: 'PUT',
    })
    handleCall(req, resolve, reject, false, { employer_id, ...reqArgs })
  })
