import React, { useEffect } from 'react'
import { Alert, Col, Row } from 'react-bootstrap'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import { getWorker$ } from 'syft-acp-core/store/workers/selectors'
import { fetchWorker } from 'syft-acp-core/store/workers/actions'
import LoadingSpinner from 'syft-acp-atoms/LoadingSpinner'

import { AppealBanWarningProps as Props, AppealBanWarningOwnProps as OwnProps } from './AppealBanWarning.types'

export const warningCopy =
  'This worker has appeals open. Banning this worker will decline those appeals and notify them.'

export const storeConnector = connect(
  (state, { workerID }: OwnProps) => ({
    worker: getWorker$(state.workers)(workerID),
  }),
  dispatch => ({
    actions: {
      ...bindActionCreators(
        {
          fetchWorker,
        },
        dispatch
      ),
    },
  })
)

const AppealBanWarning = ({ actions, worker, workerID }: Props) => {
  useEffect(() => {
    // we check for id because the object is always defined
    if (!worker.id) actions.fetchWorker(workerID)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workerID])

  if (worker.in_appeal)
    return (
      <Row>
        <Col sm={12}>
          <Alert bsStyle="danger">{warningCopy}</Alert>
        </Col>
      </Row>
    )
  if (worker.in_appeal === false) return null
  return <LoadingSpinner isLoading />
}

export default storeConnector(AppealBanWarning)
