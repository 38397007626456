import types from './action-types'
import {
  ConsentChangeWorkerMarketingPreferencesPayload,
  SetPendingConsentWorkerMarketingPreferencesPayload,
  UpdateWorkerMarketingPreferencesPayload,
  WorkerMarketingPreferencesEntity,
} from './types'

export const fetchWorkerMarketingPreferences = (workerID: number) => ({
  type: types.ENTITY_FETCH_BEGIN,
  workerID,
})

export type FetchWorkerMarketingPreferencesBeginAction = ReturnType<typeof fetchWorkerMarketingPreferences>

export const fetchWorkerMarketingPreferencesSucceeded = (
  payload: WorkerMarketingPreferencesEntity,
  request: FetchWorkerMarketingPreferencesBeginAction,
) => ({
  type: types.ENTITY_FETCH_SUCCEEDED,
  payload,
  request,
})

export type FetchWorkerMarketingPreferencesSucceededAction = ReturnType<
  typeof fetchWorkerMarketingPreferencesSucceeded
>

export const updateWorkerMarketingPreferences = (
  workerID: number,
  payload: UpdateWorkerMarketingPreferencesPayload,
) => ({
  type: types.ENTITY_UPDATE_BEGIN,
  workerID,
  payload,
})

export type UpdateWorkerMarketingPreferencesBeginAction = ReturnType<typeof updateWorkerMarketingPreferences>

export const updateWorkerMarketingPreferencesSucceeded = (
  payload: WorkerMarketingPreferencesEntity,
  request: UpdateWorkerMarketingPreferencesBeginAction,
) => ({
  type: types.ENTITY_UPDATE_SUCCEEDED,
  payload,
  request,
})

export type UpdateWorkerMarketingPreferencesSucceededAction = ReturnType<
  typeof updateWorkerMarketingPreferencesSucceeded
>

export const consentWorkerMarketingPreferences = (
  payload: ConsentChangeWorkerMarketingPreferencesPayload,
) => ({
  type: types.CONSENT_WORKER_MARKETING_PREFERENCES_BEGIN,
  payload,
})

export type ConsentChangeWorkerMarketingPreferencesAction = ReturnType<
  typeof consentWorkerMarketingPreferences
>

export const setPendingConsentWorkerMarketingPreferences = (
  payload: SetPendingConsentWorkerMarketingPreferencesPayload,
) => ({
  type: types.SET_PENDING_WORKER_MARKETING_PREFERENCES_BEGIN,
  payload,
})

export type SetPendingConsentWorkerMarketingPreferencesAction = ReturnType<
  typeof setPendingConsentWorkerMarketingPreferences
>

export const clearPendingConsentWorkerMarketingPreferences = () => ({
  type: types.CLEAR_PENDING_WORKER_MARKETING_PREFERENCES_BEGIN,
})
