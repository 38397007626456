import * as React from 'react'

import PageView from 'syft-acp-core/components/PageView'
import ConductReviewDetail from 'syft-acp-core/entities/ConductReviewDetail'

import ConductSubNav from './ConductSubNav'
import { RouteComponentProps } from 'react-router-dom'

export const ConductReviewsView = ({ match: { params } }: RouteComponentProps<{ id: string }>) => (
  <>
    <ConductSubNav />
    <PageView title={['Conduct reviews', `Viewing conduct review #${params.id}`]}>
      <ConductReviewDetail id={Number(params.id)} />
    </PageView>
  </>
)
