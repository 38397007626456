// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import React from 'react'

import PageView from 'syft-acp-core/components/PageView'
import ManagerDetail from 'syft-acp-core/entities/ManagerDetail'
import SubNavigationAdmin from './SubNavigation'

export const AdminManagersNew = () => (
  <>
    <SubNavigationAdmin />
    <PageView title={['Managers', `New manager`]}>
      <ManagerDetail id={0} />
    </PageView>
  </>
)
