import * as alertTypes from 'syft-acp-util/alerts/alertTypes'

// Tabs; one for each resource type.
// More information: <https://syft-application.github.io/syft2backend/notifications-doc.html>.
export const tabTypes = [
  {
    name: 'Workers',
    types: [
      alertTypes.ADMIN_SHIFT_IGNORED,
      alertTypes.ADMIN_TIMESHEET_NO_SHOW,
      alertTypes.ADMIN_SHIFT_STILL_NOT_CONFIRMED,
      alertTypes.ADMIN_LISTING_GOT_BAD_RATING,
    ],
  },
  {
    name: 'Listings',
    types: [
      alertTypes.ADMIN_BOOKING_CANCELLED_BY_EMPLOYER,
      alertTypes.ADMIN_SHIFT_TIME_CHANGED,
      alertTypes.ADMIN_SHIFT_STARTING_WITHIN_DAY,
      alertTypes.ADMIN_JOB_DELETED_BY_EMPLOYER,
      alertTypes.ADMIN_SHIFT_DELETED_BY_EMPLOYER,
      alertTypes.ADMIN_LISTING_GOT_BAD_RATING,
    ],
  },
  {
    name: 'Messages',
    types: [alertTypes.ADMIN_MESSAGE_STOP_WORD],
  },
  {
    name: 'Agencies',
    types: [
      alertTypes.ADMIN_AGENCY_ALLOCATION_RETURNED,
      alertTypes.ADMIN_AGENCY_DECLINED_SHIFT,
      alertTypes.ADMIN_AGENCY_IGNORED_SHIFT,
    ],
  },
]

export const defaulTitle = 'Admin alerts'
