import entityReducer from 'syft-acp-core/reducers/generators/entityReducer'
import * as entityTypes from 'syft-acp-core/reducers/generators/entities'

import * as types from './action-types'
import { FetchWorkerStrikeReasonsSucceededAction } from './actions'
import { WorkerStrikesState, WorkerStrikeEntity, WorkerStrikeReasonsState } from './types'

const workerStrikeFetchSucceededState = (state: any, action: any) => {
  const idKey = 'id'
  const lastUpdate = Number(new Date())
  const strike = {
    ...action.payload,
    activate: !!(action.payload.activated_at && !action.payload.deactivated_at),
    send_email: !!action.payload.email_sent_at,
  }

  return {
    ...state,
    lastMessage: '',
    lastStatus: null,
    lastBody: null,
    entityUpdateTimes: {
      ...state.entityUpdateTimes,
      [action.payload[idKey]]: lastUpdate,
    },
    entityDetail: {
      ...state.entityDetail,
      [action.payload[idKey]]: strike,
    },
    isSavingData: false,
    isLoadingData: false,
  }
}

export default entityReducer<WorkerStrikeEntity, WorkerStrikeReasonsState>('workerStrikes', {
  [entityTypes.ENTITIES_FETCH_BEGIN]: types.WORKER_STRIKES_FETCH_BEGIN,
  [entityTypes.ENTITIES_FETCH_SUCCEEDED]: types.WORKER_STRIKES_FETCH_SUCCEEDED,
  [entityTypes.ENTITIES_FETCH_FAILED]: types.WORKER_STRIKES_FETCH_FAILED,

  [entityTypes.ENTITY_FETCH_BEGIN]: types.WORKER_STRIKE_FETCH_BEGIN,
  [types.WORKER_STRIKE_FETCH_SUCCEEDED]: workerStrikeFetchSucceededState,
  [entityTypes.ENTITY_FETCH_FAILED]: types.WORKER_STRIKE_FETCH_FAILED,

  [entityTypes.ENTITY_SAVE_BEGIN]: types.WORKER_STRIKE_CREATE_BEGIN,
  [types.WORKER_STRIKE_CREATE_SUCCEEDED]: workerStrikeFetchSucceededState,
  [entityTypes.ENTITY_SAVE_FAILED]: types.WORKER_STRIKE_CREATE_FAILED,

  [entityTypes.ENTITY_MODIFY_BEGIN]: types.WORKER_STRIKE_UPDATE_BEGIN,
  [types.WORKER_STRIKE_UPDATE_SUCCEEDED]: workerStrikeFetchSucceededState,
  [entityTypes.ENTITY_MODIFY_FAILED]: types.WORKER_STRIKE_UPDATE_FAILED,

  [entityTypes.ENTITY_DELETE_BEGIN]: types.WORKER_STRIKE_DELETE_BEGIN,
  [entityTypes.ENTITY_DELETE_SUCCEEDED]: types.WORKER_STRIKE_DELETE_SUCCEEDED,
  [entityTypes.ENTITY_DELETE_FAILED]: types.WORKER_STRIKE_DELETE_FAILED,

  [types.WORKER_STRIKE_REASONS_FETCH_SUCCEEDED]: (
    state: WorkerStrikesState,
    action: FetchWorkerStrikeReasonsSucceededAction
  ) => ({
    ...state,
    workerStrikeReasons: action.payload,
  }),
})
