// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import { takeLatest } from 'redux-saga/effects'
import { notificationCall } from 'syft-acp-core/sagas/calls'
import { takeLatestApiCall } from 'syft-acp-core/sagas/helpers'

import { AGENCY_LOGIN_AS_BEGIN, AGENCY_LOGIN_AS_FAILED } from './action-types'
import { loginAsAgency } from './api'

export default function* saga() {
  yield takeLatestApiCall(AGENCY_LOGIN_AS_BEGIN, loginAsAgency)
  yield takeLatest(AGENCY_LOGIN_AS_FAILED, notificationCall)
}
