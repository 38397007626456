import { find, get, memoize, some } from 'lodash-es'
import { createSelector } from 'reselect'

export const selectListingByJobId$ = createSelector(
  state => state,
  store =>
    memoize(jobID => {
      if (!jobID) {
        return {}
      }
      const listings = get(store, `listings.entityMap`, {})
      return find(listings, listing => some(listing.jobs, job => job.id === jobID))
    }),
)
