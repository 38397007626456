import { partial, snakeCase } from 'lodash-es'

export enum EntityCrudActionTypes {
  'FETCH' = 'FETCH',
  'CREATE' = 'CREATE',
  'UPDATE' = 'UPDATE',
  'DELETE' = 'DELETE',
}
export enum EntityCrudActionStates {
  'BEGIN' = 'BEGIN',
  'SUCCEEDED' = 'SUCCEEDED',
  'FAILED' = 'FAILED',
}
type EntityCrudActionKey = `ENTITY_${EntityCrudActionTypes}_${EntityCrudActionStates}`
type EntityListCrudActionKey = `ENTITIES_${EntityCrudActionTypes}_${EntityCrudActionStates}`

export type GeneratedCrudActions = Record<EntityCrudActionKey | EntityListCrudActionKey, string>

const transformEntityName = (entityName: string, actionName: string, prefix: string, suffix: string) =>
  prefix + snakeCase(`${entityName}${suffix}_${actionName}`).toUpperCase()

export const generateEntityCrudActionTypes = (entityName: string, prefix = 'syftacp/') =>
  Object.keys(EntityCrudActionTypes)
    .map(type => Object.keys(EntityCrudActionStates).map(state => `${type}_${state}`))
    .flat()
    .reduce((actions, actionName) => {
      const partialTransform = partial(transformEntityName, entityName, actionName, prefix)
      actions[`ENTITY_${actionName}` as EntityCrudActionKey] = partialTransform('Entity')
      actions[`ENTITIES_${actionName}` as EntityCrudActionKey] = partialTransform('List')
      return actions
    }, {} as GeneratedCrudActions)
