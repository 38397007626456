import { apiRequest, handleCall } from 'syft-acp-core/api/call'

type BulkToggleSkillParams = {
  workerID: number
  skillID: number
  remove: boolean | false
}

/**
 * Retrieves a list of skills.
 */
export const listSkills = ({ options = {} }): Promise<any> =>
  new Promise((resolve, reject) => {
    const req = apiRequest({ path: '/skills' })
    handleCall(req, resolve, reject, true, options)
  })

/**
 * Bulk toggle a skill for a worker across roles
 *
 * @param params {Object}
 * @param params.workerID {Number} - ID of the worker
 * @param params.skillID {Number} - ID of the skill to bulk add or remove
 * @param params.remove {Boolean} - Whether we are removing the skill
 *
 * @returns {Promise}
 */
export const bulkToggleSkill = ({ workerID, skillID, remove = false }: BulkToggleSkillParams): Promise<any> =>
  new Promise((resolve, reject) => {
    if (!workerID) return reject()
    if (!skillID) return reject()

    const req = apiRequest({
      path: `/admin/workers/${workerID}/skills/${skillID}`,
      reqArgs: { _destroy: remove },
      method: 'PATCH',
    })
    return handleCall(req, resolve, reject)
  })
