import { takeLatest } from 'redux-saga/effects'

import { takeLatestApiCall } from 'syft-acp-core/sagas/helpers'
import { notificationCall } from 'syft-acp-core/sagas/calls'

import * as types from './action-types'
import * as api from './api'

export default function* saga() {
  yield takeLatestApiCall(types.TIME_OFF_REQUESTS_FETCH_BEGIN, api.listTimeOffRequests)
  yield takeLatestApiCall(types.TIME_OFF_REQUEST_FETCH_BEGIN, api.fetchTimeOffRequest)

  yield takeLatestApiCall(types.TIME_OFF_REQUEST_UPDATE_BEGIN, api.updateTimeOffRequest)
  yield takeLatest([types.TIME_OFF_REQUEST_UPDATE_FAILED, types.TIME_OFF_REQUEST_UPDATE_SUCCEEDED], notificationCall)

  yield takeLatestApiCall(types.TIME_OFF_REQUEST_APPROVE_BEGIN, api.approveTimeOffRequest)
  yield takeLatestApiCall(types.TIME_OFF_REQUEST_REJECT_BEGIN, api.rejectTimeOffRequest)

  yield takeLatest(
    [
      types.TIME_OFF_REQUESTS_FETCH_FAILED,
      types.TIME_OFF_REQUEST_FETCH_FAILED,
      types.TIME_OFF_REQUEST_APPROVE_FAILED,
      types.TIME_OFF_REQUEST_APPROVE_SUCCEEDED,
      types.TIME_OFF_REQUEST_REJECT_FAILED,
      types.TIME_OFF_REQUEST_REJECT_SUCCEEDED,
    ],
    notificationCall
  )
}
