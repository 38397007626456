import React from 'react'
import PageTitle from 'syft-acp-core/components/PageTitle'
import AgencyAccountsDetail from 'syft-acp-core/entities2/AgencyAccountsDetail'
import { AgenciesTrackingProvider } from './AgenciesTrackingProvider'
import { AgencySubNavigation } from './AgencySubNavigation'

export const AgencyAccountsView = ({ match: { params } }) => {
  const agencyIDParam = params.id ? Number(params.id) : undefined
  const accountId = params.account_id ? Number(params.account_id) : undefined
  return (
    <PageTitle title="Agencies rate card list">
      <AgencySubNavigation agencyID={agencyIDParam} />

      <AgenciesTrackingProvider agencyId={agencyIDParam} accountId={accountId}>
        <AgencyAccountsDetail accountId={accountId} id={accountId} agencyId={agencyIDParam} />
      </AgenciesTrackingProvider>
    </PageTitle>
  )
}
