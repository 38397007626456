import React, { useMemo } from 'react'
import { useIntl } from 'react-intl'
import { SUPPORTED_LOCALES } from 'syft-acp-core/lib/i18n'
import { filterSelect } from './FilterSelect'

export default props => {
  // Display all supported languages.
  const { formatDisplayName, locale } = useIntl()

  const options = useMemo(
    () =>
      SUPPORTED_LOCALES.map(id => ({
        id,
        label: formatDisplayName(id, { type: 'language' }),
      })),
    [formatDisplayName]
  )
  const SelectLanguage = useMemo(() => filterSelect(options, 'Select a language', 'id', false), [options])
  return <SelectLanguage {...props} value={locale} />
}
