import { get, omit } from 'lodash-es'

import { stringify } from 'syft-acp-core/store/filters/helpers'

/**
 * Util function to retrieve error messages from api responses
 */
export const apiErrorMessage = ({ body, message }) =>
  get(body, 'debug.message') || get(body, 'error_description') || message

export const changeEntityId = (state, oldId, newID) => ({
  ...state,
  entityMap: {
    ...omit(state.entityMap, oldId),
    [newID]: {
      ...state.entityMap[oldId],
      id: newID,
    },
  },
})

/**
 * Retrieves the group by values from an action. Used by entitySetName().
 *
 * @param {Object} options List of options with which the reducer was initialized
 * @param {Object} action Original action that the reducer is currently processing
 */
const entityGroupByValues = (options, action) => {
  // If we don't have any groupBy values, just return an empty object.
  if (!options.groupBy) {
    return {}
  }
  // We can get the action arguments either from the action itself,
  // or from action.request if this is the callback of an API call.
  // TODO: possibly modify this so that we always have action.request?
  const actionValues = action.request || action
  // Note: we assume all values exist.
  // Otherwise it's better to crash and force the developer to fix the problem.
  return options.groupBy.reduce((acc, key) => ({ ...acc, [key]: get(actionValues, key) }), {})
}

/**
 * Generates an entity set name by serializing a query object.
 *
 * @param {Object} query Query object
 * @param {Object} [options = {}] List of options with which the reducer was initialized
 * @param {Object} [action] Original action that the reducer is currently processing
 * @returns {String}
 */
export const entitySetName = (query, options = {}, action) =>
  stringify(!options.groupBy ? query : { ...query, ...entityGroupByValues(options, action) })
