// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import entityReducer from 'syft-acp-core/reducers/generators/entityReducer'
import * as entityTypes from 'syft-acp-core/reducers/generators/entities'

import * as types from '../action-types'

export default entityReducer('workerShifts', {
  [entityTypes.ENTITIES_FETCH_BEGIN]: types.WORKER_SHIFTS_FETCH_BEGIN,
  [entityTypes.ENTITIES_FETCH_SUCCEEDED]: types.WORKER_SHIFTS_FETCH_SUCCEEDED,
  [entityTypes.ENTITIES_FETCH_FAILED]: types.WORKER_SHIFTS_FETCH_FAILED,
})
