import React from 'react'
import PropTypes from 'prop-types'
import { isEmpty, get, isEqual } from 'lodash-es'
import Acp from 'syft-acp-uikit'

import './DataIndustriesAndRoles.css'

const DataIndustriesAndRoles = ({ value, context }) => {
  const handleRemoveItem = ({ rowData }) => {
    context.setDeletedPermissionsState([
      ...context.deletedPermissionsState,
      {
        ...value.find(
          permission =>
            permission.venue_id === rowData.venue_id && permission.employer_id === rowData.employer_id,
        ),
        destroy: true,
      },
    ])
    context.setPermissionsState(
      value.filter(
        permission =>
          !isEqual(permission.role_ids, rowData.role_ids) ||
          permission.venue_id !== rowData.venue_id ||
          permission.employer_id !== rowData.employer_id,
      ),
    )
  }

  const handleEditChange = data => {
    context.setPermissionsState(
      value.map(permission =>
        permission.venue_id === data.venue_id &&
        permission.employer_id === data.employer_id &&
        permission.id === data.id &&
        (permission.industry_id === get(data, 'industry_id') || isEmpty(data.role_ids))
          ? data
          : permission,
      ),
    )
  }

  return (
    <Acp.EntityList
      hasPagination
      className="data-industries-roles-table"
      idKeyValue="venud_id"
      data={{ data: value, meta: context.meta }}
    >
      <Acp.Table>
        <Acp.Col.Text value="employer_name" header="Employer" hasNoneStyles isMinimal />
        <Acp.Col.Text value="venue_name" header="Locations" hasNoneStyles />
        <Acp.Col.RolesIndustriesMultiselect
          handleEditChange={handleEditChange}
          value="venue_name"
          header="Industries + Roles"
          hasNoneStyles
        />
        <Acp.Col.Button hasNoneStyles header="" isMinimal>
          <Acp.Button kind="danger" onClick={handleRemoveItem}>
            Remove
          </Acp.Button>
        </Acp.Col.Button>
      </Acp.Table>
    </Acp.EntityList>
  )
}

DataIndustriesAndRoles.propTypes = {
  value: PropTypes.arrayOf(PropTypes.object),
  context: PropTypes.object,
}

DataIndustriesAndRoles.defaultProps = {
  value: [],
  context: {},
}

export default DataIndustriesAndRoles
