import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { pickBy } from 'lodash-es'
import { useCountrySpecificStructs } from 'syft-acp-core/components/CountrySpecific'

import { workerStatuses } from 'syft-acp-core/data/workerStatus'
import AcpCellEnum from './AcpCellEnum'
import { cellPropTypes, cellDefaultProps } from '../dataProps'
import { colPlaceholderFactory } from '../../../placeholders/factory'

// Proptypes for worker statuses. Converts the worker statuses
// to a proptype definition, i.e. { failed_induction: PropTypes.bool, ... }.
const workerStatusProps = Object.keys(workerStatuses).reduce(
  (acc, status) => ({ ...acc, [status]: PropTypes.bool }),
  {}
)

// Column placeholder component.
export const AcpColStatus = colPlaceholderFactory('AcpCellStatus')
const statusToSpec = statuses => {
  const spec = []

  for (const [slug, item] of Object.entries(statuses)) {
    spec.push({ slug, value: item.name, color: item.color || item.style, children: '' })
  }
  return spec
}
/**
 * Displays a worker's status tags. This uses AcpCellEnum with a number of preset values.
 *
 *    <Acp.Col.WorkerStatus value="worker_status" header="Status" />
 */
const AcpCellStatus = ({ value, link, options: { statuses, ...options } }) => {
  const filteredStatuses = useCountrySpecificStructs(statuses)
  const spec = useMemo(() => statusToSpec(filteredStatuses), [filteredStatuses])
  const valueArray = useMemo(() => Object.keys(pickBy(value)), [value])

  return <AcpCellEnum link={link} value={valueArray} spec={{ _nested: spec }} options={options} />
}

AcpCellStatus.propTypes = {
  ...cellPropTypes,
  value: PropTypes.shape(workerStatusProps),
  intl: PropTypes.object,
  options: PropTypes.shape({
    style: PropTypes.string,
    displayUnknownValue: PropTypes.bool,
  }).isRequired,
}

AcpCellStatus.defaultProps = {
  ...cellDefaultProps,
}

export default AcpCellStatus
