import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { get } from 'lodash-es'
import moment from 'moment'
import * as deletedRTWDocumentsActions from 'syft-acp-core/actions/worker-deleted-rtw-documents'

import './DataDeletedRTWDocumentsList.scss'

const not_available = <span className="not-available">N/A</span>

const presentDocument = document => ({
  id: document.id,
  type: document.document_type.name,
  number: document.number || not_available,
  country: document.country || not_available,
  expires: document.expiry_date
    ? moment(document.expiry_date, 'YYYY-MM-DD').format('YYYY/MM/DD')
    : not_available,
  deleted: moment(document.deleted_at).format('YYYY/MM/DD HH:mm'),
  images: get(document, 'attachments', []).map(attachment => attachment.url),
})

const DataDeletedRTWDocumentsList = ({ actions, deletedRTWDocuments, loading, workerId }) => {
  useEffect(() => {
    if (workerId) {
      actions.fetchDeletedRTWDocuments(workerId)
    }
  }, [actions, workerId])

  const documents = deletedRTWDocuments[workerId] || []

  if (loading) {
    return <p>Loading...</p>
  }

  return documents.length > 0 ? (
    <div className="DataDeletedRTWDocumentsList">
      <div className="document-header">
        <div className="cell">ID</div>
        <div className="cell">Type</div>
        <div className="cell">Number</div>
        <div className="cell">Country</div>
        <div className="cell">Expires</div>
        <div className="cell">Deleted on</div>
        <div className="cell">Photos</div>
      </div>
      {documents.map(document => {
        const doc = presentDocument(document)

        return (
          <div key={`doc${doc.id}`} className="document">
            <div className="cell doc-id">{doc.id}</div>
            <div className="cell doc-type">{doc.type}</div>
            <div className="cell doc-number">{doc.number}</div>
            <div className="cell doc-country">{doc.country}</div>
            <div className="cell doc-expires">{doc.expires}</div>
            <div className="cell doc-deleted">{doc.deleted}</div>
            <div className="cell doc-image-link">
              {doc.images.length > 0
                ? doc.images.map((image, index) => (
                    <>
                      <a
                        className="image-link"
                        href={image}
                        key={`doc${doc.id}image${index}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Image {index + 1}
                      </a>
                      {index < doc.images.length - 1 ? ', ' : ''}
                    </>
                  ))
                : not_available}
            </div>
          </div>
        )
      })}
    </div>
  ) : (
    <p>(No deleted documents)</p>
  )
}

DataDeletedRTWDocumentsList.propTypes = {
  actions: PropTypes.objectOf(PropTypes.func).isRequired,
  deletedRTWDocuments: PropTypes.object,
  loading: PropTypes.bool,
  workerId: PropTypes.number.isRequired,
}

DataDeletedRTWDocumentsList.defaultProps = {
  loading: false,
  deletedRTWDocuments: {},
}

export default connect(
  state => ({
    deletedRTWDocuments: state.deletedRTWDocuments.entityMap,
    loading: state.deletedRTWDocuments.loadingData,
  }),
  dispatch => ({
    actions: bindActionCreators(deletedRTWDocumentsActions, dispatch),
  }),
)(DataDeletedRTWDocumentsList)
