import * as React from 'karet'
import PropTypes from 'prop-types'

import NotifyBar from 'syft-acp-atoms/NotifyBar'
import { getBrowser } from 'syft-acp-util/browser'

import './BrowserNotice.css'

const BrowserNotice = ({ browserInfo = getBrowser() }) => (
  <div className="BrowserNotice">
    <NotifyBar severity="error" visible={browserInfo.name !== 'chrome'} conditional>
      We've detected your browser as <em>{browserInfo.prettyName}</em>.<br />
      <em>Syft ACP</em> is currently only tested with <em>Google Chrome</em>, so it's recommended to use that.
    </NotifyBar>
  </div>
)

BrowserNotice.propTypes = {
  browserInfo: PropTypes.shape({
    name: PropTypes.string.isRequired,
    prettyName: PropTypes.string.isRequired,
  }),
}

export default BrowserNotice
