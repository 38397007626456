import React from 'react'
import PropTypes from 'prop-types'
import { isPlainObject, get } from 'lodash-es'
import { Tooltip } from '@material-ui/core'
import { Error as ErrorIcon } from '@indeed/ifl-icons'
import { Box, Flex, Text } from '@indeed/ifl-components'

import FormattedDateTime, { fullLocalDateTimeFormat } from 'syft-acp-core/components/FormattedDateTime'
import { emptyTextFallback } from 'syft-acp-uikit/components/AcpTable/cellComponents/dataTypes/fallback'

import StartEnd from 'syft-acp-util/components/StartEnd'
import { colPlaceholderFactory } from '../../../placeholders/factory'
import { cellPropTypes, cellDefaultProps } from '../dataProps'

// Column placeholder component.
export const AcpColDateTime = colPlaceholderFactory('AcpCellDateTime')

/**
 * Cell component for displaying date time. It supports a number of formatting options via Intl.DateTimeFormat API.
 */
export default class AcpCellDateTime extends React.PureComponent {
  static propTypes = {
    ...cellPropTypes,
    // Value must be a string that can be parsed by Date().
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    options: PropTypes.shape({
      timeZone: PropTypes.string,
    }),
  }

  static defaultProps = {
    ...cellDefaultProps,
    options: {},
  }

  getOptions = () => {
    const { rowData, options } = this.props
    return {
      ...options,
      timeZone: get(rowData, options.timeZone),
    }
  }

  render() {
    const { value } = this.props
    const enableOverrides = this.props.spec?.withOverrides
    if (!value) return emptyTextFallback
    if (isPlainObject(value)) {
      const timeOverride = this.props.rowData?.overrides_summary?.start_end_time
      if (enableOverrides && timeOverride) {
        return (
          <Box sx={{ display: 'inline-flex' }}>
            <StartEnd values={Object.values(value)} rowFormat={{ options: this.getOptions() }} />
            <Tooltip
              title={
                <Text as="div" level={2} sx={{ color: 'white' }}>
                  Time updated for {timeOverride} {timeOverride === 1 ? 'worker' : 'workers'}
                </Text>
              }
              placement="bottom"
              arrow
            >
              <Flex sx={{ alignSelf: 'center' }}>
                <ErrorIcon size="sm" sx={{ alignItems: 'center', marginLeft: '2px' }} color="grey" />
              </Flex>
            </Tooltip>
          </Box>
        )
      }
      return <StartEnd values={Object.values(value)} rowFormat={{ options: this.getOptions() }} />
    }

    return <FormattedDateTime value={value} format={{ ...fullLocalDateTimeFormat, ...this.getOptions() }} />
  }
}
