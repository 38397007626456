// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import PropTypes from 'prop-types'
import React from 'react'

import PageView from 'syft-acp-core/components/PageView'
import DataRequestForm from './DataRequestForm'

export const DataRequest = ({ match: { params } }) => (
  <>
    <PageView title={['ACCESS Data Request', params.id]}>
      <DataRequestForm requestId={params.id} reporter={params.reporter} />
    </PageView>
  </>
)

DataRequest.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired,
      reporter: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
}
