import React, { useCallback } from 'react'
import { connect } from 'react-redux'
import { Alert, Col, ControlLabel, FormGroup, Row } from 'react-bootstrap'
import { bindActionCreators } from 'redux'
import { flowRight } from 'lodash-es'

import { getModal$ } from 'syft-acp-core/store/modals/selectors'
import { Modal } from 'syft-acp-core/components/Modal'
import { hideModal } from 'syft-acp-core/store/modals/actions'

import * as agencyBulkUploadActions from '../../store/agency-bulk-upload/actions'

import { AgencyBulkUploadModalProps } from './AgencyCoverageImportModal.types'

export const AgencyBulkUploadModalPure = ({
  actions,
  changes: { venues, added, deleted, unchanged },
  errors,
  isLoading,
  item,
  onUpload,
}: AgencyBulkUploadModalProps) => {
  const closeModal = useCallback(() => {
    actions.hideModal(item.modalName)
  }, [item, actions])
  const hasErrors = errors.length > 0

  return (
    <Modal
      header="Agency coverage bulk upload confirmation"
      isShown={item.isShown}
      onClose={closeModal}
      onConfirm={onUpload}
      isLoading={isLoading}
      confirmationText="Upload"
    >
      <div className="agency-coverage-import-modal">
        <FormGroup className="segment form-item">
          {hasErrors && (
            <Row>
              <Col md={3}></Col>
              <Col md={9}>
                <Alert bsStyle="danger">
                  There have been some issues with processing the data:
                  <ul>
                    {errors.map((error: string) => (
                      <li key={error}>{error}</li>
                    ))}
                  </ul>
                </Alert>
              </Col>
            </Row>
          )}
          <Row>
            <Col md={3}>
              <ControlLabel htmlFor="employerId">Changes:</ControlLabel>
            </Col>
            <Col md={9}>
              Venues: {venues}
              <br />
              Added: {added}
              <br />
              Deleted: {deleted}
              <br />
              Unchanged: {unchanged}
              <br />
            </Col>
          </Row>
        </FormGroup>
      </div>
    </Modal>
  )
}

export const storeConnector = connect(
  state => ({
    isLoading: state.agencyBulkUpload.isLoading,
    errors: state.agencyBulkUpload.errors,
    item: getModal$(state.modals)('agencyCoverageImportModal'),
  }),
  dispatch => ({
    actions: bindActionCreators({ ...agencyBulkUploadActions, hideModal }, dispatch),
  })
)

export default flowRight(storeConnector)(AgencyBulkUploadModalPure)
