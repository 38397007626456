// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import { takeLatestApiCall } from 'syft-acp-core/sagas/helpers'
import * as api from './api'
import { ROLES_FETCH_BEGIN } from './action-types'

export default function* saga() {
  yield takeLatestApiCall(ROLES_FETCH_BEGIN, api.listRoles)
}
