// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import * as types from './action-types'

export function fetchEmployerBlocklist({ options }, props, toCSV = false) {
  return { type: types.EMPLOYER_BLOCKLIST_FETCH_BEGIN, options, toCSV }
}

export function addToEmployerBlocklist(employerID, workerID) {
  return { type: types.EMPLOYER_BLOCKLIST_ADD_BEGIN, employerID, workerID }
}

export function removeFromEmployerBlocklist(employerID, workerID) {
  return { type: types.EMPLOYER_BLOCKLIST_REMOVE_BEGIN, employerID, workerID }
}
