import React, { useCallback, useEffect, useMemo } from 'react'
import deepEqual from 'react-fast-compare'
import { flowRight } from 'lodash-es'
import Acp from 'syft-acp-uikit'
import { useTrackingTrigger } from '@indeed/flex-tracking-context'

import { getDateRangeCsat } from 'syft-acp-core/lib/shifts'
import { FilterDateRange } from 'syft-acp-util/components/FilterForm'
import usePrevValue from 'syft-acp-util/hooks/usePrevValue'
import { fetchCsatAgencies } from 'syft-acp-core/store/csatAgencies/actions'
import { fetchCsatWorkers } from 'syft-acp-core/store/csatWorkers/actions'
import { fetchCsatEmployers } from 'syft-acp-core/store/csatEmployers/actions'

import { getTableStructure, passEntityStore, storeConnector, DOWNLOAD_CSAT_DATA_CSV_BUTTON_CLICKED } from './helpers'
import { CsatDataListProps as Props, RowData } from './CsatDataList.types'

const initialDate = getDateRangeCsat(0, 1)

export const getReasonsValue = (data: RowData) => {
  const arrayOfReasons = data.reasons.map(item => item.title)
  return arrayOfReasons.join()
}

export const getAppIdentifiersValue = (data: RowData) => data.app_identifiers.join(', ')

const CsatDataList = ({ actions, csatDataType, path, query, isLoadingData, data, fetchCsatCsvData }: Props) => {
  const triggerEvent = useTrackingTrigger()
  const prevQuery = usePrevValue(query)
  const { rowLink, cols = [] } = useMemo(
    () => getTableStructure({ type: csatDataType, getReasonsValue, getAppIdentifiersValue }),
    [csatDataType]
  )
  const fetch = useCallback(q => actions.fetchEntities && actions.fetchEntities({ options: { ...q } }), [actions])
  const handleDownloadCSV = useCallback(() => {
    triggerEvent(DOWNLOAD_CSAT_DATA_CSV_BUTTON_CLICKED, {
      from_date: query.from_date,
      to_date: query.to_date,
    })
    return fetchCsatCsvData(query, path)
  }, [query, path, triggerEvent, fetchCsatCsvData])

  useEffect(() => {
    if (!deepEqual(prevQuery, query)) {
      fetch(query)
    }
  }, [fetch, prevQuery, query])

  return (
    <div className="entity-detail-item csat-list-container">
      <Acp.EntityList isLoading={isLoadingData} data={data} hasResultCount hasPagination idKeyValue="id">
        <Acp.Actions>
          <Acp.FilterGroup title="Date:">
            {/* We need the key for FilterDateRange, because otherwise missing default values, we have reused same component on multiple screens and after rerender, useEffect will not trigger. */}
            <FilterDateRange key={path} defaultValue={initialDate} nameFrom="from_date" nameTo="to_date" />
          </Acp.FilterGroup>
          <Acp.Button onClick={handleDownloadCSV} kind="primary">
            Download CSV
          </Acp.Button>
        </Acp.Actions>
        <Acp.Table rowLink={rowLink}>
          {cols.map(ele => {
            if (ele.type === 'text') {
              return <Acp.Col.Text key={ele.header} value={ele.value} header={ele.header} {...ele.options} />
            } else if (ele.type === 'bool') {
              return <Acp.Col.Boolean key={ele.header} value={ele.value} header={ele.header} {...ele.options} />
            }
            return <Acp.Col.Timestamp key={ele.header} value={ele.value} header={ele.header} {...ele.options} />
          })}
        </Acp.Table>
      </Acp.EntityList>
    </div>
  )
}

export const AgencyCsat = flowRight(passEntityStore('csatAgencies', fetchCsatAgencies), storeConnector)(CsatDataList)
export const WorkerCsat = flowRight(passEntityStore('csatWorkers', fetchCsatWorkers), storeConnector)(CsatDataList)
export const EmployerCsat = flowRight(
  passEntityStore('csatEmployers', fetchCsatEmployers),
  storeConnector
)(CsatDataList)

export default CsatDataList
