import React, { useCallback, useEffect, useState } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import Modal from '../Modal'
import { getModal$ } from 'syft-acp-core/store/modals/selectors'
import { hideModal } from 'syft-acp-core/store/modals/actions'
import { A_VERY_LARGE_NUMBER } from 'syft-acp-core/api/call'
import './DownloadCSVModal.scss'
import DownloadCSVModalContent from './DownloadCSVModalContent'
import { FormControl } from 'react-bootstrap'

type OwnProps = { name: string; labelTable?: Record<string, string>; labelTableRange?: Record<string, string> }

const defaultModalName = 'downloadCSV'

const connectedModal = connect<
  Record<string, any>,
  {
    closeModal: () => ReturnType<typeof hideModal>
  },
  OwnProps
>(
  (state, { name }) => ({
    item: getModal$(state.modals)(name || defaultModalName),
  }),
  (dispatch, { name }) => ({
    closeModal: () => dispatch(hideModal(name || defaultModalName)),
  })
)

type ConnectedModalProps = ConnectedProps<typeof connectedModal>
type Props = ConnectedModalProps & OwnProps

const DownloadCSVModal = ({ item = {}, closeModal, labelTable, labelTableRange }: Props) => {
  const { options, isShown } = item
  const { subject, query, hasFilters, total } = options || {}
  const [totalPages, setTotalPages] = useState(1)
  const [pageToDownload, setPageDownload] = useState(1)
  const [itemsInFile, setItemsInFile] = useState<number>()

  useEffect(() => {
    if (total) {
      const _totalPages = Math.ceil(total / A_VERY_LARGE_NUMBER)
      if (_totalPages === 1) {
        setItemsInFile(total)
      } else {
        setItemsInFile(pageToDownload < _totalPages ? A_VERY_LARGE_NUMBER : total % A_VERY_LARGE_NUMBER)
      }
      setTotalPages(_totalPages)
    }
  }, [total, pageToDownload])

  const onConfirm = useCallback(() => {
    if (options?.onConfirm) {
      options.onConfirm(pageToDownload)
    }

    closeModal()
  }, [closeModal, options, pageToDownload])

  const onClose = useCallback(() => {
    if (options?.onClose) {
      options.onClose()
    }

    closeModal()
  }, [closeModal, options])

  const handlePageChange = (e: React.FormEvent<FormControl>) => {
    const evt = e.target as HTMLInputElement
    setPageDownload(Number(evt.value))
  }

  return (
    <Modal
      header="Confirmation"
      cancelText="Cancel"
      isShown={isShown}
      onClose={onClose}
      onConfirm={onConfirm}
      confirmationText={totalPages === 1 ? 'Download' : `Download page ${pageToDownload}`}
    >
      <div>
        <p>Download a {subject ?? ''} CSV file with these search results?</p>
        <DownloadCSVModalContent
          hasFilters={hasFilters}
          query={query}
          totalPages={totalPages}
          onPageChange={handlePageChange}
          pageToDownload={pageToDownload}
          total={total}
          itemsInFile={itemsInFile}
          labelTable={labelTable}
          labelTableRange={labelTableRange}
        />
      </div>
    </Modal>
  )
}

export default connectedModal(DownloadCSVModal)
