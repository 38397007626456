import { apiRequest, handleCall } from 'syft-acp-core/api/call'
import {
  AdhocShiftResponseEntity,
  ApproveAdhocShiftPayload,
  FetchAdhocShiftInput,
  RejectAdhocShiftPayload,
} from './types'
import { ApiResponse } from 'syft-acp-core/api/types'

const basePath = '/admin/adhoc_shifts_approval'

export const approveAdhocShift = ({ payload }: ApproveAdhocShiftPayload) =>
  new Promise<ApiResponse<AdhocShiftResponseEntity>>((resolve, reject) => {
    const req = apiRequest({
      path: `${basePath}/approve`,
      reqArgs: { adhoc_shift_booking_id: payload.adhoc_shift_booking_id },
      method: 'PUT',
    })
    handleCall(req, resolve, reject, false)
  })

export const rejectAdhocShift = ({ payload }: RejectAdhocShiftPayload) =>
  new Promise<ApiResponse<AdhocShiftResponseEntity>>((resolve, reject) => {
    const req = apiRequest({
      path: `${basePath}/reject`,
      reqArgs: { adhoc_shift_booking_id: payload.adhoc_shift_booking_id },
      method: 'PUT',
    })
    handleCall(req, resolve, reject, false)
  })

export const fetchAdhocShiftStatus = ({ payload }: FetchAdhocShiftInput) =>
  new Promise<ApiResponse<AdhocShiftResponseEntity>>((resolve, reject) => {
    const req = apiRequest({
      path: `${basePath}/?adhoc_shift_booking_id=${payload.adhoc_shift_booking_id}`,
      method: 'GET',
    })
    handleCall(req, resolve, reject, false)
  })
