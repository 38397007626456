import entityReducer from './generators/entityReducer'
import * as types from 'syft-acp-core/actions/action-types'
import * as entityTypes from 'syft-acp-core/reducers/generators/entities'

const options = {
  idKey: 'id',
}

export const mapDocumentsByWorkerId = (state, action = {}) => {
  const workerId = action.request.workerId

  return {
    ...state,
    lastMessage: '',
    lastStatus: null,
    lastBody: null,
    isLoadingData: false,
    entityMap: {
      ...state.entityMap,
      [workerId]: action.payload,
    },
  }
}

export default entityReducer(
  'deletedRTWDocuments',
  {
    [entityTypes.ENTITIES_FETCH_BEGIN]: types.DELETED_RTW_DOCUMENTS_FETCH_BEGIN,
    [entityTypes.ENTITIES_FETCH_FAILED]: types.DELETED_RTW_DOCUMENTS_FETCH_FAILED,
    [types.DELETED_RTW_DOCUMENTS_FETCH_SUCCEEDED]: mapDocumentsByWorkerId,
  },
  options
)
