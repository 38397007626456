import { rangeRight, get } from 'lodash-es'

import { VenueCategory } from 'syft-acp-core/store/venueCategories/types'
import { EmployerVenueDetailOwnProps } from './EmployerVenueDetail.types'
import { EmployerVenueEntity } from 'syft-acp-core/store/employer-venues/types'

// Range from 48 to 0 in 4-hour intervals. Presented as { id, label }.
export const complianceHours = rangeRight(0, 49, 4).map(n => ({ id: n, label: String(n) }))

export const formatVenueCategories = (venueCategories: VenueCategory[]) =>
  venueCategories.map((venue: VenueCategory) => ({ id: venue.id, label: venue.name }))

export const getEmployerVenueCategories = (employerID: number, venueCategories: VenueCategory[]) =>
  venueCategories.filter((venueCategory: VenueCategory) => employerID === venueCategory.employer_id)

export const getNeeds = (
  state: { employers: { entityDetail: any[] }; venueCategories: { entityMap: VenueCategory } },
  props: EmployerVenueDetailOwnProps,
) => ({
  platformID: get(state?.employers?.entityDetail[props.employerID], 'platform_id', null),
  saleforceVenueData: Object.values(get(state, `employerVenueSalesForce.entityMap`, {}))
    // @ts-ignore TODO: investigate existence of unused prop saleforceVenueData
    .find(row => row?.target_id === props.id),
  venueCategories: Object.values(state?.venueCategories?.entityMap || {}),
})

export const formatAccountManagerList = (
  data: EmployerVenueEntity,
  formatDate: (date: string | Date) => string,
) => {
  return data?.current_account_managers?.reduce((acc, accountManager, index) => {
    const { area_name, date_from, name, industry } = accountManager
    const dateFromSection = date_from ? formatDate(date_from) : ''
    const nameSection = acc + `${!!index ? ', ' : ''}` + name
    const infoSection = [area_name, industry, dateFromSection].filter(Boolean).join(' - ')
    return !area_name && !dateFromSection && !industry ? nameSection : nameSection + ' (' + infoSection + ')'
  }, '')
}
