// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import * as React from 'karet'
import { Button } from 'syft-acp-atoms/Button'
import { addConfirmLayer } from 'syft-acp-uikit/components/AcpTable/confirm'

import { actionPropTypes, actionDefaultProps } from './props'
import { checkSelection, checkDisabled, decorateOnClick } from './selection'

/**
 * A wrapper for buttons that passes on table state.
 *
 * Use as follows:
 *
 *    <Acp.Button kind="success">Text</Acp.Button>
 *
 * Use 'requiresSelection' or 'requiresSubSelection' to indicate that items
 * need to be selected in order to use the dropdown menu.
 *
 * The 'onClick' callbacks will be called with the table's current state
 * and selections.
 *
 * See <src/core/entities2/ComponentTest/Selection.js> for an example.
 */
const AcpButton = ({
  children,
  kind,
  id,
  title,
  disabled,
  onClick,
  needsConfirm,
  confirmHeader,
  confirmNo,
  confirmText,
  confirmYes,
  subDataSpec,
  requiresSelection,
  requiresSubSelection,
  componentArgs,
  callbackArgs,
}) => {
  const { tableData, stateSelection, stateSubSelection } = componentArgs
  const { disabledForSelection, disabledForSubSelection } = checkSelection(
    stateSelection,
    stateSubSelection,
    requiresSelection,
    requiresSubSelection,
  )
  // Decorate the onClick handler with the table state.
  const onClickFn = decorateOnClick(
    onClick,
    null,
    callbackArgs,
    subDataSpec,
    tableData,
    stateSelection,
    stateSubSelection,
  )
  return (
    <Button
      onClick={
        needsConfirm
          ? addConfirmLayer(onClick, {
              confirmHeader,
              confirmNo,
              confirmText,
              confirmYes,
            })
          : onClickFn
      }
      kind={kind}
      title={title}
      disabled={checkDisabled(disabled, disabledForSelection, disabledForSubSelection)}
      id={id || title}
    >
      {children}
    </Button>
  )
}
AcpButton.propTypes = actionPropTypes
AcpButton.defaultProps = actionDefaultProps

export default AcpButton
