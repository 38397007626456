import * as structureTypes from 'syft-acp-core/components/EditableTable'
import { ExternalPlatformEntity } from 'syft-acp-core/store/external-platforms/types'

export const createStructure = ({
  externalPlatforms,
  showTransferredOnField,
  showBranchField = false,
  showTimekeepingField = false,
}: {
  externalPlatforms?: ExternalPlatformEntity[]
  showTransferredOnField?: boolean
  showBranchField?: boolean
  showTimekeepingField?: boolean
} = {}) =>
  [
    ['id', { header: 'ID' }],
    [
      'external_platform_id',
      {
        header: 'External platform',
        editable: true,
        type: structureTypes.TYPE_DROPDOWN,
        options: externalPlatforms?.map(({ id, name }) => ({ id, label: name })),
        parameters: {
          allowAny: true,
          anyOption: 'Not transferred',
        },
      },
    ],
    [
      'external_platform_reference',
      {
        header: 'External platform reference',
        editable: true,
        triggers: [{ on: 'external_platform_id', type: 'enable', ifFalsy: true }],
      },
    ],
    showBranchField && [
      'external_branch_reference',
      {
        header: 'External Branch reference',
        editable: true,
        triggers: [{ on: 'external_platform_id', type: 'enable', ifFalsy: true }],
      },
    ],
    showTimekeepingField && [
      'external_timekeeping_reference',
      {
        header: 'External Timekeeping reference',
        editable: true,
        triggers: [{ on: 'external_platform_id', type: 'enable', ifFalsy: true }],
      },
    ],
    showTransferredOnField && [
      'transferred_on',
      {
        header: 'Transferred on',
        editable: true,
        type: structureTypes.TYPE_DATE,
        parameters: { autoDate: true, disableFuture: true },
        triggers: [{ on: 'external_platform_id', type: 'enable', ifFalsy: true }],
      },
    ],
  ].filter(Boolean)
