// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import { put, takeLatest } from 'redux-saga/effects'
import { fetchEmployer } from 'syft-acp-core/store/employers/actions'
import * as types from 'syft-acp-core/actions/action-types'
import * as api from 'syft-acp-core/api/resources/admin/sales'
import { notificationCall } from './calls'
import { takeLatestApiCall } from './helpers'

export default function* root() {
  yield takeLatestApiCall('EMPLOYER_SALES_FORCE_FETCH', api.getSalesWinAssociations)
  yield takeLatestApiCall('EMPLOYER_SALES_FORCE_SAVE', api.saveSalesWinAssociations)
  yield takeLatest(
    [types.EMPLOYER_SALES_FORCE_SAVE_SUCCEEDED, types.EMPLOYER_SALES_FORCE_SAVE_FAILED],
    notificationCall
  )
  yield takeLatest([types.EMPLOYER_SALES_FORCE_SAVE_SUCCEEDED], ({ options }) => put(fetchEmployer(options.employerID)))
}
