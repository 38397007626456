import * as React from 'karet'
import PropTypes from 'prop-types'
import InputDropdown from 'syft-acp-atoms/InputDropdown'
import { get } from 'lodash-es'

import { cellPropTypes, cellDefaultProps, PropTypesAtom } from '../dataProps'
import { colEditablePlaceholderFactory } from '../../../placeholders/factory'

export const AcpEditableColDropdown = colEditablePlaceholderFactory('AcpEditableCellDropdown', ['AcpOption'])

const AcpEditableCellDropdown = ({ value, spec, options }) => {
  // Pass on the placeholder if it's set; otherwise a default will be shown.
  const placeholder = get(options, 'placeholder', undefined)
  // Rename the 'children' value to 'label'.
  const selectOptions = spec._nested.map(o => ({ ...o, label: o.children }))
  return <InputDropdown selectOptions={selectOptions} value={value} placeholder={placeholder} />
}

AcpEditableCellDropdown.propTypes = {
  ...cellPropTypes,
  value: PropTypesAtom,
  // The dropdown options are passed as an array of objects:
  // [{ slug: 'option_a', children: 'Option A' }]
  spec: PropTypes.shape({
    _nested: PropTypes.arrayOf(
      PropTypes.shape({
        slug: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
        children: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,
      })
    ).isRequired,
  }).isRequired,
  options: PropTypes.shape({
    placeholder: PropTypes.string,
  }).isRequired,
}

AcpEditableCellDropdown.defaultProps = {
  ...cellDefaultProps,
}

AcpEditableCellDropdown.cellProperties = ['form']

export default AcpEditableCellDropdown
