import { apiRequest, handleCall } from 'syft-acp-core/api/call'
import { PatchShiftInput, FetchShiftInput } from '../shifts/types'

export const fetchFulfilmentShift = ({ payload }: FetchShiftInput) =>
  new Promise((resolve, reject) => {
    const req = apiRequest({
      path: `/admin/shifts/${payload.id}`,
      method: 'GET',
    })
    handleCall(req, resolve, reject)
  })

export const patchFulfilmentShift = ({ payload }: PatchShiftInput) =>
  new Promise((resolve, reject) => {
    const req = apiRequest({
      path: `/admin/shifts/${payload.id}`,
      reqArgs: payload,
      method: 'PATCH',
      allowNulls: true,
    })
    handleCall(req, resolve, reject)
  })
