import {
  fetchCsatSkipped,
  fetchCsatScore,
  fetchCsatResults,
  fetchCsatCsvDataCSV,
} from 'syft-acp-core/api/resources/csat'
import { takeLatestApiCall } from './helpers'

export default function* root() {
  yield takeLatestApiCall('CSAT_SKIPPED_FETCH', fetchCsatSkipped)
  yield takeLatestApiCall('CSAT_SCORE_FETCH', fetchCsatScore)
  yield takeLatestApiCall('CSAT_RESULTS_FETCH', fetchCsatResults)
  yield takeLatestApiCall('CSAT_FETCH_CSV', fetchCsatCsvDataCSV)
}
