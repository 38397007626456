// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import React from 'react'
import PropTypes from 'prop-types'

import EmployerAccountsList from 'syft-acp-core/entities/EmployerDetail/EmployerAccountsList'
import SubNavigation from 'syft-acp-core/components/Navigation/SubNavigation'

const EmployerAccountsListView = ({ match: { params } }) => (
  <div className="page entity-list">
    <SubNavigation subPage="employers" data={{ employerID: Number(params.id) }} />
    <EmployerAccountsList
      employerID={Number(params.id)}
      urlBase={`/entity/employers/view/${params.id}/accounts/view/`}
    />
  </div>
)

EmployerAccountsListView.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      // Note: ID gets passed as a string directly from the URL.
      id: PropTypes.string.isRequired,
    }).isRequired,
  }),
}

export default EmployerAccountsListView
