// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import React, { PureComponent } from 'react'
import { history } from 'syft-acp-core/history'

import { store } from 'syft-acp-core/store'
import * as tableType from 'syft-acp-core/components/EntityList/'
import * as employerVenueActions from 'syft-acp-core/store/employer-venues/actions'
import { selectVenueAreas$ } from 'syft-acp-core/store/employer-venues/selectors'
import entityDetailList from 'syft-acp-core/components/EntityDetail/entityDetailList'
import entityList from 'syft-acp-util/entityList'
import * as filterTypes from 'syft-acp-util/components/FilterForm/FilterForm'

const tableBaseFormat = [
  {
    type: tableType.TXT,
    header: 'ID',
    val: 'id',
    numeric: true,
  },
  {
    type: tableType.TXT,
    val: 'name',
    header: 'Name',
  },
  {
    type: tableType.TXT,
    val: 'desc',
    header: 'Description',
  },
  {
    type: tableType.TXT,
    val: 'access_instructions',
    header: 'Access instructions',
  },
  {
    type: tableType.TXT,
    header: 'Arrival minutes',
    val: 'arrival_minutes',
    numeric: false,
  },
]

const VenueAreasListWrapper = entityDetailList({ title: 'Venue areas', withPagination: false })

const actionFormat = (employerID, venueID) => [
  {
    label: 'New area',
    type: filterTypes.TYPE_BUTTON,
    buttonType: 'success',
    global: true,
    action: () => {
      history.push(`/entity/employers/view/${employerID}/venues/view/${venueID}/areas/new`)
    },
  },
]

export default class EmployerVenueAreaList extends PureComponent {
  render() {
    const EntityList = entityList(
      VenueAreasListWrapper,
      tableBaseFormat,
      null,
      actionFormat(this.props.employerID, this.props.venueID),
      'employerVenues',
      {
        fetchEntities: ({ options }, { venueID }) => employerVenueActions.fetchVenueAreas(venueID, options),
      },
      {
        entityList: selectVenueAreas$(store.getState().employerVenues)(this.props.venueID),
        recordList: true,
        urlGenerator: item =>
          `/entity/employers/view/${this.props.employerID}/venues/view/${this.props.venueID}/areas/view/${item.id}`,
        disableAutoScroll: true,
      },
    )
    return <EntityList {...this.props} />
  }
}
