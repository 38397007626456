// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import { takeLatest } from 'redux-saga/effects'

import * as api from 'syft-acp-core/api/resources/employers/employer_accounts'
import * as types from 'syft-acp-core/actions/action-types'
import { takeLatestApiCall } from './helpers'
import { notificationCall, redirectCall } from './calls'

export default function* root() {
  yield takeLatestApiCall('EMPLOYER_ACCOUNTS_FETCH', api.listEmployerAccounts)
  yield takeLatestApiCall('EMPLOYER_ACCOUNT_FETCH', api.fetchEmployerAccount)
  yield takeLatestApiCall('EMPLOYER_ACCOUNT_SAVE', api.saveEmployerAccount)
  yield takeLatestApiCall('EMPLOYER_ACCOUNT_DISABLE', api.disableEmployerAccount)
  yield takeLatestApiCall('EMPLOYER_ACCOUNT_INVITE', api.inviteEmployerAccount)
  yield takeLatestApiCall('EMPLOYER_ACCOUNT_DESTROY_PERMISSIONS', api.destroyEmployerAccountPermissions)
  yield takeLatestApiCall('EMPLOYER_ACCOUNT_LOGIN', api.loginAsEmployerAccount)
  yield takeLatest(
    [types.EMPLOYER_ACCOUNT_DISABLE_SUCCEEDED, types.EMPLOYER_ACCOUNT_INVITE_SUCCEEDED],
    redirectCall,
    ({ request }) => `/entity/employers/view/${request.employerID}/accounts/list`,
  )

  yield takeLatest(
    [
      types.EMPLOYER_ACCOUNT_SAVE_SUCCEEDED,
      types.EMPLOYER_ACCOUNT_SAVE_FAILED,
      types.EMPLOYER_ACCOUNT_DESTROY_PERMISSIONS_SUCCEEDED,
      types.EMPLOYER_ACCOUNT_DESTROY_PERMISSIONS_FAILED,
      types.EMPLOYER_ACCOUNT_DISABLE_SUCCEEDED,
      types.EMPLOYER_ACCOUNT_DISABLE_FAILED,
      types.EMPLOYER_ACCOUNT_INVITE_SUCCEEDED,
      types.EMPLOYER_ACCOUNT_INVITE_FAILED,
      types.EMPLOYER_ACCOUNT_LOGIN_FAILED,
    ],
    notificationCall,
  )
}
