import * as types from './action-types'

/**
 * Get a list of agency venues
 *
 * @returns {{options: *, type: string }}
 */
export const fetchAgencyVenues = ({ venueName, employerId }: { venueName: string, employerId?: number }) => ({
  type: types.AGENCY_VENUES_FETCH_BEGIN,
  payload: { venueName, employerId },
})
