// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import React from 'react'

import WatchlistComplianceList from 'syft-acp-core/entities2/WatchlistCompliance'
import PageView from 'syft-acp-core/components/PageView'
import SubNavigationWatchlist from './SubNavigation'

export const WatchlistCompliance = () => (
  <>
    <SubNavigationWatchlist />
    <PageView title={['Watchlist', 'Worker compliance']} entityPage>
      <WatchlistComplianceList />
    </PageView>
  </>
)
