import React from 'react'
import PropTypes from 'prop-types'
import { Button } from 'syft-acp-atoms/Button'
import { history } from 'syft-acp-core/history'

const onClick = workerID => history.push(`/entity/workers/view/${workerID}/networks/new`)

const NewNetworkButton = ({ workerID, label }) => (
  <div className="small-padded" style={{ display: 'inline-block' }}>
    <Button kind="success" onClick={() => onClick(workerID)}>
      {label}
    </Button>
  </div>
)

NewNetworkButton.propTypes = {
  workerID: PropTypes.number.isRequired,
  label: PropTypes.string.isRequired,
}

export default NewNetworkButton
