import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { Button } from 'syft-acp-atoms/Button'
import { offerBooking } from 'syft-acp-core/actions/bookings'

const ListingOfferButton = ({ dispatch, workerID, jobID, shiftID }) => {
  const onClick = () => {
    const params = {
      job_id: jobID,
      worker_id: workerID,
      shift_ids: [shiftID],
    }

    dispatch(offerBooking(params))
  }

  return (
    <div className="small-padded">
      <Button onClick={onClick}>Offer</Button>
    </div>
  )
}

ListingOfferButton.propTypes = {
  dispatch: PropTypes.func.isRequired,
  workerID: PropTypes.number.isRequired,
  jobID: PropTypes.number.isRequired,
  shiftID: PropTypes.number.isRequired,
}

export default connect()(ListingOfferButton)
