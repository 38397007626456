// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import { downloadAsFileFromApi } from 'syft-acp-core/api/file'
import { apiRequest, handleCall } from 'syft-acp-core/api/call'

// URL for retrieving worker CSV files.
const apiWorkerCsvURL = '/admin/workers.csv'
const apiWorkerPaycomCsvURL = '/admin/paycom_onboarding_workers.csv'

export const downloadCSV = (open, { options, view }) => {
  const path = view === 'paycom' ? apiWorkerPaycomCsvURL : apiWorkerCsvURL
  return downloadAsFileFromApi(path, options, [view, 'workers'].filter(Boolean).join('-'))
}

export const fetchCompletedShifts = ({ workerID, options = {} }) =>
  new Promise((resolve, reject) => {
    const req = apiRequest({ path: `/admin/workers/${workerID}/completed_shifts`, reqArgs: options })
    handleCall(req, resolve, reject, true, options)
  })

const baseAutocompleteRequest = async (entity, query) => {
  const response = await fetch(apiRequest({ path: `/admin/${entity}/autocomplete`, reqArgs: { query } }))
  return response.json()
}

/**
 * Runs autocomplete search for workers.
 *
 * @param {String} query String to query the database with
 * @returns {Promise} List of workers on promise fulfillment
 */
export const workerAutocomplete = async query =>
  baseAutocompleteRequest('workers', query).then(json => json.suggestions)

const transformCitiesToOptions = cities => cities?.map(({ city }) => ({ data: city, value: city }))
/**
 * Runs autocomplete search for venues cities.
 *
 * @param {String} query String to query the database with
 * @returns {Promise} List of cities from venues on promise fulfillment
 */
export const venuesCitiesAutocomplete = query => baseAutocompleteRequest('cities', query).then(transformCitiesToOptions)

/**
 * Runs autocomplete search for worker cities.
 *
 * @param {String} query String to query the database with
 * @returns {Promise} List of workers on promise fulfillment
 */
export const workerCitiesAutocomplete = query =>
  baseAutocompleteRequest('workers/cities', query).then(transformCitiesToOptions)

export const updateWorkerType = ({ workerID, workerType }) =>
  new Promise((resolve, reject) => {
    const req = apiRequest({ path: `/admin/workers/${workerID}/status`, reqArgs: workerType, method: 'PATCH' })
    handleCall(req, resolve, reject)
  })

export const fetchUpcomingWorkerJobs = ({ workerID }) =>
  new Promise((resolve, reject) => {
    const req = apiRequest({ path: `/workers/${workerID}/upcoming_jobs` })
    handleCall(req, resolve, reject, true)
  })

const workerTransferBase = ({ payload: reqArgs }, method = 'GET') =>
  new Promise((resolve, reject) => {
    const req = apiRequest({ path: `/admin/workers/worker_transfer`, reqArgs, method })
    handleCall(req, resolve, reject, true)
  })

export const fetchWorkerTransfer = action => workerTransferBase(action)
export const updateWorkerTransfer = action => workerTransferBase(action, 'PATCH')
export const deleteWorkerTransfer = action => workerTransferBase(action, 'DELETE')
export const createWorkerTransfer = action => workerTransferBase(action, 'POST')

export const uploadTransferredWorkerCsv = ({ file }) =>
  new Promise((resolve, reject) => {
    const reqArgs = new FormData()
    reqArgs.append('file', file)
    const req = apiRequest({ path: '/admin/imports/staffmark', reqArgs, method: 'POST', utilizePagination: false })
    handleCall(req, resolve, reject)
  })

export const resendWorkerInvitation = ({ email }) =>
  new Promise((resolve, reject) => {
    const req = apiRequest({
      path: '/admin/workers/worker_transfer/resend_invitation',
      reqArgs: { email },
      method: 'POST',
    })
    handleCall(req, resolve, reject)
  })
