// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import React from 'react'
import { TrackingProvider, TrackingTrigger } from '@indeed/flex-tracking-context'

import PageView from 'syft-acp-core/components/PageView'
import WorkerNetworkDetail from 'syft-acp-core/entities/WorkerNetworkDetail/WorkerNetworkDetail'
import { trackingEvents } from 'syft-acp-core/entities/WorkerNetworkDetail/WorkerNetworkDetail.tracking'
import { WorkerSubNavigation } from './SubNavigation'
import { RouteComponentProps } from 'react-router-dom'

type Props = RouteComponentProps<{
  id: string
  networkID: string
}>

export const EntityWorkersNetworksView = ({ match: { params } }: Props) => (
  <TrackingProvider
    value={{
      context: {
        worker_id: params.id,
        network_id: params.networkID,
      },
    }}
  >
    <TrackingTrigger onLoad={{ event: trackingEvents.DETAIL.PAGE.VIEWED }} />
    <WorkerSubNavigation workerID={params.id} />
    <PageView title={[`Viewing network ${params.networkID} for worker ${params.id}`]}>
      <WorkerNetworkDetail workerID={Number(params.id)} id={Number(params.networkID)} />
    </PageView>
  </TrackingProvider>
)
