import React from 'react'
import { useFlexFlagIsOn } from '@indeed/flex-feature-flags'
import SubNavigation, { SubLink } from 'syft-acp-atoms/SubNavigation'

const ConductSubNav = () => {
  const banReviews = useFlexFlagIsOn('pte_32272_hide_strikes_add_bans')

  return (
    <SubNavigation>
      <SubLink to="/conduct/appeals">Appeals</SubLink>
      {banReviews ? (
        <SubLink to="/conduct/ban-reviews">Ban reviews</SubLink>
      ) : (
        <SubLink to="/conduct/conduct-reviews">Conduct reviews</SubLink>
      )}
    </SubNavigation>
  )
}

export default ConductSubNav
