// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import moment from 'moment'

import { alertLink } from 'syft-acp-util/alerts'
// import * as alertTypes from 'syft-acp-util/alerts'; TODO: implement this.
import { formatRelative, formatISODateTime } from 'syft-acp-util/formatting'

import './AlertItem.css'

const getWithDate = createdTime => {
  const createdFormatted = formatISODateTime(createdTime)
  const todayDate = moment().format('YYYY-MM-DD')
  return createdFormatted.replace(todayDate, '').trim()
}

// Adds <span> values for search highlights.
const highlightMessage = (message, search) => {
  if (search === '') {
    return message
  }
  const re = new RegExp(`(${search})`, 'ig')
  return message.replace(re, '<em>$1</em>')
}

const AlertItem = ({
  message,
  resource_type,
  resource_id,
  created_at,
  searchValue,
  additional_resource_id,
}) => {
  // Don't display the item at all if there is no message.
  if (!message) {
    return null
  }

  const alertMessage = highlightMessage(message, searchValue)

  // Generate a link (if this resource type has one). Either a string or null.
  const link = alertLink(resource_type, resource_id, additional_resource_id)
  // If the link is null, render a plain <div> instead.
  const Container = link ? Link : 'div'

  // Displayed timestamps.
  const timeRel = formatRelative(created_at)
  const timeISO = getWithDate(created_at)

  return (
    <Container {...(link && { to: link })} className={`AlertItem ${link && 'is-link'}`}>
      <div className="message">
        <div className="time">{`${timeISO} (${timeRel})`}</div>
        <div dangerouslySetInnerHTML={{ __html: alertMessage }} />
      </div>
    </Container>
  )
}

AlertItem.propTypes = {
  id: PropTypes.number.isRequired,
  type: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  resource_type: PropTypes.string.isRequired,
  resource_id: PropTypes.number.isRequired,
  created_at: PropTypes.instanceOf(Date).isRequired,
  searchValue: PropTypes.string.isRequired,
}

export default AlertItem
