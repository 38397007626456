// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import FileSaver from 'file-saver'
import ObjectsToCsv from 'syft-acp-util/modules/objectsToCSV'

/**
 * Downloads the results as CSV.
 */
const downloadAsCSV = async ({ payload }, csvName) => {
  // Convert the payload to CSV and initiate a download.
  const csv = await new ObjectsToCsv(payload).toString()
  const blob = new Blob([csv], { type: 'text/csv;charset=utf-8' })
  FileSaver.saveAs(blob, `${csvName}.csv`)
}

export default downloadAsCSV
