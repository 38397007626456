import { apiRequest, handleCall } from 'syft-acp-core/api/call'

export const fetchAgencyBookings = ({ listingId, shiftId }) =>
  new Promise((resolve, reject) => {
    const req = apiRequest({ path: `/admin/listings/${listingId}/agency_shifts/${shiftId}/bookings` })
    handleCall(req, resolve, reject)
  })

export const saveAgencyBookings = ({ payload }) =>
  new Promise((resolve, reject) => {
    const req = apiRequest({ path: '/admin/agency_shift_workers/bulk_update', reqArgs: payload, method: 'PUT' })
    handleCall(req, resolve, reject)
  })
