import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { Modal } from 'syft-acp-core/components/Modal'
import { getModal$ } from 'syft-acp-core/store/modals/selectors'
import moment from 'moment/moment'
import { ControlLabel, FormGroup } from 'react-bootstrap'
import DataTextarea from 'syft-acp-core/components/EditableTable/DataTextarea'
import { showModal } from 'syft-acp-core/lib/modals'
import { hideModal } from 'syft-acp-core/store/modals/actions'
import { bulkEditAdminTimesheets } from 'syft-acp-core/actions/timekeeping'
import { bindActionCreators } from 'redux'

import { RetroactiveEditModalProps as Props, RetroactiveState as State } from './RetroactiveEditModal.types'

export const isDuringPayroll = () => {
  const payrollStart = moment().isoWeekday(3).set({ hour: 10, minute: 0 }).utc()
  const payrollEnd = moment().isoWeekday(3).set({ hour: 14, minute: 30 }).utc()
  return moment().isBetween(payrollStart, payrollEnd)
}

export const isRetroactive = (updatedTime: moment.MomentInput) => {
  const payrollEnd = moment().isoWeekday(3).set({ hour: 14, minute: 30 }).utc()
  const afterCurrentPayroll = moment().isSameOrAfter(payrollEnd)
  const payCycleCutoff = afterCurrentPayroll
    ? moment().isoWeekday(1).set({ hour: 0, minute: 0 })
    : moment().isoWeekday(-6).set({ hour: 0, minute: 0 })
  return moment(updatedTime).isSameOrBefore(payCycleCutoff)
}

class RetroactiveEditModal extends PureComponent<Props, State> {
  // PROPS
  constructor(props: Props) {
    super(props)
    this.state = {
      retroactiveReason: '',
      isValid: false,
    }
  }

  // SETTERS
  setRetroactiveReason = (retroactiveReason: string) => {
    this.setState({ retroactiveReason: retroactiveReason })
    if (retroactiveReason.trim().length > 1) {
      this.setState({ isValid: true })
    }
  }

  // ACTIONS
  openModal = () => {
    this.props.actions.showModal(this.props.item.modalName)
  }

  closeModal = () => {
    this.props.actions.hideModal(this.props.item.modalName)
  }

  confirmHandler = () => {
    if (this.state.isValid) {
      const bulkEditParams = [
        {
          shift_id: this.props.item.data.shift_id,
          worker_id: this.props.item.data.worker_id,
          retroactive_reason: 'RetroEdit: ' + this.state.retroactiveReason,
        },
      ]
      this.props.actions.bulkEditAdminTimesheets({ entries: bulkEditParams })
      this.closeModal()
    }
  }

  render() {
    const { retroactiveReason, isValid } = this.state

    return (
      <Modal
        header="Make retroactive time edit"
        isShown={this.props.item.isShown}
        onClose={this.closeModal} //
        onConfirm={this.confirmHandler}
        confirmationText="Submit"
        canSubmit={!isDuringPayroll() && isValid}
      >
        <div className="retroactive-edit-modal">
          {isDuringPayroll() ? (
            <div>
              The edit you are trying to make is considered retroactive (within or before the active payroll
              period) while payroll is being run. Please refresh and try this operation again outside the
              hours of Wednesday 10:00AM to 2:30PM CST.
            </div>
          ) : (
            <FormGroup className="form-item">
              <ControlLabel>Retroactive edit reason:</ControlLabel>
              <DataTextarea
                name="retroactiveReason"
                value={retroactiveReason}
                onChange={this.setRetroactiveReason}
                disabled={isDuringPayroll()}
              />
            </FormGroup>
          )}
        </div>
      </Modal>
    )
  }
}

export const storeConnector = connect(
  state => ({
    item: getModal$(state.modals)('retroactiveEditModal'),
  }),
  dispatch => ({
    actions: {
      ...bindActionCreators({ showModal, hideModal, bulkEditAdminTimesheets }, dispatch),
    },
  }),
)
export default storeConnector(RetroactiveEditModal)
