import PropTypes from 'prop-types'
import React from 'react'
import { Col, Grid, Row } from 'react-bootstrap'
import { connect } from 'react-redux'
import { fetchEmployers } from 'syft-acp-core/store/employers/actions'
import EntityDetailItem from 'syft-acp-core/components/EntityDetail/EntityDetailItem'
import RecordHeader from 'syft-acp-core/components/RecordSection/RecordHeader'
import RecordSegment from 'syft-acp-core/components/RecordSection/RecordSegment'
import SiteComponent from 'syft-acp-core/components/SiteComponent'
import entityList from 'syft-acp-util/entityList'
import { tableFormat } from './structure'

const Wrapper = props => (
  <SiteComponent>
    <Grid>
      <Row>
        <Col md={12} sm={12}>
          <EntityDetailItem withEntityList withPagination>
            <RecordSegment noPadding>
              <RecordHeader border main>
                Employers
              </RecordHeader>
            </RecordSegment>
            <div className="list">
              {props.entityListResults}
              {props.entityList}
              {props.entityListPagination}
            </div>
          </EntityDetailItem>
        </Col>
      </Row>
    </Grid>
  </SiteComponent>
)

Wrapper.propTypes = {
  children: PropTypes.array,
}

export default connect(({ cities }) => ({ cities }))(
  entityList(
    Wrapper,
    tableFormat,
    null,
    null,
    'employers',
    { fetchEntities: fetchEmployers },
    {
      recordList: true,
      disableAutoScroll: true,
    }
  )
)
