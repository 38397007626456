import * as React from 'react'
import { Col, Grid, Row } from 'react-bootstrap'
import { RouteComponentProps } from 'react-router-dom'

import PageView from 'syft-acp-core/components/PageView'

import AdhocShiftActions from 'syft-acp-core/entities2/AdhocShifts/AdhocShiftActions'

export const AdhocShiftApproval = ({
  match: { params },
}: RouteComponentProps<{ shiftBookingId: string }>) => (
  <PageView title={['Ad-hoc shift approval', `Acting on shift booking #${params.shiftBookingId}`]}>
    <Grid fluid>
      <Row>
        <Col md={12}>
          {/* @ts-ignore */}
          <AdhocShiftActions shiftBookingId={Number(params.shiftBookingId)} />
        </Col>
      </Row>
    </Grid>
  </PageView>
)
