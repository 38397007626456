import { apiRequest, handleCall } from 'syft-acp-core/api/call'
import * as actions from './actions'
import type { MatchingExplaiabilityAction } from './actions'

const fetchEventsBase = ({ payload: reqArgs }: MatchingExplaiabilityAction, method = 'GET') =>
  new Promise((resolve, reject) => {
    const req = apiRequest({
      path: `/explain_match`,
      reqArgs: {
        worker_id: reqArgs.workerId,
        job_id: reqArgs.jobId,
        use_case: 'flexers_client_can_offer_to',
      },
      method,
    })
    handleCall(req, resolve, reject, true, reqArgs)
  })

export const fetchMatchingExplainability = (action: ReturnType<typeof actions.fetchMatchingExplainability>) =>
  fetchEventsBase(action)
