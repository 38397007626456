import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { ControlLabel, HelpBlock, FormGroup } from 'react-bootstrap'

import { emailTimesheetFromListing } from 'syft-acp-core/store/listings/api'
import { getModal$ } from 'syft-acp-core/store/modals/selectors'
import { Modal } from 'syft-acp-core/components/Modal'
import { showModal, hideModal } from 'syft-acp-core/store/modals/actions'
import DataText from 'syft-acp-core/components/EditableTable/DataText'

import { withTrackingTriggerHoc } from 'syft-acp-util/withTrackingTriggerHoc'

import { trackingEvents } from './Modal.tracking'

class ListingEmailCSVModal extends PureComponent {
  constructor(props) {
    super(props)
    this.state = { valid: true, textValue: '', sending: false }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    // If we are becoming visible, while not being visible, reset the form.
    if (this.props.item.isShown !== nextProps.item.isShown && nextProps.item.isShown === true) {
      this.setState({ msg: null, valid: true, textValue: '' })
    }
  }

  onConfirmHandler = () => {
    const isValid = this.validate()

    if (isValid && this.state.sending !== true) {
      this.setState({ sending: true })
      emailTimesheetFromListing({ listingID: this.props.listingID, email: this.state.textValue }).then(data => {
        if (data.payload.success !== true) {
          const msg = 'Something went wrong while sending. Check the email address.'
          this.props.triggerEvent(trackingEvents.LISTING_EMAIL_CSV_MODAL.CSV_REQUEST.FAILED, { error_message: msg })
          this.setState({ sending: false, valid: false, msg })
        } else {
          this.props.triggerEvent(trackingEvents.LISTING_EMAIL_CSV_MODAL.CSV_REQUEST.SUCCEEDED)
          this.setState({ sending: false })
          this.closeModal()
        }
      })
    }
  }

  setValue = val => {
    this.setState({ textValue: val })
  }

  validate = () => {
    if (!this.state.textValue) {
      const msg = 'Email address can not be blank'
      this.setState({ valid: false, msg })
      return false
    }

    this.setState({ valid: true, msg: null })
    return true
  }

  closeModal = () => {
    this.props.triggerEvent(trackingEvents.LISTING_EMAIL_CSV_MODAL.CLOSED)
    this.props.dispatch(hideModal(this.props.item.modalName))
  }

  openModal = () => this.props.dispatch(showModal(this.props.item.modalName))

  render() {
    return (
      <Modal
        header="Email Timesheet CSV"
        isShown={this.props.item.isShown}
        onClose={this.closeModal}
        onConfirm={this.onConfirmHandler}
        confirmationText="Send email"
      >
        <FormGroup validationState={this.state.valid ? null : 'error'}>
          <ControlLabel>Email address</ControlLabel>
          <DataText
            onChange={this.setValue}
            value={this.state.textValue}
            onKeyPress={ev => {
              if (ev.key === 'Enter') {
                this.onConfirmHandler()
              }
            }}
            editable
          />
          {this.state.sending && <HelpBlock>Sending email...</HelpBlock>}
          {!this.state.valid && <HelpBlock>{this.state.msg}</HelpBlock>}
        </FormGroup>
      </Modal>
    )
  }
}

ListingEmailCSVModal.propTypes = {
  className: PropTypes.string,
  item: PropTypes.object,
  dispatch: PropTypes.func,
  triggerEvent: PropTypes.func.isRequired,
}

export default connect(state => ({
  item: getModal$(state.modals)('listingEmailCSVModal'),
}))(withTrackingTriggerHoc(ListingEmailCSVModal))
