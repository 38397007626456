import PropTypes from 'prop-types'

import placeholderFactory from './factory'

/**
 * Element used to generate buttons for <Acp.Table /> components.
 *
 *    <Acp.Button kind="danger" needsConfirm={ false }>Delete</Acp.Button>
 *
 * Has the following properties:
 *
 *    * kind: must be one of 'primary', 'regular', 'neutral', 'success', 'warning', 'danger', 'highlighted'.
 *    * needsConfirm: presents a modal window for confirmation if clicked.
 *    * confirmHeader: header of the confirmation modal.
 *    * confirmText: text of the confirmation modal.
 *    * confirmYes: label of the 'yes' confirmation button.
 *    * confirmNo: label of the 'no' confirmation button.
 *    * onClick: a function that runs when clicking the button, taking an object containing rowData and tableData among other things.
 *
 * See also AcpButtonLink.
 */
export default placeholderFactory('AcpButton', {
  confirmHeader: PropTypes.string,
  confirmNo: PropTypes.string,
  confirmText: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  confirmYes: PropTypes.string,
  kind: PropTypes.oneOf(['primary', 'regular', 'neutral', 'success', 'warning', 'danger', 'highlighted']),
  needsConfirm: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
})
