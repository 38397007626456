// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import FilterAutocompleteEmployers from 'syft-acp-util/components/FilterForm/FilterAutocompleteEmployers'

class DataEmployer extends PureComponent {
  static propTypes = {
    ids: PropTypes.arrayOf(PropTypes.number),
    isSaved: PropTypes.bool,
    disabled: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.any,
    parameters: PropTypes.object,
  }

  static defaultProps = {
    ids: [],
    disabled: false,
    value: '',
    isSaved: false,
    parameters: {},
  }

  handleChange = employerID => this.props.onChange(employerID)

  render() {
    const onlyShow = this.props.isSaved && this.props.parameters.displayOnSave
    return (
      <div className={classnames('DataEmployer', { 'plain-text': onlyShow })}>
        {onlyShow ? (
          <div>{this.props.value}</div>
        ) : (
          <FilterAutocompleteEmployers
            removable={this.props.parameters.removable}
            disabled={this.props.disabled}
            forceValue={this.props.value}
            queryType="id"
            callback={this.handleChange}
          />
        )}
      </div>
    )
  }
}

export default DataEmployer
