// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import * as types from './action-types'
import { FetchListOptions } from './types'

export function fetchExpiringDocuments({ options }: FetchListOptions) {
  return { type: types.EXPIRING_DOCUMENTS_FETCH_BEGIN, options }
}

/**
 * Marks expiring documents as resolved.
 *
 * @param {Array} ids Array of IDs
 */
export function markExpiringDocumentsAsResolved(ids: number[]) {
  return { type: types.EXPIRING_DOCUMENTS_RESOLVE_BEGIN, ids }
}
