import {
  AGENCY_ACCOUNTS_FETCH_BEGIN,
  AGENCY_ACCOUNTS_UPDATE_BEGIN,
  AGENCY_ACCOUNTS_PERMISSIONS_FETCH_BEGIN,
} from './action-types'

export const fetchAgencyAccounts = payload => ({
  type: AGENCY_ACCOUNTS_FETCH_BEGIN,
  payload,
})

export const fetchAgencyAccountsPermissions = payload => ({
  type: AGENCY_ACCOUNTS_PERMISSIONS_FETCH_BEGIN,
  payload,
})

export const updateAgencyAccounts = (id, data) => ({
  type: AGENCY_ACCOUNTS_UPDATE_BEGIN,
  id,
  data,
})
