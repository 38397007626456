// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import { map, get, memoize, keyBy } from 'lodash-es'
import { createSelector } from 'reselect'
import entityReducer from './generators/entityReducer'

import * as entityTypes from './generators/entities'
import { entitySetName } from './generators/utils'
import * as types from '../actions/action-types'

// These are the two types we are given by the API.
// Note: a WorkLocation can be either a venue or an area. To see which is which,
// see if the object's 'target' has a key named 'areas', which makes it a venue.
export const TYPE_EMPLOYER = 'Employer'
export const TYPE_WORKLOCATION = 'WorkLocation'
// We don't get these from the API, but we save them ourselves for ease of use.
export const TYPE_VENUE = 'Venue'
export const TYPE_AREA = 'Area'

/**
 * Selects all items associated with salesforce: venues, areas and the employer.
 */
export const selectSalesForce$ = createSelector(
  state => state,
  store =>
    memoize(args => {
      if (!args.employerID) {
        return []
      }

      // Retrieve sales win associations we fetched from the API.
      const entitySet = entitySetName(args)
      const winAssocs = get(store.employerSalesForce.entitySets[entitySet], 'entityMap', {})

      // Select the venues for our current search query (e.g. { employerID: 1 }).
      const venuesSet = store.employerVenues.entitySets[entitySet]
      if (!venuesSet) {
        return []
      }
      // Map to array and add _type: 'venue'.
      const venues = map(venuesSet.ids, item => ({ ...venuesSet.entityMap[item], _type: 'Venue', _inherit: true }))
      // Merge the venues with areas into one big array.
      const venuesAndAreas = venues.reduce(
        (acc, curr) => [
          ...acc,
          curr,
          ...curr.areas.map(area => ({ ...area, venueID: curr.id, _type: 'Area', _inherit: true })),
        ],
        []
      )
      // Merge the employer in. Save the array's order in the objects.
      // TODO: we shouldn't have this distinction between 'detail' and 'map' to begin with.
      const employerByDetail = store.employers.entityDetail[args.employerID] || {}
      const employerByMap = store.employers.entityMap[args.employerID] || {}
      const employer = employerByDetail.id ? employerByDetail : employerByMap

      // Create the same '_target_type_id' identifier that the win associations get.
      const locationData = [{ ...employer, _type: TYPE_EMPLOYER, _inherit: false }, ...venuesAndAreas].map(
        (item, _n) => ({ ...item, _n, _target_type_id: `${item._type}$${item.id}` })
      )

      // Make an object from the 'id' key of the items. This way we can easily sort the win associations.
      const locationDataKeys = keyBy(locationData, '_target_type_id')

      // Merge in the win association data. If data exists for that particular ID,
      // it means that venue or area has its own custom sales force data.
      // If not, it will inherit from its parent.
      const salesForce = Object.keys(locationDataKeys)
        .reduce((acc, n) => {
          const loc = locationDataKeys[n]
          const win = get(winAssocs, loc._target_type_id, {})
          const parentWin =
            loc._type === TYPE_VENUE
              ? // Take from employer directly.
                winAssocs[`${TYPE_EMPLOYER}$${args.employerID}`]
              : // Look up venue, go to employer if not found.
              winAssocs[`${TYPE_VENUE}$${loc.venueID}`]
              ? winAssocs[`${TYPE_VENUE}$${loc.venueID}`]
              : winAssocs[`${TYPE_EMPLOYER}$${args.employerID}`]
          const inherits = get(win, 'id') == null
          return [...acc, { ...loc, _inherit: inherits, _winAssociations: inherits ? parentWin : win }]
        }, [])
        .sort((a, b) => (a._n > b._n ? 1 : -1))

      return salesForce
    })
)

export default entityReducer(
  'employerSalesForce',
  {
    [entityTypes.ENTITIES_FETCH_BEGIN]: types.EMPLOYER_SALES_FORCE_FETCH_BEGIN,
    [entityTypes.ENTITIES_FETCH_SUCCEEDED]: types.EMPLOYER_SALES_FORCE_FETCH_SUCCEEDED,
    [entityTypes.ENTITIES_FETCH_FAILED]: types.EMPLOYER_SALES_FORCE_FETCH_FAILED,
    [entityTypes.ENTITY_SAVE_BEGIN]: types.EMPLOYER_SALES_FORCE_SAVE_BEGIN,
    [entityTypes.ENTITY_SAVE_SUCCEEDED]: types.EMPLOYER_SALES_FORCE_SAVE_SUCCEEDED,
    [entityTypes.ENTITY_SAVE_FAILED]: types.EMPLOYER_SALES_FORCE_SAVE_FAILED,
  },
  {
    idKey: '_target_type_id',
    payloadProcess: payload =>
      payload.map(item => {
        const isEmployer = item.target_type === TYPE_EMPLOYER
        const isVenue = item.target_type === TYPE_WORKLOCATION && item.target.areas
        const type = isEmployer ? TYPE_EMPLOYER : isVenue ? TYPE_VENUE : TYPE_AREA
        return {
          ...item.sales_win_attributes,
          _id: item.id,
          _swa_id: item.sales_win_id,
          _type: type,
          _target_type_id: `${type}$${item.target_id}`,
        }
      }),
    groupBy: ['employerID'],
    localEntityMap: true,
  }
)
