// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import { takeLatest } from 'redux-saga/effects'

import { listListings } from 'syft-acp-core/api'
import * as types from 'syft-acp-core/actions/action-types'
import { takeLatestApiCall } from 'syft-acp-core/sagas/helpers'
import { redirectCall, notificationCall } from 'syft-acp-core/sagas/calls'
import {
  fetchListingRatings,
  deleteListing,
  deleteMultipleListings,
  fetchAdminListing,
  saveListing,
  publishListingToAgency,
} from './api'

export default function* root() {
  yield takeLatestApiCall('LISTINGS_FETCH', listListings)
  yield takeLatestApiCall('ADMIN_LISTING_FETCH', fetchAdminListing)
  yield takeLatestApiCall('LISTING_SAVE', saveListing)
  yield takeLatestApiCall('LISTING_UPDATE', publishListingToAgency)
  yield takeLatestApiCall('LISTING_FETCH_RATINGS', fetchListingRatings)
  yield takeLatestApiCall('LISTING_DELETE', deleteListing)
  yield takeLatestApiCall('LISTINGS_DELETE', deleteMultipleListings)

  yield takeLatest(types.LISTING_DELETE_SUCCEEDED, redirectCall, () => '/listings')
  yield takeLatest(
    [
      types.LISTING_DELETE_SUCCEEDED,
      types.LISTING_DELETE_FAILED,
      types.LISTINGS_DELETE_SUCCEEDED,
      types.LISTING_UPDATE_SUCCEEDED,
      types.LISTING_UPDATE_FAILED,
      types.LISTING_SAVE_SUCCEEDED,
      types.LISTING_SAVE_FAILED,
    ],
    notificationCall,
  )
}
