import { get, isArray, keyBy, map, range, uniq } from 'lodash-es'
import { entitySetName } from 'syft-acp-core/reducers/generators/utils'
import { emptySet } from 'syft-acp-core/reducers/generators/constants'

export const entitySaveSucceed = (state, action, arrayTypes, idKey, reducerInitialState, options) => {
  const payload = options.payloadProcess ? options.payloadProcess(action.payload, action) : action.payload
  const setKey = entitySetName(action.options, options, action)
  const lastUpdate = Number(new Date())
  const localEntityMap = get(options, 'localEntityMap', false)

  // If we're using a local entity map, save the data in the local object by the entity set key.
  if (localEntityMap) {
    const currentSet = state.entitySets[setKey] || {}
    const idRange = options.keyByPayload
      ? range(get(payload, options.keyByPayload).length)
      : map(isArray(payload) ? payload : [payload], idKey)

    return {
      ...state,
      entityUpdateTimes: {
        ...state.entityUpdateTimes,
        [action.payload[idKey]]: lastUpdate,
      },
      entitySets: {
        ...state.entitySets,
        [setKey]: {
          ...emptySet,
          ...currentSet,
          entityMap: {
            ...get(currentSet, 'entityMap'),
            ...(options.keyByPayload
              ? get(payload, options.keyByPayload)
              : keyBy(payload.length == null ? [payload] : payload, idKey)),
          },
          isLoadingData: false,
          // Since we're saving either a new or an updated item, merge in the new IDs. We should keep uniq ids only otherwise we'll render duplicates
          ids: uniq([...(currentSet.ids || []), ...idRange]),
          totalPages: action.totalPages,
          totalWorkers: action.totalWorkers,
          totalBookings: action.totalBookings,
          totalShifts: action.totalShifts,
          totalVacancies: action.totalVacancies,
          total: action.total,
          activePage: action.activePage,
          perPage: action.perPage,
          nextPage: action.nextPage,
          page: action.page,
        },
      },
      entityMap: {
        ...state.entityMap,
        ...(options.keyByPayload
          ? get(payload, options.keyByPayload)
          : keyBy(payload.length == null ? [payload] : payload, idKey)),
      },
      entityDetail: {
        // Replace the item by ID.
        ...state.entityDetail,
        [payload[idKey]]: {
          ...payload,
        },
      },
      isSavingData: false,
      lastMessage: '',
      lastStatus: null,
      lastBody: null,
      isLoadingData: false,
    }
  } else {
    return {
      ...state,
      entityUpdateTimes: {
        ...state.entityUpdateTimes,
        [payload[idKey]]: lastUpdate,
      },
      entityDetail: {
        // Replace the item by ID.
        ...state.entityDetail,
        [payload[idKey]]: {
          ...payload,
        },
      },
      isSavingData: false,
      lastMessage: '',
      lastStatus: null,
      lastBody: null,
      isLoadingData: false,
    }
  }
}
