// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import React from 'react'
import PropTypes from 'prop-types'

import DataBooleanMatrix from './DataBooleanMatrix'

export const structure = {
  rows: ['early', 'day', 'night'],
  cols: ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'],
}
export const labels = {
  early: 'Early',
  day: 'Day',
  night: 'Night',
  monday: 'Mon',
  tuesday: 'Tue',
  wednesday: 'Wed',
  thursday: 'Thu',
  friday: 'Fri',
  saturday: 'Sat',
  sunday: 'Sun',
}

const DataAvailability = ({ data, editable, onChange }) => (
  <DataBooleanMatrix data={data} structure={structure} labels={labels} editable={editable} onChange={onChange} />
)

DataAvailability.defaultProps = {
  editable: true,
}

DataAvailability.propTypes = {
  data: PropTypes.object.isRequired,
  editable: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
}

export default DataAvailability
