import React, { Component, ErrorInfo, ReactNode } from 'react'
import { datadogRum } from '@datadog/browser-rum-slim'

type Props = {
  children: ReactNode
}

interface State {
  error: null | Error
}

export const ErrorPage = ({ error }: { error: Error }) => {
  return (
    <div
      style={{
        maxWidth: '100%',
        maxHeight: '100%',
        width: '100vw',
        height: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
      }}
    >
      <h1>Ooops... Something went wrong!</h1>
      <div>We were unable to load this page</div>
      <div>Please report the following error in #support channel:</div>
      <p>
        <pre>{error?.stack}</pre>
      </p>
    </div>
  )
}
class ErrorBoundary extends Component<Props, State> {
  public state: State = {
    error: null,
  }

  public static getDerivedStateFromError(error: Error): State {
    return { error }
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    datadogRum.addError(error, {
      errorInfo,
    })
  }

  public render() {
    if (this.state.error) {
      return <ErrorPage error={this.state.error} />
    }

    return this.props.children
  }
}

export default ErrorBoundary
