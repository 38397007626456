import { filter, get, isEmpty, merge, reduce } from 'lodash-es'
import moment from 'moment'
import * as React from 'react'
import { Col, Grid, Row } from 'react-bootstrap'
import { connect } from 'react-redux'
import { createSelector } from 'reselect'
import { useFlexFlagIsOn } from '@indeed/flex-feature-flags'
import { Button } from 'syft-acp-atoms/Button'
import { updateBooking, updateBookingChanges } from 'syft-acp-core/actions/bookings'
import FormError from 'syft-acp-core/components/Form/FormError'
import FormattedTimeRange from 'syft-acp-core/components/FormattedTimeRange'
import { listingShiftBreakTypes } from 'syft-acp-core/constants'
import { showModal } from 'syft-acp-core/store/modals/actions'

import ListingShiftsBreakInput from './ListingShiftsBreakInput'

import './ListingShiftsBreaksDetails.css'

export const validateShiftBooking = (shiftBooking, attr = 'break_details') => {
  const breakDetails = filter(
    get(shiftBooking, attr, []),
    ({ type }) => type !== listingShiftBreakTypes.deleted,
  )
  const startTime = get(shiftBooking, 'shift.start_time')
  const endTime = get(shiftBooking, 'shift.end_time')
  const clockInTime = get(shiftBooking, 'clock_in_time') || get(shiftBooking, 'clock_in.time')
  const clockOutTime = get(shiftBooking, 'clock_out_time') || get(shiftBooking, 'clock_out.time')
  const startTimeRef = clockInTime || startTime
  const endTimeRef = clockOutTime || endTime
  const areBreaksInvalid = breakDetails.some(
    ({ clock_in_time, clock_out_time }) =>
      moment(startTimeRef).isSameOrAfter(clock_in_time) || moment(endTimeRef).isSameOrBefore(clock_out_time),
  )

  if (areBreaksInvalid) {
    const isClockInReference = clockInTime && moment(clockInTime).isValid()
    const isClockOutReference = clockOutTime && moment(clockOutTime).isValid()

    return `Breaks should be between ${isClockInReference ? 'clock in' : 'shift start'} and ${
      isClockOutReference ? 'clock out' : 'shift end'
    } time`
  }

  const timePunches = get(shiftBooking, 'time_punches')
  if (timePunches) {
    const timePunchesOverlapping = timePunches.some(
      (timePunch, index) =>
        index > 0 && moment(timePunch?.punch_in).isBefore(timePunches[index - 1]?.punch_out),
    )
    if (timePunchesOverlapping) {
      return 'Time punches should not overlap'
    }

    const timePunchesInvalid = timePunches.some(timePunch =>
      moment(timePunch?.punch_out).isBefore(timePunch?.punch_in),
    )
    if (timePunchesInvalid) {
      return 'Punch out time should be after punch in time'
    }
  }

  return
}

export const ListingShiftsBreaksDetailsUI = ({
  openModalAction,
  shiftBooking = {},
  attr,
  timeZone,
  disabled,
}) => {
  const onDetailsClick = React.useCallback(
    () => openModalAction(shiftBooking),
    [openModalAction, shiftBooking],
  )
  const breakDetails = React.useMemo(
    () => filter(shiftBooking[attr], ({ type }) => type !== listingShiftBreakTypes.deleted),
    [attr, shiftBooking],
  )
  const duration = React.useMemo(
    () =>
      reduce(
        breakDetails,
        (sum, { clock_in_time, clock_out_time }) =>
          sum + moment(clock_out_time).diff(clock_in_time, 'minutes'),
        0,
      ),
    [breakDetails],
  )
  const noBreaks = isEmpty(breakDetails)
  const buttonDisabled =
    disabled || !(get(shiftBooking, 'clock_in_time') || get(shiftBooking, 'clock_in.time'))
  const error = React.useMemo(() => validateShiftBooking(shiftBooking, attr), [attr, shiftBooking])

  return (
    <Grid fluid className="ListingShiftsBreaksDetails">
      {error && (
        <Row>
          <Col xs={12}>
            <FormError message={error} type="error" />
          </Col>
        </Row>
      )}
      {!noBreaks && (
        <React.Fragment>
          <Row>
            {breakDetails.map(({ clock_in_time, clock_out_time }) => (
              <Col xs={12} key={`${clock_in_time}-${clock_out_time}`}>
                <FormattedTimeRange
                  value={[clock_in_time, clock_out_time]}
                  format={{ timeZone, timeZoneName: 'short' }}
                />
              </Col>
            ))}
          </Row>
          <Row>
            <Col xs={12}>
              <strong>{duration}</strong> minutes break
            </Col>
          </Row>
        </React.Fragment>
      )}
      <Row>
        <Col xs={12}>
          <Button onClick={onDetailsClick} disabled={buttonDisabled}>
            {noBreaks ? 'Add' : 'Edit'} breaks
          </Button>
        </Col>
      </Row>
    </Grid>
  )
}

const mapStateToProps = () => {
  const shiftBookingSelector = createSelector(
    [state => get(state, 'listingShiftBookings.entityMapChanges', {}), (_, { shiftBooking }) => shiftBooking],
    (shiftBookingChanges, shiftBooking) =>
      merge({}, shiftBooking, get(shiftBookingChanges, [get(shiftBooking, 'id'), 'update'], {})),
  )
  return (state, props) => ({
    shiftBooking: shiftBookingSelector(state, props),
  })
}

const mapDispatchToProps = (dispatch, { modalName, shiftBooking, attr, timeZone }) => {
  const updateBookingAction = val => {
    dispatch(updateBooking({ id: shiftBooking.id, attr, val }))
    dispatch(updateBookingChanges({ id: shiftBooking.id, attr, val }))
  }
  return {
    openModalAction: data =>
      dispatch(showModal(modalName, { attr, timeZone, onConfirm: updateBookingAction }, null, data)),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(props => {
  const isClockableBreaksEnabled = useFlexFlagIsOn('pte_16681_shift_booking_clockable_breaks')

  return isClockableBreaksEnabled ? (
    <ListingShiftsBreaksDetailsUI {...props} />
  ) : (
    <ListingShiftsBreakInput {...props} type="time" isDuration step="1" />
  )
})
