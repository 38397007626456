// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import React, { useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { FormControl, InputGroup } from 'react-bootstrap'
import classnames from 'classnames'
import { get, identity } from 'lodash-es'

import { isValidURL } from 'syft-acp-util/url'

import './DataText.css'

const DataText = ({
  value,
  defaultValue,
  header,
  prefix,
  onChange,
  editable,
  onKeyPress,
  id,
  type = 'text',
  disabled = false,
  step = null,
  slug = false,
  parameters,
}) => {
  const [initialValue] = useState(value)
  const auto = useMemo(
    () => !initialValue && defaultValue && defaultValue === value,
    [value, initialValue, defaultValue]
  )

  useEffect(() => {
    if (editable && !initialValue && !value && defaultValue && get(parameters, 'fallbackDefault', false)) {
      onChange(defaultValue)
    }
  }, [value, editable, initialValue, defaultValue, parameters, onChange])

  if (editable) {
    const inputProps = {
      onChange: ev => onChange(ev.target.value),
      value: value || '',
      placeholder: header,
      disabled,
      onKeyPress,
      type: get(parameters, 'type', type),
      step,
      id,
      'aria-label': get(parameters, 'ariaLabel'),
    }

    const className = classnames(
      'boxed-input',
      { 'monospace-text': slug },
      {
        'default-value': auto && get(parameters, 'highlightDefault', false),
      }
    )

    if (prefix) {
      // Return an input field with an InputGroup prefix.
      return (
        <InputGroup className={className}>
          <InputGroup.Addon>{prefix}</InputGroup.Addon>
          <FormControl {...inputProps} />
        </InputGroup>
      )
    }
    // Return a plain input field.
    return <FormControl {...inputProps} className={className} />
  }

  const val =
    get(parameters, 'parseUrl') && isValidURL(value) ? (
      <a href={value} rel="noopener noreferrer" target="_blank">
        {value}
      </a>
    ) : (
      value
    )
  return <div className={classnames('plain-text', 'DataText', { 'monospace-text': slug })}>{val}</div>
}

DataText.propTypes = {
  value: PropTypes.string,
  defaultValue: PropTypes.string,
  header: PropTypes.string,
  editable: PropTypes.bool,
  onChange: PropTypes.func,
  onKeyPress: PropTypes.func,
  disabled: PropTypes.bool,
  type: PropTypes.string,
  step: PropTypes.string,
  slug: PropTypes.bool,
  prefix: PropTypes.string,
  parameters: PropTypes.object,
  id: PropTypes.string,
}

DataText.defaultProps = {
  value: '',
  defaultValue: '',
  header: '',
  editable: false,
  onChange: identity,
  onKeyPress: identity,
  disabled: false,
  type: null,
  step: null,
  slug: null,
  prefix: null,
  parameters: null,
}

export default DataText
