import * as structureTypes from 'syft-acp-core/components/EditableTable'

export const accountStructure = [
  [
    'level',
    {
      header: 'Level',
      editable: true,
      options: [
        { value: 'admin', label: 'Admin' },
        { value: 'standard', label: 'Standard' },
        { value: 'disabled', label: 'Disabled' },
      ],
      type: structureTypes.TYPE_ENUM,
    },
  ],
  [
    'branch_name',
    {
      header: 'Branch',
      editable: true,
    },
  ],
  [
    'name',
    {
      header: 'Name',
      editable: true,
    },
  ],
  [
    'email',
    {
      header: 'Email',
      editable: true,
    },
  ],
  [
    'telephone_number',
    {
      header: 'Phone',
      editable: true,
    },
  ],
]

export const permissionStructure = [
  'permissions',
  {
    type: structureTypes.TYPE_INDUSTRIES_AND_ROLES,
    editable: true,
    rowOptions: {
      colSpan: 3,
    },
    header: null,
  },
]
