// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import React from 'react'

import ConversationsDetail from 'syft-acp-core/entities/ConversationsDetail'

export const WatchlistConversationsView = ({ match: { params } }) => (
  <ConversationsDetail id={Number(params.id)} />
)
