// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import { get, isEmpty, map, memoize } from 'lodash-es'
import { createSelector } from 'reselect'
import { emptySet } from './constants'
import { entitySetName } from './utils'

export {
  ENTITIES_FETCH_BEGIN,
  ENTITIES_FETCH_FAILED,
  ENTITIES_FETCH_SUCCEEDED,
  ENTITY_DELETE_BEGIN,
  ENTITY_DELETE_FAILED,
  ENTITY_DELETE_SUCCEEDED,
  ENTITY_FETCH_BEGIN,
  ENTITY_FETCH_FAILED,
  ENTITY_FETCH_SUCCEEDED,
  ENTITY_MODIFY_BEGIN,
  ENTITY_MODIFY_FAILED,
  ENTITY_MODIFY_SUCCEEDED,
  ENTITY_SAVE_BEGIN,
  ENTITY_SAVE_FAILED,
  ENTITY_SAVE_SUCCEEDED,
  ENTITY_UPDATE_BEGIN,
  ENTITY_UPDATE_FAILED,
  ENTITY_UPDATE_SUCCEEDED,
  ENTITY_CREATE_BEGIN,
  ENTITY_CREATE_FAILED,
  ENTITY_CREATE_SUCCEEDED,
  ENTITY_BULK_DELETE_BEGIN,
  ENTITY_BULK_DELETE_SUCCEEDED,
  ENTITY_BULK_DELETE_FAILED,
} from './constants'

/**
 * Selects the contents of an entitySet. Returns a new object only when the store contents change.
 *
 * For example, use as follows (in a connect()'s first argument):
 * workerRoles: selectEntitySet$(state.workerRoles)({ workerID: ownProps.workerID })
 */
export const selectEntitySet$ = createSelector(
  // Simply pass on the state. We expect the user to pass the correct store.
  state => state,
  // Retrieve the requested entity set, or an empty set.
  store => memoize(args => store.entitySets[entitySetName(args)] || emptySet),
)

/**
 * Selects an entity list associated with an entitySet.
 */
export const selectEntitySetList$ = createSelector(
  // Simply pass on the state. We expect the user to pass the correct store.
  // TODO: this is inefficient.
  state => state,
  // Retrieve the requested entity set, or an empty set.
  store =>
    memoize(args => {
      const entitySet = store.entitySets[entitySetName(args)]
      if (!entitySet) {
        return []
      }
      return map(entitySet.ids, item => entitySet.entityMap[item])
    }),
)

const getEntitySet$ = (state = {}, query) =>
  // Return a default empty set if this is the first time we use it.
  Object.keys(state).length > 0 ? state.entitySets[entitySetName(query)] || emptySet : emptySet

/**
 * Sorted list of entities as returned from the API.
 * If pagination is in effect, returns a list of entities for the active page.
 * Filters out 'undefined' values if an entry is not in entityMap.
 *
 * @param {Object} state Entity state
 * @param {Object|unknown} opts Options, esp. activePage
 * @returns {Array} List of entities for the active page
 */
export const entityList$ = (state, query = {}) =>
  map(getEntitySet$(state, query).ids, id => state.entityMap[id]).filter(e => e)

/**
 * Sorted list of entities as returned from the API.
 * If pagination is in effect, returns a list of entities for all pages.
 * Filters out 'undefined' values if an entry is not in entityMap.
 *
 * @param {Object} state Entity state
 * @param {Object} opts Options, esp. activePage
 * @returns {Array?} List of entities for the active page
 */
export const entityListAllPages$ = (state, query = {}) => {
  const allQueries = Object.keys(state.entitySets)
  const stringifyQuery = entitySetName(query)
  const filteredQueries = allQueries.filter(item => item.includes(stringifyQuery) && !isEmpty(stringifyQuery))
  const entitySets = filteredQueries.map(item => state.entitySets[item])
  const allData = entitySets
    .map(set => set.ids.map(id => state.entityMap[id]))
    .flat()
    .filter(e => e)
  return allData
}
// map(getEntitySet$(state, query).ids, id => state.entityMap[id]).filter(e => e)

/**
 * Returns true if the active page is loading data, false otherwise.
 *
 * @param {Object} state Entity state
 * @param {Object} opts Options, esp. activePage
 * @returns {Boolean} Whether the page is loading
 */
export const isLoadingData$ = (state, query = {}) => get(getEntitySet$(state, query), 'isLoadingData', false)

/**
 * Returns true if the active page has data, false otherwise.
 *
 * @param {Object} state Entity state
 * @param {Object} opts Options, esp. activePage
 * @returns {Boolean} Whether the page is loading
 */
export const hasData$ = (state, query = {}) => !!getEntitySet$(state, query).ids

export const selectSetPagination$ = (setQuery, query = {}) => ({
  activePage: Number(query.page) || setQuery.activePage || 1,
  totalPages: setQuery.totalPages,
  totalWorkers: setQuery.totalWorkers,
  totalBookings: setQuery.totalBookings,
  totalShifts: setQuery.totalShifts,
  totalVacancies: setQuery.totalVacancies,
  total: setQuery.total,
  perPage: setQuery.perPage,
  nextPage: setQuery.nextPage,
  page: setQuery.page,
})

export const pagination$ = (state, query = {}) => {
  const setQuery = getEntitySet$(state, query)
  return selectSetPagination$(setQuery, query)
}
