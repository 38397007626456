import { SUPPORTED_COUNTRY_CODES } from 'syft-acp-core/lib/i18n'
import * as structureTypes from 'syft-acp-core/components/EditableTable'

import { complianceHours } from './helpers'
import { orgTypes } from './orgTypes.js'

export const complianceStructure = [
  ['contact_name', { header: 'Name', editable: true }],
  ['email', { header: 'Email', editable: true }],
  ['telephone_number', { header: 'Tel. nr', editable: true, type: structureTypes.TYPE_PHONE_NUMBER }],
  [
    'compliance_notification',
    {
      header: 'Hours before shift letter sent',
      editable: true,
      type: structureTypes.TYPE_DROPDOWN,
      options: complianceHours,
    },
  ],
  [
    'stop_job_interaction',
    { header: 'Notice period', editable: true, type: structureTypes.TYPE_DROPDOWN, options: complianceHours },
  ],
]

export const agenciesStructure = [
  ['requires_agency', { header: 'Requires agency', type: structureTypes.TYPE_CHECKBOX, editable: true }],
  [
    'exclude_from_admin_shifts_page',
    { header: 'Hide from agency shifts page', type: structureTypes.TYPE_CHECKBOX, editable: true },
  ],
  [
    'agency_work_locations',
    {
      header: 'Select agency',
      editable: true,
      type: structureTypes.TYPE_AGENCIES_DROPDOWN,
    },
  ],
]

export const shiftRequirementsStructure = [
  [
    'no_admin_input',
    {
      header: 'No admin input',
      type: structureTypes.TYPE_CHECKBOX,
      editable: true,
      showForCountries: [SUPPORTED_COUNTRY_CODES.US],
    },
  ],
]

export const getDataStructure = ({
  venueCategories,
}: {
  venueCategories: { id: number; label: string }[]
}) => [
  ['id', { header: 'ID' }],
  ['name', { header: 'Name', editable: true }],
  ['address.line_1', { header: 'Address line 1', editable: true }],
  ['address.line_2', { header: 'Address line 2', editable: true }],
  ['address.city', { header: 'City', editable: true }],
  ['address.post_code', { editable: true, translationKey: 'postcode' }],
  ['client_id', { header: 'Client ID', editable: true }],
  [
    'address.federated_state_code',
    {
      header: 'State',
      editable: true,
      type: structureTypes.TYPE_STATE_DROPDOWN,
      showForCountries: [SUPPORTED_COUNTRY_CODES.US],
    },
  ],
  [
    'invoice_independently',
    { header: 'Invoice independently', type: structureTypes.TYPE_CHECKBOX, editable: true },
  ],
  [
    'type_of_organization',
    {
      header: 'Type of organization',
      editable: true,
      type: structureTypes.TYPE_DROPDOWN,
      options: orgTypes,
      parameters: {
        allowAny: true,
        anyOption: 'No Type',
        ariaLabel: 'Type of organization',
        convertIds: false,
        categorized: true,
      },
    },
  ],
  [
    'access_instructions',
    {
      header: 'Access instructions',
      editable: true,
      type: structureTypes.TYPE_TEXTAREA,
    },
  ],
  ['maximum_arrival_time', { header: 'Max. arrival minutes', editable: true }],
  [
    'parking_available',
    { header: 'Car Parking available?', type: structureTypes.TYPE_CHECKBOX, editable: true },
  ],
  [
    'accessible_by_public_transport',
    { header: 'Easily accessible by public transport?', type: structureTypes.TYPE_CHECKBOX, editable: true },
  ],
  [
    'public_transport_available_for_night_shifts',
    {
      header: 'Public transport available for night shifts?',
      type: structureTypes.TYPE_CHECKBOX,
      editable: true,
    },
  ],
  ['po_enforced', { header: 'PO enforced', type: structureTypes.TYPE_CHECKBOX, editable: true }],
  [
    'requires_approver_for_booking',
    { header: 'Requires approval to post', type: structureTypes.TYPE_CHECKBOX, editable: true },
  ],
  [
    'time_off_requests',
    { header: 'Time Off requests enabled', type: structureTypes.TYPE_CHECKBOX, editable: true },
  ],
  [
    'venue_category_id',
    {
      header: 'Select venue category',
      editable: true,
      type: structureTypes.TYPE_DROPDOWN,
      options: venueCategories,
      parameters: {
        allowAny: true,
        anyOption: 'No Venue Category',
      },
    },
  ],
  ['', { type: structureTypes.TYPE_SEPARATOR }],
  ['work_location_contact.name', { header: 'Contact name', editable: true }],
  [
    'work_location_contact.mobile',
    { header: 'Contact mobile', editable: true, type: structureTypes.TYPE_PHONE_NUMBER },
  ],
  ['work_location_contact.email', { header: 'Contact email', editable: true }],
  ['onsite_managers', { header: 'Onsite manager email(s)', editable: true, type: structureTypes.TYPE_ARRAY }],
  [
    'work_location_note.body',
    {
      header: 'Venue notes',
      editable: true,
      type: structureTypes.TYPE_TEXTAREA,
    },
  ],
  [
    'invoicing_note.body',
    {
      header: 'Invoicing notes',
      editable: true,
      type: structureTypes.TYPE_TEXTAREA,
    },
  ],
]
