import React, { useCallback, useMemo } from 'react'
import { connect } from 'react-redux'
import { Col, ControlLabel, FormGroup, HelpBlock, Row } from 'react-bootstrap'
import { FORM_ERROR } from 'final-form'
import { Field } from 'react-final-form'
import { identity, isEmpty, pickBy, range } from 'lodash-es'
import { bindActionCreators } from 'redux'

import { apiURL } from 'syft-acp-core/api/endpoints'
import { getModal$ } from 'syft-acp-core/store/modals/selectors'
import { notify } from 'syft-acp-core/actions/notifications'
import { reqDefaults } from 'syft-acp-core/api/call'
import { hideModal } from 'syft-acp-core/store/modals/actions'
import { dateAddTz } from 'syft-acp-util/time'
import DataDate from 'syft-acp-core/components/EditableTable/DataDate'
import DataEmployer from 'syft-acp-core/components/EditableTable/DataEmployer'

import ModalForm from '../ModalForm'

import WorkingWeekInvoicingOptions, { defaultFieldNames } from '../WorkingWeekInvoicingOptions'

import './InvoicingGdriveCSVModal.css'
import { FormValues, Props } from './InvoicingGdriveCSVModal.types'
import FormNumberInput from '../../Form/FormNumberInput'
import { formatCSVParams } from '../../../entities/InvoicingList'
import { getErrorFromMeta } from '../../Form/utils'
import { withTrackingTriggerHoc } from 'syft-acp-util/withTrackingTriggerHoc'
import { trackingEvents } from '../Modal.tracking'

export const connectModal = connect(
  state => ({
    item: getModal$(state.modals)('invoicingGdriveCsvModal'),
  }),
  dispatch => bindActionCreators({ hideModal, notify }, dispatch),
  (stateProps, dispatchProps, rest) => ({
    ...rest,
    ...stateProps,
    ...dispatchProps,
    hideModal: () => dispatchProps.hideModal(stateProps.item.modalName),
  })
)

const uploadCSV = (body: Record<string, any>) => {
  const request = new Request(apiURL('/admin/employers/invoicing_csv_upload'), {
    ...reqDefaults('POST', 'application/json'),
    body: JSON.stringify(body),
  })
  return fetch(request)
}

const initialValues = {
  masterInvoicingData: ['true'],
  workweekInvoicingData: [],
  workingWeekOptions: range(0, 7).map(String),
}

export const InvoicingGdriveCSVModal = (props: Props) => {
  const validate = useCallback((values: FormValues) => {
    const [uploadMasterCSVOption] = values.masterInvoicingData
    const [uploadWorkweekCSVOption] = values.workweekInvoicingData
    const workingWeekOptions = values.workingWeekOptions
    // Remove falsy values. If everything is correct, an empty object is returned here.
    return pickBy(
      {
        invoiceNumber: !values.invoiceNumber ? 'Invoice number can not be blank' : null,
        ...(values.dateStart || values.dateEnd
          ? { dateStart: !!values.dateStart ? null : 'Enter a date', dateEnd: !!values.dateEnd ? null : 'Enter a date' }
          : {}),
        [FORM_ERROR]:
          !uploadMasterCSVOption && (!uploadWorkweekCSVOption || isEmpty(workingWeekOptions))
            ? 'Select at least one report to upload'
            : null,
      },
      identity
    )
  }, [])

  const onSubmit = useCallback(
    async (values: FormValues) => {
      const { employerID, invoiceNumber, dateStart, dateEnd, workingWeekOptions } = values
      const { start_date } = formatCSVParams(props.item.options)
      const [uploadMasterCSVOption] = values.masterInvoicingData
      const [uploadWorkweekCSVOption] = values.workweekInvoicingData
      const timeValues = dateStart ? { start_date: dateAddTz(dateStart), end_date: dateAddTz(dateEnd) } : { start_date }
      const postBody = {
        invoice_number: invoiceNumber,
        employer_id: employerID,
        ...timeValues,
      }

      try {
        props.triggerEvent(trackingEvents.GDRIVE_INVOICE_MODAL.UPLOAD, {
          invoice_number: invoiceNumber,
          employer_id: employerID,
          ...timeValues,
          working_week_options: workingWeekOptions,
          upload_master_csv: !!uploadMasterCSVOption,
          upload_workweek_csv: !!uploadWorkweekCSVOption,
        })

        const uploadMasterCSV = !!uploadMasterCSVOption ? uploadCSV(postBody) : null
        const uploadWorkweekCSV = !!uploadWorkweekCSVOption
          ? uploadCSV({ ...postBody, invoicing_week_ends_on: workingWeekOptions })
          : null
        const result = await Promise.all([uploadMasterCSV, uploadWorkweekCSV].filter(Boolean))

        if (result.every(response => response?.ok)) {
          props.hideModal()
          props.notify('success', {
            title: 'Download Invoicing data CSV',
            message:
              'The invoicing CSV is being uploaded to Google Drive. The finance email address will receive a message when uploading is done.',
          })
          return undefined
        }
        // Successful POST, but something went wrong after all.
        return { [FORM_ERROR]: 'Could not submit the form. Please recheck your input.' }
      } catch (err) {
        // Could not successfully POST.
        return { [FORM_ERROR]: 'Could not submit the form. Server may be busy.' }
      }
    },
    [props]
  )

  const formProps = useMemo(() => ({ validate, initialValues }), [validate])

  return props.item.isShown ? (
    <ModalForm<FormValues>
      onClose={props.hideModal}
      isShown={props.item.isShown}
      onConfirm={onSubmit}
      form={formProps}
      confirmationText="Send to Google Drive"
      header="Upload invoicing CSV to Google Drive"
    >
      {({ form }) => {
        const formState = form.getState()
        return (
          <FormGroup disabled={formState.submitting} className="invoicing-gdrive-csv-form">
            <Row>
              <Col md={12}>
                <Field name="employerID">
                  {({ input }) => (
                    <div>
                      <ControlLabel>Employer</ControlLabel>
                      <DataEmployer {...input} disabled={formState.submitting} placeholder="Employer" editable />
                      <HelpBlock>
                        Optionally specify an employer; if omitted, all employers' CSVs will be uploaded.
                      </HelpBlock>
                    </div>
                  )}
                </Field>
              </Col>
            </Row>

            <Row>
              <Col md={12}>
                <WorkingWeekInvoicingOptions fieldNames={defaultFieldNames} />
              </Col>
            </Row>

            <Row>
              <Col md={12}>
                <FormGroup>
                  <ControlLabel>Invoice Number</ControlLabel>
                  <FormNumberInput min={0} name="invoiceNumber" disabled={formState.submitting} />
                </FormGroup>
              </Col>
            </Row>

            <Row>
              <Col md={6}>
                {/* TODO need to create Form Date Picker and use here */}
                <Field name="dateStart">
                  {({ input, meta }) => (
                    <FormGroup validationState={!!getErrorFromMeta(meta) ? 'error' : null}>
                      <ControlLabel>Start date</ControlLabel>
                      <DataDate {...input} disabled={formState.submitting} testId="dateStart" editable />
                      {!!getErrorFromMeta(meta) && <HelpBlock>{meta.error}</HelpBlock>}
                    </FormGroup>
                  )}
                </Field>
              </Col>

              <Col md={6}>
                <Field name="dateEnd">
                  {({ input, meta }) => (
                    <FormGroup validationState={!!getErrorFromMeta(meta) ? 'error' : null}>
                      <ControlLabel>End date</ControlLabel>
                      <DataDate {...input} testId="dateEnd" disabled={formState.submitting} editable />
                      {!!getErrorFromMeta(meta) && <HelpBlock>{meta.error}</HelpBlock>}
                    </FormGroup>
                  )}
                </Field>
              </Col>
            </Row>
          </FormGroup>
        )
      }}
    </ModalForm>
  ) : null
}

export default connectModal(withTrackingTriggerHoc(InvoicingGdriveCSVModal))
