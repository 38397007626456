// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import * as types from '../actions/action-types'
import entityReducer from './generators/entityReducer'

export const beginSaveImportState = (state: any) => ({
  ...state,
  message: '',
  isLoadingData: true,
})

export const failedSaveImportState = (state: any, action: any) => ({
  ...state,
  isLoadingData: false,
  lastMessage: `Error: ${action.payload.body?.error_description || action.payload.body?.error}`,
  message: '',
})

export const successSaveImportState = (state: any) => ({
  ...state,
  isLoadingData: false,
  hasData: true,
  message: 'Upload complete',
})

export default entityReducer('workerCsvImport', {
  [types.UPLOAD_TRANSFERRED_WORKER_CSV_BEGIN]: beginSaveImportState,
  [types.UPLOAD_TRANSFERRED_WORKER_CSV_SUCCEEDED]: successSaveImportState,
  [types.UPLOAD_TRANSFERRED_WORKER_CSV_FAILED]: failedSaveImportState,
})
