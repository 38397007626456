import { get, identity } from 'lodash-es'
import * as React from 'react'
import { useForm } from 'react-final-form'
import { connect } from 'react-redux'
import { hideModal } from 'syft-acp-core/store/modals/actions'
import { getModal$ } from 'syft-acp-core/store/modals/selectors'
import { ShortBookingInfo } from './AddNoShowReasonModal'
import { ListingShiftBreakDetailsForm } from 'syft-acp-core/components/ListingShiftBreakDetails'
import Modal from './Modal'

const FormModalWrapper = ({ shiftBooking, timeZone, isShown, children, onClose }) => {
  const form = useForm()
  const formState = form.getState()
  const canSubmit = formState.dirty && formState.valid && !formState.submitting
  const onConfirm = React.useCallback(() => form.submit(), [form])

  return (
    <Modal
      header="Edit breaks"
      confirmationText="Save"
      onClose={onClose}
      onConfirm={onConfirm}
      canSubmit={canSubmit}
      errorText={formState.error}
      isShown={isShown}
    >
      <div className="shift-breaks-modal">
        {shiftBooking && <ShortBookingInfo shiftBooking={shiftBooking} timeZone={timeZone} />}

        {children}
      </div>
    </Modal>
  )
}

const ListingShiftBreakDetailsModal = ({ closeModal, modal }) => {
  const { attr, timeZone, onConfirm = identity } = get(modal, 'options', {})
  const data = get(modal, 'data', {})

  const onSubmit = React.useCallback(
    value => {
      onConfirm(value)
      closeModal()
    },
    [closeModal, onConfirm],
  )

  return (
    modal.isShown && (
      <ListingShiftBreakDetailsForm
        key={`${data.shiftID}-${get(data, 'worker.id')}`}
        attr={attr}
        shiftBooking={data}
        timeZone={timeZone}
        onSubmit={onSubmit}
        wrapper={<FormModalWrapper isShown shiftBooking={data} timeZone={timeZone} onClose={closeModal} />}
      />
    )
  )
}

export default connect(
  (state, { modalName, hasPayBillSplit, subject }) => ({
    modal: getModal$(state.modals)(modalName),
    hasPayBillSplit,
    subject,
  }),
  (dispatch, { modalName }) => ({
    closeModal: () => dispatch(hideModal(modalName)),
  }),
)(ListingShiftBreakDetailsModal)
