import { apiRequest, handleCall } from 'syft-acp-core/api/call'
import { WorkerReferralBonusEntity } from './types'
import type { Action } from '../types'

export const fetchWorkerReferralBonuses = ({ options }: Record<string, any>) =>
  new Promise((resolve, reject) => {
    const req = apiRequest({
      path: `/admin/workers/${options.workerID}/referral_bonuses`,
      reqArgs: options,
      method: 'GET',
    })
    handleCall(req, resolve, reject, true)
  })

type SavePayload = {
  workerId: number
  data: WorkerReferralBonusEntity
}

export const saveWorkerReferralBonus = ({ payload }: Action<SavePayload>) =>
  new Promise((resolve, reject) => {
    const req = apiRequest({
      path: `/admin/workers/${payload?.workerId}/referral_bonuses`,
      reqArgs: {
        ...payload?.data,
        source_id: Number(payload?.data.source_id),
      },
      method: 'POST',
      allowNulls: true,
    })
    handleCall(req, resolve, reject, true)
  })

type DeletePayload = {
  workerId: number
  ids: number[]
}

export const deleteWorkerReferralBonuses = ({ payload }: Action<DeletePayload>) =>
  new Promise((resolve, reject) => {
    const req = apiRequest({
      path: `/admin/workers/${payload?.workerId}/referral_bonuses`,
      reqArgs: {
        referral_bonus_ids: payload?.ids,
      },
      method: 'DELETE',
      allowNulls: true,
    })
    handleCall(req, resolve, reject, true, { workerId: payload?.workerId })
  })
