import { get } from 'lodash-es'

export type Trigger = {
  on: string
  type: string
  ifFalsy?: boolean
  ifEqual?: boolean
  compare?: boolean
}

// Check for triggers that enable/disable the field.
//
// NOTE: On 2023-08-15 via PTE-16554 we introduced 'enable_and' triggers. Previously, 'enable' triggers
// function as an OR operation: if one of the triggers is truthy, the outcome is true. With 'enable_and',
// this becomes an AND operation: all triggers must be truthy for the outcome to be true.
//
// If at least one 'enable_and' trigger is present, we treat all other 'enable' triggers of the component as
// 'enable_and' as well.
export const processEnabledTriggers = (triggers: Trigger[], data: Record<string, any>): boolean => {
  const enabledTriggers = triggers ? triggers.filter(n => ['enable', 'enable_and'].includes(n.type)) : []
  if (enabledTriggers.length === 0) {
    return true
  }

  const useEnabledAnd = enabledTriggers.map(n => n.type).includes('enable_and')
  const activeTriggersCount = enabledTriggers
    .map(t => (t.ifFalsy && !!get(data, t.on, null)) || (t.ifEqual && get(data, t.on, null) === t.compare))
    .filter(n => n === true).length

  return useEnabledAnd ? activeTriggersCount === enabledTriggers.length : activeTriggersCount > 0
}
