import { AnyAction, createReducer } from '@reduxjs/toolkit'
import { fromPairs, isArray, isFunction, pick, toPairs } from 'lodash-es'

import { emptySet, EntitySet } from './constants'
import { modifyState } from './modify-state'

type ArrayType = Record<string | symbol, any>
export type BaseEntity = Record<string, any>
export type GenericState<T extends BaseEntity = BaseEntity> = {
  /** Whether any data saving currently pending. */
  isSavingData: boolean
  isLoadingData: boolean
  /** Do not update local data after succeded action. */
  skipLocalDataUpdating: boolean
  /** Entity objects mapped by ID. TODO: we need to see if we can remove this. */
  entityDetail: Record<number, T>
  /** All fetched entities mapped by their ID. */
  entityMap: Record<number, T>
  /** Unix timestamps of when these IDs were last fetched or updated. */
  entityUpdateTimes: Record<number, number>
  message?: string
  /** These are set if an error occurred in the last API call. Status is the HTTP response code. */
  lastMessage?: string
  lastStatus?: string | null
  lastBody?: string | null
  /** Result sets: these are sets of IDs returned by the API for a particular set of filters. */
  entitySets: Record<string | number, EntitySet<T>>
}

export type UserOptions<T extends BaseEntity, AdditionalState = {}> = {
  groupBy?: string[]
  initialState?: Partial<GenericState<T> & AdditionalState>
  idKey?: keyof T
  keyByPayload?: string
  optionsKey?: string
  itemLimit?: number[]
  localEntityMap?: boolean
  payloadProcess?: (payload: any, action: AnyAction) => any
}

const initialState: GenericState = {
  isSavingData: false,
  isLoadingData: false,
  skipLocalDataUpdating: false,
  entityDetail: {},
  entityMap: {},
  entityUpdateTimes: {},
  lastMessage: '',
  lastStatus: null,
  lastBody: null,
  entitySets: {
    '': emptySet,
  },
}

/** These are the default options we use for every entity reducer. */
const defaultOptions = {}

/**
 * Returns a shortened version of the entity update times object, by latest items.
 */
const trimUpdateTimes = <T extends Record<string, any>>(
  entityUpdateTimes: GenericState<T>['entityUpdateTimes'],
  limit: number,
) => {
  // Sort the update ID/timestamp pairs, keeping the latest updated at the top.
  const pairs = toPairs(entityUpdateTimes).sort((a, b) => (a[1] < b[1] ? 1 : -1))
  return fromPairs(pairs.slice(0, limit))
}

/**
 * Used to keep the entity reducer in a reasonable size.
 * Caching page after page of data can cause the localStorage to fill up quickly.
 * That's why before we add new search results, we'll limit the existing ones to a reasonable number.
 *
 * 'itemLimit' is an array, like e.g. [250, 125].
 * This example means: once we reach 1000 or more items in this part of the store,
 * limit its size down to the 250 latest items.
 */
const limitSize = <T extends BaseEntity>(state: GenericState<T>, itemLimit: number[]) => {
  // Don't do anything if we're still within cache limit.
  const entityMapLength = Object.keys(state.entityMap).length
  if (entityMapLength <= itemLimit[0]) {
    // Not cleaning up yet.
    return state
  }

  // Throw away superfluous items, keeping as many latest items as possible.
  const latestItems = trimUpdateTimes(state.entityUpdateTimes, itemLimit[1])
  const remainingIDs = Object.keys(latestItems)
  const trimmedEntityMap = pick(state.entityMap, remainingIDs)

  return {
    ...state,
    entityMap: trimmedEntityMap,
  }
}

/**
 * Returns true if a symbol is one of this file's predefined symbols.
 */
const isEntitySymbol = (symbol: string | symbol) => {
  const str = symbol.toString()
  return str.startsWith('Symbol(ENTITIES') || str.startsWith('Symbol(ENTITY')
}

/**
 * Wrap all symbol types in a single length array if they aren't arrays already.
 * TypeScript does not allow to use Symbol as a index property, so converting to unknown -> string to be able to lookup value.
 */
const wrappedSymbols = (types: ArrayType) =>
  (Object.getOwnPropertySymbols(types) as unknown[] as string[]).reduce((acc, propertySymbol) => {
    // Ensure that the caller didn't accidentally pass an invalid value to listen for.
    if (!types[propertySymbol]) {
      throw TypeError('entityReducer() called with invalid type(s)')
    }
    // Don't touch items that aren't our own symbols.
    if (!isEntitySymbol(propertySymbol)) {
      return { ...acc, [propertySymbol]: types[propertySymbol] }
    } else if (!isArray(types[propertySymbol])) {
      return { ...acc, [propertySymbol]: [types[propertySymbol]] }
    } else {
      return { ...acc, [propertySymbol]: types[propertySymbol] }
    }
  }, {})

/**
 * Creates a new reducer for a specific type of entity, e.g. workers, employers.
 * Reducers of this type support the standard three action types (BEGIN, SUCCEEDED, FAILED)
 * for loading, saving, modifying and deleting data. Not all of these need to be passed;
 * it's fine to implement a reducer with only FETCH actions, for example.
 *
 * @param _name
 * @param types
 * @param userOptions
 * @returns ReducerWithInitialState
 */
export const entityReducer = <T extends BaseEntity, AdditionalState = {}>(
  _name: string,
  types: ArrayType,
  userOptions: UserOptions<T, AdditionalState> = {},
) => {
  // Merge in our default reducer options.
  const options = { ...defaultOptions, ...userOptions }

  /** ID key to group result sets by. Must be unique. */
  const idKey = options.idKey || 'id'

  // If we have extra initial state, merge it in with the default values.
  const reducerInitialState = {
    ...initialState,
    ...(options.initialState ? options.initialState : {}),
  } as GenericState<T> & AdditionalState

  const arrayTypes: ArrayType = { ...types, ...wrappedSymbols(types) }

  return createReducer(reducerInitialState, builder =>
    builder.addDefaultCase((state, action) => {
      const value = arrayTypes[action.type]

      // Limit the size of the existing store items.
      const newState = options.itemLimit ? limitSize(state, options.itemLimit) : state

      if (isFunction(value)) {
        return value(newState, action, arrayTypes, idKey, reducerInitialState)
      } else if (isArray(value)) {
        return value.reduce((_acc, type) =>
          modifyState(newState, { ...action, type }, arrayTypes, idKey, options, reducerInitialState),
        )
      } else {
        return modifyState(newState, action, arrayTypes, idKey, options, reducerInitialState)
      }
    }),
  )
}

export default entityReducer
