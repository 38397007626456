import { AnyAction } from 'redux'
import { history } from 'syft-acp-core/history'
import { takeLatest, put, call, all } from 'redux-saga/effects'
import { takeLatestApiCall } from 'syft-acp-core/sagas/helpers'
import { notificationCall } from 'syft-acp-core/sagas/calls'
import { showModal, hideModal } from 'syft-acp-core/store/modals/actions'
import { clearItems } from 'syft-acp-core/store/checkboxes/actions'
import { selectionKey } from 'syft-acp-core/entities/EmployerDetail/EmployerRateCardList/EmployerRateCardList.constants'
import { ModalNames } from './types'
import * as api from './api'
import * as types from './action-types'

function* rateCardReplacedModal(action: AnyAction) {
  const { request = {}, payload = {} } = action
  const requestRateCardId = request.data.id
  const responseRateCardId = payload.id
  const rateCardIdReplaced = requestRateCardId && requestRateCardId !== responseRateCardId

  if (rateCardIdReplaced) {
    yield put(
      showModal(ModalNames.RateCardsReplace, {}, null, {
        newRateCardId: responseRateCardId,
      }),
    )
  }
}

function* redirectNewRateCard(action: AnyAction) {
  const isNewRateCard = !action.request.data.id
  const rateCardId = action.payload.id
  const employerId = action.request.data.employer_id

  if (isNewRateCard) {
    yield call(history.push, `/entity/employers/view/${employerId}/ratecards-v2/view/${rateCardId}`)
  }
}

function* bulkDisableEnableSuccess() {
  yield all([put(hideModal(ModalNames.RateCardsConfirm)), put(clearItems(selectionKey))])
}

export default function* saga() {
  yield takeLatestApiCall(types.NEW_EMPLOYER_RATE_CARDS_FETCH_BEGIN, api.listEmployerRateCards)
  yield takeLatestApiCall(types.NEW_EMPLOYER_RATE_CARD_FETCH_BEGIN, api.fetchEmployerRateCard)
  yield takeLatestApiCall(types.NEW_EMPLOYER_RATE_CARD_SAVE_BEGIN, api.saveEmployerRateCard)
  yield takeLatestApiCall(types.NEW_EMPLOYER_RATE_CARD_DISABLE_BEGIN, api.disableEmployerRateCard)
  yield takeLatestApiCall(types.NEW_EMPLOYER_RATE_CARD_ENABLE_BEGIN, api.enableEmployerRateCard)
  yield takeLatestApiCall(types.NEW_EMPLOYER_RATE_CARD_BULK_DISABLE_BEGIN, api.bulkDisableEmployerRateCard)
  yield takeLatestApiCall(types.NEW_EMPLOYER_RATE_CARD_BULK_ENABLE_BEGIN, api.bulkEnableEmployerRateCard)
  yield takeLatest(
    [
      types.NEW_EMPLOYER_RATE_CARD_SAVE_FAILED,
      types.NEW_EMPLOYER_RATE_CARD_SAVE_SUCCEEDED,
      types.NEW_EMPLOYER_RATE_CARD_DISABLE_SUCCEEDED,
      types.NEW_EMPLOYER_RATE_CARD_DISABLE_FAILED,
      types.NEW_EMPLOYER_RATE_CARD_ENABLE_SUCCEEDED,
      types.NEW_EMPLOYER_RATE_CARD_ENABLE_FAILED,
      types.NEW_EMPLOYER_RATE_CARD_BULK_DISABLE_SUCCEEDED,
      types.NEW_EMPLOYER_RATE_CARD_BULK_DISABLE_FAILED,
      types.NEW_EMPLOYER_RATE_CARD_BULK_ENABLE_SUCCEEDED,
      types.NEW_EMPLOYER_RATE_CARD_BULK_ENABLE_FAILED,
    ],
    notificationCall,
  )
  yield takeLatest(types.NEW_EMPLOYER_RATE_CARD_SAVE_SUCCEEDED, redirectNewRateCard)
  yield takeLatest(types.NEW_EMPLOYER_RATE_CARD_SAVE_SUCCEEDED, rateCardReplacedModal)
  yield takeLatest(
    [types.NEW_EMPLOYER_RATE_CARD_BULK_DISABLE_SUCCEEDED, types.NEW_EMPLOYER_RATE_CARD_BULK_ENABLE_SUCCEEDED],
    bulkDisableEnableSuccess,
  )
}
