import React from 'react'
import octicons from 'octicons'
import { get } from 'lodash-es'

import EntityListComponent from 'syft-acp-core/components/EntityList/EntityListComponent'
import entityList from 'syft-acp-util/entityList'
import {
  FilterGroup,
  FilterText,
  FilterEnum,
  FilterDate,
  FilterDropdown,
  FilterSelectCities,
  FilterSelectIndustries,
  FilterAutocompleteManagers,
  FilterRating,
} from 'syft-acp-util/components/FilterForm'
import { PRIORITIES, getPriorityLabel } from 'syft-acp-util/components/FilterForm/FilterTiers'
import * as tableType from 'syft-acp-core/components/EntityList/'
import * as paymentsActions from 'syft-acp-core/actions/payments'

const tierOptions = [[{ value: '', label: 'All' }], PRIORITIES]
// Possible states for payment status.
const paymentStatus = {
  successful: {
    name: 'Successful',
    style: 'success',
  },
  unsuccessful: {
    name: 'Unsuccessful',
    style: 'danger',
  },
}

// Options for the filter dropdown menu.
const statusOptions = [
  [{ value: '', label: 'Any' }],
  [
    { value: 'successful', label: 'Successful' },
    { value: 'unsuccessful', label: 'Unsuccessful' },
  ],
]

// All possible action types.
const actionType = {
  pre_auth: {
    name: 'Pre auth',
    color: '#4248ba',
  },
  cancel_auth: {
    name: 'Cancel auth',
    color: '#4266ba',
  },
  adjust_auth: {
    name: 'Adjust auth',
    color: '#4283ba',
  },
  post_auth: {
    name: 'Post auth',
    color: '#42a2ba',
  },
  settlement: {
    name: 'Settlement',
    color: '#e77a15',
  },
  registration_auth: {
    name: 'Registration auth',
    color: '#5f5a86',
  },
  registration_verify: {
    name: 'Registration verify',
    color: '#5A6986',
  },
}

// Filter dropdown for the actions.
const actionOptions = Object.keys(actionType).map(type => ({ id: type, label: actionType[type].name }))

const tableFormat = [
  { type: tableType.TXT, val: 'payment_id', header: 'ID', expl: 'Payment ID', numeric: true },
  { type: tableType.TXT, val: 'employer_id', header: 'E.ID', expl: 'Employer ID', numeric: true },
  {
    type: tableType.IMAGE,
    headerIcon: octicons.person,
    headerIconAlt: 'Picture',
    val: 'employer_id',
    connectedTo: 'employers',
    classes: ['minimal', 'image'],
  },
  { type: tableType.TXT, val: 'employer_name', header: 'Employer' },
  { type: tableType.MANAGER, val: 'employer_sales_manager_id', header: 'Sales manager' },
  { type: tableType.MANAGER, val: 'employer_account_manager_id', header: 'Account manager' },
  { type: tableType.CITY, val: 'employer_city_id', header: 'City' },
  { type: tableType.INDUSTRIES, val: 'employer_industry_ids', header: 'Industries' },
  { type: tableType.RATING, val: 'employer_rating.average', header: 'Avg. rating' },
  {
    type: tableType.TXT,
    val: e => getPriorityLabel(get(e, 'employer_tier')),
    header: 'Priority',
  },
  { type: tableType.MONO, val: 'gateway_id', header: 'Gateway ID', copyable: true, noWrap: true },
  { type: tableType.MONO, val: 'reference', header: 'Reference', copyable: true, noWrap: true },
  { type: tableType.MONEY, val: 'amount', header: 'Amount' },
  { type: tableType.TAGS, val: 'action_type', header: 'Action', typesMap: actionType },
  { type: tableType.DATE, val: 'action_date', header: 'Date', classes: ['no-wrap'] },
  { type: tableType.DATE, val: 'billing_date', header: 'Billing date', classes: ['no-wrap'] },
  { type: tableType.TAGS, val: 'status', header: 'Status', typesMap: paymentStatus },
]

const filters = (
  <div>
    <FilterGroup title="Company name">
      <FilterText name="employer_name" placeholder="Query" />
    </FilterGroup>
    <FilterGroup title="Action date">
      <FilterDate name="action_date" />
    </FilterGroup>
    <FilterGroup title="Billing date">
      <FilterDate name="billing_date" />
    </FilterGroup>
    <FilterGroup title="City">
      <FilterSelectCities name="employer_city_id" queryAttribute="employer_city_id" allowAny />
    </FilterGroup>
    <FilterGroup title="Industry">
      <FilterSelectIndustries name="industry_id" queryKey="industry_id" options={{ idKey: 'id' }} allowAny />
    </FilterGroup>
    <FilterGroup title="Priority">
      <FilterEnum name="employer_tier" options={tierOptions} />
    </FilterGroup>
    <FilterGroup title="Average rating">
      <FilterRating nameFrom="rating_gte" nameTo="rating_lte" />
    </FilterGroup>
    <FilterGroup title="Account manager">
      <FilterAutocompleteManagers
        name="employer_account_manager_id"
        queryKey="employer_account_manager_id"
        parameters={{ managerRoles: ['account_manager'] }}
        allowAny
      />
    </FilterGroup>
    <FilterGroup title="Sales manager">
      <FilterAutocompleteManagers
        name="employer_sales_manager_id"
        queryKey="employer_sales_manager_id"
        parameters={{ managerRoles: ['sales_manager'] }}
        allowAny
      />
    </FilterGroup>
    <FilterGroup title="Action">
      <FilterDropdown name="action_type" options={actionOptions} allowAny />
    </FilterGroup>
    <FilterGroup title="Status">
      <FilterEnum name="status" options={statusOptions} />
    </FilterGroup>
  </div>
)

const actionFormat = []

const PaymentsList = entityList(
  EntityListComponent,
  tableFormat,
  filters,
  actionFormat,
  'payments',
  {
    fetchEntities: paymentsActions.fetchPayments,
  },
  {
    showResultCount: true,
    fullWidth: true,
  }
)

export default PaymentsList
