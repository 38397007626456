// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

import './EntityListInputWrapper.css'

export default class EntityListInputWrapper extends PureComponent {
  static propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
  }

  static defaultProps = {
    className: '',
    children: '',
  }

  render() {
    return <div className={`entity-list-input-wrapper ${this.props.className}`}>{this.props.children}</div>
  }
}
