export const GLOBAL_SHIFT_BOOKINGS_BEGIN = 'syftacp/GLOBAL_SHIFT_BOOKINGS_BEGIN' as const
export const GLOBAL_SHIFT_BOOKINGS_FAILED = 'syftacp/GLOBAL_SHIFT_BOOKINGS_FAILED' as const
export const GLOBAL_SHIFT_BOOKINGS_SUCCEEDED = 'syftacp/GLOBAL_SHIFT_BOOKINGS_SUCCEEDED' as const

export const SHIFT_BOOKINGS_FETCH_BEGIN = 'syftacp/SHIFT_BOOKINGS_FETCH_BEGIN' as const
export const SHIFT_BOOKINGS_FETCH_FAILED = 'syftacp/SHIFT_BOOKINGS_FETCH_FAILED' as const
export const SHIFT_BOOKINGS_FETCH_SUCCEEDED = 'syftacp/SHIFT_BOOKINGS_FETCH_SUCCEEDED' as const

export const SHIFT_BOOKINGS_UPDATE = 'syftacp/SHIFT_BOOKINGS_UPDATE' as const
export const SHIFT_BOOKINGS_CLEAR = 'syftacp/SHIFT_BOOKINGS_CLEAR' as const

export const HIGHLIGHT_SHIFT_BOOKING_BEGIN = 'syftacp/HIGHLIGHT_SHIFT_BOOKING_BEGIN' as const
export const HIGHLIGHT_SHIFT_BOOKING_FAILED = 'syftacp/HIGHLIGHT_SHIFT_BOOKING_FAILED' as const
export const HIGHLIGHT_SHIFT_BOOKING_SUCCEEDED = 'syftacp/HIGHLIGHT_SHIFT_BOOKING_SUCCEEDED' as const

export const CONFIRM_SHIFT_BOOKING_BEGIN = 'syftacp/CONFIRM_SHIFT_BOOKING_BEGIN' as const
export const CONFIRM_SHIFT_BOOKING_FAILED = 'syftacp/CONFIRM_SHIFT_BOOKING_FAILED' as const
export const CONFIRM_SHIFT_BOOKING_SUCCEEDED = 'syftacp/CONFIRM_SHIFT_BOOKING_SUCCEEDED' as const

export const CONFIRM_BULK_SHIFTS_BOOKING_BEGIN = 'syftacp/CONFIRM_BULK_SHIFTS_BOOKING_BEGIN' as const
export const CONFIRM_BULK_SHIFTS_BOOKING_FAILED = 'syftacp/CONFIRM_BULK_SHIFTS_BOOKING_FAILED' as const
export const CONFIRM_BULK_SHIFTS_BOOKING_SUCCEEDED = 'syftacp/CONFIRM_BULK_SHIFTS_BOOKING_SUCCEEDED' as const
