import entityReducer from './generators/entityReducer'
import {
  AGENCY_PLATFORM_DELETE_BEGIN,
  AGENCY_PLATFORM_DELETE_FAILED,
  AGENCY_PLATFORM_DELETE_SUCCEEDED,
} from '../actions/action-types'
import * as entityTypes from './generators/entities'

export default entityReducer('agency-platform', {
  [entityTypes.ENTITY_DELETE_BEGIN]: AGENCY_PLATFORM_DELETE_BEGIN,
  [entityTypes.ENTITY_DELETE_FAILED]: AGENCY_PLATFORM_DELETE_FAILED,
  [entityTypes.ENTITY_DELETE_SUCCEEDED]: AGENCY_PLATFORM_DELETE_SUCCEEDED,
})
