// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import React from 'react'
import PropTypes from 'prop-types'
import { TrackingProvider } from '@indeed/flex-tracking-context'

import PageView from 'syft-acp-core/components/PageView'
import SalesForceList from 'syft-acp-core/entities2/EmployerSalesForceList'
import SubNavigation from 'syft-acp-core/components/Navigation/SubNavigation'

const EmployerSalesForceList = ({ match: { params } }) => (
  <TrackingProvider
    value={{
      context: {
        employer_id: Number(params.id),
      },
    }}
  >
    <div className="page entity-list">
      <SubNavigation subPage="employers" data={{ employerID: Number(params.id) }} />
      <PageView entityPage>
        <SalesForceList employerID={Number(params.id)} />
      </PageView>
    </div>
  </TrackingProvider>
)

EmployerSalesForceList.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      // Note: ID gets passed as a string directly from the URL.
      id: PropTypes.string.isRequired,
    }).isRequired,
  }),
}

export default EmployerSalesForceList
