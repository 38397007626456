import { apiRequest, handleCall } from 'syft-acp-core/api/call'

import { FetchCsatEmployersAction } from './actions'

export const fetchCsatEmployerList = ({ options }: FetchCsatEmployersAction) =>
  new Promise((resolve, reject) => {
    const request = apiRequest({
      path: `/admin/surveys/employers/csats`,
      reqArgs: options,
    })
    handleCall(request, resolve, reject, true, options)
  })
