import { apiRequest, handleCall } from 'syft-acp-core/api/call'
import { csatPathType, csatCsvType } from 'syft-acp-util/csat'

export const fetchCsatSkipped = ({ payload: { dates, type } }) =>
  new Promise((resolve, reject) => {
    const req = apiRequest({
      path: `/admin/surveys/csats/percent_skipped`,
      reqArgs: { ...dates, user_type: csatPathType[type] },
      utilizePagination: false,
    })
    handleCall(req, resolve, reject)
  })

export const fetchCsatScore = ({ payload: { dates, type } }) =>
  new Promise((resolve, reject) => {
    const req = apiRequest({
      path: `/admin/surveys/${csatPathType[type]}/csats/average_rating`,
      reqArgs: dates,
      utilizePagination: false,
    })
    handleCall(req, resolve, reject)
  })

export const fetchCsatResults = ({ payload: { dates, type } }) =>
  new Promise((resolve, reject) => {
    const req = apiRequest({
      path: `/admin/surveys/${csatPathType[type]}/csats/reasons`,
      reqArgs: dates,
      utilizePagination: false,
    })
    handleCall(req, resolve, reject)
  })

export const fetchCsatCsvDataCSV = ({ payload: { date, type } }) =>
  new Promise((resolve, reject) => {
    const req = apiRequest({ path: `/admin/surveys/${csatPathType[type]}/csats.csv`, reqArgs: date })
    handleCall(req, resolve, reject, false, {}, null, true, csatCsvType[type], true)
  })
