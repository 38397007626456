import React from 'react'
import { useDropzone } from 'react-dropzone'
import classNames from 'classnames'

import FileUploadButton from './FileUploadButton'
import { FileUploadProps as Props } from './FileUpload.types'
import S from './FileUpload.module.scss'

const FileUpload = ({
  accept,
  onDrop,
  buttonText,
  className,
  disabled = false,
  dropzone = false,
  dropzoneMessage = "Drag 'n' drop some files here, or click to select files",
  maxFiles = 1,
}: Props) => {
  const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
    accept,
    disabled,
    onDrop,
    maxFiles,
    noDrag: !dropzone,
    noClick: !dropzone,
  })

  return (
    <div
      {...getRootProps({ className: classNames(S['file-upload'], dropzone && S['file-upload--dropzone'], className) })}
    >
      <input {...getInputProps()} data-testid="dropzone-file-input" />
      {dropzone && <p>{isDragActive ? `Drop your file${maxFiles !== 1 ? 's' : ''} here` : dropzoneMessage}</p>}
      <FileUploadButton
        disabled={disabled}
        onClick={e => {
          open()
          // This prevents two 'open' events being fired due to the dropzone itself having an onclick handler
          if (dropzone) e.stopPropagation()
        }}
        text={buttonText}
      />
    </div>
  )
}

export default FileUpload
