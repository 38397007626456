import { invert } from 'lodash-es'
import changeCase from 'change-case'

// List of metadata headers we'll save on every request.
export const acceptedMetadata = [
  ['X-Next-Page', Number],
  ['X-Offset', Number],
  ['X-Page', Number],
  ['X-Per-Page', Number],
  ['X-Platform-Id', Number],
  ['X-Platform-Url', String],
  ['X-Request-Id', String],
  ['X-Runtime', String],
  ['X-Total-Bookings', Number],
  ['X-Total-Pages', Number],
  ['X-Total-Shifts', Number],
  ['X-Total-Vacancies', Number],
  ['X-Total-Workers', Number],
  ['X-Total', Number],
]

// Conversion format for metadata headers to our internal system for e.g. pagination.
// After converting metadata headers to this format, it can be merged
// with a query object for a new request.
export const commonMetaNames = {
  'X-Next-Page': ['nextPage', 'next_page'],
  'X-Offset': ['offset', 'offset'],
  'X-Page': ['page', 'page'],
  'X-Per-Page': ['perPage', 'per_page'],
  'X-Platform-Id': ['platformID', 'platform_id'],
  'X-Platform-Url': ['platformURL', 'platform_url'],
  'X-Request-Id': ['requestID', 'request_id'],
  'X-Runtime': ['runtime', 'runtime'],
  'X-Total-Bookings': ['totalBookings', 'total_bookings'],
  'X-Total-Pages': ['totalPages', 'total_pages'],
  'X-Total-Shifts': ['totalShifts', 'total_shifts'],
  'X-Total-Vacancies': ['totalVacancies', 'total_vacancies'],
  'X-Total-Workers': ['totalWorkers', 'total_workers'],
  'X-Total': ['total', 'total'],
}

/** Returns valid metadata from a request, and converts values to the right type. */
export const filterAcceptedMetadata = res => {
  return acceptedMetadata.reduce((headers, item) => ({ ...headers, [item[0]]: item[1](res.headers.get(item[0])) }), {})
}

/**
 * Changes all keys of an object to another case.
 */
const objCaseConvert = (obj, fn) => {
  if (!obj) return obj
  const newObj = {}
  for (const [key, value] of Object.entries(obj)) {
    newObj[fn(key)] = value
  }
  return newObj
}

/** Changes all keys of an object from camelCase to snake_case. */
export const objCamelToSnake = obj => objCaseConvert(obj, changeCase.snakeCase)

/** Changes all keys of an object from snake_case to camelCase. */
export const objSnakeToCamel = obj => objCaseConvert(obj, changeCase.camelCase)

// Conversion matrix used for converting between different variable styles.
// Keys are snake_case, values are camelCase.
const commonMetaSnakeCase = Object.values(commonMetaNames).reduce((obj, item) => ({ ...obj, [item[1]]: item[0] }), {})
// Keys are camelCase, values are snake_case.
const commonMetaCamelCase = invert(commonMetaSnakeCase)

/** Converts meta data objects from snake_case to camelCase and vice versa. */
export const metaCaseConvert = (meta, toCamelCase = true) => {
  const matrix = toCamelCase ? commonMetaSnakeCase : commonMetaCamelCase
  return Object.keys(meta).reduce((obj, item) => ({ ...obj, [matrix[item]]: meta[item] }), {})
}

/** Converts meta data headers (e.g. X-Next-Page) into a common format. */
export const metaHeaderConvert = (meta, useCamelCase = true) => {
  // The conversion table contains camelCase and snake_case.
  const type = useCamelCase ? 0 : 1
  // 'meta' is an object containing keys such as X-Page, etc.
  return Object.keys(meta).reduce((obj, item) => ({ ...obj, [commonMetaNames[item][type]]: meta[item] }), {})
}
