// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { get, noop, pickBy } from 'lodash-es'

import { ButtonDropdown, MenuItemBoolean } from 'syft-acp-atoms/Button'
import * as workersActions from 'syft-acp-core/store/workers/actions'
import { workerStatuses } from 'syft-acp-core/data/workerStatus'
import { useCountrySpecificStructs } from 'syft-acp-core/components/CountrySpecific'

const WorkerTypeDropdown = React.memo(
  ({ workerID, actions, workerType = {}, onUpdate = noop, title, ...rest }) => {
    const statuses = useCountrySpecificStructs(workerStatuses)
    const selectAction = useCallback(
      key => {
        const updates = { [key]: !workerType[key] }

        if (statuses[key].exclusiveWith && workerType[statuses[key].exclusiveWith]) {
          updates[statuses[key].exclusiveWith] = false
        }

        const newWorkerTypes = {
          ...workerType,
          ...updates,
        }

        actions.updateWorkerType(workerID, newWorkerTypes)
        onUpdate(pickBy(newWorkerTypes, Boolean))
      },
      [actions, workerType, workerID, statuses, onUpdate],
    )

    const isDropdownDisabled = !!Object.keys(workerType).filter(
      i => get(statuses[i], 'exclusive') && workerType[i],
    ).length
    const editableStatuses = Object.keys(workerType).filter(s => get(statuses[s], 'editable'))

    if (editableStatuses.length === 0) {
      return null
    }

    return (
      <ButtonDropdown
        {...rest}
        title={title}
        id="workerType"
        onSelect={selectAction}
        disabled={isDropdownDisabled}
      >
        {editableStatuses.map(s => (
          <MenuItemBoolean key={s} eventKey={s} checked={workerType[s]}>
            {get(statuses[s], 'name')}
          </MenuItemBoolean>
        ))}
      </ButtonDropdown>
    )
  },
)

WorkerTypeDropdown.propTypes = {
  // TODO: this can be undefined sometimes when a page isn't loaded.
  workerID: PropTypes.number.isRequired,
  workerType: PropTypes.object.isRequired,
  actions: PropTypes.objectOf(PropTypes.func).isRequired,
  onUpdate: PropTypes.func,
  title: PropTypes.string,
}

export default connect(
  (state, props) => ({
    workerType:
      get(state.workers.entityDetail[props.workerID], 'status') ||
      get(state.workers.entityMap[props.workerID], 'status'),
  }),
  dispatch => ({
    actions: bindActionCreators(workersActions, dispatch),
  }),
)(WorkerTypeDropdown)
