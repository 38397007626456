import { isEmpty, omit } from 'lodash-es'

import { apiRequest, handleCall } from 'syft-acp-core/api/call'

/**
 * Retrieves a list of agency shifts. Implements pagination.
 */
export const listAgencyShifts = ({ options = {} }) =>
  new Promise((resolve, reject) => {
    // Check whether we are using filters, to determine how to sort the results.
    // See CMS2-315 for details. Don't take 'page' into account.
    const usingFilters = !isEmpty(omit(options, ['page']))

    // Note: ONLY pass on the sort_order to the request. We can't handle our internal call
    // with the sort_order because it will cause the result set to receive a different hash.
    // In other words, sort order is done opaquely without the rest of the system knowing it.
    const req = apiRequest({
      path: '/admin/agency_shifts',
      reqArgs: {
        ...options,
        // If we are not using any filters, sort by creation date, descending.
        // If we are using filters of any kind, sort by shift start date, ascending.
        sort_order: usingFilters ? 'start_time_asc' : 'created_at_desc',
      },
    })
    // Pass the original options back so we don't save the cache under a different key.
    handleCall(req, resolve, reject, true, options)
  })
