import React, { useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { get } from 'lodash-es'
import * as filtersActions from 'syft-acp-core/store/filters/actions'
import { filterSelect } from './FilterSelect'
import US_STATES_MAP from '../../fixtures/us_states.json'

const options = Object.entries(US_STATES_MAP).map(([code, name]) => ({ id: code, label: name }))

const FilterSelectStates = ({ name, value, actions, allowAny, disabled, type }) => {
  const FilterSelect = useMemo(() => filterSelect(options, allowAny ? 'Any' : 'State', type, false), [allowAny, type])
  const onChange = useCallback(val => actions.setFilter(name, val, val), [actions, name])
  return (
    <FilterSelect
      name={name}
      className="SelectStates"
      onChange={onChange}
      value={value}
      allowAny={allowAny}
      disabled={disabled}
      type={type}
    />
  )
}

FilterSelectStates.propTypes = {
  name: PropTypes.string.isRequired,
  actions: PropTypes.objectOf(PropTypes.func).isRequired,
  disabled: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  allowAny: PropTypes.bool,
  type: PropTypes.string,
}
FilterSelectStates.defaultProps = {
  disabled: false,
  value: null,
  allowAny: false,
  type: null,
}

export default connect(
  (state, { value, name = 'state_code' }) => ({
    value: value || get(state.routing.locationBeforeTransitions.query, name),
  }),
  (dispatch, { actions }) => {
    const boundActions = bindActionCreators(filtersActions, dispatch)
    return {
      actions: {
        ...boundActions,
        ...actions,
      },
    }
  }
)(FilterSelectStates)
