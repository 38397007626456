import React from 'react'
import { connect } from 'react-redux'

import FilterReasons from 'syft-acp-util/components/FilterForm/FilterReasons'

import { DataBanReviewAssigneeDropdownProps as Props } from './DataBanReviewAssigneeDropdown.types'

export const storeConnector = connect(state => ({ assignees: state?.appeals?.assignees }))

const DataBanReviewAssigneeDropdown = ({
  disabled = false,
  editable = false,
  data,
  onChange,
  assignees,
}: Props) => {
  const isAssigneeData = assignees.find(assignee => assignee.id === Number(data?.assignee?.id))
  const value = data?.assignee?.id && isAssigneeData ? data?.assignee?.id : null

  if (editable)
    return (
      <FilterReasons
        type="assignees"
        headerLabel={!isAssigneeData && data?.assignee?.id ? data?.assignee?.full_name : 'No Assignee'}
        onChange={onChange}
        value={value as any}
        disabled={disabled}
      />
    )
  return <div className="plain-text">{data.assignee ? data?.assignee?.full_name : 'No Assignee'}</div>
}

export default storeConnector(DataBanReviewAssigneeDropdown)
