import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { get } from 'lodash-es'
import moment from 'moment'
import EntityListInputWrapper from 'syft-acp-core/components/EntityList/EntityListInputWrapper'
import { updateBooking, updateBookingChanges } from 'syft-acp-core/actions/bookings'
import { zeroPad } from 'syft-acp-util/formatting'
import TimeInput from './TimeInput'

import './ListingDurationInput.css'

// Converts a time value to a string. E.g. 4800 to '01:20'.
const timeToString = time => {
  if (time == null) return null
  const hours = Math.floor(time / 3600)
  const mins = Math.floor((time - hours * 3600) / 60)
  return `${zeroPad(hours)}:${zeroPad(mins)}`
}

// Converts a string value, e.g. '01:20', to the time in amount of seconds (4800).
const stringToTime = str => {
  const [hour, minute] = str.split(':')
  return (Number(hour) * 60 + Number(minute)) * 60
}

// Reduces e.g. [{ duration: 3600, id: 3, deletable: true }] to 3600.
const unpaidTimesToNumber = arr => arr && arr.reduce((acc, item) => (item.duration ? acc + item.duration : acc), 0)

// Calculates the difference, in minutes, between a booking's amount of minutes worked the minimum time.
// E.g. if a worker worked for 3 hours (based on clock in and out times), and the minimum is 4 hours,
// this function will return 1 hour (in minutes, so 3600).
const calculateMinimumDiff = (shiftBooking, minHours) => {
  const clockIn = moment(get(shiftBooking, 'clock_in.time'))
  const clockOut = moment(get(shiftBooking, 'clock_out.time'))

  // The amount of time the worker spent according to the clock in and out times.
  const secondsWorked = moment.utc(clockOut).diff(clockIn, 'seconds')
  // Number of seconds we need at minimum.
  const minSeconds = minHours * 3600
  // The difference between the two.
  const diff = minSeconds - secondsWorked

  // If the difference is lower than zero, it means the worker has already met the minimum.
  // In that case we can't give compensation hours. So return 'false' to indicate we will not take action.
  return diff > 0 ? diff : false
}

// Returns the minimum number of hours that a role will be working.
// Chef roles should get 6 hours, all other roles 4.
// TODO: this should not be hardcoded. See <API2-1721>.
const chefRoles = ['chef-de-partie', 'commis-chef', 'food-prep-assistant', 'head-chef', 'lead-chef', 'sous-chef']
const getMinimumHours = shift => {
  const role = shift.job.role.code
  const isChefRole = chefRoles.indexOf(role) > -1
  return isChefRole ? 6 : 4
}

class ListingDurationInput extends PureComponent {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    useCallback: PropTypes.bool,
    onChange: PropTypes.func,
    value: PropTypes.number,
    enforcedTime: PropTypes.bool,
    isUnpaidTime: PropTypes.bool,
    attr: PropTypes.string.isRequired,
    attrSource: PropTypes.string,
    shiftBooking: PropTypes.object,
    disabled: PropTypes.bool,
    enforceDeadline: PropTypes.bool,
  }

  static defaultProps = {
    isUnpaidTime: false,
    disabled: false,
    useCallback: false,
    value: null,
    shiftBooking: {},
    enforceDeadline: false,
  }

  constructor(props) {
    super()
    this.state = this.UNSAFE_componentWillReceiveProps(props, null, true)
  }

  UNSAFE_componentWillReceiveProps(nextProps, currProps, constructing = false) {
    const { attr, shiftBooking, attrSource, isUnpaidTime, enforcedTime, dispatch } = nextProps
    const rawValue = get(shiftBooking, attr)

    let value
    if (isUnpaidTime && enforcedTime === false) {
      // If compensation hours is turned off, set the missing hours duration value back to the pristine value.
      const id = shiftBooking.id
      const hrsNumber = unpaidTimesToNumber(get(shiftBooking, attrSource))
      value = timeToString(hrsNumber)
      const hrsVal = { id, attr, val: hrsNumber }
      dispatch(updateBooking(hrsVal))
      dispatch(updateBookingChanges(hrsVal))

      // Set the compensation hours value to null.
      const cmpVal = { id, attr: 'syft_unpaid_hours_compensation', val: null }
      dispatch(updateBooking(cmpVal))
      dispatch(updateBookingChanges(cmpVal))
    } else if (isUnpaidTime && enforcedTime === true) {
      // If compensation hours is turned on, calculate the amount of time (in seconds) we need to give this worker.
      const shift = get(nextProps.listingShifts, shiftBooking.shiftID)
      const minHours = getMinimumHours(shift)
      const enforcedValue = calculateMinimumDiff(shiftBooking, minHours)
      value = timeToString(enforcedValue)
      this.onChange(value)
    } else if (isUnpaidTime) {
      // The API returns 'unpaid_times' as an array of objects.
      // If we're working with API data, convert this value to a single number.
      // We store this value as 'unpaid_time_n', which is the regular 'attr' value.

      // Ensure we set the value to null if there is no clock in/out time yet.
      const clockIn = get(shiftBooking, 'clock_in.time')
      const clockOut = get(shiftBooking, 'clock_out.time')
      if (rawValue != null) {
        value = timeToString(rawValue)
      } else {
        value = clockIn && clockOut ? timeToString(unpaidTimesToNumber(get(shiftBooking, attrSource))) : null
      }
    } else {
      value = timeToString(rawValue)
    }

    // Unless we're constructing the class, set the new state.
    if (!constructing) {
      this.setState({ value })
    }
    // Return the new state for the constructor.
    return { value }
  }

  // Check whether the deadline has passed already, if this is 'unpaid time'.
  // It's not possible to add unpaid time until the deadline passes.
  get isPreDeadline() {
    const { shiftBooking, isUnpaidTime } = this.props
    return isUnpaidTime && !shiftBooking.deadline_passed
  }

  onChange = changedValue => {
    if (!this.props) return
    const { dispatch, attr, shiftBooking, onChange, useCallback } = this.props
    const { id } = shiftBooking

    if (useCallback) {
      return onChange(changedValue)
    }

    // If we're delivering a duration, send the value in seconds.
    // Fall back to null if the value is cleared.
    const val = changedValue ? stringToTime(changedValue) : stringToTime('00:00')
    dispatch(updateBooking({ id, attr, val }))
    dispatch(updateBookingChanges({ id, attr, val }))
  }

  render() {
    const { enforcedTime, disabled, enforceDeadline } = this.props
    return (
      <EntityListInputWrapper className={`listingDurationInput ${enforcedTime ? 'enforced-time' : ''}`}>
        <TimeInput
          onChange={this.onChange}
          value={this.state.value}
          limitHours={false}
          disabled={disabled || (this.isPreDeadline && enforceDeadline)}
          canBeCleared
        />
      </EntityListInputWrapper>
    )
  }
}

export default connect(state => ({
  listingShifts: state.listingShifts.entityDetail,
}))(ListingDurationInput)
