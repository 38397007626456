import React from 'react'
import PropTypes from 'prop-types'

import Acp from 'syft-acp-uikit'

class BelowMinHours extends React.PureComponent {
  static propTypes = {
    bookings: PropTypes.array.isRequired,
    noShowReasons: PropTypes.array,
    isLoading: PropTypes.bool,
  }

  render() {
    const { bookings, noShowReasons, isLoading } = this.props
    return (
      <div>
        <Acp.EntityList hasMinimumSize isIntegrated data={{ data: bookings }} isLoading={isLoading}>
          <Acp.Actions isHeader>
            <Acp.Header>Below minimum hours</Acp.Header>
          </Acp.Actions>

          <Acp.Table hideIfNull="id">
            <Acp.Col.Text value="id" header="ID" isNumeric isMinimal />
            <Acp.Col.Text value="worker.id" header="W.ID" headerTooltip="Worker ID" isNumeric isMinimal />
            <Acp.Col.Worker value="worker" header="Worker" />
            <Acp.Col.Duration value="_diff" header="Time" headerTooltip="Amount of time worked for the shift" />
            {/* <Acp.Col.Timestamp value="_shift.start_time" header="Start" isMinimal withTooltip />
            <Acp.Col.Timestamp value="_shift.end_time" header="End" isMinimal withTooltip /> */}
            <Acp.Col.Boolean value="no_show" header="NS" headerTooltip="No show" />
            <Acp.Col.Enum
              value="no_show_reason.no_show_reason_id"
              header="Reason"
              isMinimal
              headerTooltip="Reason for working less than minimum hours"
              options={{ style: 'tag' }}
            >
              {noShowReasons.map(r => (
                <Acp.Enum key={r.code} slug={r.id}>
                  {r.desc}
                </Acp.Enum>
              ))}
            </Acp.Col.Enum>
            <Acp.Col.Text value="no_show_reason.comments" header="Comment" isMain />
            {/* <Acp.Col.Text value="__comment" header={ <Acp.Icon.ClockIcon /> } headerTooltip="Amount of worked hours" /> */}
            {/* <Acp.Col.Enum value={ () => Math.round(Math.random()) ? [`admin`, `client`, `zap`] : [`admin`] } header="Logged by">
              <Acp.Enum slug="admin">Admin</Acp.Enum>
              <Acp.Enum slug="client">Client</Acp.Enum>
            </Acp.Col.Enum> */}
          </Acp.Table>
        </Acp.EntityList>
      </div>
    )
  }
}

export default BelowMinHours
