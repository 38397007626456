// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import * as React from 'react'

import AcpCellText from '../../AcpCellText'
import { AbbrHeaderProps as Props } from './AbbrHeader.types'
import './AbbrHeader.scss'

const AbbrHeader: React.FC<Props> = ({ label, children }) => (
  <AcpCellText label={label}>
    <div className="abbr-header">
      <div className="abbr-header__display">{children}</div>
      {children}
    </div>
  </AcpCellText>
)

export default React.memo(AbbrHeader)
