import { generateEntityCrudReducer } from 'syft-acp-util/store/reducers'
import watchlistComplienceTypes from './action-types'
import { WatchlistComplianceEntity } from './types'

export default generateEntityCrudReducer<WatchlistComplianceEntity>({
  name: 'watchlistCompliance',
  actions: watchlistComplienceTypes,
  options: {
    idKey: 'audit_id',
  },
})
