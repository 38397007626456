// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { avatarURL } from 'syft-acp-core/api/endpoints'

import './UserPicture.css'

/**
 * Displays a file upload input field.
 */
export default class UserPicture extends PureComponent {
  static propTypes = {
    uuid: PropTypes.string,
    alt: PropTypes.string,
  }

  static defaultProps = {
    uuid: null,
    alt: 'User picture',
  }

  render() {
    const { uuid, alt } = this.props
    return (
      <div className="UserPicture">
        <img src={avatarURL(uuid)} alt={alt} />
      </div>
    )
  }
}
