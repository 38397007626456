// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import * as types from '../actions/action-types'

// Returns the current date as an ISO string.
const latestDate = () => new Date().toISOString()

const initialState = {
  entityMap: [],
  entitySets: {},
  // These are just for compatibility with entityList(). Unused.
  lastMessage: '',
  isSavingData: false,
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.AUTH_DELETE:
      return initialState
    case types.ERROR_ADD:
      return {
        ...state,
        entityMap: [{ ...action.payload, timestamp: latestDate() }, ...state.entityMap],
      }
    default:
      return state
  }
}

export default reducer
