import React from 'react'
import { Tab, Badge } from 'react-bootstrap'
import { map } from 'lodash-es'

import AlertItem from '../AlertItem'
import NoAlerts from '../NoAlerts'

import { AlertsContainerProps, FilterProp, AlertTabHelperProp, AlertTabTypes } from './AlertsContainer.types'

// Returns alertItem nodes from an array of alerts.
const alertItems = (alerts: AlertsContainerProps['allItems'], searchValue: AlertsContainerProps['searchValue']) =>
  alerts.map(alert => <AlertItem key={`a_${alert.id}`} {...{ ...alert, searchValue }} />)

export const alertsTab = ({ name, key, tabItems, searchValue, isLoading, hasData }: AlertTabHelperProp) => (
  <Tab
    key={key}
    eventKey={name}
    title={
      <span>
        {name}
        {tabItems.length ? <Badge>{tabItems.length}</Badge> : null}
      </span>
    }
  >
    {tabItems.length > 0 ? alertItems(tabItems, searchValue) : <NoAlerts isLoading={isLoading} hasData={hasData} />}
  </Tab>
)

// Filters the alerts by our "filter" prop, e.g. { employer: <data> }.
export const filterAlerts = (alerts: AlertsContainerProps['allItems'], filter: FilterProp) => {
  if (!filter.employer) {
    return []
  }
  if (filter?.employer?.id) {
    // Only alerts relevant to an employer (we can't check by ID, so we'll check by name).
    const findName = filter.employer.brand_name || filter.employer.company_name || ''
    const valid = alerts.filter(alert => alert.message.toLowerCase().indexOf(findName?.toLowerCase()) > -1)
    const ids = map(valid, 'id')
    return alerts.filter(alert => ids.indexOf(alert.id) > -1)
  }
  // Apparently we have no filter.
  return alerts
}

// Filters out a list of alerts by resource type.
export const filterAlertsByResource = (alerts: AlertsContainerProps['allItems'], types: AlertTabTypes) => alerts.filter(alert => types.indexOf(alert.type) > -1)
