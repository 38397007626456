import { DefaultRootState } from 'react-redux'
import { createSelector } from 'reselect'
import { memoize } from 'lodash-es'

import { ShiftEntity } from '../shifts/types'

/**
 * Selects a shift by its ID.
 */
export const selectShift$ = createSelector(
  (state: DefaultRootState) => state,
  store =>
    memoize(({ shiftID }: { shiftID: number }) => {
      const empty = {} as Partial<ShiftEntity>
      if (!shiftID) return empty
      // The update action updates only entityDetail in reducer
      // this is reason why entityDetail should be by default over entityMap
      return (
        (store.fulfilmentShifts?.entityDetail?.[shiftID] as ShiftEntity) ||
        (store.fulfilmentShifts?.entityMap?.[shiftID] as ShiftEntity) ||
        empty
      )
    }),
)
