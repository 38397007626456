export const entityFetchBegin = state => {
  return {
    ...state,
    lastMessage: '',
    lastStatus: null,
    lastBody: null,
    isSavingData: false,
    isLoadingData: true,
  }
}
