// Syft ACP - UI Library <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import * as React from 'react'

import { AcpCellSelector, AcpCellSelectorLegacy } from '../../cellComponents/dataTypes'
import { HeaderSelectorProps as Props } from './HeaderSelector.types'
import './HeaderSelector.css'

export const getValue = (data: Props['tableData'], valueFn: Props['value'], valueKeys: Props['valueKeys']) =>
  valueFn &&
  data
    .map(rowData => valueFn(rowData))
    .filter(rowValue => {
      if (!rowValue) return false
      return rowValue[0][valueKeys[0]]
    })
    .flat()

const HeaderSelector = ({ rowState, scope, tableData, valueKeys, value, isSubRow }: Props) => {
  const _getValue = React.useCallback(() => getValue(tableData, value, valueKeys), [tableData, value, valueKeys])

  if (scope && valueKeys)
    return (
      <AcpCellSelector
        value={_getValue()}
        options={{
          scope,
          valueKeys,
        }}
        isSubRow={isSubRow}
        isHeader
      />
    )

  // Cosmetic component used in existing tables
  return <AcpCellSelectorLegacy rowState={{ rowSelectionLens: rowState.pageSelectionLens }} isSubRow={isSubRow} />
}

export default HeaderSelector
