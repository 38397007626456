import React, { useState, memo } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { ControlLabel, FormGroup, Row, Col } from 'react-bootstrap'
import { values, get } from 'lodash-es'

import { getModal$ } from 'syft-acp-core/store/modals/selectors'
import { Modal } from 'syft-acp-core/components/Modal'
import { hideModal } from 'syft-acp-core/store/modals/actions'
import FilterIndustryRolesMultiselect from 'syft-acp-util/components/FilterForm/FilterIndustryRolesMultiselect'
import FilterVenuesMultiselect from 'syft-acp-util/components/FilterForm/FilterVenuesMultiselect'
import AgencyEmployersAutocomplete from 'syft-acp-core/components/EditableTable/AgencyEmployersAutocomplete'
import { fetchEmployerFilteredVenuesList } from 'syft-acp-core/actions/employer-venues-filtered'
import { fetchIndustries } from 'syft-acp-core/actions/industries'
import { updateAgencyAccounts } from 'syft-acp-core/actions/agency-accounts'

export const formatPermissions = ({ selectedEmployer = {}, data = [], industries = [], venues = [] }) => [
  ...data,
  ...venues.map(venue => {
    return {
      select_all_venues: venue.value === null,
      venue_id: venue.value,
      platform_id: selectedEmployer.platform_id,
      employer_name: selectedEmployer.company_name,
      employer_id: selectedEmployer.id,
      venue_name: venue.label,
      industry_roles: industries.map(industry => ({
        industry_id: industry.value,
        role_ids: industry.options.map(role => role.value),
      })),
    }
  }),
]

const AgencyAccountsIndustriesRolesModal = memo(({ actions, item, data, employers, agencyId, onChange }) => {
  const [employerId, setEmployerId] = useState(null)
  const [venues, setVenues] = useState([])
  const [industries, setIndustries] = useState([])
  const closeModal = () => actions.hideModal(item.modalName)

  const onConfirmHandler = () => {
    const selectedEmployer = employers.find(employer => employer.id === employerId)
    const permissions = formatPermissions({ selectedEmployer, data, industries, venues })
    onChange(permissions)
    closeModal()
  }

  return (
    <Modal
      header="Set up new allocations"
      isShown={item.isShown}
      onClose={closeModal}
      onConfirm={onConfirmHandler}
      confirmationText="Add location"
    >
      <div className="missing-time-modal">
        <FormGroup className="form-item">
          <Row>
            <Col md={3}>
              <ControlLabel>Employer</ControlLabel>
            </Col>
            <Col md={9}>
              <AgencyEmployersAutocomplete agencyId={agencyId} onChange={setEmployerId} />
            </Col>
          </Row>
          <Row>
            <Col md={3}>
              <ControlLabel>Venue</ControlLabel>
            </Col>
            <Col md={9}>
              <FilterVenuesMultiselect onChange={setVenues} employerId={employerId} />
            </Col>
          </Row>
          <Row>
            <Col md={3}>
              <ControlLabel>Industries + Roles</ControlLabel>
            </Col>
            <Col md={9}>
              <FilterIndustryRolesMultiselect employerId={employerId} onChange={setIndustries} />
            </Col>
          </Row>
        </FormGroup>
      </div>
    </Modal>
  )
})

AgencyAccountsIndustriesRolesModal.propTypes = {
  className: PropTypes.string.isRequired,
  employerLocations: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  employers: PropTypes.array.isRequired,
  data: PropTypes.object.isRequired,
  item: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
  industries: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  agencyId: PropTypes.string.isRequired,
}

export default connect(
  (state, { agencyId }) => {
    return {
      item: getModal$(state.modals)('agencyAccountsIndustiresRolesModal'),
      employers: values(get(state.agencyProfile, `entityDetail[${agencyId}].employers`, {})),
    }
  },
  dispatch => ({
    actions: bindActionCreators(
      { fetchIndustries, hideModal, fetchVenues: fetchEmployerFilteredVenuesList, updateAgencyAccounts },
      dispatch
    ),
  })
)(AgencyAccountsIndustriesRolesModal)
