import { takeLatest } from 'redux-saga/effects'
import * as api from 'syft-acp-core/api/resources/admin/managers'
import * as types from 'syft-acp-core/actions/action-types'
import { redirectCall, notificationCall } from './calls'
import { takeLatestApiCall } from './helpers'

export default function* root() {
  yield takeLatestApiCall('ADMIN_MANAGERS_FETCH', api.fetchAdminManagers)
  yield takeLatestApiCall('ADMIN_MANAGER_FETCH', api.fetchAdminManager)
  yield takeLatestApiCall('ADMIN_MANAGER_CREATE', api.createAdminManager)
  yield takeLatestApiCall('ADMIN_MANAGER_UPDATE', api.updateAdminManager)
  yield takeLatestApiCall('ADMIN_MANAGER_DELETE', api.deleteAdminManager)
  yield takeLatest(
    types.ADMIN_MANAGER_CREATE_SUCCEEDED,
    redirectCall,
    ({ payload: { id } }) => `/admin/managers/view/${id}`
  )
  yield takeLatest(types.ADMIN_MANAGER_DELETE_SUCCEEDED, redirectCall, () => '/admin/managers')
  yield takeLatest(types.ADMIN_MANAGER_CREATE_SUCCEEDED, notificationCall)
  yield takeLatest(types.ADMIN_MANAGER_UPDATE_SUCCEEDED, notificationCall)
  yield takeLatest(types.ADMIN_MANAGER_DELETE_SUCCEEDED, notificationCall)
}
