import React from 'react'
import classNames from 'classnames'

import { CellTagProps as Props } from './CellTag.types'
import './CellTag.scss'

export default class AcpCellTag extends React.PureComponent<Props> {
  static defaultProps: Props = {
    value: '?',
    slug: '',
    unknown: false,
  }

  static namedColors = [
    'red',
    'orange',
    'yellow',
    'green',
    'aqua',
    'cyan',
    'blue',
    'purple',
    'magenta',
    'pink',
    'gray',
    'red-light',
    'orange-light',
    'yellow-light',
    'green-light',
    'aqua-light',
    'cyan-light',
    'blue-light',
    'purple-light',
    'magenta-light',
    'pink-light',
    'gray-light',
  ]

  static styleToColor = {
    success: 'green',
    warning: 'yellow',
    danger: 'red',
    info: 'blue',
  }

  render() {
    const { value, slug, color, unknown } = this.props
    const tagColor = (color && AcpCellTag.styleToColor[color as keyof typeof AcpCellTag.styleToColor]) || color
    const isKnownColor = AcpCellTag.namedColors.indexOf(tagColor || '') > -1
    const style = tagColor && !isKnownColor ? { backgroundColor: tagColor } : {}

    return (
      <span
        className={classNames(
          'acp-cell-tag',
          `slug-${slug}`,
          { unknown },
          isKnownColor ? `tag-color-${tagColor}` : null
        )}
        style={style}
      >
        {value || '?'}
      </span>
    )
  }
}
