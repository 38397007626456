// Syft ACP - Lib <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { get } from 'lodash-es'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import { setFilter } from 'syft-acp-core/store/filters/actions'

import FilterRatingPure from './FilterRatingPure'

const R_OR_BELOW = 'RATING_LESS_THAN_OR_EQUAL'
const R_OR_ABOVE = 'RATING_GREATER_THAN_OR_EQUAL'

class FilterRating extends PureComponent {
  static propTypes = {
    nameFrom: PropTypes.string,
    nameTo: PropTypes.string,
    amountFrom: PropTypes.number,
    amountTo: PropTypes.number,
    actions: PropTypes.objectOf(PropTypes.func).isRequired,
    withoutLessButton: PropTypes.bool,
    withoutGreaterButton: PropTypes.bool,
  }

  static defaultProps = {
    amountFrom: 1,
    amountTo: 5,
    nameFrom: 'rating_gte',
    nameTo: 'rating_lte',
    withoutLessButton: false,
    withoutGreaterButton: false,
  }

  constructor() {
    super()
    this.state = {
      ratingLte: null,
      ratingGte: null,
    }
  }

  componentDidMount() {
    this.readQuery()
  }

  readQuery = (props = this.props) => {
    let ratingLte = Number(get(props, `query.${this.props.nameTo}`, 0))
    let ratingGte = Number(get(props, `query.${this.props.nameFrom}`, 0))

    if (ratingGte > 0 && ratingLte === 0) {
      ratingLte = ratingGte
    }
    if (ratingLte > 0 && ratingGte === 0) {
      ratingGte = ratingLte
    }
    if (ratingGte === 0 && ratingLte === 0) {
      ratingGte = null
      ratingLte = null
    }

    return this.setState({ ratingLte, ratingGte }, this.writeQuery)
  }

  writeQuery = () => {
    let queryRatingLte = get(this.props, `query.${this.props.nameTo}`, null)
    let queryRatingGte = get(this.props, `query.${this.props.nameFrom}`, null)
    queryRatingLte = queryRatingLte !== null ? Number(queryRatingLte) : null
    queryRatingGte = queryRatingGte !== null ? Number(queryRatingGte) : null

    if (queryRatingLte !== this.state.ratingLte) {
      this.props.actions.setFilter(this.props.nameTo, this.state.ratingLte)
    }
    if (queryRatingGte !== this.state.ratingGte) {
      this.props.actions.setFilter(this.props.nameFrom, this.state.ratingGte)
    }
  }

  clickHandler = n => () => {
    const { ratingGte, ratingLte } = this.state
    const { amountFrom, amountTo } = this.props

    if ((n === R_OR_BELOW || n === R_OR_ABOVE) && ratingGte === null && ratingLte === null) {
      return
    }

    if (n === R_OR_BELOW) {
      if (ratingGte === amountFrom) {
        this.setState(
          {
            ratingGte: ratingLte,
          },
          this.writeQuery
        )
      } else {
        this.setState(
          {
            ratingGte: amountFrom,
            ratingLte: ratingGte,
          },
          this.writeQuery
        )
      }
    } else if (n === R_OR_ABOVE) {
      if (ratingLte === amountTo) {
        this.setState(
          {
            ratingLte: ratingGte,
          },
          this.writeQuery
        )
      } else {
        this.setState(
          {
            ratingLte: amountTo,
            ratingGte: ratingLte,
          },
          this.writeQuery
        )
      }
    } else if (ratingGte === ratingLte) {
      if (ratingGte === n) {
        this.setState(
          {
            ratingGte: null,
            ratingLte: null,
          },
          this.writeQuery
        )
      } else {
        this.setState(
          {
            ratingGte: n,
            ratingLte: n,
          },
          this.writeQuery
        )
      }
    } else if (ratingGte <= amountFrom) {
      this.setState({ ratingLte: n }, this.writeQuery)
    } else {
      this.setState({ ratingGte: n }, this.writeQuery)
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.readQuery(nextProps)
  }

  render() {
    return (
      <FilterRatingPure
        valFrom={this.state.ratingGte}
        valTo={this.state.ratingLte}
        amountFrom={this.props.amountFrom}
        amountTo={this.props.amountTo}
        callback={this.clickHandler}
        singleValue={this.props.nameFrom === this.props.nameTo}
        withoutLessButton={this.props.withoutLessButton}
        withoutGreaterButton={this.props.withoutGreaterButton}
      />
    )
  }
}

export default connect(
  state => ({
    query: state.routing.locationBeforeTransitions.query,
  }),
  dispatch => ({
    actions: bindActionCreators({ setFilter }, dispatch),
  })
)(FilterRating)
