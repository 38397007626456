import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import * as filtersActions from 'syft-acp-core/store/filters/actions'
import { Store } from 'syft-acp-core/store'

import { FilterOfferTypesProps as Props } from './FilterOfferTypes.types'
import { filterSelect } from '../FilterSelect'
import FilterGroup from 'syft-acp-uikit/components/AcpTable/filterComponents/FilterGroup'

export const storeConnector = connect(
  (state: Store) => ({
    query: state.routing.locationBeforeTransitions.query as Record<string, string | string[]>,
  }),
  dispatch => ({
    actions: {
      ...bindActionCreators(filtersActions, dispatch),
    },
  }),
)

export const filters = [
  {
    title: 'Flex offer types',
    key: 'offer_to_flex',
    values: [
      { label: 'All Flexers', id: 'all' },
      { label: 'Indeed Flex pool', id: 'network' },
      { label: 'Individual Flexers', id: 'individual' },
    ],
  },
  {
    title: 'Internal offer types',
    key: 'offer_to_internal',
    values: [
      { label: 'All staff', id: 'all' },
      { label: 'Primary venue staff', id: 'network' },
      { label: 'Individual staff', id: 'individual' },
    ],
  },
  {
    title: 'Agency offer types',
    key: 'offer_to_agency',
    values: [
      { label: 'Agency', id: 'agency' },
      { label: 'Not agency', id: 'not_agency' },
    ],
  },
]

// Data for this component are called similary to roles during initial loading here loadInitData
const FilterOfferTypes = ({
  defaultOption = 'Any',
  className = '',
  actions,
  allowAny = false,
  type,
  small = false,
  tiny = false,
  query,
  onChange,
}: Props) => {
  const onChangeFilterHandler = (key: string) => (val: string | string[]) => {
    !!onChange && onChange(key, val?.toString())
    const availableFilters = filters.map(filter => filter.key).filter(filter => filter !== key)
    availableFilters.forEach(filterKey => actions.setFilter(filterKey, null))
    actions.setFilter(key, val)
  }

  const FilterSelectElements = filters.map(filter => {
    const value = query[filter.key]
    const FilterSelectElement = filterSelect(filter.values, defaultOption || 'Any', type, false, false)
    return (
      <FilterGroup key={filter.key} title={filter.title}>
        <FilterSelectElement
          name={filter.key}
          className={`${className}`}
          onChange={onChangeFilterHandler(filter.key)}
          value={value}
          small={small}
          tiny={tiny}
          allowAny={allowAny}
        />
      </FilterGroup>
    )
  })

  return <>{FilterSelectElements}</>
}

export default storeConnector(FilterOfferTypes)
