export const ADMIN_LISTING_FETCH_BEGIN = 'syftacp/ADMIN_LISTING_FETCH_BEGIN' as const
export const ADMIN_LISTING_FETCH_FAILED = 'syftacp/ADMIN_LISTING_FETCH_FAILED' as const
export const ADMIN_LISTING_FETCH_SUCCEEDED = 'syftacp/ADMIN_LISTING_FETCH_SUCCEEDED' as const

export const LISTING_DELETE_BEGIN = 'syftacp/LISTING_DELETE_BEGIN' as const
export const LISTING_DELETE_FAILED = 'syftacp/LISTING_DELETE_FAILED' as const
export const LISTING_DELETE_SUCCEEDED = 'syftacp/LISTING_DELETE_SUCCEEDED' as const
export const LISTING_FETCH_RATINGS_BEGIN = 'syftacp/LISTING_FETCH_RATINGS_BEGIN' as const
export const LISTING_FETCH_RATINGS_FAILED = 'syftacp/LISTING_FETCH_RATINGS_FAILED' as const
export const LISTING_FETCH_RATINGS_SUCCEEDED = 'syftacp/LISTING_FETCH_RATINGS_SUCCEEDED' as const
export const LISTING_SAVE_BEGIN = 'syftacp/LISTING_SAVE_BEGIN' as const
export const LISTING_SAVE_FAILED = 'syftacp/LISTING_SAVE_FAILED' as const
export const LISTING_SAVE_SUCCEEDED = 'syftacp/LISTING_SAVE_SUCCEEDED' as const
export const LISTING_UPDATE_BEGIN = 'syftacp/LISTING_UPDATE_BEGIN' as const
export const LISTING_UPDATE_FAILED = 'syftacp/LISTING_UPDATE_FAILED' as const
export const LISTING_UPDATE_SUCCEEDED = 'syftacp/LISTING_UPDATE_SUCCEEDED' as const

export const LISTINGS_DELETE_BEGIN = 'syftacp/LISTINGS_DELETE_BEGIN' as const
export const LISTINGS_DELETE_FAILED = 'syftacp/LISTINGS_DELETE_FAILED' as const
export const LISTINGS_DELETE_SUCCEEDED = 'syftacp/LISTINGS_DELETE_SUCCEEDED' as const
export const LISTINGS_FETCH_BEGIN = 'syftacp/LISTINGS_FETCH_BEGIN' as const
export const LISTINGS_FETCH_FAILED = 'syftacp/LISTINGS_FETCH_FAILED' as const
export const LISTINGS_FETCH_SUCCEEDED = 'syftacp/LISTINGS_FETCH_SUCCEEDED' as const
export const LISTINGS_RESEND_APPROVAL_BEGIN = 'syftacp/LISTINGS_RESEND_APPROVAL_BEGIN' as const
export const LISTINGS_RESEND_APPROVAL_FAILED = 'syftacp/LISTINGS_RESEND_APPROVAL_FAILED' as const
export const LISTINGS_RESEND_APPROVAL_SUCCEEDED = 'syftacp/LISTINGS_RESEND_APPROVAL_SUCCEEDED' as const
