import React from 'react'
import { connect } from 'react-redux'
import { Grid, Row, Col } from 'react-bootstrap'

import { ButtonGroup, ButtonLink } from 'syft-acp-atoms/Button'
import { defaultWrapperPropTypes } from 'syft-acp-util/entityList'
import RecordControls from 'syft-acp-core/components/RecordSection/RecordControls'
import SiteComponent from 'syft-acp-core/components/SiteComponent'
import { getWorker$ } from 'syft-acp-core/store/workers/selectors'
import WorkerHeader from 'syft-acp-core/entities/WorkerDetail/WorkerHeader'
import NewNetworkButton from './NewNetworkButton'

const WorkerNetworkDetailWrapper = props => {
  return (
    <SiteComponent className={props.className}>
      <div>
        <Grid>
          <Row>
            <Col md={12}>
              <RecordControls>
                <ButtonGroup>
                  <ButtonLink to="/entity/workers">Back to list</ButtonLink>
                </ButtonGroup>
                <ButtonGroup>
                  {React.cloneElement(props.filterForm, {
                    extraButtons: <NewNetworkButton label="New trusted network" workerID={props.workerID} />,
                  })}
                </ButtonGroup>
              </RecordControls>
              <WorkerHeader data={props.workerData} />
            </Col>
          </Row>
        </Grid>
        {props.entityListResults}
        {props.entityList}
        {props.entityListPagination}
      </div>
    </SiteComponent>
  )
}

WorkerNetworkDetailWrapper.propTypes = defaultWrapperPropTypes

export default connect((state, ownProps) => ({
  workerData: getWorker$(state.workers)(ownProps.workerID),
}))(WorkerNetworkDetailWrapper)
