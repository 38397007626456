// Syft ACP - Lib <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import React, { useEffect } from 'react'
import ReactTooltip from 'react-tooltip'

import './InformationTip.css'

const InformationTip = ({ Id, message }: { Id: string; message: string | undefined }) => {
  useEffect(() => {
    ReactTooltip.rebuild()
  })

  const getMessage = () => {
    return message
  }

  return (
    <ReactTooltip
      id={Id}
      place="right"
      type="info"
      effect="solid"
      backgroundColor="white"
      textColor="grey"
      border={true}
      borderColor="grey"
      className="informationTip"
      getContent={getMessage}
    />
  )
}

export default InformationTip
