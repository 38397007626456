// Syft ACP - Atoms <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import iconFactory from './base/iconFactory'

import CheckIcon from './CheckIcon'
import ClockIcon from './ClockIcon'
import PeopleIcon from './PeopleIcon'
import PersonIcon from './PersonIcon'

export { CheckIcon, ClockIcon, PeopleIcon, PersonIcon }

export default iconFactory
