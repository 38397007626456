import { DefaultRootState } from 'react-redux'
import { createSelector } from 'reselect'
import { get, memoize } from 'lodash-es'

import { WorkerStrikeEntity } from './types'

/**
 * Simple selector for retrieving a worker strike.
 */
export const getWorkerStrike$ = createSelector(
  (state: DefaultRootState) => state.workerStrikes,
  workerStrikes =>
    memoize((id: number) => {
      const fromDetail = get(workerStrikes, `entityDetail[${id}]`, {})
      const fromMap = get(workerStrikes, `entityMap[${id}]`, {})
      return { ...fromDetail, ...fromMap } as WorkerStrikeEntity
    })
)
