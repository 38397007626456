import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { get, isObject } from 'lodash-es'
import NotifyBar from 'syft-acp-atoms/NotifyBar'

import './EntityAlert.css'

// An error item is occasionally an object, which we'll decompose to a string here.
const toString = obj => {
  if (isObject(obj)) {
    return Object.keys(obj)
      .map(key => `${key}: ${obj[key]}`)
      .join(', ')
  } else {
    return obj
  }
}

class EntityAlert extends React.PureComponent {
  constructor() {
    super()
    this.msgCountdown = null
    this.msgTimeout = 20000
    this.state = {
      errorMessage: null,
    }
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    // Retrieve the error message just like in render().
    // Set up a timeout that will erase the message after a while.
    const { lastBody, lastBodyKey, lastMessage } = newProps
    const lastAlert = get(lastBody, lastBodyKey, false)

    // Clear the previous timeout, which shouldn't happen anymore.
    clearTimeout(this.msgCountdown)
    this.setState({ errorMessage: lastAlert })
    if (!lastAlert || lastMessage === '') return null

    this.msgCountdown = setTimeout(() => {
      this.setState({ errorMessage: false })
    }, this.msgTimeout)
  }

  render() {
    const { errorMessage } = this.state
    if (!errorMessage) return null
    return (
      <NotifyBar severity="error">
        <span>{toString(errorMessage)}</span>
      </NotifyBar>
    )
  }
}

EntityAlert.propTypes = {
  lastBody: PropTypes.object,
  lastBodyKey: PropTypes.string,
}

EntityAlert.defaultProps = {
  lastBody: {},
  lastBodyKey: 'error_description',
}

export const EntityAlertConnected = entityStore =>
  connect(state => ({
    lastBody: state[entityStore].lastBody,
    lastMessage: state[entityStore].lastMessage,
  }))(EntityAlert)

export default EntityAlert
