import React from 'react'
import PropTypes from 'prop-types'

import { formatMissingTimeDuration } from 'syft-acp-util/formatting'
import { cellPropTypes, cellDefaultProps } from '../dataProps'
import { colPlaceholderFactory } from '../../../placeholders/factory'

// Column placeholder component.
export const AcpColDuration = colPlaceholderFactory('AcpCellDuration')

/**
 * Column type definition for AcpTable components.
 * TODO: describe.
 *
 *    <AcpCol type={ tableType.DURATION } value="id" header="ID" />
 */
export default class AcpCellDuration extends React.PureComponent {
  static propTypes = {
    ...cellPropTypes,
    // Value must be a number in milliseconds.
    // FIXME
    value: PropTypes.number,
  }

  static defaultProps = {
    ...cellDefaultProps,
  }

  render() {
    const { value } = this.props
    // Note: the value given to us will be the duration in seconds.
    // Display this duration only as hours and minutes.
    const duration = formatMissingTimeDuration(Number(value))
    return <span>{duration}</span>
  }
}
