// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import entityReducer from './generators/entityReducer'
import * as types from '../actions/action-types'
import * as entityTypes from './generators/entities'

const update = (list, findValue, newValue) => list.map(e => (findValue(e) ? newValue(e) : e))

export default entityReducer(
  'listingRatings',
  {
    [entityTypes.ENTITIES_FETCH_BEGIN]: types.LISTING_FETCH_RATINGS_BEGIN,
    [entityTypes.ENTITIES_FETCH_SUCCEEDED]: types.LISTING_FETCH_RATINGS_SUCCEEDED,
    [entityTypes.ENTITIES_FETCH_FAILED]: types.LISTING_FETCH_RATINGS_FAILED,
    [types.JOB_RATE_WORKER_SUCCEEDED]: (state, action) => {
      return {
        ...state,
        entityMap: {
          ...state.entityMap,
          [action.request.jobID]: {
            ...state.entityMap[action.request.jobID],
            worker_ratings: update(
              state.entityMap[action.request.jobID].worker_ratings,
              r => r.worker.id === action.request.workerID,
              r => ({ ...r, stars: action.request.rate })
            ),
          },
        },
      }
    },
    [types.RATING_DELETE_SUCCEEDED]: (state, action) => {
      return {
        ...state,
        entityMap: {
          ...state.entityMap,
          [action.request.jobID]: {
            ...state.entityMap[action.request.jobID],
            worker_ratings: update(
              state.entityMap[action.request.jobID].worker_ratings,
              r => r.worker.id === action.request.workerID,
              r => ({ ...r, stars: 0 })
            ),
          },
        },
      }
    },
  },
  {
    idKey: 'job_id',
    localEntityMap: false,
    groupBy: ['listingID'],
  }
)
