import React from 'react'
import { Form } from 'react-final-form'
import { isFunction } from 'lodash-es'

import Modal from '../Modal'
import { Props } from './ModalForm.types'

export const ModalForm = function <FormValues = Record<string, any>, InitialFormValues = Partial<FormValues>>({
  onConfirm,
  children,
  form: formProps = {},
  ...restModalProps
}: Props<FormValues, InitialFormValues>) {
  return (
    <Form<FormValues, InitialFormValues> {...formProps} onSubmit={onConfirm}>
      {formRenderProps => {
        const form = formRenderProps.form
        const formState = form.getState()
        return (
          <Modal
            {...restModalProps}
            onConfirm={() => form.submit()}
            canSubmit={!formState.hasValidationErrors && !formState.submitting}
            isLoading={formState.submitting}
            errorText={formState.submitError || (formState.hasValidationErrors && formState.dirty && formState.error)}
          >
            <>{isFunction(children) ? children(formRenderProps) : children}</>
          </Modal>
        )
      }}
    </Form>
  )
}
