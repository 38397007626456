import * as api from 'syft-acp-core/api/resources/admin/overpayments'
import { takeLatestApiCall } from './helpers'

export default function* root() {
  yield takeLatestApiCall('OVERPAYMENTS_FETCH', api.listOverpayments)
  /*
  yield takeLatestApiCall('OVERPAYMENT_SAVE', api.saveOverpayment);
  yield takeLatest(
    types.ADMIN_MANAGER_CREATE_SUCCEEDED,
    redirectCall,
    ({ payload: { id } }) => `/admin/managers/view/${id}`
  );
  */
}
