import entityReducer from 'syft-acp-core/reducers/generators/entityReducer'
import * as entityTypes from 'syft-acp-core/reducers/generators/entities'

import * as types from './action-types'
import {
  FetchWorkerDuplicateAccountsSucceededAction,
  WorkerDuplicateAccountsCountEntity,
  WorkerDuplicateAccountsState,
} from './types'

export default entityReducer<WorkerDuplicateAccountsCountEntity>(
  'workerDuplicateAccountsCount',
  {
    [entityTypes.ENTITIES_FETCH_BEGIN]: types.WORKER_DUPLICATE_ACCOUNTS_COUNT_FETCH_BEGIN,
    [entityTypes.ENTITIES_FETCH_FAILED]: types.WORKER_DUPLICATE_ACCOUNTS_COUNT_FETCH_FAILED,
    [types.WORKER_DUPLICATE_ACCOUNTS_COUNT_FETCH_SUCCEEDED]: (
      state: WorkerDuplicateAccountsState,
      action: FetchWorkerDuplicateAccountsSucceededAction,
    ) => {
      const workerId = action?.request?.workerID

      if (!workerId) {
        return state
      }

      const { entityMap } = state

      return {
        ...state,
        entityMap: {
          ...entityMap,
          [workerId]: {
            ...action.payload,
          },
        },
        isLoadingData: false,
        isSavingData: false,
      }
    },
  },
  {
    localEntityMap: true,
    groupBy: ['workerID'],
  },
)
