// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import React from 'react'
import SubNavigation, { SubLink } from 'syft-acp-atoms/SubNavigation'
import CountrySpecific from 'syft-acp-core/components/CountrySpecific'
import { SUPPORTED_COUNTRY_CODES } from 'syft-acp-core/lib/i18n'

const SubNavigationWatchlist = () => (
  <SubNavigation>
    <SubLink to="/watchlist/strikes">Strikes</SubLink>
    <SubLink to="/watchlist/alerts">Alerts</SubLink>
    <SubLink to="/watchlist/conversations/list">Conversations</SubLink>
    <SubLink to="/watchlist/documents">Documents</SubLink>
    <SubLink to="/watchlist/feedback">Feedback</SubLink>
    <CountrySpecific countries={[SUPPORTED_COUNTRY_CODES.GB]}>
      <SubLink to="/watchlist/compliance">Compliance</SubLink>
    </CountrySpecific>
    <SubLink to="/watchlist/workerCsat">Worker CSAT</SubLink>
    <SubLink to="/watchlist/employerCsat">Employer CSAT</SubLink>
    <SubLink to="/watchlist/agencyCsat">Agency CSAT</SubLink>
    {/* <SubLink to="/watchlist/health">Health Questionnaires</SubLink> */}
  </SubNavigation>
)

export default SubNavigationWatchlist
