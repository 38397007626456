/* eslint-disable react/prop-types */
// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited
import React from 'react'
import { formatDate } from 'syft-acp-util/formatting'
import * as tableType from 'syft-acp-core/components/EntityList/'
import * as workerMissingPayActions from 'syft-acp-core/store/worker-missing-pay/actions'
import entityList from 'syft-acp-util/entityList'
import { SUPPORTED_COUNTRY_CODES } from 'syft-acp-core/lib/i18n'
import AddWorkerMissingPayModal from 'syft-acp-core/components/Modal/AddWorkerMissingPayModal'
import { store } from 'syft-acp-core/store'
import * as filterTypes from 'syft-acp-util/components/FilterForm/FilterForm'
import { showConfirmModal, showModal } from 'syft-acp-core/store/modals/actions'
import WorkerMissingPayWrapper from './WorkerMissingPayWrapper'
import AddWorkerRetroactiveShiftModal from '../Modal/AddWorkerRetroactiveShiftModal'

const tableFormat = [
  { type: tableType.SELECTOR },
  {
    type: tableType.TXT,
    header: 'ID',
    val: 'id',
    classes: ['nowrap'],
    numeric: true,
  },
  {
    type: tableType.TXT,
    header: 'Shift ID',
    val: 'shift_id',
    classes: ['nowrap'],
    numeric: true,
  },
  {
    type: tableType.TXT,
    header: 'Employer',
    val: 'employer_id',
    classes: ['main'],
    numeric: true,
  },
  {
    type: tableType.TXT,
    header: 'Venue',
    val: 'venue_id',
    classes: ['nowrap'],
    numeric: true,
  },
  {
    type: tableType.TXT,
    header: 'Owed hours',
    // val: 'missing_time_in_hours',
    classes: ['nowrap'],
    val: e => `${Math.floor(e.missing_time_in_hours)}:${(e.missing_time_in_hours * 60) % 60}`,
  },
  {
    type: tableType.MONEY,
    header: 'Worker Pay Rate',
    val: 'worker_pay_rate',
    classes: ['nowrap'],
  },
  {
    type: tableType.TXT,
    header: 'Date of Work',
    classes: ['nowrap'],
    val: e => e.date_of_work && formatDate(e.date_of_work),
  },
  {
    type: tableType.TXT,
    header: 'Date added',
    classes: ['nowrap'],
    val: e => e.created_at && formatDate(e.created_at),
  },
  {
    type: tableType.TXT,
    header: 'Added by',
    classes: ['nowrap'],
    val: e => e.creator_full_name,
  },
]

const actionFormat = workerID => [
  {
    label: 'Delete missing pay',
    type: filterTypes.TYPE_BUTTON,
    buttonType: 'danger',
    action: items => {
      store.dispatch(
        showConfirmModal({
          question: items.length === 1 ? 'Delete this missing pay?' : `Delete these missing pays?`,
          onConfirm: () => {
            items.forEach(item => store.dispatch(workerMissingPayActions.deleteMissingPay(workerID, item)))
          },
        }),
      )
    },
  },
  {
    label: 'Add Non Standard Hours',
    type: filterTypes.TYPE_BUTTON,
    global: true,
    hideForCountries: [SUPPORTED_COUNTRY_CODES.US],
    action: () => {
      store.dispatch(showModal('addWorkerMissingPayModal'))
    },
  },
  {
    label: 'Add Retroactive Shift',
    type: filterTypes.TYPE_BUTTON,
    global: true,
    showForCountries: [SUPPORTED_COUNTRY_CODES.US],
    action: () => {
      store.dispatch(showModal('addWorkerRetroactiveShiftModal'))
    },
  },
]

const WorkerMissingPayList = entityList(
  WorkerMissingPayWrapper,
  tableFormat,
  null,
  null,
  'workerMissingPay',
  {
    fetchEntities: ({ options }, { workerID }) => workerMissingPayActions.fetchMissingPay(workerID, options),
  },
  {
    recordList: true,
    // hasLinks: false,
    disableAutoScroll: true,
    paginationKey: 'missingPayPage',
  },
)

export default props => (
  <div className="WorkerMissingPayList">
    <AddWorkerMissingPayModal worker={props.worker} />
    <AddWorkerRetroactiveShiftModal worker={props.worker} />
    <WorkerMissingPayList
      actionFormat={actionFormat(props.worker.id)}
      selectFullRow
      selectCallback={data => store.dispatch(showModal('addWorkerMissingPayModal', {}, null, data))}
      {...props}
    />
  </div>
)
