// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { Form } from 'react-bootstrap'
import flexLogoUrl from './logos/indeed-flex-logo-login.svg'
import { Box, FormField, Input } from '@indeed/ifl-components'
import { Button, Text } from '@indeed/ifl-components'

import './LoginFormPure.css'

class LoginFormPure extends PureComponent {
  static propTypes = {
    isDisabled: PropTypes.bool.isRequired,
    isAwaitingRehydration: PropTypes.bool.isRequired,
    feedbackMessage: PropTypes.string.isRequired,
    hasResetPassword: PropTypes.bool.isRequired,
    onTypeEmail: PropTypes.func.isRequired,
    onTypePassword: PropTypes.func.isRequired,
    onForgotPassword: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onLoginTrouble: PropTypes.func.isRequired,
    valueEmail: PropTypes.string.isRequired,
    valuePassword: PropTypes.string.isRequired,
  }

  render() {
    const props = this.props
    const isDisabled = props.isDisabled || props.isAwaitingRehydration
    return (
      <div className="login-container">
        <Form
          onSubmit={props.onSubmit}
          className={`login ${props.isAwaitingRehydration ? 'awaiting-rehydration' : ''}`}
          data-testid="form-login"
        >
          <Box as="img" src={flexLogoUrl} className="login-main-logo" />
          <div className="section">
            <FormField className="email" htmlFor="email" label="Email">
              <Input
                aria-label="Email"
                onChange={props.onTypeEmail}
                value={props.valueEmail}
                disabled={isDisabled}
                id="email"
              />
            </FormField>
            <FormField className="password" htmlFor="password" label="Password">
              <Input
                type="password"
                onChange={props.onTypePassword}
                aria-label="Password"
                value={props.valuePassword}
                disabled={isDisabled}
                id="password"
              />
            </FormField>
            <div className="login-buttons">
              <Button
                variant="primary"
                size="md"
                type="submit"
                disabled={isDisabled}
                onClick={props.onSubmit}
              >
                Log in
              </Button>
              <Button
                className="forgotten-password"
                type="button"
                variant="secondary"
                disabled={isDisabled}
                onClick={props.onForgotPassword}
              >
                Forgot your password?
              </Button>
            </div>
            {props.feedbackMessage && <p className="feedback-message">{props.feedbackMessage}</p>}
          </div>
          <div className="section secondary">
            <Button variant="destructiveText" onClick={this.props.onLoginTrouble}>
              <Text level={3}>Having trouble logging in?</Text>
            </Button>
          </div>
        </Form>
      </div>
    )
  }
}

export default LoginFormPure
