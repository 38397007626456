import { apiRequest, handleCall } from 'syft-acp-core/api/call'

// import { WorkerTierRewards } from './types'

/**
 * Retrieves tier data of a worker.
 *
 * @param params {Object}
 * @param params.workerID {Number} The worker ID
 */
export const fetchWorkerTierRewards = ({ workerID, options = {} }: { workerID: number; options: {} }) =>
  new Promise((resolve, reject) => {
    const req = apiRequest({
      path: `/admin/status_program/workers/${workerID}/tier_summary`,
    })
    handleCall(req, resolve, reject, true, options)
  })
