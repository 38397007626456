import * as types from 'syft-acp-core/store/action-types'
import { ManuallyConfirmWorkerInput } from 'syft-acp-core/entities/ShiftList/helpers/helpers.types'

import { ShiftBookingsUpdatePayload } from '../types'

export const fetchShiftBookings = ({ options }: { options: Record<string, any> }) => ({
  type: types.SHIFT_BOOKINGS_FETCH_BEGIN,
  options,
})
export type FetchShiftBookingsAction = ReturnType<typeof fetchShiftBookings>

export const updateShiftBookingsDisplayed = ({ payload }: { payload: ShiftBookingsUpdatePayload }) => ({
  type: types.SHIFT_BOOKINGS_UPDATE,
  payload,
})
export type UpdateShiftBookingsDisplayedAction = ReturnType<typeof updateShiftBookingsDisplayed>

export const clearShiftBookings = ({ id }: { id: number }) => ({
  type: types.SHIFT_BOOKINGS_CLEAR,
  id,
})
export type ClearShiftBookingsAction = ReturnType<typeof clearShiftBookings>

export const fetchShiftBookingFailed = (
  message: string,
  payload: { response: { status: number }; message: string }
) => ({
  type: types.SHIFT_BOOKINGS_FETCH_FAILED,
  message,
  payload,
})
export type FetchShiftBookingFailedAction = ReturnType<typeof fetchShiftBookingFailed>

export type ConfirmShiftBookingSucceededAction = {
  type: typeof types.CONFIRM_SHIFT_BOOKING_SUCCEEDED
  request: { workerID: number; jobID: number; shiftIDs: number[] }
}

export const confirmBulkShiftBookingsBegin = () => ({ type: types.CONFIRM_BULK_SHIFTS_BOOKING_BEGIN })
export type confirmBulkShiftBookingsBeginAction = ReturnType<typeof confirmBulkShiftBookingsBegin>
export const confirmBulkShiftBookingsSucceeded = (payload: ManuallyConfirmWorkerInput['payload']) => ({
  type: types.CONFIRM_BULK_SHIFTS_BOOKING_SUCCEEDED,
  payload,
})
export type ConfirmBulkShiftBookingsSucceededAction = ReturnType<typeof confirmBulkShiftBookingsSucceeded>
export const confirmBulkShiftBookingsFailed = () => ({ type: types.CONFIRM_BULK_SHIFTS_BOOKING_FAILED })
export type ConfirmBulkShiftBookingsFailedAction = ReturnType<typeof confirmBulkShiftBookingsFailed>

export const highlightShiftBooking = (bookingIDs: number[], highlight: string) => ({
  type: types.HIGHLIGHT_SHIFT_BOOKING_BEGIN,
  bookingIDs,
  highlight,
})
export type HighlightShiftBookingBeginAction = ReturnType<typeof highlightShiftBooking>
export type HighlightShiftBookingSucceededAction = {
  type: typeof types.HIGHLIGHT_SHIFT_BOOKING_SUCCEEDED
  payload: {
    [key: string]: any
    id: number
    highlight: string
  }[]
  request: Record<string, any>
}

export const confirmShiftBooking = (jobID: number, workerID: number, shiftIDs: number[]) => ({
  type: types.CONFIRM_SHIFT_BOOKING_BEGIN,
  jobID,
  workerID,
  shiftIDs,
})
export type ConfirmShiftBookingAction = ReturnType<typeof confirmShiftBooking>
