// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import * as types from './action-types'

export function setEmployerLoading({ id }) {
  return { type: types.EMPLOYER_TOKEN_FETCH_BEGIN, id }
}

export function saveEmployerToken({ id, url }) {
  return { type: types.EMPLOYER_TOKEN_SAVE, id, url }
}
