export const trackingEvents = {
  PAGE: {
    VIEWED: 'worker_transfer_detail.page.viewed',
  },
  TRANSFER: {
    CREATED: 'worker_transfer_detail.transfer.created',
    UPDATED: 'worker_transfer_detail.transfer.updated',
    DELETED: 'worker_transfer_detail.transfer.deleted',
  },
}
