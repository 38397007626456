// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

/** Shortens a subindustry, like "Front of House" to "Front o.h." */
export const shortenSubIndustry = sub => {
  if (!sub) return ''
  const l = sub.toLowerCase()
  if (l.indexOf('of house') > -1) {
    return `${sub.split(' ')[0]} o.h.`
  }
  return sub
}
