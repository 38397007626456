import * as React from 'karet'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import './InputContainer.css'

const InputContainer = ({ children }) => <span className={classNames('acp-input-container')}>{children}</span>

InputContainer.propTypes = {
  children: PropTypes.node.isRequired,
}

export default InputContainer
