// Syft ACP - Core <https://github.com/Syft-Application/syft2acp>
// © Syft Online Limited

import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { get } from 'lodash-es'

import { entityList$ } from 'syft-acp-core/reducers/generators/entities'
import UserAvatar from '.'

/**
 * Displays an avatar by ID. Must be connected to a specific part of the store (usually 'workers' or 'employers').
 */
class ConnectedUserAvatar extends React.PureComponent {
  static propTypes = {
    items: PropTypes.array.isRequired,
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    connectedTo: PropTypes.string.isRequired,
    size: PropTypes.string.isRequired,
    shape: PropTypes.string.isRequired,
  }

  // Returns an item's avatar UUID by their ID.
  // If the avatar cannot be found, the 'guest' avatar is shown instead.
  getAvatarUUID = id => {
    const { items } = this.props
    const item = items.find(i => i.id === id)
    if (!item) return 'guest'

    return get(item, 'picture.uuid', 'guest')
  }

  render() {
    const { id } = this.props
    return <UserAvatar avatar={this.getAvatarUUID(id)} size="small" shape="rounded-square" />
  }
}

export default connect((state, ownProps) => {
  const storeSection = entityList$(get(state, ownProps.connectedTo), {})
  return {
    items: storeSection || [],
  }
})(ConnectedUserAvatar)
