import React, { useState } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as filtersActions from 'syft-acp-core/store/filters/actions'

import FilterHierarchyMultiselect from '../FilterHierarchyMultiselect'
import { Store } from 'syft-acp-core/store'

import { Props, FederatedState } from './types'
import { getInitialValues, allStatesListWithAll, handleSelectAll } from './helpers'

export const storeConnector = connect(
  (state: Store) => ({
    query: state?.routing?.locationBeforeTransitions?.query,
  }),
  dispatch => ({
    actions: bindActionCreators({ ...filtersActions }, dispatch),
  }),
)

type AllProps = ConnectedProps<typeof storeConnector> & Props

const FilterUSStatesMultiselect = ({ name, actions, query }: AllProps) => {
  const states = allStatesListWithAll()
  const [selected, setSelected] = useState(getInitialValues(query))

  const onChangeHandler = (val: FederatedState[]) => {
    val = handleSelectAll(selected, val)
    setSelected(val)
    const ids: string[] = val.map(state => state.value as string).filter(id => id !== 'all')
    actions.setFilter(name, ids)
  }
  return (
    <FilterHierarchyMultiselect
      values={selected}
      onChange={onChangeHandler}
      options={states}
      placeholder="Click or type to select"
      withSearch={true}
    />
  )
}

export default storeConnector(FilterUSStatesMultiselect)
