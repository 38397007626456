import { useMemo } from 'react'
import { has } from 'lodash-es'

import type { Compliances, ComplianceSkill, GroupedComplianceDetails } from './ComplianceDetail.types'

export const useDetailStructure = (compliances: Compliances) => {
  return useMemo(
    () =>
      compliances?.skills?.reduce((acc: GroupedComplianceDetails, { skill, role }: ComplianceSkill) => {
        if (!role) {
          if (!has(acc, 'allRoles')) {
            return {
              ...acc,
              allRoles: {
                title: 'All roles',
                skills: [skill],
              },
            }
          }
          acc.allRoles?.skills.push(skill)
          return acc
        } else if (has(acc, role.id)) {
          acc[role.id].skills.push(skill)
          return acc
        }
        return {
          ...acc,
          [role.id]: {
            title: role.title,
            skills: [skill],
          },
        }
      }, {} as GroupedComplianceDetails),
    [compliances]
  )
}
