import React from 'react'
import { FilterAutocompleteMultiSelect } from 'syft-acp-util/components/FilterForm'
import { fetchTimeOffRequestVenues } from 'syft-acp-core/store/timeOffRequests/api'

const mapFetchedVenues = async (venue: string) =>
  fetchTimeOffRequestVenues(venue).then(result =>
    result.payload.map(({ id, name }) => ({ value: id, label: name })),
  )

const FilterTimeOffRequestVenue = () => {
  return <FilterAutocompleteMultiSelect name="venue_ids" queryFunction={mapFetchedVenues} />
}

export default FilterTimeOffRequestVenue
